import { CircularProgress, Dialog, DialogContent, DialogTitle, Grid, Typography, makeStyles } from '@material-ui/core'
import MROButton from 'components/buttons'
import MROTextField from 'components/form_components/TextField'
import QuantityCounter from 'components/quantity_counter'
import { ToastMessageContext } from 'lib/contexts/message_context'
import { WorkOrderServices } from 'lib/services/api/operaitons/lineMaintenance/workOrder/workOrder'
import { getOrganisationId } from 'lib/utils/common_utils'
import React, { useEffect, useState, useContext } from 'react'


const useStyles = makeStyles((theme) => ({
    dialog: {
        "& .MuiDialog-paperWidthSm": {
            minWidth: "750px",
            borderRadius: '5px'
        }
    },
    dialogTitle: {
        color: theme.palette.primary.main,
        fontSize: '18px',
        fontWeight: 500
    }

}))


function CreateParts(props) {
    const classes = useStyles()
    const { handleClose, currentSelection, workOrderId, getRemovedPartList, action } = props
    const [quantity, setQuantity] = useState()
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false)
    const message = useContext(ToastMessageContext)

    console.log("currentSelection========", currentSelection)

    useEffect(() => {
        currentSelection && setQuantity(currentSelection.part_quantity)
    }, [currentSelection])



    const validateFields = (formData) => {
        let error = {};
        let valid = true;

        const removedQty = formData.get(action === 'update' ? "removed_quantity" : "quantity");


        for (let pair of formData.entries()) {
            const key = pair[0];
            console.log(pair);
            if (pair[1] === "") {
                error[key] = true;
                valid = false;
            }
        }

        if (+removedQty === 0 && removedQty !== "") {
            error["removed_quantity_is_zero"] = true;
            error[action === 'update' ? "removed_quantity" : "quantity"] = true;
            valid = false;
        }


        if (!valid) {
            setErrors(error);
        }
        console.log({ error });
        return valid;
    };



    const submitHandler = async (e) => {
        const formData = new FormData(e.target)
        let removalLocation = formData.get('install_location')
        formData.append("reportable_type", "WorkOrder");
        formData.append("reportable_id", workOrderId);
        formData.append("organisation_id", getOrganisationId())
        formData.append("removal_location", removalLocation)

        if (!validateFields(formData)) {
            return;
        }
        if (action === 'update') {
            formData.append("id", currentSelection.id)
            try {
                setLoading(true)
                const response = await WorkOrderServices.editRemovedParts(formData)


                setLoading(false)
                if (response.success) {
                    message.showToastMessage({
                        message: "Added successfully.",
                        variant: "success",
                    });
                }
                else {
                    const msg =
                        response.errors?.length > 0
                            ? response.errors.join(", ")
                            : "Something went wrong!";
                    message.showToastMessage({
                        message: msg,
                        variant: "error",
                    });
                }
                handleClose()
                getRemovedPartList()

            }

            catch (error) {
                handleClose()
                message.showToastMessage({
                    message: "Something went wrong!",
                    variant: "error",
                });

            }


        }
        else {
            try {
                setLoading(true)
                const response = await WorkOrderServices.createRemovedParts(formData)


                setLoading(false)
                if (response.success) {
                    message.showToastMessage({
                        message: "Added successfully.",
                        variant: "success",
                    });
                }
                else {
                    const msg =
                        response.errors?.length > 0
                            ? response.errors.join(", ")
                            : "Something went wrong!";
                    message.showToastMessage({
                        message: msg,
                        variant: "error",
                    });
                }
                handleClose()
                getRemovedPartList()

            }

            catch (error) {
                handleClose()
                message.showToastMessage({
                    message: "Something went wrong!",
                    variant: "error",
                });

            }
        }
    }



    return (
        <div >
            <Dialog
                // fullWidth={true}
                className={classes.dialog}
                maxWidth={'sm'}
                open={true}
                onClose={handleClose}

                aria-labelledby="add-removed-parts"
            // style={{ width: "403px" }}
            >

                <DialogTitle ><Typography variant='subtitle2' className={classes.dialogTitle}>Removed Part </Typography> </DialogTitle>
                <DialogContent>
                    <form onSubmit={submitHandler} noValidate>
                        <Grid >
                            <MROTextField
                                name="part_off"
                                error={errors?.part_off}
                                label="Part # Off *"
                                variant="outlined"
                                // value={copySelectedPartOff}
                                defaultValue={currentSelection?.part_off}
                                InputLabelProps={{ shrink: true }}
                                placeholder={"Enter Part Number"}
                                onChange={() =>
                                    setErrors({ ...errors, part_off: false })
                                }
                            />

                            <MROTextField
                                // style={{ display: "none" }}
                                // value={currentSelection.description}
                                // disabled={currentSelection?.description && true}
                                defaultValue={currentSelection?.description_off}
                                name="description_off"
                                label="Description *"
                                InputLabelProps={{ shrink: true }}
                                error={errors?.description_off}
                                placeholder={'Enter Part Description'}
                                onChange={() =>
                                    setErrors({ ...errors, description_off: false })
                                }
                            />
                            <MROTextField
                                name="serial_off"
                                error={errors?.serial_off}
                                variant="outlined"
                                label="Serial # Off *"
                                defaultValue={currentSelection?.serial_off}
                                InputLabelProps={{ shrink: true }}
                                placeholder={"Enter Serial Number"}
                                onChange={() =>
                                    setErrors({ ...errors, serial_off: false })
                                }
                            />
                            <MROTextField
                                name="install_location"
                                error={errors?.install_location}
                                variant="outlined"
                                label="Location Off *"
                                defaultValue={currentSelection?.location}
                                InputLabelProps={{ shrink: true }}
                                placeholder={"Enter Serial Number"}
                                onChange={() =>
                                    setErrors({ ...errors, install_location: false })
                                }
                            />
                            <QuantityCounter
                                name={action === 'update' ? "removed_quantity" : "quantity"}
                                label={"QTY Removed *"}
                                placeholder={"Quantity"}
                                setQty={setQuantity}
                                qty={quantity}
                                autoFocus={action === 'update' ? errors.removed_quantity : errors.quantity}
                                error={action === 'update' ? errors.removed_quantity : errors.quantity}
                                helperText={
                                    action === 'update' ? errors.removed_quantity : errors.quantity ? errors.removed_quantity_is_zero ? "Quantity must be greater than 0" : "Please enter Quantity" : null

                                }
                                setError={() =>
                                    setErrors({ ...errors, quantity: false, removed_quantity_is_zero: null })
                                }

                            />
                            <MROTextField
                                name={"issued_comment"}
                                label={"U/S Comment *"}
                                defaultValue={currentSelection?.issued_comment
                                }
                                error={errors.issued_comment}
                                InputLabelProps={{ shrink: true }}
                                placeholder="Enter a removal reason or other comment as applicable - Max 150 Characters"
                                multiline
                                setError={() =>
                                    setErrors({ ...errors, issued_comment: false })
                                }
                            />
                        </Grid>

                        <div
                            style={{
                                marginTop: "30px",
                                marginBottom: "60px",
                                textAlign: "end",
                            }}
                        >
                            <MROButton
                                style={{ marginRight: "20px" }}
                                type="button"
                                variant={"contained"}
                                onClick={handleClose}
                            >
                                Cancel
                            </MROButton>
                            <MROButton
                                type="submit"
                                variant={"contained"}
                                color="primary"
                                loading={loading}
                            >
                                Complete
                            </MROButton>

                        </div>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default CreateParts