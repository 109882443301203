import React, { useState, useEffect, useContext } from 'react'
import MaterialTable from 'material-table'
import tableIcons, { options } from "components/universal/table_attributes";
import TableAction from "actions/table_actions";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Box, IconButton, Typography } from "@material-ui/core";
import MROButton from 'components/buttons';
import moment from 'moment';
import { getOrganisationId } from 'lib/utils/common_utils';
import ShiftServices from 'lib/services/api/planning/Shifts/shift';
import { ToastMessageContext } from 'lib/contexts/message_context';
import OvertimeReview from 'views/Planning/Shifts/Overtime/OvertimeReview';




export default function StaffOvertime(props) {
    const { setTabOvertimeValue, pageLoading, setPageLoading, bases, shiftType, areaData, zoneData } = props
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [totalCount, setTotalCount] = useState(10)
    const [action, setAction] = useState('')
    const [selectedWeek, setSelectedWeek] = useState('')
    const [currentSelection, setCurrentSelection] = useState({})
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const tableOptions = {
        ...options,
        page: page,
        total: totalCount,
        pageSize: pageSize,
        search: false,
        toolbar: false
    };
    let userDetails = JSON.parse(sessionStorage.getItem("user"));
    const message = useContext(ToastMessageContext)




    const renderViewInfoIcon = (rowData) => {

        return (
            <MROButton
                onClick={() => {
                    setAction('view')
                    setCurrentSelection(rowData)
                    setSelectedWeek(moment(rowData?.overtime_start, "DD/MM/YYYY HH:mm").format("YYYY-MM-DD"))
                }} style={{ backgroundColor: rowData?.status === 0 ? "#FFB300" : rowData?.status === 1 ? "#4FC605" : rowData?.status === 2 ? "#FF0202" : rowData?.status === 3 ? "#FFB300" : "", cursor: 'pointer', borderRadius: '5px' }}>
                <Typography style={{ fontSize: '14px', textDecoration: 'underline', color: '#fff', textTransform: 'uppercase' }}>{rowData?.status === 0 ? "Unapproved" : rowData?.status === 1 ? "Approved" : rowData?.status === 2 ? "Rejected" : rowData?.status === 3 ? "Reapproval" : ""}</Typography>
            </MROButton>
        )
    }
    const getListing = () => {

        const params = {
            organisation_id: getOrganisationId(),
            user_id: userDetails?.id,
            page: page + 1,
            limit: pageSize,

        }

        setLoading(true)
        ShiftServices.getStaffOverTimeDetails(params).then((res) => {
            setData(res.data.listing)
            setTabOvertimeValue(res.data.badge_number)
            setTotalCount(res.total_count)
            setPageLoading(false)
        }).catch(() => {
            message.showToastMessage({
                message: "Something went wrong. Try again!",
                variant: "error",
            });
        }).finally(() => {
            setLoading(false)
        })

    }




    useEffect(() => {

        getListing();

    }, [page, pageSize, totalCount, pageLoading]);


    const columns = [


        {
            title: "Requested",
            field: "requested",
            headerStyle: {
                textAlign: 'left',
                paddingLeft: '20px'

            },
            cellStyle: {
                textAlign: 'left',
                paddingLeft: '20px'
            }
        },

        {
            title: "Base",
            field: "base",
            headerStyle: {
                textAlign: 'left',


            },
            cellStyle: {
                textAlign: 'left',

            }
        },

        {
            title: "Name",
            field: "name",
            headerStyle: {
                textAlign: 'left',
                width: '30%'
            },
            cellStyle: {
                textAlign: 'left',
                width: '30%'
            }
        },
        {
            title: "Type",
            field: "type",
            headerStyle: {
                textAlign: 'left',

            },
            cellStyle: {
                textAlign: 'left',

            }
        },
        {
            title: "Overtime Start",
            field: "overtime_start",
            width: '10%'
        },
        {
            title: "Overtime End",
            field: "overtime_end",
            width: '10%'
        }, {
            title: "Hours",
            field: "hours",
        },
        {
            title: "Status",
            field: "action",
            render: rowData => renderViewInfoIcon(rowData)

        },

    ];
    return (
        <div style={{ marginTop: '30px' }} >




            <MaterialTable
                style={{ boxShadow: "0px 1px 3px #00000033", paddingRight: "0px" }}
                icons={tableIcons}
                classes
                title={""}
                columns={columns}
                data={data || []}
                isLoading={loading}
                options={tableOptions}
                localization={{
                    toolbar: {
                        searchPlaceholder: "Search anything",
                    },
                    body: {
                        emptyDataSourceMessage: "No Records found",

                    },

                }}
                onChangePage={(page) => {
                    setPage(page);
                }}
                onChangeRowsPerPage={(pageSize) => {
                    setPageSize(pageSize);
                }}
                totalCount={totalCount}
                page={page}
            />
            {action && action === "view" && <OvertimeReview
                handleClose={() => {
                    setAction('')
                }}
                bases={bases}
                shiftType={shiftType}
                areaData={areaData}
                zoneData={zoneData}
                currentSelections={currentSelection}
                selectedWeek={selectedWeek}
                setSelectedWeek={setSelectedWeek}
                getShiftOvertimeDetails={getListing}

            />}
        </div>
    )
}
