
import requestApi from "../common/boxp_api_client";
import requestrApi from "../common/boxr_api_client";
const getStationCompliance = (params) => {
    return requestApi({
        url: "/station_compliance",
        method: "GET",
        params,
    })
}

const getStationChecks = (params) => {
    return requestApi({
        url: "/station_checks",
        method: "GET",
        params,
    })
}

const getHandOverDetails = (params) => {
    return requestApi({
        url: "/handovers",
        method: "GET",
        params,
    })
}

const getVehicleChecksDetails = (params) => {
    return requestApi({
        url: "/vehicle_checks",
        method: "GET",
        params,
    })
}

const getTemperatureAndHumidityDetails = (params) => {
    return requestApi({
        url: "/storage_temperature_humidity",
        method: "GET",
        params,
    })
}

const getTemperatureAndHumidityCsv = (params) => {
    return requestApi({
        url: "/storage_temperature_humidity_csv",
        method: "GET",
        params,
    })
}

const getTemperatureAndHumidityRecentData = (params) => {
    return requestApi({
        url: "/storage_temperature_humidity_recent_data",
        method: "GET",
        params,
    })
}

const getStorageConditionListing = (params) => {
    return requestrApi({
        url: "/storage_conditions/listing_storage_condition_events",
        method: "GET",
        params,
    })

}
const getStorageEventConditionListing = (params) => {
    return requestrApi({
        url: "/storage_conditions/details_storage_event",
        method: "GET",
        params,
    })
}
export const StationDashboardServices = {
    getStationCompliance,
    getStationChecks,
    getHandOverDetails,
    getVehicleChecksDetails,
    getTemperatureAndHumidityDetails,
    getTemperatureAndHumidityCsv,
    getTemperatureAndHumidityRecentData,
    getStorageConditionListing,
    getStorageEventConditionListing
}