import React, { useReducer, useState, useContext, useEffect } from "react";
import tableIcons, { options } from "components/universal/table_attributes";
import MaterialTable from "material-table";
import tableReducer, { INITIAL_TABLE_STATE } from "reducers/table_reducer";
import TableAction from "actions/table_actions";
import SearchBarWithFilters from "components/search_with_filters";
import {
  Box,
  FormControlLabel,
  Switch,
  Typography,
} from "@material-ui/core";
import MROButton from "components/buttons";
import PartInfo from "./PartInfo";
import useDebounce from "lib/utils/debounce";
import { ToastMessageContext } from "lib/contexts/message_context";
import { PartDetailsTableRefresh } from "../section_detail";
import { makeStyles } from "@material-ui/styles";
import { WorkshopContext } from "views/Operations/Workshop/WorkshopContext/WorkshopContext";
// import clxc from "clsx";

const useStyles = makeStyles(() => ({
  stockBtn: {
    borderColor: "#4D4F5C",
    borderRadius: "8px",
    maxWidth: "70px",
  },
}));

function AlternativePartsTable(props) {
  const classes = useStyles();
  const { workOrderId, mroOwned } = props;

  const message = useContext(ToastMessageContext);
  // const [showTableData, setShowTableData] = useState(false);
  const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
  const { page, totalCount, inProgress, data } = tableState;
  const [tableRefresh, setTableRefreshs] = useState(false);
  const [searchText, setSearchText] = useState("");
  const debouncedVal = useDebounce(searchText);

  const [action, setAction] = useState(null);
  const [currentSelelction, setCurrentSelelction] = useState({});
  const [issued, setIssued] = useState(true);
  const RefreshPartDetailsTable = useContext(PartDetailsTableRefresh);
  const [pageSize, setPageSize] = useState(5);

  const { setTableRefresh } = useContext(WorkshopContext)
  const tableOptions = {
    ...options,
    page: page,
    search: false,
    total: totalCount,
    pageSize: 5,
    searchText: debouncedVal,
  };

  const closeHandler = () => {
    setTableRefreshs(!tableRefresh);
    // setCreateSection(null);
    setAction(null);
  };

  const getList = () => {
    const params = {
      reportable_type: "WorkOrder",
      reportable_id: workOrderId,
      search_keyword: debouncedVal,
      page: 1 + page,
      count_per_page: pageSize,
      to_issued: issued,
    };
    TableAction.getList(dispatch, message, params, "partsAndToolsRequestParts");
  };

  useEffect(() => {
    getList();
    setTimeout(() => {
      setTableRefresh(true)
    }, 2000)

  }, [
    workOrderId,
    debouncedVal,
    page,
    pageSize,
    RefreshPartDetailsTable,
    issued,
  ]);

  // const buttonLogic = (rowData) => {
  //   if (!rowData.approval_state) {
  //     return "#df0011";
  //   } else if (
  //     rowData?.approval_state === "approved" &&
  //     rowData?.remaining_quantity === rowData?.required_quantity
  //   ) {
  //     return "button-positive";
  //   }
  // };

  const columns = [
    {
      title: "Part Number",
      field: "part_number",
      cellStyle: {
        textAlign: "left",
        width: "30%",
        paddingLeft: 10,
      },
      headerStyle: {
        width: "30%",
        textAlign: "left",
        paddingLeft: 10,
      },
    },
    {
      title: "Description",
      field: "description",
      cellStyle: {
        width: "40%",
        paddingLeft: "20px",
      },
      headerStyle: {
        width: "40%",
        textAlign: "left",
        paddingLeft: "20px",
      },
    },
    {
      title: "Ordered",
      field: "required_quantity",
    },
    {
      title: "Issued",
      field: "issued_quantity",
    },
    {
      title: "Remaining",
      field: "remaining_quantity",
      render: (rowData) => {
        return (
          <span>{rowData?.required_quantity - rowData?.issued_quantity}</span>
        )
      }
    },
    {
      title: "Status",
      render: (rowData) => {
        if (!rowData?.approval_state && rowData?.status != null) {
          return (
            <MROButton
              variant="outlined"
              style={{
                backgroundColor: "red",
                textDecoration: "underline",
                color: "white",
                borderRadius: "6px",
                PointerEvent: "none",
                textTransform: "uppercase",
                minWidth: "100px"
              }}
              onClick={() => {
                setAction("partDetails");
                setCurrentSelelction(rowData);
              }}
            >
              <Typography style={{ wordWrap: "break-word" }}>
                <span style={{ marginRight: "3px" }}>
                  {rowData?.status?.qty}{" "}
                </span>{" "}
                <span style={{ textTransform: "uppercase" }}>{"approval"}</span>
              </Typography>
            </MROButton>
          );
        } else if (
          rowData?.status?.status === "issued" ||
          (rowData?.approval_state === "approved" &&
            rowData?.issued_quantity === rowData?.required_quantity &&
            rowData?.status === null)
        ) {
          return (
            <MROButton
              variant="outlined"
              style={{
                borderColor: "darkgrey",
                textDecoration: "underline",
                color: "darkgrey",
                borderRadius: "6px",
                PointerEvent: "none",
                textTransform: "uppercase",
                minWidth: "100px"
              }}
              onClick={() => {
                setAction("partDetails");
                setCurrentSelelction(rowData);
              }}
            >
              <Typography style={{ fontSize: "14px" }}>
                <span style={{ textTransform: "uppercase" }}>
                  {"all issued"}
                </span>
              </Typography>
            </MROButton>
          );
        } else if (
          rowData?.issued_quantity != rowData?.required_quantity &&
          rowData?.status === null
        ) {
          return (
            <MROButton
              variant="outlined"
              style={{
                borderColor: "darkgrey",
                // textDecoration: "underline",
                color: "darkgrey",
                borderRadius: "6px",
                PointerEvent: "none",
                textTransform: "uppercase",
                minWidth: "100px"
              }}
              onClick={() => {
                setAction("partDetails");
                setCurrentSelelction(rowData);
              }}
            >
              <Typography style={{ fontSize: "14px" }}>
                <span style={{ textTransform: "uppercase" }}>{"--"}</span>
              </Typography>
            </MROButton>
          );
        } else {
          if (rowData?.status?.color != null) {
            var bgColor =
              rowData?.status?.color === "amber"
                ? "#FFB300"
                : rowData?.status?.color === "green"
                  ? "#4FC605"
                  : rowData?.status?.color === "red"
                    ? "red"
                    : "darkgrey";
          }
          return (
            rowData?.status != null && (
              <MROButton
                variant="outlined"
                style={{
                  backgroundColor: bgColor,
                  textDecoration: "underline",
                  border: "none",
                  color: "white",
                  borderRadius: "6px",
                  PointerEvent: "none",
                  textTransform: "uppercase",
                  minWidth: "100px"
                }}
                onClick={() => {
                  setAction("partDetails");
                  setCurrentSelelction(rowData);
                }}
              >
                <Typography>
                  <span style={{ marginRight: "3px", fontSize: "14px" }}>
                    {rowData?.status?.qty}
                  </span>{" "}
                  <span
                    style={{ textTransform: "uppercase", fontSize: "14px" }}
                  >
                    {rowData?.status?.status || ""}
                  </span>
                </Typography>
              </MROButton>
            )
          );
        }
      },
    },
  ];

  return (
    <div>
      <SearchBarWithFilters
        handleSearch={(value) => {
          TableAction.setPage(dispatch, 0);
          setSearchText(value);
        }}
        searchText={searchText}
      >
        {/* <FormControl size="medium" className={"filter"}>
          <Select
            fullWidth
            displayEmpty
            defaultValue={""}
            onChange={(e) => setStatus(e.target.value)}
            input={<InputBase />}
          >
            <MenuItem value={""}>Status (All)</MenuItem>
            {["test1", "test2", "test3"].map((item, i) => (
              <MenuItem key={i} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}
        <Box width="100%" display="flex" justifyContent="flex-end">
          <FormControlLabel
            style={{ marginTop: "-7.5px" }}
            control={
              <Switch
                checked={issued}
                onChange={(e) => {
                  TableAction.setPage(dispatch, 0);
                  setIssued(e.target.checked);
                }}
                // name="checkedB"
                color="primary"
              />
            }
            label="Show ALL ISSUED items?"
            labelPlacement="start"
          />
        </Box>
      </SearchBarWithFilters>

      <MaterialTable
        style={{ marginTop: 20 }}
        columns={columns}
        icons={tableIcons}
        title=""
        data={data.data}
        isLoading={inProgress}
        options={tableOptions}
        onChangePage={(page) => {
          TableAction.setPage(dispatch, page);
        }}
        onChangeRowsPerPage={(pageSize) => {
          // TableAction.setPageSize(dispatch, pageSize);
          setPageSize(pageSize);
        }}
        onSearchChange={(search) => {
          TableAction.setSearchText(dispatch, search);
        }}
        totalCount={totalCount}
        page={page}
      />

      {action === "partDetails" && (
        <PartInfo
          permission={props?.permission}
          handleClose={() => setAction(null)}
          currentSelectedData={currentSelelction}
          getData={getList}
          closeHandler={closeHandler}
          mroOwned={mroOwned}
        />
      )}
    </div>
  );
}

export default AlternativePartsTable;
