import React, { useEffect, useContext, useState } from 'react'
import { Dialog, DialogContent, DialogTitle, Box, ButtonGroup, Button, FormControlLabel, Grid } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import MROButton from 'components/buttons';
import MROTextField from 'components/form_components/TextField';
import { Autocomplete } from '@material-ui/lab';
import { getOrganisationId } from 'lib/utils/common_utils';
import { ToastMessageContext } from 'lib/contexts/message_context';
import PositiveSwitch from 'components/form_components/switch';
import DateTimeField from 'components/form_components/LMEventsDateTimeField';
import ShiftServices from 'lib/services/api/planning/Shifts/shift';
import AutocompletePopper from 'components/Popper';





const useStyles = makeStyles(theme => ({
    actionButtons: {
        paddingTop: '20px',
        paddingBottom: '33px',
        textAlign: 'right',
        '& button:not(:last-child)': {
            marginRight: '20px'
        }
    },
    modal: {
        '& .MuiDialog-paperWidthMd': {
            maxWidth: '450px',
            padding: '10px'
        }
    },
    title: {
        fontSize: '18px',
        '& h2': {
            color: theme.palette.primary.main,
            textAlign: 'center',
            fontSize: '16px'

        }
    },
    formLabel: {

        '& span:last-child': {
            fontSize: '14px',
        }
    }

}));


function CreateLeaveRequest(props) {
    const classes = useStyles();
    const { action, handleClose, getListing, type } = props;
    const [errors, setErrors] = useState({});
    let userDetails = JSON.parse(sessionStorage.getItem("user"));
    const [individualUsers, setIndividualUsers] = useState([])
    const [selectedUser, setSelectedUser] = useState('')
    const [loading, setLoading] = useState(false)
    const message = useContext(ToastMessageContext)





    const getFetchDependency = () => {
        const params = {
            organisation_id: getOrganisationId(),

        }
        ShiftServices.fetchUsers(params).then((response) => {
            if (response.success) {

                setIndividualUsers(response.data.user)

            }
        }).catch(() => {

        })
    }

    useEffect(() => {
        getFetchDependency()
    }, [])



    const handleSelectedUser = (event, value) => {
        setSelectedUser(value)
    }
    const resetErrorsHandler = (name) => {
        setErrors((e) => ({ ...e, [name]: false }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault()
        const formdata = new FormData(e.target)
        let valid = true;
        let error = {};
        const startdate = formdata.get("start_datetime")
        const enddate = formdata.get("end_datetime")
        let c_startdate = new Date(startdate)
        let c_enddate = new Date(enddate)

        let comment = formdata.get("comment")
        formdata.delete("comment")
        for (let pair of formdata.entries()) {
            const value = pair[1];
            const key = pair[0];
            // console.table("formdata=====", key, value)
            if (!value) {
                error[key] = true;
                valid = false;
            }
        }
        if (startdate === "") {
            valid = false;
            error = {
                ...error,
                start_datetime: "*please enter start date"

            };
        }
        if (enddate === "") {
            valid = false;
            error = {
                ...error,
                end_datetime: "*please enter end date"

            };
        }
        if (c_startdate > c_enddate) {
            valid = false;
            error = {
                ...error,
                end_datetime: "Must be greater than Start Date"

            };
            message.showToastMessage({
                message: "End Date / Time must be after the Start Date",
                variant: "error",
            });
        }

        if (!valid) {
            setErrors(error);
            return;
        }

        setLoading(true)
        formdata.delete('user_id')
        formdata.append("organisation_id", getOrganisationId())
        formdata.append("created_user_id", userDetails?.id)
        if (type === "leave") {
            formdata.append('user_id', selectedUser.id)
        }
        else {
            formdata.append('user_id', userDetails?.id)
        }
        formdata.append("comment", comment)

        await ShiftServices.createLeaveRequest(formdata).then((res) => {
            if (res.success) {
                message.showToastMessage({
                    message: "Leave created successfully !",
                    variant: "success",
                });
                handleClose()
                getListing()
            }
            else {
                message.showToastMessage({
                    message: res.message ? res.message : "Something went wrong. Try again!",
                    variant: "error",
                });
                handleClose()
            }
        }).catch((res) => {
            handleClose()
            message.showToastMessage({
                message: res.message ? res.message : "Something went wrong. Try again!",
                variant: "error",
            });
        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <div>
            <Dialog
                maxWidth={"md"}
                fullWidth={true}
                open={true}
                className={classes.modal}
                onClose={handleClose}
                aria-labelledby="indirect-jobs"
            >
                <DialogTitle variant="subtitle2" color="secondary"
                    className={classes.title}><span >Leave Request</span>
                </DialogTitle>

                <DialogContent className={classes.card}>
                    <form onSubmit={handleSubmit}>

                        {type === "leave" && <Grid style={{ paddingBottom: '10px' }}>
                            <Autocomplete
                                fullWidth
                                getOptionSelected={(option, value) => option.id === value.id}
                                filterSelectedOptions={true}
                                onChange={handleSelectedUser}
                                id="tags-user"
                                options={individualUsers}
                                getOptionLabel={(option) => option.user_name}
                                PopperComponent={AutocompletePopper}
                                renderInput={(params) => (
                                    <MROTextField
                                        {...params}
                                        // variant="standard"
                                        label="User *"
                                        name="user_id"
                                        color={"primary"}
                                        InputLabelProps={{ shrink: true }}
                                        placeholder={"Search & Select User"}
                                        error={errors.user_id}
                                        onSelect={() => {
                                            resetErrorsHandler("user_id");
                                        }}
                                    />
                                )}
                            />
                        </Grid>}
                        <Grid >
                            <DateTimeField
                                // format={"YYYY-MM-DD"}
                                // disabled={!props?.permission?.write}
                                label={"Start Date / Time *"}
                                name={'start_datetime'}
                                InputLabelProps={{ shrink: true }}
                                errors={errors.start_datetime}
                                type={"shifts"}
                                helperText={errors.start_datetime}
                                style={
                                    { opacity: 0.55, cursor: "not-allowed !important" }}
                                onChange={(e) => {
                                    resetErrorsHandler("start_datetime");
                                }}
                            />
                        </Grid>
                        <Grid >
                            <DateTimeField
                                label={"End Date / Time *"}
                                name={'end_datetime'}
                                InputLabelProps={{ shrink: true }}
                                errors={errors.end_datetime}
                                type={"shifts"}
                                helperText={errors.end_datetime}
                                style={
                                    { opacity: 0.55, cursor: "not-allowed !important" }}
                                onChange={(e) => {
                                    resetErrorsHandler("end_datetime");

                                }}
                            />
                        </Grid>



                        <Grid style={{ paddingBottom: '10px' }}>
                            <MROTextField
                                name={"comment"}
                                label={"Comment"}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ style: { height: 'max-content', width: "100%" } }}
                                inputProps={{ maxLength: 150 }}
                                multiline
                                //  required
                                placeholder={"Comment to approver - Max 200 characters"}
                                onChange={(e) => { setErrors({ ...errors, comment: false }); }}
                                className={classes.inputField}

                            />
                        </Grid>
                        <div className={classes.actionButtons}>
                            <MROButton
                                disabled={loading}
                                type="button"
                                variant={"contained"}
                                onClick={handleClose}
                            >
                                Cancel
                            </MROButton>
                            <MROButton
                                loading={loading}
                                type="submit"
                                variant={"contained"}
                                color="primary"

                            >
                                Complete
                            </MROButton>

                        </div>
                    </form>
                </DialogContent>

            </Dialog>

        </div>)
}
export default CreateLeaveRequest