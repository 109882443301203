import {Box, Grid} from '@material-ui/core'
import React, {useEffect} from 'react'
import {useState} from 'react';
import moment from 'moment';
import FreeTextField from './FieldComponents/free_text_field';
import {getTimeDiffFormatted, timeCalculationFields} from './section_detail';

const InbondDelayCalculation = ({sectionDetails, subSection, errors, setErrors, sub_section_fields, inbondState: state, setInbondState:setState, ...props}) => {
    // const [state, setstate] = useState({
    //     'Act Arrival Date/Time (UTC)': '',
    //     'Est Arrival Date/Time (UTC)': '',
    //     'Inbd delay (mins)': ''
    // })


     //   set values if have the data in the backend
   useEffect(() => {
    if (!subSection.field_value) return;
    setState((prevState) => ({
      ...prevState,
      [subSection.field_name]: subSection.field_value,
    }));
  }, [subSection.field_value, subSection.field_name]);

    const handleChange =  (data) => {
        setState(prevState => ({
            ...prevState,
            [subSection.field_name]: data
        }))
        // setstate({
        //     ...state,
        //     [_name]: data
        // })
    }


    // useEffect(() => {
    //     let format = 'YYYY-MM-DD - HH:mm:ss'
    //     let revFormat = 'DD-MM-YYYY - HH:mm:ss'
    //     let duration = moment.duration(moment(sub_section_fields[3]?.field_value, format)
    //         .diff(moment(sub_section_fields[2].field_value, format)));

    //     if (duration.isValid()) {
    //         const hrs = (duration.get('days') * 24 + duration.get("hours")).toString().padStart(2, '0') + ":" + duration.get("minutes").toString().padStart(2, '0')
    //         const timespan = hrs.toString().padStart(2, '0')
    //         + ":" + duration.get("seconds").toString().
    //             padStart(2, '0');

    //         setState({
    //             ...state,
    //             [timeCalculationFields[0]]: sub_section_fields[2]['field_value'],
    //             [timeCalculationFields[1]]: sub_section_fields[3]['field_value'],
    //             // [timeCalculationFields[2]]: getTimeDiffFormatted(duration)
    //             'Inbd delay (mins)': getTimeDiffFormatted(duration)
    //         })
    //     }
    // }, [sub_section_fields,sectionDetails])

    const calculateInbdDelay = () => {
        const format = "YYYY-MM-DD - HH:mm:ss";
        const estArrivalDateTime = moment(state["Est Arrival Date/Time (UTC)"], format);
        const actArrivalDateTime =  moment(state["Act Arrival Date/Time (UTC)"], format);

        const duration = moment.duration(actArrivalDateTime.diff(estArrivalDateTime))

        if(duration.isValid()) {
            const inbdDelayTime = getTimeDiffFormatted(duration)
            setState(prevState => ({...prevState, "Inbd delay (mins)": inbdDelayTime}))
        } else {
            setState(prevState => ({...prevState, "Inbd delay (mins)": ""}))
        }
    }


    useEffect(() => {
        calculateInbdDelay()
    }, [state["Est Arrival Date/Time (UTC)"], state["Act Arrival Date/Time (UTC)"]])

    return (
        <Box>
            <Grid container>
                <Grid item xs={12}>
                    <FreeTextField
                        {...{...subSection, field_value: state[subSection.field_name]}}
                        handleChange={handleChange}
                        // errors={errors}
                        // setErrors={setErrors}
                        // value={subSection.field_name === timeCalculationFields[2] ? state[timeCalculationFields[2]] : ''}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}


export default React.memo(InbondDelayCalculation)
