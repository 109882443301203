import { Box, Grid, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import MROTextField from '../../../../components/form_components/TextField';
import FreeTextField from './FieldComponents/free_text_field'

export const constructObject = arr => {
    return arr.reduce(function (result, item) {
        var key = Object.keys(item)[0]; //first property: a, b, c
        result[key] = item[key];
        return result;
    }, {});
};

export const AircraftDDs = ({ workOrderId, fields, errors, setErrors }) => {

    const [subSections, setSubSections] = useState(fields.sub_sections);
    const [reasonError, setReasonError] = useState({});
    const [addMelError, setAddMelError] = useState({});
    const [out, setOut] = useState(0);

    useEffect(() => {
        if (reasonError) {
            // setErrors({ ...errors, ...reasonError })
        }
    }, [reasonError]);

    useEffect(() => {
        if (addMelError) {
            // setErrors({ ...errors, ...addMelError })
        }
    }, [addMelError]);

    useEffect(() => {
        setSubSections(fields.sub_sections)
    }, [fields.sub_sections])

    return (
        <div>
            {
                subSections && subSections.map((section, index) => {
                    if (section.child_section_name === 'Status') {
                        return <Status
                            section={section}
                            // errors={errors}
                            // setErrors={setErrors}
                            setOut={setOut} />
                    }
                    if (section.child_section_name === 'Reason ADDs Open') {
                        return <Box mt={2}><ReasonAdds
                            section={section}
                            // errors={errors}
                            // setErrors={setReasonError}
                            out={out}/></Box>
                    }
                    if (section.child_section_name === 'ADD MEL CAT') {
                        return <Box mt={2}><AddMelCat
                            section={section}
                            // errors={errors}
                            out={out}
                            // setErrors={setAddMelError}
                        /></Box>
                    }
                })
            }
        </div>
    )
}

export default React.memo(AircraftDDs)


const Status = ({ section, errors, setErrors, setOut }) => {

    const [state, setstate] = useState({
        In: section?.child_section_fields[0]?.field_value,
        Cleared: section?.child_section_fields[1]?.field_value,
        Raised: section?.child_section_fields[2]?.field_value,
        Out: '0'
    })

    const handleChange = _name => ({ target: { name, value, id } }) => {
        setstate({
            ...state,
            [_name]: value
        })
        setErrors && setErrors({
            ...errors,
            [name]: {
                valid: value ? true : false
            }
        })
    }

    useEffect(() => {
        let out = parseFloat(state.In) - parseFloat(state.Cleared) + parseFloat(state.Raised)
        setstate({
            ...state,
            Out: out || 0
        })
    }, [state.In, state.Cleared, state.Raised])

    useEffect(() => {
        setOut(state.Out)
    }, [state.Out])

    return <React.Fragment>
        {!!section?.child_section_fields?.length && <> <Typography variant={'subtitle2'}>{section.child_section_name}</Typography>
            {
                !section?.child_section_fields?.length && <Box mt={4}>
                    <Typography></Typography>
                </Box>
            }
            <input style={{ display: 'none' }} name={`org_section_id-${section.org_section_id}`} value={section.org_section_id} />
            <Grid container spacing={3} item xs={10}>
                {
                    section?.child_section_fields?.length > 0 && <>
                        <Grid item xs={12} md={6}>
                            <FreeTextField {...section.child_section_fields[0]}
                                errors={errors}
                                setErrors={setErrors}
                                handleChange={handleChange('In')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FreeTextField {...section.child_section_fields[1]}
                                handleChange={handleChange('Cleared')}
                                setErrors={setErrors}
                                errors={errors}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FreeTextField {...section.child_section_fields[2]}
                                handleChange={handleChange('Raised')}
                                errors={errors}
                                setErrors={setErrors}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FreeTextField {...section.child_section_fields[3]}
                                value={state.Out}
                            />
                        </Grid>
                    </>
                }
            </Grid></>}
    </React.Fragment>
}

const ReasonAdds = ({ section, errors, setErrors, out }) => {

    const [state, setstate] = useState({
        ['Spares']: section?.child_section_fields[0]?.field_value,
        ['Gnd Time/Resource']: section?.child_section_fields[1]?.field_value,
        ['Airline Input Required']: section?.child_section_fields[2]?.field_value,
    })
    const [fields, setFields] = useState([]);
    const [hasError, setHasError] = useState(false);

    const handleChange = _name => ({ target: { name, value, id } }) => {
        setstate({
            ...state,
            [_name]: value
        })

    }

    useEffect(() => {
        if (parseFloat(state.Spares) + parseFloat(state['Gnd Time/Resource']) + parseFloat(state['Airline Input Required']) !== parseFloat(out)) {
            // setErrors({
            //     ...errors,
            //     ...constructObject(fields.map(field => ({
            //         [field.id]: {
            //             valid: false
            //         }
            //     })))
            // })
            setHasError(true)
        }
        else {
            setErrors && setErrors({
                ...errors,
                ...constructObject(fields.map(field => ({
                    [field.id]: {
                        valid: true
                    }
                })))
            })
            setHasError(false)
        }
    }, [state, fields.length, out])

    useEffect(() => {
        setFields((section.child_section_fields.map(field => {
            return {
                name: field.field_name,
                id: field.id
            }
        })))
    }, [])

    return <React.Fragment>
        {!!section?.child_section_fields?.length && <> <Typography variant={'subtitle2'}>{section.child_section_name}</Typography>
            {
                !section?.child_section_fields?.length && <Box mt={4}>
                    <Typography></Typography>
                </Box>
            }
            <input style={{ display: 'none' }} name={`org_section_id-${section.org_section_id}`} value={section.org_section_id} />
            <Grid container spacing={3} item xs={10}>
                {
                    section && section.child_section_fields.map((field, index) => <Grid key={index} item xs={12} md={6}>
                        <FreeTextField {...field}
                            handleChange={handleChange(field.field_name)}
                            setErrors={setErrors}
                            errors={errors}
                        />
                    </Grid>
                    )
                }
            </Grid>
            {
                !!section?.child_section_fields?.length && (hasError && out != 0) && <Typography color="error">
                    *Sum of all these values should be equal to ‘OUT’
                </Typography>
            }
        </>
        }
    </React.Fragment >
}

const AddMelCat = ({ section, errors, setErrors, out }) => {

    const [state, setstate] = useState({
        ['CAT A']: section?.child_section_fields[0]?.field_value,
        ['CAT B']: section?.child_section_fields[1]?.field_value,
        ['CAT C']: section?.child_section_fields[2]?.field_value,
        ['CAT D']: section?.child_section_fields[3]?.field_value
    })
    const [fields, setFields] = useState([]);
    const [hasError, setHasError] = useState(false);

    const handleChange = _name => ({ target: { name, value, id } }) => {
        setstate({
            ...state,
            [_name]: value
        })

    }

    useEffect(() => {
        if (parseFloat(state['CAT A']) + parseFloat(state['CAT B']) + parseFloat(state['CAT C']) + parseFloat(state['CAT D']) !== parseFloat(out)) {
            // setErrors({
            //     ...errors,
            //     ...constructObject(fields.map(field => ({
            //         [field.id]: {
            //             valid: false
            //         }
            //     })))
            // })
            setHasError(true)
        }
        else {
            setErrors && setErrors({
                ...errors,
                ...constructObject(fields.map(field => ({
                    [field.id]: {
                        valid: true
                    }
                })))
            })
            setHasError(false)
        }
    }, [state, fields.length, out])


    useEffect(() => {
        setFields((section.child_section_fields.map(field => {
            return {
                name: field.field_name,
                id: field.id
            }
        })))
    }, [])

    return <React.Fragment>
        {!!section?.child_section_fields?.length && <> <Typography variant={'subtitle2'}>{section.child_section_name}</Typography>
            {
                !section?.child_section_fields?.length && <Box mt={4}>
                    <Typography></Typography>
                </Box>
            }
            <input style={{ display: 'none' }} name={`org_section_id-${section.org_section_id}`} value={section.org_section_id} />
            <Grid container spacing={3} item xs={10}>
                {
                    section && section.child_section_fields.map((field, index) => <Grid key={index} item xs={12} md={6}>
                        <FreeTextField {...field}
                            handleChange={handleChange(field.field_name)}
                            setErrors={setErrors}
                            errors={errors}
                        />
                    </Grid>
                    )
                }
            </Grid>
            {
                (hasError && out != 0) && <Typography color="error">
                    *Sum of all these values should be equal to ‘OUT’
                </Typography>
            } </>}
    </React.Fragment>
}
