import React, { useState, useReducer, useContext, useEffect } from 'react'
import MaterialTable from 'material-table';
import tableReducer, { INITIAL_TABLE_STATE } from "reducers/table_reducer";
import tableIcons, { options } from "components/universal/table_attributes";
import TableAction from "actions/table_actions";
import ViewIcon from "@material-ui/icons/Visibility";
import CopyFileIcon from "assets/CopyFiles.svg"
import GreenCopyIcon from "assets/GreenCopyIcon.svg"
import { ICON_COLOUR } from 'lib/constants/style_constants';
import { IconButton, Box } from '@material-ui/core';
import { ToastMessageContext } from "lib/contexts/message_context";
import { WorkshopContext } from 'views/Operations/Workshop/WorkshopContext/WorkshopContext';
import EquipmentAdditionalPopUp from './EquipmentAdditionalPopUp';
import { MaintenanceServices } from 'lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation';

function Equipments(props) {

    const { currentActionData, currentWorkshopDetails, currentClosedDetails, currentActionId, orderType } = props
    const [selectedRow, setSelectedRow] = useState({});
    const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
    const message = useContext(ToastMessageContext);
    const { totalCount, inProgress, data } = tableState;
    const [action, setAction] = useState(false)
    const { copyEquipments, equipmentFlag, copyInspection, copiedJobs } = useContext(WorkshopContext)
    const [pageSize, setPageSize] = useState(5);
    const [page, setPage] = useState(0);
    const [tData, setTData] = useState([])
    const [loading, setLoading] = useState(false)
    const [totalCountT, setTotalCountT] = useState(0)






    useEffect(() => {
        if (orderType === "Maintenance" || orderType === "Production") {
            getWorkshopEquipment();
        }
    }, [page, pageSize]);

    const getWorkshopEquipment = () => {
        const params = {

            page: page + 1,
            id: currentActionData.workshop_order_id,
            count_per_page: pageSize,
            workshop_action_id: currentActionData.workshop_action_id

        };
        TableAction.getList(dispatch, message, params, "getWorkshopEquipment");
    };
    const tableOptions = {
        ...options,
        page: page,
        total: totalCount,
        pageSize: pageSize,
        search: false,
        toolbar: false,

    };



    const tableOptions1 = {
        ...options,
        page: page,
        total: totalCountT,
        pageSize: pageSize,
        search: false,
        toolbar: false,

    };




    const getWorkshopTemplateEquipment = async () => {


        const params = {

            workshop_order_id: currentActionData.workshop_order_id,
            workshop_action_id: currentActionData.workshop_action_id,
            page: 1 + page,
            limit: pageSize,
        }
        setLoading(true)
        await MaintenanceServices.getTemlateEquipmentListing(params).then((res) => {
            setTData(res.data)
            setTotalCountT(res.total_count)
        }).catch(() => {
            message.showToastMessage({
                message: "Something went wrong. Try again!",
                variant: "error",
            });
        }).finally(() => {
            setLoading(false)
        })

    };
    useEffect(() => {
        if (orderType === "Maintenance Template" || orderType === "Production Template") {
            getWorkshopTemplateEquipment();
        }
    }, [page, pageSize]);



    const columns = [


        {
            title: "Description",
            field: "description",
            width: "90%",
            headerStyle: {
                textAlign: "left",
                paddingLeft: "20px",
                width: "90%",
            },
            cellStyle: {
                textAlign: "left",
                width: "90%",
                paddingLeft: "20px"
            },
        },

        {
            title: "Copy",
            headerStyle: {
                textAlign: "center",
            },
            cellStyle: {
                textAlign: "center",
            },
            render: (rowData) => (
                <div>
                    {copiedJobs.find((item) => item === currentActionData.workshop_action_id) && < IconButton
                        onClick={() => {
                            setAction("View");
                            setSelectedRow(rowData);
                            copyEquipments.push(rowData.id)
                        }}
                        disabled={equipmentFlag && copyEquipments.find((item) => item === rowData.id)}
                    >

                        {equipmentFlag &&
                            copyEquipments.find((item) => item === rowData.id) ? <img src={GreenCopyIcon} height="25px" style={{ pointerEvents: equipmentFlag && copyEquipments.find((item) => item === rowData.id) ? "none" : "" }} /> :

                            <img src={CopyFileIcon} height="25px" />
                        }
                    </IconButton>}
                </div >
            ),
        },
    ];
    return (
        <div> <MaterialTable
            style={{
                boxShadow: "0px 1px 3px #00000033",
                marginTop: "10px",
            }}
            icons={tableIcons}
            title={""}
            columns={columns}
            data={orderType === "Maintenance" || orderType === "Production" ? data.data : orderType === "Maintenance Template" || orderType === "Production Template" ? tData : []}
            isLoading={inProgress || loading}
            options={orderType === "Maintenance" || orderType === "Production" ? tableOptions : orderType === "Maintenance Template" || orderType === "Production Template" ? tableOptions1 : tableOptions}
            onChangePage={(page) => {
                setPage(page);
            }}
            onChangeRowsPerPage={(pageSize) => {
                setPageSize(pageSize);
            }}

            totalCount={orderType === "Maintenance" || orderType === "Production" ? totalCount : totalCountT}
            page={page}
        />


            {action && action === "View" && <EquipmentAdditionalPopUp handleClose={() => setAction(null)} currentClosedDetails={currentClosedDetails} currentWorkshopDetails={currentWorkshopDetails} selectedRow={selectedRow} currentActionData={currentActionData} currentActionId={currentActionId} orderType={orderType} />}


        </div>
    )
}

export default React.memo(Equipments)