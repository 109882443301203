import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  makeStyles,
  MenuItem,
  Typography,
} from "@material-ui/core";
import MROTextField from "../../../../components/form_components/TextField";
import RightDialog from "./RightDialog";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import MROButton from "../../../../components/buttons";

import PartsClientServices from "../../../../lib/services/api/stores/tooling/parts_client";
import { ToastMessageContext } from "lib/contexts/message_context";

const useStyels = makeStyles({
  container: {
    width: "100%",
    height: "100%",
    padding: "23px 37px 0px 37px",
    display: "flex",
    flexDirection: "column",

    "& form": {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
  },
  heading: {
    fontSize: "16px",
    color: "#4D4F5C",
    lineHeight: "22px",
    fontWeight: "bold",
  },
});

function TransferPartDialog(props) {
  const classes = useStyels();

  const {
    open,
    dialogCloseHandler,
    secondaryPartId,
    getPartList,
    batchNumber,
  } = props;

  const message = useContext(ToastMessageContext);

  const [qty, setQty] = useState(null);
  const [errors, setErrors] = useState(null);
  const [baseStations, setBaseStations] = useState([]);
  const [loading, setLoading] = useState(false);

  const user = JSON.parse(sessionStorage.getItem("user"));

  useEffect(() => {
    PartsClientServices.fetchDependencies()
      .then((res) => {
        if (res) {
          setBaseStations(res.base_stations);
        }
      })
      .catch(() => { });
  }, []);

  const transferPart = async (formData) => {
    setLoading(true);
    try {
      const res = await PartsClientServices.transferPart(formData);
      console.log(res);
      if (res?.success) {
        getPartList();
        dialogCloseHandler();
        message.showToastMessage({
          message: "Transferred successfully",
          variant: "success",
        });
      }
    } catch (error) {
      console.log(error, "something went wrong");
      message.showToastMessage({
        message: "Something went wrong",
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const submitHandler = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    formData.append("user_id", user.id);
    formData.append("secondary_part_id", secondaryPartId);

    let error = {};
    let isValid = true;

    // Display the key/value pairs
    for (let pair of formData.entries()) {
      if (pair[1] === "") {
        error[pair[0]] = true;
        isValid = false;
      }
    }
    // console.log(error);
    if (!isValid) {
      setErrors(error);
      return;
    }
    transferPart(formData);
  };

  return (
    <RightDialog open={open} dialogCloseHandler={dialogCloseHandler}>
      <div className={classes.container}>
        <Typography variant="h4" className={classes.heading}>
          Stock Transfer
        </Typography>

        <div style={{ display: "flex", marginTop: "14px" }}>
          <Typography
            variant="body1"
            style={{ marginRight: "30px" }}
            color="secondary"
          >
            Batch #
          </Typography>
          <Typography variant="body1">{batchNumber}</Typography>
        </div>

        <form onSubmit={submitHandler}>
          <div>
            <MROTextField
              name="to_base_station_id"
              label={"Transfer to Base *"}
              select
              error={errors?.to_base_station_id}
              autoFocus={errors?.to_base_station_id}
              helperText={
                errors?.to_base_station_id ? "Can not be left empty." : null
              }
              onChange={() => {
                setErrors((prevState) => ({
                  ...prevState,
                  to_base_station_id: false,
                }));
              }}
              InputLabelProps={{ shrink: true }}
            >
              {/* {["Test1", "Test2", "Test3"].map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))} */}

              {baseStations &&
                baseStations.map((item) => (
                  <MenuItem
                    key={item.base_station_id}
                    value={item.base_station_id}
                  >
                    {item.station}
                  </MenuItem>
                ))}
            </MROTextField>

            <MROTextField
              name="reason"
              label={"Reason *"}
              placeholder="Enter reason comments max 100 characters"
              multiline
              error={errors?.reason}
              autoFocus={errors?.reason}
              helperText={errors?.reason ? "Can not be left empty." : null}
              onChange={() => {
                setErrors((prevState) => ({ ...prevState, reason: false }));
              }}
              InputLabelProps={{ shrink: true }}
            />

            <div style={{ display: "flex", alignItems: "center" }}>
              <MROTextField
                name={"quantity"}
                label={"Quantity *"}
                placeholder="QTY"
                onChange={(e) => {
                  setQty(Number(e.target.value.replace(/[a-zA-Z]/, "")));
                  setErrors((prevState) => ({
                    ...prevState,
                    quantity_received: false,
                  }));
                }}
                InputLabelProps={{ shrink: true }}
                value={qty}
                fullWidth={false}
                style={{ marginRight: "15px" }}
                error={errors?.quantity}
                autoFocus={errors?.quantity}
                helperText={errors?.quantity ? "Please enter Quantity" : null}
              />

              <ButtonGroup
                color="primary"
                aria-label="outlined primary button group"
              >
                <Button
                  onClick={() =>
                    setQty((prevState) =>
                      prevState > 1 ? prevState - 1 : prevState
                    )
                  }
                  style={{ width: "46px", height: "36px" }}
                >
                  <RemoveIcon />
                </Button>
                <Button
                  onClick={() =>
                    setQty((prevState) => (prevState ? prevState + 1 : 1))
                  }
                  style={{ width: "46px", height: "36px" }}
                >
                  <AddIcon />
                </Button>
              </ButtonGroup>
            </div>
            <MROTextField
              name={"stamp"}
              label={"Stamp *"}
              placeholder="Show stamp in DB"
              InputLabelProps={{ shrink: true }}
              value={user.stamp_number}
              disabled
            />
          </div>

          <div
            style={{
              marginTop: "auto",
              marginBottom: "60px",
              alignSelf: "flex-end",
            }}
          >
            <MROButton
              style={{ marginRight: "20px" }}
              type="button"
              variant={"contained"}
              onClick={dialogCloseHandler}
            >
              Cancel
            </MROButton>
            <MROButton
              disabled={loading}
              type="submit"
              variant={"contained"}
              color="primary"
            >
              Save
            </MROButton>
          </div>
        </form>
      </div>
    </RightDialog>
  );
}

export default TransferPartDialog;
