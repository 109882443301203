import React, { useContext, useEffect, useReducer, useState } from "react";
import Dialog from "@material-ui/core/Dialog";

import {
    createStyles,
    Grid,
    IconButton,
    makeStyles,
    Typography,

} from "@material-ui/core";
import tableIcons, { options } from "components/universal/table_attributes";
import MaterialTable from "material-table";
import { ToastMessageContext } from "lib/contexts/message_context";
import ShiftServices from "lib/services/api/planning/Shifts/shift";
import { getOrganisationId } from "lib/utils/common_utils";
import moment from "moment";
import GreenTickIcon from 'assets/green_filled_tick.png'
import RedCrossIcon from 'assets/Red remove icon.png'
import GearAmbear from 'assets/Amber gear@3x.png'

const useStyles = makeStyles((theme) =>
    createStyles({
        modal: {
            "& .MuiDialog-paperWidthSm": {
                minWidth: "750px",
                // height: "110vh"
            },
        },
        container: {
            // minWidth: "1200px",
            // minHeight: "400px",
            padding: "50px",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden !important"
        },

        popupTitle: {
            fontSize: "20px",
            lineHeight: "28px",
            position: "absolute",
            top: "19px",
            bottom: "15px",
            left: "25px",
        },
        divider: {
            borderBottom: `1px solid ${theme.palette.primary.main}`,
        },
        tableTitle: {
            fontSize: "16px",
            lineHeight: "22px",
            color: "#4D4F5C",
            fontWeight: "bold",
            marginTop: "19px",
            marginBottom: "16px",
        },
        tableCell: {
            height: '32px',
            justifyContent: "space-between",
            whiteSpace: "normal",
            wordWrap: "break-word",

        },
    })
);
function LeaveRequest(props) {

    const { handleClose, leaveDay } = props
    const classes = useStyles();
    const message = useContext(ToastMessageContext);
    const [data, setData] = useState([])
    const [Loading, setLoading] = useState(false)





    const getListing = () => {

        const params = {
            selected_date: leaveDay

        }

        setLoading(true)
        ShiftServices.getLeaveRequestDetails(params).then((res) => {
            setData(res.data)

        }).catch(() => {
            message.showToastMessage({
                message: "Something went wrong. Try again!",
                variant: "error",
            });
        }).finally(() => {
            setLoading(false)
        })

    }

    useEffect(() => {
        getListing()
    }, [leaveDay])




    const tableOptions = {
        ...options,
        paging: false,
        search: false,
        toolbar: false,
        rowStyle: { height: 42 },
    };








    const column = [

        {
            title: "Requested",
            field: "requested",
            width: '20%',
            headerStyle: {
                textAlign: "left",
                paddingLeft: "15px",
                width: '20%',
            },
            cellStyle: {
                textAlign: "left",
                paddingLeft: "15px",
                width: '20%',
            },
        },
        {
            title: "User",
            field: "user_name",
            width: '60%',
            headerStyle: {

                textAlign: "left",
            },
            cellStyle: {
                width: '60%',
                textAlign: "left",
            },

        },

        {
            title: "Hours",
            field: "hours",

        },
        {
            title: "Status",
            field: "status",
            render: (rowData) => <div>
                {rowData?.status === 1 ? <img src={GreenTickIcon} height={'18px'} /> : rowData?.status === 2 ? <img src={RedCrossIcon} height={'18px'} /> : rowData?.status === 0 || rowData?.status === 3 ? <img src={GearAmbear} height={'18px'} /> : "  "}

            </div>
        }
    ];





    return (
        <div>
            <Dialog
                open={true}
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                className={classes.modal}
            >
                <div className={classes.container}>
                    <Typography
                        variant="body1"
                        color="primary"
                        style={{ marginBottom: "20px" }}
                        className={classes.popupTitle}
                    >
                        Leave Requests
                    </Typography>

                    <Grid item xs={6} style={{ marginTop: "10px", }}>
                        <MaterialTable
                            style={{
                                boxShadow: "0px 1px 3px #00000033",
                                borderRadius: "4px",
                                width: "650px",
                                // minWidth: "500px"
                            }}

                            options={tableOptions}
                            title=""
                            icons={tableIcons}
                            columns={column}
                            data={data || []}
                            isLoading={Loading}


                        />
                    </Grid>
                </div>
            </Dialog>

        </div>
    )
}

export default LeaveRequest