import React, { useReducer } from "react";
import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, useTheme } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import PropTypes from 'prop-types';
// import tableReducer, { INITIAL_TABLE_STATE } from "reducers/table_reducer";
import MROTextField from "components/form_components/TextField";
import SearchIcon from '@material-ui/icons/Search';
import LastPageIcon from '@material-ui/icons/LastPage';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import { KeyboardArrowRight, KeyboardArrowLeft } from "@material-ui/icons";
import _ from "lodash";
import { useState } from "react";




export default function PartNumberTable(props) {
  const { currentSelection, data, handleClose, busy } = props;

  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filteredFn, setFilteredFn] = useState({ fn: items => { return items } });

//   console.log("filtered function is=============", filteredFn)

  let partNum = currentSelection?.part_number?.split(",")
  // let partNumber = new Array({ ...partNum })

  TablePaginationActions.propTypes = {
    count: PropTypes.number,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - partNum?.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(parseInt(newPage));
  };

  const handleChangeRowsPerPage = (
    event
  ) => {
    setRowsPerPage(parseInt(+event.target.value, 10));
    setPage(0);
  };

  function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (
      event
    ) => {
      onPageChange(+event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(+event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(+event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(+event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  const handleSearch = (e) => {
    let target = e.target
    setFilteredFn({
      fn: items => {
        if (target.value === "")
            return items;
        else {
         return items.filter(x => x.toLowerCase().includes(target.value))
        }

      }
    })
  }

  return (
    <Dialog
      maxWidth={"sm"}
      fullWidth={true}
      open={true}
      className={classes.modal}
      onClose={handleClose}
      aria-labelledby="base-station-manage"
    >
      <DialogTitle variant="subtitle2" color="secondary">
        <Typography color="primary" style={{ fontSize: "17px" }}>Applicable Part Numbers</Typography>
      </DialogTitle>
      <DialogContent className={classes.card}>
        <Grid item xs={6}>
          <MROTextField
            focused={"focused"}
            label={""}
            onChange={(e)=> {
                handleSearch(e); 
                setPage(0);
            }}
            placeholder="Search Anything"
            InputLabelProps={{ shrink: true }}
            variant="standard"
            type="search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="primary" />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12} style={{ marginBottom: "20px", marginTop: "10px" }}>
          <div>
            <TableContainer component={Paper} style={{ boxShadow: "0px 1px 3px #00000033" }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ paddingLeft: "24px", padding: "10px" }}>Part #</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? filteredFn.fn(partNum)?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : partNum
                  )?.map((row) => (
                    <TableRow
                      key={row}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row" style={{ paddingLeft: "24px", padding: "10px" }}>
                        {row}
                      </TableCell>
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 40 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TableRow style={{ display: "flex", justifyContent: "flex-end" }}>
              {partNum?.length !== 0 &&
                <TablePagination
                  labelRowsPerPage=''
                  rowsPerPageOptions={[5, 10, 20, 30, 50]}
                  colSpan={3}
                  count={filteredFn.fn(partNum)?.length|| 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />}
            </TableRow>
          </div>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  bodyText: {
    fontSize: 20,
    lineHeight: "28px",
  },
}));
