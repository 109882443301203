import React from 'react'
import ButtonGroupNavigation from "components/button_group_navigation";
import { Typography, Box } from "@material-ui/core";
import LineParts from "./mro1-lineparts";
import { Route, Switch } from "react-router-dom";


export default function MRO1(props) {

    const buttons = [
        { name: "Line Parts", redirect: "/org/reports/mro1/lineparts" },
  
    ];



    return (

        <>
        <Box width="100%" p={[4, 3]}>
          <Typography></Typography>
          <div className={"top-navigation"} style={{ marginLeft: '32px' }}>
            <ButtonGroupNavigation buttons={buttons} {...props} />
          </div>
           <Switch>
            <Route
              exact
              path={"/org/reports/mro1/lineparts"}
              component={(props) => (
                <LineParts
                  {...props}
                />
              )}
            />
            
          </Switch> 
        </Box>
      </>
      
    )
}




// import React from "react";
// import { Typography, Box } from "@material-ui/core";
// import Report from "./Reports/Report";
// import MROButton from "../../../components/buttons";

// import { Route, Switch } from "react-router-dom";
// import MaintHour from "./Maint-Hours/MaintHour";

// const buttons = [
//   { name: "Reports", redirect: "/org/dashboard/reports" },
//   { name: "Maint-Hours", redirect: "/org/dashboard/maint-hour" },
// ];

// export default function LineMaintainence(props) {
//   return (
   
//   );
// }
