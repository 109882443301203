import React, { useReducer, useState, useEffect } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import TopNavigationBar from "../../components/tab_component";
import FlightIcon from "@material-ui/icons/FlightTakeoffOutlined";
import PageNotFound from "../../components/universal/page_not_found";
import UserCompliance from "./UserCompliance";
import StationCompliance from "./StationCompliance";
import LineMaintenance from "./LineMaintenance";
import authReducer, { INITIAL_STATE } from "../../reducers/auth_reducer";
import AuthActions from "../../actions/auth_actions";
import ActivityService from "../../lib/services/api/accounts/activity_report";

import { Box, makeStyles } from "@material-ui/core";
import { FormControl, InputBase, Select } from "@material-ui/core";
import { MenuItem, Grid } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import { Chip } from "@material-ui/core";
import Workshop from "./Workshops";

export default function DashboardSection(props) {
  const classes = useStyles()
  const topNavMenu = [
    {
      label: (
        <div>
          <FlightIcon className="nav_icon" />
          &nbsp;Line
        </div>
      ),
      name: "Line Maintenance",
      redirect: "/org/dashboard/performance",
      section: "/dashboard/line_maintenance",
    }, {
      label: (
        <div>
          <FlightIcon className="nav_icon" />
          &nbsp;Workshops
        </div>
      ),
      name: "Workshop",
      redirect: "/org/dashboard/workshop/maintenance",
      section: "/dashboard/workshop",
    },
    {
      label: (
        <div>
          <FlightIcon className="nav_icon" />
          &nbsp;User Compliance
        </div>
      ),
      name: "User Compliance",
      redirect: "/org/dashboard/user_compliance",
      section: "/dashboard/user_compliance",
    },
    {
      label: (
        <div>
          <FlightIcon className="nav_icon" />
          &nbsp;Station Compliance
        </div>
      ),
      name: "Station Compliance",
      redirect: "/org/dashboard/station/compliance",
      section: "/dashboard/station",
    },
  ];
  const Section = "Dashboard";
  const [authState, dispatch] = useReducer(authReducer, INITIAL_STATE);
  const [categories, setCategories] = useState([]);
  const [topMenus, setTopMenus] = useState([]);

  const [baseStations, setBaseStations] = useState([]);
  const [owners, setOwners] = useState([]);

  const [selectedFilters, setSelectedFilters] = useState([]);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location.pathname === "/org/dashboard") {
      history.push("/org/dashboard/performance");
    }
  });

  useEffect(() => {
    AuthActions.setUserData(dispatch, sessionStorage.getItem("authState"));

    // ActivityService.fetchDependencies()
    //   .then((res) => {
    //     if (res) {
    //       setBaseStations(res.airline);
    //       setOwners(res.base);
    //     }
    //   })
    //   .catch(() => { });
  }, []);

  useEffect(() => {
    setCategories(
      authState?.authState?.categories?.find(
        (category) => category.name === Section
      ).sub_categories
    );
  }, [authState?.authState?.categories]);

  useEffect(() => {
    if (categories) {

      setTopMenus(
        topNavMenu.filter((section) => {
          return categories?.some((category) => {
            return true;
          });
        })
      );
    }
  }, [categories?.length]);

  const [permission, setPermission] = useState([]);
  useEffect(() => {
    setPermission(
      categories?.map((category) => {
        return authState.authState.permissions.find(
          (permission) => permission.source_id === category.id
        );
      })
    );
  }, [categories]);

  useEffect(() => {
    if (permission) {
      setTopMenus(topNavMenu.filter(section => {
        return permission.filter(permission => {
          return permission.read || permission.write
        })?.some(category => {

          // console.log("permission is=================", category.source_type , section.name)
          return category.source_type === section.name
        })
      }))
    }
  }, [permission]);

  const filterChangeHandler = (e) => {
    const val = e.target.value;
    setSelectedFilters(val);
  };

  const chipDeleteHandler = (idx) => {
    const remainFilters = selectedFilters.filter(
      (item, index) => index !== idx
    );
    setSelectedFilters(remainFilters);
  };

  return (
    <div>
      {!!topMenus.length && (
        <>
          <TopNavigationBar {...props} tabProperties={topMenus} />

          {/* <div className={classes.wrapper}>
            <Typography variant="subtitle2" color="primary" gutterBottom>
              Filters
            </Typography>
            <FormControl className={"filter"}>
              <Select
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return "Base";
                  }

                  return selected.join(", ");
                }}
                displayEmpty
                multiple
                onChange={filterChangeHandler}
                input={<InputBase />}
                value={selectedFilters}
              >
                <MenuItem value="" disabled>
                  Base
                </MenuItem>
                {["Test one", "Test two", "Test three", "Test four"].map(
                  (item, i) => (
                    <MenuItem key={item + "-" + i} value={item}>
                      {item}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
            <Box display="flex" mt="20px">
              {selectedFilters.map((item, idx) => (
                <Chip
                  style={{ marginRight: 25 }}
                  key={item}
                  label={item}
                  onDelete={() => chipDeleteHandler(idx)}
                  color="primary"
                />
              ))}
            </Box>
          </div> */}


          <Switch>
            <Route
              path={"/org/dashboard/user_compliance"}
              component={(props) => (
                <UserCompliance
                  {...props}
                  permission={permission.find(
                    (per) => per?.source_type === "User Compliance"
                  )}
                />
              )}
            />
            <Route
              path={"/org/dashboard/station"}
              component={(props) => (
                <StationCompliance
                  {...props}
                  permission={permission.find(
                    (per) => per?.source_type === "Station Compliance"
                  )}
                />
              )}
            />
            <Route
              path={"/org/dashboard/workshop"}
              component={(props) => (
                <Workshop   {...props}
                  permission={permission.find(
                    (per) => per?.source_type === "Workshop"
                  )}

                />
              )}
            />
            <Route
              path={"/org/dashboard"}
              component={(props) => (
                <LineMaintenance
                  baseStations={baseStations}
                  owners={owners}
                  {...props}
                  permission={permission.find(
                    (per) => per?.source_type === "Line Maintenance"
                  )}
                />
              )}
            />

            <Route component={PageNotFound} />
          </Switch>
        </>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    // overflow:"hidden !important",
    width: "96%",
    backgroundColor: "#fff",
    padding: theme.spacing(1, 2),
    border: "1px solid #d5d5d5",
    margin: "2% 2%",
  },

  tableWrapper: {
    width: "96%",
    margin: "1% 2%",
  },

}));