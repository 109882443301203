import React, { useState, useContext, useEffect } from 'react'
import MaterialTable from 'material-table'
import tableIcons, { options } from "components/universal/table_attributes";
import TableAction from "actions/table_actions";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Box, IconButton, Paper, Popover, Typography } from "@material-ui/core";
import MROButton from 'components/buttons';
import moment from 'moment';
import { getOrganisationId } from 'lib/utils/common_utils';
import GreenTickIcon from 'assets/green_filled_tick.png'
import RedCrossIcon from 'assets/Red remove icon.png'
import GearAmbear from 'assets/Amber gear@3x.png'
import { Delete } from '@material-ui/icons';
import { ICON_COLOUR } from 'lib/constants/style_constants';
import ShiftServices from 'lib/services/api/planning/Shifts/shift';
import { ToastMessageContext } from 'lib/contexts/message_context';
import CreateLeaveRequest from 'views/Planning/Shifts/Overtime/NewLeaveRequest';
import momentTimeZone from 'moment-timezone';

export default function MyOvertime() {
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [totalCount, setTotalCount] = useState(10)
    const [action, setAction] = useState('')
    const [selectedWeek, setSelectedWeek] = useState('')
    const [currentSelection, setCurrentSelection] = useState({})
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const message = useContext(ToastMessageContext)
    let userDetails = JSON.parse(sessionStorage.getItem("user"));
    const [mouseX, setMouseX] = useState();
    const [mouseY, setMouseY] = useState();
    const [poperState, setPoperState] = useState(false)
    const [currentCommentsSelections, setCurrentCommentSelections] = useState({});

    const [userTimeZone, setUserTimeZone] = useState()

    const tableOptions = {
        ...options,
        page: page,
        total: totalCount,
        pageSize: pageSize,
        search: false,
        toolbar: false
    };


    const getListing = () => {

        const params = {
            organisation_id: getOrganisationId(),
            page: page + 1,
            limit: pageSize,
            user_id: userDetails?.id,
            app: true


        }

        setLoading(true)
        ShiftServices.getShiftOvertimeDetails(params).then((res) => {
            setData(res.data)
            setTotalCount(res.total_count)
        }).catch(() => {
            message.showToastMessage({
                message: "Something went wrong. Try again!",
                variant: "error",
            });
        }).finally(() => {
            setLoading(false)
        })

    }


    const handlepopupclose = () => {
        setAction("")
        setPoperState(false)
    }

    useEffect(() => {

        getListing();

    }, [page, pageSize, totalCount]);

    useEffect(() => {
        const time = momentTimeZone.tz.guess();
        let time_zone = momentTimeZone().tz(`${time}`).utcOffset()
        let final = time_zone / 60
        setUserTimeZone(`${final}`)

    }, [])

    const handleDelete = async (rowData) => {

        setLoading(true)
        await ShiftServices.deleteOverTimeRequest(rowData.id).then((res) => {

            message.showToastMessage({
                message: "Overtime request deleted successfully !",
                variant: "success",
            });

            getListing()
        }).catch((res) => {

            message.showToastMessage({
                message: res.message ? res.message : "Something went wrong. Try again!",
                variant: "error",
            });
        }).finally(() => {
            setLoading(false)
        })
    }

    const columns = [


        {
            title: "Requested",
            field: "requested",
            width: '30%',
            headerStyle: {
                textAlign: 'left',
                paddingLeft: '20px',

                width: '30%',

            },
            cellStyle: {
                textAlign: 'left',
                paddingLeft: '20px',
                width: '30%',
            }
        },


        {
            title: "Type",
            field: "shift_type",
            headerStyle: {
                textAlign: 'left',

            },
            cellStyle: {
                textAlign: 'left',

            }
        },
        {
            title: "Overtime Start",
            field: "ovetime_start",
            width: '10%'
        },
        {
            title: "Overtime End",
            field: "overtime_end",
            width: '10%'
        }, {
            title: "Hours",
            field: "hours",
        },
        {
            title: "Status",
            field: "status",
            render: (rowData) => <div>
                {rowData?.status === 1 ? <img src={GreenTickIcon} height={'18px'} /> : rowData?.status === 2 ? <img src={RedCrossIcon} height={'18px'} style={{ cursor: 'pointer' }} onClick={(e) => {
                    setAction("view_key");
                    setCurrentCommentSelections(rowData);
                    setPoperState(!poperState)
                    setMouseX(e.clientX);
                    setMouseY(e.clientY);
                }} /> : rowData?.status === 0 || rowData?.status === 3 ? <img src={GearAmbear} height={'18px'} /> : "  "}

                {action === "view_key" && <Popover id='key' open={Boolean(poperState)}
                    anchorReference={'anchorPosition'}
                    onClose={handlepopupclose}
                    anchorEl={poperState}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                    anchorPosition={{ top: mouseY, left: mouseX }}
                    PaperProps={{
                        marginLeft: '-11px'
                    }}
                >

                    <Paper style={{ width: 'max-content', height: 'fit-content', padding: '10px' }}>
                        <Typography style={{ fontSize: '13px' }}>{currentCommentsSelections?.reject_reason
                        }</Typography>


                    </Paper>
                </Popover>}
            </div>
        }, {
            title: "Action",
            field: "",
            render: (rowData) => (
                rowData?.status === 0 || rowData?.status === 3 ? <IconButton onClick={() => {
                    handleDelete(rowData)
                }}>

                    <Delete htmlColor={ICON_COLOUR} fontSize='small' />
                </IconButton> : ''
            )
        },

    ];
    return (
        <div>

            <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'flex-end' }}>
                <MROButton color='secondary' variant='contained' onClick={() => { setAction('create') }}>+ Add Request</MROButton>
            </div>
            <br />
            <div >
                <MaterialTable
                    style={{ boxShadow: "0px 1px 3px #00000033", paddingRight: "0px" }}
                    icons={tableIcons}
                    classes
                    title={""}
                    columns={columns}
                    data={data || []}
                    isLoading={loading}
                    options={tableOptions}
                    localization={{
                        toolbar: {
                            searchPlaceholder: "Search anything",
                        },
                        body: {
                            emptyDataSourceMessage: "No Records found",

                        },

                    }}
                    onChangePage={(page) => {
                        setPage(page);
                    }}
                    onChangeRowsPerPage={(pageSize) => {
                        setPageSize(pageSize);
                    }}
                    totalCount={totalCount}
                    page={page}
                />
            </div>
            {action && action === "create" && <CreateLeaveRequest handleClose={() => {
                setAction('')
            }} getListing={getListing} type={"home"} userTimeZone={userTimeZone} />}
        </div>
    )
}
