import React, { useContext, useEffect, useReducer, useState } from "react";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import tableReducer, { INITIAL_TABLE_STATE } from "reducers/table_reducer";
import MaterialTable from "material-table";
import tableIcons, { options } from "components/universal/table_attributes";
import { Box, Grid, IconButton, Typography } from "@material-ui/core";
import useDebounce from "lib/utils/debounce";
import makeStyles from "@material-ui/core/styles/makeStyles";
// import ListingService from "lib/services/api/listing_service";
import TableAction from "actions/table_actions";
import SearchByKeyword from "components/keyword_search";
import { ICON_COLOUR } from "lib/constants/style_constants";
import BoxpTableAction from "actions/boxp_table_actions";
import { ToastMessageContext } from "lib/contexts/message_context";
import UpdateStage from "./UpdateStage";
import { getOrganisationId } from "lib/utils/common_utils";

const useStyles = makeStyles((theme) => ({
  paper: {
    // padding: "4px",
    textAlign: "center",
  },

  appBar: {
    backgroundColor: "#fff",
    color: "#000000CC",
    boxShadow: "0px 3px 6px #0000001A",
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
}));

function Library(props) {
  const { action_Id } = props;
  const classes = useStyles();
  const message = useContext(ToastMessageContext);
  const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
  const { page, totalCount, pageSize, inProgress, data } = tableState;
  const [searchText, setSearchText] = useState("");
  const debouncedVal = useDebounce(searchText);
  const [action, setAction] = useState(null);
  const [currentSelection, setCurrentSelection] = useState({});

  useEffect(() => {
    getAllTaskLibrary();
  }, [page, pageSize, debouncedVal, setSearchText]);

  const getAllTaskLibrary = () => {
    const params = {
      page: page + 1,
      limit: pageSize,
      search: debouncedVal,
      organisation_id: getOrganisationId()
    };
    BoxpTableAction.getList(dispatch, message, params, "allTaskLibrary");
  };

  const tableOptions = {
    ...options,
    page: page,
    total: totalCount,
    pageSize: pageSize,
    search: false,
    rowStyle: { height: 45 },
  };

  const columns = [
    {
      title: "Title",
      field: "stage_name",
      headerStyle: {
        textAlign: "left",
        paddingLeft: "24px",
      },
      cellStyle: {
        textAlign: "left",
        paddingLeft: "24px",
        width: "40%",
      },
    },
    {
      title: "Sub-Fields",
      field: "sub_fields",
      headerStyle: {
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (rowData) => <span>{rowData?.sub_fields?.length}</span>,
    },
    {
      title: "Action",
      headerStyle: {
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (rowData) => {
        return (
          <Box>
            <IconButton
              onClick={() => {
                setAction("updateStage");
                setCurrentSelection(rowData);
              }}
              size="small"
            >
              <TouchAppIcon htmlColor={ICON_COLOUR} />
            </IconButton>
          </Box>
        );
      },
    },
  ];
  return (
    <>
      <Grid className={classes.container}>
        <Typography
          variant="subtitle2"
          color="primary"
          style={{ fontSize: "17px" }}
        >
          Search Library Stages
        </Typography>
        <Grid item xs={12} sm={4} style={{ marginBottom: "10px" }}>
          <SearchByKeyword
            searchText={searchText}
            handleSearchText={(value) => {
              TableAction.setPage(dispatch, 0);
              setSearchText(value);
            }}
          />
        </Grid>

        <div style={{ width: "50%" }}>
          <MaterialTable
            style={{ boxShadow: "0px 1px 3px #00000033", marginTop: "0%" }}
            icons={tableIcons}
            columns={columns}
            data={data.data || []}
            isLoading={inProgress}
            options={tableOptions}
            components={{
              Toolbar: () => null,
            }}
            localization={{
              toolbar: {
                searchPlaceholder: "Search anything",
              },
              body: {
                emptyDataSourceMessage: "No Tasks found",
                filterRow: {
                  filterTooltip: "Filter",
                },
              },
            }}
            onChangePage={(page) => {
              TableAction.setPage(dispatch, page);
            }}
            onChangeRowsPerPage={(pageSize) => {
              TableAction.setPageSize(dispatch, pageSize);
            }}
            totalCount={totalCount}
            page={page}
          />
        </div>
        <Typography style={{ fontSize: "12px", marginTop: "10px" }}>
          Select a Stage to view its details, edit and add to the Action.
          Multiple Stages can be added each time you return to this page.
        </Typography>
        {action && action === "updateStage" && (
          <UpdateStage
            currentSelection={currentSelection}
            handleClose={() => setAction(null)}
            action={action}
            action_Id={action_Id}
          />
        )}
      </Grid>
    </>
  );
}

export default Library;
