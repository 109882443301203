import React from "react";
import TextField from "@material-ui/core/TextField";
import withStyles from "@material-ui/core/styles/withStyles";



function container() {
  // Use the fullscreen element if in fullscreen mode, otherwise just the document's body
  return document.fullscreenElement ?? document.body;
}

const MROTextField = withStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.primary.main,
        // borderWidth: '1px !important'
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary.light + "!important",
      },
      '&.Mui-disabled fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.26) !important'
      },
      '&.Mui-error:hover  fieldset': {
        borderColor: '#f44336 !important',
      },
      "& .MuiOutlinedInput-input": {
        padding: "15px",
      },
    },
    "& label": {
      color: theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-input": {
      "&::placeholder": {
        textTransform: "none"
      },
    }
  },
}))((props) => (
  <TextField
    style={{ width: props?.width ? props?.width : "" }}
    classes={props?.classes}
    InputProps={{
      autoComplete: false,
      autoCorrect: false,
      startAdornment: props.startAdornment ? props.startAdornment : "",
      endAdorment: props.endAdorment ? props.endAdorment : "",
    }}
    fullWidth
    variant={props.variant ?? "outlined"}
    margin={"normal"}
    color={"primary"}
    helperText={props.error ? `Please ${props.select ? 'select' : 'enter'} the ${props.label?.replace("*", "")}` : null}
    {...props}
    SelectProps={{
      MenuProps: { container: container }
    }}
  />
));

export default MROTextField;
