
import requestApi from "../common/boxp_api_client";

const getUserCompliance = (params) => {
    return requestApi({
        url: "/user_compliance",
        method: "GET",
        params,
    })
}




const getUserComplianceListing = (params) => {
    return requestApi({
        url: "/user_compliance_listing",
        method: "GET",
        params,
    })
}

const getRecencyChartApi = (params) => {
    return requestApi({

        url: 'recency_dashboard_graph',
        method: "GET",
        params
    })

}

const getRecencyListingApi = (params) => {
    return requestApi({

        url: 'recency_dashboard_list',
        method: "GET",
        params
    })

}

export const UserDashboardServices = {
    getUserCompliance,
    getUserComplianceListing,
    getRecencyChartApi,
    getRecencyListingApi
}