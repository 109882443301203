import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { Box, ButtonGroup } from "@material-ui/core";
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    titleTile: {
        fontSize: "13px",
        width: '100%',
        fontWeight: 500,
        boxShadow: 'none'
    },
    titleButton: {
        backgroundColor: '#52159800',
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        border: '0.5px solid'
    },
    action: {
        width: '24%'
    },
    titleTile2: {
        padding: "3px 20px",
        fontSize: "13px",
        fontWeight: 500,
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.secondary.dark
    }
}))

function SectionLabelAction(props) {

    const classes = useStyles();

    return (
        <Box mb={2.5} ml={1.5} width="325px">
            <ButtonGroup component="div" className={classes.titleTile} variant="contained" aria-label="split button">
                <Button variant={"contained"} disableElevation component={"div"} className={clsx(classes.titleTile, classes.titleButton)}>{props.name}</Button>
                <Button disabled={!props?.permission?.write } {...props} variant={"contained"} color="primary" className={clsx(classes.titleTile, classes.action)}>+ Add</Button>
            </ButtonGroup>
        </Box>
    )
}

export function SubSectionLabel(props) {

    const classes = useStyles();

    return (
        <Button variant={"contained"} disableElevation component={"div"} className={classes.titleTile2}>{props.name}</Button>
    )
}

export default SectionLabelAction;
