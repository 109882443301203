import React, { useContext, useEffect, useState } from "react";

import { ToastMessageContext } from "lib/contexts/message_context";
import { MaintenanceServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation";
import AwayFromBase from "./AwayFromBase";
import Induction from "./Induction";
import NoGoodsIn from "./NoGoodsIn";
import { WorkshopContext } from "../../WorkshopContext/WorkshopContext";

function RenderInductionTab({
  data,
  readAndWrite,
  readOnly,
  getHeaderDetails,
  orderStateNum,
  tabStatus,
  closeTabStatus,
  getWorkshopOrderDetails,
  permission
}) {
  const message = useContext(ToastMessageContext);

  const { setState } = useContext(WorkshopContext);

  const { shouldTabDataUpdate, setShouldTabDataUpdate } =
    useContext(WorkshopContext);

  const [editTab, setEditTab] = useState(false);
  const [access, setAccess] = useState({ read: false, write: true });

  useEffect(() => {
    setState((ps) => ({ ...ps, isEditing: editTab, tab: "induction" }));

    return () => setState((ps) => ({ ...ps, tab: null }));
  }, [editTab]);

  useEffect(() => {
    if (readOnly) {
      setAccess({ read: true, write: false });
      return;
    }

    if (readAndWrite) {
      setAccess({ read: false, write: true });
      return;
    }
  }, [readAndWrite, readOnly]);

  const [inductionDetails, setInductionDetails] = useState({});

  const [isGettingDetails, setIsGettingDetails] = useState(false);

  const getInductionList = async () => {
    if (data.induction_id) {
      setIsGettingDetails(true);
      await MaintenanceServices.getInduction(data.induction_id)
        .then((res) => {
          setInductionDetails(res.data);
          setShouldTabDataUpdate(false);
        })
        .catch((err) => {
          message.showToastMessage({
            message: "Something went wrong. Try again!",
            variant: "error",
          });
        })
        .finally(() => {
          setIsGettingDetails(false);
        });
    }
  };

  useEffect(() => {
    getInductionList();
  }, [data.induction_id]);

  useEffect(() => {
    if (shouldTabDataUpdate) {
      getInductionList();
    }
  }, [shouldTabDataUpdate]);

  const _props = {
    data,
    inductionDetails,
    getInductionList,
    isGettingDetails,
    editTab,
    setEditTab,
    access,
    getHeaderDetails,
    orderStateNum,
    tabStatus,
    closeTabStatus,
    getWorkshopOrderDetails,
    setIsGettingDetails,
    permission
  };

  switch (data.induction_tab_style) {
    case 1:
      return <Induction {..._props} />;

    case 2:
      return <NoGoodsIn {..._props} />;

    case 3:
      return <AwayFromBase {..._props} />;

    default:
      return null;
  }
}

export default RenderInductionTab;
