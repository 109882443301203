// import request from "../common/api_client";
import requestrApi from "../common/boxr_api_client";
import requestApi from "../common/boxp_api_client";
// import { BASE_API_URL } from "lib/constants";

function fetchDependencies() {
  return requestrApi({
    url: `/equipment_requests/fetch_dependencies`,
    method: "GET",
  });
}
function ServiceFetchDependencies(params) {
  return requestrApi({
    url: `/services/fetch_dependencies`,
    method: "GET",
    params:params
  });
}
function workshopEquipmentFetchDependencies(params) {
  return requestrApi({
    url: `/equipment_requests/fetch_dependencies`,
    method: "GET",
    params:params
  });
}
function fetchEquipDependencies() {
  return requestrApi({
    url: `/equipment_requests/fetch_dependencies`,
    method: "GET",
  });
}
function fetchSupplier(params) {
  return requestrApi({
    url: `/equipment_requests/fetch_suppliers`,
    method: "GET",
    params: params,
  });
}
function fetchEquipmentSupplier(params) {
  return requestrApi({
    url: `/equipment_requests/fetch_suppliers`,
    method: "GET",
    params: params,
  });
}
function getProcuremntApprvl(params) {
  return requestrApi({
    url: `/equipment_requests/listing_proc_approvals`,
    method: "GET",
    params
  });
}
function releaseRequirement(params) {
  return requestApi({
    url: `/release_requirement`,
    method: "GET",
    params
  });
}
function equipment(params) {
  return requestrApi({
    url: `/equipment_requests`,
    method: "GET",
    params
  });
}

function createEquipment(formData) {
  return requestrApi({
    url: `/equipment_requests`,
    method: "POST",
    data: formData,
  });
}
function createEquipments(formData) {
  return requestrApi({
    url: `/equipments`,
    method: "POST",
    data: formData,
  });
}
function workshopCreateEquipments(formData) {
  return requestrApi({
    url: `/equipment_requests`,
    method: "POST",
    data: formData,
  });
}
function updateEquipments(formData) {
  return requestrApi({
    url: `/equipment_requests/update_equipment`,
    method: "PUT",
    data: formData,
  });
}
function workshopUpdateEquipments(formData) {
  return requestrApi({
    url: `/equipment_requests/update_equipment`,
    method: "PUT",
    data: formData,
  });
}
function updatePurchase(formData) {
  return requestrApi({
    url: `/equipment_requests/update_purchase_order`,
    method: "PUT",
    data: formData,
  });
}
function updateEquipmentQuoteServices(formData) {
  return requestrApi({
    url: `/equipments/update_equipment`,
    method: "PUT",
    data: formData,
  });
}

function updateDocuments(formData) {
  return requestrApi({
    url: `/equipment_requests/update_documents`,
    method: "PUT",
    data: formData,
  });
}
function cancelEquipmntOrder(formData) {
  return requestrApi({
    url: `/equipment_requests/cancel_eo`,
    method: "PUT",
    data: formData,
  });
}
function createPurchaseOrder(formData) {
  return requestrApi({
    url: `/equipment_requests/create_purchase_order`,
    method: "PUT",
    data: formData,
  });
}
function deleteEquipments(Id) {
  return requestrApi({
    url: `/equipment_requests/delete_eo`,
    method: "DELETE",
    data: Id,
  });
}

//Approval page
function editProcApproval(formData) {
  return requestrApi({
    url: `/equipment_requests/edit_proc_approval`,
    method: "PUT",
    data: formData,
  });
}
function changeState(formData) {
  return requestrApi({
    url: `/equipment_requests/change_state`,
    method: "PUT",
    data: formData,
  });
}

//send order report email
function sendOrderReportEmail(params) {
  return requestrApi({
    url: `/equipment_requests/send_equipment`,
    method: "GET",
    params
  });
}

function deleteDocument(params) {
  return requestrApi({
    url: `/generals/remove_attachment`,
    method: "DELETE",
    params
  });
}

const EquipmentsClientServices = {
  updatePurchase,
  fetchDependencies,
  createEquipment,
  updateEquipments,
  updateDocuments,
  fetchSupplier,
  ServiceFetchDependencies,
  workshopEquipmentFetchDependencies,
  fetchEquipDependencies,
  createEquipments,
  fetchEquipmentSupplier,
  updateEquipmentQuoteServices,
  workshopCreateEquipments,
  deleteEquipments,
  createPurchaseOrder,
  getProcuremntApprvl,
  editProcApproval,
  changeState,
  cancelEquipmntOrder,
  releaseRequirement,
  workshopUpdateEquipments,
  equipment,
  sendOrderReportEmail,
  deleteDocument
};

export default EquipmentsClientServices;
