import React, { useContext, useEffect, useMemo, useReducer, useState } from "react";
import {
    Box,
    FormControl,
    IconButton,
    InputBase,
    makeStyles,
    MenuItem,
    Select, Typography, Paper, useTheme, Grid
} from "@material-ui/core";
import tableReducer, { INITIAL_TABLE_STATE } from "reducers/table_reducer";
import MaterialTable from "material-table";
import tableIcons, { options } from "components/universal/table_attributes";
// import React, { useCallback } from 'react';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { WorkshopDashboardServices } from "lib/services/api/dashboard/workshop";
import { format24HrDateOnly, getOrganisationId } from "lib/utils/common_utils";
import TableAction from "actions/table_actions";
import Chart from "./Chart";
import ChartLoader from "./ChartLoader";
import BarChart from "./BarChart";
import { ToastMessageContext } from "lib/contexts/message_context";
import { MaintenanceServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation";
import FullScreenIcon from 'assets/display-size.svg'
import { useRef } from "react";


const useStyles = makeStyles((theme) => ({

    wrapper: {
        // backgroundColor: "#F5F5F5",
        width: 'auto',
        height: 'auto',
        backgroundSize: 'cover',
        padding: theme.spacing(2, 3),
        border: "1px solid transparent",
        margin: "1% 0",
    },
    pictureBox: {
        paddingTop: "60px",
    },

    quotePercentageText: {
        marginTop: ' -112px',
        marginBottom: ' 53px',
        textAlign: ' center',
        fontSize: '22px',
        color: theme.palette.secondary.main
    },
    centerLabel: {
        textAlign: ' center',
        marginTop: ' -50px',
        marginBottom: '66px',
        color: '#4D4F5C',
        fontSize: '12px'
    },
    inStockLabel: {
        textAlign: "left", paddingTop: '7px', fontSize: '12px', paddingLeft: '5px'
    },
    chartLabelText: {
        textAlign: "right",
        paddingLeft: '5px',
        fontSize: '22px',
        paddingRight: '11px'
    },
    orgLogo: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        left: '40px',
        top: '40px',
        right: '40px',
        '& img': {
            maxWidth: '300px',
            maxHeight: '175px'
        }
    },
}));

function Storage() {
    const theme = useTheme()
    const classes = useStyles()
    const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
    const [actionListData, setActionListData] = useState([])
    const [actionLoading, setActionLoading] = useState(false)
    const [storedData, setStoredData] = useState({})

    const [workloadData, setworkloadData] = useState([])
    const [pieChartValues, setPieChartValues] = useState({})
    const [chartLoading, setChartLoading] = useState(false)
    const [bases, setBases] = useState([]);
    const message = useContext(ToastMessageContext);
    const [filterData, setFilterData] = useState({});

    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(5);
    const [pageSize, setPageSize] = useState(10);
    const [totalValue, setTotalvalue] = useState({})
    const [fullScreenState, setFullScreenState] = useState(false)
    const id = useRef(null);

    const orgLogo = sessionStorage.getItem('logo') || "/mro-pro-logo.png";

    useEffect(() => {
        document.addEventListener('fullscreenchange', exitHandler);
        document.addEventListener('webkitfullscreenchange', exitHandler);
        document.addEventListener('mozfullscreenchange', exitHandler);
        document.addEventListener('MSFullscreenChange', exitHandler);

        function exitHandler() {
            if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
                clearInterval(id.current)
                setPage(0)
                getStorageActionDueDetails()
                getStorageDetails()
                getBaseStation()
                setFullScreenState(false)
            }
        }
    }, [fullScreenState])


    //api calll
    const getStorageDetails = async () => {
        const params = {
            organisation_id: getOrganisationId(),
            ...filterData
        }
        setChartLoading(true)
        await WorkshopDashboardServices.getStorageDetails(params).then((response) => {
            setStoredData(response.data.stored_components)
            setworkloadData(Object.values(response.data.workload))

            setPieChartValues(response.data.actions_due)
        }).catch((err) => {
            message.showToastMessage({
                message: "Something went wrong. Try again!",
                variant: "error",
            });
        }).finally(() => {
            setChartLoading(false)
        })
    }

    useEffect(() => {
        getStorageDetails()
    }, [page, pageSize, filterData])

    useEffect(() => {
        getBaseStation()
    }, [])


    useMemo(() => setTotalvalue(pieChartValues.above_ten_days + pieChartValues.two_days + pieChartValues.ten_days), [pieChartValues])
    const getBaseStation = () => {
        const params = {
            organisation_id: getOrganisationId()
        }
        MaintenanceServices.getBaseStation(params)
            .then((res) => {
                if (res) {
                    setBases(res.data);
                }
                // setLoading(false);
            })
            .catch((err) => {
                message.showToastMessage({
                    message: "Something went wrong. Try again!",
                    variant: "error",
                });
                // setLoading(false);
            });
    };

    const getStorageActionDueDetails = async () => {
        const params = {
            organisation_id: getOrganisationId(),
            page: page + 1,
            limit: pageSize,
            ...filterData
        }
        setActionLoading(true)
        await WorkshopDashboardServices.getStorageActionDueListing(params).then((response) => {
            setActionListData(response.data)
            setTotalCount(response.total_count);
        }).catch((err) => {
            message.showToastMessage({
                message: "Something went wrong. Try again!",
                variant: "error",
            });
        }).finally(() => {
            setActionLoading(false)
        })
    }
    useEffect(() => {
        getStorageActionDueDetails()
    }, [page, pageSize, filterData])

    const tableOptions = {
        ...options,
        page: page,
        total: totalCount,
        pageSize: pageSize,
        search: false,
        rowStyle: {
            height: "40px",
            maxHeight: 40,
        }
    };





    const colors = { amber: "#FFB300", green: "#4FC605", red: "#FF0202" }
    const columns = [
        {
            title: "Days",
            field: "due_days",
            headerStyle: {
                paddingLeft: 10,
            },
            cellStyle: {
                // paddingLeft: 15,
                textAlign: 'center'
            },
            render: (rowData) => (
                <div style={{ color: rowData.due_days > 10 ? colors.green : rowData.due_days <= 2 ? colors.red : colors.amber, fontWeight: 600 }}>
                    {rowData.due_days}
                </div>
            )

        },
        {
            title: "Due",
            field: "due",
            render: (rowData) => (rowData.due ? format24HrDateOnly(rowData.due) : '-')

        },
        {
            title: "MSN",
            field: "serial_number",

        }, {
            title: "Location",
            field: "location",

        }, {
            title: "Storage Order",
            field: "order_number",

        },
        {
            title: "Part #",
            field: "part_number",

        },
        {
            title: "Action #",
            field: "action_number",

        },
        {
            title: "Description",
            field: "title",
            width: '60%',
            headerStyle: {
                // width: '60%',
                textAlign: "left"
            },
            cellStyle: {
                width: '40%',
            }

        }

    ];
    const handleFilterChange = (keyItem, value) => {
        TableAction.setPage(dispatch, 0);
        setFilterData((prevState) => ({ ...prevState, [keyItem]: value }));
    };


    const handle = useFullScreenHandle();
    //10 second delay for automatic pagination
    const handleInterval = (i) => {
        return new Promise((resolve, reject) => {
            id.current = setInterval(() => resolve(i), 10000)
        })
    }

    const handleFunc = async () => {
        setFullScreenState(true)
        let i = 1
        while (i != Math.ceil(totalCount / 10)) {
            let result = await handleInterval(i)
            i++
            setPage(result)
            if (
                (result === 1 && totalCount <= 20) ||
                (result === 2 && totalCount <= 30) ||
                (result === 3 && totalCount <= 40) ||
                (result === 4 && totalCount <= 50) ||
                (result === 5 && totalCount <= 60)
            ) {
                i = 0; continue;
            }
        }
    }

    const handleChangePage = (page) => {
        if (fullScreenState) {
            console.log("do nothing")
        } else {
            setPage(page)
        }
    }
    //rgba(99, 56, 244, 0.34), rgba(67, 22, 80, 0.34))
    return (
        <FullScreen handle={handle} >
            <div style={{ paddingLeft: '14px' }}>
                <div className={classes.wrapper}
                    style={{
                        background: fullScreenState &&
                            `transparent linear-gradient(204deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%) 0% 0% no-repeat padding-box`
                    }}
                >
                    {fullScreenState && <div className={classes.orgLogo}>
                        <a href="">
                            <img src={orgLogo} />
                        </a>
                    </div>}

                    <div>
                        <div style={{
                            display: "flex", justifyContent: "flex-end", padding: 0, marginBottom: '26px',
                            marginRight: '24px'
                        }}>

                            {!fullScreenState && <FormControl>
                                <Select
                                    defaultValue={""}
                                    displayEmpty
                                    input={<InputBase />}
                                    onChange={(e) =>
                                        handleFilterChange("base", e.target.value)
                                    }

                                >
                                    <MenuItem value={""} >Base(All)</MenuItem>
                                    {
                                        bases?.map((base) => (
                                            <MenuItem key={base.id} value={base.id}>
                                                {base.station}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>}
                            <IconButton onClick={handle.enter}>
                                {!fullScreenState && <Box display="flex" justifyContent="center" onClick={handleFunc}>
                                    {" "}
                                    <img
                                        src={FullScreenIcon}
                                        alt="Icon"
                                        style={{ height: "18px" }}
                                    />{" "}
                                </Box>}
                            </IconButton>
                        </div>
                        <div style={{ paddingTop: fullScreenState && "70px", }} >
                            <Grid container xs={12} spacing={1} >
                                <Grid xs={3} style={{ paddingRight: "10px", paddingLeft: '7px' }}>
                                    <Paper style={{ width: '100%', height: '250px' }}>
                                        <Box padding="10px" display="flex" flexDirection="row" justifyContent={'center'}>

                                            <Typography gutterBottom={20} variant="subtitle2" color="primary">STORED COMPONENTS</Typography>
                                        </Box>
                                        {chartLoading ? <ChartLoader /> :
                                            <>
                                                <Box width="100%" display="flex" flexDirection="column" >

                                                    <Typography gutterBottom={10} variant="h2" color="secondary" style={{ textAlign: "center", height: '85px', paddingTop: '19px' }}>{storedData.serviceable && storedData.unserviceable ? storedData.serviceable + storedData.unserviceable : 0}</Typography>
                                                </Box>
                                                <Box width="100%" display="flex" flexDirection="column" maxHeight='500px'>
                                                    <Grid container direction="row" xs={12}>
                                                        <Grid xs={6} direction="column" container>
                                                            <Grid><Typography gutterBottom={20} variant="h4"
                                                                style={{ textAlign: "center", color: "#4FC605" }}>
                                                                {storedData.serviceable}</Typography></Grid>
                                                            <Grid><Typography style={{ textAlign: "center", fontSize: '12px' }}>Serviceable</Typography></Grid>

                                                        </Grid>
                                                        <Grid xs={6} direction="column" container><Grid><Typography gutterBottom={20} variant="h4" style={{ textAlign: "center", color: "#FF0202" }}>{storedData.unserviceable}</Typography></Grid>
                                                            <Grid><Typography style={{ textAlign: "center", fontSize: '12px' }}>Unserviceable</Typography></Grid></Grid>

                                                    </Grid>
                                                </Box>
                                            </>
                                        }
                                    </Paper>
                                </Grid>
                                <Grid xs={4} style={{ paddingRight: "10px" }}>


                                    <Paper style={{ width: '100%', height: '250px' }}>
                                        {chartLoading ? <ChartLoader /> :
                                            <>
                                                <Grid container direction="row" >
                                                    <Grid xs={7} style={{
                                                        paddingTop: ' 27px',
                                                        paddingBottom: '52px',
                                                        paddingLeft: '13px'
                                                    }}>

                                                        <Box paddingTop="10px" display="flex" flexDirection="row">

                                                            <Chart
                                                                value1={pieChartValues.above_ten_days}
                                                                value2={pieChartValues.ten_days}
                                                                value3={pieChartValues.two_days}
                                                                labels={[">10 Days", "3-10 Days", "0-2 Days"]}
                                                                labelName={"Calendar Actions"}
                                                                total={totalValue ? totalValue : 0}
                                                            />


                                                        </Box>
                                                        {/* <div>
                                                    <Typography className={classes.quotePercentageText} >{totalValue ? totalValue : 0}</Typography>
                                                    <Typography className={classes.centerLabel}>Calendar Actions</Typography>
                                                </div> */}

                                                    </Grid>
                                                    <Grid container direction="column" xs={5}>
                                                        <Grid>
                                                            <Box padding="10px" display="flex" flexDirection="row" justifyContent={'space-around'}>
                                                                <Typography gutterBottom={20} variant="subtitle2" color={'primary'}>ACTIONS DUE</Typography></Box></Grid>
                                                        <Grid container direction="row" style={{ paddingTop: '22px' }}>

                                                            <Grid item xs={5}><Typography gutterBottom={20} className={classes.chartLabelText} style={{ color: "#FF0202" }}> {pieChartValues.two_days}</Typography></Grid>

                                                            <Grid item xs={6}><Typography gutterBottom={20} className={classes.inStockLabel} >0-2 Days</Typography></Grid>


                                                        </Grid>
                                                        <Grid container direction="row">

                                                            <Grid item xs={5}><Typography gutterBottom={20} className={classes.chartLabelText} style={{ color: "#FFB300", }}>{pieChartValues.ten_days}</Typography></Grid>

                                                            <Grid item xs={7}><Typography gutterBottom={20} className={classes.inStockLabel} >3-10 Days</Typography></Grid>


                                                        </Grid>
                                                        <Grid container direction="row">

                                                            <Grid item xs={5}><Typography gutterBottom={20} className={classes.chartLabelText}
                                                                style={{ color: "#4FC605" }}>
                                                                {pieChartValues.above_ten_days}</Typography></Grid>

                                                            <Grid item xs={7}><Typography className={classes.inStockLabel} > &gt; 10 Days</Typography></Grid>


                                                        </Grid>

                                                    </Grid>

                                                </Grid>
                                            </>}
                                    </Paper></Grid>
                                <Grid xs={5}  >
                                    <Paper style={{ width: '100%', height: '250px' }}>
                                        <Box padding="10px" display="flex" flexDirection="row">

                                            <Typography gutterBottom={20} variant="subtitle2" style={{ color: theme.palette.primary.main }}>14 DAY WORKLOAD</Typography>
                                        </Box>
                                        {chartLoading ? <ChartLoader /> :
                                            <Box width="100%" display="flex" flexDirection="column" >

                                                <BarChart
                                                    value={workloadData}
                                                    color={theme.palette.primary.main}
                                                />

                                            </Box>}
                                    </Paper>
                                </Grid>
                            </Grid>
                            <br />
                            <Box paddingLeft={'2px'} paddingRight={'4px'} paddingBottom={fullScreenState ? '700px' : '20px'}>
                                <MaterialTable
                                    style={{ boxShadow: "0px 1px 3px #00000033" }}
                                    icons={tableIcons}
                                    title={
                                        <Typography color="primary" variant="subtitle2" style={{ textAlign: 'center' }}>
                                            ACTIONS DUE
                                        </Typography>
                                    }
                                    isLoading={actionLoading}
                                    data={actionListData || []}
                                    columns={columns}
                                    options={tableOptions}
                                    onChangePage={(page) => {
                                        handleChangePage(page)
                                    }}
                                    onChangeRowsPerPage={(pageSize) => {
                                        setPageSize(pageSize);
                                    }}
                                    onSearchChange={(search) => {
                                        TableAction.setSearchText(dispatch, search);
                                    }}
                                    totalCount={totalCount}
                                    page={page}
                                />
                            </Box>
                        </div>
                    </div>
                </div>
            </div>
        </FullScreen >
    )
}

export default Storage