import { AppBar, Toolbar, makeStyles, IconButton, Typography, Grid } from '@material-ui/core'
import MROFullScreenRightDrawer from 'components/fullwidth_dialog'
import React, { useReducer, useState, useEffect, useContext } from 'react'
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import MaterialTable from 'material-table';
import tableIcons, { options } from 'components/universal/table_attributes';
import tableReducer, { INITIAL_TABLE_STATE } from "reducers/table_reducer";
import TableAction from 'actions/table_actions';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { ICON_COLOUR } from 'lib/constants/style_constants';
import ViewIcon from "@material-ui/icons/Visibility";
import TechFilesPopup from './TechFilesPopup';
import Stages from './Stages';
import CopyActions from './CopyActions';
import { MaintenanceServices } from 'lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation';
import { ToastMessageContext } from 'lib/contexts/message_context';
import { WorkshopContext } from 'views/Operations/Workshop/WorkshopContext/WorkshopContext';
import { Clear } from "@material-ui/icons";
import GreenCopyIcon from "assets/GreenCopyIcon.svg"
const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: "#fff",
        color: "#000000CC",
        boxShadow: "0px 3px 6px #0000001A",
    },
    backButton: {
        marginRight: theme.spacing(2),
    },
    wrapper: {
        margin: "3%",
        overflowX: "hidden !important"
    },

}));
function HistoricalJobs(props) {

    const classes = useStyles()
    const { handleClose, selectedRow, currentWorkshopDetails, tabname } = props
    const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
    const { page, totalCount, pageSize, inProgress, data } = tableState;
    const [action, setAction] = useState('')
    const [currentSelectedRow, setCurrentSelectedRow] = useState({})
    const [isTechFiles, setIsTechFiles] = useState(false)
    const [currentSelection, setCurrentSelection] = useState({})
    const [actionData, setActionData] = useState([])
    const [actionDetails, setActionDetails] = useState(false)
    const message = useContext(ToastMessageContext)
    const { setCopyInspection, setCopyParts, setCopyServices, setServiceFlag, setEquipmentFlag, setCopyEquipments, copyInspection, copiedJobs, setCopiedJobs } = useContext(WorkshopContext)



    const handleCopyClose = () => {
        handleClose()
        setCopyInspection(false)
        setServiceFlag(false)
        setCopyParts([])
        setCopyServices([])
        setEquipmentFlag(false)
        setCopyEquipments([])
        setCopiedJobs([])
    }
    // console.log("tabname=======", tabname)
    const getActionList = async () => {
        setActionDetails(true)
        const params = {
            workshop_order_id: selectedRow.id,
            page: page + 1,
            limit: pageSize,
            action_type: tabname
        }
        await MaintenanceServices.copyPreviousGetActionList(params).then((response) => {

            setActionData(response.data)

            TableAction.setTotalCount(dispatch, response.total_count);
        }).catch((err) => {
            message.showToastMessage({
                message: "Something went wrong. Try again",
                variant: "error",
            })
        }).finally(() => {
            setActionDetails(false)
        })
    }

    useEffect(() => {
        getActionList()
    }, [selectedRow.id, page, pageSize, totalCount])


    const tableOptions = {
        ...options,
        page: page,
        total: totalCount,
        pageSize: pageSize,
        search: false,
        toolbar: false,
        // rowStyle: { height: 42 },
    };
    const column = [

        {
            title: "Action #",
            field: "action_number",
            headerStyle: {

                textAlign: 'left', paddingLeft: '5px'
            },
            cellStyle: {

                textAlign: 'left', paddingLeft: '5px'
            }
        },
        {
            title: 'Title',
            field: 'title',
            width: '60%',
            headerStyle: {
                width: '60%',
                textAlign: 'left'
            },
            cellStyle: {
                width: '60%',
                textAlign: 'left'
            }
        },
        {
            title: "Detail",
            field: "maintenance_file",
            render: (rowData) => (
                <div>
                    <IconButton>
                        <ViewIcon fontSize="small" style={{ color: ICON_COLOUR }} onClick={() => {
                            setIsTechFiles(true)
                            setAction("view_files")
                            setCurrentSelectedRow(rowData)
                        }}
                        />
                    </IconButton>

                </div>

            )

        },
        {
            title: "Stages",
            field: "stage_count",
            render: (rowData) => (
                <div>
                    <a style={{ textDecoration: "underline" }} onClick={() => {
                        setAction("view_stages")
                        setCurrentSelection(rowData)
                    }}>{rowData?.stage_count}</a>
                </div>
            )
        },
        {
            title: 'Parts',
            field: 'part_count',

        },
        {
            title: 'Service',
            field: 'service_count',

        },
        {
            title: 'Equipment',
            field: 'equipment_count',

        },

        {
            title: 'Copy',
            field: 'view',
            render: (rowData) => <>
                {copiedJobs.find((item) => item === rowData.workshop_action_id) ? <IconButton
                >
                    <img src={GreenCopyIcon} height="25px" style={{ pointerEvents: copiedJobs.find((item) => item === rowData.workshop_action_id) ? "none" : "", }} />
                </IconButton> :
                    <IconButton onClick={() => {
                        setAction('copy')
                        setCurrentSelection(rowData)
                    }}>
                        <ArrowForwardIcon color={ICON_COLOUR} />
                    </IconButton>}

            </>
        }

    ]

    return (
        <div>
            <MROFullScreenRightDrawer open={true}>
                <AppBar position="static" className={classes.appBar} elevation={0}>
                    <Toolbar>
                        <IconButton
                            onClick={handleCopyClose}
                            edge="start"
                            className={classes.backButton}
                            color="inherit"
                            aria-label="back"
                        >
                            <BackIcon />
                        </IconButton>
                        <Typography variant="subtitle2" style={{ fontSize: "17px" }}>
                            Historical Jobs
                        </Typography>
                    </Toolbar>
                </AppBar>
                <div className={classes.wrapper}>
                    <Grid>

                        <Typography color='primary' style={{ fontSize: '18px' }}>Job Actions</Typography>


                    </Grid>
                    <br />
                    <Grid style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                        <MaterialTable
                            style={{
                                boxShadow: "0px 1px 3px #00000033",
                                marginTop: "10px",
                            }}
                            icons={tableIcons}
                            classes
                            title={""}
                            columns={column}
                            data={actionData || []}
                            isLoading={actionDetails}
                            options={tableOptions}
                            onChangePage={(page) => {
                                TableAction.setPage(dispatch, page);
                            }}
                            onChangeRowsPerPage={(pageSize) => {
                                TableAction.setPageSize(dispatch, pageSize);
                            }}
                            totalCount={totalCount}
                            page={page}
                        />

                    </Grid>
                </div>
            </MROFullScreenRightDrawer>
            {
                action === 'view_files' && (
                    <TechFilesPopup
                        handleClose={() => setAction(null)}
                        currentSelectedRow={currentSelectedRow}
                        open={isTechFiles}
                    />
                )
            }
            {
                action === 'view_stages' && (
                    <Stages
                        handleClose={() => setAction(null)}
                        currentSelection={currentSelection}
                    />

                )

            }
            {action === 'copy' && (
                <CopyActions handleClose={() => setAction(null)}
                    currentSelection={currentSelection}
                    currentWorkshopDetails={currentWorkshopDetails}
                    currentClosedDetails={selectedRow}
                />

            )
            }
        </div>
    )
}

export default HistoricalJobs