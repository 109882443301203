import { Box, Typography, Divider, Grid, Paper, useTheme, makeStyles, FormControl, InputBase, MenuItem, Select, alpha, Button, Menu, FormControlLabel } from '@material-ui/core'
import React, { useState, useEffect, useContext, useMemo } from 'react'
import Chart from './Chart'
import OTP from './OTP'
import Recovered from './Recovered'
import Chargeable from './Chargeable'
import ManHours from './ManHours'
import TirePressure from 'assets/Dashboard/tire-pressure.svg'
import BoomLift from 'assets/Dashboard/boom-lift.svg'
import LadderTruck from 'assets/Dashboard/ladder-truck.svg'
import Airplane from 'assets/Dashboard/airplane.svg'
import SearchBarWithFilters from 'components/search_with_filters';
import { DatePicker } from 'antd';
import { LineMaintainenceServices } from 'lib/services/api/dashboard/line_maintenance'

import { getOrganisationId } from 'lib/utils/common_utils'
import ChartLoader from 'views/Dashboard/Workshops/Storage/ChartLoader'
import LineCustomerService from 'lib/services/api/admin/line/line_customer_client_new'
import { ToastMessageContext } from 'lib/contexts/message_context'
import ArrowDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import _without from "lodash/without";
import PositiveSwitch from 'components/form_components/switch'
import { MaintenanceServices } from 'lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation'
import moment from 'moment'


const { RangePicker } = DatePicker

const useStyles = makeStyles((theme) => ({
    image: {
        marginTop: '30px',
        marginLeft: '5px',

    },
    topUpGrid: {
        marginTop: '35px',
    },
    taxInflation: {
        marginTop: '24px',
        marginBottom: '10px'

    },
    divider: {
        textAlign: 'center',
        borderRight: '3px solid #70707099'
    },
    quotePercentageText: {
        marginTop: ' -112px',
        marginBottom: ' 53px',
        textAlign: ' center',
        fontSize: '22px',
        color: '#8CDA5C'
    },
    centerLabel: {
        textAlign: ' center',
        marginTop: ' -50px',
        marginBottom: '66px',

        fontSize: '12px',
        color: theme.palette.primary.main
    },

}))

export default function LinePerformance() {
    const [action, setAction] = useState(null)
    const classes = useStyles()
    const theme = useTheme();
    const [otpData, setOtpData] = useState({})
    const [recoveredData, setRecoveredData] = useState({})
    const [loading, setLoading] = useState(false)
    const [manHoursData, setManHoursData] = useState({})
    const [chargesData, setChargesData] = useState({})
    const [customerDetails, setCustomerDetails] = useState([]);
    const message = useContext(ToastMessageContext)

    const [selectedFilters, setSelectedFilters] = useState([]);
    const [checkedCustState, setCheckedCustState] = useState(new Array(customerDetails.length).fill(false));
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorBase, setAnchorBase] = useState(null);

    const [custButton, setCustButton] = useState(false)
    const [bases, setBases] = useState([]);
    const [checkedState, setCheckedState] = useState(new Array(bases.length).fill(false));
    const [selectedBase, setSelectedBase] = useState([]);
    const [baseButton, setBaseButton] = useState(false)

    const [value, setValue] = useState([moment().subtract(7, 'days'), moment()])
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [filterData, setFilterData] = useState({});


    //Percentage 

    const [otpPercentage, setOTPPercentage] = useState(0)
    const [recoveredPercentage, setRecoveredpercentage] = useState(0)





    //customer filters
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setCustButton(!custButton)
    };
    const handleClose = () => {
        setAnchorEl(null);
        setCustButton(!custButton)
    };

    const filterCustomerChangeHandler = (e) => {
        const { name, value, checked } = e.target
        // console.log("customer======", value, checked)

        setSelectedFilters((prevState) => [...prevState, value])
        if (!checked) {
            setSelectedFilters((current) => _without(current, value));
        }

    }
    //basefilters
    const filterChangeHandler = (e) => {
        const { name, value, checked } = e.target

        setSelectedBase((prevState) => [...prevState, value])
        if (!checked) {
            setSelectedBase((current) => _without(current, value));
        }

    }
    const handleBaseClick = (event) => {
        setAnchorBase(event.currentTarget);
        setBaseButton(!baseButton)
    };

    //date filters

    const handleDateChange = (value) => {
        setStartDate(true)
        setEndDate(true)
        setValue(value)
        if (value) {
            const startdate = moment(value[0]).format("YYYY-MM-DD");
            const enddate = moment(value[1]).format("YYYY-MM-DD");
            let dayDiff = moment(value[0]).diff(value[1], 'days')

            if (Math.abs(dayDiff) > 365) {
                setValue(null)
                setFilterData({})
                message.showToastMessage({
                    message: "Date range exceeds 1-year maximum!",
                    variant: "error",
                });

            }
            else {
                setFilterData((prevState) => ({ ...prevState, startdate, enddate }));
            }

        } else {
            const fData = { ...filterData };
            filterData?.startdate && delete fData.startdate;
            filterData?.enddate && delete fData.enddate;
            setFilterData({ ...fData });
        }
    };

    //Api calls
    const getPerformanceDetails = async () => {
        setLoading(true)
        const params = {

            organisation_id: getOrganisationId(),
            start_date: startDate ? filterData.startdate : moment().subtract(7, 'days').format("YYYY-MM-DD"),
            end_date: endDate ? filterData.enddate : moment().format("YYYY-MM-DD"),

        }
        selectedBase.forEach((item, index) => params[`base_station_id[${[index]}]`] = item)
        selectedFilters.forEach((item, index) => params[`line_customer_id[${[index]}]`] = item)
        await LineMaintainenceServices.getPerformance(params).then((response) => {
            setOtpData(response.data.otp)
            setRecoveredData(response.data.recovered)
            setManHoursData(response.data.man_hours)
            setChargesData(response.data.charges)
        }).catch((e) => {
            message.showToastMessage({
                message: "Something went wrong. Try again!",
                variant: "error",
            });
        }).finally(() => {

            setLoading(false)
        })

    }

    useEffect(() => {

        getPerformanceDetails()
    }, [selectedFilters, selectedBase, filterData])
    // console.log("Dasboardprops=========", manHoursData)


    //percentage calculation 

    useMemo(() => {
        let total = otpData.on_time + otpData.non_tech + otpData.tech
        let value1 = otpData.on_time + otpData.non_tech
        let percentage = (value1 / total) * 100
        if (total === 0) {
            setOTPPercentage(total)

        }
        else {
            setOTPPercentage(parseFloat(percentage).toFixed(2))
        }
    }, [otpData])

    useMemo(() => {
        let total = recoveredData.late_in_on_time + recoveredData.late_in_late_out
        let percentage = (recoveredData.late_in_on_time / total) * 100

        if (total === 0) {
            setRecoveredpercentage(total)
        }
        else {
            setRecoveredpercentage(parseFloat(percentage).toFixed(2))
        }
    }, [recoveredData])

    //Line customer api
    const getCustomerList = async () => {
        await LineCustomerService.getLineCustomers()
            .then((res) => {

                setCustomerDetails(res.line_customers);


            })
            .catch((err) => {
                message.showToastMessage({
                    message: "Something went wrong. Try again!",
                    variant: "error",
                });

            });
    };
    const getBaseStation = async () => {
        const params = {
            organisation_id: getOrganisationId(),
            order_by: 'station'
        }
        MaintenanceServices.getBaseStation(params)
            .then((res) => {
                if (res) {
                    setBases(res.data);
                }

            })
            .catch((err) => {
                message.showToastMessage({
                    message: "Something went wrong. Try again!",
                    variant: "error",
                });

            });
    };
    useEffect(() => {
        getCustomerList()
        getBaseStation()
    }, [])

    const ITEM_HEIGHT = 78;

    return (
        <div>



            <SearchBarWithFilters noSearch padding={'4px 16px'}
            >
                <FormControl style={{ marginLeft: '-5px' }}>
                    <Button variant={"text"} size="small" aria-haspopup="true" onClick={handleClick} endIcon={custButton ? <ArrowDropUp /> : <ArrowDown />} style={{ fontSize: "14px", backgroundColor: anchorEl ? '#efefef' : "" }} >
                        {selectedFilters.length > 0 ? `Customer(${selectedFilters.length})` : 'Customer(All)'}
                    </Button>
                    <Menu
                        id="Customer"
                        anchorEl={anchorEl}
                        keepMounted
                        open={anchorEl}
                        onClose={handleClose}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                        transformOrigin={{ vertical: "top", horizontal: "left" }}
                        style={{ marginTop: '41px' }}
                        TransitionProps={false}
                        PaperProps={{
                            style: {
                                maxHeight: ITEM_HEIGHT * 4.2,
                                width: '39ch',
                                position: 'fixed',
                                top: '260px',

                            },
                        }}
                    >
                        {/* <MenuItem value="" style={{ marginLeft: '7px' }}>Customer(All)</MenuItem> */}
                        {customerDetails?.map((option, index) => {
                            return <Box>
                                <Grid container spacing={2}>
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={9} style={{
                                        display: "flex", alignItems: "center", fontSize: "14px",

                                    }}>
                                        <label>{option.name}</label>
                                    </Grid>
                                    <Grid item xs={2} style={{ marginLeft: '-23px' }}>
                                        <FormControlLabel

                                            control={<PositiveSwitch
                                                checked={checkedCustState[index]}
                                                value={option.id}
                                                onChange={(e) => { filterCustomerChangeHandler(e) }}
                                                name={option.name} />}
                                        />
                                    </Grid>

                                </Grid>
                                <Grid><Divider variant="middle" /></Grid>
                            </Box>
                        })}
                    </Menu>
                </FormControl>
                <FormControl >
                    <Button variant={anchorBase ? "contained" : "text"} size="small" aria-haspopup="true" onClick={handleBaseClick} endIcon={baseButton ? <ArrowDropUp /> : <ArrowDown />} style={{ fontSize: "14px", backgroundColor: anchorBase ? '#efefef' : "" }}>
                        {selectedBase.length > 0 ? `Base(${selectedBase.length})` : 'Base(All)'}

                    </Button>
                    <Menu
                        id="Base"
                        anchorEl={anchorBase}
                        keepMounted
                        open={Boolean(anchorBase)}
                        onClose={() => {
                            setAnchorBase(null)
                            setBaseButton(!baseButton)
                        }}
                        // anchorOrigin={{
                        //     vertical: 'bottom',
                        //     horizontal: 'left',
                        // }}
                        style={{ marginTop: '40px' }}
                        PaperProps={{
                            style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: '39ch',
                                position: 'absolute',
                                top: '260px',
                            },
                        }}
                    >
                        {
                            bases?.map((base, index) => {
                                return <Box>
                                    <Grid container spacing={2}>
                                        <Grid item xs={1}></Grid>
                                        <Grid item xs={8} style={{
                                            display: "flex", alignItems: "center", fontSize: "14px",

                                        }}>
                                            <label>{base.station}</label>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormControlLabel
                                                style={{ alignSelf: "flex-end" }}
                                                control={<PositiveSwitch
                                                    checked={checkedState[index]}
                                                    value={base.id}
                                                    // onChange={(e) => handleChange(e, option.id)}
                                                    onChange={(e) => { filterChangeHandler(e, index) }}
                                                    name={base.station} />}
                                            // name="base"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid><Divider variant="middle" /></Grid>
                                </Box>
                            }

                            )}
                    </Menu>
                </FormControl>
                <FormControl style={{ float: "right" }}>
                    <RangePicker
                        format="DD/MM/YYYY"
                        onChange={handleDateChange}
                        allowClear={true}
                        value={value}

                    />
                </FormControl>
            </SearchBarWithFilters>
            <Box width="100%" paddingLeft={'4px'}>
                <Typography variant='h6' color='primary'>Performance</Typography>
                <Divider />

                <Grid container justify="space-between" >
                    <Grid xs={2} onClick={() => setAction("otp")}>
                        <Box mt={3} >
                            <Grid container xs={12} justifyContent="space-between" spacing={1}>
                                <Paper style={{ width: '100%', height: '275px' }}>
                                    {loading ? <ChartLoader /> : <>
                                        <Grid style={{ paddingTop: '18px' }}>


                                            <Chart
                                                value1={otpData.on_time ? otpData.on_time : 0}
                                                value2={otpData.non_tech ? otpData.non_tech : 0}
                                                value3={otpData.tech ? otpData.tech : 0}
                                                color1={"#8CDA5C"}
                                                color2={"#FFB300"}
                                                color3={"#FF0202"}
                                                labels={["On Time", "Non Tech", "Tech Delay"]}
                                                labelName={"TDR"}
                                                totalPercentage={0}
                                            />
                                            <div>
                                                <Typography className={classes.quotePercentageText} >{otpPercentage ? otpPercentage : 0}{"%"}</Typography>
                                                <Typography className={classes.centerLabel}>TDR</Typography>
                                            </div>

                                        </Grid>
                                        <Grid container direction='row' >
                                            <Grid xs={4} style={{ textAlign: "center" }}>
                                                <Grid><Typography variant="h6" style={{ color: "#4FC605" }}>{otpData.on_time ? otpData.on_time : 0}</Typography></Grid>
                                                <Grid><Typography gutterBottom={20} variant="body2" style={{ fontSize: '12px' }} >On Time</Typography></Grid>
                                            </Grid>
                                            <Grid xs={4} style={{ textAlign: "center" }}>
                                                <Grid><Typography variant="h6" style={{ color: "#FFB300" }}>{otpData.non_tech ? otpData.non_tech : 0}</Typography></Grid>
                                                <Grid><Typography gutterBottom={20} variant="body2" style={{ fontSize: '12px' }} >Non Tech</Typography></Grid>
                                            </Grid>
                                            <Grid xs={4} style={{ textAlign: "center" }}>
                                                <Grid><Typography variant="h6" style={{ color: "#FF0202" }}>{otpData.tech ? otpData.tech : 0}</Typography></Grid>
                                                <Grid><Typography gutterBottom={20} variant="body2" style={{ fontSize: '12px' }} >Tech Delay</Typography></Grid>
                                            </Grid>
                                        </Grid>
                                    </>
                                    }
                                </Paper>

                            </Grid>
                        </Box>
                    </Grid>
                    <Grid xs={2} onClick={() => setAction("recovered")}>

                        <Box mt={3} >
                            <Grid container xs={12} justifyContent="space-between" spacing={1}>
                                <Paper style={{ width: '100%', height: '275px' }}>
                                    {loading ? <ChartLoader /> : <>
                                        <Grid style={{ paddingTop: '18px' }}>


                                            <Chart
                                                value1={recoveredData.late_in_on_time ? recoveredData.late_in_on_time : 0}
                                                value2={recoveredData.late_in_late_out ? recoveredData.late_in_late_out : 0}
                                                color1={"#8CDA5C"}
                                                color2={"#FFB300"}
                                                labels={["Late In/On time", "Late In/Late Out"]}
                                                labelName={"Recovered"}
                                                totalPercentage={0}
                                            />

                                            <div>
                                                <Typography className={classes.quotePercentageText} >{recoveredPercentage ? recoveredPercentage : 0}{"%"}</Typography>
                                                <Typography className={classes.centerLabel}>Recovered</Typography>
                                            </div>

                                        </Grid>
                                        <Grid container direction='row'>
                                            <Grid xs={6} style={{ textAlign: "center" }}>
                                                <Grid><Typography variant="h6" style={{ color: "#4FC605" }}>{recoveredData.late_in_on_time ? recoveredData.late_in_on_time : 0}</Typography></Grid>
                                                <Grid><Typography gutterBottom={20} variant="body2" style={{ fontSize: '12px' }} >Late In/On time</Typography></Grid>
                                            </Grid>
                                            <Grid xs={6} style={{ textAlign: "center" }}>
                                                <Grid><Typography variant="h6" style={{ color: "#FFB300" }}>{recoveredData.late_in_late_out ? recoveredData.late_in_late_out : 0}</Typography></Grid>
                                                <Grid><Typography gutterBottom={20} variant="body2" style={{ fontSize: '12px' }} >Late In/Late Out</Typography></Grid>
                                            </Grid>
                                        </Grid>
                                    </>
                                    }
                                </Paper>

                            </Grid>
                        </Box>

                    </Grid>
                    <Grid xs={2} onClick={() => setAction("manhrs")}>

                        <Box mt={3} >
                            <Grid container xs={12} justifyContent="space-between" spacing={1}>
                                <Paper style={{ width: '100%', height: '275px' }}>
                                    {loading ? <ChartLoader /> :
                                        <>
                                            <Grid style={{ paddingTop: '18px' }}>

                                                <Chart
                                                    value1={manHoursData.maint_tasks ? parseFloat(manHoursData.maint_tasks) : 0}
                                                    value2={manHoursData.schd_checks ? parseFloat(manHoursData.schd_checks) : 0}
                                                    color1={theme.palette.secondary.main}
                                                    color2={alpha(theme.palette.primary.main, 0.75)}
                                                    labels={["Maint Tasks", "Schd Checks"]}
                                                    labelName={"Man Hrs"}
                                                    totalPercentage={0}
                                                />
                                                <div>
                                                    <Typography style={{ color: '#707070' }} className={classes.quotePercentageText} >{manHoursData.main_figure ? manHoursData.main_figure : 0}</Typography>
                                                    <Typography className={classes.centerLabel}>Man Hrs</Typography>
                                                </div>
                                            </Grid>
                                            <Grid container direction='row' >
                                                <Grid xs={6} style={{ textAlign: "center" }}>
                                                    <Grid><Typography variant="h6" color='secondary' >{manHoursData.schd_checks ? manHoursData.schd_checks : 0}</Typography></Grid>
                                                    <Grid><Typography gutterBottom={20} variant="body2" style={{ fontSize: '12px' }} >Schd Checks</Typography></Grid>
                                                </Grid>
                                                <Grid xs={6} style={{ textAlign: "center" }}>
                                                    <Grid><Typography variant="h6" color='primary' > {manHoursData.maint_tasks ? manHoursData.maint_tasks : 0}</Typography></Grid>
                                                    <Grid><Typography gutterBottom={20} variant="body2" style={{ fontSize: '12px' }} >Maint Tasks</Typography></Grid>
                                                </Grid>
                                            </Grid>
                                        </>}
                                </Paper>

                            </Grid>
                        </Box>

                    </Grid>
                    <Grid xs={5} onClick={() => setAction("chargeable")} >

                        <Box mt={3} >

                            <Paper style={{ width: '100%', height: '270px' }}>
                                <Typography variant='h6' color='primary' style={{ textAlign: 'center', paddingBottom: '10px' }}>Chargeable</Typography>
                                {loading ? <ChartLoader /> : <>
                                    <Grid direction="row" container xs={12} justifyContent="space-between">

                                        <Grid direction="column" container xs={3} className={classes.divider}>
                                            <Grid className={classes.image}>
                                                <img src={TirePressure} height={'40px'} />
                                            </Grid>
                                            <Grid container direction="column" className={classes.topUpGrid}>
                                                <Grid>{chargesData.nitrogen_value ? chargesData.nitrogen_value : "-"}</Grid>
                                                <Grid>Top Ups</Grid>
                                            </Grid>
                                            <Grid container direction="column" className={classes.taxInflation}>
                                                <Grid>{chargesData.changed_wheels ? chargesData.changed_wheels : "-"}</Grid>
                                                <Grid>Whl Changes</Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid direction="column" container xs={3} className={classes.divider}>
                                            <Grid className={classes.image}>
                                                <img src={LadderTruck} height={'40px'} />
                                            </Grid>
                                            <Grid container direction="column" className={classes.topUpGrid}>
                                                <Grid>{chargesData.medium_steps ? chargesData.medium_steps : "-"}</Grid>
                                                <Grid>Medium</Grid>
                                            </Grid>
                                            <Grid container direction="column" className={classes.taxInflation}>
                                                <Grid>{chargesData.large_steps ? chargesData.large_steps : "-"}</Grid>
                                                <Grid>Large</Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid direction="column" container xs={3} className={classes.divider}>
                                            <Grid className={classes.image}>
                                                <img src={BoomLift} height={'40px'} />
                                            </Grid>
                                            <Grid container direction="column" className={classes.topUpGrid}>
                                                <Grid>{chargesData.high_lift_equip_value ? chargesData.high_lift_equip_value : "-"}</Grid>
                                                <Grid>High Lift</Grid>
                                            </Grid>
                                            <Grid container direction="column" className={classes.taxInflation}>
                                                <Grid>{chargesData.gnd_equip_value ? chargesData.gnd_equip_value : "-"}</Grid>
                                                <Grid>APU Access Stand</Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid direction="column" container xs={3} style={{ textAlign: "center" }}>
                                            <Grid className={classes.image}>
                                                <img src={Airplane} height={'40px'} />
                                            </Grid>
                                            <Grid container direction="column" className={classes.topUpGrid}>
                                                <Grid>{chargesData.towing_man_hours ? chargesData.towing_man_hours : "-"}</Grid>
                                                <Grid>Tows</Grid>
                                            </Grid>
                                            <Grid container direction="column" className={classes.taxInflation}>
                                                <Grid>{chargesData.adhoc_events ? chargesData.adhoc_events : "-"}</Grid>
                                                <Grid>ADHOC Events</Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>
                                }
                            </Paper>

                        </Box>



                    </Grid>
                </Grid >
                <Box mt={3}>
                    <Grid >
                        <Typography variant='h6' color='primary'>Detail</Typography>
                        <Divider />
                        <br />
                        <small>Select an indicator above to display the caution conditions</small>
                    </Grid>
                    <br />
                    <Grid container justify="space-between">
                        {action && action === 'otp' && <OTP selectedFilters={selectedFilters} selectedBase={selectedBase} filterData={filterData} startDate={startDate} endDate={endDate} />}
                        {action && action === 'recovered' && <Recovered selectedFilters={selectedFilters} selectedBase={selectedBase} filterData={filterData} startDate={startDate} endDate={endDate} />}
                        {action && action === 'manhrs' && <ManHours selectedFilters={selectedFilters} selectedBase={selectedBase} filterData={filterData} startDate={startDate} endDate={endDate} />}
                        {action && action === 'chargeable' && <Chargeable selectedFilters={selectedFilters} selectedBase={selectedBase} filterData={filterData} startDate={startDate} endDate={endDate} />}
                    </Grid>
                </Box>

            </Box >
        </div >
    )
}
