export const topNavMenuOperations = [
    { name: 'Line Maintenance', redirect: '/org/operations/line_maintenance/work_order', section: '/line_maintenance/work_order' },
    { name: 'Workshop', redirect: '/org/operations/workshop/maintenance', section: '/operations/workshop' },
    { name: 'Flying Spanner', redirect: 'org/operations/flying_spanner/journey', section: '/operations/flying_spanner' },
    { name: 'Vehicles', redirect: '/org/operations/vehicles/vehicle_list', section: '/operations/vehicles' },
    { name: 'Station Checks', redirect: '/org/operations/station_checks', section: '/operations/station_checks' },
    { name: 'Handover', redirect: '/org/operations/handover/history', section: '/operations/handover' },
];

export const topNavMenuPlanning = [
    { name: 'Line', redirect: '/org/planning/line/planned_work_order', section: '/planning/line' },
    { name: 'Workshop', redirect: '/org/planning/workshop/maintenance', section: '/planning/workshop' },
    { name: 'Shifts', redirect: '/org/planning/shifts/shift_plane', section: '/planning/shifts' },
    { name: 'Resource', redirect: '/org/planning/resource/shift_patterns', section: '/planning/resource' },
];

export const topNavMenuDashboards = [
    { name: "Line Maintenance", redirect: "/org/dashboard/performance", section: "/dashboard/line_maintenance" },
    { name: "Workshop", redirect: "/org/dashboard/workshop/maintenance", section: "/dashboard/workshop" },
    { name: "User Compliance", redirect: "/org/dashboard/user_compliance", section: "/dashboard/user_compliance", },
    { name: "Station Compliance", redirect: "/org/dashboard/station/compliance", section: "/dashboard/station" },
];

export const topNavMenuStores = [
    { name: 'Parts', redirect: '/org/stores/parts', section: '/stores/parts' },
    { name: 'Tooling', redirect: '/org/stores/tooling/in_stock', section: '/stores/tooling' },
    { name: 'Form 1 / 8130', redirect: '/org/stores/form1', section: '/stores/form1' },
    { name: 'Expendables', redirect: '/org/stores/expandables/items', section: '/stores/expandables' },
];

export const topNavMenuProcurement = [
    { name: 'Parts', redirect: '/org/procurement/parts/request', section: '/procurement/parts' },
    { name: 'Services', redirect: '/org/procurement/services', section: '/procurement/services' },
    { name: 'Equipment', redirect: '/org/procurement/equipment/requests', section: '/procurement/equipment' },
    { name: 'Kits', redirect: '/org/procurement/kits/kit', section: '/procurement/kits' },
];

export const topNavMenuCompliance = [
    { name: 'User compliance', redirect: '/org/compliance/user_compliance', section: '/compliance/user_compliance' },
    { name: 'Procedures', redirect: '/org/compliance/procedures', section: '/compliance/procedures' },
    { name: 'Read & Signs', redirect: '/org/compliance/read_and_signs', section: '/compliance/read_and_signs' },
    { name: 'Approvals', redirect: '/org/compliance/approvals/approval_types', section: '/compliance/approvals' },
    { name: 'Supplier', redirect: '/org/compliance/suppliers', section: '/compliance/suppliers' },
    { name: 'Admin', redirect: '/org/compliance/admin/training', section: '/compliance/admin' },
];

export const topNavMenuAccounts = [
    { name: 'Line Maintenance', redirect: '/org/accounts/line_maintenance/lineworkorder', section: '/accounts/line_maintenance' },
    { name: "Workshop", redirect: '/org/accounts/workshop/maintenance', section: '/accounts/workshop' },
    { name: "Flying Spanner", redirect: '/org/accounts/flying_spanner/journey', section: '/accounts/flying_spanner' },
    { name: 'ADHOC Invoicing', redirect: '/org/accounts/adhoc_invoicing', section: '/accounts/adhoc_invoicing' },
    { name: 'Usage Report', redirect: '/org/accounts/usage_report', section: '/accounts/usage_report' },
    { name: "Admin", redirect: '/org/accounts/stores', section: '/accounts/stores' },
    { name: 'Admin', redirect: '/org/accounts/admin/currencies', section: '/accounts/admin' },
    // { name: 'Kits', redirect: '/org/accounts/kits', section: '/accounts/kits' },
]

export const topNavMenuAdmin = [
    { name: 'Bases', redirect: '/org/admin/bases', section: '/admin/bases' },
    { name: 'Users', redirect: '/org/admin/users/user_lists', section: '/admin/users' },
    { name: 'Line', redirect: '/org/admin/line/line_customer', section: '/admin/line' },
    { name: 'Workshop', redirect: '/org/admin/workshop/customer', section: '/admin/workshop' },
    { name: 'Stores', redirect: '/org/admin/stores/suppliers', section: '/admin/stores' },
];
