import {
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
} from "@material-ui/core";
import React from 'react';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "370px",
        height: "150px",
        borderRadius:"10px"
    },

    header: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#4D4F5C",
        fontSize:"15px",
        marginTop:"10px"
    },
    footer : {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#4D4F5C",
        marginBottom:"20px",
        fontSize:"15px"
    }


}));

const MessagePopup = (props) => {
    console.log(props)
    const { handleClose, open, topTitle, bottomTitle } = props
    const classes = useStyles();

    return (
        <Dialog
            aria-labelledby="customized-dialog-title"
            onClose={handleClose}
            open={open}
            maxWidth="md"
        >
            <DialogTitle id="customized-dialog-title">
                <Box style={{ display: "flex", justifyContent: "center" }}>
                    <Box display="flex" alignItems="center">
                        <Typography className={classes.header}>
                            {topTitle}
                        </Typography>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent className={classes.wrapper}>
                <img width={"150px"} src={"/loader.gif"} alt={"loading..."} />
            </DialogContent>
            <Typography className={classes.footer}>
                {bottomTitle}
            </Typography>
        </Dialog>
    )
}
export default MessagePopup;