import React, { useContext, useState } from 'react'
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import { Dialog, DialogContent, DialogTitle, FormControlLabel } from "@material-ui/core";
import MROButton from 'components/buttons';
import { Typography } from "@material-ui/core";
import { ToastMessageContext } from "lib/contexts/message_context";
import DateField from 'views/Accounts/Workshop/Maintenance/ManageInvoice/DateField';
import { CommercialServices } from 'lib/services/api/operaitons/WorkShop/TaskLibrary/commercial';
import { getOrganisationId } from 'lib/utils/common_utils';
import MROTextField from 'components/form_components/TextField';


const useStyles = makeStyles((theme) => ({
    formWrapper: {
        background: "#fff",
        width: "100%",
    },
    title: {
        display: "flex",
        justifyContent: "left",
        "& h2": {
            //   color: theme.palette.primary.main,
            fontSize: "40px",
            fontWeight: 40,
        },
    },
    subTitle: {
        fontSize: "16px",
        fontWeight: "bold",
        marginBottom: "20px",
        color: "#4D4F5C",
    },
    card: {
        padding: "0 25px",
    },
    actionButtons: {
        paddingBottom: "33px",
        textAlign: "end",
        marginTop: '40px',
        "& button:not(:last-child)": {
            marginRight: "20px",
        },
    },
    purchaseGrid: {
        paddingTop: "0 !important",
        paddingBottom: "0 !important",
        height: "85px",
    },
    purchaseStock: {
        marginBottom: "20px",
        maxWidth: "274px",
    },
    modal: {
        "& .MuiDialog-paperWidthMd": {
            width: "450px",
        },
    }
}));


function UpdateEstimation(props) {
    const classes = useStyles();
    const { handleClose, currentSelection, date, id } = props
    const [errors, setErrors] = useState({});
    const message = useContext(ToastMessageContext)
    const [loading, setLoading] = useState(false)


    const handleSubmit = async (e) => {

        e.preventDefault();

        const formData = new FormData(e.target);
        let valid = true;

        let error = {
            etd: false,
            reason: false,

        };
        if (formData && formData.get("etd")?.length === 0) {
            error.etd = true;
            valid = false;
        }
        if (formData && formData.get(" reason") === " ") {
            error.reason = true;
            valid = false;
        }
        if (!valid) {

            setErrors(error);
            return;
        }


        formData.append("workshop_order_id", id)
        // formData.append("tat", currentSelection?.tat_days)
        formData.append("organisation_id", getOrganisationId())
        let userDetails = JSON.parse(sessionStorage.getItem("user"));
        formData.append("user_id", userDetails?.id);
        setLoading(true)
        await CommercialServices.reviseEstDeparture(formData).then((response) => {
            if (response.success) {

                handleClose()
                message.showToastMessage({
                    message: "Revised successfully ",
                    variant: "success",
                });
            }
            else {
                message.showToastMessage({
                    message: response.message ? response.message : "Something went wrong. Try again!",
                    variant: "error",
                });
                handleClose()
            }
        }).catch((error) => {
            message.showToastMessage({
                message: "Something went wrong",
                variant: "error",
            }).finally(() => {
                setLoading(false)
            })
        })





    }

    const resetErrorsHandler = (name) => {
        setErrors((e) => ({ ...e, [name]: false }));
    };


    return (
        <div>
            <Dialog
                maxWidth={"md"}
                open={true}
                className={classes.modal}
                onClose={handleClose}
                aria-labelledby="base-station-manage"
            >
                <DialogTitle>
                    <Typography
                        color="primary"
                        variant="subtitle2"
                        className={classes.title}
                    >
                        Revise ETD
                    </Typography>
                </DialogTitle>
                <DialogContent className={classes.card}>
                    <div className={classes.formWrapper}>
                        <form noValidate onSubmit={handleSubmit}>
                            <Grid

                                container
                                spacing={1}
                                alignItems={"center"}
                                justify={"flex-start"}
                            >

                                <Grid className={classes.purchaseGrid} item xs={12}>
                                    <DateField
                                        required
                                        name="etd"
                                        label={"Estimated Departure *"}
                                        InputLabelProps={{ shrink: true }}
                                        error={errors.etd}
                                        helperText={errors.etd ? "*please enter a date" : null}
                                        onChange={(e) => { setErrors({ ...errors, etd: false }); }}
                                        defaultValue={date ? date : ""}

                                    />
                                </Grid>
                                <Grid style={{ paddingBottom: '10px' }} xs={12}>
                                    <MROTextField
                                        name={"reason"}
                                        label={"Reason *"}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{ style: { height: 'max-content', width: "100%" } }}
                                        inputProps={{ maxLength: 100 }}
                                        multiline
                                        //  required
                                        placeholder={"Enter a reason / comment - Max 100 characters"}
                                        error={errors.reason}
                                        helperText={errors.reason ? "*please enter reason" : null}
                                        onChange={(e) => { setErrors({ ...errors, reason: false }); }}
                                        className={classes.inputField}

                                    />
                                </Grid>
                            </Grid>
                            <div className={classes.actionButtons}>
                                <MROButton
                                    //   disabled={loading}
                                    type="button"
                                    variant={"contained"}
                                    onClick={handleClose}
                                >
                                    Cancel
                                </MROButton>
                                <MROButton
                                    loading={loading}
                                    type="submit"
                                    variant={"contained"}
                                    color="primary"
                                >
                                    Complete
                                </MROButton>
                            </div>
                        </form>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default UpdateEstimation