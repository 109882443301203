import React, { useState, useEffect, useRef, useMemo } from "react";

import QRCode from "qrcode.react";

import { useReturnIssuedPartStyles } from "./styles";
import { Typography, Box, Grid } from "@material-ui/core";
import MROButton from "components/buttons";
import { ReturnIssuedPartServices } from "lib/services/api/operaitons/lineMaintenance/workOrder/ReturnIssuedPart";
import { useReactToPrint } from "react-to-print";

function QRCodeStep(props) {
  const { setStep, onClose, busy, allocId } = props;
  const [qrSize, setQRSize] = useState(200)
  const classes = useReturnIssuedPartStyles();
  const [conditionsPartDetails, setConditionsPartDetails] = useState({});
  const [isPrintWindowOpen, setIsPrintWindowOpen] = useState(false);
  const organisation = JSON.parse(sessionStorage.getItem("organisation"));

  const printContainerRef = useRef(null);

  const promiseResolveRef = useRef(null);
  const printHandler = useReactToPrint({
    pageStyle: `@media all {
  .page-break {
    display: none;
     margin: none !important;
   padding:none !important;
   background-color: white !important;
    border-style : hidden!important;
  }
}

@media print {
  html, body {
    
    height: 50% !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact  !important;
    font-size:8px !important;
    margin: none !important;
   padding:none !important;
   box-shadow:none !important;
   background-color: white !important;
   line-height: 0px !important;
   outline:none; !important',
    border-style : hidden!important;
}

@media print {
  .page-break {
    margin: none !important;
     padding:none !important;
     display: block;
     break-before: avoid !important;
     break-inside: avoid !important;
     box-shadow:none !important;
     background-color: white !important;
      border-style : hidden!important;
  }
}
@media print {
  div {
     break-inside: avoid !important;  
  }
}
@page {
  size: auto;
  margin: none !important;
   padding:none !important;
   background-color: white !important;
   break-inside: avoid !important;
}`,
    content: () => printContainerRef.current,
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        promiseResolveRef.current = resolve;
        setIsPrintWindowOpen(true);
      })
    },
    onAfterPrint: () => {
      promiseResolveRef.current = null;
      setIsPrintWindowOpen(false)
    },
  });
  useEffect(() => {
    if (isPrintWindowOpen && promiseResolveRef.current) {
      // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
      promiseResolveRef.current();
    }
  }, [isPrintWindowOpen]);




  const getDetails = async () => {
    try {
      const params = {
        alloc_id: allocId,
      };
      const response = await ReturnIssuedPartServices.getConditionPartDetails(
        params
      );

      setConditionsPartDetails(response);
    } catch (error) {
      console.log(error);
    }
  };
  let descCharacter = 0
  const maxCharacterLength = 20;
  let MRO = ''
  let description = ''

  const owners = organisation?.company_name
  MRO = owners.substring(0, maxCharacterLength)
  description = conditionsPartDetails?.primary_part?.description.substr(0, maxCharacterLength)

  // useMemo(() => {
  //   if (conditionsPartDetails?.primary_part?.description !== undefined)
  //     descCharacter = conditionsPartDetails?.primary_part?.description.length
  // }, [conditionsPartDetails])




  const dummyData = [
    {
      label: "MRO ",
      value: owners.length > maxCharacterLength ? `${MRO}...` : MRO,
    },
    {
      label: "Part #",
      value: conditionsPartDetails?.primary_part?.part_number,
    },
    {
      label: "Batch #",
      value: conditionsPartDetails?.secondary_part?.batch_number,
    },
    {
      label: "Serial #",
      value: conditionsPartDetails?.secondary_part?.serial_number,
    },
    { label: "Receipt Date", value: conditionsPartDetails?.receipt_date },
    {
      label: "Description",
      value: description ? description + "..." : ''
    },
    {
      label: "Owner",
      value: conditionsPartDetails?.secondary_part?.mro_owned
        ? organisation.company_name
        : conditionsPartDetails?.line_customer,
    },
    { label: "Location", value: conditionsPartDetails?.store_location },
    {
      label: "ALOC ID",
      value: conditionsPartDetails?.part_condition?.alloc_id,
    },
    {
      label: "Job #",
      value: conditionsPartDetails?.part_condition?.reportable_type === "WorkshopOrder" ? `${conditionsPartDetails?.order_number}-${conditionsPartDetails?.action}` : conditionsPartDetails?.order_number,
    },
    {
      label: "Shelf-life Exp",
      value: conditionsPartDetails?.secondary_part?.shelf_life_expiry,
    },
    { label: "QTY", value: conditionsPartDetails?.part_condition?.quantity },
  ];

  useEffect(() => {
    getDetails();
  }, []);

  const data = {
    primaryPartId: `${conditionsPartDetails?.primary_part?.id}`,
    secondaryPartId: `${conditionsPartDetails?.secondary_part?.id}`,
    part: conditionsPartDetails?.primary_part?.part_number,
    batch: conditionsPartDetails?.secondary_part?.batch_number,
    serial: conditionsPartDetails?.secondary_part?.serial_number,
    description: conditionsPartDetails?.primary_part?.description,
    receiptDate: conditionsPartDetails?.receipt_date,
    owner: conditionsPartDetails?.secondary_part?.mro_owned
      ? organisation.company_name
      : conditionsPartDetails?.line_customer,
    allocId: conditionsPartDetails?.part_condition?.alloc_id,
    shelfLifeExp: conditionsPartDetails?.secondary_part?.shelf_life_expiry,
    qty: conditionsPartDetails?.part_condition?.quantity,
    location: conditionsPartDetails?.store_location,
    baseStationId: conditionsPartDetails?.part_request?.to_base_station_id,
    status: conditionsPartDetails?.condition,
    return_location_enabled: false,
    Job: conditionsPartDetails?.part_condition?.reportable_type === "WorkshopOrder" ? `${conditionsPartDetails?.order_number}-${conditionsPartDetails?.action}` : conditionsPartDetails?.order_number,
    MRO: organisation?.company_name

  };
  // MRO: conditionsPartDetails?.part_condition?.aircraft_registration,
  return (
    <div>
      {/* printContainer */}
      <Typography
        color="primary"
        className={classes.heading}
        style={{ fontWeight: "normal", marginTop: 16, marginBottom: 26 }}
      >
        Print Part QR Label
      </Typography>
      <Box width="100%" ref={printContainerRef} >
        <div className="page-break">
          <Box
            // width="335px"
            // pl={"25px"}
            // pr={"25px"}
            // pt={"6px"}
            // pb={"35px"}
            boxShadow={isPrintWindowOpen ? " none " : "0px 3px 6px #00000029"}
            borderRadius="7px"
            style={{ backgroundColor: '#fff', marginBottom: '0 !important' }}

          > <Typography
            className={classes.heading}
            style={{ fontWeight: "normal", marginTop: '1px', marginBottom: 0, textAlign: 'center', fontWeight: 600, fontSize: 24 }}
          >SERVICEABLE </Typography>
            <Grid container style={{ backgroundColor: '#fff', marginBottom: '0 !important' }}>
              {dummyData.map((item, idx) => (
                <React.Fragment key={`${item.label} - ${idx}`}>
                  <Grid item xs={4} style={{ paddingLeft: '5px', marginBottom: !isPrintWindowOpen && 3 }}>
                    <Typography color="primary" variant="subtitle1">
                      {item.label}
                    </Typography>
                  </Grid>
                  <Grid item xs={8} style={{ marginBottom: !isPrintWindowOpen && 3 }}>
                    <Typography style={{ fontSize: !isPrintWindowOpen && 16, color: "#4D4F5C" }}>
                      {item.value ?? "-"}
                    </Typography>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid><span style={{ textAlign: 'center', display: 'block ', margin: isPrintWindowOpen && '0px !important', padding: isPrintWindowOpen && '0px !important' }}>
              <QRCode
                // size={200}
                value={JSON.stringify(data)}
                style={{ height: isPrintWindowOpen ? 95 : 200, width: isPrintWindowOpen ? 95 : 200 }}
              //       value={`
              //  {
              //    primaryPartId:           ${conditionsPartDetails?.primary_part?.id}
              //  secondaryPartId:         ${conditionsPartDetails?.secondary_part?.id}
              //  part:                    ${
              //    conditionsPartDetails?.primary_part?.part_number
              //  }
              //  batch:                   ${
              //    conditionsPartDetails?.secondary_part?.batch_number
              //  }
              //  serial:                  ${
              //    conditionsPartDetails?.secondary_part?.serial_number
              //  }
              //  description:             ${
              //    conditionsPartDetails?.primary_part?.description
              //  }
              //  receiptDate:             ${conditionsPartDetails?.receipt_date}
              //  owner:                   ${
              //    conditionsPartDetails?.secondary_part?.mro_owned
              //      ? organisation.company_name
              //      : conditionsPartDetails?.line_customer
              //  }
              //  allocId:                 ${
              //    conditionsPartDetails?.part_condition?.alloc_id
              //  }
              //  shelfLifeExp:            ${
              //    conditionsPartDetails?.secondary_part?.shelf_life_expiry
              //  }
              //  qty:                     ${
              //    conditionsPartDetails?.part_condition?.quantity
              //  }
              //  location:                ${conditionsPartDetails?.store_location}
              //  baseStationId:           ${
              //    conditionsPartDetails?.part_request?.to_base_station_id
              //  }
              //  status:                  ${conditionsPartDetails?.condition}
              //  return_location_enabled: ${false}
              // }
              //   `}

              />
            </span >

          </Box >
        </div >
      </Box >
      {/* */}
      < Box margin="0 auto" mt="46px" display="flex" justifyContent="center" >
        <MROButton
          style={{ marginRight: "20px" }}
          type="button"
          variant={"contained"}
          onClick={() => setStep(2)}
        >
          Cancel
        </MROButton>
        <MROButton
          type="submit"
          variant={"contained"}
          color="primary"
          style={{ background: "#4FC605" }}
          // onClick={() => window.print()}
          onClick={() => {
            printHandler();
            setIsPrintWindowOpen(true);
          }}
        >
          Print
        </MROButton>
      </Box >
    </div >
  );
}

export default QRCodeStep;
