import MaterialTable from "material-table";
import React, { useReducer, useState, useEffect, useContext } from "react";
import tableReducer, { INITIAL_TABLE_STATE } from "reducers/table_reducer";
import tableIcons, { options } from "components/universal/table_attributes";
import TableAction from "actions/table_actions";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useDebounce from "lib/utils/debounce";
import CopyFileIcon from "assets/CopyFiles.svg"
import GreenCopyIcon from "assets/GreenCopyIcon.svg"
import AdditionalData from "./AdditionalData";
import { ToastMessageContext } from "lib/contexts/message_context";
import { IconButton } from "@material-ui/core";
import { WorkshopContext } from "views/Operations/Workshop/WorkshopContext/WorkshopContext";
import alertIcon from 'assets/icons/Alert_icon.png'
const useStyles = makeStyles(() => ({}));

function Parts(props) {
  // const classes = useStyles();
  const { currentClosedDetails, currentPlanningDetails, copyTab } = props
  const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
  const { page, totalCount, pageSize, inProgress, data } = tableState;
  const [action, setAction] = useState(false)
  const message = useContext(ToastMessageContext);
  const { copyPartTabStatus, flag } = useContext(WorkshopContext)
  const [issued, setIssued] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [selectedRow, setSelectedRow] = useState({});
  const debouncedVal = useDebounce(searchText);
  const tableOptions = {
    ...options,
    page: page,
    total: totalCount,
    pageSize: pageSize,
    search: false,
    toolbar: false
  };



  const getList = () => {
    const params = {
      reportable_type: "WorkshopOrder",
      reportable_id: currentClosedDetails.id,
      search_keyword: debouncedVal,
      page: 1 + page,
      count_per_page: pageSize,
      to_issued: issued,
      // ...filters
    };

    TableAction.getList(dispatch, message, params, "workshopRequestPart");
  };

  useEffect(() => {
    getList();
  }, [page, debouncedVal, pageSize, issued]);

  const columns = [
    {
      title: "Action #",
      field: "action_number",
      headerStyle: {
        textAlign: "left",
        paddingLeft: "20px"
      },
      cellStyle: {
        textAlign: "left",
        paddingLeft: "20px"
      },
    },
    {
      title: "Part #",
      field: "part_number",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      title: "Description",
      field: "description",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        textAlign: "left",
        width: "70%",
      },
    },
    {
      title: "Quantity",
      field: "required_quantity",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      title: "Action",
      headerStyle: {
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (rowData) => (
        <div>
          <IconButton
            onClick={() => {
              setAction("View");
              copyPartTabStatus.push(rowData.id)
              setSelectedRow(rowData);

            }}
            disabled={flag && copyPartTabStatus.find((item) => item === rowData.id)}
          >
            {flag && copyPartTabStatus.find((item) => item === rowData.id) ? <img src={GreenCopyIcon} height="25px" style={{ pointerEvents: flag && copyPartTabStatus.find((item) => item === rowData.id) ? "none" : "", }} /> :

              <img src={CopyFileIcon} height="25px" />
            }
          </IconButton>
        </div>
      ),
    },
  ];
  return (
    <div>

      <MaterialTable
        style={{
          boxShadow: "0px 1px 3px #00000033",
          marginTop: "10px",
        }}
        icons={tableIcons}
        title={""}
        columns={columns}
        data={data.data || []}
        isLoading={false}
        options={tableOptions}
        // onRowClick={(e, rowData) => {
        //   selected.push(rowData.id)
        // }

        //   }
        onChangePage={(page) => {
          TableAction.setPage(dispatch, page);
        }}
        onChangeRowsPerPage={(pageSize) => {
          TableAction.setPageSize(dispatch, pageSize);
        }}
        totalCount={totalCount}
        page={page}
      />
      <div style={{ margin: ' 14px 0px 20px 3px' }}>
        <img src={alertIcon} height="15px" /> <span style={{ padding: '6px' }}> Copy Actions first! Parts must be associated to a new Action in the 'To' Order</span>
      </div>
      {action && action === "View" &&
        <AdditionalData handleClose={() => setAction(null)} currentClosedDetails={currentClosedDetails} currentPlanningDetails={currentPlanningDetails} selectedRow={selectedRow} copyTab={copyTab} />}
    </div>
  );
}

export default Parts;
