import React, { useState, useEffect, useContext } from "react";
import MROFullScreenRightDrawer from "components/fullwidth_dialog";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import Typography from "@material-ui/core/Typography";
import { Box, CircularProgress } from "@material-ui/core"
import { CalenderMaintenanceService } from "lib/services/api/operaitons/WorkShop/Storage/CalenderMaintenanceService";
import { ToastMessageContext } from "lib/contexts/message_context";
import FileUpload from "components/form_components/fileUpload";
import _ from "lodash";
import { format24HrDateTime } from "lib/utils/common_utils";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: "3%",
  },
  appBar: {
    backgroundColor: "#fff",
    color: "#000000CC",
    boxShadow: "0px 3px 6px #0000001A",
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  paper: {
    borderRadius: "5px",
    backgroundColor: "#F5F5F5",
    padding: theme.spacing(2),
    boxShadow: "0px 1px 4px #0000001A",
  },
  partValue: {
    fontSize: "14px",
    color: "#4D4F5C",
    padding: "2px",
    display: "flex",
    alignItems: "center",
  },
  partKey: {
    "& p": {
      fontSize: "14px",
      padding: "1px",
    },
  },
  partCard: {
    borderRadius: "8px",
    padding: "17px 24px",
    "& p": {
      fontSize: "14px",
      padding: "5px",
    },
  },
  sec1: {
    borderRadius: "10px",
    backgroundColor: "#fff",
    padding: theme.spacing(5),
    boxShadow: "0px 1px 4px #0000001A",
  },
}));

function ManageInspection(props) {
  const classes = useStyles();
  const { handleClose, getAllActionStages, action_Id, currentSelectedInfo } = props;
  // console.log("  action_Id===========", action_Id)
  const [loading, setLoading] = useState(false)
  const [details, setDetails] = useState({})
  const [subFields, setSubFields] = useState([])
  const message = useContext(ToastMessageContext);
  const getStagesDetails = async () => {
    setLoading(true);

    const params = {
      id: action_Id
    }
    await CalenderMaintenanceService.getStagesDetails(params)
      .then((res) => {
        if (res) {
          setDetails(res.data)
          setSubFields(res.data.sub_fields)
        }

      })
      .catch((err) => {
        message.showToastMessage({
          message: "Something went wrong. Try again!",
          variant: "error",
        });
        setLoading(false);
      });
  };

  useEffect(() => {
    getStagesDetails()
  }, [action_Id])
  return (
    <div>
      <MROFullScreenRightDrawer open={true}>
        <AppBar position="static" className={classes.appBar} elevation={0}>
          <Toolbar>
            <IconButton
              onClick={handleClose}
              edge="start"
              className={classes.backButton}
              color="inherit"
              aria-label="back"
            >
              <BackIcon />
            </IconButton>
            <Typography variant="subtitle2" style={{ fontSize: '20px' }} >Event Information</Typography>
          </Toolbar>
        </AppBar>

        <div className={classes.wrapper}>
          {loading && _.isEmpty(details) ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="700px"
            >
              <CircularProgress color="primary" size={40} />
            </Box>
          ) : (
            <>
              <Grid>
                <Typography
                  color="Primary"
                  varient="h3"
                  style={{ marginBottom: "10px" }}
                >
                  Event
                </Typography>
              </Grid>
              <Grid className={classes.paper}>
                <Box display="flex">
                  <Box flex={50} className={classes.partKey}>
                    <Typography color="Primary">
                      Title
                    </Typography>
                  </Box>
                  <Box flex={50} className={classes.partValue}>
                    <Typography>{details.title ? details.title : "-"}</Typography>
                  </Box>
                  <Box flex={50} className={classes.partValue}></Box>
                  <Box flex={50} className={classes.partValue}></Box>
                </Box>
              </Grid>

              <Grid>
                <Typography
                  color="Primary"
                  varient="h3"
                  style={{ marginBottom: "10px", marginTop: "10px" }}
                >
                  Sub-Fields
                </Typography>
              </Grid>
              <Grid className={classes.paper}>
                <Box display="flex">
                  <Box flex={50} className={classes.partKey}>
                    <Typography color="primary">
                      Sub-Field 1
                    </Typography>
                  </Box>
                  <Box flex={50} className={classes.partValue}>
                    <Typography>{subFields[0] !== undefined ? subFields[0].field_name : "-"}</Typography>
                  </Box>
                  <Box flex={50} className={classes.partKey}>
                    <Typography color="primary" >
                      Sub-Field 6
                    </Typography>
                  </Box>
                  <Box flex={50} className={classes.partValue}>
                    <Typography>{subFields[1] !== undefined ? subFields[1].field_name : "-"}</Typography>
                  </Box>
                </Box>

                <Box display="flex">
                  <Box flex={50} className={classes.partKey}>
                    <Typography color="primary" >
                      Sub-Field 2
                    </Typography>
                  </Box>
                  <Box flex={50} className={classes.partValue}>
                    <Typography>{subFields[2] !== undefined ? subFields[2].field_name : "-"}</Typography>
                  </Box>
                  <Box flex={50} className={classes.partKey}>
                    <Typography color="primary" >
                      Sub-Field 7
                    </Typography>
                  </Box>
                  <Box flex={50} className={classes.partValue}>
                    <Typography>{subFields[3] !== undefined ? subFields[3].field_name : "-"}</Typography>
                  </Box>
                </Box>

                <Box display="flex">
                  <Box flex={50} className={classes.partKey}>
                    <Typography color="primary" >
                      Sub-Field 3
                    </Typography>
                  </Box>
                  <Box flex={50} className={classes.partValue}>
                    <Typography>{subFields[4] !== undefined ? subFields[4].field_name : "-"}</Typography>
                  </Box>
                  <Box flex={50} className={classes.partKey}>
                    <Typography color="primary" >
                      Sub-Field 8
                    </Typography>
                  </Box>
                  <Box flex={50} className={classes.partValue}>
                    <Typography>{subFields[5] !== undefined ? subFields[5].field_name : "-"}</Typography>
                  </Box>
                </Box>
                <Box display="flex">
                  <Box flex={50} className={classes.partKey}>
                    <Typography color="primary" >
                      Sub-Field 4
                    </Typography>
                  </Box>
                  <Box flex={50} className={classes.partValue}>
                    <Typography>{subFields[6] !== undefined ? subFields[6].field_name : "-"}</Typography>
                  </Box>
                  <Box flex={50} className={classes.partKey}>
                    <Typography color="primary" >
                      Sub-Field 9
                    </Typography>
                  </Box>
                  <Box flex={50} className={classes.partValue}>
                    <Typography>{subFields[7] !== undefined ? subFields[7].field_name : "-"}</Typography>
                  </Box>
                </Box>
                <Box display="flex">
                  <Box flex={50} className={classes.partKey}>
                    <Typography color="primary" >
                      Sub-Field 5
                    </Typography>
                  </Box>
                  <Box flex={50} className={classes.partValue}>
                    <Typography>{subFields[8] !== undefined ? subFields[8].field_name : "-"}</Typography>
                  </Box>
                  <Box flex={50} className={classes.partKey}>
                    <Typography color="primary" >
                      Sub-Field 10
                    </Typography>
                  </Box>
                  <Box flex={50} className={classes.partValue}>
                    <Typography>{subFields[9] !== undefined ? subFields[9].field_name : "-"}</Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid>
                <Typography
                  color="primary"
                  varient="h3"
                  style={{ marginBottom: "10px", marginTop: "10px" }}
                >
                  Event Closure
                </Typography>
              </Grid>
              <Grid className={classes.paper}>
                <Box display="flex">
                  <Box flex={50} className={classes.partKey}>
                    <Typography color="primary">
                      Mech
                    </Typography>
                  </Box>
                  <Box flex={50} className={classes.partValue}>
                    <Typography>{details.mech ? details.mech : "-"}</Typography>
                  </Box>
                  <Box flex={50} className={classes.partKey}>
                    <Typography color="primary">
                      Date / Time
                    </Typography>
                  </Box>
                  <Box flex={50} className={classes.partValue}>
                    <Typography>{details.mech_closed ? format24HrDateTime(details.mech_closed) : "-"}</Typography>
                  </Box>
                </Box>

                <Box display="flex">
                  <Box flex={50} className={classes.partKey}>
                    <Typography color="primary">
                      Insp
                    </Typography>
                  </Box>
                  <Box flex={50} className={classes.partValue}>
                    <Typography>{details.insp ? details.insp : "-"}</Typography>
                  </Box>
                  <Box flex={50} className={classes.partKey}>
                    <Typography color="primary">
                      Date / Time
                    </Typography>
                  </Box>
                  <Box flex={50} className={classes.partValue}>
                    <Typography>{details.insp_closed ? format24HrDateTime(details.insp_closed) : "-"}</Typography>
                  </Box>
                </Box>
              </Grid>

              <Box>
                <Typography
                  color="primary"
                  varient="h3"
                  style={{ marginBottom: "10px", marginTop: "10px" }}
                >
                  Tech Records
                </Typography>
                <Grid>
                  <FileUpload
                    defaultValue={
                      details?.part_technical_record !== undefined
                        ? details?.part_technical_record.map((item) => ({
                          filename: item?.file_name,
                          url: item?.file_path,
                          id: item?.id,
                        }))
                        : ""
                    }
                    showBorderOnNoFiles={true}
                    APIUrl={""}
                    multiple={true}
                    name={"maintenance_file"}
                    action={"Upload Document"}
                    uploadButtonView={true}
                  />
                </Grid>
              </Box>
              <Grid>
                <Typography
                  color="primary"
                  varient="h3"
                  style={{ marginBottom: "10px", marginTop: "10px" }}
                >
                  Closure
                </Typography>
              </Grid>
              <Grid className={classes.paper}>
                <Box display="flex">
                  <Box flex={50} className={classes.partKey}>
                    <Typography color="primary" >
                      Closed
                    </Typography>
                  </Box>
                  <Box flex={50} className={classes.partValue}>
                    <Typography>{details.closed ? format24HrDateTime(details.closed) : "-"}</Typography>
                  </Box>
                  <Box flex={50} className={classes.partKey}>
                    <Typography color="primary" >
                      Closed by
                    </Typography>
                  </Box>
                  <Box flex={50} className={classes.partValue}>
                    <Typography>{details.closed_by ? details.closed_by : "-"}</Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid>
                <Typography
                  color="primary"
                  varient="h3"
                  style={{ marginBottom: "10px", marginTop: "10px" }}
                >
                  Event Scheduling
                </Typography>
              </Grid>
              <Grid className={classes.paper}>
                <Box display="flex">
                  <Box flex={50} className={classes.partKey}>
                    <Typography color="primary" >
                      Next Due
                    </Typography>
                  </Box>
                  <Box flex={50} className={classes.partValue}>
                    <Typography>{details.next_due ? format24HrDateTime(details.next_due) : "-"}</Typography>
                  </Box>
                  <Box flex={50} className={classes.partValue}></Box>
                  <Box flex={50} className={classes.partValue}></Box>
                </Box>
              </Grid>
            </>
          )}
        </div>
      </MROFullScreenRightDrawer>
    </div>
  );
}

export default ManageInspection;
