import { Button, ButtonGroup, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import MROTextField from "./form_components/TextField";
import { fixedInputValueTo2DecimalPlaces } from "lib/utils/helperFunctions";

export default function NumericIncrementJobClockings({ setError, state, setState, ...props }) {
    const [val, setVal] = useState(props.value ? parseFloat(props?.value) : 0);
    const handleChange = (action) => () => {
        typeof setError === "function" && setError();
        if (action === "inc") {
            if (parseFloat(val || 0) >= 0)
                if (parseFloat(val) % 0.25 === 0) {
                    let total = parseFloat(val) + 0.25
                    setVal(parseFloat(total).toFixed(2));
                }
                else

                    setVal((parseFloat(val || 0) + 0.25).toFixed(2))
        }
        if (action === "dec") {
            if (parseFloat(val) > 0)
                if (parseFloat(val) % 0.25 === 0) {
                    setVal(parseFloat(val - 0.25))
                } else if (parseFloat(val) <= 0.25) {
                    setVal(0)
                }
                else setVal((parseFloat(val || 0) - 0.25).toFixed(2))
        }
    };

    useEffect(() => {
        setState({
            ...state,
            man_hours: val,
        });
    }, [val]);

    // console.log(props?.val, state, val, val % 0.25, parseFloat(val) % 0.25 === 0);

    // useEffect(() => {
    //     if (state.man_hours > 0) {
    //         setVal(parseFloat(state.man_hours))
    //     }

    // }, [state.man_hours]);

    let inputprops = {
        value: props.value,
    };

    return (
        <>
            <Grid container alignItems="center">
                <Grid item xs={7}>
                    <MROTextField
                        {...props}
                        defaultValue={val}
                        onChange={(e) => {
                            typeof setError === "function" && setError();
                            typeof setVal === "function" && setVal((fixedInputValueTo2DecimalPlaces(e.target.value.replace(/[A-Za-z]/g, ""))));
                        }}
                    />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={4}>
                    <ButtonGroup
                        color="primary"
                        aria-label="outlined primary button group"
                    >
                        <Button
                            style={{ width: "46px", height: "36px" }}
                            onClick={handleChange("dec")}
                        >
                            {" "}
                            <RemoveIcon />
                        </Button>
                        <Button
                            style={{ width: "46px", height: "36px" }}
                            onClick={handleChange("inc")}
                        >
                            {" "}
                            <AddIcon />
                        </Button>
                    </ButtonGroup>
                </Grid>
            </Grid>
        </>
    );
}
