import requestApi from "../../common/boxp_api_client";
import requestrApi from "../../common/boxr_api_client";

//fetch dependencies

const fetchEventTypes = (params) => {
    return requestApi({
        url: '/event_types',
        method: 'GET',
        params
    })
}
const fetchPlannedAircraft = (params) => {
    return requestApi({
        url: '/planned_aircraft',
        method: 'GET',
        params
    })
}
const fetchScopeOfApproval = (params) => {
    return requestApi({
        url: '/scope_of_approval',
        method: 'GET',
        params
    })
}

const fetchPlannedEngineers = (params) => {
    return requestApi({
        url: '/planned_engineers',
        method: 'GET',
        params
    })
}

const getBaseStation = (params) => {
    return requestApi({
        url: '/base-stations',
        method: "GET",
        params
    });
};
function getLineCustomers(params) {
    return requestrApi({
        url: '/line_customers/listing_line_customers',
        method: 'GET',
        params: params
    });
}
const getLMActivityGraph = (params) => {
    return requestApi({
        url: '/lm_event_graph',
        method: 'GET',
        params
    })
}

const CreateLineEvents = (formData) => {
    return requestApi({
        url: '/create_event',
        method: 'POST',
        data: formData
    })
}

const getLMEventDetails = (params) => {
    return requestApi({
        url: '/individual_lm_event_info',
        method: 'GET',
        params
    })
}



const updateLineEvents = (formData) => {
    return requestApi({
        url: '/update_event_info?_method=PUT',
        method: 'POST',
        data: formData
    })
}

const deleteLineEvents = (params) => {
    return requestApi({
        url: `/delete_event_info`,
        method: "DELETE",
        params
    });

}

const getLMSeriesDetails = (params) => {
    return requestApi({
        url: '/event_series_list',
        method: 'GET',
        params
    })
}


const getLMSeriesInfo = (params) => {
    return requestApi({
        url: '/event_series_info',
        method: 'GET',
        params
    })
}



const updateLineEventsSeries = (formData) => {
    return requestApi({
        url: '/update_event_series?_method=PUT',
        method: 'POST',
        data: formData
    })
}

const deleteLineEventsSeries = (params) => {
    return requestApi({
        url: `/delete_event_series`,
        method: "DELETE",
        params
    });

}
const updateUnarchiveSeries = (formData) => {
    return requestApi({
        url: '/unarchive_event_series?_method=PUT',
        method: 'POST',
        data: formData
    })
}

const linkWorkOrderListing = (params) => {
    return requestApi({
        url: '/work_order_listing',
        method: 'GET',
        params
    })
}
const updateLinkUnlinkWorkOrder = (formData) => {
    return requestApi({
        url: '/link_unlink_work_order_to_event?_method=PUT',
        method: 'POST',
        data: formData
    })
}
const PlanningLineMaintenance = {
    fetchEventTypes,
    fetchPlannedAircraft,
    fetchScopeOfApproval,
    fetchPlannedEngineers,
    getLMActivityGraph,
    CreateLineEvents,
    getBaseStation,
    getLineCustomers,
    getLMEventDetails,
    updateLineEvents,
    deleteLineEvents,
    getLMSeriesDetails,
    getLMSeriesInfo,
    updateLineEventsSeries,
    deleteLineEventsSeries,
    updateUnarchiveSeries,
    linkWorkOrderListing,
    updateLinkUnlinkWorkOrder




}

export default PlanningLineMaintenance