import SearchBarWithFilters from "components/search_with_filters";
import React, { useState, useEffect, useContext, useMemo } from "react";
import { MaintenanceServices } from 'lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation';
import { newformatMMDate, getOrganisationId } from "lib/utils/common_utils";
import { ToastMessageContext } from "lib/contexts/message_context";
import { Divider, FormControlLabel, Grid, Menu, FormControl, Button, Box, makeStyles, } from "@material-ui/core";
import PositiveSwitch from "components/form_components/switch";
import _without from "lodash/without";
import ArrowDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import MaterialTable from 'material-table'
import tableIcons, { options } from 'components/universal/table_attributes';
import { StationDashboardServices } from "lib/services/api/dashboard/station_compliance";
import { Visibility as View } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { ICON_COLOUR } from "lib/constants/style_constants";
import StorageInfo from "./InfoPage";
import moment from "moment";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;

export default function ConditionEvents(props) {
    const classes = useStyles()
    const [bases, setBases] = useState([]);
    const [anchorBase, setAnchorBase] = useState(null);
    const [baseButton, setBaseButton] = useState(false)
    const [selectedBase, setSelectedBase] = useState([]);
    const message = useContext(ToastMessageContext);
    const [checkedState, setCheckedState] = useState(new Array(bases.length).fill(false));
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [totalCount, setTotalCount] = useState(0)
    const [action, setAction] = useState(null)
    const [currentSelection, setCurrentSelection] = useState(null)
    const [filterState, setFilterState] = useState({})




    const getBaseStation = async () => {
        const params = {
            organisation_id: getOrganisationId(),
            order_by: 'station'
        }
        MaintenanceServices.getBaseStation(params)
            .then((res) => {
                if (res) {
                    setBases(res.data);
                }

            })
            .catch((err) => {
                message.showToastMessage({
                    message: "Something went wrong. Try again!",
                    variant: "error",
                });

            });
    };



    useEffect(() => {
        getBaseStation()
    }, [])


    const getStoragetData = async () => {
        setLoading(true)
        const params = {
            organisation_id: getOrganisationId(),
            page: page + 1,
            count_per_page: pageSize,
            ...filterState

        }
        selectedBase.forEach((item, index) => params[`base_station_ids[${[index]}]`] = item)
        await StationDashboardServices.getStorageConditionListing(params).then((response) => {
            setData(response.data)
            setTotalCount(response.total_count)
        }).catch((err) => {
            message.showToastMessage({
                message: "Something went wrong. Try again!",
                variant: "error",
            });
        }).finally(() => {
            setLoading(false)
        })
    }
    useEffect(() => {
        getStoragetData()
    }, [page, pageSize, selectedBase, filterState])


    const handleBaseClick = (event) => {
        setAnchorBase(event.currentTarget);
        setBaseButton(!baseButton)
    };
    const filterChangeHandler = (e) => {
        const { name, value, checked } = e.target
        setSelectedBase((prevState) => [...prevState, value])
        if (!checked) {
            setSelectedBase((current) => _without(current, value));
        }

    }
    const ITEM_HEIGHT = 78;


    const handleDateChange = (value) => {
        setPage(0);
        if (value) {
            const start_date = moment(value[0]).format("DD/MM/YYYY");
            const end_date = moment(value[1]).format("DD/MM/YYYY");

            setFilterState((prevState) => ({ ...prevState, start_date, end_date }));
        } else {
            const fData = { ...filterState };
            filterState?.start_date && delete fData.start_date;
            filterState?.end_date && delete fData.end_date;
            setFilterState({ ...fData });
        }
    };


    const tableOptions = {
        ...options,
        page: page,
        total: totalCount,
        pageSize: pageSize,
        search: false,
        sort: false,
        toolbar: false,


    };

    const columns = [
        {
            title: "Event Date",
            field: "event_date",
            headerStyle: {

                textAlign: 'left',
                paddingLeft: '15px'
            },
            cellStyle: {

                textAlign: 'left',
                paddingLeft: '15px'
            }


        }, {
            title: "Base",
            field: "base_station"

        }, {
            title: "Type",
            field: "type",
            width: '30%',
            headerStyle: {
                width: '30%',
                textAlign: 'left'
            },
            cellStyle: {

                width: '30%',
                textAlign: 'left'
            }


        }, {
            title: "Recorded Value",
            field: "value"

        }, {
            title: "Min Limit",
            field: "min_limit"

        }, {
            title: "Max Limit",
            field: "max_limit"

        }, {
            title: "Affected Parts",
            field: "affected_part"

        }, {
            title: "Action",
            field: "",
            render: (rowData) => <IconButton onClick={() => {
                setAction("View")
                setCurrentSelection(rowData)
            }}>
                <View htmlColor={ICON_COLOUR} fontSize="small" />
            </IconButton>

        },



    ]




    return (
        <div className={classes.root}>
            <SearchBarWithFilters noSearch padding={'4px 16px'}>

                <FormControl className={"filter"} style={{ marginLeft: '-11px' }}>
                    <Button variant={anchorBase ? "contained" : "text"} size="small" aria-haspopup="true" onClick={handleBaseClick} endIcon={baseButton ? <ArrowDropUp /> : <ArrowDown />} style={{ fontSize: "14px", backgroundColor: anchorBase ? '#efefef' : "" }}>
                        {selectedBase.length > 0 ? `Base(${selectedBase.length})` : 'Base(All)'}

                    </Button>
                    <Menu
                        id="Base"
                        anchorEl={anchorBase}
                        keepMounted
                        open={Boolean(anchorBase)}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                        transformOrigin={{ vertical: "top", horizontal: "left" }}
                        onClose={() => {
                            setAnchorBase(null)
                            setBaseButton(!baseButton)
                        }}
                        // anchorOrigin={{
                        //     vertical: 'bottom',
                        //     horizontal: 'left',
                        // }}
                        style={{ marginTop: '0px' }}
                        PaperProps={{
                            style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: '39ch',
                                position: 'absolute',
                                top: '260px',
                            },
                        }}
                    >
                        {
                            bases?.map((base, index) => {
                                return <Box>
                                    <Grid container spacing={2}>
                                        <Grid item xs={1}></Grid>
                                        <Grid item xs={8} style={{
                                            display: "flex", alignItems: "center", fontSize: "14px",

                                        }}>
                                            <label>{base.station}</label>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormControlLabel
                                                style={{ alignSelf: "flex-end" }}
                                                control={<PositiveSwitch
                                                    checked={checkedState[index]}
                                                    value={base.id}
                                                    // onChange={(e) => handleChange(e, option.id)}
                                                    onChange={(e) => { filterChangeHandler(e, index) }}
                                                    name={base.station} />}
                                            // name="base"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid><Divider variant="middle" /></Grid>
                                </Box>
                            }

                            )}
                    </Menu>
                </FormControl>
                <FormControl className="filter" style={{ float: 'right' }}>
                    <RangePicker
                        style={{ width: "291px", color: "black" }}
                        format="DD/MM/YYYY"
                        onChange={handleDateChange}
                        allowClear={true}
                    /></FormControl>
            </SearchBarWithFilters>
            <MaterialTable
                style={{ boxShadow: '0px 1px 3px #00000033', paddingRight: '0px' }}
                icons={tableIcons}
                title={''}
                options={tableOptions}
                data={data || []}
                columns={columns}
                isLoading={loading}
                onChangePage={(page) => {
                    setPage(page);
                }}
                onChangeRowsPerPage={(pageSize) => {
                    setPageSize(pageSize);
                }}

                totalCount={totalCount}
                page={page}
            />
            {action && action === "View" && <StorageInfo currentSelection={currentSelection} handleClose={() => {
                setAction(null)
            }} />}


        </div>
    )
}
const useStyles = makeStyles((theme) => ({
    alert: {
        display: "flex",
        justifyContent: "center",
        color: theme.palette.warning.main,
    },
    root: {
        width: "94%",
        margin: "2% 3%",
        '&.MuiStepConnector-root': {
            borderTopWidth: '10px'
        }
    },
}));
