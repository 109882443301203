import React, { useContext, useState, useReducer } from 'react'
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import { Dialog, DialogContent, DialogTitle, Box, CircularProgress } from "@material-ui/core";
import MROTextField from "components/form_components/TextField";
import MROButton from 'components/buttons';
import { Typography } from "@material-ui/core";
import { FormControl } from '@material-ui/core';
import { DatePicker } from "antd";
import moment from 'moment';
import { StationDashboardServices } from 'lib/services/api/dashboard/station_compliance';
import { getOrganisationId } from 'lib/utils/common_utils';
import { downloadCsvFileFromUrlWithBase } from 'lib/utils/helperFunctions';
import { ToastMessageContext } from 'lib/contexts/message_context';


const { RangePicker } = DatePicker;



const useStyles = makeStyles((theme) => ({
    modal: {
        "& .MuiDialog-paperWidthMd": {
            width: "38%",
            zIndex: 2
        }
    },
    formWrapper: {
        background: "#fff",
        width: "100%",
    },
    title: {
        display: "flex",
        justifyContent: "center",
        textAlign: 'center',
        "& h2": {
            //   color: theme.palette.primary.main,
            fontSize: "20px",
            fontWeight: 40,
        },
    },
    subTitle: {
        fontSize: "16px",
        fontWeight: "bold",
        marginBottom: "20px",
        color: "#4D4F5C",
    },
    card: {
        padding: "9px 154px",
    },
    actionButtons: {
        paddingBottom: "33px",
        textAlign: "center",
        "& button:not(:last-child)": {
            marginRight: "20px",
        },
    },
    purchaseGrid: {
        paddingTop: "0 !important",
        paddingBottom: "0 !important",
        height: "85px",
    },
    purchaseStock: {
        marginBottom: "20px",
        maxWidth: "274px",
    },
}));

function DownloadFile(props) {
    const classes = useStyles()
    const { handleClose, selectedBase } = props
    const [value, setValue] = useState([null, null]);
    const [filterData, setFilterData] = useState({});
    const [loading, setLoading] = useState(false)
    const message = useContext(ToastMessageContext)

    const handleDateChange = (value) => {
        setValue(value)
        if (value) {
            const start_date = moment(value[0]).format("YYYY-MM-DD");
            const end_date = moment(value[1]).format("YYYY-MM-DD");

            setFilterData((prevState) => ({ ...prevState, start_date, end_date }));
        } else {
            const fData = { ...filterData };
            filterData?.start_date && delete fData.start_date;
            filterData?.end_date && delete fData.end_date;
            setFilterData({ ...fData });
        }
    };

    // download csv 

    const handleDownloadCSV = async () => {
        setLoading(true)
        const params = {
            organisation_id: getOrganisationId(),
            ...filterData,
        }
        selectedBase.forEach((item, index) => params[`base_station_id[${[index]}]`] = item)
        await StationDashboardServices.getTemperatureAndHumidityCsv(params).then((response) => {
            downloadCsvFileFromUrlWithBase(response.file)
            handleClose()
        }).catch((error) => {
            message.showToastMessage({
                message: "Something went wrong. Try again!",
                variant: "error",
            });
        }).finally(() => {
            setLoading(false)
        })

    }

    const onLoad = () => {
        return (
            <Box
                width="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <CircularProgress color="primary" size={40} />
            </Box>
        );

    }
    return (

        <div>
            <Dialog
                maxWidth={"md"}
                open={true}
                className={classes.modal}
                onClose={handleClose}
                aria-labelledby="download"
            >



                <DialogTitle>
                    <Typography
                        color="primary"
                        className={classes.title}
                    >
                        Download
                    </Typography>
                    <br />

                    <Typography style={{ marginBottom: '20px' }} className={classes.title}>  Select a date range to download historical temperature & humidity data from the filtered / selected Bases</Typography>
                </DialogTitle>
                <DialogContent className={classes.card}>
                    <div className={classes.formWrapper}>
                        <Grid
                            className={classes.purchaseStock}
                            container
                            spacing={1}
                            alignItems={"center"}
                            justify={"flex-start"}
                        >


                            <Grid style={{ marginBottom: '20px' }}>

                                <FormControl className="filter">
                                    <RangePicker
                                        // value={value}
                                        format="DD/MM/YYYY"
                                        onChange={handleDateChange}
                                        allowClear={true}
                                        placement={"bottomLeft"}
                                        popupStyle={{
                                            positionFixed: true,
                                            zIndex: 99999,
                                            overFlow: 'visible'// use this to make the popper position: fixed
                                        }}

                                    />
                                </FormControl></Grid>
                        </Grid>
                        <div className={classes.actionButtons}>
                            <MROButton
                                //   disabled={loading}
                                type="button"
                                variant={"contained"}
                                onClick={handleClose}

                            >
                                Cancel
                            </MROButton>
                            <MROButton
                                //   disabled={loading}
                                loading={loading}
                                type="submit"
                                variant={"contained"}
                                color="primary"
                                onClick={handleDownloadCSV}
                            >
                                Download
                            </MROButton>
                        </div>
                    </div>

                </DialogContent>
            </Dialog>

        </div>
    )
}

export default DownloadFile