// import request from "../../common/api_client";
import requestrApi from "../../common/boxr_api_client";

function getAllTooling(params) {
    return requestrApi({
        url: '/toolings',
        method: 'GET',
        params:params
    });
}
function getToolImageDetails(params) {
    return requestrApi({
        url: '/toolings/image_list',
        method: 'GET',
        params:params
    });
}
function getAllFileList(params) {
    return requestrApi({
        url: '/toolings/file_list',
        method: 'GET',
        params:params
    });
}
function getToolImageHistory(params) {
    return requestrApi({
        url: '/toolings/calibration_certificate_list',
        method: 'GET',
        params:params
    });
}
function fetchDependencies() {
    return requestrApi({
        url: '/toolings/fetch_filter_dependencies',
        method: 'GET',
    });
}
function getToolHistory(params) {
    return requestrApi({
        url: '/toolings/tooling_histories',
        method: 'GET',
        params:params
    });
}
function fetchSampleDocument() {
    return requestrApi({
        url: '/toolings/fetch_sample_document',
        method: 'GET',
    });
}
function downloadTemplate() {
    return requestrApi({
        url: '/primary_parts/fetch_sample_document',
        method: 'GET',
    });
}
function createToolings(formData) {
    return requestrApi({
        url:'/toolings',
        method: 'POST',
        data:formData
    })
}
function createToolingImage(formData) {
    return requestrApi({
        url:'/toolings/create_image',
        method: 'POST',
        data:formData
    })
}
function createToolingFiles(formData) {
    return requestrApi({
        url:'/toolings/create_files',
        method: 'POST',
        data:formData
    })
}
function bulkUpload(formData) {
    return requestrApi({
        url:'/toolings/bulk_upload',
        method: 'POST',
        data:formData
    })
}
function createCalibrationCert(formData) {
    return requestrApi({
        url:'/toolings/create_calibration_certificate',
        method: 'POST',
        data:formData
    })
}
function createImages(formData) {
    return requestrApi({
        url:'/toolings/create_image',
        method: 'POST',
        data:formData
    })
}
function deleteTooling(formData) {
    return requestrApi({
        url:`/toolings/destroy_tooling`,
        method: 'DELETE',
        data:formData
    })
}
function deleteToolImages(formData) {
    return requestrApi({
        url:`/toolings/remove_certificate`,
        method: 'DELETE',
        data:formData
    })
}
function changeStatus(formData){
    return requestrApi({
        url:`/toolings/update_tooling`,
        method: 'PUT',
        data:formData
    })
}
function updateDocument(formData){
    return requestrApi({
        url:`/toolings/update_documents`,
        method: 'PUT',
        data:formData
    })
}
function bulkUploadStock(formData) {
    return requestrApi({
        url:'/primary_parts/bulk_upload',
        method: 'POST',
        data:formData
    })
}

const InStockClientervices = {
    downloadTemplate, bulkUploadStock, getToolHistory,createImages, createCalibrationCert,deleteToolImages,createToolingImage,createToolingFiles,getAllFileList,
    deleteTooling, getToolImageDetails, updateDocument, getToolImageHistory, getAllTooling,createToolings,deleteTooling,fetchDependencies,changeStatus,bulkUpload,fetchSampleDocument
};

export default InStockClientervices;