import React, { useState, useEffect } from 'react'
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import {

    MuiPickersUtilsProvider,
    KeyboardTimePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { IconButton, InputAdornment } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(1, 0),
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: theme.palette.primary.main,
                borderWidth: '1px !important'
            },
            "&:hover fieldset": {
                borderColor: theme.palette.primary.light + "!important",
            },
            '&.Mui-disabled fieldset': {
                borderColor: 'rgba(0, 0, 0, 0.26) !important'
            },
            '&.Mui-error:hover  fieldset': {
                borderColor: '#f44336 !important',
            },
            "& .MuiOutlinedInput-input": {
                padding: "15px",
            },
        },
        "& label": {
            color: theme.palette.primary.main,
        },
        "& .MuiOutlinedInput-input": {
            "&::placeholder": {
                textTransform: "none"
            },
        }
    },
}));
function PlanningTimeField({
    label,
    name,
    renderDate,
    defaultValue,
    variant,
    disabled,
    onlyTime,
    onChange,
    setErrors,
    error,
    format,
    background,
    pointerEvent,
    helperText,
    type
}) {

    const classes = useStyles();
    const [selectedDate, setSelectedDate] = useState(null);
    const outputFormat = "DD/MM/YYYY";
    useEffect(() => {
        if (defaultValue !== "Invalid date") {
            setSelectedDate(
                defaultValue
                    ? moment(defaultValue, onlyTime ? "HH:mm" : "DD/MM/YYYY HH:mm:ss a")
                    : null
            );
        } else {
            setSelectedDate("");
            // setErrors({
            //     ...errors,
            //     [name]: { valid: false }
            // })
        }
    }, [defaultValue]);

    const changeTime = (date) => {
        setSelectedDate(date);
        onChange && onChange(moment(date).format("HH:mm"));
        if (date === null) {
            // setErrors({
            //     ...errors,
            //     [name]: { valid: false }
            // })
        } else {
            // setErrors({
            //     ...errors,
            //     [name]: { valid: true }
            // })
        }
    };

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardTimePicker
                clearable
                className={classes.root}
                fullWidth
                autoOk
                focused
                inputVariant="outlined"
                label={label}
                variant={variant}
                InputProps={{ readOnly: true }}
                ampm={false}
                color={"primary"}
                placeholder={"HH:MM"}
                format={"HH:mm"}
                value={selectedDate && selectedDate}
                keyboardIcon={<AccessTimeIcon />}
                error={error && error}
                helperText={helperText}
                InputAdornmentProps={{ position: "end" }}
                onChange={(date) => {
                    setSelectedDate(date);
                    onChange && onChange(moment(date).format(type === "resource" ? "HH:mm:ss" : "DD/MM/YYYY HH:mm:ss"));
                }}
            />
            <input
                // style={{ display: "none" }}
                type={"hidden"}
                name={name || "date"}
                defaultValue={defaultValue}
                value={selectedDate ? moment(selectedDate).format("HH:mm") : null}
            />
        </MuiPickersUtilsProvider>
    )
}

export default PlanningTimeField