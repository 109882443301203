import AuthClient from '../lib/services/api/auth_services';

export const AUTH_ACTIONS = {
    AUTHENTICATED: 'authenticated_user',
    SET_AUTH_STATE: 'save_user',
    AUTHENTICATION_ERROR: 'authentication_error',
    PASSWORD_CHANGE_SUCCESS: 'password_change_success',
    PASSWORD_CHANGE_ERROR: 'password_change_error',
    TOGGLE_LOADING_STATE: 'toggle_loading_state',
    TOGGLE_MASK: 'toggle_mask_state',
    CLEAR: 'clear',
    SET_ERRORS: 'set_error'
}

export function userSignIn(dispatch, formData) {
    dispatch({ type: AUTH_ACTIONS.TOGGLE_LOADING_STATE });
    AuthClient.login(formData).then((response) => {
        debugger;
        if (response.user) {
            dispatch({ type: AUTH_ACTIONS.AUTHENTICATED, response: response })
            sessionStorage.setItem("authState", JSON.stringify(response));

            debugger;
            window.location.href = '/home';
            // window.location.href = '/auth/two-factor-auth';
        }
        else if (response.errors) {
            dispatch({ type: AUTH_ACTIONS.AUTHENTICATION_ERROR, response: response.errors ? response.errors.join(" ,") : "Something went wrong" })
        }
    }).catch((e) => {
        dispatch({ type: AUTH_ACTIONS.AUTHENTICATION_ERROR, response: e.errors ? e.errors.join(" ,") : "Something went wrong" })
    });
}

export function setUserData(dispatch) {
    const data = JSON.parse(sessionStorage.getItem("authState"));
    const user = JSON.parse(sessionStorage.getItem('user'));
    dispatch({ type: AUTH_ACTIONS.SET_AUTH_STATE, authState: data, user })
}

export function userSignOut(dispatch) {
    async function logout() {
        const orgLogo = sessionStorage.getItem('logo') || "";
        const user = JSON.parse(sessionStorage.getItem('user'));
        if (user && user.email) {
            AuthClient.logout().then(() => {
                sessionStorage.clear();
                orgLogo && sessionStorage.setItem('logo', orgLogo);
            }).catch(() => {
                sessionStorage.clear();
                orgLogo && sessionStorage.setItem('logo', orgLogo);
            });
        }
        else {
            sessionStorage.clear();
            orgLogo && sessionStorage.setItem('logo', orgLogo);
        }
    }
    logout().then(() => {/*Nothing to Do*/ });
}

export function changePassword(dispatch, formData, headers, handleSuccess) {
    dispatch({ type: AUTH_ACTIONS.TOGGLE_LOADING_STATE });
    AuthClient.changePassword(formData, headers).then((res) => {
        if (res.success) {
            dispatch({ type: AUTH_ACTIONS.PASSWORD_CHANGE_SUCCESS, response: res });
            handleSuccess();
        }
        else {
            dispatch({ type: AUTH_ACTIONS.PASSWORD_CHANGE_ERROR, response: res.errors ? res.errors.join(', ') : res.message ? res.message : "Something Went wrong!. Try again." });
        }
    }).catch(() => {
        dispatch({ type: AUTH_ACTIONS.PASSWORD_CHANGE_ERROR });
    })
}

export function setError(dispatch, error) {
    dispatch({ type: AUTH_ACTIONS.SET_ERRORS, error: error });
}

export function changeFieldMask(dispatch) {
    dispatch({ type: AUTH_ACTIONS.TOGGLE_MASK });
}

const AuthActions = {
    userSignIn,
    userSignOut,
    changePassword,
    changeFieldMask,
    setError,
    setUserData
}

export default AuthActions;
