import React, { useEffect, useContext, useState } from 'react'
import { Dialog, DialogContent, DialogTitle, MenuItem, Grid, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import MROButton from 'components/buttons';
import MROTextField from 'components/form_components/TextField';
import { getOrganisationId } from 'lib/utils/common_utils';
import { ToastMessageContext } from 'lib/contexts/message_context';
import QuantityCounter from 'components/quantity_counter';
import { Autocomplete } from '@material-ui/lab';
import WarningIcon from "@material-ui/icons/Warning";
import tableIcons, { options } from "components/universal/table_attributes";
import MaterialTable from 'material-table';
import ShiftServices from 'lib/services/api/planning/Shifts/shift';
import RedCrossIcon from 'assets/Red remove icon.png'




const useStyles = makeStyles(theme => ({
    actionButtons: {
        paddingTop: '20px',
        paddingBottom: '33px',
        textAlign: 'center',
        '& button:not(:last-child)': {
            marginRight: '20px'
        }
    },
    modal: {
        '& .MuiDialog-paperWidthMd': {
            maxWidth: '450px',
        }
    },
    title: {
        fontSize: '18px',
        '& h2': {
            color: theme.palette.primary.main,
            textAlign: 'center'
        }
    },
    formLabel: {

        '& span:last-child': {
            fontSize: '14px',
        }
    }

}));

export default function RejectOverTime(props) {
    const classes = useStyles()
    const { handleClose, approvedData, id, getListing } = props
    const [qty, setQty] = useState();
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false)
    const message = useContext(ToastMessageContext)



    const handleSubmit = async (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)


        let valid = true;
        let error = {};
        for (let pair of formData.entries()) {
            const value = pair[1];
            const key = pair[0];
            // console.table("formdata=====", key, value)
            if (!value) {
                error[key] = true;
                valid = false;
            }
        }

        if (!valid) {
            setErrors(error);
            return;
        }

        formData.append("overtime_request_id", id)
        formData.append("action", "reject")

        setLoading(true)
        await ShiftServices.postOverTimeActionRequest(formData).then((res) => {

            message.showToastMessage({
                message: "Overtime Rejected Successfully",
                variant: "success",
            });

            handleClose()
            getListing()


        }).catch(() => {
            message.showToastMessage({
                message: "Something went wrong. Try again!",
                variant: "error",
            });
            handleClose()
            getListing()
        }).finally(() => {
            setLoading(false)
        })

    }






    return (
        <div>
            <Dialog
                maxWidth={"md"}
                fullWidth={true}
                open={true}
                className={classes.modal}
                onClose={handleClose}
                aria-labelledby="reject-leave"
            >
                <DialogTitle variant="subtitle2" color="secondary"
                    className={classes.title}><span style={{ fontSize: '16px' }}><img src={RedCrossIcon} height={'20px'} style={{ marginRight: '5px' }} /> Reject Overtime</span>
                </DialogTitle>

                <DialogContent className={classes.card}>
                    <form onSubmit={handleSubmit} >
                        <>
                            <Grid container>
                                <Grid xs={1}></Grid>
                                <Grid style={{ paddingBottom: '10px' }} xs={10}>
                                    <MROTextField
                                        name={"reject_reason"}
                                        label={"Reason *"}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{ style: { height: 'max-content', width: "100%" } }}
                                        inputProps={{ maxLength: 150 }}
                                        multiline
                                        //  required
                                        placeholder={"Enter rejection reason / details - Max 150 characters"}
                                        error={errors.reject_reason}
                                        helperText={errors.reject_reason ? "*please enter reject reason" : null}
                                        onChange={(e) => { setErrors({ ...errors, reject_reason: false }); }}
                                        className={classes.inputField}

                                    />
                                </Grid>
                            </Grid>


                            <div className={classes.actionButtons}>
                                <MROButton
                                    //   disabled={loading}
                                    type="button"
                                    variant={"contained"}
                                    onClick={handleClose}
                                >
                                    Cancel
                                </MROButton>
                                <MROButton
                                    loading={loading}
                                    type="submit"
                                    variant={"contained"}
                                    color="primary"

                                >
                                    Complete
                                </MROButton>

                            </div>
                        </>
                    </form>


                </DialogContent>








            </Dialog >




        </div >
    )
}
