import { TABLE_ACTIONS } from "../actions/table_actions";
import CreateReducer from '../lib/utils/create_reducer';

export const INITIAL_TABLE_STATE = {
    page: 0,
    totalCount: 5,
    pageSize: 10,
    searchText: null,
    data: [],
    inProgress: false
}

const tableReducer = CreateReducer(INITIAL_TABLE_STATE, {
    [TABLE_ACTIONS.CHANGE_STATE](state, action) {
        return {
            ...state,
            [action.name]: action.value,
        };
    },
    [TABLE_ACTIONS.SET_RESPONSE](state, action) {
        return {
            ...state,
            data: action.payload.data,
            totalCount: action.payload.totalCount || action.payload.data.length,
            inProgress: false
        }
    },
    [TABLE_ACTIONS.TOGGLE_IN_PROGRESS_STATE](state, action) {
        return {
            ...state,
            inProgress: !!action.payload || !state.inProgress
        }
    },
    [TABLE_ACTIONS.UPDATE_DATA_IN_LIST](state, action) {
        return {
            ...state,
            data: action.payload,
        }
    }
});

export default tableReducer;