import React from "react";

import { Grid, Typography, makeStyles, Box } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  error: {
    borderColor: "red",
    color: "red",
  },
}));

function MROOnSiteOffSite(props) {
  const classes = useStyles();

  const {
    value,
    handleToggle,
    error,
    setError,
    name,
    label,
    disabled,
    label1,
    label2,
  } = props;

  return (
    <Box my="8px">
      <Box display="flex" flexDirection="row" alignItems="center">
        <span>
          <Typography
            style={{ marginRight: 0 }}
            className={error ? classes.error : {}}
          >
            {label}
          </Typography>
        </span>
        <span>
          <Box>
            <ToggleButtonGroup
              name={"physical_check_status"}
              value={value}
              onChange={(e, value) => {
                typeof handleToggle === "function" && handleToggle(value);
                typeof setError === "function" && setError();
              }}
              aria-label="text formatting"
              exclusive
            >
              <ToggleButton
                style={{
                  pointerEvents: disabled ? "none" : "",
                  textTransform: "none",
                }}
                className={error ? classes.error : {}}
                value={label1 ?? "mro_on_site"}
              >
                {label1 ?? "MRO / On-Site"}
              </ToggleButton>
              <ToggleButton
                style={{
                  pointerEvents: disabled ? "none" : "",
                  textTransform: "none",
                }}
                className={error ? classes.error : {}}
                value={label2 ?? "supplier_off_site"}
              >
                {label2 ?? "Supplier / Off-Site"}
              </ToggleButton>
            </ToggleButtonGroup>
            <input style={{ display: "none" }} value={value} name={name} />
          </Box>
        </span>
      </Box>
      {error && (
        <p style={{ color: "red", fontSize: 12 }}>
          Please select the yes or no
        </p>
      )}
    </Box>
  );
}

export default MROOnSiteOffSite;
