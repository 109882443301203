import React, { useContext, useEffect, useReducer, useState, useRef } from "react";
import Dialog from "@material-ui/core/Dialog";

import {
    createStyles,
    Grid,
    IconButton,
    makeStyles,
    Paper,
    Typography,
    CircularProgress, useTheme
} from "@material-ui/core";
import { ToastMessageContext } from "lib/contexts/message_context";
import {
    SortableContainer,
    SortableElement,
    SortableHandle
} from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ReorderIcon from '@material-ui/icons/Reorder';
// import { List, Column } from 'react-virtualized';
import 'react-virtualized/styles.css';
import { MaintenanceServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation";
import LoadingIndicator from "components/universal/loading_indicator";
import MROButton from "components/buttons";


const useStyles = makeStyles((theme) =>
    createStyles({
        modal: {
            "& .MuiDialog-paperWidthSm": {
                minWidth: "900px",
                height: "max-content"
            },
        },
        container: {
            minWidth: "600px",
            padding: "78px 40px",
            position: "relative",
            display: "flex",
            flexDirection: "column",

        },

        popupTitle: {
            fontSize: "20px",
            lineHeight: "28px",
            position: "absolute",
            top: "19px",
            bottom: "15px",
            left: "25px",
        },
        divider: {
            borderBottom: `1px solid ${theme.palette.primary.main}`,
        },
        tableTitle: {
            fontSize: "16px",
            lineHeight: "22px",
            color: "#4D4F5C",
            fontWeight: "bold",
            marginTop: "19px",
            marginBottom: "16px",
        },
        tableCell: {
            height: '32px',
            justifyContent: "space-between",
            whiteSpace: "normal",
            wordWrap: "break-word",

        },
        actionButtons: {
            paddingTop: '65px',
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
        },
    })
);

export default function ReorderAction(props) {

    const theme = useTheme()

    const { handleClose, workshop_id, action_type, getListing } = props
    const classes = useStyles();
    const message = useContext(ToastMessageContext);
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const ref = useRef()






    useEffect(() => {
        getAllActions();
    }, [workshop_id]);

    const getAllActions = async () => {
        setLoading(true);
        const params = {
            workshop_order_id: workshop_id,
            action_type: action_type,

        };
        await MaintenanceServices.getAllActions(params)
            .then((res) => {
                if (res) {

                    setData(res.data)
                }
                setLoading(false);
            })
            .catch((err) => {
                message.showToastMessage({
                    message: "Something went wrong. Try again!",
                    variant: "error",
                });
                setLoading(false);
            });
    };


    const handleSortEnd = ({ oldIndex, newIndex }) => {
        if (newIndex > data.length) {
            console.log("index=====", newIndex, data.length)
        }
        else {
            const fromInspection = data[oldIndex].action_number
            const toInspection = data[newIndex].action_number
            setData(data => arrayMoveImmutable(data, oldIndex, newIndex))
            const formData = {
                workshop_order_id: workshop_id,
                action_type: action_type,
                from_position: fromInspection.slice(1),
                to_position: toInspection.slice(1)
            }

            MaintenanceServices.updateReorderFunctions(formData).then((res) => {
                getAllActions()
            }).catch(() => {
                message.showToastMessage({
                    message: "Something went wrong. Try again!",
                    variant: "error",
                });
            })
        }

    };

    const handleSortStart = ({ node }) => {

        const tds = document.getElementsByClassName("SortableHelper")[0].childNodes;
        node.childNodes.forEach(
            (node, idx) => (tds[idx].style.width = `${node.offsetWidth}px`)
        );
    };
    const SortableTableBody = SortableContainer(({ children }) => (
        <tbody>{children}</tbody>
    ));
    const SortableIcon = SortableHandle(({ tabIndex }) => (
        <div tabIndex={tabIndex}>
            <ReorderIcon htmlColor={`${theme.palette.secondary.main}`} />
        </div>
    ));
    const DragHandle = SortableHandle(({ action_number, title }) => <List >
        <ListItem>
            <ListItemText style={{}}>


                <Grid container>
                    <Grid item xs={1}> <Typography style={{ cursor: 'move' }}>  <SortableIcon /></Typography></Grid>
                    <Grid item xs={2}> <Typography style={{ fontSize: '14px', paddingLeft: '15px' }}>{action_number}</Typography></Grid>
                    <Grid item xs={9}> <Typography style={{ fontSize: '14px' }} >{title}</Typography></Grid>


                </Grid>




            </ListItemText>
        </ListItem>
    </List >);

    const SortableRow = SortableElement(({ action_number
        , title }) => (
        <div style={{ minWidth: '813px', borderBottom: '2px solid #E2E2E3', borderRadius: '0px', height: '50px' }} >
            <DragHandle action_number={action_number} title={title} />

        </div>
    ));







    return (
        <div>
            <Dialog
                open={true}
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                className={classes.modal}
            >

                <Typography
                    variant="body1"
                    color="primary"
                    style={{ marginBottom: "20px" }}
                    className={classes.popupTitle}
                >
                    Reorder Actions
                </Typography>



                <div className={classes.container}>
                    <Paper>


                        <List style={{ borderRadius: '0px', borderBottom: '2px solid #E2E2E3', height: '50px' }}  >
                            <ListItem>
                                <ListItemText >
                                    <Grid container>
                                        <Grid item xs={1}> {" "}</Grid>
                                        <Grid item xs={2} style={{ fontWeight: 600, fontSize: '14px' }}>Action#</Grid>
                                        <Grid item xs={9} style={{ fontWeight: 600, fontSize: '14px' }}>Title</Grid>


                                    </Grid>
                                </ListItemText>
                            </ListItem>
                        </List>

                        {data.length > 0 && !loading ? <div id="scrollableDiv" style={{ overflow: 'scroll !important' }}>


                            <SortableTableBody
                                ref={ref.current}
                                helperClass="SortableHelper"
                                onSortEnd={handleSortEnd}
                                onSortStart={handleSortStart}
                                useDragHandle
                            >
                                {data.map((row, ix) => (
                                    <SortableRow key={`row-${ix}`} index={ix} {...row} />
                                ))}
                            </SortableTableBody>


                        </div> : <Paper style={{
                            borderRadius: '0px',
                            minWidth: '650px',
                            textAlign: "center",
                            height: "50px",
                            paddingTop: '23px',
                            display: "flex",
                            /* padding: 20px; */
                            justifyContent: "center",
                            paddingTop: "13px"
                        }}> {loading ? <LoadingIndicator size={20} /> : "No Actions Found"}</Paper>}
                    </Paper>
                    <Grid item className={classes.actionButtons}>
                        <MROButton
                            onClick={() => {
                                handleClose()
                                getListing()
                            }}
                            variant={"contained"}
                            color="primary"

                        >
                            Complete
                        </MROButton>
                    </Grid>

                </div>


            </Dialog>
        </div>
    )
}
