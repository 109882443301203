import { Box, Card, CardContent, CardHeader, Grid, MenuItem, Typography } from '@material-ui/core'
import React, { useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MROButton from '../../../../components/buttons'
import ManageDialog from '../../../../components/form_components/manage_dialog'

export default function ViewPrintUSLabel(props) {

    const { part_off, part_on, batch, description, serial_on, serial_off, removal_location, removal_reason } = props.currentSelection;
    const { data } = props;
    const printRef = useRef()

    const handlePrint = () => window.print()

    const classes = useStyles();
    return (
        <>

            <ManageDialog ref={printRef} noPadding maxWidth="sm" handleClose={props.handlePrintClose} handleSubmit={() => { }}>
                <Grid >
                    <Box width="100%" mb={4}>
                        <Typography variant="subtitle2" color="primary">
                            Unserviceable Label
                    </Typography>
                        <Box pt={2.5}>
                            <Card>
                                <Box style={{ backgroundColor: '#00da00', padding: 10, width: '100%' }}>
                                    <Typography variant="subtitle2" style={{ color: '#fff' }}>SERVICEABLE PART DETAILS</Typography>
                                </Box>
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item xs={4}>
                                            <Box>
                                                <div className={classes.items}>Part #</div>
                                                <div className={classes.items}>Batch #</div>
                                                <div className={classes.items}>Description #</div>
                                                <div className={classes.items}>Serial #</div>
                                                <div className={classes.items}>Work Order #</div>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Box>
                                                <div className={classes.items}>{part_on || '-'}</div>
                                                <div className={classes.items}>{batch || '-'}</div>
                                                <div className={classes.items}>{description || '-'}</div>
                                                <div className={classes.items}>{serial_on || '-'}</div>
                                                <div className={classes.items}>{data?.work_order_number || '-'}</div>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box pt={2.5}>
                            <Card>
                                <Box style={{ backgroundColor: '#ec0000', padding: 10, width: '100%' }}>
                                    <Typography variant="subtitle2" style={{ color: '#fff' }}>UNSERVICEABLE PART DETAILS</Typography>
                                </Box>
                                <CardContent>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            <Box>
                                                <div className={classes.items}>Part # off</div>
                                                <div className={classes.items}>Serial # off</div>
                                                <div className={classes.items}>A/C Registration</div>
                                                <div className={classes.items}>Removal Location</div>
                                                <div className={classes.items}>Comments</div>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Box>
                                                <div className={classes.items}>{part_off || '-'}</div>
                                                <div className={classes.items}>{serial_off || '-'}</div>
                                                <div className={classes.items}>{data?.aircraft_registration || '-'}</div>
                                                <div className={classes.items}>{removal_location || '-'}</div>
                                                <div className={classes.items}>{removal_reason || '-'}</div>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box display="flex" position="relative" pb={5}>
                            <Box display="flex" width="100%" justifyContent="flex-end" pt={2} px={3} position="absolute" right="10">
                                <MROButton type="button" variant={"contained"} color="primary" onClick={handlePrint}>Print</MROButton>
                             &nbsp; &nbsp; &nbsp;
                            <MROButton type="button" variant={"contained"} onClick={props.handlePrintClose}>Cancel</MROButton>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </ManageDialog>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    items: {
        padding: theme.spacing(1)
    }
}))