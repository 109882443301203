import MaterialTable from "material-table";
import React, { useReducer, useState, useEffect, useContext } from "react";
import tableReducer, { INITIAL_TABLE_STATE } from "reducers/table_reducer";
import tableIcons, { options } from "components/universal/table_attributes";
import TableAction from "actions/table_actions";
import { IconButton } from "@material-ui/core";
import AdditionalData from "./AdditionalData";
import useDebounce from "lib/utils/debounce";
import { ToastMessageContext } from "lib/contexts/message_context";
import CopyFileIcon from "assets/CopyFiles.svg"
import GreenCopyIcon from "assets/GreenCopyIcon.svg"
import ViewIcon from "@material-ui/icons/Visibility";
import { ICON_COLOUR } from "lib/constants/style_constants";
import { MaintenanceServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation";
import { WorkshopContext } from "views/Operations/Workshop/WorkshopContext/WorkshopContext";
import alertIcon from 'assets/icons/Alert_icon.png'
import TechFilesPopup from "./TechFilesPopup";


function Services(props) {

  const { currentClosedDetails, currentPlanningDetails, copyTab } = props
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [totalCount, setTotalCount] = useState(0)
  const [action, setAction] = useState(false)
  const message = useContext(ToastMessageContext);
  const { copyServiceTabStatus, serviceStatus } = useContext(WorkshopContext)
  const debouncedVal = useDebounce(searchText);
  const [selectedRow, setSelectedRow] = useState({});
  const [isTechFiles, setIsTechFiles] = useState(false)
  const [currentSelectedRow, setCurrentSelectedRow] = useState({})
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  const getServices = async () => {

    const params = {

      workshop_order_id: currentClosedDetails?.id,
      search: debouncedVal,
      page: 1 + page,
      limit: pageSize,
    }

    setLoading(true)
    await MaintenanceServices.getTemlateServiceListing(params).then((res) => {
      setData(res.data)
      setTotalCount(res.total_count)
    }).catch(() => {
      message.showToastMessage({
        message: "Something went wrong. Try again!",
        variant: "error",
      });
    }).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    getServices();
  }, [page, pageSize, debouncedVal]);


  const tableOptions = {
    ...options,
    page: page,
    total: totalCount,
    pageSize: pageSize,
    search: false,
    toolbar: false
  };

  const columns = [
    {
      title: "Action #",
      field: "action_number",
      headerStyle: {
        textAlign: "left",
        paddingLeft: "20px"
      },
      cellStyle: {
        textAlign: "left",
        paddingLeft: "20px"
      },
    },
    {
      title: "Category",
      field: "category",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      title: "Type",
      field: "type",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      title: "Description",
      field: "description",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        textAlign: "left",
        width: "30%",
      },
    },
    {
      title: "Location",
      field: "location",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        textAlign: "left",
      },

    },

    {
      title: "Tech Data",
      // field: "tech_data",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (rowData) => (
        <div>
          <IconButton>
            <ViewIcon fontSize="small" style={{ color: ICON_COLOUR }} onClick={() => {
              setIsTechFiles(true)
              setAction("view_files")
              setCurrentSelectedRow(rowData)
            }}
            />
          </IconButton>

        </div>

      )
    },
    {
      title: "Action",
      headerStyle: {
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (rowData) => (
        <div>
          <IconButton
            onClick={() => {
              setAction("View");
              setSelectedRow(rowData);
              copyServiceTabStatus.push(rowData.id)
            }}
            disabled={serviceStatus && copyServiceTabStatus.find((item) => item === rowData.id)}
          >
            {serviceStatus && copyServiceTabStatus.find((item) => item === rowData.id) ? <img src={GreenCopyIcon} height="25px" style={{ pointerEvents: serviceStatus && copyServiceTabStatus.find((item) => item === rowData.id) ? "none" : "" }} /> :

              <img src={CopyFileIcon} height="25px" />
            }
          </IconButton>
        </div>
      ),
    },
  ];
  return (
    <div>

      <MaterialTable
        style={{
          boxShadow: "0px 1px 3px #00000033",
          marginTop: "10px",
        }}
        icons={tableIcons}
        title={""}
        columns={columns}
        data={data || []}
        isLoading={loading}
        options={tableOptions}
        onChangePage={(page) => {
          setPage(page);
        }}
        onChangeRowsPerPage={(pageSize) => {
          setPageSize(pageSize);
        }}
        totalCount={totalCount}
        page={page}
      />
      <ul style={{ listStyleType: 'none', margin: ' 14px 0px 20px -33px' }}>
        <li style={{ marginBottom: '6px' }}>
          <img src={alertIcon} height="15px" /> <span style={{ padding: '6px' }}> Copy Actions first! Parts must be associated to a new Action in the 'To' Order</span> </li>
        <li style={{ marginBottom: '6px' }}>
          <img src={alertIcon} height="15px" /> <span style={{ padding: '6px' }}> Tech Data will be copied. Check for correct revision and update as necessary in the 'To' Order after copying</span> </li>
        <li style={{ marginBottom: '6px' }}>
          <img src={alertIcon} height="15px" /> <span style={{ padding: '6px' }}> Supplier Site services must have a Part # and Serial # added in the Service Request in the 'To' Order after copying</span></li>
      </ul >
      {action && action === "View" && <AdditionalData handleClose={() => setAction(null)} currentClosedDetails={currentClosedDetails} currentPlanningDetails={currentPlanningDetails} selectedRow={selectedRow} copyTab={copyTab} />
      }

      {
        action === 'view_files' && (
          <TechFilesPopup
            handleClose={() => setAction(null)}
            currentSelectedRow={currentSelectedRow}
            open={isTechFiles}
          />
        )
      }
    </div >
  );
}

export default Services;
