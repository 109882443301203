import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "lib/utils/common_utils";
import { IconButton, Typography } from "@material-ui/core";

import pdfLogo from "../../../../assets/pdf.png";
import jpgLogo from "../../../../assets/jpg.png";

import PartsClientServices from "../../../../lib/services/api/stores/tooling/parts_client";
import { BASE_API_URL_BOXR } from "lib/constants";

const useStyles = makeStyles((theme) => ({
  fileContainer: {
    width: "640px",
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "15px",
    display: "flex",
    alignItems: "center",
    padding: "10px",
    minHeight: "132px",
    overflowX: "scroll",
  },

  filePreviewContainer: {
    maxWidth: "130px",
    marginRight: "30px",
  },

  logo: {
    height: "76px",
  },

  certDialog: {
    "& .MuiDialog-paper": {
      boxShadow: "0px 3px 6px #00000029",
      position: "absolute",
      left: "calc(50% - 250px)",
    },
  },
}));

export default function ReleaseCertPopup(props) {
  const classes = useStyles();

  const { open, dialogCloseHandler, selectedSecondaryPartId, title, msds } =
    props;

  const [files, setFiles] = useState([]);

  const handleClose = () => {
    dialogCloseHandler();
  };

  useEffect(() => {
    if (msds) {
      setFiles(msds);
      return;
    }
    if (selectedSecondaryPartId) {
      PartsClientServices.fetchMaterialCertificates(selectedSecondaryPartId)
        .then((res) => setFiles(res.data))
        .catch((err) => console.log(err));
    }
  }, [selectedSecondaryPartId, msds]);

  const handleClick = (item) => {
    window.open(item.url)
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="755px"
        style={{
          minHeight: "256",
          // left: "20%",
          boxShadow: "0px 3px 6px #00000029",
        }}
        className={classes.certDialog}
      >
        <div style={{ padding: "20px", overflow: "hidden" }}>
          {title && (
            <Typography
              variant="body1"
              style={{ marginBottom: "10px", color: "#4D4F5C" }}
            >
              {title}
            </Typography>
          )}
          <div className={classes.fileContainer}>
            {files.length === 0 ? <Typography variant="subtitle2">No files found</Typography> : files.map((item) => {
              const fileType = item.file_name.split(".")[1];

              let logo = pdfLogo;
              if (fileType === "jpg") {
                logo = jpgLogo;
              }

              return (
                <a
                  href={msds ? item.url : `${item.url}`}
                  target="_blank"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                    <div key={item.id} className={classes.filePreviewContainer} >
                      <img src={logo} alt="file-logo" className={classes.logo} />
                      <Typography
                        variant="body1"
                        noWrap
                        style={{ marginTop: "10px", color: "#4D4F5C" }}
                      >
                        {item.file_name}
                      </Typography>
                    </div>
                </a>
              );
            })}
          </div>
        </div>
      </Dialog>
    </div>
  );
}
