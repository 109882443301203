import { BASE_API_URL_BOXR } from "lib/constants";
import { BASE_API_URL_BOXP } from "lib/constants";
// import { useEffect } from "react";
import { validateCommaSeparatedEmail, validateEmail } from "./common_utils";

export const downloadFileFromUrl = (fileUrl) => {
  const link = document.createElement("a");
  link.href = `${BASE_API_URL_BOXR}${fileUrl}`;
  document.body.append(link);
  link.click();
  document.body.removeChild(link);
};
export const downloadCsvFileFromUrl = (fileUrl) => {
  const link = document.createElement("a");
  link.href = `${BASE_API_URL_BOXP}${fileUrl}`;
  document.body.append(link);
  link.click();
  document.body.removeChild(link);
};
export const downloadCsvFileFromUrlWithBase = (fileUrl) => {
  const link = document.createElement("a");
  link.href = `${BASE_API_URL_BOXP}${fileUrl}`;
  document.body.append(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadPdfFileFromUrl = (fileUrl) => {
  const link = document.createElement("a");
  link.href = `${fileUrl}`;
  document.body.append(link);
  link.click();
  document.body.removeChild(link);
};

export const showHyphenIfValueNull = (prop) => (prop ? prop : "-");

const addHyphenToNullFieldsInObj = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null || obj[key] === "") {
      obj[key] = "-";
    }
  });
  return obj;
};

export const addHyphenToNullFields = (arr = []) => {
  return arr.map((el) => addHyphenToNullFieldsInObj(el));
};

let userDetail = JSON.parse(sessionStorage.getItem("user"));
export const userDetails = {
  userId: userDetail?.id,
  userStamp: userDetail?.stamp_number,
};

export const fixedInputValueTo2DecimalPlaces = function (value) {
  if (typeof value !== "string") return;
  return value.indexOf(".") >= 0
    ? value.substr(0, value.indexOf(".")) + value.substr(value.indexOf("."), 3)
    : value;
};
export const fixedInputValueTo3DecimalPlaces = function (value) {
  // if (typeof value !== "string") return;
  return value.indexOf(".") >= 0
    ? value.substr(0, value.indexOf(".")) + value.substr(value.indexOf("."), 4)
    : value;
};

export const validateFormData = (
  formData,
  optionalFields = [],
  email = "",
  dateTime = ""
) => {
  let valid = true;
  let error = {};

  for (const pair of formData.entries()) {
    const key = pair[0];
    const value = pair[1];

    // console.log("value is===================", optionalFields)

    if (value === "select" || value === "Search") {
      error[key] = true;
      valid = false;
    }

    if (dateTime != "" && key === dateTime) {
      error[key] = true;
      valid = false;
    }

    if (
      email !== "" &&
      key === email &&
      !validateCommaSeparatedEmail(formData.get(email).trim())
    ) {
      error[key] = true;
      valid = false;
    }

    if (value === "" && !optionalFields.includes(key)) {
      error[key] = true;
      valid = false;
    }
  }

  return { error, valid };
};

export const removeSecFromDateTime = (date) => {
  return date?.split(":").slice(0, -1).join(":");
};
