import React from "react"

const Defects = ({ fill }) => (
  <svg id="XMLID_248_" xmlns="http://www.w3.org/2000/svg" width="125" height="18" viewBox="0 0 135 135">
    <g id="XMLID_1388_">
      <path id="XMLID_1393_" d="M151.487,254.738,162.824,243.4l17.6,17.6a2.637,2.637,0,0,0,3.729,0l6.785-6.785a2.637,2.637,0,0,0,.51-3.011l-11.865-24.562,1.985-1.985a18.084,18.084,0,0,0,4.686-17.331,2.636,2.636,0,0,0-1.879-1.879,18.084,18.084,0,0,0-17.332,4.686l-1.985,1.985-24.552-11.854a2.636,2.636,0,0,0-3.011.51l-6.785,6.785a2.637,2.637,0,0,0,0,3.729l17.589,17.59-11.337,11.337-7.611-2.653a2.637,2.637,0,0,0-2.732.625l-5.866,5.866a2.637,2.637,0,0,0,0,3.729l23.166,23.166a2.637,2.637,0,0,0,3.729,0l5.866-5.866a2.637,2.637,0,0,0,.625-2.732Zm34.4-2.927-3.6,3.6-15.735-15.735,9.081-9.081Zm-4.422-41.573a12.8,12.8,0,0,1-3.623,10.69l-30.728,30.727-9.329,2.262,2.262-9.329,13.848-13.848,16.88-16.88A12.8,12.8,0,0,1,181.462,210.238Zm-45.159-.816,3.6-3.6,21.21,10.24-9.081,9.081Zm-7.125,33.663,5.353,1.865-1.786,7.364-6.4-6.4Zm16.606,22.269-6.4-6.4,7.364-1.786,1.865,5.353Z" transform="translate(-88.345 -147.266)" fill={fill} />
      <path id="XMLID_1421_" d="M132.363,11.6h-11.6V8.438a8.438,8.438,0,0,0-16.875,0V11.6H75.938V8.438a8.438,8.438,0,0,0-16.875,0V11.6H31.113V8.438a8.438,8.438,0,0,0-16.875,0V11.6H2.637A2.637,2.637,0,0,0,0,14.238V132.363A2.637,2.637,0,0,0,2.637,135H113.115a2.637,2.637,0,0,0,1.864-.772l19.248-19.248a2.637,2.637,0,0,0,.772-1.864V14.238A2.637,2.637,0,0,0,132.363,11.6Zm-23.2-3.164a3.164,3.164,0,0,1,6.328,0v11.6a3.164,3.164,0,0,1-6.328,0Zm-44.824,0a3.164,3.164,0,0,1,6.328,0v11.6a3.164,3.164,0,0,1-6.328,0Zm-44.824,0a3.164,3.164,0,0,1,6.328,0v11.6a3.164,3.164,0,0,1-6.328,0ZM5.273,46.406H55.635a2.637,2.637,0,0,0,0-5.273H5.273V16.875h8.965v3.164a8.438,8.438,0,0,0,16.875,0V16.875H59.063v3.164a8.438,8.438,0,0,0,16.875,0V16.875h27.949v3.164a8.438,8.438,0,0,0,16.875,0V16.875h8.965V41.133H79.365a2.637,2.637,0,0,0,0,5.273h50.361v64.072H113.115a2.637,2.637,0,0,0-2.637,2.637v16.611H5.273ZM126,115.752,115.752,126V115.752Z" fill={fill} />
      <path id="XMLID_1426_" d="M72.684,313.236H62.137a2.637,2.637,0,0,0,0,5.273H72.684a2.637,2.637,0,0,0,0-5.273Z" transform="translate(-43.812 -230.645)" fill={fill} />
      <path id="XMLID_1427_" d="M395.137,318.51h10.547a2.637,2.637,0,0,0,0-5.273H395.137a2.637,2.637,0,0,0,0,5.273Z" transform="translate(-289.009 -230.645)" fill={fill} />
      <path id="XMLID_1428_" d="M248.637,161.273a2.638,2.638,0,1,0-1.864-.773A2.656,2.656,0,0,0,248.637,161.273Z" transform="translate(-181.137 -114.867)" fill={fill} />
    </g>
  </svg>
)
export default Defects
