import React from "react";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  makeStyles,
} from "@material-ui/core";
import MROButton from "components/buttons";

function PopupDialog(props) {
  const {
    open,
    handleClose,
    children,
    handleSubmit,
    isLoading,
    width,
    centerActionBtns,
    btnTitle,
    noActionBtns,
    btnLeft,
    bgColor,
    color,
    leftStartIcon,
    rightStartIcon,
    submit,
    hideYesButton,
    notShowButton
  } = props;

  const classes = useStyles(props);

  const submitHandler = (e) => {
    e.preventDefault();
    typeof handleSubmit === "function" && handleSubmit(e);
  };

  return (
    <Dialog
      className={classes.dialog}
      open={!!open}
      maxWidth={"lg"}
      onClose={handleClose}
    >
      <DialogContent style={{ width: width ?? "fit-content", overflow: "scroll" }}>
        <form onSubmit={submitHandler} noValidate>
          {children}
          {!noActionBtns && <Box
            width="100%"
            display="flex"
            justifyContent={centerActionBtns ? "center" : "flex-end"}
            mt="30px"
          >
            {!notShowButton && <MROButton
              type={submit ?? "button"}
              variant={"contained"}
              onClick={handleClose}
              startIcon={leftStartIcon ?? ""}
              style={{ marginRight: "20px", background: bgColor, color: color }}
            >
              {btnLeft ?? "Cancel"}
            </MROButton>}
           {!notShowButton && <MROButton
              style={{ textTransform: "capitalize", display: hideYesButton ? "none" : "" }}
              type="submit"
              variant={"contained"}
              color="primary"
              disabled={isLoading}
              startIcon={rightStartIcon ?? ""}
            >
              {isLoading ? (
                <CircularProgress size={20} color="primary" />
              ) : (
                 btnTitle ?? open
              )}
            </MROButton>}
          </Box>}
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default PopupDialog;

const useStyles = makeStyles(() => ({
  dialog: {
    "& .MuiDialog-paper": {
      boxShadow: "0px 3px 6px #00000029",
      position: "absolute",
      // left: (props) =>
      //   props?.fromLeft ? `calc(60% - ${props.fromLeft})` : `calc(60% - 250px)`,
      //   height: "717px",
    },

    // "& .MuiDialogContent-root": {
    //   padding: (props) =>
    //     props.padding ? props.padding : "37px 65px 63px 65px",
    //   overflow: "hidden",
    // },
  },
}));