import React, { useReducer, useEffect, useContext } from "react";
import useDebounce from "lib/utils/debounce";
import IconButton from "@material-ui/core/IconButton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Divider, Grid, InputBase } from "@material-ui/core";
import tableReducer, { INITIAL_TABLE_STATE } from "reducers/table_reducer";
import MaterialTable from "material-table";
import tableIcons, { options } from "components/universal/table_attributes";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import { useState } from "react";
import { formatToDateOnly, getOrganisationId } from "lib/utils/common_utils";
import { ToastMessageContext } from "lib/contexts/message_context";
import SearchBarWithPartsFilters from "components/search_with_filters_parts";
import SelectedOrderDetails from "../SelectedOrderDetails";
import { MaintenanceServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation";



const useStyles = makeStyles((theme) => ({
    paper: {
        padding: "4px",
        textAlign: "center",
    },
    offgoingchecksTable: {
        "& td, th ": {
            border: "1px solid #dddddd",
            textAlign: "left",
            padding: "8px",
        },
        partCard: {
            borderRadius: "8px",
            padding: "17px 24px",
            "& p": {
                fontSize: "14px",
                padding: "5px",
            },
        },
        offgoingchecksTable: {
            "& td, th ": {
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
            },
        },
    },

    logo: {
        height: "76px",
    },
    filePreviewContainer: {
        maxWidth: "130px",
        marginRight: "30px",
    },
    wrapper: {
        margin: "1%",
    },


}));

export default function CopyTemplates(props) {
    const classes = useStyles();
    const { handleClose,
        currentSelection,
        workshop_id,
        getWorkshopOrderDetails,
        setPlanningAction,
        getPlanningList,
        getMaintenanceOrder,
        actionTabId,
        planningId,
        planningWholeDetails,
        getPlanningDetails,
        closeTabAction, copyTab } = props;
    const [data, setData] = useState([])
    const [action, setAction] = useState(null);
    const message = useContext(ToastMessageContext);
    const [searchText, setSearchText] = useState("");
    const debouncedVal = useDebounce(searchText);
    const [currentSelectedDetails, setCurrentSelectedDetails] = useState({});
    const [planningData, setPlanningData] = useState({})
    const [loading, setLoading] = useState(false)
    const [partNumber, setPartNumber] = useState('')
    const [description, setDescription] = useState('')
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [totalCount, setTotalCount] = useState(0)

    const tableOptions = {
        ...options,
        page: page,
        total: totalCount,
        pageSize: pageSize,
        search: false,
    };
    useEffect(() => {
        if (currentSelection) {
            setPartNumber(currentSelection?.part_number)
            setDescription(currentSelection?.description || currentSelection?.item_description
            )
        }

    }, [currentSelection])

    const handlePartCopy = () => {
        setPage(0);
        setSearchText(partNumber)

    }
    const handleDescriptionCopy = () => {
        setPage(0);
        setSearchText(description)

    }

    const handleSearch = (value) => {

        setPage(0);
        setSearchText(value);
    }
    const clearSearch = () => {
        handleSearch('')
    }






    const columns = [
        {
            title: "Order #",
            field: "order_number",
        },
        {
            title: "Date Raised",
            field: "created_at",
            render: (rowData) => formatToDateOnly(rowData.created_at)
        },
        {
            title: "Part #",
            field: "part_number",
            headerStyle: {
                textAlign: "left",
            },
            cellStyle: {
                textAlign: "left",
                width: "10%"
            }
        },
        {
            title: "Description",
            field: "item_description",
            width: "45%",
            headerStyle: {
                textAlign: "left",
                width: "45%"
            },
            cellStyle: {
                textAlign: "left",
                width: "45%"
            }
        },


        {
            title: "Action",
            render: (rowData) => (
                <IconButton onClick={() => {
                    setAction("Selected_Order")
                    setCurrentSelectedDetails(rowData)
                }}>
                    <TouchAppIcon />
                </IconButton>
            ),
        },
    ];

    const getList = async () => {
        const params = {
            page: page + 1,
            limit: pageSize,
            search: debouncedVal,
            organisation_id: getOrganisationId(),
            type: actionTabId === 20 || actionTabId === 24 ? "Maintenance Template" : "Production Template"


        }
        setLoading(true)
        await MaintenanceServices.getTemplateListing(params).then((res) => {
            setData(res.data)
            setTotalCount(res.total_count)
        }).catch((res) => {
            message.showToastMessage({
                message: "Something went wrong. Try again!",
                variant: "error",
            });
        }).finally(() => {
            setLoading(false)
        })

    }
    useEffect(() => {
        getList()
        setPlanningData(currentSelection)
    }, [page, pageSize, debouncedVal, currentSelection])

    return (

        <div className={classes.wrapper}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <SearchBarWithPartsFilters nofilter partNumber={partNumber} setPartNumber={setPartNumber} setDescription={setDescription} description={description} handleDescriptionCopy={handleDescriptionCopy} handlePartCopy={handlePartCopy} searchText={searchText} handleSearch={handleSearch} />


                    <div>
                        <MaterialTable
                            style={{
                                boxShadow: "0px 1px 3px #00000033",
                                marginTop: "10px",
                            }}
                            icons={tableIcons}
                            classes
                            title={""}
                            columns={columns}
                            data={data || []}
                            isLoading={loading}
                            options={tableOptions}
                            onChangePage={(page) => {
                                setPage(page);
                            }}
                            onChangeRowsPerPage={(pageSize) => {
                                setPageSize(pageSize);
                            }}
                            totalCount={totalCount}
                            page={page}
                        />
                    </div>
                    <div style={{ margin: ' 14px 0px 20px 3px' }}>
                        <span style={{ padding: '6px' }}>Select an Order to view its details and add to this Order</span>
                    </div>
                </Grid>
            </Grid>



            {
                action === "Selected_Order" &&
                <SelectedOrderDetails
                    handleClose={() => setAction(null)}
                    currentPlanningDetails={planningData}
                    currentSelectedDetails={currentSelectedDetails}
                    workshop_id={workshop_id}
                    getWorkshopOrderDetails={getWorkshopOrderDetails}
                    getMaintenanceOrderList={getList}
                    getPlanningList={getPlanningList}
                    planningId={planningId}
                    planningWholeDetails={planningWholeDetails}
                    type={"templates"}
                    copyTab={copyTab}
                />
            }
        </div>


    )


}