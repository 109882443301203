import React, { useContext, useEffect, useMemo, useState } from "react";
import MROFullScreenRightDrawer from "components/fullwidth_dialog";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import Typography from "@material-ui/core/Typography";
import { ToastMessageContext } from "lib/contexts/message_context";
import DeleteIcon from "@material-ui/icons/Delete";
import { Box, MenuItem } from "@material-ui/core";
import { ICON_COLOUR } from "lib/constants/style_constants";
import DeleteCusApproval from "./DeleteCusApproval";
import MROButton from "components/buttons";
import MaterialTable from "material-table";
import tableIcons, { options } from "components/universal/table_attributes";
import { Visibility as ViewIcon } from "@material-ui/icons";
import YesNoField from "components/form_components/YesNoField";
import MROTextField from "components/form_components/TextField";
import WarningIcon from "@material-ui/icons/Warning";
import { MaintenanceServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation";
import UploadButton from "components/form_components/upload_multiple";
import { validateFormData } from "lib/utils/helperFunctions";
import { CommercialServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/commercial";
import { WorkshopContext } from "../WorkshopContext/WorkshopContext";
import { getOrganisationId } from "lib/utils/common_utils";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: "3%",
  },
  appBar: {
    backgroundColor: "#fff",
    color: "#000000CC",
    boxShadow: "0px 3px 6px #0000001A",
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  wrap: {
    borderRadius: "5px",
    backgroundColor: "#fff",
    padding: theme.spacing(3),
    boxShadow: "0px 1px 4px #0000001A",
  },
  itemContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  actionButtons: {
    display: "flex",
    justifyContent: "flex-end",
    textAlign: "right",
    marginRight: "33px",
    "& button:not(:last-child)": {
      marginRight: "20px",
    },
  },
  disableField: {
    pointerEvents: "none",
    backgroundColor: "#F5F5F5",
  },
}));

function CustomerApproval(props) {
  const classes = useStyles();
  const {
    handleClose,
    approvedPositionData,
    getApprovedPosition,
    currentSelectedId,
    approveData
  } = props;
  const message = useContext(ToastMessageContext);
  const { setApprovalStateCommercial } = useContext(WorkshopContext);
  const [busy, setBusy] = useState(null);
  const [action, setAction] = useState(null);
  const [releaseType, setReleaseType] = useState(null);
  const [approvedToggle, setApprovedToggle] = useState("yes");
  const [amendements, setAmendments] = useState(null);
  const [errors, setErrors] = useState({});
  const [sum, setSum] = useState(null);
  const [proofAcceptnace, setProofAcceptance] = useState([]);
  const [proofRejection, setProofRejection] = useState([]);
  const [togglevalue, setToggleValue] = useState("yes")
  const [amendementsToggle, setAmendmentsToggle] = useState(null)
  const [revisedtotal, setRevisedTotal] = useState(null)
  // console.log("approved position data=================", approvedPositionData)
  // console.log("approved  data=================", approveData)

  const [input, setInput] = useState({
    tat_days_count: "",
    labour_value: "",
    parts_value: "",
    service_value: "",
    equipment_value: "",
    aog_fee: "",
  });

  useEffect(() => {
    if (approveData !== null) {
      setInput({
        tat_days_count: +approveData?.tat_days_count || "0",
        labour_value: +approveData?.labour_value || "0",
        parts_value: +approveData?.parts_value || "0",
        service_value: +approveData?.service_value || "0",
        equipment_value: +approveData?.equipment_value || "0",
        aog_fee: +approveData?.aog_fee || "0",
      });
    }
  }, [approveData, togglevalue, amendementsToggle])


  const resetErrorsHandler = (name) => {
    setErrors((e) => ({ ...e, [name]: false }));
  };

  useEffect(() => {
    setAmendments("no")
    setAmendmentsToggle("no")
  }, [])

  useMemo(
    () =>
      setSum(
        +input.labour_value +
        +input.parts_value +
        +input.service_value +
        +input.equipment_value +
        +input.aog_fee
      ),
    [input]
  );
  useMemo(
    () =>
      setRevisedTotal(
        +input.labour_value +
        +input.parts_value +
        +input.service_value +
        +input.equipment_value
      ),
    [input]
  );
  //handle input change
  const handleInput = function (e) {
    const { value } = e.target;
    if (value.match(/\./g)) {
      const [, decimal] = value.split(".");

      // restrict value to only 2 decimal places
      if (decimal?.length > 2) {
        // do nothing
        return;
      }
    }
    // setState((ps) => ({ ...ps, isEditing: true }));
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };

  const column = [
    {
      title: "Current Position",
      field: "approval_number",
      headerStyle: {
        textAlign: "center",
      },
      cellStyle: {
        width: "20%",
        textAlign: "center",
      },
    },
    {
      title: "TAT (Days)",
      field: "tat_days_count",
      render: (rowData) => !rowData.tat_days_count ? "0" : rowData.tat_days_count
    },
    {
      title: "Labour",
      field: "labour_value",
      render: (rowData) => !rowData.labour_value ? "0.00" : rowData.labour_value
    },
    {
      title: "Parts",
      field: "parts_value",
      render: (rowData) => !rowData.parts_value ? "0.00" : rowData.parts_value
    },
    {
      title: "Services",
      field: "service_value",
      render: (rowData) => !rowData.service_value ? "0.00" : rowData.service_value
    },
    {
      title: "Equipment",
      field: "equipment_value",
      render: (rowData) => !rowData.equipment_value ? "0.00" : rowData.equipment_value
    },
    {
      title: "AOG Fee",
      field: "aog_fee",
      render: (rowData) => !rowData.aog_fee ? "0.00" : rowData.aog_fee
    },
    {
      title: "Total",
      field: "total",
    },
  ];

  const tableOptions = {
    ...options,
    search: false,
    paging: false,
    rowStyle: { height: 42 },
  };

  const handleApprovedToggle = (value) => {
    if (value !== null) {
      setToggleValue(value)
      setApprovedToggle(value)
    }
    else {
      setApprovedToggle(togglevalue)
    }
  };

  // console.log("toggle=====", togglevalue, amendementsToggle)
  useEffect(() => {
    getReleaseType();
  }, []);

  const getReleaseType = async () => {
    const params = {
      organisation_id: getOrganisationId()
    }
    await MaintenanceServices.getReleaseType(params)
      .then((res) => {
        if (res) {
          setReleaseType(res.data);
        }
      })
      .catch((err) => {
        message.showToastMessage({
          message: "Something went wrong. Try again!",
          variant: "error",
        });
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const { error, valid } = validateFormData(formData, [
      approvedToggle === "no"
        ? ("amendments_agreed", "proof_of_acceptance")
        : "",
      approvedToggle === "yes"
        ? ("release_type", "reject_comments", "proof_of_rejection")
        : "",
    ]);

    if (approvedToggle === null) {
      setBusy(null);
      setErrors((er) => ({ ...er, quote_accepted: true }));
    }

    if (proofAcceptnace?.length === 0 && approvedToggle === "yes") {
      setBusy(null);
      setErrors((er) => ({ ...er, proof_of_acceptance: true }));
    }
    if (proofRejection?.length === 0 && approvedToggle === "no") {
      setBusy(null);
      setErrors((er) => ({ ...er, proof_of_rejection: true }));
    }

    if (
      (proofAcceptnace?.length === 0 && approvedToggle === "yes") ||
      (proofRejection?.length === 0 && approvedToggle === "no") || approvedToggle === null
    ) {
      setErrors((err) => ({ ...err, ...error }));
      setBusy(null);
      return;
    }

    if (!valid) {
      setBusy(null);
      setErrors(error);
      return;
    }
    formData.delete("tat_days_count")
    formData.delete("labour_value")
    formData.delete("parts_value")
    formData.delete("service_value")
    formData.delete("equipment_value")
    formData.delete("aog_fee")
    formData.delete("total")

    formData.append("tat_days_count", input.tat_days_count)
    formData.append("labour_value", input.labour_value || "")
    formData.append("parts_value", input.parts_value || "")
    formData.append("service_value", input.service_value || "")
    formData.append("equipment_value", input.equipment_value || "")
    formData.append("aog_fee", input.aog_fee || "")
    formData.append("total", parseFloat(revisedtotal).toFixed(2) || "")
    formData.append("id", currentSelectedId);
    setBusy("loading");
    await CommercialServices.customerApproval(formData)
      .then((res) => {
        if (res) {
          handleClose(res.success);
          setApprovalStateCommercial(false);
          getApprovedPosition();
          message.showToastMessage({
            message: "Approved successfully!!",
            variant: "success",
          });
        }
      })
      .catch(() => {
        message.showToastMessage({
          message: "Something wnt wrong. Try again!!",
          variant: "error",
        });
      })
      .finally(() => {
        setBusy(null);
      });
  };

  return (
    <div>
      <MROFullScreenRightDrawer open={true}>
        <AppBar position="static" className={classes.appBar} elevation={0}>
          <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
            <Box display="flex" alignItems="center">
              <IconButton
                onClick={handleClose}
                edge="start"
                className={classes.backButton}
                color="inherit"
                aria-label="back"
              >
                <BackIcon />
              </IconButton>
              <Typography variant="subtitle2" style={{ fontSize: "17px" }}>
                Customer Approval
              </Typography>
            </Box>
            <IconButton onClick={() => setAction("delete")}>
              <DeleteIcon fontSize="small" htmlColor={ICON_COLOUR} />
            </IconButton>
          </Toolbar>
        </AppBar>

        <form noValidate onSubmit={handleSubmit}>
          <div className={classes.wrapper}>
            <Grid>
              <Typography color="primary" style={{ marginBottom: "10px" }}>
                Submission
              </Typography>
            </Grid>
            <MaterialTable
              style={{
                boxShadow: "0px 1px 4px #00000033",
              }}
              icons={tableIcons}
              title={""}
              columns={column}
              data={approvedPositionData || []}
              isLoading={""}
              options={tableOptions}
              components={{
                Toolbar: () => null,
              }}
              localization={{
                toolbar: {},
                body: {
                  emptyDataSourceMessage: "No data found",
                  filterRow: {
                    filterTooltip: "Filter",
                  },
                },
              }}
            />

            <Grid>
              <Typography
                color="primary"
                style={{ marginBottom: "10px", marginTop: "10px" }}
              >
                Approval
              </Typography>
            </Grid>
            <YesNoField
              label="Quotation Approved? *"
              name="quote_accepted"
              handleToggle={handleApprovedToggle}
              value={approvedToggle}
              error={errors.quote_accepted}
              setError={() => {
                resetErrorsHandler("quote_accepted");
              }}
            />
            {approvedToggle === "no" && (
              <Grid container spacing={1} className={classes.itemContainer}>
                <Grid item>
                  <Typography style={{ fontSize: "13px" }}>
                    Is the item being Scrapped, or returned U/S? Update the
                    order’s Release Type below to match the return state
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <MROTextField
                    name={"release_type"}
                    label={"Release Type 1*"}
                    placeholder={"Select"}
                    select
                    defaultValue={"select"}
                    InputLabelProps={{ shrink: true }}
                    error={errors.release_type}
                    onChange={(e) => {
                      resetErrorsHandler(e.target.name);
                    }}
                  >
                    <MenuItem value="select">
                      <span style={{ opacity: "40%" }}>Select</span>
                    </MenuItem>
                    {releaseType?.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.type_name}
                      </MenuItem>
                    ))}
                  </MROTextField>
                </Grid>
                <Grid item>
                  <MROTextField
                    name={"reject_comments"}
                    label={"Comments"}
                    required
                    InputProps={{ style: { height: 70, width: "60%" } }}
                    inputProps={{ maxLength: 250 }}
                    error={errors.reject_comments}
                    onChange={(e) => {
                      resetErrorsHandler(e.target.name);
                    }}
                    placeholder={
                      "Where applicable, add comments below that you wish to appear on the release documents (U/S label, re...."
                    }
                    InputLabelProps={{ shrink: true }}
                  ></MROTextField>
                </Grid>
                <Grid item>
                  <Typography>
                    Proof of rejection (customer approval email etc) *
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <UploadButton
                    multiple={false}
                    accept={"application/pdf"}
                    action={"Upload Approval"}
                    noFileExist={"Attached Files:"}
                    error={errors.proof_of_rejection}
                    setFiles={(files) => {
                      setProofRejection(files);
                      resetErrorsHandler("proof_of_rejection");
                    }}
                    name={"proof_of_rejection"}
                    label=""
                    labelName="Proof of rejection (customer approval email etc) *"
                  />
                </Grid>
                <Grid item>
                  <Box display="flex" alignItems="center">
                    <WarningIcon
                      style={{
                        color: "#FFB300",
                        marginRight: "10px",
                        fontSize: 20,
                      }}
                    />
                    <Typography style={{ fontSize: "13px" }}>
                      Is the item being Scrapped? If so, make sure to raise and
                      approve a Maintenance Action to scrap the item!
                    </Typography>
                  </Box>
                </Grid>
                <Grid item>
                  <Box display="flex" alignItems="center">
                    <WarningIcon
                      style={{
                        color: "#FFB300",
                        marginRight: "10px",
                        fontSize: 20,
                      }}
                    />
                    <Typography style={{ fontSize: "13px" }}>
                      Remember to Reject each Maintenance Action not authorised
                      by this approval!
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            )}
            {approvedToggle === "yes" && (
              <Grid container spacing={1} className={classes.itemContainer}>
                <Grid item>
                  <YesNoField
                    label="Were there any amendments agreed from the figures sent? *"
                    name="amendments_agreed"
                    handleToggle={(value) => {
                      if (value !== null) {
                        setAmendmentsToggle(value)
                        setAmendments(value)
                      } else {
                        setAmendments(amendementsToggle)
                      }

                    }}
                    value={amendements}
                    error={errors.amendments_agreed}
                    setError={() => {
                      resetErrorsHandler("amendments_agreed");
                    }}
                  />
                </Grid>
                {amendements === "yes" && (
                  <>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        margin: "10px 0px 5px 0px",
                      }}
                    >
                      Revise figures as per agreement
                    </Typography>
                    <br></br>
                    <Grid
                      container
                      spacing={2}
                      alignItems={"center"}
                      justify={"flex-start"}
                      xs={12}
                    >
                      <Grid item>
                        <MROTextField
                          name={"tat_days_count"}
                          label={"TAT (Days) "}
                          value={input.tat_days_count}
                          type="text"
                          width="120px"
                          required
                          placeholder={"0.00"}
                          error={errors.tat_days_count}
                          onChange={(e) => {
                            resetErrorsHandler(e.target.name);
                            handleInput(e);
                          }}
                          InputLabelProps={{ shrink: true }}
                        ></MROTextField>
                      </Grid>
                      <Grid item>
                        <MROTextField
                          name={"labour_value"}
                          label={"Labour"}
                          width="120px"
                          value={input.labour_value}
                          type="text"
                          required
                          placeholder={"0.00"}
                          error={errors.labour_value}
                          onChange={(e) => {
                            resetErrorsHandler(e.target.name);
                            handleInput(e);
                          }}
                          InputLabelProps={{ shrink: true }}
                        ></MROTextField>
                      </Grid>
                      <Grid item>
                        <MROTextField
                          name={"parts_value"}
                          width="120px"
                          label={"Parts"}
                          type="text"
                          required
                          value={input.parts_value}
                          placeholder={"0.00"}
                          error={errors.parts_value}
                          onChange={(e) => {
                            resetErrorsHandler(e.target.name);
                            handleInput(e);
                          }}
                          InputLabelProps={{ shrink: true }}
                        ></MROTextField>
                      </Grid>
                      <Grid item>
                        <MROTextField
                          name={"service_value"}
                          width="120px"
                          label={"Services"}
                          value={input.service_value}
                          type="text"
                          required
                          placeholder={"0.00"}
                          error={errors.service_value}
                          onChange={(e) => {
                            resetErrorsHandler(e.target.name);
                            handleInput(e);
                          }}
                          InputLabelProps={{ shrink: true }}
                        ></MROTextField>
                      </Grid>
                      <Grid item>
                        <MROTextField
                          name={"equipment_value"}
                          width="120px"
                          label={"Equipment"}
                          required
                          value={input.equipment_value}
                          placeholder={"0.00"}
                          type="text"
                          error={errors.equipment_value}
                          onChange={(e) => {
                            resetErrorsHandler(e.target.name);
                            handleInput(e);
                          }}
                          InputLabelProps={{ shrink: true }}
                        ></MROTextField>
                      </Grid>
                      <Grid item>
                        <MROTextField
                          name={"aog_fee"}
                          width="120px"
                          label={"AOG Fee"}
                          required
                          value={input.aog_fee}
                          placeholder={"0.00"}
                          type="text"
                          error={errors.aog_fee}
                          onChange={(e) => {
                            resetErrorsHandler(e.target.name);
                            handleInput(e);
                          }}
                          InputLabelProps={{ shrink: true }}
                        ></MROTextField>
                      </Grid>
                      <Grid item>
                        <MROTextField
                          required
                          name={"total"}
                          width="120px"
                          label={"Total"}
                          error={errors.total}
                          value={parseFloat(sum).toFixed(2)}
                          placeholder={"0.00"}
                          defaultValue={0.0}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{
                            className: classes.disableField,
                          }}
                          onChange={(e) => {
                            resetErrorsHandler(e.target.name);
                          }}
                        ></MROTextField>
                      </Grid>

                      <Grid item>
                        <Box display="flex" alignItems="center">
                          <WarningIcon
                            style={{
                              color: "#FFB300",
                              marginRight: "10px",
                              fontSize: 20,
                            }}
                          />
                          <Typography style={{ fontSize: "13px" }}>
                            Amended Sum Charges are reflected in the report
                            summary, however, are not reflected in the itemised
                            breakdown!
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </>
                )}
                <Grid item xs={4} style={{ marginTop: "10px" }}>
                  <Typography>
                    Proof of acceptance (customer approval email etc) *
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <UploadButton
                    multiple={false}
                    accept={"application/pdf"}
                    action={"Upload Approval"}
                    noFileExist={"Attached Files:"}
                    error={errors.proof_of_acceptance}
                    setFiles={(files) => {
                      setProofAcceptance(files);
                      resetErrorsHandler("proof_of_acceptance");
                    }}
                    name={"proof_of_acceptance"}
                    label=""
                    labelName="Proof of acceptance (customer approval email etc) *"
                  />
                </Grid>
                <Grid item>
                  <Box display="flex" alignItems="center">
                    <WarningIcon
                      style={{
                        color: "#FFB300",
                        marginRight: "10px",
                        fontSize: 20,
                      }}
                    />
                    <Typography style={{ fontSize: "13px" }}>
                      Remember to Approve each Maintenance Action covered by
                      this approval!
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            )}
          </div>
          <div className={classes.actionButtons}>
            <MROButton variant={"contained"} onClick={handleClose}>
              Cancel
            </MROButton>
            <MROButton
              loading={busy === "loading"}
              variant={"contained"}
              color={"primary"}
              type={"submit"}
            >
              Complete
            </MROButton>
          </div>
        </form>
        {action === "delete" && (
          <DeleteCusApproval
            handleClose={() => setAction(null)}
            open={true}
            selectedId={currentSelectedId}
            closeHandler={handleClose}
            getApprovedPosition={getApprovedPosition}
          />
        )}
      </MROFullScreenRightDrawer>
    </div>
  );
}

export default CustomerApproval;
