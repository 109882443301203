import React, { useContext, useEffect, useState } from "react";
import MROFullScreenRightDrawer from "components/fullwidth_dialog";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import Typography from "@material-ui/core/Typography";
import { ButtonGroup, Button } from "@material-ui/core/";
import MROTextField from "components/form_components/TextField";
import Subtract from "@material-ui/icons/Remove";
import Add from "@material-ui/icons/Add";
import FileUpload from "components/form_components/fileUpload";
import YesNoInput from "views/Accounts/WorkOrder/FieldComponents/yes_or_no_based_input_field";
import QuantityCounter from "components/quantity_counter";
import MROButton from "components/buttons";
import MenuItem from "@material-ui/core/MenuItem";
import YesNoField from "components/form_components/YesNoField";
import { ToastMessageContext } from "lib/contexts/message_context";
import { MaintenanceServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation";
import { InspectionServices } from "lib/services/api/operaitons/WorkShop/Inspection/InspectionService";
import { validateFormData } from "lib/utils/helperFunctions";
import { BASE_API_URL_BOXP } from "lib/constants";
import NumericIncrement from "views/Operations/LineMaintenance/WorkOrder/FieldComponents/numeric_increment";
import { getOrganisationId } from "lib/utils/common_utils";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: "3%",
    overflowX:"hidden !important"
  },
  appBar: {
    backgroundColor: "#fff",
    color: "#000000CC",
    boxShadow: "0px 3px 6px #0000001A",
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  wrap: {
    borderRadius: "5px",
    backgroundColor: "#fff",
    padding: theme.spacing(3),
    boxShadow: "0px 1px 4px #0000001A",
  },
  paper: {
    borderRadius: "5px",
    backgroundColor: "#F5F5F5",
    padding: theme.spacing(2),
    boxShadow: "0px 1px 4px #0000001A",
  },
  partValue: {
    fontSize: "14px",
    color: "#4D4F5C",
    padding: "2px",
    display: "flex",
    alignItems: "center",
  },
  partKey: {
    "& p": {
      fontSize: "14px",
      padding: "1px",
    },
  },
  partCard: {
    borderRadius: "8px",
    padding: "17px 24px",
    "& p": {
      fontSize: "14px",
      padding: "5px",
    },
  },
  sec1: {
    borderRadius: "10px",
    backgroundColor: "#fff",
    padding: theme.spacing(5),
    boxShadow: "0px 1px 4px #0000001A",
  },
  actionButtons: {
    paddingTop: "20px",
    paddingBottom: "33px",
    textAlign: "right",
    marginRight: "20px",
    "& button:not(:last-child)": {
      marginRight: "20px",
    },
  },
}));

function ActionDetails(props) {
  const classes = useStyles();
  const { handleClose, workshop_id, action_type, getAllActions } = props;
  const message = useContext(ToastMessageContext);
  const [dropDownData, setDropdownData] = useState([]);
  const [errors, setErrors] = useState({});
  const [ataChapter, setAtaChapter] = useState(null);
  const [mechAuth, setMechAuth] = useState(null);
  const [inspAuth, setInspAuth] = useState(null);
  const [busy, setBusy] = useState(null);
  const [maintFile, setMaintFile] = useState([]);
  const [state, setState] = useState({ man_hours: ''});
  const [criticalConsideration, setCriticalConsideration] = useState(null);
  const [IndependentInsp, setIndependentInsp] = useState(null);

  useEffect(() => {
    getDropdownData();
  }, []);

  const getDropdownData = async () => {
    const params = {
      organisation_id: getOrganisationId()
    }
    await InspectionServices.getAllDropDown(params)
      .then((res) => {
        if (res) {
          setDropdownData(res.data);
        }
      })
      .catch(() => {
        message.showToastMessage({
          message: "Something went wrong. Try again!",
          variant: "error",
        });
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const { error, valid } = validateFormData(formData, ["maintenance_file[]"]);
    if (!valid) {
      // setLoading(null);
      setErrors(error);
      return;
    }

    formData.append("workshop_order_id", workshop_id);
    formData.append("action_type", action_type);
    let userDetails = JSON.parse(sessionStorage.getItem("user"));
    formData.append("raised_by", userDetails?.id);
    // formData.append("status", "Open");
    formData.delete("maintenance_file[]");
    maintFile.forEach((file)=> (formData.append("maintenance_file[]", file)))
    setBusy("loading");
    await InspectionServices.createAction(formData)
      .then((res) => {
        if (res) {
          handleClose();
          getAllActions();
          setBusy(null);
          message.showToastMessage({
            message: "Created successfully!!",
            variant: "success",
          });
        }
      })
      .catch(() => {
        setBusy(null);
        message.showToastMessage({
          message: "Something went wrong. Try again!",
          variant: "error",
        });
      });
    setBusy(null);
  };

  const resetErrorsHandler = (name) => {
    setErrors((e) => ({ ...e, [name]: false }));
  };

  const handleCriticalConsideration = (value) => {
    setCriticalConsideration(value);
  };
  const handleIndependentInsp = (value) => {
    setIndependentInsp(value);
  };

  const handleChange = ({ target: { name, value } }) => {
    // setState({
    //     ...state,
    //     [name]: value
    // })
}

  return (
    <div>
      <MROFullScreenRightDrawer open={true}>
        <AppBar position="static" className={classes.appBar} elevation={0}>
          <Toolbar>
            <IconButton
              onClick={handleClose}
              edge="start"
              className={classes.backButton}
              color="inherit"
              aria-label="back"
            >
              <BackIcon />
            </IconButton>
            <Typography variant="subtitle2" style={{ fontSize: "17px" }}>
              Add Action
            </Typography>
          </Toolbar>
        </AppBar>

        <form noValidate onSubmit={handleSubmit}>
          <div className={classes.wrapper}>
            <Grid>
              <Typography color="primary" style={{ marginBottom: "10px" }}>
                Action Details
              </Typography>
            </Grid>
            <Grid item container style={{ width: "50%" }}>
              <MROTextField
                name={"title"}
                label={"Title *"}
                placeholder={"Action Title - Max 50 Characters"}
                inputProps={{ maxLength: 50 }}
                InputLabelProps={{ shrink: true }}
                error={errors.title}
                onChange={(e) => {
                  resetErrorsHandler(e.target.name);
                }}
              ></MROTextField>
            </Grid>
            <Grid item xs={4}>
              <MROTextField
                name={"ata_chapter_id"}
                label={"ATA Chapter *"}
                placeholder={"Select"}
                select
                requird
                InputLabelProps={{ shrink: true }}
                error={errors.ata_chapter_id}
                value={ataChapter}
                onChange={(e) => {
                  resetErrorsHandler(e.target.name);
                  setAtaChapter(e.target.value);
                }}
              >
                {dropDownData?.ata_chapters?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.chapter_number}
                  </MenuItem>
                ))}
              </MROTextField>
            </Grid>
            <Grid item xs={4}>
              <MROTextField
                name={"mech_auth_id"}
                label={"Mech Auth *"}
                placeholder={"Select"}
                select
                InputLabelProps={{ shrink: true }}
                error={errors.mech_auth_id}
                value={mechAuth}
                onChange={(e) => {
                  resetErrorsHandler(e.target.name);
                  setMechAuth(e.target.value);
                }}
              >
                {dropDownData?.mech_insp_auth?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option?.type_name}
                  </MenuItem>
                ))}
              </MROTextField>
            </Grid>
            <Grid item xs={4}>
              <MROTextField
                name={"insp_auth_id"}
                label={"Insp Auth *"}
                placeholder={"Select"}
                select
                InputLabelProps={{ shrink: true }}
                error={errors.insp_auth_id}
                value={inspAuth}
                onChange={(e) => {
                  resetErrorsHandler(e.target.name);
                  setInspAuth(e.target.value);
                }}
              >
                {dropDownData?.mech_insp_auth?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option?.type_name}
                  </MenuItem>
                ))}
              </MROTextField>
            </Grid>
            <Grid item xs={4}>
              <NumericIncrement
                name="estimate_man_hours"
                label="Estimate Man-Hrs *"
                error={errors.estimate_man_hours}
                value={state.man_hours}
                placeholder={"Man-Hours required"}
                onChange={handleChange}
                noIncreament
                state={state}
                setState={setState}
                inputProps={{ min: 0, step: 0.5 }}
                InputLabelProps={{ shrink: true }}
                setError={() =>
                  setErrors((prevState) => ({
                    ...prevState,
                    estimate_man_hours: false,
                  }))
                }
              />
            </Grid>
            <Grid style={{ marginTop: "8px" }}>
              <YesNoField
                label="Safety critical considerations? (CDCCL, H&S, COSHH etc) *"
                name="critical_considerations"
                handleToggle={handleCriticalConsideration}
                value={criticalConsideration}
                error={errors.critical_considerations}
                setError={() => resetErrorsHandler("critical_considerations")}
              />
            </Grid>
            <Grid style={{ marginTop: "8px" }}>
              <YesNoField
                label="Independent Inspection required? *"
                name="independent_insp_required"
                handleToggle={handleIndependentInsp}
                value={IndependentInsp}
                error={errors.independent_insp_required}
                setError={() => resetErrorsHandler("independent_insp_required")}
              />
            </Grid>
            <Typography
              wrap
              style={{
                marginRight: 30,
                margin: "20px 0px 20px 0px",
                fontSize: "15px",
              }}
            >
              Upload PDF versions of relevant maintenance data you want to be
              visible in the APP
            </Typography>
            
            <FileUpload
              setFiles={(files) => {
                setMaintFile(files);
              }}
              width={"50%"}
              showBorderOnNoFiles={true}
              name="maintenance_file[]"
              multiple={true}
              APIUrl={""}
              action={"Upload Document"}
            />
          </div>
          <div className={classes.actionButtons}>
            <MROButton variant={"contained"} onClick={handleClose}>
              Cancel
            </MROButton>
            <MROButton
              loading={busy === "loading"}
              variant={"contained"}
              color={"primary"}
              type={"submit"}
            >
              Add
            </MROButton>
          </div>
        </form>
      </MROFullScreenRightDrawer>
    </div>
  );
}

export default ActionDetails;
