import React, { useState, useContext, useEffect, useReducer } from 'react';
import Button from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography/Typography";
import AuthClient from "../../lib/services/api/auth_services";
import { validateEmail } from "../../lib/utils/common_utils";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import UserIcon from "@material-ui/icons/PersonOutlined";
import useAuthStyles from "./auth_styles";
import LockIcon from "@material-ui/icons/LockOutlined";
import { Grid } from "@material-ui/core";
import { ToastMessageContext } from 'lib/contexts/message_context';
import TotpAuth from './TotpAuth';
// import TwoFactorAuthActions from 'actions/Two_factor_auth';
import authReducer, { INITIAL_STATE } from 'reducers/auth_reducer';
import AuthActions from 'actions/auth_actions';




export default function TwoFactorAuth(props) {
  const classes = useAuthStyles();
  const [authState, dispatch] = useReducer(authReducer, INITIAL_STATE);
  const authMethod = JSON.parse(sessionStorage.getItem('user'));
  const messages = useContext(ToastMessageContext)
  const { email, password, application, browser, entitySid } = props.location
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState({});
  const [otpMethod, setOtpMethod] = useState(false)
  const [checked, setChecked] = useState(authMethod?.auth_method == "qrcode" ? 1 :
    authMethod?.auth_method == "email" ? 3 : authMethod?.auth_method == "phone" ? 2 : 1)
  const [otpNum, setOtpNum] = useState(null)
  const [verified, setVerified] = useState(null)
  const [factAndEntityId, setFactAndEntityId] = useState({})
  const [step_1, setStep_1] = useState(false)

  // console.log("verified is==========================", authState.error)

  useEffect(() => {
    if (verified == "approved")
      AuthActions.userSignIn(dispatch, { email: email, verified_otp: true, success: true, password: password, application: application, browser: browser });
  }, [verified])

  const options = [
    {
      name: "TOTP Authentication APP (setup new device)",
      selected: null,
      id: 1
    },
    {
      name: "SMS / Text Message code",
      selected: null,
      id: 2
    },
    {
      name: "Email code",
      selected: null,
      id: 3
    },
  ]

  const handleClick = (id) => {
    setChecked((prev) => (id == prev ? null : id))
  }

  const handleOtpMethod = () => {
    if (checked == 3) {
      AuthClient.sendOtpByEmail({ stamp_number: authMethod?.stamp_number })
        .then((res) => {
          if (res?.success) {
            messages.showToastMessage({
              message: "OTP has been sent to your registered email id",
              variant: "success",
            });
          } else {
            res.errors &&
              messages.showToastMessage({
                message: res.errors.join(","),
                variant: "error",
              });
          }
        })
        .catch((err) => {
          messages.showToastMessage({
            message: "Something went wrong. Try Again",
            variant: "error",
          });
        })
    } else if (checked == 2) {
      AuthClient.sendOtpByPhone({ stamp_number: authMethod?.stamp_number })
        .then((res) => {
          if (res?.success) {
            messages.showToastMessage({
              message: "OTP has been sent to your registered mobile number",
              variant: "success",
            });
          } else {
            res.errors &&
              messages.showToastMessage({
                message: res.errors.join(","),
                variant: "error",
              });
          }
        })
        .catch((err) => {
          messages.showToastMessage({
            message: "Something went wrong. Try Again",
            variant: "error",
          });
        })
    }
  }

  useEffect(() => {
    if (authMethod?.auth_method == "email") {
      const params = {
        stamp_number: authMethod?.stamp_number
      }
      AuthClient.sendOtpByEmail(params)
        .then((res) => {
          if (res?.success) {
            messages.showToastMessage({
              message: "OTP has been sent to your registered mail id",
              variant: "success",
            });
          } else {
            res.errors &&
              messages.showToastMessage({
                message: res.errors.join(","),
                variant: "error",
              });
          }
        })
        .catch((err) => {
          messages.showToastMessage({
            message: "Something went wrong. Try Again",
            variant: "error",
          });
        })
    }
    if (authMethod?.auth_method == "phone") {
      AuthClient.sendOtpByPhone({ stamp_number: authMethod?.stamp_number })
        .then((res) => {
          if (res?.success) {
            messages.showToastMessage({
              message: "OTP has been sent to your registered mobile number",
              variant: "success",
            });
          } else {
            res.errors &&
              messages.showToastMessage({
                message: res.errors.join(","),
                variant: "error",
              });
          }
        })
        .catch((err) => {
          messages.showToastMessage({
            message: "Something went wrong. Try Again",
            variant: "error",
          });
        })
    }
  }, [])

  const handleClickNext = () => {
    if (checked != 1) {
      handleOtpMethod()
    }
    // if (checked === 1) {
    //   setCheckBoxState(true)
    // }
    setOtpMethod(!otpMethod)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    let params = {}
    if (!validateEmail(email)) {
      setError({ email: "invalid format, provide valid email" });
      return;
    }

    if (checked != 2 && checked != 3) {
      params = {
        otp: otpNum,
        stamp_number: authMethod?.stamp_number
        // factor_sid: factAndEntityId?.factorSid,
        // entity_sid: factAndEntityId?.entitySid
      }
    } else {
      params = {
        otp: otpNum,
        stamp_number: authMethod?.stamp_number
      }
    }
    if (checked == 3) {
      AuthClient.verifyOtpEmail(params)
        .then((res) => {
          if (res?.data == "approved") {
            setVerified(res?.data)
            messages.showToastMessage({
              message: "Verified successfully",
              variant: "success",
            });
          } else {
            messages.showToastMessage({
              message: "Entered OTP is invalid",
              variant: "error",
            });
          }
        })
        .catch((err) => {
          messages.showToastMessage({
            message: "Entered OTP is invalid",
            variant: "error",
          });
        })
    } else if (checked == 2) {
      AuthClient.verifyOtpPhone(params)
        .then((res) => {
          if (res?.data == "approved") {
            setVerified(res?.data)
            messages.showToastMessage({
              message: "Verified successfully",
              variant: "success",
            });
          } else {
            messages.showToastMessage({
              message: "Entered OTP is invalid",
              variant: "error",
            });
          }
        })
        .catch((err) => {
          messages.showToastMessage({
            message: "Entered OTP is invalid",
            variant: "error",
          });
        })
    } else {
      AuthClient.verifyTOTPCode(params)
        .then((res) => {
          if (res?.data?.challenge_status == "approved") {
            setVerified(res?.data?.challenge_status)
            messages.showToastMessage({
              message: "Verified successfully",
              variant: "success",
            });
          } else if (res?.data?.challenge_status == "pending") {
            messages.showToastMessage({
              message: res?.data?.message,
              variant: "error",
            });
          } else {
            messages.showToastMessage({
              message: res?.errors,
              variant: "error",
            });
          }
        })
        .catch((err) => {
          messages.showToastMessage({
            message: err?.errors,
            variant: "error",
          });
        })
    }
  }

  useEffect(() => {
    if (otpMethod) setOtpNum("")
  }, [otpMethod])


  return (
    <div className={classes.wrapper}>
      <div className={classes.overlay}></div>
      <div style={{ zIndex: '99' }}>
        {!!!message ? <form spellcheck="false" className={classes.form} onSubmit={handleSubmit} autoComplete="custom-form">
          {/* <Typography component={"h6"} variant={"h6"}>Reset Password</Typography> */}
          <div component={"h6"} variant={"h6"}>OTP</div>
          <FormControl fullWidth className={classes.formField}>
            <OutlinedInput
              required
              name="username"
              autoComplete="new-password"
              placeholder="Username"
              autoFocus
              disabled
              className='forgot'
              style={{ backgroundColor: "rgba(79, 198, 5, 0.20)" }}
              defaultValue={email}
              error={!!error.email}
              helperText={error.email ? error.email : null}
              onFocus={() => { setError(false) }}
              startAdornment={<InputAdornment position="start">
                <UserIcon htmlColor={"#ffffff80"} />
              </InputAdornment>
              }
            />
          </FormControl>
          <FormControl fullWidth className={classes.formField}>
            <OutlinedInput
              required
              name="otp"
              autoComplete="new-otp"
              placeholder="Enter OTP code"
              autoFocus
              type="number"
              value={otpNum}
              onChange={(e) => setOtpNum(e.target.value)}
              error={!!error.email}
              helperText={error.email ? error.email : null}
              onFocus={() => { setError(false) }}
              startAdornment={<InputAdornment position="start">
                <LockIcon htmlColor={"#ffffff80"} />
              </InputAdornment>
              }
            />
          </FormControl>

          {otpMethod &&
            <Grid className={classes.checkBox}>
              <p style={{ marginBottom: "15px" }}>Set your preferred OTP channel</p>
              {options.map((item) =>
              (
                <Grid>
                  <input
                    type="checkbox"
                    value={item.id}
                    onChange={() => handleClick(item.id)}
                    // defaultChecked={true}
                    checked={item.id === checked}
                    name={item.name}
                  />&nbsp;&nbsp;
                  {item.id == 1 ? <span>
                    TOTP Authentication APP <span style={{ cursor: "pointer" }} onClick={() => setStep_1(true)} >{<u style={{ display: checked === 1 && !entitySid ? undefined : "none" }}>(setup new device)</u>}</span></span> : item.name}
                </Grid>
              )
              )}
              {/* <Grid><input type="checkbox" defaultChecked={true} onClick={handleClick} />&nbsp;&nbsp;TOTP Authentication APP (recommended)</Grid>
                            <Grid><input type="checkbox" onClick={handleClick} />&nbsp;&nbsp;SMS / Text Message code</Grid>
                            <Grid><input type="checkbox" onClick={handleClick} />&nbsp;&nbsp;Email code</Grid> */}
            </Grid>
          }

          <div style={{ margin: '20px 0' }}>
            <span style={{ cursor: 'pointer', textDecoration: "underline" }} onClick={() => setOtpMethod(!otpMethod)}>
              Change OTP method?
            </span>
          </div>
          {otpMethod &&
            <div style={{ margin: '20px 0' }}>
              <Button
                type="button"
                className={classes.submit}
                disabled={loading}
                name="Next"
                onClick={handleClickNext}
                fullWidth
              >
                {"Next"}
              </Button>
            </div>}
          {!otpMethod &&
            <>
              <Button
                type="submit"
                className={classes.submit}
                disabled={loading}
                fullWidth
                name="log_in"
              >
                {"Log in"}
              </Button>
              <div style={{ textAlign: "center", marginTop: '15px' }}>
                <span style={{ marginTop: '20px', color: 'red' }}>{authState.error.message || null}</span>

              </div></>
          }
        </form> : <form spellcheck="false" className={classes.form}>
          <Typography style={{ width: '400px' }} align="center" variant="subtitle1" gutterBottom
            className={classes.message}>
            {message.message}
          </Typography>
          {!message.success && <Button
            fullWidth
            className={classes.submit}
          // onClick={() => setMessage(null)}
          >
            Retry
          </Button>}
          {message.message &&
            <Typography align="center" variant="subtitle2"
              style={{ cursor: 'pointer', margin: '20px 0' }}
              onClick={() => props.history.push('/auth/signin')}>
              Sign in
            </Typography>
          }
        </form>}
      </div>
      <div className={classes.brandLogo}>
        <img src={'/mro-pro-logo.png'} alt={"mro pro logo"} />
      </div>
      {checked === 1 && step_1 &&
        <TotpAuth
          open={step_1}
          handleClose={() => setStep_1(false)}
          setOtpMethod={setOtpMethod}
          otpMethod={otpMethod}
          setCheckBoxState={setStep_1}
          factAndEntityId={factAndEntityId}
          email={email}
          password={password}
          application={application}
          browser={browser}
          setFactAndEntityId={setFactAndEntityId}
          dispatch={dispatch}
          authMethod={authMethod}
        />}
    </div>
  );
}