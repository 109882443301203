// import request from "./common/api_client";
import requestrApi from "./common/boxr_api_client";

function signUp(formData) {
    return requestrApi({
        url: '/auth/',
        method: 'POST',
        data: formData,
        handleHeaders: 2
    });
}

function login(formData) {
    return requestrApi({
        url: '/auth/sign_in',
        method: 'POST',
        data: formData,
        handleHeaders: 1,
    });
}

function logout() {
    return requestrApi({
        url: '/auth/sign_out',
        method: 'DELETE',
        handleHeaders: 0,
    });
}

function changePassword(formData, headers) {
    return requestrApi({
        url: '/users/create_password',
        method: 'POST',
        data: formData,
        headers: headers
    });
}

function changePin(formData, headers) {
    return requestrApi({
        url: '/users/create_password',
        method: 'POST',
        data: formData,
        headers: headers
    });
}


function requestReset(formData) {
    return requestrApi({
        url: '/auth/password',
        method: 'POST',
        data: formData,
    });
}

function updatePassword(formData) {
    return requestrApi({
        url: '/users/change_password',
        method: 'POST',
        data: formData
    })
}
function postOrgData(formData) {
    return requestrApi({
        url: '/organisations/organisation_login',
        method: 'POST',
        data: formData,
        handleHeaders: 3,
    });
}

function verifyOtpEmail(params) {
    return requestrApi({
        url: '/users/verify_by_email',
        method: 'POST',
        params
    });
}
function verifyOtpPhone(params) {
    return requestrApi({
        url: '/users/verify_by_number',
        method: 'POST',
        params
    });
}
function verifyTOTPCode(params) {
    return requestrApi({
        url: '/users/verify_totp_code',
        method: 'POST',
        params
    });
}
function verifyTOTPQR(params) {
    return requestrApi({
        url: '/users/verify_qr_code',
        method: 'POST',
        params
    });
}
function sendOtpByEmail(params) {
    return requestrApi({
        url: '/users/send_otp_by_email',
        method: 'GET',
        params
    });
}
function sendOtpByPhone(params) {
    return requestrApi({
        url: '/users/send_otp_by_num',
        method: 'GET',
        params
    });
}
function sendQRCodeLink(params) {
    return requestrApi({
        url: '/users/send_otp_by_qr',
        method: 'GET',
        params
    });
}

const AuthClient = {
    verifyTOTPQR, verifyTOTPCode, sendQRCodeLink, verifyOtpPhone, verifyOtpEmail, sendOtpByPhone, sendOtpByEmail, signUp, login, logout, changePassword, requestReset, updatePassword, postOrgData, changePin
};

export default AuthClient;