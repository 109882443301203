import React, { useState } from "react";
import MROFullScreenRightDrawer from "components/fullwidth_dialog";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import Typography from "@material-ui/core/Typography";
import MROTextField from "components/form_components/TextField";
import FileUpload from "components/form_components/fileUpload";
import QuantityCounter from "components/quantity_counter";
import MROButton from "components/buttons";
import PositiveSwitch from "components/form_components/switch";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: "4%",
    backgroundColor: "#F7F8FA",
  },
  appBar: {
    backgroundColor: "#fff",
    color: "#000000CC",
    boxShadow: "0px 3px 6px #0000001A",
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  actionButtons: {
    paddingTop: "20px",
    paddingBottom: "33px",
    textAlign: "right",
    marginRight: "20px",
    "& button:not(:last-child)": {
      marginRight: "20px",
    },
  },
  content: {
    margin: "3%",
    backgroundColor: "#F7F8FA",
    // padding: "3% 6%",
    borderRadius: "0 0 5px 5px",
    minHeight: "fit-content",
  },
  inputField: {
    maxWidth: "328px",
  },
  input1: {
    height: 200,
    padding: 20,
  },
  input2: {
    height: 200,
    fontSize: "3em",
  },
}));

function CalendarActionDetails(props) {
  const classes = useStyles();
  const { handleClose, action } = props;
  const [files, setFiles] = useState([]);

  return (
    <div>
      <MROFullScreenRightDrawer open={true}>
        <AppBar position="static" className={classes.appBar} elevation={0}>
          <Toolbar>
            <IconButton
              onClick={handleClose}
              edge="start"
              className={classes.backButton}
              color="inherit"
              aria-label="back"
            >
              <BackIcon />
            </IconButton>
            <Typography variant="subtitle2">
              {action === "EditCalendarInfo"
                ? "Edit Calendar Action"
                : "Add Calendar Action"}
            </Typography>
          </Toolbar>
        </AppBar>

        <Grid container xs={12} spacing={1}>
          <Grid item xs={6}>
            <div className={classes.wrapper}>
              <Grid>
                <Typography
                  color="primary"
                  varient="h3"
                  style={{ marginBottom: "10px" }}
                >
                  Action Details
                </Typography>
              </Grid>

              <Grid item xs={10}>
                <MROTextField
                  name={"action_stage"}
                  label={"Action & Stage Title *"}
                  placeholder={"Action & Stage Title - Max 50 Characters"}
                  requird
                  InputLabelProps={{ shrink: true }}
                ></MROTextField>
              </Grid>
              <Grid item xs={6}>
                <MROTextField
                  name={"mech_auth"}
                  label={"Mech Auth *"}
                  placeholder={"Select"}
                  select
                  requird
                  InputLabelProps={{ shrink: true }}
                ></MROTextField>
              </Grid>
              <Grid item xs={6}>
                <MROTextField
                  name={"Insp_auth"}
                  label={"Insp Auth *"}
                  placeholder={"Select"}
                  select
                  requird
                  InputLabelProps={{ shrink: true }}
                ></MROTextField>
              </Grid>
              <Grid item xs={6}>
                <QuantityCounter
                  name={"estimate_man_hrs"}
                  label={"Planned Man-Hrs *"}
                  placeholder={"Man-Hours required"}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={6}>
                <MROTextField
                  name={"ata_chapter"}
                  label={"ATA Chapter *"}
                  placeholder={"Select"}
                  select
                  requird
                  InputLabelProps={{ shrink: true }}
                ></MROTextField>
              </Grid>
              <Grid item xs={6}>
                <MROTextField
                  name={"ata_chapter"}
                  label={"Tech Data Ref *"}
                  placeholder={"Enter tech data reference for the task"}
                  requird
                  InputLabelProps={{ shrink: true }}
                ></MROTextField>
              </Grid>
              <Grid item xs={6}>
                <QuantityCounter
                  name={"frequency_days"}
                  label={"Frequency (days) *"}
                  placeholder={"Days"}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={6}>
                <departureTimeCalcFields
                  name={"Desired Date"}
                  label={"Last Completed *"}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              <div>
                <span>
                  Operator / CAMO Part M technical records applicable each time?
                </span>
                &nbsp;&nbsp;
                <span>
                  <PositiveSwitch
                    edge="end"
                    checked={true}
                    inputProps={{ "aria-labelledby": "on-or-off" }}
                    // onChange={(e) => setShow(!show)}
                  />
                </span>
              </div>

              <FileUpload
                //onDelete={handleDeleteCertificates}
                //defaultValue={certificatesData}
                setFiles={setFiles}
                multiple={true}
                //name={"documents[]"}
                action={"Upload Document"}
              />
            </div>
          </Grid>

          <Grid item xs={6}>
            <div className={classes.content}>
              <form noValidate>
                <Typography
                  variant="body1"
                  color="primary"
                  className={classes.title}
                ></Typography>{" "}
                <Grid
                  item
                  container
                  justify="space-between"
                  alignItems="center"
                >
                  <Typography
                    color="primary"
                    varient="h3"
                    //   style={{ fontWeight: "bold" }}
                  >
                    Stage Details
                  </Typography>
                </Grid>
                <Grid
                  container
                  // spacing={1}
                  alignItems={"center"}
                  justify={"flex-start"}
                >
                  <Grid container alignItems={"center"} justify={"flex-start"}>
                    <Grid item xs={12}>
                      <MROTextField
                        name={"description"}
                        label={"Detailed Description *"}
                        placeholder="Detailed Stage Description - Max 2000 Characters"
                        InputProps={{ style: { height: 200, width: 500 } }}
                        InputLabelProps={{ shrink: true }}
                        multiline
                        className={classes.inputField}
                      />
                    </Grid>
                  </Grid>

                  <>
                    <Grid item container style={{ marginTop: "25px" }}>
                      <Typography
                        varient="h3"
                        color="primary"
                        //   style={{ fontWeight: "bold" }}
                      >
                        Stage Details
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography>
                        Upto 10 Sub Fields can be used to capture text E.g.
                        torque values, temp readings etc.
                      </Typography>
                    </Grid>

                    <Grid container spacing={2} item xs={12}>
                      <Grid item xs={6}>
                        <MROTextField
                          name={"sub_field1"}
                          label={"Sub Field1"}
                          placeholder="Time Mixed(mins)"
                          InputLabelProps={{ shrink: true }}
                          className={classes.inputField}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <MROTextField
                          name={"sub_field6"}
                          label={"Sub Field6"}
                          placeholder="Description - Max 50 Characters"
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ classes: { input: classes.input1 } }}
                          className={classes.inputField}
                        ></MROTextField>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} item xs={12}>
                      <Grid item xs={6}>
                        <MROTextField
                          name={"sub_field2"}
                          label={"Sub Field2"}
                          placeholder="Humidity @ time of application "
                          InputLabelProps={{ shrink: true }}
                          className={classes.inputField}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <MROTextField
                          name={"sub_field7"}
                          label={"Sub Field7"}
                          placeholder="Description - Max 50 Characters"
                          InputLabelProps={{ shrink: true }}
                          className={classes.inputField}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} item xs={12}>
                      <Grid item xs={6}>
                        <MROTextField
                          name={"sub_field3"}
                          label={"Sub Field3"}
                          placeholder="Temp @ time of application"
                          InputLabelProps={{ shrink: true }}
                          className={classes.inputField}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <MROTextField
                          name={"sub_field8"}
                          label={"Sub Field8"}
                          placeholder="Description - Max 50 Characters"
                          InputLabelProps={{ shrink: true }}
                          className={classes.inputField}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} item xs={12}>
                      <Grid item xs={6}>
                        <MROTextField
                          name={"sub_field4"}
                          label={"Sub Field4"}
                          placeholder="Final application time"
                          InputLabelProps={{ shrink: true }}
                          className={classes.inputField}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <MROTextField
                          name={"sub_field9"}
                          label={"Sub Field9"}
                          placeholder="Description - Max 50 Characters"
                          InputLabelProps={{ shrink: true }}
                          className={classes.inputField}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} item xs={12}>
                      <Grid item xs={6}>
                        <MROTextField
                          name={"sub_field5"}
                          label={"Sub Field5"}
                          placeholder="Description - Max 50 Characters"
                          InputLabelProps={{ shrink: true }}
                          className={classes.inputField}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <MROTextField
                          name={"sub_field10"}
                          label={"Sub Field10"}
                          placeholder="Description - Max 50 Characters"
                          InputLabelProps={{ shrink: true }}
                          className={classes.inputField}
                        />
                      </Grid>
                    </Grid>
                  </>
                </Grid>
              </form>
            </div>
          </Grid>
        </Grid>

        <div className={classes.actionButtons}>
          <MROButton variant={"contained"}>Cancel</MROButton>
          <MROButton variant={"contained"} color={"primary"} type={"submit"}>
          {action === "EditCalendarInfo"
                ? "Update"
                : "Add"}
          </MROButton>
        </div>
      </MROFullScreenRightDrawer>
    </div>
  );
}

export default CalendarActionDetails;
