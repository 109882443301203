import boxpUpdateData from "./boxp_update_data_service";
import BoxpCreateData from "./boxp_create_data_service";
import boxpDeleteData from "./boxp_delete_data";
import { getData } from "./listing_service";
import { updateDataWithParams } from './update_data_service';

const BoxpDataService = {
    getData,
    BoxpCreateData,
    boxpUpdateData,
    boxpDeleteData,
    updateDataWithParams,
}
export default BoxpDataService;