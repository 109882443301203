
import React, { useState, useContext, useEffect } from "react";
import MROFullScreenRightDrawer from "components/fullwidth_dialog";
import {
    AppBar,
    IconButton,
    Toolbar,
    makeStyles,
    Typography,
    Box,
    ButtonGroup,
    MenuItem,
    Select,
    InputBase,
    FormControl,
} from "@material-ui/core";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import { Visibility as ViewIcon } from "@material-ui/icons";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import MaterialTable from "material-table";
import tableIcons, { options } from "components/universal/table_attributes";
import { CommercialServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/commercial";
import { _formatDate, format24HrDateOnly, getOrganisationId } from "lib/utils/common_utils";
import { ICON_COLOUR } from "lib/constants/style_constants";
import PriceBreaks from "../../QuotePopUp/PriceBreaks";
import { ToastMessageContext } from "lib/contexts/message_context";

const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: "#fff",
        color: "#000000CC",
        boxShadow: "0px 3px 6px #0000001A",
    },
    backButton: {
        marginRight: theme.spacing(2),
    },
    root: { "& > :first-child": { position: "unset" } },
    wrapper: {
        padding: "3%",
        background: "white",
    },
    alert: {
        display: "flex",
        justifyContent: "center",
    }, partKey: {
        fontSize: "14px",
        // padding: "5px",
    },
    partValue: {
        fontSize: "14px",
        color: "#4D4F5C",
        padding: "5px",
        display: "flex",
        alignItems: "center",
    },

}));

export default function Historcial(props) {


    const { handleClose, currentSelection, checked, onClose, currentWorkshopDetails, setInput, input, name, salesRate, setTatDays, setTatquantity, setStandardId, } = props
    const classes = useStyles();
    const [loading, setLoading] = useState(false)
    const [orderDetails, setOrderDetails] = useState({})
    const [approvedDetails, setApprovedDetails] = useState([])
    const [costDetails, setCostDetails] = useState([])
    const [action, setAction] = useState(null)
    const [filters, setFilters] = useState({ cost_performance: "initial" })

    const message = useContext(ToastMessageContext)
    const tableOptions = {
        ...options,
        paging: false,
        search: false,
        toolbar: false
    };

    const handleFilterChange = (keyItem, value) => {

        setFilters((prevState) => ({ ...prevState, [keyItem]: value }));
    };
    const getDetails = async () => {
        const params = {
            organisation_id: getOrganisationId(),
            show_per_unit_values: checked,
            id: currentSelection?.id,
            ...filters


        }
        setLoading(true)
        await CommercialServices.getHistoricalJobsDetails(params).then((res) => {
            setOrderDetails(res?.data?.order_details)
            setApprovedDetails(res?.data?.approved_values)
            setCostDetails(res?.data?.cost_performance)
        }).catch(() => {
            message.showToastMessage({
                message: "Something went wrong. Try again!",
                variant: "error",
            });
        }).finally(() => {
            setLoading(false)
        })




    }

    useEffect(() => {
        getDetails()
    }, [filters])



    const handleCalculation = (rowData) => {
        let saleRate = +currentWorkshopDetails?.salesRate || +salesRate
        let quantity = +currentWorkshopDetails?.quantity
        let labour = rowData?.labour ? +rowData.labour * quantity : 0
        let parts = rowData?.parts ? +rowData?.parts * quantity : 0
        let services = rowData?.service ? +rowData?.service * quantity : 0
        let equipment = rowData?.equipment ? +rowData?.equipment * quantity : 0

        let labourValue = parseFloat(labour / saleRate).toFixed(2)
        let partsValue = parseFloat(parts / saleRate).toFixed(2)
        let servicesValue = parseFloat(services / saleRate).toFixed(2)
        let equipmentValue = parseFloat(equipment / saleRate).toFixed(2)
        // console.log("labour===", labour, labour / saleRate)
        if (name === "quote") {
            if (orderDetails?.workshop_standard_price_id !== null) { setStandardId(orderDetails?.workshop_standard_price_id) }
            setTatquantity(rowData?.tat ? +rowData?.tat : 0)

            setInput({
                // tat_days: +input?.tat_days || "0",
                labour: +labourValue || "0",
                parts: +partsValue || "0",
                services: +servicesValue || "0",
                equipment: +equipmentValue || "0",
                aog_fee: +input?.aog_fee || "0",
            });
            onClose()
        }

        else {
            setInput({
                tat_days: +rowData?.tat || "0",
                labour_value: +labourValue || "0",
                parts_value: +partsValue || "0",
                service_value: +servicesValue || "0",
                equipment_value: +equipmentValue || "0",
                aog_fee: +input?.aog_fee || "0",
            });
            onClose()
            // setTatDays(+rowData?.tat)
        }





    }



    const columns = [
        {
            title: " ",
            field: "title",
            width: '28%',
            headerStyle: {
                width: '28%',
                textAlign: "left",
                paddingLeft: "20px",
            },
            cellStyle: {
                width: '28%',
                textAlign: "left",
                paddingLeft: "20px",
            },
        },
        {
            title: "Quantity",
            field: "quantity" || "0.00",

        },

        {
            title: "TAT",
            field: "tat" || "0.00",

        },

        {
            title: "Labour *",
            field: "labour",
            render: (rowData) => <span style={{ color: rowData.labour < 0 ? "#FF0202" : "#4FC605" }}> {rowData?.labour ? parseFloat(rowData?.labour).toFixed(2) : "0.00 "} </span>,
        },
        {
            title: "Parts",
            field: "parts" || "0.00",
            render: (rowData) => <span style={{ color: +rowData.parts < 0 ? "#FF0202" : "#4FC605" }}> {rowData?.parts ? parseFloat(rowData?.parts).toFixed(2) : "0.00 "} </span>,
        },
        {
            title: "Services",
            field: "service" || "0.00",
            render: (rowData) => <span style={{ color: +rowData.services < 0 ? "#FF0202" : "#4FC605" }}> {rowData?.service ? parseFloat(rowData?.service).toFixed(2) : "0.00 "} </span>,
        },
        {
            title: "Equipment",
            field: "equipment" || "0.00",
            render: (rowData) => <span style={{ color: +rowData.equipment < 0 ? "#FF0202" : "#4FC605" }}> {rowData?.equipment ? parseFloat(rowData?.equipment).toFixed(2) : "0.00 "} </span>,
        },
        {
            title: "Other",
            field: "aog_fee" || "0.00"
        },
        {
            title: "Total",
            field: "total" || "0.00",
            render: (rowData) => <span style={{ color: +rowData.total < 0 ? "#FF0202" : "#4FC605" }}> {rowData?.total ? parseFloat(rowData?.total).toFixed(2) : "0.00 "} </span>,
        },
        {
            title: "Currency",
            field: "currency",
        },
        {
            title: "Action",
            render: (rowData) => (
                <IconButton onClick={() => {
                    handleCalculation(rowData)
                }}>
                    <TouchAppIcon />
                </IconButton>
            ),
        },
    ];

    const jobsColumns = [
        {
            title: <FormControl className={"filter"}>
                <Select
                    fullWidth
                    displayEmpty
                    id="tags-standard"
                    defaultValue={"initial"}
                    input={<InputBase />}
                    onChange={(e) =>
                        handleFilterChange("cost_performance", e.target.value)
                    }
                >
                    <MenuItem value={"initial"}>Initial</MenuItem>
                    <MenuItem value={"exceptions"}>Exceptions</MenuItem>
                    <MenuItem value={"overall"}>Overall</MenuItem>
                </Select>
            </FormControl>,
            field: "title",
            width: '28%',
            headerStyle: {
                width: '28%',
                textAlign: "left",
                paddingLeft: "20px",
            },
            cellStyle: {
                width: '28%',
                textAlign: "left",
                paddingLeft: "20px",
            },
        },


        {
            title: "Labour *",
            field: "labour",
            render: (rowData) => <span style={{ color: rowData.labour < 0 ? "#FF0202" : "#4FC605" }}> {rowData?.labour ? parseFloat(rowData?.labour).toFixed(2) : "0.00 "} </span>,
        },
        {
            title: "Parts",
            field: "parts" || "0.00",
            render: (rowData) => <span style={{ color: +rowData.parts < 0 ? "#FF0202" : "#4FC605" }}> {rowData?.parts ? parseFloat(rowData?.parts).toFixed(2) : "0.00 "} </span>,
        },
        {
            title: "Services",
            field: "service" || "0.00",
            render: (rowData) => <span style={{ color: +rowData.services < 0 ? "#FF0202" : "#4FC605" }}> {rowData?.service ? parseFloat(rowData?.service).toFixed(2) : "0.00 "} </span>,
        },
        {
            title: "Equipment",
            field: "equipment" || "0.00",
            render: (rowData) => <span style={{ color: +rowData.equipment < 0 ? "#FF0202" : "#4FC605" }}> {rowData?.equipment ? parseFloat(rowData?.equipment).toFixed(2) : "0.00 "} </span>,
        },
        {
            title: "Other",
            field: "aog_fee" || "0.00"
        },
        {
            title: "Total",
            field: "total" || "0.00",
            render: (rowData) => <span style={{ color: +rowData.total < 0 ? "#FF0202" : "#4FC605" }}> {rowData?.total ? parseFloat(rowData?.total).toFixed(2) : "0.00 "} </span>,
        },
        {
            title: "Currency",
            field: "currency",
        },

    ];
    const GetInfoCard = (props) => {
        // const classes = useStyles();
        return (
            <Box display="flex" pl={'8px'}>
                <Box flex={50} >
                    <Typography style={{ padding: "5px" }} className={classes.partKey} color="primary">
                        {props?.keyName}
                    </Typography>
                </Box>
                <Box flex={50} className={classes.partValue}>
                    {props?.value ?? "-"}
                </Box>
            </Box>
        );
    };



    return (
        <MROFullScreenRightDrawer open={true} className={classes.root}>
            <AppBar position="static" className={classes.appBar} elevation={0}>
                <Toolbar>
                    <IconButton
                        onClick={() => {
                            handleClose()
                        }}
                        edge="start"
                        className={classes.backButton}
                        color="inherit"
                        aria-label="back"
                    >
                        <BackIcon />
                    </IconButton>
                    <Typography variant="subtitle2">Historical Job</Typography>
                </Toolbar>
            </AppBar>
            <div className={classes.wrapper}>


                <Typography variant="subtitle1" color="primary" style={{ marginBottom: '10px' }}>Order Details</Typography>


                <div style={{ width: "100%", marginBottom: "20px" }}>
                    <Box
                        display="flex"
                        alignItems="center"
                        // justifyContent={(loading || _.isEmpty(currentSelection)) && "center"}
                        bgcolor="#F5F5F5"
                        className={classes.partCard}
                    >
                        <>
                            <Box flexGrow={1}>
                                <div style={{ width: "100%" }}>
                                    <GetInfoCard keyName={"Order #"} value={orderDetails?.order_number} />
                                    <GetInfoCard keyName={"Date Completed"} value={orderDetails?.crs_date ? _formatDate(orderDetails?.crs_date) : ""} />
                                    <GetInfoCard keyName={"Description of Works"} value={orderDetails?.description} />

                                </div>
                            </Box>

                            <Box flexGrow={1}>
                                <div style={{ width: "100%" }}>
                                    <GetInfoCard
                                        keyName={"Customer"}
                                        value={orderDetails?.name}

                                    />
                                    <GetInfoCard
                                        keyName={"Quantity"}
                                        value={orderDetails?.quantity}

                                    />
                                    <GetInfoCard
                                        keyName={"Price Breaks"}
                                        value={orderDetails?.price_breaks ? <a onClick={() => { setAction("price_breaks") }} style={{ textDecoration: 'underline' }}>{orderDetails?.price_breaks}</a> : ""}

                                    />

                                </div>
                            </Box>

                            <Box flexGrow={1} alignSelf="flex-start">
                                <div style={{ width: "100%" }}>
                                    <GetInfoCard
                                        keyName={"Workshop Report"}
                                        value={<ViewIcon
                                            onClick={() => {
                                                if (orderDetails?.workshop_report.length > 0) {
                                                    window.open(orderDetails?.workshop_report[0]?.file_path)
                                                }
                                                else {
                                                    message.showToastMessage({
                                                        message: "No Report Found !",
                                                        variant: "error",
                                                    });
                                                }

                                            }}
                                            style={{ cursor: "pointer" }}
                                            fontSize="small"
                                            htmlColor={ICON_COLOUR}
                                        />}
                                    />
                                    <GetInfoCard
                                        keyName={"Commercial Report"}
                                        value={<ViewIcon
                                            onClick={() => {
                                                if (orderDetails?.commercial_report.length > 0) {

                                                    window.open(orderDetails?.commercial_report[0]?.file_path)
                                                }
                                                else {
                                                    message.showToastMessage({
                                                        message: "No Report Found !",
                                                        variant: "error",
                                                    });
                                                }

                                            }}
                                            style={{ cursor: "pointer" }}
                                            fontSize="small"
                                            htmlColor={ICON_COLOUR}
                                        />}
                                    />
                                    <GetInfoCard
                                        keyName={""}
                                        value={""}
                                    />

                                </div>
                            </Box>


                        </>
                    </Box>
                </div>
                <Typography variant="subtitle1" color="primary">Approval Values</Typography>


                <div style={{ marginBottom: '20px' }}>
                    <MaterialTable
                        style={{
                            boxShadow: "0px 1px 3px #00000033",
                            marginTop: "10px",
                        }}
                        icons={tableIcons}
                        classes
                        title={""}
                        columns={columns}
                        data={approvedDetails || []}
                        // isLoading={loading}
                        options={tableOptions}

                    />
                </div>
                <Typography variant="subtitle1" color="primary">Cost Performance</Typography>


                <div style={{ marginBottom: '10px' }}>
                    <MaterialTable
                        style={{
                            boxShadow: "0px 1px 3px #00000033",
                            marginTop: "10px",
                        }}
                        icons={tableIcons}
                        classes
                        title={""}
                        columns={jobsColumns}
                        data={costDetails || []}
                        isLoading={loading}
                        options={tableOptions}

                    />
                </div>
                {action === "price_breaks" &&
                    <PriceBreaks
                        open={action}
                        handleClose={() => setAction(null)}
                        workshop_order_id={orderDetails?.workshop_order_id}
                    />
                }




            </div>



        </MROFullScreenRightDrawer>
    )
}
