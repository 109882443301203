import {
    FormControl,
    IconButton,
    InputBase,
    makeStyles,
    MenuItem,
    Menu,
    Switch,
    Select, FormControlLabel, Grid, Chip, Typography, Divider, Box, CircularProgress, Button,
    Paper,
    TextField
} from "@material-ui/core";
import SearchBarWithFilters from "components/search_with_filters";
import React, { useContext, useEffect, useReducer, useState, useMemo } from "react";
import ArrowDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import { ICON_COLOUR } from "lib/constants/style_constants";
import moment, { months } from "moment";
import { ToastMessageContext } from "lib/contexts/message_context";
import { alpha, useTheme } from "@material-ui/core";
// Range picker
import { DatePicker } from "antd";
import PositiveSwitch from "components/form_components/switch";
import _without from "lodash/without";
import ChartLoader from "../Storage/ChartLoader";
import { MaintenanceServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation";
import { format24HrDateTime, getOrganisationId } from "lib/utils/common_utils";
import ReactApexChart from "react-apexcharts";
import CommericalTable from "./CommericalTable"
import CameraIcon from "assets/Dashboard/Camera.svg"
import PartsIconGrey from "assets/maint/Parts/partsIconGray.png";
import ServicesIconGray from "assets/maint/Services/services_icon_grey.png";
import SpecEquipIconGrey from "assets/maint/SpecEquip/specEquipGray.png";
import { WorkshopDashboardServices } from "lib/services/api/dashboard/workshop";
import _ from "lodash";
import StoppedOrders from "../Maintenance/StoppedOrders";
import ArrowUpwardIcon from 'assets/Dashboard/arrowupwardGreen.svg'
import ArrowDownwardIcon from 'assets/Dashboard/arrowdownwardRed.svg'
import StopIcon from '@material-ui/icons/Stop';
import Line from '@material-ui/icons/Minimize';
import Chart from "../Maintenance/Chart New"
const useStyles = makeStyles((theme) => ({
    root: {
        width: "95%",
        margin: "-1% 2%",
        paddingLeft: '13px',
        '&.MuiStepConnector-root': {
            borderTopWidth: '10px'
        }
    },

    formWrapper: {
        width: "95%",
        margin: "0% 3%",

    },

    monthText: {
        fontSize: '12px',
        marginTop: '-9px',
        marginLeft: "11px",
        width: "104px",
        background: "#fff",

    },
    secondaryColor: {
        color: theme.palette.secondary.main

    },
    quotePercentageText: {
        marginTop: ' -87px',
        marginBottom: ' 53px',
        textAlign: ' center',
        fontSize: '22px',
        color: "#4FC605",
        paddingLeft: "9px"
    },
    centerLabel: {
        textAlign: ' center',
        marginTop: ' -50px',
        marginBottom: '66px',
        color: '#4D4F5C',
        fontSize: '12px'
    },
    quoteText: {
        fontSize: '12px',
        paddingTop: "4px",
        paddingLeft: "7px"
    },

    chartText: {
        paddingRight: '10px', paddingBottom: '15px', textDecoration: "underline", cursor: "pointer", WebkitUserSelect: "all", WebkitAppearance: 'none'
    },
    month: {
        fontSize: "12px"
    },
    lengends: {
        marginTop: "3px",
        fontSize: "13px"
    },
    line: {
        marginTop: "5px",
        fontSize: "13px"
    },
    wipText: {
        fontSize: "13px"
    }


}));


export default function Production(props) {

    const classes = useStyles();
    const theme = useTheme()
    const [loading, setLoading] = useState(false);
    const [filterData, setFilterData] = useState("otp");
    const [customerDetails, setCustomerDetails] = useState([]);
    const message = useContext(ToastMessageContext);
    const [bases, setBases] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [selectedBase, setSelectedBase] = useState([]);
    const [custButton, setCustButton] = useState(false)
    const [checked, setChecked] = useState(false)
    const [baseButton, setBaseButton] = useState(false)
    const [runTable, setRunTable] = useState(false)
    const [action, setAction] = useState('')
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorBase, setAnchorBase] = useState(null);
    const [refresh, setRefresh] = useState(false)
    //Completed details
    const [completedOrders, setCompletedOrders] = useState({})
    const [completedValues, setCompletedValues] = useState({})
    const [currentDate, setCurrentDate] = useState(null)
    const [previousDate, setPreviousDate] = useState(null)
    const [previousLastDate, setPreviousLastDate] = useState(null)
    const [twelveMonthsBarData, setTwelveMonthsBarData] = useState([])
    const [twelveMonthsLineData, setTwelveMonthsLineData] = useState([])

    const [progressLoading, setProgressLoading] = useState(false);
    const [wipData, setWipData] = useState({})
    const [quotesData, setQuotesData] = useState({})
    const [commercialData, setCommercialData] = useState({})
    const [currentSelection, setCurrentSelection] = useState({ graph: "", key: "" })
    const [quotesPercentage, setQuotesPercentage] = useState(0);
    const [commercialPercentage, setCommercialPercentage] = useState(0);

    // Order Stage------


    const [state, setState] = useState({
        series: [


        ],

        options: {
            chart: {
                type: 'line',
                height: 350,
                stacked: true,
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                },

            },
            stroke: {
                width: [2, 0, 0, 0]
            },
            colors: ['#8CDA5C', '#FFCD59', '#FF5A5A'],
            // colors: [`${theme.palette.secondary.main}`],

            dataLabels: {
                enabled: false
            },

            xaxis: {

                labels: {
                    show: true,
                }

            },
            yaxis: {
                tickAmount: 5,
                labels: {
                    rotate: 0,
                },
            },

            grid: {
                show: true,
                strokeDashArray: 0,
                position: 'back',
                xaxis: {
                    lines: {
                        show: false
                    }
                },
                yaxis: {
                    lines: {
                        show: true
                    }
                },
            },
            responsive: [{
                breakpoint: 480,

            }],
            plotOptions: {
                bar: {
                    horizontal: false,
                    borderRadius: 0,
                    // columnWidth: '30%',
                    // barHeight: '80%',
                    dataLabels: {
                        enabled: false
                    },
                },


            },

            legend: {
                show: false
            },
            fill: {
                opacity: 1
            }
        },


    })


    const [column, setColumn] = useState({

        series: [],
        options: {
            chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                }
            },
            yaxis: {
                tickAmount: 5,
                labels: {
                    rotate: 0,
                },
            },
            dataLabels: {
                enabled: false
            },
            responsive: [{
                breakpoint: 480,

            }],
            plotOptions: {
                bar: {
                    horizontal: false,
                    borderRadius: 0,
                    // columnWidth: '30%',
                    // barHeight: '80%',
                    dataLabels: {
                        enabled: false
                    },

                },
            },

            legend: {
                show: false
            },
            fill: {
                opacity: 1
            }
        },




    })


    const [order, setOrder] = useState({

        series: [

            {
                name: "Order",
                data: [0, 0, 0, 0, 0, 0, 0]
            }


        ],
        options: {
            chart: {
                type: 'bar',
                height: 350,
                stacked: false,
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                }
            },
            dataLabels: {
                enabled: false
            },
            yaxis: {
                tickAmount: 5,
                labels: {
                    rotate: 0,
                },
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'top',
                        offsetX: -20,
                        offsetY: -40
                    }
                }
            }],
            plotOptions: {
                bar: {
                    horizontal: false,
                    borderRadius: 0,
                    columnWidth: '30%',
                    barHeight: '80%',
                    dataLabels: {
                        enabled: false
                    },

                },
            },

            legend: {
                position: 'top',
                horizontalAlign: "center",
                offsetX: 200,



            },
            fill: {
                opacity: 1
            }
        },




    })







    const getDetails = () => {
        const params = {
            order_type: "Production",
            organisation_id: getOrganisationId(),

            // ...filterData
        }
        selectedBase.forEach((item, index) => params[`base[${[index]}]`] = item)
        selectedFilters.forEach((item, index) => params[`customer[${[index]}]`] = item)
        setProgressLoading(true)
        handleCompleteDetails(params)
        handleInprogressDetails(params)
    }

    const handleCompleteDetails = async (params) => {

        await WorkshopDashboardServices.getCompletedOrders(params).then((res) => {
            if (res.success) {
                setCompletedOrders(res?.data[0].completed_orders)
                setCompletedValues(res?.data[0].completed_values)
                handleDates(res?.data[0])


            }
        }).catch((err) => {
            message.showToastMessage({
                message: "Something went wrong",
                variant: "error",
            });
        }).finally(() => {

        })
    }

    const handleDates = (data) => {
        let format = "YYYY-MM-DD"
        let curr = moment(data?.current_date, format).format("DD/MMM")
        let previous = moment(data?.previous_month_current_date, format).format("DD MMM")
        let lastMonth = moment(data?.pervious_month_last_date, format).format("MMM DD")
        setCurrentDate(curr)
        setPreviousDate(previous)
        setPreviousLastDate(lastMonth)

    }
    const handleInprogressDetails = async (params) => {


        await WorkshopDashboardServices.getInprogressWorkOrder(params).then((response) => {
            if (response.success) {
                setWipData(response?.data[0]?.wip)
                setQuotesData(response?.data[0]?.open_quotes)
                setCommercialData(response?.data[0]?.open_commercial_positions)
                let orderStage = Object.values(response?.data[0]?.order_stage)
                let orderStageU = _.transform(response?.data[0]?.order_stage, (result, val, key) => {
                    result[key.charAt(0).toUpperCase() + key.slice(1)] = val;
                })

                let key = Object.keys(orderStageU)
                if (orderStage !== undefined) {

                    setOrder((prevState) => {
                        return {
                            // ...updatedState,
                            series: [

                                {
                                    name: "Order",
                                    data: orderStage
                                }

                            ],
                            options: {

                                xaxis: {
                                    categories: key,
                                    labels: {
                                        show: true,
                                    }

                                },
                                colors: [`${theme.palette.secondary.main}`],
                            }
                        }
                    })


                }
            }
        }).catch((err) => {
            message.showToastMessage({
                message: "Something went wrong",
                variant: "error",
            });
        }).finally(() => {
            setProgressLoading(false)
            setRefresh(false)
        })


    }


    useEffect(() => {
        getDetails()

    }, [selectedBase, selectedFilters, refresh]);
    useEffect(() => {

        getGraphDetails()
    }, [selectedBase, selectedFilters, filterData, checked]);




    const getGraphDetails = async () => {
        const params = {
            order_type: "Production",
            organisation_id: getOrganisationId(),
            data: filterData,
            compare_previous_year: checked
        }
        selectedBase.forEach((item, index) => params[`base[${[index]}]`] = item)
        selectedFilters.forEach((item, index) => params[`customer[${[index]}]`] = item)
        setLoading(true)
        await WorkshopDashboardServices.getTweleveMonthsOrder(params).then((response) => {
            if (response.success) {
                setTwelveMonthsBarData(response?.data?.bar)
                setTwelveMonthsLineData(response?.data?.line)

            }
        }).catch((err) => {
            message.showToastMessage({
                message: "Something went wrong",
                variant: "error",
            });
        }).finally(() => {
            setLoading(false)
        })
    }


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setCustButton(!custButton)
    };
    const handleBaseClick = (event) => {
        setAnchorBase(event.currentTarget);
        setBaseButton(!baseButton)
    };
    const handleClose = () => {
        setAnchorEl(null);
        setCustButton(!custButton)
    };




    const filterChangeHandler = (e) => {
        const { name, value, checked } = e.target
        let value1 = bases.map((item) => {

            if (item.id === parseInt(value)) {
                item.status = !item.status
            }
            return item
        })
        // setRunTable(false)
        setSelectedBase((prevState) => [...prevState, value])
        if (!checked) {
            setSelectedBase((current) => _without(current, value));
        }

    }





    const filterCustomerChangeHandler = (e) => {
        const { name, value, checked } = e.target
        // console.log("customer======", value, checked)

        let value1 = customerDetails.map((item) => {

            if (item.id === parseInt(value)) {
                item.status = !item.status
            }
            return item
        })

        setSelectedFilters((prevState) => [...prevState, value])
        if (!checked) {
            setSelectedFilters((current) => _without(current, value));
        }

    }




    useEffect(() => {
        getCustomerList();
        getBaseStation();
    }, []);

    const getCustomerList = async () => {
        const params = {
            organisation_id: getOrganisationId(),
            order_by: 'name'
        }
        await MaintenanceServices.getCustomerList(params)
            .then((res) => {
                if (res) {



                    let data = res.data.customers
                    if (data.length > 0) {
                        let value = data.map((item) => ({
                            ...item,
                            status: false
                        }))
                        setCustomerDetails(value);
                    }
                    else {

                        setCustomerDetails(res.data.customers);

                    }
                }

            })
            .catch((err) => {
                message.showToastMessage({
                    message: "Something went wrong. Try again!",
                    variant: "error",
                });

            });
    };
    const getBaseStation = async () => {
        const params = {
            organisation_id: getOrganisationId(),
            order_by: 'station'
        }
        MaintenanceServices.getBaseStation(params)
            .then((res) => {
                if (res) {


                    let data = res.data
                    if (data.length > 0) {
                        let value = data.map((item) => ({
                            ...item,
                            status: false
                        }))
                        setBases(value);
                    }
                    else {

                        setBases(res.data);

                    }


                }

            })
            .catch((err) => {
                message.showToastMessage({
                    message: "Something went wrong. Try again!",
                    variant: "error",
                });

            });
    };
    const ITEM_HEIGHT = 78;


    // chart Options last twelve month


    const bar1 = []
    const bar2 = []
    const bar3 = []
    const line = []
    const date = []










    useMemo(() => {
        if (filterData === "otp") {
            if (twelveMonthsBarData != []) {
                for (let i = 0;i < twelveMonthsBarData.length;i++) {
                    bar1.push(twelveMonthsBarData[i].on_time)
                    bar2.push(twelveMonthsBarData[i].revised_on_time)
                    bar3.push(twelveMonthsBarData[i].late)
                    date.push(moment(twelveMonthsBarData[i].date).format("MMM D"))



                }

                if (twelveMonthsLineData.length > 0) {
                    for (let l = 0;l < twelveMonthsLineData.length;l++) {
                        line.push(parseInt(twelveMonthsLineData[l].previous_year_data))
                    }
                }
                if (bar1.length > 0 && !checked) {
                    setColumn((prevState) => {

                        const updatedState = _.clone(prevState);

                        return {
                            ...updatedState,
                            series: [
                                {
                                    name: 'On time',
                                    data: bar1,
                                },
                                {
                                    name: 'Revised',
                                    data: bar2,

                                },
                                {
                                    name: 'Late',
                                    data: bar3,

                                },],
                            options: {
                                ...updatedState.options,

                                xaxis: {
                                    categories: date,
                                    labels: {
                                        show: true,
                                    }

                                },
                                dataLabels: {
                                    enabled: false
                                },
                                colors: ['#8CDA5C', '#FFCD59', "#FF0202"],
                            }
                        }
                    })



                }

                else if (date.length > 0 && checked) {
                    setState((prevState) => {

                        const updatedState = _.clone(prevState);

                        return {
                            ...updatedState,
                            series: [

                                {
                                    name: 'Due',
                                    data: line,
                                    type: 'line',
                                    order: 1

                                },
                                {
                                    name: 'On time',
                                    data: bar1,
                                    type: 'column',
                                    order: 2
                                },
                                {
                                    name: 'Revised',
                                    data: bar2,
                                    type: 'column',
                                    order: 3
                                },
                                {
                                    name: 'Late',
                                    data: bar3,
                                    type: 'column',
                                    order: 4
                                },],
                            options: {
                                ...updatedState.options,
                                xaxis: {
                                    categories: date,

                                    labels: {
                                        show: true,
                                    }

                                },
                                colors: ['#8CDA5C', '#8CDA5C', '#FFCD59', "#FF0202"],
                            }
                        }
                    })



                }
            }
        }

        if (filterData === "type") {

            if (twelveMonthsBarData != []) {
                for (let i = 0;i < twelveMonthsBarData.length;i++) {
                    bar1.push(twelveMonthsBarData[i].adhoc_true)
                    bar2.push(twelveMonthsBarData[i].adhoc_false)
                    date.push(moment(twelveMonthsBarData[i].date).format("MMM D"))



                }

            }
            if (twelveMonthsLineData.length > 0) {
                for (let l = 0;l < twelveMonthsLineData.length;l++) {
                    line.push(parseInt(twelveMonthsLineData[l].previous_year_data))
                }
            }

            if (date.length > 0 && !checked) {
                setColumn((prevState) => {

                    // const updatedState = _.clone(prevState);

                    return {
                        // ...updatedState,
                        series: [

                            {
                                name: 'ADHOC',
                                data: bar1,

                            },
                            {
                                name: 'Quoted',
                                data: bar2,

                            },
                        ],
                        options: {

                            chart: {
                                type: 'bar',
                                stacked: true,
                                toolbar: {
                                    show: false
                                },
                                zoom: {
                                    enabled: false
                                },

                            },
                            xaxis: {
                                categories: date,

                                labels: {
                                    show: true,
                                }

                            },
                            legend: {
                                show: false
                            },
                            dataLabels: {
                                enabled: false
                            },
                            colors: [`${theme.palette.primary.main}`, `${theme.palette.secondary.main}`],
                        }
                    }
                })



            }



            else if (bar1.length > 0 && checked) {
                setState((prevState) => {

                    const updatedState = _.clone(prevState);

                    return {
                        ...updatedState,
                        series: [

                            {
                                name: 'Due',
                                data: line,
                                type: 'line',
                                order: 1

                            },
                            {
                                name: 'ADHOC',
                                data: bar1,
                                type: 'column',
                                order: 2
                            },
                            {
                                name: 'Quoted',
                                data: bar2,
                                type: 'column',
                                order: 3
                            },
                        ],
                        options: {
                            ...updatedState.options,

                            xaxis: {
                                categories: date,

                                labels: {
                                    show: true,
                                }

                            },
                            dataLabels: {
                                enabled: false
                            },
                            colors: [`${theme.palette.secondary.main}`, `${theme.palette.primary.main}`, `${theme.palette.secondary.main}`],
                        }
                    }
                })



            }

        }
        if (filterData === "value") {

            if (twelveMonthsBarData != []) {
                for (let i = 0;i < twelveMonthsBarData.length;i++) {
                    bar1.push(twelveMonthsBarData[i].value)
                    date.push(moment(twelveMonthsBarData[i].date).format("MMM D"))


                }
            }
            if (twelveMonthsLineData.length > 0) {
                for (let l = 0;l < twelveMonthsLineData.length;l++) {
                    line.push(parseInt(twelveMonthsLineData[l].previous_year_data))
                }
            }

            if (bar1.length > 0 && !checked) {
                setColumn((prevState) => {


                    return {
                        // ...updatedState,
                        series: [

                            {
                                name: 'Value',
                                data: bar1,

                            },

                        ],
                        options: {

                            chart: {
                                type: 'bar',

                                stacked: false,
                                toolbar: {
                                    show: false
                                },
                                zoom: {
                                    enabled: false
                                },

                            },
                            xaxis: {
                                categories: date,
                                tickAmount: 5,
                                labels: {
                                    show: true,
                                }

                            },
                            legend: {
                                show: false
                            },
                            dataLabels: {
                                enabled: false
                            },
                            colors: [`${theme.palette.secondary.main}`],
                        }
                    }
                })



            }

            else if (bar1.length > 0 && checked) {


                setState((prevState) => {

                    const updatedState = _.clone(prevState);

                    return {
                        ...updatedState,
                        series: [

                            {
                                name: 'Previous line',
                                data: line,
                                type: 'line',
                                order: 1

                            },
                            {
                                name: 'Value',
                                data: bar1,
                                type: 'column',
                                order: 2
                            },

                        ],
                        options: {
                            ...updatedState.options,
                            chart: {
                                type: 'line',

                                stacked: true,
                                toolbar: {
                                    show: false
                                },
                                zoom: {
                                    enabled: false
                                },

                            },
                            xaxis: {
                                categories: date,

                                labels: {
                                    show: true,
                                }

                            },
                            legend: {
                                show: false
                            },
                            dataLabels: {
                                enabled: false
                            },
                            colors: [`${theme.palette.secondary.main}`, `${theme.palette.secondary.main}`],
                        }
                    }
                })



            }



        }






    }, [twelveMonthsBarData, twelveMonthsLineData])



    useMemo(() => {
        let totalValue = quotesData?.seven_days + quotesData?.seven_to_thirty_days + quotesData?.thirty_days
        let quotePercentage = (quotesData?.seven_days / totalValue) * 100

        if (totalValue === 0) {
            setQuotesPercentage(totalValue)
        }
        else {
            setQuotesPercentage(Math.floor(quotePercentage))
        }
    }, [quotesData?.seven_days, quotesData?.seven_to_thirty_days, quotesData?.thirty_days])


    useMemo(() => {
        let totalValue = commercialData?.approved + commercialData?.pending + commercialData?.no_position
        let quotePercentage = (commercialData?.approved / totalValue) * 100

        if (totalValue === 0) {
            setCommercialPercentage(totalValue)
        }
        else {
            setCommercialPercentage(Math.floor(quotePercentage))
        }
    }, [commercialData?.approved, commercialData?.pending, commercialData?.no_position])


    const handleLocalTime = (date) => {


        let localTime = moment.utc(date, "DD/MM/YYYY HH:mm").local().format("DD/MM/YYYY HH:mm");


        return localTime ? localTime : " - "

    }









    return (

        <div className={classes.formWrapper}>
            <SearchBarWithFilters noSearch padding={'4px 16px'}
            >
                <FormControl style={{ marginRight: '10px' }}>
                    <Button variant={"text"} size="small" aria-haspopup="true" onClick={handleClick} endIcon={custButton ? <ArrowDropUp /> : <ArrowDown />} style={{ fontSize: "14px", backgroundColor: anchorEl ? '#efefef' : "" }} >
                        {selectedFilters.length > 0 ? `Customer(${selectedFilters.length})` : 'Customer(All)'}
                    </Button>
                    <Menu
                        id="Customer"
                        anchorEl={anchorEl}
                        keepMounted
                        open={anchorEl}
                        onClose={handleClose}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                        transformOrigin={{ vertical: "top", horizontal: "left" }}
                        style={{ marginTop: '41px' }}
                        TransitionProps={false}
                        PaperProps={{
                            style: {
                                maxHeight: ITEM_HEIGHT * 4.2,
                                width: '39ch',
                                position: 'fixed',
                                top: '260px',

                            },
                        }}
                    >
                        {/* <MenuItem value="" style={{ marginLeft: '7px' }}>Customer(All)</MenuItem> */}
                        {customerDetails?.map((option, index) => {
                            return <Box>
                                <Grid container spacing={2}>
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={9} style={{
                                        display: "flex", alignItems: "center", fontSize: "14px",

                                    }}>
                                        <label>{option.name}</label>
                                    </Grid>
                                    <Grid item xs={2} style={{ marginLeft: '-23px' }}>
                                        <FormControlLabel

                                            control={<PositiveSwitch
                                                checked={option.status}
                                                value={option.id}
                                                onChange={(e) => { filterCustomerChangeHandler(e) }}
                                                name={option.name} />}
                                        />
                                    </Grid>

                                </Grid>
                                <Grid><Divider variant="middle" /></Grid>
                            </Box>
                        })}
                    </Menu>
                </FormControl>

                <FormControl >
                    <Button variant={anchorBase ? "contained" : "text"} size="small" aria-haspopup="true" onClick={handleBaseClick} endIcon={baseButton ? <ArrowDropUp /> : <ArrowDown />} style={{ fontSize: "14px", backgroundColor: anchorBase ? '#efefef' : "" }}>
                        {selectedBase.length > 0 ? `Base(${selectedBase.length})` : 'Base(All)'}

                    </Button>
                    <Menu
                        id="Base"
                        anchorEl={anchorBase}
                        keepMounted
                        open={Boolean(anchorBase)}
                        onClose={() => {
                            setAnchorBase(null)
                            setBaseButton(!baseButton)
                        }}
                        // anchorOrigin={{
                        //     vertical: 'bottom',
                        //     horizontal: 'left',
                        // }}
                        style={{ marginTop: '40px' }}
                        PaperProps={{
                            style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: '39ch',
                                position: 'absolute',
                                top: '260px',
                            },
                        }}
                    >
                        {
                            bases?.map((base, index) => {
                                return <Box>
                                    <Grid container spacing={2}>
                                        <Grid item xs={1}></Grid>
                                        <Grid item xs={8} style={{
                                            display: "flex", alignItems: "center", fontSize: "14px",

                                        }}>
                                            <label>{base.station}</label>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormControlLabel
                                                style={{ alignSelf: "flex-end" }}
                                                control={<PositiveSwitch
                                                    checked={base.status}
                                                    value={base.id}
                                                    // onChange={(e) => handleChange(e, option.id)}
                                                    onChange={(e) => { filterChangeHandler(e, index) }}
                                                    name={base.station} />}
                                            // name="base"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid><Divider variant="middle" /></Grid>
                                </Box>
                            }

                            )}
                    </Menu>
                </FormControl>
            </SearchBarWithFilters>
            {/* 1st row*/}
            <div style={{ marginLeft: '3px' }}>

                <Typography variant="h6" style={{ paddingBottom: '10px' }}>Completed orders</Typography>
                <Grid container xs={12} spacing={2} >

                    <Grid item xs={3} >
                        <Paper style={{ height: '320px', padding: '5px' }}>
                            <Typography variant="subtitle2" color="primary" style={{ paddingLeft: '14px', paddingTop: "15px" }}>Month to Date </Typography>

                            <Grid style={{ border: '1px solid #ACACAC80', margin: '27px 10px 0px 10px' }}>




                                <Typography className={classes.monthText}>Completed Orders</Typography>


                                <Grid container style={{ paddingTop: "21px", paddingBottom: "21px" }} >
                                    <Grid item xs={4} style={{ textAlign: 'center' }} >

                                        <Grid>{completedOrders?.current_month_count || 0}</Grid>
                                        <Grid className={classes.month}>At {currentDate || " -"}</Grid>
                                    </Grid>
                                    <Grid item xs={4} style={{ textAlign: 'center' }}>

                                        <Grid style={{ color: completedOrders?.partial_month_count_percent >= 0 ? "#4FC605" : "#FF0202" }}>

                                            {completedOrders?.partial_month_count_percent >= 0 ? <img src={ArrowUpwardIcon} /> : <img src={ArrowDownwardIcon} />}
                                            {completedOrders?.partial_month_count_percent ? Math.abs(completedOrders?.partial_month_count_percent).toFixed(2) : 0} %

                                        </Grid>
                                        <Grid className={classes.month}><b>{completedOrders?.prev_month_partial_count || 0} @ {previousDate || "-"}</b></Grid>

                                    </Grid>
                                    <Grid item xs={4} style={{ textAlign: 'center' }}>
                                        <Grid style={{ color: completedOrders?.full_month_count_percent >= 0 ? "#4FC605" : "#FF0202" }}>

                                            {completedOrders?.full_month_count_percent >= 0 ? <img src={ArrowUpwardIcon} /> : <img src={ArrowDownwardIcon} />}

                                            {completedOrders?.full_month_count_percent ? Math.abs(completedOrders?.full_month_count_percent).toFixed(2) : 0}%



                                        </Grid>
                                        <Grid className={classes.month}><b>{completedOrders?.prev_month_full_count || 0} </b> {previousLastDate}</Grid>
                                    </Grid>

                                </Grid>

                            </Grid>

                            <br />


                            <Grid style={{ border: '1px solid #ACACAC80', margin: '16px 10px 25px' }}>
                                <Typography className={classes.monthText}>Completed Values</Typography>
                                <Grid container style={{ paddingTop: "21px", paddingBottom: "21px" }} >
                                    <Grid item xs={4} style={{ textAlign: 'center' }} >
                                        <Grid>{completedValues?.current_month_amount || 0}</Grid>
                                        <Grid className={classes.month}>At {currentDate || " -"}</Grid>

                                    </Grid>
                                    <Grid item xs={4} style={{ textAlign: 'center' }}>
                                        <Grid style={{ color: completedValues?.partial_month_amount_percent >= 0 ? "#4FC605" : "#FF0202" }}>

                                            {completedValues?.partial_month_amount_percent >= 0 ?
                                                <img src={ArrowUpwardIcon} /> : <img src={ArrowDownwardIcon} />}{completedValues?.partial_month_amount_percent ? Math.abs(completedValues?.partial_month_amount_percent).toFixed(2) : 0}% </Grid>

                                        <Grid className={classes.month}><b>{completedValues?.prev_month_partial_amount || 0} </b> {previousDate || "-"}
                                        </Grid>

                                    </Grid>
                                    <Grid item xs={4} style={{ textAlign: 'center' }}>
                                        <Grid style={{ color: completedValues?.full_month_amount_percent >= 0 ? "#4FC605" : "#FF0202" }}>
                                            {completedValues?.full_month_amount_percent >= 0 ?
                                                <img src={ArrowUpwardIcon} /> :
                                                <img src={ArrowDownwardIcon} />}
                                            {completedValues?.full_month_amount_percent ? Math.abs(completedValues?.full_month_amount_percent).toFixed(2) : 0} %



                                        </Grid>
                                        <Grid className={classes.month}><b>{completedValues?.prev_month_full_amount || 0} </b>{previousLastDate}</Grid>
                                    </Grid>

                                </Grid>

                            </Grid>

                        </Paper>




                    </Grid>


                    {/* Last 12 months */}

                    <Grid xs={9} item >
                        <Paper style={{ height: '320px', }}>


                            <Grid container style={{ padding: "14px 6px 3px 15px" }}>
                                <Grid item xs={2}>     <Typography variant="subtitle2" color="primary">Last 12 Months</Typography></Grid>
                                <Grid xs={1} style={{ marginTop: '-2px' }}>   <FormControl size="medium" className={"filter"}>
                                    <Select
                                        fullWidth
                                        displayEmpty
                                        onChange={(e) => {

                                            setFilterData(e.target.value)
                                        }}
                                        id="tags-standard"
                                        name=""
                                        value={filterData}
                                        defaultValue={"otp"}
                                        input={<InputBase />}
                                    >
                                        <MenuItem value={"otp"}>OTP</MenuItem>
                                        <MenuItem value={"type"}>Type</MenuItem>
                                        <MenuItem value={"value"}>Value</MenuItem>

                                    </Select>
                                </FormControl>
                                </Grid>
                                <Grid xs={5} container>

                                    {checked ? filterData === "otp" ? <>
                                        <Grid xs={4} container>
                                            <Grid ><Line htmlColor={"#4FC605"} style={{ marginTop: "-6px" }} /></Grid>
                                            <Grid className={classes.line}>Previous Total</Grid>
                                        </Grid>
                                        <Grid container xs={3}>
                                            <Grid ><StopIcon htmlColor={"#4FC605"} /></Grid>
                                            <Grid className={classes.lengends}>On time</Grid>
                                        </Grid>
                                        <Grid xs={3} container>
                                            <Grid ><StopIcon htmlColor={"#FFB300"} /></Grid>
                                            <Grid className={classes.lengends}>Revised</Grid>

                                        </Grid>
                                        <Grid xs={2} container>
                                            <Grid ><StopIcon htmlColor={"#FF0202"} /></Grid>
                                            <Grid className={classes.lengends}>Late</Grid>

                                        </Grid>




                                    </> : filterData === "type" ? <>
                                        <Grid xs={2} container></Grid>
                                        <Grid container xs={4}>
                                            <Grid ><Line htmlColor={`${theme.palette.secondary.main}`} style={{ marginTop: "-6px" }} /></Grid>
                                            <Grid className={classes.line}>Previous Total</Grid>
                                        </Grid>
                                        <Grid xs={3} container>
                                            <Grid ><StopIcon htmlColor={`${theme.palette.secondary.main}`} /></Grid>
                                            <Grid className={classes.lengends}>ADHOC</Grid>

                                        </Grid>
                                        <Grid xs={3} container>
                                            <Grid ><StopIcon htmlColor={`${theme.palette.primary.main}`} /></Grid>
                                            <Grid className={classes.lengends}>Quoted</Grid>

                                        </Grid>

                                    </>
                                        : filterData === "value" ? <>
                                            <Grid xs={3} container></Grid>
                                            <Grid container xs={2}>

                                            </Grid>
                                            <Grid xs={4} container>

                                                <Grid ><Line htmlColor={`${theme.palette.secondary.main}`} style={{ marginTop: "-6px" }} /></Grid>
                                                <Grid className={classes.line}>Previous Total</Grid>

                                            </Grid>
                                            <Grid xs={3} container>
                                                <Grid ><StopIcon htmlColor={`${theme.palette.secondary.main}`} /></Grid>
                                                <Grid className={classes.lengends}>Value</Grid>

                                            </Grid>

                                        </> : " " : ""}
                                    {!checked ? filterData === "otp" ? <>
                                        <Grid xs={3} container></Grid>
                                        <Grid container xs={3}>
                                            <Grid ><StopIcon htmlColor={"#4FC605"} /></Grid>
                                            <Grid className={classes.lengends}>On time</Grid>
                                        </Grid>
                                        <Grid xs={3} container>
                                            <Grid ><StopIcon htmlColor={"#FFB300"} /></Grid>
                                            <Grid className={classes.lengends}>Revised</Grid>

                                        </Grid>
                                        <Grid xs={3} container>
                                            <Grid ><StopIcon htmlColor={"#FF0202"} /></Grid>
                                            <Grid className={classes.lengends}>Late</Grid>

                                        </Grid>




                                    </> : filterData === "type" ? <>
                                        <Grid xs={3} container></Grid>
                                        <Grid container xs={3}>

                                        </Grid>
                                        <Grid xs={3} container>
                                            <Grid ><StopIcon htmlColor={`${theme.palette.secondary.main}`} /></Grid>
                                            <Grid className={classes.lengends}>ADHOC</Grid>

                                        </Grid>
                                        <Grid xs={3} container>
                                            <Grid ><StopIcon htmlColor={`${theme.palette.primary.main}`} /></Grid>
                                            <Grid className={classes.lengends}>Quoted</Grid>

                                        </Grid>

                                    </> : filterData === "value" ? <>
                                        <Grid xs={3} container></Grid>
                                        <Grid container xs={3}>

                                        </Grid>
                                        <Grid xs={3} container>

                                        </Grid>
                                        <Grid xs={3} container>
                                            <Grid ><StopIcon htmlColor={`${theme.palette.secondary.main}`} /></Grid>
                                            <Grid className={classes.lengends}>Value</Grid>

                                        </Grid>

                                    </> : "" : ""}


                                </Grid>
                                <Grid item xs={3} container justifyContent="end" style={{
                                    marginTop: "-4px",
                                    marginLeft: "30px"
                                }}>

                                    <Grid item xs={11} style={{ textAlign: "center" }}>
                                        <span style={{
                                            fontSize: '14px',
                                            position: 'relative',
                                            top: '7px',

                                        }}>Compare previous year </span>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <FormControlLabel

                                            control={
                                                <PositiveSwitch
                                                    checked={checked}
                                                    onChange={(e) => setChecked(e.target.checked)}
                                                    color="success"
                                                />
                                            }

                                        />
                                    </Grid>
                                </Grid>


                            </Grid>

                            {loading ? <ChartLoader /> : <Grid>



                                {!checked &&
                                    <ReactApexChart
                                        options={column.options}
                                        series={column.series}
                                        type="bar"
                                        height={250} />}

                                {checked && <ReactApexChart
                                    options={state.options}
                                    series={state.series}
                                    type="line"
                                    height={250} />}


                            </Grid>}


                        </Paper>



                    </Grid>

                </Grid>

                <br />



                <Typography variant="h6" style={{ paddingBottom: '10px' }}>In Progress Orders</Typography>

                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Paper style={{ height: '240px', }}>
                            <Typography variant="subtitle2" color="primary" style={{ paddingLeft: '14px', paddingTop: "15px" }}>Order Stage</Typography>
                            <Grid>

                                <ReactApexChart
                                    options={order.options}
                                    series={order.series}
                                    type="bar"
                                    height={200} />
                            </Grid>


                        </Paper>



                    </Grid>
                    <Grid item xs={3}>
                        <Paper style={{ height: '240px' }}>
                            <Grid container style={{ padding: '10px' }}>

                                <Grid item xs={4}>

                                    <Typography gutterBottom={20} variant="subtitle2" color="primary">WIP</Typography>

                                </Grid>
                                <Grid item xs={8}><Typography style={{ fontSize: '11px', fontStyle: "italic" }}>Last updated :{handleLocalTime(wipData?.wip_updated_at)}
                                </Typography></Grid>


                            </Grid>
                            <Grid container style={{ paddingLeft: '10px' }}>
                                <Grid xs={12}>
                                    <Typography gutterBottom={20} variant="subtitle2" color="primary">{wipData?.jobs || 0}</Typography>
                                </Grid>
                                <Grid xs={12}>
                                    <Typography gutterBottom={20} variant="body2" >Jobs</Typography>
                                </Grid>

                                <Grid xs={12}>
                                    <Typography gutterBottom={20} variant="subtitle2" color="primary">{wipData?.wip_value || 0}</Typography>
                                </Grid>
                                <Grid xs={12}>
                                    <Typography gutterBottom={20} variant="body2" >WIP Value</Typography>
                                </Grid>
                            </Grid>
                            <Grid container style={{ paddingLeft: '10px', paddingTop: '15px' }} >
                                <Grid item xs={3} className={classes.wipText}>{wipData?.wip_labour ? parseFloat(wipData?.wip_labour).toFixed(2) : 0}</Grid>
                                <Grid item xs={3} className={classes.wipText}>{wipData?.wip_parts ? parseFloat(wipData?.wip_parts).toFixed(2) : 0}</Grid>
                                <Grid item xs={3} className={classes.wipText}>{wipData?.wip_services ? parseFloat(wipData?.wip_services).toFixed(2) : 0}</Grid>
                                <Grid item xs={3} className={classes.wipText}>{wipData?.wip_equipment ? parseFloat(wipData?.wip_equipment).toFixed(2) : 0}</Grid>

                            </Grid>
                            <Grid container style={{ paddingLeft: '10px', paddingTop: '15px' }} >
                                <Grid item xs={3}><img src={CameraIcon} height={"18px"} /></Grid>
                                <Grid item xs={3}><img src={PartsIconGrey} height={"18px"} /></Grid>

                                <Grid item xs={3}><img src={ServicesIconGray} height={"18px"} /></Grid>
                                <Grid item xs={3}><img src={SpecEquipIconGrey} height={"18px"} /></Grid>

                            </Grid>

                        </Paper>
                    </Grid>
                    <Grid container xs={5} style={{ display: 'grid', gridTemplateColumns: "repeat(2, 1fr)", columnGap: "10px", marginTop: '10px', WebkitAppearance: 'none' }}>


                        <Grid>
                            <Paper style={{ height: '240px' }}>

                                <Grid style={{ padding: '10px' }}>


                                    <Typography gutterBottom={20} variant="subtitle2" color="primary">Open Quotes</Typography>

                                </Grid>
                                {!progressLoading ?
                                    <Grid container direction="row" style={{ paddingTop: '10px' }}>
                                        <Grid item xs={7} style={{ paddingTop: "16px" }}>
                                            <Chart

                                                value1={quotesData?.seven_days}
                                                value2={quotesData?.seven_to_thirty_days}
                                                value3={quotesData?.thirty_days}
                                                name="quotes"
                                                labels={["< 7 Days", "7 - 30 Days", "> 30 Days"]}


                                            />
                                            <div>
                                                <Typography className={classes.quotePercentageText} >{quotesPercentage ? quotesPercentage : 0}{"%"}</Typography>
                                            </div>
                                        </Grid>
                                        <Grid item container direction="column" xs={5} style={{ paddingTop: "25px", paddingLeft: '10px' }}>
                                            <Grid container>

                                                <Grid item xs={2} ><Typography gutterBottom={20} className={classes.chartText} style={{ color: "#4FC605" }} onClick={() => {
                                                    setAction("workshop")
                                                    setCurrentSelection({ graph: "open_quotes ", key: "seven_days" })
                                                }
                                                }>{quotesData?.seven_days || 0}</Typography></Grid>
                                                <Grid > <Typography gutterBottom={20} className={classes.quoteText}>&lt; 7 Days</Typography></Grid>

                                            </Grid>
                                            <Grid container>

                                                <Grid item xs={2}><Typography gutterBottom={20} className={classes.chartText} style={{ color: "#FFB300", }} onClick={() => {
                                                    setAction("workshop")
                                                    setCurrentSelection({ graph: "open_quotes ", key: "seven_to_thirty_days" })
                                                }
                                                }>{quotesData?.seven_to_thirty_days || 0}</Typography></Grid>
                                                <Grid > <Typography gutterBottom={20} className={classes.quoteText}>7 - 30 Days</Typography></Grid>

                                            </Grid>
                                            <Grid container>

                                                <Grid item xs={2}><Typography gutterBottom={20} className={classes.chartText} style={{ color: "#FF0202" }} onClick={() => {
                                                    setAction("workshop")
                                                    setCurrentSelection({ graph: "open_quotes ", key: "thirty_days" })
                                                }
                                                }>{quotesData?.thirty_days || 0}</Typography></Grid>
                                                <Grid > <Typography gutterBottom={20} className={classes.quoteText}>&gt; 30 Days</Typography></Grid>

                                            </Grid>

                                        </Grid>
                                    </Grid> : <ChartLoader />}


                            </Paper>



                        </Grid>
                        <Grid>


                            <Paper style={{ height: '240px' }}>

                                <Grid style={{ padding: '10px' }}>


                                    <Typography gutterBottom={20} variant="subtitle2" color="primary">Approved Positions</Typography>

                                </Grid>
                                {!progressLoading ?
                                    <Grid container direction="row" style={{ paddingTop: '10px' }}>
                                        <Grid item xs={7} style={{ paddingTop: "16px" }}>
                                            <Chart
                                                value1={commercialData?.approved}
                                                value2={commercialData?.pending}
                                                value3={commercialData?.no_position}

                                                labels={["Approved", "Pending", "No Position"]}


                                            />
                                            <div>
                                                <Typography className={classes.quotePercentageText} >{commercialPercentage ? commercialPercentage : 0}{"%"}</Typography>
                                            </div>
                                        </Grid>
                                        <Grid item container direction="column" xs={5} style={{ paddingTop: "25px", paddingLeft: '10px' }}>
                                            <Grid container>

                                                <Grid item xs={2} ><Typography gutterBottom={20} className={classes.chartText} style={{ color: "#4FC605", }} onClick={() => {
                                                    setAction("workshop")
                                                    setCurrentSelection({ graph: "open_commercial_positions", key: "approved" })
                                                }


                                                }>{commercialData?.approved || 0}</Typography></Grid>
                                                <Grid > <Typography gutterBottom={20} className={classes.quoteText}>Approved</Typography></Grid>

                                            </Grid>
                                            <Grid container>

                                                <Grid item xs={2}><Typography gutterBottom={20} className={classes.chartText} style={{ color: "#FFB300" }} onClick={() => {
                                                    setAction("workshop")
                                                    setCurrentSelection({ graph: "open_commercial_positions", key: "pending" })

                                                }


                                                }>{commercialData?.pending || 0}</Typography></Grid>
                                                <Grid > <Typography gutterBottom={20} className={classes.quoteText}>Pending</Typography></Grid>

                                            </Grid>
                                            <Grid container>

                                                <Grid item xs={2}><Typography gutterBottom={20} className={classes.chartText} style={{ color: "#FF0202" }} onClick={() => {
                                                    setAction("workshop")
                                                    setCurrentSelection({ graph: "open_commercial_positions", key: "no_position" })
                                                }


                                                }>{commercialData?.no_position || 0}</Typography></Grid>
                                                <Grid > <Typography gutterBottom={20} className={classes.quoteText}>No Position</Typography></Grid>

                                            </Grid>

                                        </Grid>
                                    </Grid> : <ChartLoader />}


                            </Paper>




                        </Grid>

                    </Grid>



                    <Grid xs={12} >
                        <CommericalTable table={"top"} name={"TOP"} bases={bases} customerDetails={customerDetails} permission={props.permission} filterData={filterData} selectedBase={selectedBase} selectedFilters={selectedFilters} runTable={runTable} setRefresh={setRefresh} />
                    </Grid>


                </Grid>

            </div >

            {action && action === "workshop" && <StoppedOrders open={true}
                handleCloseAction={() => {
                    setAction("")
                    setCurrentSelection({ graph: "", key: "" })
                }}
                order_type_name={"Production"}
                currentSelections={currentSelection}
                getDetails={getDetails}
                tabID={21}
                permission={props?.permission}
            />
            }
        </div >

    )
}
