import React from 'react'
import { useState, useContext, useEffect } from 'react';
import { IconButton, InputBase, makeStyles, Button, Paper, Menu, Grid, Divider, CircularProgress, useTheme } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import SearchBarWithFilters from 'components/search_with_filters';
import { FormControl, FormControlLabel, Select, MenuItem } from '@material-ui/core';
import { DatePicker, theme } from 'antd';
import LineCustomerService from 'lib/services/api/admin/line/line_customer_client_new'
import { LineMaintainenceServices } from "lib/services/api/dashboard/line_maintenance";
import Pagination from '@material-ui/lab/Pagination';
import { getOrganisationId } from 'lib/utils/common_utils';
import { ToastMessageContext } from 'lib/contexts/message_context';
import Chart from "react-google-charts";
import ChartLoader from 'views/Dashboard/Workshops/Storage/ChartLoader';
import CustomPaginationActionsTable from './TablePaginationActions';
import moment from 'moment';
import ArrowDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import _without from "lodash/without";
import { MaintenanceServices } from 'lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation'
import PositiveSwitch from 'components/form_components/switch';
import DetailsPopUp from './DetailsPopUp';
import CustomDaysPaginationActionsTable from './DaysPaginationActions';
import Verified from 'assets/Dashboard/verified.svg'
import VerifiedAmber from 'assets/Dashboard/verifiedAmber.svg'
import VerifiedGray from 'assets/Dashboard/verifiedGray.svg'
import "./tootltip.css";
import LensIcon from '@material-ui/icons/Lens';


const { RangePicker } = DatePicker

export default function Activity(props) {
    const classes = useStyles()
    const [loading, setLoading] = useState(false)
    const message = useContext(ToastMessageContext)
    const [values, setValues] = useState([])
    const [action, setAction] = useState('')
    const theme = useTheme()
    //filters
    const [customerDetails, setCustomerDetails] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [checkedCustState, setCheckedCustState] = useState(new Array(customerDetails.length).fill(false));
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorBase, setAnchorBase] = useState(null);

    const [custButton, setCustButton] = useState(false)
    const [bases, setBases] = useState([]);
    const [checkedState, setCheckedState] = useState(new Array(bases.length).fill(false));
    const [selectedBase, setSelectedBase] = useState([]);
    const [baseButton, setBaseButton] = useState(false)


    const [date, setDate] = useState(null)

    const [filterData, setFilterData] = useState({});

    const [selectedRowDetails, setSelectedRowDetails] = useState({})
    //pagination

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);

    const [xAxisPage, setXAxisPage] = useState(0);
    const [xAxisRowsPerPage, setxAxisRowsPerPage] = useState(7);

    const [totalCount, setTotalCount] = useState(10)
    const [totalDaysCount, setTotalDaysCount] = useState(10)

    //min & max value

    const [minimumValue, setMinimumValue] = useState('')
    const [maximumValue, setMaximumValue] = useState('')

    //date filter

    const [value, setValue] = useState([moment().subtract(28, 'days'), moment().add(28, 'days')])
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)

    const [defaultDate, setDefaultDate] = useState(true)


    useState(() => {
        if (defaultDate === true) {
            if (xAxisRowsPerPage === 7) {
                setXAxisPage(4)
            }

        }


    }, [defaultDate])


    //customer filters
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setCustButton(!custButton)
    };
    const handleClose = () => {
        setAnchorEl(null);
        setCustButton(!custButton)
    };

    const filterCustomerChangeHandler = (e) => {
        const { name, value, checked } = e.target
        // console.log("customer======", value, checked)

        setSelectedFilters((prevState) => [...prevState, value])
        if (!checked) {
            setSelectedFilters((current) => _without(current, value));
        }

        if (defaultDate === true) {
            if (xAxisRowsPerPage === 7) {
                setXAxisPage(4)
            }
            else if (xAxisRowsPerPage === 14) {
                setXAxisPage(2)
            }
            else if (xAxisRowsPerPage === 31) {
                setXAxisPage(1)
            }
        }
        else {
            setXAxisPage(0)
            setxAxisRowsPerPage(7)
            setPage(0)
            setRowsPerPage(50)
        }
    }
    //basefilters
    const filterChangeHandler = (e) => {
        const { name, value, checked } = e.target

        setSelectedBase((prevState) => [...prevState, value])
        if (!checked) {
            setSelectedBase((current) => _without(current, value));
        }
        if (defaultDate === true) {
            if (xAxisRowsPerPage === 7) {
                setXAxisPage(4)
            }
            else if (xAxisRowsPerPage === 14) {
                setXAxisPage(2)
            }
            else if (xAxisRowsPerPage === 31) {
                setXAxisPage(1)
            }
        }
        else {
            setXAxisPage(0)
            setxAxisRowsPerPage(7)
            setPage(0)
            setRowsPerPage(50)
        }
    }
    const handleBaseClick = (event) => {
        setAnchorBase(event.currentTarget);
        setBaseButton(!baseButton)
    };

    //date filters

    const handleDateChange = (value) => {
        setStartDate(true)
        setEndDate(true)
        setValue(value)

        if (value) {
            const start_date = moment(value[0]).format("YYYY-MM-DD");
            const end_date = moment(value[1]).format("YYYY-MM-DD");
            let dayDiff = moment(value[0]).diff(value[1], 'days')
            setDate(true)


            if (Math.abs(dayDiff) > 365) {

                setFilterData({})
                message.showToastMessage({
                    message: "Date range exceeds 1-year maximum!",
                    variant: "error",
                });

            }
            else {
                setFilterData((prevState) => ({ ...prevState, start_date, end_date }));
                setDefaultDate(false)
                setXAxisPage(0)
            }

        } else {
            const fData = { ...filterData };
            filterData?.start_date && delete fData.start_date;
            filterData?.end_date && delete fData.end_date;
            setFilterData({ ...fData });
            setDefaultDate(false)
            setDate(false)
            setXAxisPage(0)
            setPage(0)
            setRowsPerPage(50)
            // setxAxisRowsPerPage(7)
        }
    };

    // verified
    const handleFilterChange = (keyItem, value) => {

        setFilterData((prevState) => ({ ...prevState, [keyItem]: value }));
    };
    const green = '#8CDA5C'
    const red = '#FF0202'
    const grey = '#A9A9A9'
    const amber = "#FFB300"

    //chart columns
    const columns = [
        { type: "string", id: "Aircrafts" },
        { type: "string", id: "Name" },
        { type: "string", role: "style" },
        { type: 'string', role: 'tooltip', 'p': { 'html': true } },
        { type: "date", id: "Start" },
        { type: "date", id: "End" },

    ];


    // showing chart loader
    const rows = [["Loading....... ", " ", 'fff', '', new Date(moment().day()), new Date(moment().day())],
    ]

    const loadingData = [columns, ...rows];

    function generateTooltipContent(item) {



        return ''
    }
    //Api calls
    const getActivityData = async () => {
        setLoading(true)
        const params = {
            organisation_id: getOrganisationId(),
            scale: xAxisRowsPerPage,
            scale_page: xAxisPage + 1,
            page: page + 1,
            limit: rowsPerPage,
            start_date: startDate ? filterData.start_date : moment().subtract(28, 'days').format("YYYY-MM-DD"),
            end_date: endDate ? filterData.end_date : moment().add(28, 'days').format("YYYY-MM-DD"),
            ...filterData,
        }
        selectedBase.forEach((item, index) => params[`base_station_id[${[index]}]`] = item)
        selectedFilters.forEach((item, index) => params[`line_customer_id[${[index]}]`] = item)
        await LineMaintainenceServices.getActivityDetails(params).then((response) => {
            setTotalCount(response.total_count)
            setTotalDaysCount(response.data.total_scale_days)
            if (response.success) {

                const timeline = response.data.dataset
                const labels = response.data.labels
                let days = moment(labels[0])
                let min = new Date(days)
                let max = new Date(labels[labels.length - 1])
                // rows with values
                const rows = timeline.map((item) => ([
                    item[0],
                    item[1],
                    item[4] === "green" ? green : item[4] === "grey" ? grey : item[4] === "amber" ? amber : red,
                    generateTooltipContent(item[5]),
                    new Date(item[2]),
                    item[3] === null || new Date(item[2]).getTime() > new Date(item[3]).getTime() ? new Date(item[2]) : new Date(item[3]),
                ]))
                const data = [columns, ...rows];
                setMaximumValue(max)
                setMinimumValue(min)
                // when empty data using this default rows
                const rows1 = [["No Data Available ", " ", 'fff', '', new Date(moment().day()), new Date(moment().day())],
                [".", "  ", 'fff', '', new Date(moment().day()), new Date(moment().day())]]

                const data1 = [columns, ...rows1];

                if (timeline.length === 0) {
                    setValues(data1)
                    setMinimumValue(new Date(moment().day()))
                    setMaximumValue(new Date(moment().day()))
                }
                else { setValues(data) }
            }

        }).catch((e) => {
            message.showToastMessage({
                message: "Something went wrong. Try again!",
                variant: "error",
            });
        }).finally(() => {

            setLoading(false)
        })

    }

    useEffect(() => {

        getActivityData()
    }, [page, xAxisPage, xAxisRowsPerPage, rowsPerPage, selectedBase, selectedFilters, filterData])


    // tooltip




    const options = {
        timeline: {
            // width: 400,
            groupByRowLabel: true,
            showRowNumber: true,
            allowHtml: true,
            // groupWidth: 20,
            showBarLabels: false

        },
        tooltip: { isHtml: true },
        // nableInteractivity: false,
        backgroundColor: '#fff',
        hAxis: {
            format: 'd/M/yy',
            minValue: loading ? new Date(moment().day()) : minimumValue,
            maxValue: loading ? new Date(moment().day()) : maximumValue

        },
        avoidOverlappingGridLines: false,
        legend: {
            position: 'right',
            alignment: 'end',
            textStyle: {
                color: '#666',
                fontSize: 12,
            },
        },

    };

    //Line customer api
    const getCustomerList = async () => {
        await LineCustomerService.getLineCustomers()
            .then((res) => {

                setCustomerDetails(res.line_customers);


            })
            .catch((err) => {
                message.showToastMessage({
                    message: "Something went wrong. Try again!",
                    variant: "error",
                });

            });
    };
    const getBaseStation = async () => {
        const params = {
            organisation_id: getOrganisationId(),
            order_by: 'station'
        }
        MaintenanceServices.getBaseStation(params)
            .then((res) => {
                if (res) {
                    setBases(res.data);
                }

            })
            .catch((err) => {
                message.showToastMessage({
                    message: "Something went wrong. Try again!",
                    variant: "error",
                });

            });
    };
    useEffect(() => {
        getCustomerList()
        getBaseStation()
    }, [])

    const ITEM_HEIGHT = 78;
    // console.log("data=====", values)
    const handleDatePagination = () => {
        setPage(0)
    }
    return (
        <div>

            <SearchBarWithFilters noSearch padding={'4px 16px'}
            >
                <FormControl style={{ marginLeft: '-5px' }}>
                    <Button variant={"text"} size="small" aria-haspopup="true" onClick={handleClick} endIcon={custButton ? <ArrowDropUp /> : <ArrowDown />} style={{ fontSize: "14px", backgroundColor: anchorEl ? '#efefef' : "" }} >
                        {selectedFilters.length > 0 ? `Customer(${selectedFilters.length})` : 'Customer(All)'}
                    </Button>
                    <Menu
                        id="Customer"
                        anchorEl={anchorEl}
                        keepMounted
                        open={anchorEl}
                        onClose={handleClose}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                        transformOrigin={{ vertical: "top", horizontal: "left" }}
                        style={{ marginTop: '41px' }}
                        TransitionProps={false}
                        PaperProps={{
                            style: {
                                maxHeight: ITEM_HEIGHT * 4.2,
                                width: '39ch',
                                position: 'fixed',
                                top: '260px',

                            },
                        }}
                    >
                        {/* <MenuItem value="" style={{ marginLeft: '7px' }}>Customer(All)</MenuItem> */}
                        {customerDetails?.map((option, index) => {
                            return <Box>
                                <Grid container spacing={2}>
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={9} style={{
                                        display: "flex", alignItems: "center", fontSize: "14px",

                                    }}>
                                        <label>{option.name}</label>
                                    </Grid>
                                    <Grid item xs={2} style={{ marginLeft: '-23px' }}>
                                        <FormControlLabel

                                            control={<PositiveSwitch
                                                checked={checkedCustState[index]}
                                                value={option.id}
                                                onChange={(e) => { filterCustomerChangeHandler(e) }}
                                                name={option.name} />}
                                        />
                                    </Grid>

                                </Grid>
                                <Grid><Divider variant="middle" /></Grid>
                            </Box>
                        })}
                    </Menu>
                </FormControl>
                <FormControl >
                    <Button variant={anchorBase ? "contained" : "text"} size="small" aria-haspopup="true" onClick={handleBaseClick} endIcon={baseButton ? <ArrowDropUp /> : <ArrowDown />} style={{ fontSize: "14px", backgroundColor: anchorBase ? '#efefef' : "" }}>
                        {selectedBase.length > 0 ? `Base(${selectedBase.length})` : 'Base(All)'}

                    </Button>
                    <Menu
                        id="Base"
                        anchorEl={anchorBase}
                        keepMounted
                        open={Boolean(anchorBase)}
                        onClose={() => {
                            setAnchorBase(null)
                            setBaseButton(!baseButton)
                        }}

                        style={{ marginTop: '40px' }}
                        PaperProps={{
                            style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: '39ch',
                                position: 'absolute',
                                top: '260px',
                            },
                        }}
                    >
                        {
                            bases?.map((base, index) => {
                                return <Box>
                                    <Grid container spacing={2}>
                                        <Grid item xs={1}></Grid>
                                        <Grid item xs={8} style={{
                                            display: "flex", alignItems: "center", fontSize: "14px",

                                        }}>
                                            <label>{base.station}</label>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormControlLabel
                                                style={{ alignSelf: "flex-end" }}
                                                control={<PositiveSwitch
                                                    checked={checkedState[index]}
                                                    value={base.id}
                                                    // onChange={(e) => handleChange(e, option.id)}
                                                    onChange={(e) => { filterChangeHandler(e, index) }}
                                                    name={base.station} />}
                                            // name="base"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid><Divider variant="middle" /></Grid>
                                </Box>
                            }

                            )}
                    </Menu>
                </FormControl>
                <FormControl className={"filter"}>
                    <Select
                        defaultValue={""}
                        displayEmpty input={<InputBase />}
                        onChange={(e) =>
                            handleFilterChange("verified", e.target.value)
                        }
                    >
                        <MenuItem value={""} >Verified (All)</MenuItem>
                        <MenuItem value={"true"}>Verified</MenuItem>
                        <MenuItem value={"false"}> NOT Verified</MenuItem>
                    </Select>
                </FormControl>
                <FormControl style={{ float: "right" }}>
                    <RangePicker
                        format="DD/MM/YYYY"
                        onChange={handleDateChange}
                        allowClear={true}
                        // defaultValue={[moment().subtract(1, 'months'), moment()]}
                        value={value}
                    //   disabledDate={(current) => handleDisabledRange(current)}
                    />
                </FormControl>
            </SearchBarWithFilters>

            <Paper style={{ width: "100%", padding: "30px 35px", }}>
                <Box width="100%" display="flex" justifyContent="space-between">
                    <Typography color="primary" variant="h6">
                        Aircraft
                    </Typography>

                    <IconButton style={{ marginTop: '-3px' }}  >
                        {/* <CloudDownloadIcon /> */}
                    </IconButton>

                </Box>
                <div onClick={handleDatePagination}>
                    <CustomDaysPaginationActionsTable rowPerPageOptions={[7, 14, 31]} count={totalDaysCount} page={xAxisPage} setPage={setXAxisPage} rowsPerPage={xAxisRowsPerPage} setRowsPerPage={setxAxisRowsPerPage} label={'Scale (days) :'} defaultDate={defaultDate} />

                </div>
                <Box width="100%" display="flex" p={2}>
                    <Box width="100%" id='timeline' >

                        <Chart
                            chartType="Timeline"

                            width='100%'
                            height="550px"
                            options={options}
                            loader={loading && <ChartLoader />}
                            data={loading ? loadingData : values}
                            chartEvents={[
                                {
                                    eventName: "select",
                                    callback: (params) => {
                                        const { chartWrapper, google } = params
                                        const chart = chartWrapper.getChart()
                                        const data = chart.getSelection()[0].row + 1

                                        if (data) {

                                            setSelectedRowDetails(data)
                                            setAction('View')
                                            console.log("Charttttt=======", data)
                                        }
                                    },
                                },
                                {
                                    eventName: "ready",
                                    callback: (params) => {
                                        let container = document.getElementById("timeline");
                                        let texts = container.getElementsByTagName('text');
                                        Array.prototype.forEach.call(texts, function (text) {
                                            if ((parseFloat(text.getAttribute('y')) > 0) || (parseFloat(text.getAttribute('x')) > 0)) {
                                                text.setAttribute('font-weight', 500);

                                            }
                                            else {
                                                text.setAttribute('font-weight', 500);
                                            }
                                        });


                                    }
                                }



                            ]}

                        />
                    </Box>
                </Box>
                <Grid item xs={12} container style={{ marginLeft: "67px" }}>
                    <Grid className={classes.legends} item><LensIcon htmlColor={red} className={classes.icons} /><span>Technical Delay (TDR)</span></Grid>
                    <Grid className={classes.legends} item><LensIcon htmlColor={amber} className={classes.icons} /><span>Non Tech Delay (ODR)</span></Grid>
                    <Grid className={classes.legends} item ><LensIcon htmlColor={green} className={classes.icons} /><span>On Time</span></Grid>
                    <Grid className={classes.legends} item ><LensIcon htmlColor={grey} className={classes.icons} /><span>In Work / Planned</span></Grid> </Grid>
                <Box mt={"-34px"}>

                    <CustomPaginationActionsTable rowPerPageOptions={[10, 20, 50, 100]} count={totalCount} label={''} page={page} setPage={setPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} />
                </Box>
            </Paper>
            {action && action === 'View' && <DetailsPopUp handleClose={() => {
                setAction(null)
                getActivityData()
            }} selectedRowDetails={selectedRowDetails} data={values}
                permission={props?.permission}


            />}
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    // root: {
    //     flexShrink: 0,
    //     marginLeft: theme.spacing(2.5),
    // },
    legends: {
        paddingRight: "31px"
    },
    icons: {
        fontSize: "15px",
        position: "absolute",
        marginTop: "3px",
        marginLeft: '-20px'
    }
}));

