import { CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Typography, makeStyles, Grid } from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import MROButton from 'components/buttons'
import FileUpload from 'components/form_components/fileUpload'
import { ICON_COLOUR } from 'lib/constants/style_constants'
import { ToastMessageContext } from 'lib/contexts/message_context'
import { WorkOrderServices } from 'lib/services/api/operaitons/lineMaintenance/workOrder/workOrder'
import PlanningWorkOrderServices from 'lib/services/api/planning/LineMaintenance/PlannedWO'
import React, { useContext, useEffect, useState } from 'react'



const useStyles = makeStyles((theme) => ({

    dialogTitle: {
        color: theme.palette.primary.main,
        fontSize: '18px'
    },
    modal: {
        "&.MuiDialog-paperWidthMd": {
            width: "563px",
        },
    },
    actionButtons: {
        paddingTop: "5px",
        paddingBottom: "20px",
        textAlign: "right",
        marginRight: "20px",
        "& button:not(:last-child)": {
            marginRight: "20px",
        },
    },

}))
function ViewRecords(props) {
    const classes = useStyles()
    const { handleClose, currentSelection } = props
    const [loading, setLoading] = useState(true)
    const [files, setFiles] = useState([])
    const [edit, setEdit] = useState(false)
    const [boolState, setBoolState] = useState(false);
    const [deletedStatus, setDeletedStatus] = useState(false);
    const [records, setRecords] = useState([]);
    const [busy, setBusy] = useState(null);
    const message = useContext(ToastMessageContext)

    useEffect(() => {
        getRecords()
    }, [currentSelection])

    const getRecords = () => {
        setLoading(true)
        const params = {
            task_id: currentSelection?.id
        }
        PlanningWorkOrderServices.getRecords(params).then((res) => {
            setFiles(res.blob_records)
        }).catch(() => {

        }).finally(() => {
            setLoading(false)
        })
    }


    const deleteFileHandler = async (id) => {
        setBusy("file-deleting");
        try {
            const response = await WorkOrderServices.deleteDocument({ id: id });

            if (response.success) {
                message.showToastMessage({
                    message: "File deleted successfully",
                    variant: "success",
                });
                setDeletedStatus(true);
                getRecords()
            }
            setBusy(null);
        } catch (error) {
            message.showToastMessage({
                message: "Something went wrong",
                variant: "error",
            });
            setBusy("file-deleting-error");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        formData.append("task_id", currentSelection?.id);

        formData.delete("documents[]");
        records.forEach((file) =>
            formData.append("documents[]", file)
        );
        setBusy("loading");
        if (deletedStatus !== true) {
            await WorkOrderServices.EditPlannedRecords(formData)
                .then(() => {
                    setBusy(null);
                    handleClose();
                    message.showToastMessage({
                        message: "Added successfully!",
                        variant: "success",
                    });
                    setDeletedStatus(false);
                })
                .catch(() => {
                    setBusy(null);
                    handleClose();
                    message.showToastMessage({
                        message: "Something went wrong. Try again!",
                        variant: "error",
                    });
                });
            setBusy(null);
        }
        setBusy(null);
        handleClose();
    };
    useEffect(() => {
        if (records.length) {
            setDeletedStatus(false);
        }
    }, [records]);
    return (
        <div>
            <Dialog
                maxWidth={"md"}
                className={classes.modal}
                aria-labelledby="deAssign"
                onClose={handleClose}
                classes={{ root: classes.modal }}

                open={true}
            >

                <DialogTitle >
                    <Grid container>

                        <Grid xs={10}><Typography variant='subtitle2' className={classes.dialogTitle}>Uploaded Records </Typography>
                        </Grid>

                        <Grid><IconButton onClick={() => {
                            setBoolState(true)

                        }}>

                            <Edit htmlColor={ICON_COLOUR} />
                        </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>




                <DialogContent style={{ width: '693px' }} >
                    <form noValidate onSubmit={handleSubmit}>
                        <FileUpload
                            isLoading={loading}
                            defaultValue={files !== undefined ? files.map((file) => ({
                                filename: file?.filename,
                                url: file?.url,
                                id: file?.id,
                            })) : ''}
                            setFiles={(files) => {
                                setRecords(files);
                            }}
                            onDelete={boolState === false ? null : deleteFileHandler}
                            showBorderOnNoFiles={true}
                            multiple={true}
                            APIUrl={""}
                            isDeleting={busy === "file-deleting"}
                            uploadButtonView={boolState === false}
                            name={"documents[]"}
                            action={"Upload Document"} />
                        <br />
                        {boolState === true && (
                            <div className={classes.actionButtons}>
                                <MROButton variant={"contained"}
                                    onClick={handleClose}>
                                    Cancel
                                </MROButton>
                                <MROButton
                                    loading={busy === "loading"}
                                    variant={"contained"}
                                    color={"primary"}
                                    type={"submit"}
                                >
                                    Complete
                                </MROButton>
                            </div>
                        )}
                    </form>
                </DialogContent>
            </Dialog>

        </div>
    )
}

export default ViewRecords