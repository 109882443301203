import React, { useContext, useEffect, useReducer } from "react";

import MaterialTable from "material-table";
import tableIcons, { options } from "components/universal/table_attributes";
import TableAction from "actions/table_actions";
import tableReducer, { INITIAL_TABLE_STATE } from "reducers/table_reducer";
import MROButton from "components/buttons";
import clxc from "clsx";
import { Typography } from "antd";
import { ToastMessageContext } from "lib/contexts/message_context";
import moment from 'moment'
import { formatMMMDate } from "lib/utils/common_utils";
import { Box, Button, FormControl, IconButton, InputBase, makeStyles, MenuItem, Select } from "@material-ui/core";
import SearchBarWithFilters from "components/search_with_filters";
import useDebounce from "lib/utils/debounce";
import { useState } from "react";
import { BASE_API_URL_BOXR } from "lib/constants";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { ICON_COLOUR } from "lib/constants/style_constants";

const useStyles = makeStyles(() => ({
  bookedOut: {
    borderColor: "#4D4F5C",
    borderRadius: "7px",
    minWidth: "20px",
    height: "30px",
    color: "#fff",
    backgroundColor: "#FF0202",
    width: "80px",
  },
  stockBtn2: {
    borderColor: "#4D4F5C",
    borderRadius: "7px",
    minWidth: "20px",
    height: "30px",
    color: "#fff",
    backgroundColor: "#4FC605",
    width: "80px",
  },
}));

function ToolingTable(props) {
  const classes = useStyles()
  const { currentSelectedOrder } = props;

  const message = useContext(ToastMessageContext);

  const [searchText, setSearchText] = useState(null);

  const debouncedSearchText = useDebounce(searchText);

  const [filterData, setFilterData] = useState({});

  const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
  const { page, totalCount, pageSize, inProgress, data } = tableState;

  const getList = () => {
    const params = {
      // reportable_type: "WorkshopOrder",
      workshop_order_id: currentSelectedOrder.id,
      page: 1 + page,
      count_per_page: pageSize,
      return_status: true,
      search_keyword: debouncedSearchText,
      ...filterData,
    };
    TableAction.getList(
      dispatch,
      message,
      params,
      "workshopToolingRequestPart"
    );
  };

  useEffect(() => {
    getList();
  }, [page, pageSize, filterData, debouncedSearchText]);

  const tableOptions = {
    ...options,
    page: page,
    search: false,
    total: totalCount,
    pageSize: pageSize,
    toolbar: false,
  };

  const columnConfig = [
    {
      title: "",
      field: "id",
      width: "4%",
      render: (rowData) => <span>&nbsp;</span>,
    },
    {
      title: "Tool #",
      field: "tool_id",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        padding: "10px 5px",
      },
    },
    {
      title: "Part #",
      field: "part",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      title: "Description",
      field: "description",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        textAlign: "left",
        width: "30%",
      },
    },
    {
      title: "Cal Due",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        textAlign: "left"
      },
      render: (rowData) => {
        if(rowData.calibration_due === null){
          return "-"
        }else{
          return formatMMMDate(rowData?.calibration_due)
        }
      }
    },
    {
      title: "Cert",
      field: "user",
      headerStyle: {
        textAlign: "center",
      },
      render: (rowData) => {
        return (
          <IconButton onClick={() => {
            if (rowData?.calibration_certificate !== null) {
              window.open(BASE_API_URL_BOXR + "/" + rowData?.calibration_certificate)
            } else {
              message.showToastMessage({
                message: "No Document Found.",
                variant: "error",
              });
            }
          }}
            size="small"
            style={{display: rowData?.calibration_certificate === null ? "none" : ""}}
            >
            <VisibilityIcon fontSize="small" color={ICON_COLOUR} />
          </IconButton>
        );
      }
    },
    {
      title: "User",
      field: "booked_out_user_stamp_number",
      headerStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Booked Out",
      headerStyle: {
        textAlign: "center",
      },
      render: (rowData) => {
        if(rowData.booked_out_timestamp === null){
          return "-"
        }else{
          return formatMMMDate(rowData?.booked_out_timestamp)
        }
      }
    },
    {
      title: "Returned",
      headerStyle: {
        textAlign: "center",
      },
      render: (rowData) => {
        if(rowData.returned_timestamp === null){
          return "-"
        }else{
          return formatMMMDate(rowData?.returned_timestamp)
        }
      }
    },
    {
      title: "Status",
      render: (rowData) => {
        if (rowData?.status === "returned") {
          return (
            <MROButton
              className={clxc(classes.button, "button-info", classes.stockBtn2)}
              variant="contained"
            >
              <Typography
                style={{
                  fontSize: "10px",
                  color: "white",
                  textTransform: "capitalize",
                }}
              >
                RETURNED
              </Typography>
            </MROButton>
          );
        } else {
          return (
            <Box m={0} p={0}>
              <MROButton
                className={clxc(classes.button,"button-info", classes.bookedOut)}
                variant="contained"
                style={{ whiteSpace: "nowrap" }}
              >
                <Typography
                  style={{
                    fontSize: "10px",
                    color: "white",
                    textTransform: "capitalize",
                  }}
                >
                  BOOKED OUT
                </Typography>
              </MROButton>
            </Box>
          );
        }
      },
    },
  ];

  const handleFilterChange = (keyItem, value) => {
    TableAction.setPage(dispatch, 0);
    setFilterData((prevState) => ({ ...prevState, [keyItem]: value }));
  };

  return (
    <>
      <SearchBarWithFilters
        handleSearch={(value) => {
          TableAction.setPage(dispatch, 0);
          setSearchText(value);
        }}
        searchText={searchText}
      >
        <Select
          defaultValue={null}
          displayEmpty
          input={<InputBase />}
          onChange={(e) =>
            handleFilterChange("status", e.target.value)
          }
        >
          <MenuItem value={null}>Status (All)</MenuItem>
          {[{ name: "Booked Out", value: "booked_out" }, { name: "Returned", value: "returned" }].map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </SearchBarWithFilters>
      <MaterialTable
        style={{ marginTop: 20 }}
        columns={columnConfig}
        icons={tableIcons}
        title=""
        data={data.data || []}
        isLoading={inProgress}
        options={tableOptions}
        onChangePage={(page) => {
          TableAction.setPage(dispatch, page);
        }}
        onChangeRowsPerPage={(pageSize) => {
          TableAction.setPageSize(dispatch, pageSize);
        }}
        onSearchChange={(search) => {
          TableAction.setSearchText(dispatch, search);
        }}
        totalCount={totalCount}
        page={page}
      />
    </>
  );
}

export default ToolingTable;
