import React, { useContext, useState, useEffect } from 'react'
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import { Dialog, DialogContent, DialogTitle, Box, MenuItem } from "@material-ui/core";
import MROButton from 'components/buttons';
import { Typography } from "@material-ui/core";
import { ToastMessageContext } from "lib/contexts/message_context";
import DateField from 'components/form_components/DateField';
import MROTextField from "components/form_components/TextField";
import { MaintenanceServices } from 'lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation';
import { WorkshopContext } from 'views/Operations/Workshop/WorkshopContext/WorkshopContext';
import ServiceRequestListClientServices from 'lib/services/api/procurement/Services';
import { getOrganisationId } from 'lib/utils/common_utils';
import moment from 'moment';




const useStyles = makeStyles((theme) => ({
    formWrapper: {
        background: "#fff",
        width: "100%",

    },
    title: {
        display: "flex",
        justifyContent: "left",
        "& h2": {
            //   color: theme.palette.primary.main,
            fontSize: "40px",
            fontWeight: 40,
        },
    },
    subTitle: {
        fontSize: "16px",
        fontWeight: "bold",
        marginBottom: "20px",
        color: "#4D4F5C",
    },
    card: {
        padding: "8px 29px",
    },
    actionButtons: {
        paddingBottom: "33px",
        textAlign: "end",
        marginRight: "6px",
        marginTop: '21px',
        "& button:not(:last-child)": {
            marginRight: "20px",
        },
    },

    modal: {
        "& .MuiDialog-paperWidthMd": {
            width: "24%",
        },
    }
}));


export default function EquipmentAdditionalPopUp(props) {
    const classes = useStyles();
    const { handleClose, currentClosedDetails, currentWorkshopDetails, selectedRow, currentActionData, currentActionId, orderType } = props
    // const [actionDpdOptions, setActionDpdOptions] = useState([]);
    const { setEquipmentFlag } = useContext(WorkshopContext)
    const [error, setError] = useState({});
    const message = useContext(ToastMessageContext)

    // const getActions = async () => {
    //     try {
    //         const { data } = await MaintenanceServices.getActionsDpdOptions({
    //             id: currentPlanningDetails.id,
    //         });
    //         setActionDpdOptions(data);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    // useEffect(() => {
    //     getActions();
    // }, []);

    const handleSubmit = async (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        let userDetails = JSON.parse(sessionStorage.getItem("user"));

        let valid = true;
        let errors = {
            // workshop_action_id: false,
            desired_date: false,
            duration: false

        };
        // if (formData && formData.get("workshop_action_id")?.length === 0) {
        //     errors.workshop_action_id = true;
        //     valid = false;
        // }
        if (formData && formData.get("desired_date").length === 0) {
            errors.desired_date = true;
            valid = false;
        }
        if (formData && formData.get("duration").length === 0) {
            errors.duration = true;
            valid = false;
        }

        if (!valid) {

            setError(errors);
            return;
        }
        formData.append("workshop_action_id", currentActionId)
        formData.append("user_id", userDetails.id)
        formData.append("base_station_id", currentWorkshopDetails?.base_station_id)
        formData.append("description", selectedRow?.description)
        // // setBusy("loading")
        ServiceRequestListClientServices.workshopCreateEquipments(formData)
            .then((res) => {
                if (res.success) {
                    setEquipmentFlag(true)
                    handleClose()
                    message.showToastMessage({
                        message: "Copied successfully ",
                        variant: "success",
                    });
                }
            }).catch(() => {
                message.showToastMessage({
                    message: "Something went wrong. Try again!!",
                    variant: "error"
                })
            }).finally(() => {
                // setBusy(null)
            })
    }




    return (
        <div>
            <Dialog
                maxWidth={"md"}
                open={true}
                className={classes.modal}
                onClose={handleClose}
                aria-labelledby="base-station-manage"
            >
                <DialogTitle>
                    <Typography
                        color="primary"
                        className={classes.title}
                    >
                        Additional Data Required
                    </Typography>
                </DialogTitle>
                <DialogContent className={classes.card}>
                    <div className={classes.formWrapper}>
                        <form noValidate onSubmit={handleSubmit}>

                            <Grid>
                                <DateField
                                    required
                                    name="desired_date"
                                    label={"Desired Date"}
                                    InputLabelProps={{ shrink: true }}
                                    error={error.desired_date}
                                    helperText={error.desired_date ? "*please enter a date" : null}
                                    onChange={(e) => { setError({ ...error, desired_date: false }); }}

                                />
                            </Grid>
                            <Grid>
                                <MROTextField
                                    name={"duration"}
                                    type="number"
                                    label={"Duration (days)"}
                                    InputLabelProps={{ shrink: true }}
                                    placeholder={"Number of days"}
                                    error={error.duration}
                                    onChange={(e) => {
                                        setError({ ...error, duration: false })
                                    }} >
                                </MROTextField>

                            </Grid>
                            <div className={classes.actionButtons}>
                                <MROButton
                                    //   disabled={loading}
                                    type="button"
                                    variant={"contained"}
                                    onClick={handleClose}
                                >
                                    Cancel
                                </MROButton>
                                <MROButton
                                    //   disabled={loading}
                                    type="submit"
                                    variant={"contained"}
                                    color="primary"
                                >
                                    Complete
                                </MROButton>
                            </div>
                        </form>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}
