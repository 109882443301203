import { AppBar, Toolbar, makeStyles, IconButton, Typography, Grid, MenuItem, FormControlLabel, ButtonGroup, Box, InputAdornment, CircularProgress, Paper } from '@material-ui/core'
import MROFullScreenRightDrawer from 'components/fullwidth_dialog'
import React, { useReducer, useState, useEffect, useContext, useMemo } from 'react'
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import MROTextField from 'components/form_components/TextField';
// import DateTimeField from 'views/Accounts/Workshop/Maintenance/ManageInvoice/DateField';
import PositiveSwitch from 'components/form_components/switch';
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import QuantityCounter from "components/quantity_counter";
import MROButton from 'components/buttons';
import { Autocomplete } from '@material-ui/lab';
import DeleteIcon from "@material-ui/icons/Delete";
import { ICON_COLOUR } from 'lib/constants/style_constants';
import PlanningLineMaintenance from 'lib/services/api/planning/LineMaintenance/lineEventsandSeries';
import DateTimeField from 'components/form_components/LMEventsDateTimeField';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { getOrganisationId } from 'lib/utils/common_utils';
import _ from "lodash";
import { ToastMessageContext } from 'lib/contexts/message_context';
import moment from 'moment';
import { getTimeDiffFormatted } from 'views/Operations/LineMaintenance/WorkOrder/section_detail';
import DetailsPopUp from 'views/Planning/Line maintenance/LMEvents/DetailsPopUp';
import DeleteEvent from 'views/Planning/Line maintenance/LMEvents/DeleteEvent';
import NumericIncrement from "components/numeric_increment_job_clockings";



const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: "#fff",
        color: "#000000CC",
        boxShadow: "0px 3px 6px #0000001A",
    },
    backButton: {
        marginRight: theme.spacing(2),
    },
    wrapper: {
        margin: "2% 3%",
        // overflowX: "hidden !important"
    },
    title: {
        fontSize: '18px'
    },
    toggle: {
        '&.MuiToggleButton-root': {
            minWidth: '80px'
        }
    },
    actionButtons: {
        marginTop: '15px',
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
    },
    partKey: {
        fontSize: "14px",
        // padding: "5px",
    },
    partValue: {
        fontSize: "14px",
        color: "#4D4F5C",
        padding: "5px",
        display: "flex",
        alignItems: "center",
    }, disableField: {
        pointerEvents: "none",
        backgroundColor: "#F5F5F5",
    },
    paper: {
        // position: 'absolute',
        // marginTop: '500px',

        height: 'max-content',
        // width: 'fit-content',
        '&.MuiAutocomplete-popper': {
            zIndex: '1000000 !important'
        }
    }

}));
const GetInfoCard = (props) => {
    const classes = useStyles();
    return (
        <Box display="flex" pl={'5px'}>
            <Box flex={50} className={classes.partKey}>
                <Typography style={{ padding: "5px" }} color="primary">
                    {props?.keyName}
                </Typography>
            </Box>
            <Box flex={50} className={classes.partValue}>
                {props?.value ?? "-"}
            </Box>
        </Box>
    );
};
export default function EditLmEvents(props) {
    const classes = useStyles()
    const { handleClose, selectedRowDetails, data, approvalTypes, permission, currentSelection } = props
    const [qty, setQty] = useState({ man_hours: '' });
    const [state, setState] = useState("single")
    const [action, setAction] = useState('')
    const [eventDetails, setEventDetails] = useState({})
    const [errors, setErrors] = useState({});
    const [groundTime, setgroundTime] = useState("")
    const [plannedAircraft, setPlannedAircraft] = useState('')
    const [mechQty, setMechQty] = useState();
    const [laeQty, setLaeQty] = useState();
    const [scopeTypes, setScopeTypes] = useState([])
    const [selectedScope, setSelectedScope] = useState([])
    const [plannedEngineers, setPlannedEngineers] = useState([])
    const [selectedEngineers, setSelectedEngineers] = useState([])
    const [loading, setLoading] = useState(false);
    const message = useContext(ToastMessageContext)
    const [arrivalTime, setArrivalTime] = useState(null)
    const [departureTime, setDepartureTime] = useState(null)
    let eventid = currentSelection.id
    let userDetails = JSON.parse(sessionStorage.getItem("user"));
    const [busy, setbusy] = useState(false)
    const [notes, setNotes] = useState('')
    const [departureFlightName, setDepartureFlightName] = useState('')
    const [groundTimeWholeValue, setgroundTimeWholeValue] = useState("")
    const [scopeChanged, setScopeChanged] = useState(false)

    const PopperMy = (props) => {
        return <Paper {...props} className={classes.paper} placement="bottom-start" />;
    };


    const getScopeOfArrrival = async () => {

        const params = {
            organisation_id: getOrganisationId()
        }
        await PlanningLineMaintenance.fetchScopeOfApproval(params).then((res) => {
            setScopeTypes(res.data)
        }).catch((response) => {

        })
    }

    useEffect(() => {
        getScopeOfArrrival()
    }, [])

    const getPlannedEngineers = async () => {

        const params = {
            organisation_id: getOrganisationId()
        }
        await PlanningLineMaintenance.fetchPlannedEngineers(params).then((res) => {
            setPlannedEngineers(res.data)
        }).catch((response) => {

        })
    }

    useEffect(() => {
        getPlannedEngineers()
    }, [])
    const getEventDetails = async () => {
        setLoading(true)
        const params = {
            event_id: currentSelection?.id

        }
        await PlanningLineMaintenance.getLMEventDetails(params).then((res) => {
            setEventDetails(res.data)
        }).catch((err) => {


        }).finally(() => {
            setLoading(false)
        })

    }

    useEffect(() => {
        getEventDetails()
    }, [currentSelection])

    useEffect(() => {
        if (eventDetails !== undefined) {
            setgroundTime(eventDetails.gnd_time)
            setPlannedAircraft(parseInt(eventDetails.planned_aircraft))
            setMechQty(eventDetails?.mech_quantity)
            setLaeQty(eventDetails?.lae_quantity)
            setQty({ man_hours: parseFloat(eventDetails?.total_man_hours).toFixed(2) })
            setSelectedEngineers(eventDetails?.planned_engineers)
            setSelectedScope(eventDetails.scope_of_approval)
            setArrivalTime(eventDetails?.arr_datetime)
            setDepartureTime(eventDetails?.dep_datetime)
            setNotes(eventDetails.notes ? eventDetails.notes?.note : '')
            setDepartureFlightName(eventDetails.dep_flight_number ? eventDetails.dep_flight_number : '')

        }
    }, [eventDetails])

    const getDecimalConversion = (date) => {
        let arr1 = getTimeDiffFormatted(date)
        var arr = arr1.split(':');
        var dec = parseInt((arr[1] / 6) * 10, 10);
        var final = arr[0] + "." + dec
        // console.log("arrivalTime=====", final)
        setgroundTime(final)

    }

    useMemo(() => {
        if (arrivalTime !== undefined && departureTime !== undefined) {

            const format = "DD/MM/YYYY HH:mm:ss";
            const depatureDateTime = moment(departureTime, format);
            const actArrivalDateTime = moment(arrivalTime, format);
            if (depatureDateTime && actArrivalDateTime) {
                const duration = moment.duration(depatureDateTime.diff(actArrivalDateTime))
                let final = parseFloat(duration.asHours()).toFixed(2)
                let gndWholeValue = parseFloat(duration.asHours())

                setgroundTimeWholeValue(gndWholeValue)
                setgroundTime(final)
            }


        }

    }, [arrivalTime, departureTime])

    const handleSubmit = async (e) => {
        e.preventDefault()
        const formdata = new FormData(e.target)
        formdata.delete("dep_details_known")
        formdata.delete("notes")
        formdata.delete("dep_flight_number")
        let valid = true;
        let error = {};


        for (let pair of formdata.entries()) {
            const value = pair[1];
            const key = pair[0];
            // console.table("formdata=====", key, value)
            if (!value) {
                error[key] = true;
                valid = false;
            }
        }
        let gnd_time = parseInt(formdata.get("gnd_time"))
        // if (gnd_time === 0 || groundTime === '') {
        //     valid = false;
        //     error = {
        //         ...error,
        //         gnd_time: `Gnd Time must be greater than 0!`,
        //     };
        // }
        if (!valid) {
            setErrors(error);
            return;
        }
        setbusy(true)
        formdata.append("organisation_id", getOrganisationId())
        formdata.append("event_id", eventid)
        formdata.append("logged_user_id", userDetails?.id)
        formdata.delete("gnd_time")
        formdata.append("gnd_time", groundTimeWholeValue)
        if (eventDetails?.notes?.note !== notes) {
            formdata.append("notes", notes ? notes : '')
        }
        formdata.append("dep_flight_number", departureFlightName)
        selectedEngineers.forEach((item, index) => {
            formdata.append(`planned_engineers[${[index]}]`, item.id)
        })
        if (scopeChanged) {
            selectedScope.forEach((item, index) => {
                formdata.append(`scope_of_approval[${[index]}]`, item.id)
            })
        }
        await PlanningLineMaintenance.updateLineEvents(formdata).then((res) => {
            if (res.success) {
                message.showToastMessage({
                    message: "Events updated successfully !",
                    variant: "success",
                });
                handleClose()
                setbusy(false)
            }
        }).catch((res) => {
            handleClose()
            message.showToastMessage({
                message: res.error ? res.error : "Something went wrong. Try again!",
                variant: "error",
            });
        })

    }
    const resetErrorsHandler = (name) => {
        setErrors((e) => ({ ...e, [name]: false }));
    };


    function container() {
        // Use the fullscreen element if in fullscreen mode, otherwise just the document's body
        return document.fullscreenElement ?? document.body;
    }
    return (
        <div>
            <MROFullScreenRightDrawer open={true} container={container()}>

                <AppBar position="static" className={classes.appBar} elevation={0}>
                    <Toolbar>
                        <Grid container xs={12}>
                            <Grid >
                                <IconButton
                                    onClick={handleClose}
                                    edge="start"
                                    className={classes.backButton}
                                    color="inherit"
                                    aria-label="back"
                                >
                                    <BackIcon />
                                </IconButton>
                            </Grid>
                            <Grid item xs={10} >
                                <Typography variant="subtitle2" style={{ fontSize: "17px", paddingTop: '5px' }}>
                                    Individual LM Event Info
                                </Typography>
                            </Grid>

                        </Grid>
                    </Toolbar>
                </AppBar>
                {loading && _.isEmpty(eventDetails) ? (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        minHeight="700px"
                    >
                        <CircularProgress color="primary" size={40} />
                    </Box>
                ) : (
                    <div className={classes.wrapper}>
                        <form onSubmit={handleSubmit} noValidate>
                            <Typography variant='subtitle1' color='primary' className={classes.title}>Detail</Typography>

                            {/* Details info */}
                            <div style={{ width: "100%", marginBottom: "20px" }}>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    // justifyContent={(loading || _.isEmpty(currentSelection)) && "center"}
                                    bgcolor="#F5F5F5"
                                    className={classes.partCard}
                                >
                                    <>
                                        <Box flexGrow={1}>
                                            <div style={{ width: "100%" }}>
                                                <GetInfoCard keyName={"Airline"} value={eventDetails?.airline} />
                                                <GetInfoCard keyName={"Arrival Flight #"} value={eventDetails?.arr_flight_number} />

                                            </div>
                                        </Box>
                                        <Box flexGrow={1}>
                                            <div style={{ width: "100%" }}>
                                                <GetInfoCard
                                                    keyName={"Base"}
                                                    value={eventDetails?.base}
                                                />
                                                <GetInfoCard
                                                    keyName={"Event Type"}
                                                    value={eventDetails?.event_type}
                                                />

                                            </div>
                                        </Box>
                                        <Box flexGrow={1} alignSelf="flex-start">
                                            <div style={{ width: "100%" }}>
                                                <GetInfoCard
                                                    keyName={"Series ID"}
                                                    value={eventDetails?.series_id}
                                                />
                                                <GetInfoCard
                                                    keyName={"Work Order #"}
                                                    value={<a onClick={() => {
                                                        setAction('view_detail_popup')
                                                    }} style={{ textDecoration: 'underline' }}>{eventDetails?.work_order_number}</a>}
                                                />

                                            </div>
                                        </Box>
                                    </>
                                </Box>
                            </div>
                            {/* Event Scheduler */}
                            <Typography variant='subtitle1' color='primary' className={classes.title}>Event Scheduler</Typography>
                            <Grid container xs={12} spacing={2}>
                                <Grid item xs={3} style={{ paddingTop: '15px' }}>
                                    <DateTimeField
                                        // format={"YYYY-MM-DD"}
                                        disabled={!props?.permission?.write}
                                        label={"Arr Date / Time (UTC) *"}
                                        name={'arr_datetime'}
                                        InputLabelProps={{ shrink: true }}
                                        error={errors.arr_datetime}
                                        defaultValue={eventDetails?.arr_datetime}
                                        value={arrivalTime}
                                        helperText={errors.arr_datetime ? "*please enter a date" : null}
                                        style={
                                            { opacity: 0.55, cursor: "not-allowed !important" }}
                                        onChange={(e) => {
                                            resetErrorsHandler("arr_datetime");
                                            setArrivalTime(e)
                                        }}
                                    // InputProps={{
                                    //     className: !props?.permission?.write && classes.disableField,
                                    // }}
                                    />
                                </Grid>
                                <Grid item xs={3} style={{ paddingTop: '15px' }}>
                                    <DateTimeField
                                        label={"Dep Date / Time (UTC) *"}
                                        name={'dep_datetime'}
                                        disabled={!props?.permission?.write}
                                        InputLabelProps={{ shrink: true }}
                                        error={errors.dep_date_time}
                                        defaultValue={eventDetails?.dep_datetime}
                                        value={departureTime}
                                        helperText={errors.dep_date_time ? "*please enter a date" : null}
                                        style={
                                            { opacity: 0.55, cursor: "not-allowed !important" }}
                                        onChange={(e) => {
                                            resetErrorsHandler("dep_date_time");
                                            setDepartureTime(e)
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <MROTextField
                                        disabled={!props.permission.write}
                                        name={'dep_flight_number'}
                                        label={'Departure Flight # '}
                                        placeholder="Departure Flight #"
                                        // error={errors.dep_flight_number}
                                        value={departureFlightName}
                                        defaultValue={eventDetails.dep_flight_number ? eventDetails.dep_flight_number : "--"}
                                        onChange={(e) => {
                                            setDepartureFlightName(e.target.value)
                                        }
                                        }

                                        InputLabelProps={{ shrink: true }}>

                                    </MROTextField>
                                </Grid>
                                <Grid item xs={3} >
                                    <MROTextField
                                        disabled={!props.permission.write}
                                        className={classes.disableField}
                                        name="gnd_time"
                                        error={errors?.gnd_time}
                                        helperText={errors?.gnd_time}
                                        label={"Gnd Time *"}
                                        variant="outlined"
                                        value={groundTime}
                                        InputLabelProps={{ shrink: true }}
                                        placeholder={"Hours"}
                                        InputProps={{

                                            endAdornment: <InputAdornment position="end">
                                                <AccessTimeIcon />

                                            </InputAdornment>,

                                        }}

                                    />
                                </Grid>
                            </Grid>


                            {/*Planning Info */}
                            <Typography variant='subtitle1' color='primary' className={classes.title}>Planning Info</Typography>

                            <Grid container xs={12} spacing={2}>
                                <Grid item xs={3}>

                                    <MROTextField label={'Planned Aircraft *'}
                                        disabled={!props?.permission?.write}
                                        select
                                        InputLabelProps={{ shrink: true }}
                                        value={plannedAircraft}
                                        name={"planned_aircraft"}
                                        error={errors?.planned_aircraft}
                                        onChange={(e) => {
                                            resetErrorsHandler(e.target.name)
                                            setPlannedAircraft(e.target.value)
                                        }
                                        }>
                                        <MenuItem value={""}>Select</MenuItem>
                                        {approvalTypes && approvalTypes.map((value) => (
                                            <MenuItem value={value.id}>{value.type_name}</MenuItem>
                                        ))}
                                    </MROTextField>
                                </Grid>
                                <Grid item xs={3}>
                                    <QuantityCounter
                                        disabled={!props?.permission?.write}
                                        name={"mech_quantity"}
                                        label={"Mechs Required *"}
                                        // shouldNotAcceptZero={true}
                                        placeholder="Mech QTY"
                                        error={errors["mech_quantity"]}
                                        helperText={
                                            errors["mech_quantity"] &&
                                            (errors["mech_quantity_is_zero"]
                                                ? "Mech QTY Required Must Be Greater Than 0"
                                                : `Please enter Mech Quantity required`)
                                        }
                                        setError={() =>
                                            setErrors((prevState) => ({
                                                ...prevState,
                                                mech_quantity: false,
                                            }))
                                        }
                                        setQty={setMechQty}
                                        qty={mechQty}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <QuantityCounter
                                        disabled={!props?.permission?.write}
                                        label={"LAEs Required *"}
                                        placeholder="QTY"
                                        name={'lae_quantity'}
                                        error={errors["lae_quantity"]}
                                        // shouldNotAcceptZero={true}
                                        helperText={
                                            errors["lae_quantity"] &&
                                            (errors["lae_quantity_is_zero"]
                                                ? "QTY Required Must Be Greater Than 0"
                                                : `Please enter LAE Quantity required`)
                                        }
                                        setError={() =>
                                            setErrors((prevState) => ({
                                                ...prevState,
                                                lae_quantity: false,
                                            }))
                                        }
                                        setQty={setLaeQty}
                                        qty={laeQty}
                                    />

                                </Grid>
                                <Grid item xs={3}>
                                    <NumericIncrement
                                        name={"total_man_hours"}
                                        label={"Total Planned Man-Hrs *"}
                                        placeholder="QTY"
                                        noIncreament
                                        error={errors["total_man_hours"]}
                                        helperText={
                                            errors["total_man_hours"] &&
                                            (errors["quantity_is_zero"]
                                                ? "QTY Required Must Be Greater Than 0"
                                                : `Please enter total Man hours required`)
                                        }
                                        setError={() =>
                                            setErrors((prevState) => ({
                                                ...prevState,
                                                total_man_hours: false,
                                            }))
                                        }
                                        inputProps={{ min: 0, step: 0.25 }}
                                        InputLabelProps={{ shrink: true }}
                                        setState={setQty}
                                        state={qty}
                                        value={qty.man_hours}
                                        disabled={!props?.permission?.write}
                                    />

                                </Grid>
                            </Grid>
                            <Grid xs={12}>
                                <Autocomplete
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    value={selectedEngineers && selectedEngineers}
                                    defaultValue={eventDetails?.planned_engineers}
                                    id="tags-standard"
                                    multiple
                                    PopperComponent={PopperMy}
                                    getOptionLabel={(option) => option?.name || ""}
                                    options={plannedEngineers && plannedEngineers}
                                    fullWidth
                                    disabled={!props.permission.write}
                                    onChange={(event, value) => {
                                        setSelectedEngineers(value);
                                        // setError({ ...error, part_number: null });
                                    }}
                                    renderInput={(params) => (
                                        <MROTextField
                                            {...params}
                                            label="Planned Engineers"
                                            color={"primary"}
                                            // placeholder="Search planned engineers"
                                            margin="normal"
                                            variant="standard"
                                            id="test"
                                            InputLabelProps={{ shrink: true }}

                                        // autoFocus={error.part_number}
                                        // error={error.part_number}
                                        // helperText={
                                        //     error.part_number ? "*please select kit part" : null
                                        // }
                                        />
                                    )}
                                />

                            </Grid>
                            <Grid xs={12}>
                                <Autocomplete
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    id="tags-standard"
                                    disabled={!props.permission.write}
                                    // loading={loading}
                                    PopperComponent={PopperMy}
                                    defaultValue={eventDetails?.scope_of_approval}
                                    value={selectedScope && selectedScope}
                                    getOptionLabel={(option) => option?.type_name || ""}
                                    options={scopeTypes && scopeTypes || []}
                                    fullWidth
                                    multiple
                                    onChange={(event, value) => {
                                        setSelectedScope(value);
                                        setScopeChanged(true)
                                        // setErrors({ ...errors, scope_of_approval: null });
                                    }}
                                    renderInput={(params) => (
                                        <MROTextField
                                            {...params}
                                            label="Specific Scope of Approval"
                                            color={"primary"}
                                            margin="normal"
                                            variant="standard"
                                            id="test"
                                            InputLabelProps={{ shrink: true }}

                                        // name={'scope_of_approval'}
                                        // autoFocus={errors.scope_of_approval}
                                        // error={errors.scope_of_approval}
                                        // helperText={
                                        //     errors.scope_of_approval ? "*please select scoe of approval" : null
                                        // }
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <MROTextField
                                    name={"notes"}
                                    label={"Notes"}
                                    multiline
                                    placeholder="Add any specific planning notes"
                                    InputProps={{
                                        style: {
                                            height: 'max-content',
                                            width: "100%",
                                            // pointerEvents: disabled && "none",
                                            // backgroundColor: disabled && "#F5F5F5",
                                        },
                                    }}
                                    inputProps={{ maxLength: 1000 }}
                                    InputLabelProps={{ shrink: true }}
                                    disabled={!props.permission.write}
                                    value={notes}

                                    onChange={(e) => {
                                        setNotes(e.target.value);

                                    }}


                                />
                            </Grid>
                            {props?.permission?.write && <Grid className={classes.actionButtons} spacing={1} container style={{ paddingBottom: '20px' }}>
                                <Grid item>
                                    <MROButton
                                        type="button"
                                        variant={"contained"}
                                        onClick={handleClose}
                                    >
                                        Cancel
                                    </MROButton>
                                </Grid>
                                <Grid item>
                                    <MROButton
                                        type="submit"
                                        variant={"contained"}
                                        color="primary"
                                        loading={busy}
                                    >
                                        Complete
                                    </MROButton>
                                </Grid>
                            </Grid>}
                        </form>
                    </div>
                )
                }
            </MROFullScreenRightDrawer >
            {action && action === 'view_detail_popup' && <DetailsPopUp handleClose={() => {
                setAction(null)
            }} selectedRowDetails={currentSelection.work_order_id} />}

        </div >
    )
}
