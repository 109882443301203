import React from "react";

import pdfLogo from "../../assets/pdf.png";
import jpgLogo from "../../assets/jpg.png";

import { BASE_API_URL_BOXR } from "lib/constants";
import { Typography, makeStyles } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  fileContainer: {
    // width: "fit-content",
    width: "100%",
    minHeight: "159px",
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "15px",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    padding: "10px",
  },

  filePreviewContainer: {
    width: "100px",
    marginRight: "30px",
    position: "relative",
  },

  cancelIcon: {
    color: "red",
    position: "absolute",
    top: 0,
    right: 0,
    cursor: "pointer",
  },

  logo: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },

  certDialog: {
    "& .MuiDialog-paper": {
      boxShadow: "0px 3px 6px #00000029",
    },
  },
}));

function FetchedFilePreview(props) {
  const { files, fileDeleteHandler, isLoading, selectedFileId } = props;

  const classes = useStyles();

  const renderItem = (fileId) => {
    if (fileDeleteHandler) {
      return isLoading && fileId === selectedFileId ? (
        <CircularProgress
          style={{ position: "absolute", top: 0, right: 0 }}
          size={10}
        />
      ) : (
        <CancelIcon
          onClick={(e) => {
            // e.stoppropagation();
            fileDeleteHandler(fileId);
          }}
          className={classes.cancelIcon}
          fontSize="medium"
        />
      );
    }
  };

  return (
    <div className={classes.fileContainer}>
      {files.length !== 0 ? (
        files.map((item) => {
          const fileType = item.filename.split(".")[1];

          let logo = pdfLogo;
          if (
            fileType === "jpg" ||
            fileType === "jpeg" ||
            fileType === "png" ||
            fileType === "svg"
          ) {
            logo = `${BASE_API_URL_BOXR}${item.url}`;
          }

          return (
            <div key={item.id} className={classes.filePreviewContainer}>
              <div style={{ marginBottom: "20px" }}>
                {renderItem(item.id)}
                <div
                  style={{
                    cursor: "point",
                    height: "90px",
                    width: "70px",
                  }}
                  onClick={() => window.open(`${BASE_API_URL_BOXR}${item.url}`)}
                >
                  <img src={logo} alt="file-logo" className={classes.logo} />
                </div>
                <Typography
                  variant="body1"
                  noWrap
                  style={{ marginTop: "10px", color: "#4D4F5C" }}
                >
                  {item.filename}
                </Typography>
              </div>
            </div>
          );
        })
      ) : (
        <h3>No files found</h3>
      )}
    </div>
  );
}

export default FetchedFilePreview;
