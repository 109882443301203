import requestApi from "lib/services/api/common/boxp_api_client";
import { BASE_API_URL_BOXP } from "lib/constants";

const getAllActions = (params) => {
    return requestApi({
        url: `/action_calendar`,
        method: "GET",
        params,
    });
}
const createAction = (formData) => {
    return requestApi({
        url: `/action_calendar`,
        method: "POST",
        data: formData
    });
};

const getAllDropDown = (params) => {
    return requestApi({
        url: `/action_dependency`,
        method: "GET",
        params
    });
};
const getActions = (id) => {
    return requestApi({
        url: `/action_calendar/${id}`,
        method: "GET",
    });
}
const updateAction = (data, id) => {
    return requestApi({
        url: `/action_calendar/${id}?_method=PUT`,
        method: "POST",
        data,
    });
};
const deleteAction = (id) => {
    return requestApi({
        url: `/action_calendar/${id}`,
        method: "DELETE",
    });
};
const getAllActionStages = (params) => {
    return requestApi({
        url: `/action_stages`,
        method: "GET",
        params
    });
};

const getStagesDetails = (params) => {
    return requestApi({
        url: `/action_stage_details`,
        method: "GET",
        params
    });
};
const unlockPopup = (formData) => {
    return requestApi({
        url: `/unlock_inspection?_method=PUT`,
        method: "POST",
        data: formData,
    });
};
const updateFile = (formData) => {
    return requestApi({
        url: `/update_file`,
        method: "POST",
        data: formData
    });
};


export const CalenderMaintenanceService = {
    getAllActions,
    createAction,
    getAllDropDown,
    getActions,
    updateAction,
    deleteAction,
    getAllActionStages,
    getStagesDetails,
    unlockPopup,
    updateFile
};