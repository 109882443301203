import CreateReducer from '../../../lib/utils/create_reducer';
import {WORK_ORDER_ACTIONS} from "../../../actions/admin/line/work_order_builder_actions";

export const INITIAL_STATE = {
    loading:false,
    currentCategory:{},
    categories:[],
    sectionLoading:false,
    sectionDetails:[]
};


const workOrderReducer = CreateReducer(INITIAL_STATE, {
    [WORK_ORDER_ACTIONS.SET_TEMPLATE_SECTION_DETAILS](state,action){
        return {
            ...state,
            loading:false,
            sectionLoading: false,
            sectionDetails: action.response
        }
    },
    [WORK_ORDER_ACTIONS.SET_TEMPLATE_CATEGORIES](state,action){
        return {
            ...state,
            loading: false,
            categories: action.response
        };
    },
    [WORK_ORDER_ACTIONS.UPDATE_SECTION_STATUS](state,action){
        const updatedCategories = state.categories.map((section)=>{
            if(section.id === action.response.section_id){
                return ({...section,status:!section.status})
            }
            return section;
        })
        return {
            ...state,
            loading: false,
            categories: updatedCategories
        }
    },
    [WORK_ORDER_ACTIONS.UPDATE_SECTION_FIELD_STATUS](state,action){
        return {
            ...state,
            loading:false,
        }
    },
    [WORK_ORDER_ACTIONS.SET_CURRENT_CATEGORY](state,action){
        return {
            ...state,
            loading:false,
            currentCategory: action.response
        }
    },
    [WORK_ORDER_ACTIONS.TOGGLE_LOADING](state,action){
        return {
            ...state,
            loading:!state.loading
        }
    },
    [WORK_ORDER_ACTIONS.TOGGLE_SECTION_LOADING](state,action){
        return {
            ...state,
            sectionLoading:!state.loading
        }
    }
})

export default workOrderReducer;