import React, { useReducer, useEffect, useState, useContext } from 'react'
import { Grid, Paper, Typography, Box, Button } from '@material-ui/core'
import { statusMapText, statusMapButtons } from './Training'
import tableIcons, {
  options,
} from '../../../components/universal/table_attributes'
import MaterialTable from 'material-table'
import { format24HrDateOnly, getOrganisationId } from 'lib/utils/common_utils';
import tableReducer, {
  INITIAL_TABLE_STATE,
} from 'reducers/table_reducer';
import { UserDashboardServices } from 'lib/services/api/dashboard/user_compiance';
import TableAction from 'actions/table_actions'
import { ToastMessageContext } from "lib/contexts/message_context";
import useDebounce from 'lib/utils/debounce'

export default function Approvals(props) {

  const { selectedBase } = props
  const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
  const { page, pageSize, inProgress } = tableState;
  const [totalCount, setTotalCount] = useState(10);
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const message = useContext(ToastMessageContext);
  const [searchText, setSearchText] = useState('')
  const debounceVal = useDebounce(searchText)

  const trainingListing = async () => {
    setLoading(true)
    const params = {
      organisation_id: getOrganisationId(),
      dial_name: 'approvals',
      page: page + 1,
      limit: pageSize,
      search: debounceVal
    }
    selectedBase.forEach((item, index) => params[`base_station_id[${[index]}]`] = item)
    await UserDashboardServices.getUserComplianceListing(params).then((response) => {
      setData(response.data)
      setTotalCount(response.total_count);
    }).catch(() => {
      message.showToastMessage({
        message: "Something went wrong",
        variant: "error",
      });
    }).finally(() => {
      setLoading(false)
    })

  }

  useEffect(() => {
    trainingListing()
  }, [page, pageSize, selectedBase, debounceVal])


  const tableOptions = {
    ...options,
    page: page,
    total: totalCount,
    pageSize: pageSize,
    search: true,
    toolbar: true,
    headerStyle: {
      paddingTop: "19px",
      paddingBottom: "19px",
      textAlign: "center",
    },
  };






  const columns = [
    {
      title: 'Status',
      field: 'status',
      // width: '10%',
      headerStyle: {
        paddingLeft: '45px',
        textAlign: 'left'

      },
      cellStyle: {
        paddingLeft: '20px'
      },
      render: rowData => <Button size="small" className={statusMapButtons[rowData.status]} variant="outlined"><b>{rowData.status}</b></Button>
    },
    {
      title: 'Stamp #',
      field: 'stamp_number',

      headerStyle: {
        textAlign: 'left',

      },
      cellStyle: {
        textAlign: 'left',
      },

    },
    {
      title: 'User',
      field: 'user',

      headerStyle: {
        textAlign: 'left',

      },
      cellStyle: {
        textAlign: 'left',
      },
    },
    {
      title: 'Base',
      field: 'base',
      headerStyle: {
        textAlign: 'left',

      },
      cellStyle: {
        textAlign: 'left',
      },

    },
    {
      title: 'Item',
      field: 'item',
      headerStyle: {
        textAlign: 'left',
        width: '50%'

      },
      cellStyle: {
        textAlign: 'left',
        width: '50%'
      },
    },

    {
      title: 'Expiry',
      field: 'expiry',
      headerStyle: {
        textAlign: 'left',

      },
      cellStyle: {
        textAlign: 'left',
      },
      render: rowData => rowData.expiry ? <span>
        <Typography style={{ color: rowData.days <= 0 ? '#FF0202' : rowData.days <= 30 ? "#FFB300" : '', fontWeight: 600 }}>
          {
            format24HrDateOnly(rowData.expiry)
          }
        </Typography>
      </span> : '-'
    },
    {
      title: 'Days',
      field: 'days',
      headerStyle: {
        textAlign: 'left',

      },
      cellStyle: {
        textAlign: 'left',
      },
      render: rowData => rowData.days ? <span>
        <Typography style={{ color: rowData.days <= 0 ? '#FF0202' : rowData.days <= 30 ? "#FFB300" : '', fontWeight: 600 }}>
          {
            rowData.days
          }
        </Typography>
      </span> : '-'
    },

  ]
  return (
    <>
      <Grid xs={12} md={12}>
        <Box p={1}>
          <MaterialTable
            style={{ boxShadow: '0px 1px 3px #00000033', paddingRight: '0px' }}
            icons={tableIcons}
            title={<Typography color="primary" variant="subtitle2" style={{ textAlign: "center" }}>
              Approvals
            </Typography>}
            data={data || []}
            columns={columns}
            options={tableOptions}
            isLoading={loading}
            onChangePage={(page) => {
              TableAction.setPage(dispatch, page);
            }}
            onChangeRowsPerPage={(pageSize) => {
              TableAction.setPageSize(dispatch, pageSize);
            }}
            onSearchChange={(search) => {
              setSearchText(search);
            }}
            totalCount={totalCount}
            page={page}
          />
        </Box>
      </Grid>
    </>
  )
}

