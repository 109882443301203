import React, { useState, useContext } from "react";
import MROFullScreenRightDrawer from "components/fullwidth_dialog";
import {
    AppBar,
    IconButton,
    Toolbar,
    makeStyles,
    Typography,
    Box,
    ButtonGroup,
} from "@material-ui/core";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import MROButton from "components/buttons";
import CreateNewActions from "./CreateNewActions/CreateActions";
import History from "./History";
import { WorkshopContext } from 'views/Operations/Workshop/WorkshopContext/WorkshopContext';




const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: "#fff",
        color: "#000000CC",
        boxShadow: "0px 3px 6px #0000001A",
    },
    backButton: {
        marginRight: theme.spacing(2),
    },
    root: { "& > :first-child": { position: "unset" } },
    wrapper: {
        padding: "3%",
        background: "white",
    },
}));

function AddActions(props) {
    const { getAllActions, action_type, workshop_id, action, handleClose, currentWorkshopDetails, tabId, name, copyTab } = props
    const classes = useStyles();
    const [state, setState] = useState("Create New");
    const { setCopyInspection, setCopyParts, setCopyServices, setServiceFlag, setEquipmentFlag, setCopyEquipments, copyInspection, copiedJobs, setCopiedJobs } = useContext(WorkshopContext)



    const handleCopyClose = () => {
        handleClose()
        getAllActions()
        setCopyInspection(false)
        setServiceFlag(false)
        setCopyParts([])
        setCopyServices([])
        setEquipmentFlag(false)
        setCopyEquipments([])
        setCopiedJobs([])
    }
    // c
    return (
        <MROFullScreenRightDrawer open={true} className={classes.root}>
            <AppBar position="static" className={classes.appBar} elevation={0}>
                <Toolbar>
                    <IconButton
                        onClick={() => {
                            handleCopyClose()
                        }}
                        edge="start"
                        className={classes.backButton}
                        color="inherit"
                        aria-label="back"
                    >
                        <BackIcon />
                    </IconButton>
                    <Typography variant="subtitle2">Add Action</Typography>
                </Toolbar>
            </AppBar>
            <div className={classes.wrapper}>
                <Box mt="25px" mb="25px" ml={'20px'}>
                    <ButtonGroup color="primary">
                        <MROButton
                            variant={state === "Create New" ? "contained" : "outlined"}
                            onClick={() => setState("Create New")}
                            style={{
                                width: "125px",
                                color: state === "Create New" ? "white" : undefined,
                            }}

                        >
                            Create New
                        </MROButton>
                        <MROButton
                            variant={state === "History" ? "contained" : "outlined"}
                            onClick={() => setState("History")}
                            style={{
                                width: "125px",
                                color: state === "History" ? "white" : undefined,
                            }}
                        >
                            History
                        </MROButton>
                    </ButtonGroup>
                </Box>

                <Box width="100%">
                    {state === "Create New" ? (
                        <CreateNewActions
                            getAllActions={getAllActions}
                            action_type={action_type}
                            workshop_id={workshop_id}
                            action={action}
                            handleClose={handleClose}
                            copyTab={copyTab}
                        />
                    ) : (
                        <History
                            getAllActions={getAllActions}
                            action_type={action_type}
                            workshop_id={workshop_id}
                            action={action}
                            handleClose={handleClose}
                            currentWorkshopDetails={currentWorkshopDetails}
                            tabId={tabId}
                            tabname={name}
                            copyTab={copyTab}
                        />
                    )}
                </Box>
            </div>
        </MROFullScreenRightDrawer>
    )
}

export default AddActions