import React from 'react'
import makeStyles from "@material-ui/core/styles/makeStyles";
import { MessageServices } from 'lib/services/api/messaging/message_services';
import { useContext, useEffect, useState } from 'react';
import { WorkshopContext } from 'views/Operations/Workshop/WorkshopContext/WorkshopContext';
import { IconButton } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    badge: {
        width: '24px',
        height: '24px',
        borderRadius: '14px',
        // backgroundColor: 'red',
        fontSize: '16px',
        marginTop: '-1px',
        marginLeft: '-15px',
        color: '#fff'
    },
}));

function MessageCount() {
    const classes = useStyles()
    const { messageTotalCount, setMessagetotalCount } = useContext(WorkshopContext)
    const [messageDetails, setMessageDetails] = useState({})
    //message bagde
    let userDetails = JSON.parse(sessionStorage.getItem("user"));

    const getTotalUnReadCount = async () => {
        const params = {
            user_id: userDetails?.id
        }
        MessageServices.getTotalUnReadCount(params).then((res) => {
            setMessageDetails(res.data)
            let total = parseInt(res.data.message_count) + parseInt(res.data.read_sign_count)
            setMessagetotalCount(total)
        }).catch(() => {

        })
    }
    // console.log("messagecount======", messageTotalCount)
    useEffect(() => {
        const timeout = setTimeout(() => {
            getTotalUnReadCount()
        }, 0)
        return () => {
            clearTimeout(timeout)
        }
    }, [])
    return (
        <>
            {messageTotalCount > 0 ? <span>
                <IconButton size="small" style={{
                    backgroundColor: messageDetails.read_sign_color === "red" || messageDetails.message_color === "red" ? "#FF0202" : "#FFB300"
                }} className={classes.badge}>{messageTotalCount}</IconButton></span> : ''}

        </>
    )
}

export default MessageCount