import { Paper, withStyles } from '@material-ui/core'
import React from 'react'


function AutocompletePopper(props) {
    return (
        <Paper {...props} placement="bottom-start" />
    )
}

export default AutocompletePopper