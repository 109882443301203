import React from "react";
import { Typography, Box, makeStyles } from "@material-ui/core";
import Report from "./Reports/Report";
import MROButton from "../../../components/buttons";
import ButtonGroupNavigation from "components/button_group_navigation";
import { Route, Switch } from "react-router-dom";
import MaintHour from "./Maint-Hours/index";
import Performance from "./Performance/LinePerformance";
import Activity from "./Activity/index";
import StationBoard from "./StationBoard";

const buttons = [
  { name: "Performance", redirect: "/org/dashboard/performance" },
  { name: "Activity", redirect: "/org/dashboard/activity" },
  { name: "Maint Hours", redirect: "/org/dashboard/maint-hours" },
  { name: "Station Board", redirect: "/org/dashboard/board" },

];

export default function LineMaintainence(props) {
  const classes = useStyles();
  return (
    <>
      <Box width="100%" className={classes.subNavigation}>
        <Typography></Typography>
        <div className={"top-navigation"} style={{ margin: 0 }}>
          <ButtonGroupNavigation buttons={buttons} {...props} />
        </div>
        <Switch>
          <Route
            exact
            path={"/org/dashboard/performance"}
            component={(props) => (
              <Performance
                // baseStations={baseStations}
                // owners={owners}
                {...props}
              />
            )}
          />
          <Route
            path={"/org/dashboard/activity"}
            component={(rprops) => (
              <Activity
                // baseStations={baseStations}
                // owners={owners}
                {...rprops}
                props={props}
                permission={props?.permission}
              />
            )}
          />
          <Route
            path={"/org/dashboard/maint-hours"}
            component={(props) => (
              <MaintHour
                // baseStations={baseStations}
                // owners={owners}
                {...props}
              />
            )}
          />
          <Route
            path={"/org/dashboard/board"}
            component={(rprops) => (
              <StationBoard
                // baseStations={baseStations}
                // owners={owners}
                {...rprops}
                {...props}
              />
            )}
          />

        </Switch>
      </Box>
    </>
  );
}
const useStyles = makeStyles(theme => ({
  subNavigation: {
    margin: '2% 3%',
    width: '94%',
  }
}));