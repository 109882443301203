import React, { useState } from "react";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import FreeTextField from "./free_text_field";
import Typography from "@material-ui/core/Typography";
import NumericField from "../FieldComponents/numeric_field_with_increments";
import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

function YesNoInput(props) {
  const classes = useStyles();
  const {
    handleChange,
    formState,
    secondary_fields,
    field_value,
    errors,
    setErrors,
  } = props;

  const [value, setValue] = useState(
    field_value === null ? "" : String(field_value) === "true" ? "yes" : "no"
  );
  const [secValue, setSecValue] = useState(
    secondary_fields && secondary_fields[0]?.field_value === null
      ? ""
      : (secondary_fields && String(secondary_fields[0]?.field_value)) ===
        "true"
        ? "yes"
        : "no"
  );



  const handleToggle = (name, value) => {
    if (value) {
      setValue(value);
      handleChange({ name: name, value: value });
    }
  };

  const handleSecToggle = (name, value) => {
    if (value) {
      setErrors({
        ...errors,
        [name]: {
          valid: value ? true : false,
        },
      });
      setSecValue(value);
      handleChange({ name: name, value: value });
    }
  };

  const fieldTypeMap = {
    Freetext: "textfield",
    Datetime: "textfield",
    "Yes/No": "yes_or_no",
    Numeric: "numeric",
    Dropdown: "textfield",
  };

  const error =
    props.errors &&
    !props.disabled &&
    props.errors[props?.id || props?.org_field_id] &&
    !props.errors[props?.id || props?.org_field_id].valid &&
    props.mandatory &&
    !props?.disabled;
  const secError =
    props.errors &&
    props?.secondary_fields &&
    props?.secondary_fields[0]?.org_field_id &&
    props.errors[props?.secondary_fields[0]?.org_field_id] &&
    !props?.errors[props?.secondary_fields[0]?.org_field_id]?.valid;

  console.log({ secError });
  const handleSecInput = (e) => {
    setErrors({
      ...errors,
      [e.name]: {
        valid: e.value !== "" ? true : false,
      },
    });
    handleChange(e);
  };

  return (
    <Grid
      container
      className={"align-left-space-between flex-center"}
      style={!props.secondary_field ? {} : { width: "100%" }}
    >
      <Grid
        item
        style={!props.secondary_field ? {} : { width: "100%" }}
        xs={8}
        lg={6}
      >
        <Typography variant={"subtitle1"}>{`${props.field_name} ${props.mandatory ? " *" : ""
          }`}</Typography>
        <input
          style={{ display: "none" }}
          value={value === "yes" ? true : value === "no" ? false : null}
          name={props?.id || props?.org_field_id}
        />
        <ToggleButtonGroup
          value={value}
          name={props.field_name}
          exclusive
          onChange={(e, val) => handleToggle(props.id, val)}
          aria-label="yes-or-no"
        >
          <ToggleButton
            className={error ? classes.error : {}}
            size={"small"}
            value="no"
            aria-label="centered"
          >
            No
          </ToggleButton>
          <ToggleButton
            className={error ? classes.error : {}}
            size={"small"}
            value="yes"
            aria-label="left aligned"
          >
            Yes
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      {(value === "yes" && props.field_name !== "Tools Removed from A/C") && props.secondary_field && (
        <Grid style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }} xs={12} lg={6}>
          {fieldTypeMap[props?.secondary_fields[0]?.field_type] ===
            "textfield" && (
              <FreeTextField
                {...props.secondary_fields[0]}
                errors={errors}
                setErrors={setErrors}
                menuItems={props?.secondary_fields[0]?.dropdown_values}
                handleChange={handleSecInput}
              />
            )}

          {fieldTypeMap[props?.secondary_fields[0]?.field_type] ===
            "numeric" && (
              <NumericField
                {...props?.secondary_fields[0]}
                errors={errors}
                handleChange={handleSecInput}
                formState={formState}
              />
            )}
          {fieldTypeMap[props?.secondary_fields[0]?.field_type] ==
            "yes_or_no" && (
              <Box>
                <Typography variant={"subtitle1"} style={{ marginTop: "25px" }}>
                  {props?.secondary_fields[0]?.field_name + "*"}
                </Typography>
                <input
                  style={{ display: "none" }}
                  value={
                    secValue === "yes" ? true : secValue === "no" ? false : null
                  }
                  name={
                    props?.secondary_fields[0]?.id ||
                    props?.secondary_fields[0]?.org_field_id
                  }
                />
                <ToggleButtonGroup
                  value={secValue}
                  name={props?.secondary_fields[0]?.field_name}
                  exclusive
                  onChange={(e, val) =>
                    handleSecToggle(props?.secondary_fields[0]?.org_field_id, val)
                  }
                  aria-label="yes-or-no"
                >
                  <ToggleButton
                    className={secError && !secValue ? classes.error : {}}
                    color={error ? "red" : "primary"}
                    size={"small"}
                    value="no"
                    aria-label="centered"
                  >
                    No
                  </ToggleButton>
                  <ToggleButton
                    className={secError && !secValue ? classes.error : {}}
                    color={error ? "red" : "primary"}
                    size={"small"}
                    value="yes"
                    aria-label="left aligned"
                  >
                    Yes
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
            )}
        </Grid>
      )}

      {(value === "no" && props.field_name === "Tools Removed from A/C") && props.secondary_field && (
        <Grid style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }} xs={12} lg={6}>

          {fieldTypeMap[props?.secondary_fields[0]?.field_type] ==
            "yes_or_no" && (
              <Box>
                <Typography variant={"subtitle1"} style={{ marginTop: "25px" }}>
                  {props?.secondary_fields[0]?.field_name + "*"}
                </Typography>
                <input
                  style={{ display: "none" }}
                  value={
                    secValue === "yes" ? true : secValue === "no" ? false : null
                  }
                  name={
                    props?.secondary_fields[0]?.id ||
                    props?.secondary_fields[0]?.org_field_id
                  }
                />
                <ToggleButtonGroup
                  value={secValue}
                  name={props?.secondary_fields[0]?.field_name}
                  exclusive
                  onChange={(e, val) =>
                    handleSecToggle(props?.secondary_fields[0]?.org_field_id, val)
                  }
                  aria-label="yes-or-no"
                >
                  <ToggleButton
                    className={secError && !secValue ? classes.error : {}}
                    color={error ? "red" : "primary"}
                    size={"small"}
                    value="no"
                    aria-label="centered"
                  >
                    No
                  </ToggleButton>
                  <ToggleButton
                    className={secError && !secValue ? classes.error : {}}
                    color={error ? "red" : "primary"}
                    size={"small"}
                    value="yes"
                    aria-label="left aligned"
                  >
                    Yes
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
            )}
        </Grid>



      )}
      {/* {value === "no" && props?.secondary_field && (
        <Grid style={{ width: "100%" }} xs={12} lg={6}>
          {fieldTypeMap[props?.secondary_fields[0]?.field_type] ==
            "yes_or_no" && (
              <Box>
                <Typography variant={"subtitle1"}>
                  {props?.secondary_fields[0]?.field_name + " *"}
                </Typography>
                <input
                  style={{ display: "none" }}
                  value={
                    secValue === "yes" ? true : secValue === "no" ? false : null
                  }
                  name={
                    props?.secondary_fields[0]?.id ||
                    props?.secondary_fields[0]?.org_field_id
                  }
                />
                <ToggleButtonGroup
                  value={secValue}
                  name={props?.secondary_fields[0]?.field_name}
                  exclusive
                  onChange={(e, val) =>
                    handleSecToggle(props?.secondary_fields[0]?.org_field_id, val)
                  }
                  aria-label="yes-or-no"
                >
                  <ToggleButton
                    className={secError && !secValue ? classes.error : {}}
                    color={error ? "red" : "primary"}
                    size={"small"}
                    value="no"
                    aria-label="centered"
                  >
                    No
                  </ToggleButton>
                  <ToggleButton
                    className={secError && !secValue ? classes.error : {}}
                    color={error ? "red" : "primary"}
                    size={"small"}
                    value="yes"
                    aria-label="left aligned"
                  >
                    Yes
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
            )}
        </Grid>
      )} */}
    </Grid >
  );
}

export default React.memo(YesNoInput);

const useStyles = makeStyles((theme) => ({
  error: {
    borderColor: "#f44336",
    borderWidth: "1px",
    borderStyle: "solid",
    margin: "0px",
  },
}));
