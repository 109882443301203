import React, { useState, useMemo } from "react";

import { Grid, Paper, Typography, Box, Button, Divider, makeStyles } from "@material-ui/core";
import Chart from "./Chart";
import ChartLoader from "../../Workshops/Storage/ChartLoader";



export default function VehiclePass(props) {
  const { inspectionData, airSidePass, loading } = props
  const classes = useStyles()
  const [inspection, setInspection] = useState(0)
  const [airSideValue, setAirSideValue] = useState(0)



  useMemo(() => {
    let totalValue = inspectionData.green + inspectionData.amber + inspectionData.red
    let percentage = (inspectionData.green / totalValue) * 100

    if (totalValue === 0) {
      setInspection(totalValue)
    }
    else {
      setInspection(Math.floor(percentage))
    }
  }, [inspectionData])

  useMemo(() => {
    let totalValue = airSidePass.green + airSidePass.amber + airSidePass.red
    let percentage = (airSidePass.green / totalValue) * 100

    if (totalValue === 0) {
      setAirSideValue(totalValue)
    }
    else {
      setAirSideValue(Math.floor(percentage))
    }
  }, [airSidePass])


  return (

    <Box mt={2}>

      <Paper style={{ width: '100%', height: '350px' }}>

        <Box width="100%" display="flex" p={2}>

          <Box width="98%">
            <Grid>
              <Typography gutterBottom={20} variant="subtitle2" color='primary' >Vehicles</Typography>
            </Grid>
            <Grid container >

              <Grid item xs={8}>

                {loading ? <ChartLoader padding={'8px'} /> : <>
                  <Chart
                    // values={quote}
                    value1={inspectionData.green}
                    value2={inspectionData.amber}
                    value3={inspectionData.red}
                    labels={["> 30 Days", "< 30 Days", "< 0 Days"]}
                    labelName={"Inspection"}
                  // totalPercentage={quotesPercentage}
                  />
                  <div>
                    <Typography color='secondary' className={classes.quotePercentageText} >{inspection}{"%"}</Typography>
                    <Typography className={classes.centerLabel}>Inspection</Typography>
                  </div>
                </>
                }
              </Grid>
              <Grid item xs={4} container direction="column">
                <Grid item container direction="row" >
                  <Grid item  ><Typography gutterBottom={20} variant="h5" style={{ color: "#4FC605", textAlign: "left" }}>{inspectionData.green}</Typography></Grid>
                  <Grid className={classes.textLabels}><small > &gt; 30 Days</small></Grid>
                </Grid>
                <Grid item container direction="row" >
                  <Grid item  ><Typography gutterBottom={20} variant="h5" style={{ color: "#FFB300", textAlign: "left" }}> {inspectionData.amber}</Typography></Grid>
                  <Grid className={classes.textLabels}><small> &lt; 30 Days</small></Grid>
                </Grid>
                <Grid item container direction="row" >
                  <Grid item  ><Typography gutterBottom={20} variant="h5" style={{ color: "#DF0011", textAlign: "left" }}> {inspectionData.red}</Typography></Grid>
                  <Grid className={classes.textLabels}><small> &lt; 0 Days</small></Grid>
                </Grid>
              </Grid>

            </Grid>
          </Box>

        </Box>
        <Divider style={{ marginTop: '8px' }} />
        <Box width="100%" display="flex" p={2}>

          <Grid container >
            <Grid item xs={8}>
              {loading ? <ChartLoader /> : <>
                <Chart
                  // values={quote}
                  value1={airSidePass.green}
                  value2={airSidePass.amber}
                  value3={airSidePass.red}
                  labels={["> 30 Days", "< 30 Days", "< 0 Days"]}
                // labelName={"Airside Pass"}
                // totalPercentage={quotesPercentage}
                />
                <div>
                  <Typography color='secondary' className={classes.quotePercentageText} >{airSideValue}{"%"}</Typography>
                  <Typography className={classes.centerLabel}>Airside Pass</Typography>
                </div>
              </>

              }
            </Grid>
            <Grid item xs={4} container direction="column">
              <Grid item container direction="row" >
                <Grid item  ><Typography gutterBottom={20} variant="h5" style={{ color: "#4FC605", textAlign: "left", }}>{airSidePass.green}</Typography></Grid>
                <Grid className={classes.textLabels}><small > &gt; 30 Days</small></Grid>
              </Grid>
              <Grid item container direction="row" >
                <Grid item  ><Typography gutterBottom={20} variant="h5" style={{ color: "#FFB300", textAlign: "left" }}> {airSidePass.amber}</Typography></Grid>
                <Grid className={classes.textLabels}><small> &lt; 30 Days</small></Grid>
              </Grid>
              <Grid item container direction="row" >
                <Grid item  ><Typography gutterBottom={20} variant="h5" style={{ color: "#DF0011", textAlign: "left" }}> {airSidePass.red}</Typography></Grid>
                <Grid className={classes.textLabels}><small> &lt; 0 Days</small></Grid>
              </Grid>
            </Grid>

          </Grid>
        </Box>
      </Paper>
    </Box>





  );
}


const useStyles = makeStyles({
  textLabels: {

    marginTop: '7px',
    marginLeft: '10px'

  },
  quotePercentageText: {
    marginTop: ' -86px',
    marginBottom: '46px',
    textAlign: ' center',
    fontSize: '18px',

  },
  centerLabel: {
    textAlign: ' center',
    marginTop: ' -50px',
    color: '#4D4F5C',
    fontSize: '12px'
  },

})