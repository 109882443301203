import requestrApi from "lib/services/api/common/boxr_api_client";
import { BASE_API_URL_BOXR } from "lib/constants";


const getActionNumber = (params) => {
    return requestrApi({
      url: `${BASE_API_URL_BOXR}/workshop_actions/list_action_numbers`,
      method: "GET",
      params: params
    });
  };
const createAction = (formData) => {
    return requestrApi({
      url: `${BASE_API_URL_BOXR}/action_imp`,
      method: "POST",
      data: formData
    });
  };


export const ServicesAndEquip = {
    getActionNumber,
    createAction
};
