import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TabSectionBar } from "components/tab_component";
import Divider from "@material-ui/core/Divider";
import { Check, Warning, CheckBox } from "@material-ui/icons";
import PositiveSwitch from "components/form_components/switch";
import StaffLeave from "./StaffLeave";
import StaffOvertime from "./StaffOvertime";
import MyLeave from "./MyLeave";
import MyOvertime from "./MyOvertime";
import { IconButton, Paper } from "@material-ui/core";
import ResourcesServices from 'lib/services/api/planning/Resource/resources';
import { getOrganisationId } from "lib/utils/common_utils";


const useStyles = makeStyles((theme) => ({
    wrapper: {
        // margin: "1% 3%",
    },
    content: {
        boxShadow: "0px 1px 3px #00000033",
        margin: "2% 0",
    },
    detail: {
        backgroundColor: "#fff",
        padding: "1%",
    },
    success: {
        color: "green",
        marginLeft: 8,
    },
    warning: {
        color: "orange",
        marginLeft: 8,
    },
    indicator: {
        display: "flex",
        alignItems: "center",
    },
}));

function TabIndex(props) {
    const classes = useStyles();
    const [currentTab, setCurrentTab] = useState(0);
    const [tabLeaveValue, setTabLeaveValue] = useState(0);
    const [tabOvertimeValue, setTabOvertimeValue] = useState(0);
    const [pageLoading, setPageLoading] = useState(true);
    const [zoneData, setZoneData] = useState([])
    const [areaData, setAreaData] = useState([])
    const [shiftType, setShiftType] = useState([])
    const [bases, setBases] = useState([]);

    const navMenu = [
        { label: <div className={classes.indicator}>Staff Leave&nbsp;&nbsp;{tabLeaveValue > 0 ? <IconButton style={{ background: '#FFB300', color: '#fff', height: '17px', fontSize: '13px', width: '15px' }}> {tabLeaveValue}</IconButton> : ''}</div> },
        {
            label: (
                <div className={classes.indicator}>Staff Overtime&nbsp;&nbsp;{tabOvertimeValue > 0 ? <IconButton style={{ background: '#FFB300', color: '#fff', height: '17px', fontSize: '13px', width: '15px' }}>{tabOvertimeValue}</IconButton> : ""} </div>
            ),
        },
        // { label: <div className={classes.indicator}>Maint Actions </div> },
        { label: <div className={classes.indicator}>My Leave</div> },
        { label: <div className={classes.indicator}>My Overtime </div> },

    ];





    const getBaseStation = async () => {
        const params = {
            organisation_id: getOrganisationId(),
            order_by: 'station'
        }
        ResourcesServices.getBaseStation(params)
            .then((res) => {
                if (res) {
                    setBases(res.data);
                }

            })
            .catch((err) => {


            });
    };


    const getShiftTypeDetails = async () => {
        const params = {
            organisation_id: getOrganisationId(),
        }
        ResourcesServices.fetchShiftTypes(params)
            .then((res) => {
                if (res) {
                    setShiftType(res.data.filter((item) => item.id !== 1));

                }

            })
            .catch((err) => {

            });
    };

    const getAreaListing = () => {

        const params = {
            organisation_id: getOrganisationId(),

        }
        ResourcesServices.getShiftAreaListing(params).then((res) => {
            setAreaData(res.data)
        }).catch(() => {

        }).finally(() => {

        })

    }

    const getZoneListing = () => {

        const params = {
            organisation_id: getOrganisationId(),


        }
        ResourcesServices.getZoneListing(params).then((res) => {
            setZoneData(res.data)

        }).catch(() => {

        }).finally(() => {

        })

    }




    useEffect(() => {
        getBaseStation()
        getShiftTypeDetails()
        getAreaListing()
        getZoneListing()

    }, [])

    return (
        <Paper className={classes.wrapper}>
            <TabSectionBar
                // completed={completed}
                {...props}
                tabProperties={navMenu}
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
                isGettingDetails={false}
            />
            <Divider
                variant="fullWidth"
                style={{ padding: "1px", backgroundColor: "rgba(222, 214, 214, 0.2)" }}
            />
            <div className={classes.detail}>
                {currentTab === 0 && <StaffLeave
                    setTabLeaveValue={setTabLeaveValue}
                    bases={bases}
                    shiftType={shiftType}
                    areaData={areaData}
                    zoneData={zoneData}
                />}
                {currentTab === 1 &&
                    <StaffOvertime setTabOvertimeValue={setTabOvertimeValue}
                        pageLoading={pageLoading} setPageLoading={setPageLoading}

                        bases={bases}
                        shiftType={shiftType}
                        areaData={areaData}
                        zoneData={zoneData}

                    />}
                {/* {currentTab === 2 && <MaintActions />} */}
                {currentTab === 2 && <MyLeave />}
                {currentTab === 3 && <MyOvertime />}

            </div>
        </Paper>
    );
}

export default TabIndex;
