import requestApi from "lib/services/api/common/boxp_api_client";

function getActionIndicators(params) {
  return requestApi({
    url: `/action_indicator`,
    method: "GET",
    params,
  });
}
function getApprovedPosition(params) {
  return requestApi({
    url: `/approved_position`,
    method: "GET",
    params,
  });
}
function getCurrentPosition(params) {
  return requestApi({
    url: `/current_position`,
    method: "GET",
    params,
  });
}

function getActionBreakdown(params) {
  return requestApi({
    url: `/action_breakdown`,
    method: "GET",
    params
  })
}
function getEstimationDeparture(params) {
  return requestApi({
    url: `/estimated_departure`,
    method: "GET",
    params
  })
}
function getListItems(params) {
  return requestApi({
    url: `/line_items`,
    method: "GET",
    params: params
  })

}
function getExceptionTableListing(params) {
  return requestApi({
    url: `/action_overview`,
    method: "GET",
    params: params
  })

}

function getApprovalSubmissionTable(params) {
  return requestApi({
    url: `/submission_table`,
    method: "GET",
    params
  })
}
function getApprovalSubmissionDocument(params) {
  return requestApi({
    url: `/approval_documents`,
    method: "GET",
    params
  })
}
function downloadSubmissionDocument(params) {
  return requestApi({
    url: `/download_approval_documents`,
    method: "GET",
    params
  })
}

function getStandardPricing(params) {
  return requestApi({
    url: `/get_standard_pricing`,
    method: "GET",
    params
  })
}
function getHistoricalJobs(params) {
  return requestApi({
    url: `/historical_jobs`,
    method: "GET",
    params
  })
}
function getHistoricalJobsDetails(params) {
  return requestApi({
    url: `/historical_job_details`,
    method: "GET",
    params
  })
}



function updateApprovalStatus(data) {
  return requestApi({
    url: `/action_approval?_method=PUT`,
    method: "POST",
    data,
  });
}
function createNewApproval(data) {
  return requestApi({
    url: `/create_new_approval`,
    method: "POST",
    data,
  });
}
function customerApproval(data) {
  return requestApi({
    url: `/approve_customer_approval?_method=PUT`,
    method: "POST",
    data,
  });
}
const deleteApproval = (params, id) => {
  return requestApi({
    url: `/delete_approved_position/${id}`,
    method: "DELETE",
    params
  });
};

function getNextStage(formData) {
  return requestApi({
    url: `/next_stage?_method=PUT`,
    method: "POST",
    data: formData
  });
}

function updateCustomerSupply(formData) {
  return requestApi({
    url: `/update_customer_supply?_method=PUT`,
    method: "POST",
    data: formData
  });
}
function updateApprovalActionStatus(formData) {
  return requestApi({
    url: `/approve_all_actions?_method=PUT`,
    method: "POST",
    data: formData
  });
}
function reviseEstDeparture(formData) {
  return requestApi({
    url: `/revise_estimate_departure`,
    method: "POST",
    data: formData
  });
}

export const CommercialServices = {
  getActionIndicators,
  updateApprovalStatus,
  getApprovedPosition,
  getCurrentPosition,
  createNewApproval,
  customerApproval,
  deleteApproval,
  getActionBreakdown,
  getListItems,
  getNextStage,
  updateCustomerSupply,
  updateApprovalActionStatus,
  getEstimationDeparture,
  reviseEstDeparture,
  getApprovalSubmissionTable,
  getApprovalSubmissionDocument,
  downloadSubmissionDocument,
  getExceptionTableListing,
  getStandardPricing,
  getHistoricalJobs,
  getHistoricalJobsDetails


};
