import requestApi from "lib/services/api/common/boxp_api_client";

function getCertificationDetails(id) {
  return requestApi({
    url: `/certification/${id}`,
    method: "GET",
  });
}

const unlockCertification = (formData) => {
  return requestApi({
    url: `/undo_certification?_method=PUT`,
    method: "POST",
    data: formData,
  });
};

export const CertificateServices = {
  getCertificationDetails,
  unlockCertification
};
