import React, { useEffect, useContext, useState, useMemo } from 'react'
import { Dialog, DialogContent, DialogTitle, Box, ButtonGroup, Button, FormControlLabel, Grid, MenuItem } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import MROButton from 'components/buttons';
import MROTextField from 'components/form_components/TextField';
import { getOrganisationId } from 'lib/utils/common_utils';
import { ToastMessageContext } from 'lib/contexts/message_context';
import NumericIncrement from "views/Operations/LineMaintenance/WorkOrder/FieldComponents/numeric_increment";
import { WorkOrderServices } from 'lib/services/api/operaitons/lineMaintenance/workOrder/workOrder';



const useStyles = makeStyles(theme => ({
    actionButtons: {
        paddingTop: '20px',
        paddingBottom: '33px',
        textAlign: 'right',
        '& button:not(:last-child)': {
            marginRight: '20px'
        }
    },
    modal: {
        '& .MuiDialog-paperWidthMd': {
            maxWidth: '380px',
            left: "calc(62% - 250px)",
        }
    },
    title: {
        fontSize: '18px',
        '& h2': {
            color: theme.palette.primary.main,
            textAlign: 'center'

        }
    },
    formLabel: {

        '& span:last-child': {
            fontSize: '14px',
        }
    },
    disableField: {
        pointerEvents: "none",
        backgroundColor: "#F5F5F5",
    },

}));

export default function EditTask(props) {
    const classes = useStyles()
    const { handleClose, currentSelection, getListing } = props
    const [errors, setErrors] = useState({})

    const [mroState, setMROState] = useState({ man_hours: '' });
    const [loading, setLoading] = useState(false)
    const message = useContext(ToastMessageContext)



    const handleSubmit = async (e) => {
        e.preventDefault()
        const formdata = new FormData(e.target)
        let valid = true;
        let error = {};


        for (let pair of formdata.entries()) {
            const value = pair[1];
            const key = pair[0];
            // console.table("formdata=====", key, value)
            if (!value) {
                error[key] = true;
                valid = false;
            }
        }

        if (!valid) {
            setErrors(error);
            return;
        }
        const task = formdata.get("task")
        const hours = formdata.get("mro_hours")
        let params = {
            task_id: currentSelection.id,
            task_tlp: task,
            act_hours: hours


        }
        setLoading(true)
        await WorkOrderServices.EditPlannedTask(params).then((res) => {
            handleClose()
            getListing()
            message.showToastMessage({
                message: "Updated successfully",
                variant: "success",
                duration: 800
            });
        }).catch(err => {
            message.showToastMessage({ message: 'Something went wrong', variant: 'error' })
        }).finally(() => {
            setLoading(false)
        })
    }

    useState(() => {
        if (currentSelection !== undefined) {
            setMROState({ man_hours: currentSelection?.act_hrs })
        }
    }, [currentSelection])
    return (
        <div>
            <Dialog
                maxWidth={"md"}
                fullWidth={true}
                open={true}
                className={classes.modal}
                onClose={handleClose}
                aria-labelledby="Edit_task"
                style={{ width: '380px', left: '40%' }}
            >
                <DialogTitle variant="subtitle2" color="secondary"
                    className={classes.title}><span style={{ fontSize: '16px' }}>Edit Planned Task</span>
                </DialogTitle>

                <DialogContent className={classes.card} >
                    <form onSubmit={handleSubmit}>


                        <Grid style={{ paddingBottom: '10px' }}>
                            <MROTextField
                                name={"task"}
                                label={"Task # *"}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ style: { height: 'max-content', width: "100%" } }}
                                inputProps={{ maxLength: 30 }}
                                placeholder={"Task / TLP #"}
                                className={classes.inputField}
                                error={errors.task}
                                onChange={(e) => { setErrors({ ...errors, task: false }); }}
                                defaultValue={currentSelection?.task}
                            />



                        </Grid>

                        <Grid i>
                            <NumericIncrement
                                name="mro_hours"
                                label="Act Man-Hours *"
                                error={errors.mro_hours}
                                value={mroState.man_hours}
                                placeholder={""}
                                // onChange={handleChange}
                                noIncreament
                                state={mroState}
                                setState={setMROState}
                                inputProps={{ min: 0, step: 0.5 }}
                                helperText={
                                    (errors["mro_hours"] && mroState.man_hours <= 0) ? <span style={{ color: "red" }}>QTY Required Must Be Greater Than 0</span> : (errors["mro_hours"] && mroState.man_hours === "") ? <span style={{ color: "red" }}>*Please Enter Estimated Man Hrs</span> : ''
                                }
                                InputLabelProps={{ shrink: true }}
                                setError={() =>
                                    setErrors((prevState) => ({
                                        ...prevState,
                                        mro_hours: false,
                                    }))
                                }

                            />
                        </Grid>






                        <div className={classes.actionButtons}>
                            <MROButton
                                //   disabled={loading}
                                type="button"
                                variant={"contained"}
                                onClick={handleClose}
                            >
                                Cancel
                            </MROButton>
                            <MROButton
                                loading={loading}
                                type="submit"
                                variant={"contained"}
                                color="primary"

                            >
                                Complete
                            </MROButton>

                        </div>
                    </form>
                </DialogContent>

            </Dialog>


        </div>
    )
}
