import requestrApi from "../../common/boxr_api_client";

function getWorkOrderTemplates(params) {
    return requestrApi({
        url: '/line_reports/list_templates',
        method: 'GET',
        params: params
    });
}
function createNewTemplate(formData) {
    return requestrApi({
        url: '/line_reports/create_template',
        method: 'POST',
        data: formData
    })
}

function getSectionsOfTemplate(params) {
    return requestrApi({
        url: '/line_reports/show_sections',
        method: 'GET',
        params: params
    });
}
function getSectionsDetailsOfTemplate(params) {
    return requestrApi({
        url: '/line_reports/show_fields',
        method: 'GET',
        params: params
    });
}

function updateSectionStatus(formData) {
    return requestrApi({
        url: '/line_reports/edit_sections',
        method: 'PUT',
        data: formData
    })
}

function currencyDependency() {
    return requestrApi({
        url: '/currencies/dependency_listing',
        method: 'GET'
    });
}

function updateSectionStatusAll(formData) {
    return requestrApi({
        url: '/line_reports/select_all',
        method: 'PUT',
        data: formData
    })
}

function updateFieldStatus(formData) {
    return requestrApi({
        url: '/line_reports/edit_fields',
        method: 'PUT',
        data: formData
    })
}

function updateTemplateName(formData) {
    return requestrApi({
        url: '/line_reports/edit_template',
        method: 'PUT',
        data: formData
    })
}

const WorkOrderTemplateService = {
    getWorkOrderTemplates,
    createNewTemplate,
    getSectionsOfTemplate,
    getSectionsDetailsOfTemplate,
    updateSectionStatus,
    updateFieldStatus,
    updateTemplateName,
    updateSectionStatusAll,
    currencyDependency
};

export default WorkOrderTemplateService;