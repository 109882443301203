import React, { useReducer, useState, useEffect, useContext } from 'react'
import tableReducer, { INITIAL_TABLE_STATE } from "reducers/table_reducer";
import ViewIcon from "@material-ui/icons/Visibility";
import MaterialTable from "material-table";
import { ICON_COLOUR } from "lib/constants/style_constants";
import {
    Box,
    IconButton,
    Typography,
    Button,
    Grid,
    makeStyles
} from "@material-ui/core";
import tableIcons, { options } from "components/universal/table_attributes";
import { format24HrDateOnly, getOrganisationId } from "lib/utils/common_utils";
import { WorkshopDashboardServices } from 'lib/services/api/dashboard/workshop';
import { ToastMessageContext } from "lib/contexts/message_context";
import BoxpTableAction from "actions/boxp_table_actions";
import TabDetails from 'views/Operations/Workshop/TabDetails';
import moment from "moment";
import LinearProgressBar from 'components/linear_progress_bar';
import whiteSettingIcon from "../../../../assets/icons/white-settings.png";
import MROButton from 'components/buttons';
import MessagePopup from "components/universal/messagePopup";
import RedHandIcon from "assets/red_hand.png";
import useDebounce from 'lib/utils/debounce';
import InfoIcon from '@material-ui/icons/Info'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import greenCheckIcon from "assets/green check.png";
import StatusPopup from 'views/Operations/Workshop/Maintenance/StatusPopup';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import ApprovalVar from '../Maintenance/ApprovalVar';
import EstimationDeparture from '../Maintenance/EstimationDeparture';

const useStyles = makeStyles((theme) => ({

    runButton: {
        backgroundColor: "#4FC605", color: "white",
        '&:hover': {
            backgroundColor: "#4FC605",
            opacity: 0.75,
            color: "white"
        }
    }

}));

function CommericalTop(props) {
    const classes = useStyles()
    const { filterData, startDate, endDate, selectedBase, selectedFilters, setRefresh } = props
    const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
    const [loading, setLoading] = useState(false);
    const message = useContext(ToastMessageContext);
    const [totalCount, setTotalCount] = useState(5);
    const [pageSize, setPageSize] = useState(5);
    const [page, setPage] = useState(0);
    const [commercialOverviewData, setCommercialOverviewData] = useState([])
    const [action, setAction] = useState(false);
    const [currentSelection, setCurrentSelection] = useState({})
    const { data, inProgress } = tableState;
    const [runTable, setRunTable] = useState(false)
    const [searchText, setSearchText] = useState("")
    const debounceVal = useDebounce(searchText)

    const getCommercialOverview = () => {
        const params = {
            order_type: "Production",
            page: page + 1,
            organisation_id: getOrganisationId(),

            limit: pageSize,
            search: debounceVal
        }
        selectedBase.forEach((item, index) => params[`base_filter[${[index]}]`] = item)
        selectedFilters.forEach((item, index) => params[`customer[${[index]}]`] = item)
        setLoading(true)
        WorkshopDashboardServices.getCommercialOverView(params).then((response) => {
            if (response.success) {
                setCommercialOverviewData(response.data)

            }
            setTotalCount(response.total_count);
        }).catch((err) => {
            message.showToastMessage({
                message: "Something went wrong",
                variant: "error",
            });
        }).finally(() => {
            setLoading(false)
        })
    }




    useEffect(() => {

        getCommercialOverview()

    }, [page, pageSize, selectedBase, totalCount, debounceVal, selectedFilters])
    const renderIcons = (order_state, rowData) => {
        if (order_state >= 0 && order_state <= 9) {
            return (
                <Box display="flex" justifyContent="center">
                    <IconButton onClick={() => {
                        setAction("statusPopup");
                        setCurrentSelection(rowData)
                    }}>
                        <InfoIcon style={{ color: "#FFB300" }} />
                    </IconButton>
                </Box>)
        } else if (order_state === 10) {
            return (
                <Box display="flex" justifyContent="center">
                    <IconButton>
                        <img
                            src={greenCheckIcon}
                            alt="Icon"
                            style={{ height: "21px" }}
                        />
                    </IconButton>
                </Box>)
        } else if (order_state === 11 || order_state === 12) {
            return (
                <Box display="flex" justifyContent="center">
                    <IconButton>
                        <CheckCircleIcon style={{ color: "red" }} />
                    </IconButton>
                </Box>)
        }
    }

    const handleCommercialtableUpdate = async () => {
        const params = {
            organisation_id: getOrganisationId(),
            order_type: "Production",

        }
        await WorkshopDashboardServices.getUpdateCommercialOverView(params).then((res) => {
            setRunTable(true)
            getCommercialOverview()
            setRefresh(true)
        }).catch(() => {

        }).finally(() => {
            if (!loading) {
                setTimeout(() => {
                    setRunTable(false)
                }, 2000)

            }
        })

    }




    const handleActions = (rowData) => {
        setAction("viewCommerial");
        setCurrentSelection(rowData);
        getMaintenanceOrderList()
    }

    const getMaintenanceOrderList = () => {
        const params = {
            page: page + 1,
            limit: pageSize,
            order_type: "Maintenance",
            organisation_id: getOrganisationId(),

        };
        // selectedBase.forEach((item, index) => params[`base_filter[${[index]}]`] = item)
        BoxpTableAction.getList(dispatch, message, params, "OrderList");
    };


    const renderZeroState = (value) => {
        return (

            <div>
                <img src={RedHandIcon} height={'16px'} />
            </div>
        )


    }






    const columns = [
        {
            title: "Order #",
            field: "order_number",
            width: '8%',
            headerStyle: {
                textAlign: 'left',
                paddingLeft: 24
            },
            cellStyle: {
                textAlign: 'left',
                paddingLeft: 24,
                width: '8%',
            },
            render: (rowData) => <a style={{ textDecoration: "underline" }} onClick={() => {
                handleActions(rowData)

            }}>
                {rowData?.order_number}
            </a>

        },
        {
            title: "Customer",
            field: "customer",
            width: '12%',
            headerStyle: {
                textAlign: 'left',

            },
            cellStyle: {
                width: '12%',
                textAlign: 'left'
            },
        },
        {
            title: "PO#",
            field: "cus-ref",
            headerStyle: {
                textAlign: 'left',
            },
            cellStyle: {
                textAlign: 'left',
            },
        },
        {
            title: "Description",
            field: "description",
            width: '15%',
            headerStyle: {
                width: '15%',
                textAlign: 'left',
            },
            cellStyle: {
                width: '15%',
                textAlign: 'left',
            },

        },
        {
            title: "Work/Status",
            field: "work_status",
            headerStyle: {
                textAlign: 'left',
            }, cellStyle: {

                textAlign: 'left',
            },
        }, {
            title: "Status",
            field: "",
            headerStyle: {
                textAlign: "center",
            },
            cellStyle: {
                textAlign: "center",
            },
            render: (rowData) => renderIcons(rowData?.order_state_id, rowData),
        },
        {
            title: "ETD",
            field: "etd",
            render: (rowData) => rowData?.etd ? <a style={{ textDecoration: "underline" }} onClick={() => {
                setAction("etd")
                setCurrentSelection(rowData)
            }}>
                {format24HrDateOnly(rowData?.etd)}

            </a> : "",
        },
        {
            title: "TAT",
            field: "tat",
            headerStyle: {
                textAlign: 'left',
            },
            render: (rowData) =>
                <div >
                    <LinearProgressBar
                        variant='determinate'
                        value={parseFloat(rowData.tat).toFixed(2)}
                        width='70px'
                        barHeight='20px'
                        bgColor={Number(rowData.tat) === 0 ? "#FFFFFF" : '#4FC605'}
                        color={"#4D4F5C"}
                    />
                </div>
        },
        {
            title: "Action Progress",
            field: "stages",
            headerStyle: {
                textAlign: 'left',
            },
            render: (rowData) =>
                <div >
                    <LinearProgressBar
                        variant='determinate'
                        value={Number(rowData.actions)}
                        width='70px'
                        barHeight='20px'
                        bgColor={Number(rowData.actions) === 0 ? "#FFFFFF" : '#4FC605'}
                        color={"#4D4F5C"}
                    />
                </div>

        },
        {
            title: "Stage Progress",
            field: "stages",
            headerStyle: {
                textAlign: 'left',
            },
            render: (rowData) =>
                <div >
                    <LinearProgressBar
                        variant='determinate'
                        value={Number(rowData.stages)}
                        width='70px'
                        barHeight='20px'
                        bgColor={Number(rowData.stages) === 0 ? "#FFFFFF" : '#4FC605'}
                        color={"#4D4F5C"}
                    />
                </div>
        }, {
            title: "Labour",
            field: "labour",


            render: (rowData) =>
                <div >
                    <LinearProgressBar
                        variant='determinate'
                        value={Number(rowData.labour)}
                        width='70px'
                        barHeight='20px'
                        bgColor={(Number(rowData.labour) > 0 && Number(rowData.labour) < 75) ? '#4FC605' : (Number(rowData.labour) >= 75 && Number(rowData.labour) < 100) ? '#FFB300' : Number(rowData.labour) >= 100 ? '#FF0202' : '#fff'}
                        color={Number(rowData.labour) >= 100 ? "#FFFFFF" : "#4D4F5C"}
                    />
                </div>
        },
        {
            title: "Approval Var",
            field: "variance",
            render: (rowData) =>
                <Grid container>
                    <Grid item xs={9}>
                        <a style={{ textDecoration: "underline", color: rowData?.variance > 100 ? "#FF0202" : "#4FC605" }} onClick={() => {
                            setAction("approval")
                            setCurrentSelection(rowData)
                        }}>

                            {rowData?.variance ? rowData?.variance : ""}

                        </a>
                    </Grid>
                    <Grid>
                        {(rowData?.labour > 100 || rowData?.parts === null || rowData?.parts > 100 || rowData?.services === null || rowData?.services > 100 || rowData?.equipment === null || rowData?.equipment > 100 || rowData?.overall === null || rowData?.overall > 100) ? <PriorityHighIcon htmlColor='#FF0202' style={{ position: "absolute", marginTop: "-1px" }} /> : ""}
                    </Grid>
                </Grid>

        },



    ];



    const handleLocalTime = (date) => {


        let localTime = moment.utc(date, "DD/MM/YYYY HH:mm").local().format("DD/MM/YYYY HH:mm");

        return localTime ? localTime : " - "

    }

    const tableOptions = {
        ...options,
        page: page,
        total: totalCount,
        pageSize: pageSize,
        search: true,
        searchText: debounceVal,

    };
    return (
        <Box p={1}>

            <MaterialTable
                style={{ boxShadow: "0px 1px 3px #00000033", paddingRight: "0px" }}
                icons={tableIcons}
                title={
                    <div style={{ width: '290px' }}>
                        <Typography color="primary" variant="subtitle2" style={{ textAlign: "left" }}>
                            In Progress Overview
                        </Typography>
                        <i >Last updated: {handleLocalTime(commercialOverviewData.last_updated_at) ?? '-'}  </i>
                    </div>
                }
                isLoading={loading}
                data={commercialOverviewData.listing || []}
                columns={columns}
                options={tableOptions}
                onChangePage={(page) => {
                    setPage(page);
                }}
                onChangeRowsPerPage={(pageSize) => {
                    setPageSize(pageSize);
                }}
                onSearchChange={(search) => {
                    setLoading(true)
                    setPage(0);
                    setSearchText(search);

                }}
                totalCount={totalCount}
                page={page}
                actions={[
                    {
                        icon: 'Add',
                        tooltip: 'Add User',
                        isFreeAction: true,
                    }
                ]}
                components={{
                    Actions: props => (
                        <Grid container>
                            <Grid style={{ width: '10px' }}>{" "}</Grid>
                            <MROButton startIcon={<img
                                width="25px"
                                height="25px"
                                src={whiteSettingIcon}
                                alt="icon"
                            // style={{ paddingLeft: '20px' }}
                            />}
                                variant="contained"
                                className={classes.runButton}
                                onClick={() => {
                                    setRunTable(true)
                                    setPage(0)
                                    handleCommercialtableUpdate()
                                }}
                            >
                                Refresh Data
                            </MROButton>
                        </Grid>
                    ),
                }}
            />
            {
                action && action !== "statusPopup" && action !== "approval" && action !== "etd" && (
                    <TabDetails
                        permission={props.permission}
                        handleClose={() => setAction(null)}
                        action={"Unlock"}
                        currentSelection={currentSelection}
                        // bases={props.bases}
                        // lineCustomers={props.customerDetails?.customers}
                        tabId={21}
                        order_type={2}
                        setAction={setAction}
                        getMaintenanceOrderList={getCommercialOverview}
                        type={"dashboardCommercial"}
                    // paginationReset={paginationReset}
                    />
                )
            }
            {runTable &&
                <MessagePopup
                    handleClose={() => setRunTable(false)}
                    open={runTable}
                    topTitle={"Updating Commercial Overview table"}
                    bottomTitle={"Please be patient, this will take a few moments"}
                />}
            {action !== "Send Quote " && action !== "Unlock" && action === "statusPopup" &&
                <StatusPopup open={true} handleClose={() => setAction(null)} workshop_order_id={currentSelection?.id} />
            }
            {action !== "Send Quote " && action !== "Unlock" && action !== "statusPopup" && action === "approval" && <ApprovalVar handleClose={() => setAction(null)} currentSelection={currentSelection} orderType={"Production"} />}
            {action !== "Send Quote " && action !== "Unlock" && action !== "statusPopup" && action === "etd" && <EstimationDeparture handleClose={() => setAction(null)} currentSelection={currentSelection} />}
        </Box>
    )
}

export default React.memo(CommericalTop)