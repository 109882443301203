// import request from "../common/api_client";
import requestrApi from "../common/boxr_api_client";
const BASE_PATH= '/part_requests';
const constType = {
    'stock': 'fetch_secondary_parts',
    'alternateList': 'list_alternate_parts'
}


function getAllRequestList(params, formData) {
    return requestrApi({
        url: `${BASE_PATH}/procurement_requests_listing`,
        method: 'GET',
        params: params,
        data: formData
    });
}

function getPartInfo(params) {
    return requestrApi({
        url: `primary_parts/primary_part_info`,
        method: 'GET',
        params: params,
    });
}

function fetchDependencies() {
    return requestrApi({
        url: `primary_parts/fetch_dependencies`,
        method: 'GET',
    });
}

function getPartList(params,fetchType) {
    return requestrApi({
        url: `primary_parts/${constType[fetchType]}`,
        method: 'GET',
        params: params,
    });
}

function EditPartRequest(params) {
    return requestrApi({
        url: `${BASE_PATH}/edit_part_request`,
        method: 'PUT',
        params: params,
    })
}


function createPo(formData) {
    return requestrApi({
        url: `/purchase_orders`,
        method: 'POST',
        data:formData
    })
}

function allocatePart(data)  {
    return requestrApi({
        url: "/procurement_requests/procurement_request_allocate",
        method: "PUT",
        data
    })
}

function updateAlternateQty (data) {
    return requestrApi({
        url: `/procurement_requests/allocate_alternate_parts`,
        method: "PUT",
        data
    })
}


const RequestListClientServices = { getAllRequestList, getPartInfo, fetchDependencies, getPartList, EditPartRequest, createPo, allocatePart, updateAlternateQty}

export default RequestListClientServices;