import React, { useState, createContext, useReducer, useContext } from "react";

export const PlannedWorkOrderContext = createContext({});




export default function PlannedWorkOrderProvider({ children }) {
    const [workOrderDetails, setWorkOrderDetails] = useState('')
    const [deleteTaskStatus, setdeleteTaskDetails] = useState(false)
    const [partId, setPartId] = useState([])
    const [toolId, setToolId] = useState([])




    let PlannedValue = {
        workOrderDetails,
        setWorkOrderDetails,
        deleteTaskStatus,
        setdeleteTaskDetails,
        partId, setPartId,
        toolId, setToolId
    }
    return (
        <PlannedWorkOrderContext.Provider value={PlannedValue}>
            {children}
        </PlannedWorkOrderContext.Provider>
    );

}