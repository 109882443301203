import React, { useContext, useEffect, useState } from "react";
import { Typography, Grid, Box, CircularProgress } from "@material-ui/core";
import MROTextField from "components/form_components/TextField";
import MROButton from "components/buttons";
import QrCodeIcon from "assets/icons/qr-code.png";
import PrintLable from "./PrintLable";
import {
  removeSecFromDateTime,
  validateFormData,
} from "lib/utils/helperFunctions";
import { MaintenanceServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation";
import { ToastMessageContext } from "lib/contexts/message_context";

import lock from "../../../../../assets/icons/Lock.svg";
import unlock from "../../../../../assets/icons/Unlock.svg";

import moment from "moment";
import { WorkshopContext } from "../../WorkshopContext/WorkshopContext";

const Styles = {
  svg: {
    filter: "invert(1)",
    height: 18,
    marginBottom: 2,
  },
};

function AwayFromBase(props) {
  const message = useContext(ToastMessageContext);

  const {
    data,
    inductionDetails,
    getInductionList,
    editTab,
    setEditTab,
    access,
    orderStateNum,
    tabStatus,
    closeTabStatus,
    getWorkshopOrderDetails,
    setIsGettingDetails,
    isGettingDetails
  } = props;

  // const [editTab, setEditTab] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [closeTab, setCloseTab] = useState(true);
  const [action, setAction] = useState(null);
  const { editButton, setEditButton} =
  useContext(WorkshopContext);
  const [busy, setBusy] = useState(null);
  const [errors, setErrors] = useState({});

  const [fieldsData, setFieldsData] = useState({
    complete_by_stamp: null,
    date_time_away_base: null,
    comments: null,
  });

  useEffect(() => {
    setFieldsData({
      complete_by_stamp: inductionDetails.complete_by_stamp,
      date_time_away_base: inductionDetails.date_time_away_base,
      comments: inductionDetails.comments,
    });
  }, [inductionDetails]);

  const handleCloseTab = () =>{
    getInductionList()
    getWorkshopOrderDetails();
    setEditTab(!editTab)
    setDisabled(!disabled);
  }

  const handleTab = () => {
    setEditButton(true)
    setEditTab(!editTab);
    setDisabled(!disabled);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);

    const { valid, error } = validateFormData(formData);

    if (!valid) {
      setErrors(error);
      return;
    }

    formData.set(
      "date_time_away_base",
      moment(fieldsData.date_time_away_base, "DD/MM/YYYY HH:mm:ss").format(
        "YYYY-MM-DD HH:mm:ss"
      )
    );

    formData.append("completed_by", inductionDetails?.completed_by);
    if(closeTab)
    {
      formData.append("edit_tab", true);
    }
    setBusy("loading");
    try {
      const response = await MaintenanceServices.updateAwayFromBase(
        formData,
        data.induction_id
      );

      if (response.success) {
        message.showToastMessage({
          message: "Updated Successfully!",
          variant: "success",
        });
        handleCloseTab()
      } else {
        message.showToastMessage({
          message:
            response.error.length > 0
              ? response.error?.join(", ")
              : "Something went wrong!",
          variant: "error",
        });
      }

      setBusy(null);
    } catch (error) {
      setBusy("error");
      message.showToastMessage({
        message: "Something went wrong",
        variant: "error",
      });
    }
  };

  const handleEditAPiCall = async () => {
    setIsGettingDetails(true);
    const param = {
      workshop_order_id:data?.id,
      tab:"induction"
    };
    await MaintenanceServices.editButton(param)
      .then((res) => {
        if (res.success) {
          getWorkshopOrderDetails();
          setEditButton(!editButton);
        }
      })
      .catch((err) => {
        message.showToastMessage({
          message: "Something went wrong. Try again!",
          variant: "error",
        });
        setBusy(null);
      })
      .finally(() => {
        setBusy(null);
        setIsGettingDetails(false);
      });
  };

  useEffect(() => {
    if (editButton) {
      handleEditAPiCall();
    }
  }, [editButton])

  if (isGettingDetails) {
    return (
      <Box
        width="100%"
        minHeight="400px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress color="primary" size={40} />
      </Box>
    );
  }

  return (
    <div>
      <Grid>
        <Typography
          color="primary"
          varient="h3"
          style={{ marginBottom: "10px" }}
        >
          Away From Base Checks
        </Typography>
      </Grid>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <MROTextField
              name={"complete_by_stamp"}
              label={"Completed By"}
              required
              variant="outlined"
              placeholder={""}
              InputLabelProps={{ shrink: true }}
              style={{ pointerEvents: "none", background: "#F5F5F5" }}
              value={fieldsData.complete_by_stamp}
              error={errors.complete_by_stamp}
            ></MROTextField>
          </Grid>
          <Grid item xs={3}>
            <MROTextField
              name={"date_time_away_base"}
              required
              label={"Date / Time"}
              placeholder={""}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              style={{ pointerEvents: "none", background: "#F5F5F5" }}
              value={removeSecFromDateTime(fieldsData?.date_time_away_base)}
              error={errors.date_time_away_base}
            ></MROTextField>
          </Grid>
        </Grid>
        <Grid container>
          <MROTextField
            name={"comments"}
            label={"Comments *"}
            placeholder={""}
            variant="outlined"
            error={errors.comments}
            style={{
              pointerEvents: disabled ? "none" : undefined,
              background: disabled ? "#F5F5F5" : undefined,
            }}
            InputLabelProps={{ shrink: true }}
            inputProps={{ maxLength: 250 }}
            value={fieldsData.comments}
            onChange={(event) => {
              const value = event.target.value;
              setFieldsData((prevState) => ({
                ...prevState,
                comments: value,
              }));
              setErrors((e) => ({ ...e, comments: false }));
            }}
          ></MROTextField>
        </Grid>

        <Grid>
          <Typography
            color="secondary"
            varient="h3"
            style={{ marginBottom: "10px", marginTop: "10px" }}
          >
            QR Label
          </Typography>
          <MROButton
            style={{ background: "#4FC607" }}
            type="button"
            variant={"contained"}
            color="secondary"
            onClick={() => setAction("qr_code")}
          >
            <img
              src={QrCodeIcon}
              alt="qr-code"
              style={{ width: "18px", height: "18px", marginRight: "8px" }}
            />
            Print Label
          </MROButton>
        </Grid>
        <Grid style={{ display: "flex", justifyContent: "flex-end" }}>
          {orderStateNum > 1 && orderStateNum <= 8 && tabStatus && (
            <MROButton
              type="button"
              variant={"contained"}
              style={{
                backgroundColor: "#FFB000",
                color: "white",
                // display: editTab ? "none" : undefined,
              }}
              onClick={() => handleTab()}
              startIcon={<img style={Styles.svg} src={lock} alt="lock" />}
            >
              Edit Tab
            </MROButton>
          )}

          {orderStateNum > 1 && closeTabStatus === 2 && (
            <MROButton
              variant={"contained"}
              style={{
                backgroundColor: "red",
                color: "white",
                // display: !editTab || access.read ? "none" : undefined,
              }}
              type="submit"
              loading={busy === "loading"}
              startIcon={<img style={Styles.svg} src={unlock} alt="unlock" />}
              onClick={()=> setCloseTab(true)}
            >
              Close Tab
            </MROButton>
          )}
        </Grid>
      </form>
      {action === "qr_code" && (
        <PrintLable
          open={action === "qr_code"}
          handleClose={() => setAction(null)}
          id={data.id}
        />
      )}
    </div>
  );
}

export default AwayFromBase;
