import React, { useEffect, useContext, useReducer, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import {
  Typography,
  FormControl,
  InputBase,
  MenuItem,
  Select,
  IconButton,
  Box,
  Grid,
  Button,
} from "@material-ui/core";
import { Visibility as ViewIcon } from "@material-ui/icons";
import tableReducer, {
  INITIAL_TABLE_STATE,
} from "../../../../reducers/table_reducer";
import SearchBarWithFilters from "../../../../components/search_with_filters";
import tableIcons, {
  options,
} from "../../../../components/universal/table_attributes";
import MaterialTable from "material-table";
import TableAction from "../../../../actions/table_actions";
import { ICON_COLOUR } from "../../../../lib/constants/style_constants";
import { Chip } from "@material-ui/core";
import MROButton from "../../../../components/buttons";
import { ToastMessageContext } from "lib/contexts/message_context";
import NewLinkedMaintOrder from "./NewLinkedMaintOrder";
import { StorageServices } from "lib/services/api/operaitons/WorkShop/Storage/StorageService";
import greenCheckIcon from "assets/green check.png";
import AmberIcon from "assets/Amber gear@3x.png";
import RedCheckIcon from "assets/Red filled tick.png";
import useDebounce from "lib/utils/debounce";
import { formatToDateOnly, getOrganisationId } from "lib/utils/common_utils";
import TabDetails from "views/Operations/Workshop/TabDetails";
import StatusPopup from "views/Operations/Workshop/Maintenance/StatusPopup";
import { MaintenanceServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: "#fff",
    // padding: theme.spacing(4, 3),
    border: "1px solid #d5d5d5",
    margin: "1% 0",
  },
  tableWrapper: {
    width: "96%",
    padding: "3%",
  },
  bold: {
    fontWeight: 600,
  },
}));

export default function AddMaint(props) {
  // console.log("Storage000", props)
  const { workshop_id, orderState } = props
  const classes = useStyles();
  const [searchText, setSearchText] = useState("");
  const [action, setAction] = useState("");
  const message = useContext(ToastMessageContext);
  const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
  const { page, totalCount, pageSize, inProgress } = tableState;
  const [currentSelection, setCurrentSelection] = useState({});
  const [addMaintdata, setAddMaintdata] = useState(false);
  const debouncedVal = useDebounce(searchText);
  const [loading, setLoading] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [customerDetails, setCustomerDetails] = useState([]);
  const [bases, setBases] = useState([]);


  //Api Main Listing
  const getLinkedMaintenanceList = () => {
    setLoading(true)
    const params = {
      page: page + 1,
      type: "get_order_listing",
      storage_order_id: workshop_id,
      search: debouncedVal,
      ...filterData,
    }
    StorageServices.getLinkedMaintOrderListing(params).then((response) => {
      setAddMaintdata(response.data)
      // console.log("StorageServices===", response)
      TableAction.setTotalCount(dispatch, response.total_count);
    }).catch((e) => {
      message.showToastMessage({
        message: "Something went wrong. Try again!",
        variant: "error",
      })
    }).finally(() => {
      setLoading(false)
    })
  }
  useEffect(() => {
    getLinkedMaintenanceList()
  }, [page, pageSize, totalCount, debouncedVal, filterData])


  const handleFilterChange = (keyItem, value) => {
    TableAction.setPage(dispatch, 0);
    setFilterData((prevState) => ({ ...prevState, [keyItem]: value }));
  };

  const columnCalAction = [
    {
      title: "MO#",
      field: "order_no",
      headerStyle: {
        textAlign: "left",
        paddingLeft: "22px",
      },
      cellStyle: {
        textAlign: "left",
        paddingLeft: "22px",
      },
    },
    {
      title: "Description",
      field: "description",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        textAlign: "left",
        width: "50%"
      },
    },
    {
      title: "Raised",
      field: "created_at",
      render: rowData => { return rowData.created_at != null ? formatToDateOnly(rowData.created_at) : "-" },
    },
    {
      title: "Status",
      field: "created_at",
      render: (rowData) => {
        if (rowData.order_state_id === 10) {
          return (<Box display="flex" justifyContent="center">
            {" "}
            <img src={greenCheckIcon} alt="Icon" style={{ height: "18px" }} />{" "}
          </Box>)
        }
        else if (rowData.order_state_id === 11 || rowData.order_state_id === 12) {
          return (<Box display="flex" justifyContent="center">
            {" "}
            <img src={RedCheckIcon} alt="Icon" style={{ height: "18px" }} />{" "}
          </Box>)
        }
        else {
          return (<Box display="flex" justifyContent="center">
            {" "}
            <img src={AmberIcon} alt="Icon" style={{ height: "18px" }} />{" "}
          </Box>)
        }
      }
    },
    {
      title: "State",
      field: "order_state",

    },
    {
      title: "W/S Report",
      render: (rowData) => {
        if (rowData.workshop_report !== undefined && rowData.workshop_report !== [] && rowData.workshop_report.length != 0) {
          return (
            <Box>
              <IconButton

              >
                <ViewIcon fontSize="small" style={{ color: ICON_COLOUR }} onClick={() => window.open(rowData.workshop_report.map(document => document.file_path))
                } />
              </IconButton>
            </Box>
          );
        }
        else {
          return "-"
        }
      },
    },
    {
      title: "Action",
      render: (rowData) => {
        return (
          <Box>
            <IconButton
              onClick={() => {
                setCurrentSelection(rowData);
                setAction("Unlock");
              }}
              size="small"
            >
              <ViewIcon fontSize="small" style={{ color: ICON_COLOUR }} />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const tableOptions = {
    ...options,
    page: page,
    total: totalCount,
    pageSize: pageSize,
    search: false,
    toolbar: false,
    headerStyle: {
      paddingTop: "19px",
      paddingBottom: "19px",
      textAlign: "center",
    },
  };

  //Tabdetails Api

  const paginationReset = () => {
    TableAction.setPage(dispatch, 0)
  }
  const getCustomerList = async () => {
    const params = {
      organisation_id: getOrganisationId()
    }
    await MaintenanceServices.getCustomerList(params)
      .then((res) => {
        if (res) {
          setCustomerDetails(res.data);
        }
        // setLoading(false);
      })
      .catch((err) => {
        message.showToastMessage({
          message: "Something went wrong. Try again!",
          variant: "error",
        });
        // setLoading(false);
      });
  };
  const getBaseStation = () => {
    const params = {
      organisation_id: getOrganisationId()
    }
    MaintenanceServices.getBaseStation(params)
      .then((res) => {
        if (res) {
          setBases(res.data);
        }
        // setLoading(false);
      })
      .catch((err) => {
        message.showToastMessage({
          message: "Something went wrong. Try again!",
          variant: "error",
        });
        // setLoading(false);
      });
  };


  return (
    <div>
      <Typography color="primary" style={{ margin: "0px 0px 10px 0px" }}>
        Linked Maintenance Orders
      </Typography>
      <div>
        <Box style={{ display: "flex", justifyContent: "flex-start" }}>
          <Typography color={"secondary"} noWrap className={classes.bold}>
            <MROButton
              disabled={!props?.permission.write}
              variant={"contained"}

              style={{ backgroundColor: !props?.permission.write ? "#F5F5F5" : " ", color: "white", display: orderState === "Closed" && "none" }}
              onClick={() => setAction("newOrder")}
              color={"secondary"}
            >
              + Add Maint Order
            </MROButton>
          </Typography>
        </Box>
      </div>
      <SearchBarWithFilters
        handleSearch={(value) => {
          setSearchText(value);
          TableAction.setPage(dispatch, 0);
        }}
      >
        <div>
          <FormControl className={"filter"}>
            <Select defaultValue={""} displayEmpty input={<InputBase />} onChange={(e) =>
              handleFilterChange("order_state", e.target.value)
            }>
              <MenuItem value={""}>State (All)</MenuItem>
              <MenuItem value={"open"}>Open</MenuItem>
              <MenuItem value={"closed"}>Closed</MenuItem>
            </Select>
          </FormControl>
        </div>
      </SearchBarWithFilters>

      <Grid>
        <MaterialTable
          style={{ boxShadow: "0px 1px 3px #00000033" }}
          icons={tableIcons}
          title={<Typography variant="subtitle2">Requests</Typography>}
          columns={columnCalAction}
          isLoading={loading}
          data={addMaintdata || []}
          options={tableOptions}
          localization={{
            toolbar: {
              searchPlaceholder: "Search anything",
            },
            body: {
              emptyDataSourceMessage: "No Requests Found",
              filterRow: {
                filterTooltip: "Filter",
              },
            },
          }}
          onChangePage={(page) => {
            TableAction.setPage(dispatch, page);
          }}
          onChangeRowsPerPage={(pageSize) => {
            TableAction.setPageSize(dispatch, pageSize);
          }}
          onSearchChange={(search) => {
            TableAction.setSearchText(dispatch, search);
          }}
          totalCount={totalCount}
          page={page}
        />
      </Grid>
      {
        action && action !== "statusPopup" && action !== "newOrder" && (
          <TabDetails
            permission={props?.permission}
            handleClose={() => setAction(null)}
            action={action}
            currentSelection={currentSelection}
            bases={bases}
            lineCustomers={customerDetails?.customers}
            tabId={20}
            order_type={1}
            setAction={setAction}
            data={addMaintdata}
            getMaintenanceOrderList={getLinkedMaintenanceList}
            paginationReset={paginationReset}
          />
        )
      }
      {/* {
        action !== "Send Quote " && action !== "Unlock" && action === "statusPopup" &&
        <StatusPopup open={true} handleClose={() => setAction(null)} workshop_order_id={workshop_id} />
      } */}
      {action === "newOrder" && <NewLinkedMaintOrder action={action} handleClose={() => setAction(null)} data={addMaintdata} workshopId={workshop_id} getMaintenanceOrderList={getLinkedMaintenanceList} />}
    </div>
  );
}
