import React, { useState, useContext, useEffect } from "react";
import MROFullScreenRightDrawer from "components/fullwidth_dialog";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import MROTextField from "components/form_components/TextField";
import MROButton from "components/buttons";
import { ToastMessageContext } from "lib/contexts/message_context";
import { validateFormData } from "lib/utils/helperFunctions";
import DeleteIcon from "@material-ui/icons/Delete";
import { InspectionServices } from "lib/services/api/operaitons/WorkShop/Inspection/InspectionService";
import { Box, MenuItem } from "@material-ui/core";
import { ICON_COLOUR } from "lib/constants/style_constants";
import DeleteStage from "./DeleteStage";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      height: "max-content",
    },
  },
  wrapper: {
    padding: "2%",
    backgroundColor: "#fff",
    marginLeft: '24px'
  },
  appBar: {
    backgroundColor: "#fff",
    color: "#000000CC",
    boxShadow: "0px 3px 6px #0000001A",
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  content: {
    backgroundColor: "#fff",
    borderRadius: "0 0 5px 5px",
    minHeight: "fit-content",
  },
  inputField: {
    maxWidth: "328px",
  },
  actionButtons: {
    textAlign: "right",
    marginTop: "30px",
    "& button": {
      // marginRight: "20px",
    },
  },
}));

function UpdateStage(props) {
  const classes = useStyles();
  const { getAllActionStages, actionProp, closeStageHandler, action_Id, handleClose, selectedAction } = props;
  const message = useContext(ToastMessageContext);
  const [action, setAction] = useState(null)
  const [errors, setErrors] = useState({});
  const [description, setDescription] = useState(null);
  const [title, setTitle] = useState(null);
  const [busy, setBusy] = useState(null);
  const [fieldZero, setFieldZero] = useState({ id: null, name: null });
  const [fieldOne, setFieldOne] = useState({ id: null, name: null });
  const [fieldTwo, setFieldTwo] = useState({ id: null, name: null });
  const [fieldThree, setFieldThree] = useState({ id: null, name: null });
  const [fieldFour, setFieldFour] = useState({ id: null, name: null });
  const [fieldFive, setFieldFive] = useState({ id: null, name: null });
  const [fieldSix, setFieldSix] = useState({ id: null, name: null });
  const [fieldSeven, setFieldSeven] = useState({ id: null, name: null });
  const [fieldEight, setFieldEight] = useState({ id: null, name: null });
  const [fieldNine, setFieldNine] = useState({ id: null, name: null });
  const [typeZero, setTypeZero] = useState(null);
  const [typeOne, setTypeOne] = useState(null);
  const [typeTwo, setTypeTwo] = useState(null);
  const [typeThree, setTypeThree] = useState(null);
  const [typeFour, setTypeFour] = useState(null);
  const [typeFive, setTypeFive] = useState(null);
  const [typeSix, setTypeSix] = useState(null);
  const [typeSeven, setTypeSeven] = useState(null);
  const [typeEight, setTypeEight] = useState(null);
  const [typeNine, setTypeNine] = useState(null);

  useEffect(() => {
    setTitle(selectedAction?.title);
    setDescription(selectedAction?.description);

    if (selectedAction?.sub_fields != undefined) {
      setFieldZero(
        {
          id: selectedAction?.sub_fields[0]?.id,
          name: selectedAction?.sub_fields[0]?.field_name === "null"
            ? ""
            : selectedAction?.sub_fields[0]?.field_name
        }
      );
      setFieldOne(
        {
          id: selectedAction?.sub_fields[1]?.id,
          name: selectedAction?.sub_fields[1]?.field_name === "null"
            ? ""
            : selectedAction?.sub_fields[1]?.field_name
        }
      );
      setFieldTwo(
        {
          id: selectedAction?.sub_fields[2]?.id,
          name: selectedAction?.sub_fields[2]?.field_name === "null"
            ? ""
            : selectedAction?.sub_fields[2]?.field_name
        }
      );
      setFieldThree(
        {
          id: selectedAction?.sub_fields[3]?.id,
          name: selectedAction?.sub_fields[3]?.field_name === "null"
            ? ""
            : selectedAction?.sub_fields[3]?.field_name
        }
      );
      setFieldFour(
        {
          id: selectedAction?.sub_fields[4]?.id,
          name: selectedAction?.sub_fields[4]?.field_name === "null"
            ? ""
            : selectedAction?.sub_fields[4]?.field_name
        }
      );
      setFieldFive(
        {
          id: selectedAction?.sub_fields[5]?.id,
          name: selectedAction?.sub_fields[5]?.field_name === "null"
            ? ""
            : selectedAction?.sub_fields[5]?.field_name
        }
      );
      setFieldSix(
        {
          id: selectedAction?.sub_fields[6]?.id,
          name: selectedAction?.sub_fields[6]?.field_name === "null"
            ? ""
            : selectedAction?.sub_fields[6]?.field_name
        }
      );
      setFieldSeven(
        {
          id: selectedAction?.sub_fields[7]?.id,
          name: selectedAction?.sub_fields[7]?.field_name === "null"
            ? ""
            : selectedAction?.sub_fields[7]?.field_name
        }
      );
      setFieldEight(
        {
          id: selectedAction?.sub_fields[8]?.id,
          name: selectedAction?.sub_fields[8]?.field_name === "null"
            ? ""
            : selectedAction?.sub_fields[8]?.field_name
        }
      );
      setFieldNine(
        {
          id: selectedAction?.sub_fields[9]?.id,
          name: selectedAction?.sub_fields[9]?.field_name === "null"
            ? ""
            : selectedAction?.sub_fields[9]?.field_name
        }
      );

      setTypeZero(
        selectedAction?.sub_fields[0]?.field_type === "null"
          ? ""
          : selectedAction?.sub_fields[0]?.field_type
      );
      setTypeOne(
        selectedAction?.sub_fields[1]?.field_type === "null"
          ? ""
          : selectedAction?.sub_fields[1]?.field_type
      );
      setTypeTwo(
        selectedAction?.sub_fields[2]?.field_type === "null"
          ? ""
          : selectedAction?.sub_fields[2]?.field_type
      );
      setTypeThree(
        selectedAction?.sub_fields[3]?.field_type === "null"
          ? ""
          : selectedAction?.sub_fields[3]?.field_type
      );
      setTypeFour(
        selectedAction?.sub_fields[4]?.field_type === "null"
          ? ""
          : selectedAction?.sub_fields[4]?.field_type
      );
      setTypeFive(
        selectedAction?.sub_fields[5]?.field_type === "null"
          ? ""
          : selectedAction?.sub_fields[5]?.field_type
      );
      setTypeSix(
        selectedAction?.sub_fields[6]?.field_type === "null"
          ? ""
          : selectedAction?.sub_fields[6]?.field_type
      );
      setTypeSeven(
        selectedAction?.sub_fields[7]?.field_type === "null"
          ? ""
          : selectedAction?.sub_fields[7]?.field_type
      );
      setTypeEight(
        selectedAction?.sub_fields[8]?.field_type === "null"
          ? ""
          : selectedAction?.sub_fields[8]?.field_type
      );
      setTypeNine(
        selectedAction?.sub_fields[9]?.field_type === "null"
          ? ""
          : selectedAction?.sub_fields[9]?.field_type
      );
    }
  }, [selectedAction]);

  const validateFields = (formData) => {
    const { error, valid } = validateFormData(formData, [
      "field_name[0]",
      "field_name[1]",
      "field_name[2]",
      "field_name[3]",
      "field_name[4]",
      "field_name[5]",
      "field_name[6]",
      "field_name[7]",
      "field_name[8]",
      "field_name[9]",
      "field_type[0]",
      "field_type[1]",
      "field_type[2]",
      "field_type[3]",
      "field_type[4]",
      "field_type[5]",
      "field_type[6]",
      "field_type[7]",
      "field_type[8]",
      "field_type[9]",
    ]);

    if (!valid) {
      setBusy(null)
      setErrors(error);
      return;
    }
    return valid;
  };

  const handleUpdateData = () => {
    setBusy("updating");
    const param = {
      id: action_Id,
      stage_name: title,
      description: description,
      sub_fields: [
        {
          id: fieldZero.id || null,
          field_name: fieldZero.name || null,
          field_type: typeZero || null
        },
        {
          id: fieldOne.id || null,
          field_name: fieldOne.name || null,
          field_type: typeOne || null
        },
        {
          id: fieldTwo.id || null,
          field_name: fieldTwo.name || null,
          field_type: typeTwo || null
        },
        {
          id: fieldThree.id || null,
          field_name: fieldThree.name || null,
          field_type: typeThree || null
        },
        {
          id: fieldFour.id || null,
          field_name: fieldFour.name || null,
          field_type: typeFour || null
        },
        {
          id: fieldFive.id || null,
          field_name: fieldFive.name || null,
          field_type: typeFive || null
        },
        {
          id: fieldSix.id || null,
          field_name: fieldSix.name || null,
          field_type: typeSix || null
        },
        {
          id: fieldSeven.id || null,
          field_name: fieldSeven.name || null,
          field_type: typeSeven || null
        },
        {
          id: fieldEight.id || null,
          field_name: fieldEight.name || null,
          field_type: typeEight || null
        },
        {
          id: fieldNine.id || null,
          field_name: fieldNine.name || null,
          field_type: typeNine || null
        },
      ]
    }

    InspectionServices.editStage(param).then((res) => {
      if (res?.success) {
        setBusy(null);
        closeStageHandler(res.success);
        getAllActionStages();
        message.showToastMessage({
          message: "Stage Edited successfully",
          variant: "success",
        });
      } else {
        setBusy(null);
        res.errors &&
          message.showToastMessage({
            message: res.errors.join(","),
            variant: "error",
          });
      }
    }).finally(() => {
      setBusy(null);
    })
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    if (!validateFields(formData)) {
      return;
    }

    if (actionProp === "Edit_Stage") {
      handleUpdateData(formData);
    }
  };

  const resetErrorsHandler = (name) => {
    setErrors((e) => ({ ...e, [name]: false }));
  };


  return (
    <div>
      <MROFullScreenRightDrawer open={true}>
        <AppBar position="static" className={classes.appBar} elevation={0}>
          <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
            <Box display="flex" alignItems="center">
              <IconButton
                onClick={handleClose}
                edge="start"
                className={classes.backButton}
                color="inherit"
                aria-label="back"
              >
                <BackIcon />
              </IconButton>
              <Typography variant="subtitle2" style={{ fontSize: "17px" }}>
                Edit Stage
              </Typography>
            </Box>
            <IconButton onClick={() => setAction("delete")}>
              <Typography
                style={{
                  fontSize: "13px",
                  fontWeight: "20px",
                  backgroundColor: "transparent",
                }}
              >
                Delete Item&nbsp;&nbsp;
              </Typography>
              <DeleteIcon fontSize="small" htmlColor={ICON_COLOUR} />
            </IconButton>
          </Toolbar>
        </AppBar>

        <div className={classes.wrapper}>
          <div className={classes.content}>
            <form noValidate onSubmit={handleSubmit}>
              <Typography
                variant="body1"
                color="primary"
                className={classes.title}
              ></Typography>{" "}
              <Grid item container justify="space-between" alignItems="center">
                <Typography
                  color="primary"
                  style={{ fontWeight: "bold", fontSize: "20px" }}
                >
                  Stage Details
                </Typography>
              </Grid>
              <Grid container alignItems={"center"} justify={"flex-start"}>
                <Grid container alignItems={"center"} justify={"flex-start"}>
                  <Grid item xs={12}>
                    <MROTextField
                      label={"Title"}
                      placeholder="Title - Max 75 Characters"
                      name={"stage_name"}
                      value={title}
                      margin="normal"
                      variant="outlined"
                      inputProps={{ maxLength: 75 }}
                      id="test"
                      InputLabelProps={{ shrink: true }}
                      required
                      error={errors.stage_name}
                      // className={classes.inputField}
                      onChange={(e) => {
                        resetErrorsHandler(e.target.name);
                        setTitle(e.target.value);
                      }}
                      color="primary"
                    />
                  </Grid>
                </Grid>

                <Grid container className={classes.root}>
                  <Grid item xs={12}>
                    <MROTextField
                      name={"description"}
                      label={"Detailed Description *"}
                      placeholder="Description - Max 2000 Characters"
                      inputProps={{ maxLength: 6000 }}
                      InputProps={{ style: { width: "100%" } }}
                      value={description}
                      InputLabelProps={{ shrink: true }}
                      multiline
                      error={errors.description}
                      onChange={(e) => {
                        setDescription(e.target.value);
                        resetErrorsHandler(e.target.name);
                      }}
                    />
                  </Grid>
                </Grid>

                <>
                  <Grid item container style={{ marginTop: "25px" }}>
                    <Typography
                      variant="body1"
                      color="primary"
                      style={{ fontWeight: "bold" }}
                    >
                      Stage Details
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      Upto 10 Sub Fields can be used to capture text E.g. torque
                      values, temp readings etc.
                    </Typography>
                  </Grid>
                  <Grid container xs={12}>
                    <Grid spacing={4} container item xs={11}>
                      <Grid item xs={6} container spacing={1}>
                        <Grid item xs={9}>
                          <MROTextField
                            name={"field_name[0]"}
                            label={"Sub-Field 1"}
                            value={fieldZero.name}
                            placeholder="Description - Max 50 Characters"
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 50 }}
                            className={classes.inputField}
                            onChange={(e) => {
                              setFieldZero({ id: selectedAction?.sub_fields[0]?.id, name: e.target.value });
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <MROTextField
                            name={"field_type[0]"}
                            label={"Type *"}
                            InputLabelProps={{ shrink: true }}
                            select
                            value={typeZero}
                            error={errors["field_type[0]"]}
                            onChange={(e) => {
                              setTypeZero(e.target.value);
                              resetErrorsHandler("field_type[0]");
                            }}
                          >
                            <MenuItem value={"FT"}>Free Text</MenuItem>
                            <MenuItem value={"NU"}>Numeric</MenuItem>
                            <MenuItem value={'YN'}>Yes | No</MenuItem>
                            <MenuItem value={'DT'}>Date & Time</MenuItem>
                            <MenuItem value={"TI"}>Time</MenuItem>
                          </MROTextField>
                        </Grid>
                      </Grid>
                      <Grid item xs={6} container spacing={1}>
                        <Grid item xs={9}>
                          <MROTextField
                            name={"field_name[1]"}
                            label={"Sub-Field 2"}
                            value={fieldOne.name}
                            placeholder="Description - Max 50 Characters"
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 50 }}
                            className={classes.inputField}
                            onChange={(e) => {
                              setFieldOne({ id: selectedAction?.sub_fields[1]?.id, name: e.target.value });
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <MROTextField
                            name={"field_type[1]"}
                            label={"Type *"}
                            InputLabelProps={{ shrink: true }}
                            select
                            value={typeOne}
                            error={errors["field_type[1]"]}
                            onChange={(e) => {
                              setTypeOne(e.target.value);
                              resetErrorsHandler(e.target.name);
                            }}
                          >
                            <MenuItem value={"FT"}>Free Text</MenuItem>
                            <MenuItem value={"NU"}>Numeric</MenuItem>
                            <MenuItem value={'YN'}>Yes | No</MenuItem>
                            <MenuItem value={'DT'}>Date & Time</MenuItem>
                            <MenuItem value={"TI"}>Time</MenuItem>
                          </MROTextField>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid spacing={4} container item xs={11}>
                      <Grid item xs={6} container spacing={1}>
                        <Grid item xs={9}>
                          <MROTextField
                            name={"field_name[2]"}
                            label={"Sub-Field 3"}
                            value={fieldTwo.name}
                            placeholder="Description - Max 50 Characters"
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 50 }}
                            className={classes.inputField}
                            onChange={(e) => {
                              setFieldTwo({ id: selectedAction?.sub_fields[2]?.id, name: e.target.value });
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <MROTextField
                            name={"field_type[2]"}
                            label={"Type *"}
                            InputLabelProps={{ shrink: true }}
                            select
                            error={errors["field_type[2]"]}
                            value={typeTwo}
                            onChange={(e) => {
                              setTypeTwo(e.target.value);
                              resetErrorsHandler(e.target.name);
                            }}
                          >
                            <MenuItem value={"FT"}>Free Text</MenuItem>
                            <MenuItem value={"NU"}>Numeric</MenuItem>
                            <MenuItem value={'YN'}>Yes | No</MenuItem>
                            <MenuItem value={'DT'}>Date & Time</MenuItem>
                            <MenuItem value={"TI"}>Time</MenuItem>
                          </MROTextField>
                        </Grid>
                      </Grid>
                      <Grid item xs={6} container spacing={1}>
                        <Grid item xs={9}>
                          <MROTextField
                            name={"field_name[3]"}
                            label={"Sub-Field 4"}
                            value={fieldThree.name}
                            placeholder="Description - Max 50 Characters"
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 50 }}
                            className={classes.inputField}
                            onChange={(e) => {
                              setFieldThree({ id: selectedAction?.sub_fields[3]?.id, name: e.target.value });
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <MROTextField
                            name={"field_type[3]"}
                            label={"Type *"}
                            InputLabelProps={{ shrink: true }}
                            select
                            value={typeThree}
                            error={errors["field_type[3]"]}
                            onChange={(e) => {
                              setTypeThree(e.target.value);
                              resetErrorsHandler(e.target.name);
                            }}
                          >
                            <MenuItem value={"FT"}>Free Text</MenuItem>
                            <MenuItem value={"NU"}>Numeric</MenuItem>
                            <MenuItem value={'YN'}>Yes | No</MenuItem>
                            <MenuItem value={'DT'}>Date & Time</MenuItem>
                            <MenuItem value={"TI"}>Time</MenuItem>
                          </MROTextField>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* 56 */}
                    <Grid spacing={4} container item xs={11}>
                      <Grid item xs={6} container spacing={1}>
                        <Grid item xs={9}>
                          <MROTextField
                            name={"field_name[4]"}
                            label={"Sub-Field 5"}
                            value={fieldFour.name}
                            placeholder="Description - Max 50 Characters"
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 50 }}
                            className={classes.inputField}
                            onChange={(e) => {
                              setFieldFour({ id: selectedAction?.sub_fields[4]?.id, name: e.target.value });
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <MROTextField
                            name={"field_type[4]"}
                            label={"Type *"}
                            InputLabelProps={{ shrink: true }}
                            select
                            value={typeFour}
                            error={errors["field_type[3]"]}
                            onChange={(e) => {
                              setTypeFour(e.target.value);
                              resetErrorsHandler(e.target.name);
                            }}
                          >
                            <MenuItem value={"FT"}>Free Text</MenuItem>
                            <MenuItem value={"NU"}>Numeric</MenuItem>
                            <MenuItem value={'YN'}>Yes | No</MenuItem>
                            <MenuItem value={'DT'}>Date & Time</MenuItem>
                            <MenuItem value={"TI"}>Time</MenuItem>
                          </MROTextField>
                        </Grid>
                      </Grid>

                      <Grid item xs={6} container spacing={1}>


                        <Grid item xs={9}>
                          <MROTextField
                            name={"field_name[5]"}
                            label={"Sub-Field 6"}
                            placeholder="Description - Max 50 Characters"
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 50 }}
                            value={fieldFive.name}
                            className={classes.inputField}
                            onChange={(e) => {
                              setFieldFive({ id: selectedAction?.sub_fields[5]?.id, name: e.target.value });
                            }}
                          ></MROTextField>
                        </Grid>
                        <Grid item xs={3}>
                          <MROTextField
                            name={"field_type[5]"}
                            label={"Type *"}
                            InputLabelProps={{ shrink: true }}
                            select
                            value={typeFive}
                            error={errors["field_type[5]"]}
                            onChange={(e) => {
                              setTypeFive(e.target.value);
                              resetErrorsHandler(e.target.name);
                            }}
                          >
                            <MenuItem value={"FT"}>Free Text</MenuItem>
                            <MenuItem value={"NU"}>Numeric</MenuItem>
                            <MenuItem value={'YN'}>Yes | No</MenuItem>
                            <MenuItem value={'DT'}>Date & Time</MenuItem>
                            <MenuItem value={"TI"}>Time</MenuItem>
                          </MROTextField>
                        </Grid>
                      </Grid>
                    </Grid>


                    <Grid spacing={4} container item xs={11}>
                      <Grid item xs={6} container spacing={1}>
                        <Grid item xs={9}>
                          <MROTextField
                            name={"field_name[6]"}
                            label={"Sub-Field 7"}
                            value={fieldSix.name}
                            placeholder="Description - Max 50 Characters"
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 50 }}
                            className={classes.inputField}
                            onChange={(e) => {
                              setFieldSix({ id: selectedAction?.sub_fields[6]?.id, name: e.target.value });
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <MROTextField
                            name={"field_type[6]"}
                            label={"Type *"}
                            value={typeSix}
                            InputLabelProps={{ shrink: true }}
                            select
                            error={errors["field_type[6]"]}
                            onChange={(e) => {
                              setTypeSix(e.target.value);
                              resetErrorsHandler(e.target.name);
                            }}
                          >
                            <MenuItem value={"FT"}>Free Text</MenuItem>
                            <MenuItem value={"NU"}>Numeric</MenuItem>
                            <MenuItem value={'YN'}>Yes | No</MenuItem>
                            <MenuItem value={'DT'}>Date & Time</MenuItem>
                            <MenuItem value={"TI"}>Time</MenuItem>
                          </MROTextField>
                        </Grid>
                      </Grid>

                      <Grid item xs={6} container spacing={1}>


                        <Grid item xs={9}>
                          <MROTextField
                            name={"field_name[7]"}
                            label={"Sub-Field 8"}
                            value={fieldSeven.name}
                            placeholder="Description - Max 50 Characters"
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 50 }}
                            className={classes.inputField}
                            onChange={(e) => {
                              setFieldSeven({ id: selectedAction?.sub_fields[7]?.id, name: e.target.value });
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <MROTextField
                            name={"field_type[7]"}
                            label={"Type *"}
                            InputLabelProps={{ shrink: true }}
                            select
                            error={errors["field_type[7]"]}
                            value={typeSeven}
                            onChange={(e) => {
                              setTypeSeven(e.target.value);
                              resetErrorsHandler(e.target.name);
                            }}
                          >
                            <MenuItem value={"FT"}>Free Text</MenuItem>
                            <MenuItem value={"NU"}>Numeric</MenuItem>
                            <MenuItem value={'YN'}>Yes | No</MenuItem>
                            <MenuItem value={'DT'}>Date & Time</MenuItem>
                            <MenuItem value={"TI"}>Time</MenuItem>
                          </MROTextField>
                        </Grid>
                      </Grid>
                    </Grid>



                    <Grid spacing={4} container item xs={11}>
                      <Grid item xs={6} container spacing={1}>
                        <Grid item xs={9}>
                          <MROTextField
                            name={"field_name[8]"}
                            label={"Sub-Field 9"}
                            value={fieldEight.name}
                            placeholder="Description - Max 50 Characters"
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 50 }}
                            className={classes.inputField}
                            onChange={(e) => {
                              setFieldEight({ id: selectedAction?.sub_fields[8]?.id, name: e.target.value });
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <MROTextField
                            name={"field_type[8]"}
                            label={"Type *"}
                            InputLabelProps={{ shrink: true }}
                            select
                            value={typeEight}
                            error={errors["field_type[8]"]}
                            onChange={(e) => {
                              setTypeEight(e.target.value);
                              resetErrorsHandler(e.target.name);
                            }}
                          >
                            <MenuItem value={"FT"}>Free Text</MenuItem>
                            <MenuItem value={"NU"}>Numeric</MenuItem>
                            <MenuItem value={'YN'}>Yes | No</MenuItem>
                            <MenuItem value={'DT'}>Date & Time</MenuItem>
                            <MenuItem value={"TI"}>Time</MenuItem>
                          </MROTextField>
                        </Grid>
                      </Grid>

                      <Grid item xs={6} container spacing={1}>
                        <Grid item xs={9}>
                          <MROTextField
                            name={"field_name[9]"}
                            label={"Sub-Field 10"}
                            value={fieldNine.name}
                            placeholder="Description - Max 50 Characters"
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 50 }}
                            className={classes.inputField}
                            onChange={(e) => {
                              setFieldNine({ id: selectedAction?.sub_fields[9]?.id, name: e.target.value });
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <MROTextField
                            name={"field_type[9]"}
                            label={"Type *"}
                            InputLabelProps={{ shrink: true }}
                            select
                            value={typeNine}
                            error={errors["field_type[9]"]}
                            onChange={(e) => {
                              setTypeNine(e.target.value);
                              resetErrorsHandler(e.target.name);
                            }}
                          >
                            <MenuItem value={"FT"}>Free Text</MenuItem>
                            <MenuItem value={"NU"}>Numeric</MenuItem>
                            <MenuItem value={'YN'}>Yes | No</MenuItem>
                            <MenuItem value={'DT'}>Date & Time</MenuItem>
                            <MenuItem value={"TI"}>Time</MenuItem>
                          </MROTextField>
                        </Grid>
                      </Grid>
                    </Grid>


                  </Grid>
                </>
              </Grid>
              <div className={classes.actionButtons}>
                <MROButton
                  type="button"
                  variant={"contained"}
                  onClick={handleClose}
                  style={{ marginRight: "20px" }}
                >
                  Cancel
                </MROButton>
                <MROButton
                  loading={busy === "updating"}
                  type="submit"
                  color="primary"
                  variant={"contained"}
                  style={{ marginRight: "15px" }}
                >
                  Update
                </MROButton>
              </div>
            </form>
          </div>
        </div>
      </MROFullScreenRightDrawer>
      {action && action === "delete" && (
        <DeleteStage
          action_Id={action_Id}
          getAllActionStages={getAllActionStages}
          open={true}
          handleClose={() => setAction(null)}
          closeStageHandler={closeStageHandler}
        />
      )}
    </div>
  );
}

export default UpdateStage;



