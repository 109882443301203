import React, { useState, useEffect } from "react";
import MROFullScreenRightDrawer from "components/fullwidth_dialog";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import Typography from "@material-ui/core/Typography";
import { ICON_COLOUR } from "lib/constants/style_constants";
import MROButton from "components/buttons";
import MROTextField from "components/form_components/TextField";
import DeleteIcon from "@material-ui/icons/Delete";
import { Box } from "@material-ui/core";
import "./textField.css";
import DeleteDailog from "components/form_components/DeleteDailog";
import DeleteStage from "./DeleteStage";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: "2%",
    backgroundColor: "#fff",
  },
  root: {
    "& .MuiOutlinedInput-root": {
      height: "140px",
    },
  },
  appBar: {
    backgroundColor: "#fff",
    color: "#000000CC",
    boxShadow: "0px 3px 6px #0000001A",
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  description: {
    borderRadius: "5px",
    height: "250px",
    fontSize: "15px",
    backgroundColor: "#F5F5F5",
    padding: theme.spacing(1),
    boxShadow: "0px 1px 4px #0000001A",
  },
  actionButtons: {
    textAlign: "right",
    marginTop: "30px",
    "& button": {
      // marginRight: "20px",
    },
  },
  content: {
    backgroundColor: "#fff",
    // padding: "3% 6%",
    borderRadius: "0 0 5px 5px",
    minHeight: "fit-content",
  },
}));

function EditStage(props) {
  const classes = useStyles();
  const { handleClose, action_Id, getAllActionStages, closeStageHandler } = props;
  const [action, setAction] = useState(null);

  return (
    <div>
      <MROFullScreenRightDrawer open={true}>
        <AppBar position="static" className={classes.appBar} elevation={0}>
          <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
            <Box display="flex" alignItems="center">
              <IconButton
                onClick={handleClose}
                edge="start"
                className={classes.backButton}
                color="inherit"
                aria-label="back"
              >
                <BackIcon />
              </IconButton>
              <Typography variant="subtitle2" style={{ fontSize: "17px" }}>
                Edit Stage
              </Typography>
            </Box>
            <IconButton onClick={() => setAction("delete")}>
              <Typography
                style={{
                  fontSize: "13px",
                  fontWeight: "20px",
                  backgroundColor: "transparent",
                }}
              >
                Delete Item&nbsp;&nbsp;
              </Typography>
              <DeleteIcon fontSize="small" htmlColor={ICON_COLOUR} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div className={classes.wrapper}>
          <div className={classes.content}>
            <form noValidate>
              <Typography
                variant="body1"
                color="primary"
                className={classes.title}
              ></Typography>{" "}
              <Typography
                color="primary"
                variant="body1"
                style={{ fontWeight: "bold", marginBottom: "0px" }}
              >
                Stage Details
              </Typography>
              <Grid
                container
                // spacing={1}
                alignItems={"center"}
                justify={"flex-start"}
              >
                <Grid
                  container
                  // spacing={1}
                  alignItems={"center"}
                  justify={"flex-start"}
                >
                  <Grid item xs={6}>
                    <MROTextField
                      label={"Title"}
                      placeholder="Enter the title"
                      name={"title"}
                      margin="normal"
                      variant="outlined"
                      id="test"
                      InputLabelProps={{ shrink: true }}
                      required
                      // autoFocus={error.partError || true}
                      // error={error.partError}
                      // helperText={error.partError}
                      // onChange={(e) => {
                      //   setError({ ...error, partError: null });
                      //   setselectedPartNumber(e.target.value);
                      // }}
                      className={classes.inputField}
                      color="primary"
                    />
                  </Grid>
                </Grid>

                <Grid container className={classes.root}>
                  <Grid item xs={12}>
                    <MROTextField
                      name={"description"}
                      label={"Detailed Description *"}
                      placeholder="Description - Max 2000 Characters"
                      inputProps={{ maxLength: 2000 }}
                      // value={description}
                      InputProps={{ style: { height: 130, width: "100%" } }}
                      InputLabelProps={{ shrink: true }}
                      multiline
                      // error={errors.description}
                      // onChange={(e) => {
                      //   setDescription(e.target.value);
                      //   resetErrorsHandler(e.target.name);
                      //   handleUpdateStage()
                      // }}
                    />
                  </Grid>
                </Grid>

                <>
                  <Grid item container style={{ marginTop: "25px" }}>
                    <Typography
                      variant="body1"
                      color="primary"
                      style={{ fontWeight: "bold" }}
                    >
                      Stage Details
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      Upto 10 Sub Fields can be used to capture text E.g. torque
                      values, temp readings etc.
                    </Typography>
                  </Grid>

                  <Grid spacing={4} container item xs={8}>
                    <Grid item xs={6}>
                      <MROTextField
                        name={"field_name[0]"}
                        label={"Sub-Field 1"}
                        // value={fieldZero}
                        placeholder="Description - Max 50 Characters"
                        InputLabelProps={{ shrink: true }}
                        // onChange={(e) => setFieldZero(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={6} container justify="flex-end">
                      <MROTextField
                        name={"field_name[5]"}
                        label={"Sub-Field 6"}
                        placeholder="Description - Max 50 Characters"
                        InputLabelProps={{ shrink: true }}
                        // value={fieldFive}
                        // onChange={(e) => setFieldFive(e.target.value)}
                      ></MROTextField>
                    </Grid>
                  </Grid>
                  <Grid spacing={4} container item xs={8}>
                    <Grid item xs={6}>
                      <MROTextField
                        name={"field_name[1]"}
                        label={"Sub-Field 2"}
                        // value={fieldOne}
                        placeholder="Description - Max 50 Characters"
                        InputLabelProps={{ shrink: true }}
                        // onChange={(e) => setFieldOne(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={6} container justify="flex-end">
                      <MROTextField
                        name={"field_name[6]"}
                        label={"Sub-Field 7"}
                        // value={fieldSix}
                        placeholder="Description - Max 50 Characters"
                        InputLabelProps={{ shrink: true }}
                        // onChange={(e) => setFieldSix(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                  <Grid spacing={4} container item xs={8}>
                    <Grid item xs={6}>
                      <MROTextField
                        name={"field_name[2]"}
                        label={"Sub-Field 3"}
                        // value={fieldTwo}
                        placeholder="Description - Max 50 Characters"
                        InputLabelProps={{ shrink: true }}
                        // onChange={(e) => setFieldTwo(e.target.value)}
                      />
                    </Grid>

                    <Grid item xs={6} container justify="flex-end">
                      <MROTextField
                        name={"field_name[7]"}
                        label={"Sub-Field 8"}
                        // value={fieldSeven}
                        placeholder="Description - Max 50 Characters"
                        InputLabelProps={{ shrink: true }}
                        // onChange={(e) => setFieldSeven(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                  <Grid spacing={4} container item xs={8}>
                    <Grid item xs={6}>
                      <MROTextField
                        name={"field_name[3]"}
                        label={"Sub-Field 4"}
                        // value={fieldThree}
                        placeholder="Description - Max 50 Characters"
                        InputLabelProps={{ shrink: true }}
                        // onChange={(e) => setFieldThree(e.target.value)}
                      />
                    </Grid>

                    <Grid item xs={6} container justify="flex-end">
                      <MROTextField
                        name={"field_name[8]"}
                        label={"Sub-Field 9"}
                        // value={fieldEight}
                        placeholder="Description - Max 50 Characters"
                        InputLabelProps={{ shrink: true }}
                        // onChange={(e) => setFieldEight(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid spacing={4} container item xs={8}>
                    <Grid item xs={6}>
                      <MROTextField
                        name={"field_name[4]"}
                        label={"Sub-Field 5"}
                        // value={fieldFour}
                        placeholder="Description - Max 50 Characters"
                        InputLabelProps={{ shrink: true }}
                        // onChange={(e) => setFieldFour(e.target.value)}
                      />
                    </Grid>

                    <Grid item xs={6} container justify="flex-end">
                      <MROTextField
                        name={"field_name[9]"}
                        label={"Sub-Field 10"}
                        // value={fieldNine}
                        placeholder="Description - Max 50 Characters"
                        InputLabelProps={{ shrink: true }}
                        // onChange={(e) => setFieldNine(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </>
              </Grid>
              <div className={classes.actionButtons}>
                <MROButton
                  //disabled={loading && action === "update"}
                  type="button"
                  variant={"contained"}
                  onClick={handleClose}
                  style={{ marginRight: "20px" }}
                >
                  cancel
                </MROButton>
                <MROButton
                  //disabled={loading && action === "update"}
                  type="button"
                  color="primary"
                  variant={"contained"}
                  style={{ marginRight: "15px" }}
                >
                  update
                </MROButton>
              </div>
            </form>
          </div>
        </div>
      </MROFullScreenRightDrawer>
      {action && action === "delete" && (
        <DeleteStage
          action_Id={action_Id}
          getAllActionStages={getAllActionStages}
          open={true}
          handleClose={() => setAction(null)}
          closeStageHandler={closeStageHandler}
        />
      )}
    </div>
  );
}

export default EditStage;
