import {
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    Grid,
} from "@material-ui/core";
import React, { useState } from 'react'
import { makeStyles } from "@material-ui/core/styles";
import './index.css';
import AmberGear from 'assets/Amber gear@3x.png'
import GreenCheck from 'assets/green_filled_tick.png'
import HalllowCircle from 'assets/Hollow circle.png'
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined';

import CommercialIconAmber from "assets/maint/Commercial/CommercialIconAmber.png";
import CommercialIconGray from "assets/maint/Commercial/CommercialIconGray.png";
import CommercialIconGreen from "assets/maint/Commercial/CommercialIconGreen.png";
import CommercialIconRed from "assets/maint/Commercial/CommercialIconRed.png";

import PartsIconGrey from "assets/maint/Parts/partsIconGray.png";
import PartsIconGreen from "assets/maint/Parts/partsIconGreen.png";
import PartsIconRed from "assets/maint/Parts/partsIconRed.png";
import PartsIconAmber from "assets/maint/Parts/partsIconAmber.png";

import PlanningIconAmber from "assets/maint/Planning/planningIconAmber.png";
import PlanningIconRed from "assets/maint/Planning/planningIconRed.png";
import PlanningIconGrey from "assets/maint/Planning/planningIconGrey.png";
import PlanningIconGreen from "assets/maint/Planning/planningIconGreen.png";

import ServicesIconAmber from "assets/maint/Services/servicesIconAmber.png";
import ServicesIconGray from "assets/maint/Services/services_icon_grey.png";
import ServicesIconRed from "assets/maint/Services/servicesIconRed.png";
import ServiceIconGreen from "assets/maint/Services/ServicesIconGreen.png/";

import SpecEquipIconGrey from "assets/maint/SpecEquip/specEquipGray.png";
import SpecEquipIconAmber from "assets/maint/SpecEquip/specEquipIconAmber.png";
import SpecEquipIconRed from "assets/maint/SpecEquip/specEquipIconRed.png";
import SpecEquipIconGreen from "assets/maint/SpecEquip/specEquipIconGreen.png";

import NoStoppage from "assets/Stoppage/No_stoppage.png";
import NotRequired from "assets/Stoppage/Not_required.png";
import NotStarted from "assets/Stoppage/Not_started.png";
import PartiallyStopped from "assets/Stoppage/Partially_stopped.png";
import Stopped from "assets/Stoppage/Stopped.png";

import { MaintenanceServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation";
import { useEffect } from "react";
import LoadingIndicator from "components/universal/loading_indicator";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        backgroundColor: "#6338F4",
        border: "1px solid #6338F4",
        margin: "1% 0",
        display: "flex",
        "& button": {
            marginRight: theme.spacing(2),
            width: "200px",
            "& .MuiButton-label": {
                justifyContent: "space-between",
                width: "150px",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
            },
        },
    },

    borderLine: {
        zIndex: 1,
        height: "6px",
        width: "420px",
        backgroundColor: "#4D4F5C",
        marginBottom: "30px",
    },
    boxMod: {
        border: '2px solid grey',
        borderRadius: '10px',
        height: '36px',
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",
    }
}));

const StatusPopup = (props) => {
    console.log(props)
    const classes = useStyles();
    const [productStoppage, setProductionStoppageData] = useState([])
    const [loading, setLoading] = useState(false)

    const {
        open,
        handleClose,
        workshop_order_id
    } = props

    useEffect(() => {
        productionStoppage()
    }, [])

    const productionStoppage = () => {
        setLoading(true)
        const params = {
            workshop_order_id: workshop_order_id
        }
        MaintenanceServices.productionStoppage(params)
            .then((res) => {
                if (res.success) {
                    setProductionStoppageData(res.data)
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setLoading(false)
            })
    }

    return (
        <Dialog
            aria-labelledby="customized-dialog-title"
            onClose={handleClose}
            open={open}
            maxWidth="md"
        >
            <DialogTitle id="customized-dialog-title">
                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                    <Box display="flex" alignItems="center">
                        <Typography
                            style={{
                                fontSize: "20px",
                                color: "#4D4F5C"
                            }}
                            variant="subtitle2"
                        >
                            Stage
                        </Typography>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent style={{ width: "500px", marginBottom: '10px', height:"auto" }}>
                {!loading ?
                    <>
                        <Grid container style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center" }}>
                            <Grid item style={{ marginBottom: "-20px", zIndex: 3, paddingRight:"10px" }}>
                                <div style={{ margingTop: "-10px", marginLeft: '-10px' }}>Induction</div>
                                <img
                                    src={productStoppage['induction'] === 0 ? HalllowCircle :
                                        productStoppage['order_state'] === 1 ? AmberGear :
                                            productStoppage['induction'] > 2 ? GreenCheck : HalllowCircle
                                    }
                                    alt="Icon"
                                    style={{ background: "#fff" }}
                                    height={"35px"} />
                            </Grid>
                            <Grid item style={{ marginBottom: "-62px", zIndex: 3, position: "relative" }}>
                                <img
                                    src={productStoppage['order_state'] < 2 || productStoppage['planning'] === 0 ? HalllowCircle :
                                        productStoppage['order_state'] === 2 ? AmberGear :
                                            productStoppage['planning'] === 4 ? GreenCheck : HalllowCircle
                                    }
                                    alt="Icon"
                                    style={{ background: "#fff" }}
                                    height={"35px"} />
                                <div style={{ margingTop: "-10px", marginRight: '-0px' }}>Planning</div>
                            </Grid>
                            <Grid item style={{ marginBottom: "-20px", zIndex: 3, paddingRight:"10px" }}>
                                <div style={{ margingTop: "-10px", marginLeft: '-20px' }}>Inspection</div>
                                <img
                                    src={productStoppage['inspection_action_required'] === false ? NotRequired :
                                        productStoppage['order_state'] < 3 || productStoppage['inspection'] === 0 ? HalllowCircle :
                                            productStoppage['order_state'] === 3 ? AmberGear :
                                                productStoppage['inspection'] === 3 ? GreenCheck : HalllowCircle
                                    }
                                    alt="Icon"
                                    style={{ background: "#fff" }}
                                    height={"35px"} />
                            </Grid>
                            <Grid item style={{ marginBottom: "-62px", zIndex: 3 }}>
                                <img
                                    src={productStoppage['order_state'] < 4 || productStoppage['commercial'] === 0 ? HalllowCircle :
                                        productStoppage['order_state'] === 4 ? AmberGear :
                                            productStoppage['order_state'] > 4 ? GreenCheck : HalllowCircle
                                    }
                                    alt="Icon"
                                    style={{ background: "#fff" }}
                                    height={"35px"} />
                                <div style={{ margingTop: "-10px", marginLeft: '-20px' }}>Commercial</div>
                            </Grid>
                            <Grid item style={{ marginBottom: "-20px", zIndex: 3 }}>
                                <div style={{ margingTop: "-10px", marginLeft: '-20px' }}>Maintenance</div>
                                <img
                                    src={productStoppage['order_state'] < 5 || productStoppage['maintenance'] === 0 ? HalllowCircle :
                                        productStoppage['order_state'] === 5 ? AmberGear :
                                            productStoppage['maintenance'] === 3 ? GreenCheck : HalllowCircle
                                    }
                                    alt="Icon"
                                    style={{ background: "#fff" }}
                                    height={"35px"} />
                            </Grid>
                            <Grid item style={{ marginBottom: "-62px", zIndex: 3 }}>
                                <img
                                    src={productStoppage['order_state'] < 8 || productStoppage['certification'] === 0 ? HalllowCircle :
                                        productStoppage['order_state'] === 8 ? AmberGear :
                                            productStoppage['certification'] === 3 ? GreenCheck : HalllowCircle
                                    }
                                    alt="Icon"
                                    style={{ background: "#fff" }}
                                    height={"35px"} />
                                <div style={{ margingTop: "-10px", marginLeft: '-20px' }}>Certification</div>
                            </Grid>
                            <Grid item style={{ marginBottom: "-20px", zIndex: 3 }}>
                                <div style={{ margingTop: "-10px", marginLeft: '-5px' }}>Release</div>
                                <img
                                    src={productStoppage['order_state'] < 9 || productStoppage['release_away_base'] === 0 ||
                                        productStoppage['release_good_out'] === 0 || productStoppage['release_no_good_out'] === 0
                                        || productStoppage['release_scrap'] === 0 ? HalllowCircle :
                                        productStoppage['order_state'] === 9 ? AmberGear :
                                            productStoppage['release_away_base'] === 3 ||
                                                productStoppage['release_good_out'] === 3 || productStoppage['release_no_good_out'] === 3
                                                || productStoppage['release_scrap'] === 3 ? GreenCheck : HalllowCircle
                                    }
                                    alt="Icon"
                                    style={{ background: "#fff" }}
                                    height={"35px"} />
                            </Grid>
                        </Grid>

                        <Grid style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Grid className={classes.borderLine}>

                            </Grid>
                        </Grid>
                    </>
                    : <LoadingIndicator variant={"block"} />}

                <Grid style={{ paddingTop: "50px" }}></Grid>

                <Grid container spacing={0.5} className={classes.boxMod}>
                    <Grid item >
                        <span>
                            <Typography style={{ fontSize: '13px', display: "flex", alignItems: "center" }} > <RadioButtonUncheckedOutlinedIcon height={"20px"} />&nbsp;&nbsp;Not Started</Typography></span>
                    </Grid>
                    <Grid item >
                        <Typography style={{ fontSize: '13px' }}><img src={NotRequired} alt="Icon" height={"20px"} />&nbsp;&nbsp;Not Required</Typography>
                    </Grid>
                    <Grid item >
                        <Typography style={{ fontSize: '13px' }}><img src={AmberGear} alt="Icon" height={"20px"} />&nbsp;&nbsp;In Progress </Typography>
                    </Grid>
                    <Grid item >
                        <Typography style={{ fontSize: '13px' }}> <img src={GreenCheck} alt="Icon" height={"20px"} />&nbsp;&nbsp;Complete</Typography>
                    </Grid>
                </Grid>
                <br />


                <Grid xs={12}> <Typography
                    style={{
                        fontSize: "20px",
                        color: "#4D4F5C",
                    }}
                    variant="subtitle2"
                >
                    Production Stoppage
                </Typography>
                </Grid>

                <br />
                <br />

                {!loading ? <Grid container xs={12} spacing={1} style={{ marginTop: '-29px', display: "flex", justifyContent: "space-around" }} >
                    {/* <Grid item xs={1}></Grid> */}
                    <Grid item xs={2.1}>
                        <img
                            src={productStoppage["parts_status"] === "grey" ? PartsIconGrey
                                : productStoppage["parts_status"] === "amber" ? PartsIconAmber :
                                    productStoppage["parts_status"] === "red" ? PartsIconRed :
                                        productStoppage["parts_status"] === "green" ? PartsIconGreen : ""
                            }
                            style={{ height: "50px" }}
                            alt="Icon" />
                        <Typography
                            style={{ marginLeft: "8px", marginTop: "7px" }}>
                            Parts
                        </Typography>
                    </Grid>
                    <Grid item xs={2.1}>
                        <img
                            src={productStoppage["planning_status"] === "grey" ? PlanningIconGrey
                                : productStoppage["planning_status"] === "amber" ? PlanningIconAmber :
                                    productStoppage["planning_status"] === "red" ? PlanningIconRed :
                                        productStoppage["planning_status"] === "green" ? PlanningIconGreen : ""
                            }
                            alt="Icon"
                            style={{ marginLeft: "4px", height: "50px" }}
                        />
                        <Typography style={{ marginTop: "7px" }}>
                            Planning
                        </Typography>
                    </Grid>
                    <Grid item xs={2.1}><img
                        src={productStoppage["commercial_status"] === "grey" ? CommercialIconGray
                            : productStoppage["commercial_status"] === "amber" ? CommercialIconAmber :
                                productStoppage["commercial_status"] === "red" ? CommercialIconRed :
                                    productStoppage["commercial_status"] === "green" ? CommercialIconGreen : ""
                        }
                        alt="Icon"
                        style={{ marginLeft: "10px", height: "50px" }} />
                        <Typography style={{ marginTop: "7px" }}>
                            Commercial
                        </Typography>
                    </Grid>
                    <Grid item xs={2.1}>
                        <img
                            src={productStoppage["service_status"] === "grey" ? ServicesIconGray
                                : productStoppage["service_status"] === "amber" ? ServicesIconAmber :
                                    productStoppage["service_status"] === "red" ? ServicesIconRed :
                                        productStoppage["service_status"] === "green" ? ServiceIconGreen : ""
                            }
                            alt="Icon"
                            style={{ height: "50px", }}
                        />
                        <Typography style={{ marginTop: "7px", marginRight: "8px" }}>
                            Services
                        </Typography>
                    </Grid>
                    <Grid item xs={2.1}>
                        <img
                            src={productStoppage["equipment_status"] === "grey" ? SpecEquipIconGrey
                                : productStoppage["equipment_status"] === "amber" ? SpecEquipIconAmber :
                                    productStoppage["equipment_status"] === "red" ? SpecEquipIconRed :
                                        productStoppage["equipment_status"] === "green" ? SpecEquipIconGreen : ""
                            }
                            alt="Icon"
                            style={{ height: "50px" }}
                        />
                        <Typography style={{ marginTop: "7px" }}>
                            Spec Equip
                        </Typography>
                    </Grid>
                </Grid> : <LoadingIndicator variant={"block"} />}


                <br />
                <Grid container spacing={0.5} className={classes.boxMod}>
                    <Grid item >
                        <span>
                            <Typography style={{ fontSize: '13px' }} > <img src={NotStarted} alt="Icon" height={"20px"} />&nbsp;&nbsp;Not Started</Typography></span>
                    </Grid>
                    <Grid item >
                        <Typography style={{ fontSize: '13px' }}><img src={NoStoppage} alt="Icon" height={"20px"} />&nbsp;&nbsp;No Stoppage</Typography>
                    </Grid>
                    <Grid item >
                        <Typography style={{ fontSize: '13px' }}><img src={PartiallyStopped} alt="Icon" height={"20px"} />&nbsp;&nbsp;Partially Stopped</Typography>
                    </Grid>
                    <Grid item >
                        <Typography style={{ fontSize: '13px' }}> <img src={Stopped} alt="Icon" height={"20px"} />&nbsp;&nbsp;Stopped</Typography>
                    </Grid>
                </Grid>
            </DialogContent>

        </Dialog>
    )
}
export default StatusPopup;