import React, { useEffect, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import { Box, CircularProgress, Grid, useTheme } from "@material-ui/core";
import MROButton from "components/buttons";
import CautionPopUp from "../CautionPopUp";
import { CertificateServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/Certification";
import lock from "assets/icons/Lock.svg";
import unlock from "assets/icons/Unlock.svg";
import { userDetails } from "lib/utils/helperFunctions";
import { BASE_API_URL_BOXP } from "lib/constants";
import SignatureFile from "components/form_components/fileUpload/SIgnatureFile";
import { format24HrDateTime } from "lib/utils/common_utils";
import FileUpload from "components/form_components/fileUpload";
const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: "3%",
  },
  svg: {
    filter: "invert(1)",
    height: 18,
    marginBottom: 2,
  },
  paper: {
    borderRadius: "5px",
    backgroundColor: "#F5F5F5",
    padding: theme.spacing(2),
    boxShadow: "0px 1px 4px #0000001A",
  },
  partValue: {
    fontSize: "14px",
    color: "#4D4F5C",
    padding: "5px",
  },
  partKey: {
    "& p": {
      fontSize: "14px",
      padding: "1px",
    },
  },
  partCard: {
    borderRadius: "8px",
    padding: "17px 24px",
    "& p": {
      fontSize: "14px",
      padding: "5px",
    },
  },
  sec: {
    width: "20%",
    height: "70px",
    borderRadius: "6px",
    backgroundColor: "#fff",
    padding: theme.spacing(5),
    boxShadow: "0px 1px 4px #0000001A",
  },
}));

function CRSOverView(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [editTab, setEditTab] = useState(false);
  const [action, setAction] = useState(null);
  const [isGettingDetails, setIsGettingDetails] = useState(null);
  const [certificationDetails, setCertificationDetails] = useState({});
  const { workshop_id, orderStateNum, tabStatus } = props;

  const handleTab = () => {
    setEditTab(!editTab);
    setAction("unlock");
    // setDisabled(!disabled)
  };

  useEffect(() => {
    getCertificationDetails();
  }, []);

  const getCertificationDetails = async () => {
    setIsGettingDetails(true);
    await CertificateServices.getCertificationDetails(workshop_id)
      .then((res) => {
        if (res) {
          setCertificationDetails(res?.data);
        }
      })
      .then(() => {
        console.log("error");
      })
      .finally(() => {
        setIsGettingDetails(false);
      });
  };

  if (isGettingDetails) {
    return (
      <Box
        width="100%"
        minHeight="400px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress color="primary" size={40} />
      </Box>
    );
  }
  const GetInfoCard = (props) => {
    const classes = useStyles();
    const theme = useTheme();

    return (
      <Box display="flex">
        <Box flex={5} className={classes.partKey}>
          <Typography
            style={{ color: theme.palette.primary.main }}
            color="secondary"
          >
            {props?.keyName}
          </Typography>
        </Box>
        <Box flex={22} className={classes.partValue}>
          {props?.value}
        </Box>
      </Box>
    );
  };

  const closureMessage = [
    {
      id: 1,
      name: "Opening this tab will undo closure certification!",
    },
    {
      id: 2,
      name: "The Order State will be taken backward to this tab stage!",
    },
    {
      id: 3,
      name: "The tab will need to be closed again by a certifier in the APP!",
    },
  ];

  return (
    <div>
      <Box>
        <Typography
          style={{
            color: theme.palette.primary.main,
            marginBottom: "12px",
            fontSize: "17px",
          }}
          color="primary"

        >
          CRS Overview
        </Typography>

        <Box
          display="flex"
          alignItems="center"
          //   justifyContent={(loading || _.isEmpty(partDetails)) && "center"}
          bgcolor="#F5F5F5"
          className={classes.partCard}
        >
          <Box flexGrow={1}>
            <div style={{ width: "100%" }}>
              <GetInfoCard
                keyName={"CRS Cautions accepted by"}
                value={certificationDetails?.crs_cautions_accepted_by}
              />
              <GetInfoCard
                keyName={"Maintenance Type"}
                value={certificationDetails?.maintenance_type}
              />
              <GetInfoCard
                keyName={"Quantity"}
                value={certificationDetails?.quantity}
              />
              <GetInfoCard
                keyName={"Release Auth"}
                value={certificationDetails?.release_auth}
              />
              <GetInfoCard
                keyName={"Release Type 1"}
                value={certificationDetails?.release_type_1}
              />
              <GetInfoCard
                keyName={"Release Type 2"}
                value={certificationDetails?.release_type_2}
              />
              <GetInfoCard
                keyName={"Remarks"}
                value={""}
              // value={certificationDetails?.tech_data_crs_remarks}
              />
              <Typography style={{ margin: "12px 0px 12px" }}>
                {certificationDetails?.tech_data_crs_remarks}
                {/* - SRM 51-60-07-0G-0 Rev 70 Dated 10 Jul
                2020, AMM 51-21-11/701 Rev 72 dated 15 Jun 2020 and CMM 55-40-09
                Rev 33 Dated Mar 01/2020 */}
              </Typography>
              <Typography style={{ margin: "12px 0px 12px" }}>
                {certificationDetails?.reopen_remarks}
              </Typography>
              <Typography style={{ margin: "12px 0px 12px" }}>
                {/* Fan repaired and tested satisfactory IAW approved maintenance
                data mentioned above */}
                {certificationDetails?.crs_remarks}
              </Typography>
              <GetInfoCard keyName={"Authorised Signature"} value="" />
              <Grid>
                <SignatureFile
                  defaultValue={certificationDetails?.authorisation_sign?.map(
                    (file) => ({
                      filename: file?.file_name,
                      url: file?.file_path,
                      id: file?.id,
                    })
                  )}
                  APIUrl={""}
                  uploadButtonView={false}
                />


              </Grid>
              <GetInfoCard
                keyName={"Name"}
                value={certificationDetails?.name}
              />
              <GetInfoCard
                keyName={"Stamp"}
                value={certificationDetails?.crs_cautions_accepted_by}
              />
              <GetInfoCard
                keyName={"Date & Time"}
                value={format24HrDateTime(certificationDetails?.crs_datetime)}
              />
            </div>
          </Box>
        </Box>
      </Box>

      <Grid
        style={{
          display: "flex",
          justifyContent: "flex-start",
          marginTop: "20px",
        }}
      >
        <MROButton
          value={editTab}
          variant={"contained"}
          style={{
            backgroundColor: editTab ? "green" : "#FFB000",
            color: "white",
            display: orderStateNum > 8 && tabStatus && props?.permission.write ? "" : "none",
          }}
          onClick={handleTab}
          startIcon={
            <img
              className={classes.svg}
              src={editTab ? unlock : lock}
              alt="lock"
            />
          }
        >
          {editTab ? "Open" : "Unlock"}
        </MROButton>
      </Grid>
      {action === "unlock" && (
        <CautionPopUp
          open={editTab}
          handleClose={() => setAction(null)}
          cautionMessages={closureMessage}
          getCertificationDetails={getCertificationDetails}
          workshop_id={workshop_id}
          tab={"certification"}
          setEditTab={setEditTab}
          editTab={editTab}
          getWorkshopOrderDetails={props.getWorkshopOrderDetails}
          getMaintenanceOrderList={props.getMaintenanceOrderList}
        />
      )}
    </div>
  );
}

export default CRSOverView;
