import React, { useRef, useState } from "react";

import { Box, Typography, Grid, CircularProgress } from "@material-ui/core";

import { useReactToPrint } from "react-to-print";
import QRCode from "qrcode.react";
import MROButton from "./buttons";

function QRCodeWithData(props) {
  const { qrData, data, handleClose, loading } = props;

  const printContainerRef = useRef();
  const printHandler = useReactToPrint({
    content: () => printContainerRef.current,
    onAfterPrint: () => setIsPrintWindowOpen(false),
  });

  const [isPrintWindowOpen, setIsPrintWindowOpen] = useState(false);

  if (loading) {
    return (
      <Box
        width="100%"
        height="649px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress color="primary" size={30} />
      </Box>
    );
  }

  return (
    <div>
      {/* printContainer */}
      <Typography
        color="primary"
        style={{ fontWeight: "normal", fontSize: "18px" }}
      >
        Print Part QR Label
      </Typography>
      <Box width="100%" ref={printContainerRef} mt="25px">
        <Box
          width="335px"
          pl="25px"
          pr="25px"
          pt="11px"
          pb="35px"
          boxShadow={isPrintWindowOpen ? undefined : "0px 3px 6px #00000029"}
          borderRadius="7px"
        >
          <Grid container>
            {data.map((item, idx) => (
              <React.Fragment key={`${item.label}-${idx}`}>
                <Grid item xs={4} style={{ marginBottom: 3 }}>
                  <Typography color="primary" variant="subtitle1">
                    {item.label}
                  </Typography>
                </Grid>
                <Grid item xs={8} style={{ marginBottom: 3 }}>
                  <Typography style={{ fontSize: 16, color: "#4D4F5C" }}>
                    {item.value ?? "-"}
                  </Typography>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>

          <Box mt="26px" display="flex" justifyContent="center">
            <QRCode size={200} value={JSON.stringify(qrData)} />
          </Box>
        </Box>
      </Box>

      <Box margin="0 auto" mt="48px" display="flex" justifyContent="center">
        <MROButton
          style={{ marginRight: "20px" }}
          type="button"
          variant={"contained"}
          onClick={handleClose}
        >
          Cancel
        </MROButton>
        <MROButton
          type="submit"
          variant={"contained"}
          color="primary"
          style={{ background: "#4FC605" }}
          // onClick={() => window.print()}
          onClick={() => {
            printHandler();
            setIsPrintWindowOpen(true);
          }}
        >
          Print
        </MROButton>
      </Box>
    </div>
  );
}

export default QRCodeWithData;
