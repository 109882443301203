import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import { CircularProgress } from "@material-ui/core";

const MROButton = withStyles((theme) => ({
  root: {
    fontWeight: 400,
    textTransform: "none",
    fontSize: 14,
    "&:hover, &:active": {
      borderColor: theme.palette.primary.dark,
      boxShadow: "none",
    },
  },
}))((props) => (
  <Button
    type={"button"}
    disableElevation
    disableRipple
    disableFocusRipple
    disabled={props.loading}
    {...props}
  >
    {props.loading ? (
      <CircularProgress size={20} color="primary" />
    ) : (
      props.children
    )}
  </Button>
));

export default MROButton;
