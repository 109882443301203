import React, { useContext, useState } from 'react'
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import { Dialog, DialogContent, DialogTitle, FormControlLabel } from "@material-ui/core";
import PositiveSwitch from "components/form_components/switch";
import MROButton from 'components/buttons';
import { Typography } from "@material-ui/core";
import { ToastMessageContext } from "lib/contexts/message_context";
import { MaintenanceServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation";
import DateField from 'views/Accounts/Workshop/Maintenance/ManageInvoice/DateField';
import { WorkshopContext } from "views/Operations/Workshop/WorkshopContext/WorkshopContext";


const useStyles = makeStyles((theme) => ({
    formWrapper: {
        background: "#fff",
        width: "100%",
    },
    title: {
        display: "flex",
        justifyContent: "left",
        "& h2": {
            //   color: theme.palette.primary.main,
            fontSize: "40px",
            fontWeight: 40,
        },
    },
    subTitle: {
        fontSize: "16px",
        fontWeight: "bold",
        marginBottom: "20px",
        color: "#4D4F5C",
    },
    card: {
        padding: "0 25px",
    },
    actionButtons: {
        paddingBottom: "33px",
        textAlign: "end",
        marginTop: '40px',
        "& button:not(:last-child)": {
            marginRight: "20px",
        },
    },
    purchaseGrid: {
        paddingTop: "0 !important",
        paddingBottom: "0 !important",
        height: "85px",
    },
    purchaseStock: {
        marginBottom: "20px",
        maxWidth: "274px",
    },
    modal: {
        "& .MuiDialog-paperWidthMd": {
            width: "25%",
        },
    }
}));


function AdditionalFieldPopUp(props) {
    const classes = useStyles();
    const { handleClose, currentSelectedRow, actionData, currentPlanningDetails } = props
    const [checked, setChecked] = useState(true)
    const [errors, setErrors] = useState({});
    const message = useContext(ToastMessageContext)
    const [files, setFiles] = useState([])
    const { setCopyCalendarStatus } = useContext(WorkshopContext)

    const handleSubmit = async (e) => {

        e.preventDefault();

        const formData = new FormData(e.target);
        let valid = true;

        let error = {
            last_completed: false,
            camo_records: false,

        };
        if (formData && formData.get("last_completed")?.length === 0) {
            error.last_completed = true;
            valid = false;
        }
        if (formData && formData.get("camo_records") === " ") {
            error.camo_records = true;
            valid = false;
        }
        if (!valid) {

            setErrors(error);
            return;
        }


        formData.append("workshop_order_id", currentPlanningDetails?.id)
        formData.append("workshop_action_id", currentSelectedRow.workshop_action_id)
        formData.append("action_type", currentSelectedRow.action_type)
        let userDetails = JSON.parse(sessionStorage.getItem("user"));
        formData.append("raised_by", userDetails?.id);
        formData.append("camo_records", checked)
        formData.append("repeat_events", currentSelectedRow.repeat_events)
        await MaintenanceServices.copyPreviousCreateAction(formData).then((response) => {
            if (response.success) {
                setCopyCalendarStatus(true)
                handleClose()
                message.showToastMessage({
                    message: "Copied successfully ",
                    variant: "success",
                });
            }
        }).catch((error) => {
            message.showToastMessage({
                message: "Something went wrong",
                variant: "error",
            });
        })





    }

    const resetErrorsHandler = (name) => {
        setErrors((e) => ({ ...e, [name]: false }));
    };



    return (
        <div>
            <Dialog
                maxWidth={"md"}
                open={true}
                className={classes.modal}
                onClose={handleClose}
                aria-labelledby="base-station-manage"
            >
                <DialogTitle>
                    <Typography
                        color="primary"
                        className={classes.title}
                    >
                        Additional Data Required
                    </Typography>
                </DialogTitle>
                <DialogContent className={classes.card}>
                    <div className={classes.formWrapper}>
                        <form noValidate onSubmit={handleSubmit}>
                            <Grid

                                container
                                spacing={1}
                                alignItems={"center"}
                                justify={"flex-start"}
                            >

                                <Grid className={classes.purchaseGrid} item xs={12}>
                                    <DateField
                                        required
                                        name="last_completed"
                                        label={"Due Date *"}
                                        InputLabelProps={{ shrink: true }}
                                        error={errors.last_completed}
                                        helperText={errors.last_completed ? "*please enter a date" : null}
                                        onChange={(e) => { setErrors({ ...errors, last_completed: false }); }}

                                    />
                                </Grid>
                                <Grid>
                                    <FormControlLabel
                                        control={
                                            <PositiveSwitch
                                                defaultChecked={true}
                                                style={{ background: "transparent" }}
                                                checked={checked}
                                                value={checked}
                                                onChange={(e) => {
                                                    setChecked(e.target.checked);
                                                }}
                                                error={errors.camo_records}
                                                setError={() => resetErrorsHandler("camo_records")}
                                            />}
                                        label="Operator / CAMO Part M technical records applicable each time?"
                                        labelPlacement="start"
                                        name={"camo_records"}
                                    />
                                </Grid>
                            </Grid>
                            <div className={classes.actionButtons}>
                                <MROButton
                                    //   disabled={loading}
                                    type="button"
                                    variant={"contained"}
                                    onClick={handleClose}
                                >
                                    Cancel
                                </MROButton>
                                <MROButton
                                    //   disabled={loading}
                                    type="submit"
                                    variant={"contained"}
                                    color="primary"
                                >
                                    Complete
                                </MROButton>
                            </div>
                        </form>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default AdditionalFieldPopUp