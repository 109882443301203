import axios from "axios";
import { BASE_API_URL_BOXP } from "../../../constants";

export const requestApi = async function (options) {
    const authHeaders = JSON.parse(sessionStorage.getItem('authorization_headers'));
    authHeaders["Access-Control-Allow-Origin"] = "*";
    // authHeaders["Access-Control-Allow-Headers"] = "Origin, X-Requested-With, Content-Type, Accept";
    // authHeaders["Access-Control-Allow-Headers"] = "*";
    // authHeaders["Access-Control-Allow-Methods"] = "GET,POST,PUT,DELETE,OPTIONS";
    const users = JSON.parse(sessionStorage.getItem('user'));
    const organisation = JSON.parse(sessionStorage.getItem('organisation'));
    if (users != null && users.role == "super_admin") {

        let mainUrl = options['url']
        let url1 = mainUrl + '?organisation_id=';
        let url2 = mainUrl + '&organisation_id=';
        let logicFiedUrl = mainUrl?.includes('?') ? (url2 + organisation.id) : (url1 + organisation.id)
        // options['url'] != '/organisations/organisation_login' && (options['url'] = options['url'] + '?organisation_id=' + organisation.id)
        options['url'] != '/organisations/organisation_login' && (options['url'] = logicFiedUrl)
    }
    const client = axios.create({
        baseURL: BASE_API_URL_BOXP,
        headers: options.headers || authHeaders
    });

    const onSuccess = function (response) {
        if (options.handleHeaders === 1) {
            if (response && response.data && response.data.user) {
                sessionStorage.setItem('authorization_headers', JSON.stringify(response.headers));
                const userRole = response.data.user_role;
                const user = JSON.stringify({ ...response.data.user, role: userRole });
                sessionStorage.setItem("user", user);
                const permissions = JSON.stringify(response.data.permissions);
                const organisation = JSON.stringify(response.data.organisation);
                const logo = response.data.avatar;
                permissions && sessionStorage.setItem('permissions', permissions);
                organisation && sessionStorage.setItem('organisation', organisation);
                logo && sessionStorage.setItem('logo', logo);
            }
        }
        if (options.handleHeaders === 3) {
            if (response && response.data && response.data.user) {
                console.log(response)

                console.log("response header is====================", response.headers)
                const userRole = response.data.user.role;
                const user = JSON.stringify({ ...response.data.user, role: userRole });
                const organisation = JSON.stringify(response.data.organisation);
                const logo = response.data.avatar;
                sessionStorage.setItem("user", user);
                sessionStorage.setItem('authorization_headers', JSON.stringify(response.headers));
                organisation && sessionStorage.setItem('organisation', organisation);
                logo && sessionStorage.setItem('logo', logo);
            }
        }
        if (options.handleHeaders === 0) {
            const logo = sessionStorage.getItem('logo');
            sessionStorage.clear();
            logo && sessionStorage.setItem('logo', logo);
            window.location.href = '/auth/signin';
        }
        const resp = response.data;
        if (resp.hasOwnProperty("success")) {
            return resp;
        }
        else {
            return { ...resp, success: true }
        }
    };

    const onError = function (error) {
        if (options.handleHeaders === 0) {
            const logo = sessionStorage.getItem('logo');
            sessionStorage.clear();
            logo && sessionStorage.setItem('logo', logo);
        }
        if (error.response) {
            if (error.response.status === 401 && options.handleHeaders !== 1) {
                window.location.href = '/auth/signin';
            }
        }
        //Check API and return response in accepted state with error data and success equals false flag to avoid catch block in all api requests.
        return Promise.reject(error.response ? error.response.data : { message: "something went wrong", success: false } || error.message || error.errors);
    };

    return client(options)
        .then(onSuccess)
        .catch(onError);
};

export default requestApi;

