import requestrApi from "./common/boxr_api_client";

const dataUrl = {
    commercialRates:"/line_customers/commercial_rates",
    fetchCapabiity: "/capabilities",
    allocationDetailsList: "/part_requests/allocated_job_req_details"
}

export function getData(params, section) {
    return requestrApi({
        url: dataUrl[section],
        method: 'GET',
        params: params,
        paramsSerializer: (params) => {
            // Sample implementation of query string building
            let result = '';
            Object.keys(params).forEach(key => {
                if (params[key] != null) {
                    if(key== "tasks[]" || key == "consumables[]"){
                        const tasksVal=params[key];
                        tasksVal.map(function(eachTask){
                            result += `${key}=${encodeURIComponent(eachTask)}&`;
                        })
                    }
                    else{
                        result += `${key}=${encodeURIComponent(params[key])}&`;
                    }
                   
                }
            });
            return result.substr(0, result.length - 1);
        }
    })
}

const BoxrListingService = {
    getData
}


export default BoxrListingService;
