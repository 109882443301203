import React, { useContext, useEffect, useReducer, useState } from "react";
import {
  Grid,
  Typography,
  makeStyles,
  FormControl,
  Select,
  InputBase,
  MenuItem,
} from "@material-ui/core";
import clxc from "clsx";
import SearchBarWithFilters from "components/search_with_filters";
import MaterialTable from "material-table";
import tableIcons, { options } from "components/universal/table_attributes";
import TableAction from "actions/table_actions";
import tableReducer, { INITIAL_TABLE_STATE } from "reducers/table_reducer";
import MROButton from "components/buttons";
import useDebounce from "lib/utils/debounce";
import { ToastMessageContext } from "lib/contexts/message_context";
import AddSpecialistEquipment from "./SpecialistEquipment/AddSpecialistEquipment";
import SpecialistEquip from "./SpecialistEquipment/SpecialistEquip";
import { formatDate } from "lib/utils/common_utils";

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
    width: "100%",
    "& .MuiOutlinedInput-multiline": {
      minHeight: "89px",
    },

    "& textarea::placeholder": {
      textTransform: "none !important",
    },

    "& form": {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    bold: {
      fontWeight: 600,
    },
  },
  title: {
    fontSize: "17px",
    marginTop: "10px",
    marginBottom: "10px",
    marginRight: "20px",
  },
  title2: {
    fontSize: "20px",
    margin: "20px 20px 10px 0px",
  },

  StockInfoDrawer: {
    "& > div[aria-hidden='true']": {
      visibility: "hidden !important",
    },
  },
  pendEo: {
    borderColor: "#4D4F5C",
    borderRadius: "7px",
    minWidth: "20px",
    height: "30px",
    backgroundColor: "red",
  },
  booked: {
    borderColor: "#4FC605",
    borderRadius: "7px",
    minWidth: "20px",
    height: "30px",
    backgroundColor: "#FFB300",
  },
  received: {
    borderColor: "#4FC605",
    borderRadius: "7px",
    minWidth: "20px",
    height: "30px",
    backgroundColor: "#4FC605",
  },
  cancelled: {
    borderColor: "#4FC605",
    borderRadius: "7px",
    minWidth: "20px",
    height: "30px",
    backgroundColor: "#4FC605",
  },
  rejected: {
    borderColor: "#4FC605",
    borderRadius: "7px",
    minWidth: "20px",
    height: "30px",
    backgroundColor: "red",
  }
}));

function EquipmentServices(props) {
  const classes = useStyles();
  const { workshop_id, dependencies, orderState,baseId} = props;
  const message = useContext(ToastMessageContext);
  const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
  const { page, totalCount, pageSize, inProgress, data } = tableState;
  const [searchText, setSearchText] = useState("");
  const debouncedVal = useDebounce(searchText);
  const [action, setAction] = useState(null);
  const [filters, setFilters] = useState({});
  const [currentSelection, setCurrentSelection] = useState({});

  useEffect(() => {
    getWorkshopEquipment();
  }, [page, pageSize, debouncedVal, filters]);

  const getWorkshopEquipment = () => {
    const params = {
      search_keyword: debouncedVal,
      page: page + 1,
      id: workshop_id,
      count_per_page: pageSize,
      ...filters,
    };
    TableAction.getList(dispatch, message, params, "getWorkshopEquipment");
  };

  const tableOptions = {
    ...options,
    page: page,
    search: false,
    total: totalCount,
    pageSize: pageSize,
    searchText: searchText,
    toolbar: false,
  };

  const renderDescription = (rowData) => {
    return rowData?.description?.slice(0, 99)
  }

  const columnConfig = [
    {
      title: "",
      field: "id",
      width: "3%",
      render: () => <span></span>,
    },
    {
      title: "Action #",
      field: "workshop_action_number",
      headerStyle: {
        textAlign: "center",
        width: "10%",
      },
      cellStyle: {
        textAlign: "center",
        width: "10%"
      },
    },
    {
      title: "Description",
      field: "description",
      headerStyle: {
        textAlign: "left",
        width: "30%",
      },
      cellStyle: {
        textAlign: "left",
        width: "30%",
      },
      render: (rowData) => renderDescription(rowData)
    },
    {
      title: "Needed",
      headerStyle: {
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (rowData) => formatDate(rowData?.desired_date)
    },
    {
      title: "Days",
      field: "duration",
      headerStyle: {
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "E/Order #",
      field: "order_number",
      headerStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Planned",
      headerStyle: {
        textAlign: "center",
      },
      render: (rowData) => formatDate(rowData?.planned_date)
    },
    {
      title: "Status",
      render: (rowData) => {
        if (rowData?.state === "open" && rowData?.approval_state === false) {
          return (
            <MROButton
              className={clxc(classes.button, "button-info", classes.pendEo)}
              onClick={() => {
                setAction("SpecialistEquip");
                setCurrentSelection(rowData);
              }}
              variant="contained"
            >
              <u style={{ fontSize: "12px", color: "white" }}>PEND E/O</u>
            </MROButton>
          )
        }
        if (rowData?.approval_state === true && rowData?.state === "open") {
          return (
            <MROButton
              className={clxc(classes.button, "button-info", classes.booked)}
              onClick={() => {
                setAction("SpecialistEquip");
                setCurrentSelection(rowData);
              }}
              variant="contained"
            >
              <u style={{ fontSize: "12px", color: "white" }}>BOOKED&nbsp;&nbsp;</u>
            </MROButton>
          )
        }
        if (rowData?.state === "complete") {
          return (
            <MROButton
              className={clxc(classes.button, "button-info", classes.received)}
              onClick={() => {
                setAction("SpecialistEquip");
                setCurrentSelection(rowData);
              }}
              variant="contained"
            >
              <u style={{ fontSize: "12px", color: "white" }}>RECEIVE</u>
            </MROButton>
          )
        }
        if (rowData?.state === "cancelled") {
          return (
            <MROButton
              className={clxc(classes.button, "button-info", classes.cancelled)}
              onClick={() => {
                setAction("SpecialistEquip");
                setCurrentSelection(rowData);
              }}
              variant="contained"
            >
              <u style={{ fontSize: "12px", color: "white" }}>CANCELLED</u>
            </MROButton>
          )
        }
        if (rowData?.state === "rejected") {
          return (
            <MROButton
              className={clxc(classes.button, "button-info", classes.rejected)}
              onClick={() => {
                setAction("SpecialistEquip");
                setCurrentSelection(rowData);
              }}
              variant="contained"
            >
              <u style={{ fontSize: "12px", color: "white" }}>REJECTED</u>
            </MROButton>
          )
        }
      }
    },
  ];

  const filterChangeHandler = (keyItem, value) => {
    TableAction.setPage(dispatch, 0);
    setFilters((prevState) => ({ ...prevState, [keyItem]: value }));
  };

  return (
    <>
      <Typography color="primary" className={classes.title}>
        Specialist Equipment
      </Typography>
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Typography
            className={classes.bold}
          // style={{ marginLeft: 1000 }}
          >
            <MROButton
              disabled={!props?.permission.write}
              style={{ width: 140, height: 36, display: orderState === "Closed" && "none" }}
              variant={"contained"}
              color={"secondary"}
              onClick={() => setAction("Add")}
            >
              + Add Item
            </MROButton>
          </Typography>
          <SearchBarWithFilters
            handleSearch={(value) => {
              TableAction.setPage(dispatch, 0);
              setSearchText(value);
            }}
            searchText={searchText}
          >
            <FormControl size="medium" className={"filter"}>
              <Select
                defaultValue={undefined}
                displayEmpty
                input={<InputBase />}
                onChange={(e) => filterChangeHandler("workshop_action_id", e.target.value)}
              // value={filters.workshop_action_id}
              >
                <MenuItem value={undefined}> Action (All)</MenuItem>
                {dependencies?.actions?.map((item) => (
                  <MenuItem
                    key={item.workshop_action_id}
                    value={item.workshop_action_id}
                  >
                    {item.action_number}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </SearchBarWithFilters>

          {/* Table */}
          <MaterialTable
            style={{ marginTop: 20 }}
            columns={columnConfig}
            icons={tableIcons}
            title=""
            data={data?.data || [{}]}
            isLoading={inProgress}
            options={tableOptions}
            localization={{
              body: {
                emptyDataSourceMessage: 'No data Found',
              },
            }}
            onChangePage={(page) => {
              TableAction.setPage(dispatch, page);
            }}
            onChangeRowsPerPage={(pageSize) => {
              TableAction.setPageSize(dispatch, pageSize);
            }}
            onSearchChange={(search) => {
              TableAction.setSearchText(dispatch, search);
            }}
            totalCount={totalCount}
            page={page}
          />
        </Grid>
      </Grid>

      {action === "Add" && (
        <AddSpecialistEquipment
          action={action}
          workshop_id={workshop_id}
          onClose={() => setAction(null)}
          getWorkshopEquipment={getWorkshopEquipment}
          orderState={orderState}
          baseId={baseId}
        />
      )}
      {action === "SpecialistEquip" && (
        <SpecialistEquip
          baseId={baseId}
          workshop_id={workshop_id}
          action={"Edit"}
          handleClose={() => setAction(null)}
          orderState={orderState}
          currentSelection={currentSelection}
          getWorkshopEquipment={getWorkshopEquipment}
          permission={props?.permission}
        />
      )}
    </>
  );
}

export default EquipmentServices;
