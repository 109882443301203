import DataService from "../lib/services/api";

export const DATA_SERVICE_ACTIONS = {
    SET_DATA: 'set_current_data',
    TOGGLE_IN_PROGRESS: 'data_add_update_in_progress',
    CLEAR_DATA: 'clear_data'
}

function createData(dispatch, message, formData, section, key, cb, getList, handleClose, setNewData) {
    //debugger;
    dispatch({ type: DATA_SERVICE_ACTIONS.TOGGLE_IN_PROGRESS });
    return DataService.createData(formData, section).then((res) => {
        if (res.success) {
            // dispatch({ type: DATA_SERVICE_ACTIONS.SET_DATA, payload: res, key: key });
            typeof cb === 'function' && cb(Date.now());
            typeof getList === 'function' && getList();
            typeof handleClose === 'function' && handleClose(res.company_approval);
            typeof setNewData === 'function' && setNewData(res.company_approval);
            return message.showToastMessage({ message: "Added Successfully", variant: "success" });
        }
        if (res.data) {
            typeof cb === 'function' && cb(res.data.id)
        }
        else if (Array.isArray(res.errors)) {
            dispatch({ type: DATA_SERVICE_ACTIONS.TOGGLE_IN_PROGRESS, payload: false });
            message.showToastMessage({ message: res.errors ? res.errors.join(', ') : "Something went wrong. Try again", variant: 'error' });
            typeof cb === 'function' && cb(Date.now());
            typeof handleClose === 'function' && handleClose();
        } else if (res.errors) {
            dispatch({ type: DATA_SERVICE_ACTIONS.TOGGLE_IN_PROGRESS, payload: false });
            message.showToastMessage({ message: res.errors ? res.errors : "Something went wrong. Try again", variant: 'error' });
            typeof cb === 'function' && cb(Date.now());
            typeof handleClose === 'function' && handleClose();
        }
    }).catch((e) => {
        dispatch({ type: DATA_SERVICE_ACTIONS.TOGGLE_IN_PROGRESS });
        message.showToastMessage({ message: e.message || "Something went wrong", variant: "error" });
        typeof handleClose === 'function' && handleClose();
    })
}

function updateData(dispatch, message, formData, id, section, key = 'data', cb = () => { }, errCb = () => { }) {
    dispatch({ type: DATA_SERVICE_ACTIONS.TOGGLE_IN_PROGRESS });
    return DataService.updateData(formData, id, section).then((res) => {
        if (res.success) {
            dispatch({ type: DATA_SERVICE_ACTIONS.SET_DATA, payload: res, key: key });
            message.showToastMessage({ message: "Updated successfully", variant: "success" });
            typeof cb === 'function' && cb();
            return res
        }
        else {
            dispatch({ type: DATA_SERVICE_ACTIONS.TOGGLE_IN_PROGRESS });
            message.showToastMessage({ message: res.errors ? res.errors.join(', ') : "Something went wrong. Try again", variant: "error" });
        }
    }).catch((e) => {
        dispatch({ type: DATA_SERVICE_ACTIONS.TOGGLE_IN_PROGRESS });
        message.showToastMessage({ message: e.message || "Something went wrong. Try again", variant: 'error' });
        typeof errCb === 'function' && errCb();
    })
}

function deleteData(dispatch, message, id, data, section, cb = () => { }, params, getList) {
    dispatch({ type: DATA_SERVICE_ACTIONS.TOGGLE_IN_PROGRESS });
    console.log({ id, section, data })
    return DataService.deleteData(id, section, data, params).then((res) => {
        if (res.success) {
            dispatch({ type: DATA_SERVICE_ACTIONS.SET_DATA, payload: { data: { id: data.id || id } } });
            message.showToastMessage({ message: "Removed successfully", variant: "success" });
            typeof cb === 'function' && cb();
            typeof getList === 'function' && getList();
            // handleClose();
        }
        else {
            dispatch({ type: DATA_SERVICE_ACTIONS.TOGGLE_IN_PROGRESS });
            message.showToastMessage({ message: res.errors ? res.errors.join(', ') : "Something went wrong. Try again", variant: "error" });
        }
    }).catch((e) => {
        dispatch({ type: DATA_SERVICE_ACTIONS.TOGGLE_IN_PROGRESS });
        message.showToastMessage({ message: e.message || "Something went wrong. Try again", variant: 'error' });
    })
}

function clearData(dispatch) {
    dispatch({ type: DATA_SERVICE_ACTIONS.CLEAR_DATA });
}

const DataServiceAction = {
    createData,
    updateData,
    clearData,
    deleteData
}
export default DataServiceAction;