import React, { useContext, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import MROButton from "components/buttons";
import { InspectionServices } from "lib/services/api/operaitons/WorkShop/Inspection/InspectionService";
import { ToastMessageContext } from "lib/contexts/message_context";

const useStyles = makeStyles(() => ({
  modal: {
    "& .MuiDialog-paperWidthMd": {
      width: "25%",
    },
  },
  title: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  warning: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "16px",
    marginBottom: "20px",
  },
  actionButtons: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

function DeleteStage(props) {
  const classes = useStyles();
  const { handleClose, open, action_Id, getAllActionStages } = props;
  const message = useContext(ToastMessageContext);
  const [loading, setLoading] = useState(null);

  const handleDelete = () => {
    setLoading("busy");
    InspectionServices.deleteStage(action_Id)
      .then((res) => {
        if (res.success) {
          props.closeStageHandler(res.success);
          getAllActionStages();
          message.showToastMessage({
            message: "Stage deleted successfully",
            variant: "success",
          });
        }
      })
      .catch(() => {
        message.showToastMessage({
          message: "Something went wrong. Try again!",
          variant: "error",
        });
      })
      .finally(() => {
        setLoading(null);
      });
  };

  return (
    <div>
      <Dialog
        maxWidth={"md"}
        fullWidth={true}
        open={open}
        className={classes.modal}
        onClose={handleClose}
      >
        <DialogTitle>
          <Typography color="primary" className={classes.title}>
            Delete Item
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography className={classes.warning}>
            This cannot be undone. Are you sure?
          </Typography>
          <Grid className={classes.actionButtons} spacing={1} container>
            <Grid item>
              <MROButton
                type="button"
                variant={"contained"}
                onClick={handleClose}
              >
                Cancel
              </MROButton>
            </Grid>
            <Grid item>
              <MROButton
                loading={loading === "busy"}
                onClick={handleDelete}
                type="submit"
                variant={"contained"}
                color="primary"
              >
                Yes
              </MROButton>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default DeleteStage;
