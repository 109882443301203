import React, { useMemo, useState } from "react";
import ReactApexChart from 'react-apexcharts'


function Handover(props) {

  const { barData, loading, lineData, xAxisValue } = props


  const complete = []
  const raised = []
  const not_ackn = []


  useMemo(() => {

    if (barData != []) {
      for (let i = 0;i < barData.length;i++) {
        complete.push(barData[i].complete)
        raised.push(barData[i].raised)
        not_ackn.push(barData[i].not_ackn)


      }


    }

  }, [barData, lineData, loading])




  // console.log('stationcheck======', barData)
  const [state, setState] = useState({
    series: [
      {
        name: 'Due',
        data: lineData,
        type: 'line',
        order: 1
      },
      {
        name: 'Complete',
        type: 'column',
        data: complete,
        order: 2
      },
      {
        name: 'Raised',
        type: 'column',
        data: raised,
        order: 2
      },
      {
        name: 'Not Ackn',
        type: 'column',
        data: not_ackn,
        order: 2
      },

    ],

    options: {
      chart: {
        type: 'line',
        stacked: true,
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        },

      },
      stroke: {
        width: [2, 0, 0, 0]
      },
      colors: ['#8CDA5C', '#8CDA5C', '#FFCD59', '#FF5A5A'],

      dataLabels: {
        enabled: false
      },

      xaxis: {

        categories: xAxisValue,

        labels: {
          show: true,
        }

      },
      yaxis: {
        labels: {
          rotate: 0,
        },
      }, grid: {
        show: true,
        strokeDashArray: 0,
        position: 'back',
        xaxis: {
          lines: {
            show: false
          }
        },
        yaxis: {
          lines: {
            show: true
          }
        },
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'top',
            offsetX: 0,
            offsetY: 0
          }
        }
      }],
      plotOptions: {
        bar: {
          columnWidth: '30%',
          barHeight: '80%',
          dataLabels: {
            enabled: false
          },
        },


      },

      legend: {
        position: 'top',
        horizontalAlign: "center",
        // floating: true,
        // offsetY: -5,
        offsetX: 100,
        markers: {
          width: [20, 15, 15, 15],
          height: [8, 15, 15, 15],
          strokeWidth: 1,
          strokeColor: '#fff',
          borderRadius: '5px',
          radius: [2, 2, 2, 0],


        },

      },
      fill: {
        opacity: 1
      }
    },


  })
  return (


    <>

      <ReactApexChart options={state.options} series={state.series} type="line" height={300} />


    </>

  );
}
export default React.memo(Handover)