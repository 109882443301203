import React, { useContext, useEffect, useReducer, useState } from "react";
import Dialog from "@material-ui/core/Dialog";

import {
    createStyles,
    Grid,
    IconButton,
    makeStyles,
    Typography,

} from "@material-ui/core";
import tableIcons, { options } from "components/universal/table_attributes";
import MaterialTable from "material-table";
import { ToastMessageContext } from "lib/contexts/message_context";
import tableReducer, { INITIAL_TABLE_STATE } from "reducers/table_reducer";
import CapabilityServices from "lib/services/api/compliance/capability_client";
import { getOrganisationId } from "lib/utils/common_utils";
import moment from "moment";

const useStyles = makeStyles((theme) =>
    createStyles({
        modal: {
            "& .MuiDialog-paperWidthSm": {
                minWidth: "750px",
                // height: "110vh"
            },
        },
        container: {
            // minWidth: "1200px",
            // minHeight: "400px",
            padding: "50px",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden !important"
        },

        popupTitle: {
            fontSize: "20px",
            lineHeight: "28px",
            position: "absolute",
            top: "19px",
            bottom: "15px",
            left: "25px",
        },
        divider: {
            borderBottom: `1px solid ${theme.palette.primary.main}`,
        },
        tableTitle: {
            fontSize: "16px",
            lineHeight: "22px",
            color: "#4D4F5C",
            fontWeight: "bold",
            marginTop: "19px",
            marginBottom: "16px",
        },
        tableCell: {
            height: '32px',
            justifyContent: "space-between",
            whiteSpace: "normal",
            wordWrap: "break-word",

        },
    })
);

export default function RevisionHistory(props) {
    const { handleClose, getTechListing, currentSelection } = props;
    const classes = useStyles();
    const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
    const message = useContext(ToastMessageContext);
    const [actionStages, setActionStages] = useState([]);
    const [pageSize, setPageSize] = useState(5);
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(5);
    const [data, setData] = useState([])
    const [Loading, setLoading] = useState(false)

    const tableOptions = {
        ...options,
        page: page,
        total: totalCount,
        pageSize: pageSize,
        search: false,
        toolbar: false,
        rowStyle: { height: 42 },
    };

    const getAllDetails = async () => {
        setLoading(true)
        const params = {
            organisation_id: getOrganisationId(),
            id: currentSelection?.id,
            page: page + 1,
            count_per_page: pageSize,


        }
        await CapabilityServices.getAllRevisionDetails(params).then((res) => {
            setData(res.data)
            setTotalCount(res.total_count)
        }).catch(() => {
            message.showToastMessage({
                message: "Something went wrong",
                variant: "error",
            });
        }).finally(() => {
            setLoading(false)
        })

    }

    useEffect(() => {
        getAllDetails()
    }, [page, pageSize, totalCount])
    const column = [
        {
            title: "Verified",
            field: "verified",
            headerStyle: {
                textAlign: "left",
                paddingLeft: "15px",
            },
            cellStyle: {
                textAlign: "left",
                paddingLeft: "15px",
            },
            render: (rowData) => rowData?.verified ? moment(rowData?.verified, "YYYY-MM-DD").format("DD/MM/YYYY") : "-"
        },
        {
            title: "User",
            field: "user",

            headerStyle: {

                textAlign: "left",
            },
            cellStyle: {

                textAlign: "left",
            },
        },
        {
            title: "Revision #",
            field: "revision",
            width: '50%',
            headerStyle: {

                textAlign: "left",
            },
            cellStyle: {
                width: '50%',
                textAlign: "left",
            },

        },
        {
            title: "Revision Date",
            field: "revision_date",

            headerStyle: {

                textAlign: "left",
            },
            cellStyle: {

                textAlign: "left",
            },
            render: (rowData) => rowData?.revision_date ? moment(rowData?.revision_date, "YYYY-MM-DD").format("DD/MM/YYYY") : "-"
        },




    ];


    return (
        <div>
            <Dialog
                open={true}
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                className={classes.modal}
            >
                <div className={classes.container}>
                    <Typography
                        variant="body1"
                        color="primary"
                        style={{ marginBottom: "20px" }}
                        className={classes.popupTitle}
                    >
                        Revision & Verification History
                    </Typography>

                    <Grid item xs={6} style={{ marginTop: "10px", }}>
                        <MaterialTable
                            style={{
                                boxShadow: "0px 1px 3px #00000033",
                                borderRadius: "4px",
                                width: "650px",
                                // minWidth: "500px"
                            }}
                            // isLoading={isGettingDetails}
                            options={tableOptions}
                            title=""
                            icons={tableIcons}
                            columns={column}
                            data={data || []}
                            isLoading={Loading}
                            onChangePage={(page) => {
                                setPage(page);
                            }}
                            onChangeRowsPerPage={(pageSize) => {
                                setPageSize(pageSize);
                            }}
                            totalCount={totalCount}
                            page={page}

                        />
                    </Grid>
                </div>
            </Dialog>

        </div>
    );
}
