import React from "react";
import MROFullScreenRightDrawer from "components/fullwidth_dialog";
import TabPanel from "./AddStageTabComponent";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "4px",
    textAlign: "center",
  },
  offgoingchecksTable: {
    "& td, th ": {
      border: "1px solid #dddddd",
      textAlign: "left",
      padding: "8px",
    },
  },
  ongoingchecksTable: {
    "& td, th ": {
      border: "1px solid #dddddd",
      textAlign: "left",
      padding: "8px",
    },
  },

  logo: {
    height: "76px",
  },
  filePreviewContainer: {
    maxWidth: "130px",
    marginRight: "30px",
  },
  appBar: {
    backgroundColor: "#fff",
    color: "#000000CC",
    boxShadow: "0px 3px 6px #0000001A",
  },
  checksAppBar: {
    backgroundColor: "lightgray",
    color: theme.palette.secondary.main,
    boxShadow: "0px 3px 6px gray",
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  generalDetail: {
    backgroundColor: "white",
    padding: "2%",
    color: "#fff",
    borderRadius: "5px 5px 0 0",
  },
  content: {
    backgroundColor: "#fff",
    padding: "3% 6%",
    borderRadius: "0 0 5px 5px",
    minHeight: "50vh",
    "& h4": {
      padding: "5px 0",
    },
  },
  titleTile: {
    padding: "3px 20px",
    fontSize: "13px",
    fontWeight: 500,
    marginBottom: "20px",
  },
  wrapper: {

    backgroundColor: "#fff",
    marginLeft: '24px'
  },
}));

function AddStageTab(props) {
  const classes = useStyles();
  const { handleClose, action_Id } = props;

  const closeHHandler = () => {
    handleClose();
    props.getActionStages();
  };
  return (
    <div>
      <MROFullScreenRightDrawer open={true}>
        <AppBar position="static" className={classes.appBar} elevation={0}>
          <Toolbar>
            <IconButton
              onClick={closeHHandler}
              edge="start"
              className={classes.backButton}
              color="inherit"
              aria-label="back"
            >
              <BackIcon />
            </IconButton>
            <Typography variant="subtitle2" style={{ fontSize: "17px" }}>
              Add Stage
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.wrapper}>
          <TabPanel
            getActionStages={props.getActionStages}
            handleClose={handleClose}
            action_Id={action_Id}
            props={props}
          />
        </div>
      </MROFullScreenRightDrawer>
    </div>
  );
}

export default AddStageTab;
