import React, { useState, useReducer, useEffect, useContext } from 'react'
import { MaintenanceServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation";
import MaterialTable from "material-table";
import tableIcons, { options } from 'components/universal/table_attributes';
import CheckBoxIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { ICON_COLOUR } from 'lib/constants/style_constants';
import GreenTick from 'assets/CheckTick.svg'
import GreyTick from 'assets/CheckTickGrey.svg'
import { IconButton } from '@material-ui/core';
import { getOrganisationId } from 'lib/utils/common_utils';
import { ToastMessageContext } from 'lib/contexts/message_context';


export default function Certification(props) {

    const { workshopOrderId, disabled, tabState } = props

    // console.log("data======", tabState)

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const message = useContext(ToastMessageContext)




    const getDetails = async () => {

        setLoading(true)
        const params = {
            workshop_order_id: workshopOrderId
        }
        await MaintenanceServices.getPlanningServiceDetails(params)
            .then((res) => {
                if (res.success) {
                    setData(res?.data)
                }
            }).catch((err) => {
                console.log("error", err)
            }).finally(() => {
                setLoading(false)
            })

    }



    useEffect(() => {
        if (workshopOrderId) {
            getDetails()
        }
    }, [workshopOrderId])



    const handleSelection = (rowData, name) => {

        const formData = {
            organisation_id: getOrganisationId(),
            workshop_order_id: workshopOrderId,
            service_life_id: rowData?.service_lifes_id,
            // capture_later: rowData?.capture_later === null ? false : !rowData?.capture_later,
            applicable: rowData?.applicable ? "false" : "true"

        }
        const formData1 = {
            organisation_id: getOrganisationId(),
            workshop_order_id: workshopOrderId,
            service_life_id: rowData?.service_lifes_id,
            applicable: "true",
            capture_later: rowData?.capture_later === null ? "true" : rowData?.capture_later === false ? "true" : false,


        }

        MaintenanceServices.updatePlanningServiceDetails(name === "applicable" ? formData : formData1).then((res) => {
            if (res.success) {
                getDetails()
            }
        }).catch(() => {

        })



    }

    const handleUpdate = (newData) => {
        const formData = {
            id: newData?.id,
            value: newData?.value

        }

        MaintenanceServices.updatePlanningServiceValue(formData).then((res) => {
            if (res.success) {
                getDetails()
                message.showToastMessage({
                    message: "Updated Successfully!",
                    variant: "success",
                });
            }
        }).catch(() => {
            message.showToastMessage({
                message: "Something went wrong. Try again!",
                variant: "error",
            });
        })


    }



    const column = [

        {
            title: "Applicable",
            field: "name",
            width: '10%',
            editable: 'never',
            headerStyle: {
                width: '10%',
                textAlign: 'left', paddingLeft: '15px'
            },
            cellStyle: {
                width: '10%',
                textAlign: 'left', paddingLeft: '15px'
            },
            render: (rowData) =>
                <div onClick={() => {
                    handleSelection(rowData, "applicable")
                }} style={{ cursor: disabled ? "none" : "pointer", pointerEvents: disabled ? "none" : "" }}>
                    {!rowData?.applicable ?
                        <CheckBoxIcon htmlColor={ICON_COLOUR} style={{ marginTop: '6px' }} />
                        : rowData?.applicable ?
                            <IconButton >
                                <img src={tabState >= 2 ? GreyTick : GreenTick} height={'20px'} />

                            </IconButton> : ""



                    }


                </div>

        },

        {
            title: "Item",
            field: "item",
            editable: 'never',
            headerStyle: {

                textAlign: 'left'
            },
            cellStyle: {

                textAlign: 'left'
            },

        },

        {
            title: 'Description',
            field: 'description',
            width: '50%',
            editable: 'never',
            headerStyle: {
                width: '50%',
                textAlign: 'left'
            },
            cellStyle: {
                width: '50%',
                textAlign: 'left'
            },

        },
        {
            title: 'Capture Later *',
            field: 'capture_later',
            editable: 'never',
            render: (rowData) =>
                <div onClick={() => {
                    handleSelection(rowData, "capture")
                }} style={{ cursor: disabled ? "none" : "pointer", pointerEvents: disabled ? "none" : "" }}>
                    {rowData?.applicable &&
                        !rowData?.capture_later ?
                        <CheckBoxIcon htmlColor={ICON_COLOUR} style={{ marginTop: '6px' }} />
                        : rowData?.capture_later ?
                            <IconButton >
                                <img src={tabState >= 2 ? GreyTick : GreenTick} height={'20px'} />

                            </IconButton> : ""



                    }


                </div>
        },

        {
            title: 'Value',
            field: 'value',
            editable: 'onUpdate',
        },


    ]
    const tableOptions = {
        ...options,
        paging: false,
        search: false,
        toolbar: false,
        // rowStyle: { height: 42 },
    };


    return (
        <div>

            <MaterialTable
                style={{
                    boxShadow: "0px 1px 3px #00000033",
                    marginTop: "10px",
                    paddingBottom: "48px"
                }}
                icons={tableIcons}
                classes
                title={""}
                columns={column}
                data={data || []}
                isLoading={loading}
                options={tableOptions}
                editable={!disabled ? {

                    isEditHidden: rowData => !rowData?.applicable,
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            handleUpdate(newData)
                            resolve();

                        }),

                } : ""}
            />

            <div style={{
                position: "absolute",
                marginTop: "-32px",
                marginLeft: "19px"
            }}>
                <i>* If ‘Capture Later’ is selected, the User will be prompted at final certification to capture the applicable In-Service Life value</i>

            </div>






        </div>
    )
}
