import { Box, Checkbox, Divider, FormControlLabel, Grid, Dialog, DialogContent, DialogTitle, Typography, makeStyles, MenuItem } from '@material-ui/core';

import MROTextField from 'components/form_components/TextField';
import DataService from 'lib/services/api';
import React, { useEffect, useState, useCallback, useReducer, useContext } from "react";
import { Autocomplete } from '@material-ui/lab'
import PositiveSwitch from 'components/form_components/switch'
import NumericIncrementForWorkOrderLae from './FieldComponents/NumericIncrementForWorkOrderLae';
import NumericIncrementForWorkOrderNonLae from './FieldComponents/NumericIncrementForWorkOrderNonLae'
import MROButton from 'components/buttons';
import tableReducer, { INITIAL_TABLE_STATE } from 'reducers/table_reducer';
import { ToastMessageContext } from 'lib/contexts/message_context';
import { WorkOrderServices } from 'lib/services/api/operaitons/lineMaintenance/workOrder/workOrder';



const useStyles = makeStyles((theme) => ({
    dialog: {
        "& .MuiDialog-paperWidthSM": {
            boxShadow: "0px 3px 6px #00000029",
            // position: "absolute",
            // left: "calc(50% - 250px)",
            // width: '500px',
            height: '238px',
            borderRadius: '5px'
        }
    },
    dialogTitle: {
        color: theme.palette.primary.main,
        fontSize: '18px'
    }

}))


function UpdateTechLog(props) {
    const classes = useStyles()
    const { handleClose, currentSelection, workOrderId, task_type, typename } = props
    const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
    const [currentRow, setCurrentRow] = useState(null);
    const [ataChapters, setAtaChapters] = useState([]);
    const [errors, setErrors] = useState({});
    const [show, setShow] = useState(false)
    const [showLae, setShowLae] = useState(false)
    const [state, setState] = useState({
        part_on: '',
        ata_chapter: '',
        description: '',
        // man_hours: '',
        lae_hrs: '',
        non_lae_hrs: '',

    });
    const message = useContext(ToastMessageContext)
    console.log("currentSelection", currentSelection)
    useEffect(() => {
        if (currentSelection !== undefined) {
            setState((ps) => ({ ...ps, lae_hrs: currentSelection?.lae_hrs }))
            setState((ps) => ({ ...ps, non_lae_hrs: currentSelection?.non_lae_hrs }))
        }
    }, [currentSelection])

    const getAtaChapters = useCallback(
        () => {
            let params = {

            }
            DataService.getData(params, 'listingATAChapter').then(res => {
                setAtaChapters(res.data.map(d => ({
                    id: d.ata_chapter_id,
                    name: d.chapter_number
                })))

            }).catch(err => {

            })
        },
        [],
    )

    useEffect(() => {
        getAtaChapters()
    }, [])

    const handleSubmit = (e) => {
        const formData = new FormData(e.target)
        formData.append('id', workOrderId)
        formData.append('task_type', task_type)
        formData.append("task_id", currentSelection?.task_id)
        formData.append('man_hours', Number(state.lae_hrs) + Number(state.non_lae_hrs))

        let valid = true;
        let error = {};
        if (formData.get('ata_chapter_id') === '') {
            error['ata_chapter_id'] = {
                valid: false
            }
            valid = false;
        } if (formData.get('task_tlp') === '') {
            error['task_tlp'] = {
                valid: false
            }
            valid = false;
        } if (formData.get('description') === '') {
            error['description'] = {
                valid: false
            }
            valid = false;
        } if (formData.get('lae_hrs') === '') {
            error['lae_hrs'] = {
                valid: false
            }
            valid = false;
        } if (formData.get('non_lae_hrs') === '') {
            error['non_lae_hrs'] = {
                valid: false
            }
            valid = false;
        }

        if (!valid) {
            setErrors(error)
            return
        }

        WorkOrderServices.UpdateMaintTechLog(formData).then((res) => {

            message.showToastMessage({ message: "Updated successfully", variant: "success" });
            handleClose()
        }).catch((res) => {
            message.showToastMessage({ message: res.errors ? res.errors.join(', ') : "Something went wrong. Try again", variant: "error" });
            handleClose()
        })


    }





    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={true}
                onClose={handleClose}
                className={classes.dialog}
                aria-labelledby="tech-log-main">

                <DialogTitle ><Typography variant='subtitle2' className={classes.dialogTitle}> Edit task </Typography> </DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit} noValidate>
                        <Grid container direction={'row'} justify="space-around" style={{ marginBottom: 50 }}>
                            <Grid item xs={12}>
                                <Box height="100%" width="100%" px={0}>
                                    <MROTextField
                                        name="task_tlp"
                                        error={errors.task_tlp && !errors?.task_tlp?.valid}
                                        label="Task/TLP # *"
                                        InputLabelProps={{ shrink: true }}
                                        defaultValue={currentSelection?.task_tlp}
                                        onChange={() =>
                                            setErrors({ ...errors, task_tlp: false })
                                        }
                                    />


                                    <MROTextField
                                        name={"ata_chapter_id"}
                                        style={{ backgroundColor: '#fff' }}
                                        variant="outlined"
                                        label="ATA Chapter *"
                                        color={"primary"}
                                        select
                                        defaultValue={currentSelection?.ata_chapter_id}
                                        placeholder={"All"}
                                        InputLabelProps={{ shrink: true }}
                                        error={errors.ata_chapter_id && !errors?.ata_chapter_id?.valid}
                                        helperText={errors.ata ? "*please select a ATA Chapter" : null}
                                        onChange={() =>
                                            setErrors({ ...errors, ata_chapter_id: false })
                                        }
                                    >
                                        {ataChapters.map((item) =>
                                            <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                        )}
                                    </MROTextField>



                                    <MROTextField
                                        name="description"
                                        label="Description *"
                                        defaultValue={currentSelection?.description}
                                        error={errors.description && !errors?.description?.valid}
                                        onChange={() =>
                                            setErrors({ ...errors, description: false })
                                        }
                                        InputLabelProps={{ shrink: true }} />
                                    {/* <div>
                                        <FormControlLabel
                                            control={
                                                <PositiveSwitch
                                                    checked={showLae}
                                                    onChange={() => setShowLae(!showLae)}
                                                // name="show_not_approved_items"
                                                />
                                            }
                                            label={<Typography style={{ fontSize: "14px" }}>{`Item / Man-Hrs transferred to ${typename}?`}</Typography>}
                                            labelPlacement="start"
                                        // name={"show_not_ready_items"}
                                        />
                                    </div> */}

                                    <NumericIncrementForWorkOrderLae
                                        name="lae_hrs"
                                        label="LAE Man-Hours *"
                                        error={errors.lae_hrs && !errors?.lae_hrs?.valid}
                                        value={state.lae_hrs}
                                        noIncreament
                                        state={state}
                                        setState={setState}
                                        inputProps={{ min: 0, step: 0.5 }}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={() =>
                                            setErrors({ ...errors, lae_hrs: false })
                                        }
                                    />


                                    <div>
                                        <FormControlLabel
                                            control={
                                                <PositiveSwitch
                                                    checked={show}
                                                    onChange={() => setShow(!show)}
                                                // name="show_not_approved_items"
                                                />
                                            }
                                            label={<Typography style={{ fontSize: "14px" }}>{"Add Mechanic / Technician (non LAE)"}</Typography>}
                                            labelPlacement="start"
                                        // name={"show_not_ready_items"}
                                        />
                                    </div>
                                    {show &&
                                        <NumericIncrementForWorkOrderNonLae
                                            name="non_lae_hrs"
                                            label="Man-Hours (non LAE) *"
                                            error={errors.non_lae_hrs && !errors?.non_lae_hrs?.valid}
                                            value={state.non_lae_hrs}
                                            noIncreament
                                            state={state}
                                            setState={setState}
                                            inputProps={{ min: 0, step: 0.5 }}
                                            InputLabelProps={{ shrink: true }}
                                            onChange={() =>
                                                setErrors({ ...errors, non_lae_hrs: false })
                                            }
                                        />}

                                    <Box mt={2} display="flex" justifyContent="flex-end">


                                        <MROButton variant="contained" onClick={handleClose}>Cancel</MROButton>
                                        &nbsp;&nbsp;
                                        <MROButton variant="contained" color="primary"
                                            type="submit"
                                        >Save</MROButton>
                                    </Box>

                                </Box>

                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default React.memo(UpdateTechLog)