import React, { useEffect } from 'react';
import withDefaultLayout from "../../Layout";
import PageNotFound from "../../../components/universal/page_not_found";

export const requireAuth = (allowedRoles) => (ComposedComponent) => {

    function Authentication(props) {
        const queryString = window.location.search;

        useEffect(() => {
            // alert("Auth" + queryString)
            const user = JSON.parse(sessionStorage.getItem('user'));
            const userRole = user ? user.role : null;
            if (!userRole) {
                queryString == '' && props.history.push('/auth/signin');
            }
            //eslint-disable-next-line react-hooks/exhaustive-deps
        });
        const user = JSON.parse(sessionStorage.getItem('user'));
        const userRole = user ? user.role : "";
        if (queryString == '') {
            // ? commented for now will need to discuss
            // !Info allowedRoles.includes(userRole)
            if ((userRole)) {
                const ComponentWithLayout = withDefaultLayout(ComposedComponent);
                return <ComponentWithLayout {...props} />
            } else {
                return <PageNotFound />;
            }
        } else {
            const ComponentWithLayout = withDefaultLayout(ComposedComponent);
            return <ComponentWithLayout {...props} />
        }
    }

    return (Authentication);
};
