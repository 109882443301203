import React, { useEffect } from 'react'
import { Dialog, DialogContent, makeStyles, createStyles, Typography, Grid, Paper, Box, CircularProgress, DialogTitle, ButtonGroup } from '@material-ui/core'
import { useState, useContext } from 'react';
import MROButton from 'components/buttons';
import { LineMaintainenceServices } from 'lib/services/api/dashboard/line_maintenance';
import { ToastMessageContext } from 'lib/contexts/message_context';
import WarningIcon from '@material-ui/icons/Warning';
import MaterialTable from 'material-table';
import tableIcons, { options } from 'components/universal/table_attributes';
import DateTimeField from 'components/form_components/LMEventsDateTimeField';
import { formatDateWithHrMin } from 'lib/utils/common_utils';
import moment from 'moment';


const useStyles = makeStyles((theme) =>
    createStyles({
        modal: {
            "& .MuiDialog-paperWidthSm": {
                minWidth: "700px",
                // height: "350px"
            },
        },

    })
);

function CancelEvent(props) {


    const classes = useStyles()
    const { handleClose, currentSelection } = props
    const [loading, setLoading] = useState(false)
    const message = useContext(ToastMessageContext)
    const [state, setState] = useState('')
    const [data, setData] = useState([])
    const [errors, setErrors] = useState({});
    const [time, setTime] = useState(null)
    const [hours, setHours] = useState(null)
    const [chargeable, setChargeable] = useState(false)
    function container() {
        // Use the fullscreen element if in fullscreen mode, otherwise just the document's body
        return document.fullscreenElement ?? document.body;
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)

        let valid = true;
        let error = {};


        if (state === "yes") {
            const date = formData.get("notice_datetime")

            if (!date || date === "") {

                valid = false;
                error = {
                    ...error,
                    notice_datetime: "*Please select Notice date"
                }
            }
            if (hours < 0) {
                error = {
                    ...error,
                    notice_datetime: "*Cancellation notice cannot be after planned arrival"
                }

            }

        }



        if (!valid) {
            setErrors(error);
            return;
        }
        setLoading(true)

        formData.append("event_id", currentSelection.id)

        formData.append("cancellation_notice", hours ? hours : 0)
        formData.append("cancellation_chargeable", chargeable)

        LineMaintainenceServices.updateCancelEvent(formData).then((res) => {
            if (res.success) {
                message.showToastMessage({
                    message: "Event cancelled successfully !",
                    variant: "success",
                });
                handleClose()
            }
        }).catch((res) => {
            handleClose()
            message.showToastMessage({
                message: res.errors ? res.errors : "Something went wrong. Try again!",
                variant: "error",
            });
        }).finally(() => {
            setLoading(false)
        })

    }
    const tableOptions = {
        ...options,
        paging: false,
        search: false,
        header: false,
        toolbar: false,
        rowStyle: {
            height: '42px'
        }
    };
    const column = [

        {
            title: "title",
            field: "title",
            width: '60%',
            headerStyle: {
                textAlign: "left",
                paddingLeft: "15px",
                width: '60%',
            },
            cellStyle: {
                textAlign: "left",
                paddingLeft: "15px",
                width: '60%',
            },
        },
        {
            title: "Hours",
            field: "value1",
            render: (rowData) => rowData?.title === "Chargeable?" ? <span style={{ color: chargeable ? "#4FC605" : "#FF0202" }}>{rowData?.value1}</span> : rowData?.value1

        },


    ];
    useEffect(() => {
        setData(
            [{
                title: "Calculated Notice Period (Hours):",
                value1: hours
            },
            {
                title: "Customer Contracted Notice Period (Hours):",
                value1: currentSelection?.cancellation_notice || 0

            },
            {
                title: "Chargeable?",
                value1: chargeable ? "Yes" : "No"
            },
            ]
        )
    }, [currentSelection, hours, chargeable])

    useEffect(() => {
        if (time !== undefined && time !== null) {
            let error = {};
            const format = "DD/MM/YYYY HH:mm:ss";
            const actArrivalDateTime = moment(currentSelection?.arr_datetime, "YYYY-MM-DD HH:mm:ss").format(format)
            const notice_time = moment(time, format)


            const arrival = moment(actArrivalDateTime, format)



            const duration = moment.duration(arrival.diff(notice_time))
            if (duration >= 0) {
                let final = parseFloat(duration.asHours()).toFixed(2)
                let value = final >= (currentSelection?.cancellation_notice ? currentSelection?.cancellation_notice : 0) ? false : true
                setChargeable(value)
                setHours(final)

            }
            else {
                let final = parseFloat(duration.asHours()).toFixed(2)
                let value = final >= (currentSelection?.cancellation_notice ? currentSelection?.cancellation_notice : 0) ? false : true
                setChargeable(value)
                setHours(final)
                error = {
                    ...error,
                    notice_datetime: "*Cancellation notice cannot be after planned arrival"
                }

                setErrors(error)
            }




        }
    }, [time])

    return (
        <div>   <Dialog
            open={true}
            onClose={handleClose}
            aria-labelledby="allocate engineers"
            aria-describedby="allocate engineers"
            className={classes.modal}
            container={container()}
        >
            <DialogTitle>
                <Typography color='primary' variant='subtitle1' style={{ fontSize: '18px', textAlign: 'center' }}>Cancel Event</Typography>
            </DialogTitle>

            <Box display="flex" width="100%" mb={2} justifyContent={"center"}>
                <WarningIcon
                    style={{ color: "rgb(255, 179, 0)", marginRight: 16 }}
                />
                <Typography variant="" style={{ fontSize: "15px" }}>
                    Cancellation Notice is important and drives cancellation fees!
                </Typography>
            </Box>



            <DialogContent style={{ marginTop: "10px" }}>
                <form onSubmit={handleSubmit}>

                    <Grid container >
                        <Grid item xs={4} style={{ padding: '8px 0px 0px 6px' }}>
                            <Typography>Notice Provided? *</Typography>

                        </Grid>
                        <Grid item xs={4} style={{ paddingRight: "22px", marginBottom: "10px" }}>
                            <ButtonGroup color="primary" style={{ width: "55%" }}>
                                <MROButton
                                    variant={state === "no" ? "contained" : "outlined"}
                                    onClick={() => {
                                        setState("no")
                                        setHours(0)
                                        setChargeable(true)
                                    }}
                                    style={{
                                        width: "125px",
                                        color: state === "no" ? "white" : undefined,
                                    }}

                                >
                                    NO
                                </MROButton>
                                <MROButton
                                    variant={state === "yes" ? "contained" : "outlined"}
                                    onClick={() => setState("yes")}
                                    style={{
                                        width: "125px",
                                        color: state === "yes" ? "white" : undefined,
                                    }}
                                >
                                    Yes
                                </MROButton>
                            </ButtonGroup>
                        </Grid>

                    </Grid>
                    {state === "yes" && <>
                        <Grid item xs={6} style={{ margin: "10px 0 10px 0px" }}>
                            <DateTimeField
                                label={"Notice Provided At *"}
                                name={'notice_datetime'}
                                // disabled={!props?.permission?.write}
                                InputLabelProps={{ shrink: true }}
                                errors={errors.notice_datetime}
                                defaultValue={currentSelection?.notice_datetime}
                                value={time}
                                helperText={errors.notice_datetime ? errors.notice_datetime : null}
                                style={
                                    { opacity: 0.55, cursor: "not-allowed !important" }}
                                onChange={(e) => {
                                    setErrors((e) => ({ ...e, notice_datetime: false }));
                                    setTime(e)
                                }}
                                maxDate={moment(currentSelection?.arr_datetime, "YYYY-MM-DD HH:mm:ss")}
                            />
                        </Grid>
                        <Grid item xs={12} container style={{ paddingLeft: "5px" }}>


                            <Grid>
                                <i> Planned arrival date / time: </i> </Grid>
                            <Grid alignItems='center'> <i> <Typography color="primary" style={{ paddingLeft: "10px", fontSize: "14px" }}>{formatDateWithHrMin(currentSelection?.arr_datetime)}</Typography> </i></Grid>

                        </Grid>
                    </>
                    }
                    <Grid item xs={6} style={{ marginTop: "10px", }}>
                        <MaterialTable
                            style={{
                                boxShadow: "0px 1px 3px #00000033",
                                borderRadius: "4px",
                                width: "650px",
                                // minWidth: "500px"
                            }}

                            options={tableOptions}
                            title=""
                            icons={tableIcons}
                            columns={column}
                            data={data || []}
                            isLoading={loading}


                        />
                    </Grid>



                    <Grid item xs={12} container direction='row' style={{ justifyContent: 'center', marginTop: '40px', marginBottom: '30px' }} >

                        <MROButton type='button' variant='contained' style={{ marginRight: '23px' }} onClick={() => {
                            handleClose()
                        }}>

                            Cancel
                        </MROButton>
                        <MROButton variant='contained' color='primary' loading={loading}
                            type="submit"
                        >

                            Yes
                        </MROButton>


                    </Grid>

                </form>
            </DialogContent>
        </Dialog>
        </div>
    )
}

export default CancelEvent