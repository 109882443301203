import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import MROButton from "components/buttons";

const useStyles = makeStyles(() => ({
  modal: {
    "& .MuiDialog-paperWidthMd": {
      width: "25%",
    },
  },
  title: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  warning: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "16px",
    marginBottom: "20px",
  },
  actionButtons: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

function DeleteDailog(props) {
  const classes = useStyles();
  const { handleClose, open, deleteSelection, setLinkedKitContentsTableData, loading, handleConfirm, title, description } =
    props;

  const handleDelete = () => {
    if(typeof handleConfirm === "function") {
      handleConfirm()
      return 
    }
    if (deleteSelection.isLocallyAdded) {
      setLinkedKitContentsTableData((prevState) =>
        prevState.filter(
          (item) => item.alternate_part_id !== deleteSelection.alternate_part_id
        )
      );
    }
  };

  return (
    <div>
      <Dialog
        maxWidth={"md"}
        fullWidth={true}
        open={open}
        className={classes.modal}
        onClose={handleClose}
      >
        <DialogTitle>
          <Typography color="primary" className={classes.title}>
            {title ?? <>Delete Item</>}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography className={classes.warning}>
           {description ?? <>This cannot be undone. Are you sure?</>}
          </Typography>
          <Grid className={classes.actionButtons} spacing={1} container>
            <Grid item>
              <MROButton
                type="button"
                variant={"contained"}
                onClick={handleClose}
              >
                Cancel
              </MROButton>
            </Grid>
            <Grid item>
              <MROButton
                loading={loading}
                onClick={handleDelete}
                type="submit"
                variant={"contained"}
                color="primary"
              >
                Yes
              </MROButton>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default DeleteDailog;
