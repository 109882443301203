import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function DaysPaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
    // console.log("Days==", theme)

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    return (
        <div className={classes.root}>
            {/* <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}

                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton> */}
            <IconButton onClick={handleBackButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page === 0}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            {/* <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page === 0}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton> */}
        </div>
    );
}






const useStyles2 = makeStyles({
    table: {
        minWidth: 500,
    },
});

export default function CustomDaysPaginationActionsTable({ rowPerPageOptions, count, label, page, setPage, rowsPerPage, setRowsPerPage, defaultDate }) {
    const classes = useStyles2();




    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {

        setRowsPerPage(parseInt(event.target.value, 10));

        if (defaultDate && event.target.value === 7) {
            setPage(4)
        }
        else if (defaultDate && event.target.value === 14) {
            setPage(2)
        }
        else if (defaultDate && event.target.value === 31) {
            setPage(1)
        }
        else {
            setPage(0)
        }

    };

    return (


        <TablePagination
            component="div"
            rowsPerPageOptions={rowPerPageOptions}
            colSpan={3}
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={DaysPaginationActions}
            labelRowsPerPage={label}
        />



    );
}
