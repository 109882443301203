import React, { useEffect, useContext, useState } from 'react'
import { Dialog, DialogContent, DialogTitle, MenuItem, Grid, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import MROButton from 'components/buttons';
import MROTextField from 'components/form_components/TextField';
import { getOrganisationId } from 'lib/utils/common_utils';
import { ToastMessageContext } from 'lib/contexts/message_context';
import QuantityCounter from 'components/quantity_counter';
import { Autocomplete } from '@material-ui/lab';
import WarningIcon from "@material-ui/icons/Warning";
import tableIcons, { options } from "components/universal/table_attributes";
import MaterialTable from 'material-table';
import ShiftServices from 'lib/services/api/planning/Shifts/shift';
import GreenTickIcon from 'assets/green_filled_tick.png'






const useStyles = makeStyles(theme => ({
    actionButtons: {
        paddingTop: '20px',
        paddingBottom: '33px',
        textAlign: 'right',
        '& button:not(:last-child)': {
            marginRight: '20px'
        }
    },
    modal: {
        '& .MuiDialog-paperWidthMd': {
            maxWidth: '650px',
        }
    },
    title: {
        fontSize: '18px',
        '& h2': {
            color: theme.palette.primary.main,
            textAlign: 'center'
        }
    },
    formLabel: {

        '& span:last-child': {
            fontSize: '14px',
        }
    }

}));

export default function ApproveOverTime(props) {
    const classes = useStyles()
    const { handleClose, approvedData, id, getListing, onClose, overtimeDetails } = props
    const [qty, setQty] = useState();
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false)
    const message = useContext(ToastMessageContext)




    const tableOptions = {
        ...options,
        paging: false,
        search: false,
        header: false,
        toolbar: false,
        rowStyle: {
            height: '42px'
        }
    };


    const columns = [


        {
            title: "Title",
            field: "title",
            cellStyle: {
                textAlign: 'left',
                paddingLeft: '15px'
            },
            headerStyle: {
                textAlign: 'left',
                paddingLeft: '15px'
            }



        },

        {
            title: "va1ue",
            field: "value",
            cellStyle: {
                textAlign: 'right',
                paddingRight: '15px'
            },
            headerStyle: {
                textAlign: 'left',
                paddingRight: '15px'
            }
        },


    ];
    const tableData = [
        {
            "title": "Overtime Start",
            "value": approvedData?.start_datetime
        }, {
            "title": "Overtime End",
            "value": approvedData?.end_datetime
        }, {
            "title": "Shift Hours",
            "value": approvedData?.hours
        },


    ]

    const handleSubmit = async (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        let valid = true;
        let error = {};
        for (let pair of formData.entries()) {
            const value = pair[1];
            const key = pair[0];
            // console.table("formdata=====", key, value)
            if (!value) {
                error[key] = true;
                valid = false;
            }
        }

        if (!valid) {
            setErrors(error);
            return;
        }

        formData.append("overtime_request_id", id)
        formData.append("action", "approve")
        formData.append("timezone_id", overtimeDetails?.timezone_id ? overtimeDetails?.timezone_id : "")

        setLoading(true)
        await ShiftServices.postOverTimeActionRequest(formData).then((res) => {

            message.showToastMessage({
                message: "Overtime Approved Successfully",
                variant: "success",
            });

            onClose()
            getListing()


        }).catch(() => {
            message.showToastMessage({
                message: "Something went wrong. Try again!",
                variant: "error",
            });
            handleClose()
            getListing()
        }).finally(() => {
            setLoading(false)
        })






    }


    return (
        <div>
            <Dialog
                maxWidth={"md"}
                fullWidth={true}
                open={true}
                className={classes.modal}
                onClose={handleClose}
                aria-labelledby="indirect-jobs"
            >
                <DialogTitle variant="subtitle2" color="secondary"
                    className={classes.title}><span style={{ fontSize: '16px' }}><img src={GreenTickIcon} height={'20px'} style={{ marginRight: '5px' }} /> Approve Overtime</span>
                </DialogTitle>

                <Typography style={{ textAlign: 'center' }}>Check and confirm the overtime dates and hours</Typography>
                <br />

                <DialogContent className={classes.card}>
                    <form onSubmit={handleSubmit} >
                        <>

                            <MaterialTable
                                style={{ boxShadow: "0px 1px 3px #00000033", paddingRight: "0px" }}
                                icons={tableIcons}
                                title={""}
                                // isLoading={loading}
                                data={tableData || []}
                                columns={columns}
                                options={tableOptions}


                            />





                            <div className={classes.actionButtons}>
                                <MROButton
                                    //   disabled={loading}
                                    type="button"
                                    variant={"contained"}
                                    onClick={handleClose}
                                >
                                    Cancel
                                </MROButton>
                                <MROButton
                                    loading={loading}
                                    type="submit"
                                    variant={"contained"}
                                    color="primary"

                                >
                                    Complete
                                </MROButton>

                            </div>
                        </>
                    </form>
                </DialogContent>
            </Dialog>

        </div>
    )
}
