import { AppBar, Toolbar, makeStyles, IconButton, Typography, Grid, MenuItem, FormControlLabel, ButtonGroup, Box, CircularProgress, Divider, Button } from '@material-ui/core'
import MROFullScreenRightDrawer from 'components/fullwidth_dialog'
import React, { useReducer, useState, useEffect, useContext } from 'react'
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import MROButton from 'components/buttons';
import { Delete, Edit as EditIcon } from "@material-ui/icons";
import { ICON_COLOUR } from 'lib/constants/style_constants';
import MaterialTable from "material-table";
import tableIcons, { options } from 'components/universal/table_attributes';
import { StationDashboardServices } from 'lib/services/api/dashboard/station_compliance';
import { getOrganisationId } from 'lib/utils/common_utils';
import { ToastMessageContext } from 'lib/contexts/message_context';
import WarningIcon from "@material-ui/icons/Warning";
import { downloadFileFromUrl } from "lib/utils/helperFunctions";
import GetAppIcon from "@material-ui/icons/GetApp";
const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: "#fff",
        color: "#000000CC",
        boxShadow: "0px 3px 6px #0000001A",
    },
    backButton: {
        marginRight: theme.spacing(2),
    },
    wrapper: {
        margin: "3%",
        // overflowX: "hidden !important"
    },
    title: {
        fontSize: '15px',
        paddingBottom: '17px',
        paddingLeft: '6px'
    },

    toggle: {
        width: '78px',
        height: '31px'


    },

    actionButtons: {
        marginTop: '15px',
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
    },
    partKey: {
        fontSize: "14px",
        // padding: "5px",
    },
    partValue: {
        fontSize: "14px",
        color: "#4D4F5C",
        padding: "5px",
        display: "flex",
        alignItems: "center",
    },

}));

const GetInfoCard = (props) => {
    const classes = useStyles();
    return (
        <Box display="flex" pl={'8px'}>
            <Box flex={50} >
                <Typography style={{ padding: "5px" }} className={classes.partKey} color="primary">
                    {props?.keyName}
                </Typography>
            </Box>
            <Box flex={50} className={classes.partValue}>
                {props?.value ?? "-"}
            </Box>
        </Box>
    );
};

export default function StorageInfo(props) {

    const classes = useStyles()
    const { handleClose, currentSelection } = props
    const message = useContext(ToastMessageContext)
    const [busy, setBusy] = useState(null)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [totalCount, setTotalCount] = useState(0)

    const tableOptions = {
        ...options,
        page: page,
        total: totalCount,
        pageSize: pageSize,
        search: false,
        sort: false,
        toolbar: false,
        rowStyle: {
            height: '42px'
        }
    };

    const downloadCsvHandler = async () => {
        setBusy("downloading");
        try {
            const params = {
                csv: true,
                organisation_id: getOrganisationId(),
                id: currentSelection?.id
            };
            const { file: fileUrl } = await StationDashboardServices.getStorageEventConditionListing(
                params
            );

            // for downloading file automatically
            downloadFileFromUrl(fileUrl);
            message.showToastMessage({
                message: "File downloaded successfully",
                variant: "success",
            });
        } catch (error) {
            message.showToastMessage({
                message: "something went wrong!",
                variant: "error",
            });
        } finally {
            setBusy(null);
        }
    };
    const getStoragetData = async () => {
        setLoading(true)
        const params = {
            organisation_id: getOrganisationId(),
            page: page + 1,
            count_per_page: pageSize,
            id: currentSelection?.id

        }

        await StationDashboardServices.getStorageEventConditionListing(params).then((response) => {
            setData(response.data)
            setTotalCount(response.total_count)
        }).catch((err) => {
            message.showToastMessage({
                message: "Something went wrong. Try again!",
                variant: "error",
            });
        }).finally(() => {
            setLoading(false)
        })
    }
    useEffect(() => {
        getStoragetData()
    }, [page, pageSize])





    const columns = [
        {
            title: "Part #",
            field: "part",
            headerStyle: {
                textAlign: 'left',
                paddingLeft: '15px'
            },
            cellStyle: {
                textAlign: 'left',
                paddingLeft: '15px'
            }


        }, {
            title: "Description",
            field: "description",
            headerStyle: {
                textAlign: 'left'
            },
            cellStyle: {
                textAlign: 'left'
            }


        }, {
            title: "Batch #",
            field: "batch_number",
            headerStyle: {
                textAlign: 'left'
            },
            cellStyle: {
                textAlign: 'left'
            }


        }, {
            title: "Owner",
            field: "owner",
            headerStyle: {
                textAlign: 'left'
            },
            cellStyle: {
                textAlign: 'left'
            }


        }, {
            title: "Location",
            field: "location",
            headerStyle: {
                textAlign: 'left'
            },
            cellStyle: {
                textAlign: 'left'
            }


        }, {
            title: "QTY",
            field: "quantity"

        },


    ]




    return (
        <div>
            <MROFullScreenRightDrawer open={true}>

                <AppBar position="static" className={classes.appBar} elevation={0}>
                    <Toolbar>

                        <Grid container xs={12}>
                            <Grid >
                                <IconButton
                                    onClick={() => {
                                        handleClose()
                                        // getListing()
                                    }}
                                    edge="start"
                                    className={classes.backButton}
                                    color="inherit"
                                    aria-label="back"
                                >
                                    <BackIcon />
                                </IconButton>
                            </Grid>
                            <Grid item xs={10} >
                                <Typography variant="subtitle2" style={{ fontSize: "16px", paddingTop: '5px' }}>
                                    Storage Condition Event
                                </Typography>
                            </Grid>

                        </Grid>
                    </Toolbar>
                </AppBar>
                <div className={classes.wrapper}>

                    <Typography variant='subtitle2' color='primary' className={classes.title}>Event Details</Typography>

                    {/* Details info */}
                    <div style={{ width: "100%", marginBottom: "20px" }}>
                        <Box
                            display="flex"
                            alignItems="center"
                            // justifyContent={(loading || _.isEmpty(currentSelection)) && "center"}
                            bgcolor="#F5F5F5"
                            className={classes.partCard}
                            style={{ borderRadius: '5px' }}
                        >
                            <>
                                <Box flexGrow={1}>
                                    <div style={{ width: "100%" }}>
                                        <GetInfoCard keyName={"Event Date"} value={currentSelection?.event_date || "-"} />
                                        <GetInfoCard keyName={"Recorded Value"} value={currentSelection?.value || "-"} />

                                    </div>
                                </Box>
                                <Box flexGrow={1}>
                                    <div style={{ width: "100%" }}>
                                        <GetInfoCard
                                            keyName={"Base"}
                                            value={currentSelection?.base_station || "-"}
                                        />
                                        <GetInfoCard
                                            keyName={"Min Limit"}
                                            value={currentSelection?.min_limit || "-"}
                                        />

                                    </div>
                                </Box>

                                <Box flexGrow={1} alignSelf="flex-start">
                                    <div style={{ width: "100%" }}>
                                        <GetInfoCard
                                            keyName={"Type"}
                                            value={currentSelection?.type || "-"}
                                        />
                                        <GetInfoCard
                                            keyName={"Max Limit"}
                                            value={currentSelection?.max_limit || "-"}
                                        />


                                    </div>
                                </Box>


                            </>
                        </Box>

                        <br />
                        <Typography variant='subtitle2' color='primary' className={classes.title}>Affected Parts</Typography>
                        <MaterialTable
                            style={{ boxShadow: '0px 1px 3px #00000033', paddingRight: '0px' }}
                            icons={tableIcons}
                            title={''}
                            options={tableOptions}
                            data={data || []}
                            columns={columns}
                            isLoading={loading}
                            onChangePage={(page) => {
                                setPage(page);
                            }}
                            onChangeRowsPerPage={(pageSize) => {
                                setPageSize(pageSize);
                            }}

                            totalCount={totalCount}
                            page={page}
                        />


                    </div>
                    <div
                        style={{}}>
                        <span> <WarningIcon
                            style={{
                                color: "#FFB300",
                                marginRight: "10px",
                                fontSize: 18,
                            }}
                        /></span><span style={{
                            fontSize: '14px', position: "absolute",
                            marginTop: '0px',
                        }}> <i>Affected parts shown are parts received before the condition event that remain in stock currently</i></span>
                    </div >
                    <Box mt="23px">
                        <MROButton
                            startIcon={<GetAppIcon />}
                            variant="outlined"
                            color="secondary"
                            onClick={downloadCsvHandler}
                            disabled={busy === "downloading"}
                        >
                            Download CSV
                        </MROButton>
                    </Box>

                </div>

            </MROFullScreenRightDrawer>







        </div>
    )
}
