import { Dialog, withStyles, makeStyles, Grid, DialogTitle, Paper, Typography, Button, Popover, Fade, MenuItem } from '@material-ui/core';
import MROTextField from 'components/form_components/TextField';
import React, { useContext, useEffect, useReducer, useRef, useState } from 'react'
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng, getDetails
} from "use-places-autocomplete";
import { Autocomplete } from "@material-ui/lab";
import MROButton from 'components/buttons';
import { ToastMessageContext } from 'lib/contexts/message_context';



let cachedScripts = []




function useScript(src) {
    // Keeping track of script loaded and error state

    const [state, setState] = useState({
        loaded: false,
        error: false
    });

    useEffect(
        () => {
            // If cachedScripts array already includes src that means another instance ...
            // ... of this hook already loaded this script, so no need to load again.
            if (cachedScripts.includes(src)) {
                setState({
                    loaded: true,

                    error: false
                });
            } else {
                cachedScripts.push(src);
                // Create script
                let script = document.createElement("script");
                script.src = src;
                script.async = true;
                // Script event listener callbacks for load and error
                const onScriptLoad = () => {
                    setState({
                        loaded: true,
                        error: false
                    });
                };

                const onScriptError = () => {
                    // Remove from cachedScripts we can try loading again
                    const index = cachedScripts.indexOf(src);
                    if (index >= 0) cachedScripts.splice(index, 1);
                    script.remove();
                    setState({
                        loaded: true,
                        error: true
                    });
                };
                script.addEventListener("load", onScriptLoad);
                script.addEventListener("error", onScriptError);
                // Add script to document body
                document.body.appendChild(script);
                // Remove event listeners on cleanup
                return () => {
                    script.removeEventListener("load", onScriptLoad);
                    script.removeEventListener("error", onScriptError);
                };
            }
        },
        [src] // Only re-run effect if script src changes
    );
    return [state.loaded, state.error];
}


export default function Map(props) {
    const { handleClose, location, setLocation, setDialogValue, dialogValue, setCompleteAddress, setFullAddress, } = props
    const classes = useStyles()
    const [mapDetails, setMapDetails] = useState({ name: '', id: '', placeAddress: '' })
    const [mapLocDetails, setMapLocDetails] = useState({ latitude: "", longitude: "", address: "" })
    const [userCurrentLocation, setUserCurrentLocation] = useState({ latitude: "", longitude: "", })
    const message = useContext(ToastMessageContext);
    const ref = useRef()
    const [action, setAction] = useState(null)
    const [allResults, setAllResults] = useState([])
    const [mouseX, setMouseX] = useState();
    const [mouseY, setMouseY] = useState();
    const [poperState, setPoperState] = useState(false)

    const handlepopupclose = () => {
        setAction("")
        setPoperState(false)
    }

    const [loaded, error] = useScript(
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyD8xz9Vdoalaxr8NJyaYAAZ8ZorxPQJ06g&libraries=places,drawing,geometry,marker"
    );

    useEffect(() => {
        if (dialogValue !== undefined || dialogValue !== null) {
            setMapDetails({ placeAddress: dialogValue })
        }
    }, [])
    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(success, errors)
        } else {
            console.log("Geolocation is not supported by this browser.")
        }

    }, [])
    function success(position) {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;

        setUserCurrentLocation({ latitude: latitude, longitude: longitude })

    }

    function errors() {
        console.log("Unable to retrieve your location");
    }
    // console.log("loc===", userCurrentLocation, location)
    const handleClick = (e) => {
        setAction("result");

        setPoperState(!poperState)
        setMouseX(e.clientX);
        setMouseY(e.clientY);
    };




    useEffect(() => {
        if (loaded) {

            const myLatlng = { lat: location?.latitude ? location.latitude : userCurrentLocation?.latitude ? userCurrentLocation?.latitude : 53.4806837, lng: location?.longitude ? location.longitude : userCurrentLocation?.longitude ? userCurrentLocation?.longitude : -2.2427183 };
            const map = new window.google.maps.Map(document.getElementById("map"), {
                center: myLatlng,
                zoom: 13,
                mapTypeId: "roadmap",
            });
            // Create the search box and link it to the UI element.

            const geocoder = new window.google.maps.Geocoder();
            let infoWindow = new window.google.maps.InfoWindow();
            const infowindowContent = document.getElementById("infowindow-content");
            //get place details

            const marker = new window.google.maps.Marker({
                map,
                position: myLatlng,
            });

            //click listner
            map.addListener("click", (event) => {

                if (event.placeId) {

                    geocoder.geocode({ placeId: event.placeId }).then(({ results }) => {

                        setAllResults(results)
                        getAddDetails(results[0].formatted_address)
                        map.setZoom(4);
                        map.setCenter(results[0].geometry.location);

                        const parameter = {
                            placeId: event.placeId,
                        }
                        // setTitle("")
                        getDetails(parameter)
                            .then((details) => {
                                setFullAddress([details])
                                setCompleteAddress(details.address_components)
                            })
                            .catch((error) => {
                                console.log("Error: ", error);
                            });
                        // setCompleteAddress(results[0].address_components)
                        const { lat, lng } = getLatLng(results[0]);
                        setMapLocDetails({ latitude: lat, longitude: lng, address: results[0].formatted_address })
                        setLocation({ latitude: lat, longitude: lng, address: results[0].formatted_address })
                        // Set the position of the marker using the place ID and location.
                        // @ts-ignore TODO This should be in @typings/googlemaps.

                        marker.setPlace({
                            placeId: event.placeId,
                            location: results[0].geometry.location,

                        });

                        marker.setVisible(true);
                        let name = event.name
                        let address = results[0].formatted_address


                        // infowindowContent.children["place-name"].textContent = event.name;
                        // infowindowContent.children["place-id"].textContent = event.placeId;
                        // infowindowContent.children["place-address"].textContent = results[0].formatted_address;
                        // infoWindow.close()
                        // infoWindow.setHeaderContent(name)
                        // infoWindow.setContent(address)
                        // infoWindow.open(map, marker);

                    })

                }
                else if (event.latLng.toJSON()) {


                    geocoder.geocode({ location: event.latLng.toJSON() }).then(({ results }) => {
                        setAllResults(results)
                        getAddDetails(results[0].formatted_address)
                        map.setZoom(4);
                        map.setCenter(results[0].geometry.location);

                        const parameter = {
                            placeId: results[0].placeId,
                        }
                        // setTitle("")
                        getDetails(parameter)
                            .then((details) => {
                                setFullAddress([details])
                                setCompleteAddress(details.address_components)
                            })
                            .catch((error) => {
                                console.log("Error: ", error);
                            });
                        // setCompleteAddress(results[0].address_components)
                        const { lat, lng } = getLatLng(results[0]);
                        setMapLocDetails({ latitude: lat, longitude: lng, address: results[0].formatted_address })
                        setLocation({ latitude: lat, longitude: lng, address: results[0].formatted_address })
                        marker.setPlace({
                            placeId: event.placeId,
                            location: results[0].geometry.location,

                        });

                        marker.setVisible(true);

                    })


                }
                else {
                    message.showToastMessage({
                        message: "No  places found",
                        variant: "error",
                    });
                    setMapDetails({ placeAddress: "" })
                }
            });



        }
    }, [loaded, location, userCurrentLocation]);
    function getAddDetails(results) {
        // console.log(results)
        setMapDetails({ placeAddress: results })

    }


    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,

    } = usePlacesAutocomplete({ callbackName: "maploc" });



    const handleGetLocationDetails = (value1) => {


        if (value1 !== undefined && value !== null && value1 !== null) {
            let description = value1.description
            setMapDetails({ placeAddress: description })
            getGeocode({ address: description }).then((results) => {
                const parameter = {
                    placeId: results[0].place_id,
                }
                console.log(results)
                setAllResults(results)
                // setTitle("")
                getDetails(parameter)
                    .then((details) => {
                        setFullAddress([details])
                        setCompleteAddress(details.address_components)
                    })
                    .catch((error) => {
                        console.log("Error: ", error);
                    });
                // setCompleteAddress(results[0].address_components)
                const { lat, lng } = getLatLng(results[0]);
                // console.log("📍 Coordinates: ", { lat, lng });
                setMapLocDetails({ latitude: lat, longitude: lng, address: description })
                setLocation({ latitude: lat, longitude: lng, address: description })

            });
        }
        else {
            setMapLocDetails({ latitude: "", longitude: "", address: "" })
        }
    }

    return (

        <Dialog
            maxWidth={"md"}
            open={true}
            className={classes.modal}
            onClose={handleClose}
            aria-labelledby="download"
            disableEnforceFocus
        >

            <DialogTitle>

                <Typography color='primary' variant="subtitle1" style={{ fontSize: '18px' }} >Search</Typography>
            </DialogTitle>
            <Grid container style={{ padding: "30px", marginTop: '-106px' }}>
                <Grid item xs={12} style={{ marginBottom: "-116px" }}>
                    <Grid item xs={12} md={6} ref={ref} style={{
                        position: "relative", top: '64px', zIndex: 1, left: "216px"
                    }}>
                        <Autocomplete
                            style={{ width: "" }}
                            getOptionSelected={(option, value) =>
                                option.id === value.id
                            }
                            value={value ? value : null}
                            onChange={(event, value) => {
                                console.log(value)
                                handleGetLocationDetails(value)
                            }}
                            id="tags-locations"
                            options={data}
                            loading={status}
                            name={"address"}
                            fullWidth
                            getOptionLabel={(option) => typeof option === "string" ? option : option.description}
                            onInputChange={(e, newValue) => {
                                setValue(newValue)

                            }}
                            renderInput={(params) => (
                                <MROTextField
                                    {...params}
                                    label={"Search"}
                                    style={{ backgroundColor: "#fff" }}
                                    variant="outlined"
                                    color={"primary"}
                                    InputLabelProps={{ shrink: true }}
                                />
                            )}
                        />
                    </Grid>




                    <div id="map" style={{ height: "800px" }} />
                    {/* <div id="infowindow-content">
                        <span id="place-name" class="title"></span><br />
                        <strong>Place ID</strong>: <span id="place-id"></span><br />
                        <span id="place-address"></span>
                    </div> */}



                </Grid>


                <Grid item xs={12}>
                    <Paper style={{
                        width: "91%", position: 'relative', top: '-21px', zIndex: 1, left: '4px', padding: '21px', borderRadius: '5px'
                    }}>
                        <Grid style={{ fontSize: '16px' }}>
                            {mapDetails?.placeAddress ? mapDetails?.placeAddress : "No places found"}
                            <span></span>
                        </Grid>
                        <div className={classes.actionButtons}>
                            <MROButton
                                //   disabled={loading}
                                type="button"
                                variant={"contained"}
                                onClick={handleClose}
                            >
                                Cancel
                            </MROButton>
                            <MROButton

                                type="button"
                                variant={"contained"}
                                color="primary"
                                onClick={() => {
                                    setDialogValue(mapDetails?.placeAddress)
                                    setLocation({ latitude: mapLocDetails.latitude, longitude: mapLocDetails.longitude, address: mapLocDetails.address })
                                    handleClose()
                                }}
                            >
                                Done
                            </MROButton>
                        </div>
                        {allResults.length > 0 ? <Grid item xs={12} style={{ paddingLeft: "152px", paddingTop: "15px" }}>
                            <Button style={{
                                borderRadius: "25px",
                                background: "#00000029",
                                paddingLeft: "22px",
                                paddingRight: "22px",
                                fontSize: "15px",
                                color: "black"
                            }}
                                onClick={handleClick}
                            >Tap to show {allResults.length} more results options</Button>
                        </Grid> : ""}



                    </Paper>
                </Grid>



            </Grid>

            {action && action === "result" && <Popover id='key' open={Boolean(poperState)}
                anchorReference={'anchorPosition'}
                onClose={handlepopupclose}
                anchorEl={poperState}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                anchorPosition={{ top: 600, left: mouseX }}
                PaperProps={{
                    marginLeft: '-11px',
                    marginTop: '-22px'
                }}
            >

                <Paper style={{ width: 'max-content', height: 'fit-content', padding: '10px' }}>
                    {allResults.map((item) => <MenuItem style={{ fontSize: '15px' }} onClick={() => {
                        setValue(item.formatted_address)
                        handleGetLocationDetails({ description: item.formatted_address })
                        handlepopupclose()
                    }}>{item.formatted_address}</MenuItem>)}


                </Paper>
            </Popover>}








        </Dialog >

    );
}





const useStyles = makeStyles((theme) => ({
    modal: {
        "& .MuiDialog-paperWidthMd": {
            width: "800px",
        }
    },
    actionButtons: {
        marginTop: '-22px',
        // paddingBottom: "5px",
        // paddingTop: '20px',
        textAlign: "right",
        "& button:not(:last-child)": {
            marginRight: "20px",
        },
    },
}));
