import React, { useContext, useEffect, useReducer, useState } from 'react';
import moment from 'moment';
import { Box, Button, FormControl, InputBase, MenuItem, Select, Typography } from '@material-ui/core';
import MaterialTable from 'material-table';
import tableReducer, { INITIAL_TABLE_STATE } from '../../../../reducers/table_reducer';
import { ToastMessageContext } from '../../../../lib/contexts/message_context';
import tableIcons, { options } from '../../../../components/universal/table_attributes';
import DataServiceAction from '../../../../actions/data_actions';
import TableAction from '../../../../actions/table_actions';
import { format24HrDateOnly, formatDate } from '../../../../lib/utils/common_utils';
import InStockClientServices from "../../../../lib/services/api/stores/tooling/instock_client";
import MROButton from '../../../../components/buttons';

const statusMapButtons = { "Good": "button-positive", "Near Due": "button-warning", "Overdue": "button-negative" }
const getDaysDifference = (rowData) => {
    const endDate = moment(format24HrDateOnly(rowData.calibration_due), 'DD/MM/YYYY');
    const startDate = moment(new Date(), 'DD/MM/YYYY');
    const result = endDate.diff(startDate, 'days');
    if (result < 0) {
        return 'Overdue'
    } else if (result > 30) {
        return 'Good'
    } else {
        return 'Near Due'
    }
}
export const renderDue = (rowData) => {
    if (rowData.calibrated === false) {
        return <Button size="small" className={statusMapButtons[rowData.calibrated_status]} variant="outlined"><b>{rowData.calibrated_status || 'N/A'}</b></Button>
    }
    else {
        return <Typography className={statusMapButtons[getDaysDifference(rowData)]} >{format24HrDateOnly(rowData.calibration_due)}</Typography>
    }
}


export default function AddNewTool(props) {

    const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
    const { page, totalCount, pageSize, searchText, inProgress, data } = tableState;
    const message = useContext(ToastMessageContext);
    const [currentSelection, setCurrentSelection] = useState({});
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [open, setOpen] = useState(false);
    const [action, setAction] = useState('');
    const [baseStationId, setBaseStationId] = useState(null);
    const { workOrderId } = props;


    const tableOptions = {
        ...options,
        page: page,
        selection: true,
        search: true,
        total: totalCount,
        pageSize: pageSize,
        searchText: searchText,
        showSelectAllCheckbox: false,
    };

    console.log({ currentSelection })

    useEffect(() => {
        getList();
    }, [pageSize, page, searchText, totalCount, baseStationId]);

    useEffect(() => {
        if (props.hasOwnProperty('getList'))
            props.getList(getList)
    }, [props.getList])

    function getList() {
        let params = {
            id: workOrderId,
            page: page + 1,
            count_per_page: pageSize,
            base_station_id: baseStationId,
            search_keyword: searchText
        }
        TableAction.getList(dispatch, message, params, 'fetch_tooling');
    }

    const handleCloseMenu = (data = '') => {
        setOpen(false)
        setAction(null)
        setCurrentSelection({})
        props.handleClose();
        setSelectedRows([]);
        props.setCreatedId(data)
    };

    useEffect(() => {
        if (action === 'add') {
            if (selectedRows.length === 0) {
                setAction(null)
                return message.showToastMessage({ message: 'No Tools Selected', variant: 'error' })
            }
            let data = {
                reportable_id: workOrderId,
                reportable_type: "WorkOrder",
                tool_ids: selectedRows
            }
            DataServiceAction.createData(dispatch, message, data, 'saveTooling', '', handleCloseMenu)
        }
    }, [action])

    const columns = [
        {
            title: 'Tool ID',
            field: 'tool_id',
            headerStyle: {
                textAlign: 'left',
            },
            cellStyle: {
                textAlign: 'left',
                // width: '0.8%'
            }
        },
        {
            title: 'Description',
            field: 'description',
            headerStyle: {
                textAlign: 'left',
            },
            cellStyle: {
                textAlign: 'left',
                // width: '0.8%'
            }
        },
        {
            title: 'Calibration Date',
            field: 'calibration_due',
            render: renderDue,
            headerStyle: {
                textAlign: 'center',
            },
            cellStyle: {
                textAlign: 'center',
                // width: '0.8%'
            }
        },
    ]

    const [baseStations, setBaseStations] = useState([]);
    const handleChange = (e) => {
        const val = e.target.value;
        setBaseStationId(val);
    }
    const fetchFilterDependencies = () => {
        InStockClientServices.fetchDependencies().then((res) => {
            if (res) {
                setBaseStations(res.base_stations);
            }
        }).catch(() => {
        });
    }

    useEffect(() => {
        fetchFilterDependencies()
    }, [])

    return (
        <>
            <Box mb={2.5}>
                <Box mb={2}>
                    <FormControl className={"filter"}>
                        <Select
                            defaultValue={undefined}
                            displayEmpty
                            onChange={handleChange}
                            input={<InputBase />}
                           >
                            <MenuItem value={undefined} >
                                Base Stations (All)
                        </MenuItem>
                            {baseStations && baseStations.map(base => <MenuItem key={base.id} value={base.id}>
                                {base.station}
                            </MenuItem>)}
                        </Select>
                    </FormControl>
                </Box>
                <MaterialTable
                    columns={columns}
                    icons={tableIcons}
                    title=""
                    data={data.data}
                    isLoading={inProgress}
                    // columns={columns}
                    options={{
                        ...tableOptions,
                        selectionProps: rowData => ({
                            checked: selectedRows.includes(rowData.tool_primary_key),
                            onChange: (_, checked) => {
                              const selectedIds = [...selectedRows];
                              if (checked) {
                                selectedIds.push(rowData.tool_primary_key);
                              } else {
                                const index = selectedIds.indexOf(rowData.tool_primary_key);
                                if (index !== -1) {
                                  selectedIds.splice(index, 1);
                                }
                              }
                              setSelectedRows(selectedIds);
                            },
                          }),
                          rowKey: 'tool_primary_key',
                    }}
                    onChangePage={(page) => {
                        TableAction.setPage(dispatch, page);
                    }}
                    onChangeRowsPerPage={(pageSize) => {
                        TableAction.setPageSize(dispatch, pageSize);
                    }}
                    onSearchChange={(search) => {
                        TableAction.setSearchText(dispatch, search);
                    }}
                    totalCount={totalCount}
                    page={page}
                    // onSelectionChange={(rows) => {
                    //     setSelectedKeys(rows.map(row => row.tool_primary_key))
                    // }}
                />

                <Box mt={3} display="flex" justifyContent="flex-end">
                    <MROButton type="button" variant={"contained"} color="primary"
                        onClick={() => {
                            setAction('add')
                        }}
                    >Add Tool(s)</MROButton>
                </Box>
            </Box>
        </>
    )
}
