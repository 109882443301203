import React, { useState, useEffect, useContext } from "react";
import MROFullScreenRightDrawer from "components/fullwidth_dialog";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import Typography from "@material-ui/core/Typography";
import { Box, useTheme, CircularProgress, Tooltip } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { ICON_COLOUR } from "lib/constants/style_constants";
import CalendarActionDetails from "./CalenderActionDetails";
import { CalenderMaintenanceService } from "lib/services/api/operaitons/WorkShop/Storage/CalenderMaintenanceService";
import { ToastMessageContext } from "lib/contexts/message_context";
import FileUpload from "components/form_components/fileUpload";
import _ from "lodash";
import EditActionInfo from "./ActionInformation/EditActionInfo";
import { formatDateFunction, formatToDateOnly, format24HrDateTime } from "lib/utils/common_utils";
import ManHousrHistory from "../Inspection/ActionInformation/ManhoursHistory";
import RescheduleIcon from "@material-ui/icons/History";


const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: "3%",
  },
  appBar: {
    backgroundColor: "#fff",
    color: "#000000CC",
    boxShadow: "0px 3px 6px #0000001A",
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  description: {
    borderRadius: "5px",
    height: "250px",
    fontSize: "15px",
    backgroundColor: "#F5F5F5",
    padding: theme.spacing(1),
    boxShadow: "0px 1px 4px #0000001A",
  },
  paper1: {
    borderRadius: "5px",
    width: "100%",
    backgroundColor: "#F5F5F5",
    padding: theme.spacing(1),
    boxShadow: "0px 1px 4px #0000001A",
  },
  paper: {
    borderRadius: "5px",
    backgroundColor: "#F5F5F5",
    padding: theme.spacing(2),
    boxShadow: "0px 1px 4px #0000001A",
  },
  partValue: {
    fontSize: "14px",
    color: "#4D4F5C",
    padding: "2px",
    display: "flex",
    alignItems: "center",
  },
  partKey: {
    "& p": {
      fontSize: "14px",
      padding: "1px",
    },
  },
  partCard: {
    borderRadius: "8px",
    padding: "17px 24px",
    "& p": {
      fontSize: "14px",
      padding: "5px",
    },
  },
  sec1: {
    borderRadius: "10px",
    backgroundColor: "#fff",
    padding: theme.spacing(5),
    boxShadow: "0px 1px 4px #0000001A",
  },
  EditIcon: {
    bgcolor: "primary",
    color: "#fff",
  },
  editButton: {
    marginLeft: "300px",
  },
  partValue: {
    fontSize: "14px",
    color: "#4D4F5C",
    padding: "2px",
    display: "flex",
    alignItems: "center",
  },
  partKey: {
    "& p": {
      fontSize: "14px",
      padding: "1px",
    },
  },
  partCard: {
    borderRadius: "8px",
    padding: "17px 24px",
    "& p": {
      fontSize: "14px",
      padding: "5px",
    },
  },
}));

function CalendarActionInfo(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { handleClose, currentSelectedInfo, getAllActions, currentSelectedID, setSelectedRowId, getAllActionStages, currentSelection } = props;
  const [loading, setLoading] = useState(false)
  const [action, setAction] = useState(null);
  const [actionData, setActionData] = useState({});
  const [subFields, setSubFields] = useState([])
  const message = useContext(ToastMessageContext);
  const [currentManHoursSelection, setCurrentManHoursSelection] = useState({})


  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElMan, setAnchorElMan] = useState(null);
  const [busy, setBusy] = useState(null);
  const [popupData, setPopupData] = useState([]);
  // for opening history table popup
  const handleClickHistory = (event) => {
    setAnchorEl(true);
  };
  const handleClick = (event) => {
    setAnchorElMan(true);
  };

  // for closing history table popup
  const handleCloseFunc = () => {
    setAnchorEl(false);
  };
  const handleCloseFuncMan = () => {
    setAnchorElMan(false);
  };

  // console.log("planning varified====", currentSelection)
  const handleCloseEdit = () => {
    if (action === "EditCalendarInfo") {
      setAction(null);
    }
  };

  const getActions = async () => {
    setLoading(true);


    await CalenderMaintenanceService.getActions(currentSelectedID)
      .then((res) => {
        if (res) {
          setActionData(res.data)
          setSubFields(res.data.sub_fields)

        }

      })
      .catch((err) => {
        message.showToastMessage({
          message: "Something went wrong. Try again!",
          variant: "error",
        });
        setLoading(false);
      });
  };

  useEffect(() => {
    getActions()
  }, [currentSelectedID])

  const GetInfoCard = (props) => {
    const classes = useStyles();
    const theme = useTheme();

    return (
      <Box display="flex">
        <Box flex={50} className={classes.partKey}>
          <Typography
            style={{ padding: "5px", color: theme.palette.primary.main }}
            color="secondary"
          >
            {props?.keyName}
          </Typography>
        </Box>
        <Box flex={50} className={classes.partValue}>
          {props?.value ?? "-"}
        </Box>
      </Box>
    );
  };
  return (
    <div>
      <MROFullScreenRightDrawer open={true}>
        <AppBar position="static" className={classes.appBar} elevation={0}>
          <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
            <Box display={"flex"} alignItems={"center"}>
              <IconButton
                onClick={() => {
                  handleClose()
                  getAllActions()
                }}
                edge="start"
                className={classes.backButton}
                color="inherit"
                aria-label="back"
              >
                <BackIcon />
              </IconButton>
              <Typography variant="subtitle2">
                Calendar Action Information
              </Typography>
            </Box>
            {props?.permission.write &&
              <IconButton>
                <EditIcon
                  fontSize="small"
                  htmlColor={ICON_COLOUR}
                  onClick={() => setAction("EditCalendarInfo")}
                  style={{ display: currentSelection?.planning_verification === "Verified" && "none" }}
                />
              </IconButton>}
          </Toolbar>
        </AppBar>

        <div className={classes.wrapper}>
          {loading && _.isEmpty(actionData) ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="700px"
            >
              <CircularProgress color="primary" size={40} />
            </Box>
          ) : (
            <>
              <Grid>
                <Typography
                  color="primary"
                  varient="h3"
                  style={{ marginBottom: "10px", marginTop: "10px" }}
                >
                  Action Details
                </Typography>
              </Grid>

              <Box
                display="flex"
                alignItems="center"
                //   justifyContent={(loading || _.isEmpty(partDetails)) && "center"}
                bgcolor="#F5F5F5"
                className={classes.partCard}
              >
                <Box flexGrow={1}>
                  <div style={{ width: "100%" }}>
                    <GetInfoCard keyName={"Raised by"} value={actionData.raised_by} />
                    <GetInfoCard keyName={"ATA Chapter"} value={actionData.ata_chapter} />
                    <GetInfoCard keyName={"Mech Auth"} value={actionData.mech_auth} />
                    <GetInfoCard keyName={"Insp Auth"} value={actionData.insp_auth} />
                    <GetInfoCard keyName={"Title"} value={actionData.title} />

                  </div>
                </Box>
                <Box flexGrow={1}>
                  <div style={{ width: "100%" }}>
                    <GetInfoCard keyName={"Raised"} value={formatDateFunction(actionData.raised_date_time)} />
                    <GetInfoCard keyName={"Planned Man-Hrs"} value={parseInt(actionData.estimate_man_hours)} />
                    <GetInfoCard keyName={"Tech Data Reference"} value={actionData.tech_data_ref} />
                    <GetInfoCard keyName={"CAMO Part-M Records"} value={actionData.camo_records === true ? "Yes" : "No"} />

                    <GetInfoCard keyName={""} value="" />
                    <GetInfoCard keyName={""} value="" />
                    <GetInfoCard keyName={""} value="" />
                  </div>
                </Box>
                <Box flexGrow={1}>
                  <div style={{ width: "100%" }}>
                    <GetInfoCard keyName={"Frequency (days)"} value={actionData.calendar_frequency} />
                    <GetInfoCard keyName={"Last Complete"} value={format24HrDateTime(actionData.last_completed)
                      || "-"} />
                    <GetInfoCard keyName={"Due"} value={formatToDateOnly(actionData.due)} />
                    <GetInfoCard keyName={""} value="" />
                    <GetInfoCard keyName={""} value="" />
                    <GetInfoCard keyName={""} value="" />
                    <GetInfoCard keyName={""} value="" />
                  </div>
                </Box>
              </Box>

              <Grid>
                <Typography
                  color="primary"
                  varient="h3"
                  style={{ marginBottom: "10px", marginTop: "10px" }}
                >
                  Detailed Description
                </Typography>
              </Grid>
              <Grid className={classes.description}>
                <Typography style={{ fontSize: "15px" }}>
                  {actionData.description}
                </Typography>
              </Grid>

              <Grid>
                <Typography
                  color="primary"
                  varient="h3"
                  style={{ marginBottom: "10px", marginTop: "10px" }}
                >
                  Sub-Fields
                </Typography>
              </Grid>
              <Box
                display="flex"
                alignItems="center"
                //   justifyContent={(loading || _.isEmpty(partDetails)) && "center"}
                bgcolor="#F5F5F5"
                className={classes.partCard}
              >
                <Box flexGrow={1}>
                  <div style={{ width: "100%" }}>
                    <GetInfoCard keyName={"Sub Field 1"} value={subFields[0] != undefined ? subFields[0].field_name : "-"} />
                    <GetInfoCard keyName={"Sub Field 2"} value={subFields[2] != undefined ? subFields[2].field_name : "-"} />
                    <GetInfoCard keyName={"Sub Field 3"} value={subFields[4] != undefined ? subFields[4].field_name : "-"} />
                    <GetInfoCard keyName={"Sub Field 4"} value={subFields[6] != undefined ? subFields[6].field_name : "-"} />
                    <GetInfoCard keyName={"Sub Field 5"} value={subFields[8] != undefined ? subFields[8].field_name : "-"} />
                  </div>
                </Box>
                <Box flexGrow={1}>
                  <div style={{ width: "100%" }}>
                    <GetInfoCard keyName={"Sub Field 6"} value={subFields[1] != undefined ? subFields[1].field_name : "-"} />
                    <GetInfoCard keyName={"Sub Field 7"} value={subFields[3] != undefined ? subFields[3].field_name : "-"} />
                    <GetInfoCard keyName={"Sub Field 8"} value={subFields[5] != undefined ? subFields[5].field_name : "-"} />
                    <GetInfoCard keyName={"Sub Field 9"} value={subFields[7] != undefined ? subFields[7].field_name : "-"} />
                    <GetInfoCard keyName={"Sub Field 10"} value={subFields[9] != undefined ? subFields[9].field_name : "-"} />
                  </div>
                </Box>
              </Box>

              <Box>
                <Typography
                  color="primary"
                  varient="h3"
                  style={{ marginBottom: "10px", marginTop: "10px" }}
                >
                  Technical Data
                </Typography>
                <Grid>
                  <FileUpload
                    defaultValue={
                      actionData?.maintenance !== undefined
                        ? actionData?.maintenance.map((item) => ({
                          filename: item?.file_name,
                          url: `${item?.path}`,
                          id: item?.id,
                        }))
                        : ""
                    }
                    showBorderOnNoFiles={true}
                    APIUrl={""}
                    multiple={true}
                    name={"maintenance_file"}
                    action={"Upload Document"}
                    uploadButtonView={true}
                  />
                </Grid>
              </Box>
              <Grid>
                <Typography
                  color="primary"
                  varient="h3"
                  style={{ marginBottom: "10px", marginTop: "10px" }}
                >
                  Man-Hrs
                </Typography>
              </Grid>
              <Grid className={classes.paper}>
                <Box display="flex">
                  <Box flex={18} className={classes.partKey}>
                    <Typography color="primary">Actual Man-hrs</Typography>
                  </Box>
                  <Box flex={38} className={classes.partKey}>
                    <Typography>{actionData?.actual_man_hours || "-"
                    }</Typography>
                  </Box>
                  <Box flex={18} className={classes.partKey}>
                    <Typography color="primary"></Typography>
                  </Box>
                  <Box flex={38} className={classes.partKey}>
                    <Typography></Typography>
                  </Box>
                  <Box flex={18} className={classes.partKey}>
                    <Typography color="primary"></Typography>
                  </Box>
                  <Box flex={38} className={classes.partKey}>
                    <Typography></Typography>
                  </Box>
                  {/* <Box flex={54} className={classes.partKey}>
                    <Typography color="primary"></Typography>
                  </Box> */}
                  <Box display={"flex"} justifyContent="flex-end" className={classes.partKey}>
                    <Box display="flex">
                      <Tooltip title="History">
                        <IconButton
                          onClick={(e) => {
                            handleClick(e);
                            setCurrentManHoursSelection(currentSelection);
                          }}
                          className={"text-info"}
                        >
                          <RescheduleIcon />
                        </IconButton>
                      </Tooltip>
                      <ManHousrHistory
                        busy={busy}
                        open={anchorElMan}
                        handleClick={handleClick}
                        handleClose={handleCloseFuncMan}
                        currentSelection={currentManHoursSelection}
                        selectedId={currentSelectedID}
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid>
                <Typography
                  color="primary"
                  varient="h3"
                  style={{ marginBottom: "10px", marginTop: "10px" }}
                >
                  Closure
                </Typography>
              </Grid>
              <Box
                display="flex"
                alignItems="center"
                //   justifyContent={(loading || _.isEmpty(partDetails)) && "center"}
                bgcolor="#F5F5F5"
                className={classes.partCard}
              >
                <Box flexGrow={1}>
                  <div style={{ width: "100%" }}>
                    <GetInfoCard keyName={"Closed"} value={format24HrDateTime(actionData.closed_date_time) || "-"} />
                  </div>
                </Box>
                <Box flexGrow={1}>
                  <div style={{ width: "100%" }}>
                    <GetInfoCard keyName={"Closed by"} value={actionData.closed_by || "-"} />
                  </div>
                </Box>
              </Box>
            </>)}


        </div>

      </MROFullScreenRightDrawer>

      {action === "EditCalendarInfo" &&

        <EditActionInfo
          action={action}
          selectedAction={actionData}
          currentSelectedInfo={currentSelectedInfo}
          getAllActions={getAllActions}
          getActions={getActions}
          closeHandler={handleClose}
          getAllActionStages={getAllActionStages}
          handleClose={handleCloseEdit}
          currentSelectedID={currentSelectedID}
          setSelectedRowId={setSelectedRowId}
          data={actionData}
        />}
    </div>
  );
}

export default CalendarActionInfo;
