import React from "react"



const Tools = ({ fill }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="25.266" height="25.265" viewBox="0 0 38.266 38.265" fill={fill}>
        <g id="Wrench" transform="translate(-3.756 -3.763)">
            <path id="Path_20471" data-name="Path 20471" d="M41.506,8.495a.678.678,0,0,0-1.11-.232l-4.389,4.389c-.026.026-.211.064-.545-.047a3.764,3.764,0,0,1-1.371-.911,3.762,3.762,0,0,1-.911-1.371c-.122-.367-.063-.529-.047-.545l4.388-4.388a.678.678,0,0,0-.232-1.11,7.447,7.447,0,0,0-9.806,9.231L9.774,31.221a5.42,5.42,0,1,0,4.79,4.791L32.275,18.3a7.448,7.448,0,0,0,9.232-9.807ZM11.259,38.358a2.71,2.71,0,1,1-3.832-3.833,2.775,2.775,0,0,1,3.832,0,2.709,2.709,0,0,1,0,3.833ZM27.548,19.2l-11.5,11.5a.677.677,0,1,1-.958-.958l11.5-11.5a.677.677,0,1,1,.958.958Z" transform="translate(0 0)" fill={fill} />
        </g>
    </svg>
)
export default Tools