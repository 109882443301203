import requestApi from "./common/boxp_api_client";

const updateDataUrl = {
    acceptanceOrder: ""
}

function boxpUpdateData(formData, id = null, section) {
    return requestApi({
        url: id ? `${updateDataUrl[section]}/${id}` : `${updateDataUrl[section]}`,
        method: 'PUT',
        data: formData
    })
}

export const boxpUpdateDataWithParams = (params, section) => {
    return requestApi({
        url: `${updateDataUrl[section]}`,
        method: 'PUT',
        params: params
    })
}

export default boxpUpdateData;
