import React from "react";
import Drawer from '@material-ui/core/Drawer';
import withStyles from "@material-ui/core/styles/withStyles";


const MROFullScreenRightDrawer = withStyles(theme => ({
    paperAnchorRight: {
        width: "calc(100% - 250px)",
        backgroundColor: "#ffff"
    },
    modal:{
        left:'250px !important',
        '& .MuiBackdrop-root':{
            left:'250px'
        }
    }
}))((props) => <Drawer transitionDuration={{enter:800,exit:200}} elevation={0} anchor={"right"} open={true} {...props}>{props.open? props.children:<p style={{backgroundColor:'#fff',height:'100vh'}}/>}</Drawer>)

export default MROFullScreenRightDrawer;