import React, { useContext, useMemo, useReducer, useState } from "react";
import MaintMROFullScreenRightDrawer from "components/MaintFullWidthRightDialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MaintenanceDetails from "./Maintenance/MaintenanceDetails";
import { TabSectionBar } from "components/tab_component";
import Divider from "@material-ui/core/Divider";
import Quote from "./Quote";
import FormDataProvider from "lib/contexts/form_context";
import TabComponent from "./Maintenance/InductionTab/TabComponent";
import ReleaseComponent from "./Release/TabComponent"
import Planning from "./PlanningTab/Planning";
import Inspection from "./Inspection";
import Commercial from "./Commercial";
import PartsAndTools from "./Parts&Tools";
import ServicesAndEquip from "./ServicesAndEquip";
import CRSOverView from "./CRS";
import InductionTab from "./Production/InductionTab/Induction";
import { useEffect } from "react";
import { ToastMessageContext } from "lib/contexts/message_context";
import { OperationWorkshopTaskLibraryServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/save_operation_taskLibrary";
import { MaintenanceServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation"
import { WorkshopContext } from "./WorkshopContext/WorkshopContext";
import RouterPrompt from "components/RouterPrompt/RouterPrompt";
import tickMark from "../../../assets/green check.png";
import { Box } from "@material-ui/core";
import AddMaint from "./Storage/AddMaint";
import CallMaint from "./CallMaint";
import WarningDialog from "./UniversalWarningDialog";
import { getOrganisationId } from "lib/utils/common_utils";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#fff",
    color: "#000000CC",
    boxShadow: "0px 3px 6px #0000001A",
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  wrapper: {
    margin: "2%",
  },
  wrapper2: {
    margin: "2%",
  },
  detail: {
    padding: "3%",
    backgroundColor: "#fff",
    boxShadow: "0px 3px 6px #0000001A",
  },
}));

function TabDetails(props) {
  const classes = useStyles();
  const {
    handleClose,
    tabId,
    action,
    currentSelection,
    setAction,
    getMaintenanceOrderList,
    paginationReset,
    getCalenderActionTwoDays,
    getCalenderActionTenDays,
    tabName,
    type,

  } = props;

  const message = useContext(ToastMessageContext);
  const { state, setState, setTabStatus, EdtabStatus, copyIdflag,
    setCopyIdFlag, setCopyPlanningId } =
    useContext(WorkshopContext);
  const [workshopOrder, setWorkshopOrder] = useState([]);
  const [headerDetails, setHeaderDetails] = useState([]);
  const [dateField, setDateField] = useState(null);
  const [loading, setLoading] = useState(false);
  const [statuFlag, setFlagStatus] = useState();
  const [orderStateNum, setOrderStateNum] = useState(null);
  const [isGettingDetails, setIsGettingDetails] = useState(false);
  const [orderId, setOrderId] = useState(null)
  const [tabDetailsWhenOrderGen, setTabDetailsWhenGenerate] = useState({})
  // const [currentTab, setCurrentTab] = useState(
  //   tabId === 23 && action === "Unlock"
  //     ? 2818
  //     : tabId === 23 && props.tab === "storage"
  //       ? 2834
  //       : 2815
  // );
  const [currentTab, setCurrentTab] = useState(2815);
  const [clickedTabId, setClickedTabId] = useState(null);
  const [editTab, setEditTab] = useState(false);
  const [orderDetails, setMainOrderDetails] = useState([]);

  const [workshopOrderDetails, setWorkshopOrderDetails] = useState({});



  // console.log("tacurrentSelectionbdetails", workshopOrderDetails?.commercial)

  useEffect(() => {
    renderTab(currentSelection.order_state_id)
  }, [currentSelection.order_state_id])

  useEffect(() => {
    setState((ps) => ({ ...ps, isEditing: editTab }));
  }, [editTab]);

  useEffect(() => {
    if (action === "Unlock") {
      getHeaderDetails();
    }
    if (action === "calendarAction") {
      getHeaderDetails();
    }
  }, []);

  const getHeaderDetails = async () => {
    setIsGettingDetails(true);
    if (currentSelection.id) {
      await MaintenanceServices.getHeaderDetails(currentSelection.id)
        .then((res) => {
          if (res) {
            setHeaderDetails(res.data);
            setDateField(res.data.raised);
          }
        })
        .catch((err) => {
          message.showToastMessage({
            message: "Something went wrong. Try again!",
            variant: "error",
          });
        })
        .finally(() => {
          setIsGettingDetails(null);
        });
    }
  };

  useEffect(() => {
    setFlagStatus(currentSelection?.quote_sent ? true : false);
  }, [currentSelection?.quote_sent]);

  useEffect(() => {
    if (action === "Send Quote ") {
      getWorkshopOrder();
    }
  }, []);

  const getWorkshopOrder = async () => {
    let user = JSON.parse(sessionStorage.getItem("user"));
    const params = {
      order_type: props.order_type,
      user_id: user.id,
      organisation_id: getOrganisationId()
    };
    setIsGettingDetails(true);
    if (action === "Send Quote ") {
      await OperationWorkshopTaskLibraryServices.getWorkshopOrder(params)
        .then((res) => {
          if (res.success) {
            setTabDetailsWhenGenerate(res.data.tab_status_details[0])
            setOrderId(res.data.id)
            setWorkshopOrder(res.data);
            // props.getWorkshopOrderId(res.data?.id);
          }
        })
        .catch((err) => {
          message.showToastMessage({
            message: "Something went wrong. Try again!",
            variant: "error",
          });
        }).finally(() => {
          setIsGettingDetails(false);
        })
    }
  };

  const renderIcon = (state, tabName) => {
    return state === 3 || state === 4 ? (
      <Box display="flex" flexDirection="row">
        {" "}
        {tabName}{" "}
        <img
          src={tickMark}
          alt="green-check-mark"
          style={{ marginLeft: 12, height: 25 }}
        />{" "}
      </Box>
    ) : (
      tabName
    );
  };
  const renderReleaseIcon = (state, tabName) => {
    let release_scrap = state.release_scrap
    let release_away_base = state.release_away_base
    let release_good_out = state.release_good_out
    let release_no_good_out = state.release_no_good_out
    if ((release_scrap === 3 || release_scrap === 4) ||
      (release_away_base === 3 || release_away_base === 4) ||
      (release_good_out === 3 || release_good_out === 4) ||
      (release_no_good_out === 3 || release_good_out === 4)
    ) {
      return (
        <Box display="flex" flexDirection="row">
          {" "}
          {tabName}{" "}
          <img
            src={tickMark}
            alt="green-check-mark"
            style={{ marginLeft: 12, height: 25 }}
          />{" "}
        </Box>)
    } else {
      return tabName;
    }
  };

  const renderTab = (state) => {
    console.log("called this func", state)
    if (state === 0 || state === 11 || state === 12) {
      setCurrentTab(2815)
    } else if (state === 1 && tabId != 21) {
      setCurrentTab(2818)
    }
    else if (state === 1 && tabId === 21) {
      setCurrentTab(2918)
    }
    else if (state === 2) {
      setCurrentTab(2826)
    }
    else if (state === 7 && tabId === 23) {
      setCurrentTab(2834)
    }
    else if (state === 3) {
      setCurrentTab(2831)
    }

    else if (state === 4) {
      setCurrentTab(2833)
    } else if (state === 5) {
      setCurrentTab(2844)
    } else if (state === 5 && tabId === 21) {
      setCurrentTab(2881)
    }
    else if (state === 8 || state === 10) {
      setCurrentTab(2837)
    } else if (state === 9) {
      setCurrentTab(2838)
    }

  }

  const navMenu1 = useMemo(
    () =>
      [
        {
          id: 2815,
          label: renderIcon(workshopOrderDetails.quotation, "Quote"),
          backendKey: "quotation",
        },
        {
          id: 2818,
          label: renderIcon(workshopOrderDetails.induction, "Induction"),
          backendKey: "induction",
        },
        {
          id: 2826,
          label: renderIcon(workshopOrderDetails.planning, "Planning"),
          backendKey: "planning",
        },
        {
          id: 2831,
          label: renderIcon(workshopOrderDetails.inspection, "Inspection"),
          backendKey: "inspection",
        },
        {
          id: 2833,
          label: renderIcon(workshopOrderDetails.commercial, "Commercial"),
          backendKey: "commercial",
        },
        {
          id: 2844,
          label: renderIcon(workshopOrderDetails.maintenance, "Maintenance"),
          backendKey: "maintenance",
        },
        {
          id: 2835,
          label: renderIcon(workshopOrderDetails.part_tools, "Parts & Tools"),
          backendKey: "part_tools",
        },
        {
          id: 2836,
          label: renderIcon(
            workshopOrderDetails.service_equip,
            "Services & Equip"
          ),
          backendKey: "service_equip",
        },
        {
          id: 2837,
          label: renderIcon(
            workshopOrderDetails.certification,
            "Certification"
          ),
          backendKey: "certification",
        },
        {
          id: 2838,
          label: renderReleaseIcon(workshopOrderDetails, "Release"),
          backendKey: ["release_scrap", "release_away_base", "release_good_out", "release_no_good_out"],
        },
      ].filter((tab) => {
        if (action === "Send Quote ") {
          setCurrentTab(2815)
          if (typeof tab.backendKey === "object") return;
          return tabDetailsWhenOrderGen[tab.backendKey] !== 0
        }
        else if (tabId === 24 && orderStateNum === 4) {
          setCurrentTab(2826)
          // console.log("currentSate-----", orderStateNum, action, tabId)
          if (typeof tab.backendKey === "object") return;
          return tabDetailsWhenOrderGen[tab.backendKey] !== 0

        }
        else if (type === 'dashboardCommercial') {
          setCurrentTab(2833)
          if (typeof tab.backendKey === "object") return;
          return tabDetailsWhenOrderGen[tab.backendKey] !== 0
        }
        else if (typeof tab.backendKey === "object" && action != "Send Quote  ") {
          if (workshopOrderDetails[tab.backendKey[0]] != 0) return "release_scrap"
          if (workshopOrderDetails[tab.backendKey[1]] != 0) return "release_away_base"
          if (workshopOrderDetails[tab.backendKey[2]] != 0) return "release_good_out"
          if (workshopOrderDetails[tab.backendKey[3]] != 0) return "release_no_good_out"
        } else {
          return workshopOrderDetails[tab.backendKey] !== 0
        }
      }),
    [workshopOrderDetails, isGettingDetails, tabDetailsWhenOrderGen]
  );

  const navMenu2 = useMemo(
    () =>
      [
        {
          id: 2815,
          label: renderIcon(workshopOrderDetails.quotation, "Quote"),
          backendKey: "quotation",
        },
        {
          id: 2918,
          label: renderIcon(workshopOrderDetails.induction, "Induction"),
          backendKey: "induction",
        },
        {
          id: 2826,
          label: renderIcon(workshopOrderDetails.planning, "Planning"),
          backendKey: "planning",
        },
        {
          id: 2831,
          label: renderIcon(workshopOrderDetails.inspection, "Inspection"),
          backendKey: "inspection",
        },
        {
          id: 2833,
          label: renderIcon(workshopOrderDetails.commercial, "Commercial"),
          backendKey: "commercial",
        },
        {
          id: 2881,
          label: renderIcon(workshopOrderDetails.production, "Production"),
          backendKey: "production",
        },
        {
          id: 2835,
          label: renderIcon(workshopOrderDetails.part_tools, "Parts & Tools"),
          backendKey: "part_tools",
        },
        {
          id: 2836,
          label: renderIcon(
            workshopOrderDetails.service_equip,
            "Services & Equip"
          ),
          backendKey: "service_equip",
        },
        {
          id: 2837,
          label: renderIcon(
            workshopOrderDetails.certification,
            "Certification"
          ),
          backendKey: "certification",
        },
        {
          id: 2838,
          label: renderReleaseIcon(workshopOrderDetails, "Release"),
          backendKey: ["release_scrap", "release_away_base", "release_good_out", "release_no_good_out"],
        },
      ].filter((tab) => {
        if (action === "Send Quote ") {
          setCurrentTab(2815)
          if (typeof tab.backendKey === "object") return;
          return tabDetailsWhenOrderGen[tab.backendKey] !== 0
        }
        else if (tabId === 25 && orderStateNum === 4) {
          setCurrentTab(2826)
          if (typeof tab.backendKey === "object") return;
          return tabDetailsWhenOrderGen[tab.backendKey] !== 0
          // console.log("currentSate-----", orderStateNum, action)
        }
        else if (typeof tab.backendKey === "object" && action != "Send Quote  ") {
          if (workshopOrderDetails[tab.backendKey[0]] != 0) return "release_scrap"
          if (workshopOrderDetails[tab.backendKey[1]] != 0) return "release_away_base"
          if (workshopOrderDetails[tab.backendKey[2]] != 0) return "release_good_out"
          if (workshopOrderDetails[tab.backendKey[3]] != 0) return "release_no_good_out"
        } else {
          return workshopOrderDetails[tab.backendKey] !== 0
        }
      }),
    [workshopOrderDetails, isGettingDetails, tabDetailsWhenOrderGen]
  );

  const navMenu3 = useMemo(
    () =>
      [
        {
          id: 2818,
          label: renderIcon(workshopOrderDetails.induction, "Induction"),
          backendKey: "induction",
        },
        {
          id: 2826,
          label: renderIcon(workshopOrderDetails.planning, "Planning"),
          backendKey: "planning",
        },
        {
          id: 2831,
          label: renderIcon(workshopOrderDetails.inspection, "Inspection"),
          backendKey: "inspection",
        },
        {
          id: 2834,
          label: renderIcon(workshopOrderDetails.calendar_maint, "Cal Maint"),
          backendKey: "calendar_maint",
        },
        {
          id: 2835,
          label: renderIcon(workshopOrderDetails.part_tools, "Parts & Tools"),
          backendKey: "part_tools",
        },
        {
          id: 2836,
          label: renderIcon(
            workshopOrderDetails.service_equip,
            "Services & Equip"
          ),
          backendKey: "service_equip",
        },
        {
          id: 2936,
          label: renderIcon(workshopOrderDetails.additional_maint, "Add’l Maint"),
          backendKey: "additional_maint",
        },
        {
          id: 2837,
          label: renderIcon(
            workshopOrderDetails.certification,
            "Certification"
          ),
          backendKey: "certification",
        },
        {
          id: 2838,
          label: renderReleaseIcon(workshopOrderDetails, "Release"),
          backendKey: ["release_scrap", "release_away_base", "release_good_out", "release_no_good_out"]
        },
      ].filter((tab) => {
        if (action === "Send Quote ") {
          setCurrentTab(2815)
          if (typeof tab.backendKey === "object") return;
          return tabDetailsWhenOrderGen[tab.backendKey] !== 0
        }
        else if (action === "calendarAction") {
          setCurrentTab(2834)
          return workshopOrderDetails[tab.backendKey] !== 0
        }
        else if (typeof tab.backendKey === "object" && action != "Send Quote  ") {
          if (workshopOrderDetails[tab.backendKey[0]] != 0) return "release_scrap"
          if (workshopOrderDetails[tab.backendKey[1]] != 0) return "release_away_base"
          if (workshopOrderDetails[tab.backendKey[2]] != 0) return "release_good_out"
          if (workshopOrderDetails[tab.backendKey[3]] != 0) return "release_no_good_out"
        }
        else {
          return workshopOrderDetails[tab.backendKey] !== 0
        }
      }),
    [workshopOrderDetails, isGettingDetails, tabDetailsWhenOrderGen]
  );
  // console.log("currentSate-----", action)
  useEffect(() => {
    if (state.tab === "induction") {
      if (
        state.tab === "induction" &&
        state.isEditing &&
        state.showToastMessage
      ) {
        message.showToastMessage({
          message: " Close Tab before leaving this tab!",
          variant: "error",
        });
      }
    }
    if (state.tab === "quote") {
      if (state.tab === "quote" && state.isEditing && state.shouldShowWarning && !state.warningDialogState && !state.warningState) {
        message.showToastMessage({
          message: " Close Tab before leaving this tab!",
          variant: "error",
        });
      }
    }
    if (state.tab === "planning") {
      if (
        state.tab === "planning" &&
        state.isEditing &&
        state.shouldShowWarning && !state.warningDialogState && state.controlPlanningPop
      ) {
        message.showToastMessage({
          message: " Close Tab before leaving this tab!",
          variant: "error",
        });
      }
    }
    if (state.tab === "release") {
      if (
        state.tab === "release" &&
        state.isEditing &&
        state.showToastMessage
      ) {
        message.showToastMessage({
          message: " Close Tab before leaving this tab!",
          variant: "error",
        });
      }
    }
  }, [state]);

  const clickHandler = () => {
    // console.log("called tab close")
    if (!props?.tab) {
      if (action === "Send Quote ") {
        typeof paginationReset === "function" && paginationReset()
      }
      getMaintenanceOrderList();
    }
    if (copyIdflag) {
      setCopyIdFlag(false)
      setCopyPlanningId({})
    }
    if (tabId === 23 && tabName === "Operations") {
      getMaintenanceOrderList()
      getCalenderActionTenDays()
      getCalenderActionTwoDays()
    }
    if (state.isEditing) {
      setState((ps) => ({
        ...ps,
        shouldShowWarning: true,
        handleConfirm: handleClose,
      }));
      return;
    }
    if (props.stoppageOrderClose) {
      props.stoppageOrderClose();
    }
    handleClose()
  };


  const getWorkshopOrderDetails = async () => {
    console.log("called tab api")
    setIsGettingDetails(true);
    try {
      const { data } = await MaintenanceServices.getOrderDetails(currentSelection.id);
      console.log("workshop===", data[0])
      setWorkshopOrderDetails(data[0]);
      setOrderStateNum(data[0]?.order_state_id);
      setIsGettingDetails(false);
    } catch (error) {
      // console.log("error", error);
      setIsGettingDetails(false);
    }
  };

  useEffect(() => {
    if (action !== "Send Quote ") getWorkshopOrderDetails();
  }, []);

  useEffect(() => {
    if (workshopOrderDetails?.quotation === 4) setTabStatus(true);
  }, [workshopOrderDetails.quotation]);


  const displayProductionTab = (tabId) => {
    if (tabId === 25 || props?.fromPlanninngProduction) {
      const filteredNavMenu2 = navMenu2.filter((item) => item.id != 2833 && item.label != "Commercial");
      // console.log("menudetails", orderStateNum)
      return filteredNavMenu2
    }
    else {
      return navMenu2
    }
  }



  const displayMaintenanceTab = (tabId) => {
    if (tabId === 24 || props?.fromPlanninng) {
      const filteredNavMenu1 = navMenu1.filter((item) => item.id != 2833 && item.label != "Commercial");
      // console.log("menudetails", filteredNavMenu1)
      return filteredNavMenu1
    }
    else {
      return navMenu1
    }
  }

  // console.log("warninf statet is-=====================", workshopOrderDetails.release_good_out)

  return (
    <MaintMROFullScreenRightDrawer open={true}>
      <AppBar position="static" className={classes.appBar} elevation={0}>
        <Toolbar>
          <IconButton
            onClick={clickHandler}
            edge="start"
            className={classes.backButton}
            color="inherit"
            aria-label="back"
          >
            <BackIcon />
          </IconButton>
          <Typography style={{ fontSize: "17px" }} variant="subtitle2">
            {tabId === 21 || tabId === 25
              ? "Production Orders"
              : tabId === 23
                ? "Storage Orders"
                : "Maintenance Order"}
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.wrapper}>
        {action && (
          <MaintenanceDetails
            tabId={tabId}
            actionProps={action}
            currentSelection={currentSelection}
            quoteId={currentSelection?.id}
            quoteStatus={currentSelection?.quote_sent}
            loading={loading}
            workshopOrder={workshopOrder}
            getList={getMaintenanceOrderList}
            handleClose={handleClose}
            bases={props.bases}
            lineCustomers={props.lineCustomers}
            data={headerDetails}
            getHeaderDetails={getHeaderDetails}
            dateField={dateField}
            orderState={currentSelection?.order_state}
            isGettingDetails={isGettingDetails}
            permission={props?.permission}
            actionStoppedOrder={props?.actionStoppedOrder}
            type={type}
          />
        )}
      </div>

      {
        <div className={classes.wrapper2}>
          <TabSectionBar
            {...props}
            tabProperties={
              tabId === 21 || tabId === 25 ? displayProductionTab(tabId) : tabId === 23 ? navMenu3 : displayMaintenanceTab(tabId)
            }
            isGettingDetails={isGettingDetails}
            currentTab={currentTab}
            setCurrentTab={
              state?.isEditing
                ? (newValue) => {
                  setClickedTabId(newValue);
                  setState((ps) => ({
                    ...ps,
                    shouldShowWarning: true,
                    handleConfirm: () => setCurrentTab(newValue),
                    showToastMessage: true,
                    editTabStatus: EdtabStatus ? true : false
                  }));
                }
                : setCurrentTab
            }
          />
          {
            <Divider
              variant="fullWidth"
              style={{
                padding: "1px",
                backgroundColor: "rgba(222, 214, 214, 0.2)",
              }}
            />
          }
          <div className={classes.detail}>
            <FormDataProvider>
              {currentTab === 2815 && workshopOrderDetails.quotation !== 0 && (
                <Quote
                  readAndWrite={
                    workshopOrderDetails.quotation === 1 ||
                    workshopOrderDetails.quotation === 2
                  }
                  readOnly={
                    workshopOrderDetails.quotation === 3 ||
                    workshopOrderDetails.quotation === 4
                  }
                  action={action}
                  quoteId={currentSelection?.quotation_id}
                  workshop_id_maint={currentSelection?.id}
                  quoteStatus={
                    action === "Send Quote "
                      ? false
                      : currentSelection?.quote_sent
                  }
                  editTab={editTab}
                  setEditTab={setEditTab}
                  statuFlag={statuFlag}
                  workshopOrder={workshopOrder}
                  setAction={setAction}
                  currentSelection={currentSelection}
                  handleClose={handleClose}
                  getMaintenanceOrderList={getMaintenanceOrderList}
                  getWorkshopOrderDetails={getWorkshopOrderDetails}
                  tabStatus={workshopOrderDetails?.quotation === 4}
                  tabState={workshopOrderDetails.quotation}
                  orderStateNum={orderStateNum}
                  permission={props?.permission}
                  tabId={tabId}
                  actionStoppedOrder={props?.actionStoppedOrder}
                />
              )}
              {tabId === 21 || tabId === 25
                ? currentTab === 2918 &&
                workshopOrderDetails.induction !== 0 && (
                  <InductionTab
                    readAndWrite={
                      workshopOrderDetails.induction === 1 ||
                      workshopOrderDetails.induction === 2
                    }
                    readOnly={
                      workshopOrderDetails.induction === 3 ||
                      workshopOrderDetails.induction === 4
                    }
                    data={currentSelection}
                    workshopOrder={workshopOrder}
                    tabId={tabId}
                    getHeaderDetails={getHeaderDetails}
                    tabStatus={workshopOrderDetails.induction === 3}
                    orderStateNum={orderStateNum}
                    closeTabStatus={workshopOrderDetails.induction}
                    getWorkshopOrderDetails={getWorkshopOrderDetails}
                    permission={props?.permission}
                  />
                )
                : currentTab === 2818 &&
                workshopOrderDetails.induction !== 0 && (
                  <TabComponent
                    readAndWrite={
                      workshopOrderDetails.induction === 1 ||
                      workshopOrderDetails.induction === 2
                    }
                    readOnly={
                      workshopOrderDetails.induction === 3 ||
                      workshopOrderDetails.induction === 4
                    }
                    data={currentSelection}
                    workshopOrder={workshopOrder}
                    tabId={tabId}
                    getHeaderDetails={getHeaderDetails}
                    tabStatus={workshopOrderDetails.induction === 3}
                    orderStateNum={orderStateNum}
                    closeTabStatus={workshopOrderDetails.induction}
                    getWorkshopOrderDetails={getWorkshopOrderDetails}
                    permission={props?.permission}
                  />
                )}
              {currentTab === 2826 && workshopOrderDetails.planning !== 0 && (
                <Planning
                  readAndWrite={
                    workshopOrderDetails.planning === 1 ||
                    workshopOrderDetails.planning === 2
                  }
                  readOnly={
                    workshopOrderDetails.planning === 3 ||
                    workshopOrderDetails.planning === 4
                  }
                  planning_Id={currentSelection?.planning_id}
                  editTab={editTab}
                  setEditTab={setEditTab}
                  actionStatus={action}
                  setActionStatus={setAction}
                  currentSelection={currentSelection}
                  workshop_id={currentSelection?.id}
                  workshopOrder={workshopOrder}
                  handleClose={handleClose}
                  getMaintenanceOrderList={getMaintenanceOrderList}
                  tabStatus={workshopOrderDetails.planning === 4}
                  orderStateNum={orderStateNum}
                  closeTabStatus={workshopOrderDetails.planning}
                  getWorkshopOrderDetails={getWorkshopOrderDetails}
                  actions={tabId === 21 || tabId === 25}
                  permission={props?.permission}
                  actionTabId={tabId}
                  copyTab={"normal"}
                />
              )}
              {currentTab === 2831 && workshopOrderDetails.inspection !== 0 && (
                <Inspection
                  readAndWrite={
                    workshopOrderDetails.inspection === 1 ||
                    workshopOrderDetails.inspection === 2
                  }
                  readOnly={
                    workshopOrderDetails.inspection === 3 ||
                    workshopOrderDetails.inspection === 4
                  }
                  actions={"Inspection"}
                  workshop_id={currentSelection?.id}
                  action_type_Insp={"Inspection"}
                  action_type_prod={"Production"}
                  tabStatus={workshopOrderDetails.inspection === 3}
                  orderStateNum={orderStateNum}
                  closeHandler={handleClose}
                  orderState={currentSelection?.order_state}
                  getWorkshopOrderDetails={getWorkshopOrderDetails}
                  getMaintenanceOrderList={getMaintenanceOrderList}
                  permission={props?.permission}
                  workshopOrder={currentSelection}
                  actionTabId={tabId}
                  copyTab={"normal"}
                  tabDetails={workshopOrderDetails.inspection}
                />
              )}
              {currentTab === 2881 && workshopOrderDetails.production !== 0 && (
                <Inspection
                  readAndWrite={
                    workshopOrderDetails.production === 1 ||
                    workshopOrderDetails.production === 2
                  }
                  readOnly={
                    workshopOrderDetails.production === 3 ||
                    workshopOrderDetails.production === 4
                  }
                  actions={"Production"}
                  workshop_id={currentSelection?.id}
                  action_type_prod={"Production"}
                  tabStatus={workshopOrderDetails.production === 3}
                  orderStateNum={orderStateNum}
                  orderState={currentSelection?.order_state}
                  closeHandler={handleClose}
                  getWorkshopOrderDetails={getWorkshopOrderDetails}
                  getMaintenanceOrderList={getMaintenanceOrderList}
                  permission={props?.permission}
                  workshopOrder={currentSelection}
                  actionTabId={tabId}
                  copyTab={"normal"}
                  tabDetails={workshopOrderDetails.production}
                />
              )}
              {tabId !== 24 && tabId !== 25 && currentTab === 2833 && workshopOrderDetails.commercial !== 0 && (
                <Commercial
                  color={tabId === 21 ? "#4FC605" : "red"}
                  currentSelectedOrder={currentSelection}
                  orderState={currentSelection.order_state}
                  permission={props?.permission}
                  orderStateNum={workshopOrderDetails?.commercial}
                />
              )}
              {currentTab === 2834 && workshopOrderDetails.calendar_maint !== 0 && (
                <CallMaint
                  readAndWrite={
                    workshopOrderDetails.calendar_maint === 1 ||
                    workshopOrderDetails.calendar_maint === 2
                  }
                  readOnly={
                    workshopOrderDetails.calendar_maint === 3 ||
                    workshopOrderDetails.calendar_maint === 4
                  }
                  actions={"Calendar"}
                  tabId={23}
                  customerDetails={props.customerDetails}
                  currentDataSelection={currentSelection}
                  workshop_id={currentSelection?.id}
                  action_type_cal={"Calendar"}
                  orderStateNum={orderStateNum}
                  orderState={currentSelection?.order_state}
                  tabStatus={workshopOrderDetails.calendar_maint === 3}
                  permission={props?.permission}
                  getWorkshopOrderDetails={getWorkshopOrderDetails}
                  getMaintenanceOrderList={getMaintenanceOrderList}
                  tabDetails={workshopOrderDetails.calendar_maint}
                />
              )}
              {currentTab === 2936 && workshopOrderDetails.additional_maint !== 0 && (
                <AddMaint actionStatus={action}
                  setActionStatus={setAction}
                  currentDataSelection={currentSelection}
                  workshop_id={currentSelection?.id}
                  permission={props?.permission}
                  orderState={currentSelection?.order_state}
                />
              )}
              {currentTab === 2844 && workshopOrderDetails.maintenance !== 0 && (
                <Inspection
                  readAndWrite={
                    workshopOrderDetails.maintenance === 1 ||
                    workshopOrderDetails.maintenance === 2
                  }
                  readOnly={
                    workshopOrderDetails.maintenance === 3 ||
                    workshopOrderDetails.maintenance === 4
                  }
                  actions={"Maintenance"}
                  workshop_id={currentSelection?.id}
                  actionTypeMaintenance={"Maintenance"}
                  tabStatus={workshopOrderDetails.maintenance === 3}
                  orderStateNum={orderStateNum}
                  closeHandler={handleClose}
                  getWorkshopOrderDetails={getWorkshopOrderDetails}
                  getMaintenanceOrderList={getMaintenanceOrderList}
                  permission={props?.permission}
                  workshopOrder={currentSelection}
                  actionTabId={tabId}
                  copyTab={"normal"}
                  tabDetails={workshopOrderDetails.maintenance}
                />
              )}
              {currentTab === 2835 && workshopOrderDetails.part_tools !== 0 && (
                <PartsAndTools
                  bases={props.bases}
                  lineCustomers={props.lineCustomers}
                  currentSelectedOrder={currentSelection}
                  orderState={currentSelection.order_state}
                  partsAndToolsStatus={workshopOrderDetails.part_tools}
                  permission={props?.permission}
                />
              )}
              {currentTab === 2836 &&
                workshopOrderDetails.service_equip !== 0 && (
                  <ServicesAndEquip
                    workshop_id={currentSelection?.id}
                    orderState={currentSelection.order_state}
                    baseId={currentSelection.base_station_id}
                    permission={props?.permission}
                  />
                )}
              {currentTab === 2837 &&
                workshopOrderDetails.certification !== 0 && (
                  <CRSOverView
                    workshop_id={currentSelection?.id}
                    handleClose={() => setAction(null)}
                    tabStatus={workshopOrderDetails.certification === 3}
                    orderStateNum={orderStateNum}
                    getWorkshopOrderDetails={getWorkshopOrderDetails}
                    getMaintenanceOrderList={getMaintenanceOrderList}
                    permission={props?.permission}
                  />
                )}
              {currentTab === 2838 &&
                <ReleaseComponent
                  data={currentSelection}
                  workshopOrderDetails={workshopOrderDetails}
                  getMaintenanceOrderList={getMaintenanceOrderList}
                  getWorkshopOrderDetails={getWorkshopOrderDetails}
                  orderStateNum={orderStateNum}
                  getHeaderDetails={getHeaderDetails}
                  tabStatus={workshopOrderDetails.release_good_out === 3}
                  closeTabStatus={workshopOrderDetails.release_good_out}
                  tabStatusAFB={workshopOrderDetails.release_away_base === 3}
                  closeTabStatusAFB={workshopOrderDetails.release_away_base}
                  tabStatusScrap={workshopOrderDetails.release_scrap === 3}
                  closeTabStatusScrap={workshopOrderDetails.release_scrap}
                  permission={props?.permission}
                />
              }
            </FormDataProvider>
          </div>
        </div>
      }

      {/* This will triger if user either try to change the tab or go back. */}
      {/* There are two implimentation of the  same functionality becuase the react-router-dom will not able to
        notice the change of dialog becuase the dialogs are not opening with help of routing or any route.
      */}
      {state.tab !== "induction" &&
        state.tab !== "planning" &&
        state.tab === "quote" &&
        state.shouldShowWarning &&
        !state.editTabStatus &&
        state.isEditing ? (
        <WarningDialog
          titleColor="warning"
          action={state.shouldShowWarning && state.isEditing}
          handleClose={() =>
            setState((ps) => ({ ...ps, shouldShowWarning: false }))
          }
          handleConfirm={() => {
            // state.handleConfirm();
            setState((ps) => ({
              ...ps,
              shouldShowWarning: true,
              isEditing: true,
            }));
          }}
          hideYesButton={true}
          title="Warning"
          description="Data is NOT saved! Don't leave without saving data!!"
        />
      ) : null}
      {/* This dialog will triger if isEditing is true and user try to change the route */}
      {state.tab !== "induction" ? (
        <RouterPrompt
          when={state.isEditing}
          tab={state.tab === "quote" ? "quote" : "planning"}
        />
      ) : (
        <RouterPrompt when={state.isEditing} tab="induction" />
      )}
      {/* {state.tab === "planning" && (
        <RouterPrompt when={state.isEditing} tab="planning" />
      )} */}
      {/* {state.tab === "quote" && (
        <RouterPrompt when={state.isEditing} tab="quote" />
      )} */}
      {state.tab !== "induction" &&
        state.tab !== "planning" &&
        state.tab === "quote" &&
        state.warningDialogState &&
        state.isEditing &&
        state.shouldShowWarning ? (
        <WarningDialog
          titleColor="warning"
          action={state.warningDialogState}
          handleClose={() =>
            setState((ps) => ({ ...ps, shouldShowWarning: false }))
          }
          handleConfirm={() => {
            state.handleConfirm();
            setState((ps) => ({
              ...ps,
              shouldShowWarning: false,
              isEditing: false
            }));
          }}
          hideYesButton={false}
          btnTitle="Yes"
          title="Warning"
          description="Data is NOT saved! Are you sure want to leave this page?"
        />
      ) : null}

      {state.tab !== "induction" &&
        state.tab !== "quote" &&
        state.tab === "planning" &&
        state.isEditing &&
        state.shouldShowWarning &&
        !state.editTabStatus ? (
        <WarningDialog
          titleColor="warning"
          action={state.shouldShowWarning && state.isEditing}
          handleClose={() =>
            setState((ps) => ({ ...ps, shouldShowWarning: false }))
          }
          handleConfirm={() => {
            // state.handleConfirm();
            setState((ps) => ({
              ...ps,
              shouldShowWarning: true,
              isEditing: true,
            }));
          }}
          hideYesButton={true}
          title="Warning"
          description="Data is NOT saved! Don't leave without saving data!!"
        />
      ) : null}

      {state.tab !== "induction" &&
        state.tab !== "quote" &&
        state.tab === "planning" &&
        state.warningDialogState &&
        state.isEditing &&
        state.shouldShowWarning ? (
        <WarningDialog
          titleColor="warning"
          action={state.warningDialogState}
          handleClose={() =>
            setState((ps) => ({ ...ps, shouldShowWarning: false }))
          }
          handleConfirm={() => {
            state.handleConfirm();
            setState((ps) => ({
              ...ps,
              shouldShowWarning: false,
              isEditing: false
            }));
          }}
          hideYesButton={false}
          btnTitle="Yes"
          title="Warning"
          description="Data is NOT saved! Are you sure want to leave this page?"
        />
      ) : null}
      {state.tab !== "induction" &&
        state.tab !== "quote" &&
        state.tab != "planning" &&
        state.tab === "release" &&
        state.isEditing &&
        state.shouldShowWarning ? (
        <WarningDialog
          titleColor="warning"
          action={state.warningDialogState}
          handleClose={() =>
            setState((ps) => ({ ...ps, shouldShowWarning: false }))
          }
          handleConfirm={() => {
            state.handleConfirm();
            setState((ps) => ({
              ...ps,
              shouldShowWarning: false,
              isEditing: false
            }));
          }}
          hideYesButton={false}
          btnTitle="Yes"
          title="Warning"
          description="Data is NOT saved! Are you sure want to leave this page?"
        />
      ) : null}
    </MaintMROFullScreenRightDrawer>
  );
}

export default React.memo(TabDetails);
