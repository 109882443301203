import React, { useState, useEffect } from "react";

import QRCodeWithData from "components/qr_code_with_data";
import MRODialog from "components/MRODialog";
import { MaintenanceServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation";
import { removeSecFromDateTime } from "lib/utils/helperFunctions";

function PrintLable(props) {
  const { open, handleClose, id } = props;

  const [barcodeData, setBarcodeData] = useState({});

  const [loading, setLoading] = useState(false);

  const getBarcodeData = async () => {
    try {
      setLoading(true);
      const response = await MaintenanceServices.getBarcodeData({
        workshop_order_id: id,
      });
      setBarcodeData(response ?? {});
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBarcodeData();
  }, [id]);

  const data = [
    {
      label: "Order Ref",
      value: barcodeData.order_ref,
    },
    {
      label: "Part #",
      value: barcodeData.part,
    },
    {
      label: "Serial #",
      value: barcodeData.serial,
    },
    {
      label: "Description",
      value: barcodeData.description,
    },
    {
      label: "Customer",
      value: barcodeData.customer,
    },
    {
      label: "Customer Ref",
      value: barcodeData.customer_ref,
    },
    {
      label: "Booked In",
      value: removeSecFromDateTime(barcodeData.bookedIn),
    },
  ];

  const qrData = {
    orderRef: barcodeData.order_ref,
    partNo: barcodeData.part,
    serialNo: barcodeData.serial,
    desc: barcodeData.description,
    customer: barcodeData.customer,
    customerRef: barcodeData.customer_ref,
    bookedIn: barcodeData.bookedIn,
    reportable_type: "WorkshopOrder",
    reportable_id: `${id}`

  };

  return (
    <MRODialog
      padding="26px 40px"
      width="423px"
      open={open}
      handleClose={handleClose}
      noActionBtns
    >
      <QRCodeWithData
        data={data}
        qrData={qrData}
        handleClose={handleClose}
        loading={loading}
      />
    </MRODialog>
  );
}

export default PrintLable;
