import React, { useState, useEffect } from "react";
import MROTextField from "components/form_components/TextField";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { setError } from "../../../../../actions/auth_actions";

function DropdownField(props) {
    const { handleChange, errors, setErrors } = props;
    const [choices, setChoices] = useState([]);
    const [selectedOption, setSelectedOption] = useState({});


    const fieldProps = {
        // required: props.mandatory || false,
        label: props.field_name,
        name: `${props.id}`,
        defaultValue: { id: props.field_value, name: choices?.find(choice => choice.id === props.field_value)?.name },
        // error: props.errors[props.id || props.org_field_id]
    }

    useEffect(() => {
        let dropdownOptions = [];
        if (props.dropdown_values && props.dropdown_values.length > 0) {
            dropdownOptions = props.dropdown_values.map(option => {
                return ({ id: option.id, name: option.station })
            });
        }
        setChoices(dropdownOptions);
    }, [props.dropdown_values]);

    const handleDropdownValue = (value) => {
        setSelectedOption(value);
        handleChange && handleChange({ name: props.id || props.org_field_id, value: value.id });
        debugger
        setErrors({
            ...errors,
            [props.id]: {
                valid: value ? true : false
            }
        })
    }

    return (
        <div style={{ minWidth: '245px', paddingRight: '25px' }}>
            <Autocomplete
                // {...fieldProps}
                fullWidth
                style={{ width: 'inherit' }}
                getOptionSelected={(option, value) => option.id === value.id}
                value={(selectedOption && selectedOption.id) ? selectedOption : fieldProps.defaultValue}
                onChange={(event, value) => handleDropdownValue(value)}
                options={choices}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                    <MROTextField
                        {...params}
                        {...fieldProps}
                        style={{ backgroundColor: '#fff' }}
                        variant="outlined"
                        label={fieldProps.label}
                        value={selectedOption}
                        color={"primary"}
                        error={props?.error}
                        InputLabelProps={{ shrink: true }}
                    />
                )}
            />
        </div>
    )
}

export default React.memo(DropdownField);