import React, { useReducer, useEffect, useContext } from "react";
import useDebounce from "lib/utils/debounce";
import IconButton from "@material-ui/core/IconButton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Divider, Grid, InputBase } from "@material-ui/core";
import tableReducer, { INITIAL_TABLE_STATE } from "reducers/table_reducer";
import MaterialTable from "material-table";
import TableAction from "actions/table_actions";
import tableIcons, { options } from "components/universal/table_attributes";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import { useState } from "react";
import SelectedOrderDetails from "./SelectedOrderDetails";
import BoxpTableAction from "actions/boxp_table_actions";
import { formatToDateOnly, getOrganisationId } from "lib/utils/common_utils";
import { ToastMessageContext } from "lib/contexts/message_context";
import SearchBarWithPartsFilters from "components/search_with_filters_parts";




const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "4px",
    textAlign: "center",
  },
  offgoingchecksTable: {
    "& td, th ": {
      border: "1px solid #dddddd",
      textAlign: "left",
      padding: "8px",
    },
    partCard: {
      borderRadius: "8px",
      padding: "17px 24px",
      "& p": {
        fontSize: "14px",
        padding: "5px",
      },
    },
    offgoingchecksTable: {
      "& td, th ": {
        border: "1px solid #dddddd",
        textAlign: "left",
        padding: "8px",
      },
    },
  },

  logo: {
    height: "76px",
  },
  filePreviewContainer: {
    maxWidth: "130px",
    marginRight: "30px",
  },
  wrapper: {
    margin: "1%",
  },




}));

function CopyPreviuos(props) {
  const classes = useStyles();
  const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
  const { handleClose,
    currentSelection,
    workshop_id,
    getWorkshopOrderDetails,
    setPlanningAction,
    getPlanningList,
    getMaintenanceOrder,
    actionTabId,
    planningId,
    planningWholeDetails,
    getPlanningDetails,
    closeTabAction,
    copyTab } = props;
  // console.log("planning id====", actionTabId)
  const [action, setAction] = useState(null);
  const message = useContext(ToastMessageContext);
  const { page, totalCount, pageSize, inProgress, data } = tableState;
  const [searchText, setSearchText] = useState("");
  const debouncedVal = useDebounce(searchText);
  const [currentSelectedDetails, setCurrentSelectedDetails] = useState({});
  const [planningData, setPlanningData] = useState({})
  const [loading, setLoading] = useState(false)
  const [partNumber, setPartNumber] = useState('')
  const [description, setDescription] = useState('')

  const tableOptions = {
    ...options,
    page: page,
    total: totalCount,
    pageSize: pageSize,
    search: false,
  };

  console.log("plannnnnn====", planningId, currentSelection)
  useEffect(() => {
    getMaintenanceOrderList();
    setPlanningData(currentSelection)

  }, [page, pageSize, debouncedVal, currentSelection]);

  // console.log("name===", tabname)
  useEffect(() => {
    if (currentSelection) {
      setPartNumber(currentSelection?.part_number)
      setDescription(currentSelection?.description || currentSelection?.item_description
      )
    }

  }, [currentSelection])

  const getMaintenanceOrderList = () => {
    const params = {
      page: page + 1,
      limit: pageSize,
      order_type: actionTabId === 21 || actionTabId === 25
        ? "Production"
        : actionTabId === 23
          ? "Storage"
          : "Maintenance",
      organisation_id: getOrganisationId(),
      search: debouncedVal,
      show_in_work_order: true,

    };

    BoxpTableAction.getList(dispatch, message, params, "OrderList");
  };


  const columns = [
    {
      title: "Order #",
      field: "order_no",
    },
    {
      title: "Date Raised",
      field: "created_at",
      render: (rowData) => formatToDateOnly(rowData.created_at)
    },
    {
      title: "Part #",
      field: "part_number",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        textAlign: "left",
        width: "10%"
      }
    },
    {
      title: "Description",
      field: "description",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        textAlign: "left",
        width: "45%"
      }
    },
    {
      title: "Customer",
      field: "customer",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        textAlign: "left",
      }
    },
    {
      title: "Quantity",
      field: "quantity",
    },
    {
      title: "Action",
      render: (rowData) => (
        <IconButton onClick={() => {
          setAction("Selected_Order")
          setCurrentSelectedDetails(rowData)
        }}>
          <TouchAppIcon />
        </IconButton>
      ),
    },
  ];

  const handlePartCopy = () => {
    TableAction.setPage(dispatch, 0);
    setSearchText(partNumber)

  }
  const handleDescriptionCopy = () => {
    TableAction.setPage(dispatch, 0);
    setSearchText(description)

  }

  const handleSearch = (value) => {

    TableAction.setPage(dispatch, 0);
    setSearchText(value);
  }
  const clearSearch = () => {
    handleSearch('')
  }
  return (


    <div className={classes.wrapper}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SearchBarWithPartsFilters nofilter partNumber={partNumber} setPartNumber={setPartNumber} setDescription={setDescription} description={description} handleDescriptionCopy={handleDescriptionCopy} handlePartCopy={handlePartCopy} searchText={searchText} handleSearch={handleSearch} />


          <div>
            <MaterialTable
              style={{
                boxShadow: "0px 1px 3px #00000033",
                marginTop: "10px",
              }}
              icons={tableIcons}
              classes
              title={""}
              columns={columns}
              data={data.data}
              isLoading={inProgress || loading}
              options={tableOptions}
              onChangePage={(page) => {
                TableAction.setPage(dispatch, page);
              }}
              onChangeRowsPerPage={(pageSize) => {
                TableAction.setPageSize(dispatch, pageSize);
              }}
              totalCount={totalCount}
              page={page}
            />
          </div>
          <div style={{ margin: ' 14px 0px 20px 3px' }}>
            <span style={{ padding: '6px' }}>Select an Order to view its details and add to this Order</span>
          </div>
        </Grid>
      </Grid>



      {
        action === "Selected_Order" &&
        <SelectedOrderDetails
          handleClose={() => setAction(null)}
          currentPlanningDetails={planningData}
          currentSelectedDetails={currentSelectedDetails}
          workshop_id={workshop_id}
          getWorkshopOrderDetails={getWorkshopOrderDetails}
          getMaintenanceOrderList={getMaintenanceOrderList}
          getPlanningList={getPlanningList}
          planningId={planningId}
          planningWholeDetails={planningWholeDetails}
          type={"previous_order"}
          copyTab={copyTab}
        />
      }
    </div>


  );
}

export default CopyPreviuos;
