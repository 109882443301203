import React, { useEffect, useContext, useState } from 'react'
import { Dialog, DialogContent, DialogTitle, MenuItem, Grid, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import MROButton from 'components/buttons';
import MROTextField from 'components/form_components/TextField';
import { getOrganisationId } from 'lib/utils/common_utils';
import { ToastMessageContext } from 'lib/contexts/message_context';
import QuantityCounter from 'components/quantity_counter';
import { Autocomplete } from '@material-ui/lab';
import WarningIcon from "@material-ui/icons/Warning";
import tableIcons, { options } from "components/universal/table_attributes";
import MaterialTable from 'material-table';
import ShiftServices from 'lib/services/api/planning/Shifts/shift';
import GreenTickIcon from 'assets/green_filled_tick.png'

import NumericIncrement from "views/Operations/LineMaintenance/WorkOrder/FieldComponents/numeric_increment";




const useStyles = makeStyles(theme => ({
    actionButtons: {
        paddingTop: '20px',
        paddingBottom: '33px',
        textAlign: 'right',
        '& button:not(:last-child)': {
            marginRight: '20px'
        }
    },
    modal: {
        '& .MuiDialog-paperWidthMd': {
            maxWidth: '650px',
        }
    },
    title: {
        fontSize: '18px',
        '& h2': {
            color: theme.palette.primary.main,
            textAlign: 'center'
        }
    },
    formLabel: {

        '& span:last-child': {
            fontSize: '14px',
        }
    }

}));

export default function ApproveLeave(props) {
    const classes = useStyles()
    const { handleClose, approvedData, id, getListing, onClose } = props
    const [qty, setQty] = useState();
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false)
    const message = useContext(ToastMessageContext)
    const [calculatedValue, setCalculatedValue] = useState(0)

    const [state, setState] = useState({ man_hours: '' });

    const tableOptions = {
        ...options,
        paging: false,
        search: false,
        header: false,
        toolbar: false,
        rowStyle: {
            height: '42px'
        }
    };


    useEffect(() => {
        if (parseFloat(state.man_hours) > 0) {
            let value = parseFloat(approvedData?.days)
            let value1 = parseFloat(state.man_hours)
            let toatl = parseFloat(value * value1).toFixed(2)
            setCalculatedValue(toatl)
        }
    }, [state.man_hours])

    const columns = [


        {
            title: "Title",
            field: "title",
            cellStyle: {
                textAlign: 'left',
                paddingLeft: '15px'
            },
            headerStyle: {
                textAlign: 'left',
                paddingLeft: '15px'
            }



        },

        {
            title: "va1ue",
            field: "value",
            cellStyle: {
                textAlign: 'right',
                paddingRight: '15px'
            },
            headerStyle: {
                textAlign: 'left',
                paddingRight: '15px'
            },
            render: (rowData) => <Typography style={{ color: rowData?.title === "Leave Remaining (before this request)" ? approvedData?.remaining_leave >= approvedData?.shift_hours?.leave_hours_total ? "#4FC605" : approvedData?.remaining_leave < approvedData?.shift_hours?.leave_hours_total ? '#FF0202' : '' : '' }} >

                {rowData?.value}
            </Typography >
        },


    ];
    const tableData = [
        {
            "title": "Leave Start",
            "value": approvedData?.start_datetime
        }, {
            "title": "Leave End",
            "value": approvedData?.end_datetime
        }, {
            "title": "Leave Days",
            "value": approvedData?.days
        }, {
            "title": "Calculated Shift Hours",
            "value": approvedData?.shift_hours?.shift_hours_total > 0 ? approvedData?.shift_hours?.shift_hours_total : calculatedValue

        }, {
            "title": "Leave Remaining (before this request)",
            "value": approvedData?.remaining_leave
        }, {
            "title": "Leave Period",
            "value": approvedData?.leave_period

        },


    ]

    const handleSubmit = async (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        let valid = true;
        let error = {};
        for (let pair of formData.entries()) {
            const value = pair[1];
            const key = pair[0];
            // console.table("formdata=====", key, value)
            if (!value) {
                error[key] = true;
                valid = false;

            }
        }
        if (parseFloat(state.man_hours) <= 0) {
            error["shift_hours_per_day"] = true
            valid = false

        }

        if (!valid) {
            setErrors(error);
            return;
        }

        formData.append("leave_request_id", id)
        formData.append("action", "approve")

        if (approvedData?.shift_hours?.leave_hours_total === 0) {

            approvedData.shift_hours.leave_days.forEach((item, index) => {

                formData.append(`leave_day_id[${index}]`, item.id)

            })



        }
        else if (approvedData?.shift_hours?.leave_hours_total > 0) {

            approvedData.shift_hours.leave_days.forEach((item, index) => {
                formData.append(`leave_day_id[${index}]`, item.id)
                formData.append(`hours[${index}]`, item.hours)
            })
            approvedData.shift_hours.leave_days.forEach((item, key) => {

                item.shifts.forEach((shift, index) => {
                    if (shift.leave_hours === shift.shift_hours) {
                        formData.append(`status[${key}]`, 1)
                        formData.append(`shift_id[${key}]`, shift.id)
                        formData.append(`leave_hours[${key}]`, shift.leave_hours)
                    }
                    else if (shift.leave_hours < shift.shift_hours) {
                        formData.append(`shift_id[${key}]`, shift.id)
                        formData.append(`leave_hours[${key}]`, shift.leave_hours)
                    }
                })
            })


        }
        setLoading(true)
        await ShiftServices.postLeaveActionRequest(formData).then((res) => {

            message.showToastMessage({
                message: "Leave Approved Successfully",
                variant: "success",
            });

            onClose()
            getListing()


        }).catch(() => {
            message.showToastMessage({
                message: "Something went wrong. Try again!",
                variant: "error",
            });
            handleClose()
            getListing()
        }).finally(() => {
            setLoading(false)
        })






    }


    return (
        <div>
            <Dialog
                maxWidth={"md"}
                fullWidth={true}
                open={true}
                className={classes.modal}
                onClose={handleClose}
                aria-labelledby="indirect-jobs"
            >
                <DialogTitle variant="subtitle2" color="secondary"
                    className={classes.title}><span style={{ fontSize: '16px' }}><img src={GreenTickIcon} height={'20px'} style={{ marginRight: '5px' }} />  Approve Leave</span>
                </DialogTitle>

                <Typography style={{ textAlign: 'center' }}>Check the requested shift hours verses remaining leave hours for the User.</Typography>
                <br />

                <DialogContent className={classes.card}>


                    <MaterialTable
                        style={{ boxShadow: "0px 1px 3px #00000033", paddingRight: "0px" }}
                        icons={tableIcons}
                        title={""}
                        // isLoading={loading}
                        data={tableData || []}
                        columns={columns}
                        options={tableOptions}


                    />


                    <br />

                    <Typography variant='subtitle2' style={{ fontSize: '18px', paddingLeft: '10px' }}>Cautions</Typography>

                    <Grid container direction='row' style={{ padding: '10px 10px ' }}>

                        <Grid item xs={0.8}> <WarningIcon
                            style={{
                                color: "#FFB300",
                                marginRight: "10px",
                                fontSize: 23,
                                marginTop: '2px'
                            }}
                        /></Grid>
                        <Grid item xs={10}><Typography>{approvedData?.shift_hours?.shift_hours_total === 0 ? "ZERO shift hours have been found for this request! Enter an amount of leave hours to allocate against each day for this request" : approvedData?.shift_hours?.shift_hours_total > approvedData?.remaining_leave ? "Leave requested exceeds remaining leave for the period!" : approvedData?.shift_hours?.shift_hours_total <= approvedData?.remaining_leave ? "Nil" : ""} </Typography></Grid>
                    </Grid>

                    <form onSubmit={handleSubmit} >
                        <>

                            <Grid item xs={8} style={{ paddingLeft: '20px', paddingBottom: '30px' }}>
                                {approvedData?.shift_hours?.shift_hours_total === 0 &&



                                    <NumericIncrement
                                        name={"shift_hours_per_day"}
                                        label={"Shift Hours / Day *"}
                                        error={errors["shift_hours_per_day"]}
                                        value={state.man_hours}
                                        placeholder={"Shift-Hours required"}
                                        noIncreament
                                        state={state}
                                        setState={setState}
                                        inputProps={{ min: 0, step: 0.5 }}
                                        helperText={
                                            (errors["shift_hours_per_day"] && parseFloat(state.man_hours) <= 0) ? <span style={{ color: "red" }}>QTY Required Must Be Greater Than 0</span> : (errors["shift_hours_per_day"] && state.man_hours === "") ? <span style={{ color: "red" }}>*Please Enter Shift Hrs</span> : ''
                                        }
                                        InputLabelProps={{ shrink: true }}
                                        setError={() =>
                                            setErrors((prevState) => ({
                                                ...prevState,
                                                shift_hours_per_day: false,
                                            }))
                                        }
                                    />
                                }


                            </Grid>
                            <div className={classes.actionButtons}>
                                <MROButton
                                    //   disabled={loading}
                                    type="button"
                                    variant={"contained"}
                                    onClick={handleClose}
                                >
                                    Cancel
                                </MROButton>
                                <MROButton
                                    loading={loading}
                                    type="submit"
                                    variant={"contained"}
                                    color="primary"

                                >
                                    Complete
                                </MROButton>

                            </div>
                        </>
                    </form>
                </DialogContent>
            </Dialog>

        </div>
    )
}
