import React, { useContext, useState } from 'react'
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import MROTextField from "components/form_components/TextField";
import MROButton from 'components/buttons';
import { Typography } from "@material-ui/core";
import { FormControl } from '@material-ui/core';
import { DatePicker } from "antd";
import moment from 'moment';
import { LineMaintainenceServices } from 'lib/services/api/dashboard/line_maintenance';
import { getOrganisationId } from 'lib/utils/common_utils';
import { ToastMessageContext } from 'lib/contexts/message_context';
import { downloadCsvFileFromUrlWithBase } from "lib/utils/helperFunctions";

const { RangePicker } = DatePicker;

const useStyles = makeStyles((theme) => ({
    modal: {
        "& .MuiDialog-paperWidthMd": {
            width: "38%",
        }
    },
    formWrapper: {
        background: "#fff",
        width: "100%",
    },
    title: {
        display: "flex",
        justifyContent: "center",
        textAlign: 'center',
        "& h2": {
            //   color: theme.palette.primary.main,
            fontSize: "40px",
            fontWeight: 40,
        },
    },
    subTitle: {
        fontSize: "16px",
        fontWeight: "bold",
        marginBottom: "20px",
        color: "#4D4F5C",
    },
    card: {
        padding: "9px 154px",
    },
    actionButtons: {
        paddingBottom: "33px",
        textAlign: "center",
        "& button:not(:last-child)": {
            marginRight: "20px",
        },
    },
    purchaseGrid: {
        paddingTop: "0 !important",
        paddingBottom: "0 !important",
        height: "85px",
    },
    purchaseStock: {
        display: 'flex',
        marginBottom: "20px",
        textAlign: 'center'
        // maxWidth: "274px",
    },
}));

function DownloadFile(props) {
    const classes = useStyles()
    const { handleClose, selectedFilters, selectedBase, labels } = props

    const [value, setValue] = useState([moment(labels[0]), moment(labels[labels.length - 1])]);
    const [filterData, setFilterData] = useState({});
    const message = useContext(ToastMessageContext);
    const [loading, setLoading] = useState(false)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [flag, setFlag] = useState(false)



    const handleDownloadCsvFile = async (e) => {
        e.preventDefault()
        if (value === null) {
            setFlag(true)
        }
        else {
            setLoading(true)
            const params = {
                organisation_id: getOrganisationId(),
                start_date: startDate ? filterData.start_date : moment(labels[0]).format("YYYY-MM-DD"),
                end_date: endDate ? filterData.end_date : moment(labels[labels.length - 1]).format("YYYY-MM-DD"),

            }
            selectedBase.forEach((item, index) => params[`base_station_id[${[index]}]`] = item)
            selectedFilters.forEach((item, index) => params[`line_customer_id[${[index]}]`] = item)
            await LineMaintainenceServices.getDownloadCSVMaintHrs(params).then((response) => {
                downloadCsvFileFromUrlWithBase(response.file);
            }).catch(() => {
                message.showToastMessage({
                    message: "Something went wrong. Try again!",
                    variant: "error",
                });
            }).finally(() => {
                setLoading(false)
                handleClose()
            })
        }
    }

    const handleDateChange = (value) => {
        setValue(value)
        setStartDate(true)
        setEndDate(true)
        setFlag(false)
        if (value) {
            const start_date = moment(value[0]).format("YYYY-MM-DD");
            const end_date = moment(value[1]).format("YYYY-MM-DD");

            setFilterData((prevState) => ({ ...prevState, start_date, end_date }));
        } else {
            const fData = { ...filterData };
            filterData?.start_date && delete fData.start_date;
            filterData?.end_date && delete fData.end_date;
            setFilterData({ ...fData });
        }


    };

    return (
        <div>
            <Dialog
                maxWidth={"md"}
                open={true}
                className={classes.modal}
                onClose={handleClose}
                aria-labelledby="download"
            >

                <DialogTitle>
                    <Typography
                        color="primary"
                        className={classes.title}
                    >
                        Download
                    </Typography>
                    <br />

                    <Typography style={{ marginBottom: '20px' }} className={classes.title}>   Select and date range to download historical man-hours from the filtered / selected Bases</Typography>
                </DialogTitle>
                <DialogContent className={classes.card}>
                    <div className={classes.formWrapper}>
                        <Grid
                            className={classes.purchaseStock}
                            container
                            spacing={1}
                            alignItems={"center"}
                            justify={"flex-start"}
                        >


                            <Grid xs={12} style={{ marginBottom: '20px' }}>

                                <FormControl className="filter">

                                    <RangePicker
                                        value={value}
                                        format="DD/MM/YYYY"
                                        onChange={handleDateChange}
                                        allowClear={true}
                                        popupStyle={{
                                            positionFixed: true,
                                            zIndex: 99999,
                                            overFlow: 'visible'// use this to make the popper position: fixed
                                        }}

                                        required={true}
                                        style={{ border: flag ? '1px solid red' : '' }}
                                    />


                                </FormControl>




                            </Grid>
                            {flag && <span style={{ color: 'red', marginTop: '-12px', paddingLeft: '13px', fontSize: '12px', marginBottom: '16px' }}>*please enter a date</span>}
                        </Grid>
                        <div className={classes.actionButtons}>
                            <MROButton
                                //   disabled={loading}
                                type="button"
                                variant={"contained"}
                                onClick={handleClose}
                            >
                                Cancel
                            </MROButton>
                            <MROButton
                                //   disabled={loading}
                                loading={loading}
                                type="submit"
                                variant={"contained"}
                                color="primary"
                                onClick={handleDownloadCsvFile}
                            >
                                Download
                            </MROButton>
                        </div>
                    </div>

                </DialogContent>
            </Dialog>

        </div>
    )
}

export default DownloadFile