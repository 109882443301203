import React, { useState, useContext, useEffect } from "react";
import MROFullScreenRightDrawer from "components/fullwidth_dialog";
import {
    AppBar,
    IconButton,
    Toolbar,
    makeStyles,
    Typography,
    Box,
    ButtonGroup,
    Button,
    FormControlLabel,
} from "@material-ui/core";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import MROButton from "components/buttons";
import SearchBarWithPartsFilters from "components/search_with_filters_parts";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import MaterialTable from "material-table";
import TableAction from "actions/table_actions";
import tableIcons, { options } from "components/universal/table_attributes";
import { Alert } from "@material-ui/lab";
import clxc from "clsx";
import { getOrganisationId } from "lib/utils/common_utils";
import useDebounce from "lib/utils/debounce";
import { CommercialServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/commercial";
import { ToastMessageContext } from "lib/contexts/message_context";
import { Visibility } from "@material-ui/icons";
import { ICON_COLOUR } from "lib/constants/style_constants";
import Historcial from "./Historcial";
import PriceBreaks from "../../QuotePopUp/PriceBreaks";
import PositiveSwitch from "components/form_components/switch";

const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: "#fff",
        color: "#000000CC",
        boxShadow: "0px 3px 6px #0000001A",
    },
    backButton: {
        marginRight: theme.spacing(2),
    },
    root: { "& > :first-child": { position: "unset" } },
    wrapper: {
        padding: "3%",
        background: "white",
    },
    alert: {
        display: "flex",
        justifyContent: "center",
        //  color: '#FFB300',
        backgroundColor: `rgba(255, 179, 0, 0.15)`
    },
    title: {
        fontSize: '16px',
        color: theme.palette.primary.main
    },
}));

export default function PartPricing(props) {


    const { handleClose, currentWorkshopDetails, setInput, input, name, setTatDays, setTatquantity, setStandardId, salesRate } = props
    const classes = useStyles();
    const [partNumber, setPartNumber] = useState('')
    const [description, setDescription] = useState('')
    const [data, setData] = useState([])
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [totalCount, setTotalCount] = useState(0)
    const [searchText, setSearchText] = useState("");
    const [action, setAction] = useState(null)
    const [currentSelection, setCurrentSelection] = useState(null)
    const [checked, setChecked] = useState(false)
    const [jobsData, setJobsData] = useState([])
    const [pages, setPages] = useState(0)
    const [pageSizes, setPageSizes] = useState(10)
    const [totalCounts, setTotalCounts] = useState(0)


    const debouncedVal = useDebounce(searchText);
    const [loading, setLoading] = useState(false)
    const message = useContext(ToastMessageContext)


    const tableOptions = {
        ...options,
        page: page,
        total: totalCount,
        pageSize: pageSize,
        search: false,
    };
    const tableOptions1 = {
        ...options,
        page: pages,
        total: totalCounts,
        pageSize: pageSizes,
        search: false,
    };

    useEffect(() => {
        if (currentWorkshopDetails) {
            setPartNumber(currentWorkshopDetails?.part_number)
            setDescription(currentWorkshopDetails?.description)
        }

    }, [currentWorkshopDetails])

    // console.log("quote==============", currentWorkshopDetails)
    const getDetails = async () => {
        const params = {
            organisation_id: getOrganisationId(),
            page: page + 1,
            limit: pageSize,
            search_part_desc: debouncedVal,


        }
        setLoading(true)
        await CommercialServices.getStandardPricing(params).then((res) => {
            setData(res.data)
            setTotalCount(res.total_count)
        }).catch(() => {
            message.showToastMessage({
                message: "Something went wrong. Try again!",
                variant: "error",
            });
        }).finally(() => {
            setLoading(false)
        })




    }

    useEffect(() => {
        getDetails()
    }, [page, pageSize, debouncedVal])



    //HistoricalJob


    const getHistoricalDetails = async () => {
        const params = {
            organisation_id: getOrganisationId(),
            page: pages + 1,
            limit: pageSizes,
            search_part_desc: debouncedVal,
            show_per_unit_values: checked,

        }
        setLoading(true)
        await CommercialServices.getHistoricalJobs(params).then((res) => {

            setJobsData(res.data)
            setTotalCounts(res.total_count)
        }).catch(() => {
            message.showToastMessage({
                message: "Something went wrong. Try again!",
                variant: "error",
            });
        }).finally(() => {
            setLoading(false)
        })




    }

    useEffect(() => {
        getHistoricalDetails()
    }, [pages, pageSizes, debouncedVal, checked])
    // console.log("labour===", currentWorkshopDetails)


    const handleCalculation = (rowData) => {
        let saleRate = +currentWorkshopDetails?.salesRate || +salesRate
        let quantity = +currentWorkshopDetails?.quantity
        let labour = rowData?.labour ? +rowData.labour * quantity : 0
        let parts = rowData?.parts ? +rowData?.parts * quantity : 0
        let services = rowData?.services ? +rowData?.services * quantity : 0
        let equipment = rowData?.equipment ? +rowData?.equipment * quantity : 0

        let labourValue = parseFloat(labour / saleRate).toFixed(2)
        let partsValue = parseFloat(parts / saleRate).toFixed(2)
        let servicesValue = parseFloat(services / saleRate).toFixed(2)
        let equipmentValue = parseFloat(equipment / saleRate).toFixed(2)
        // console.log("labour===", labour, labour / saleRate, saleRate)
        if (name === "quote") {
            setStandardId(rowData?.id)
            setTatquantity(rowData?.tat ? +rowData?.tat : 0)
            setInput({
                // tat_days: +input?.tat_days || "0",
                labour: +labourValue || "0",
                parts: +partsValue || "0",
                services: +servicesValue || "0",
                equipment: +equipmentValue || "0",
                aog_fee: +input?.aog_fee || "0",
            });
            handleClose()
        }

        else {
            setStandardId(rowData?.id)
            setInput({

                labour_value: +labourValue || "0",
                parts_value: +partsValue || "0",
                service_value: +servicesValue || "0",
                equipment_value: +equipmentValue || "0",
                aog_fee: +input?.aog_fee || "0",
            });

            // setTatDays(+rowData?.tat)
            handleClose()
        }





    }


    const handlePartCopy = () => {
        setPage(0);
        setSearchText(partNumber)

    }
    const handleDescriptionCopy = () => {
        setPage(0);
        setSearchText(description)

    }

    const handleSearch = (value) => {

        setPage(0);
        setSearchText(value);
    }


    const renderDueDayStatus = (rowData) => {
        let buttonColor = '';
        if (rowData?.age < 275)
            buttonColor = "button-positive";
        else if (rowData?.age >= 275 && rowData?.age <= 547)
            buttonColor = "button-warning";
        else if (rowData?.age > 547)
            buttonColor = "button-negative";
        return <Button
            disabled
            size="small"
            className={clxc(classes.button, buttonColor)}
            variant="outlined"
        ><b>{rowData.age} Days</b>
        </Button>
    }


    const columns = [
        {
            title: "Part #",
            field: "part_number",
            width: "10%",
            headerStyle: {
                width: "10%",
                textAlign: "left",
                paddingLeft: "10px",
            },
            cellStyle: {
                width: "10%",
                textAlign: "left",
                paddingLeft: "10px",
            },
        },
        {
            title: "Description",
            field: "description",
            width: "20%",
            headerStyle: {
                textAlign: "left",
                width: "20%",
            },
            cellStyle: {
                width: "20%",
                textAlign: "left",

            },

        },

        {
            title: "Work / Status",
            field: "status_work",
            headerStyle: {
                textAlign: "left",
            },
            cellStyle: {
                textAlign: "left",
                // padding: "10px 5px"
            },
        },
        {
            title: "TAT",
            field: "tat" || "0.00",

        },

        {
            title: "Labour *",
            field: "labour" || "0.00",
            render: (rowData) => rowData?.labour ? parseFloat(rowData?.labour).toFixed(2) : "0.00 ",
        },
        {
            title: "Parts",
            field: "parts" || "0.00",
            render: (rowData) => rowData?.parts ? parseFloat(rowData?.parts).toFixed(2) : "0.00 ",
        },
        {
            title: "Services",
            field: "services" || "0.00",
            render: (rowData) => rowData?.services ? parseFloat(rowData?.services).toFixed(2) : "0.00 ",
        },
        {
            title: "Equipment",
            field: "equipment" || "0.00",
            render: (rowData) => rowData?.equipment ? parseFloat(rowData?.equipment).toFixed(2) : "0.00 ",
        },

        {
            title: "Total",
            field: "price" || "0.00",
            render: (rowData) => rowData?.total ? parseFloat(rowData?.total).toFixed(2) : "0.00 ",
        },
        {
            title: "Currency",
            field: "currency" || "0.00",
        },
        {
            title: "Age",
            field: "age",
            headerStyle: {
                textAlign: "center",
            },
            cellStyle: {
                textAlign: "center",
                // padding: "10px 5px"
            },
            render: rowData => renderDueDayStatus(rowData)
        },

        {
            title: "Action",
            render: (rowData) => (
                <IconButton onClick={() => {
                    handleCalculation(rowData)
                }}>
                    <TouchAppIcon />
                </IconButton>
            ),
        },
    ];

    const jobsColumns = [
        {
            title: "Part #",
            field: "part_number",
            width: "10%",
            headerStyle: {
                width: "10%",
                textAlign: "left",
                paddingLeft: "10px",
            },
            cellStyle: {
                width: "10%",
                textAlign: "left",
                paddingLeft: "10px",
            },
        },
        {
            title: "Description",
            field: "description",
            width: "20%",
            headerStyle: {
                textAlign: "left",
                width: "20%",
            },
            cellStyle: {
                width: "20%",
                textAlign: "left",

            },
        },
        {
            title: "Order #",
            field: "order_number",
            headerStyle: {
                textAlign: "left",
            },
            cellStyle: {
                textAlign: "left",
                width: "10%"
            }
        },
        {
            title: "Quantity",
            field: "quantity",
        },
        {
            title: "TAT",
            field: "tat",

        },
        {
            title: "Value",
            field: "value",

        },
        {
            title: "Costs",
            field: "costs",
        },
        {
            title: "Margin %",
            field: "margin",
        }, {
            title: "Currency",
            field: "currency",
        },
        {
            title: "Action",
            render: (rowData) => (
                <IconButton onClick={() => {
                    setAction("view")
                    setCurrentSelection(rowData)
                }}>
                    <Visibility fontSize="small" htmlColor={ICON_COLOUR} />
                </IconButton>
            ),
        },
    ];


    // function SubTable(props) {
    //     const [data, setData] = useState([])
    //     console.log("rowDat===", props)
    //     useEffect(() => {
    //         if (props?.rowData) {
    //             setData(props?.rowData?.child)
    //         }
    //     }, [props]);

    //     return (
    //         <div >
    //             <MaterialTable
    //                 style={{ boxShadow: 'none' }}
    //                 icons={tableIcons}
    //                 classes
    //                 title={""}
    //                 columns={column4}
    //                 data={data || []}
    //                 // isLoading={loading}
    //                 options={{
    //                     ...options,
    //                     toolbar: false,
    //                     paging: false,
    //                     header: false,

    //                 }}
    //             />


    //         </div>
    //     );
    // }

    const column4 = [
        {
            title: "Order #",
            field: "title",
            width: "60.6%",
            headerStyle: {
                width: "60.6%",
                textAlign: "left",
                paddingLeft: '80px'
            },
            cellStyle: {
                width: "60.6%",
                textAlign: "left",
                paddingLeft: '80px'
            },
        },



        {
            title: "Value",
            field: "value",
            width: "8%",
            headerStyle: {
                width: "8%",
                textAlign: "left",
                paddingLeft: '23px'
            },
            cellStyle: {
                width: "8%",
                textAlign: "left",
                paddingLeft: '23px'
            },
        },
        {
            title: "Costs",
            field: "costs",

            width: "8%",
            headerStyle: {
                width: "8%",
                textAlign: "left",
                paddingLeft: '14px'
            },
            cellStyle: {
                width: "8%",
                textAlign: "left",
                paddingLeft: '14px'
            },
        },
        {
            title: "Margin %",
            field: "margin",

            width: "8%",
            headerStyle: {
                width: "8%",
                textAlign: "left",
                paddingLeft: '14px'
            },
            cellStyle: {
                width: "8%",
                textAlign: "left",
                paddingLeft: '14px'
            },
        },
        {
            title: "Action",
            field: " ",
            width: "20.85%",

            headerStyle: {
                width: "20.85%",
                textAlign: "left",
                paddingLeft: '15px'
            },
            cellStyle: {
                width: "20.85%",
                textAlign: "left",
                paddingLeft: '15px'
            },
        },




    ];

    return (
        <MROFullScreenRightDrawer open={true} className={classes.root}>
            <AppBar position="static" className={classes.appBar} elevation={0}>
                <Toolbar>
                    <IconButton
                        onClick={() => {
                            handleClose()
                        }}
                        edge="start"
                        className={classes.backButton}
                        color="inherit"
                        aria-label="back"
                    >
                        <BackIcon />
                    </IconButton>
                    <Typography variant="subtitle2">Part Pricing Lookup</Typography>
                </Toolbar>
            </AppBar>
            <div className={classes.wrapper}>


                <Typography variant="subtitle1" color="primary" className={classes.title}>Search Part</Typography>

                <SearchBarWithPartsFilters nofilter partNumber={partNumber} setPartNumber={setPartNumber} setDescription={setDescription} description={description} handleDescriptionCopy={handleDescriptionCopy} handlePartCopy={handlePartCopy} searchText={searchText} handleSearch={handleSearch} />

                <Box style={{ marginTop: '-26px' }}>
                    <Alert className={classes.alert} icon={false}>
                        <span style={{ color: "red" }}>Attention!</span> All pricing data is shown in the system default currency per unit (qty 1). On selection the relevant quantity based values will pulled into the current job in the Customer’s currency
                    </Alert>
                </Box>

                <Typography variant="subtitle1" color="primary" className={classes.title} style={{ marginTop: '5px' }}>Standard Pricing</Typography>


                <div style={{ marginTop: '15px' }}>
                    <MaterialTable
                        style={{
                            boxShadow: "0px 1px 3px #00000033",
                            marginTop: "10px",
                        }}
                        icons={tableIcons}
                        classes
                        title={""}
                        columns={columns}
                        data={data || []}
                        isLoading={loading}
                        options={tableOptions}
                        onChangePage={(page) => {
                            setPage(page);
                        }}
                        onChangeRowsPerPage={(pageSize) => {
                            setPageSize(pageSize);
                        }}
                        totalCount={totalCount}
                        page={page}
                    />
                </div>
                <Typography variant="subtitle1" color="primary" className={classes.title} style={{ marginTop: '15px' }}>Historic Jobs</Typography>


                <div style={{ marginTop: '15px' }}>
                    <MaterialTable
                        style={{
                            boxShadow: "0px 1px 3px #00000033",
                            marginTop: "10px",
                        }}
                        icons={tableIcons}
                        classes
                        title={""}
                        columns={jobsColumns}
                        data={jobsData || []}
                        isLoading={loading}
                        options={tableOptions1}
                        onChangePage={(page) => {
                            setPages(page);
                        }}
                        onChangeRowsPerPage={(pageSize) => {
                            setPageSizes(pageSize);
                        }}
                        totalCount={totalCounts}
                        page={pages}
                        actions={[
                            {
                                icon: () => (
                                    <FormControlLabel
                                        style={{ fontSize: '14px', color: "#000000CC" }}
                                        control={
                                            <PositiveSwitch
                                                checked={checked}
                                                onChange={(e) => setChecked(e.target.checked)}
                                            // name="revision_controlled"
                                            />
                                        }
                                        label="Show per unit values"
                                        labelPlacement="start"
                                        name=""
                                    />
                                ),
                                tooltip: "",
                                isFreeAction: true,

                            },
                        ]}
                        detailPanel={[
                            {
                                render: rowData => {
                                    let data = rowData?.child || []
                                    return (
                                        <div >
                                            <MaterialTable
                                                style={{ boxShadow: 'none' }}
                                                icons={tableIcons}
                                                classes
                                                title={""}
                                                columns={column4}
                                                data={data || []}
                                                // isLoading={loading}
                                                options={{
                                                    ...options,
                                                    toolbar: false,
                                                    paging: false,
                                                    header: false,

                                                }}
                                            />


                                        </div>)


                                }



                            }



                        ]}
                    />
                </div>


                {action && action === "view" && <Historcial handleClose={() => setAction(null)} currentSelection={currentSelection} checked={checked} currentWorkshopDetails={currentWorkshopDetails} setInput={setInput} input={input} onClose={handleClose} name={name} salesRate={salesRate} setTatquantity={setTatquantity} setStandardId={setStandardId} setTatDays={setTatDays} />}


            </div>



        </MROFullScreenRightDrawer>
    )
}
