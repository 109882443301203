import React from 'react'
import { Grid, Paper, Typography, Box, Button, Divider, makeStyles } from "@material-ui/core";
import { getRates } from 'actions/admin/line/adhoc_rates_actions';
import Gear from 'assets/gear.svg'
import Wrench from 'assets/wrench.svg'

function Stores(props) {

    const { shellLife, tooling } = props
    return (
        <Box mt={2}>

            <Paper style={{ width: '100%', height: '350px' }}>

                <Box width="100%" display="flex" p={2}>

                    <Box width="98%">
                        <Grid>
                            <Typography gutterBottom={20} variant="subtitle2" color='primary'>Stores</Typography>
                        </Grid>
                        <Grid container direction='column' spacing={2}>
                            <Grid style={{ textAlign: "center", marginBottom: "20px" }}>
                                <Typography gutterBottom={20}>Shelf life Parts</Typography>
                            </Grid>
                            <Grid container direction='row' style={{ marginBottom: "35px" }}>
                                <Grid item xs={4} container direction='column' style={{ textAlign: "center" }} >
                                    <Grid item ><Typography gutterBottom={20} variant="h5" style={{ color: "#FFB300" }}>{shellLife.amber}</Typography></Grid>
                                    <Grid ><small > &lt; 30 Days</small></Grid></Grid>
                                <Grid item xs={4} style={{ textAlign: "center" }} >
                                    <img src={Gear} style={{ height: '50px', color: '#000000' }} />
                                </Grid>
                                <Grid item xs={4} container direction='column' style={{ textAlign: "center" }} >
                                    <Grid item ><Typography gutterBottom={20} variant="h5" style={{ color: "#DF0011" }}> {shellLife.red}</Typography></Grid>
                                    <Grid ><small > &lt; 0 Days</small></Grid></Grid>
                            </Grid>
                        </Grid>
                    </Box>

                </Box>

                <Divider />
                <Box width="100%" display="flex" p={2}>
                    <Box width="98%">

                        <Grid container direction='column' spacing={2} style={{ marginBottom: "24px" }}>
                            <Grid style={{ textAlign: "center", marginBottom: "20px" }}>
                                <Typography gutterBottom={20}>Tools Due Cal / Insp</Typography>
                            </Grid>
                            <Grid container direction='row'  >
                                <Grid item xs={4} container direction='column' style={{ textAlign: "center" }} >
                                    <Grid item ><Typography gutterBottom={20} variant="h5" style={{ color: "#FFB300" }}> {tooling.amber}</Typography></Grid>
                                    <Grid ><small > &lt; 30 Days</small></Grid></Grid>
                                <Grid item xs={4} style={{ textAlign: "center" }} >
                                    <img src={Wrench} style={{ height: '50px', color: '#000000' }} />
                                </Grid>
                                <Grid item xs={4} container direction='column' style={{ textAlign: "center" }} >
                                    <Grid item ><Typography gutterBottom={20} variant="h5" style={{ color: "#DF0011" }}> {tooling.red}</Typography></Grid>
                                    <Grid ><small > &lt; 0 Days</small></Grid></Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>

            </Paper>
        </Box>

    )
}

export default Stores