import { AppBar, Toolbar, makeStyles, IconButton, Typography, Grid, Box } from '@material-ui/core'
import MROFullScreenRightDrawer from 'components/fullwidth_dialog'
import React, { useReducer, useState } from 'react'
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import Alert from '@material-ui/lab/Alert';
import AlertIcon from '@material-ui/icons/ReportProblem';
import Actions from './CopyTables/Actions';
import Parts from './CopyTables/Parts';
import Services from './CopyTables/Services';
import Equipments from './CopyTables/Equipments';


const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: "#fff",
        color: "#000000CC",
        boxShadow: "0px 3px 6px #0000001A",
    },
    backButton: {
        marginRight: theme.spacing(2),
    },
    wrapper: {
        margin: "3%",
        // overflow: "hidden !important"
    },

}));

function CopyActions(props) {
    const classes = useStyles()
    const { handleClose, currentSelection, currentWorkshopDetails, currentClosedDetails, orderType } = props
    const [currentActionId, setCurrentActionId] = useState('')
    console.log("orderType=====", orderType, currentClosedDetails)
    return (
        <div>
            <MROFullScreenRightDrawer open={true}>
                <AppBar position="static" className={classes.appBar} elevation={0}>
                    <Toolbar>
                        <IconButton
                            onClick={handleClose}
                            edge="start"
                            className={classes.backButton}
                            color="inherit"
                            aria-label="back"
                        >
                            <BackIcon />
                        </IconButton>
                        <Typography variant="subtitle2" style={{ fontSize: "17px" }}>
                            Actions
                        </Typography>
                    </Toolbar>
                </AppBar>

                <div className={classes.wrapper}>


                    <Grid >
                        <Alert severity="error" icon={<AlertIcon color={'#FF0202'} />} >
                            <Typography style={{ fontSize: '13px' }}>Be sure to copy Actions in the correct order you want them on the job. First copy the Action, then the Parts, Services and Equipment as applicable. Do not leave this page until you have completed ALL copying!</Typography>
                        </Alert>
                    </Grid>

                    <br />
                    {/* Actionns Table */}
                    <Grid>
                        <Typography color='primary'>Action</Typography>
                        <Actions currentActionData={currentSelection} currentWorkshopDetails={currentWorkshopDetails} setCurrentActionId={setCurrentActionId} />
                    </Grid>
                    <br />
                    {/* Parts */}
                    <Grid>
                        <Typography color='primary'>Parts</Typography>
                        <Parts currentActionData={currentSelection} currentWorkshopDetails={currentWorkshopDetails} currentClosedDetails={currentClosedDetails} currentActionId={currentActionId} orderType={orderType} />
                    </Grid>
                    <br />
                    {/* Service */}
                    <Grid>
                        <Typography color='primary'>Services</Typography>
                        <Services currentActionData={currentSelection} currentWorkshopDetails={currentWorkshopDetails} currentClosedDetails={currentClosedDetails} currentActionId={currentActionId} orderType={orderType} />
                    </Grid>
                    <br />
                    {/* equipment */}
                    <Grid>
                        <Typography color='primary'>Equipment</Typography>
                        <Equipments currentActionData={currentSelection} currentWorkshopDetails={currentWorkshopDetails} currentClosedDetails={currentClosedDetails} currentActionId={currentActionId} orderType={orderType} />
                    </Grid>
                </div>

            </MROFullScreenRightDrawer>

        </div>
    )
}

export default React.memo(CopyActions)