import React, { useState, useEffect, useContext } from "react";
import MROFullScreenRightDrawer from "components/fullwidth_dialog";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import Typography from "@material-ui/core/Typography";
import { Box, CircularProgress } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { ICON_COLOUR } from "lib/constants/style_constants";
import { ToastMessageContext } from "lib/contexts/message_context";
import EditStage from "./EditStage";
import { InspectionServices } from "lib/services/api/operaitons/WorkShop/Inspection/InspectionService";
import _ from "lodash";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: "3%",
  },
  description: {
    borderRadius: "5px",
    height: "250px",
    fontSize: "15px",
    backgroundColor: "#F5F5F5",
    padding: theme.spacing(1),
  },
  root: {
    backgroundColor: "#ffffff",
  },
  appBar: {
    backgroundColor: "#fff",
    color: "#000000CC",
    boxShadow: "0px 3px 6px #0000001A",
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  paper: {
    borderRadius: "5px",
    backgroundColor: "#F5F5F5",
    padding: theme.spacing(2),
  },
  partValue: {
    fontSize: "14px",
    color: "#4D4F5C",
    padding: "2px",
    display: "flex",
    alignItems: "center",
  },
  partKey: {
    "& p": {
      fontSize: "14px",
      padding: "1px",
    },
  },
  partCard: {
    borderRadius: "8px",
    padding: "17px 24px",
    "& p": {
      fontSize: "14px",
      padding: "5px",
    },
  },
  sec1: {
    borderRadius: "10px",
    backgroundColor: "#fff",
    padding: theme.spacing(5),
    boxShadow: "0px 1px 4px #0000001A",
  },
  EditIcon: {
    bgcolor: "primary",
    color: "#fff",
  },
  editButton: {
    marginLeft: "300px",
  },
}));

function StageInfo(props) {
  const classes = useStyles();
  const { handleClose, action_Id, styleProp, getAllActionStages } = props;
  const [action, setAction] = useState(null);
  const [loading, setLoading] = useState(false);
  const message = useContext(ToastMessageContext);
  const [selectedAction, setSelectedAction] = useState([]);
  const [fieldZero, setFieldZero] = useState(null);
  const [fieldOne, setFieldOne] = useState(null);
  const [fieldTwo, setFieldTwo] = useState(null);
  const [fieldThree, setFieldThree] = useState(null);
  const [fieldFour, setFieldFour] = useState(null);
  const [fieldFive, setFieldFive] = useState(null);
  const [fieldSix, setFieldSix] = useState(null);
  const [fieldSeven, setFieldSeven] = useState(null);
  const [fieldEight, setFieldEight] = useState(null);
  const [fieldNine, setFieldNine] = useState(null);

  const handleCloseEdit = () => {
    if (action === "EditStage") {
      setAction(null);
    }
  };

  useEffect(() => {
    getStageAction();
  }, []);

  const getStageAction = async () => {
    setLoading(true);
    const params = {
      id: action_Id,
    };
    await InspectionServices.getStageAction(params)
      .then((res) => {
        if (res) {
          setLoading(false);
          setSelectedAction(res.data);
        }
      })
      .catch(() => {
        setLoading(false);
        message.showToastMessage({
          message: "Something went wrong. Try again!",
          variant: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const closeHHandler = () => {
    handleClose();
    props.getActionStages();
  };
  useEffect(() => {
    if (selectedAction?.sub_fields != undefined) {
      setFieldZero(
        selectedAction?.sub_fields[0]?.field_name === "null"
          ? ""
          : selectedAction?.sub_fields[0]?.field_name
      );
      setFieldOne(
        selectedAction?.sub_fields[1]?.field_name === "null"
          ? ""
          : selectedAction?.sub_fields[1]?.field_name
      );
      setFieldTwo(
        selectedAction?.sub_fields[2]?.field_name === "null"
          ? ""
          : selectedAction?.sub_fields[2]?.field_name
      );
      setFieldThree(
        selectedAction?.sub_fields[3]?.field_name === "null"
          ? ""
          : selectedAction?.sub_fields[3]?.field_name
      );
      setFieldFour(
        selectedAction?.sub_fields[4]?.field_name === "null"
          ? ""
          : selectedAction?.sub_fields[4]?.field_name
      );
      setFieldFive(
        selectedAction?.sub_fields[5]?.field_name === "null"
          ? ""
          : selectedAction?.sub_fields[5]?.field_name
      );
      setFieldSix(
        selectedAction?.sub_fields[6]?.field_name === "null"
          ? ""
          : selectedAction?.sub_fields[6]?.field_name
      );
      setFieldSeven(
        selectedAction?.sub_fields[7]?.field_name === "null"
          ? ""
          : selectedAction?.sub_fields[7]?.field_name
      );
      setFieldEight(
        selectedAction?.sub_fields[8]?.field_name === "null"
          ? ""
          : selectedAction?.sub_fields[8]?.field_name
      );
      setFieldNine(
        selectedAction?.sub_fields[9]?.field_name === "null"
          ? ""
          : selectedAction?.sub_fields[9]?.field_name
      );
    }
    // }
  }, [selectedAction]);
  return (
    <MROFullScreenRightDrawer open={true}>
      <AppBar position="static" className={classes.appBar} elevation={0}>
        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
          <Box display="flex" alignItems="center">
            <IconButton
              onClick={closeHHandler}
              edge="start"
              className={classes.backButton}
              color="inherit"
              aria-label="back"
            >
              <BackIcon />
            </IconButton>
            <Typography variant="subtitle2" style={{ fontSize: "17px" }}>
              Stage Information
            </Typography>
          </Box>
          {props.permission.write && <Box style={{ display: styleProp }}>
            <IconButton onClick={() => setAction("EditStage")}>
              <EditIcon fontSize="small" htmlColor={ICON_COLOUR} />
            </IconButton>
          </Box>}
        </Toolbar>
      </AppBar>

      <div className={classes.root}>
        <div className={classes.wrapper}>
          {loading && _.isEmpty(selectedAction) ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="700px"
            >
              <CircularProgress color="primary" size={40} />
            </Box>
          ) : (
            <>
              <Grid>
                <Typography
                  color="primary"
                  varient="h3"
                  style={{ marginBottom: "10px", marginTop: "10px" }}
                >
                  Stage Details
                </Typography>
              </Grid>
              <Grid className={classes.paper}>
                <Box display="flex">
                  <Box flex={18} className={classes.partKey}>
                    <Typography color="primary">Raised by</Typography>
                  </Box>
                  <Box flex={38} className={classes.partKey}>
                    <Typography>{selectedAction?.raised_by}</Typography>
                  </Box>
                  <Box flex={18} className={classes.partKey}>
                    <Typography color="primary">Raised</Typography>
                  </Box>
                  <Box flex={38} className={classes.partKey}>
                    <Typography>
                      {moment(
                        selectedAction?.raised,
                        "YYYY/MM/DD HH:mm:ss"
                      ).format("DD-MM-YYYY HH:mm")}
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex">
                  <Box flex={18} className={classes.partKey}>
                    <Typography color="primary">Title</Typography>
                  </Box>
                  <Box flex={38} className={classes.partKey}>
                    <Typography>{selectedAction?.title}</Typography>
                  </Box>
                  <Box flex={18} className={classes.partKey}>
                    <Typography color="primary"></Typography>
                  </Box>
                  <Box flex={38} className={classes.partKey}>
                    <Typography></Typography>
                  </Box>
                </Box>
              </Grid>

              <Typography
                color="primary"
                varient="h3"
                style={{ marginBottom: "10px", marginTop: "10px" }}
              >
                Detailed Description
              </Typography>
              <Grid className={classes.description}>
                <Typography style={{ fontSize: "15px" }}>
                  {selectedAction?.description}
                </Typography>
              </Grid>

              <Grid>
                <Typography
                  color="primary"
                  varient="h3"
                  style={{ marginBottom: "10px", marginTop: "10px" }}
                >
                  Sub-Fields
                </Typography>
              </Grid>
              <Grid className={classes.paper}>
                <Box display="flex">
                  <Box flex={18} className={classes.partKey}>
                    <Typography color="primary">Sub Field 1</Typography>
                  </Box>
                  <Box flex={38} className={classes.partKey}>
                    <Typography>{fieldZero || "-"}</Typography>
                  </Box>
                  <Box flex={18} className={classes.partKey}>
                    <Typography color="primary">Sub Field 2</Typography>
                  </Box>
                  <Box flex={38} className={classes.partKey}>
                    <Typography>{fieldOne || "-"}</Typography>
                  </Box>

                </Box>

                <Box display="flex">
                  <Box flex={18} className={classes.partKey}>
                    <Typography color="primary">Sub Field 3</Typography>
                  </Box>
                  <Box flex={38} className={classes.partKey}>
                    <Typography>{fieldTwo || "-"}</Typography>
                  </Box>
                  <Box flex={18} className={classes.partKey}>
                    <Typography color="primary">Sub Field 4</Typography>
                  </Box>
                  <Box flex={38} className={classes.partKey}>
                    <Typography>{fieldThree || "-"}</Typography>
                  </Box>

                </Box>
                <Box display="flex">

                  <Box flex={18} className={classes.partKey}>
                    <Typography color="primary">Sub Field 5</Typography>
                  </Box>
                  <Box flex={38} className={classes.partKey}>
                    <Typography>{fieldFour || "-"}</Typography>
                  </Box>
                  <Box flex={18} className={classes.partKey}>
                    <Typography color="primary">Sub Field 6</Typography>
                  </Box>
                  <Box flex={38} className={classes.partKey}>
                    <Typography>{fieldFive || "-"}</Typography>
                  </Box>

                </Box>
                <Box display="flex">
                  <Box flex={18} className={classes.partKey}>
                    <Typography color="primary">Sub Field 7</Typography>
                  </Box>
                  <Box flex={38} className={classes.partKey}>
                    <Typography>{fieldSix || "-"}</Typography>
                  </Box>

                  <Box flex={18} className={classes.partKey}>
                    <Typography color="primary">Sub Field 8</Typography>
                  </Box>
                  <Box flex={38} className={classes.partKey}>
                    <Typography>{fieldSeven || "-"}</Typography>
                  </Box>

                </Box>
                <Box display="flex">
                  <Box flex={18} className={classes.partKey}>
                    <Typography color="primary">Sub Field 9</Typography>
                  </Box>
                  <Box flex={38} className={classes.partKey}>
                    <Typography>{fieldEight || "-"}</Typography>
                  </Box>
                  <Box flex={18} className={classes.partKey}>
                    <Typography color="primary">Sub Field 10</Typography>
                  </Box>
                  <Box flex={38} className={classes.partKey}>
                    <Typography>{fieldNine || "-"}</Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid>
                <Typography
                  color="primary"
                  varient="h3"
                  style={{ marginBottom: "10px", marginTop: "10px" }}
                >
                  Closure
                </Typography>
              </Grid>
              <Grid className={classes.paper}>
                <Box display="flex">
                  <Box flex={18} className={classes.partKey}>
                    <Typography color="primary">Mech Closed</Typography>
                  </Box>
                  <Box flex={38} className={classes.partKey}>
                    <Typography>{"-"}</Typography>
                  </Box>
                  <Box flex={18} className={classes.partKey}>
                    <Typography color="primary">Mech</Typography>
                  </Box>
                  <Box flex={38} className={classes.partKey}>
                    <Typography></Typography>
                  </Box>
                </Box>
                <Box display="flex">
                  <Box flex={18} className={classes.partKey}>
                    <Typography color="primary">Insp Closed</Typography>
                  </Box>
                  <Box flex={38} className={classes.partKey}>
                    <Typography>{"-"}</Typography>
                  </Box>
                  <Box flex={18} className={classes.partKey}>
                    <Typography color="primary">Insp</Typography>
                  </Box>
                  <Box flex={38} className={classes.partKey}>
                    <Typography></Typography>
                  </Box>
                </Box>
              </Grid>
            </>
          )}
        </div>
      </div>
      {action === "EditStage" && (
        <EditStage
          getAllActionStages={getAllActionStages}
          action_Id={action_Id}
          closeStageHandler={handleClose}
          handleClose={handleCloseEdit}
        />
      )}
    </MROFullScreenRightDrawer>
  );
}

export default StageInfo;
