import React, { useContext, useEffect, useState } from "react";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Box,
    ButtonGroup,
    Button,
    Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MROButton from "components/buttons";
import PlanningLineMaintenance from 'lib/services/api/planning/LineMaintenance/lineEventsandSeries';
import { ToastMessageContext } from "lib/contexts/message_context";


const useStyles = makeStyles((theme) => ({
    actionButtons: {
        paddingTop: "20px",
        paddingBottom: "33px",
        textAlign: "right",
        "& button:not(:last-child)": {
            marginRight: "20px",
        },
    },
    modal: {
        "& .MuiDialog-paperWidthMd": {
            maxWidth: "550px",
        },
    },
    title: {
        "& h2": {
            color: theme.palette.primary.main,
        },

        "& span": {
            color: theme.palette.primary.main,
            fontSize: 22,
            textAlign: 'center'
        },
    },
    card: {
        padding: "0 25px",
    },
}));


function DeleteEvent(props) {
    const classes = useStyles();
    const { handleClose, eventId, onClose } = props
    const message = useContext(ToastMessageContext)
    const [loading, setLoading] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault();
        const params = {
            event_id: eventId
        }
        setLoading(true)
        await PlanningLineMaintenance.deleteLineEvents(params).then((res) => {
            message.showToastMessage({
                message: "Deleted successfully !",
                variant: "success",
            });
            handleClose()
        }).catch((res) => {
            handleClose()
            message.showToastMessage({
                message: "Something went wrong. Try again!",
                variant: "error",
            });
        }).finally(() => {
            setLoading(false)
        })
    }


    return (

        <div>
            <Dialog
                maxWidth={"md"}
                fullWidth={true}
                open={true}
                className={classes.modal}
                onClose={onClose}
                aria-labelledby="delete-event"
            >
                <DialogTitle
                    variant="subtitle1"
                    color="secondary"
                    className={classes.title}
                >
                    <Typography style={{ textAlign: 'center' }}>Confirm - Delete Individual LM Event?</Typography>
                </DialogTitle>
                <DialogContent>

                    <Typography style={{ textAlign: 'center' }}>This action cannot be undone</Typography>
                </DialogContent>
                <Box
                    display="flex"
                    width="100%"
                    justifyContent="center"
                    my="25px"
                    align="center"
                >
                    <MROButton
                        type="button"
                        variant={"contained"}
                        onClick={onClose}
                        style={{ marginRight: 20 }}
                    >
                        Cancel
                    </MROButton>
                    <MROButton
                        color="primary"
                        onClick={handleSubmit}
                        variant={"contained"}
                        loading={loading}
                    >
                        Continue
                    </MROButton>
                </Box>
            </Dialog>

        </div>
    )
}

export default DeleteEvent