import React, { useState, useEffect } from 'react'
import DropdownField from "./FieldComponents/dropdown_field"
import MROTextField from '../../../../components/form_components/TextField'
import { Grid } from '@material-ui/core';
import FreeTextField from './FieldComponents/free_text_field';

export const StaionCompleteAndComments = ({ bases, subSection, errors, setErrors, selectedBase }) => {
    const [dropdownValues, setDropDownValues] = useState([]);

    console.log("bases os==================", selectedBase)
    console.log("subsection os==================", subSection.field_value)
    const fielsubSection = {
        // required: subSection.mandatory || false,
        type: subSection.field_type === 'Datetime' ? 'date' : "text",
        label: subSection.field_name,
        name: `${subSection.id || subSection.org_field_id}`,
        defaultValue: subSection.field_value,
        value: subSection.value,
        disabled: subSection.disabled,
        error: errors && errors[subSection.id || subSection.org_field_id] && !errors[subSection.id || subSection.org_field_id].valid
    }

    useEffect(() => {
        if (!!bases?.length) {
            setDropDownValues(bases.map(base => base.station))
        }
    }, [bases?.length])

    return (
        <div>


            {
                subSection.field_name === 'Comments' && <MROTextField
                    {...fielsubSection}
                    multiline
                    rows={4}
                    InputLabelProps={{ shrink: true }}
                />
            }
            <Grid item xs={4} style={{ paddingRight: '13px' }}>
                {
                    subSection.field_name === 'Station Complete' &&
                    // <DropdownField
                    //     {...subSection}
                    //     {...fielsubSection}
                    //     errors={errors}
                    //     bases={bases}
                    //     setErrors={setErrors}
                    //     dropdown_values={dropdownValues}
                    // />
                    <FreeTextField
                    {...{...subSection, field_value: selectedBase}}
                    errors={errors}
                    isDpdDisabled={!!selectedBase}
                    setErrors={setErrors}
                    menuItems={
                     dropdownValues
                    }
                  />
                }
            </Grid>
        </div>
    )
}

export default React.memo(StaionCompleteAndComments)
