import React, { useEffect, useReducer } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Dialog, makeStyles } from "@material-ui/core";
import { Popover } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import MaterialTable from "material-table";
import tableReducer, { INITIAL_TABLE_STATE } from "reducers/table_reducer";
import tableIcons, {
    options,
} from "components/universal/table_attributes";
import TableAction from "actions/table_actions";
import PopupDialog from "./PopupDialog";
import { MaintenanceServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation";
import { useState } from "react";

const StyledMenu = withStyles({
    paper: {
        width: "523px",
        height: "fit-content",
        top: "290px !important",
        padding: "30px",
        boxShadow: "0px 3px 6px #00000029",
    },
})((props) => (
    <Popover
        elevation={0}
        // getContentAnchorEl={null}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "center",
        }}
        {...props}
    />
));

export default function UnitPricePopup(props) {
    const { currentSelection, open, handleClose, workshop_order_id } = props;

    const classes = useStyles();


    const [priceDetails, setPriceDetails] = useState([])
    const [total, setTotal] = useState()
    const [busy, setBusy] = useState(null)

    const getPriceDetails = async () => {
        setBusy(true)
        const params = {
            workshop_order_id: workshop_order_id
        }
        await MaintenanceServices.getPriceDetails(params)
            .then((res) => {
                setPriceDetails(res.data)

            })
            .catch((err) => {
            })
            .finally(() => {
                setBusy(false);
            });
    }
    useEffect(() => {
        getPriceDetails()

    }, [])

    // tableOptions
    const tableOptions = {
        ...options,
        search: false,
        toolbar: false,
        paging: false,
        headerStyle: {
            textAlign: "center",
            paddingTop: "22px",
            paddingBottom: "16px",
        },
        rowStyle: {
            height: '42px'
        }
    };

    const handleTotal = () => {
        setTotal(currentSelection?.total)
        return total ? total : '-'

    }

    const columns = [
        {
            title: "Labour",
            field: "labour",


        },
        {
            title: "Parts",
            field: "parts",

        },
        {
            title: "Services",
            field: "services",

        },
        {
            title: "Equip",
            field: "equipment",

        },
        {
            title: "Unit Total",
            field: "total",
            render: () => handleTotal()
        },
    ];

    return (
        <div style={{ position: "relative" }}>
            <PopupDialog
                open={open}
                handleClose={handleClose}
                width="481px"
                notShowButton={true}
            >
                <Box
                    width="100%"
                    mb="30px"
                    ml="0px"
                >
                    <Typography color="primary" className={classes.bodyText}>
                        Unit Price
                    </Typography>
                </Box>

                <Box width="100%">
                    <MaterialTable
                        style={{ boxShadow: "0px 1px 3px #00000033" }}
                        icons={tableIcons}
                        columns={columns}
                        isLoading={busy}
                        data={[priceDetails]}
                        options={tableOptions}



                    />
                </Box>
            </PopupDialog>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    bodyText: {
        fontSize: 20,
        lineHeight: "28px",
    },
}));
