import React, { useContext, useState } from "react";
import MRORightDrawer from "components/temporary_sidebar";
import { Typography, Box, Grid } from "@material-ui/core";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import { makeStyles } from "@material-ui/core";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import MROButton from "components/buttons";
import { InspectionServices } from "lib/services/api/operaitons/WorkShop/Inspection/InspectionService";
import { ToastMessageContext } from "lib/contexts/message_context";

const useStyles = makeStyles((theme) => ({
  stageStyle: {
    border: "0.5px solid gray",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "5px",
    width: "260px",
    height: "50px",
    wordWrap: "break-word",
    boxShadow: "0px 1px 4px #0000001A",
  },
  actionButtons: {
    paddingTop: "20px",
    textAlign: "right",
    "& button": {
      marginRight: "20px",
    },
  },
}));

function EditStageOrder(props) {
  const classes = useStyles();
  const { handleClose, stageDetails, workshop_id,closeDrawer} = props;
  const message = useContext(ToastMessageContext);
  const [stageObj, setStageObj] = useState(stageDetails);
  const [busy, setBusy] = useState(null)

  // console.log("stage obj=================", stageObj);

  const handleSubmit = () => {
    let stageParams = stageObj?.map((item) =>{
      return {stage_position: item.stage_position, workshop_library_id: item.workshop_library_id}
    })
    const params = {
      workshop_action_id: workshop_id,
      stage_position_data: stageParams,
    };
    // console.log("params is========================", params);
    setBusy("update")
    InspectionServices.updateStagePosition(params)
      .then((res) => {
        if (res) {
          closeDrawer(res);
          props.getAllActionStages()
          message.showToastMessage({
            message: "Updated Successfully!!",
            variant: "success",
          });
        }
      })
      .catch(() => {
        message.showToastMessage({
          message: "Something went wrong. Try again!!",
          variant: "error",
        });
      }).finally(()=>{
        setBusy(null)
      })
  };

  // console.log("stage obj is================", stageObj)

  const resetDisplayPositions = list => {
    const resetList = list.map((c, index) => {
      const slide = c
      slide.stage_position = index + 1
      return slide
    })
    return resetList
  }

  const handleDrag = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return resetDisplayPositions(result)
  };

  const onDragUpdate = result => {
    if (!result.destination) {
      return
    }
    const dragged = stageObj[result.source.index]
    const previousDraggedIndex = dragged.stage_position
    dragged.stage_position = result.destination.index + 1
    const draggedIndexDifference = dragged.stage_position - previousDraggedIndex
    const updatedContent = stageObj.map((c, index) => {
      const slide = c
      if (slide.stage_position === result.destination.index + 1 && index !== result.source.index) {
        slide.stage_position -= draggedIndexDifference
      }
      return slide
    })
    setStageObj(updatedContent)
  }

  return (
    <div>
      <MRORightDrawer onClose={handleClose}>
        <DragDropContext
          onDragUpdate={onDragUpdate}
          onDragEnd={(result) => {
            if (!result.destination) {
              return
            }
            const content = handleDrag(stageDetails, result.source.index,result.destination.index)
            setStageObj(content)
            stageDetails.splice( result.destination.index, 0, stageDetails.splice(result.source.index, 1)[0]);

            // handleDrag(param);
            // const srcIndx = param.source.index;
            // const desIndx = param.destination?.index;
            // stageDetails.splice(desIndx, 0, stageDetails.splice(srcIndx, 1)[0]);

            // console.log("stage details is===================",param, srcIndx+1,desIndx+1)
          }}
        >
          <Typography variant="subtitle2">Edit Stage Order</Typography>
          <Grid style={{ marginTop: "20px", marginBottom: "20px" }}>
            <Typography style={{ fontSize: "13px" }}>
              Click and Drag Stages up or down to re-order as required
            </Typography>
          </Grid>
          <Typography variant="subtitle2" style={{ marginBottom: "20px" }}>
            Stage Order
          </Typography>
          <Droppable droppableId="droppable-1">
            {(provided, snapshot) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {stageDetails?.map((stage, index) => (
                  <Draggable
                    key={stage.id}
                    draggableId={"draggable-" + stage.workshop_library_id}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <Grid
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Box
                          display="flex"
                          justifyContent={"center"}
                          alignItems="center"
                          mb="20px"
                        >
                          <Box flex={50}>
                            <Typography
                              color="primary"
                              variant="h6"
                              style={{ margin: "4px" }}
                            >
                              {stage.stage_position}
                            </Typography>
                          </Box>
                          <Box flex={50}>
                            <Grid className={classes.stageStyle}>
                              <TouchAppIcon /> &nbsp;&nbsp;
                              {stage.stage_name}
                            </Grid>
                          </Box>
                        </Box>
                      </Grid>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div className={classes.actionButtons}>
          <MROButton type="button" variant={"contained"} onClick={handleClose}>
            Cancel
          </MROButton>
          <MROButton
            loading={busy === "update"}
            type="submit"
            variant={"contained"}
            color="primary"
            onClick={handleSubmit}
          >
            Save
          </MROButton>
        </div>
      </MRORightDrawer>
    </div>
  );
}

export default EditStageOrder;
