import { ServiceRatesOptions, CallOutRatesService } from "../../../lib/services/api/admin/line/adhoc_rates_client";

export const ADHOC_RATES_ACTIONS = {
    TOGGLE_LOADING: 'toggle_loading',
    SET_ERROR: 'set_error',
    SET_RATES: 'set_rates_data',
    ADD_RATE: 'add_rate_data',
    UPDATE_RATE: 'update_rate_data',
    REMOVE_RATE: 'remove_rate_data',
    SET_CURRENT_SELECTION: 'set_current_selection',
    SET_ACTION: 'set_action_type',
    RESET_ACTION: 'reset_action'
}

const getData = {
    service_rates: (params) => ServiceRatesOptions.getServiceRates(params),
    call_out_rates: (params) => CallOutRatesService.getCalloutRates(params)
}

const createData = {
    service_rates: (formData) => ServiceRatesOptions.createServiceRates(formData),
    call_out_rates: (formData) => CallOutRatesService.createCalloutRates(formData),
}

const updateData = {
    service_rates: (formData) => ServiceRatesOptions.updateServiceRate(formData),
    call_out_rates: (formData) => CallOutRatesService.updateCalloutRate(formData),
}

const deleteData = {
    service_rates: (formData) => ServiceRatesOptions.removeServiceRate(formData),
    call_out_rates: (formData) => CallOutRatesService.removeCalloutRate(formData),
}

export function getRates(dispatch, message, type = "service_rates", params) {
    dispatch({ type: ADHOC_RATES_ACTIONS.TOGGLE_LOADING });
    getData[type](params).then((res) => {
        if (res[type]) {
            dispatch({ type: ADHOC_RATES_ACTIONS.SET_RATES, response: { data: res[type], total: res['total_count'] } });
        }
        else {
            dispatch({ type: ADHOC_RATES_ACTIONS.TOGGLE_LOADING });
            message.showToastMessage({ message: res.errors ? res.errors.join(', ') : "Something went wrong. Try again!", variant: "error" })
        }
    }).catch((e) => {
        dispatch({ type: ADHOC_RATES_ACTIONS.TOGGLE_LOADING });
        message.showToastMessage({ message: e.message || "Something went wrong. Try again!", variant: "error" })
    })
}

export function setCurrentSelection(dispatch, data) {
    dispatch({ type: ADHOC_RATES_ACTIONS.SET_CURRENT_SELECTION, response: data });
}

export function setActionType(dispatch, type) {
    dispatch({ type: ADHOC_RATES_ACTIONS.SET_ACTION, response: type });
}

export function resetActions(dispatch) {
    dispatch({ type: ADHOC_RATES_ACTIONS.RESET_ACTION });
}

export function createRate(dispatch, message, type, formData) {
    dispatch({ type: ADHOC_RATES_ACTIONS.TOGGLE_LOADING });
    createData[type](formData).then((res) => {
        if (res.success) {
            dispatch({ type: ADHOC_RATES_ACTIONS.ADD_RATE, response: res.data });
            message.showToastMessage({ message: "Successfully added New Rate", variant: "success" });
        }
        else {
            dispatch({ type: ADHOC_RATES_ACTIONS.TOGGLE_LOADING });
            message.showToastMessage({ message: res.errors ? res.errors.join(', ') : "Something went wrong. Try again!", variant: "error" })
        }
    }).catch((e) => {
        dispatch({ type: ADHOC_RATES_ACTIONS.TOGGLE_LOADING });
        message.showToastMessage({ message: e.message || "Something went wrong. Try again!", variant: "error" })
    })
}

export function updateRate(dispatch, message, type, formData) {
    dispatch({ type: ADHOC_RATES_ACTIONS.TOGGLE_LOADING });
    updateData[type](formData).then((res) => {
        if (res.success) {
            dispatch({ type: ADHOC_RATES_ACTIONS.UPDATE_RATE, response: { ...res.data, base: formData.get('base') } });
            message.showToastMessage({ message: "Rate updated successfully", variant: "success" });
        }
        else {
            dispatch({ type: ADHOC_RATES_ACTIONS.TOGGLE_LOADING });
            message.showToastMessage({ message: res.errors ? res.errors.join(', ') : "Something went wrong. Try again!", variant: "error" })
        }
    }).catch((e) => {
        dispatch({ type: ADHOC_RATES_ACTIONS.TOGGLE_LOADING });
        message.showToastMessage({ message: e.message || "Something went wrong. Try again!", variant: "error" })
    })
}

export function deleteRate(dispatch, message, type, id) {
    dispatch({ type: ADHOC_RATES_ACTIONS.TOGGLE_LOADING });
    deleteData[type](id).then((res) => {
        if (res.success) {
            dispatch({ type: ADHOC_RATES_ACTIONS.REMOVE_RATE, response: id });
            message.showToastMessage({ message: "Rate removed successfully", variant: "success" });
        }
        else {
            dispatch({ type: ADHOC_RATES_ACTIONS.TOGGLE_LOADING });
            message.showToastMessage({ message: res.errors ? res.errors.join(', ') : "Something went wrong. Try again!", variant: "error" })
        }
    }).catch((e) => {
        dispatch({ type: ADHOC_RATES_ACTIONS.TOGGLE_LOADING });
        message.showToastMessage({ message: e.message || "Something went wrong. Try again!", variant: "error" })
    })
}


const AdhocRatesAction = {
    getRates,
    setCurrentSelection,
    createRate,
    updateRate,
    setActionType,
    resetActions,
    deleteRate
}

export default AdhocRatesAction;