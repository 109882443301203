import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";

const ChartLoader = (props) => (
    <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: `${props.padding ? props.padding : '80px'}`,


        // height: `${props?.height ? props?.height : '23'}rem`
    }}>
        <CircularProgress size={30} thickness={5} />
    </div>
);

export default ChartLoader;