import { Typography, Grid } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { styled } from "@material-ui/styles";
import { Paper } from "@material-ui/core";
import tableIcons from "components/universal/table_attributes";
import MaterialTable from "material-table";
import { useReducer } from "react";
import tableReducer, { INITIAL_TABLE_STATE } from "reducers/table_reducer";
import TableAction from "actions/table_actions";
import { WorkshopContext } from "../WorkshopContext/WorkshopContext";


const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: "2%",
  },
  appBar: {
    backgroundColor: "#fff",
    color: "#000000CC",
    boxShadow: "0px 3px 6px #0000001A",
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  main: {
    marginRight: theme.spacing(2),
  },
  generalDetail: {
    backgroundColor: theme.palette.primary.main,
    padding: "2%",
    color: "#fff",
    borderRadius: "5px 5px 0 0",
  },
  content: {
    backgroundColor: "#fff",
    padding: "3% 6%",
    borderRadius: "0 0 5px 5px",
    minHeight: "fit-content",
  },
  titleTile: {
    padding: "3px 20px",
    fontSize: "13px",
    fontWeight: 500,
    marginBottom: "20px",
  },
  inputField: {
    maxWidth: "328px",
  },
  actionButtons: {
    textAlign: "right",
    marginTop: "30px",
    "& button": {
      // marginRight: "20px",
    },
  },
  input1: {
    height: 200,
    padding: 20,
  },
  noteText: {
    color: theme.palette.primary.main,
    paddingLeft: "10px", fontStyle: "italic", fontSize: "15px"
  }
}));


function CurrentLivePosition(props) {
  const classes = useStyles();

  const { tatCount } = useContext(WorkshopContext)
  const { totalRow, tatDays } = props;
  const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
  const { page, totalCount, pageSize, options } = tableState;

  const tableOptions = {
    ...options,
    page: page,
    total: totalCount,
    pageSize: pageSize,
    search: false,
    toolbar: false,
    paging: false,
    rowStyle: { height: 42 },
  };
  const data = [{}];
  const total = parseFloat(totalRow.total_estimate_charge) + parseFloat(totalRow?.parts) + parseFloat(totalRow?.services) + parseFloat(totalRow?.equipment) + parseFloat(totalRow?.aog_fee)


  const column = [
    {
      title: "Current Position",
      field: "current_position",
      headerStyle: {
        paddingLeft: '22px'
      },
      cellStyle: {
        width: "40%",
        paddingLeft: "22px"
      },
      render: () => <>Live</>
    },
    {
      title: "TAT",
      field: "tat",
      render: () => <>{totalRow?.tat_days}</>
    },
    {
      title: "Labour *",
      field: "labour",
      render: () => <>{totalRow.total_estimate_charge}</>
    },
    {
      title: "Parts",
      field: "parts",
      render: () => <>{totalRow?.parts}</>
    },
    {
      title: "Services",
      field: "services",
      render: () => <>{totalRow?.services}</>
    },
    {
      title: "Equipment",
      field: "equipment",
      render: () => <>{totalRow?.equipment}</>
    },
    {
      title: "AOG Fee",
      field: "aog_fee",
      render: () => <>{totalRow?.aog_fee}</>
    },
    {
      title: "Total",
      field: "total",
      render: () => <>{total.toFixed(2)}</>
    },
  ];

  return (
    <div className={props?.classes?.wrapper}>
      <Grid>
        <Typography
          color="primary"
          varient="h3"
          style={{ marginBottom: "10px" }}
        >
          Current Live Position
        </Typography>
      </Grid>
      <MaterialTable
        style={{
          boxShadow: "0px 1px 3px #00000033",
          //   marginTop: "10px",
        }}
        icons={tableIcons}
        title={""}
        columns={column}
        data={data || []}
        options={tableOptions}
        components={{
          Toolbar: () => null,
        }}
        localization={{
          toolbar: {},
          body: {
            emptyDataSourceMessage: "No History found",
            filterRow: {
              filterTooltip: "Filter",
            },
          },
        }}
        onChangePage={(page) => {
          TableAction.setPage(dispatch, page);
        }}
        onChangeRowsPerPage={(pageSize) => {
          TableAction.setPageSize(dispatch, pageSize);
        }}
        totalCount={totalCount}
        page={page}
        onRowClick={(row) => { }}
      />
      <Typography
        style={{ marginTop: "20px", fontStyle: "italic", fontSize: "13px" }}
      >
        * Labour figure shown in the table is calculated from the estimate hours from each action. The ACTUAL bookings currently equate to:
        <span className={classes.noteText}>{totalRow?.labour}  </span>
      </Typography>
    </div>
  );
}

export default CurrentLivePosition;
