import React from "react";

import { Grid, Typography, makeStyles, Box } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  error: {
    borderColor: "red",
    color: "red",
  },
}));

function YesNoField(props) {
  const classes = useStyles();

  const { value, handleToggle, error, setError, name, label, disabled, NoFieldError } = props;

  return (
    <Box my="8px">
      <Box display="flex" flexDirection="row" alignItems="center">
        <span>
          <Typography style={{ marginRight: 30 }} className={error ? classes.error : {}}>{label}</Typography>
        </span>
        <span>
          <Box>
            <ToggleButtonGroup
              name={"physical_check_status"}
              value={value}
              onChange={(e, value) => {
                typeof handleToggle === "function" && handleToggle(value);
                typeof setError === "function" && setError();
              }}
              aria-label="text formatting"
              exclusive
            >
              <ToggleButton style={{pointerEvents: disabled ? "none" : ""}} className={error ? classes.error : {}} value="no">
                No
              </ToggleButton>
              <ToggleButton style={{pointerEvents: disabled ? "none" : ""}} className={error ? classes.error : {}} value="yes">
                Yes
              </ToggleButton>
            </ToggleButtonGroup>
            <input
              style={{ display: "none" }}
              value={
                value?.toLowerCase() === "yes"
                  ? true
                  : value?.toLowerCase() === "no"
                  ? false
                  : null
              }
              name={name}
            />
          </Box>
        </span>
      </Box>
      {!NoFieldError && error && (
        <p style={{ color: "red", fontSize: 12 }}>
          Please select the yes or no
        </p>
      )}
      {NoFieldError &&  <p style={{ color: "red", fontSize: 12 }}>
      Must be Yes
        </p>}
    </Box>
  );
}

export default YesNoField;
