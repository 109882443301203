import React, { useContext, useEffect, useReducer, useState } from "react";
import {
  Grid,
  Typography,
  makeStyles,
  FormControl,
  Select,
  InputBase,
  MenuItem,
} from "@material-ui/core";
import clxc from "clsx";
import SearchBarWithFilters from "components/search_with_filters";
import MaterialTable from "material-table";
import tableIcons, { options } from "components/universal/table_attributes";
import TableAction from "actions/table_actions";
import tableReducer, { INITIAL_TABLE_STATE } from "reducers/table_reducer";
import MROButton from "components/buttons";
import useDebounce from "lib/utils/debounce";
import { ToastMessageContext } from "lib/contexts/message_context";
import SpecialistService from "./SpecialistService";
import ServiceRequestListClientServices from "lib/services/api/procurement/Services";
import EquipmentServices from "./Equipment";
import ManageSpecialistService from "./ManageSpecialistService";
import { formatDate, _formatDate } from "lib/utils/common_utils";

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
    width: "100%",
    "& .MuiOutlinedInput-multiline": {
      minHeight: "89px",
    },

    "& textarea::placeholder": {
      textTransform: "none !important",
    },

    "& form": {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    bold: {
      fontWeight: 600,
    },
  },
  title: {
    fontSize: "17px",
    marginBottom: "10px",
    marginRight: "20px",
  },
  title2: {
    fontSize: "20px",
    margin: "20px 20px 10px 0px",
  },

  StockInfoDrawer: {
    "& > div[aria-hidden='true']": {
      visibility: "hidden !important",
    },
  },
  pendSo: {
    borderColor: "#4D4F5C",
    borderRadius: "7px",
    minWidth: "20px",
    height: "30px",
    backgroundColor: "red",
  },
  dispatch: {
    borderColor: "#4FC605",
    borderRadius: "7px",
    minWidth: "20px",
    height: "30px",
    backgroundColor: "#FFB300",
  },
  received: {
    borderColor: "#4FC605",
    borderRadius: "7px",
    minWidth: "20px",
    height: "30px",
    backgroundColor: "#4FC605",
  },
  clarify: {
    borderColor: "#4FC605",
    borderRadius: "7px",
    minWidth: "25px",
    height: "30px",
    backgroundColor: "#FFB300",
  },
  complete: {
    borderColor: "#4FC605",
    borderRadius: "7px",
    minWidth: "20px",
    height: "30px",
    backgroundColor: "#4FC605",
  },
  cancelled: {
    borderColor: "#4FC605",
    borderRadius: "7px",
    minWidth: "20px",
    height: "30px",
    backgroundColor: "#4FC605",
  },
  rejected: {
    borderColor: "#4FC605",
    borderRadius: "7px",
    minWidth: "20px",
    height: "30px",
    backgroundColor: "red",
  },
}));

function ServicesAndEquip(props) {
  const classes = useStyles();
  const { workshop_id, orderState, baseId } = props;
  const message = useContext(ToastMessageContext);
  const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
  const { page, totalCount, pageSize, inProgress, data } = tableState;
  const [searchText, setSearchText] = useState("");
  const debouncedVal = useDebounce(searchText);
  const [dependencies, setDependencies] = useState([]);
  const [action, setAction] = useState(null);
  const [equipmentDependency, setEquipmentDependencies] = useState({});
  const [currentSelection, setCurrentSelection] = useState({});
  const [filterData, setFilterData] = useState({});

  useEffect(() => {
    getServices();
  }, [page, pageSize, debouncedVal, filterData]);

  const getServices = () => {
    const params = {
      count_per_page: pageSize,
      page: page + 1,
      workshop_order_id: workshop_id,
      search_keyword: debouncedVal,
      ...filterData
    };
    TableAction.getList(dispatch, message, params, "workshopServices");
  };

  useEffect(() => {
    fetchServicesDependencies();
    fetchEquipmentDependencies();
  }, []);

  const fetchServicesDependencies = async () => {
    const params = {
      workshop_order_id: workshop_id,
    };
    await ServiceRequestListClientServices.ServiceFetchDependencies(params)
      .then((res) => {
        if (res) {
          setDependencies(res);
        }
      })
      .catch(() => {
        message.showToastMessage({
          message: "Something went wrong. Try again!",
          variant: "error",
        });
      });
  };
  const fetchEquipmentDependencies = async () => {
    const params = {
      workshop_order_id: workshop_id,
    };
    await ServiceRequestListClientServices.workshopEquipmentFetchDependencies(
      params
    )
      .then((res) => {
        if (res) {
          setEquipmentDependencies(res);
        }
      })
      .catch((err) => {
        message.showToastMessage({
          message: "Something went wrong. Try again!",
          variant: "error",
        });
      });
  };

  const tableOptions = {
    ...options,
    page: page,
    search: false,
    total: totalCount,
    pageSize: pageSize,
    searchText: searchText,
    toolbar: false,
  };

  const columns = [
    {
      title: "",
      field: "id",
      width: "4%",
      render: () => <span>&nbsp;</span>,
    },
    {
      title: "Action #",
      field: "workshop_action_number",
      headerStyle: {
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Category",
      field: "service_type",
      headerStyle: {
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center ",
      },
    },
    {
      title: "Location",
      field: "location",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (rowData) => {
        if (rowData?.location === "mro_on_site") return "MRO Site"
        else if (rowData?.location === "supplier_off_site") {
          return "Supplier Site"
        }
      }
    },
    {
      title: "S/Order #",
      field: "order_number",
      headerStyle: {
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (rowdata) => {
        if (rowdata?.order_number === null) return "-"
        else {
          return rowdata?.order_number
        }
      }
    },
    {
      title: "Planned For",
      field: "planned_date",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        textAlign: "left",
        width: "30%",
      },
      render: (rowdata) => formatDate(rowdata.planned_date)
    },
    {
      title: "Dispatched",
      field: "dispatched_by",
      headerStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Received",
      field: "received_by",
      headerStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Certified",
      field: "insp_by",
      headerStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Status",
      render: (rowData) => {
        if (rowData?.state === "complete") {
          return (
            <MROButton
              className={clxc(classes.button, "button-info", classes.complete)}
              onClick={() => {
                setAction("SpecialistService");
                setCurrentSelection(rowData);
              }}
              variant="contained"
            >
              <u style={{ fontSize: "12px", color: "white" }}>COMPLETE</u>
            </MROButton>
          )
        }
        if (rowData?.state === "cancelled") {
          return (
            <MROButton
              className={clxc(classes.button, "button-info", classes.cancelled)}
              onClick={() => {
                setAction("SpecialistService");
                setCurrentSelection(rowData);
              }}
              variant="contained"
            >
              <u style={{ fontSize: "12px", color: "white" }}>CANCELLED</u>
            </MROButton>
          )
        }
        if (rowData?.state === "rejected") {
          return (
            <MROButton
              className={clxc(classes.button, "button-info", classes.rejected)}
              onClick={() => {
                setAction("SpecialistService");
                setCurrentSelection(rowData);
              }}
              variant="contained"
            >
              <u style={{ fontSize: "12px", color: "white" }}>REJECTED</u>
            </MROButton>
          )
        }
        if (rowData.approval_state === false
          && rowData?.state != "rejected"
          && rowData?.state != "cancelled") {
          return (
            <MROButton
              className={clxc(classes.button, "button-info", classes.pendSo)}
              onClick={() => {
                setAction("SpecialistService");
                setCurrentSelection(rowData);
              }}
              variant="contained"
            >
              <u style={{ fontSize: "12px", color: "white" }}>PEND S/O&nbsp;</u>
            </MROButton>
          )
        }
        if (rowData?.state === "open" &&
          rowData.approval_state === true &&
          rowData.location === "supplier_off_site"
          && rowData.dispatched_by === null) {
          return (
            <MROButton
              className={clxc(classes.button, "button-info", classes.dispatch)}
              onClick={() => {
                setAction("SpecialistService");
                setCurrentSelection(rowData);
              }}
              variant="contained"
            >
              <u style={{ fontSize: "12px", color: "white" }}>DISPATCH</u>
            </MROButton>
          )
        }
        if (rowData?.state === "open" &&
          rowData.approval_state === true &&
          rowData.location === "supplier_off_site"
          && rowData.dispatched_by != null && rowData.received_by === null) {
          return (
            <MROButton
              className={clxc(classes.button, "button-info", classes.received)}
              onClick={() => {
                setAction("SpecialistService");
                setCurrentSelection(rowData);
              }}
              variant="contained"
            >
              <u style={{ fontSize: "12px", color: "white" }}>RECEIVE&nbsp;&nbsp;&nbsp;</u>
            </MROButton>
          )
        }
        if ((rowData?.state === "open" &&
          rowData.approval_state === true &&
          rowData.location === "supplier_off_site"
          && rowData.dispatched_by != null &&
          rowData.received_by != null &&
          rowData.insp_by === null) ||
          (rowData.approval_state === true &&
            rowData.location === "mro_on_site" && rowData.insp_by === null)) {
          return (
            <MROButton
              style={{ width: "87px" }}
              className={clxc(classes.button, "button-info", classes.clarify)}
              onClick={() => {
                setAction("SpecialistService");
                setCurrentSelection(rowData);
              }}
              variant="contained"
            >
              <u style={{ fontSize: "12px", color: "white" }}>CERTIFY&nbsp;&nbsp;&nbsp;</u>
            </MROButton>
          )
        }
      }
    },
  ];

  const handleFilterChange = (keyItem, value) => {
    TableAction.setPage(dispatch, 0);
    setFilterData((prevState) => ({ ...prevState, [keyItem]: value }));
  };

  return (
    <>
      <Typography color="primary" className={classes.title}>
        Specialist Services
      </Typography>
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Typography
            className={classes.bold}
          // style={{ marginLeft: 1000 }}
          >
            <MROButton
              disabled={!props.permission.write}
              style={{ width: 140, height: 36, display: orderState === "Closed" && "none" }}
              variant={"contained"}
              color={"secondary"}
              onClick={() => setAction("Add")}
            >
              + Add Service
            </MROButton>
          </Typography>
          <SearchBarWithFilters
            handleSearch={(value) => {
              TableAction.setPage(dispatch, 0);
              setSearchText(value);
            }}
          >
            <FormControl size="medium" className={"filter"}>
              <Select
                name="workshop_action_id"
                defaultValue={""}
                displayEmpty
                input={<InputBase />}
                onChange={(e) =>
                  handleFilterChange("workshop_action_id", e.target.value)
                }
              >
                <MenuItem value={""}> Action (All)</MenuItem>
                {dependencies?.actions?.map((item) => (
                  <MenuItem
                    key={item.workshop_action_id}
                    value={item.workshop_action_id}
                  >
                    {item.action_number}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl size="medium" className={"filter"}>
              <Select
                name="site"
                defaultValue={""}
                displayEmpty
                input={<InputBase />}
                onChange={(e) =>
                  handleFilterChange("site", e.target.value)
                }
              >
                <MenuItem value={""}> Location (All)</MenuItem>
                <MenuItem value={"mro_on_site"}>MRO Site</MenuItem>
                <MenuItem value={"supplier_off_site"}>Supplier Site</MenuItem>
              </Select>
            </FormControl>
            {/* <FormControl className={"filter"}>
              <Select
                defaultValue={null}
                displayEmpty
                input={<InputBase />}
                onChange={(e) =>
                  handleFilterChange("part_category_id", e.target.value)
                }
              >
                <MenuItem value={null}>Type (All)</MenuItem>
                {dependencies?.service_types?.map((item) => (
                  <MenuItem
                    key={item.part_category_id}
                    value={item.part_category_id}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
          </SearchBarWithFilters>

          {/* Table */}
          <MaterialTable
            style={{ marginTop: 20 }}
            columns={columns}
            icons={tableIcons}
            title=""
            data={data?.data || []}
            isLoading={inProgress}
            options={tableOptions}
            onChangePage={(page) => {
              TableAction.setPage(dispatch, page);
            }}
            onChangeRowsPerPage={(pageSize) => {
              TableAction.setPageSize(dispatch, pageSize);
            }}
            onSearchChange={(search) => {
              TableAction.setSearchText(dispatch, search);
            }}
            totalCount={totalCount}
            page={page}
          />
        </Grid>
      </Grid>
      <EquipmentServices
        dependencies={equipmentDependency}
        workshop_id={workshop_id}
        orderState={orderState}
        permission={props?.permission}
        baseId={baseId}
      />
      {action === "Add" && (
        <ManageSpecialistService
          action={action}
          dependencies={dependencies}
          workshop_id={workshop_id}
          onClose={() => setAction(null)}
          getServices={getServices}
          orderState={orderState}
          baseId={baseId}
        />
      )}
      {action === "SpecialistService" && (
        <SpecialistService
          currentSelection={currentSelection}
          handleClose={() => setAction(null)}
          dependencies={dependencies}
          workshop_id={workshop_id}
          getServices={getServices}
          orderState={orderState}
          permission={props?.permission}
          data={data}
          setCurrentSelection={setCurrentSelection}
        />
      )}
    </>
  );
}

export default ServicesAndEquip;
