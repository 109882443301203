import { Box } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { useContext } from 'react'
import MROButton from '../../../../../components/buttons'
import UploadButton from '../../../../../components/form_components/upload_button'
import UploadMultipleButton from '../../../../../components/form_components/upload_multiple_button'
import { ToastMessageContext } from '../../../../../lib/contexts/message_context'
import DataService from '../../../../../lib/services/api'

export const MultiUploadPreview = (props) => {
    console.log('multi upload button', props?.permission?.write )
    const [files, setFiles] = useState([]);
    const message = useContext(ToastMessageContext);

    const handleChange = (e) => {
        console.log(e)
       setFiles(e);
        props.setFiles(e);
    }

    useEffect(() => {
        props.setOrgSectionId(props.org_section_id)
    }, [props.org_section_id, props])

    const handleUpload = () => {
        let formData = new FormData();

        formData.append('org_section_id', props.org_section_id)
        formData.append('id', props.id)
        formData.append('documents[]', files[0][0])
        formData.append('operable_type', 'WorkOrder')


        DataService.createData(formData, 'uploadDocs').then(res => {
            props.getSectionDetails()
            message.showToastMessage({ message: 'Documnet uploaded successfully', variant: 'success' })
        }).catch(err => {
            message.showToastMessage({ message: 'Failed to upload documnet.', variant: 'error' })
        })
    }

    const handleRemoveFiles = id => () => {
        DataService.deleteData(null, 'destroyDoc', { id: id })
            .then(res => {
                message.showToastMessage({ message: 'Documnet removed successfully', variant: 'success' })
                props.getSectionDetails()
            }).catch(err => {
                message.showToastMessage({ message: 'Failed to remove documnet.', variant: 'error' })
            })
    }

    return (
        <div>
            <UploadMultipleButton {...props} handleImageChange={handleChange} multiple
                defualtValue={props.file}
                handleRemove={handleRemoveFiles}
            />
            <Box>
                {!!files.length && <MROButton disabled={!props?.permission?.write } onClick={handleUpload}>
                    Upload File
                </MROButton>}
            </Box>
        </div>
    )
}

export default React.memo(MultiUploadPreview)
