import React from 'react'
import { Dialog, DialogContent, makeStyles, createStyles, Typography, Grid, Paper, Box, CircularProgress, DialogTitle } from '@material-ui/core'
import { useState, useContext, useEffect } from 'react';
import { Autocomplete } from '@material-ui/lab';
import MROTextField from 'components/form_components/TextField';
import MROButton from 'components/buttons';
import { LineMaintainenceServices } from 'lib/services/api/dashboard/line_maintenance';
import { ToastMessageContext } from 'lib/contexts/message_context';
import PlanningLineMaintenance from 'lib/services/api/planning/LineMaintenance/lineEventsandSeries';
import { getOrganisationId } from 'lib/utils/common_utils';


const useStyles = makeStyles((theme) =>
    createStyles({
        modal: {
            "& .MuiDialog-paperWidthSm": {
                minWidth: "700px",
                height: "350px"
            },
        },

    })
);

function AllocateEngineers(props) {
    const classes = useStyles()
    const { handleClose, currentSelection, ref } = props
    const [loading, setLoading] = useState(false)
    const message = useContext(ToastMessageContext)
    const [plannedEngineers, setPlannedEngineers] = useState([])
    const [selectedEngineers, setSelectedEngineers] = useState([])
    let userDetails = JSON.parse(sessionStorage.getItem("user"));

    const PopperMy = (props) => {
        return <Paper {...props} className={classes.paper} placement="bottom-start" />;
    };



    const getPlannedEngineers = async () => {

        const params = {
            organisation_id: getOrganisationId()
        }
        await PlanningLineMaintenance.fetchPlannedEngineers(params).then((res) => {
            setPlannedEngineers(res.data)
            if (currentSelection.allocated_engineers.length > 0) {


                let value = res.data.filter((item) => currentSelection.allocated_engineers.find((obj) => obj.id === item.id))
                setSelectedEngineers(value)
            }
        }).catch((response) => {

        })
    }

    useEffect(() => {
        getPlannedEngineers()
    }, [])

    const handleSubmit = () => {

        const formData = new FormData()
        formData.append("event_id", currentSelection.id)
        formData.append("organisation_id", getOrganisationId())
        selectedEngineers.forEach((item, index) => {
            formData.append(`planned_engineers[${[index]}]`, item.id)
        })
        formData.append("user_id", userDetails?.id)
        setLoading(true)
        LineMaintainenceServices.updateAllocatedEngineers(formData).then((res) => {
            if (res.success) {
                message.showToastMessage({
                    message: "Engineers updated successfully !",
                    variant: "success",
                });
                handleClose()
            }
        }).catch((res) => {
            handleClose()
            message.showToastMessage({
                message: res.errors ? res.errors : "Something went wrong. Try again!",
                variant: "error",
            });
        }).finally(() => {
            setLoading(false)
        })

    }


    function container() {
        // Use the fullscreen element if in fullscreen mode, otherwise just the document's body
        return document.fullscreenElement ?? document.body;
    }


    return (
        <div>   <Dialog
            open={true}
            onClose={handleClose}
            aria-labelledby="allocate engineers"
            aria-describedby="allocate engineers"
            className={classes.modal}
            style={{ zIndex: '99999 !important' }}
            container={container()}
        >
            <DialogTitle>
                <Typography color='primary' variant='subtitle1' style={{ fontSize: '18px' }}>Allocate Engineers</Typography>
            </DialogTitle>
            <DialogContent>
                <Autocomplete
                    getOptionSelected={(option, value) => option.id === value.id}
                    value={selectedEngineers && selectedEngineers}
                    // defaultValue={eventDetails?.planned_engineers}
                    id="tags-standard"
                    multiple
                    PopperComponent={PopperMy}
                    getOptionLabel={(option) => option?.name || ""}
                    options={plannedEngineers && plannedEngineers}
                    fullWidth
                    onChange={(event, value) => {
                        setSelectedEngineers(value);
                        // setError({ ...error, part_number: null });
                    }}
                    renderInput={(params) => (
                        <MROTextField
                            {...params}
                            label="Planned Engineers"
                            color={"primary"}
                            // placeholder="Search planned engineers"
                            margin="normal"
                            variant="standard"
                            id="test"
                            InputLabelProps={{ shrink: true }}

                        // autoFocus={error.part_number}
                        // error={error.part_number}
                        // helperText={
                        //     error.part_number ? "*please select kit part" : null
                        // }
                        />
                    )}
                />
                <Grid item xs={12} container direction='row' style={{ justifyContent: 'flex-end', marginTop: '140px' }} >

                    <MROButton type='button' variant='contained' style={{ marginRight: '23px' }} onClick={() => {
                        handleClose()
                    }}>

                        Cancel
                    </MROButton>
                    <MROButton variant='contained' color='primary' loading={loading}
                        onClick={handleSubmit}
                    >

                        Update
                    </MROButton>


                </Grid>


            </DialogContent>
        </Dialog>
        </div>
    )
}

export default AllocateEngineers