import React, { useContext, useEffect, useReducer, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import {
  CircularProgress,
  createStyles,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  makeStyles,
  Typography,
} from "@material-ui/core";
import SearchByCategory from "components/category_based_search";
import { Clear } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import { ICON_COLOUR } from "lib/constants/style_constants";
import tableReducer, { INITIAL_TABLE_STATE } from "reducers/table_reducer";
import tableIcons, { options } from "components/universal/table_attributes";
import MaterialTable, { MTableBodyRow } from "material-table";
import TableAction from "actions/table_actions";
import DeleteIcon from "@material-ui/icons/Delete";
import MROButton from "components/buttons";
import { ToastMessageContext } from "../../../../lib/contexts/message_context";
import { v4 as uuidv4 } from "uuid";

import PartsClientServices from "../../../../lib/services/api/stores/tooling/parts_client";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: "50px",
      width: "1144px",
      height: "886px",
      position: "relative",
      display: "flex",
      flexDirection: "column",
    },

    popupTitle: {
      fontSize: "20px",
      lineHeight: "28px",

      position: "absolute",
      top: "19px",
      left: "25px",
    },
    divider: {
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
    tableTitle: {
      fontSize: "16px",
      lineHeight: "22px",
      color: "#4D4F5C",
      fontWeight: "bold",
      marginTop: "19px",
      marginBottom: "16px",
    },
  })
);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function StockInfoEditPopup(props) {
  const { open, popupCloseHandler, selectedPrimaryPartId } = props;
  const classes = useStyles();
  const message = useContext(ToastMessageContext);
  const [loading, setLoading] = useState(false);
  const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
  const { page, totalCount, pageSize, inProgress } = tableState;

  const [isDeleting, setIsDeleting] = useState(false);

  const [alternateTableState, setAlternateTableState] = useState({
    page: 0,
    totalCount: 5,
    pageSize: 10,
    isLoading: false,
  });

  const tableOptions = {
    ...options,
    search: false,
    toolbar: false,
  };

  const alternatesTableOptions = {
    ...options,
    ...alternateTableState,
    search: false,
    toolbar: false,
  };

  const [isSearching, setIsSearching] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [searchTableData, setSearchTableData] = useState([]);
  const [linkedAlternatesTableData, setLinkedAlternatesTableData] = useState(
    []
  );

  // this is for showing loading spinner on the right row
  const [selectedAlternatePardId, setSelectedAlternatePardId] = useState("");

  const getAlternateList = () => {
    const params = {
      id: selectedPrimaryPartId,
      page: alternateTableState.page + 1,
      count_per_page: alternateTableState.pageSize,
    };

    setAlternateTableState((prevState) => {
      return {
        ...prevState,
        isLoading: true,
      };
    });
    PartsClientServices.getAlternateParts(params)
      .then((res) => {
        if (res?.data) {
          setLinkedAlternatesTableData(res?.data);
          setAlternateTableState((prevState) => {
            return {
              ...prevState,
              totalCount: res.total_count,
            };
          });
        } else {
          setLinkedAlternatesTableData([]);
        }
      })
      .finally(() => {
        setAlternateTableState((prevState) => {
          return {
            ...prevState,
            isLoading: false,
          };
        });
      });
  };

  useEffect(() => {
    getAlternateList();
  }, [
    open,
    selectedPrimaryPartId,
    alternateTableState.page,
    alternateTableState.pageSize,
  ]);

  const fetchPrimaryParts = () => {
    const params = {
      search_keyword: searchText,
      page: page + 1,
      count_per_page: pageSize ? pageSize : 10,
    };
    setIsSearching(true);
    PartsClientServices.getAllParts(params)
      .then((res) => {
        if (res?.data) {
          setSearchTableData(res.data);
          TableAction.setTotalCount(dispatch, res?.total_count);
        } else {
          setSearchTableData([]);
          TableAction.setTotalCount(dispatch, 0);
        }
      })
      .catch((err) => console.log("error", err))
      .finally(() => setIsSearching(false));
  };

  //fetching primary parts
  useEffect(() => {
    fetchPrimaryParts();
  }, [page, pageSize]);

  useEffect(() => {
    TableAction.setPage(dispatch, 0);
    const timeout = setTimeout(() => {
      // if the user stops typing; search function will trigger
      fetchPrimaryParts();
    }, 600);

    return () => clearTimeout(timeout);
  }, [searchText]);

  // adding row to the alternates tables
  const addDataToAlternatesHandler = (item) => {
    setLinkedAlternatesTableData((prevState) => {
      return [...prevState, { ...item, isLocallyAdded: true }];
    });
  };

  // this function only delete the alternate that are being fetched from the server
  // it will delete the part from teh database
  const deleteAlternatePartFromTheServer = (id) => {
    const formData = new FormData();

    formData.append("alternate_part_id", id);
    formData.append("id", selectedPrimaryPartId);
    setIsDeleting(true);
    PartsClientServices.deleteAlternatePart(formData)
      .then((res) => {
        if (res?.success) {
          message.showToastMessage({
            message: "Deleted successfully",
            variant: "success",
          });
          getAlternateList();
        } else {
          throw new Error(res?.errors);
        }
      })
      .catch((err) => {
        message.showToastMessage({
          message: err ? err[0] : "Something went wrong",
          variant: "error",
        });
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  // deleting row from the alternates table
  const deleteDataToAlternatesHandler = (id, isLocallyAdded) => {
    if (!isLocallyAdded) {
      setSelectedAlternatePardId(id);
      deleteAlternatePartFromTheServer(id);
      return;
    }

    setLinkedAlternatesTableData((prevState) =>
      prevState.filter((item) => item.alternate_part_id !== id)
    );
  };

  const columns = [
    {
      title: "Part #",
      field: "part_number",
      headerStyle: {
        textAlign: "left",
        paddingLeft: "10px",
      },
      cellStyle: {
        textAlign: "left",
        paddingLeft: "10px",
      },
    },
    {
      title: "Description",
      field: "description",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        textAlign: "left",
      },
    },

    {
      title: "Action",
      field: "",
      render: (rowData) => (
        <IconButton>
          <AddIcon
            onClick={() => {
              addDataToAlternatesHandler({
                // this alternate part id is only use for deleting items
                // from the table
                alternate_part_id: rowData.id,
                // id: uuidv4(),
                part_number: rowData.part_number,
                description: rowData.description,
              });
            }}
          />
        </IconButton>
      ),
    },
  ];

  // columns for second tables
  const columnsForSecondTable = [
    {
      title: "Part #",
      field: "part_number",
      headerStyle: {
        textAlign: "left",
        paddingLeft: "10px",
      },
      cellStyle: {
        textAlign: "left",
        paddingLeft: "10px",
      },
    },
    {
      title: "Description",
      field: "description",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "isLocallyAdded",
      headerStyle: {
        display: "none",
      },
      cellStyle: {
        display: "none",
      },
    },

    {
      title: "Action",
      field: "",
      render: (rowData) => {
        if (
          isDeleting &&
          rowData.alternate_part_id === selectedAlternatePardId
        ) {
          return <CircularProgress size={20} color="primary" />;
        }
        return (
          <IconButton>
            <DeleteIcon
              onClick={() =>
                deleteDataToAlternatesHandler(
                  rowData.alternate_part_id,
                  rowData.isLocallyAdded
                )
              }
            />
          </IconButton>
        );
      },
    },
  ];

  const handleClose = () => {
    setSearchText("");
    TableAction.setPage(dispatch, 0);
    TableAction.setPageSize(dispatch, 0);
    popupCloseHandler();
  };

  const updateData = () => {
    const formData = new FormData();

    if (linkedAlternatesTableData.length !== 0) {
      formData.append("id", selectedPrimaryPartId);
    }

    linkedAlternatesTableData.forEach((item) => {
      formData.append("alternate_part_ids[]", item.alternate_part_id);
    });

    setLoading(true);
    PartsClientServices.updateAlternateParts(formData)
      .then((res) => {
        if (!res.success) {
          message.showToastMessage({
            message: res.errors[0],
            variant: "error",
          });
          // for closing edit popup
        } else {
          handleClose();
          message.showToastMessage({
            message: " Alternate Part's list updated successfully",
            variant: "success",
          });
        }
      })
      .catch((err) => {
        message.showToastMessage({
          message: "Something went wrong",
          variant: "error",
        });
      })
      .finally(() => setLoading(false));
  };

  // searching functionality
  const searchHandler = () => {
    setIsSearching(true);
    PartsClientServices.searchPrimaryPart(searchText, selectedPrimaryPartId)
      .then((res) => {
        setSearchTableData(res.data);
        setIsSearching(false);
      })
      .catch((err) => {
        setIsSearching(false);
        console.log(err);
      });
  };

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     // if the user stops typing; search function will trigger
  //     searchHandler();
  //   }, 600);

  //   return () => clearTimeout(timeout);
  // }, [searchText]);

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="1144px"
      >
        <div className={classes.container}>
          <Typography
            variant="body1"
            color="primary"
            className={classes.popupTitle}
          >
            Alternate Part Links
          </Typography>
          <div style={{ width: "328px", marginTop: "37px" }}>
            <InputBase
              fullWidth
              placeholder={"Search anything"}
              onChange={(e) => setSearchText(e.target.value)}
              value={searchText}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon htmlColor={ICON_COLOUR} />
                </InputAdornment>
              }
              endAdornment={
                !!searchText?.length ? (
                  <IconButton
                    title="Clear Search"
                    onClick={() => setSearchText("")}
                    size="medium"
                  >
                    <Clear fontSize="small" htmlColor={ICON_COLOUR} />
                  </IconButton>
                ) : null
              }
            />
            <Divider variant="fullWidth" className={classes.divider} />
          </div>

          <Grid container>
            <Grid item xs={6}>
              <Typography variant="body1" className={classes.tableTitle}>
                Search Results
              </Typography>

              <MaterialTable
                style={{
                  boxShadow: "0px 1px 3px #00000033",
                  borderRadius: "4px",
                  maxWidth: "500px",
                }}
                title=""
                icons={tableIcons}
                options={tableOptions}
                isLoading={isSearching}
                columns={columns}
                data={searchTableData}
                localization={{
                  body: {
                    emptyDataSourceMessage: "No Primary Parts Found",
                  },
                  header: {
                    actions: "",
                  },
                }}
                onChangePage={(page) => {
                  TableAction.setPage(dispatch, page);
                }}
                onChangeRowsPerPage={(pageSize) => {
                  TableAction.setPageSize(dispatch, pageSize);
                }}
                totalCount={totalCount}
                page={page}
              />
            </Grid>

            {/* -------------------------------------second table------------------------------------------------ */}
            <Grid item xs={6}>
              <Typography variant="body1" className={classes.tableTitle}>
                Linked Alternates
              </Typography>

              <MaterialTable
                style={{
                  boxShadow: "0px 1px 3px #00000033",
                  borderRadius: "4px",
                  maxWidth: "500px",
                }}
                isLoading={alternateTableState.isLoading}
                options={alternatesTableOptions}
                title=""
                icons={tableIcons}
                columns={columnsForSecondTable}
                data={linkedAlternatesTableData}
                localization={{
                  body: {
                    emptyDataSourceMessage: "No Alternates Found",
                  },
                  header: {
                    actions: "",
                  },
                }}
                onChangePage={(page) => {
                  setAlternateTableState((prevState) => {
                    return { ...prevState, page: page };
                  });
                }}
                onChangeRowsPerPage={(pageSize) => {
                  setAlternateTableState((prevState) => {
                    return { ...prevState, pageSize: pageSize };
                  });
                }}
                totalCount={alternateTableState.totalCount}
                page={alternateTableState.page}
              />
            </Grid>
          </Grid>
          <div
            style={{
              marginTop: "auto",
              alignSelf: "flex-end",
            }}
          >
            <MROButton
              style={{ marginRight: "20px" }}
              type="button"
              variant={"contained"}
              onClick={handleClose}
            >
              Cancel
            </MROButton>
            <MROButton
              onClick={
                linkedAlternatesTableData.length > 0
                  ? updateData
                  : popupCloseHandler
              }
              type="button"
              variant={"contained"}
              color="primary"
            >
              {loading ? <CircularProgress size={20} /> : "Complete"}
            </MROButton>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
