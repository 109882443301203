import React, { useEffect, useReducer } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Dialog, makeStyles } from "@material-ui/core";
import { Popover } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import MaterialTable from "material-table";
import tableReducer, { INITIAL_TABLE_STATE } from "reducers/table_reducer";
import tableIcons, {
    options,
} from "components/universal/table_attributes";
import TableAction from "actions/table_actions";
import PopupDialog from "./PopupDialog";
import { MaintenanceServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation";
import { useState } from "react";

const StyledMenu = withStyles({
    paper: {
        width: "523px",
        height: "fit-content",
        top: "290px !important",
        padding: "30px",
        boxShadow: "0px 3px 6px #00000029",
    },
})((props) => (
    <Popover
        elevation={0}
        // getContentAnchorEl={null}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "center",
        }}
        {...props}
    />
));

export default function PriceBreaks(props) {
    const { open, data, handleClose, busy, workshop_order_id } = props;

    const classes = useStyles();

    const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(null);
    const [pageSize, setPageSize] = useState(5);
    const [loading, setLoading] = useState(false)
    const [priceBreakDetails, setPriceBreaksDetails] = useState([]);

    useEffect(() => {
        getAllPriceBreaks()
    }, [page, pageSize])

    const getAllPriceBreaks = () => {
        const params = {
            page: page + 1,
            limit: pageSize,
            workshop_order_id: workshop_order_id
        }
        setLoading(true)
        MaintenanceServices.getAllPriceBreaks(params)
            .then((res) => {
                setLoading(false)
                setTotalCount(res.total_count)
                setPriceBreaksDetails(res.data)
            }).catch(() => {
                setLoading(false)
                console.log("error")
            })
    }

    // tableOptions
    const tableOptions = {
        ...options,
        page: page,
        total: totalCount,
        pageSize: pageSize,
        search: false,
        toolbar: false,
        headerStyle: {
            textAlign: "center",
            paddingTop: "22px",
            paddingBottom: "16px",
        },
    };

    const columns = [
        {
            title: "Band",
            field: "band",
        },
        {
            title: "Min QTY",
            field: "min_quantity",
        },
        {
            title: "Units",
            field: "units",
        },
        {
            title: "Discount",
            field: "discount",
        },
        {
            title: "Unit Price",
            field: "unit_price",
        },
    ];

    return (
        <div style={{ position: "relative" }}>
            <PopupDialog
                open={open}
                handleClose={handleClose}
                width="481px"
                notShowButton={true}
            >
                <Box
                    width="100%"
                    mb="30px"
                    ml="0px"
                >
                    <Typography color="primary" className={classes.bodyText}>
                        Price Breaks
                    </Typography>
                </Box>

                <Box width="100%">
                    <MaterialTable
                        style={{ boxShadow: "0px 1px 3px #00000033" }}
                        icons={tableIcons}
                        isLoading={loading}
                        columns={columns}
                        data={priceBreakDetails || []}
                        options={tableOptions}
                        localization={{
                            toolbar: {
                                searchPlaceholder: "Search anything",
                            },
                            body: {
                                emptyDataSourceMessage: "No data Found",
                                filterRow: {
                                    filterTooltip: "Filter",
                                },
                            },
                        }}
                        onChangePage={(page) => {
                            setPage(page);
                          }}
                          onChangeRowsPerPage={(pageSize) => {
                            setPageSize(pageSize);
                          }}
                        onSearchChange={(search) => {
                            TableAction.setSearchText(dispatch, search);
                        }}
                        totalCount={totalCount}
                        page={page}
                    />
                </Box>
            </PopupDialog>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    bodyText: {
        fontSize: 20,
        lineHeight: "28px",
    },
}));
