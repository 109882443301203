import React, { useContext, useEffect, useReducer, useState } from "react";
import Dialog from "@material-ui/core/Dialog";

import {
    createStyles,
    Grid,
    IconButton,
    makeStyles,
    Typography,

} from "@material-ui/core";
import tableIcons, { options } from "components/universal/table_attributes";
import MaterialTable from "material-table";
import { ToastMessageContext } from "lib/contexts/message_context";
import AmberGear from "assets/AmberGear.png";
import { CommercialServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/commercial";
import greenCheckIcon from "assets/green check.png";
import RescheduleIcon from "@material-ui/icons/History";
import LinearProgressBar from 'components/linear_progress_bar';
import RedRemoveIcon from "assets/Red remove icon.png";
import EstimationHistory from "views/Operations/Workshop/Commercial/EstimationHistory";



const useStyles = makeStyles((theme) =>
    createStyles({
        modal: {
            "& .MuiDialog-paperWidthSm": {
                minWidth: "800px",
                height: "max-content"
            },
        },
        container: {
            // minWidth: "1200px",
            // minHeight: "400px",
            padding: "25px",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            // overflow: "hidden !important"
        },




    })
);

export default function EstimationDeparture(props) {

    const { handleClose, currentSelection, onClose } = props
    const classes = useStyles();
    const message = useContext(ToastMessageContext);
    const [loading, setLoading] = useState(false)

    const [action, setAction] = useState(false)
    const [currentSelections, setCurrentSelections] = useState({})
    const [estData, setEstData] = useState([])


    const getListing = async () => {
        setLoading(true);
        const params = {
            workshop_order_id: currentSelection?.id,
        };
        await CommercialServices.getEstimationDeparture(params).then((res) => {

            if (res?.success) {
                setEstData(res?.data);
            }
        })
            .catch(() => {
                console.log("error");
            })
            .finally(() => {
                setLoading(false);
            });
    };



    useEffect(() => {

        getListing();

    }, [currentSelection]);







    const tableOptions = {
        ...options,
        paging: false,
        toolbar: false,
        search: false,

    };

    //Estimation Departure
    const column = [
        {
            title: "Type",
            field: "type",

            headerStyle: {
                textAlign: "left",
                paddingLeft: "22px",
            },
            cellStyle: {
                textAlign: "left",
                paddingLeft: "22px",
            },

        },
        {
            title: "Position",
            field: "position",

        },
        {
            title: "Date",
            field: "date",
        },
        {
            title: "History",
            field: "",
            // width: "20%",

            headerStyle: {
                textAlign: "left",

            },
            cellStyle: {
                // width: "20%",
            }, render: (rowData) => {
                if (rowData?.type === "Approved") {
                    return ""
                }
                else if (rowData?.type === "Revised") {
                    return (
                        <IconButton onClick={() => {
                            setAction("est_history")
                            setCurrentSelections(rowData)
                        }}>
                            <RescheduleIcon />
                        </IconButton>

                    )
                }

            }
        },
        {
            title: "Inducted / Start",
            field: "inducted_start",

            width: "10%"
        }, {
            title: "TAT",
            field: "tat_days",


        }, {
            title: "ETD",
            field: "etd",

        }, {
            title: "Progress",
            field: "progress",
            render: (rowData) =>
                <div style={{ position: 'absolute', marginTop: '-10px' }}>
                    <LinearProgressBar
                        variant='determinate'
                        value={Number(rowData.progress)}
                        width='70px'
                        barHeight='20px'
                        bgColor={(rowData.progress > 0 && rowData.progress < 75) ? '#4FC605' : (rowData.progress >= 75 && rowData.progress < 100) ? '#FFB300' : rowData.progress >= 100 ? '#FF0202' : '#fff'}
                        color={rowData.progress >= 100 ? "#FFFFFF" : "#4D4F5C"}
                    />
                </div>

        },
        {
            title: "Acceptance",
            render: (rowData) => {
                if (rowData?.type === "Approved") {
                    if (rowData?.acceptance === "green") {
                        return (
                            <div style={{ backgroundColor: "transparent", color: "green" }}>
                                <img width="25px" height="25px" src={greenCheckIcon} />
                            </div>
                        );
                    } else if (rowData?.acceptance === null) {

                        return (
                            <div style={{ backgroundColor: "transparent" }}>
                                <img width="25px" height="25px" src={AmberGear} />
                            </div>
                        );
                    } else if (rowData?.acceptance === "red") {
                        return (
                            <div style={{ backgroundColor: "transparent" }}>
                                <img width="25px" height="25px" src={RedRemoveIcon} />
                            </div>
                        );
                    }
                }
                else {
                    return (
                        <div style={{ backgroundColor: "transparent" }}>

                        </div>
                    );
                }
            },
        },



    ]
    return (
        <div>
            <Dialog
                open={true}
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                className={classes.modal}
            >
                <div className={classes.container}>

                    <Typography color="primary">  Estimated Departure : {<span style={{ color: currentSelection?.etd_type === 0 ? "#4FC605" : "#FFB300" }}> {currentSelection?.etd_type === 0 ? "Approved" : currentSelection?.etd_type === 1 ? "Revised" : ""}</span>}</Typography>
                    <div style={{ paddingTop: '20px' }}>
                        <MaterialTable
                            style={{ boxShadow: "0px 1px 3px #00000033", marginTop: "10px" }}
                            icons={tableIcons}
                            classes
                            title={""}
                            columns={column}
                            data={estData || []}
                            isLoading={loading}
                            options={tableOptions}

                        />
                    </div>
                </div>
            </Dialog>
            {action === "est_history" && (
                <EstimationHistory
                    currentSelection={currentSelections}
                    handleClose={() => setAction(null)}

                />
            )}
        </div>
    )
}
