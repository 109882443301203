import React, { useEffect, useState } from 'react'
import MROTextField from '../../../../../components/form_components/TextField'
import DataService from '../../../../../lib/services/api'

export default function RecordsTotalHours({ name, workOrderId }) {

    const [totalHours, setTotalHours] = useState(0)

    useEffect(() => {
        let params = {
            reportable_id: workOrderId,
            reportable_type: "WorkOrder"
        }
        DataService.getData(params, 'operationTotalHours').then(res => {
            setTotalHours(res.data)
        })
    }, [])
    return (
        <div style={{ width: '100%', paddingRight: '25px', marginTop: '0.5rem' }}>
            <MROTextField name={name} label="Total Hours"
                value={totalHours}
                InputLabelProps={{ shrink: true }}
                inputProps={{ readOnly: true }}
                style={{
                    color: 'rgba(0, 0, 0, 0.87) !important', cursor: 'not-allowed !important',
                    backgroundColor: 'rgba(0, 0, 0, 0.1)'
                }}
            />
        </div>
    )
}
