// import request from "../common/api_client";
import requestrApi from "../common/boxr_api_client";
import requestApi from "../common/boxp_api_client";
// import { BASE_API_URL } from "lib/constants";

function fetchDependencies() {
  return requestrApi({
    url: `/services/fetch_dependencies`,
    method: "GET",
  });
}
function ServiceFetchDependencies(params) {
  return requestrApi({
    url: `/services/fetch_dependencies`,
    method: "GET",
    params: params
  });
}
function workshopEquipmentFetchDependencies(params) {
  return requestrApi({
    url: `/equipment_requests/fetch_dependencies`,
    method: "GET",
    params: params
  });
}
function fetchEquipDependencies() {
  return requestrApi({
    url: `/equipment_requests/fetch_dependencies`,
    method: "GET",
  });
}
function fetchSupplier(params) {
  return requestrApi({
    url: `/services/fetch_suppliers`,
    method: "GET",
    params: params,
  });
}
function fetchEquipmentSupplier(params) {
  return requestrApi({
    url: `/services/fetch_suppliers`,
    method: "GET",
    params: params,
  });
}
function getProcuremntApprvl(params) {
  return requestrApi({
    url: `/services/listing_proc_approvals`,
    method: "GET",
    params
  });
}
function releaseRequirement(params) {
  return requestApi({
    url: `/release_requirement`,
    method: "GET",
    params
  });
}
function services(params) {
  return requestrApi({
    url: `/services`,
    method: "GET",
    params
  });
}

function createServices(formData) {
  return requestrApi({
    url: `/services`,
    method: "POST",
    data: formData,
  });
}
function createEquipments(formData) {
  return requestrApi({
    url: `/equipments`,
    method: "POST",
    data: formData,
  });
}
function workshopCreateEquipments(formData) {
  return requestrApi({
    url: `/equipment_requests`,
    method: "POST",
    data: formData,
  });
}
function updateServices(formData) {
  return requestrApi({
    url: `/services/update_service`,
    method: "PUT",
    data: formData,
  });
}
function workshopUpdateEquipments(formData) {
  return requestrApi({
    url: `/equipment_requests/update_equipment`,
    method: "PUT",
    data: formData,
  });
}
function updatePurchase(formData) {
  return requestrApi({
    url: `/services/update_purchase_order`,
    method: "PUT",
    data: formData,
  });
}
function updateEquipmentQuoteServices(formData) {
  return requestrApi({
    url: `/equipments/update_equipment`,
    method: "PUT",
    data: formData,
  });
}

function updateDocuments(formData) {
  return requestrApi({
    url: `/services/update_documents`,
    method: "PUT",
    data: formData,
  });
}
function cancelServiceOrder(formData) {
  return requestrApi({
    url: `/services/cancel_so`,
    method: "PUT",
    data: formData,
  });
}
function createPurchaseOrder(formData) {
  return requestrApi({
    url: `/services/create_purchase_order`,
    method: "PUT",
    data: formData,
  });
}
function deleteServices(Id) {
  return requestrApi({
    url: `/services/delete_service`,
    method: "DELETE",
    data: Id,
  });
}

//Approval page
function editProcApproval(formData) {
  return requestrApi({
    url: `/services/edit_proc_approval`,
    method: "PUT",
    data: formData,
  });
}
function changeState(formData) {
  return requestrApi({
    url: `/services/change_state`,
    method: "PUT",
    data: formData,
  });
}

//send order report email
function sendOrderReportEmail(params) {
  return requestrApi({
    url: `/services/send_order`,
    method: "GET",
    params
  });
}
function deleteDocument(params) {
  return requestrApi({
    url: `/generals/remove_attachment`,
    method: "DELETE",
    params
  });
}

const ServiceRequestListClientServices = {
  updatePurchase,
  fetchDependencies,
  createServices,
  updateServices,
  updateDocuments,
  fetchSupplier,
  ServiceFetchDependencies,
  workshopEquipmentFetchDependencies,
  fetchEquipDependencies,
  createEquipments,
  fetchEquipmentSupplier,
  updateEquipmentQuoteServices,
  workshopCreateEquipments,
  deleteServices,
  createPurchaseOrder,
  getProcuremntApprvl,
  editProcApproval,
  changeState,
  cancelServiceOrder,
  releaseRequirement,
  workshopUpdateEquipments,
  services,
  sendOrderReportEmail,
  deleteDocument
};

export default ServiceRequestListClientServices;
