import requestrApi from "../../common/boxr_api_client";
import requestApi from "lib/services/api/common/boxp_api_client";





const getBaseStation = (params) => {
    return requestrApi({
        url: '/base_stations',
        method: "GET",
        params
    });
};

function getLineCustomers(params) {
    return requestrApi({
        url: '/line_customers/listing_line_customers',
        method: 'GET',
        params: params
    });
}


const getPlannedWorkOrder = (params) => {
    return requestrApi({
        url: '/work_orders/planned_work_orders',
        method: "GET",
        params
    });
};

const getPlannedTask = (params) => {
    return requestrApi({
        url: '/work_orders/planned_tasks',
        method: "GET",
        params
    });
};
const getPlannedTasksParts = (params) => {
    return requestrApi({
        url: '/work_orders/planned_parts',
        method: "GET",
        params
    });
};
const getPlannedTasksTools = (params) => {
    return requestrApi({
        url: '/work_orders/planned_tools',
        method: "GET",
        params
    });
};

const createPlannedTask = (formData) => {
    return requestrApi({
        url: '/work_orders/create_planned_task',
        method: "POST",
        data: formData
    });
};

const createTaskLibrary = (formData) => {
    return requestrApi({
        url: '/work_orders/create_task_library',
        method: "POST",
        data: formData
    });
};




const getAllDropDown = (params) => {
    return requestrApi({
        url: `/task_libraries/fetch_ata_chapters`,
        method: "GET",
        params
    });
};
const convertHourstoDecimal = (params) => {
    return requestrApi({
        url: `/work_orders/convert_hours_minutes`,
        method: "GET",
        params
    });
};

const createPlannedTools = (params) => {
    return requestrApi({
        url: '/work_orders/create_tool',
        method: "POST",
        params
    });
};


const getSubPlannedTasksTools = (params) => {
    return requestrApi({
        url: '/work_orders/task_tools',
        method: "GET",
        params
    });
};

const getSubPlannedTasksParts = (params) => {
    return requestrApi({
        url: '/work_orders/task_parts',
        method: "GET",
        params
    });
};

const createNewParts = (params) => {
    return requestrApi({
        url: '/work_orders/create_lm_part',
        method: "POST",
        params
    });
};

const searchCreateNewParts = (params) => {
    return requestrApi({
        url: 'work_orders/search_create_part',
        method: "POST",
        params
    });
};
const getPlannedLibraryListing = (params) => {
    return requestrApi({
        url: '/work_orders/task_library',
        method: "GET",
        params
    });
};
const updatePlannedTask = (params) => {
    return requestrApi({
        url: '/work_orders/edit_planned_tasks',
        method: "PUT",
        params
    });
};
const getRecords = (params) => {
    return requestrApi({
        url: '/work_orders/view_records',
        method: "GET",
        params
    });
};

const fetchAllToolsListing = (params) => {
    return requestrApi({
        url: '/toolings/toolings_dependencies',
        method: "GET",
        params
    });
};
const deleteWorkOrder = (params) => {
    return requestrApi({
        url: '/work_orders/destroy_work_order',
        method: "DELETE",
        params
    });
};


const deletePlannedTask = (params) => {
    return requestrApi({
        url: '/work_orders/delete_planned_tasks',
        method: "DELETE",
        params
    });
};
const deletePlannedTools = (params) => {
    return requestrApi({
        url: '/work_orders/delete_lmp_tool',
        method: "DELETE",
        params
    });
};
const deletePlannedParts = (params) => {
    return requestrApi({
        url: '/work_orders/delete_lmp_part',
        method: "DELETE",
        params
    });
};

const getToolingList = (params) => {
    return requestrApi({
        url: '/work_orders/tools_table',
        method: "GET",
        params
    });
};
const getToolingFinder = (params) => {
    return requestrApi({
        url: '/toolings',
        method: "GET",
        params
    });
};
// Task Library tab

const getTaskLibraryListing = (params) => {
    return requestrApi({
        url: '/work_orders/task_library_table',
        method: "GET",
        params
    });
};

const createJobRequest = (params) => {
    return requestrApi({
        url: "/part_requests/operation_part_request",
        method: "POST",
        params
    })
}
const updateTaskManHours = (formData) => {
    return requestrApi({
        url: '/task_libraries/update_task_library',
        method: "PUT",
        data: formData
    });
};


const getActualHoursListing = (params) => {
    return requestrApi({
        url: '/work_orders/avg_hours_table',
        method: "GET",
        params
    });
};

const createTaskWorkOrder = (formData) => {
    return requestrApi({
        url: '/work_orders/create_operational_task',
        method: "POST",
        data: formData
    });
}
const updateTaskWorkOrder = (formData) => {
    return requestrApi({
        url: 'work_orders/update_planned_task',
        method: "PUT",
        data: formData
    });
}
const getHistory = (params) => {
    return requestrApi({
        url: 'work_orders/history_table',
        method: "GET",
        params
    });
};
const changetaskState = (params) => {
    return requestrApi({
        url: '/work_orders/change_state?',
        method: "PUT",
        params
    });
};

const deleteAllTempPlannedTask = (formData) => {

    return requestrApi({
        url: '/work_orders/delete_parts_tool',
        method: "DELETE",
        data: formData
    });

}


const PlanningWorkOrderServices = {
    getBaseStation,
    getLineCustomers,
    getPlannedWorkOrder,
    getAllDropDown,
    convertHourstoDecimal,
    createPlannedTask,
    getPlannedTask,
    getPlannedTasksParts,
    getPlannedTasksTools,
    getTaskLibraryListing,
    createPlannedTools,
    getSubPlannedTasksTools,
    getSubPlannedTasksParts,
    createNewParts,
    searchCreateNewParts,
    getPlannedLibraryListing,
    updatePlannedTask,
    getRecords,
    createJobRequest,
    fetchAllToolsListing,
    deleteWorkOrder,
    deletePlannedTask,
    getToolingList,
    deletePlannedTools,
    deletePlannedParts,
    getActualHoursListing,
    updateTaskManHours,
    createTaskWorkOrder,
    getToolingFinder,
    updateTaskWorkOrder,
    createTaskLibrary,
    getHistory,
    changetaskState,
    deleteAllTempPlannedTask


}
export default PlanningWorkOrderServices