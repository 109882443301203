import React, { useContext, useState } from "react";
import { Box, CircularProgress, } from "@material-ui/core";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  // IconButton,
  Typography,
} from "@material-ui/core";
import exclamationIcon from "assets/exclamation.png"
import WarningIcon from "@material-ui/icons/Warning";
import MROButton from "components/buttons";
import { ToastMessageContext } from "lib/contexts/message_context";
import { CertificateServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/Certification";
import { MaintenanceServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation";
import { CalenderMaintenanceService } from "lib/services/api/operaitons/WorkShop/Storage/CalenderMaintenanceService";

// import { WorkshopContext } from "./WorkshopContext/WorkshopContext";

// const useStyles = makeStyles(() => {});
function CautionPopUp(props) {
  const [acceptAll, setAcceptAll] = useState(false);
  const [busy, setBusy] = useState(null);
  const message = useContext(ToastMessageContext);
  const { getWorkshopOrderDetails, editTab, setEditTab, closeHandler, open, handleClose, getMaintenanceOrderList, cautionMessages, getCertificationDetails, workshop_id, tab, action_type, title, show } = props;
  // const classes = useStyles();

  const handleCertification = async (formData) => {
    formData.append("workshop_order_id", workshop_id)
    await CertificateServices.unlockCertification(formData)
      .then((res) => {
        if (res) {
          handleClose(res.success)
          getCertificationDetails()
          getWorkshopOrderDetails()
          getMaintenanceOrderList()
          message.showToastMessage({
            message: "Completed successfully!!",
            variant: "success",
          });
        }
      })
      .catch(() => {
        message.showToastMessage({
          message: "Something went wrong. Try again",
          variant: "error",
        });
      })
      .finally(() => {
        setBusy(null);
      });
  }
  const handlePlanningClosure = async (formDataProps) => {
    console.log("formdata is", formDataProps)
    await MaintenanceServices.planningData(
      formDataProps,
      ""
    )
      .then((res) => {
        message.showToastMessage({
          message: "Planning completed Successfully",
          variant: "success",
        });
      })
      .catch(() => {
        message.showToastMessage({
          message: "Something went wrong. Try again!",
          variant: "error",
        });
        setBusy(null);
      });
  }
  const handleInspMaintProd = async (formData) => {
    formData.append("workshop_order_id", workshop_id)
    formData.append("action_type", action_type)
    await MaintenanceServices.unlockPopup(formData)
      .then((res) => {
        if (res.success) {
          message.showToastMessage({
            message: "Completed successfully!!",
            variant: "success",
          });
        }
        handleClose();
        getWorkshopOrderDetails()
        getMaintenanceOrderList()
      })
      .catch(() => {
        message.showToastMessage({
          message: "Something went wrong. Try again",
          variant: "error",
        });
      })
      .finally(() => {
        setBusy(null);
      });
  }

  const handleCalendarClosure = async (formData) => {
    formData.append("workshop_order_id", workshop_id)
    formData.append("action_type", action_type)
    await CalenderMaintenanceService.unlockPopup(formData)
      .then((res) => {
        if (res.success) {
          message.showToastMessage({
            message: "Completed successfully!!",
            variant: "success",
          });
        }
        handleClose();
        getWorkshopOrderDetails()
        getMaintenanceOrderList()
      })
      .catch(() => {
        message.showToastMessage({
          message: "Something went wrong. Try again",
          variant: "error",
        });
      })
      .finally(() => {
        setBusy(null);
      });
  }



  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target)
    setBusy("loading");

    if (tab === "certification") {
      handleCertification(formData)
    }

    if (tab === "inspection") {
      handleInspMaintProd(formData)
    }
    if (tab === "planning") {
      handlePlanningClosure()
    }
    if (tab === "calendar") {
      handleCalendarClosure(formData)
    }
  };

  const closeHandle = () => {
    if (tab === "inspection" || tab === "certification" || tab === "calendar") {
      handleClose()
      setEditTab(!editTab)
    } else {
      handleClose()
    }
  }

  if (props?.isGettingDetails) {
    return (
      <Box
        width="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress color="primary" size={40} />
      </Box>
    );
  }

  return (
    <div>
      {" "}
      <Dialog
        aria-labelledby="customized-dialog-title"
        onClose={closeHandle}
        open={open}
      >
        <DialogTitle id="customized-dialog-title">
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <Box display="flex" alignItems="center">
              <Typography
                style={{
                  fontSize: "18px",
                }}
                color="primary"
              >
                {title ?? "Caution - Unlock / open tab?"}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center">
              {" "}
              <img
                src={exclamationIcon}
                alt="Icon"
              // style={{ height: "28px" }}
              />{" "}
            </Box>
          </Box>
          <Typography>{show ?? "Read and accept before continuing"}</Typography>
        </DialogTitle>
        <form noValidate onSubmit={handleSubmit}>
          <DialogContent style={{ width: "500px" }}>
            {cautionMessages?.map((item) => (
              <Box
                m={0}
                pt={2}
                display="flex"
                alignItems="center"
                key={item?.id}
              >
                <WarningIcon
                  style={{
                    color: "#FFB300",
                    marginRight: "10px",
                    fontSize: 20,
                  }}
                />
                {item?.name}
              </Box>
            ))}
            <Box pt={3} display="flex" alignItems="center">
              <span style={{ textDecoration: "underline" }}>Accept all</span>
              &nbsp;&nbsp;&nbsp;
              <input
                type="checkbox"
                value={acceptAll}
                onClick={() => setAcceptAll(!acceptAll)}
              />
            </Box>
            <Box
              mt="auto"
              display="flex"
              justifyContent="flex-end"
              style={{ marginTop: "20px" }}
            >
              <MROButton
                style={{ marginRight: "20px" }}
                type="button"
                variant={"contained"}
                onClick={closeHandle}
              >
                Cancel
              </MROButton>
              <MROButton
                type={acceptAll ? "submit" : "button"}
                variant={"contained"}
                color={acceptAll ? "primary" : ""}
                style={{ backgroundColor: !acceptAll && "#FFB300" }}
                loading={busy === "loading"}
              >
                {props?.btnTitle ?? "Continue"}
              </MROButton>
            </Box>
          </DialogContent>
        </form>
      </Dialog>
    </div>
  );
}

export default CautionPopUp;
