import React, { useEffect, useState } from "react";
import {

    MuiPickersUtilsProvider,
    KeyboardDateTimePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { makeStyles } from '@material-ui/core';
import moment from "moment";
import EventIcon from 'assets/CalendarIcon.svg'




const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(1, 0),
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: theme.palette.primary.main + '!important',
                borderWidth: '1px !important'
            },
            '&:hover fieldset': {
                borderColor: theme.palette.primary.light + '!important',
                // borderWidth: '1px !important'
            },
            '& .MuiOutlinedInput-input': {
                padding: '15px'
            }
        },
        '& label': {
            color: theme.palette.primary.main
        }
    },
}))

function PartsDateTimeField({ label, name, defaultValue, onChange }) {
    const classes = useStyles();
    const [selectedDate, setSelectedDate] = useState(null);
    const [open, setOpen] = useState(false)

    useEffect(() => {
        if (defaultValue !== 'Invalid date' && defaultValue !== null) {
            setSelectedDate(defaultValue ? moment(defaultValue, "DD/MM/YYYY") : null)
        }
        else {
            setSelectedDate(null)
        }
    }, [defaultValue]);

    const changeDate = (date) => {

        if (date === null) {
            setSelectedDate(null)
            onChange && onChange(null);


        } else {
            setSelectedDate(date);
            onChange && onChange(moment(date).format("DD/MM/YYYY"));

        }
    }

    const TextComponent = (value) => {
        return value === null ? <img src={EventIcon} height={"18px"} onClick={() => {
            setOpen(true)
        }} style={{ cursor: "pointer" }} /> : <a style={{ textDecoration: "underline", fontSize: "14px" }} onClick={() => {
            setOpen(true)
        }}  >{moment(value).format("DD/MM/YYYY")}</a>
    }

    return (

        <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDateTimePicker
                clearable
                className={classes.root}
                slotProps={{ textField: { display: "none" } }}
                TextFieldComponent={() => TextComponent(selectedDate)}
                autoOk
                open={open}
                onClick={() => setOpen(true)}
                onClose={() => setOpen(false)}
                id=""
                ampm={false}
                inputVariant="standard"
                label={label}
                color={"primary"}
                format={"DD/MM/YYYY"}
                value={selectedDate}
                inputProps={{ readOnly: true }}
                InputAdornmentProps={{ position: "start", }}
                InputProps={{
                    disableUnderline: true,

                }}
                // keyboardIcon={selectedDate ? "" : <img src={EventIcon} height={"18px"} />}
                onChange={date => { changeDate(date) }}
                style={{ width: "100px", color: "#fff" }}

            />
            <input style={{ display: 'none' }} name={name || "date"} defaultValue={defaultValue} value={selectedDate ? moment(selectedDate).format("DD/MM/YYYY") : null} />
        </MuiPickersUtilsProvider>
    )
}

export default React.memo(PartsDateTimeField);