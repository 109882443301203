import React, { useReducer, useState, useContext, useEffect } from "react";
import MROFullScreenRightDrawer from "components/fullwidth_dialog";
import { makeStyles } from "lib/utils/common_utils";
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Tooltip,
  useTheme,
  Typography, Grid
} from "@material-ui/core";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import moment from "moment";
import LoadingIndicator from "components/universal/loading_indicator";
import tableReducer, {
  INITIAL_TABLE_STATE,
} from "../../../../../reducers/table_reducer";
import TableAction from "../../../../../actions/table_actions";
import MaterialTable from "material-table";
import tableIcons, {
  options,
} from "../../../../../components/universal/table_attributes";
import ViewIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import { ICON_COLOUR } from "../../../../../lib/constants/style_constants";
import RescheduleIcon from "@material-ui/icons/History";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import MROTextField from "../../../../../components/form_components/TextField";
import MROButton from "../../../../../components/buttons";
import EditPartDetails from "./EditPartDetails";
import HistoryTablePopup from "views/Stores/Parts/History/HistoryTablePopup";
import ReplayIcon from "@material-ui/icons/Replay";
import ReturnIssuedPart from "./ReturnIssuedPart/ReturnIssuedPart";
import { ToastMessageContext } from "lib/contexts/message_context";
import { OperationService } from "lib/services/api/operaitons/save_operation";
import HistoryClientServices from "lib/services/api/stores/parts/historyClient";
import CertificatePopup from "views/Stores/Parts/Stocks/CertificatePopup";
import { BASE_API_URL_BOXR } from "lib/constants";
import PartsClientServices from "lib/services/api/stores/tooling/parts_client";
import _ from "lodash";
import WarningIcon from "@material-ui/icons/Warning";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#fff",
    color: "#000000CC",
    boxShadow: "0px 3px 6px #0000001A",
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  wrapper: {
    padding: "0 3%",
    background: "white",
  },
  root: { "& > :first-child": { position: "unset" } },
  //   detail: {
  //     padding: "3%",
  //     backgroundColor: "#fff",
  //     boxShadow: "0px 3px 6px #0000001A",
  //   },
  partKey: {
    "& p": {
      fontSize: "14px",
      padding: "5px",
    },
  },
  partValue: {
    fontSize: "14px",
    color: "#4D4F5C",
    padding: "5px",
  },
  partCard: {
    borderRadius: "8px",
    padding: "17px 24px",
    "& p": {
      fontSize: "14px",
      padding: "5px",
    },
  },
}));

const GetInfoCard = (props) => {
  const classes = useStyles();
  return (
    <Box display="flex">
      <Box flex={50} className={classes.partKey}>
        <Typography color="secondary">{props?.keyName}</Typography>
      </Box>
      <Box flex={50} className={classes.partValue}>
        {props?.value ?? "-"}
      </Box>
    </Box>
  );
};

function PartInfo(props) {
  const { handleClose, currentSelectedData, getData, closeHandler, name, mroOwned } = props;


  const message = useContext(ToastMessageContext);

  const theme = useTheme();
  const classes = useStyles();

  const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
  const { page, totalCount, searchText, inProgress, data } = tableState;
  const [tableRefresh, setTableRefresh] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [busy, setBusy] = useState(null);
  const [value, setValue] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isReturnIssuedPart, setIsReturnIssuedPart] = useState(false);
  const [partInfo, setPartInfo] = useState({});
  const [popupData, setPopupData] = useState([]);
  const [isMaterialPopupOpen, setIsMaterialPopupOpen] = useState(false);
  const [msds, setMsds] = useState([]);
  const [allocId, setAllocId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [partDetails, setPartDetails] = useState({});
  const [currentPartId, setCurrentPartId] = useState();
  const [selectedSecondaryPartId, setSelectedSecondaryPartId] = useState("");
  const [currentSelection, setCurrentSelection] = useState({});
  const [pageSize, setPageSize] = useState(5);
  const [requestedDate, setRequestedDate] = useState("");

  useEffect(() => {
    if (
      partInfo?.part_condition_details?.created_at != undefined &&
      partInfo?.part_condition_details?.created_at != null
    ) {
      let momentDate = moment.utc(partInfo?.part_condition_details?.created_at);
      let formattedDate = moment(momentDate, "DD/mm/YYYY hh:mm")
        .local()
        .format("DD/MM/YYYY hh:mm");
      setRequestedDate(formattedDate);
    }
  }, [partInfo]);
  console.log("mroooooooo", currentSelection.id, currentSelectedData)
  const tableOptions = {
    ...options,
    page: page,
    search: false,
    total: totalCount,
    pageSize: pageSize,
    searchText: searchText,
  };

  const handleCloseFunc = () => {
    setTableRefresh(!tableRefresh);
    setIsEdit(false);
  };

  const getList = () => {
    const params = {
      id: currentSelectedData?.id,
      page: page + 1,
      count_per_page: pageSize,
    };
    TableAction.getList(dispatch, message, params, "partInfoTable");
  };

  const getPartDetials = () => {
    // setTableRefresh(!tableRefresh)
    OperationService.getRequestPartDetails({ id: currentSelectedData.id })
      .then((res) => {
        setPartInfo(res);
      })
      .catch((error) => {
        console.log("Something went wrong!", error);
      });
  };

  useEffect(() => {
    getList();
  }, [currentSelectedData?.id, page, pageSize]);

  useEffect(() => {
    getPartDetials();
  }, [currentSelectedData?.id]);

  const getPopupData = async () => {
    const params = {
      id: currentSelection.id,
    };

    try {
      setBusy("loading");
      const { data } = await HistoryClientServices.getConditionedParts(params);
      setPopupData(data);
    } catch (error) {
      setBusy("error");
    } finally {
      setBusy(null);
    }
  };

  useEffect(() => {
    if (currentSelection.id) {
      getPopupData();
    }
  }, [currentSelection.id]);

  useEffect(() => {
    console.table(currentSelectedData);
    console.log("yes no field value", currentSelectedData?.repairable);
    const defaultValue = partInfo?.part_condition_details?.rotable_removed
      ? "yes"
      : "no";
    setValue(defaultValue);
  }, [partInfo?.part_condition_details]);

  const handleToggle = (value) => {
    if (value) {
      setValue(value);
    }
  };

  const renderStatBtn = (condition) => {
    if (condition === "issued" || condition === "rts") {
      return (
        <MROButton
          variant="outlined"
          style={{
            borderColor: "darkgrey",
            color: "darkgrey",
            borderRadius: "8px",
            PointerEvent: "none",
            textTransform: "uppercase",
          }}
        >
          {condition}
        </MROButton>
      );
    } else {
      const bgColor = condition === "pre_load" ? "#4FC607" : "#FFB302";
      return (
        <MROButton
          variant="contained"
          style={{
            background: bgColor,
            color: "white",
            borderRadius: "8px",
            PointerEvent: "none",
            textTransform: "uppercase",
          }}
        >
          {condition}
        </MROButton>
      );
    }
  };

  useEffect(() => {
    if (partDetails.alternate_part_id) {
      setCurrentPartId(partDetails.alternate_part_id);
    } else {
      setCurrentPartId(currentSelectedData.prime_part_id);
    }
  }, [partDetails.alternate_part_id, currentSelection.id, currentSelectedData.prime_part_id]);

  const getPartList = () => {
    const params = {
      id: currentPartId,
      page: 1 + page,
      count_per_page: pageSize,
    };

    setLoading(true);
    PartsClientServices.getAllSecondaryParts(params).then((res) => {
      if (res?.data) {
        setSelectedSecondaryPartId(res?.data?.secondary_part_id);
        setLoading(false);
        TableAction.setTotalCount(dispatch, res.total_count);
      } else {
        setSelectedSecondaryPartId([]);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    setLoading(true);
    if (currentPartId) {
      getPartList();
    }
  }, [currentPartId, pageSize, page]);

  const columns = [
    {
      field: "alloc_id",
      title: "ALOC ID",
    },
    {
      field: "batch_number",
      title: "B/N",
    },
    {
      field: "quantity",
      title: "QTY",
    },
    {
      field: "condition",
      title: "State",
      render: (rowData) => renderStatBtn(rowData.condition),
    },
    {
      field: "user",
      title: "By",
    },
    {
      field: "receipt_date",
      title: "Date",
    },
    {
      field: "eta",
      title: "ETA",
    },
    {
      field: "cert",
      title: "Cert",
      render: (rowData) => (
        <IconButton
          onClick={() => {
            setIsMaterialPopupOpen(true);
            setMsds(
              rowData.certificates?.map((item) => ({
                ...item,
                url: BASE_API_URL_BOXR + item.url,
              }))
            );
          }}
        >
          <ViewIcon fontSize="small" color={ICON_COLOUR} />
        </IconButton>
      ),
    },
    {
      field: "",
      title: "Action",
      render: (rowData) => (
        <Box display="flex" alignItems="center" justifyContent="center">
          <Tooltip title="History">
            <IconButton
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setCurrentSelection(rowData);
              }}
              className={"text-info"}
            >
              <RescheduleIcon />
            </IconButton>
          </Tooltip>
          {rowData.condition?.toLowerCase() === "issued" && (
            <IconButton
              disabled={!props?.permission?.write}
              onClick={() => {
                setCurrentSelection(rowData);
                setIsReturnIssuedPart(true);
                setAllocId(rowData.alloc_id);
              }}
            >
              <Tooltip title="Return">
                <ReplayIcon />
              </Tooltip>
            </IconButton>
          )}
          <HistoryTablePopup
            busy={busy}
            anchorEl={anchorEl}
            handleClick={(event) => setAnchorEl(event.currentTarget)}
            handleClose={() => setAnchorEl(null)}
            currentSelection={currentSelection}
            data={popupData}
          />
        </Box>
      ),
    },
  ];

  useEffect(() => {
    setLoading(true);
    if (currentPartId) {
      PartsClientServices.getPrimaryPartInfo(currentPartId)
        .then((response) => {
          response.data && setPartDetails(response.data);
          setMsds(response.data.mds);
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    }
  }, [currentPartId]);

  return (
    <MROFullScreenRightDrawer open={true} className={classes.root}>
      <AppBar position="static" className={classes.appBar} elevation="">
        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
          <Box display="flex" alignItems="center">
            <IconButton
              onClick={handleClose}
              edge="start"
              className={classes.backButton}
              color="inherit"
              aria-label="back"
            >
              <BackIcon />
            </IconButton>
            <Typography variant="subtitle2" style={{ fontSize: "17px" }}>Part Details </Typography>
          </Box>
          <IconButton disabled={!props?.permission?.write} onClick={() => setIsEdit(true)}>
            <EditIcon fontSize="small" color={ICON_COLOUR} />
          </IconButton>
        </Toolbar>
      </AppBar>
      {/* <div className={classes.wrapper}>
                <GeneralWorkOrderDetails
                    getList={props.getList}
                    handleClose={handleClose}
                    data={currentSelection} /> 
            </div> */}
      {true ? (
        <div className={classes.wrapper} style={{ marginTop: "0.5%" }}>
          {/* Request detials container */}
          <Box mt="35px">
            <Grid container>
              <Grid item xs={10}>
                <Typography
                  style={{
                    color: theme.palette.primary.main,
                    marginBottom: "12px",
                    fontSize: "17px",
                  }}
                  color="primary"
                  variant="subtitle2"
                >
                  Request Details
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Grid container style={{ marginBottom: '12px' }}>
                  {partInfo?.data?.customer_supply === true ?
                    <>
                      < WarningIcon
                        style={{
                          color: "#FFB300",
                          marginRight: "10px",
                          fontSize: 20,
                        }}
                      />
                      <Typography >Customer Supply Part!</Typography>
                    </> : <Typography> </Typography>}
                </Grid>
              </Grid>
            </Grid>

            <Box
              display="flex"
              alignItems="center"
              justifyContent={(loading || _.isEmpty(partDetails)) && "center"}
              bgcolor="#F5F5F5"
              className={classes.partCard}
            >
              <Box flexGrow={1}>
                <div style={{ width: "100%" }}>
                  <GetInfoCard
                    keyName={"Part Number"}
                    value={currentSelectedData?.part_number || "-"}
                  />
                  <GetInfoCard
                    keyName={"Description"}
                    value={currentSelectedData?.description || "-"}
                  />
                  <GetInfoCard
                    keyName={"Maint Data Ref"}
                    value={currentSelectedData?.maint_data_ref || "-"}
                  />

                  <GetInfoCard
                    keyName={"Quantity"}
                    value={Math.abs(partInfo?.data?.required_quantity) ?? "-"}
                  />
                  <GetInfoCard
                    keyName={"Allocated"}
                    value={partInfo?.data?.allocated_quantity ?? 0}
                  />
                  <GetInfoCard
                    keyName={"Remaining"}
                    value={Math.abs(partInfo?.data?.remaining_quantity) ?? "-"}
                  />
                </div>
              </Box>
              <Box flexGrow={1}>
                <div style={{ width: "100%" }}>
                  <GetInfoCard
                    keyName={"Requested"}
                    value={requestedDate || "-"}
                  />
                  <GetInfoCard
                    keyName={"Requested By"}
                    value={currentSelectedData?.requested_by || "-"}
                  />

                  <GetInfoCard
                    keyName={"Request ID"}
                    value={currentSelectedData?.request_id || "-"}
                  />
                  <GetInfoCard
                    keyName={"DD / HIL #"}
                    value={
                      partInfo?.part_condition_details?.defferal_data || "-"
                    }
                  />
                  <GetInfoCard
                    keyName={"Location"}
                    value={
                      partInfo?.part_condition_details?.install_location || "-"
                    }
                  />
                  <Box flexGrow={1} justifyContent="center" alignItem="center">
                    <div style={{ width: "100%" }}>
                      <GetInfoCard
                        keyName={"Material Data"}
                        value={
                          <div style={{ marginTop: "3px" }}>
                            <ViewIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => setIsMaterialPopupOpen(true)}
                              fontSize="small"
                              htmlColor={ICON_COLOUR}
                            />
                          </div>
                        }
                      />
                    </div>
                  </Box>
                </div>
              </Box>
            </Box>
          </Box>

          {/* Allocation details section */}
          <Box mt="20px" mb='40px'>
            <Typography
              style={{
                color: theme.palette.primary.main,
                marginBottom: "12px",
                fontSize: "16px",
              }}
              color="primary"
              variant="subtitle2"
            >
              Allocated Details
            </Typography>

            <MaterialTable
              style={{ boxShadow: "0px 1px 3px #00000033" }}
              columns={columns}
              icons={tableIcons}
              title=""
              data={data.data}
              isLoading={inProgress}
              options={tableOptions}
              onChangePage={(page) => {
                TableAction.setPage(dispatch, page);
              }}
              onChangeRowsPerPage={(pageSize) => {
                // TableAction.setPageSize(dispatch, pageSize);
                setPageSize(pageSize);
              }}
              onSearchChange={(search) => {
                TableAction.setSearchText(dispatch, search);
              }}
              totalCount={totalCount}
              page={page}
            />
          </Box>

          {/* Removal section */}
          {/* <Box mt="10px" width="60%">
            <Typography
              style={{
                color: theme.palette.primary.main,
                marginBottom: "12px",
                fontSize: "16px",
              }}
              color="primary"
              variant="subtitle2"
            >
              Part Removal
            </Typography>

            <Box mb={1}>
              <Box display="inline-block" mr={2}>
                <Typography gutterBottom={12} style={{ fontWeight: "bold" }}>
                  Repairable Part Removed? *
                </Typography>
              </Box>
              <input
                style={{ display: "none" }}
                name="part_removed"
                value={value === "no" ? false : true}
              />
              <ToggleButtonGroup
                value={value}
                name=""
                exclusive
                onChange={(e, val) => handleToggle(val)}
                aria-label="yes-or-no"
                style={{ pointerEvents: "none" }}
              >
                <ToggleButton
                  // className={
                  //   errors.part_removed && !errors?.part_removed?.valid
                  //     ? classes.error
                  //     : {}
                  // }
                  size={"small"}
                  value="no"
                  aria-label="centered"
                >
                  No
                </ToggleButton>
                <ToggleButton
                  // className={
                  //   errors.part_removed && !errors?.part_removed?.valid
                  //     ? classes.error
                  //     : {}
                  // }
                  size={"small"}
                  value="yes"
                  aria-label="left aligned"
                >
                  Yes
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>

            {value === "yes" && (
              <Box width="100%">
                <Box width="100%" display="flex">
                  <Box width="50%" mr="40px">
                    <MROTextField
                      label={"Part# off*"}
                      placeholder="Enter Part Number"
                      name={"part_off"}
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      disabled
                      value={partInfo?.part_condition_details?.part_off}
                    />
                  </Box>
                  <Box width="50%">
                    <MROTextField
                      label={"Serial # Off *"}
                      placeholder="Enter Part Number"
                      name={"serial_off"}
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      disabled
                      value={partInfo?.part_condition_details?.serial_off}
                    />
                  </Box>
                </Box>

                <Box width="100%" mb="10px">
                  <MROTextField
                    label={"U/S Comment *"}
                    placeholder="Enter a removal reason or other comment as applicable - Max 150 Characters"
                    name={"comment"}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    disabled
                    value={partInfo?.part_condition_details?.issued_comment}
                  />
                </Box>

                <Box style={{ marginBottom: "20px" }}>
                  <MROButton
                    variant="contained"
                    color="secondary"
                    onClick={() =>
                      currentSelectedData?.document_url
                        ? window.open(
                          BASE_API_URL_BOXR + currentSelectedData?.document_url
                        )
                        : null
                    }
                    startIcon={<ViewIcon color={ICON_COLOUR} />}
                  >
                    View U/S Label
                  </MROButton>
                </Box>
              </Box>
            )}
          </Box> */}
        </div>
      ) : (
        <div>
          <LoadingIndicator variant={"block"} />
        </div>
      )}

      <EditPartDetails
        open={isEdit}
        currentSelectedData={partInfo}
        onClose={handleCloseFunc}
        getList={getData}
        getPartDetials={getPartDetials}
        dispatch={dispatch}
        closeHandler={closeHandler}
      />

      {isReturnIssuedPart && (
        <ReturnIssuedPart
          currentSelection={currentSelection}
          open={isReturnIssuedPart}
          onClose={() => setIsReturnIssuedPart(false)}
          allocId={allocId}
          qtyFieldTitle="Quantity Returned"
          getPopupData={getPopupData}
          name={name}
          updateTable={() => {
            getList();
            getData();
            getPartDetials();
          }}
          mroOwned={mroOwned}
        />
      )}
      {isMaterialPopupOpen && (
        <CertificatePopup
          open={isMaterialPopupOpen}
          dialogCloseHandler={() => setIsMaterialPopupOpen(false)}
          // selectedSecondaryPart={selectedRow}
          selectedSecondaryPartId={selectedSecondaryPartId}
          msds={msds}
        />
      )}
    </MROFullScreenRightDrawer>
  );
}

export default PartInfo;
