import React, { useContext, useState, useEffect } from 'react'
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import { Dialog, DialogContent, DialogTitle, Box, MenuItem } from "@material-ui/core";
import MROButton from 'components/buttons';
import { Typography } from "@material-ui/core";
import { ToastMessageContext } from "lib/contexts/message_context";
import DateField from 'components/form_components/DateField';
import MROTextField from "components/form_components/TextField";
import ServiceRequestListClientServices from "lib/services/api/procurement/Services";
import { MaintenanceServices } from 'lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation';
import { WorkshopContext } from 'views/Operations/Workshop/WorkshopContext/WorkshopContext';


const useStyles = makeStyles((theme) => ({
    formWrapper: {
        background: "#fff",
        width: "100%",
    },
    title: {
        display: "flex",
        justifyContent: "left",
        "& h2": {
            //   color: theme.palette.primary.main,
            fontSize: "40px",
            fontWeight: 40,
        },
    },
    subTitle: {
        fontSize: "16px",
        fontWeight: "bold",
        marginBottom: "20px",
        color: "#4D4F5C",
    },
    card: {
        padding: "0 25px",
    },
    actionButtons: {
        paddingBottom: "33px",
        textAlign: "end",
        marginRight: "6px",
        marginTop: '21px',
        "& button:not(:last-child)": {
            marginRight: "20px",
        },
    },
    purchaseGrid: {
        paddingTop: "0 !important",
        paddingBottom: "0 !important",
        height: "85px",
    },
    purchaseStock: {
        marginBottom: "75px",
        maxWidth: "340px",
        marginTop: '10px'
    },
    modal: {
        "& .MuiDialog-paperWidthMd": {
            width: "24%",
        },
    }
}));

function ServicesAdditionalPopUp(props) {
    const classes = useStyles();
    const { handleClose, currentWorkshopDetails, currentClosedDetails, selectedRow, currentActionId, orderType, currentActionData } = props
    const message = useContext(ToastMessageContext)
    const { setServiceFlag } = useContext(WorkshopContext)
    const [error, setError] = useState({});


    console.log("action id====", currentActionData)


    const handleSubmit = async (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        let userDetails = JSON.parse(sessionStorage.getItem("user"));
        let valid = true;
        let errors = {
            desired_date: false

        };

        if (formData && formData.get("desired_date").length === 0) {
            errors.desired_date = true;
            valid = false;
        }
        if (!valid) {

            setError(errors);
            return;
        }
        if (orderType === "Maintenance" || orderType === "Production") {
            formData.append("workshop_action_id", currentActionId)
            formData.append("service_id", selectedRow.id)
            formData.append("location", selectedRow.location);
            formData.append("part_scope_id", selectedRow.part_scope_id);
            formData.append("approval_type_id", selectedRow?.approval_type_id);
            formData.append("part_category_id", selectedRow.part_category_id)
            formData.append("user_id", userDetails?.id);
            formData.append("workshop_order_id", currentWorkshopDetails?.id);
            formData.append("description", selectedRow?.description);
            formData.append("tech_data_ref", selectedRow?.tech_data_ref);
            formData.append("base_station_id", currentWorkshopDetails?.base_station_id);
            formData.append("from_workshop_action_id", currentWorkshopDetails?.id);
            formData.append("to_workshop_action_id", currentActionData?.workshop_order_id);
        }
        else if (orderType === "Maintenance Template" || orderType === "Production Template") {
            formData.append("workshop_action_id", currentActionId)
            formData.append("service_id", selectedRow.id)
            formData.append("location", selectedRow.location === "Supplier Site" ? "supplier_off_site" : "mro_on_site");
            formData.append("part_scope_id", selectedRow.part_scope_id);
            formData.append("approval_type_id", selectedRow?.approval_type_id);
            formData.append("part_category_id", selectedRow.part_category_id)
            formData.append("user_id", userDetails?.id);
            formData.append("workshop_order_id", currentWorkshopDetails?.id);
            formData.append("description", selectedRow?.description);
            formData.append("tech_data_ref", selectedRow?.tech_data_ref);
            formData.append("base_station_id", currentWorkshopDetails?.base_station_id);
            formData.append("from_workshop_action_id", currentWorkshopDetails?.id);
            formData.append("to_workshop_action_id", currentActionData?.workshop_order_id);
            formData.append("wst_service_id", selectedRow.id);
        }
        // selectedRow.tech_data.forEach(file => formData.append("tech_data", file))
        // selectedRow.order_reports.forEach(file => formData.append("order_reports", file))


        await ServiceRequestListClientServices.createServices(formData).then((response) => {
            if (response.success) {
                setServiceFlag(true)
                handleClose()
                message.showToastMessage({
                    message: "Copied successfully ",
                    variant: "success",
                });
            }
        }).catch((error) => {
            message.showToastMessage({
                message: "Something went wrong",
                variant: "error",
            });
        })
    }

    return (
        <div>
            <Dialog
                maxWidth={"md"}
                open={true}
                className={classes.modal}
                onClose={handleClose}
                aria-labelledby="base-station-manage"
            >
                <DialogTitle>
                    <Typography
                        color="primary"
                        className={classes.title}
                    >
                        Additional Data Required
                    </Typography>
                </DialogTitle>
                <DialogContent className={classes.card}>
                    <div className={classes.formWrapper}>
                        <form noValidate onSubmit={handleSubmit}>


                            <Grid item xs={12}>
                                <DateField
                                    required
                                    name="desired_date"
                                    label={"Desired Date"}
                                    InputLabelProps={{ shrink: true }}
                                    error={error.desired_date}
                                    helperText={error.desired_date ? "*please enter a date" : null}
                                    onChange={(e) => { setError({ ...error, desired_date: false }); }}

                                />
                            </Grid>

                            <div className={classes.actionButtons}>
                                <MROButton
                                    //   disabled={loading}
                                    type="button"
                                    variant={"contained"}
                                    onClick={handleClose}
                                >
                                    Cancel
                                </MROButton>
                                <MROButton
                                    //   disabled={loading}
                                    type="submit"
                                    variant={"contained"}
                                    color="primary"
                                >
                                    Complete
                                </MROButton>
                            </div>
                        </form>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default ServicesAdditionalPopUp