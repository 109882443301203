import React, { useState, useContext } from "react";
import MROFullScreenRightDrawer from "components/fullwidth_dialog";
import {
    AppBar,
    IconButton,
    Toolbar,
    makeStyles,
    Typography,
    Box,
    ButtonGroup,
} from "@material-ui/core";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import MROButton from "components/buttons";
import { WorkshopContext } from 'views/Operations/Workshop/WorkshopContext/WorkshopContext';
import CopyPreviuos from "./CopyPrevious";
import CopyTemplates from "./CopyTemplates";


const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: "#fff",
        color: "#000000CC",
        boxShadow: "0px 3px 6px #0000001A",
    },
    backButton: {
        marginRight: theme.spacing(2),
    },
    root: { "& > :first-child": { position: "unset" } },
    wrapper: {
        padding: "3%",
        background: "white",
    },
}));
export default function CopyNewFunction(props) {
    const { closeTabAction, handleClose, currentSelection, workshop_id, getWorkshopOrderDetails, getMaintenanceOrder, getPlanningList, setPlanningAction, actionTabId, planningId, planningNullId, planningWholeDetails, copyTab } = props
    const classes = useStyles();
    const [state, setState] = useState("Templates");
    const { setCopyInspection, setCopyParts, setCopyServices, setServiceFlag, setEquipmentFlag, setCopyEquipments, copyInspection, copiedJobs, setCopiedJobs } = useContext(WorkshopContext)


    const handleCopyClose = () => {
        handleClose()
        setCopyInspection(false)
        setServiceFlag(false)
        setCopyParts([])
        setCopyServices([])
        setEquipmentFlag(false)
        setCopyEquipments([])
        setCopiedJobs([])
    }

    return (
        <MROFullScreenRightDrawer open={true} className={classes.root}>
            <AppBar position="static" className={classes.appBar} elevation={0}>
                <Toolbar>
                    <IconButton
                        onClick={() => {
                            handleClose()
                            setPlanningAction(null)
                            getPlanningList()
                            getWorkshopOrderDetails()
                            getMaintenanceOrder()
                        }}
                        edge="start"
                        className={classes.backButton}
                        color="inherit"
                        aria-label="back"
                    >
                        <BackIcon />
                    </IconButton>
                    <Typography variant="subtitle2">Copy From</Typography>
                </Toolbar>
            </AppBar>
            <div className={classes.wrapper}>
                <Typography style={{ paddingLeft: '10px' }}

                >Select where you want to copy from</Typography>
                <Box mt="10px" mb="10px" ml={'10px'}>
                    <ButtonGroup color="primary">
                        <MROButton
                            variant={state === "Templates" ? "contained" : "outlined"}
                            onClick={() => setState("Templates")}
                            style={{
                                width: "125px",
                                color: state === "Templates" ? "white" : undefined,
                            }}

                        >
                            Templates
                        </MROButton>
                        <MROButton
                            variant={state === "Previous Order" ? "contained" : "outlined"}
                            onClick={() => setState("Previous Order")}
                            style={{
                                width: "125px",
                                color: state === "Previous Order" ? "white" : undefined,
                            }}
                        >
                            Previous Order
                        </MROButton>
                    </ButtonGroup>
                </Box>

                <Box width="100%">
                    {state === "Templates" ? (
                        <CopyTemplates handleClose={handleClose}
                            closeTabAction={closeTabAction}
                            currentSelection={currentSelection}
                            workshop_id={workshop_id}
                            getWorkshopOrderDetails={getWorkshopOrderDetails}
                            getMaintenanceOrder={getMaintenanceOrder}
                            getPlanningList={getPlanningList}
                            setPlanningAction={setPlanningAction}
                            actionTabId={actionTabId}
                            planningId={planningId}
                            planningNullId={planningNullId}
                            planningWholeDetails={planningWholeDetails}
                            copyTab={copyTab}
                        />
                    ) : (
                        <CopyPreviuos handleClose={handleClose}
                            closeTabAction={closeTabAction}
                            currentSelection={currentSelection}
                            workshop_id={workshop_id}
                            getWorkshopOrderDetails={getWorkshopOrderDetails}
                            getMaintenanceOrder={getMaintenanceOrder}
                            getPlanningList={getPlanningList}
                            setPlanningAction={setPlanningAction}
                            actionTabId={actionTabId}
                            planningId={planningId}
                            planningNullId={planningNullId}
                            planningWholeDetails={planningWholeDetails}
                            copyTab={copyTab}
                        />
                    )}
                </Box>
            </div>
        </MROFullScreenRightDrawer>
    )
}
