import React, { useContext } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import MROButton from "../../components/buttons";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import { WorkshopContext } from "views/Operations/Workshop/WorkshopContext/WorkshopContext";



const useStyles = makeStyles(theme => ({
    formWrapper: {
        background: '#fff',
        width: '400px',
        padding: theme.spacing(3)
    },
    widthChange: {
        background: '#fff',
        width: '550px',
        padding: theme.spacing(3)
    },
    centerALign: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: 20,
        marginBottom: 20
    },
    title: {
        textTransform: 'capitalize',
        paddingBottom: theme.spacing(2),
        fontSize: 20,
    },
    actionButtons: {
        paddingTop: '20px',
        paddingBottom: '30px',
        textAlign: 'right',
        '& button': {
            textTransform: 'capitalize',
        }
    },
    paddingBottomLg: {
        paddingBottom: theme.spacing(11),
        marginBottom: theme.spacing(6)
    },
    paddingBottom: {
        paddingBottom: theme.spacing(8)
    },
    modal: {
        '&.MuiDialog-paperWidthSm': {
            maxWidth: '780px',

        }
    },
}));

function ManageDataDialog(props) {
    const classes = useStyles();
    const { paddingBottom, fullWidth, noTitle, handleClose, handleSubmit, handleCloseMenu, children, loading, title, type, maxWidth, files, noAction, centerActionBtns, centerAlign, fontSize, changeWidth, rWidth } = props;
    const { tableRefresh, setTableRefresh } = useContext(WorkshopContext)


    const handleFormSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);

        if (files) {
            if (Array.isArray(files)) {
                for (let i = 0;i < files.length - 1;i++) {
                    formData.append('documents[]', files[i][0])
                }
                return handleSubmit(formData);
            }
            formData.append('documents[]', files)
            return handleSubmit(formData);
        }
        handleSubmit(formData);
        setTimeout(() => {
            setTableRefresh(true)
        }, 2000)

    }

    const _handleClose = () => {
        handleClose();
        if (handleCloseMenu) handleCloseMenu();
        setTableRefresh(false)
    }
    const _handleSubmit = () => {
        handleSubmit()
    }

    const handleConfirm = () => {
        if (type === 'delete') {

            return _handleSubmit(), setTimeout(() => {
                setTableRefresh(true)
            }, 2000)


        }
        handleCloseMenu ? handleCloseMenu() : handleClose()
        setTableRefresh(false)
    }

    return (
        <Dialog
            maxWidth={maxWidth || "sm"}
            open={true}
            fullWidth={fullWidth}
            onClose={handleClose}
            aria-labelledby="create-form"
            className={rWidth ? classes.modal : ""}
        >
            <DialogContent className={paddingBottom ? classes.paddingBottomLg : ''}>
                {type !== 'delete' ? <div className={changeWidth ? classes.widthChange : rWidth ? "" : classes.formWrapper} style={maxWidth ? { width: 'auto' } : {}}>
                    {!noTitle && <Typography variant="subtitle2" color="primary" className={centerAlign ? classes.centerALign : classes.title} style={{ fontSize: fontSize ?? "" }}>
                        {title === "" ? "" : title}
                    </Typography>}
                    <form spellcheck="false" noValidate onSubmit={handleFormSubmit}>
                        {children}
                        {!noAction && <div className={classes.actionButtons} style={{ display: centerActionBtns ? 'flex' : undefined, justifyContent: centerActionBtns ? 'center' : undefined }}>
                            <MROButton disabled={loading} type="button" variant={"contained"}
                                onClick={() => _handleClose()} style={{ marginRight: '20px' }}>Cancel</MROButton>
                            {
                                type !== 'view' &&
                                <MROButton loading={loading} disabled={loading} type="submit" variant={"contained"} color="primary">{type}</MROButton>
                            }
                        </div>}
                    </form>
                </div> :
                    <div className={rWidth ? "" : classes.formWrapper}>
                        {children}
                        {!noAction && <div className={classes.actionButtons} style={{ display: centerActionBtns ? 'flex' : undefined, justifyContent: centerActionBtns ? 'center' : undefined }}>
                            <MROButton disabled={loading} type="button" variant={"contained"} onClick={_handleClose} style={{ marginRight: '20px' }}>Cancel</MROButton>
                            <MROButton disabled={loading} type="button" variant={"contained"} color="primary" onClick={() => {
                                handleConfirm()
                            }}>Yes, Confirm</MROButton>
                        </div>}
                    </div>
                }
            </DialogContent>
        </Dialog >
    )
}

export default ManageDataDialog;
