import React, { useContext, useEffect, useReducer, useRef, useState } from "react";
import ManageDataDialog from "components/form_components/manage_data_dialog";
import Grid from "@material-ui/core/Grid";
import MROTextField from "components/form_components/TextField";
import Typography from "@material-ui/core/Typography";
import BoxpDataServiceAction from "actions/boxp_data_actions";
import dataReducer, { INITIAL_DATA_STATE } from "reducers/data_reducer";
import { ToastMessageContext } from "lib/contexts/message_context";
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
    getDetails
} from "use-places-autocomplete";
import { Autocomplete } from "@material-ui/lab";
import { Dialog, DialogContent, DialogTitle, Box, makeStyles } from "@material-ui/core";
import MROButton from "components/buttons";
import { MaintenanceServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation";
import { Button, InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import mapIcon from 'assets/map.png'
import { ICON_COLOUR } from "lib/constants/style_constants";
import Map from "views/Admin/Workshop/Customers/Addresses/Map";



const useStyles = makeStyles((theme) => ({

    modal: {
        "& .MuiDialog-paperWidthMd": {
            maxWidth: "600px",
        },
    },

}));



function ManageAddress(props) {
    const message = useContext(ToastMessageContext);
    const classes = useStyles();
    const { action, handleClose, currentSelection, workshopDetails, setSelectedAddress, getCustomerAddress } = props;
    const [loading, setLoading] = useState(false);
    const [state, rDispatch] = useReducer(dataReducer, INITIAL_DATA_STATE);
    const [error, setError] = useState({});
    const [location, setLocation] = useState({ latitude: "", longitude: "", address: "" })
    const ref = useRef()
    const [fullAddress, setFullAddress] = useState([])
    const [map, setMap] = useState(null)
    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,

    } = usePlacesAutocomplete({ callbackName: "maploc" });

    //Autofill format
    const [completeAddress, setCompleteAddress] = useState([])
    const [title, setTitle] = useState(null)
    const [addressLine1, setAddressLine1] = useState(null)
    const [addressLine2, setAddressLine2] = useState(null)
    const [addressLine3, setAddressLine3] = useState(null)
    const [city, setCity] = useState(null)
    const [postCode, setPostCode] = useState(null)
    const [country, setCountry] = useState(null)

    // University College Hospital, Euston Road, London, UK


    const handleSubmit = async (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        setLoading(true);

        let valid = true;
        let errors = {
            geo_address: false,
            name: false,
            title: false,
            address_line_1: false,
            city: false,
            postcode: false,
            country: false,
        };
        if (value === "" || value === undefined) {
            errors.geo_address = true;
            valid = false;
        }

        if (formData && !formData.get("title").length) {
            errors.title = true;
            valid = false;
        }
        if (formData && !formData.get("address_line_1").length) {
            errors.address_line_1 = true;
            valid = false;
        }
        if (formData && !formData.get("city").length) {
            errors.city = true;
            valid = false;
        }
        if (formData && !formData.get("postcode").length) {
            errors.postcode = true;
            valid = false;
        }
        if (formData && !formData.get("country").length) {
            errors.country = true;
            valid = false;
        }
        if (!valid) {
            setLoading(false);
            setError(errors);
            return;
        }
        formData.delete("geo_address")
        formData.append("line_customer_id", workshopDetails.line_customer_id);
        formData.append("latitude", location.latitude);
        formData.append("longitude", location.longitude);
        formData.append("address", location.address);
        await MaintenanceServices.updateCustomerAddress(formData).then((res) => {
            if (res.success)
                setSelectedAddress(res.id)
            getCustomerAddress()
            handleClose()
        }).catch(() => {
            message.showToastMessage({
                message: "Something went wrong. Try Again",
                variant: "error",
            });
        })




    };



    const handleGetLocationDetails = (value1) => {


        if (value1 !== undefined && value !== null && value1 !== null) {

            let description = value1.description
            getGeocode({ address: description }).then((results) => {

                const parameter = {
                    placeId: results[0].place_id,
                }
                getDetails(parameter)
                    .then((details) => {
                        // console.log("Error: ", details);
                        setFullAddress([details])
                        setCompleteAddress(details.address_components)
                    })
                    .catch((error) => {
                        console.log("Error: ", error);
                    });


                const { lat, lng } = getLatLng(results[0]);
                // console.log("📍 Coordinates: ", { lat, lng });
                setLocation({ latitude: lat, longitude: lng, address: description })
            });
        }
        else {
            setLocation({ latitude: "", longitude: "", address: "" })
        }
    }
    //Auto fill address

    useEffect(() => {
        setTitle("")
        setCity("")
        setAddressLine1("")
        setAddressLine2("")
        setAddressLine3("")
        setPostCode("")
        setCountry("")
        if (completeAddress.length > 0) {
            // console.log("complete=====", fullAddress)
            //title

            let titleValue = ""


            if (fullAddress.length > 0) {
                let titleV1 = fullAddress.filter((item) => item.types.filter((val) => {
                    if (val === "point_of_interest" || val === "establishment" || val === "park") {
                        setTitle(item.name)

                    }

                }))
            }
            else if (title === undefined || titleValue === "" || titleValue === null) {


                let titleV1 = completeAddress.filter((item) => item.types.filter((val) => {
                    if (val === "point_of_interest" || val === "establishment" || val === "park" || value === 'airport') {
                        setTitle(item.long_name)

                    }

                }))
            }


            //address line 1

            // premise, subpremise, street_number, route

            let addLine = {
                premise: null,
                subpremise: null,
                street_number: null,
                route: null

            }
            let add1Val = completeAddress.filter((item) => item.types.filter((val) => {
                if (val === "premise") {
                    addLine.premise = item.long_name
                }
                if (val === "subpremise") {
                    addLine.subpremise = item.long_name
                } if (val === "street_number") {
                    addLine.street_number = item.long_name
                } if (val === "route") {
                    addLine.route = item.long_name
                }

            }))
            let addLine1 = Object.values(addLine).filter(Boolean).join(", ")

            setAddressLine1(addLine1 ? addLine1 : "")


            //city
            // postal_town (use this as preference if both exists)
            // locality (else this if exists)
            let fcity = ""
            let cityVal = completeAddress.filter((item) => item.types.filter((val) => {
                if (val === "postal_town") {
                    fcity = item.long_name
                }
                else if (val === "locality" && val !== "postal_town") {
                    fcity = item.long_name
                }
            }))

            setCity(fcity)


            //Post code
            //postal_code
            let p_code = ""
            let postVal = completeAddress.filter((item) => item.types.filter((val) => {
                if (val === "postal_code") {
                    p_code = item.long_name
                }

            }))
            setPostCode(p_code)

            //country

            let coun_val = ""
            let countryVal = completeAddress.filter((item) => item.types.filter((val) => {
                if (val === "country") {
                    coun_val = item.long_name
                }

            }))
            setCountry(coun_val)

            //AddressLine2 and 3
            // sublocality_level_1
            // sublocality_level_2
            // sublocality_level_3
            // sublocality_level_4
            // sublocality_level_5

            let levels = {
                sublocality_level_1: null,
                sublocality_level_2: null,
                sublocality_level_3: null,
                sublocality_level_4: null,
                sublocality_level_5: null

            }

            let addressFilter = completeAddress.filter((item) => item.types.filter((val) => {
                if (val === "sublocality_level_1") {
                    levels.sublocality_level_1 = item.long_name
                }
                if (val === "sublocality_level_2") {
                    levels.sublocality_level_2 = item.long_name
                }
                if (val === "sublocality_level_3") {
                    levels.sublocality_level_3 = item.long_name
                }
                if (val === "sublocality_level_4") {
                    levels.sublocality_level_1 = item.long_name
                }
                if (val === "sublocality_level_5") {
                    levels.sublocality_level_1 = item.long_name
                }

            }))

            if (levels.sublocality_level_5 !== null) {
                setAddressLine2(levels.sublocality_level_5)

                let levels5 = {
                    level_1: levels.sublocality_level_1,
                    level_2: levels.sublocality_level_2,
                    level_3: levels.sublocality_level_3,
                    level_4: levels.sublocality_level_4,

                }
                let addLine3 = Object.values(levels5).filter(Boolean).join(", ")
                setAddressLine3(addLine3)

            }
            else if (levels.sublocality_level_5 === null && levels.sublocality_level_4 !== null) {
                setAddressLine2(levels.sublocality_level_4)

                let levels5 = {
                    level_1: levels.sublocality_level_1,
                    level_2: levels.sublocality_level_2,
                    level_3: levels.sublocality_level_3,

                }
                let addLine3 = Object.values(levels5).filter(Boolean).join(", ")
                setAddressLine3(addLine3)
            }
            else if (levels.sublocality_level_5 === null && levels.sublocality_level_4 === null && levels.sublocality_level_3 !== null) {
                setAddressLine2(levels.sublocality_level_3)

                let levels5 = {
                    level_1: levels.sublocality_level_1,
                    level_2: levels.sublocality_level_2,

                }
                let addLine3 = Object.values(levels5).filter(Boolean).join(", ")
                setAddressLine3(addLine3)
            }
            else if (levels.sublocality_level_5 === null && levels.sublocality_level_4 === null && levels.sublocality_level_3 === null && levels.sublocality_level_2 !== null) {
                setAddressLine2(levels.sublocality_level_2)
                let addLine3 = levels.sublocality_level_1 ? levels.sublocality_level_1 : ""
                setAddressLine3(addLine3)
            }
            else if (levels.sublocality_level_5 === null && levels.sublocality_level_4 === null && levels.sublocality_level_3 === null && levels.sublocality_level_2 === null && levels.sublocality_level_1 !== null) {
                setAddressLine2(levels.sublocality_level_1)
                setAddressLine3("")
            }
            else {
                setAddressLine2("")
                setAddressLine3("")
            }


        }

    }, [completeAddress])


    return (
        <Dialog
            maxWidth={"md"}
            fullWidth={true}
            open={true}
            className={classes.modal}
            onClose={handleClose}
            aria-labelledby="delete-event"
        >
            <DialogTitle

            // className={classes.title}
            >
                <Typography variant="subtitle2"
                    color="primary" style={{ textAlign: 'center', fontSize: '16px' }}>Customer Address</Typography>
            </DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit} >
                    <Grid
                        container
                        spacing={1}
                        alignItems={"center"}
                        justify={"flex-start"}
                    >
                        <Grid item xs={12} ref={ref} >
                            <Autocomplete
                                style={{ width: "" }}
                                getOptionSelected={(option, value) =>
                                    option.id === value.id
                                }
                                value={value ? value : null}
                                onChange={(event, value) => {
                                    setTitle("")
                                    setCity("")
                                    setAddressLine1("")
                                    setAddressLine2("")
                                    setAddressLine3("")
                                    setPostCode("")
                                    setCountry("")
                                    handleGetLocationDetails(value)
                                    setError((error) => ({ ...error, geo_address: false }));
                                }}
                                id="tags-locations"
                                options={data}
                                loading={status}

                                fullWidth
                                getOptionLabel={(option) => typeof option === "string" ? option : option.description}
                                onInputChange={(e, newValue) => {


                                    setValue(newValue)

                                }}
                                renderInput={(params) => (
                                    <MROTextField
                                        {...params}
                                        name={"geo_address"}
                                        error={error.geo_address}
                                        label={"Geographical Location *"}
                                        style={{ backgroundColor: "#fff" }}
                                        variant="outlined"
                                        color={"primary"}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{
                                            ...params.InputProps,
                                            startAdornment: (<>


                                                <InputAdornment position="start"><SearchIcon htmlColor={ICON_COLOUR} /></InputAdornment>
                                                {params.InputProps.startAdornment}
                                            </>

                                            ),
                                            endAdornment: (<> <InputAdornment position="start" style={{ cursor: 'pointer' }}>

                                                <img src={mapIcon} height={'18px'} onClick={() => {
                                                    setMap("showmap")
                                                }} />
                                            </InputAdornment>
                                                {params.InputProps.endAdornment}
                                            </>),
                                        }}
                                    />
                                )}
                            />
                        </Grid>


                        <Grid item xs={12} md={6}>
                            <MROTextField
                                name={"title"}
                                label={"Title"}
                                required
                                value={title}
                                placeholder={"Name or title of address"}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ maxLength: 50 }}
                                autoFocus={error.title}
                                error={error.title}
                                helperText={error.title ? "*please enter a title" : null}
                                onChange={(e) => {
                                    setTitle(e.target.value)
                                    setError({ ...error, title: false });
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <MROTextField
                                name={"city"}
                                label={"City"}
                                required
                                value={city}
                                placeholder={"City"}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ maxLength: 50 }}
                                autoFocus={error.city}
                                error={error.city}
                                helperText={error.city ? "*please enter a city" : null}
                                onChange={(e) => {
                                    setCity(e.target.value)
                                    setError({ ...error, city: false });
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <MROTextField
                                name={"address_line_1"}
                                label={"Address Line 1"}
                                required
                                value={addressLine1}
                                placeholder={"Line 1"}
                                inputProps={{ maxLength: 50 }}
                                InputLabelProps={{ shrink: true }}
                                autoFocus={error.address_line_1}
                                error={error.address_line_1}
                                helperText={
                                    error.address_line_1 ? "*please enter a address_line_1" : null
                                }
                                onChange={(e) => {
                                    setAddressLine1(e.target.value)
                                    setError({ ...error, address_line_1: false });
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <MROTextField
                                name={"postcode"}
                                label={"Post Code"}
                                required
                                placeholder={"Post Code"}
                                //   type="number"
                                inputProps={{
                                    maxLength: 50,
                                }}
                                value={postCode}
                                InputLabelProps={{ shrink: true }}
                                autoFocus={error.postcode}
                                error={error.postcode}
                                helperText={error.postcode ? "*please enter a postcode" : null}
                                onChange={(e) => {
                                    setPostCode(e.target.value)
                                    setError({ ...error, postcode: false });
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <MROTextField
                                name={"address_line_2"}
                                label={"Address Line 2"}
                                inputProps={{ maxLength: 50 }}
                                placeholder={"Line 2"}
                                InputLabelProps={{ shrink: true }}
                                value={addressLine2}
                                onChange={(e) => {
                                    setAddressLine2(e.target.value)

                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <MROTextField
                                name={"country"}
                                label={"Country"}
                                required
                                placeholder={"Country"}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ maxLength: 50 }}
                                autoFocus={error.country}
                                error={error.country}
                                value={country}
                                helperText={error.country ? "*please enter a country" : null}
                                onChange={(e) => {
                                    setCountry(e.target.value)
                                    setError({ ...error, country: false });
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <MROTextField
                                name={"address_line_3"}
                                label={"Address Line 3"}
                                inputProps={{ maxLength: 50 }}
                                autoFocus
                                placeholder={"Line 3"}
                                InputLabelProps={{ shrink: true }}
                                value={addressLine3}
                                onChange={(e) => {
                                    setAddressLine3(e.target.value)

                                }}
                            />
                        </Grid>





                    </Grid>






                    <Box
                        display="flex"
                        width="100%"
                        justifyContent="center"
                        my="25px"
                        align="center"
                    >
                        <MROButton
                            type="button"
                            variant={"contained"}
                            onClick={handleClose}
                            style={{ marginRight: 20 }}
                        >
                            Cancel
                        </MROButton>
                        <MROButton
                            color="primary"
                            // onClick={handleSubmit}
                            variant={"contained"}
                            loading={loading}
                            type={"submit"}
                        >
                            Complete
                        </MROButton>
                    </Box>
                </form>
            </DialogContent>
            {map && map === "showmap" && <Map handleClose={() => setMap(null)} setLocation={setLocation} location={location} setDialogValue={setValue} dialogValue={value} setCompleteAddress={setCompleteAddress} setFullAddress={setFullAddress} />}
        </Dialog>
    );
}

export default ManageAddress;
