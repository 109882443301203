import React, { useState, useContext, useEffect, useReducer } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Dialog, makeStyles } from "@material-ui/core";
import { createStyles, } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import MaterialTable from "material-table";

import tableIcons, {
    options,
} from "components/universal/table_attributes";

import { formatDateWithHrMin, getOrganisationId } from "lib/utils/common_utils";

import TableAction from "actions/table_actions";
import tableReducer, { INITIAL_TABLE_STATE } from "reducers/table_reducer";
import { StationDashboardServices } from "lib/services/api/dashboard/station_compliance";
import { ToastMessageContext } from "lib/contexts/message_context";


export default function LiveRecords(props) {
    const { handleClose, selectedBase } = props;
    const classes = useStyles();
    const message = useContext(ToastMessageContext)
    const [loading, setLoading] = useState(false)
    const [totalCount, setTotalCount] = useState(5);
    const [pageSize, setPageSize] = useState(5);
    const [ataData, setAtaData] = useState([]);
    const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
    const { page } = tableState;




    // tableOptions
    const tableOptions = {
        ...options,
        page: page,
        total: totalCount,
        pageSize: pageSize,
        search: false,
        toolbar: false,
        headerStyle: {
            textAlign: "center",
            paddingTop: "22px",
            paddingBottom: "16px",
        },
    };


    const getRecentData = async () => {
        setLoading(true)
        const params = {
            organisation_id: getOrganisationId(),
            page: page + 1,
            limit: pageSize

        }
        selectedBase.forEach((item, index) => params[`base_station_id[${[index]}]`] = item)
        await StationDashboardServices.getTemperatureAndHumidityRecentData(params).then((response) => {
            setAtaData(response.data)
            setTotalCount(response.total_count)
        }).catch((err) => {
            message.showToastMessage({
                message: "Something went wrong. Try again!",
                variant: "error",
            });
        }).finally(() => {
            setLoading(false)
        })
    }
    useEffect(() => {
        getRecentData()
    }, [page, pageSize])




    const columns = [
        {
            title: "Base",
            field: "base_station",
            headerStyle: {
                textAlign: 'left', paddingLeft: '15px'
            },
            cellStyle: {
                textAlign: 'left', paddingLeft: '15px'
            }
        },
        {
            title: "Sensor ID",
            field: "serial",
            width: '20%',
            headerStyle: {
                textAlign: 'left',
                width: '20%',
            },
            cellStyle: {
                textAlign: 'left',
                width: '20%',
            }
        },
        {
            title: "Date / Time",
            field: "date_time",
            render: (rowData) => rowData.date_time ? formatDateWithHrMin(rowData.date_time) : '-',
            headerStyle: {
                textAlign: 'left'
            },
            cellStyle: {
                textAlign: 'left'
            }
        },
        {
            title: "Temperature",
            field: "temperature",
        },
        {
            title: "Humidity",
            field: "humidity",
        },
        {
            title: "Battery Level",
            field: "battery_level",
        },
    ];

    return (
        <div style={{ position: "relative" }}>
            <Dialog
                open={true}
                onClose={handleClose}
                aria-labelledby="History"
                aria-describedby="History"
                className={classes.modal}
            >
                <Box
                    width="100%"
                    mb="30px"
                    ml="0px"
                >
                    <Typography color="primary" className={classes.bodyText}>
                        Most Recent Data
                    </Typography>
                    <br />
                    <Typography style={{ textAlign: 'center' }}>
                        The table below shows the most recent transmission from each sensor
                    </Typography>
                </Box>

                <Box width="100%" className={classes.container}>
                    <MaterialTable
                        style={{ boxShadow: "0px 1px 3px #00000033" }}
                        icons={tableIcons}
                        columns={columns}
                        data={ataData || []}
                        options={tableOptions}
                        localization={{
                            toolbar: {
                                searchPlaceholder: "Search anything",
                            },
                            body: {
                                emptyDataSourceMessage: "No data Found",
                                filterRow: {
                                    filterTooltip: "Filter",
                                },
                            },
                        }}
                        isLoading={loading}
                        page={page}
                        onChangePage={(page) => {
                            TableAction.setPage(dispatch, page);
                        }}
                        onChangeRowsPerPage={(pageSize) => {
                            setPageSize(pageSize);
                        }}
                        totalCount={totalCount}
                    />
                </Box>
            </Dialog>
        </div>
    );
}


const useStyles = makeStyles((theme) =>
    createStyles({
        modal: {
            "& .MuiDialog-paperWidthSm": {
                minWidth: "750px",
                // height: "110vh"
            },
        },
        container: {
            // minWidth: "1200px",
            // minHeight: "400px",
            padding: "23px",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden !important"
        },
        bodyText: {
            fontSize: 20,
            lineHeight: "28px",
            textAlign: 'center',
            marginTop: '25px'
        },

    })
);
