import React, { useEffect, useContext, useState } from 'react'
import { Dialog, DialogContent, DialogTitle, Box, ButtonGroup, Button, FormControlLabel, Grid, Typography, FormControl, FormLabel, Divider } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import MROButton from 'components/buttons';
import MROTextField from 'components/form_components/TextField';
import { getOrganisationId } from 'lib/utils/common_utils';
import { ToastMessageContext } from 'lib/contexts/message_context';
import PositiveSwitch from 'components/form_components/switch';
import DateTimeField from 'components/form_components/LMEventsDateTimeField';
import QuantityCounter from 'components/quantity_counter';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import UploadButton from 'components/form_components/capability_file_upload'
import CapabilityServices from 'lib/services/api/compliance/capability_client';
import { validateFormData } from "lib/utils/helperFunctions";
import DateField from 'components/form_components/DateField';
import { BASE_API_URL_BOXR } from 'lib/constants';

const useStyles = makeStyles(theme => ({
    actionButtons: {
        paddingTop: '20px',
        paddingBottom: '33px',
        textAlign: 'right',
        '& button:not(:last-child)': {
            marginRight: '20px'
        }
    },
    modal: {
        '& .MuiDialog-paperWidthMd': {
            maxWidth: '750px',
        }
    },
    title: {
        fontSize: '18px',
        '& h2': {
            color: theme.palette.primary.main,

        }
    },
    formLabel: {
        // '&.MuiDivider-vertical': {
        //     width: '3px'
        // },
        '&.MuiDivider-root': {
            backgroundColor: '#707070'
        }
    }

}));
export default function CreateTechData(props) {
    const classes = useStyles();
    const { techaction, handleClose, getTechListing, capabiltity, currentSelection, refData, setAcceptAll } = props;
    const [error, setError] = useState({});
    const [uploadError, setUploadError] = useState('');
    const [value, setValue] = useState('file');
    const [password, setPassword] = useState("no")
    const [checked, setChecked] = useState(false)
    const user = JSON.parse(sessionStorage.getItem("user"));
    const [uploadFile, setUploadFiles] = useState()
    const [loading, setLoading] = useState(false)
    const message = useContext
        (ToastMessageContext)

    const handleChange = (event) => {
        setValue(event.target.value);
    };
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };


    useEffect(() => {
        if (currentSelection && currentSelection !== undefined) {
            setValue(currentSelection?.type)
            setChecked(currentSelection?.revision_controlled)
            if (currentSelection?.username && currentSelection?.password) {
                setPassword("yes")
            }
        }
    }, [currentSelection])


    const handleCreate = async (formData) => {
        setLoading(true)

        let uploadfile = formData.get('file');
        if (value === "file" && !uploadfile?.name || uploadfile?.size === 0) {
            setLoading(false);
            return setUploadError('Please upload document')
        }
        await CapabilityServices.createTechData(formData).then((res) => {
            if (res.success) {
                handleClose();
                getTechListing()
                message.showToastMessage({ message: "New Tech Data added successfully", variant: "success" });
            }
            else {
                message.showToastMessage({ message: res.errors ? res.errors.join(',') : "Something went wrong. Try Again", variant: "error" });
                setLoading(false);
            }
        }).catch(() => {
            message.showToastMessage({ message: "Something went wrong. Try Again", variant: "error" });
            setLoading(false);
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleUpdate = async (formData) => {
        setLoading(true)
        let uploadfile = formData.get('file');
        if (value === "file" && !uploadfile?.name || uploadfile?.size === 0) {
            if (currentSelection?.file === null) {
                console.log("file===", currentSelection?.file)
                setLoading(false);
                return setUploadError('Please upload document')
            }
        }
        await CapabilityServices.updateTechData(formData).then((res) => {
            if (res.success) {
                handleClose();
                getTechListing()
                message.showToastMessage({ message: "Updated successfully", variant: "success" });
            }
            else {
                message.showToastMessage({ message: res.errors ? res.errors.join(',') : "Something went wrong. Try Again", variant: "error" });
                setLoading(false);
            }
        }).catch(() => {
            message.showToastMessage({ message: "Something went wrong. Try Again", variant: "error" });
            setLoading(false);
        }).finally(() => {
            setLoading(false)
        })
    }

    // console.log("fileUpload-----", uploadFile)

    const handleTechSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target)


        let valid = true
        let errors = {
            name: false,
            url: false,
            username: false,
            password: false,
            revision: false,
            revision_date: false,

        }


        if (formData && !formData.get('name').length) {
            errors.name = true;
            valid = false;
        }



        if (value === "link" && formData && (formData.get('url') !== null) && !formData.get('url').length) {
            errors.url = true;
            valid = false;
        }

        if (password) {
            if (formData && (formData.get('username') !== null) && !formData.get('username').length) {

                errors.username = true;
                valid = false;

            }
            if (formData && (formData.get('password') !== null) && !formData.get('password').length) {

                errors.password = true;
                valid = false;

            }
        }

        if (checked) {
            if (formData && (formData.get('revision') !== null) && !formData.get('revision').length) {
                errors.revision = true;
                valid = false;
            }
            if (formData && (formData.get('revision_date') !== null) && !formData.get('revision_date').length) {

                errors.revision_date = true;
                valid = false;

            }
        }
        if (!valid) {
            setError(errors);
            return;
        }



        formData.delete("shared_password")
        formData.append("capability_id", capabiltity?.id ? capabiltity?.id : refData)
        formData.append("organisation_id", getOrganisationId())

        formData.append("user_id", user.id);
        formData.append("revision_controlled", checked);
        if (techaction === "createtechdata") {
            handleCreate(formData)
        }
        else if (techaction === "update_tech") {
            formData.append("id", currentSelection?.id)
            handleUpdate(formData)
        }


    }






    return (
        <div>
            <Dialog
                maxWidth={"md"}
                fullWidth={true}
                open={true}
                className={classes.modal}
                onClose={handleClose}
                aria-labelledby="tech-data"
            >
                <DialogTitle variant="subtitle2" color="secondary"
                    className={classes.title}><Typography style={{ fontSize: '20px' }}>{techaction === "createtechdata" ? "Add " : "Update "} Technical Data</Typography>
                </DialogTitle>

                <DialogContent>
                    <form onSubmit={handleTechSubmit}>
                        <Grid container>
                            <Grid item xs={5}>
                                <Typography variant={"subtitle2"}>Item Details</Typography>
                                <Grid style={{ paddingBottom: '10px' }}>
                                    <MROTextField
                                        name={"name"}
                                        label={"Title *"}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{ style: { height: 'max-content', width: "100%" } }}
                                        inputProps={{ maxLength: 100 }}
                                        multiline
                                        //  required
                                        placeholder={"Enter a title for the item"}
                                        error={error.name}
                                        helperText={error.name ? "*please enter title" : null}
                                        onChange={(e) => { setError({ ...error, name: false }); }}
                                        className={classes.inputField}
                                        defaultValue={currentSelection ? currentSelection.name : ''}
                                    />
                                </Grid>
                                <Grid container spacing={1} alignItems={"center"} justify={"flex-start"}>
                                    <FormControl component="fieldset" disabled={currentSelection?.type ===
                                        "link" || currentSelection?.type === "file"}>
                                        <Typography variant={"subtitle2"}>Choose the item type you want to add</Typography>
                                        <RadioGroup row aria-label="file" name="type" value={value} onChange={handleChange}>
                                            <FormControlLabel value="file" control={<Radio />} label="File" />
                                            <FormControlLabel value="link" control={<Radio />} label="URL Link" />

                                        </RadioGroup>
                                    </FormControl>

                                </Grid>

                                {(value === "file") && <Grid item xs={12} style={{ paddingTop: '20px' }}>
                                    <UploadButton
                                        required={true}
                                        error={uploadError}
                                        name={"file"}
                                        action={"Upload Document"}
                                        setUploadFiles={setUploadFiles}
                                        defaultValue={currentSelection?.file ? [{ name: (currentSelection && currentSelection?.file) ? currentSelection.file.filename : null, url: (currentSelection && currentSelection?.file) ? BASE_API_URL_BOXR + currentSelection.file.url : null }] : ''} />
                                </Grid>}

                                {(value === "link") &&
                                    <Grid>
                                        <Grid item xs={12} style={{ paddingTop: '20px' }}>
                                            <MROTextField
                                                name={"url"}
                                                className={classes.websiteInput}
                                                label={"Link*"}
                                                pattern
                                                InputLabelProps={{ shrink: true }}
                                                onChange={(e) => {
                                                    setError({ ...error, url: false });
                                                }}
                                                placeholder="Enter url link"
                                                error={error.url}
                                                helperText={error.url ? "*please enter a link" : null}
                                                defaultValue={currentSelection ? currentSelection.url : ""}
                                            />
                                        </Grid>
                                        <Grid item xs={12} style={{ paddingTop: '20px' }}>
                                            <FormControl component="fieldset">
                                                <Typography variant={"subtitle2"}>Do you want to add a shared password / key?</Typography>
                                                <RadioGroup row={true} aria-label="item" value={password} name="shared_password" onChange={handlePasswordChange}>
                                                    <FormControlLabel value="no" control={<Radio />} label="No" />
                                                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />

                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                        {(password === "yes") && <Grid>
                                            <Grid item xs={12}>
                                                <MROTextField
                                                    name={"username"}
                                                    label={"User / Account *"}
                                                    InputLabelProps={{ shrink: true }}
                                                    autoFocus={error.username}
                                                    error={error.username}
                                                    helperText={error.username ? "*please enter a username" : null}
                                                    onChange={(e) => { setError({ ...error, username: false }); }}
                                                    defaultValue={currentSelection ? currentSelection.username : ""}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <MROTextField
                                                    name={"password"}
                                                    label={"Password*"}
                                                    InputLabelProps={{ shrink: true }}
                                                    autoFocus={error.password}
                                                    error={error.password}
                                                    helperText={error.password ? "*please enter a password" : null}
                                                    onChange={(e) => { setError({ ...error, password: false }); }}
                                                    defaultValue={currentSelection ? currentSelection.password : ""}

                                                />
                                            </Grid>
                                        </Grid>}
                                    </Grid>


                                }
                            </Grid>
                            <Grid xs={1} style={{ paddingLeft: '27px' }}>
                                <Divider orientation={"vertical"} classes={{ root: classes.formLabel }} />

                            </Grid>
                            <Grid item xs={6}>

                                <Typography variant={"subtitle2"}>Revision Status</Typography>
                                <Grid container >
                                    <Grid item xs={9}>
                                        <Typography style={{ marginTop: '5px', marginLeft: '2px' }}>Revision controlled item?</Typography></Grid>
                                    <Grid>   <FormControlLabel

                                        control={
                                            <PositiveSwitch
                                                checked={checked}
                                                onChange={(e) => setChecked(e.target.checked)}
                                                name="revision_controlled"
                                                disabled={currentSelection?.revision_controlled === true || currentSelection?.revision_controlled === false}
                                            />
                                        }
                                        label=""
                                        labelPlacement=""
                                        name=""
                                    /></Grid>
                                </Grid>



                                {checked && <Grid>
                                    <Grid style={{ paddingBottom: '10px' }}>
                                        <MROTextField
                                            name={"revision"}
                                            label={"Revision # *"}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{ style: { height: 'max-content', width: "100%" } }}
                                            inputProps={{ maxLength: 100 }}
                                            multiline
                                            //  required
                                            placeholder={"Enter a title for the item"}
                                            error={error.revision}
                                            helperText={error.revision ? "*please enter title" : null}
                                            onChange={(e) => { setError({ ...error, revision: false }); }}
                                            className={classes.inputField}
                                            defaultValue={currentSelection ? currentSelection.revision
                                                : ""}
                                        />
                                    </Grid>
                                    <Grid style={{ paddingBottom: '10px' }}>
                                        <DateField
                                            name="revision_date"
                                            label={"Revision Date *"}
                                            // InputLabelProps={{ shrink: true }}
                                            error={error.revision_date}
                                            helperText={error.revision_date ? "*please enter revision date" : null}
                                            onChange={(e) => { setError({ ...error, revision_date: false }); }}
                                            defaultValue={currentSelection ? currentSelection.revision_date
                                                : ""}

                                        />
                                    </Grid>
                                </Grid>}


                            </Grid>






                        </Grid>


                        <div className={classes.actionButtons}>
                            <MROButton
                                //   disabled={loading}
                                type="button"
                                variant={"contained"}
                                onClick={handleClose}
                            >
                                Cancel
                            </MROButton>
                            <MROButton
                                //   disabled={loading}
                                loading={loading}
                                type="submit"
                                variant={"contained"}
                                color="primary"
                                onClick={(e) => {
                                    // setAcceptAll(true)
                                }}
                            >
                                {techaction === "createtechdata" ? "Complete" : "Update"}
                            </MROButton>
                        </div>

                    </form>
                </DialogContent>

            </Dialog>


        </div>
    )
}
