import React, { useReducer, useContext, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import MaterialTable from "material-table";
import tableReducer, { INITIAL_TABLE_STATE } from "reducers/table_reducer";
import tableIcons, {
    options,
} from "components/universal/table_attributes";
import TableAction from "actions/table_actions";
import HistoryDialog from "./HistoryDialog";
import { InspectionServices } from "lib/services/api/operaitons/WorkShop/Inspection/InspectionService";
import { ToastMessageContext } from "lib/contexts/message_context";
import { format24HrDateTime, getUserStamp } from "lib/utils/common_utils";


export default function ManHousrHistory(props) {
    const { open, handleClose, busy, selectedId } = props;

    const classes = useStyles();
    const message = useContext(ToastMessageContext)
    const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);

    const [historyData, setHistoryData] = useState([])
    const [pageSize, setPageSize] = useState(5);
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(5);
    // tableOptions
    const tableOptions = {
        ...options,
        page: page,
        total: totalCount,
        pageSize: pageSize,
        search: false,
        toolbar: false,
        paging: true,
        headerStyle: {
            textAlign: "center",
            paddingTop: "22px",
            paddingBottom: "16px",
        },
    };

    const columns = [
        {
            title: "User",
            field: "user",
            headerStyle: {
                textAlign: 'left', paddingLeft: '10px'
            },
            cellStyle: {
                textAlign: 'left',
                paddingLeft: '10px'
            }
        },
        {
            title: "Date / Time",
            field: "date_time",
            headerStyle: {
                textAlign: 'left',
                width: '50%'
            },
            cellStyle: {
                textAlign: 'left',
                width: '50%'
            }
        },
        {
            title: "Hours",
            field: "hours",
            headerStyle: {
                textAlign: 'center',

            },
            cellStyle: {
                textAlign: 'center',
            }
        },
    ];

    useEffect(() => {
        const getManHoursData = async () => {
            const params = {
                workshop_action_id: selectedId,
                page: page + 1,
                limit: pageSize

            }
            await InspectionServices.getManHoursData(params)
                .then((res) => {
                    if (res) {
                        setHistoryData(res.data);
                        setTotalCount(res.total_count);
                    }
                })
                .catch(() => {
                    message.showToastMessage({
                        message: "Something went wrong. Try again!",
                        variant: "error",
                    });
                });
        };
        getManHoursData()
    }, [selectedId, page, pageSize, totalCount])



    return (
        <div style={{ position: "relative" }}>
            <HistoryDialog
                open={open}
                handleClose={handleClose}
                width="550px"
                notShowButton={true}
            >
                <Box
                    width="100%"
                    mb="30px"
                    ml="0px"
                >
                    <Typography color="primary" className={classes.bodyText}>
                        History
                    </Typography>
                </Box>

                <Box width="100%">
                    <MaterialTable
                        style={{ boxShadow: "0px 1px 3px #00000033" }}
                        icons={tableIcons}
                        columns={columns}
                        data={historyData || []}
                        options={tableOptions}
                        localization={{
                            toolbar: {
                                searchPlaceholder: "Search anything",
                            },
                            body: {
                                emptyDataSourceMessage: "No Records Found",
                                filterRow: {
                                    filterTooltip: "Filter",
                                },
                            },
                        }}
                        isLoading={busy === "loading"}
                        onChangePage={(page) => {
                            setPage(page);
                        }}
                        onChangeRowsPerPage={(pageSize) => {
                            setPageSize(pageSize);
                        }}

                        totalCount={totalCount}
                        page={page}
                    />
                </Box>
            </HistoryDialog>
        </div>
    );
}

const useStyles = makeStyles(() => ({
    bodyText: {
        fontSize: 20,
        lineHeight: "28px",
    },
}));
