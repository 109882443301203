import requestApi from "../common/boxp_api_client";
import requestrApi from "../common/boxr_api_client";

function getActivityService(params) {
    return requestrApi({
        url: `/accounts/activity_report`,
        method: 'GET',
        params: params
    });
}
function fetchDependencies() {
    return requestrApi({
        url: '/accounts/fetch_filter_dependencies',
        method: 'GET',
    });
}

function fetchAircraftRegistrations(lineCustomerId, baseStationId, startDate, endDate) {
    return requestrApi({
        url: 'consolidate_reports/fetch_aircraft_registrations?line_customer_id=' + lineCustomerId + '&base_station_id=' + baseStationId + '&start_date=' + startDate + '&end_date=' + endDate,
        method: 'GET',
    });
}

function getDelayInbounds(params) {
    return requestrApi({
        url: '/work_orders/inbound_work_orders',
        method: 'GET',
        params
    });
}
function getDelayExceedsGnd(params) {
    return requestrApi({
        url: '/work_orders/exceeds_gnd_time',
        method: 'GET',
        params
    });
}

function getAccountsActivityData(params) {

    return requestApi({
        url: '/accounts_line_activity',
        method: 'GET',
        params

    })
}

function updateAccountInvoices(params) {

    return requestApi({
        url: 'edit_remove_invoice_number?_method=PUT',
        method: 'POST',
        params

    })
}
function activityDownloadCSV(params) {
    return requestApi({
        url: '/accounts_line_activity_csv',
        method: 'GET',
        params

    })

}

function accountCancellation(params) {
    return requestrApi({
        url: '/line_customers/canclations',
        method: 'GET',
        params

    })

}
function getInvoiceReportDetails(params) {
    return requestrApi({
        url: '/accounts/listing_invoice_report',
        method: 'GET',
        params

    })

}
function getReportDetails(params) {
    return requestrApi({
        url: '/accounts/reports_details',
        method: 'GET',
        params

    })

}

function getReportWoDetails(params) {
    return requestrApi({
        url: '/accounts/reports_work_orders',
        method: 'GET',
        params

    })

}
function updateLineInvoiceNumber(params) {
    return requestrApi({
        url: '/accounts/edit_invoice_report',
        method: 'PUT',
        params

    })

}
const deleteLineInvoice = (params) => {
    return requestrApi({
        url: `/accounts/destroy_invoice_report`,
        method: "DELETE",
        params
    });
};
const ActivityService = {
    getActivityService, fetchDependencies, fetchAircraftRegistrations,
    getDelayInbounds,
    getDelayExceedsGnd,
    getAccountsActivityData,
    updateAccountInvoices,
    activityDownloadCSV,
    accountCancellation,
    getInvoiceReportDetails,
    getReportDetails,
    getReportWoDetails,
    updateLineInvoiceNumber,
    deleteLineInvoice

};

export default ActivityService;