import { makeStyles, Paper } from "@material-ui/core";
import { Typography, FormControl, Select, InputBase, MenuItem, IconButton, Grid, Divider, Button, FormControlLabel, Menu } from "@material-ui/core";
import { Box } from "@material-ui/core";
import React, { useState, useContext } from "react";

import ReactApexChart from "react-apexcharts";
import ApexCharts from 'apexcharts'
import IndicatorLabel from "../../LineMaintenance/IndicatorLabel";
import CloudDownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import DownloadFile from "./DownloadFile";
import { formatToDateOnly, getOrganisationId } from "lib/utils/common_utils";
import { StationDashboardServices } from "lib/services/api/dashboard/station_compliance";
import GraphicEqIcon from '@material-ui/icons/GraphicEq';
import LiveRecords from "./LiveRecords";
import ChartLoader from "../../Workshops/Storage/ChartLoader";
import { useMemo } from "react";
import _ from "lodash";
import { ToastMessageContext } from "lib/contexts/message_context";
import moment from "moment";
import { useEffect } from "react";
import { MaintenanceServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation";
import PositiveSwitch from "components/form_components/switch";
import ArrowDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import SearchBarWithFilters from "components/search_with_filters";
import _without from "lodash/without";
import { DatePicker } from "antd";

const { RangePicker } = DatePicker;
const HUMIDITY_COLOR = "#56D9FE";
const TEMPERATURE_COLOR = "#5FE3A1";

const useStyles = makeStyles((theme) => ({
  alert: {
    display: "flex",
    justifyContent: "center",
    color: theme.palette.warning.main,
  },
  root: {
    width: "94%",
    margin: "2% 3%",
    '&.MuiStepConnector-root': {
      borderTopWidth: '10px'
    }
  },
}));

function TemperatureHumidity(props) {
  const classes = useStyles();
  const [tempLoading, setTemploading] = useState(false)
  const [bases, setBases] = useState([]);
  const [action, setAction] = useState(null)
  const [filterData, setFilterData] = useState({ days: 'thirty' });
  const message = useContext(ToastMessageContext);
  const [anchorBase, setAnchorBase] = useState(null);
  const [baseButton, setBaseButton] = useState(false)
  const [selectedBase, setSelectedBase] = useState([]);
  const [checkedState, setCheckedState] = useState(new Array(bases.length).fill(false));
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [value, setValue] = useState([moment().subtract(7, 'days'), moment()])
  const [humdLoading, setHumdloading] = useState(false)

  const [dataSeries, setDataSeries] = useState({
    series: [],

    options: {
      chart: {
        height: 350,
        width: "100%",
        type: "line",
        // stacked: true,

        dropShadow: {
          enabled: true,
          color: "#000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        toolbar: {
          show: false,
        },
      },

      colors: ["#FFB300", "#145999", "#26B4E8", "#4FC605", "#FF0202", "#000000", "#A4BFD8", "#707070"],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
        width: 2,
      },

      grid: {
        borderColor: "#e7e7e7",
        row: {
          colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
        column: {
          colors: ["transparent", "transparent"],
          opacity: 0.5
        },
      },
      markers: {
        size: 0,
      },
      animations: {
        enabled: false
      },
      xaxis: {
        categories: [],
        // tickAmount: 10,
        labels: {
          rotate: 0,
          show: true
        },


      },
      yaxis: {
        min: 0,
        max: 100,
        // tickAmount: 4,
      },
      annotations: {
        yaxis: []
      },

      legend: {
        position: "top",
        horizontalAlign: "right",
        // floating: true,
        offsetY: 5,
        offsetX: 5,
      },
    },
  })


  const [humdSeries, setHumdSeries] = useState({
    series: [],

    options: {
      chart: {
        height: 350,
        width: "100%",
        type: "line",
        // stacked: true,

        dropShadow: {
          enabled: true,
          color: "#000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        toolbar: {
          show: false,
        },
      },

      colors: ["#FFB300", "#145999", "#26B4E8", "#4FC605", "#FF0202", "#000000", "#A4BFD8", "#707070"],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
        width: 2,
      },

      grid: {
        borderColor: "#e7e7e7",
        row: {
          colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
        column: {
          colors: ["transparent", "transparent"],
          opacity: 0.5
        },
      },
      markers: {
        size: 0,
      },
      animations: {
        enabled: false
      },
      xaxis: {
        categories: [],
        // tickAmount: 10,
        labels: {
          rotate: 0,
          show: true
        },


      },
      yaxis: {
        min: 0,
        max: 100,
        // tickAmount: 4,
      },
      annotations: {
        yaxis: []
      },

      legend: {
        position: "top",
        horizontalAlign: "right",
        // floating: true,
        offsetY: 5,
        offsetX: 5,
      },
    },
  })
  const handleDateChange = (value) => {
    setStartDate(true)
    setEndDate(true)
    setValue(value)
    if (value) {
      const startdate = moment(value[0]).format("YYYY-MM-DD");
      const enddate = moment(value[1]).format("YYYY-MM-DD");
      let dayDiff = moment(value[0]).diff(value[1], 'days')

      if (Math.abs(dayDiff) > 365) {
        setValue(null)
        setFilterData({})
        message.showToastMessage({
          message: "Date range exceeds 1-year maximum!",
          variant: "error",
        });

      }
      else {
        setFilterData((prevState) => ({ ...prevState, startdate, enddate }));
      }

    } else {
      const fData = { ...filterData };
      filterData?.startdate && delete fData.startdate;
      filterData?.enddate && delete fData.enddate;
      setFilterData({ ...fData });
    }
  };





  const getTemperatureData = async () => {

    setTemploading(true)
    const params = {
      organisation_id: getOrganisationId(),
      type: 'Temperature',
      start_date: startDate ? filterData.startdate : moment().subtract(7, 'days').format("YYYY-MM-DD"),
      end_date: endDate ? filterData.enddate : moment().format("YYYY-MM-DD"),
      // ...filterData
    }
    selectedBase.forEach((item, index) => params[`base_station_id[${[index]}]`] = item)
    await StationDashboardServices.getTemperatureAndHumidityDetails(params).then((response) => {
      const annotations = response.data.storage_condition_range

      setDataSeries((prevState) => {

        const updatedState = _.clone(prevState);
        // console.log("item===",)
        return {
          ...updatedState,
          series: response.data.dataset,
          options: {
            ...updatedState.options,

            xaxis: {
              type: 'category',
              categories: response.data.labels,
              tickAmount: 5,
              labels: {
                formatter: (value) => {
                  return moment(value).format('DD/MMM')
                },
                rotateAlways: false,
                show: true,

              }

            },
            dataLabels: {
              show: false
            },
            tooltip: {

              shared: true,

              y: {
                formatter: function (value) {
                  return value === undefined ? "0.00" : value
                }
              }
            },
            annotations: {

              yaxis: [
                {
                  y: annotations[0].min_value,
                  y2: annotations[0].max_value,

                  fillColor: "#5FE3A1"
                },
                // {
                //   y: annotations[1].min_value,
                //   y2: annotations[1].max_value,
                //   fillColor: "#56D9FE"
                // },

              ]

            }
          }
        }
      })


    }).catch((e) => {
      message.showToastMessage({
        message: "Something went wrong. Try again!",
        variant: "error",
      });
    }).finally(() => {
      // setTemploading(false)
      setTimeout(() => {
        setTemploading(false)

      }, 2000)

    })
  }
  useMemo(() => {
    getTemperatureData()

  }, [selectedBase, filterData])


  //Humdity data

  const getHumidityData = async () => {

    setHumdloading(true)
    const params = {
      organisation_id: getOrganisationId(),
      type: 'Humidity',
      start_date: startDate ? filterData.startdate : moment().subtract(7, 'days').format("YYYY-MM-DD"),
      end_date: endDate ? filterData.enddate : moment().format("YYYY-MM-DD"),
      // ...filterData
    }
    selectedBase.forEach((item, index) => params[`base_station_id[${[index]}]`] = item)
    await StationDashboardServices.getTemperatureAndHumidityDetails(params).then((response) => {
      const annotations = response.data.storage_condition_range

      setHumdSeries((prevState) => {

        const updatedState = _.clone(prevState);
        // console.log("item===",)
        return {
          ...updatedState,
          series: response.data.dataset,
          options: {
            ...updatedState.options,

            xaxis: {
              type: 'category',
              categories: response.data.labels,
              tickAmount: 5,
              labels: {
                formatter: (value) => {
                  return moment(value).format('DD/MMM')
                },
                rotateAlways: false,
                show: true,

              }

            },
            tooltip: {

              shared: true,

              y: {
                formatter: function (value) {
                  return value === undefined ? "0.00" : value
                }
              }
            },
            dataLabels: {
              show: false
            },

            annotations: {

              yaxis: [
                {
                  y: annotations[0].min_value,
                  y2: annotations[0].max_value,

                  fillColor: "#56D9FE"
                },


              ]

            }
          }
        }
      })


    }).catch((e) => {
      message.showToastMessage({
        message: "Something went wrong. Try again!",
        variant: "error",
      });
    }).finally(() => {
      // setTemploading(false)
      setTimeout(() => {
        setHumdloading(false)

      }, 2000)

    })
  }

  useMemo(() => {
    getHumidityData()
  }, [selectedBase, filterData])

  const handleFilterChange = (keyItem, value) => {
    setFilterData((prevState) => ({ ...prevState, [keyItem]: value }));
  };
  //filters
  const handleBaseClick = (event) => {
    setAnchorBase(event.currentTarget);
    setBaseButton(!baseButton)
  };
  const filterChangeHandler = (e) => {
    const { name, value, checked } = e.target
    setSelectedBase((prevState) => [...prevState, value])
    if (!checked) {
      setSelectedBase((current) => _without(current, value));
    }

  }


  const ITEM_HEIGHT = 78;
  const getBaseStation = async () => {
    const params = {
      organisation_id: getOrganisationId(),
      order_by: 'station'
    }
    MaintenanceServices.getBaseStation(params)
      .then((res) => {
        if (res) {
          setBases(res.data);
        }

      })
      .catch((err) => {
        message.showToastMessage({
          message: "Something went wrong. Try again!",
          variant: "error",
        });

      });
  };



  useEffect(() => {
    getBaseStation()
  }, [])


  return (<>

    <div className={classes.root}>
      <div  >
        <SearchBarWithFilters noSearch padding={'4px 16px'}>

          <FormControl className={"filter"} style={{ marginLeft: '-11px' }}>
            <Button variant={anchorBase ? "contained" : "text"} size="small" aria-haspopup="true" onClick={handleBaseClick} endIcon={baseButton ? <ArrowDropUp /> : <ArrowDown />} style={{ fontSize: "14px", backgroundColor: anchorBase ? '#efefef' : "" }}>
              {selectedBase.length > 0 ? `Base(${selectedBase.length})` : 'Base(All)'}

            </Button>
            <Menu
              id="Base"
              anchorEl={anchorBase}
              keepMounted
              open={Boolean(anchorBase)}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
              onClose={() => {
                setAnchorBase(null)
                setBaseButton(!baseButton)
              }}
              // anchorOrigin={{
              //     vertical: 'bottom',
              //     horizontal: 'left',
              // }}
              style={{ marginTop: '0px' }}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: '39ch',
                  position: 'absolute',
                  top: '260px',
                },
              }}
            >
              {
                bases?.map((base, index) => {
                  return <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={1}></Grid>
                      <Grid item xs={8} style={{
                        display: "flex", alignItems: "center", fontSize: "14px",

                      }}>
                        <label>{base.station}</label>
                      </Grid>
                      <Grid item xs={3}>
                        <FormControlLabel
                          style={{ alignSelf: "flex-end" }}
                          control={<PositiveSwitch
                            checked={checkedState[index]}
                            value={base.id}
                            // onChange={(e) => handleChange(e, option.id)}
                            onChange={(e) => { filterChangeHandler(e, index) }}
                            name={base.station} />}
                        // name="base"
                        />
                      </Grid>
                    </Grid>
                    <Grid><Divider variant="middle" /></Grid>
                  </Box>
                }

                )}
            </Menu>
          </FormControl>
          <FormControl style={{ float: "right" }}>

            <RangePicker
              format="DD/MM/YYYY"
              onChange={handleDateChange}
              allowClear={true}
              // defaultValue={[moment().subtract(1, 'months'), moment()]}
              value={value}
            //   disabledDate={(current) => handleDisabledRange(current)}
            />
          </FormControl>
          <FormControl style={{ float: "right" }}>
            <IconButton style={{ paddingRight: '15px' }} onClick={() => setAction("download")} >
              <CloudDownloadIcon />
            </IconButton>
          </FormControl>
          <FormControl style={{ float: "right" }}>
            <IconButton style={{ paddingRight: '15px' }} onClick={() => setAction("history")} >
              <GraphicEqIcon />
            </IconButton>

          </FormControl>
        </SearchBarWithFilters>
      </div>
      <Box >
        <Paper style={{ width: "100%", padding: "30px 35px" }}>
          <Box width="100%" display="flex" justifyContent="space-between">
            <Typography color="primary" variant="h6">
              Temperature
            </Typography>

          </Box>
          {tempLoading ? <ChartLoader /> :
            <Box width="100%" display="flex" p={2}>
              <Box width="98%">

                <ReactApexChart
                  options={dataSeries.options}
                  series={dataSeries.series}
                  type="line"
                  height={350}
                />
              </Box>
            </Box>}


        </Paper>
      </Box>
      <br />
      <Box >
        <Paper style={{ width: "100%", padding: "30px 35px" }}>
          <Box width="100%" display="flex" justifyContent="space-between">
            <Typography color="primary" variant="h6">
              Humidity
            </Typography>

          </Box>
          {humdLoading ? <ChartLoader /> :
            <Box width="100%" display="flex" p={2}>
              <Box width="98%">

                <ReactApexChart
                  options={humdSeries.options}
                  series={humdSeries.series}
                  type="line"
                  height={350}
                />
              </Box>
            </Box>}


        </Paper>
      </Box>
      {action && action === 'download' && <DownloadFile handleClose={() => setAction(null)} selectedBase={selectedBase} />}
      {action && action === 'history' && <LiveRecords handleClose={() => setAction(null)} selectedBase={selectedBase} />}
    </div>
  </>
  );
}

export default React.memo(TemperatureHumidity)



