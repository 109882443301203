import MROFullScreenRightDrawer from 'components/fullwidth_dialog'
import React, { useReducer } from 'react'
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import { Box, makeStyles, MenuItem, Tooltip } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import MROTextField from 'components/form_components/TextField';
import TouchAppIcon from "@material-ui/icons/TouchApp";

import MaterialTable from "material-table";
import TableAction from 'actions/table_actions';
import tableReducer, {
    INITIAL_TABLE_STATE,
} from 'reducers/table_reducer';
import tableIcons, {
    options,
} from 'components/universal/table_attributes';
import {
    Visibility as ViewIcon,
} from "@material-ui/icons";
import { useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import BoxpTableAction from 'actions/boxp_table_actions';
import { useContext } from 'react';
import { ToastMessageContext } from 'lib/contexts/message_context';
import { useEffect } from 'react';
import { format24HrDateTime } from 'lib/utils/common_utils';
import { ICON_COLOUR } from 'lib/constants/style_constants';
import { addHyphenToNullFields } from 'lib/utils/helperFunctions';
import UnitPricePopup from './UnitPricePopup';
import PriceBreaks from './PriceBreaks';
import CommercialOverview from './CommercialOverview';


const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: "#fff",
        color: "#000000CC",
        boxShadow: "0px 3px 6px #0000001A",
    },
    backButton: {
        marginRight: theme.spacing(2),
    },
    root: {
        backgroundColor: "#ffffff",
    },
    wrapper: {
        padding: "0 28px",
    },
    mainTitle: {
        fontSize: "20px",
        paddingTop: "25px",
    },
    subTitle: {
        fontSize: "16px",
        fontWeight: "bold",
        marginBottom: "10px",
        marginTop: "20px",
    },
    partCard: {
        borderRadius: "8px",
        padding: "17px 24px",
        "& p": {
            fontSize: "14px",
            padding: "5px",
        },
    },
    descriptionText: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: "8px",
        padding: "20px",
        background: "#F5F5F5",
    },
    partKey: {
        fontSize: "14px",
        // padding: "5px",
    },
    partValue: {
        fontSize: "14px",
        color: "#4D4F5C",
        padding: "5px",
        display: "flex",
        alignItems: "center",
    },
    alternatesInfo: {
        fontSize: "16px",
        color: "#4D4F5C",
        marginTop: "15px",
        display: "block",
    },
    disableField: {
        pointerEvents: "none",
        backgroundColor: "#F5F5F5",
    }

}));

function PartHistory(props) {
    const { handleClose, primePart, workshop_order_id } = props;
    const message = useContext(ToastMessageContext)
    const [selectedPart, setSelectedPart] = useState({});
    const [runApiCall, setRunApiCall] = useState(false);
    const [action, setAction] = useState(null)
    const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
    const { page, totalCount, pageSize, searchText, data, inProgress } = tableState;
    const [selectedId, setSelectedId] = useState({})
    const [currentSelection, setCurrentSelection] = useState({})
    const classes = useStyles()


    const handleAction = (data) => {
        props.handleData(data)
        handleClose();
    }

    useEffect(() => {
        if (runApiCall) {
            getPartHistory();
        }
    }, [page, pageSize, selectedPart, searchText]);

    const getPartHistory = () => {
        const params = {
            page: page + 1,
            limit: pageSize,
            order_type: "Maintenance",
            primary_part_id: selectedPart?.id,
            search: searchText
        };
        BoxpTableAction.getList(dispatch, message, params, "partHistory");
    };

    //columns
    const columns = [
        {
            title: "Order #",
            field: "order_no",

        },
        {
            title: "Date",
            field: "date",
            render: (rowData) => format24HrDateTime(rowData?.created_at)
        },
        {
            title: "Base",
            field: "base_station",

        },
        {
            title: "Customer",
            field: "customer",

        },
        {
            title: "Description of works",
            field: "planning_description",
            headerStyle: {
                textAlign: "center",
                width: "20%",
            },
            cellStyle: {
                textAlign: "center",
                width: "20%",
            },

        },
        {
            title: "WS Report",
            field: "wsreport",
            headerStyle: {
                width: "5%",
            },
            render: (rowData) => (
                <IconButton>
                    <ViewIcon
                        fontSize="small"
                        color={ICON_COLOUR}
                        onClick={() => {
                            window.open(rowData?.workshop_report?.map(report => report?.file_path));
                        }}
                    />
                </IconButton>
            ),

        }, {
            title: "TAT",
            field: "tat_days",

        },
        {
            title: "Unit Price",
            field: "total",
            render: (rowData) => {
                return (
                    <>
                        <IconButton onClick={() => {
                            setAction("unit_price")
                            setSelectedId(rowData?.id)
                            setCurrentSelection(rowData)
                        }}>
                            <Typography color='primary' style={{ textDecoration: 'underline' }}>
                                {rowData?.total}
                            </Typography>
                        </IconButton>
                    </>
                )
            }
        },
        {
            title: "Price Breaks",
            field: "price_breaks",
            render: (rowData) => {
                return (
                    <>
                        <IconButton onClick={() => {
                            setAction("price_breaks")
                            setSelectedId(rowData?.id)
                        }}>
                            <Typography color='primary' style={{ textDecoration: 'underline' }}>
                                {rowData?.price_breaks}
                            </Typography>
                        </IconButton>
                    </>
                )
            }
        },
        {
            title: "AOG Fee",
            field: "aog",

        }, {
            title: "Cur",
            field: "currency_code",

        }, {
            title: "Actuals",
            field: "aprvl",
            render: (rowData) => {
                return (
                    <>
                        <IconButton onClick={() => {
                            setAction("commercial")
                            setSelectedId(rowData?.id)
                            setCurrentSelection(rowData)
                        }}>
                            <Typography color='primary' style={{ textDecoration: 'underline' }}>
                                {rowData?.aprvl}
                            </Typography>
                        </IconButton>
                    </>
                )
            }

        },
        {
            title: "Action",
            field: "action",
            render: (rowData) => (
                <IconButton onClick={() => handleAction(rowData)}>
                    <TouchAppIcon />
                </IconButton>
            ),

        }
    ];

    const tableOptions = {
        ...options,
        page: page,
        total: totalCount,
        pageSize: pageSize,
        searchText: searchText,
        headerStyle: {
            paddingTop: "2px",
            paddingBottom: "13px",
            textAlign: "center",
        },
    };



    return (
        <MROFullScreenRightDrawer open={true}>
            <AppBar position='static' className={classes.appBar} elevation={0}>

                <Toolbar>
                    <IconButton
                        edge="start"
                        className={classes.backButton}
                        color="inherit"
                        aria-label="back"
                        onClick={() => {
                            handleClose()
                        }
                        }
                    >
                        <BackIcon />
                    </IconButton>

                    <Typography variant="subtitle2" style={{ fontSize: "17px" }}>
                        Part History
                    </Typography>
                </Toolbar>
            </AppBar>
            <div className={classes.root}>
                <div className={classes.wrapper}>
                    <Typography
                        variant={"subtitle1"}
                        color={"primary"}
                        className={classes.mainTitle}
                    >
                        Part Details
                    </Typography>
                    {""}
                    <Typography>
                        Search and select a part to see historical Orders in the system</Typography>
                    <Grid
                        style={{ marginBottom: "20px", width: "86%" }}
                        container
                        spacing={4}
                        alignItems={"center"}
                        justify={"flex-start"}
                    >
                        <Grid item xs={4}>
                            <Autocomplete
                                getOptionSelected={(option, value) => option.id === value.id}
                                value={selectedPart !== null && selectedPart}
                                onChange={(event, value) => {
                                    setSelectedPart(value);
                                    setRunApiCall(true)
                                }}
                                id="tags-standard"
                                options={primePart || []}
                                // loading={loading}
                                getOptionLabel={(option) => option?.part_number || ''}
                                renderInput={(params) => (
                                    <MROTextField
                                        {...params}
                                        style={{ backgroundColor: "#fff", height: "54px" }}
                                        variant="outlined"
                                        name="part_number"
                                        placeholder={"Search and Select"}
                                        label="Part Number"
                                        color={"primary"}
                                        InputProps={{
                                            ...params.InputProps,
                                            type: "search",
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={4} style={{ marginBottom: "5px" }}>
                            <MROTextField
                                name={"item_description"}
                                label={"Item Description"}
                                // disabled={true}
                                InputProps={{
                                    className: classes.disableField,
                                }}
                                value={!selectedPart?.description ? "" : selectedPart?.description}
                                InputLabelProps={{ shrink: true }}
                            ></MROTextField>
                        </Grid>
                    </Grid>
                    <Typography
                        variant={"subtitle1"}
                        color={"primary"}
                        className={classes.mainTitle}
                    >
                        History
                    </Typography>
                    <div>
                        <MaterialTable
                            style={{
                                boxShadow: "0px 1px 3px #00000033",
                                marginTop: "10px",
                            }}
                            icons={tableIcons}
                            classes
                            title={""}
                            columns={columns}
                            localization={{
                                toolbar: {
                                    searchPlaceholder: "Search anything",
                                },
                                body: {
                                    emptyDataSourceMessage: "No data Found",
                                    filterRow: {
                                        filterTooltip: "Filter",
                                    },
                                },
                            }}
                            data={runApiCall ? addHyphenToNullFields(data.data) : []}
                            isLoading={inProgress}
                            options={tableOptions}
                            onChangePage={(page) => {
                                TableAction.setPage(dispatch, page);
                            }}
                            onChangeRowsPerPage={(pageSize) => {
                                TableAction.setPageSize(dispatch, pageSize);
                            }}
                            onSearchChange={(search) => {
                                TableAction.setSearchText(dispatch, search);
                            }}
                            totalCount={totalCount}
                            page={page}
                        />
                    </div>
                </div>

            </div>
            {action === "unit_price" &&
                <UnitPricePopup
                    open={action}
                    handleClose={() => setAction(null)}
                    workshop_order_id={selectedId}
                    currentSelection={currentSelection}
                />
            }
            {action === "price_breaks" &&
                <PriceBreaks
                    open={action}
                    handleClose={() => setAction(null)}
                    workshop_order_id={selectedId}
                />
            }
            {action === "commercial" &&
                <CommercialOverview
                    open={action}
                    handleClose={() => setAction(null)}
                    workshop_order_id={selectedId}
                    currentSelection={currentSelection}
                />
            }


        </MROFullScreenRightDrawer>
    )
}

export default PartHistory