import requestApi from "lib/services/api/common/boxp_api_client";
import { BASE_API_URL_BOXP } from "lib/constants";
import requestrApi from "lib/services/api/common/boxr_api_client";
import request from "lib/services/api/common/api_client";
// import { BASE_API_URL_BOXR } from "lib/constants";


const getAllDropDown = (params) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/action_dependency`,
    method: "GET",
    params
  });
};
const createAction = (formData) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/action_imp`,
    method: "POST",
    data: formData
  });
};
const getInspAuth = () => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/insp_auth`,
    method: "GET",
  });
};
const getActionPhotos = (id) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/action_photo/${id}`,
    method: "GET",
  });
};
const getSelectedAction = (id) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/action_imp/${id}`,
    method: "GET"
  });
};
const getActionCertificate = (id) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/action_certificate/${id}`,
    method: "GET",
  });
};
const getActionComments = (id) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/action_comment/${id}`,
    method: "GET",
  });
};
const getAllActionStages = (params) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/action_stages`,
    method: "GET",
    params
  });
};
const getStageAction = (params) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/action_stage_details`,
    method: "GET",
    params
  });
};

const addCertificate = (formData) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/action_certificate`,
    method: "POST",
    data: formData,
  });
};
const addPhotos = (formData) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/action_photo`,
    method: "POST",
    data: formData,
  });
};
const addComments = (formData) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/action_comment`,
    method: "POST",
    data: formData,
  });
};
const updateAction = (data, id) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/action_imp/${id}?_method=PUT`,
    method: "POST",
    data,
  });
};
const updateActionVerification = (data, id) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/action_verification/${id}?_method=PUT`,
    method: "POST",
    data,
  });
};
const editcomments = (data, id) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/action_comment/${id}?_method=PUT`,
    method: "POST",
    data,
  });
};
const addOldStagesToAction = (formData) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/add_stage_to_action`,
    method: "POST",
    data: formData,
  });
};
const addLibrary = (formData) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/add_stage_to_action`,
    method: "POST",
    data: formData,
  });
};
const updateStagePosition = (formData) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/update_stage_position?_method=PUT`,
    method: "POST",
    data: formData,
  });
};
const deleteAction = (id) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/action_imp/${id}`,
    method: "DELETE",
  });
};
const deleteStage = (id) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/delete_stage/${id}`,
    method: "DELETE",
  });
};
const editStage = (data) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/edit_stage?_method=PUT`,
    method: "POST",
    data,
  });
};

const getHistoryData = (params) => {
  return requestApi({
    url: `/certification_history`,
    method: "GET",
    params
  });
};

const getManHoursData = (params) => {
  return requestApi({
    url: `/man_hours_history`,
    method: "GET",
    params
  });
};
export const InspectionServices = {
  getAllDropDown,
  createAction,
  updateAction,
  deleteAction,
  getInspAuth,
  getActionPhotos,
  getActionCertificate,
  addCertificate,
  addPhotos,
  getSelectedAction,
  addComments,
  editcomments,
  getActionComments,
  updateActionVerification,
  addOldStagesToAction,
  addLibrary,
  getAllActionStages,
  getStageAction,
  updateStagePosition,
  deleteStage,
  editStage,
  getHistoryData,
  getManHoursData

};
