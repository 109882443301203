import React from "react"

const Records = ({ fill }) => (
  <svg id="Group_6771" data-name="Group 6771" xmlns="http://www.w3.org/2000/svg" width="120.989" height="18" viewBox="0 0 120.989 135">
    <g id="Group_5703" data-name="Group 5703" transform="translate(0)">
      <path id="Path_19820" data-name="Path 19820" d="M171.322,205.273A5.273,5.273,0,0,0,166.049,200H111.732a5.273,5.273,0,0,0,0,10.547h54.316A5.273,5.273,0,0,0,171.322,205.273Z" transform="translate(-85.395 -147.266)" fill={fill} />
      <path id="Path_19821" data-name="Path 19821" d="M111.732,280a5.273,5.273,0,0,0,0,10.547h32.988a5.273,5.273,0,0,0,0-10.547Z" transform="translate(-85.395 -206.172)" fill={fill} />
      <path id="Path_19822" data-name="Path 19822" d="M65.3,124.453H47.664a10.559,10.559,0,0,1-10.547-10.547V21.094A10.559,10.559,0,0,1,47.664,10.547H112.5a10.559,10.559,0,0,1,10.547,10.547V53.525a5.273,5.273,0,0,0,10.547,0V21.094A21.118,21.118,0,0,0,112.5,0H47.664A21.118,21.118,0,0,0,26.57,21.094v92.812A21.118,21.118,0,0,0,47.664,135H65.3a5.273,5.273,0,1,0,0-10.547Z" transform="translate(-26.57)" fill={fill} />
      <path id="Path_19823" data-name="Path 19823" d="M302.632,276.651a15.837,15.837,0,0,0-22.369,0l-28.952,28.888a5.276,5.276,0,0,0-1.321,2.2l-6.305,20.758a5.273,5.273,0,0,0,6.454,6.615l21.285-5.9a5.268,5.268,0,0,0,2.318-1.35l28.891-28.837A15.838,15.838,0,0,0,302.632,276.651Zm-35.361,42.766-10.708,2.966,3.134-10.317,19.535-19.492,7.459,7.459Zm27.907-27.854-1.022,1.02-7.458-7.458,1.018-1.016a5.274,5.274,0,0,1,7.462,7.454Z" transform="translate(-186.27 -200.299)" fill={fill} />
      <path id="Path_19824" data-name="Path 19824" d="M166.049,120H111.732a5.273,5.273,0,0,0,0,10.547h54.316a5.273,5.273,0,0,0,0-10.547Z" transform="translate(-85.395 -88.359)" fill={fill} />
    </g>
  </svg>
)

export default Records