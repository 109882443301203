import React from 'react'
import { Box } from "@material-ui/core";
import RenderReleaseTab from './RenderReleaseTab';

export default function TabComponent(props) {
    console.log("called this component renderRelease")
    return (
        <Box sx={{ width: "100%" }}>
            <RenderReleaseTab {...props} />
        </Box>
    )
}
