import { Box, Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import MROTextField from '../../../../components/form_components/TextField';
import FreeTextField from './FieldComponents/free_text_field'
import { HydAndOilQuarts } from './section_detail';

export const OilAndHydFluid = ({ subSection, errors, setErrors, sub_section_fields, oilState, setOilState, disabled }) => {

    const [val, setVal] = useState(subSection.field_value);
    const [secondaryCheck, setSecondaryCheck] = useState(false);

    useEffect(() => {
        const oilField = sub_section_fields.find(section => section.field_name === HydAndOilQuarts[0])
        const hydField = sub_section_fields.find(section => section.field_name === HydAndOilQuarts[1])
        setOilState({
            ...oilState,
            'Oil - Quarts': oilField?.field_value,
            'Hyd Fluid - Quarts': hydField?.field_value,
        })
    }, [])

    useEffect(() => {
        setSecondaryCheck(parseFloat(oilState[subSection.field_name]) >= 1)
    }, [oilState['Oil - Quarts'], oilState['Hyd Fluid - Quarts']])


    const handleChange = _name => ({ target: { value } }) => {

        setOilState({
            ...oilState,
            [_name]: value
        })
    }

    const inputProps = {
        max: subSection.max_length,
        step: subSection.decimal_places ? (1 / (10 ^ subSection.decimal_places)) : null,
        readOnly:disabled
    }
    const fieldProps = {
        // required: subSection.mandatory || false,
        type: "number",
        label: subSection.field_name === "Tool Pre-Check Completed By - Stamp #" ? 'Tool Pre-Checks' : subSection.field_name,
        name: subSection.id || subSection.org_field_id,
        defaultValue: subSection.field_value,
        disabled: subSection.disabled,
        // error: errors && errors[subSection.id || subSection.org_field_id],
        autoFocus: errors && errors[subSection.id || subSection.org_field_id] && !errors[subSection.id || subSection.org_field_id].valid,
        error: errors && errors[subSection.id || subSection.org_field_id] && !errors[subSection.id || subSection.org_field_id].valid,
        secondary_fields: subSection.secondary_fields ? {
            // required: subSection.secondary_fields[0].mandatory,
            label: oilState['Oil - Quarts'] ? subSection.secondary_fields[0].field_name + ' *' : subSection.secondary_fields[0].field_name,
            name: subSection.secondary_fields[0].org_field_id,
            defaultValue: subSection.secondary_fields[0].field_value,
            disabled: subSection.secondary_fields[0].disabled,
            error: errors && errors[subSection.secondary_fields[0].org_field_id] && !errors[subSection.secondary_fields[0].org_field_id]?.valid,
            autoFocus: errors && errors[subSection.secondary_fields[0].org_field_id] && !errors[subSection.secondary_fields[0].org_field_id]?.valid
        } : {}
    }




    return (
        <Box>
            <Grid container spacing={3}  >
                <Grid item xs={secondaryCheck ? 6 : 12}>
                    <FreeTextField {...subSection}
                        {...fieldProps}
                        errors={errors}
                        handleChange={(handleChange(subSection.field_name))}
                        style={{ paddingRight: '25px' }}
                        disabled={disabled}
                    />
                </Grid>
                {
                    secondaryCheck &&
                    <Grid item xs={6}>
                        <MROTextField
                            style={{ paddingRight: '25px' }}
                            {...fieldProps.secondary_fields}
                            inputProps={inputProps}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => {
                                handleChange({ name: e.target.name, value: e.target.value });
                                setErrors({
                                    ...errors,
                                    [e.target.name]: {
                                        valid: e.target.value ? true : false
                                    }
                                })
                            }}
                        />
                    </Grid>
                }
            </Grid>
        </Box >
    )
}


export default React.memo(OilAndHydFluid)
