import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  Grid,
  Box,
  FormControlLabel,
} from "@material-ui/core";
import MROFullScreenRightDrawer from "components/fullwidth_dialog";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import React, { useContext, useEffect, useMemo } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ApprovedPosition from "./ApprovedPosition";
import CurrentLivePosition from "./CurrentLivePosition";
import { useState } from "react";
import MROTextField from "components/form_components/TextField";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import WarningIcon from "@material-ui/icons/Warning";
import MROButton from "components/buttons";
import YesNoField from "components/form_components/YesNoField";
import { validateFormData } from "lib/utils/helperFunctions";
import { CommercialServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/commercial";
import { ToastMessageContext } from "lib/contexts/message_context";
import { WorkshopContext } from "../WorkshopContext/WorkshopContext";
import PositiveSwitch from "components/form_components/switch";
import MessagePopup from "components/universal/messagePopup";
import CreateApprovaCaution from "./CreateApprovaCaution";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: "3%",
  },
  appBar: {
    backgroundColor: "#fff",
    color: "#000000CC",
    boxShadow: "0px 3px 6px #0000001A",
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  disableField: {
    pointerEvents: "none",
    backgroundColor: "#F5F5F5",
  },
}));

function ApprovalManage(props) {
  const classes = useStyles();
  const {
    handleClose,
    currentSelectedOrder,
    getApprovedPosition,
    currentPositionData,
    totalRow,
    tatDays,
    approvedPosition
  } = props;
  const { setApprovalStateCommercial, approvalStateCommercial } = useContext(WorkshopContext);
  const message = useContext(ToastMessageContext);
  const [value, setValue] = useState(null);
  const [busy, setBusy] = useState(null);
  const [sum, setSum] = useState(null);
  const [report, setReport] = useState(true);
  const [errors, setErrors] = useState({});
  const [messagePopup, setMessagePopup] = useState(false)
  const [revisedtotal, setRevisedTotal] = useState(null)
  const [input, setInput] = useState({
    tat_days: "",
    labour_value: "",
    parts_value: "",
    service_value: "",
    equipment_value: "",
    aog_fee: "",
  });

  const [confirmationPopup, setConfirmationPopup] = useState(false)
  const [acceptAll, setAcceptAll] = useState(false)
  const [popupValues, setPopUpValues] = useState({})
  const [formvalues, setFormvalues] = useState({})
  // console.log("current position is===================", currentPositionData)

  useMemo(
    () =>
      setSum(
        +input.labour_value +
        +input.parts_value +
        +input.service_value +
        +input.equipment_value +
        +input.aog_fee
      ),
    [input]
  );
  useMemo(
    () =>
      setRevisedTotal(
        +input.labour_value +
        +input.parts_value +
        +input.service_value +
        +input.equipment_value
      ),
    [input]
  );
  useEffect(() => {
    if (currentPositionData !== null) {
      let datas = currentPositionData?.map(data => {
        setInput({
          tat_days: +data?.tat_days || "0",
          labour_value: +data?.total_estimate_charge || "0",
          parts_value: +data?.parts || "0",
          service_value: +data?.services || "0",
          equipment_value: +data?.equipment || "0",
          aog_fee: +data?.aog_fee || "0",
        });
      })
    }
  }, [currentPositionData, value])

  const handleToggle = (value) => {
    if (value) {
      setValue(value);
    }
  };
  //handle input change
  const handleInput = function (e) {
    const { value } = e.target;
    if (value.match(/\./g)) {
      const [, decimal] = value.split(".");

      // restrict value to only 2 decimal places
      if (decimal?.length > 2) {
        // do nothing
        return;
      }
    }
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };

  const resetErrorsHandler = (name) => {
    setErrors((e) => ({ ...e, [name]: false }));
  };

  useEffect(() => {
    setValue("yes");
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const { error, valid } = validateFormData(formData, ["send_report_to_customer"]);
    if (!valid) {
      setBusy(null);
      setErrors(error);
      return;
    }
    setBusy("loading");

    formData.delete("tat_days")
    formData.delete("labour_value")
    formData.delete("parts_value")
    formData.delete("service_value")
    formData.delete("equipment_value")
    formData.delete("aog_fee")
    formData.delete("total")
    formData.append("tat_days", input.tat_days)
    formData.append("labour_value", input.labour_value || "")
    formData.append("parts_value", input.parts_value || "")
    formData.append("service_value", input.service_value || "")
    formData.append("equipment_value", input.equipment_value || "")
    formData.append("aog_fee", input.aog_fee || "")
    formData.append("total", parseFloat(revisedtotal).toFixed(2) || "")
    formData.append("workshop_order_id", currentSelectedOrder?.id);
    formData.delete("send_report_to_customer");
    formData.append("send_report_to_customer", report);


    setPopUpValues({
      tat_days: input.tat_days,
      labour_value: input.labour_value,
      parts_value: input.parts_value,
      service_value: input.service_value,
      equipment_value: input.equipment_value,
      aog_fee: input.aog_fee,
      total: sum
    })

    if (valid) {

      setConfirmationPopup(true)
      setFormvalues(formData)

    }



  };
  // console.log("formValues==", formvalues)

  const handlePopUp = async (e) => {
    setMessagePopup(true)

    await CommercialServices.createNewApproval(formvalues)
      .then((res) => {
        if (res) {
          handleClose(res.success);
          setApprovalStateCommercial(res.success);
          getApprovedPosition();
          message.showToastMessage({
            message: "created successfully!!",
            variant: "success",
          });
        }
      })
      .catch(() => {
        message.showToastMessage({
          message: "Something went wrong. Try again!!",
          variant: "error",
        });
      })
      .finally(() => {
        setBusy(null);
        setMessagePopup(false)
      });

  }

  return (
    <div>
      <MROFullScreenRightDrawer open={true}>
        <AppBar position="static" className={classes.appBar} elevation={0}>
          <Toolbar>
            <IconButton
              onClick={handleClose}
              edge="start"
              className={classes.backButton}
              color="inherit"
              aria-label="back"
            >
              <BackIcon />
            </IconButton>
            <Typography variant="subtitle2" style={{ fontSize: "17px" }}>
              Create New Customer Approval
            </Typography>
          </Toolbar>
        </AppBar>

        <ApprovedPosition
          classes={classes}
          approvedPosition={props?.approvedPosition}
          handleClose={handleClose}
        />
        <CurrentLivePosition
          classes={classes}
          currentPositionData={currentPositionData}
          handleClose={handleClose}
          totalRow={totalRow}
          tatDays={approvedPosition?.data?.tat_days_count}
        />
        <form noValidate onSubmit={handleSubmit}>
          <div className={classes.wrapper}>
            <Typography
              color="primary"
              varient="h3"
              style={{ margin: "10px 0px 10px" }}
            >
              Approval Submission
            </Typography>
            <Grid style={{ marginTop: "8px" }}>
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "left",
                }}
              >
                <YesNoField
                  label="Submit current live position to customer ? *"
                  name="submit_live_pos"
                  handleToggle={handleToggle}
                  value={value}
                  error={errors.submit_live_pos}
                  setError={() => {
                    resetErrorsHandler("submit_live_pos");
                  }}
                />
              </Grid>
            </Grid>
            {value === "no" && (
              <div>
                <Typography
                  style={{ fontWeight: "bold", margin: "15px 0px 10px 0px" }}
                >
                  Revise figures as required
                </Typography>
                <Grid
                  container
                  spacing={2}
                  alignItems={"center"}
                  justify={"flex-start"}
                  xs={12}
                >
                  <Grid item>
                    <MROTextField
                      name={"tat_days"}
                      label={"TAT (Days) "}
                      value={input.tat_days}
                      defaultValue={input.tat_days}
                      type="text"
                      width="120px"
                      required
                      placeholder={"0.00"}
                      error={errors.tat_days}
                      onChange={(e) => {
                        resetErrorsHandler(e.target.name);
                        handleInput(e);
                      }}
                      InputLabelProps={{ shrink: true }}
                    ></MROTextField>
                  </Grid>
                  <Grid item>
                    <MROTextField
                      name={"labour_value"}
                      label={"Labour"}
                      width="120px"
                      value={input.labour_value}
                      type="text"
                      required
                      placeholder={"0.00"}
                      error={errors.labour_value}
                      onChange={(e) => {
                        resetErrorsHandler(e.target.name);
                        handleInput(e);
                      }}
                      InputLabelProps={{ shrink: true }}
                    ></MROTextField>
                  </Grid>
                  <Grid item>
                    <MROTextField
                      name={"parts_value"}
                      width="120px"
                      label={"Parts"}
                      type="text"
                      required
                      value={input.parts_value}
                      placeholder={"0.00"}
                      error={errors.parts_value}
                      onChange={(e) => {
                        resetErrorsHandler(e.target.name);
                        handleInput(e);
                      }}
                      InputLabelProps={{ shrink: true }}
                    ></MROTextField>
                  </Grid>
                  <Grid item>
                    <MROTextField
                      name={"service_value"}
                      width="120px"
                      label={"Services"}
                      value={input.service_value}
                      type="text"
                      required
                      placeholder={"0.00"}
                      error={errors.service_value}
                      onChange={(e) => {
                        resetErrorsHandler(e.target.name);
                        handleInput(e);
                      }}
                      InputLabelProps={{ shrink: true }}
                    ></MROTextField>
                  </Grid>
                  <Grid item>
                    <MROTextField
                      name={"equipment_value"}
                      width="120px"
                      label={"Equipment"}
                      required
                      value={input.equipment_value}
                      placeholder={"0.00"}
                      type="text"
                      error={errors.equipment_value}
                      onChange={(e) => {
                        resetErrorsHandler(e.target.name);
                        handleInput(e);
                      }}
                      InputLabelProps={{ shrink: true }}
                    ></MROTextField>
                  </Grid>
                  <Grid item>
                    <MROTextField
                      name={"aog_fee"}
                      width="120px"
                      label={"AOG Fee"}
                      required
                      value={input.aog_fee}
                      placeholder={"0.00"}
                      type="text"
                      error={errors.aog_fee}
                      onChange={(e) => {
                        resetErrorsHandler(e.target.name);
                        handleInput(e);
                      }}
                      InputLabelProps={{ shrink: true }}
                    ></MROTextField>
                  </Grid>
                  <Grid item>
                    <MROTextField
                      required
                      name={"total"}
                      width="120px"
                      label={"Total"}
                      error={errors.total}
                      value={parseFloat(sum).toFixed(2)}
                      placeholder={"0.00"}
                      defaultValue={0.0}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        className: classes.disableField,
                      }}
                      onChange={(e) => {
                        resetErrorsHandler(e.target.name);
                      }}
                    ></MROTextField>
                  </Grid>
                </Grid>
                <Box display="flex" alignItems="center">
                  <WarningIcon
                    style={{
                      color: "#FFB300",
                      // marginRight: "10px",
                      fontSize: 20,
                    }}
                  />
                  &nbsp;&nbsp;&nbsp;
                  <Typography style={{ fontSize: "14px" }}>
                    Amended Sum Charges are reflected in the report summary,
                    however, are not reflected in the itemised breakdown!
                  </Typography>
                </Box>
              </div>
            )}
            <Grid container style={{ width: "70%", marginBottom: "10px" }}>
              <MROTextField
                name={"comments"}
                label={"Comments"}
                multiline
                required
                style={{ overflowWrap: "break-word" }}
                InputProps={{ style: { height: 70, width: "100%" } }}
                inputProps={{ maxLength: 250 }}
                error={errors.comments}
                onChange={(e) => {
                  resetErrorsHandler(e.target.name);
                }}
                placeholder={
                  "Amendment Comments - add comments you wish to appear on your commercial report summary - Max 250 Characters"
                }
                InputLabelProps={{ shrink: true }}
              ></MROTextField>

              {/* <Grid style={{ width: "70"}}> */}
              <FormControlLabel
                control={
                  <PositiveSwitch
                    defaultChecked={true}
                    name="send_report_to_customer"
                    style={{ background: "transparent" }}
                    checked={report}
                    onChange={() => {
                      setReport(!report);
                    }}
                  />
                }
                label={
                  <Typography variant="body2" style={{ marginTop: "5px" }}>
                    Send report to Customer ?
                  </Typography>
                }
                labelPlacement="start"
                name={"send_report_to_customer"}
              />
            </Grid>
          </div>
          <div style={{ marginLeft: "0px" }} className={"action-buttons"}>
            <MROButton
              // loading={busy === "loading"}
              variant={"contained"}
              color={"primary"}
              type="submit"
            >
              Complete
            </MROButton>
          </div>
        </form>
        {confirmationPopup && <CreateApprovaCaution handleClose={() => {
          setConfirmationPopup(false)

        }}

          onClose={() => {
            setConfirmationPopup(false)
            setAcceptAll(false)
          }}
          open={confirmationPopup}
          popupValues={popupValues}
          setAcceptAll={setAcceptAll}
          acceptAll={acceptAll}

          handleSubmit={handlePopUp}
        />}
        {messagePopup &&
          <MessagePopup
            handleClose={() => setMessagePopup(false)}
            open={messagePopup}
            topTitle={"The Commercial Reports for the job are being prepared"}
            bottomTitle={"Please be patient, this will take a few moments"}
          />}
      </MROFullScreenRightDrawer>
    </div>
  );
}

export default ApprovalManage;
