import WorkOrderTemplateService from "../../../lib/services/api/admin/line/work_order_builder_client";

export const WORK_ORDER_ACTIONS = {
    UPDATE_SECTION_STATUS: 'update_section_status',
    UPDATE_SECTION_FIELD_STATUS: 'update_section_field_status',
    TOGGLE_LOADING: 'toggle_loading',
    TOGGLE_SECTION_LOADING: 'toggle_section_loading',
    SET_ERROR: 'set_error',
    SET_TEMPLATE_CATEGORIES: 'set_template_categories',
    SET_TEMPLATE_SECTION_DETAILS: 'set_template_section_details',
    SET_CURRENT_CATEGORY: 'current_category',
}

export function updateSectionStatus(dispatch, message, formData) {
    WorkOrderTemplateService.updateSectionStatus(formData).then((res) => {
        if (res.success) {
            dispatch({ type: WORK_ORDER_ACTIONS.UPDATE_SECTION_STATUS, response: formData });
            message.showToastMessage({ message: "Section updated successfully", variant: "success" })
        }
        else {
            dispatch({ type: WORK_ORDER_ACTIONS.SET_ERROR, response: res });
            message.showToastMessage({ message: res.errors ? res.errors.join(', ') : "Couldn't update status.Retry again!", variant: "error" })
        }
    }).catch((e) => {
        message.showToastMessage({ message: e.message || "Couldn't update status.Retry again!", variant: "error" })
        dispatch({ type: WORK_ORDER_ACTIONS.SET_ERROR, response: e.message });
    })
}

export function updateSectionStatusAll(dispatch, message, formData, cb = () => { }) {
    return WorkOrderTemplateService.updateSectionStatusAll(formData).then((res) => {
        if (res.success) {
            dispatch({ type: WORK_ORDER_ACTIONS.UPDATE_SECTION_STATUS, response: formData });
            message.showToastMessage({ message: "Section updated successfully", variant: "success" })
            cb && cb()
        }
        else {
            dispatch({ type: WORK_ORDER_ACTIONS.SET_ERROR, response: res });
            message.showToastMessage({ message: res.errors ? res.errors.join(', ') : "Couldn't update status.Retry again!", variant: "error" })
        }
    }).catch((e) => {
        message.showToastMessage({ message: e.message || "Couldn't update status.Retry again!", variant: "error" })
        dispatch({ type: WORK_ORDER_ACTIONS.SET_ERROR, response: e.message });
    })
}

export function updateSectionFieldStatus(dispatch, message, formData, handleSuccess) {
    WorkOrderTemplateService.updateFieldStatus(formData).then((res) => {
        if (res.success) {
            dispatch({ type: WORK_ORDER_ACTIONS.UPDATE_SECTION_FIELD_STATUS, response: res });
            message.showToastMessage({ message: "Status updated successfully", variant: "success" })
            handleSuccess && handleSuccess();
        }
        else {
            dispatch({ type: WORK_ORDER_ACTIONS.SET_ERROR, response: res });
            message.showToastMessage({ message: res.errors ? res.errors.join(', ') : "Couldn't update status.Retry again!", variant: "error" })
        }
    }).catch((e) => {
        dispatch({ type: WORK_ORDER_ACTIONS.SET_ERROR, response: e.message });
        message.showToastMessage({ message: e.message || "Couldn't update status.Retry again!", variant: "error" })
    })
}

export function getSectionsOfTemplate(dispatch, message, formData, cb = () => { }, noLoader) {
    !noLoader && dispatch({ type: WORK_ORDER_ACTIONS.TOGGLE_LOADING });
    WorkOrderTemplateService.getSectionsOfTemplate(formData).then((res) => {
        if (res.success) {
            dispatch({ type: WORK_ORDER_ACTIONS.SET_TEMPLATE_CATEGORIES, response: res.section_list });
            cb && cb()
        }
        else {
            dispatch({ type: WORK_ORDER_ACTIONS.TOGGLE_LOADING });
        }
    }).catch((e) => {
        dispatch({ type: WORK_ORDER_ACTIONS.TOGGLE_LOADING });
        message.showToastMessage({ message: e.message || "Something went wrong. Try again!", variant: "error" })
    })
}

export function getSectionDetailsOfTemplate(dispatch, message, formData) {
    dispatch({ type: WORK_ORDER_ACTIONS.TOGGLE_SECTION_LOADING });
    WorkOrderTemplateService.getSectionsDetailsOfTemplate(formData).then((res) => {
        if (res.success) {
            dispatch({ type: WORK_ORDER_ACTIONS.SET_TEMPLATE_SECTION_DETAILS, response: (res.section_list && res.section_list.sub_sections) ? res.section_list.sub_sections : [] })
        }
        else {
            dispatch({ type: WORK_ORDER_ACTIONS.TOGGLE_SECTION_LOADING });
        }
    }).catch((e) => {
        dispatch({ type: WORK_ORDER_ACTIONS.TOGGLE_SECTION_LOADING });
        message.showToastMessage({ message: e.message || "Something went wrong. Try again!", variant: "error" })
    })
}


export function setCurrentCategory(dispatch, category) {
    dispatch({ type: WORK_ORDER_ACTIONS.SET_CURRENT_CATEGORY, response: category });
}

const WorkOrderAction = {
    getSectionDetailsOfTemplate,
    updateSectionStatus, updateSectionStatusAll,
    updateSectionFieldStatus,
    getSectionsOfTemplate,
    setCurrentCategory,
}
export default WorkOrderAction;
