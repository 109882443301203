import React, { useState, useEffect, useContext, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box, Grid, Divider, FormControl, Select, InputBase, MenuItem, Paper, Button, FormControlLabel, Menu } from "@material-ui/core";

import HandOver from "./Handover";
import Vehicles from "./Vehicles";
import VehiclePass from "./VehiclePass";
import StationChecks from "./StationChecks";
import Stores from "./Stores";
import SearchBarWithFilters from "components/search_with_filters";
import { StationDashboardServices } from "lib/services/api/dashboard/station_compliance";
import { getOrganisationId } from "lib/utils/common_utils";
import ChartLoader from "../../Workshops/Storage/ChartLoader";
import moment from "moment";
import { MaintenanceServices } from 'lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation';
import { ToastMessageContext } from "lib/contexts/message_context";
import ArrowDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import PositiveSwitch from "components/form_components/switch";
import _without from "lodash/without";

function StationCompliance(props) {
  const classes = useStyles();
  const [complianceData, setComplianceData] = useState([])
  const [vehicleInspectionData, setVehicleInspectionData] = useState({})
  const [airSidePassData, setAirSidePassData] = useState({})
  const [toolingData, setToolingData] = useState({})
  const [shellLifeData, setShellLifeData] = useState({})
  const [filterData, setFilterData] = useState({ days: 'seven' });
  const [bases, setBases] = useState([]);
  const [loading, setloading] = useState(false)
  const [anchorBase, setAnchorBase] = useState(null);
  const [baseButton, setBaseButton] = useState(false)
  const [selectedBase, setSelectedBase] = useState([]);
  const [checkedState, setCheckedState] = useState(new Array(bases.length).fill(false));


  const message = useContext(ToastMessageContext);
  //Station Check
  const [stationCheckData, setStationCheckData] = useState([])
  const [stationCheckDetails, setStationCheckDetails] = useState(false)
  const [stationCheckLineData, setStationCheckLineData] = useState([])
  const [lineValues, setLineValues] = useState([])
  const [xAxisValue, setXAxisValue] = useState([])

  //Handover Check


  const [handOverBarData, setHandOverBarData] = useState([])
  const [handOverLineData, setHandOverLineData] = useState([])
  const [handOverLineValue, setHandOverLineValue] = useState([])
  const [handOverXAxisValue, setHandOverXAxis] = useState([])
  const [filterHandOverData, setFilterHandOverData] = useState({ days: 'seven' });
  const [handOverDetails, setHandOverDetails] = useState(false)
  // Vehicle checks
  const [vehicleBarData, setVehicleBarData] = useState([])
  const [vehicleLineData, setVehicleLineData] = useState([])
  const [vehicleLineValue, setVehicleLineValue] = useState([])
  const [vehicleXAxisValue, setVehicleXAxis] = useState([])
  const [filterVehicleData, setFilterVehicleData] = useState({ days: 'seven' });
  const [vehicleDetails, setVehicleDetails] = useState(false)



  //statin check api
  const getStationChecksData = async () => {
    const params = {

      organisation_id: getOrganisationId(),
      ...filterData
    }
    setStationCheckDetails(true)
    selectedBase.forEach((item, index) => params[`base_station_id[${[index]}]`] = item)
    await StationDashboardServices.getStationChecks(params).then((response) => {
      setStationCheckData(response.data.bar)
      setStationCheckLineData(response.data.line)
    }).catch(() => {
      message.showToastMessage({
        message: "Something went wrong",
        variant: "error",
      });
    }).finally(() => {
      setStationCheckDetails(false)
    })
  }
  useEffect(() => {
    getStationChecksData()

  }, [filterData, selectedBase])

  const getStations = async () => {
    const params = {

      organisation_id: getOrganisationId(),
      // ...filterData
    }
    setloading(true)
    selectedBase.forEach((item, index) => params[`base_station_id[${[index]}]`] = item)
    await StationDashboardServices.getStationCompliance(params).then((response) => {

      setVehicleInspectionData(response.data.vehicle_inspections[0])
      setAirSidePassData(response.data.airside_pass[0])
      setToolingData(response.data.tooling[0])
      setShellLifeData(response.data.shelf_life_parts[0])
    }).catch(() => {

    }).finally(() => {
      setloading(false)
    })
  }

  useEffect(() => {
    getStations()

  }, [selectedBase])

  //handover api
  const getHandOverData = async () => {
    const params = {

      organisation_id: getOrganisationId(),
      ...filterHandOverData
    }
    setHandOverDetails(true)
    selectedBase.forEach((item, index) => params[`base_station_id[${[index]}]`] = item)
    await StationDashboardServices.getHandOverDetails(params).then((response) => {
      setHandOverBarData(response.data.bar)
      setHandOverLineData(response.data.line)
    }).catch(() => {
      message.showToastMessage({
        message: "Something went wrong",
        variant: "error",
      });
    }).finally(() => {
      setHandOverDetails(false)
    })
  }
  useEffect(() => {
    getHandOverData()

  }, [filterHandOverData, selectedBase])

  //VehicleChecks

  const getVehicleCheckData = async () => {
    const params = {

      organisation_id: getOrganisationId(),
      ...filterVehicleData
    }
    setVehicleDetails(true)
    selectedBase.forEach((item, index) => params[`base_station_id[${[index]}]`] = item)
    await StationDashboardServices.getVehicleChecksDetails(params).then((response) => {
      setVehicleBarData(response.data.bar)
      setVehicleLineData(response.data.line)
    }).catch(() => {
      message.showToastMessage({
        message: "Something went wrong",
        variant: "error",
      });
    }).finally(() => {
      setVehicleDetails(false)
    })
  }
  useEffect(() => {
    getVehicleCheckData()

  }, [filterVehicleData, selectedBase])



  const ITEM_HEIGHT = 78;
  const getBaseStation = async () => {
    const params = {
      organisation_id: getOrganisationId(),
      order_by: 'station'
    }
    MaintenanceServices.getBaseStation(params)
      .then((res) => {
        if (res) {
          setBases(res.data);
        }

      })
      .catch((err) => {
        message.showToastMessage({
          message: "Something went wrong. Try again!",
          variant: "error",
        });

      });
  };



  useEffect(() => {
    getBaseStation()
  }, [])





  const handleBaseClick = (event) => {
    setAnchorBase(event.currentTarget);
    setBaseButton(!baseButton)
  };
  const filterChangeHandler = (e) => {
    const { name, value, checked } = e.target
    console.log("filter====", name, value, checked)
    setSelectedBase((prevState) => [...prevState, value])
    if (!checked) {
      setSelectedBase((current) => _without(current, value));
    }

  }

  useMemo(() => {
    const line = []
    const date = []
    if (stationCheckLineData != []) {
      for (let l = 0;l < stationCheckLineData.length;l++) {
        line.push(parseInt(stationCheckLineData[l].count))
        date.push(moment(stationCheckLineData[l].date).format("MMM D"))
        setLineValues(line)
        setXAxisValue(date)
      }

    }

  }, [stationCheckLineData])

  useMemo(() => {
    const lineHandOver = []
    const dateHandOver = []
    if (handOverLineData != []) {
      for (let l = 0;l < handOverLineData.length;l++) {
        lineHandOver.push(parseInt(handOverLineData[l].count))
        dateHandOver.push(moment(handOverLineData[l].date).format("MMM D"))
        setHandOverLineValue(lineHandOver)
        setHandOverXAxis(dateHandOver)
      }

    }

  }, [handOverLineData])


  useMemo(() => {
    const lineVehicle = []
    const dateVehicle = []
    if (vehicleLineData != []) {
      for (let l = 0;l < vehicleLineData.length;l++) {
        lineVehicle.push(vehicleLineData[l].count === null ? 0 : vehicleLineData[l].count)
        dateVehicle.push(moment(vehicleLineData[l].date).format("MMM D"))
        setVehicleLineValue(lineVehicle)
        setVehicleXAxis(dateVehicle)
      }

    }

  }, [vehicleLineData])


  const handleFilterStationChange = (keyItem, value) => {
    setFilterData((prevState) => ({ ...prevState, [keyItem]: value }));
  };

  return (
    <div className={classes.root} >
      <div  >
        <SearchBarWithFilters noSearch padding={'4px 16px'}>

          <FormControl className={"filter"} style={{ marginLeft: '-11px' }}>
            <Button variant={anchorBase ? "contained" : "text"} size="small" aria-haspopup="true" onClick={handleBaseClick} endIcon={baseButton ? <ArrowDropUp /> : <ArrowDown />} style={{ fontSize: "14px", backgroundColor: anchorBase ? '#efefef' : "" }}>
              {selectedBase.length > 0 ? `Base(${selectedBase.length})` : 'Base(All)'}

            </Button>
            <Menu
              id="Base"
              anchorEl={anchorBase}
              keepMounted
              open={Boolean(anchorBase)}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
              onClose={() => {
                setAnchorBase(null)
                setBaseButton(!baseButton)
              }}
              // anchorOrigin={{
              //     vertical: 'bottom',
              //     horizontal: 'left',
              // }}
              style={{ marginTop: '0px' }}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: '39ch',
                  position: 'absolute',
                  top: '260px',
                },
              }}
            >
              {
                bases?.map((base, index) => {
                  return <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={1}></Grid>
                      <Grid item xs={8} style={{
                        display: "flex", alignItems: "center", fontSize: "14px",

                      }}>
                        <label>{base.station}</label>
                      </Grid>
                      <Grid item xs={3}>
                        <FormControlLabel
                          style={{ alignSelf: "flex-end" }}
                          control={<PositiveSwitch
                            checked={checkedState[index]}
                            value={base.id}
                            // onChange={(e) => handleChange(e, option.id)}
                            onChange={(e) => { filterChangeHandler(e, index) }}
                            name={base.station} />}
                        // name="base"
                        />
                      </Grid>
                    </Grid>
                    <Grid><Divider variant="middle" /></Grid>
                  </Box>
                }

                )}
            </Menu>
          </FormControl>

        </SearchBarWithFilters>
      </div>
      <Box >
        <Typography variant='h6' color='primary'>Overview</Typography>
        <Divider />
        <Box mt={2}>
          <Grid container spacing={2} justify="space-between">
            <Grid item xs={6}>

              <Box mt={2}>


                <Paper style={{ width: '100%', height: '394px' }}>
                  <Box width="100%" display="flex" p={2}>
                    <Box width="98%">

                      <Typography gutterBottom={20} variant="subtitle2" color='primary'>Station Checks</Typography>
                      <FormControl className={"filter"} style={{
                        float: 'right',
                        marginTop: '-32px',
                        marginBottom: ' -29px'
                      }}>
                        <Select
                          onChange={(e) =>
                            handleFilterStationChange("days", e.target.value)
                          }
                          defaultValue={"seven"}
                          // value={"Days"}
                          // displayEmpty
                          input={<InputBase />} >
                          <MenuItem value={"seven"} >7 Days</MenuItem>
                          <MenuItem value={"fourteen"}>14 Days</MenuItem>
                          <MenuItem value={"thirty"}> 30 Days</MenuItem>
                        </Select>
                      </FormControl>
                      <Grid>
                        {stationCheckDetails ? <ChartLoader /> : <StationChecks barData={stationCheckData} loading={stationCheckDetails} lineData={lineValues} xAxisValue={xAxisValue} />}

                      </Grid>
                    </Box>
                  </Box>
                </Paper>


              </Box>



            </Grid>

            <Grid item xs={6}>
              <Box mt={2}>


                <Paper style={{ width: '100%', height: '394px' }}>
                  <Box width="100%" display="flex" p={2}>
                    <Box width="98%">

                      <Typography gutterBottom={20} variant="subtitle2" color='primary' >Handovers</Typography>
                      <FormControl className={"filter"} style={{
                        float: 'right',
                        // marginLeft: '31rem',
                        marginTop: '-32px',
                        marginBottom: ' -29px'
                      }}>
                        <Select
                          onChange={(e) =>
                            setFilterHandOverData((prevState) => ({ ...prevState, days: e.target.value }))

                          }
                          defaultValue={"seven"}
                          // value={"Days"}
                          // displayEmpty
                          input={<InputBase />} >
                          <MenuItem value={"seven"} >7 Days</MenuItem>
                          <MenuItem value={"fourteen"}>14 Days</MenuItem>
                          <MenuItem value={"thirty"}> 30 Days</MenuItem>
                        </Select>
                      </FormControl>
                      <Grid>
                        {handOverDetails ? <ChartLoader /> : <HandOver barData={handOverBarData} lineData={handOverLineValue} xAxisValue={handOverXAxisValue} />}

                      </Grid>
                    </Box>
                  </Box>
                </Paper>


              </Box>
            </Grid>

          </Grid>
        </Box >
        <Box mt={3} mb={5}>
          <Grid container spacing={1} >
            <Grid item xs={6} style={{ paddingRight: '7px' }}>
              <Box mt={2}>


                <Paper style={{ width: '100%', height: '350px' }}>
                  <Box width="100%" display="flex" p={2}>
                    <Box width="98%">

                      <Typography gutterBottom={20} variant="subtitle2" color='primary'>Vehicle Checks</Typography>
                      <FormControl className={"filter"} style={{
                        float: 'right',
                        marginTop: '-32px',
                        marginBottom: ' -29px'
                      }}>
                        <Select
                          onChange={(e) =>
                            setFilterVehicleData((prevState) => ({ ...prevState, days: e.target.value }))

                          }
                          defaultValue={"seven"}
                          // value={"Days"}
                          // displayEmpty
                          input={<InputBase />} >
                          <MenuItem value={"seven"} >7 Days</MenuItem>
                          <MenuItem value={"fourteen"}>14 Days</MenuItem>
                          <MenuItem value={"thirty"}> 30 Days</MenuItem>
                        </Select>
                      </FormControl>
                      <Grid>
                        {vehicleDetails ? <ChartLoader /> : <Vehicles barData={vehicleBarData} lineData={vehicleLineValue} xAxisValue={vehicleXAxisValue} />}

                      </Grid>
                    </Box>
                  </Box>
                </Paper>


              </Box>


            </Grid>
            <Grid item xs={6} style={{ paddingLeft: '10px' }}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <VehiclePass inspectionData={vehicleInspectionData} airSidePass={airSidePassData} loading={loading} />
                </Grid>
                <Grid item xs={6}>
                  <Stores tooling={toolingData} shellLife={shellLifeData} />
                </Grid>
              </Grid>

            </Grid>

          </Grid>
        </Box>
        {/* <Box mt={3} width="100%">
          <Grid container spacing={-3}>
            <TemperatureHumidity selectedBase={selectedBase} />
          </Grid>
        </Box> */}
      </Box >
    </div >
  );
}

const useStyles = makeStyles((theme) => ({
  alert: {
    display: "flex",
    justifyContent: "center",
    color: theme.palette.warning.main,
  },
  root: {
    width: "94%",
    margin: "2% 3%",
    '&.MuiStepConnector-root': {
      borderTopWidth: '10px'
    }
  },
}));

export default React.memo(StationCompliance)