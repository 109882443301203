import React from "react";
import { Typography, Box, makeStyles, IconButton } from "@material-ui/core";
import MROButton from "../../../components/buttons";
import ButtonGroupNavigation from "components/button_group_navigation";
import { Route, Switch } from "react-router-dom";
import FullscreenIcon from '@material-ui/icons/Fullscreen';


import Production from "./Production";
import Storage from "./Storage";
import Maintenances from "./Maintenance/WorkshopMaintenance";
const useStyles = makeStyles(theme => ({
    subNavigation: {
        margin: '2% 3%',
        width: '85%',
    }
}));
const buttons = [
    { name: "Maintenance", redirect: "/org/dashboard/workshop/maintenance" },
    { name: "Production", redirect: "/org/dashboard/workshop/production" },
    { name: "Storage", redirect: "/org/dashboard/workshop/storage" },
];

function Workshop(props) {
    const classes = useStyles();
    return (
        <div>
            <div className={classes.subNavigation}>
                <ButtonGroupNavigation buttons={buttons} {...props} />
            </div>
            <Switch>
                <Route exact path={'/org/dashboard/workshop/maintenance'} component={rprops => <Maintenances {...rprops} {...props} />} />
                <Route exact path={'/org/dashboard/workshop/production'} component={rprops => <Production {...rprops} {...props} />} />
                <Route exact path={'/org/dashboard/workshop/storage'} component={rprops => <Storage {...rprops} {...props} />} />
                <Route component={Maintenances} />
            </Switch>
        </div>

    )
}

export default Workshop