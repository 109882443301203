import React from "react";

const Maintenance = ({ fill }) => (
  <svg id="settings" xmlns="http://www.w3.org/2000/svg" width="125" height="18" viewBox="0 0 135 135">
    <g id="Group_5698" data-name="Group 5698">
      <g id="Group_5697" data-name="Group 5697">
        <path id="Path_19816" data-name="Path 19816" d="M120.15,56.025a57.279,57.279,0,0,0-7.425-17.55l6.75-14.175-9.45-9.45L95.85,21.6a52.072,52.072,0,0,0-16.875-6.75L74.25,0H60.75l-5.4,14.85c-6.075,1.35-11.475,4.05-16.875,7.425L24.3,15.525,14.85,24.3,21.6,38.475A52.072,52.072,0,0,0,14.85,55.35L0,60.75v13.5l14.85,5.4A63.016,63.016,0,0,0,21.6,96.525L14.85,110.7l9.45,9.45,14.175-6.75c5.4,3.375,10.8,6.075,16.875,7.425L60.75,135h13.5l5.4-14.85c2.025-.675,4.725-1.35,6.75-2.025l-10.8-10.8C49.95,112.05,27,93.15,27,67.5A40.619,40.619,0,0,1,67.5,27c24.975,0,43.875,22.275,39.825,45.9L118.8,84.375c.675-2.025,1.35-3.375,1.35-5.4L135,74.25V60.75Z" fill={fill} />
      </g>
    </g>
    <g id="Group_5700" data-name="Group 5700" transform="translate(40.729 39.484)">
      <g id="Group_5699" data-name="Group 5699">
        <path id="Path_19817" data-name="Path 19817" d="M246.041,227.712l-39.825-39.825c9.45-20.925-10.125-43.2-31.725-37.125l12.15,12.15c5.4,5.4,5.4,14.175,0,18.9-4.725,5.4-14.175,5.4-18.9,0l-12.15-11.475c-6.075,18.9,13.5,40.5,35.775,31.725l39.825,39.825a9.787,9.787,0,0,0,14.175,0C249.416,238.512,249.416,231.762,246.041,227.712Z" transform="translate(-154.47 -149.745)" fill={fill} />
      </g>
    </g>
  </svg>
)

export default Maintenance