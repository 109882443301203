import { Typography, Grid } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { styled } from "@material-ui/styles";
import { Paper } from "@material-ui/core";
import tableIcons from "components/universal/table_attributes";
import MaterialTable from "material-table";
import { useReducer } from "react";
import tableReducer, { INITIAL_TABLE_STATE } from "reducers/table_reducer";
import greenCheckIcon from "assets/green check.png";
import TableAction from "actions/table_actions";
import { ToastMessageContext } from "lib/contexts/message_context";
import { WorkshopContext } from "../WorkshopContext/WorkshopContext";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: "2%",
  },
  appBar: {
    backgroundColor: "#fff",
    color: "#000000CC",
    boxShadow: "0px 3px 6px #0000001A",
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  main: {
    marginRight: theme.spacing(2),
  },
  generalDetail: {
    backgroundColor: theme.palette.primary.main,
    padding: "2%",
    color: "#fff",
    borderRadius: "5px 5px 0 0",
  },
  content: {
    backgroundColor: "#fff",
    padding: "3% 6%",
    borderRadius: "0 0 5px 5px",
    minHeight: "fit-content",
  },
  titleTile: {
    padding: "3px 20px",
    fontSize: "13px",
    fontWeight: 500,
    marginBottom: "20px",
  },
  inputField: {
    maxWidth: "328px",
  },
  actionButtons: {
    textAlign: "right",
    marginTop: "30px",
    "& button": {
      // marginRight: "20px",
    },
  },
  input1: {
    height: 200,
    padding: 20,
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function ApprovedPosition(props) {
  //   const classes = useStyles();
    const { approvedPosition } = props;
  const { setTatCount } = useContext(WorkshopContext)
  // const {message} = useContext(ToastMessageContext)
  const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
  const { page, totalCount, pageSize, options, data } = tableState;

  const tableOptions = {
    ...options,
    page: page,
    total: totalCount,
    pageSize: pageSize,
    search: false,
    toolbar: false,
    paging: false,
    rowStyle: { height: 42 },
    headerStyle:{
      height:"42px"
    }
  };

  const handleTatCount = (rowData) =>{
    setTatCount(rowData?.tat_days_count)
      return (<span>{rowData?.tat_days_count}</span>)
  }

  const columns = [
    {
      title: "Approval",
      field: "approval_number",
      headerStyle: {
        paddingLeft: "22px",
      },
      cellStyle: {
        width: "40%",
        paddingLeft: "22px",
      },
      render: (rowData) => rowData?.approval_number === 0 ? "Quote" : rowData?.approval_number
    },
    {
      title: "Acceptance",
      headerStyle: {
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: () => {
        return (
          <div style={{ backgroundColor: "transparent", color: "green" }}>
            <img width="25px" height="25px" src={greenCheckIcon} />
          </div>
        );
      },
    },
    {
      title: "TAT",
      field: "tat_days_count" || "0.00",
      render: (rowData) => handleTatCount(rowData)
    },
    {
      title: "Labour",
      field: "labour_value" || "0.00" ,
      render: (rowData) => rowData.labour_value != null ? rowData.labour_value : "0.00"
    },
    {
      title: "Parts",
      field: "parts_value" || "0.00",
      render: (rowData) => rowData.parts_value != null ? rowData.parts_value : "0.00"
    },
    {
      title: "Services",
      field: "service_value" || "0.00",
      render: (rowData) => rowData.service_value != null ? rowData.service_value : "0.00"
    },
    {
      title: "Equipment",
      field: "equipment_value" || "0.00",
      render: (rowData) => rowData.equipment_value != null ? rowData.equipment_value : "0.00"
    },
    {
      title: "AOG Fee",
      field: "aog_fee" || "0.00",
      render: (rowData) => rowData.aog_fee != null ? rowData.aog_fee : "0.00"
    },
    {
      title: "Total",
      field: "total" || "0.00",
      render: (rowData) => rowData.total != null ? rowData.total : "0.00"
    },
  ];

  return (
    <div className={props?.classes?.wrapper}>
      <Grid>
        <Typography
          color="primary"
          varient="h3"
          style={{ marginBottom: "10px" }}
        >
          Approved Position
        </Typography>
      </Grid>
      <MaterialTable
          style={{
            boxShadow: "0px 1px 3px #00000033" ,
            //   marginTop: "10px",
          }}
          icons={tableIcons}
          title={""}
          columns={columns}
          data={approvedPosition?.data || []}
          options={tableOptions}
          components={{
            Toolbar: () => null,
          }}
          localization={{
            toolbar: {},
            body: {
              emptyDataSourceMessage: "No History found",
              filterRow: {
                filterTooltip: "Filter",
              },
            },
          }}
          onChangePage={(page) => {
            TableAction.setPage(dispatch, page);
          }}
          onChangeRowsPerPage={(pageSize) => {
            TableAction.setPageSize(dispatch, pageSize);
          }}
          totalCount={totalCount}
          page={page}
          onRowClick={(row) => {}}
        />
    </div>
  );
}

export default ApprovedPosition;
