import React, { useEffect, useReducer, useState } from 'react';
import Button from '@material-ui/core/Button';
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import PasswordVisible from "@material-ui/icons/Visibility";
import PasswordHide from "@material-ui/icons/VisibilityOff";
import { validateEmail } from "../../lib/utils/common_utils";
import UserIcon from "@material-ui/icons/PersonOutlined";
import LockIcon from "@material-ui/icons/LockOutlined";
import useAuthStyles from "./auth_styles";
import authReducer, { INITIAL_STATE } from "../../reducers/auth_reducer";
import AuthActions from "../../actions/auth_actions";
import AuthClient from '../../lib/services/api/auth_services';
import TwoFactorAuthActions from 'actions/Two_factor_auth';
const { detect } = require('detect-browser');


const orgLogo = sessionStorage.getItem('logo') || "";

export default function SignIn(props) {
    const classes = useAuthStyles();
    const [authState, dispatch] = useReducer(authReducer, INITIAL_STATE);
    const { loading, error, mask } = authState;

    const [userBrowser, setUserBrowser] = useState('')

    useEffect(() => {
        const browser = detect();
        setUserBrowser(browser.name)
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const email = formData.get('eid');
        const password = formData.get('secret_phrase');
        formData.append('browser', userBrowser)

        if (!validateEmail(email.trim())) {
            AuthActions.setError(dispatch, { email: "invalid format, provide valid email" });
            return;
        }
        const params = {
            email: email,
            password: password,
            application: 'web',
            browser: userBrowser,
            history: props.history,
        }
        TwoFactorAuthActions.userSignIn(dispatch, { email: email, success: false, password: password, application: 'web', browser: userBrowser }, params);

        // if (process.env.REACT_APP_ENV === "development") {
        //     AuthActions.userSignIn(dispatch, { email: email, password: password, verified_otp: true, success: true, application: 'web', browser: userBrowser })
        // } else {
        //     TwoFactorAuthActions.userSignIn(dispatch, { email: email, success: false, password: password, application: 'web', browser: userBrowser }, params);
        // }
    };

    useEffect(() => {
        //AuthActions.userSignOut(dispatch)
        const queryString = window.location.search;
        queryString != '' && loginViaSuperAdmin();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loginViaSuperAdmin = (queryString) => {
        const urlParams = new URLSearchParams(window.location.search);
        const accessToken = urlParams.get('access-token')
        const client = urlParams.get('client')
        const uId = urlParams.get('uid')
        let formData = new FormData();
        formData.append("organisation_id", urlParams.get('orgId'));
        const headers = {
            "access-token": accessToken,
            "client": client,
            "uid": uId,
            "cache-control": "max-age=0, private, must-revalidate",
            "content-type": "application/json; charset=utf-8",
            "expiry": "1606917294",
            "token-type": "Bearer"
        }
        sessionStorage.setItem('authorization_headers', JSON.stringify(headers));
        setTimeout(() => {
            AuthClient.postOrgData(formData).then((res) => {
                sessionStorage.setItem("authState", JSON.stringify(res));
                window.location.href = '/home';
            })
        }, 1000)
    }
    const clearError = () => {
        AuthActions.setError(dispatch, { email: false });
    }
    const handleMasked = () => {
        AuthActions.changeFieldMask(dispatch);
    }

    return (
        <div className={classes.wrapper}>
            <div className={classes.overlay}></div>
            <div style={{ zIndex: '99' }}>
                <div className={classes.orgLogo}>
                    <img src={orgLogo} />
                </div>
                <form spellcheck="false" className={classes.form} onSubmit={handleSubmit} autoComplete="custom-form">
                    {/* <Typography component={"h6"} variant={"h6"}>Login</Typography> */}
                    <FormControl fullWidth className={classes.formField}>
                        <OutlinedInput
                            required
                            name="eid"
                            type="email"
                            autoComplete="new-password"
                            placeholder="Username"
                            autoFocus
                            error={!!error.email}
                            onFocus={clearError}
                            startAdornment={<InputAdornment position="start">
                                <UserIcon htmlColor={"#ffffff80"} />
                            </InputAdornment>
                            }
                        />
                        {!!error.email && <FormHelperText >{error.email ? error.email : null}</FormHelperText>}
                    </FormControl>
                    <FormControl fullWidth className={classes.formField}>
                        <OutlinedInput
                            required
                            startAdornment={<InputAdornment position="start">
                                <LockIcon htmlColor={"#ffffff80"} />
                            </InputAdornment>
                            }
                            endAdornment={
                                <InputAdornment position="end">
                                    {!mask ? <PasswordVisible htmlColor={"#ffffff80"} className={classes.eye} onClick={handleMasked} /> :
                                        <PasswordHide htmlColor={"#ffffff80"} className={classes.eye} onClick={handleMasked} />}
                                </InputAdornment>
                            }
                            name="secret_phrase"
                            type={mask ? "password" : "text"}
                            placeholder={"Password"}
                            autoComplete="new-password"
                            onFocus={clearError}
                        />
                    </FormControl>
                    <div style={{ margin: '20px 0' }}>
                        <span style={{ cursor: 'pointer' }} onClick={() => props.history.push('/auth/forgot-password')}>
                            Forgot password?
                        </span>
                    </div>
                    <Button
                        fullWidth
                        type="submit"
                        className={classes.submit}
                        disabled={loading}
                    >
                        Sign In
                    </Button>
                    <div style={{ textAlign: "center", marginTop: '15px' }}>
                        <span style={{ marginTop: '20px', color: 'red' }}>{error.message || null}</span>
                    </div>
                </form>
            </div>
            <div className={classes.brandLogo}>
                <img src={'/mro-pro-logo.png'} alt={"mro pro logo"} />
            </div>
        </div>
    );
}