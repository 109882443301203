import React, { useContext, useEffect, useState } from "react";
import MROFullScreenRightDrawer from "components/fullwidth_dialog";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import Typography from "@material-ui/core/Typography";
import MROTextField from "components/form_components/TextField";
import FileUpload from "components/form_components/fileUpload";
import QuantityCounter from "components/quantity_counter";
import MROButton from "components/buttons";
import MenuItem from "@material-ui/core/MenuItem";
import { ToastMessageContext } from "lib/contexts/message_context";
import { MaintenanceServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation";
import { InspectionServices } from "lib/services/api/operaitons/WorkShop/Inspection/InspectionService";
import DeleteIcon from "@material-ui/icons/Delete";
import { Box, FormControlLabel } from "@material-ui/core";
import { ICON_COLOUR } from "lib/constants/style_constants";
import DeleteAction from "./DeleteAction";
import { formatUTC, getOrganisationId } from "lib/utils/common_utils";
import PositiveSwitch from "components/form_components/switch";
import DateField from "views/Accounts/Workshop/Maintenance/ManageInvoice/DateField";
import { CalenderMaintenanceService } from "lib/services/api/operaitons/WorkShop/Storage/CalenderMaintenanceService";
import { validateFormData } from "lib/utils/helperFunctions";
import NumericIncrement from "views/Operations/LineMaintenance/WorkOrder/FieldComponents/numeric_increment";
import moment from "moment";


const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: "3%",
  },
  appBar: {
    backgroundColor: "#fff",
    color: "#000000CC",
    boxShadow: "0px 3px 6px #0000001A",
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  wrap: {
    borderRadius: "5px",
    backgroundColor: "#fff",
    padding: theme.spacing(3),
    boxShadow: "0px 1px 4px #0000001A",
  },
  paper: {
    borderRadius: "5px",
    backgroundColor: "#F5F5F5",
    padding: theme.spacing(2),
    boxShadow: "0px 1px 4px #0000001A",
  },
  partValue: {
    fontSize: "14px",
    color: "#4D4F5C",
    padding: "2px",
    display: "flex",
    alignItems: "center",
  },
  partKey: {
    "& p": {
      fontSize: "14px",
      padding: "1px",
    },
  },
  partCard: {
    borderRadius: "8px",
    padding: "17px 24px",
    "& p": {
      fontSize: "14px",
      padding: "5px",
    },
  },
  sec1: {
    borderRadius: "10px",
    backgroundColor: "#fff",
    padding: theme.spacing(5),
    boxShadow: "0px 1px 4px #0000001A",
  },
  actionButtons: {
    paddingTop: "20px",
    paddingBottom: "33px",
    textAlign: "right",
    marginRight: "20px",
    "& button:not(:last-child)": {
      marginRight: "20px",
    },
  },
}));

function EditActionInfo(props) {
  const classes = useStyles();
  const {
    handleClose,
    currentSelectedInfo,
    closeHandler,
    getAllActions,
    selectedAction,
    getSelectedAction,
    setSelectedRowId,
    currentSelectedID,
    getAllActionStages,
    getActions,
    data
  } = props;

  const message = useContext(ToastMessageContext);
  const [dropDownData, setDropdownData] = useState([]);
  const [errors, setErrors] = useState({});
  const [ataChapter, setAtaChapter] = useState(null);
  const [mechAuth, setMechAuth] = useState(null);
  const [inspAuth, setInspAuth] = useState(null);
  const [techData, setTechData] = useState(null);
  const [busy, setBusy] = useState(null);
  const [action, setAction] = useState(null);
  const [qty, setQty] = useState(null);
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState(null);
  const [desc, setDesc] = useState(null);
  const [maintFile, setMaintFile] = useState([]);
  const [state, setState] = useState({ man_hours: '' });
  const [fieldZero, setFieldZero] = useState({ id: null, name: null });
  const [fieldOne, setFieldOne] = useState({ id: null, name: null });
  const [fieldTwo, setFieldTwo] = useState({ id: null, name: null });
  const [fieldThree, setFieldThree] = useState({ id: null, name: null });
  const [fieldFour, setFieldFour] = useState({ id: null, name: null });
  const [fieldFive, setFieldFive] = useState({ id: null, name: null });
  const [fieldSix, setFieldSix] = useState({ id: null, name: null });
  const [fieldSeven, setFieldSeven] = useState({ id: null, name: null });
  const [fieldEight, setFieldEight] = useState({ id: null, name: null });
  const [fieldNine, setFieldNine] = useState({ id: null, name: null });
  const [date, setDate] = useState(null)
  const [updatedData, setUpdatedData] = useState({})
  const [loading, setLoading] = useState(false)
  const [showFrequency, setShowFrequency] = useState(false)

  useEffect(() => {
    if (selectedAction?.ata_chapter_id !== undefined) {
      setAtaChapter(selectedAction?.ata_chapter_id);
    }
    if (selectedAction?.mech_auth_id !== undefined) {
      setMechAuth(selectedAction?.mech_auth_id);
    }
    if (selectedAction?.insp_auth_id != undefined) {
      setInspAuth(selectedAction?.insp_auth_id);
    }
    setTitle(selectedAction?.title);
    setDesc(selectedAction?.description);
    if (selectedAction?.tech_data_ref) {
      setTechData(selectedAction?.tech_data_ref)
    }
    if (selectedAction?.action_due_date
    ) {
      setDate(formatUTC(selectedAction?.action_due_date)
      )

    }

    if (selectedAction?.sub_fields != undefined && selectedAction?.sub_fields != []) {
      setFieldZero(
        {
          id: selectedAction?.sub_fields[0]?.id,
          name: selectedAction?.sub_fields[0]?.field_name === "null"
            ? ""
            : selectedAction?.sub_fields[0]?.field_name
        }
      );
      setFieldFive(
        {
          id: selectedAction?.sub_fields[1]?.id,
          name: selectedAction?.sub_fields[1]?.field_name === "null"
            ? ""
            : selectedAction?.sub_fields[1]?.field_name
        }
      );
      setFieldOne(
        {
          id: selectedAction?.sub_fields[2]?.id,
          name: selectedAction?.sub_fields[2]?.field_name === "null"
            ? ""
            : selectedAction?.sub_fields[2]?.field_name
        }
      );

      setFieldTwo(
        {
          id: selectedAction?.sub_fields[4]?.id,
          name: selectedAction?.sub_fields[4]?.field_name === "null"
            ? ""
            : selectedAction?.sub_fields[4]?.field_name
        }
      );
      setFieldThree(
        {
          id: selectedAction?.sub_fields[6]?.id,
          name: selectedAction?.sub_fields[6]?.field_name === "null"
            ? ""
            : selectedAction?.sub_fields[6]?.field_name
        }
      );
      setFieldFour(
        {
          id: selectedAction?.sub_fields[8]?.id,
          name: selectedAction?.sub_fields[8]?.field_name === "null"
            ? ""
            : selectedAction?.sub_fields[8]?.field_name
        }
      );

      setFieldSix(
        {
          id: selectedAction?.sub_fields[3]?.id,
          name: selectedAction?.sub_fields[3]?.field_name === "null"
            ? ""
            : selectedAction?.sub_fields[3]?.field_name
        }
      );
      setFieldSeven(
        {
          id: selectedAction?.sub_fields[5]?.id,
          name: selectedAction?.sub_fields[5]?.field_name === "null"
            ? ""
            : selectedAction?.sub_fields[5]?.field_name
        }
      );
      setFieldEight(
        {
          id: selectedAction?.sub_fields[7]?.id,
          name: selectedAction?.sub_fields[7]?.field_name === "null"
            ? ""
            : selectedAction?.sub_fields[7]?.field_name
        }
      );
      setFieldNine(
        {
          id: selectedAction?.sub_fields[9]?.id,
          name: selectedAction?.sub_fields[9]?.field_name === "null"
            ? ""
            : selectedAction?.sub_fields[9]?.field_name
        }
      );
    }

  }, [selectedAction]);

  useEffect(() => {
    if (data)
      setUpdatedData(data)
  }, [data])
  // console.log("selectedAction=========", qty)
  useEffect(() => {
    if (selectedAction?.repeat_events) {
      setShowFrequency(selectedAction?.repeat_events)
    }
  }, [selectedAction?.repeat_events])
  useEffect(() => {
    if (selectedAction?.calendar_frequency) {
      setQty(selectedAction?.calendar_frequency)
    }
  }, [selectedAction?.calendar_frequency]);



  useEffect(() => {
    if (selectedAction?.estimate_man_hours) {
      setState({ man_hours: selectedAction?.estimate_man_hours })
    }
  }, [selectedAction?.estimate_man_hours]);

  useEffect(() => {
    if (selectedAction?.camo_records) {
      setShow(selectedAction?.camo_records)
    }
  }, [selectedAction?.camo_records]);

  useEffect(() => {
    getDropdownData();
  }, []);

  const handleDateFunction = (e) => {
    if (e) {

      let date = formatUTC(e)
      setDate(date)
    }

    // console.log("date=====", formatUTC(e))
  }

  const getDropdownData = async () => {
    const params = {
      organisation_id: getOrganisationId()
    }
    await InspectionServices.getAllDropDown(params)
      .then((res) => {
        if (res) {
          setDropdownData(res.data);
        }
      })
      .catch(() => {
        message.showToastMessage({
          message: "Something went wrong. Try again!",
          variant: "error",
        });
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    formData.append("id", currentSelectedID)
    maintFile.forEach((file) => formData.set("maintenance_file[]", file))
    setLoading(true)
    let valid = true
    let error = {
      title: false,
      ata_chapter_id: false,
      mech_auth_id: false,
      insp_auth_id: false,
      tech_data_ref: false,
      last_completed: false,
      calendar_frequency: false,
      camo_records: false,
      description: false,
      estimate_man_hours: false
    };
    if (qty === undefined && showFrequency || null) {
      error.calendar_frequency = true
      valid = false
    }
    if (title === "" || null) {
      error.title = true
      valid = false
    }
    if (ataChapter === "" || null) {
      error.ata_chapter_id = true
      valid = false
    }
    if (mechAuth === "" || null) {
      error.mech_auth_id = true
      valid = false
    }
    if (state.man_hours === "" || null) {
      error.estimate_man_hours = true
      valid = false
    }
    if (date === "" || null) {
      error.last_completed = true
      valid = false
    }

    if (techData === "" || null) {
      error.tech_data_ref = true
      valid = false
    }
    if (inspAuth === "" || null) {
      error.insp_auth_id = true
      valid = false
    }

    if (desc === "" || null) {
      error.description = true
      valid = false
    }
    if (!valid) {
      setBusy(null)
      setErrors(error);
      return;
    }



    const param = {
      title: title,
      ata_chapter_id: ataChapter,
      mech_auth_id: mechAuth,
      insp_auth_id: inspAuth,
      estimate_man_hours: state.man_hours,
      critical_considerations: selectedAction.critical_considerations,
      independent_insp_required: selectedAction.independent_insp_required,
      raised_by: selectedAction.raised_by,
      raised_date_time: selectedAction.raised_date_time,
      camo_records: show,
      closed_by: selectedAction.closed_by,
      verified_by: selectedAction.verified_by,
      verified_date_time: selectedAction.verified_date_time,
      tech_data_ref: techData,
      calendar_frequency: qty,
      last_completed: date,
      description: desc,
      id: currentSelectedID,
      stage_id: selectedAction.stage_id,
      repeat_events: showFrequency,
      sub_fields: [
        {
          id: fieldZero.id || null,
          field_name: fieldZero.name || null
        },
        {
          id: fieldOne.id || null,
          field_name: fieldOne.name || null
        },
        {
          id: fieldTwo.id || null,
          field_name: fieldTwo.name || null
        },
        {
          id: fieldThree.id || null,
          field_name: fieldThree.name || null
        },
        {
          id: fieldFour.id || null,
          field_name: fieldFour.name || null
        },
        {
          id: fieldFive.id || null,
          field_name: fieldFive.name || null
        },
        {
          id: fieldSix.id || null,
          field_name: fieldSix.name || null
        },
        {
          id: fieldSeven.id || null,
          field_name: fieldSeven.name || null
        },
        {
          id: fieldEight.id || null,
          field_name: fieldEight.name || null
        },
        {
          id: fieldNine.id || null,
          field_name: fieldNine.name || null
        },
      ]
    }
    try {
      handleFileUpdate(formData)
      handleUpdateFields(param, currentSelectedID)
    }
    catch (err) {
      console.log(err)
    }
    finally {
      setBusy(null);
      setLoading(false)
    }
  };


  const handleUpdateFields = async (param, currentSelectedID) => {
    setBusy("loading");
    await CalenderMaintenanceService.updateAction(param, currentSelectedID)
      .then((res) => {
        if (res) {
          handleClose();
          closeHandler()
          getAllActions();
          getAllActionStages()
          setBusy(null);
          message.showToastMessage({
            message: "Updated successfully!!",
            variant: "success",
          });
        }
      })
      .catch(() => {
        setBusy(null);
        message.showToastMessage({
          message: "Something went wrong. Try again!",
          variant: "error",
        });
      });

  }

  const handleFileUpdate = async (formData) => {
    setBusy("loading");
    await CalenderMaintenanceService.updateFile(formData).then((res) => {
      if (res) {
        // handleClose();
        // closeHandler()
        // // getAllActions();
        // // getAllActionStages()
        // setBusy(null);
        message.showToastMessage({
          message: "Updated successfully!!",
          variant: "success",
        });
      }
    })
      .catch(() => {
        setBusy(null);
        message.showToastMessage({
          message: "Something went wrong. Try again!",
          variant: "error",
        });
      });

  }
  const deleteFileHandler = async (id) => {

    setBusy("file-deleting");
    try {
      const response = await MaintenanceServices.deleteFile(id);

      if (response.success) {

        message.showToastMessage({
          message: "File deleted successfully",
          variant: "success",
        });
        getAllActions()
        getActions()
        // getSelectedAction();
      }
      setUpdatedData(data)
    } catch (error) {
      message.showToastMessage({
        message: "Something went wrong",
        variant: "error",
      })


    }
    finally {
      setBusy(null);
      setLoading(false)
    }
  };

  const resetErrorsHandler = (name) => {
    setErrors((e) => ({ ...e, [name]: false }));
  };

  const handleFrequency = (e) => {
    // console.log("events=====", e.target.checked)
    if (e.target.checked === false) {
      setQty(null)
    }

  }
  const handleChange = ({ target: { name, value } }) => {
    setState({
      ...state,
      [name]: value
    })
  }

  return (
    <div>
      <MROFullScreenRightDrawer open={true}>
        <AppBar position="static" className={classes.appBar} elevation={0}>
          <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
            <Box display="flex" alignItems="center">
              <IconButton
                onClick={() => {

                  handleClose()
                  closeHandler()
                  getAllActions()
                }}
                edge="start"
                className={classes.backButton}
                color="inherit"
                aria-label="back"
              >
                <BackIcon />
              </IconButton>
              <Typography style={{ fontSize: "16px", fontWeight: "bold" }}>
                Edit Calendar Action
              </Typography>
            </Box>
            <IconButton onClick={() => setAction("delete")}>
              <Typography
                style={{
                  fontSize: "13px",
                  fontWeight: "20px",
                  backgroundColor: "transparent",
                }}
              >
                Delete Item&nbsp;&nbsp;
              </Typography>
              <DeleteIcon fontSize="small" htmlColor={ICON_COLOUR} />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Grid container xs={12} spacing={1}>
          <Grid item xs={6}>
            <div className={classes.wrapper}>
              <Grid>
                <Typography
                  color="primary"
                  varient="h3"
                  style={{ marginBottom: "10px" }}
                >
                  Action Details
                </Typography>
              </Grid>

              <Grid item xs={10}>
                <MROTextField
                  name={"title"}
                  label={"Action & Stage Title *"}
                  placeholder={"Action & Stage Title - Max 50 Characters"}
                  value={title}
                  InputLabelProps={{ shrink: true }}
                  error={errors.title}
                  onChange={(e) => {
                    resetErrorsHandler(e.target.name);
                    setTitle(e.target.value);
                  }}
                ></MROTextField>
              </Grid>
              <Grid item xs={6}>
                <MROTextField
                  name={"ata_chapter_id"}
                  label={"ATA Chapter *"}
                  placeholder={"Select"}
                  value={ataChapter}
                  error={errors.ata_chapter_id}
                  select
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {
                    resetErrorsHandler(e.target.name);
                    setAtaChapter(e.target.value);
                  }}
                >
                  {dropDownData?.ata_chapters?.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.chapter_number}
                    </MenuItem>
                  ))}
                </MROTextField>
              </Grid>
              <Grid item xs={6}>
                <MROTextField
                  name={"mech_auth_id"}
                  label={"Mech Auth *"}
                  error={errors.mech_auth_id}
                  placeholder={"Select"}
                  select
                  // requird
                  InputLabelProps={{ shrink: true }}
                  value={mechAuth}
                  onChange={(e) => {
                    resetErrorsHandler(e.target.name);
                    setMechAuth(e.target.value);
                  }}
                >
                  {dropDownData?.mech_insp_auth?.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option?.type_name}
                    </MenuItem>
                  ))}
                </MROTextField>
              </Grid>
              <Grid item xs={6}>
                <MROTextField
                  name={"insp_auth_id"}
                  label={"Insp Auth *"}
                  placeholder={"Select"}
                  select
                  // required
                  InputLabelProps={{ shrink: true }}
                  value={inspAuth}
                  error={errors.insp_auth_id}
                  onChange={(e) => {
                    resetErrorsHandler(e.target.name);
                    setInspAuth(e.target.value);
                  }}
                >
                  {dropDownData?.mech_insp_auth?.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option?.type_name}
                    </MenuItem>
                  ))}
                </MROTextField>
              </Grid>
              <Grid item xs={6}>
                <NumericIncrement
                  name="estimate_man_hours"
                  label="Estimate Man-Hrs *"
                  error={errors.estimate_man_hours}
                  value={state.man_hours}
                  placeholder={"Man-Hours required"}
                  onChange={handleChange}
                  noIncreament
                  state={state}
                  setState={setState}
                  inputProps={{ min: 0, step: 0.5 }}
                  InputLabelProps={{ shrink: true }}
                  setError={() =>
                    setErrors((prevState) => ({
                      ...prevState,
                      estimate_man_hours: false,
                    }))
                  }
                />
                {/* <QuantityCounter
                  name={"estimate_man_hours"}
                  label={"Estimate Man-Hrs *"}
                  placeholder={"Man-Hours required"}
                  InputLabelProps={{ shrink: true }}
                  setQty={setState}
                  // value={qty}
                  // onChange={handleChange}
                  shouldNotAcceptZero={true}
                  qty={state}
                  step={"calendar"}
                  error={errors.estimate_man_hours}
                  setError={() =>
                    setErrors((prevState) => ({
                      ...prevState,
                      estimate_man_hours: false,
                    }))
                  }
                  minQty={1}
                /> */}
              </Grid>

              <Grid item xs={6}>
                <MROTextField
                  name={"tech_data_ref"}
                  label={"Tech Data Ref *"}
                  placeholder={"Enter tech data reference for the task"}
                  // required
                  value={techData}
                  InputLabelProps={{ shrink: true }}
                  error={errors.tech_data_ref}
                  onChange={(e) => {
                    resetErrorsHandler(e.target.name);
                    setTechData(e.target.value);
                  }}
                ></MROTextField>
              </Grid>
              <Grid item xs={6}>
                <DateField
                  required
                  name="last_completed"
                  label={"Due Date *"}
                  InputLabelProps={{ shrink: true }}
                  calenderAction={true}
                  onChange={(e) => {
                    handleDateFunction(e)
                    setErrors({ ...errors, last_completed: false });
                  }}
                  defaultValue={date}
                  error={errors.last_completed}
                  helperText={errors.last_completed ? "*please enter a date" : null}

                />
              </Grid>
              <Grid>
                <FormControlLabel
                  style={{ marginLeft: '3px' }}
                  control={
                    <PositiveSwitch
                      checked={showFrequency}
                      value={showFrequency}
                      onChange={(e) => {
                        setShowFrequency(e.target.checked)
                        handleFrequency(e)
                      }
                      }
                      // error={errors.calendar_frequency}
                      error={errors.repeat_events}
                      setError={() => resetErrorsHandler("repeat_events")}

                    />
                  }

                  label="Frequency based repeating event? "
                  labelPlacement="start"
                  name="repeat_events"
                />
              </Grid>
              {showFrequency &&
                <Grid item xs={6}>
                  <QuantityCounter
                    name={"calendar_frequency"}
                    label={"Frequency (days) "}
                    placeholder={"Days"}
                    // onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    setQty={setQty}
                    qty={qty}
                    value={qty}
                    autoFocus={errors.calendar_frequency}
                    error={errors.calendar_frequency}
                    shouldNotAcceptZero={true}
                    helperText={
                      errors["calendar_frequency"] && `Please enter days`
                    }
                    setError={() =>
                      setErrors((prevState) => ({
                        ...prevState,
                        calendar_frequency: false,
                      }))
                    }
                  />
                </Grid>
              }

              <FormControlLabel
                style={{ marginLeft: '3px' }}
                control={
                  <PositiveSwitch
                    checked={show}
                    value={show}
                    onChange={(e) => {
                      setShow(e.target.checked)

                    }
                    }
                    error={errors.camo_records}
                    setError={() => resetErrorsHandler("camo_records")}

                  />
                }

                label="Operator / CAMO Part M technical records applicable each time?"
                labelPlacement="start"
                name="camo_records"
              />


            </div>
          </Grid>

          <Grid item xs={6} style={{ marginTop: '18px' }}>
            <div className={classes.content}>

              <Typography
                variant="body1"
                color="primary"
                className={classes.title}
              ></Typography>{" "}
              <Grid
                item
                container
                justify="space-between"
                alignItems="center"
              >
                <Typography
                  color="primary"
                  varient="h3"
                //   style={{ fontWeight: "bold" }}
                >
                  Event Details
                </Typography>
              </Grid>
              <Grid
                container
                // spacing={1}
                alignItems={"center"}
                justify={"flex-start"}
              >
                <Grid container alignItems={"center"} justify={"flex-start"}>
                  <Grid item xs={12} style={{ paddingRight: '15px' }}>
                    <MROTextField
                      name={"description"}
                      label={"Detailed Description *"}
                      placeholder="Detailed Event Description - Max 2000 Characters"
                      InputProps={{ style: { height: 200 } }}
                      InputLabelProps={{ shrink: true }}
                      multiline
                      value={desc}
                      className={classes.description}
                      error={errors.description}
                      onChange={(e) => {
                        resetErrorsHandler(e.target.name);
                        setDesc(e.target.value)
                      }}
                    />
                  </Grid>
                </Grid>

                <>
                  <Grid item container style={{ marginTop: "25px" }}>
                    <Typography
                      varient="h3"
                      color="primary"
                    //   style={{ fontWeight: "bold" }}
                    >
                      Stage Details
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      Upto 10 Sub Fields can be used to capture text E.g.
                      torque values, temp readings etc.
                    </Typography>
                  </Grid>

                  <Grid container spacing={2} item xs={12}>
                    <Grid item xs={6}>
                      <MROTextField
                        name={"field_name[0]"}
                        label={"Sub-Field 1"}
                        value={fieldZero.name}
                        placeholder="Description - Max 50 Characters"
                        InputLabelProps={{ shrink: true }}
                        className={classes.inputField}
                        onChange={(e) => {
                          setFieldZero({ id: selectedAction?.sub_fields[0]?.id, name: e.target.value });
                          // handleUpdateStage();
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <MROTextField
                        name={"field_name[5]"}
                        label={"Sub-Field 6"}
                        value={fieldFive.name}
                        placeholder="Description - Max 50 Characters"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ classes: { input: classes.input1 } }}
                        className={classes.inputField}
                        onChange={(e) => {
                          setFieldFive({ id: selectedAction?.sub_fields[1]?.id, name: e.target.value });
                        }}
                      ></MROTextField>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} item xs={12}>
                    <Grid item xs={6}>
                      <MROTextField
                        name={"field_name[1]"}
                        label={"Sub-Field 2"}
                        placeholder="Description - Max 50 Characters "
                        value={fieldOne.name}
                        InputLabelProps={{ shrink: true }}
                        className={classes.inputField}
                        onChange={(e) => {
                          setFieldOne({ id: selectedAction?.sub_fields[2]?.id, name: e.target.value });

                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <MROTextField
                        name={"field_name[6]"}
                        label={"Sub-Field 7"}
                        value={fieldSix.name}
                        placeholder="Description - Max 50 Characters"
                        InputLabelProps={{ shrink: true }}
                        className={classes.inputField}
                        onChange={(e) => {
                          setFieldSix({ id: selectedAction?.sub_fields[3]?.id, name: e.target.value });

                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} item xs={12}>
                    <Grid item xs={6}>
                      <MROTextField
                        name={"field_name[2]"}
                        label={"Sub-Field 3"}
                        value={fieldTwo.name}
                        placeholder="Description - Max 50 Characters"
                        InputLabelProps={{ shrink: true }}
                        className={classes.inputField}
                        onChange={(e) => {
                          setFieldTwo({ id: selectedAction?.sub_fields[4]?.id, name: e.target.value });

                        }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <MROTextField
                        name={"field_name[7]"}
                        label={"Sub-Field 8"}
                        value={fieldSeven.name}
                        placeholder="Description - Max 50 Characters"
                        InputLabelProps={{ shrink: true }}
                        className={classes.inputField}
                        onChange={(e) => {
                          setFieldSeven({ id: selectedAction?.sub_fields[5]?.id, name: e.target.value });
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} item xs={12}>
                    <Grid item xs={6}>
                      <MROTextField
                        name={"field_name[3]"}
                        label={"Sub-Field 4"}
                        value={fieldThree.name}
                        placeholder="Description - Max 50 Characters"
                        InputLabelProps={{ shrink: true }}
                        className={classes.inputField}
                        onChange={(e) => {
                          setFieldThree({ id: selectedAction?.sub_fields[6]?.id, name: e.target.value });

                        }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <MROTextField
                        name={"field_name[8]"}
                        label={"Sub-Field 9"}
                        value={fieldEight.name}
                        placeholder="Description - Max 50 Characters"
                        InputLabelProps={{ shrink: true }}
                        className={classes.inputField}
                        onChange={(e) => {
                          setFieldEight({ id: selectedAction?.sub_fields[7]?.id, name: e.target.value });

                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} item xs={12}>
                    <Grid item xs={6}>
                      <MROTextField
                        name={"field_name[4]"}
                        label={"Sub-Field 5"}
                        value={fieldFour.name}
                        placeholder="Description - Max 50 Characters"
                        InputLabelProps={{ shrink: true }}
                        className={classes.inputField}
                        onChange={(e) => {
                          setFieldFour({ id: selectedAction?.sub_fields[8]?.id, name: e.target.value });

                        }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <MROTextField
                        name={"field_name[9]"}
                        label={"Sub-Field 10"}
                        value={fieldNine.name}
                        placeholder="Description - Max 50 Characters"
                        InputLabelProps={{ shrink: true }}
                        className={classes.inputField}
                        onChange={(e) => {
                          setFieldNine({ id: selectedAction?.sub_fields[9]?.id, name: e.target.value });

                        }}
                      />
                    </Grid>
                  </Grid>
                </>
              </Grid>
            </div>

          </Grid>
        </Grid>
        <form noValidate onSubmit={(e) => {
          handleSubmit(e)
          // console.log("Addd clicked=======")
        }}>
          <Grid style={{ marginLeft: '30px', width: '88%' }}>
            <FileUpload
              defaultValue={
                updatedData?.maintenance !== undefined
                  ? updatedData?.maintenance.map((item) => ({
                    filename: item?.file_name,
                    url: `${item?.path}`,
                    id: item?.id,
                  }))
                  : ""
              }
              APIUrl={""}
              onDelete={deleteFileHandler}
              isDeleting={busy === "file-deleting"}
              // isLoading={loading}
              setFiles={(files) => {
                setMaintFile(files);
              }}
              multiple={true}
              width={"50%"}
              showBorderOnNoFiles={true}
              name={"maintenance_file[]"}
              action={"Upload Document"}
            />
          </Grid>
          <Grid className={classes.actionButtons}>
            <MROButton variant={"contained"} onClick={handleClose}>Cancel</MROButton>
            <MROButton loading={loading} variant={"contained"} color={"primary"}

              type="submit"
            >
              Update
            </MROButton>
          </Grid>
        </form>






        {action === "delete" && (
          <DeleteAction
            handleClose={() => setAction(null)}
            open={true}
            selectedId={currentSelectedID}
            section={"deleteActionData"}
            closeHandler={closeHandler}
            getAllActions={getAllActions}
            setSelectedRowId={setSelectedRowId}
          />
        )}
      </MROFullScreenRightDrawer>
    </div>
  );
}

export default EditActionInfo;
