import React, { useEffect, useReducer, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Dialog, makeStyles } from "@material-ui/core";
import { Popover } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import MaterialTable from "material-table";
import tableReducer, { INITIAL_TABLE_STATE } from "reducers/table_reducer";
import tableIcons, {
    options,
} from "components/universal/table_attributes";
import TableAction from "actions/table_actions";
import PopupDialog from "./PopupDialog";
import { CommercialServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/commercial";


const StyledMenu = withStyles({
    paper: {
        width: "523px",
        height: "fit-content",
        top: "290px !important",
        padding: "30px",
        boxShadow: "0px 3px 6px #00000029",
    },
})((props) => (
    <Popover
        elevation={0}
        // getContentAnchorEl={null}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "center",
        }}
        {...props}
    />
));

export default function CommercialOverview(props) {
    const { open, data, handleClose, currentSelection, workshop_order_id } = props;

    const classes = useStyles();

    const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);

    const [currentPosition, setCurrentPosition] = useState([]);
    const [approvedPosition, setApprovedPosition] = useState([]);
    const [loadingCurrentPosition, setLoadingCurrentPosition] = useState(false);
    const [loadingApprovedPosition, setLoadingApprovedPosition] = useState(false);

    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(5)
    const [totalCount, setTotalCount] = useState(5)
    // tableOptions
    const tableOptions = {
        ...options,
        search: false,
        toolbar: false,
        paging: false,
        headerStyle: {
            textAlign: "center",
            paddingTop: "22px",
            paddingBottom: "16px",
        },
        rowStyle: { height: 42 },
    };
    const tableOptions2 = {
        ...options,
        page: page,
        total: totalCount,
        pageSize: pageSize,
        search: false,
        toolbar: false,
        headerStyle: {
            textAlign: "center",
            paddingTop: "22px",
            paddingBottom: "16px",
        },
        rowStyle: { height: 42 },
    };

    const columns = [
        {
            title: "Current",
            field: "live_values",

        },
        {
            title: "TAT",
            field: "tat_days" || "0.00",
            render: (rowData) => rowData.tat_days != null ? rowData.tat_days : "-"
        },
        {
            title: "Labour",
            field: "labour" || "0.00",
            render: (rowData) => rowData.labour != null ? rowData.labour : "0.00"
        },
        {
            title: "Parts",
            field: "parts" || "0.00",
            render: (rowData) => rowData.parts != null ? rowData.parts : "0.00"
        },
        {
            title: "Services",
            field: "services" || "0.00",
            render: (rowData) => rowData.services != null ? rowData.services : "0.00"
        },
        {
            title: "Equip",
            field: "equipment" || "0.00",
            render: (rowData) => rowData.equipment != null ? rowData.equipment : "0.00"
        },
        {
            title: "AOG",
            field: "aog_fee" || "0.00",
            render: (rowData) => rowData.aog_fee != null ? rowData.aog_fee : "0.00"
        },
        {
            title: "Total",
            field: "total" || "0.00",
            render: (rowData) => rowData.total === null ? '0.00' : rowData.total
        },
    ];
    const columns2 = [
        {
            title: "Approval",
            field: "approval_number",
            render: (rowData) => rowData?.approval_number === 0 ? "Quote" : rowData?.approval_number
        },
        {
            title: "TAT",
            field: "tat_days_count",
            render: (rowData) => {

                return <>{rowData?.tat_days_count || "0"}</>;
            },
        },
        {
            title: "Labour",
            field: "labour_value",
            render: (rowData) => rowData.labour_value === null ? "0.00" : rowData.labour_value
        },
        {
            title: "Parts",
            field: "parts_value",
            render: (rowData) => rowData.parts_value === null ? "0.00" : rowData.parts_value
        },
        {
            title: "Services",
            field: "service_value",
            render: (rowData) => rowData.service_value === null ? "0.00" : rowData.service_value
        },
        {
            title: "Equip",
            field: "equipment_value",
            render: (rowData) => rowData.equipment_value === null ? "0.00" : rowData.equipment_value
        },
        {
            title: "AOG",
            field: "aog_fee",
            render: (rowData) => rowData.aog_fee === null ? "0.00" : rowData.aog_fee
        },
        {
            title: "Total",
            field: "total",
            render: (rowData) => rowData.total === null ? '0.00' : rowData.total
        },
    ];

    const getCurrentPosition = async () => {
        setLoadingCurrentPosition(true);
        const params = {
            workshop_order_id: workshop_order_id,
        };
        await CommercialServices.getCurrentPosition(params)
            .then((res) => {
                if (res) {
                    setCurrentPosition(res.data);
                }
            })
            .catch(() => {
                console.log("error");
            })
            .finally(() => {
                setLoadingCurrentPosition(false);
            });
    };


    useEffect(() => {
        getCurrentPosition()
    }, [])

    const getApprovedPosition = async () => {
        setLoadingApprovedPosition(true);
        const params = {
            workshop_order_id: workshop_order_id,
            page: page + 1,
            limit: pageSize
        };
        await CommercialServices.getApprovedPosition(params)
            .then((res) => {
                if (res) {
                    setApprovedPosition(res.data);
                    setTotalCount(res.total_count)
                    // setCheckQuoteAccepted(res.data[0]?.quote_accepted)
                }
            })
            .catch(() => {
                console.log("error");
            })
            .finally(() => {
                setLoadingApprovedPosition(false);
            });
    };


    useEffect(() => {
        getApprovedPosition()
    }, [page, pageSize, totalCount])


    return (
        <div style={{ position: "relative" }}>
            <PopupDialog
                open={open}
                handleClose={handleClose}
                width="581px"
                notShowButton={true}
            >
                <Box
                    width="100%"
                    mb="30px"
                    ml="0px"
                    display="flex"
                    justifyContent="space-between"
                >
                    <Typography color="primary" className={classes.bodyText}>
                        Commercial Overview
                    </Typography>
                    <Typography style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ fontSize: "12px" }}>Job Quantity</span>&nbsp;&nbsp;<Typography color="primary" style={{ fontSize: "25px" }}>{currentSelection?.quantity}</Typography>
                    </Typography>
                </Box>
                <Typography variant="subtitle2" style={{ fontSize: "14px", marginBottom: "13px" }}>Current Calculation *</Typography>

                <Box width="100%" style={{ boxShadow: "0px 1px 3px #00000033" }}>
                    <MaterialTable
                        style={{ boxShadow: "0px 1px 0.5px #00000033" }}
                        icons={tableIcons}
                        columns={columns}
                        data={currentPosition || []}
                        options={tableOptions}
                        localization={{
                            toolbar: {
                                searchPlaceholder: "Search anything",
                            },
                            body: {
                                emptyDataSourceMessage: "No data Found",
                                filterRow: {
                                    filterTooltip: "Filter",
                                },
                            },
                        }}
                        isLoading={loadingCurrentPosition}
                        onChangePage={(page) => {
                            setPage(page);
                        }}
                        onChangeRowsPerPage={(pageSize) => {
                            setPageSize(pageSize);
                        }}
                        // onSearchChange={(search) => {
                        //     TableAction.setSearchText(dispatch, search);
                        // }}
                        totalCount={totalCount}
                        page={page}
                    />
                    <Typography style={{ fontSize: "12px", fontStyle: "italic", padding: "12px" }}>* Current calculation is based on actual job values, however, utilises current labour and parts rates</Typography>
                </Box>
                <Typography variant="subtitle2" style={{ fontSize: "14px", marginBottom: "13px", marginTop: "13px" }}>Approved Positions</Typography>

                <Box width="100%">
                    <MaterialTable
                        style={{ boxShadow: "0px 1px 3px #00000033" }}
                        icons={tableIcons}
                        columns={columns2}
                        data={approvedPosition || []}
                        options={tableOptions2}
                        localization={{
                            toolbar: {
                                searchPlaceholder: "Search anything",
                            },
                            body: {
                                emptyDataSourceMessage: "No data Found",
                                filterRow: {
                                    filterTooltip: "Filter",
                                },
                            },
                        }}
                        isLoading={loadingApprovedPosition}
                        onChangePage={(page) => {
                            setPage(page);
                        }}
                        onChangeRowsPerPage={(pageSize) => {
                            setPageSize(pageSize);
                        }}
                        // onSearchChange={(search) => {
                        //     TableAction.setSearchText(dispatch, search);
                        // }}
                        totalCount={totalCount}
                        page={page}
                    />
                </Box>
            </PopupDialog>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    bodyText: {
        fontSize: 16,
        lineHeight: "28px",
    },
}));
