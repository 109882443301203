import React, { useState } from "react";

import { Grid, Typography } from "@material-ui/core";
import MROButton from "components/buttons";
import QrCodeIcon from "assets/icons/qr-code.png";
import PrintLable from "./PrintLable";

function NoGoodsIn({data}) {

  const [action, setAction] = useState(null);

  return (
    <div>
      <Grid>
        <Typography
          color="primary"
          varient="h3"
          style={{ marginBottom: "10px", marginTop: "10px" }}
        >
          QR Label
        </Typography>
        <MROButton
          style={{ background: "#4FC607" }}
          type="button"
          variant={"contained"}
          color="secondary"
          onClick={() => setAction("qr_code")}
        >
          <img
            src={QrCodeIcon}
            alt="qr-code"
            style={{ width: "18px", height: "18px", marginRight: "8px" }}
          />
          Print Label
        </MROButton>
      </Grid>

      {action === "qr_code" && (
            <PrintLable
              open={action === "qr_code"}
              handleClose={() => setAction(null)}
              id={data.id}
            />
          )}
    </div>
  );
}

export default NoGoodsIn;
