import requestApi from "lib/services/api/common/boxp_api_client";
import { BASE_API_URL_BOXP } from "lib/constants";
import { BASE_API_URL_BOXR } from "lib/constants";
import requestrApi from "lib/services/api/common/boxr_api_client";
import { getOrganisationId } from "lib/utils/common_utils";

const addTask = (formData) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/public/api/v1/add_stage`,
    method: "POST",
    data: formData,
  });
};
const editTask = (formData) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/public/api/v1/edit_stage`,
    method: "POST",
    data: formData,
  });
};
const createCustomer = (formData) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/customer`,
    method: "POST",
    data: formData,
  });
};
const editCustomer = (formData, id = null) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/customer/update/${id}?_method=PUT`,
    method: "POST",
    data: formData,
  });
};

const getCustomerAll = (params) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/customer/all`,
    method: "GET",
    params,
  });
};
const getCustomer = (id = null) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/customer/${id}`,
    method: "GET",
    // params,
  });
};

const updateContact = (formData, id = null) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/customer/contact/update/${id}?_method=PUT`,
    method: "POST",
    data: formData,
  });
};
const deleteContact = (id = null) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/customer/contact/${id}`,
    method: "DELETE",
  });
};
const updateEmail = (formData, id = null) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/customer/report_emails/update/${id}?_method=PUT`,
    method: "POST",
    data: formData,
  });
};

const deleteEmail = (id = null) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/customer/report_emails/${id}`,
    method: "DELETE",
  });
};
const updateAddress = (formData, id = null) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/customer/address/update/${id}?_method=PUT`,
    method: "POST",
    data: formData,
  });
};

const deleteAddress = (id = null) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/customer/address/${id}`,
    method: "DELETE",
  });
};
const updateStorageRates = (formData, id = null) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/customer/storage_rates/update/${id}?_method=PUT`,
    method: "POST",
    data: formData,
  });
};

const deleteStorageRates = (id = null) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/customer/storage_rates/${id}`,
    method: "DELETE",
  });
};

const updateCommercialRates = (formData, params) => {
  return requestrApi({
    url: `${BASE_API_URL_BOXR}/line_customers/update_commercial_rates`,
    method: "PUT",
    data: formData,
    params
  });
};
const currencies = (params) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/currencies`,
    method: "GET",
    params
  });
};
const getPaymentTerms = (params) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/get_payment_terms`,
    method: "GET",
    params
  });
};
const getIncoterms = (params) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/get_incoterms`,
    method: "GET",
    params
  });
};

const getQuotation = (id) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/quotation/${id}`,
    method: "GET",
  });
}
const getWorkshopOrder = (params) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/workshop_order_generate`,
    method: "POST",
    params
  });
}

const getBaseStation = (params) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/base-stations`,
    method: "GET",
    params
  });
}

const acceptanceOrder = (formData, id = null) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/quotation-acceptance-update/${id}?_method=PUT`,
    method: "POST",
    data: formData,
  });
};


export const OperationWorkshopTaskLibraryServices = {
  addTask,
  editTask,
  createCustomer,
  editCustomer,
  getCustomerAll,
  getCustomer,
  updateContact,
  updateEmail,
  deleteContact,
  deleteEmail,
  updateAddress,
  deleteAddress,
  updateStorageRates,
  deleteStorageRates,
  updateCommercialRates,
  currencies,
  getQuotation,
  getWorkshopOrder,
  getBaseStation,
  acceptanceOrder,
  getPaymentTerms,
  getIncoterms
};
