import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import MROButton from "../buttons";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    formWrapper: {
        background: '#fff',
        width: '100%',
        padding: theme.spacing(3)
    },
    noPadding: {
        padding: theme.spacing(0)
    },
    title: {
        textTransform: 'capitalize',
        paddingBottom: theme.spacing(2)
    },
    actionButtons: {
        paddingTop: '20px',
        textAlign: 'right',
        '& button': {
            marginRight: '20px',
            textTransform: 'capitalize',
        }
    },
}));

function ManageDialog(props) {
    const classes = useStyles();
    const { handleClose, handleSubmit, handleCloseMenu, children, loading, title, type, maxWidth, files } = props;

    const handleFormSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);

        if (files) {
            if (Array.isArray(files)) {
                for (let i = 0; i < files.length - 1; i++) {
                    formData.append('documents[]', files[i][0])
                }
                return handleSubmit(formData);
            }
            formData.append('documents[]', files)
            return handleSubmit(formData);
        }
        handleSubmit(formData);
    }

    const _handleClose = () => {
        handleClose();
        if (handleCloseMenu) handleCloseMenu();
    }
    const _handleSubmit = () => {
        handleSubmit()
    }

    const handleConfirm = () => {
        if (type === 'delete') {
            return _handleSubmit()
        }
        handleCloseMenu ? handleCloseMenu() : handleClose()
    }

    return (
        <Dialog
            maxWidth={maxWidth || "md"}
            fullWidth={true}
            open={true}
            onClose={handleClose}
            aria-labelledby="create-form"
        >
            <DialogContent>
                {type !== 'delete' ? <div className={clsx(classes.formWrapper, props?.noPadding ? classes.noPadding : '')} style={maxWidth ? { width: 'auto' } : {}}>
                    <Typography variant="subtitle2" color="secondary" className={classes.title}>
                        {title}
                    </Typography>
                    {
                        <form spellcheck="false" onSubmit={handleFormSubmit}>
                            {children}
                            {props.formActions && <div className={classes.actionButtons}>
                                <MROButton disabled={loading} type="button" variant={"contained"}
                                    onClick={() => _handleClose()}>Cancel</MROButton>
                                {
                                    type !== 'view' &&
                                    <MROButton disabled={loading} type="submit" variant={"contained"} color="primary">{type}</MROButton>
                                }
                            </div>}
                        </form>
                    }
                </div> :
                    <div className={classes.formWrapper}>
                        {children}
                        <div className={classes.actionButtons}>
                            <MROButton disabled={loading} type="button" variant={"contained"} onClick={_handleClose}>Cancel</MROButton>
                            <MROButton disabled={loading} type="button" variant={"contained"} color="primary" onClick={() => {
                                handleConfirm()
                            }}>Yes, Confirm</MROButton>
                        </div>
                    </div>
                }
            </DialogContent>
        </Dialog >
    )
}

export default ManageDialog;
