import requestrApi from "../common/boxr_api_client";

const saveOperationFields =
  (params = {}) =>
  (data) => {
    return requestrApi({
      url: "/operation_fields/save_operation_fields",
      method: "PUT",
      params: params,
      data: data,
    });
  };

const retrieveTableStructures = (params = {}) => {
  return requestrApi({
    url: "/operation_fields/retrieve_table_structures",
    method: "GET",
    params: params,
  });
};

const addRequestPart = (data) => {
  return requestrApi({
    url: "/part_requests/operation_part_request",
    method: "POST",
    data: data,
  });
};

const editRequestPart = (formData) => {
  return requestrApi({
    url: "/part_requests/edit_part_request",
    method: "PUT",
    data: formData,
  });
};

const getRequestPartDetails = (params) => {
  return requestrApi({
    url: "/part_requests/part_request_details",
    method: "GET",
    params,
  });
};

const getAllocatedJobReq = (params) =>{
  return requestrApi({
    url: "/part_requests/allocated_job_req_details",
    method: "GET",
    params,
  });
}

// This is for Create tab.
const addPart = (formData) => {
  return requestrApi({
    url: "/primary_parts",
    method: "POST",
    data: formData,
  });
};

const createJobRequest = (params) => {
  return requestrApi({
    url: "/part_requests/operation_part_request",
    method: "POST",
    params
  })
}

function deleteTooling(formData) {
  return requestrApi({
      url: `/operation_fields/delete_tooling`,
      method: 'DELETE',
      data: formData,
  })
}

export const OperationService = {
  saveOperationFields,
  retrieveTableStructures,
  addRequestPart,
  getRequestPartDetails,
  editRequestPart,
  getAllocatedJobReq,
  addPart,
  createJobRequest,
  deleteTooling
};
