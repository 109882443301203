import { Box, Grid, MenuItem } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import moment from "moment";
import FreeTextField from "./FieldComponents/free_text_field";
import {
  getTimeDiffFormatted
} from "./section_detail";
import ListingService from "lib/services/api/listing_service";
import MROTextField from "components/form_components/TextField";

export const DepartureDelayCalculation = ({
  subSection,
  errors,
  setErrors,
  sub_section_fields,
  inbondState: state,
  lineCustomerId,
  actArrivalDate,
  setInbondState: setState,
  setDelayCode,
  ...props
}) => {
  // const [state, setstate] = useState({
  //     'Act Arrival Date/Time (UTC)': '',
  //     'Est Arrival Date/Time (UTC)': '',
  //     'Inbd delay (mins)': ''
  // })

  console.log("props are=================", subSection, "============", sub_section_fields, "========", state, "=======", actArrivalDate)

  const [delayReasonDpdValues, setDelayReasonDpdValues] = useState([]);

  useEffect(() => {
    if (delayReasonDpdValues.length > 0) return;
    const parmas = { line_customer_id: lineCustomerId };
    ListingService.getData(parmas, "delayReasonDpdValues")
      .then((res) => {
        setDelayReasonDpdValues(res.delay_codes);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //   set values if have the data in the backend
  useEffect(() => {
    if (!subSection.field_value) return;
    setState((prevState) => ({
      ...prevState,
      [subSection.field_name]: subSection.field_value,
    }));
  }, [subSection.field_value, subSection.field_name]);

  const handleChange = (data) => {
    setState({
      ...state,
      [subSection.field_name]: data,
    });
  };

  // useEffect(() => {
  //   let format = "YYYY-MM-DD - HH:mm:ss a";
  //   let revFormat = "DD-MM-YYYY - HH:mm:ss a";
  //   let duration = moment.duration(
  //     moment(sub_section_fields[0].field_value, format).diff(
  //       moment(sub_section_fields[1].field_value, format)
  //     )
  //   );

  //   if (duration.isValid()) {
  //     const days = duration.get("days");
  //     const hrs =
  //       days > 0
  //         ? String(duration.get("days")).padStart(2, "0") +
  //           ":" +
  //           String(duration.get("hours")).padStart(2, "0") +
  //           ":" +
  //           String(duration.get("minutes")).padStart(2, "0")
  //         : String(duration.get("hours")).padStart(2, "0") +
  //           ":" +
  //           String(duration.get("minutes")).padStart(2, "0");
  //     const timespan = hrs;

  //     setState({
  //       ...state,
  //       [departureTimeCalcFields[0]]: sub_section_fields?.find(
  //         (field) => field?.field_name === departureTimeCalcFields[0]
  //       )["field_value"],
  //       [departureTimeCalcFields[1]]: sub_section_fields?.find(
  //         (field) => field?.field_name === departureTimeCalcFields[1]
  //       )["field_value"],
  //       Delay: getTimeDiffFormatted(duration),
  //     });
  //   }
  // }, []);

  const calculateGndAndDelay = () => {
    const format = "YYYY-MM-DD - HH:mm:ss";
    //Act Departure Date/Time (UTC)
    const actDeparture = moment(state["Act Departure Date/Time (UTC)"], format);
    const estDeparture = moment(state["Est Departure Date/Time (UTC)"], format);

    const actArrival = moment(actArrivalDate, format);

    const gndDuration = moment.duration(actDeparture.diff(actArrival));
    const delayDuration = moment.duration(actDeparture.diff(estDeparture));

    // if (delayDuration.isValid()) {
    const DelayHours = getTimeDiffFormatted(delayDuration);

    const gndHours = getTimeDiffFormatted(gndDuration);

    console.log("actual departure===========", DelayHours, gndHours)
    setDelayCode(delayDuration.isValid() ? DelayHours : "00:00")
    setState((prevState) => ({
      ...prevState,
      Delay: delayDuration.isValid() ? DelayHours : "",
      "Gnd time": gndDuration.isValid() ? gndHours : "",
    }));
    // }
  };

  useEffect(() => {
    calculateGndAndDelay();
  }, [
    state["Act Departure Date/Time (UTC)"],
    state["Est Departure Date/Time (UTC)"],
    actArrivalDate,
  ]);

  return (
    <Box>
      <Grid container>
        <Grid item xs={12}>
          <FreeTextField
            {...{ ...subSection, field_value: state[subSection.field_name] }}
            sub_section_fields={sub_section_fields}
            handleChange={handleChange}
          // errors={errors}
          // setErrors={setErrors}
          // value={
          //   subSection.field_name === departureTimeCalcFields[2]
          //     ? state[departureTimeCalcFields[2]]
          //     : subSection.field_name === departureTimeCalcFields[3]
          //     ? state[departureTimeCalcFields[3]]
          //     : ""
          // }
          />

          {subSection.secondary_fields &&
            subSection.secondary_fields.map(
              (field, idx) =>
                /[1-9]/.test(state["Delay"]) &&
                !/[-]/.test(state["Delay"]) && (
                  // <FreeTextField
                  //   key={idx}
                  //   {...{ ...field, form: "mainform" }}
                  //   handleChange={() => null}
                  //   menuItems={
                  //     field.field_type === "Dropdown"
                  //       ? delayReasonDpdValues
                  //       : undefined
                  //   }
                  // />
                  <MROTextField
                    select
                    label={field.field_name}
                    defaultValue={field.field_value}
                    name={field.org_field_id}
                    required={field.mandatory}
                    style={{ paddingRight: 25 }}
                  >
                    {delayReasonDpdValues?.map((item, idx) => (
                      <MenuItem key={idx} value={item.name}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </MROTextField>
                )
            )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default React.memo(DepartureDelayCalculation);
