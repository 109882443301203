import React from 'react'
import makeStyles from "@material-ui/core/styles/makeStyles";
import withStyles from "@material-ui/core/styles/withStyles";

import { LinearProgress, Typography } from "@material-ui/core";


const useStyles = makeStyles(theme => ({
    mySelector: props => ({
        display: 'block',
        width: props.width,
        height: props.height,
        background: '#fff',
        border: '1px solid #00000033',
        '& .MuiLinearProgress-barColorPrimary': {
            background: props.background,
        },
        color: props.color

    }),

    label: {
        zIndex: 1,
        position: ' absolute',
        marginTop: '-20px',
        fontSize: '13px',
        paddingLeft: '10px'
    }
}));

export default function LinearProgressBar(props) {

    const styleProps = { width: props.width, height: props.barHeight, background: props.bgColor, color: props.color };
    const classes = useStyles(styleProps)

    return (
        <>
            <LinearProgress className={classes.mySelector} variant={props.variant} value={props.value > 100 ? 100 : props.value} />
            <Typography className={classes.label} style={{ color: props.color }}>{props.value ? `${props.value} %` : '-'}</Typography>
        </>
    )
}
