import React, { useContext, useEffect, useReducer, useCallback } from "react";
import MROFullScreenRightDrawer from "components/fullwidth_dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
  Box,
  FormControl,
  Grid,
  InputBase,
  MenuItem,
  Select,
} from "@material-ui/core";
import { MaintenanceServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation";
import tableReducer, { INITIAL_TABLE_STATE } from "reducers/table_reducer";
import MaterialTable from "material-table";
import TableAction from "actions/table_actions";
import tableIcons, { options } from "components/universal/table_attributes";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import { useState } from "react";
import SearchBarWithFilters from "components/search_with_filters";
import BoxrTableAction from "actions/boxr_table_actions";
import { ToastMessageContext } from "lib/contexts/message_context";
import useDebounce from "lib/utils/debounce";
import { BASE_API_URL_BOXR } from "lib/constants";
import PdfIcon from "assets/pdf.png";
import { getOrganisationId } from "lib/utils/common_utils";
import { Visibility as ViewIcon } from "@material-ui/icons";
import { ICON_COLOUR } from "lib/constants/style_constants";
import PartNumberTable from "./PartNumberTable";
import ScopePopup from "./ScopePopup";
import CapabilityServices from "lib/services/api/compliance/capability_client";
import TechData from "views/Compliance/Approvals/Capability/TechData";
import DataService from "lib/services/api";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "4px",
    textAlign: "center",
  },
  offgoingchecksTable: {
    "& td, th ": {
      border: "1px solid #dddddd",
      textAlign: "left",
      padding: "8px",
    },
    partCard: {
      borderRadius: "8px",
      padding: "17px 24px",
      "& p": {
        fontSize: "14px",
        padding: "5px",
      },
    },
    offgoingchecksTable: {
      "& td, th ": {
        border: "1px solid #dddddd",
        textAlign: "left",
        padding: "8px",
      },
    },
  },

  logo: {
    height: "76px",
  },
  filePreviewContainer: {
    maxWidth: "130px",
    marginRight: "30px",
  },
  wrapper: {
    margin: "3%",
  },
  appBar: {
    backgroundColor: "#fff",
    color: "#000000CC",
    boxShadow: "0px 3px 6px #0000001A",
  },
  checksAppBar: {
    backgroundColor: "lightgray",
    color: theme.palette.secondary.main,
    boxShadow: "0px 3px 6px gray",
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  generalDetail: {
    backgroundColor: "white",
    padding: "2%",
    color: "#fff",
    borderRadius: "5px 5px 0 0",
  },
  content: {
    backgroundColor: "#fff",
    padding: "3% 6%",
    borderRadius: "0 0 5px 5px",
    minHeight: "50vh",
    "& h4": {
      padding: "5px 0",
    },
  },
  titleTile: {
    padding: "3px 20px",
    fontSize: "13px",
    fontWeight: 500,
    marginBottom: "20px",
  },
  pdfFile: {
    border: "1px solid #ccc",
    display: "inline-block",
    padding: "10px",
    borderRadius: "5px",
    position: "relative",
  },
  pdfIcon: {
    position: "absolute",
    top: "8px",
    left: "5px",
    color: "#ff414f",
  },
  pdfName: {
    color: "#707070",
  },
}));

function SearchCapability(props) {
  const classes = useStyles();
  const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
  const { handleClose } = props;
  const [currentSelection, setCurrentSelection] = useState([]);
  const message = useContext(ToastMessageContext);
  const [action, setAction] = useState(null);
  const [platformList, setPlatformList] = useState([]);
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [searchText, setSearchText] = useState("");
  const debouncedVal = useDebounce(searchText);
  const { page, totalCount, pageSize, inProgress, data } = tableState;
  const [filterData, setFilterData] = useState({})
  const [dependencyList, setFetchDependencyList] = useState([]);
  const [busy, setBusy] = useState(null);
  const [ataChapters, setAtaChapters] = useState([]);



  const tableOptions = {
    ...options,
    page: page,
    total: totalCount,
    pageSize: pageSize,
    toolbar: false,
    searchText: searchText,
  };

  useEffect(() => {
    getAllCapability();
  }, [pageSize, page, searchText, debouncedVal, selectedPlatform, filterData]);

  const getAllCapability = () => {
    const params = {
      search_keyword: debouncedVal,
      count_per_page: pageSize,
      page: page + 1,
      platform_id: selectedPlatform,
      status: "Active",
      ...filterData
    };
    BoxrTableAction.getList(dispatch, message, params, "fetchCapabiity");
  };

  const handleAction = (rowData) => {
    props.func(rowData?.reference_data, rowData?.id);
    setAction("capability");
    setCurrentSelection(rowData);
  };

  const getAtaChapters = useCallback(
    () => {
      let params = {

      }
      DataService.getData(params, 'listingATAChapter').then(res => {
        setAtaChapters(res.data.map(d => ({
          id: d.ata_chapter_id,
          name: d.chapter_number
        })))
      }).catch(err => {

      })
    },
    [],
  )


  const getfetchDependency = () => {
    setBusy("loading-platform");
    const params = {
      organisation_id: getOrganisationId()
    }
    CapabilityServices.getFetchDependency(params)
      .then((data) => {
        if (data) {
          setFetchDependencyList(data);
        }
        setBusy(null);
      })
      .catch((err) => {
        console.log("platform api failed", err);
        setBusy("platform-error");
      });
  };

  useEffect(() => {
    getfetchDependency();
    getAtaChapters()
  }, [action]);

  useEffect(() => {
    if (action && action === "capability") {
      handleClose();
    }
  }, [currentSelection]);

  const columns = [
    {
      title: "Ref #",
      field: "reference_data",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        textAlign: "left",
        width: "10%",
      },
    },
    {
      title: "Type",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        textAlign: "left",

      },
      render: (rowData) =>
        rowData?.capability_type ? <span>{rowData.capability_type.name}</span> : "-",

    },
    {
      title: "OEM / D-Org",
      field: "oem",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        textAlign: "left",

      },
    },
    {
      title: "Platform",
      field: "platform_names",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        textAlign: "left",
        width: "10%",
      },
    },
    {
      title: "Part #",
      field: "part_number",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        textAlign: "left",
        width: "10%",
      },
      render: (rowData) => (
        <Box>
          <IconButton
            // disabled={!props.permission.write}
            onClick={() => {
              setAction("part_number");
              setCurrentSelection(rowData);
            }}
          >
            <ViewIcon fontSize="small" style={{ color: ICON_COLOUR }} />
          </IconButton>
        </Box>
      ),
    },
    {
      title: "Description",
      field: "description",
      headerStyle: {
        textAlign: "left",
        width: "30%",
      },
      cellStyle: {
        textAlign: "left",
        width: "30%",
      },
    },
    {
      title: "Tech Data",
      headerStyle: {
        textAlign: "center",

      },
      cellStyle: {
        textAlign: "center",

      },
      render: (rowData) => (
        <IconButton
          onClick={() => {
            setCurrentSelection(rowData)
            setAction("tech_data")
          }}
          size="small"
        >
          <ViewIcon fontSize="small" style={{ color: ICON_COLOUR }} />
        </IconButton>
      ),
    },
    {
      title: "Detail",
      headerStyle: {
        textAlign: "center",

      },
      cellStyle: {
        textAlign: "center",

      },
      render: (rowData) => (
        <IconButton
          onClick={() => {
            setCurrentSelection(rowData)
            setAction("scope_view")
          }}
          size="small"
        >
          <ViewIcon fontSize="small" style={{ color: ICON_COLOUR }} />
        </IconButton>
      ),
    },
    {
      title: "Document",
      field: "document",
      headerStyle: {
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",

      },
      render: (rowData) => (
        <IconButton
          onClick={() => {
            if (rowData.document) {
              window.open(BASE_API_URL_BOXR + rowData.document);
            } else {
              message.showToastMessage({
                message: "No Document Found.",
                variant: "error",
              });
            }
          }}
          size="small"
        >
          <ViewIcon fontSize="small" style={{ color: ICON_COLOUR }} />
        </IconButton>
      ),
    },
    {
      title: "Action",
      render: (rowData) => (
        <IconButton onClick={() => handleAction(rowData)}>
          <TouchAppIcon />
        </IconButton>
      ),
    },
  ];
  const handleFilterChange = (keyItem, value) => {
    TableAction.setPage(dispatch, 0);
    setFilterData((prevState) => ({ ...prevState, [keyItem]: value }));
  };

  return (
    <div>
      <MROFullScreenRightDrawer open={true}>
        <AppBar position="static" className={classes.appBar} elevation={0}>
          <Toolbar>
            <IconButton
              onClick={handleClose}
              edge="start"
              className={classes.backButton}
              color="inherit"
              aria-label="back"
            >
              <BackIcon />
            </IconButton>
            <Typography variant="subtitle2" style={{ fontSize: "17px" }}>
              Search Capability
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.wrapper}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography style={{ fontSize: "13px" }}>
                User the search and filter options below to search for the relevant capability document
              </Typography>

              <SearchBarWithFilters
                handleSearch={(value) => {
                  TableAction.setPage(dispatch, 0);
                  setSearchText(value);
                }}
              >
                <FormControl size="medium" className={"filter"}>
                  <Select
                    fullWidth
                    displayEmpty
                    id="tags-standard"
                    defaultValue={""}
                    input={<InputBase />}
                    onChange={(e) =>
                      handleFilterChange("platform_id", e.target.value)
                    }
                  >
                    <MenuItem value={""}>Platform (All)</MenuItem>
                    {dependencyList?.platforms?.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl size="medium" className={"filter"}>
                  <Select
                    fullWidth
                    displayEmpty
                    id="tags-standard"
                    defaultValue={""}
                    input={<InputBase />}
                    onChange={(e) =>
                      handleFilterChange("release_type_id", e.target.value)
                    }
                  >
                    <MenuItem value={""}>Approval (All)</MenuItem>
                    {dependencyList?.release_types?.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.type_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {/* <FormControl size="medium" className={"filter"}>
          <Select
            fullWidth
            displayEmpty
            id="tags-standard"
            defaultValue={""}
            input={<InputBase />}
            onChange={(e) =>
              handleFilterChange("scope", e.target.value)
            }
          >
            <MenuItem value={""}>Scope (All)</MenuItem>
            {[{ name: "Full", value: true }, { name: "Limited", value: false }].map((item) => (
              <MenuItem key={item.name} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}
                <FormControl size="medium" className={"filter"}>
                  <Select
                    fullWidth
                    displayEmpty
                    id="tags-standard"
                    defaultValue={""}
                    input={<InputBase />}
                    onChange={(e) =>
                      handleFilterChange("status_works", e.target.value)
                    }
                  >
                    <MenuItem value={""}>Status / Work (All)</MenuItem>
                    {dependencyList?.status_works?.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl size="medium" className={"filter"}>
                  <Select
                    fullWidth
                    displayEmpty
                    id="tags-standard"
                    defaultValue={""}
                    input={<InputBase />}
                    onChange={(e) =>
                      handleFilterChange("capability_type", e.target.value)
                    }
                  >
                    <MenuItem value={""}>Type (All)</MenuItem>
                    {dependencyList?.capability_types?.map((item) => (
                      <MenuItem key={item.id} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl size="medium" className={"filter"}>
                  <Select
                    fullWidth
                    displayEmpty
                    id="tags-standard"
                    defaultValue={""}
                    input={<InputBase />}
                    onChange={(e) =>
                      handleFilterChange("capability_rating_id", e.target.value)
                    }
                  >
                    <MenuItem value={""}>Rating (All)</MenuItem>
                    {dependencyList?.capability_rating?.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl size="medium" className={"filter"}>
                  <Select
                    fullWidth
                    displayEmpty
                    id="tags-standard"
                    defaultValue={""}
                    input={<InputBase />}
                    onChange={(e) =>
                      handleFilterChange("capability_oem_id", e.target.value)
                    }
                  >
                    <MenuItem value={""}>OEM (All)</MenuItem>
                    {dependencyList?.capability_oems?.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl className={"filter"}>
                  <Select
                    defaultValue={undefined}
                    displayEmpty
                    input={<InputBase />}
                    onChange={e => handleFilterChange('ata_chapter_id', e.target.value)}
                  >
                    <MenuItem value={undefined}>ATA (All)</MenuItem>
                    {ataChapters?.map((item) => (
                      <MenuItem
                        value={item.id}
                        key={item.id}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>


              </SearchBarWithFilters>

              <Typography style={{ fontSize: "15px", fontWeight: 600 }}>
                Workshop Capabilities
              </Typography>
              <div>
                <MaterialTable
                  style={{
                    boxShadow: "0px 1px 3px #00000033",
                    marginTop: "10px",
                  }}
                  icons={tableIcons}
                  classes
                  title={""}
                  columns={columns}
                  data={data.data || []}
                  isLoading={inProgress}
                  options={tableOptions}
                  onChangePage={(page) => {
                    TableAction.setPage(dispatch, page);
                  }}
                  onChangeRowsPerPage={(pageSize) => {
                    TableAction.setPageSize(dispatch, pageSize);
                  }}
                  onSearchChange={(search) => {
                    TableAction.setSearchText(dispatch, search);
                  }}
                  totalCount={totalCount}
                  page={page}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </MROFullScreenRightDrawer>
      {action && action === "part_number" &&
        <PartNumberTable
          busy={""}
          anchorEl={""}
          handleClose={() => setAction(null)}
          currentSelection={currentSelection}
          data={[{}]}
        />}
      {action && action === "scope_view" &&
        <ScopePopup
          busy={""}
          open={action}
          handleClose={() => setAction(null)}
          currentSelection={currentSelection}
          data={[{}]}
        />
      }
      {action && action === "tech_data" &&
        <TechData
          busy={busy}
          open={action}
          handleClose={() => setAction(null)}
          currentSelection={currentSelection}
        />
      }
      {/* {action === "Selected_Order" && <SelectedOrderDetails handleClose={handleClose} /> } */}
    </div>
  );
}

export default SearchCapability;
