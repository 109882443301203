import { Grid, Typography } from "@material-ui/core";
import MROButton from "components/buttons";
import React from "react";
import QrCodeIcon from "assets/icons/qr-code.png";
import TabComponent from "../../../Workshop/Maintenance/InductionTab/TabComponent";

function InductionTab(props) {
  return (
    <div>
      <TabComponent {...props} />
    </div>
  );
}

export default InductionTab;
