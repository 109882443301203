import React from 'react'
import { Typography, Box, makeStyles, IconButton } from "@material-ui/core";
import MROButton from "../../../components/buttons";
import ButtonGroupNavigation from "components/button_group_navigation";
import { Route, Switch } from "react-router-dom";


import Compliance from './Compliance/index'
import StoreCondition from './StoreCondition/TemperatureHumidity'
import ConditionEvents from './Condition Events';

const useStyles = makeStyles(theme => ({
    subNavigation: {
        margin: '2% 3%',
        width: '94%',
    }
}));
const buttons = [
    { name: "Compliance", redirect: "/org/dashboard/station/compliance" },
    { name: "Store Conditions", redirect: "/org/dashboard/station/storeconditions" },
    { name: "Condition Events", redirect: "/org/dashboard/station/conditionevents" },

];


function Stations(props) {
    const classes = useStyles();
    return (
        <div>
            <div className={classes.subNavigation}>
                <ButtonGroupNavigation buttons={buttons} {...props} />
            </div>
            <Switch>
                <Route exact path={'/org/dashboard/station/compliance'} component={rprops => <Compliance {...rprops} {...props} />} />
                <Route exact path={'/org/dashboard/station/storeconditions'} component={rprops => <StoreCondition {...rprops} {...props} />} />
                <Route exact path={'/org/dashboard/station/conditionevents'} component={rprops => <ConditionEvents {...rprops} {...props} />} />

                <Route component={Compliance} />
            </Switch>

        </div>
    )
}

export default Stations