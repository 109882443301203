import React from "react"



const Invoicing = ({ fill }) =>
(<svg xmlns="http://www.w3.org/2000/svg" width="120.989" height="28" viewBox="0 0 98.66 109.915" fill={fill}>
  <g id="bill" transform="translate(-25.17)">
    <path id="Path_20299" data-name="Path 20299" d="M117.167,0H52.956a6.672,6.672,0,0,0-6.664,6.664V21.578a29.129,29.129,0,0,0,0,56.014V103.25a6.672,6.672,0,0,0,6.664,6.664h52.472a2.348,2.348,0,0,0,1.66-.687l16.055-16.055a2.347,2.347,0,0,0,.687-1.66V6.664A6.672,6.672,0,0,0,117.167,0ZM52.956,4.694h64.211a1.972,1.972,0,0,1,1.97,1.97V18.487H50.987V6.664A1.972,1.972,0,0,1,52.956,4.694ZM29.864,49.584A24.431,24.431,0,1,1,54.3,74.015,24.458,24.458,0,0,1,29.864,49.584ZM50.987,103.25V78.521a29.079,29.079,0,0,0,25.26-9.815H90.628a2.347,2.347,0,1,0,0-4.694H79.589a28.914,28.914,0,0,0,3.721-11.9h7.318a2.347,2.347,0,0,0,0-4.694H83.339a28.912,28.912,0,0,0-3.551-11.9H90.627a2.347,2.347,0,1,0,0-4.694H76.55A29.079,29.079,0,0,0,50.986,20.648v2.534h68.151V89.165h-9.391a6.671,6.671,0,0,0-6.664,6.664v9.391H52.956A1.972,1.972,0,0,1,50.987,103.25Zm56.79-1.35V95.829a1.972,1.972,0,0,1,1.97-1.97h6.071Z" transform="translate(0 0)" fill={fill} />
    <path id="Path_20300" data-name="Path 20300" d="M351.093,142.563h7.8a2.347,2.347,0,0,0,0-4.694h-7.8a2.347,2.347,0,0,0,0,4.694Z" transform="translate(-251.243 -107.05)" fill={fill} />
    <path id="Path_20301" data-name="Path 20301" d="M351.093,216.8h7.8a2.347,2.347,0,0,0,0-4.694h-7.8a2.347,2.347,0,0,0,0,4.694Z" transform="translate(-251.243 -164.695)" fill={fill} />
    <path id="Path_20302" data-name="Path 20302" d="M351.093,291.046h7.8a2.347,2.347,0,0,0,0-4.694h-7.8a2.347,2.347,0,0,0,0,4.694Z" transform="translate(-251.243 -222.341)" fill={fill} />
    <path id="Path_20303" data-name="Path 20303" d="M309.7,365.287a2.347,2.347,0,1,0,0-4.694H287.738a2.347,2.347,0,1,0,0,4.694Z" transform="translate(-202.051 -279.986)" fill={fill} />
    <path id="Path_20304" data-name="Path 20304" d="M106.239,154.745l-2.864-2.864a4.083,4.083,0,0,0-5.774,0L82.582,166.9,78.43,162.75a4.083,4.083,0,0,0-5.774,0l-2.864,2.864a4.088,4.088,0,0,0,0,5.774l9.9,9.9a4.056,4.056,0,0,0,2.887,1.2h0a4.055,4.055,0,0,0,2.887-1.2l20.771-20.771A4.088,4.088,0,0,0,106.239,154.745ZM82.581,177.538,73.544,168.5l2-2,5.379,5.379a2.347,2.347,0,0,0,3.32,0l16.247-16.247,2,2Z" transform="translate(-33.72 -117.001)" fill={fill} />
    <text id="_" data-name="$$$" transform="translate(75.088 14.837)" fill={fill} font-size="11" font-family="SourceSansPro-Bold, Source Sans Pro" font-weight="700"><tspan x="0" y="0">$$$</tspan></text>
  </g>
</svg>)

export default Invoicing