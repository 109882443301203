import React, { useContext, useState } from 'react'
import MROTextField from "components/form_components/TextField";
import MROButton from "components/buttons";
import ViewIcon from "@material-ui/icons/Visibility";
import { Box, CircularProgress, Grid, Typography, useTheme } from '@material-ui/core';
// import { ViewIcon as Visibility } from '@material-ui/icons';
import makeStyles from "@material-ui/core/styles/makeStyles";
import { ICON_COLOUR } from "lib/constants/style_constants";
import { ToastMessageContext } from 'lib/contexts/message_context';
import SignatureFile from 'components/form_components/fileUpload/SIgnatureFile';
import { useEffect } from 'react';
import { validateFormData } from 'lib/utils/helperFunctions';
import { MaintenanceServices } from 'lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation';
import { WorkshopContext } from '../WorkshopContext/WorkshopContext';
import lock from "assets/icons/Lock.svg";
import unlock from "assets/icons/Unlock.svg";
import { _formatDate } from 'lib/utils/common_utils';
import { BASE_API_URL_BOXP } from 'lib/constants';
import ReleaseCertPopup from './ReleaseCertPopup';

const Styles = {
    svg: {
        filter: "invert(1)",
        height: 18,
        marginBottom: 2,
    },
};

const useStyles = makeStyles((theme) => ({
    wrapper: {
        margin: "3%",
    },
    appBar: {
        backgroundColor: "#fff",
        color: "#000000CC",
        boxShadow: "0px 3px 6px #0000001A",
    },
    backButton: {
        marginRight: theme.spacing(2),
    },
    paper: {
        borderRadius: "5px",
        backgroundColor: "#F5F5F5",
        padding: theme.spacing(2),
        boxShadow: "0px 1px 4px #0000001A",
    },
    partValue: {
        fontSize: "14px",
        color: "#4D4F5C",
        padding: "2px",
        display: "flex",
        alignItems: "center",
    },
    partKey: {
        "& p": {
            fontSize: "14px",
            padding: "1px",
        },
    },
    partCard: {
        borderRadius: "8px",
        padding: "17px 24px",
        "& p": {
            fontSize: "14px",
            padding: "5px",
        },
    },
    sec1: {
        borderRadius: "10px",
        backgroundColor: "#fff",
        padding: theme.spacing(5),
        boxShadow: "0px 1px 4px #0000001A",
    },
    sec: {
        width: "30%",
        height: "90px",
        borderRadius: "6px",
        backgroundColor: "#fff",
        padding: theme.spacing(5),
        boxShadow: "0px 1px 4px #0000001A",
    },
    fileView: {
        backgroundColor: "#ffffff",
        padding: "45px",
        border: "1px solid gray",
        borderRadius: " 8px",
        marginBottom: "12px",
    },
}));
function AwayFromBase(props) {
    const {
        data,
        releaseDetails,
        getReleaseList,
        isGettingDetails,
        editTab,
        setEditTab,
        getHeaderDetails,
        orderStateNum,
        tabStatusAFB,
        closeTabStatusAFB,
        getWorkshopOrderDetails,
        setIsGettingDetails,
        releaseDocuments
    } = props;
    const message = useContext(ToastMessageContext)
    const [disabled, setDisabled] = useState(true);
    const [closeTab, setCloseTab] = useState(true);
    const [busy, setBusy] = useState(null);
    const [errors, setErrors] = useState({});
    const [isRelease, setIsRelease] = useState(false);
    const [msds, setMsds] = useState([])
    const classes = useStyles();
    const { editButton, setEditButton } =
        useContext(WorkshopContext);
    const [releaseComments, setReleaseComments] = useState(null);

    // console.log("release details is================", releaseDocuments?.unserviceable_label)

    useEffect(() => {
        if (releaseDetails?.comments != null || releaseDetails?.comments != undefined) {
            setReleaseComments(releaseDetails?.comments)
        }
    }, [releaseDetails?.comments])

    const handleCloseTab = () => {
        getReleaseList()
        getWorkshopOrderDetails();
        setEditTab(!editTab)
        setDisabled(!disabled);
    }

    const handleTab = () => {
        setEditButton(true)
        setEditTab(!editTab);
        setDisabled(!disabled);
    };

    const handleEditAPiCall = async () => {
        setIsGettingDetails(true);
        const param = {
            workshop_order_id: releaseDetails?.workshop_order_id,
            tab: "release",
            release_tab_style: 3
        };
        await MaintenanceServices.editButton(param)
            .then((res) => {
                if (res.success) {
                    getWorkshopOrderDetails();
                    setEditButton(!editButton);
                }
            })
            .catch((err) => {
                message.showToastMessage({
                    message: "Something went wrong. Try again!",
                    variant: "error",
                });
                setBusy(null);
            })
            .finally(() => {
                setBusy(null);
                setIsGettingDetails(false);
            });
    };

    useEffect(() => {
        if (editButton) {
            handleEditAPiCall();
        }
    }, [editButton])

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData(e.target);

        const { valid, error } = validateFormData(formData);

        if (!valid) {
            setErrors(error);
            return;
        }
        formData.append("workshop_order_id", releaseDetails?.workshop_order_id,)
        formData.append("release_tab_style", 3)
        formData.append("edit_tab", true)
        formData.append("client_name", releaseDetails?.client_name)
        formData.append("client_position", releaseDetails?.client_position)
        formData.append("accepted_at", releaseDetails?.accepted_at)
        formData.append("free_from_fod", releaseDetails?.free_from_fod)
        formData.append("completed_by", releaseDetails?.completed_by)
        formData.append("tool_equip_removed", releaseDetails?.tool_equip_removed)
        formData.append("security_passes_returned", releaseDetails?.security_passes_returned)

        releaseDetails.client_signature.forEach(clientSign => formData.append("client_signature", clientSign))

        // let params = {
        //     edit_tab: true
        // }
        setBusy("loading");
        try {
            const response = await MaintenanceServices.updateReleaseAwayFromBase(
                formData,
                data.release_id,

            );

            if (response.success) {
                message.showToastMessage({
                    message: "Updated Successfully!",
                    variant: "success",
                });
                handleCloseTab()
            } else {
                message.showToastMessage({
                    message:
                        response.error.length > 0
                            ? response.error?.join(", ")
                            : "Something went wrong!",
                    variant: "error",
                });
            }

            setBusy(null);
        } catch (error) {
            setBusy("error");
            message.showToastMessage({
                message: "Something went wrong",
                variant: "error",
            });
        }
    };
    const resetErrorsHandler = (name) => {
        setErrors((e) => ({ ...e, [name]: false }));
    };
    const theme = useTheme()
    const GetInfoCard = (props) => {
        console.log(props)
        const classes = useStyles();
        return (
            <Box display="flex">
                <Box flex={15} className={classes.partKey}>
                    <Typography
                        style={{ padding: "5px", color: theme.palette.primary.main }}
                        color="secondary"
                    >
                        {props?.keyName}
                    </Typography>
                </Box>
                <Box flex={50} className={classes.partValue}>
                    {props?.value ?? "-"}
                </Box>
            </Box>

        )
    }

    if (isGettingDetails) {
        return (
            <Box
                width="100%"
                minHeight="400px"
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <CircularProgress color="primary" size={40} />
            </Box>
        );
    }
    return (

        <div>
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
                <Box display="flex" alignItems="center">
                    <Typography
                        style={{
                            color: theme.palette.primary.main,
                            marginBottom: "12px",
                            fontSize: "17px",
                        }}
                        color="primary"

                    >
                        Documentation
                    </Typography>
                </Box>
            </Box>

            <Box
                display="flex"
                alignItems="center"
                bgcolor="#F5F5F5"
                className={classes.partCard}
            >
                <Box flexGrow={1}>
                    <div style={{ width: "100%" }}>
                        <GetInfoCard keyName={"Serviceable Label"} value={
                            releaseDocuments.data?.serviceable_label != undefined && releaseDocuments.data?.serviceable_label != [] ?
                                <div style={{ marginTop: "3px" }}>
                                    <ViewIcon
                                        style={{ cursor: "pointer" }}
                                        fontSize="small"
                                        htmlColor={ICON_COLOUR}
                                        onClick={() => {
                                            if (releaseDocuments?.serviceable_label != undefined && releaseDocuments?.serviceable_label != []) {
                                                window.open(releaseDocuments?.serviceable_label.map(document => document.file_path
                                                ))
                                            }
                                            else {
                                                message.showToastMessage({
                                                    message: "No Label Found.",
                                                    variant: "error",
                                                });
                                            }
                                        }}
                                    />
                                </div> : "N/A"
                        } />
                        <GetInfoCard keyName={"Workshop Report"} value={
                            releaseDocuments?.workshop_report !== undefined && releaseDocuments?.serviceable_label != [] ?
                                <div style={{ marginTop: "3px" }}>
                                    <ViewIcon
                                        style={{ cursor: "pointer" }}
                                        fontSize="small"
                                        htmlColor={ICON_COLOUR}
                                        onClick={() => {
                                            if (releaseDocuments?.workshop_report !== undefined && releaseDocuments?.serviceable_label != []) {
                                                window.open(releaseDocuments?.workshop_report.map(document => document.file_path
                                                ))
                                            }
                                            else {
                                                message.showToastMessage({
                                                    message: "No Report Found.",
                                                    variant: "error",
                                                });
                                            }

                                        }}
                                    />
                                </div> : "N/A"
                        } />
                        <GetInfoCard keyName={"C of C"} value={
                            releaseDocuments?.c_of_c !== undefined && releaseDocuments?.serviceable_label != [] ?
                                <div style={{ marginTop: "3px" }}>
                                    <ViewIcon
                                        style={{ cursor: "pointer" }}
                                        fontSize="small"
                                        htmlColor={ICON_COLOUR}
                                        onClick={() => {
                                            if (releaseDocuments?.c_of_c !== undefined && releaseDocuments?.serviceable_label != []) {
                                                window.open(releaseDocuments?.c_of_c.map(document => document.file_path
                                                ))
                                            }
                                            else {
                                                message.showToastMessage({
                                                    message: "No file Found.",
                                                    variant: "error",
                                                });
                                            }

                                        }}
                                    />
                                </div> : "N/A"} />
                        <GetInfoCard keyName={"Release Cert1 "} value={
                            <div style={{ marginTop: "3px" }}>
                                <ViewIcon
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        setIsRelease(true);
                                        setMsds(
                                            releaseDocuments.release_cert_1?.map((item) => ({
                                                ...item,
                                                url: item.file_path,
                                            }))
                                        );
                                    }}
                                    fontSize="small"
                                    htmlColor={ICON_COLOUR}
                                />
                            </div>
                        } />
                        <GetInfoCard keyName={"Release Cert2 "}

                            value={
                                <div style={{ marginTop: "3px" }}>
                                    <ViewIcon
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setIsRelease(true);
                                            setMsds(
                                                releaseDocuments.release_cert_2?.map((item) => ({
                                                    ...item,
                                                    url: item.file_path,
                                                }))
                                            );
                                        }}
                                        fontSize="small"
                                        htmlColor={ICON_COLOUR}
                                    />
                                </div>
                            } />
                        <GetInfoCard keyName={"Scrap Cert"}
                            value={releaseDocuments.data?.scrap_certificate !== undefined && releaseDocuments.data?.scrap_certificate.length != 0 ?
                                <div style={{ marginTop: "3px" }}>
                                    <ViewIcon
                                        style={{ cursor: "pointer" }}
                                        fontSize="small"
                                        htmlColor={ICON_COLOUR}
                                        onClick={() => {
                                            if (releaseDocuments.data?.scrap_certificate != undefined && releaseDocuments.data?.scrap_certificate != []) {
                                                window.open(releaseDocuments.data?.scrap_certificate.map(document => document.file_path
                                                ))
                                            }
                                            else {
                                                message.showToastMessage({
                                                    message: "No Label Found.",
                                                    variant: "error",
                                                });
                                            }
                                        }}

                                    />
                                </div> : "N/A"} />
                        <GetInfoCard keyName={"Unserviceable Label"} value={
                            releaseDocuments?.unserviceable_label != undefined && releaseDocuments?.unserviceable_label.length != 0 ?
                                <div style={{ marginTop: "3px" }}>
                                    <ViewIcon
                                        style={{ cursor: "pointer" }}
                                        fontSize="small"
                                        htmlColor={ICON_COLOUR}
                                        onClick={() => {
                                            if (releaseDocuments?.unserviceable_label != undefined && releaseDocuments?.unserviceable_label != []) {
                                                window.open(releaseDocuments?.unserviceable_label.map(document => document.file_path
                                                ))
                                            }
                                            else {
                                                message.showToastMessage({
                                                    message: "No label Found.",
                                                    variant: "error",
                                                });
                                            }

                                        }}
                                    />
                                </div> : "N/A"} />
                    </div>
                </Box>
            </Box>
            <Typography
                style={{
                    color: theme.palette.primary.main,
                    margin: "15px 0px 12px",
                    fontSize: "17px",
                }}
                color="primary"

            >
                Client Acceptance
            </Typography>
            <form noValidate onSubmit={handleSubmit}>
                <Box
                    display="flex"
                    alignItems="center"

                    bgcolor="#F5F5F5"
                    className={classes.partCard}
                >
                    <Box flexGrow={1}>
                        <div style={{ width: "100%" }}>
                            <GetInfoCard keyName={"Name"} value={releaseDetails?.client_name} />
                            <GetInfoCard keyName={"Position"} value={releaseDetails?.client_position} />
                            <GetInfoCard keyName={"Date / Time"} value={_formatDate(releaseDetails?.accepted_at)} />
                            <GetInfoCard keyName={"Signature"} value="" />
                            <Grid>
                                <SignatureFile
                                    defaultValue={releaseDetails?.client_signature?.map(
                                        (file) => ({
                                            filename: file?.file_name,
                                            url: file?.file_path,
                                            id: file?.id,
                                        })
                                    )}
                                    APIUrl={""}
                                    uploadButtonView={false}
                                />
                            </Grid>
                        </div>
                    </Box>
                </Box>

                <Typography
                    style={{
                        color: theme.palette.primary.main,
                        margin: "15px 0px 12px",
                        fontSize: "17px",
                    }}
                    color="primary"

                >
                    Site Clearance
                </Typography>
                <Box
                    display="flex"
                    alignItems="center"
                    bgcolor="#F5F5F5"
                    className={classes.partCard}
                >
                    <Box flexGrow={1}>
                        <div style={{ width: "100%" }}>
                            <GetInfoCard keyName={"Work site cleared of FOD"} value={releaseDetails?.free_from_fod ? "Yes" : "No"} />
                            <GetInfoCard
                                keyName={"All tooling & Equip removed from site"}
                                value={releaseDetails?.tool_equip_removed ? "Yes" : "No"}
                            />
                            <GetInfoCard keyName={"Security passes returned"} value={releaseDetails?.security_passes_returned ? "Yes" : "No"} />
                            <GetInfoCard keyName={"Completed by"} value={releaseDetails?.completed_by_userstamp} />
                            <GetInfoCard keyName={"Comments"} value="" />
                            <Grid container>
                                <MROTextField
                                    name={"comments"}
                                    label={"Comments *"}
                                    placeholder={""}
                                    variant="outlined"
                                    // defaultValue={releaseDetails?.comments}
                                    error={errors.comments}
                                    value={releaseComments}
                                    disabled={editTab ? false : true}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => { setReleaseComments(e.target.value); resetErrorsHandler(e.target.name) }}
                                    style={{ backgroundColor: editTab ? '#fff' : "" }}
                                ></MROTextField>
                            </Grid>
                        </div>
                    </Box>
                </Box>
                {/* <Grid style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
                    <MROButton
                        value={editTab}
                        variant={"contained"}
                        style={{
                            backgroundColor: editTab ? "red" : "#FFB000",
                            color: "white",
                        }}
                        onClick={() => handleTab()}
                        startIcon={editTab ? <img src={Unlock} style={{ height: '18px' }} /> : <img src={LockIcon} style={{ height: '18px', filter: "invert(1)" }} />}
                    >
                        {editTab ? "Close Tab" : "Edit Tab"}
                    </MROButton>
                </Grid> */}
                <Grid style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
                    {orderStateNum > 9 && tabStatusAFB && props?.permission.write &&
                        <MROButton
                            type="button"
                            variant={"contained"}
                            style={{
                                backgroundColor: "#FFB000",
                                color: "white",
                                display: editTab ? "none" : undefined,
                            }}
                            onClick={() => handleTab()}
                            startIcon={<img style={Styles.svg} src={lock} alt="lock" />}
                        >
                            Edit Tab
                        </MROButton>}

                    {orderStateNum > 9 && closeTabStatusAFB === 2 && props?.permission.write && (
                        <MROButton
                            variant={"contained"}
                            style={{
                                backgroundColor: "red",
                                color: "white",
                                display: !editTab ? "none" : undefined,
                            }}
                            type="submit"
                            loading={busy === "loading"}
                            startIcon={<img style={Styles.svg} src={unlock} alt="unlock" />}
                            onClick={() => setCloseTab(true)}
                        >
                            Close Tab
                        </MROButton>
                    )}
                </Grid>
            </form>
            {
                isRelease &&
                <ReleaseCertPopup
                    dialogCloseHandler={() => setIsRelease(false)}
                    open={isRelease}
                    msds={msds}
                />
            }

        </div>)
}


export default AwayFromBase
