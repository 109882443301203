import MaterialTable from "material-table";
import React, { useReducer } from "react";
import tableReducer, { INITIAL_TABLE_STATE } from "reducers/table_reducer";
import tableIcons, { options } from "components/universal/table_attributes";
import TableAction from "actions/table_actions";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PositiveSwitch from "components/form_components/switch";
import { Box, FormControlLabel } from "@material-ui/core";
// import { IconButton } from "@material-ui/core";
// import {Visibility as ViewIcon} from "@material-ui/icons/Visibility";
// import { ICON_COLOUR } from "lib/constants/style_constants";
import MROButton from "components/buttons";

const useStyles = makeStyles(() => ({}));

function MaintActions() {
  // const classes = useStyles();
  const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
  const { page, totalCount, pageSize } = tableState;

  const tableOptions = {
    ...options,
    page: page,
    total: totalCount,
    pageSize: pageSize,
    search: false,
    toolbar: false
  };

  const columns = [
    {
      title: "Action #",
      field: "actions",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      title: "Title",
      field: "title",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        textAlign: "left",
        width: "70%",
      },
    },
    {
      title: "Man-Hrs",
      field: "man_hrs",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      title: "Stages",
      field: "stages",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      title: "Tech Data",
      headerStyle: {
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
      },
      //   render: (rowData) => (
      //       <div>
      //     <IconButton
      //       onClick={() => {
      //         // setAction("view_min_max");
      //         // setCurrentSelection(rowData);
      //       }}
      //     >
      //       <ViewIcon fontSize="small" htmlColor={ICON_COLOUR} />
      //     </IconButton>
      //     </div>
      //   ),
    },
    {
      title: "Action",
      headerStyle: {
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (rowData) => (
        <div>
          <FormControlLabel
            control={
              <PositiveSwitch
                size="small"
                edge="end"
                checked={false}
                inputProps={{ "aria-labelledby": "on-or-off" }}
              // onChange={(e) => setShow(!show)}
              />
            }
          />
        </div>
      ),
    },
  ];
  return (
    <div>
      <div className={"align-right"}>
        <span>Select all items for copy</span>
        <span>
          <PositiveSwitch
            edge="end"
            checked={false}
            inputProps={{ "aria-labelledby": "on-or-off" }}
          // onChange={(e) => setShow(!show)}
          />
        </span>
      </div>
      <MaterialTable
        style={{
          boxShadow: "0px 1px 3px #00000033",
          marginTop: "10px",
        }}
        icons={tableIcons}
        title={""}
        columns={columns}
        data={[
          {
            actions: "Status / Work",
            title: "Inspect / Test",
            man_hrs: "76.0",
            stages: "4"
          },
          {
            actions: "Status / Work",
            title: "Inspect / Test",
            man_hrs: "76.0",
            stages: "4"
          },
          {
            actions: "Status / Work",
            title: "Inspect / Test",
            man_hrs: "76.0",
            stages: "4"
          },
          {
            actions: "Status / Work",
            title: "Inspect / Test",
            man_hrs: "76.0",
            stages: "4"
          },
        ]}
        isLoading={false}
        options={tableOptions}
        onChangePage={(page) => {
          TableAction.setPage(dispatch, page);
        }}
        onChangeRowsPerPage={(pageSize) => {
          TableAction.setPageSize(dispatch, pageSize);
        }}
        totalCount={totalCount}
        page={page}
      />
      <Box width="100%" display="flex" justifyContent="flex-end" mt="25px">
        <MROButton
          type="submit"
          variant={"contained"}
          color="primary"
        // loading={busy === "loading"}
        >
          Copy & Next
        </MROButton>
      </Box>
    </div>
  );
}

export default MaintActions;
