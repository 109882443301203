import React, { useState, useEffect, useContext } from "react";
import MROFullScreenRightDrawer from "components/fullwidth_dialog";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import Typography from "@material-ui/core/Typography";
import { Box, CircularProgress } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { ICON_COLOUR } from "lib/constants/style_constants";
import MROButton from "components/buttons";
import EditStageOrder from "./EditStageOrder";
import EditActionInfo from "./EditActionInfo";
import FileUpload from "components/form_components/fileUpload";
import { BASE_API_URL_BOXP } from "lib/constants";
import { InspectionServices } from "lib/services/api/operaitons/WorkShop/Inspection/InspectionService";
import { ToastMessageContext } from "lib/contexts/message_context";
import moment from "moment";
import _ from "lodash";
import { formatDateFunction } from "lib/utils/common_utils";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: "3%",
  },
  root: {
    backgroundColor: "#ffffff",
  },
  appBar: {
    backgroundColor: "#fff",
    color: "#000000CC",
    boxShadow: "0px 3px 6px #0000001A",
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  paper: {
    borderRadius: "5px",
    backgroundColor: "#F5F5F5",
    padding: theme.spacing(2),
  },
  partValue: {
    fontSize: "14px",
    color: "#4D4F5C",
    padding: "2px",
    display: "flex",
    alignItems: "center",
  },
  partKey: {
    "& p": {
      fontSize: "14px",
      padding: "1px",
    },
  },
  partCard: {
    borderRadius: "8px",
    padding: "17px 24px",
    "& p": {
      fontSize: "14px",
      padding: "5px",
    },
  },
  sec1: {
    borderRadius: "10px",
    backgroundColor: "#fff",
    padding: theme.spacing(5),
    boxShadow: "0px 1px 4px #0000001A",
  },
  EditIcon: {
    bgcolor: "primary",
    color: "#fff",
  },
  editButton: {
    marginLeft: "300px",
  },
}));

function ActionInformation(props) {
  const classes = useStyles();
  const {
    handleClose,
    selectedId,
    getAllActions,
    setSelectedRowId,
    styleProp,
    readProp,
  } = props;
  const [action, setAction] = useState(null);
  const message = useContext(ToastMessageContext);
  const [loading, setLoading] = useState(false);
  const [stageDetails, setStageDetails] = useState([]);
  const [selectedAction, setSelectedAction] = useState([]);

  const handleEdit = () => {
    setAction("EditInfo");
  };

  const handleCloseEdit = () => {
    if (action === "EditInfo" || action === "EditActionInfo") {
      setAction(null);
    }
  };

  useEffect(() => {
    getAllActionStages();
  }, []);

  const getAllActionStages = async () => {
    const params = {
      workshop_action_id: selectedId,
    };
    await InspectionServices.getAllActionStages(params)
      .then((res) => {
        if (res) {
          setStageDetails(res.data);
        }
      })
      .catch((err) => {
        message.showToastMessage({
          message: "Something went wrong. Try again!",
          variant: "error",
        });
      })
      .finally(() => {
        // setIsGettingDetails(false);
      });
  };

  useEffect(() => {
    getSelectedAction();
  }, []);

  const getSelectedAction = async () => {
    setLoading(true);
    await InspectionServices.getSelectedAction(selectedId)
      .then((res) => {
        if (res) {
          setLoading(false);
          setSelectedAction(res.data);
        }
      })
      .catch(() => {
        setLoading(false);
        message.showToastMessage({
          message: "Something went wrong. Try again!",
          variant: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCloseButton = () => {
    props.getAllActionStages();
    handleClose();
  };

  return (
    <MROFullScreenRightDrawer open={true}>
      <AppBar position="static" className={classes.appBar} elevation={0}>
        <Toolbar>
          <IconButton
            onClick={handleCloseButton}
            edge="start"
            className={handleClose}
            color="inherit"
            aria-label="back"
          >
            <BackIcon />
          </IconButton>
          <Typography variant="subtitle2" style={{ fontSize: "17px" }}>
            Action Information
          </Typography>
          <div style={{ display: styleProp }}>
            <IconButton style={{ marginLeft: "920px" }}>
              <EditIcon
                fontSize="small"
                htmlColor={ICON_COLOUR}
                onClick={() => setAction("EditActionInfo")}
              />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      <div className={classes.root}>
        <div className={classes.wrapper}>
          {loading && _.isEmpty(selectedAction) ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="700px"
            >
              <CircularProgress color="primary" size={40} />
            </Box>
          ) : (
            <>
              <Grid>
                <Typography
                  color="primary"
                  varient="h3"
                  style={{ marginBottom: "10px", marginTop: "10px" }}
                >
                  Action Details
                </Typography>
              </Grid>
              <Grid className={classes.paper}>
                <Box display="flex">
                  <Box flex={18} className={classes.partKey}>
                    <Typography color="primary">Raised by</Typography>
                  </Box>
                  <Box flex={38} className={classes.partKey}>
                    <Typography>{selectedAction?.raised_by}</Typography>
                  </Box>
                  <Box flex={18} className={classes.partKey}>
                    <Typography color="primary">Raised</Typography>
                  </Box>
                  <Box flex={38} className={classes.partKey}>
                    <Typography>
                      {formatDateFunction(selectedAction?.raised_date_time)}
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex">
                  <Box flex={18} className={classes.partKey}>
                    <Typography color="primary">ATA Chapter</Typography>
                  </Box>
                  <Box flex={38} className={classes.partKey}>
                    <Typography>{selectedAction?.ata_chapter}</Typography>
                  </Box>
                  <Box flex={18} className={classes.partKey}>
                    <Typography color="primary">Estimated Man-Hrs</Typography>
                  </Box>
                  <Box flex={38} className={classes.partKey}>
                    <Typography>
                      {selectedAction?.estimate_man_hours}
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex">
                  <Box flex={18} className={classes.partKey}>
                    <Typography color="primary">Mech Auth</Typography>
                  </Box>
                  <Box flex={38} className={classes.partKey}>
                    <Typography>{selectedAction?.mech_auth}</Typography>
                  </Box>
                  <Box flex={18} className={classes.partKey}>
                    <Typography color="primary">Safety Critical</Typography>
                  </Box>
                  <Box flex={38} className={classes.partKey}>
                    <Typography>
                      {selectedAction?.critical_considerations === false
                        ? "No"
                        : "Yes"}
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex">
                  <Box flex={18} className={classes.partKey}>
                    <Typography color="primary">Insp Auth</Typography>
                  </Box>
                  <Box flex={38} className={classes.partKey}>
                    <Typography>{selectedAction?.insp_auth}</Typography>
                  </Box>
                  <Box flex={18} className={classes.partKey}>
                    <Typography color="primary">Ind Insp</Typography>
                  </Box>
                  <Box flex={38} className={classes.partKey}>
                    <Typography>
                      {selectedAction?.independent_insp_required === false
                        ? "No"
                        : "Yes"}
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex">
                  <Box flex={18} className={classes.partKey}>
                    <Typography color="primary">Title</Typography>
                  </Box>
                  <Box flex={38} className={classes.partKey}>
                    <Typography>{selectedAction?.title}</Typography>
                  </Box>
                  <Box flex={18} className={classes.partKey}>
                    <Typography color="primary"></Typography>
                  </Box>
                  <Box flex={38} className={classes.partKey}>
                    <Typography></Typography>
                  </Box>
                </Box>
              </Grid>
              <Typography
                color="primary"
                varient="h3"
                style={{ marginBottom: "10px", marginTop: "10px" }}
              >
                Tech Data
              </Typography>
              <Grid>
                <FileUpload
                  defaultValue={
                    selectedAction?.maintenance !== undefined
                      ? selectedAction?.maintenance.map((item) => ({
                          filename: item?.file_name,
                          url: `${item?.path}`,
                          id: item?.id,
                        }))
                      : ""
                  }
                  showBorderOnNoFiles={true}
                  APIUrl={""}
                  multiple={true}
                  name={"maintenance_file[]"}
                  action={"Upload Document"}
                  uploadButtonView={true}
                />
              </Grid>

              <Grid>
                <Typography
                  color="primary"
                  varient="h3"
                  style={{ marginBottom: "10px", marginTop: "10px" }}
                >
                  Verification
                </Typography>
              </Grid>
              <Grid className={classes.paper}>
                <Box display="flex">
                  <Box flex={18} className={classes.partKey}>
                    <Typography color="primary">Verified</Typography>
                  </Box>
                  <Box flex={38} className={classes.partKey}>
                    <Typography>
                      {selectedAction?.verified_date_time === null
                        ? "-"
                        : formatDateFunction(
                            selectedAction?.verified_date_time
                          )}
                    </Typography>
                  </Box>
                  <Box flex={18} className={classes.partKey}>
                    <Typography color="primary">Verified by</Typography>
                  </Box>
                  <Box flex={38} className={classes.partKey}>
                    <Typography>
                      {selectedAction?.verified_by === null
                        ? "-"
                        : selectedAction?.verified_by}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid>
                <Typography
                  color="primary"
                  varient="h3"
                  style={{ marginBottom: "10px", marginTop: "10px" }}
                >
                  Stages
                </Typography>
              </Grid>
              <Grid className={classes.paper}>
                <Box display="flex">
                  <Box flex={18} className={classes.partKey}>
                    <Typography color="primary">Number of Stages</Typography>
                  </Box>
                  <Box flex={38} className={classes.partKey}>
                    <Typography>{stageDetails.length || "-"}</Typography>
                  </Box>
                  <Box flex={18} className={classes.partKey}>
                    <Typography color="primary">Order</Typography>
                  </Box>
                  <Box flex={38} className={classes.partKey}>
                    <Typography>
                      {(
                        <MROButton
                          variant="contained"
                          color="primary"
                          startIcon={<EditIcon />}
                          onClick={handleEdit}
                          style={{ display: readProp ? "none" : "-" }}
                        >
                          Edit
                        </MROButton>
                      ) || "-"}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid>
                <Typography
                  color="primary"
                  varient="h3"
                  style={{ marginBottom: "10px", marginTop: "10px" }}
                >
                  Man-Hrs
                </Typography>
              </Grid>
              <Grid className={classes.paper}>
                <Box display="flex">
                  <Box flex={18} className={classes.partKey}>
                    <Typography color="primary">Actual Man-hrs</Typography>
                  </Box>
                  <Box flex={38} className={classes.partKey}>
                    <Typography>{"-"}</Typography>
                  </Box>
                  <Box flex={18} className={classes.partKey}>
                    <Typography color="primary"></Typography>
                  </Box>
                  <Box flex={38} className={classes.partKey}>
                    <Typography></Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid>
                <Typography
                  color="primary"
                  varient="h3"
                  style={{ marginBottom: "10px", marginTop: "10px" }}
                >
                  Closure
                </Typography>
              </Grid>
              <Grid className={classes.paper}>
                <Box display="flex">
                  <Box flex={18} className={classes.partKey}>
                    <Typography color="primary">Closed</Typography>
                  </Box>
                  <Box flex={38} className={classes.partKey}>
                    <Typography>{selectedAction?.closed || "-"}</Typography>
                  </Box>
                  <Box flex={18} className={classes.partKey}>
                    <Typography color="primary">Closed by</Typography>
                  </Box>
                  <Box flex={38} className={classes.partKey}>
                    <Typography>{selectedAction?.closed_by || "-"}</Typography>
                  </Box>
                </Box>
              </Grid>
            </>
          )}
        </div>
      </div>

      {action === "EditInfo" && (
        <EditStageOrder
          handleClose={handleCloseEdit}
          closeDrawer={handleClose}
          stageDetails={stageDetails}
          workshop_id={selectedId}
          getAllActionStages={props.getAllActionStages}
        />
      )}
      {action === "EditActionInfo" && (
        <EditActionInfo
          selectedId={selectedId}
          closeHandler={handleClose}
          handleClose={handleCloseEdit}
          selectedAction={selectedAction}
          getAllActions={getAllActions}
          getSelectedAction={getSelectedAction}
          setSelectedRowId={setSelectedRowId}
        />
      )}
    </MROFullScreenRightDrawer>
  );
}

export default ActionInformation;
