import React, { useContext, useEffect } from "react";

import { Box, Typography } from "@material-ui/core";
import QuantityCounter from "components/quantity_counter";
import MROButton from "components/buttons";
import { useReturnIssuedPartStyles } from "./styles";
import { ToastMessageContext } from "lib/contexts/message_context";

function StepOne(props) {
  const {
    submitHandler,
    setQty,
    qty,
    onClose,
    busy,
    currentSelection,
    errors,
    setErrors,
    qtyFieldTitle,
  } = props;

  const classes = useReturnIssuedPartStyles();

  const message = useContext(ToastMessageContext);

  useEffect(() => {
    console.log("renders.....");
    if (currentSelection?.quantity && currentSelection?.quantity < qty) {
      // message.showToastMessage({
      //   message: "QTY must not be greater than allocation!",
      //   variant: "error",
      // });
      setErrors((prevState) => ({
        ...prevState,
        quantity: `QTY must not be greater than allocation!`,
      }));
    } else {
      setErrors((prevState) => ({ ...prevState, quantity: null }));
    }
  }, [qty]);

  return (
    <div className={classes.firstStepRoot}>
      <Typography align="center" className={classes.heading}>
        Quantity
      </Typography>
      <Typography className={classes.bodyText}>
        QTY of part being returned back to stock
      </Typography>

      <form
        noValidate
        onSubmit={submitHandler}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <QuantityCounter
          name={"quantity"}
          label={qtyFieldTitle ?? "Quantity"}
          placeholder={currentSelection?.quantity}
          required
          setQty={setQty}
          qty={qty}
          autoFocus={errors?.quantity}
          helperText={
            errors?.quantity ? (
              errors?.quantity
            ) : (
              <span style={{ opacity: 0, visibility: 'hidden' }}>QTY must not be greater than allocation!</span>
            )
          }
          setError={() => setErrors({ quantity: null })}
          error={errors.quantity}
        />

        <Box margin="0 auto" mt="48px" display="flex" justifyContent="center">
          <MROButton
            style={{ marginRight: "20px" }}
            type="button"
            variant={"contained"}
            onClick={onClose}
          >
            Cancel
          </MROButton>
          <MROButton
            loading={busy === "loading"}
            type="submit"
            variant={"contained"}
            color="primary"
          >
            Next
          </MROButton>
        </Box>
      </form>
    </div>
  );
}

export default StepOne;
