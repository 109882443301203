import MaterialTable from "material-table";
import React, { useReducer, useState, useEffect, useContext } from "react";
import tableReducer, { INITIAL_TABLE_STATE } from "reducers/table_reducer";
import tableIcons, { options } from "components/universal/table_attributes";
import TableAction from "actions/table_actions";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FileCopy from '@material-ui/icons/FileCopy';
import { Box, FormControlLabel } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import CopyFileIcon from "assets/CopyFiles.svg"
import GreenCopyIcon from "assets/GreenCopyIcon.svg"
import { Visibility as ViewIcon } from "@material-ui/icons/Visibility";
import { ICON_COLOUR } from "lib/constants/style_constants";
import MROButton from "components/buttons";
import AdditionalData from "./AdditionalData";
import alertIcon from 'assets/icons/Alert_icon.png'
import { ToastMessageContext } from "lib/contexts/message_context";
import { WorkshopContext } from "views/Operations/Workshop/WorkshopContext/WorkshopContext";
const useStyles = makeStyles(() => ({}));



function Equipment(props) {
  const classes = useStyles();
  const { currentClosedDetails, currentPlanningDetails, copyTab } = props
  const [selectedRow, setSelectedRow] = useState({});
  const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
  const message = useContext(ToastMessageContext);
  const { page, totalCount, pageSize, inProgress, data } = tableState;
  const { copyEquipmentTabStatus, equipmentStatus } = useContext(WorkshopContext)
  const [action, setAction] = useState(false)
  const tableOptions = {
    ...options,
    page: page,
    total: totalCount,
    pageSize: pageSize,
    search: false,
    toolbar: false
  };
  useEffect(() => {
    getWorkshopEquipment();
  }, [page, pageSize]);

  const getWorkshopEquipment = () => {
    const params = {

      page: page + 1,
      id: currentClosedDetails?.id,
      count_per_page: pageSize,

    };
    TableAction.getList(dispatch, message, params, "getWorkshopEquipment");
  };

  const columns = [
    {
      title: "Action #",
      field: "workshop_action_number",
      headerStyle: {
        textAlign: "left",
        paddingLeft: "20px"
      },
      cellStyle: {
        textAlign: "left",
        paddingLeft: "33px"
      },
    },

    {
      title: "Description",
      field: "description",
      width: "80%",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        textAlign: "left",
        width: "80%",
      },
    },

    {
      title: "Action",
      headerStyle: {
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (rowData) => (
        <div>
          <IconButton
            onClick={() => {
              setAction("View");
              setSelectedRow(rowData);
              copyEquipmentTabStatus.push(rowData.id)
            }}
            disabled={equipmentStatus && copyEquipmentTabStatus.find((item) => item === rowData.id)}
          >

            {equipmentStatus &&
              copyEquipmentTabStatus.find((item) => item === rowData.id) ? <img src={GreenCopyIcon} height="25px" style={{ pointerEvents: equipmentStatus && copyEquipmentTabStatus.find((item) => item === rowData.id) ? "none" : "" }} /> :

              <img src={CopyFileIcon} height="25px" />
            }
          </IconButton>
        </div>
      ),
    },
  ];
  return (
    <div>

      <MaterialTable
        style={{
          boxShadow: "0px 1px 3px #00000033",
          marginTop: "10px",
        }}
        icons={tableIcons}
        title={""}
        columns={columns}
        data={data.data || []}
        isLoading={inProgress}
        options={tableOptions}
        onChangePage={(page) => {
          TableAction.setPage(dispatch, page);
        }}
        onChangeRowsPerPage={(pageSize) => {
          TableAction.setPageSize(dispatch, pageSize);
        }}
        totalCount={totalCount}
        page={page}
      />
      <div style={{ margin: ' 14px 0px 20px 3px' }}>
        <img src={alertIcon} height="15px" /> <span style={{ padding: '6px' }}> Copy Actions first! Parts must be associated to a new Action in the 'To' Order</span>
      </div>
      {action && action === "View" && <AdditionalData handleClose={() => setAction(null)} currentClosedDetails={currentClosedDetails} currentPlanningDetails={currentPlanningDetails} selectedRow={selectedRow} copyTab={copyTab} />}
    </div>
  );
}

export default Equipment;
