import * as React from "react";

import { Box } from "@material-ui/core";

import RenderInductionTab from "./Index";

export default function TabComponent(props) {
  return (
    <Box sx={{ width: "100%" }}>
      <RenderInductionTab {...props} />
    </Box>
  );
}
