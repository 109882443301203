import requestApi from "./common/boxp_api_client";

const deletionUrl = {
    deleteComment: "/action_comment",
    deleteActionData : "/action_imp",
    deletePriceBreak: "/price_breaks"
}

function boxpDeleteData(id, section, data, params) {
    let url = params ? `${deletionUrl[section]}${params ? '?id=' + id : id || ''}`
        : `${deletionUrl[section]}/${id || ''}`
    return requestApi({
        url: url,
        method: 'DELETE',
        data: id ? '' : data
    })
}

export default boxpDeleteData;