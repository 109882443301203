import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
                size="large"
            >
                {theme.direction === 'rtl' ? <LastPageIcon style={{ transform: 'rotate(90deg)', marginTop: '0px' }} /> : <FirstPageIcon style={{ transform: 'rotate(90deg)', marginTop: '0px' }} />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page" size="large">
                {theme.direction === 'rtl' ? <KeyboardArrowRight style={{ transform: 'rotate(90deg)', marginTop: '4px' }} /> : <KeyboardArrowLeft style={{ transform: 'rotate(90deg)', marginTop: '4px' }} />}
            </IconButton>
            <IconButton
                size="large"
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft style={{ transform: 'rotate(90deg)', marginTop: '4px' }} /> : <KeyboardArrowRight style={{ transform: 'rotate(90deg)', marginTop: '4px' }} />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
                size="large"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon style={{ transform: 'rotate(90deg)', marginTop: '1px' }} /> : <LastPageIcon style={{ transform: 'rotate(90deg)', marginTop: '1px' }} />}
            </IconButton>
        </div>
    );
}






const useStyles2 = makeStyles({
    table: {
        minWidth: 250,
    },
});

export default function CustomPaginationActionsTable({ rowPerPageOptions, count, label, page, setPage, rowsPerPage, setRowsPerPage }) {
    const classes = useStyles2();




    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (


        <TablePagination
            component="div"
            rowsPerPageOptions={rowPerPageOptions}
            colSpan={3}
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
            labelRowsPerPage={label}
        />



    );
}
