// import request from "../../common/api_client";
import requestrApi from "../../common/boxr_api_client";
const BASE_PATH = "/part_conditions";

function getAllPartHistory(params) {
  return requestrApi({
    url: `${BASE_PATH}/listing_parts`,
    method: "GET",
    params: params,
  });
}

function getHistoryDependency(params) {
  return requestrApi({
    url: `${BASE_PATH}/fetch_dependencies`,
    method: "GET",
    params: params,
  });
}

function fetchDependencies() {
  return requestrApi({
    url: `${BASE_PATH}/fetch_dependencies`,
    method: "GET",
  });
}

function getConditionedParts(params) {
  return requestrApi({
    url: `/part_conditions/part_condition_info`,
    method: "GET",
    params: params,
  });
}

const HistoryClientServices = {
  getHistoryDependency,
  getAllPartHistory,
  fetchDependencies,
  getConditionedParts,
};

export default HistoryClientServices;
