import React, { useContext, useEffect, useState } from "react";
import MROButton from "../buttons";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import FileIcon from "@material-ui/icons/AttachFile";
import { ReactComponent as UploadIcon } from "../../assets/icons/UploadDoc.svg";
import DataService from "../../lib/services/api";
import { ToastMessageContext } from "lib/contexts/message_context";

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    input: {
        display: 'none',
    },
}));

export default function CapabilityFileUpload(props) {
    const classes = useStyles();
    const { hideExistingFiles, accept, name, action, multiple, updateTheCertUpdatedFlag, handleImageChange, defaultValue, error, process, id, required, disabled } = props;
    const [files, setFiles] = useState();
    const [loading, setLoading] = useState(false);

    const fileList = Array.from(files || []);
    const message = useContext(ToastMessageContext)
    useEffect(() => {
        if (process && files && files[0] && id) {
            setLoading(true);
            const formData = new FormData();
            formData.append(name, files[0]);
            formData.append('id', id);
            DataService.updateData(formData, "", 'authDoc').then((res) => {
                setLoading(false);
            })
        }
    }, [process])

    // const handleChange = (e) => {

    //     setFiles(e.target.files);
    //     handleImageChange && handleImageChange(e);
    // }

    const handleChange = (e) => {
        let bytes = e.target.files[0].size
        let fileSize = Math.round(bytes / (1024 * 1024))
        if (multiple && fileSize < 20) {
            const tempFiles = e.target.files;
            setFiles((prevState) => [...prevState, ...tempFiles]);
            handleImageChange && handleImageChange(e);
        } else if (!multiple && fileSize < 20) {
            setFiles(Array.from(e.target.files));
            handleImageChange && handleImageChange(e);
        } else {
            message.showToastMessage({
                message: "20 MB maximum file size",
                variant: "error"
            })
        }
    };
    if (fileList.length > 0) {
        typeof updateTheCertUpdatedFlag === "function" && updateTheCertUpdatedFlag(true)
    }

    return (
        <div>
            <input
                accept={accept || "*"}
                className={classes.input}
                id="upload-file"
                multiple={multiple || false}
                type="file"
                name={name}
                onChange={handleChange}
                disabled={disabled}
            />
            <label htmlFor="upload-file">
                <MROButton disabled={disabled} variant="contained" color="secondary" component="span" startIcon={<UploadIcon />}>
                    {action ? action : "Upload"}
                </MROButton>
            </label>
            {
                <div>
                    {defaultValue && defaultValue.length > 0 && <Typography variant={"subtitle2"} color="secondary">Existing Files:</Typography>}
                    {defaultValue && defaultValue.map((file, i) => <div key={`file-${i}`}>
                        {file.name ? <Typography style={{ textDecoration: 'none', color: 'inherit' }} component={"a"} href={file.url ? file.url : file.document_url || "#"} target={file.url ? "_blank" : '_blank'} className={"card-tile-secondary"}><FileIcon />&nbsp;&nbsp;{file.name}</Typography> : "No Files found"}
                    </div>)}

                </div>
            }

            <div style={{ padding: '15px 10px 5px 0px' }}>

                {
                    error && required && fileList.length <= 0 && defaultValue.length <= 0 &&
                    <Typography color="error" variant="subtitle2">{error}</Typography>
                }
                {fileList && fileList.length > 0 && <Typography variant={"subtitle2"} color="secondary">Chosen Files(new)1:</Typography>}
                {fileList && fileList.map(file => <div key={file.lastModified}>
                    <Typography component={"div"} className={"card-tile-secondary"}><FileIcon />&nbsp;&nbsp;{file.name}</Typography>
                </div>)}
            </div>
        </div>
    )
}
