import React, { useReducer, useEffect, useState, useContext } from 'react'
import { Grid, Paper, Typography, Box, Button } from '@material-ui/core'
import tableIcons, { options } from '../../../components/universal/table_attributes'
import MaterialTable from 'material-table'
import moment from 'moment'
import tableReducer, {
  INITIAL_TABLE_STATE,
} from 'reducers/table_reducer';
import { UserDashboardServices } from 'lib/services/api/dashboard/user_compiance';
import TableAction from 'actions/table_actions'
import { ToastMessageContext } from "lib/contexts/message_context";
import { format24HrDateOnly, getOrganisationId } from 'lib/utils/common_utils'
import useDebounce from 'lib/utils/debounce'



export const statusMapButtons = {
  "Good": "button-positive",
  "On track": "button-positive",
  "Pending": "button-warning",
  "Expired": "button-negative",
  "Low": "button-warning",
  "Not Complete": "button-negative",
  "Not Acknowledge": "button-negative",
  "Pass": "button-success",
  "Fail": "button-negative",
  "Failed": "button-neagtive"
}
export const statusMapText = { 'Good': 'success', 'Pending': 'warning', 'Overdue': 'error' };
export default function ReadAndSigns(props) {
  const { selectedBase } = props
  const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
  const { page, pageSize, inProgress } = tableState;
  const [totalCount, setTotalCount] = useState(10);
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const message = useContext(ToastMessageContext);
  const [searchText, setSearchText] = useState('')
  const debounceVal = useDebounce(searchText)


  const readSignListing = async () => {
    setLoading(true)
    const params = {
      organisation_id: getOrganisationId(),
      dial_name: 'read_sign',
      page: page + 1,
      limit: pageSize,
      search: debounceVal
    }
    selectedBase.forEach((item, index) => params[`base_station_id[${[index]}]`] = item)
    await UserDashboardServices.getUserComplianceListing(params).then((response) => {
      setData(response.data)
      setTotalCount(response.total_count);
    }).catch(() => {
      message.showToastMessage({
        message: "Something went wrong",
        variant: "error",
      });
    }).finally(() => {
      setLoading(false)
    })

  }

  useEffect(() => {
    readSignListing()
  }, [page, pageSize, selectedBase, totalCount, debounceVal])




  const columns = [
    {
      title: 'Status',
      field: 'status',
      render: rowData => <Button size="small" className={statusMapButtons[rowData.status]} variant="outlined"><b>{rowData.status}</b></Button>
    },
    {
      title: 'Stamp',
      field: 'stamp',

    },
    {
      title: 'User',
      field: 'user',

    },
    {
      title: 'Base',
      field: 'base',

    },
    {
      title: 'Item',
      field: 'item',
      width: '50%',
      headerStyle: {
        textAlign: 'left',
        width: '50%',
      },
      cellStyle: {
        textAlign: 'left',
        width: '50%',
      }

    },
    {
      title: 'Issued',
      field: 'issued',
      align: 'center',
      render: rowData => rowData?.issued ? <span style={{ fontWeight: 600, color: rowData.age > 7 ? '#FF0202' : (rowData.age >= 0 && rowData.age <= 7) ? '#FFB300' : '' }} >{moment(rowData.issued, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY')}</span> : '-'
    },

    {
      title: 'Age',
      field: 'age',
      render: rowData => rowData.age ? <span>
        <Typography style={{ fontWeight: 600, color: rowData.age > 7 ? '#FF0202' : (rowData.age >= 0 && rowData.age <= 7) ? '#FFB300' : '' }} >
          {
            rowData.age
          }
        </Typography>
      </span > : '-'

    },

  ]

  const tableOptions = {
    ...options,
    page: page,
    total: totalCount,
    pageSize: pageSize,
    search: true,
    toolbar: true,
    headerStyle: {
      paddingTop: "19px",
      paddingBottom: "19px",
      textAlign: "center",
    },
  };

  return (
    <>
      <Grid xs={12} md={12}>
        <Box p={1}>
          <MaterialTable
            style={{ boxShadow: '0px 1px 3px #00000033', paddingRight: '0px' }}
            icons={tableIcons}
            title={<Typography color="primary" variant="subtitle2">
              Read & Signs
            </Typography>}
            data={data || []}
            columns={columns}
            options={tableOptions}
            isLoading={loading}
            onChangePage={(page) => {
              TableAction.setPage(dispatch, page);
            }}
            onChangeRowsPerPage={(pageSize) => {
              TableAction.setPageSize(dispatch, pageSize);
            }}
            onSearchChange={(search) => {
              setSearchText(search);
            }}
            totalCount={totalCount}
            page={page}
          />
        </Box>
      </Grid>
    </>
  )
}

