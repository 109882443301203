
import { AppBar, Toolbar, makeStyles, IconButton, Typography, Grid, MenuItem, FormControlLabel, ButtonGroup, InputAdornment, Paper } from '@material-ui/core'
import MROFullScreenRightDrawer from 'components/fullwidth_dialog'
import React, { useReducer, useState, useEffect, useContext } from 'react'
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import MROTextField from 'components/form_components/TextField';
// import DateTimeField from 'components/form_components/DateTimeField';
import PositiveSwitch from 'components/form_components/switch';
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import QuantityCounter from "components/quantity_counter";
import MROButton from 'components/buttons';
import { Autocomplete } from '@material-ui/lab';
import PlanningTimeField from 'components/form_components/PlanningTimeField';
import PlanningLineMaintenance from 'lib/services/api/planning/LineMaintenance/lineEventsandSeries';
import { getOrganisationId } from 'lib/utils/common_utils';
import DateTimeField from 'components/form_components/LMEventsDateTimeField';
import _ from "lodash";
import { ToastMessageContext } from 'lib/contexts/message_context';
import moment from 'moment';
import { useMemo } from 'react';
import { getTimeDiffFormatted } from 'views/Operations/LineMaintenance/WorkOrder/section_detail';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import NumericIncrement from "components/numeric_increment_job_clockings";
import { LineMaintainenceServices } from 'lib/services/api/dashboard/line_maintenance';




const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: "#fff",
        color: "#000000CC",
        boxShadow: "0px 3px 6px #0000001A",
    },
    backButton: {
        marginRight: theme.spacing(2),
    },
    wrapper: {
        margin: "3%",
        // overflowX: "hidden !important"
    },
    title: {
        fontSize: '18px'
    },
    toggle: {
        // width: '70px',
        height: '31px',
        '&.MuiToggleButton-sizeSmall': {
            fontSize: "13px",
            padding: '11px'
        },
        '&.MuiToggleButtonGroup-root': {

            flexWrap: 'wrap',
            // width: '96%'
        }

    },
    actionButtons: {
        marginTop: '15px',
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
    },
    disableField: {
        pointerEvents: "none",
        backgroundColor: "#F5F5F5",
    },
    paper: {
        // position: 'absolute',
        // marginTop: '500px',

        height: 'max-content',
        // width: 'fit-content',
        '&.MuiAutocomplete-popper': {
            zIndex: '1000000 !important'
        }
    }

}));
function CreateLMEvents(props) {
    const classes = useStyles()
    const { handleClose, customerDetails, baseDetails, eventDetails, approvalTypes } = props
    const [qty, setQty] = useState({ man_hours: '' });
    const [plusDays, setPlusDays] = useState(0);
    const [mechQty, setMechQty] = useState();
    const [laeQty, setLaeQty] = useState();
    const [state, setState] = useState("single")
    const [depatureChecked, setDepatureChecked] = useState(true)
    const [filterData, setFilterData] = useState({});
    const [scopeTypes, setScopeTypes] = useState([])
    const [selectedScope, setSelectedScope] = useState([])
    const [selectedApprovals, setSelectedApprovalsType] = useState([])
    const [customer, setCustomer] = useState('')
    const [event, setEvent] = useState('')
    const [base, setBase] = useState('')
    const [plannedAircraft, setPlannedAircraft] = useState('')
    const [fixedDays, setFixedDays] = useState(["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"])
    const [falseDays, setFalseDays] = useState([])
    const message = useContext(ToastMessageContext)
    const [selectedDays, setSelectedDays] = useState([]);
    const [errors, setErrors] = useState({});
    const [arrivalTime, setArrivalTime] = useState(null)
    const [departureTime, setDepartureTime] = useState(null)
    const [groundTime, setgroundTime] = useState("")
    const [groundTimeWholeValue, setgroundTimeWholeValue] = useState("")
    const [notes, setNotes] = useState('')




    const PopperMy = (props) => {
        return <Paper {...props} className={classes.paper} placement="bottom-start" />;
    };


    function container() {
        // Use the fullscreen element if in fullscreen mode, otherwise just the document's body
        return document.fullscreenElement ?? document.body;
    }

    const getScopeOfArrrival = async () => {

        const params = {
            organisation_id: getOrganisationId()
        }
        await PlanningLineMaintenance.fetchScopeOfApproval(params).then((res) => {
            setScopeTypes(res.data)
        }).catch((response) => {

        })
    }

    useEffect(() => {
        getScopeOfArrrival()
    }, [])
    const resetErrorsHandler = (name) => {
        setErrors((e) => ({ ...e, [name]: false }));
    };
    const getDecimalConversion = (date) => {
        let arr1 = getTimeDiffFormatted(date)
        var arr = arr1.split(':');
        var dec = parseInt((arr[1] / 6) * 10, 10);
        var final = arr[0] + "." + dec
        setgroundTime(final)

    }

    useMemo(() => {
        if (arrivalTime !== null && departureTime !== null) {

            const format = "DD/MM/YYYY HH:mm:ss";
            const depatureDateTime = moment(departureTime, format);
            const actArrivalDateTime = moment(arrivalTime, format);
            if (depatureDateTime && actArrivalDateTime) {
                const duration = moment.duration(depatureDateTime.diff(actArrivalDateTime))
                let final = parseFloat(duration.asHours()).toFixed(2)
                let gndWholeValue = parseFloat(duration.asHours())

                setgroundTimeWholeValue(gndWholeValue)
                setgroundTime(final)


            }

            if (plusDays) {
                let departureData = new Date(depatureDateTime)
                let days = departureData.setHours(departureData.getHours() + (plusDays * 24))
                let departure = moment(days).format("DD/MM/YYYY HH:mm:ss")
                const finalDate = moment(departure, format)
                const duration = moment.duration(finalDate.diff(actArrivalDateTime))
                const final = parseFloat(duration.asHours()).toFixed(2)
                let gndWholeValue = parseFloat(duration.asHours())

                setgroundTimeWholeValue(gndWholeValue)
                setgroundTime(final)

            }
        }

    }, [arrivalTime, departureTime, plusDays])

    const handleSubmit = async (e) => {
        e.preventDefault()
        const formdata = new FormData(e.target)
        formdata.delete("dep_details_known")
        formdata.delete("notes")
        let valid = true;
        let error = {};
        let gnd_time = parseInt(formdata.get("gnd_time"))

        for (let pair of formdata.entries()) {
            const value = pair[1];
            const key = pair[0];
            // console.table("formdata=====", key, value)
            if (!value) {
                error[key] = true;
                valid = false;
            }
        }

        // if (gnd_time === 0 || groundTime === '') {
        //     valid = false;
        //     error = {
        //         ...error,
        //         gnd_time: `Gnd Time must be greater than 0!`,
        //     };
        // }
        if (!valid) {
            setErrors(error);
            return;
        }




        // console.log("formData====", formdata)

        formdata.delete("additional_aircraft")
        formdata.delete("gnd_time")
        formdata.append("gnd_time", groundTimeWholeValue)
        formdata.append("organisation_id", getOrganisationId())
        formdata.append("notes", notes ? notes : '')
        formdata.append("dep_details_known", depatureChecked)
        formdata.append("series", state === "single" ? false : true)
        if (state === "series") {
            selectedDays.forEach((item, index) => {
                formdata.append(`${item}`, true)
            })
            falseDays.forEach((item, index) => {
                formdata.append(`${item}`, false)
            })
        }

        selectedApprovals.forEach((item, index) => {
            formdata.append(`additional_aircraft[${[index]}]`, item.id)
        })
        selectedScope.forEach((item, index) => {
            formdata.append(`scope_of_approval[${[index]}]`, item.id)
        })
        await LineMaintainenceServices.CreateLineEvents(formdata).then((res) => {
            if (res.success) {
                message.showToastMessage({
                    message: "Events created successfully !",
                    variant: "success",
                });
                handleClose()
            }
        }).catch((res) => {
            handleClose()
            message.showToastMessage({
                message: res.error ? res.error : "Something went wrong. Try again!",
                variant: "error",
            });
        })
    }

    const handleSelectedDays = (event, days) => {


        setSelectedDays(days)
        let data = _.differenceWith(fixedDays, days, _.isEqual)
        setFalseDays(data)
    };

    // console.log("date======", selectedDays, falseDays)


    return (
        <div>
            <MROFullScreenRightDrawer open={true} container={container()}>

                <AppBar position="static" className={classes.appBar} elevation={0}>
                    <Toolbar>
                        <IconButton
                            onClick={handleClose}
                            edge="start"
                            className={classes.backButton}
                            color="inherit"
                            aria-label="back"
                        >
                            <BackIcon />
                        </IconButton>
                        <Typography variant="subtitle2" style={{ fontSize: "17px" }}>
                            Add Line Maintenance Events
                        </Typography>
                    </Toolbar>
                </AppBar>
                <div className={classes.wrapper}>
                    <form onSubmit={handleSubmit}>
                        <Typography variant='subtitle1' color='primary' className={classes.title}>Detail</Typography>

                        <Grid container xs={12} spacing={2}>
                            <Grid item xs={3}>
                                <MROTextField label={'Select Customer *'}
                                    select
                                    defaultValue={''}
                                    name={"line_customer_id"}
                                    value={customer}
                                    InputLabelProps={{ shrink: true }}
                                    error={errors.line_customer_id}

                                    onChange={(e) => {
                                        setCustomer(e.target.value)
                                        resetErrorsHandler("line_customer_id")
                                    }

                                    }>
                                    <MenuItem value={''}>Select</MenuItem>
                                    {customerDetails && customerDetails.map((value) => (
                                        <MenuItem value={value.id}>{value.name}</MenuItem>
                                    ))}
                                </MROTextField>
                            </Grid>
                            <Grid item xs={3}>
                                <MROTextField label={'Base *'}
                                    select
                                    name={"base_station_id"}
                                    defaultValue={''}
                                    value={base}
                                    InputLabelProps={{ shrink: true }}
                                    error={errors.base_station_id}

                                    onChange={(e) => {
                                        setBase(e.target.value)
                                        resetErrorsHandler("base_station_id")
                                    }
                                    }>
                                    <MenuItem value={""}>Select</MenuItem>
                                    {baseDetails && baseDetails.map((value) => (
                                        <MenuItem value={value.id}>{value.station}</MenuItem>
                                    ))}
                                </MROTextField>
                            </Grid>
                            <Grid item xs={3}>
                                <MROTextField label={'Event Type *'}
                                    select
                                    defaultValue={''}
                                    value={event}
                                    name={"event_type_id"}
                                    InputLabelProps={{ shrink: true }}
                                    error={errors.event_type_id}
                                    onChange={(e) => {
                                        setEvent(e.target.value)
                                        resetErrorsHandler("event_type_id")
                                    }
                                    }>
                                    <MenuItem value={""}>Select</MenuItem>
                                    {eventDetails && eventDetails.map((value) => (
                                        <MenuItem value={value.id}>{value.name}</MenuItem>
                                    ))}
                                </MROTextField></Grid>
                        </Grid>
                        <Grid container xs={12} spacing={2}>
                            <Grid item xs={3}>
                                <MROTextField
                                    name={'arr_flight_number'}
                                    label={'Arrival Flight # *'}
                                    placeholder="Flight # must match flight radar"
                                    error={errors.arr_flight_number}
                                    inputProps={{ maxLength: 20 }}
                                    onChange={(e) => {
                                        resetErrorsHandler("arr_flight_number")
                                    }
                                    }
                                    InputLabelProps={{ shrink: true }}>
                                </MROTextField>
                            </Grid>
                            <Grid item xs={3} style={{ paddingTop: '15px' }}>
                                <DateTimeField
                                    label={"Arr Date / Time (UTC) *"}
                                    name={'arr_datetime'}
                                    InputLabelProps={{ shrink: true }}
                                    error={errors.arr_datetime}
                                    helperText={errors.arr_datetime ? "*please enter a date" : null}
                                    style={
                                        { opacity: 0.55, cursor: "not-allowed !important" }}
                                    onChange={(e) => {
                                        resetErrorsHandler("arr_datetime");
                                        setArrivalTime(e)
                                    }}

                                />
                            </Grid>

                        </Grid>
                        <Grid xs={12} style={{ marginLeft: '-15px' }}>
                            <FormControlLabel

                                control={<PositiveSwitch
                                    checked={depatureChecked}
                                    // value={''}
                                    onChange={(e) => { setDepatureChecked(e.target.checked) }}
                                    name={'dep_details_known'} />}
                                label="Departure details known?"
                                labelPlacement="start"
                            />
                        </Grid>
                        <Grid container xs={12} spacing={2}>
                            {depatureChecked ? <>   <Grid item xs={3}>
                                <MROTextField
                                    name={'dep_flight_number'}
                                    label={'Departure Flight # *'}
                                    placeholder="Departure Flight #"
                                    error={errors.dep_flight_number}
                                    onChange={(e) => {
                                        resetErrorsHandler("dep_flight_number")
                                    }
                                    }
                                    inputProps={{ maxLength: 20 }}
                                    InputLabelProps={{ shrink: true }}>

                                </MROTextField>
                            </Grid>
                                <Grid item xs={3} style={{ paddingTop: '15px' }}>
                                    <DateTimeField
                                        label={"Dep Date / Time (UTC) *"}
                                        name={'dep_datetime'}
                                        InputLabelProps={{ shrink: true }}
                                        error={errors.dep_datetime}
                                        helperText={errors.dep_datetime ? "*please enter a date" : null}
                                        style={
                                            { opacity: 0.55, cursor: "not-allowed !important" }}
                                        onChange={(e) => {
                                            resetErrorsHandler("dep_datetime");
                                            setDepartureTime(e)
                                        }}
                                    />
                                </Grid>
                            </> : <Grid item xs={3} style={{ paddingTop: '15px' }}>
                                <DateTimeField
                                    label={"Dep Date / Time (UTC) *"}
                                    name={'dep_datetime'}
                                    InputLabelProps={{ shrink: true }}
                                    error={errors.dep_datetime}
                                    defaultValue={eventDetails?.dep_datetime}
                                    value={departureTime}
                                    helperText={errors.dep_datetime ? "*please enter a date" : null}
                                    style={
                                        { opacity: 0.55, cursor: "not-allowed !important" }}
                                    onChange={(e) => {
                                        resetErrorsHandler("dep_datetime");
                                        setDepartureTime(e)
                                    }}
                                />
                            </Grid>}

                            <Grid item xs={3} >
                                <MROTextField
                                    className={classes.disableField}
                                    name="gnd_time"
                                    error={errors?.gnd_time}
                                    label={"Gnd Time *"}
                                    variant="outlined"
                                    helperText={errors?.gnd_time}
                                    value={groundTime}
                                    InputLabelProps={{ shrink: true }}
                                    placeholder={"Hours"}
                                    onChange={(e) => {
                                        resetErrorsHandler("gnd_time");

                                    }}
                                    InputProps={{

                                        endAdornment: <InputAdornment position="end">
                                            <AccessTimeIcon />

                                        </InputAdornment>,

                                    }}

                                />
                                {/* <PlanningTimeField
                                    required
                                    fullWidth
                                    onlyTime
                                    pointerEvent={"none"}
                                    disabled={true}
                                    background={"#F5F5F5"}
                                    label={"Gnd Time *"}
                                    format={"HH:mm"}
                                    renderDate={"HH:mm"}
                                    placeholder=" hh:mm"
                                    name="gnd_time"
                                    defaultValue={groundTime}
                                    errors={errors.gnd_time}
                                    onChange={(e) => {
                                        resetErrorsHandler("gnd_time");
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                /> */}
                            </Grid>
                        </Grid>

                        {/*Planning Info */}
                        <Typography variant='subtitle1' color='primary' className={classes.title}>Planning Info</Typography>

                        <Grid container xs={12} spacing={2}>
                            <Grid item xs={3}>
                                <MROTextField label={'Planned Aircraft *'}
                                    select
                                    InputLabelProps={{ shrink: true }}
                                    value={plannedAircraft}
                                    name={"planned_aircraft"}
                                    error={errors?.planned_aircraft}
                                    onChange={(e) => {
                                        resetErrorsHandler(e.target.name)
                                        setPlannedAircraft(e.target.value)
                                    }
                                    }>
                                    <MenuItem value={""}>Select</MenuItem>
                                    {approvalTypes && approvalTypes.map((value) => (
                                        <MenuItem value={value.id}>{value.type_name}</MenuItem>
                                    ))}
                                </MROTextField>
                            </Grid>
                            <Grid item xs={3}>
                                <QuantityCounter
                                    name={"mech_quantity"}
                                    label={"Mechs Required *"}
                                    // shouldNotAcceptZero={true}
                                    placeholder="Mech QTY"
                                    error={errors["mech_quantity"]}
                                    helperText={
                                        errors["mech_quantity"] &&
                                        (errors["mech_quantity_is_zero"]
                                            ? "Mech QTY Required Must Be Greater Than 0"
                                            : `Please enter Mech Quantity required`)
                                    }
                                    setError={() =>
                                        setErrors((prevState) => ({
                                            ...prevState,
                                            mech_quantity: false,
                                        }))
                                    }
                                    setQty={setMechQty}
                                    qty={mechQty}
                                />

                            </Grid>
                            <Grid item xs={3}>
                                <QuantityCounter
                                    label={"LAEs Required *"}
                                    placeholder="QTY"
                                    name={'lae_quantity'}
                                    error={errors["lae_quantity"]}
                                    // shouldNotAcceptZero={true}
                                    helperText={
                                        errors["lae_quantity"] &&
                                        (errors["lae_quantity_is_zero"]
                                            ? "QTY Required Must Be Greater Than 0"
                                            : `Please enter LAE Quantity required`)
                                    }
                                    setError={() =>
                                        setErrors((prevState) => ({
                                            ...prevState,
                                            lae_quantity: false,
                                        }))
                                    }
                                    setQty={setLaeQty}
                                    qty={laeQty}
                                />

                            </Grid>
                            <Grid item xs={3}>
                                <NumericIncrement
                                    name={"total_man_hours"}
                                    label={"Total Planned Man-Hrs *"}
                                    placeholder="QTY"
                                    noIncreament
                                    error={errors["total_man_hours"]}
                                    helperText={
                                        errors["total_man_hours"] &&
                                        (errors["quantity_is_zero"]
                                            ? "QTY Required Must Be Greater Than 0"
                                            : `Please enter total Man hours required`)
                                    }
                                    setError={() =>
                                        setErrors((prevState) => ({
                                            ...prevState,
                                            total_man_hours: false,
                                        }))
                                    }
                                    inputProps={{ min: 0, step: 0.25 }}
                                    InputLabelProps={{ shrink: true }}
                                    setState={setQty}
                                    state={qty}
                                    value={qty.man_hours}
                                />

                            </Grid>
                        </Grid>
                        <Grid xs={12}>
                            <Autocomplete
                                getOptionSelected={(option, value) => option.id === value.id}
                                // value={selectedPart !== null && selectedPart}
                                PopperComponent={PopperMy}
                                id="tags-standard"
                                // loading={loading}
                                getOptionLabel={(option) => option?.type_name || ""}
                                options={approvalTypes || []}
                                fullWidth
                                multiple
                                onChange={(event, value) => {
                                    setSelectedApprovalsType(value);
                                    // setError({ ...error, part_number: null });
                                }}
                                renderInput={(params) => (
                                    <MROTextField
                                        {...params}
                                        label="Additional possible aircraft types"
                                        color={"primary"}
                                        // placeholder="Search"
                                        // name={"additional_aircraft"}
                                        margin="normal"
                                        variant="standard"
                                        id="test"
                                        InputLabelProps={{ shrink: true }}
                                    // autoFocus={error.part_number}
                                    // error={error.part_number}
                                    // helperText={
                                    //     error.part_number ? "*please select kit part" : null
                                    // }
                                    />
                                )}
                            />

                        </Grid>
                        <Grid xs={12}>
                            <Autocomplete

                                PopperComponent={PopperMy}
                                getOptionSelected={(option, value) => option.id === value.id}
                                id="tags-standard"
                                // loading={loading}
                                getOptionLabel={(option) => option?.type_name || ""}
                                options={scopeTypes && scopeTypes || []}
                                fullWidth
                                multiple
                                onChange={(event, value) => {
                                    setSelectedScope(value);
                                    // setErrors({ ...errors, scope_of_approval: null });
                                }}
                                renderInput={(params) => (
                                    <MROTextField
                                        {...params}
                                        label="Specific Scope of Approval"
                                        color={"primary"}
                                        margin="normal"
                                        variant="standard"
                                        id="test"
                                        InputLabelProps={{ shrink: true }}
                                    // name={'scope_of_approval'}
                                    // autoFocus={errors.scope_of_approval}
                                    // error={errors.scope_of_approval}
                                    // helperText={
                                    //     errors.scope_of_approval ? "*please select scoe of approval" : null
                                    // }
                                    />
                                )}
                            />

                        </Grid>
                        <Grid item xs={12}>
                            <MROTextField
                                name={"notes"}
                                label={"Notes"}
                                multiline
                                placeholder={"Add any specific planning notes"}
                                InputProps={{
                                    style: {
                                        height: 'max-content',
                                        width: "100%",
                                        // pointerEvents: disabled && "none",
                                        // backgroundColor: disabled && "#F5F5F5",
                                    },
                                }}
                                inputProps={{ maxLength: 200 }}
                                InputLabelProps={{ shrink: true }}
                                // error={errors.notes}
                                value={notes}

                                onChange={(e) => {
                                    setNotes(e.target.value);

                                }}



                            />
                        </Grid>
                        <Grid className={classes.actionButtons} spacing={1} container style={{ paddingBottom: '20px' }}>
                            <Grid item>
                                <MROButton
                                    type="button"
                                    variant={"contained"}
                                    onClick={handleClose}
                                >
                                    Cancel
                                </MROButton>
                            </Grid>
                            <Grid item>
                                <MROButton
                                    //   loading={busy === "loading"}
                                    type="submit"
                                    variant={"contained"}
                                    color="primary"
                                >
                                    Complete
                                </MROButton>
                            </Grid>
                        </Grid>
                    </form>
                </div>

            </MROFullScreenRightDrawer >


        </div >
    )
}

export default CreateLMEvents