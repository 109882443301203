import requestApi from "./common/boxp_api_client";

const creationUrl = {
    createContact: "/customer/contact",
    createEmail: "/customer/report_emails",
    createAddress: "/customer/address",
    createStorageRates:"/customer/storage_rates",
    createQuotation: "/quotation",
    planning: "/planning"
}

function BoxpCreateData(formData, section) {

    return requestApi({
        url: creationUrl[section],
        method: 'POST',
        data: formData
    })
}

export default BoxpCreateData;
