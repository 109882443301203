import React, { useReducer, useContext, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import MaterialTable from "material-table";
import tableReducer, { INITIAL_TABLE_STATE } from "reducers/table_reducer";
import tableIcons, {
    options,
} from "components/universal/table_attributes";
import TableAction from "actions/table_actions";
import HistoryDialog from "./HistoryDialog";
import { InspectionServices } from "lib/services/api/operaitons/WorkShop/Inspection/InspectionService";
import { ToastMessageContext } from "lib/contexts/message_context";
import { format24HrDateTime } from "lib/utils/common_utils";


export default function HistoryTablePopup(props) {
    const { open, data, handleClose, busy, selectedId, type } = props;

    const classes = useStyles();
    const message = useContext(ToastMessageContext)
    const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
    const { page, totalCount, pageSize } = tableState;
    const [historyData, setHistoryData] = useState([])

    // tableOptions
    const tableOptions = {
        ...options,
        page: page,
        total: totalCount,
        pageSize: pageSize,
        search: false,
        toolbar: false,
        paging: false,
        headerStyle: {
            textAlign: "center",
            paddingTop: "22px",
            paddingBottom: "16px",
        },
    };

    const columns = [
        {
            title: "Event",
            field: "event",
            // render: (rowData) => rowData?.status?.toUpperCase(),
        },
        {
            title: "User",
            field: "user",
        },
        {
            title: "Date / Time",
            field: "datetime",
            render:(rowData)=> format24HrDateTime(rowData?.date_time),
        },
    ];

    useEffect(()=>{
        const getHistoryData = async () => {
            const params = {
                id: selectedId,
                type: type ? "Stage" : "Action",
                page: page + 1,
                limit: 10
            }
            await InspectionServices.getHistoryData(params)
                .then((res) => {
                    if (res) {
                        setHistoryData(res.data);
                    }
                })
                .catch(() => {
                    message.showToastMessage({
                        message: "Something went wrong. Try again!",
                        variant: "error",
                    });
                });
        };
        getHistoryData()
    },[selectedId])

    

    return (
        <div style={{ position: "relative" }}>
            <HistoryDialog
                open={open}
                handleClose={handleClose}
                width="481px"
                notShowButton={true}
            >
                <Box
                    width="100%"
                    mb="30px"
                    ml="0px"
                >
                    <Typography color="primary" className={classes.bodyText}>
                        History
                    </Typography>
                </Box>

                <Box width="100%">
                    <MaterialTable
                        style={{ boxShadow: "0px 1px 3px #00000033" }}
                        icons={tableIcons}
                        columns={columns}
                        data={historyData || []}
                        options={tableOptions}
                        localization={{
                            toolbar: {
                                searchPlaceholder: "Search anything",
                            },
                            body: {
                                emptyDataSourceMessage: "No histories Found",
                                filterRow: {
                                    filterTooltip: "Filter",
                                },
                            },
                        }}
                        isLoading={busy === "loading"}
                        onChangePage={(page) => {
                            TableAction.setPage(dispatch, page);
                        }}
                        onChangeRowsPerPage={(pageSize) => {
                            TableAction.setPageSize(dispatch, pageSize);
                        }}
                        onSearchChange={(search) => {
                            TableAction.setSearchText(dispatch, search);
                        }}
                        totalCount={totalCount}
                        page={page}
                    />
                </Box>
            </HistoryDialog>
        </div>
    );
}

const useStyles = makeStyles(() => ({
    bodyText: {
        fontSize: 20,
        lineHeight: "28px",
    },
}));
