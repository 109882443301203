import { useState, useEffect } from "react";
import PrimePartsClientServices from "lib/services/api/procurement/primePart";

function useDependencies() {
  const [state, setState] = useState({
    baseStations: [],
    lineCustomers: [],
  });

  const fetchDependencies = () => {
    PrimePartsClientServices.fetchDependencies()
      .then((res) => {
        if (res) {
          setState({
            baseStations: res.base_stations,
            lineCustomers: res.line_customers,
          });
        } else {
          setState({ baseStations: [], lineCustomers: [] });
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    fetchDependencies();
  }, []);

  return [state.baseStations, state.lineCustomers];
}

export default useDependencies;
