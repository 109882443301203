import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Dialog, makeStyles } from "@material-ui/core";
import { Popover } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import MaterialTable from "material-table";
import tableReducer, { INITIAL_TABLE_STATE } from "reducers/table_reducer";
import tableIcons, {
    options,
} from "components/universal/table_attributes";
import TableAction from "actions/table_actions";
import PopupDialog from "./../QuotePopUp/PopupDialog"
import { MaintenanceServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation";
import LinearProgressBar from 'components/linear_progress_bar';





const StyledMenu = withStyles({
    paper: {
        width: "523px",
        height: "fit-content",
        top: "290px !important",
        padding: "30px",
        boxShadow: "0px 3px 6px #00000029",
    },
})((props) => (
    <Popover
        elevation={0}
        // getContentAnchorEl={null}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "center",
        }}
        {...props}
    />
));

function LabourPopup(props) {
    const { currentRow, open, handleClose, workshop_order_id } = props;

    const [data, setData] = useState([])
    const classes = useStyles();


    useEffect(() => {
        let row = { ...currentRow }
        setData([row])


    }, [currentRow])

    // tableOptions
    const tableOptions = {
        ...options,
        search: false,
        toolbar: false,
        paging: false,
        headerStyle: {
            textAlign: "center",
            paddingTop: "22px",
            paddingBottom: "16px",
        },
        cellStyle: {
            textAlign: 'center'
        },
        rowStyle: {
            height: '42px'
        }
    };
    const columns = [
        {
            title: "Item",
            field: "live_values",

        },
        {
            title: "Estimated",
            field: "est_labour",

        },
        {
            title: "Current",
            field: "labour",

        },
        {
            title: "Status",
            field: "status",


            render: (rowData) =>
                <div style={{ position: 'absolute', marginTop: '-10px' }}>
                    <LinearProgressBar
                        variant='determinate'
                        value={rowData.status}
                        width='70px'
                        barHeight='20px'
                        bgColor={(rowData.status > 0 && rowData.status < 75) ? '#4FC605' : (rowData.status >= 75 && rowData.status < 100) ? '#FFB300' : rowData.status >= 100 ? '#FF0202' : '#fff'}
                        color={rowData.status >= 100 ? "#FFFFFF" : "#4D4F5C"}
                    />
                </div>

        },
    ];


    return (
        <div style={{ position: "relative" }}>
            <PopupDialog
                open={open}
                handleClose={handleClose}
                width="481px"
                notShowButton={true}
            >
                <Box
                    width="100%"
                    mb="30px"
                    ml="0px"
                >
                    <Typography color="primary" className={classes.bodyText}>
                        Labour Overview
                    </Typography>
                </Box>

                <Box width="100%">
                    <MaterialTable
                        style={{ boxShadow: "0px 1px 3px #00000033" }}
                        icons={tableIcons}
                        columns={columns}
                        // isLoading={busy}
                        data={data || []}
                        options={tableOptions}



                    />
                </Box>
            </PopupDialog>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    bodyText: {
        fontSize: 20,
        lineHeight: "28px",
    },
}));

export default LabourPopup