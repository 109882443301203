import requestrApi from "lib/services/api/common/boxr_api_client";
// import requestApi from "../../../common/boxp_api_client";

const getConditionPartDetails = (params) => {
  return requestrApi({
    url: "/part_conditions/part_condition_details",
    method: "GET",
    params: params,
  });
};

export const ReturnIssuedPartServices = {
  getConditionPartDetails,
};
