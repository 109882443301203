import React, { useContext, useEffect, useState } from "react";

import MRODialog from "components/MRODialog";
import {
  Typography,
  Grid,
  makeStyles,
  MenuItem,
  FormControlLabel,
  ButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import MROTextField from "components/form_components/TextField";

import { userDetails, validateFormData } from "lib/utils/helperFunctions";
import MROOnSiteOffSite from "components/form_components/MRoOnSite_OffSite_Field";
// import ServiceRequestListClientServices from "lib/services/api/procurement/Services";
import { ToastMessageContext } from "lib/contexts/message_context";
import QuantityCounter from "components/quantity_counter";
import PositiveSwitch from "components/form_components/switch";
import YesNoField from "components/form_components/YesNoField";
import UploadButton from "components/form_components/upload_multiple";
import MROButton from "components/buttons";
import { StorageServices } from "lib/services/api/operaitons/WorkShop/Storage/StorageService";
import { Autocomplete } from "@material-ui/lab";
import { getOrganisationId } from "lib/utils/common_utils";
import { MaintenanceServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation";

const useStyles = makeStyles((theme) => ({
  formWrapper: {
    background: "#fff",
    width: "100%",
    "& input[name='part_number']": { textTransform: "uppercase" },
  },
  title: {
    "& h2": {
      color: theme.palette.primary.main,
    },
  },
  subTitle: {
    fontSize: "16px",
    fontWeight: "bold",
    marginBottom: "20px",
    color: "#4D4F5C",
  },
  card: {
    padding: "10px 25px",
  },
  actionButtons: {
    paddingBottom: "33px",
    textAlign: "right",
    "& button:not(:last-child)": {
      marginRight: "8px",
    },
  },
  partCard: {
    borderRadius: "8px",
    padding: "17px 24px",
  },
  partKey: {
    fontSize: "16px",
    padding: "5px",
  },
  partValue: {
    fontSize: "16px",
    color: "#4D4F5C",
    padding: "5px",
  },
  disableField: {
    pointerEvents: "none",
    backgroundColor: "#F5F5F5",
  },
  modal: {
    "& .MuiDialog-paperWidthMd": {
      maxWidth: "763px",
    },
  },
  purchaseGrid: {
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
  },
  costData: {
    "& > div": {
      display: "flex",
      "&:not(:last-child)": {
        marginBottom: "10px",
      },
      "& span:first-child": {
        color: "#4D4F5C",
        fontSize: "16px",
        flex: "0 0 30%",
      },
      "& span:last-child": {
        color: "#056191",
        fontSize: "16px",
      },
    },
  },
  cost: {
    "& div:first-child": {
      display: "flex",
      alignItems: "baseline",
      marginBottom: "10px",
      "& > p": {
        fontSize: "20px",
        marginLeft: "13px",
      },
    },
    "& div:last-child": {
      display: "flex",
      alignItems: "center",
      "& > p": {
        margin: "0",
        marginRight: "40px",
      },
    },
  },
  error: {
    borderColor: "red",
  },
  shelfLifeControlled: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "20px 0 25px",
    margiRight: "-11px",
    "& span:last-child": {
      fontSize: "14px",
    },
  },
}));

function NewLinkedMaintOrder(props) {
  console.log("LinkedMaintOrder", props)
  const { action, handleClose, workshopId, getMaintenanceOrderList } =
    props;
  const message = useContext(ToastMessageContext);
  const classes = useStyles();
  const [busy, setBusy] = useState(null);
  const [selectedPart, setSelectedPart] = useState({});
  const [errors, setErrors] = useState({});
  const [qty, setQty] = useState(null);
  const [inspection, setInspection] = useState(false);
  const [state, setState] = useState("quote");
  const [customerOrderDoc, setCustomerOrderDoc] = useState([]);
  const [description, setDescription] = useState(null);
  const [serialNumber, setSerialNumber] = useState(null);
  const [inductionMenu, setInductionMenu] = useState(null);
  const [release, setRelease] = useState(null);
  const [customerOrderNumber, setCustomerOrderNumber] = useState(null);
  const [secondaryRefNumber, setSecondaryRefNumber] = useState(null);
  const [aogSwitchState, setAogSwitchstate] = useState(null);
  const [primePart, setPrimePart] = useState([])

  useEffect(() => {
    setDescription(!selectedPart?.description ? "" : selectedPart?.description)
  }, [selectedPart?.description])

  useEffect(() => {
    getPartDetails()
  }, [])

  const getPartDetails = () => {
    const params = {
      organisation_id: getOrganisationId(),
      active: true
    }
    MaintenanceServices.getPartNumber(params)
      .then((res) => {
        setPrimePart(res.data)
      }).catch(() => {
        console.log("error")
      })
  }

  const resetErrorsHandler = (name) => {
    setErrors((e) => ({ ...e, [name]: false }));
  };

  const handleAog = (value) => {
    setAogSwitchstate(value);
  };


  const handleSubmit = (e) => {
    e.preventDefault()
    const formData = new FormData(e.target);
    formData.append("type", state);
    formData.append("storage_order_id", workshopId)
    formData.append("primary_part_id", selectedPart.id);
    formData.append("organisation_id", getOrganisationId())
    formData.delete("inspection_action")
    formData.append("inspection_action", inspection);
    if (state === "without_quote") {
      let valid = true;

      let errorFields = {
        part_number: false,
        serial_number: false,
        item_description: false,
        quantity: false,
        induction_tab_style: false,
        release_tab_style: false,
        secondary_customer_ref: false,
        customer_order_number: false
      };
      if (customerOrderDoc.length === 0) {
        errorFields["customer_order_doc[]"] = true
        setErrors((er) => ({ ...er, "customer_order_doc[]": true }));
        valid = false;
      }
      if (formData && formData.get("part_number")?.length === 0) {
        errorFields.part_number = true
        valid = false;
      }
      if (formData && formData.get("item_description")?.length === 0) {
        errorFields.item_description = true;
        valid = false;
      }
      if (formData && formData.get("serial_number")?.length === 0) {
        errorFields.serial_number = true;
        valid = false;
      }
      if (formData && formData.get("quantity")?.length === 0) {
        errorFields.quantity = true;
        valid = false;
      }
      if (formData && formData.get("customer_order_number")?.length === 0) {
        errorFields.customer_order_number = true;
        valid = false;
      }
      if (formData && formData.get("secondary_customer_ref")?.length === 0) {
        errorFields.secondary_customer_ref = true;
        valid = false;
      }
      if (formData && formData.get("induction_tab_style")?.length === 0) {
        errorFields.induction_tab_style = true;
        valid = false;
      }
      if (formData && formData.get("release_tab_style")?.length === 0) {
        errorFields.release_tab_style = true;
        valid = false;
      }
      if (!valid) {
        // setBusy(null)
        setErrors(errorFields);
        return;
      }

      formData.delete("customer_order_doc[]")
      const cusOrder = [];
      customerOrderDoc.forEach((_, i) => {
        cusOrder[i] = Array.from(customerOrderDoc[i]);
        cusOrder[i].forEach((file) =>
          formData.append("customer_order_doc[]", file)
        );
      });


    }
    setBusy("loading")
    StorageServices.createLinkedMaintenanceOrder(formData)
      .then((res) => {
        if (res) {
          handleClose(res.success)
          getMaintenanceOrderList();
          message.showToastMessage({
            message: "Created successfully!!",
            variant: "success"
          })
        }
      }).catch((res) => {
        handleClose(res.error)
        message.showToastMessage({
          message: "Something went wrong. Try again!!",
          variant: "error"
        })
      }).finally(() => {
        setBusy(null)
      })
  }
  return (
    <div>
      <Dialog
        maxWidth={"md"}
        fullWidth={true}
        open={true}
        className={classes.modal}
        onClose={handleClose}
        aria-labelledby="base-station-manage"
      // handleSubmit={handleSubmit}
      >
        <DialogTitle
          variant="subtitle2"
          color="primary"
          className={classes.title}
        >
          New Linked Maintenance Order
        </DialogTitle>
        <DialogContent className={classes.card}>
          <div className={classes.formWrapper}>
            <form spellcheck="false" onSubmit={handleSubmit}>
              <Grid item xs={6}>
                <ButtonGroup color="primary">
                  <MROButton
                    // loading={busy === "loading"}
                    style={{ width: "120px" }}
                    type={"button"}
                    onClick={() => setState("quote")}
                    variant={state === "quote" ? "contained" : "outlined"}

                  >
                    Quote
                  </MROButton>
                  <MROButton
                    type={"button"}
                    style={{ width: "120px" }}
                    onClick={() => setState("without_quote")}
                    variant={state === "without_quote" ? "contained" : "outlined"}

                  >
                    Without Quote
                  </MROButton>
                </ButtonGroup>
              </Grid>
              <br />
              {state === "quote" ? (
                <Typography style={{ fontSize: "13px" }}>
                  Select “Complete” to raise a new Maintenance Order via the Quote
                  process
                </Typography>
              ) : <Typography style={{ fontSize: "13px" }}>
                Complete the following details to setup a linked Maintenance Order
              </Typography>}

              {state === "without_quote" &&
                (
                  <div>
                    <Typography
                      color="primary"
                      style={{ fontSize: "15px", marginTop: "10px" }}
                    >
                      Part Details
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Autocomplete
                          // disabled={!props.permission.write}
                          getOptionSelected={(option, value) => option.id === value.id}
                          value={selectedPart !== null && selectedPart}
                          onChange={(event, value) => {
                            setSelectedPart(value);
                          }}
                          id="tags-standard"
                          options={primePart || []}
                          getOptionLabel={(option) => option.part_number}
                          renderInput={(params) => (
                            <MROTextField
                              {...params}
                              style={{ backgroundColor: "#fff", height: "54px" }}
                              variant="outlined"
                              name="part_number"
                              defaultValue="Select"
                              placeholder={"Search and Select"}
                              label="Part Number *"
                              color={"primary"}
                              InputProps={{
                                ...params.InputProps,
                                type: "search",
                              }}
                              InputLabelProps={{ shrink: true }}
                              error={errors.part_number}
                              onSelect={() => {
                                resetErrorsHandler("part_number");
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <MROTextField
                          name={"serial_number"}
                          label={"Serial Number"}
                          InputLabelProps={{ shrink: true }}
                          className={classes.input}
                          value={serialNumber}
                          placeholder={"Serial Number"}
                          inputProps={{ maxLength: 30 }}
                          onChange={(e) => {
                            resetErrorsHandler(e.target.name);
                            setSerialNumber(e.target.value);
                          }}
                          error={errors.serial_number}
                        ></MROTextField>
                      </Grid>
                      <Grid item xs={6}>
                        <MROTextField
                          name={"item_description"}
                          label={"Item Description"}
                          InputProps={{
                            className: classes.disableField,
                          }}
                          InputLabelProps={{ shrink: true }}
                          className={classes.input}
                          value={description}
                          inputProps={{ maxLength: 30 }}
                          onChange={(e) => {
                            resetErrorsHandler(e.target.name);
                          }}
                          error={errors.item_description}
                        ></MROTextField>
                      </Grid>
                      <Grid item >
                        <QuantityCounter
                          name={"quantity"}
                          label={"Quantity *"}
                          placeholder={"QTY"}
                          setQty={setQty}
                          qty={qty}
                          autoFocus={errors.quantity}
                          error={errors.quantity}
                          helperText={
                            errors["quantity"] &&
                            `Please enter Units Issued Each QTY`
                          }
                          setError={() =>
                            setErrors((prevState) => ({
                              ...prevState,
                              quantity: false,
                            }))
                          }
                        />
                      </Grid>
                    </Grid>

                    <Typography
                      color="primary"
                      style={{ fontSize: "15px", marginTop: "10px" }}
                    >
                      Order Setup
                    </Typography>
                    <Grid container>
                      <Grid item xs={6}>
                        <YesNoField
                          label="AOG? *"
                          name="aog_status"
                          handleToggle={handleAog}
                          value={aogSwitchState}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel
                          control={
                            <PositiveSwitch
                              //   defaultChecked={quotation?.capture_later}
                              checked={inspection}
                              value={inspection}
                              // name="inspection_action"
                              onChange={(e) => {
                                setInspection(e.target.checked);
                              }}
                            />
                          }
                          label={
                            <Typography
                              variant="body2"
                              style={{ marginTop: "5px", marginLeft: "0px" }}
                            >
                              Inspection Actions required?
                            </Typography>
                          }
                          labelPlacement="start"
                          name={"inspection_action"}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <MROTextField
                          name={"induction_tab_style"}
                          label={"Induction Tab Style"}
                          placeholder={"Select"}
                          value={inductionMenu}
                          select
                          // required
                          defaultValue={"select"}
                          onChange={(e) => {
                            resetErrorsHandler(e.target.name);
                            setInductionMenu(e.target.value);
                          }}
                          error={errors.induction_tab_style}
                          InputLabelProps={{ shrink: true }}
                          className={classes.input}
                        >
                          <MenuItem value={"At Base - Goods In"}>
                            At Base - Goods In
                          </MenuItem>
                          <MenuItem value={"At Base - NO Goods In"}>
                            At Base - NO Goods In
                          </MenuItem>
                          <MenuItem value={"Away From Base"}>
                            Away From Base
                          </MenuItem>
                        </MROTextField>
                      </Grid>
                      <Grid item xs={6}>
                        <MROTextField
                          name={"release_tab_style"}
                          label={"Release Tab Style *"}
                          placeholder={"Select"}
                          value={release}
                          select
                          // required

                          defaultValue={"select"}
                          onChange={(e) => {
                            resetErrorsHandler(e.target.name);
                            setRelease(e.target.value);
                          }}
                          error={errors.release_tab_style}
                          InputLabelProps={{ shrink: true }}
                          className={classes.input}
                        >
                          <MenuItem value={"At Base - Goods Out"}>
                            At Base - Goods Out
                          </MenuItem>
                          <MenuItem value={"At Base - NO Goods Out"}>
                            At Base - NO Goods Out
                          </MenuItem>
                          <MenuItem value={"Away From Base"}>
                            Away From Base
                          </MenuItem>
                        </MROTextField>
                      </Grid>
                    </Grid>

                    <Typography
                      color="primary"
                      style={{ fontSize: "15px", marginTop: "10px" }}
                    >
                      Customer Order
                    </Typography>

                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <MROTextField
                          name={"customer_order_number"}
                          label={"Customer Order #"}
                          // required
                          variant="outlined"
                          value={customerOrderNumber}
                          inputProps={{ maxLength: 30 }}
                          onChange={(e) => {
                            resetErrorsHandler(e.target.name);
                            setCustomerOrderNumber(e.target.value)
                          }}
                          error={errors.customer_order_number}
                          placeholder="Purchase Order, or Service Order ref"
                          InputLabelProps={{ shrink: true }}
                          className={classes.input}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <MROTextField
                          name={"secondary_customer_ref"}
                          label={"Secondary Customer Ref"}
                          // required
                          value={secondaryRefNumber}
                          placeholder={"Aircraft Reg, or other customer ref"}
                          onChange={(e) => {
                            resetErrorsHandler(e.target.name);
                            setSecondaryRefNumber(e.target.value);
                          }}
                          error={errors.secondary_customer_ref}
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ maxLength: 30 }}
                          className={classes.input}
                        >

                        </MROTextField>
                      </Grid>
                    </Grid>

                    <Grid item xs={6} style={{ marginTop: "10px" }}>
                      <UploadButton
                        multiple={false}
                        accept={"application/pdf"}
                        action={"Upload Document"}
                        noFileExist={"Attached Files:"}
                        error={errors["customer_order_doc[]"]}
                        setFiles={(files) => {
                          setCustomerOrderDoc(files);
                          resetErrorsHandler("customer_order_doc[]");
                        }}
                        name={"customer_order_doc[]"}
                        label="Customer Order Doc *"
                      />
                    </Grid>
                  </div>
                )}
              <div className={classes.actionButtons}>
                <MROButton
                  type="button"
                  variant={"contained"}
                  onClick={handleClose}
                >
                  Cancel
                </MROButton>
                <MROButton
                  loading={busy === "loading"}
                  type="submit"
                  variant={"contained"}
                  color="primary"
                >
                  Complete
                </MROButton>
              </div>
            </form>
          </div>

        </DialogContent>
      </Dialog>
    </div>
  );
}

export default NewLinkedMaintOrder;
