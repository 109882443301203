import React, { useState, useContext, useEffect } from "react";
import MROFullScreenRightDrawer from "components/fullwidth_dialog";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import MROTextField from "components/form_components/TextField";
import MROButton from "components/buttons";
import { ToastMessageContext } from "lib/contexts/message_context";
import { validateFormData } from "lib/utils/helperFunctions";
import { organisationDetails } from "theme/main_theme";
import { TaskLibraryServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/TaskLibrary/task_library";
import { InspectionServices } from "lib/services/api/operaitons/WorkShop/Inspection/InspectionService";
import { WorkshopContext } from "../../WorkshopContext/WorkshopContext";
import { MenuItem } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      height: "max-content",
    },
  },
  wrapper: {
    padding: "2%",
    backgroundColor: "#fff",
    marginLeft: '24px'
  },
  appBar: {
    backgroundColor: "#fff",
    color: "#000000CC",
    boxShadow: "0px 3px 6px #0000001A",
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  content: {
    backgroundColor: "#fff",
    // padding: "2% 3%",
    borderRadius: "0 0 5px 5px",
    minHeight: "fit-content",
  },
  inputField: {
    maxWidth: "328px",
  },
  actionButtons: {
    textAlign: "right",
    marginTop: "30px",
    "& button": {
      // marginRight: "20px",
    },
  },
}));

function UpdateStage(props) {
  const classes = useStyles();
  const { handleClose, action, currentSelection, action_Id } = props;
  const message = useContext(ToastMessageContext);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState(null);
  const [title, setTitle] = useState(null);
  const [busy, setBusy] = useState(null);
  const { stageUpdate, setStageUpdate } = useContext(WorkshopContext);
  const [taskLibraryData, setTaskLibraryData] = useState([]);
  const [fieldZero, setFieldZero] = useState(null);
  const [fieldOne, setFieldOne] = useState(null);
  const [fieldTwo, setFieldTwo] = useState(null);
  const [fieldThree, setFieldThree] = useState(null);
  const [fieldFour, setFieldFour] = useState(null);
  const [fieldFive, setFieldFive] = useState(null);
  const [fieldSix, setFieldSix] = useState(null);
  const [fieldSeven, setFieldSeven] = useState(null);
  const [fieldEight, setFieldEight] = useState(null);
  const [fieldNine, setFieldNine] = useState(null);
  const [addLib, setAddToLib] = useState(false)
  const [typeZero, setTypeZero] = useState(null);
  const [typeOne, setTypeOne] = useState(null);
  const [typeTwo, setTypeTwo] = useState(null);
  const [typeThree, setTypeThree] = useState(null);
  const [typeFour, setTypeFour] = useState(null);
  const [typeFive, setTypeFive] = useState(null);
  const [typeSix, setTypeSix] = useState(null);
  const [typeSeven, setTypeSeven] = useState(null);
  const [typeEight, setTypeEight] = useState(null);
  const [typeNine, setTypeNine] = useState(null);

  useEffect(() => {
    getTaskLibraryData();
  }, []);

  const getTaskLibraryData = async () => {
    await TaskLibraryServices.getTaskLibraryData(currentSelection?.id)
      .then((res) => {
        if (res?.success) {
          setTaskLibraryData(res.data);
        } else {
          res.errors &&
            message.showToastMessage({
              message: res.errors.join(","),
              variant: "error",
            });
        }
      })
      .finally(() => {
        // setIsGettingDetails(false)
      });
  };

  useEffect(() => {
    // if (action === "Update") {
    setTitle(taskLibraryData?.stage_name);
    setDescription(taskLibraryData?.description);

    if (taskLibraryData?.sub_fields != undefined) {
      setFieldZero(
        taskLibraryData?.sub_fields[0]?.field_name === "null"
          ? ""
          : taskLibraryData?.sub_fields[0]?.field_name
      );
      setFieldOne(
        taskLibraryData?.sub_fields[1]?.field_name === "null"
          ? ""
          : taskLibraryData?.sub_fields[1]?.field_name
      );
      setFieldTwo(
        taskLibraryData?.sub_fields[2]?.field_name === "null"
          ? ""
          : taskLibraryData?.sub_fields[2]?.field_name
      );
      setFieldThree(
        taskLibraryData?.sub_fields[3]?.field_name === "null"
          ? ""
          : taskLibraryData?.sub_fields[3]?.field_name
      );
      setFieldFour(
        taskLibraryData?.sub_fields[4]?.field_name === "null"
          ? ""
          : taskLibraryData?.sub_fields[4]?.field_name
      );
      setFieldFive(
        taskLibraryData?.sub_fields[5]?.field_name === "null"
          ? ""
          : taskLibraryData?.sub_fields[5]?.field_name
      );
      setFieldSix(
        taskLibraryData?.sub_fields[6]?.field_name === "null"
          ? ""
          : taskLibraryData?.sub_fields[6]?.field_name
      );
      setFieldSeven(
        taskLibraryData?.sub_fields[7]?.field_name === "null"
          ? ""
          : taskLibraryData?.sub_fields[7]?.field_name
      );
      setFieldEight(
        taskLibraryData?.sub_fields[8]?.field_name === "null"
          ? ""
          : taskLibraryData?.sub_fields[8]?.field_name
      );
      setFieldNine(
        taskLibraryData?.sub_fields[9]?.field_name === "null"
          ? ""
          : taskLibraryData?.sub_fields[9]?.field_name
      );



      setTypeZero(
        taskLibraryData?.sub_fields[0]?.field_type === "null"
          ? ""
          : taskLibraryData?.sub_fields[0]?.field_type
      );
      setTypeOne(
        taskLibraryData?.sub_fields[1]?.field_type === "null"
          ? ""
          : taskLibraryData?.sub_fields[1]?.field_type
      );
      setTypeTwo(
        taskLibraryData?.sub_fields[2]?.field_type === "null"
          ? ""
          : taskLibraryData?.sub_fields[2]?.field_type
      );
      setTypeThree(
        taskLibraryData?.sub_fields[3]?.field_type === "null"
          ? ""
          : taskLibraryData?.sub_fields[3]?.field_type
      );
      setTypeFour(
        taskLibraryData?.sub_fields[4]?.field_type === "null"
          ? ""
          : taskLibraryData?.sub_fields[4]?.field_type
      );
      setTypeFive(
        taskLibraryData?.sub_fields[5]?.field_type === "null"
          ? ""
          : taskLibraryData?.sub_fields[5]?.field_type
      );
      setTypeSix(
        taskLibraryData?.sub_fields[6]?.field_type === "null"
          ? ""
          : taskLibraryData?.sub_fields[6]?.field_type
      );
      setTypeSeven(
        taskLibraryData?.sub_fields[7]?.field_type === "null"
          ? ""
          : taskLibraryData?.sub_fields[7]?.field_type
      );
      setTypeEight(
        taskLibraryData?.sub_fields[8]?.field_type === "null"
          ? ""
          : taskLibraryData?.sub_fields[8]?.field_type
      );
      setTypeNine(
        taskLibraryData?.sub_fields[9]?.field_type === "null"
          ? ""
          : taskLibraryData?.sub_fields[9]?.field_type
      );
    }
    // }
  }, [taskLibraryData]);

  const validateFields = (formData) => {
    const { error, valid } = validateFormData(formData, [
      "field_name[0]",
      "field_name[1]",
      "field_name[2]",
      "field_name[3]",
      "field_name[4]",
      "field_name[5]",
      "field_name[6]",
      "field_name[7]",
      "field_name[8]",
      "field_name[9]",
      "field_type[0]",
      "field_type[1]",
      "field_type[2]",
      "field_type[3]",
      "field_type[4]",
      "field_type[5]",
      "field_type[6]",
      "field_type[7]",
      "field_type[8]",
      "field_type[9]",
    ]);

    console.log({ error });
    if (!valid) {
      setLoading(false);
      setErrors(error);
      return;
    }
    return valid;
  };

  const handleUpdateData = (formData) => {
    setBusy("updating");
    formData.delete("field_name[0]");
    formData.set("field_name[0]", fieldZero || "");
    formData.delete("field_name[1]");
    formData.set("field_name[1]", fieldOne || "");
    formData.delete("field_name[2]");
    formData.set("field_name[2]", fieldTwo || "");
    formData.delete("field_name[3]");
    formData.set("field_name[3]", fieldThree || "");
    formData.delete("field_name[4]");
    formData.set("field_name[4]", fieldFour || "");
    formData.delete("field_name[5]");
    formData.set("field_name[5]", fieldFive || "");
    formData.delete("field_name[6]");
    formData.set("field_name[6]", fieldSix || "");
    formData.delete("field_name[7]");
    formData.set("field_name[7]", fieldSeven || "");
    formData.delete("field_name[8]");
    formData.set("field_name[8]", fieldEight || "");
    formData.delete("field_name[9]");
    formData.set("field_name[9]", fieldNine || "");




    formData.delete("field_type[0]")
    formData.set("field_type[0]", typeZero || "")
    formData.delete("field_type[1]")
    formData.set("field_type[1]", typeOne || "")
    formData.delete("field_type[2]")
    formData.set("field_type[2]", typeTwo || "")
    formData.delete("field_type[3]")
    formData.set("field_type[3]", typeThree || "")
    formData.delete("field_type[4]")
    formData.set("field_type[4]", typeFour || "")
    formData.delete("field_type[5]")
    formData.set("field_type[5]", typeFive || "")
    formData.delete("field_type[6]")
    formData.set("field_type[6]", typeSix || "")
    formData.delete("field_type[7]")
    formData.set("field_type[7]", typeSeven || "")
    formData.delete("field_type[8]")
    formData.set("field_type[8]", typeEight || "")
    formData.delete("field_type[9]")
    formData.set("field_type[9]", typeNine || "")




    formData.append("organisation_id", organisationDetails?.id);
    let userDetails = JSON.parse(sessionStorage.getItem("user"));
    formData.append("workshop_action_id", action_Id);
    formData.append("add_to_library", addLib);
    formData.append("raised_by", userDetails?.id);
    formData.append("customize", stageUpdate);
    if (stageUpdate === false) {
      formData.append("workshop_library_id", currentSelection?.id);
    }

    InspectionServices.addOldStagesToAction(formData).then((res) => {
      if (res?.success) {
        setBusy(null);
        handleClose(res.data);
        setStageUpdate(false);
        message.showToastMessage({
          message: "Added successfully",
          variant: "success",
        });
      } else {
        setBusy(null);
        res.errors &&
          message.showToastMessage({
            message: res.errors.join(","),
            variant: "error",
          });
      }
      setBusy(null);
    });
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    const formData = new FormData(e.target);
    if (!validateFields(formData)) {
      return;
    }

    if (action === "updateStage") {
      handleUpdateData(formData);
    }
  };

  const resetErrorsHandler = (name) => {
    setErrors((e) => ({ ...e, [name]: false }));
  };

  const handleUpdateStage = () => {
    setStageUpdate(true);
  };

  const handleCloseFunc = () => {
    handleClose();
    setStageUpdate(false);
  };

  return (
    <div>
      <MROFullScreenRightDrawer open={true}>
        <AppBar position="static" className={classes.appBar} elevation={0}>
          <Toolbar>
            <IconButton
              onClick={handleCloseFunc}
              edge="start"
              className={classes.backButton}
              color="inherit"
              aria-label="back"
            >
              <BackIcon />
            </IconButton>
            <Typography variant="subtitle2" style={{ fontSize: "17px" }}>
              Add Stage
            </Typography>
          </Toolbar>
        </AppBar>

        <div className={classes.wrapper}>
          <div className={classes.content}>
            <form noValidate onSubmit={handleSubmit}>
              <Typography
                variant="body1"
                color="primary"
                className={classes.title}
              ></Typography>{" "}
              <Grid item container justify="space-between" alignItems="center">
                <Typography
                  color="primary"
                  style={{ fontWeight: "bold", fontSize: "20px" }}
                >
                  Stage Details
                </Typography>
              </Grid>
              <Grid container alignItems={"center"} justify={"flex-start"}>
                <Grid container alignItems={"center"} justify={"flex-start"}>
                  <Grid item xs={12}>
                    <MROTextField
                      label={"Title"}
                      placeholder="Title - Max 75 Characters"
                      name={"stage_name"}
                      value={title}
                      margin="normal"
                      variant="outlined"
                      inputProps={{ maxLength: 75 }}
                      id="test"
                      InputLabelProps={{ shrink: true }}
                      required
                      error={errors.stage_name}
                      // className={classes.inputField}
                      onChange={(e) => {
                        resetErrorsHandler(e.target.name);
                        setTitle(e.target.value);
                        handleUpdateStage();
                      }}
                      color="primary"
                    />
                  </Grid>
                </Grid>

                <Grid container className={classes.root}>
                  <Grid item xs={12}>
                    <MROTextField
                      name={"description"}
                      label={"Detailed Description *"}
                      placeholder="Description - Max 2000 Characters"
                      inputProps={{ maxLength: 6000 }}
                      InputProps={{ style: { width: "100%" } }}
                      value={description}
                      InputLabelProps={{ shrink: true }}
                      multiline
                      error={errors.description}
                      onChange={(e) => {
                        setDescription(e.target.value);
                        resetErrorsHandler(e.target.name);
                        handleUpdateStage();
                      }}
                    />
                  </Grid>
                </Grid>

                <>
                  <Grid item container style={{ marginTop: "25px" }}>
                    <Typography
                      variant="body1"
                      color="primary"
                      style={{ fontWeight: "bold" }}
                    >
                      Stage Details
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      Upto 10 Sub Fields can be used to capture text E.g. torque
                      values, temp readings etc.
                    </Typography>
                  </Grid>
                  <Grid container xs={12}>
                    <Grid spacing={4} container item xs={11}>
                      <Grid item xs={6} container spacing={2}>
                        <Grid item xs={9}>
                          <MROTextField
                            name={"field_name[0]"}
                            label={"Sub-Field 1"}
                            value={fieldZero}
                            placeholder="Description - Max 50 Characters"
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 50 }}
                            className={classes.inputField}
                            onChange={(e) => {
                              setFieldZero(e.target.value);
                              handleUpdateStage();
                            }}
                          />
                        </Grid>

                        <Grid item xs={3}>
                          <MROTextField
                            name={"field_type[0]"}
                            label={"Type *"}
                            InputLabelProps={{ shrink: true }}
                            select
                            value={typeZero}
                            error={errors["field_type[0]"]}
                            onChange={(e) => {
                              setTypeZero(e.target.value);
                              resetErrorsHandler("field_type[0]");
                            }}
                          >
                            <MenuItem value={"FT"}>Free Text</MenuItem>
                            <MenuItem value={"NU"}>Numeric</MenuItem>
                            <MenuItem value={'YN'}>Yes | No</MenuItem>
                            <MenuItem value={'DT'}>Date & Time</MenuItem>
                            <MenuItem value={"TI"}>Time</MenuItem>
                          </MROTextField>
                        </Grid>
                      </Grid>
                      <Grid item xs={6} container spacing={2}>

                        <Grid item xs={9}>
                          <MROTextField
                            name={"field_name[1]"}
                            label={"Sub-Field 2"}
                            value={fieldOne}
                            placeholder="Description - Max 50 Characters"
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 50 }}
                            className={classes.inputField}
                            onChange={(e) => {
                              setFieldOne(e.target.value);
                              handleUpdateStage();
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <MROTextField
                            name={"field_type[1]"}
                            label={"Type *"}
                            InputLabelProps={{ shrink: true }}
                            select
                            value={typeOne}
                            error={errors["field_type[1]"]}
                            onChange={(e) => {
                              setTypeOne(e.target.value);
                              resetErrorsHandler(e.target.name);
                            }}
                          >
                            <MenuItem value={"FT"}>Free Text</MenuItem>
                            <MenuItem value={"NU"}>Numeric</MenuItem>
                            <MenuItem value={'YN'}>Yes | No</MenuItem>
                            <MenuItem value={'DT'}>Date & Time</MenuItem>
                            <MenuItem value={"TI"}>Time</MenuItem>
                          </MROTextField>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid spacing={4} container item xs={11}>
                      <Grid item xs={6} container spacing={2}>
                        <Grid item xs={9}>
                          <MROTextField
                            name={"field_name[2]"}
                            label={"Sub-Field 3"}
                            value={fieldTwo}
                            placeholder="Description - Max 50 Characters"
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 50 }}
                            className={classes.inputField}
                            onChange={(e) => {
                              setFieldTwo(e.target.value);
                              handleUpdateStage();
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <MROTextField
                            name={"field_type[2]"}
                            label={"Type *"}
                            InputLabelProps={{ shrink: true }}
                            select
                            error={errors["field_type[2]"]}
                            value={typeTwo}
                            onChange={(e) => {
                              setTypeTwo(e.target.value);
                              resetErrorsHandler(e.target.name);
                            }}
                          >
                            <MenuItem value={"FT"}>Free Text</MenuItem>
                            <MenuItem value={"NU"}>Numeric</MenuItem>
                            <MenuItem value={'YN'}>Yes | No</MenuItem>
                            <MenuItem value={'DT'}>Date & Time</MenuItem>
                            <MenuItem value={"TI"}>Time</MenuItem>
                          </MROTextField>
                        </Grid>
                      </Grid>

                      <Grid item xs={6} container spacing={2}>


                        <Grid item xs={9}>
                          <MROTextField
                            name={"field_name[3]"}
                            label={"Sub-Field 4"}
                            value={fieldThree}
                            placeholder="Description - Max 50 Characters"
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 50 }}
                            className={classes.inputField}
                            onChange={(e) => {
                              setFieldThree(e.target.value);
                              handleUpdateStage();
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <MROTextField
                            name={"field_type[3]"}
                            label={"Type *"}
                            InputLabelProps={{ shrink: true }}
                            select
                            value={typeThree}
                            error={errors["field_type[3]"]}
                            onChange={(e) => {
                              setTypeThree(e.target.value);
                              resetErrorsHandler(e.target.name);
                            }}
                          >
                            <MenuItem value={"FT"}>Free Text</MenuItem>
                            <MenuItem value={"NU"}>Numeric</MenuItem>
                            <MenuItem value={'YN'}>Yes | No</MenuItem>
                            <MenuItem value={'DT'}>Date & Time</MenuItem>
                            <MenuItem value={"TI"}>Time</MenuItem>
                          </MROTextField>
                        </Grid>
                      </Grid>
                    </Grid>


                    <Grid spacing={4} container item xs={11}>
                      <Grid item xs={6} container spacing={2}>
                        <Grid item xs={9}>
                          <MROTextField
                            name={"field_name[4]"}
                            label={"Sub-Field 5"}
                            value={fieldFour}
                            placeholder="Description - Max 50 Characters"
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 50 }}
                            className={classes.inputField}
                            onChange={(e) => {
                              setFieldFour(e.target.value);
                              handleUpdateStage();
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <MROTextField
                            name={"field_type[4]"}
                            label={"Type *"}
                            InputLabelProps={{ shrink: true }}
                            select
                            value={typeFour}
                            error={errors["field_type[3]"]}
                            onChange={(e) => {
                              setTypeFour(e.target.value);
                              resetErrorsHandler(e.target.name);
                            }}
                          >
                            <MenuItem value={"FT"}>Free Text</MenuItem>
                            <MenuItem value={"NU"}>Numeric</MenuItem>
                            <MenuItem value={'YN'}>Yes | No</MenuItem>
                            <MenuItem value={'DT'}>Date & Time</MenuItem>
                            <MenuItem value={"TI"}>Time</MenuItem>
                          </MROTextField>
                        </Grid>
                      </Grid>

                      <Grid item xs={6} container spacing={2}>
                        <Grid item xs={9}>
                          <MROTextField
                            name={"field_name[5]"}
                            label={"Sub-Field 6"}
                            placeholder="Description - Max 50 Characters"
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 50 }}
                            value={fieldFive}
                            className={classes.inputField}
                            onChange={(e) => {
                              setFieldFive(e.target.value);
                              handleUpdateStage();
                            }}
                          ></MROTextField>
                        </Grid>
                        <Grid item xs={3}>
                          <MROTextField
                            name={"field_type[5]"}
                            label={"Type *"}
                            InputLabelProps={{ shrink: true }}
                            select
                            value={typeFive}
                            error={errors["field_type[5]"]}
                            onChange={(e) => {
                              setTypeFive(e.target.value);
                              resetErrorsHandler(e.target.name);
                            }}
                          >
                            <MenuItem value={"FT"}>Free Text</MenuItem>
                            <MenuItem value={"NU"}>Numeric</MenuItem>
                            <MenuItem value={'YN'}>Yes | No</MenuItem>
                            <MenuItem value={'DT'}>Date & Time</MenuItem>
                            <MenuItem value={"TI"}>Time</MenuItem>
                          </MROTextField>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid spacing={4} container item xs={11}>
                      <Grid item xs={6} container spacing={2}>
                        <Grid item xs={9}>
                          <MROTextField
                            name={"field_name[6]"}
                            label={"Sub-Field 7"}
                            value={fieldSix}
                            placeholder="Description - Max 50 Characters"
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 50 }}
                            className={classes.inputField}
                            onChange={(e) => {

                              setFieldSix(e.target.value);
                              handleUpdateStage();
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <MROTextField
                            name={"field_type[6]"}
                            label={"Type *"}
                            value={typeSix}
                            InputLabelProps={{ shrink: true }}
                            select
                            error={errors["field_type[6]"]}
                            onChange={(e) => {
                              setTypeSix(e.target.value);
                              resetErrorsHandler(e.target.name);
                            }}
                          >
                            <MenuItem value={"FT"}>Free Text</MenuItem>
                            <MenuItem value={"NU"}>Numeric</MenuItem>
                            <MenuItem value={'YN'}>Yes | No</MenuItem>
                            <MenuItem value={'DT'}>Date & Time</MenuItem>
                            <MenuItem value={"TI"}>Time</MenuItem>
                          </MROTextField>
                        </Grid>
                      </Grid>
                      <Grid item xs={6} container spacing={2}>
                        <Grid item xs={9}>
                          <MROTextField
                            name={"field_name[7]"}
                            label={"Sub-Field 8"}
                            value={fieldSeven}
                            placeholder="Description - Max 50 Characters"
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 50 }}
                            className={classes.inputField}
                            onChange={(e) => {
                              setFieldSeven(e.target.value);
                              handleUpdateStage();
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <MROTextField
                            name={"field_type[7]"}
                            label={"Type *"}
                            InputLabelProps={{ shrink: true }}
                            select
                            error={errors["field_type[7]"]}
                            value={typeSeven}
                            onChange={(e) => {
                              setTypeSeven(e.target.value);
                              resetErrorsHandler(e.target.name);
                            }}
                          >
                            <MenuItem value={"FT"}>Free Text</MenuItem>
                            <MenuItem value={"NU"}>Numeric</MenuItem>
                            <MenuItem value={'YN'}>Yes | No</MenuItem>
                            <MenuItem value={'DT'}>Date & Time</MenuItem>
                            <MenuItem value={"TI"}>Time</MenuItem>
                          </MROTextField>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid spacing={4} container item xs={11}>
                      <Grid item xs={6} container spacing={2}>
                        <Grid item xs={9}>
                          <MROTextField
                            name={"field_name[8]"}
                            label={"Sub-Field 9"}
                            value={fieldEight}
                            placeholder="Description - Max 50 Characters"
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 50 }}
                            className={classes.inputField}
                            onChange={(e) => {
                              setFieldEight(e.target.value);
                              handleUpdateStage();
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <MROTextField
                            name={"field_type[8]"}
                            label={"Type *"}
                            InputLabelProps={{ shrink: true }}
                            select
                            value={typeEight}
                            error={errors["field_type[8]"]}
                            onChange={(e) => {
                              setTypeEight(e.target.value);
                              resetErrorsHandler(e.target.name);
                            }}
                          >
                            <MenuItem value={"FT"}>Free Text</MenuItem>
                            <MenuItem value={"NU"}>Numeric</MenuItem>
                            <MenuItem value={'YN'}>Yes | No</MenuItem>
                            <MenuItem value={'DT'}>Date & Time</MenuItem>
                            <MenuItem value={"TI"}>Time</MenuItem>
                          </MROTextField>
                        </Grid>
                      </Grid>

                      <Grid item xs={6} container spacing={2}>
                        <Grid item xs={9}>
                          <MROTextField
                            name={"field_name[9]"}
                            label={"Sub-Field 10"}
                            value={fieldNine}
                            placeholder="Description - Max 50 Characters"
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 50 }}
                            className={classes.inputField}
                            onChange={(e) => {
                              setFieldNine(e.target.value);
                              handleUpdateStage();
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <MROTextField
                            name={"field_type[9]"}
                            label={"Type *"}
                            InputLabelProps={{ shrink: true }}
                            select
                            value={typeNine}
                            error={errors["field_type[9]"]}
                            onChange={(e) => {
                              setTypeNine(e.target.value);
                              resetErrorsHandler(e.target.name);
                            }}
                          >
                            <MenuItem value={"FT"}>Free Text</MenuItem>
                            <MenuItem value={"NU"}>Numeric</MenuItem>
                            <MenuItem value={'YN'}>Yes | No</MenuItem>
                            <MenuItem value={'DT'}>Date & Time</MenuItem>
                            <MenuItem value={"TI"}>Time</MenuItem>
                          </MROTextField>
                        </Grid>
                      </Grid>
                    </Grid>


                  </Grid>
                </>
              </Grid>
              <div className={classes.actionButtons}>
                <Typography
                  style={{
                    fontSize: "12px",
                    marginBottom: "20px",
                    marginRight: '20px',
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <input type="checkbox" value={addLib} onClick={() => setAddToLib(!addLib)} />
                  &nbsp;&nbsp; Add to your library
                </Typography>

                <MROButton
                  type="button"
                  variant={"contained"}
                  onClick={handleClose}
                  style={{ marginRight: "20px" }}
                >
                  Cancel
                </MROButton>
                <MROButton
                  loading={busy === "updating"}
                  type="submit"
                  color="primary"
                  variant={"contained"}
                  style={{ marginRight: "15px" }}
                >
                  Add
                </MROButton>
              </div>
            </form>
          </div>
        </div>
      </MROFullScreenRightDrawer>
    </div>
  );
}

export default UpdateStage;
