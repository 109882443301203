import React, { useContext, useState } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import MRODialog from "components/MRODialog";
import QuantityCounter from "components/quantity_counter";
import { makeStyles } from "@material-ui/styles";
import { ToastMessageContext } from "lib/contexts/message_context";
import MROTextField from "components/form_components/TextField";
import { validateFormData } from "lib/utils/helperFunctions";
import { MaintenanceServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation";
import NumericIncrementMinQty from "views/Operations/LineMaintenance/WorkOrder/FieldComponents/NumericIncrementMinQty";
import NumericIncrementDiscount from "views/Operations/LineMaintenance/WorkOrder/FieldComponents/NumericIncrementDiscount";
import { useEffect } from "react";

function PriceBrand(props) {
    const classes = useStyles();
    const { handleClose, open, getAllPriceBreaks, unit_total, workshop_order_id, action, currentSelectedData } = props;
    const [busy, setBusy] = useState(null);
    const [qty, setQty] = useState()
    const [priceBreak, setPricebreak] = useState(null);
    const [unitTotal, setUnitTotal] = useState(null);//7483329076
    const [errors, setErrors] = useState({});
    const [state, setState] = useState({
        // min_quantity: '',
        discount: ''
    })
    const message = useContext(ToastMessageContext);

    //   console.log("current selected data isd====================", currentSelectedData, action)

    useEffect(() => {
        let unitTotal = unit_total * (1 - (Number(state.discount) / 100))
        setUnitTotal(unitTotal)
    }, [unit_total, state.discount, currentSelectedData])

    useEffect(() => {
        const getPriceBreaks = async () => {
            if (action === "edit_price") {
                await MaintenanceServices.getPriceBreaks(currentSelectedData?.id)
                    .then((res) => {
                        if (res.success) {
                            setPricebreak(res.data)
                        }
                    })
                    .catch((err) => {
                    })
                    .finally(() => {
                        setBusy(null);
                    });
            }
        }
        getPriceBreaks()

    }, [currentSelectedData])

    useEffect(() => {
        if (action === "edit_price") {
            setState({
                // min_quantity: currentSelectedData?.min_quantity,
                discount: currentSelectedData?.discount
            })
            setQty(currentSelectedData?.min_quantity,)
            setUnitTotal(priceBreak?.band_unit_cost)
        }
    }, [currentSelectedData, priceBreak])

    const submitHandler = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const { error, valid } = validateFormData(formData, ["band_unit_price"]);
        if (!valid) {
            setBusy(null);
            setErrors(error);
            return;
        }

        //Create request
        if (action !== "edit_price") {
            setBusy("create");
            formData.append("workshop_order_id", workshop_order_id);
            await MaintenanceServices.createPriceBreaks(formData)
                .then((res) => {
                    if (res.success) {
                        getAllPriceBreaks();
                        handleClose();
                        message.showToastMessage({
                            message: "Created Successfully!",
                            variant: "success",
                        });
                    }

                })
                .catch((res) => {
                    message.showToastMessage({
                        message: res?.error,
                        variant: "error",
                    })
                    handleClose();
                })
                .finally(() => {
                    setBusy(null);
                });
        } else {
            setBusy("edit");
            await MaintenanceServices.editPriceBreaks(formData, currentSelectedData?.id)
                .then((res) => {
                    if (res.success) {
                        getAllPriceBreaks();
                        handleClose();
                        message.showToastMessage({
                            message: "Updated Successfully!",
                            variant: "success",
                        });
                    }
                })
                .catch((res) => {
                    message.showToastMessage({
                        message: res?.error,
                        variant: "error",
                    })
                    handleClose();
                })
                .finally(() => {
                    setBusy(null);
                });
        }
    }

    const handleChange = ({ target: { name, value } }) => {
        setState({
            ...state,
            [name]: value
        })
    }

    return (
        <MRODialog
            open={open && "Complete"}
            handleClose={handleClose}
            handleSubmit={submitHandler}
            isLoading={busy === 'create' || busy === "edit"}
            centerActionBtns={true}
            padding="28px"
            width="380px"
        >
            <Typography
                color="primary"
                // variant="subtitle2"
                style={{ marginBottom: "30px", fontSize: 22, display: "flex", justifyContent: "center" }}
            >
                Price Band
            </Typography>
            <Box display="flex" justifyContent={"flex-start"}>
                <Typography
                    variant="subtitle2"
                    style={{ marginBottom: "5px" }}
                >
                    {action === "edit_price" ? `Band ${currentSelectedData.band}` : "Band"}
                </Typography>
            </Box>
            <Box xs={12}>
                {/* <NumericIncrementMinQty
                    name="min_quantity"
                    label="Minimum QTY *"
                    error={errors.min_quantity}
                    value={state.min_quantity}
                    placeholder={"Minimum Quantity"}
                    onChange={handleChange}
                    noIncreament
                    state={state}
                    setState={setState}
                    inputProps={{ min: 0, step: 0.5 }}
                    InputLabelProps={{ shrink: true }}
                    setError={() =>
                        setErrors((prevState) => ({
                            ...prevState,
                            min_quantity: false,
                        }))
                    }
                /> */}
                <QuantityCounter
                    name={"min_quantity"}
                    label={"Minimum QTY *"}
                    placeholder={"Minimum QTY"}
                    setQty={setQty}
                    qty={qty}
                    autoFocus={errors["min_quantity"]}
                    error={errors["min_quantity"]}
                    helperText={
                        errors["quantity"] &&
                        (errors["quantity_is_zero"]
                            ? "QTY Required Must Be Greater Than 0"
                            : `Please enter Quanityt Received`)
                    }
                    setError={() =>
                        setErrors((prevState) => ({
                            ...prevState,
                            min_quantity: false,
                        }))
                    }
                    minQty={1}
                />
            </Box>
            <Box xs={12} >
                <NumericIncrementDiscount
                    name="discount"
                    label="Discount %*"
                    error={errors.discount}
                    value={state.discount}
                    placeholder={"discount"}
                    onChange={handleChange}
                    noIncreament
                    state={state}
                    setState={setState}
                    inputProps={{ min: 0, step: 0.5 }}
                    InputLabelProps={{ shrink: true }}
                    setError={() =>
                        setErrors((prevState) => ({
                            ...prevState,
                            discount: false,
                        }))
                    }
                />
            </Box>

            <Grid xs={12} style={{ display: "flex" }}>
                <Typography style={{ fontSize: "12px", fontStyle: "italic" }}>Quote single unit price:</Typography>&nbsp;&nbsp;
                <Typography color="primary" style={{ fontSize: "14px", fontStyle: "italic" }}>{unit_total}</Typography>
            </Grid>
            <Grid xs={12} >
                <MROTextField
                    name={"band_unit_price"}
                    label={"Band Unit Price"}
                    value={parseFloat(unitTotal).toFixed(2)}
                    className={classes.disableField}
                    InputLabelProps={{ shrink: true }}
                />
            </Grid>
        </MRODialog>
    );
}

const useStyles = makeStyles((theme) => ({
    details: {
        "& .MuiGrid-grid-xs-8 .MuiTypography-root": {
            textAlign: "center",
        },
    },
    disableField: {
        pointerEvents: "none",
        backgroundColor: "#F5F5F5",
    },
}));

export default PriceBrand;
