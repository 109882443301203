import React, { useState, useContext, useEffect } from 'react'
import AwayFromBase from './AwayFromBase'
import NoGoodsOut from './NoGoodsOut'
import ReleaseDetails from './index'
import Scrap from './Scrap'
import { ToastMessageContext } from "lib/contexts/message_context";
import { MaintenanceServices } from 'lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation'
import { WorkshopContext } from '../WorkshopContext/WorkshopContext'


function RenderReleaseTab(props) {
    const {
        data,
        workshopOrderDetails,
        closeTabStatus,
        tabStatus,
        getWorkshopOrderDetails,
        orderStateNum,
        getHeaderDetails,
        tabStatusAFB,
        closeTabStatusAFB,
        tabStatusScrap,
        closeTabStatusScrap,
        permission
    } = props
    const [fetchDetails, setFetchDetails] = useState(false)
    const [releaseDetails, setReleaseDetails] = useState({})
    const [releaseDocuments, setReleaseDocuments] = useState({})
    const message = useContext(ToastMessageContext);

    const { setState } = useContext(WorkshopContext);

    const { shouldTabDataUpdate, setShouldTabDataUpdate } =
        useContext(WorkshopContext);

    const [editTab, setEditTab] = useState(false);

    useEffect(() => {
        setState((ps) => ({ ...ps, isEditing: editTab, tab: "release" }));

        return () => setState((ps) => ({ ...ps, tab: null }));
    }, [editTab]);


    const [isGettingDetails, setIsGettingDetails] = useState(false);
    const getReleaseList = async () => {
        if (data.release_id) {
            setIsGettingDetails(true)

            const params = data.id
            await MaintenanceServices.getReleaseTabDetails(data.release_id)
                .then((res) => {
                    setReleaseDetails(res.data);
                    setShouldTabDataUpdate(false);
                    console.log("releaseaatb", data)
                })
                .catch((err) => {
                    message.showToastMessage({
                        message: "Something went wrong. Try again!",
                        variant: "error",
                    });
                })
                .finally(() => {

                    setIsGettingDetails(false)
                });
        }
    };
    const getReleaseDocuments = async () => {
        if (data.id) {
            setFetchDetails(true);
            const params = {
                workshop_order_id: data.id,
            };
            await MaintenanceServices.getReleaseDocument(params)
                .then((response) => {
                    setReleaseDocuments(response.data)
                    // console.log("releasedocument", releaseDocuments)
                })
                .catch((err) => {
                    message.showToastMessage({
                        message: "Something went wrong. Try again!",
                        variant: "error",
                    });
                })
                .finally(() => {
                    setFetchDetails(false);
                });
        }
    };
    useEffect(() => {
        getReleaseDocuments()
    }, [data.id])

    useEffect(() => {
        if (shouldTabDataUpdate) {
            getReleaseList();
        }
    }, [shouldTabDataUpdate]);

    useEffect(() => {
        getReleaseList()
    }, [data.release_id])

    const _props = {
        data,
        releaseDetails,
        getReleaseList,
        isGettingDetails,
        editTab,
        setEditTab,
        getHeaderDetails,
        orderStateNum,
        tabStatus,
        closeTabStatus,
        getWorkshopOrderDetails,
        setIsGettingDetails,
        tabStatusAFB,
        closeTabStatusAFB,
        tabStatusScrap,
        closeTabStatusScrap,
        releaseDocuments,
        fetchDetails,
        permission
    };



    switch (data.release_tab_style) {
        case 1:
            return <ReleaseDetails releaseDetails={releaseDetails} releaseDocuments={releaseDocuments}  {..._props} />;

        case 2:
            return <NoGoodsOut releaseDetails={releaseDetails} releaseDocuments={releaseDocuments} {..._props} />;

        case 3:
            return <AwayFromBase releaseDetails={releaseDetails} {..._props} />;
        case 4:
            return <Scrap releaseDetails={releaseDetails} {..._props} />

        default:
            return null;
    }


}

export default RenderReleaseTab