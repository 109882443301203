import React, { useState, useEffect, useRef } from "react";

import QRCode from "qrcode.react";

import { useReturnIssuedPartStyles } from "./styles";
import { Typography, Box, Grid } from "@material-ui/core";
import MROButton from "components/buttons";
import { ReturnIssuedPartServices } from "lib/services/api/operaitons/lineMaintenance/workOrder/ReturnIssuedPart";
import { useReactToPrint } from "react-to-print";

function QRCodeStep(props) {
  const { setStep, onClose, busy, allocId } = props;

  const classes = useReturnIssuedPartStyles();

  const organisation = JSON.parse(sessionStorage.getItem("organisation"));

  const printContainerRef = useRef();
  const printHandler = useReactToPrint({
    content: () => printContainerRef.current,
    onAfterPrint: () => setIsPrintWindowOpen(false),
  });

  const [conditionsPartDetails, setConditionsPartDetails] = useState({});
  const [isPrintWindowOpen, setIsPrintWindowOpen] = useState(false);

  const getDetails = async () => {
    try {
      const params = {
        alloc_id: allocId,
      };
      const response = await ReturnIssuedPartServices.getConditionPartDetails(
        params
      );

      setConditionsPartDetails(response);
    } catch (error) {
      console.log(error);
    }
  };

  const dummyData = [
    {
      label: "Part #",
      value: conditionsPartDetails?.primary_part?.part_number,
    },
    {
      label: "Batch #",
      value: conditionsPartDetails?.secondary_part?.batch_number,
    },
    {
      label: "Serial #",
      value: conditionsPartDetails?.secondary_part?.serial_number,
    },
    { label: "Receipt Date", value: conditionsPartDetails?.receipt_date },
    {
      label: "Description",
      value: conditionsPartDetails?.primary_part?.description,
    },
    {
      label: "Owner",
      value: conditionsPartDetails?.secondary_part?.mro_owned
        ? organisation.company_name
        : conditionsPartDetails?.line_customer,
    },
    { label: "Location", value: conditionsPartDetails?.store_location },
    {
      label: "ALOC ID",
      value: conditionsPartDetails?.part_condition?.alloc_id,
    },
    {
      label: "Shelf-life Exp",
      value: conditionsPartDetails?.secondary_part?.shelf_life_expiry,
    },
    { label: "QTY", value: conditionsPartDetails?.part_condition?.quantity },
  ];

  useEffect(() => {
    getDetails();
  }, []);

  const data = {
    primaryPartId: conditionsPartDetails?.primary_part?.id,
    secondaryPartId: conditionsPartDetails?.secondary_part?.id,
    part: conditionsPartDetails?.primary_part?.part_number,
    batch: conditionsPartDetails?.secondary_part?.batch_number,
    serial: conditionsPartDetails?.secondary_part?.serial_number,
    description: conditionsPartDetails?.primary_part?.description,
    receiptDate: conditionsPartDetails?.receipt_date,
    owner: conditionsPartDetails?.secondary_part?.mro_owned
      ? organisation.company_name
      : conditionsPartDetails?.line_customer,
    allocId: conditionsPartDetails?.part_condition?.alloc_id,
    shelfLifeExp: conditionsPartDetails?.secondary_part?.shelf_life_expiry,
    qty: conditionsPartDetails?.part_condition?.quantity,
    location: conditionsPartDetails?.store_location,
    baseStationId: conditionsPartDetails?.part_request?.to_base_station_id,
    status: conditionsPartDetails?.condition,
    return_location_enabled: false,
  };

  return (
    <div>
      {/* printContainer */}
      <Typography
        color="primary"
        className={classes.heading}
        style={{ fontWeight: "normal", marginTop: 60, marginBottom: 0 }}
      >
        Print Part QR Label
      </Typography>
      <Box width="100%" ref={printContainerRef} mt="25px">
        <Box
          width="335px"
          pl="25px"
          pr="25px"
          pt="11px"
          pb="35px"
          boxShadow={isPrintWindowOpen ? undefined :"0px 3px 6px #00000029"}
          borderRadius="7px"
        >
          <Grid container>
            {dummyData.map((item, idx) => (
              <React.Fragment key={`${item.label}-${idx}`}>
                <Grid item xs={4} style={{ marginBottom: 3 }}>
                  <Typography color="primary" variant="subtitle1">
                    {item.label}
                  </Typography>
                </Grid>
                <Grid item xs={8} style={{ marginBottom: 3 }}>
                  <Typography style={{ fontSize: 16, color: "#4D4F5C" }}>
                    {item.value ?? "-"}
                  </Typography>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>

          <Box mt="26px" display="flex" justifyContent="center">
            <QRCode
              size={200}
              //       value={`
              //  {
              //    primaryPartId:           ${conditionsPartDetails?.primary_part?.id}
              //  secondaryPartId:         ${conditionsPartDetails?.secondary_part?.id}
              //  part:                    ${
              //    conditionsPartDetails?.primary_part?.part_number
              //  }
              //  batch:                   ${
              //    conditionsPartDetails?.secondary_part?.batch_number
              //  }
              //  serial:                  ${
              //    conditionsPartDetails?.secondary_part?.serial_number
              //  }
              //  description:             ${
              //    conditionsPartDetails?.primary_part?.description
              //  }
              //  receiptDate:             ${conditionsPartDetails?.receipt_date}
              //  owner:                   ${
              //    conditionsPartDetails?.secondary_part?.mro_owned
              //      ? organisation.company_name
              //      : conditionsPartDetails?.line_customer
              //  }
              //  allocId:                 ${
              //    conditionsPartDetails?.part_condition?.alloc_id
              //  }
              //  shelfLifeExp:            ${
              //    conditionsPartDetails?.secondary_part?.shelf_life_expiry
              //  }
              //  qty:                     ${
              //    conditionsPartDetails?.part_condition?.quantity
              //  }
              //  location:                ${conditionsPartDetails?.store_location}
              //  baseStationId:           ${
              //    conditionsPartDetails?.part_request?.to_base_station_id
              //  }
              //  status:                  ${conditionsPartDetails?.condition}
              //  return_location_enabled: ${false}
              // }
              //   `}
              value={JSON.stringify(data)}
            />
          </Box>
        </Box>
      </Box>

      <Box margin="0 auto" mt="48px" display="flex" justifyContent="center">
        <MROButton
          style={{ marginRight: "20px" }}
          type="button"
          variant={"contained"}
          onClick={() => setStep(2)}
        >
          Cancel
        </MROButton>
        <MROButton
          type="submit"
          variant={"contained"}
          color="primary"
          style={{ background: "#4FC605" }}
          // onClick={() => window.print()}
          onClick={() => {
            printHandler();
            setIsPrintWindowOpen(true);
          }}
        >
          Print
        </MROButton>
      </Box>
    </div>
  );
}

export default QRCodeStep;
