import React, { useState, useEffect, useReducer } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import WarningIcon from "@material-ui/icons/Warning";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import MROButton from "components/buttons";
import tableReducer, { INITIAL_TABLE_STATE } from "reducers/table_reducer";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import MROTextField from "components/form_components/TextField";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import QuantityCounter from "components/quantity_counter";
import { ToastMessageContext } from "lib/contexts/message_context";
import { useContext } from "react";
import { useMemo } from "react";
import UploadButton from "components/form_components/upload_multiple";
import AwsDateTimeFieldFor from "components/form_components/AwsDateTimeFieldFor";
import PositiveSwitch from "components/form_components/switch";
import { MaintenanceServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation";
import { Autocomplete } from "@material-ui/lab";
import YesNoField from "components/form_components/YesNoField";
import { validateFormData } from "lib/utils/helperFunctions";
import UnlockIcon from "assets/Unlock.png";
import lock from "assets/icons/Lock.svg";
import unlock from "assets/icons/Unlock.svg";
import { WorkshopContext } from "./WorkshopContext/WorkshopContext";
import "index.css";
import { useTheme } from "@material-ui/core";
import { getOrganisationId, getUserId } from "lib/utils/common_utils";
import AddIcon from "@material-ui/icons/Add";
import AddIconBox from "@material-ui/icons/AddBox";
import ManagePrimaryParts from "./QuotePopUp/ManagePrimaryParts";
import RestoreIcon from '@material-ui/icons/Restore';
import PartHistory from "./QuotePopUp/PartHistory";
import MessagePopup from "components/universal/messagePopup";
import MaterialTable from "material-table";
import tableIcons, { options } from "components/universal/table_attributes";
import PriceBrand from "./QuotePopUp/PriceBrand";
import { ICON_COLOUR } from "lib/constants/style_constants";
import BoxpDataService from "lib/services/api/boxp_index";

import FileUpload from "components/form_components/fileUploadV2"
import PartPricing from "./Commercial/LookupPricing/PartPricing";
const useStyles = makeStyles((theme) => ({
  modal: {
    "& .MuiDialog-paperWidthMd": {
      width: "25%",
    },
  },
  currencyName: {
    // color: theme.palette.secondary.main, 
    fontWeight: "bold"
  },
  wrapper: {
    width: "85%",
    margin: "1% 3%",
  },
  option: {
    backgroundColor: "red",
  },
  paper: {
    borderRadius: "5px",
    backgroundColor: "#fff",
    padding: theme.spacing(3),
    boxShadow: "0px 1px 4px #0000001A",
    "& input[name='part_number']": { textTransform: "uppercase" },
    "& input[name='item_description']": { textTransform: "uppercase" },
  },
  warning: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "16px",
    marginBottom: "20px",
  },
  input: {
    "&::placeholder": {
      textTransform: "inherit",
    },
  },
  "&::-webkit-input-placeholder": { color: "blue" },
  inputEstimate: {
    "&::placeholder": {
      fontSize: "10px",
    },
  },
  button: {
    padding: "5px !important",
  },
  disableField: {
    pointerEvents: "none",
    backgroundColor: "#F5F5F5",
  },
  disableFieldDesc: {
    pointerEvents: "none",
    backgroundColor: "#F5F5F5",
    textTransform: "uppercase"
  },
  svg: {
    filter: "invert(1)",
    height: 18,
    marginBottom: 2,
  },
  title: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  actionButtons: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

function Quote(props) {
  const classes = useStyles();
  const theme = useTheme();
  const {
    workshopOrder,
    quoteId,
    action,
    getMaintenanceOrderList,
    handleClose,
    setAction,
    quoteStatus,
    workshop_id_maint,
    statuFlag,
    readOnly,
    readAndWrite,
    editTab,
    setEditTab,
    tabStatus,
    currentSelection,
    getWorkshopOrderDetails,
    actionStoppedOrder
  } = props;
  const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
  // const [state, rDispatch] = useReducer(dataReducer, INITIAL_DATA_STATE);
  const { page, totalCount, pageSize } = tableState;
  const message = useContext(ToastMessageContext);
  const [selectedPart, setSelectedPart] = useState({});
  const [currentSelectedData, setCurrentSelectedData] = useState({})
  const [primePart, setPrimePart] = useState([])
  const [errors, setErrors] = useState({});
  const [editStatus, setEditStatus] = useState(false);
  const [aogUpdate, setAogUpdate] = useState(null);
  const [updateQuoteLoading, setUpdateQuoteLoading] = useState(null);
  const [disabled, setDisabled] = useState(
    action === "Send Quote " || quoteStatus === false ? false : true
  );
  const [customerDetails, setCustomerDetails] = useState([]);
  const [qty, setQty] = useState();
  const [tatQuantity, setTatquantity] = useState();
  const [menu, setMenu] = useState(null);
  const {
    state,
    setState,
    shouldTabDataUpdate,
    setShouldTabDataUpdate,
    editButton,
    setEditButton,
    setTabStatus,
    setEditTabStatus,
  } = useContext(WorkshopContext);
  const [switchState, setSwitchstate] = useState(false);
  const [release, setRelease] = useState(null);
  const [baseStation, setBaseStation] = useState([]);
  const [loading, setLoading] = useState(null);
  const [quotation, setQuotation] = useState([]);
  const [cusExist, setExistCus] = useState('yes');
  const [quantityPrice, setQuantityPrice] = useState('no');
  const [detailedBreakDown, setDetailedBreakDown] = useState('no');
  const [contactEmail, setContactEmail] = useState(null);
  const [partNumber, setPartNumber] = useState(null);
  const [serialNumber, setSerialNumber] = useState(null);
  const [quoteComments, setQuoteComments] = useState(null);
  const [selectedCustomerId, setSelectedCustomerId] = useState({});
  const [customerIdError, setSelectedCustomerIdError] = useState(false);
  const [workshopBase, setWorkshopBase] = useState(null);
  const [notExistsCusName, setCusNotExistsName] = useState(null);
  const [acceptanceAction, setAcceptanceAction] = useState(null);
  const [buttonStatus, setButtonStatus] = useState(false);
  const [button, setButton] = useState(false);
  const [quoteAccept, setQuoteAccept] = useState(null);
  const [quoteCrete, setQuoteCreate] = useState(null);
  const [fieldData, setFieldData] = useState(null);
  const [isGettingDetails, setIsGettingDetails] = useState(false);
  const [proofAcceptance, setProofAcceptance] = useState([]);
  const [customerOrderDoc, setCustomerOrderDoc] = useState([]);
  const [currencyName, setCurrencyname] = useState(null);
  const [salesRate, setSalesRate] = useState(null);
  const [busy, setBusy] = useState(null);
  const [messagePopup, setMessagePopup] = useState(false);
  const [description, setDescription] = useState(null)
  const [priceBreakDetails, setPriceBreaksDetails] = useState([])
  const [access, setAccess] = useState({ read: false, write: true });
  const [disabledAccptance, setDisabledAcceptance] = useState(false);
  const [pages, setPages] = useState(0);
  const [totalCounts, setTotalCounts] = useState(5);
  const [pageSizes, setPageSizes] = useState(5);
  const [actions, setActions] = useState("");
  const [unitPriceDetails, setUnitPriceDetails] = useState()
  const [internalNotes, setInternalNotes] = useState('')
  const [internalFiles, setInternalFiles] = useState([])
  const [workStatus, setWorkStatus] = useState("")
  const [input, setInput] = useState({
    labour: "",
    parts: "",
    services: "",
    equipment: "",
    aog_fee: "",
  });
  const [sum, setSum] = useState(null);
  const [priceAllDetails, setPriceAllDetails] = useState([])
  const [rate, setRate] = useState(1)
  const [standardId, setStandardId] = useState(null)



  let permission = !props.permission.write
  let workshop_order_id = action === "Send Quote"
    ? quotation?.workshop_order_id
    : (buttonStatus === true && action != "Send Quote ") ||
      (quoteStatus === false && quoteId === null) || (quotation?.linked_storage_order_id != null)
      ? workshop_id_maint
      : action === "Send Quote " ||
        (action === "Unlock" && quoteId !== null)
        ? workshopOrder.id
        : ""

  useEffect(() => {
    if (quotation?.linked_storage_order_id) {
      setExistCus("yes")
    }
  }, [quotation])

  useEffect(() => {
    getAllPriceBreaks()
  }, [workshopOrder.id])

  useMemo(() => {
    //  console.log('quantity=====', quantityPrice, priceBreakDetails.length, totalCounts)
    if (priceBreakDetails.length > 1) {
      setQuantityPrice('yes')
    }
  }, [quantityPrice, priceBreakDetails])

  useEffect(() => {
    setDescription(!selectedPart?.description ? "" : selectedPart?.description)
  }, [selectedPart?.description])

  useEffect(() => {
    if (menu === "At Base - NO Goods In" || menu === "At Base - Goods In" || menu === "Away From Base") {
      setSwitchstate(false)
    }
  }, [menu])

  useEffect(() => {
    if (switchState === undefined || switchState === "") {
      setSwitchstate(false)
    }
  }, [switchState])

  useEffect(() => {
    if (!tabStatus) return;
    else {
      setDisabledAcceptance(true);
    }
  }, [tabStatus]);

  useEffect(() => {
    if (readOnly) {
      setAccess({ read: true, write: false });
      return;
    }

    if (readAndWrite) {
      setAccess({ read: false, write: true });
      return;
    }
  }, [readAndWrite, readOnly]);

  useEffect(() => {
    setState((ps) => ({ ...ps, isEditing: editTab, tab: "quote", warningDialogState: false }));

    return () => setState((ps) => ({ ...ps, tab: null }));
  }, [editTab]);

  useEffect(() => {
    setLoading(action === "Send Quote ");
  }, []);

  const handleWarningDialog = () => {
    setEditTabStatus(false)
    setState((ps) => ({ ...ps, warningState: true }));
  }

  const handleQuoteSentButton = () => {
    handleWarningDialog()
    setButton(true);
    setDisabled(false);
  };

  const handleTab = () => {
    if (quoteStatus === true) {
      setAction("Send Quote");
      setDisabled(false);
    }
  };

  const handleEditAPiCall = async () => {
    if (disabledAccptance === false) {
      setIsGettingDetails(true);
      const param = {
        workshop_order_id: currentSelection?.id,
        tab: "quotation",
      };
      await MaintenanceServices.editButton(param)
        .then((res) => {
          setBusy(null);
          if (res.success) {
            getWorkshopOrderDetails();
            setEditStatus(!editStatus);
            setTabStatus(false);
            setEditButton(!editButton);
            setBusy(null);
          }
        })
        .catch((err) => {
          message.showToastMessage({
            message: "Something went wrong. Try again!",
            variant: "error",
          });
          setBusy(null);
        })
        .finally(() => {
          setIsGettingDetails(false);
        });
    }
  };

  useEffect(() => {
    if (editButton) {
      handleEditAPiCall();
    }
  }, [editButton]);

  const handleCloseTab = () => {
    getQuotation();
    getWorkshopOrderDetails();
    setEditTab(!editTab);
    setDisabledAcceptance(!disabledAccptance);
  };

  const handleEditTab = () => {
    setEditButton(true);
    setDisabledAcceptance(!disabledAccptance);
    setEditTab(!editTab);
  };

  useEffect(() => {
    if (action === "Unlock" && quoteId != null) {
      getQuotation();
    }
  }, []);

  // useEffect(() => {
  //   if (shouldTabDataUpdate) {
  //     getQuotation();
  //   }
  // }, [shouldTabDataUpdate]);

  const getQuotation = async () => {
    // setLoading(true);
    setIsGettingDetails(true);
    await MaintenanceServices?.getQuotation(quoteId)
      .then((res) => {
        if (res) {
          setQuotation(res.data);
          setShouldTabDataUpdate(false);
        }
        // setLoading(false);
      })
      .catch((err) => {
        message.showToastMessage({
          message: "Something went wrong. Try again!",
          variant: "error",
        });
        // setLoading(false);
      })
      .finally(() => {
        setIsGettingDetails(false);
      });
  };

  useEffect(() => {
    if (action === "Unlock") {
      if (quotation !== null) {
        let obj = {
          id: quotation?.line_customer_id,
          name: quotation?.customer_name,
        };
        setSelectedCustomerId(obj);
      }
      let obj2 = {
        id: quotation?.primary_part_id,
        part_number: quotation?.part_number
      }
      setSelectedPart(obj2)
      setDescription(quotation?.item_description)
      if (quotation?.linked_storage_order_id === null) {
        setExistCus(
          quotation?.customer_existing === true
            ? "yes"
            : quotation?.customer_existing === false
              ? "no"
              : ""
        );
      }
      setWorkshopBase(quotation?.base_station_id);
      setContactEmail(quotation?.contact_email);
      setAogUpdate(
        quotation?.aog_status === true
          ? "yes"
          : quotation?.aog_status === false
            ? "no"
            : ""
      );
      setRate(quotation?.sales_rate)
      setSalesRate(quotation?.sales_rate)
      setStandardId(quotation?.workshop_standard_price_id)
      setSwitchstate(quotation?.capture_later);
      setPartNumber(quotation?.part_number);
      setSerialNumber(quotation?.serial_number);
      setQty(quotation?.quantity);
      setTatquantity(quotation?.tat_days);
      setCusNotExistsName(quotation?.customer_name);
      setQuoteComments(quotation?.quote_comments);
      setInternalNotes(quotation?.internal_notes);
      setWorkStatus(quotation?.work_status)
      setQuantityPrice(quotation?.quantity_based_price_breaks === true ? 'yes' : 'no')
      setDetailedBreakDown(quotation?.detailed_breakdown === true ? 'yes' : 'no')
      if (quotation?.labour != undefined) {
        setInput({
          labour: +quotation?.labour,
          parts: +quotation?.parts,
          services: +quotation?.services,
          equipment: +quotation?.equipment,
          aog_fee: +quotation?.aog_fee,
        });
      }
      setQuoteAccept(
        quotation?.quote_accepted === true
          ? "yes"
          : quotation?.quote_accepted === false
            ? "no"
            : ""
      );
      setFieldData(quotation?.quote_accepted_at);
      setMenu(quotation?.induction_tab_style);
      setRelease(quotation?.release_tab_style);
    }
  }, [quotation]);

  useEffect(() => {
    getCustomerList();
  }, []);

  const getCustomerList = async () => {
    const params = {
      organisation_id: getOrganisationId()
    }
    await MaintenanceServices.getCustomerList(params)
      .then((res) => {
        if (res) {
          setCustomerDetails(res.data);
        }
        // setLoading(false);
      })
      .catch((err) => {
        message.showToastMessage({
          message: "Something went wrong. Try again!",
          variant: "error",
        });
        // setLoading(false);
      });
  };

  useEffect(() => {
    getBaseStation();
  }, [page, pageSize]);

  const getBaseStation = async () => {
    const params = {
      organisation_id: getOrganisationId()
    }
    await MaintenanceServices.getBaseStation(params)
      .then((res) => {
        if (res) {
          setBaseStation(res.data);
        }
        // setLoading(false);
      })
      .catch((err) => {
        message.showToastMessage({
          message: "Something went wrong. Try again!",
          variant: "error",
        });
        // setLoading(false);
      });
  };

  const handleAcceptanceOrderSubmit = async (e) => {
    e.preventDefault();
    setBusy("loading");
    updateAllPriceBreaks()
    setLoading(
      acceptanceAction === "save_for_later" ? "save_for_later" : "complete"
    );
    const formData = new FormData(e.target);
    const cus_order_num = formData.get("customer_order_number");
    // const customer_order_doc = formData.get("customer_order_doc[]");
    if (acceptanceAction === "save_for_later") {
      formData.delete("capture_later");
      formData.append(
        "capture_later",
        switchState === null ? false : switchState
      );
      formData.append("save_later", 1);
    }
    if (
      (acceptanceAction != "save_for_later" && quoteAccept === "yes") ||
      editTab
    ) {
      formData.delete("capture_later");
      const { error, valid } = validateFormData(formData, [
        "capture_later",
        "secondary_customer_ref",
        "customer_order_number",
      ]);

      if (
        cus_order_num === "" &&
        cus_order_num.length === 0 &&
        !quotation?.customer_order_number?.length
      ) {
        setErrors((er) => ({ ...er, customer_order_number: true }));
        setLoading(null);
        setBusy(null);
      }
      if (
        (quotation?.customer_order_doc?.length === 0) &&
        customerOrderDoc.length === 0 &&
        switchState === false || switchState === undefined || switchState === "" &&
        !quotation?.customer_order_doc?.length
      ) {
        setErrors((er) => ({ ...er, "customer_order_doc[]": true }));
        setLoading(null);
        setBusy(null);
      }
      if (
        proofAcceptance.length === 0 &&
        !quotation?.proof_acceptance?.length
      ) {
        setLoading(null);
        setErrors((er) => ({ ...er, proof_acceptance: true }));
        setBusy(null);
      }

      if (
        (!valid && !disabled) ||
        (quotation?.customer_order_doc?.length === 0) &&
        (customerOrderDoc.length === 0 &&
          switchState === false || switchState === undefined || switchState === "" &&
          !quotation?.customer_order_doc?.length) ||
        (proofAcceptance.length === 0 &&
          !quotation?.proof_acceptance?.length) ||
        (cus_order_num === "" &&
          cus_order_num.length === 0 &&
          !quotation?.customer_order_number?.length)
      ) {
        // console.log("captrue later goods in===", switchState)
        setErrors((err) => ({ ...err, ...error }));
        setLoading(null);
        setBusy(null);
        return;
      }

      if (!valid) {
        setBusy(null);
        setLoading(null);
        setErrors(error);
        return;
      }
    }
    if (quoteAccept === "yes" && acceptanceAction !== "save_for_later") {
      formData.delete("capture_later");
      formData.append(
        "capture_later",
        cus_order_num?.length ? false : switchState
      );
    }
    formData.delete("proof_acceptance");
    const proof = [];
    proofAcceptance.forEach((_, i) => {
      proof[i] = Array.from(proofAcceptance[i]);
      proof[i].forEach((file) => formData.append("proof_acceptance", file));
    });
    formData.append("user_id", getUserId());
    formData.delete("customer_order_doc[]");
    const cusOrder = [];
    customerOrderDoc.forEach((_, i) => {
      cusOrder[i] = Array.from(customerOrderDoc[i]);
      cusOrder[i].forEach((file) =>
        formData.append("customer_order_doc[]", file)
      );
    });

    if (editTab) {
      formData.append("close_tab", true);
      updateAllPriceBreaks()
      await MaintenanceServices.acceptanceOrder(formData, quotation?.id)
        .then((res) => {
          setBusy(null);
          if (res.success) {
            // console.log("called edit tab inside");
            setEditStatus(!editStatus);
            setState((ps) => ({ ...ps, warningDialogState: false, isEditing: false }));
            setEditButton(false);
            handleCloseTab();
            message.showToastMessage({
              message: "Updated Successfully",
              variant: "success",
            });
            setBusy(null);
          }
        })
        .catch((err) => {
          message.showToastMessage({
            message: "Something went wrong. Try again!",
            variant: "error",
          });
          setBusy(null);
        });
    } else {

      await MaintenanceServices.acceptanceOrder(formData, quotation?.id)
        .then((res) => {
          setLoading(null);
          setBusy(null);
          if (acceptanceAction === "save_for_later") {
            message.showToastMessage({
              message: "Saved Successfully",
              variant: "success",
            });
            setTimeout(() => {
              handleClose();
              getMaintenanceOrderList();
            }, 1000);
            setState((ps) => ({ ...ps, warningDialogState: false, isEditing: false }));
            setLoading(null);
            setBusy(null);
          } else {
            message.showToastMessage({
              message: "Updated Successfully",
              variant: "success",
            });
            if (props?.tabState === 1 && props?.orderStateNum > 0) {
              getQuotation();
              getWorkshopOrderDetails();
            } else {
              setTimeout(() => {
                handleClose();
                getMaintenanceOrderList();
              }, 1000);
            }
            setLoading(null);
            setBusy(null);
          }
        })
        .catch((err) => {
          message.showToastMessage({
            message: "Something went wrong. Try again!",
            variant: "error",
          });
          setLoading(null);
          setBusy(null);
        });
    }
    //creating planning id on complete


  };

  console.log("default value is==============", standardId, input, rate, salesRate)
  let userDetails = JSON.parse(sessionStorage.getItem("user"));
  const handleSubmit = async (e) => {
    e.preventDefault();
    updateAllPriceBreaks()
    if (
      action === "Send Quote " ||
      action === "Send Quote" ||
      quoteStatus === false ||
      buttonStatus === true
    ) {
      setLoading(action === "Send Quote " ? "Send Quote" : "Unlock");
      const formData = new FormData(e.target);
      formData.delete("internal_notes")
      const { error, valid } = validateFormData(
        formData,
        ["serial_number"],
        "contact_email"
      );

      // console.log({ error });
      if (!valid) {
        message.showToastMessage({
          message: "Complete all mandatory fields!",
          variant: "error",
        });
        setLoading(null);
        setErrors(error);
        return;
      }
      let id = formData.get("customer_name");
      let tat_days = formData.get("estimated_TAT_days");
      let baseId = formData.get("workshop_base");
      formData.append(
        "workshop_order_id",
        action === "Send Quote"
          ? quotation?.workshop_order_id
          : (buttonStatus === true && action != "Send Quote ") ||
            (quoteStatus === false && quoteId === null) || (quotation?.linked_storage_order_id != null)
            ? workshop_id_maint
            : action === "Send Quote " ||
              (action === "Unlock" && quoteId !== null)
              ? workshopOrder.id
              : ""
      );
      formData.append("base_station_id", baseId);
      formData.delete("quantity_based_price_breaks");
      formData.delete("detailed_breakdown");
      formData.append("quantity_based_price_breaks", quantityPrice === 'yes' ? true : false);
      formData.append("detailed_breakdown", detailedBreakDown === 'yes' ? true : false);
      formData.append("tat_days", tat_days);
      formData.append("quote_by", userDetails.id);
      formData.append("part_number", selectedPart.part_number);
      formData.append("primary_part_id", selectedPart.id);
      formData.append('internal_notes', internalNotes)
      formData.append("user_id", getUserId());
      if (standardId !== null && standardId !== undefined) {
        formData.append("workshop_standard_price_id", standardId)
      }
      internalFiles.length > 0 && internalFiles.forEach(item => formData.append(`internal_files[]`, item))

      if (selectedCustomerId != undefined && cusExist === "yes") {
        formData.append("line_customer_id", selectedCustomerId.id);
      } else if (cusExist === "no") {
        formData.append("line_customer_id", "");
      } else {
        setSelectedCustomerIdError(true);
        return;
      }

      if (
        (!quotation?.linked_storage_order_id) && action === "Send Quote " ||
        (quoteStatus === false && quoteId === null)
      ) {
        // formData.append("quote_sent", true);
        setQuoteCreate("crete_quote");
        setMessagePopup(true)
        formData.append("organisation_id", getOrganisationId())
        await MaintenanceServices.createQuotation(formData)
          .then((res) => {
            setTabStatus(false);

            message.showToastMessage({
              message: "Quotation created successfully!",
              variant: "success",
            });
          })
          .catch((err) => {
            message.showToastMessage({
              message: "Something went wrong. Try again!",
              variant: "error",
            }).finally(() => {
              setQuoteCreate(null);
              setMessagePopup(false)
            })

          });
        updateAllPriceBreaks()
        const params = {
          workshop_order_id:
            quoteStatus === false && quoteId === null
              ? workshop_id_maint
              : workshopOrder?.id,
          quote_sent: true,
        };
        await MaintenanceServices.getSendQuoteStatus(params)
          .then((res) => {
            if (res) {
            }
            getMaintenanceOrderList();
          })
          .catch((err) => {
            message.showToastMessage({
              message: "Something went wrong. Try again!",
              variant: "error",
            });
          });
        setLoading(null);
        setTimeout(() => {
          handleClose();
          getMaintenanceOrderList();
        }, 2000);
      }

      if (action === "Send Quote") {
        if (quoteStatus === true) {
          const params = {
            workshop_order_id: workshop_id_maint,
            quote_sent: false,
          };
          await MaintenanceServices.getSendQuoteStatus(params)
            .then((res) => {
              if (res) {
              }
              getMaintenanceOrderList();
            })
            .catch((err) => {
              message.showToastMessage({
                message: "Something went wrong. Try again!",
                variant: "error",
              });
            });
          setLoading(null);
        }
      }

      if (buttonStatus === true || quotation?.linked_storage_order_id != null) {
        formData.append("organisation_id", getOrganisationId())
        if (quotation?.linked_storage_order_id != null) {
          setMessagePopup(true)
        }
        setUpdateQuoteLoading("loading");
        await MaintenanceServices.updateQuote(formData, quotation?.id)
          .then((res) => {
            setLoading(null);
            setTabStatus(false);
            setEditTabStatus(true)
            setMessagePopup(false)
            setState((ps) => ({ ...ps, isEditing: true, warningState: false }));
            if (action === "Send Quote " || quoteId == null || quoteId === undefined || quotation?.quote_accepted === null) {
              setState((ps) => ({ ...ps, warningDialogState: false, isEditing: false, warningState: false }));
            }
            message.showToastMessage({
              message: "Updated Successfully",
              variant: "success",
            });
            setUpdateQuoteLoading(null);
            setAction(actionStoppedOrder ? "" : "Unlock");
            setDisabled(true);
          })
          .catch((err) => {
            setMessagePopup(false)
            message.showToastMessage({
              message: "Something went wrong. Try again!",
              variant: "error",
            });
            setUpdateQuoteLoading(null);
            setLoading(null);
          });
        const params = {
          workshop_order_id: workshop_id_maint,
          quote_sent: true,
        };
        setUpdateQuoteLoading("loading");
        await MaintenanceServices.getSendQuoteStatus(params)
          .then((res) => {
            if (res) {
            }
            getMaintenanceOrderList();
            setUpdateQuoteLoading(null);
          })
          .catch((err) => {
            message.showToastMessage({
              message: "Something went wrong. Try again!",
              variant: "error",
            });
          });
        setUpdateQuoteLoading(null);
        setLoading(null);
        if (props?.tabState === 1 && props?.orderStateNum > 0) {
          setButton(!button);
          setDisabled(!disabled);
          setButtonStatus(!buttonStatus);
        } else {
          setTimeout(() => {
            handleClose();
            getMaintenanceOrderList();
          }, 2000);
        }
        setLoading(null);
      }
    }
  };

  useMemo(
    () =>
      setSum(
        +input.labour +
        +input.parts +
        +input.services +
        +input.equipment

      ),
    [input]
  );

  useEffect(() => {
    if (cusExist === "no" && notExistsCusName) {
      if (notExistsCusName === quotation?.customer_name) {
        setExistCus('yes')
        let obj = {
          id: quotation?.line_customer_id,
          name: quotation?.customer_name,
        };
        setSelectedCustomerId(obj);
        console.log("notExistsCusName", quotation?.customer_name)
      }
      else {
        let udx = customerDetails?.customers.findIndex((item) => item.name === notExistsCusName)
        if (udx > -1) {
          setErrors((er) => ({ ...er, customer_name: true }));
          message.showToastMessage({
            message: "Customer already exists! Selecting from existing!",
            variant: "error",
          });
        }
      }
    }
  }, [cusExist, notExistsCusName])
  //handle input change
  const handleInput = function (e) {
    const { value } = e.target;
    if (value.match(/\./g)) {
      const [, decimal] = value.split(".");

      // restrict value to only 2 decimal places
      if (decimal?.length > 2) {
        // do nothing
        return;
      }
    }
    // setState((ps) => ({ ...ps, isEditing: true }));
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    let res = customerDetails?.default_currency?.map((item) => {
      setCurrencyname(item?.currency_name);
      setSalesRate(item?.sales_rate);
    });
  }, [customerDetails]);

  const handleInductionChange = (e) => {
    setMenu(e.target.value);
  };
  const handleReleaseChange = (e) => {
    setRelease(e.target.value);
  };

  const handleExistCus = (value) => {
    handlePopup();
    if (value) {
      setExistCus(value);
    }
  };

  let priceLength = priceBreakDetails.length - 1
  const handleQuantityBasePrice = (value) => {
    if (value) {
      if (value === 'no' && priceBreakDetails.length > 1) {
        message.showToastMessage({
          message: "“Remove Price Breaks First!",
          variant: "error",
        });
        handlePopup();
        setQuantityPrice('yes');
      }

      else {
        handlePopup();
        setQuantityPrice(value);
      }
    }
  };
  const handleAogUpdate = (value) => {
    if (value) {
      setAogUpdate(value);
    }
    handlePopup();
  };

  const handleBreakDown = (value) => {
    if (value) {
      setDetailedBreakDown(value)
    }
    handlePopup()

  }

  const resetErrorsHandler = (name) => {
    setErrors((e) => ({ ...e, [name]: false }));
  };

  const handleSaveForLater = () => {
    setAcceptanceAction("save_for_later");
  };

  useEffect(() => {
    getPartDetails()
  }, [])

  const getPartDetails = () => {
    const params = {
      organisation_id: getOrganisationId(),
      active: true
    }
    MaintenanceServices.getPartNumber(params)
      .then((res) => {
        setPrimePart(res.data)
      }).catch(() => {
        console.log("error")
      })
  }
  useEffect(() => {
    if (quantityPrice === 'yes') {
      getAllPriceBreaks()
    }
  }, [quantityPrice, quotation, pages, totalCounts, pageSizes])
  // console.log("order========", action, quotation?.workshop_order_id, workshopOrder.id, workshop_id_maint)
  const getAllPriceBreaks = () => {
    const params = {
      page: pages + 1,
      limit: pageSizes,
      workshop_order_id: action === "Send Quote"
        ? quotation?.workshop_order_id || workshopOrder.id
        : (buttonStatus === true && action != "Send Quote ") ||
          (quoteStatus === false && quoteId === null) || (quotation?.linked_storage_order_id != null)
          ? workshop_id_maint
          : action === "Send Quote " ||
            (action === "Unlock" && quoteId !== null)
            ? quotation?.workshop_order_id || workshopOrder.id
            : workshopOrder.id
    }
    setLoading(true)
    MaintenanceServices.getAllPriceBreaks(params)
      .then((res) => {
        setLoading(false)
        setTotalCounts(res.total_count)
        setPriceBreaksDetails(res.data)
      }).catch(() => {
        setLoading(false)
        console.log("error")
      })
  }
  if (isGettingDetails) {
    return (
      <Box
        width="100%"
        minHeight="400px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress color="primary" size={40} />
      </Box>
    );
  }

  const handlePopup = () => {
    if (action === "Send Quote " || quoteId == null || quoteId === undefined || quotation?.quote_accepted === null) {
      setState((ps) => ({ ...ps, warningDialogState: true, isEditing: true }));
    }
  };


  let helperText = errors["accepted_quantity"] && (errors["quantity_is_zero"]
    ? "QTY Required Must Be Greater Than 0"
    : `Please enter Quantity required`)



  const tableOptions = {
    ...options,
    page: pages,
    total: totalCounts,
    pageSize: pageSizes,
    search: false,
    toolbar: true,
    rowStyle: { height: 42 },
  };

  const columns = [
    {
      title: "",
      field: "id",
      width: "4%",
      render: (rowData) => <span>&nbsp;</span>,
    },
    {
      title: "Band",
      field: "band",
    },
    {
      title: "Min QTY",
      field: "min_quantity",
    },
    {
      title: "Units",
      field: "units",
      headerStyle: {
        textAlign: "left",
        width: "40%"
      },
      cellStyle: {
        textAlign: "left",
        paddingLeft: '15px',
        width: "40%"
      }
    },
    {
      title: "Discount",
      field: "discount",
      render: (rowData) => rowData.discount ? `${rowData.discount}%` : '',
    },
    {
      title: "Unit Price",
      field: "unit_price",
      render: (rowData) => handlePriceBreakUnitPrice(rowData)

    },
    {
      title: "Action",
      field: "actual_man_hours",
      render: (rowData) => rowData?.band > 1 ?
        < Box >

          <IconButton
            disabled={!props.permission.write || disabled}
            style={{ display: !props.permission.write && "none" }}
            onClick={() => {
              setActions("edit_price");
              setCurrentSelectedData(rowData);
            }}
          >
            <EditIcon fontSize="small" htmlColor={ICON_COLOUR} />
          </IconButton>
          {rowData?.highest_value ?
            <IconButton
              disabled={!props.permission.write || disabled}
              onClick={() => {
                setActions("delete_price");
                setCurrentSelectedData(rowData);
              }}
            >
              <DeleteIcon fontSize="small" htmlColor={ICON_COLOUR} />
            </IconButton> : <span style={{ paddingLeft: '14px' }}></span>}
        </Box > : ''
    }

  ];

  const handleData = (data) => {
    setInput({
      labour: +data?.labour || 0,
      parts: +data?.part || 0,
      services: +data?.service || 0,
      equipment: +data?.equipment || 0,
      aog_fee: +data?.aog || 0,
    });
    setSelectedPart({
      id: data?.primary_part_id,
      part_number: data?.part_number,
      description: data?.description
    })
    setTatquantity(data?.tat_days)
    setDescription(data?.description)
    setQty(data?.quantity)

  }

  const handleSubmitPriceBreak = () => {
    setBusy("loading")
    BoxpDataService.boxpDeleteData(currentSelectedData?.id, 'deletePriceBreak', currentSelectedData).then((res) => {
      if (res.success) {
        setBusy(null);
        getAllPriceBreaks();
        setActions(null);
        message.showToastMessage({ message: "Deleted successfully", variant: "success" });
      } else {
        setBusy(null);
        setActions(null);
        message.showToastMessage({ message: "Something went wrong!!", variant: "error" });
      }
    })
  }


  const handlePriceBreakUnitPrice = (data) => {

    let unit_price = sum * (1 - (data?.discount / 100))
    setUnitPriceDetails(unit_price)
    return unit_price === 0 ? "0.00" : unit_price.toFixed(2)
  }


  const updateAllPriceBreaks = async () => {

    for (let i = 0;i < priceBreakDetails.length;i++) {
      priceAllDetails.push({
        id: priceBreakDetails[i].id,
        min_quantity: priceBreakDetails[i].min_quantity,
        discount: priceBreakDetails[i].discount,
        unit_price: sum * (1 - (priceBreakDetails[i].discount / 100))

      })
    }
    const params = {
      "price_breaks": priceAllDetails
    }
    await MaintenanceServices.updateAllPriceBreaks(params).then((res) => {
      console.log("successs")
    }).catch((res) => {
      message.showToastMessage({ message: "Something went wrong!!", variant: "error" });
    })

  }
  // console.log("actions is=======================", actions)
  //internal files
  const deleteFileHandler = async (id) => {
    setBusy("file-deleting");
    try {
      const response = await MaintenanceServices.deleteFile(id);

      if (response.success) {
        message.showToastMessage({
          message: "File deleted successfully",
          variant: "success",
        });
        getQuotation();
        // setBusy(null);
      }
      setBusy(null);
    } catch (error) {
      message.showToastMessage({
        message: "Something went wrong",
        variant: "error",
      });
      setBusy("file-deleting-error");
    }
  }
  return (
    <div>
      <Grid>
        <Typography color="primary" style={{ marginBottom: "10px" }}>
          Quotation
        </Typography>
        <form spellcheck="false" noValidate onSubmit={handleSubmit}>
          <Grid className={classes.paper}>
            <MROButton variant={"contained"}>Customer</MROButton>
            <br />
            <Grid
              style={{ marginTop: "8px", background: "transparent" }}
              className={disabled || access.read || quotation?.linked_storage_order_id != null ? classes.disableField : null}
            >
              <YesNoField
                disabled={disabled || !props.permission.write || quotation?.linked_storage_order_id != null}
                label="Existing Customer?*"
                name="customer_existing"
                handleToggle={handleExistCus}
                value={cusExist}
                error={errors.customer_existing}
                setError={() => {
                  resetErrorsHandler("customer_existing");
                }}
              />
            </Grid>

            <Grid
              style={{ marginBottom: "20px" }}
              container
              spacing={5}
              alignItems={"center"}
              justify={"flex-start"}
            >
              {cusExist === "yes" ? (
                <Grid className={classes.purchaseGrid} item xs={4}>
                  <Autocomplete
                    disabled={disabled || !props.permission.write || quotation?.linked_storage_order_id != null}
                    getOptionSelected={(option, value) => option.id === value.id}
                    value={selectedCustomerId !== null && selectedCustomerId}
                    onChange={(event, value) => {
                      setSelectedCustomerId(value);
                      handlePopup();
                    }}
                    id="tags-standard"
                    options={customerDetails?.customers || []}
                    loading={loading}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <MROTextField
                        {...params}
                        style={{ backgroundColor: "#fff", height: "54px", pointerEvents: !props.permission.write && "none" }}
                        variant="outlined"
                        name="customer_name"
                        defaultValue="Select"
                        label="Select Customer *"
                        color={"primary"}
                        InputProps={{
                          ...params.InputProps,
                          type: "search",
                        }}
                        InputLabelProps={{ shrink: true }}
                        error={errors.customer_name}
                        onSelect={() => {
                          resetErrorsHandler("customer_name");
                        }}
                      />
                    )}
                  />
                </Grid>
              ) : (
                <Grid className={classes.purchaseGrid} item xs={4}>
                  <MROTextField
                    name={"customer_name"}
                    label={"Customer Name"}
                    InputLabelProps={{ shrink: true }}
                    required
                    placeholder="Enter a customer name"
                    disabled={!props.permission.write}
                    InputProps={{
                      className: disabled && classes.disableField,
                    }}
                    value={notExistsCusName}
                    error={errors.customer_name}
                    onChange={(e) => {
                      resetErrorsHandler(e.target.name);
                      setCusNotExistsName(e.target.value);
                      handlePopup();
                    }}
                  ></MROTextField>
                </Grid>
              )}
              <Grid className={classes.purchaseGrid} item xs={4}>
                <MROTextField
                  name={"contact_email"}
                  label={"Contact Email"}
                  disabled={!props.permission.write}
                  InputProps={{
                    className: disabled && classes.disableField,
                  }}
                  required
                  value={contactEmail}
                  placeholder={
                    "Email address quote sends to(Comma separate Email)"
                  }
                  error={errors.contact_email}
                  autoFocus={errors.contact_email}
                  helperText={
                    errors.contact_email
                      ? "*please enter a correct email"
                      : null
                  }
                  onChange={(e) => {
                    setContactEmail(e.target.value);
                    resetErrorsHandler(e.target.name);
                    handlePopup();
                  }}
                  InputLabelProps={{ shrink: true }}
                ></MROTextField>
              </Grid>
            </Grid>

            <Grid style={{ marginTop: "8px" }}>
              <MROButton variant={"contained"}>Workscope</MROButton>
            </Grid>
            <br />

            <Grid
              style={{ marginTop: "8px", background: "transparent" }}
              className={disabled || access.read ? classes.disableField : null}
            >
              <YesNoField
                disabled={disabled || !props.permission.write}
                label="AOG? *"
                name="aog_status"
                handleToggle={handleAogUpdate}
                value={aogUpdate}
                error={errors.aog_status}
                setError={() => resetErrorsHandler("aog_status")}
              />
            </Grid>

            <Grid
              style={{ marginBottom: "20px", width: "100%" }}
              container
              spacing={4}
              alignItems={"center"}
              justify={"flex-start"}
            >
              <Grid className={classes.purchaseGrid} item xs={3}>
                <MROTextField
                  name={"work_status"}
                  label={"Status / Work *"}
                  placeholder={"Select"}
                  value={workStatus}
                  select
                  requird
                  InputProps={{
                    className: disabled && classes.disableField,
                  }}
                  // value={fieldsData.status_work}
                  InputLabelProps={{ shrink: true }}
                  error={errors.status_work}
                  onChange={(e) => {
                    resetErrorsHandler(e.target.name);
                    setWorkStatus(e.target.value);
                    handlePopup();
                  }}
                >
                  <MenuItem value={"Select"}>Select</MenuItem>
                  <MenuItem value={"Inspected / Tested"}>Inspected / Tested</MenuItem>
                  <MenuItem value={"Repaired"}>Repaired</MenuItem>
                  <MenuItem value={'Overhauled'}>Overhauled</MenuItem>
                  <MenuItem value={'Modified'}>Modified</MenuItem>
                  <MenuItem value={"New"}>New</MenuItem>
                  <MenuItem value={"Prototype"}>Prototype</MenuItem>
                  <MenuItem value={"Customer Instruction"}>Customer Instruction</MenuItem>
                </MROTextField>
              </Grid>
              <Grid className={classes.purchaseGrid} item xs={3}>
                <Autocomplete
                  disabled={disabled || !props.permission.write}
                  getOptionSelected={(option, value) => option.id === value.id}
                  value={selectedPart !== null && selectedPart}
                  onChange={(event, value) => {
                    setSelectedPart(value);
                    handlePopup();
                  }}
                  id="tags-standard"
                  options={primePart || []}
                  // loading={loading}
                  getOptionLabel={(option) => option?.part_number || ""}
                  renderInput={(params) => (
                    <MROTextField
                      {...params}
                      style={{ backgroundColor: "#fff", height: "54px", pointerEvents: !props.permission.write }}
                      variant="outlined"
                      name="part_number"
                      defaultValue="Select"
                      placeholder={"Search and Select"}
                      label="Part Number *"
                      color={"primary"}
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                      }}
                      InputLabelProps={{ shrink: true }}
                      error={errors.part_number}
                      onSelect={() => {
                        resetErrorsHandler("part_number");
                        handlePopup();
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid className={classes.purchaseGrid} item xs={4} style={{ marginBottom: "7px" }}>
                <MROTextField
                  name={"item_description"}
                  label={"Item Description"}
                  // disabled={!props.permission.write}
                  InputProps={{
                    className: classes.disableFieldDesc,
                  }}
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  onChange={(e) => {
                    handlePopup();
                  }}
                  value={description}
                  InputLabelProps={{ shrink: true }}
                ></MROTextField>
              </Grid>

              {/* <Grid className={classes.purchaseGrid} item xs={2}>
                <MROButton
                  disabled={disabled || !props?.permission.write}
                  type="button"
                  variant={"contained"}
                  onClick={() => setActions("editPartHistory")}
                  color="primary"
                  startIcon={<RestoreIcon size="small" />}
                >
                  <Typography style={{ whiteSpace: "nowrap" }}>Part History</Typography>
                </MROButton>
              </Grid> */}
              <Grid className={classes.purchaseGrid} item xs={2}>
                <MROButton
                  disabled={disabled || !props.permission.write}
                  type="button"
                  variant={"contained"}
                  onClick={() => setActions("Add")}
                  color="secondary"
                  startIcon={<AddIcon size="small" />}
                >
                  Add Part
                </MROButton>
              </Grid>

            </Grid>
            <Grid
              style={{ marginBottom: "20px", width: "100%" }}
              container
              spacing={4}
              alignItems={"center"}
              justify={"flex-start"}
            >
              <Grid className={classes.purchaseGrid} item xs={3}>
                <MROTextField
                  name={"serial_number"}
                  label={"Serial Number"}
                  disabled={!props.permission.write}
                  InputProps={{
                    className: disabled && classes.disableField,
                  }}
                  inputProps={{
                    maxLength: 30,
                  }}
                  // required
                  value={serialNumber}
                  placeholder={"Serial Number"}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {
                    setSerialNumber(e.target.value);
                    handlePopup();
                  }}
                ></MROTextField>
              </Grid>
              <Grid className={classes.purchaseGrid} item xs={3}>
                <MROTextField
                  name={"workshop_base"}
                  label={"Workshop Base *"}
                  disabled={!props.permission.write}
                  InputProps={{
                    className: disabled && classes.disableField,
                  }}
                  // required
                  select
                  placeholder="Select"
                  defaultValue="Select"
                  InputLabelProps={{ shrink: true }}
                  error={errors.workshop_base}
                  value={workshopBase}
                  onChange={(e) => {
                    resetErrorsHandler(e.target.name);
                    setWorkshopBase(e.target.value);
                    handlePopup();
                  }}
                >
                  <MenuItem value={"Select"}>Select</MenuItem>
                  {baseStation?.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.station}
                    </MenuItem>
                  ))}
                </MROTextField>
              </Grid>
              <Grid className={classes.purchaseGrid} item xs={4}>
                <QuantityCounter
                  name={"estimated_TAT_days"}
                  label={"Estimated TAT-Days*"}
                  // required
                  disabled={disabled || !props.permission.write}
                  placeholder={"Turn Around Time"}
                  InputLabelProps={{ shrink: true, fontSize: "10px" }}
                  InputProps={{
                    classes: {
                      input: classes.inputEstimate,
                    },
                  }}
                  autoFocus={errors["estimated_TAT_days"]}
                  error={errors["estimated_TAT_days"]}
                  helperText={
                    errors["estimated_TAT_days"] &&
                    (errors["quantity_is_zero"]
                      ? "QTY Required Must Be Greater Than 0"
                      : `Please enter Estimated TAT - Days`)
                  }
                  setError={() =>
                    setErrors((prevState) => ({
                      ...prevState,
                      estimated_TAT_days: false,
                    }))
                  }
                  qty={tatQuantity}
                  setQty={setTatquantity}
                />
              </Grid>
            </Grid>

            {/* <Grid
              style={{ marginBottom: "20px", width: "86%" }}
              container
              spacing={4}
              alignItems={"center"}
              justify={"flex-start"}
            >
              <Grid className={classes.purchaseGrid} item xs={4}> */}
            {/* <QuantityCounter
                  name={"quantity"}
                  label={"Quantity"}
                  placeholder="QTY"
                  disabled={disabled || !props.permission.write}
                  required
                  error={errors["quantity"]}
                  helperText={
                    errors["quantity"] &&
                    (errors["quantity_is_zero"]
                      ? "QTY Required Must Be Greater Than 0"
                      : `Please enter Quantity required`)
                  }
                  setError={() =>
                    setErrors((prevState) => ({
                      ...prevState,
                      quantity: false,
                    }))
                  }
                  setQty={setQty}
                  qty={qty}
                /> */}
            {/* </Grid> */}
            {/* </Grid> */}

            <Grid style={{ marginBottom: "20px" }}>
              <MROButton variant={"contained"}>Unit Price</MROButton>
            </Grid>
            <Grid container xs={12} >
              <Grid item xs={12} md={10} container
                spacing={4} style={{ marginBottom: "20px" }}>

                <Grid className={classes.purchaseGrid} item xs={2}>
                  <MROTextField
                    name={"labour"}
                    label={"Labour"}
                    disabled={!props.permission.write}
                    InputProps={{
                      className: disabled && classes.disableField,
                    }}
                    value={input.labour}
                    type="text"
                    required
                    placeholder={"0.00"}
                    error={errors.labour}
                    onChange={(e) => {
                      resetErrorsHandler(e.target.name);
                      handleInput(e);
                    }}
                    InputLabelProps={{ shrink: true }}
                  ></MROTextField>
                </Grid>
                <Grid className={classes.purchaseGrid} item xs={2}>
                  <MROTextField
                    name={"parts"}
                    label={"Parts"}
                    disabled={!props.permission.write}
                    InputProps={{
                      className: disabled && classes.disableField,
                    }}
                    type="text"
                    required
                    value={input.parts}
                    placeholder={"0.00"}
                    error={errors.parts}
                    onChange={(e) => {
                      resetErrorsHandler(e.target.name);
                      handleInput(e);
                    }}
                    InputLabelProps={{ shrink: true }}
                  ></MROTextField>
                </Grid>
                <Grid className={classes.purchaseGrid} item xs={2}>
                  <MROTextField
                    name={"services"}
                    label={"Services"}
                    disabled={!props.permission.write}
                    InputProps={{
                      className: disabled && classes.disableField,
                    }}
                    value={input.services}
                    type="text"
                    required
                    placeholder={"0.00"}
                    error={errors.services}
                    onChange={(e) => {
                      resetErrorsHandler(e.target.name);
                      handleInput(e);
                    }}
                    InputLabelProps={{ shrink: true }}
                  ></MROTextField>
                </Grid>
                <Grid className={classes.purchaseGrid} item xs={2}>
                  <MROTextField
                    name={"equipment"}
                    label={"Equipment"}
                    disabled={!props.permission.write}
                    InputProps={{
                      className: disabled && classes.disableField,
                    }}
                    required
                    value={input.equipment}
                    placeholder={"0.00"}
                    type="text"
                    // autoFocus={error.equipment}
                    error={errors.equipment}
                    // helperText={
                    //   error.equipment ? "*please enter a equipment" : null
                    // }
                    onChange={(e) => {
                      resetErrorsHandler(e.target.name);
                      handleInput(e);
                    }}
                    InputLabelProps={{ shrink: true }}
                  ></MROTextField>
                </Grid>
                <Grid className={classes.purchaseGrid} item xs={2}>
                  <MROTextField
                    name={"total"}
                    label={"Unit Total *"}
                    error={errors.total}
                    value={parseFloat(sum).toFixed(2)}
                    placeholder={"0.00"}
                    defaultValue={0.0}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      className: classes.disableField,
                    }}
                    onChange={(e) => {
                      resetErrorsHandler(e.target.name);
                    }}
                  ></MROTextField>
                  <Grid
                    style={{
                      background: theme.palette.primary.main,
                      width: "100%",
                      height: "0.5px",
                    }}
                  ></Grid>
                </Grid>
                <Grid className={classes.purchaseGrid} item xs={2}>
                  <MROTextField
                    name={"aog_fee"}
                    label={"Other (1 off)"}
                    InputProps={{
                      className: disabled && classes.disableField,
                    }}
                    required
                    disabled={!props.permission.write}
                    value={input.aog_fee}
                    placeholder={"0.00"}
                    type="text"
                    error={errors.aog_fee}
                    onChange={(e) => {
                      resetErrorsHandler(e.target.name);
                      handleInput(e);
                    }}
                    InputLabelProps={{ shrink: true }}
                  ></MROTextField>
                </Grid>
              </Grid>
              <Grid item xs={12} md={2} style={{ textAlign: "center", paddingTop: "21px", paddingLeft: "31px" }}>

                <MROButton
                  disabled={disabled || !props?.permission.write}
                  type="button"
                  variant={"contained"}
                  onClick={() => {


                    if (cusExist === "yes") {
                      if (selectedCustomerId?.id !== undefined) {

                        setRate(selectedCustomerId?.currency?.sales_rate)
                        setActions("lookup")
                      }
                      else {
                        message.showToastMessage({
                          message: "Select Customer First!",
                          variant: "error",
                        });

                      }

                    }
                    else if (cusExist === "no") {


                      setActions("lookup")


                    }

                  }}
                  color="primary"
                  startIcon={<RestoreIcon size="small" />}
                >
                  <Typography style={{ whiteSpace: "nowrap" }}>Lookup Pricing</Typography>
                </MROButton>
              </Grid>




            </Grid>

            <Box
              display="flex"
              alignItems="center"
              margin="0 auto"
              mt="0px"
              mb="23px"
            >
              <WarningIcon
                style={{ color: "#FFB300" }}
                fontSize="20px"
              />{" "}
              <Typography
                className={classes.bodyText}
                style={{ marginBottom: 0 }}
              >
                <span style={{ padding: "5px" }}>
                  This customer’s currency is{" "}
                  <span className={classes.currencyName}>
                    {action === "Unlock" && quoteId != null
                      ? quotation?.currency_name
                      : (cusExist === "yes"
                        ? selectedCustomerId?.currency?.currency_name
                        : cusExist === "no"
                          ? currencyName
                          : "") || "-"}
                  </span>{" "}
                  Quote all figures in the customer’s rate using the current
                  sales exchange rate of:{" "}
                  <span className={classes.currencyName}>
                    {action === "Unlock" && quoteId != null
                      ? quotation?.sales_rate
                      : (cusExist === "yes"
                        ? selectedCustomerId?.currency?.sales_rate
                        : cusExist === "no"
                          ? salesRate
                          : "") || "-"}
                  </span>
                </span>
              </Typography>
            </Box>
            <Grid
              style={{ marginTop: "8px", marginBottom: "8px", background: "transparent" }}
              className={disabled || access.read || quotation?.linked_storage_order_id != null ? classes.disableField : null}
            >
              <YesNoField
                disabled={disabled || !props.permission.write || quotation?.linked_storage_order_id != null}
                label="Send detailed cost breakdown (Includes Labour, Parts, Services and Equipment)?"
                name="cost_breakdown"
                handleToggle={handleBreakDown}
                value={detailedBreakDown}
                error={errors.detailed_breakdown}
                setError={() => {
                  resetErrorsHandler("detailed_breakdown");
                }}
              />
            </Grid>
            <Grid
              style={{ marginTop: "8px", marginBottom: "8px", background: "transparent" }}
              className={disabled || access.read || quotation?.linked_storage_order_id != null ? classes.disableField : null}
            >
              <YesNoField
                disabled={disabled || !props.permission.write || quotation?.linked_storage_order_id != null}
                label="Add quantity based price breaks?"
                name="quantity_based_price_breaks"
                handleToggle={handleQuantityBasePrice}
                value={quantityPrice}
                error={errors.quantity_based_price_breaks}
                setError={() => {
                  resetErrorsHandler("quantity_based_price_breaks");
                }}
              />
            </Grid>

            {quantityPrice === 'yes' &&
              <MaterialTable
                style={{ boxShadow: "0px 1px 3px #00000033", marginTop: "10px" }}
                icons={tableIcons}
                title={""}
                isLoading={loading}
                columns={columns}
                data={priceBreakDetails || []}
                options={tableOptions}
                localization={{
                  body: {
                    emptyDataSourceMessage: "No Records Found",
                  },
                }}
                onChangePage={(page) => {
                  setPages(page);
                }}
                onChangeRowsPerPage={(pageSize) => {
                  setPageSizes(pageSize);
                }}
                totalCount={totalCounts}
                page={pages}
                actions={props?.permission.write && [
                  {
                    icon: () => (
                      <div style={{ display: access.read ? "none" : undefined }}>
                        <AddIconBox color="primary" />
                      </div>
                    ),
                    tooltip: "Add",
                    hidden: disabled,
                    isFreeAction: true,
                    onClick: () =>
                      setActions('add_qty_price'),
                  },
                ]}
              />
            }

            <Grid style={{ marginBottom: "20px", marginTop: "20px" }}>
              <MROButton variant={"contained"}>Quote Comments</MROButton>
            </Grid>
            {/* <Grid className={classes.purchaseGrid} item xs={4}>
              <MROTextField
                name={"quote_by"}
                label={"Quote by *"}
                disabled={!props.permission.write}
                InputProps={{
                  className: classes.disableField,
                }}
                placeholder={"Populate User Stamp"}
                requird
                value={
                  action === "Send Quote " || quoteId === null
                    ? userDetails.stamp_number
                    : quotation?.quote_by_stamp_number
                }
                error={errors.quote_by}
                onChange={(e) => {
                  resetErrorsHandler(e.target.name);
                }}
                InputLabelProps={{ shrink: true }}
              ></MROTextField>
            </Grid> */}

            <Grid container alignItems={"center"} justify={"flex-start"}>
              <Grid item xs={12}>
                <MROTextField
                  name={"quote_comments"}
                  label={"Quote Comments *"}
                  disabled={!props.permission.write}
                  multiline
                  placeholder="Enter scope and any further details or charges here. E.g, travel, accommodation etc. Max 1000 characters"
                  InputProps={{
                    style: {
                      height: 'max-content',
                      width: "100%",
                      pointerEvents: disabled && "none",
                      backgroundColor: disabled && "#F5F5F5",
                    },
                  }}
                  inputProps={{ maxLength: 1000 }}
                  value={quoteComments}
                  InputLabelProps={{ shrink: true }}
                  error={errors.quote_comments}
                  onChange={(e) => {
                    resetErrorsHandler(e.target.name);
                    setQuoteComments(e.target.value);
                    handlePopup();
                  }}

                // className={classes.inputField}
                />
              </Grid>
            </Grid>
            <Grid style={{ marginBottom: "20px", marginTop: "20px" }}>
              <MROButton variant={"contained"}>Internal Remarks</MROButton>
            </Grid>
            <Grid item xs={12}>
              <MROTextField
                name={"internal_notes"}
                label={"Internal Notes"}
                disabled={!props.permission.write}
                multiline
                placeholder="Enter any internal notes. These will NOT go to the customer and are for internal reference only. Max 2000 characters"
                InputProps={{
                  style: {
                    height: 'max-content',
                    width: "100%",
                    pointerEvents: disabled && "none",
                    backgroundColor: disabled && "#F5F5F5",
                  },
                }}
                inputProps={{ maxLength: 2000 }}
                value={internalNotes}
                InputLabelProps={{ shrink: true }}
                // error={errors.quote_comments}
                onChange={(e) => {
                  // resetErrorsHandler(e.target.name);
                  setInternalNotes(e.target.value);
                  handlePopup();
                }}

              // className={classes.inputField}
              />
            </Grid>
            <Grid style={{ marginBottom: "20px", marginTop: "20px" }}>
              <MROButton variant={"contained"}>Internal Files</MROButton>
            </Grid>
            <Grid>

              <FileUpload
                defaultValue={quotation?.internal_files !== undefined
                  && quotation?.internal_files.map((file) => ({
                    filename: file?.file_name,
                    url: file?.path,
                    id: file?.id,
                  }))
                }
                uploadButtonView={disabled === true}
                setFiles={(files) => { setInternalFiles(files) }}
                showBorderOnNoFiles={true}
                name="internal_files[]"
                multiple={true}
                APIUrl={""}
                action={"Upload Document"}
                onDelete={disabled ? null : deleteFileHandler}
                isDeleting={busy === "file-deleting"}
                viewOnly={disabledAccptance || !props.permission.write}
              />
            </Grid>
            <br />
            <div style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingBottom: '15px'
            }}>
              {statuFlag && quoteStatus && action != "Send Quote " ? (
                <MROButton
                  // loading={action === "Send Quote"}
                  variant={"contained"}
                  type={"submit"}
                  // color="primary"
                  disabled={!props.permission.write}
                  style={{
                    backgroundColor: !props.permission.write ? "#F5F5F5" : theme.palette.secondary.main,
                    position: "relative",
                    backgroundColor: button ? "transparent" : !props.permission.write ? "transparent" : "#FFB300",
                    color: "white",
                    display: access.read ? "none" : undefined,
                  }}
                  onClick={() => handleQuoteSentButton()}
                >
                  {button ? (
                    <MROButton
                      loading={updateQuoteLoading === "loading"}
                      variant={"contained"}
                      type={"submit"}
                      disabled={!props.permission.write}
                      // color={theme.palette.secondary.main}
                      style={{
                        backgroundColor: !props.permission.write ? "#F5F5F5" : theme.palette.secondary.main,
                        position: "absolute",
                        color: "white",
                        padding: "7px",
                        marginTop: "10px",
                        left: 0,
                        display: access.read ? "none" : undefined,
                      }}
                      onClick={() => {
                        setButtonStatus(true);
                        handleWarningDialog();
                      }}
                    >
                      Send Quote
                    </MROButton>
                  ) : (
                    props.permission.write &&
                    <Box display="flex" justifyContent="center">
                      {" "}
                      <img
                        src={UnlockIcon}
                        alt="Icon"
                        style={{ height: "18px" }}
                      />
                      &nbsp;&nbsp;{"Unlock"}
                    </Box>
                  )}
                </MROButton>
              ) : (
                <MROButton
                  loading={quoteCrete === "crete_quote" || updateQuoteLoading === "loading"}
                  variant={"contained"}
                  type={"submit"}
                  disabled={!props.permission.write}
                  // color={theme.palette.secondary.main}
                  style={{
                    backgroundColor: !props.permission.write ? "#c2bebe" :
                      action === "Send Quote" ||
                        quoteStatus === false ||
                        quoteStatus === undefined ||
                        action === "Send Quote "
                        ? theme.palette.secondary.main
                        : action === "Unlock"
                          ? "#FFB300"
                          : "#FFB300",
                    color: "white",
                    display: access.read ? "none" : undefined,
                  }}
                  onClick={() => handleTab()}
                >
                  <div style={{ backgroundColor: !props.permission.write ? "#c2bebe" : "" }}>
                    {quoteStatus === false || action === "Send Quote "
                      ? "Send Quote"
                      : action}
                  </div>
                </MROButton>
              )}
            </div>
          </Grid>
        </form>
      </Grid>
      <Grid style={{ marginBottom: "20px" }}></Grid>

      <form noValidate onSubmit={handleAcceptanceOrderSubmit}>
        <Grid>
          <Typography color="primary" style={{ marginBottom: "10px" }}>
            Acceptance & Order Setup
          </Typography>
          <Grid className={classes.paper}>
            <MROButton variant={"contained"}>Acceptance</MROButton>
            <br />
            <Grid
              style={{ marginTop: "8px", background: "transparent" }}
              className={access.read ? classes.disableField : null}
            >
              <YesNoField
                disabled={!props.permission.write}
                label="Quote accepted? *"
                name="quote_accepted"
                handleToggle={(value) => {
                  if (value) {
                    setQuoteAccept(value);
                  }
                  handlePopup();
                }}
                value={quoteAccept}
                error={errors.quote_accepted}
                setError={() => resetErrorsHandler("quote_accepted")}
              />
            </Grid>

            {quoteAccept === "yes" && (
              <Grid>
                <Grid
                  style={{ marginBottom: "20px" }}
                  container
                  spacing={5}
                  alignItems={"center"}
                  justify={"flex-start"}
                >
                  <Grid className={classes.purchaseGrid} item xs={12}>
                    <Grid item xs={4}>
                      <QuantityCounter
                        name={"accepted_quantity"}
                        label={"Accepted Quantity"}
                        placeholder="QTY"
                        disabled={disabledAccptance}
                        required
                        error={errors["accepted_quantity"]}
                        helperText={helperText}
                        // helperText={
                        //   errors["quantity"] &&
                        //   (errors["quantity_is_zero"]
                        //     ? "QTY Required Must Be Greater Than 0"
                        //     : `Please enter Quantity required`)
                        // }
                        setError={() =>
                          setErrors((prevState) => ({
                            ...prevState,
                            accepted_quantity: false,
                          }))
                        }
                        setQty={setQty}
                        qty={qty}
                      />
                    </Grid>
                  </Grid>

                  <Grid className={classes.purchaseGrid} item xs={4}>
                    <MROTextField
                      name={"quote_accepted_by"}
                      label={"Accepted by"}
                      disabled={!props.permission.write}
                      InputLabelProps={{ shrink: true }}
                      placeholder={"Customer accepter name"}
                      InputProps={{
                        className: disabledAccptance && classes.disableField,
                      }}
                      required
                      defaultValue={quotation?.quote_accepted_by}
                      error={errors.quote_accepted_by}
                      onChange={(e) => {
                        resetErrorsHandler(e.target.name);
                        handlePopup();
                      }}
                    ></MROTextField>
                  </Grid>
                  <Grid className={classes.purchaseGrid} item xs={4}>
                    <AwsDateTimeFieldFor
                      required
                      fullWidth
                      pointerEvent={"none"}
                      disabled={disabledAccptance || !props.permission.write}
                      background={"#F5F5F5"}
                      // format="ll - HH:mm"
                      label={"Date / Time *"}
                      format={"YYYY-MM-DD HH:mm:ss"}
                      renderDate={"DD/MM/YYYY HH:mm"}
                      placeholder="DD/MM/YYYY hh:mm"
                      name="quote_accepted_at"
                      value={fieldData}
                      errors={errors.quote_accepted_at}
                      onChange={(e) => {
                        resetErrorsHandler("quote_accepted_at");
                        handlePopup();
                      }}
                      defaultValue={quotation?.quote_accepted_at}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                </Grid>

                <Box
                  display="flex"
                  alignItems="center"
                  margin="0 auto"
                // style={{ display: access.read ? "none" : undefined }}
                >
                  <Box>
                    <UploadButton
                      readOnly={disabledAccptance || !props.permission.write}
                      visibilityStatus={access.read ? "none" : undefined}
                      multiple={false}
                      accept={"application/pdf"}
                      action={"Upload Document"}
                      required={true}
                      error={errors["proof_acceptance"]}
                      noFileExist={"Attached Files:"}
                      defaultValue={quotation?.proof_acceptance?.map(
                        (file) => ({
                          name: file.file_name,
                          url: file.path,
                        })
                      )}
                      setFiles={(files) => {
                        setProofAcceptance(files);
                        resetErrorsHandler("proof_acceptance");
                        handlePopup();
                      }}
                      name={"proof_acceptance"}
                      label="Proof of acceptance *"
                    />
                  </Box>
                </Box>

                <Grid style={{ marginTop: 30 }}>
                  <Grid>
                    <MROButton variant={"contained"}>Order Setup</MROButton>
                  </Grid>
                  <Grid
                    style={{ marginBottom: "20px" }}
                    container
                    spacing={5}
                    alignItems={"center"}
                    justify={"flex-start"}
                  >
                    <Grid className={classes.purchaseGrid} item xs={4}>
                      <MROTextField
                        name={"induction_tab_style"}
                        label={"Induction Tab Style"}
                        placeholder={"Select"}
                        disabled={!props.permission.write}
                        value={menu}
                        select
                        required
                        InputProps={{
                          className: disabledAccptance && classes.disableField,
                        }}
                        defaultValue={quotation?.induction_tab_style}
                        InputLabelProps={{ shrink: true }}
                        error={errors.induction_tab_style}
                        onChange={(e) => {
                          resetErrorsHandler(e.target.name);
                          handleInductionChange(e);
                          handlePopup();
                        }}
                      >
                        <MenuItem value={"At Base - Goods In"}>
                          At Base - Goods In
                        </MenuItem>
                        <MenuItem value={"At Base - NO Goods In"}>
                          At Base - NO Goods In
                        </MenuItem>
                        <MenuItem value={"Away From Base"}>
                          Away From Base
                        </MenuItem>
                      </MROTextField>
                    </Grid>
                    <Grid className={classes.purchaseGrid} item xs={4}>
                      <MROTextField
                        name={"release_tab_style"}
                        label={"Release Tab Style *"}
                        placeholder={"Select"}
                        disabled={!props.permission.write}
                        value={release}
                        select
                        InputProps={{
                          className: disabledAccptance && classes.disableField,
                        }}
                        InputLabelProps={{ shrink: true }}
                        defaultValue={quotation?.release_tab_style}
                        error={errors.release_tab_style}
                        onChange={(e) => {
                          resetErrorsHandler(e.target.name);
                          handleReleaseChange(e);
                          handlePopup();
                        }}
                      >
                        <MenuItem value={"At Base - Goods Out"}>
                          At Base - Goods Out
                        </MenuItem>
                        <MenuItem value={"At Base - NO Goods Out"}>
                          At Base - NO Goods Out
                        </MenuItem>
                        <MenuItem value={"Away From Base"}>
                          Away From Base
                        </MenuItem>
                        {/* <MenuItem value={"Scrap"}>
                          Scrap
                        </MenuItem> */}
                      </MROTextField>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid style={{ marginTop: 10 }}>
                  <Grid>
                    <MROButton variant={"contained"}>Customer Order</MROButton>
                  </Grid>

                  <div>
                    <FormControlLabel
                      control={
                        <PositiveSwitch
                          disabled={!props.permission.write || disabledAccptance || menu === "At Base - NO Goods In" || menu === "Away From Base"}
                          defaultChecked={quotation?.capture_later}
                          // className={access.read ? classes.disableField : null}
                          checked={switchState}
                          // value={switchState}
                          // InputProps={{
                          //   className:
                          //     disabledAccptance && classes.disableField,
                          // }}
                          name="capture_later"
                          onChange={(e) => {
                            setSwitchstate(e.target.checked);
                            handlePopup();
                          }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          style={{ marginTop: "5px", marginLeft: "0px" }}
                        >
                          Capture later at Goods in ?
                        </Typography>
                      }
                      labelPlacement="start"
                      name={"capture_later"}
                    />
                  </div>

                  {!switchState ? (
                    <Grid>
                      <Grid
                        style={{ marginBottom: "20px" }}
                        container
                        spacing={5}
                        alignItems={"center"}
                        justify={"flex-start"}
                      >
                        <Grid className={classes.purchaseGrid} item xs={4}>
                          <MROTextField
                            name={"customer_order_number"}
                            label={"Customer Order # *"}
                            placeholder={"Purchase Order, or Service Order ref"}
                            disabled={!props.permission.write}
                            InputProps={{
                              className:
                                disabledAccptance && classes.disableField,
                            }}
                            inputProps={{ maxLength: 30 }}
                            InputLabelProps={{ shrink: true }}
                            defaultValue={quotation?.customer_order_number}
                            error={errors.customer_order_number}
                            onChange={(e) => {
                              resetErrorsHandler(e.target.name);
                              handlePopup();
                            }}
                          ></MROTextField>
                        </Grid>
                        <Grid className={classes.purchaseGrid} item xs={4}>
                          <MROTextField
                            name={"secondary_customer_ref"}
                            label={"Secondary Customer Ref"}
                            disabled={!props.permission.write}
                            placeholder={"Aircraft Reg, or other customer ref"}
                            inputProps={{ maxLength: 30 }}
                            InputProps={{
                              className:
                                disabledAccptance && classes.disableField,
                            }}
                            defaultValue={quotation?.secondary_customer_ref}
                            onChange={(e) => {
                              handlePopup();
                            }}
                            InputLabelProps={{ shrink: true }}
                          ></MROTextField>
                        </Grid>
                      </Grid>

                      <Box
                        display="flex"
                        alignItems="center"
                        margin="0 auto"
                      // style={{ display: access.read ? "none" : undefined }}
                      >
                        <UploadButton
                          readOnly={disabledAccptance || !props.permission.write}
                          visibilityStatus={access.read ? "none" : undefined}
                          multiple={true}
                          accept={"application/pdf"}
                          action={"Upload Document"}
                          noFileExist={"Attached Files:"}
                          defaultValue={quotation?.customer_order_doc?.map(
                            (file) => ({
                              name: file.file_name,
                              url: file.path,
                            })
                          )}
                          error={errors["customer_order_doc[]"]}
                          setFiles={(files) => {
                            setCustomerOrderDoc(files);
                            resetErrorsHandler("customer_order_doc[]");
                            handlePopup();
                          }}
                          name={"customer_order_doc[]"}
                          label="Customer Order Doc *"
                        />
                      </Box>
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            )}
            {props?.orderStateNum === 0 && props?.tabState === 1 && props?.permission.write && (
              <Grid item md={12} style={{ marginTop: "15px" }}>
                <div className={"align-right"}>
                  <MROButton
                    disabled={
                      quoteStatus === false ||
                      quoteStatus === undefined ||
                      quoteStatus === null
                    }
                    loading={loading === "save_for_later"}
                    style={{
                      marginRight: "15px",
                      display: access.read ? "none" : undefined,
                    }}
                    variant={"contained"}
                    type="submit"
                    onClick={() => handleSaveForLater()}
                  >
                    Save for later
                  </MROButton>
                  <MROButton
                    disabled={
                      quoteStatus === false ||
                      quoteStatus === undefined ||
                      quoteStatus === null
                    }
                    variant={"contained"}
                    loading={loading === "complete"}
                    type="submit"
                    color="primary"
                    style={{ display: access.read ? "none" : undefined }}
                    onClick={() => setAcceptanceAction("complete")}
                  >
                    Complete
                  </MROButton>
                </div>
              </Grid>
            )}
          </Grid>

          <div className={"align-right"}>
            {quoteAccept === "yes" &&
              tabStatus &&
              props?.orderStateNum > 0 &&
              props?.orderStateNum <= 8 && props.permission.write && (
                <MROButton
                  type="button"
                  variant={"contained"}
                  style={{
                    backgroundColor: "#FFB000",
                    color: "white",
                    display: editTab ? "none" : undefined,
                    marginTop: "18px",
                  }}
                  onClick={() => {
                    handleEditTab();
                    setEditStatus(!editStatus);
                    setEditTabStatus(true)
                    setState((ps) => ({ ...ps, warningDialogState: false, isEditing: false, shouldShowWarning: false, editTabStatus: true }));
                  }}
                  startIcon={
                    <img className={classes.svg} src={lock} alt="lock" />
                  }
                >
                  Edit Tab
                </MROButton>
              )}

            {props?.tabState === 1 && props?.orderStateNum > 0 && props?.permission.write && (
              <MROButton
                variant={"contained"}
                style={{
                  backgroundColor: "red",
                  color: "white",
                  marginTop: "18px",
                }}
                type={state.warningState ? "button" : "submit"}
                loading={busy === "loading"}
                onClick={() => {
                  state.warningState &&
                    message.showToastMessage({
                      message: " Quote must be sent first!!",
                      variant: "error",
                    });
                }}
                startIcon={
                  <img className={classes.svg} src={unlock} alt="unlock" />
                }
              >
                Close Tab
              </MROButton>
            )}
          </div>
        </Grid>
      </form>
      {actions && actions === 'Add' &&
        <ManagePrimaryParts
          handleClose={() => setActions(null)}
          action={actions}
          primePart={primePart}
          getPartDetails={getPartDetails}
          setSelectedPart={setSelectedPart}
          setDescription={setDescription}
        />}
      {actions && actions === 'editPartHistory'
        && <PartHistory
          handleClose={() => setActions(null)}
          action={action}
          primePart={primePart}
          handleData={handleData}
          workshop_order_id={workshop_order_id}
        />
      }
      {actions && actions === 'add_qty_price'
        &&
        <PriceBrand
          handleClose={() => setActions(null)}
          open={action}
          action={actions}
          getAllPriceBreaks={getAllPriceBreaks}
          unit_total={parseFloat(sum).toFixed(2)}
          workshop_order_id={workshop_order_id || quotation?.workshop_order_id}
        />
      }
      {actions && actions === 'edit_price'
        &&
        <PriceBrand
          handleClose={() => setActions(null)}
          open={action}
          action={actions}
          getAllPriceBreaks={getAllPriceBreaks}
          unit_total={parseFloat(sum).toFixed(2)}
          workshop_order_id={workshop_order_id}
          currentSelectedData={currentSelectedData}
        />
      }
      {messagePopup &&
        <MessagePopup
          handleClose={() => setMessagePopup(false)}
          open={messagePopup}
          topTitle={"The Quote Report for the job are being prepared"}
          bottomTitle={"Please be patient, this will take a few moments"}
        />}
      {actions && actions === "lookup" && (
        <PartPricing handleClose={() => setActions(null)}
          currentWorkshopDetails={{ quantity: "1", part_number: selectedPart?.part_number, description: description, salesRate: cusExist === "no" ? "1" : rate }}
          setInput={setInput} input={input} name={"quote"} setTatquantity={setTatquantity} setStandardId={setStandardId} salesRate={salesRate}
        />

      )}
      {actions && actions === "delete_price" &&
        <Dialog
          maxWidth={"md"}
          fullWidth={true}
          open={actions === "delete_price"}
          className={classes.modal}
          onClose={() => setActions(null)}
        >
          <DialogTitle>
            <Typography color="primary" className={classes.title}>
              Delete Item
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography className={classes.warning}>
              This cannot be undone. Are you sure?
            </Typography>
            <Grid className={classes.actionButtons} spacing={1} container style={{ paddingBottom: '20px' }}>
              <Grid item>
                <MROButton
                  type="button"
                  variant={"contained"}
                  onClick={() => setActions(null)}
                >
                  Cancel
                </MROButton>
              </Grid>
              <Grid item>
                <MROButton
                  loading={busy === "loading"}
                  onClick={handleSubmitPriceBreak}
                  type="submit"
                  variant={"contained"}
                  color="primary"
                >
                  Yes
                </MROButton>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>}
    </div>
  );
}

export default React.memo(Quote);
