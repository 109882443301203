import { Button, ButtonGroup, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import MROTextField from "../../../../../components/form_components/TextField";
import { fixedInputValueTo2DecimalPlaces } from "lib/utils/helperFunctions";

export default function NumericIncrementDiscount({ setError, state, setState, ...props }) {
  const [val, setVal] = useState(props.value ? parseFloat(props?.value) : "");
  const handleChange = (action) => () => {
    typeof setError === "function" && setError();
    if (action === "inc") {
      if (parseFloat(val || 0) >= 0)
        if (parseFloat(val) % 0.5 === 0) setVal(parseFloat(val + 0.5));
        else setVal(parseFloat(val || 0) + 0.5);
    }
    if (action === "dec") {
      if (parseFloat(val) > 0)
        if (parseFloat(val) % 0.5 === 0){
          setVal(parseFloat(val - 0.5))
        } else if (parseFloat(val) <= 0.5 ){
          setVal(0)
        }
        else setVal(Math.round(parseFloat(val || 0) - 0.5))
    }
  };

  useEffect(() => {
    setState({
      ...state,
      discount: val,
    });
  }, [val]);

  console.log({ val });

  useEffect(() => {
    // setVal(parseFloat(props?.value))
  }, [props.value]);

  let inputprops = {
    value: props.value,
  };

  return (
    <>
      <Grid style={{ display: "flex", alignItems: "center" }}>
        {/* <Grid item xs={8} style={{marginRight:"8px"}}> */}
          <MROTextField
            {...props}
            defaultValue={val}
            onChange={(e) => {
              typeof setError === "function" && setError();
              typeof setVal === "function" && setVal((fixedInputValueTo2DecimalPlaces(e.target.value.replace(/[A-Za-z]/g, ""))));
            }}
          />
        {/* </Grid> */}
        <Grid item xs={1}></Grid>
          <ButtonGroup
            color="primary"
            aria-label="outlined primary button group"
          >
            <Button
              style={{ width: "46px", height: "36px" }}
              onClick={handleChange("dec")}
            >
              {" "}
              <RemoveIcon />
            </Button>
            <Button
              style={{ width: "46px", height: "36px" }}
              onClick={handleChange("inc")}
            >
              {" "}
              <AddIcon />
            </Button>
          </ButtonGroup>
        {/* </Grid> */}
      </Grid>
    </>
  );
}
