
import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import { DialogContent, IconButton, Typography, Grid, makeStyles } from "@material-ui/core";
import FileUpload from "components/form_components/fileUpload";
import { BASE_API_URL_BOXR } from "lib/constants";

const useStyles = makeStyles((theme) => ({
    certDialog: {
        "& .MuiDialog-paper": {
            boxShadow: "0px 3px 6px #00000029",
            position: "absolute",
            left: "calc(50% - 250px)",
            width: '44%'
        },
    },
    description: {
        borderRadius: "5px",
        height: "max-content",
        fontSize: "15px",
        backgroundColor: "#F5F5F5",
        padding: theme.spacing(1),
        whiteSpace: 'break-spaces'
    },
}));
function TechFilesPopup(props) {
    const { handleClose, currentSelectedRow, open, name } = props
    const classes = useStyles();
    return (
        <div>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="755px"
                style={{
                    minHeight: "256",
                    // left: "20%",
                    boxShadow: "0px 3px 6px #00000029",
                }}
                className={classes.certDialog}
            >
                <div style={{ padding: "20px", overflow: "hidden" }}>
                    {name !== 'service' && <>
                        <div>
                            <Typography
                                color="primary"
                                varient="h3"
                                style={{ marginBottom: "10px" }}
                            >
                                Detailed Description
                            </Typography>

                        </div>
                        <div><Grid className={classes.description}>
                            <Typography style={{ fontSize: "15px" }}>
                                {currentSelectedRow?.detailed_description || ' '}
                            </Typography>
                        </Grid></div>

                    </>}
                    <div>
                        <Typography
                            color="primary"
                            varient="h3"
                            style={{ marginBottom: "10px", marginTop: "10px" }}
                        >
                            Files
                        </Typography>
                    </div>
                    <div>
                        {name === 'service' ? <FileUpload
                            defaultValue={currentSelectedRow.tech_data !== undefined
                                ? currentSelectedRow.tech_data.map((file) => ({
                                    filename: file?.filename,
                                    url: file?.url,
                                    id: file?.id,
                                })) : " "
                            }
                            APIUrl={""}
                            showBorderOnNoFiles={true}
                            uploadButtonView={true}
                            multiple={true}
                            name={"maintenance_file"}
                            action={"Upload Document"}

                        /> :
                            <FileUpload
                                defaultValue={currentSelectedRow.maintenance_file !== undefined
                                    ? currentSelectedRow.maintenance_file.map((file) => ({
                                        filename: file?.file_name,
                                        url: file?.file_path,
                                        id: file?.id,
                                    })) : " "
                                }
                                APIUrl={""}

                                // setFiles={setPrePackingFiles}
                                showBorderOnNoFiles={true}
                                uploadButtonView={true}
                                multiple={true}
                                name={"maintenance_file"}
                                action={"Upload Document"}


                            />}
                    </div>
                </div>

            </Dialog>
        </div>
    )
}

export default TechFilesPopup