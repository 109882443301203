import { Grid, makeStyles, Box, Typography, InputAdornment, MenuItem, IconButton, Divider } from '@material-ui/core'
import ManageDataDialog from 'components/form_components/manage_data_dialog'
import MROTextField from 'components/form_components/TextField'
import React, { useEffect, useState, useCallback, useReducer, useContext } from 'react'
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { Autocomplete } from '@material-ui/lab';
import QuantityCounter from 'components/quantity_counter';
import CopyParts from 'assets/copyparts.png'
import DataService from 'lib/services/api';
import { WorkOrderServices } from 'lib/services/api/operaitons/lineMaintenance/workOrder/workOrder';
import PartsClientServices from "lib/services/api/stores/tooling/parts_client";
import { MRO_MANAGED_PART, MRO_NON_MANAGED_PART, MRO_REQUEST_PART } from "../../../../constants";
import { ToastMessageContext } from 'lib/contexts/message_context';
import dataReducer, { INITIAL_DATA_STATE } from 'reducers/data_reducer';
import { getOrganisationId } from 'lib/utils/common_utils';




const useStyles = makeStyles((theme) => ({
    error: {
        borderColor: "#ff0056",
        borderWidth: "1px",
        borderStyle: "solid",
        margin: "0px",
    },
    purchaseInfo2: {
        borderRadius: "8px",
        // padding: "10px 25px",
        "& div": {
            display: "flex",
            alignItems: "center",
            margin: "10px 0",
            fontSize: "16px",
            "& span:first-child": {
                flex: "0 0 50%",
                color: theme.palette.primary.main,
            },
            "& span:last-child": {
                color: "#4D4F5C",
            },
        },
    },

}));

function AddPart(props) {
    const classes = useStyles();
    const { handleClose, action, workOrderId, lineCustomerId, popUpData } = props
    const [loading, setLoading] = useState(false)
    const [busy, setBusy] = useState(false)
    const [quantity, setQuantity] = useState();
    const [batchNumbers, setBatchNumbers] = useState([]);
    const [selectedSecondaryPart, setSelectedSecondaryPart] = useState({});
    const [details, setDetails] = useState(null);
    const [partDetails, setPartDetails] = useState({});
    const [primaryPartData, setPrimaryPartData] = useState([])
    const [primeId, setPrimeId] = useState(null)
    const [selectedSecondaryPartList, setSelectedSecondaryPartList] = useState([])
    const [selectBatch, setSelectBatch] = useState({})
    const [copySelectedPartOff, setCopSelectedpartOff] = useState('')
    const [nonManagedCopyParts, setNonmanagedCopyParts] = useState('')
    const [quantityRemoved, setQuantityRemoved] = useState();
    const [existingQty, setExistingQty] = useState(0);
    const [errors, setErrors] = useState(false);
    const [mroOwned, setMroOwned] = useState(null);
    const [value, setValue] = useState("no");
    const user = JSON.parse(sessionStorage.getItem("user"));
    const [selectedPartBase, setSelectedPartBase] = useState(null);
    const [state, rDispatch] = useReducer(dataReducer, INITIAL_DATA_STATE);
    const message = useContext(ToastMessageContext);
    let selectedPart = ''



    const handleToggle = (name, value) => {
        if (value) {
            setValue(value);
            // handleChange({ name: name, value: value })
        }
    };
    const getDetails = async () => {
        const params = {
            organisation_id: getOrganisationId(),
            batch_number: selectBatch.batch_number,
        };
        try {
            const { data } = await WorkOrderServices.getBatchNumbers(params);
            // console.log(data);
            if (action != "update") {
                setExistingQty(data?.quantity)
            }
            setDetails(data);
        } catch (error) {
            console.log(error);
        }
    };
    // console.log("primaryPartData is===============", primeId, details)
    useEffect(() => {
        const { batch_number } = selectedSecondaryPart;

        if (batch_number || selectBatch?.batch_number) {
            getDetails();
        }
    }, [selectedSecondaryPart?.batch_number, selectBatch?.batch_number]);


    const getPrimePartData = async () => {
        if (primaryPartData) {
            PartsClientServices.getAllPartsDependency({}).then((res) => {
                setPrimaryPartData(res.data);
            });
        }
    }

    useEffect(() => {
        details && setSelectedPartBase(details?.base);
    }, [details?.base]);

    useEffect(() => {
        getPrimePartData()
    }, [primeId])

    useEffect(() => {
        const params = {
            id: primeId
        }
        if (primeId) {
            PartsClientServices.getAllSecondaryParts(params).then((res) =>
                setSelectedSecondaryPartList(res.data),
                selectedPart = primeId
            );
        }
    }, [primeId]);
    console.log("primarypartData=====", action)
    // fetching available qty from the database
    useEffect(() => {
        if (action === "parts") {
            const params = {
                organisation_id: getOrganisationId(),
                batch_number: selectedSecondaryPart?.batch_number,
            };

            DataService.getData(params, "existingQty").then((res) => {
                const { unique_part_quantity } = res;
                setExistingQty(unique_part_quantity);
            });
        }
    }, [
        selectedSecondaryPart,
        action,
        setExistingQty,
    ]);

    const handleClickCopyParts = () => {
        // console.log("primarypartData=====", partDetails, nonManagedCopyParts)

        setCopSelectedpartOff(partDetails?.part_number)

    }

    const cb = (id = "") => {
        props.setCreatedId(id);
    };

    // useEffect(() => {
    //     // setBusy("loading");
    //     LineCustomerServiceNew.getLineCustomerDetails({
    //         line_customer_id: lineCustomerId,
    //     })
    //         .then((res) => {
    //             const {
    //                 line_customer: { part_type },
    //             } = res;
    //             setMroOwned(part_type);
    //         })
    //         .catch((err) =>
    //             console.log("something went wrong from part details", err)
    //         )
    //         .finally(() => { }

    //         );
    // }, []);


    const handleSubmit = useCallback(
        async (formData) => {
            let error = {};
            let _valid = true;
            let partRemovedData = formData.get("part_removed");
            let removalLocation = formData.get('install_location')
            let description_off = formData.get('description')
            const qty = formData.get("quantity");
            formData.delete("part_removed")

            if (partRemovedData === "yes") {
                formData.append("removal_location", removalLocation)
            }
            formData.append("description_off", description_off)
            formData.append("rotable_removed", partRemovedData === "yes" ? true : false);



            await new Promise((resolve, reject) => {
                resolve(
                    (() => {
                        for (var pair of formData.entries()) {
                            // console.log("key-value =>", pair[0] + ', ' + pair[1]);
                            if (
                                pair[1] === "" &&
                                pair[0] !== "serial_on" &&
                                pair[0] !== "removal_reason"
                            ) {
                                if (pair[0] === "quantity") {
                                    error[pair[0]] = {
                                        valid: pair[1] === "" ? false : true,
                                    };
                                } else if (pair[0] !== "quantity") {
                                    error[pair[0]] = {
                                        valid: pair[1] === "" ? false : true,
                                    };
                                }

                                _valid = false;
                            }
                        }
                    })()
                );
            });

            if (qty !== "" &&
                Number(qty) > Number(existingQty)
            ) {
                error = {
                    ...error,
                    quantity: true,
                    qtyMsg: "Please enter quantity within the available quantity.",
                };
                _valid = false;
            }

            setErrors(error);
            // console.log("errorrr", error, existingQty)
            if (!_valid) {
                return;
            }


            formData.append("user_id", user?.id);
            formData.append("base_station", selectedPartBase);
            formData.append("reportable_id", workOrderId);
            formData.append("reportable_type", "WorkOrder");

            if (partRemovedData === "no") {
                formData.append("part_off", "");
                formData.append("serial_off", "");
                formData.append("removal_location", "");
                formData.append("removal_reason", "");
            }





            setLoading(true);

            DataService.updateData(formData, "", "managedPart")
                .then((response) => {
                    if (response.success) {

                    }
                    else if (!response.success) {
                        message.showToastMessage({ message: response.errors ? response.errors.join(', ') : "Something went wrong. Try again", variant: "error" });

                    }

                    handleClose();
                    // console.log("runing...", props.getList);
                    props.getList();


                })
                .catch((err) => {
                    // console.log("from add parts tools", err);
                })
                .finally(() => {
                    setLoading(false)
                    // setTableRefresh(false)
                })
        },


        [selectedPartBase, existingQty]
    );










    return (
        <ManageDataDialog
            title={"Add Parts"}
            // noAction={createSection === ("tool" || "tasks")}
            type={"Create"}
            fullWidth
            maxWidth={"md"}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            loading={loading}
        >






            <Grid container direction={"row"} justify="space-around">

                <Grid item xs={5}>
                    <Typography variant="subtitle2" gutterBottom={12}>
                        Installed Part
                    </Typography>
                    <Grid item xs={12}>
                        <Autocomplete
                            freeSolo
                            id="free-solo-2-demo"
                            disableClearable
                            options={primaryPartData || []}
                            getOptionLabel={(option) =>
                                option?.part_number ? option?.part_number : ""
                            }
                            getOptionSelected={(option, value) => option.primary_part_id === value.primary_part_id
                            }
                            onChange={(event, newValue) => {
                                setPrimeId(newValue?.primary_part_id)
                                setPrimaryPartData(newValue);
                                setPartDetails({
                                    part_number: newValue.part_number,
                                    description: newValue.description,
                                    serial_number: newValue.serial_number,
                                    units_of_measure: newValue.unit_name

                                })
                            }}

                            className={"search"}
                            renderInput={(params) => (
                                <MROTextField
                                    {...params}
                                    label={"Part # *"}
                                    placeholder="First, search & select part number"
                                    name={"part_number"}
                                    margin="normal"
                                    variant="outlined"
                                    id="test"
                                    InputProps={{
                                        ...params.InputProps,
                                        type: "search",
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                    className={classes.inputField}
                                    color="primary"
                                    error={errors?.part_number && !errors?.part_number?.valid}
                                />
                            )}
                        />

                    </Grid>
                    <MROTextField

                        name="batch_number"
                        label="Batch #"
                        select
                        InputLabelProps={{ shrink: true }}
                        error={errors?.batch_number && !errors?.batch_number?.valid}
                        onClick={(e) => setSelectBatch({ batch_number: e.target.value })}
                    >
                        <MenuItem value="select">
                            <span style={{ opacity: "40%" }}>Second, select batch number</span>
                        </MenuItem>
                        {selectedSecondaryPartList.length === 0 &&
                            <span style={{ opacity: "40%", display: "flex", justifyContent: "center" }}>No Result Found</span>}
                        <MenuItem>
                        </MenuItem>
                        {selectedSecondaryPartList.length !== 0 && selectedSecondaryPartList.filter(((item) => item.state === "In Stock")).map((uom) => (
                            <MenuItem
                                key={uom.batch_number}
                                value={uom.batch_number}
                            >
                                {uom.batch_number}
                            </MenuItem>
                        ))}
                    </MROTextField>
                    <MROTextField
                        style={{ display: "none" }}
                        value={partDetails.description}
                        // disabled={currentSelection?.description && true}
                        // defaultValue={currentSelection?.description}
                        name="description"
                    // label="Description *"
                    // InputLabelProps={{ shrink: true }}
                    // error={errors?.description && !errors?.description?.valid}
                    />
                    <div className={classes.purchaseInfo2}>
                        <div>
                            <span>Part #</span>
                            <span>{partDetails?.part_number ?? "-"}</span>
                        </div>
                        <div>
                            <span>Description</span>
                            <span>{partDetails?.description ?? "-"}</span>
                        </div>   <div>
                            <span>Units of Measure</span>
                            <span>{partDetails?.units_of_measure ?? "-"}</span>
                        </div>
                        <div>
                            <span>Serial # On</span>
                            <span>{partDetails?.serial_number ?? "-"}</span>
                        </div>
                    </div>
                    <Grid
                        container
                        // spacing={1}
                        alignItems={"center"}
                    // justify={"flex-start"}
                    >
                        <QuantityCounter
                            name="quantity"
                            label={
                                "Quantity*"
                            }
                            // placeholder={placeholder}
                            setQty={setQuantity}
                            qty={quantity}
                            autoFocus={errors.quantity}
                            error={errors.quantity}
                            helperText={
                                errors.quantity
                                    ? errors.qtyMsg
                                        ? errors.qtyMsg
                                        : "*please enter Quantity" : `Available quantity ${existingQty}`

                            }
                            setError={() =>
                                setErrors({ ...errors, quantity: false, qtyMsg: null })
                            }
                        // minQty={1}
                        />
                    </Grid>
                    <MROTextField
                        name="install_location"
                        label="Install Location *"
                        InputLabelProps={{ shrink: true }}
                        error={
                            errors?.install_location && !errors?.install_location?.valid
                        }

                        onChange={() => {
                            setErrors({ ...errors, install_location: false })
                        }}

                        placeholder="Location"
                    />



                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item xs={5}>
                    <Typography variant="subtitle2" gutterBottom={12}>
                        Removed Part
                    </Typography>

                    <Box height="100%" width="100%" px={0}>

                        <Box mb={1}>
                            <Box display="inline-block" mr={2}>
                                <Typography gutterBottom={12}>
                                    Record Part Removed *
                                </Typography>
                            </Box>
                            <input
                                style={{ display: "none" }}
                                name="part_removed"
                                value={value}
                            />
                            <ToggleButtonGroup
                                value={value}
                                name=""
                                exclusive
                                onChange={(e, val) => handleToggle("rotable", val)}
                                aria-label="yes-or-no"
                            >
                                <ToggleButton
                                    className={
                                        errors.part_removed && !errors?.part_removed?.valid
                                            ? classes.error
                                            : {}
                                    }
                                    size={"small"}
                                    value="no"
                                    aria-label="centered"
                                >
                                    No
                                </ToggleButton>
                                <ToggleButton
                                    className={
                                        errors.part_removed && !errors?.part_removed?.valid
                                            ? classes.error
                                            : {}
                                    }
                                    size={"small"}
                                    value="yes"
                                    aria-label="left aligned"
                                >
                                    Yes
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Box>
                        {value === "yes" && (
                            <>
                                <MROTextField
                                    name="part_off"
                                    error={errors?.part_off && !errors?.part_off?.valid}
                                    label="Part # Off *"
                                    variant="outlined"
                                    value={copySelectedPartOff}

                                    onChange={(e) => setCopSelectedpartOff(e.target.value)}
                                    InputLabelProps={{ shrink: true }}
                                    placeholder={"Enter Part Number"}
                                    InputProps={{

                                        endAdornment: <InputAdornment position="end">
                                            <IconButton
                                                aria-label="copy-part-on"
                                                onClick={handleClickCopyParts}
                                                // onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                <img src={CopyParts} height={'22px'} />
                                            </IconButton>
                                        </InputAdornment>,

                                    }}

                                />
                                <MROTextField
                                    name="serial_off"
                                    error={errors?.serial_off && !errors?.serial_off?.valid}
                                    variant="outlined"
                                    label="Serial # Off *"

                                    InputLabelProps={{ shrink: true }}
                                    placeholder={"Enter Serial Number"}
                                />
                                <Grid
                                    container
                                    // spacing={1}
                                    alignItems={"center"}
                                // justify={"flex-start"}

                                >
                                    <QuantityCounter
                                        name="removed_quantity"
                                        label={"QTY Removed *"}
                                        // placeholder={placeholder}
                                        setQty={setQuantityRemoved}
                                        qty={quantityRemoved}
                                        autoFocus={errors.removed_quantity}
                                        error={errors.removed_quantity}
                                        helperText={
                                            errors.quantity_removed
                                                ? errors.qtyMsg
                                                    ? errors.qtyMsg
                                                    : "*please enter Quantity"
                                                : mroOwned === MRO_MANAGED_PART
                                                    ? `Available quantity ${existingQty}`
                                                    : null
                                        }
                                        setError={() =>
                                            setErrors({ ...errors, removed_quantity: false, qtyMsg: null })
                                        }
                                    // minQty={1}
                                    />
                                </Grid>



                                <MROTextField
                                    name={"issued_comment"}
                                    label={"U/S Comment *"}
                                    variant="outlined"
                                    multiline
                                    InputProps={{
                                        style: {
                                            height: 'max-content',
                                            width: "100%",
                                        },
                                    }}
                                    rows={4}
                                    InputLabelProps={{ shrink: true }}
                                    placeholder="Enter a removal reason or other comment as applicable - Max 150 Characters"
                                    inputProps={{ maxLength: 150 }}
                                />



                            </>
                        )}

                    </Box>


                </Grid>

            </Grid>












        </ManageDataDialog>



    )
}

export default AddPart