import React, {
  useState,
  useEffect,
  useContext,
  useReducer,
  createContext,
} from "react";
import ListingService from "../../../../lib/services/api/listing_service";
import SectionLabel from "./FieldComponents/section_label";
import FreeTextField from "./FieldComponents/free_text_field";
import Grid from "@material-ui/core/Grid";
import YesNoInput from "./FieldComponents/yes_or_no_based_input_field";
import NumericField from "./FieldComponents/numeric_field_with_increments";
import LoadingIndicator from "../../../../components/universal/loading_indicator";
import {
  FormContext,
} from "../../../../lib/contexts/form_context";
import Typography from "@material-ui/core/Typography";
import MROButton from "../../../../components/buttons";
import DropdownField from "./FieldComponents/dropdown_field";
// import DateField from "../../../../components/form_components/DateField";
// import UploadMultipleButton from "components/form_components/upload_multiple_button";
// import ManageDialog from "../../../../components/form_components/manage_dialog";
// import MaterialTable from "material-table";
// import tableIcons, {
//   options,
// } from "../../../../components/universal/table_attributes";
import { OperationService } from "../../../../lib/services/api/operaitons/save_operation";
import { ToastMessageContext } from "../../../../lib/contexts/message_context";
import {
  Box,
  MuiThemeProvider,
  useTheme,
} from "@material-ui/core";
import { createTheme } from '@material-ui/core/styles'
import AddIcon from "@material-ui/icons/Add";
import moment from "moment";
import AddNewPartsAndTools from "./add_parts_tools";
import PartDetails from "./PartDetails";
import TableAction from "../../../../actions/table_actions";
import tableReducer, {
  INITIAL_TABLE_STATE,
} from "../../../../reducers/table_reducer";
// import dataReducer, {
//   INITIAL_DATA_STATE,
// } from "../../../../reducers/data_reducer";
import WorkOrderTooligs from "./WorkOrderTooligs";
import Rendermainttable from "./RenderMaintTable";
import SectionLabelAction from "./FieldComponents/section_label_with_action";
import AddMaintTasks from "./AddMaintTasks";
import AdditionalEngineers from "./AdditionalEngineers";
import { MultiUploadPreview } from "./FieldComponents/MultiUpload";
import { AircraftDDs } from "./AircraftDDs";
import InbondDelayCalculation from "./InbondDelayCalc";
import { DepartureDelayCalculation } from "./DepartureDelayCalc";
import { OilAndHydFluid } from "./OilAndHydFluid";
import { StaionCompleteAndComments } from "./StaionCompleteAndComments";
import { useRef } from "react";
import DataService from "lib/services/api";
import CreateAlternativePart from "./AlternativeParts/create/CreateAlternativePart";
import { MRO_MANAGED_PART, MRO_REQUEST_PART } from "../../../../constants";
import UploadButton from "components/form_components/upload_button";
import _ from 'lodash'
import AlternativePartsTable from "./AlternativeParts/AlternativePartsTable";
import SignatureFile from "components/form_components/fileUpload/SIgnatureFile";
import RemovedParts from "./AlternativeParts/RemovedParts/RemovedParts";
import PlannedTaskDetails from "./PlannedTaskDetails";


export const PartDetailsTableRefresh = createContext();

const mapTasks = {
  "Tech Log Maint Tasks": "tech_log_maint",
  "Non-Fixed Price Scheduled Maintenance": "fixed_price_maint",
  Defects: "defects",
  "Cabin Maint Tasks": "cabin_maint",
};
export const timeCalculationFields = [
  "Est Arrival Date/Time (UTC)",
  "Act Arrival Date/Time (UTC)",
  "Inbd delay (mins)",
];

export const departureTimeCalcFields = [
  "Est Departure Date/Time (UTC)",
  "Act Departure Date/Time (UTC)",
  "Delay",
  "Gnd time",
];

export const HydAndOilQuarts = ["Oil - Quarts", "Hyd Fluid - Quarts"];

export const getTimeDiffFormatted = (duration) => {
  const days = duration.get("days");

  const hrsMinDiff =
    String(Math.abs(duration.get("hours"))).padStart(2, "0") +
    ":" +
    String(Math.abs(duration.get("minutes"))).padStart(2, "0");
  const hrs = Boolean(Math.abs(days))
    ? String(duration.get("days")).padStart(2, "0") +
    ":" +
    String(Math.abs(duration.get("hours"))).padStart(2, "0") +
    ":" +
    String(Math.abs(duration.get("minutes"))).padStart(2, "0")
    : Math.sign(duration.get("minutes")) == "-1"
      ? "- " + hrsMinDiff
      : hrsMinDiff;

  return hrs;
};

export const StationAndCommentFields = ["Station Complete", "Comments"];

function SectionDetail(props) {
  const theme1 = useTheme()
  const [tableRefresh, setTableRefresh] = useState(false);
  const { sectionId, lineCustomerId, workOrderId, baseStationId } = props;
  const [sectionDetails, setSectionDetails] = useState([]);
  const [sectionName, setSectionName] = useState("");
  const message = useContext(ToastMessageContext);
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState("");
  const [createdId, setCreatedId] = useState("");
  const form = useContext(FormContext);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({});
  const [mroOwned, setMroOwned] = useState(1);
  const [subSectionDetails, setSubSectionDetails] = useState([])
  const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
  const {
    page,
    totalCount,
    pageSize,
    data: _data,
  } = tableState;
  const [state, rDispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
  const [getList, setGetList] = useState(() => { });

  const [orgSectionId, setOrgSectionId] = useState(null);
  const [files, setFiles] = useState([]);

  const fileUploadHandler = () => {
    const fileFormData = new FormData();

    let existFile = sectionDetails?.map(sec => { return sec.sub_section_fields[0]?.file?.map(name => name.filename) })
    if (existFile[0] !== undefined) {
      const fileList = Array.from(files[0] || []);
      let newFile = fileList?.map((item) => item.name)
      let uniqueFiles = newFile?.filter(val => !existFile[0].includes(val));
      let popFile = fileList?.filter((item) => {
        if (uniqueFiles.includes(item.name) !== false) {
          return item
        }
      })
      popFile.forEach((file) => fileFormData.append("documents[]", file))
    } else {
      const file = [];
      files.forEach((_, i) => {
        file[i] = Array.from(files[i]);
        file[i].forEach((file) => fileFormData.append("documents[]", file));
      });
    }

    console.log("files ateeeee==============", files)

    // const file = [];
    // files.forEach((_, i) => {
    //   file[i] = Array.from(files[i]);
    //   file[i].forEach((file) => fileFormData.append("documents[]", file));
    // });
    // let result = _.intersection(popFile, existFile[0])
    // uniqueFiles.forEach((file)=> fileFormData.append("documents[]", file))

    orgSectionId && fileFormData.append("org_section_id", orgSectionId);
    fileFormData.append("id", workOrderId);

    fileFormData.append("operable_type", "WorkOrder");
    DataService.createData(fileFormData, "uploadDocs")
      .then((res) => getSectionDetails())
      .catch((err) => console.log(err));
  };

  const sectionRef = useRef();

  useEffect(() => {
    getSectionDetails();
  }, [sectionId, workOrderId, lineCustomerId]);

  const [fieldsMap, setFieldsMap] = useState({});

  const fieldsToSkipValidation = [
    "Closed By",
    "Name",
    "Date Closed",
    "Total Hours",
  ];

  useEffect(() => {
    let map = {};
    let list = sectionDetails
      .map((sd) => {
        if (sd.sub_section_fields) {
          return sd.sub_section_fields;
        }
        if (sd.sub_sections) {
          return sd.sub_sections;
        }
      })
      .flat();

    list.map((item) => {
      // if (item) {
      map[item?.id] = {
        name: item?.field_name,
        ...item,
      };
      if (item?.secondary_field) {
        map[item?.secondary_fields[0]?.org_field_id] = {
          name: item.secondary_fields[0]?.field_name,
          ...item?.secondary_fields[0],
        };
      }
      if (item?.child_section_fields) {
        item.child_section_fields.map((cf) => {
          map[cf.id] = {
            name: cf.field_name,
            ...cf,
          };
        });
      }
      // }
    });

    setFieldsMap(map);

    // setOveridesErrorsField(Object?.values(map)?.map(value => value?.name))
  }, [sectionName, sectionDetails]);

  const fieldTypeMap = {
    Freetext: "textfield",
    Datetime: "Datetime",
    "Yes/No": "yes_or_no",
    Numeric: "numeric",
    Dropdown: "dropdown",
    table_data: "table_data",
    File: "File",
    Numerictext: "Numerictext",
    Time: "time",
  };

  const getSectionDetails = () => {
    if (sectionId && workOrderId && lineCustomerId) {
      const params = {
        line_customer_id: lineCustomerId || 72,
        org_section_id: sectionId || 153,
        id: workOrderId || 118,
        operable_type: "WorkOrder",
      };
      setLoading(true);
      ListingService.getData(params, "workOrderSectionDetail").then((res) => {
        if (res.section_details) {
          setSectionName(res.section_details.section_name);
          setSectionDetails(res.section_details.sub_sections || []);
        }
        setLoading(false);
      });
    }
  };

  const handleSubmit = (e) => {
    // #region testing region fold for submit
    // try {
    e.preventDefault();
    if (e.target.id === "mainform") {
      // const currentFormData = form.formState;
      // let _data = [];
      // let __data = [];
      // let newData = [];
      // let allIndexes = "";
      // // let errors = {

      // // }
      // for (var pair of new FormData(e.target).entries()) {
      //   _data.push(pair);
      //   __data.push(pair[0]);
      // }
      // const findIndexOfAll = (arr, val) =>
      //   arr.reduce(
      //     (acc, el, i) => (el.indexOf(val) > -1 ? [...acc, i] : acc),
      //     []
      //   );
      // const allIndexs = findIndexOfAll(__data, "org_section_id");

      // const constructObject = (arr) => {
      //   return arr.reduce((acc, val) => {
      //     const [key, value] = val;
      //     if (value.includes("[", "]")) {
      //       acc[key] = JSON.parse(value);
      //     } else {
      //       let ud = updateFieldData(value);
      //       acc[key] = ud?.id ? ud.id : value === "" ? null : value;
      //     }

      //     return acc;
      //   }, {});
      // };

      // allIndexs.map((indexes, i) => {
      //   let d = [..._data];
      //   if (d[indexes][1] !== "137")
      //     newData.push({
      //       org_section_id: d[indexes][1],
      //       fields: [
      //         {
      //           ...constructObject(
      //             d.splice(
      //               allIndexs[i] + 1,
      //               allIndexs[i + 1] ? allIndexs[i + 1] - 1 : d.length - 1
      //             )
      //           ),
      //         },
      //       ],
      //     });
      // });

      // const newFormData = [];
      // for (let data in currentFormData) {
      //   if (currentFormData.hasOwnProperty(data)) {
      //     let fObj = {
      //       id: data,
      //       value: currentFormData[data],
      //     };
      //     newFormData.push(fObj);
      //   }
      // }

      let params = {};

      // let data = {
      //   operable_type: "WorkOrder",
      //   id: workOrderId,
      //   close: false,
      //   data: newData,
      // };

      // let error = {};
      // let fieldData = {};
      // const whiteListKeys = [];
      //['4012', '4013', '4018', '4070']

      // newData.map((data) => {
      //   let field = data.fields[0];
      //   Object.keys(field).map((key) => {
      //     if (!fieldsToSkipValidation.includes(fieldsMap[key]?.name)) {
      //       // error[key] = {
      //       //     valid: field[key] !== '' ? true : false
      //       // }
      //       fieldData[key] = {
      //         value: field[key],
      //       };
      //     }
      //   });
      // });

      // let overrrideErr = {}
      // Object.keys(fieldData).map(d => {
      //     // if (fieldsMap[d].mandatory)
      //     //  if (fieldsMap[d]?.)
      //     let err = errors[d]
      //     error[d] = {
      //         valid: fieldData[d].value === '' && fieldsMap[d]?.mandatory ? false : true
      //     }
      //     if (overridesErrorsField.includes(fieldsMap[d]?.name) && fieldData[d]?.value) {
      //         overrrideErr[d] = {
      //             valid: true
      //         }
      //     }
      // })
      //
      // setErrors({...error, ...errors, ...overrrideErr})
      // if (Object.values({...error, ...errors, ...overrrideErr}).some(e => e.valid === false)) {
      //     sectionRef.current.scrollIntoView({behavior: "smooth"});
      //     return false;
      // } else {
      //     setErrors({})
      // }
      //************************************************************************************
      //? This logic has been written by Shahzaib
      let sectionData = {
        operable_type: "WorkOrder",
        id: workOrderId,
        close: false,
        data: [],
      };

      let data = [];

      let occurrence = 0;

      //! converting the form data into requierd data structure by api
      for (const pair of new FormData(e.target).entries()) {
        const key = pair[0];
        let bases = props.bases.filter(base => pair[1].includes(base.station))
        const value = pair[1].includes("[", "]")
          ? JSON.parse(pair[1]) : bases?.length != 0 ? null
            : pair[1];

        if (key.includes("org_section_id")) {
          data.push({ org_section_id: value, fields: [] });
          occurrence++;
        } else {
          if (data[occurrence - 1]?.fields.length === 0) {
            data[occurrence - 1].fields.push({
              [key]: value === "" || value === "Invalid date" ? null : value,
            });
          } else {
            data[occurrence - 1].fields[0][key] =
              value === "" || value === "Invalid date" ? null : value;
          }
        }
      }

      const updatedData = data.length > 0 && data.filter((item) => item.fields.length > 0)
      // console.log("data===", updatedData)
      sectionData.data = updatedData;
      //************************************************************************************

      OperationService.saveOperationFields(params)(sectionData)
        .then((response) => {
          if (files.length > 0) {
            fileUploadHandler();
          }
          props.getList();
          message.showToastMessage({
            message: "Work Order updated successfully.",
            variant: "success",
          });
          getSectionDetails();
        })
        .catch((err) => {
          message.showToastMessage({
            message: "Something went wrong. Try Again",
            variant: "error",
          });
        });
    }
    // } catch (error) {
    //   message.showToastMessage({
    //     message: "Something went wrong. Try Again",
    //     variant: "error",
    //   });
    // }
    //#endregion
  };

  const [showTableData, setShowTableData] = useState(false);
  const [currentSelection, setCurrentSelection] = useState();
  const [data, setData] = useState([]);

  const columns = [
    {
      title: "Task / TLP #",
      field: "task_tlp",
    },
    {
      title: "ATA Chapter",
      field: "ata_chapter",
    },
    {
      title: "Description",
      field: "description",
    },
    {
      title: "Man-Hours",
      field: "man_hours",
    },
  ];

  useEffect(() => {
    // if (currentSelection?.child_section_name)
    // getOperationList(mapTasks[currentSelection?.child_section_name])
  }, [page, totalCount, pageSize, currentSelection]);

  const closeTableData = () => {
    setShowTableData(false);
    setCurrentSelection(null);
    TableAction.clearData(rDispatch);
  };
  const handleTableData = (data) => () => {
    setShowTableData(true);
    setCurrentSelection(data);
    getOperationList(mapTasks[data.child_section_name]);
  };

  function getOperationList(task_type) {
    let params = {
      id: workOrderId,
      task_type,
      page: page + 1,
      count_per_page: pageSize,
    };
    TableAction.getList(dispatch, message, params, "operational_tasks");
  }

  const getSectionName = (title) => {
    switch (title) {
      case "Part Details":
        return "part";
      case "Tooling":
        return "tool";
      default:
        return null
    }
  };

  const [createSection, setCreateSection] = useState("");
  const handleClose = () => {
    setTableRefresh(!tableRefresh);
    setCreateSection(null);
    setAction(null);
  };

  //! Inbd delay (mins) = Act Arrival Date/Time (UTC) - Est Arrival Date/Time (UTC)
  const [inbondState, setInbondState] = useState({
    "Est Arrival Date/Time (UTC)": "",
    "Act Arrival Date/Time (UTC)": "",
    "Inbd delay (mins)": "",
  });
  const [delayCode, setDelayCode] = useState("00.00")
  // ! Delay = Act Departure Date/Time (UTC) - Est Departure Date/Time (UTC)
  //! Gnd time = Act Departure Date/Time (UTC) - Act Arrival Date/Time (UTC)
  const [departureDetailsTime, setDepartureDetailsTime] = useState({
    "Est Departure Date/Time (UTC)": "",
    "Act Departure Date/Time (UTC)": "",
    Delay: "",
    "Gnd time": "",
  });

  const [oilState, setOilState] = useState({
    "Oil - Quarts": "",
    "Hyd Fluid - Quarts": "",
  });

  // useEffect(() => {
  //   let format = "YYYY-MM-DD - HH:mm:ss";
  //   let revFormat = "DD-MM-YYYY - HH:mm:ss";
  //   let duration = moment.duration(
  //     moment(
  //       inbondState[timeCalculationFields[1]],
  //        format).diff(
  //       moment(
  //         inbondState[timeCalculationFields[0]],
  //          format)
  //     )
  //   );

  //   if (duration.isValid()) {
  //     const hrs =
  //       (duration.get("days") * 24 + duration.get("hours"))
  //         .toString()
  //         .padStart(2, "0") +
  //       ":" +
  //       duration.get("minutes").toString().padStart(2, "0");
  //     const timespan = hrs.toString().padStart(2, "0");
  //     // + ":" + duration.get("seconds").toString().
  //     //     padStart(2, '0');

  //     setInbondState({
  //       ...inbondState,
  //       "Inbd delay (mins)": getTimeDiffFormatted(duration),
  //     });
  //   }
  // }, [
  //   inbondState["Act Arrival Date/Time (UTC)"],
  //   inbondState["Est Arrival Date/Time (UTC)"],
  // ]);

  // useEffect(() => {
  //   let format = "YYYY-MM-DD - HH:mm:ss";
  // }, [
  //   inbondState["Act Arrival Date/Time (UTC)"],
  //   departureDetailsTime["Act Departure Date/Time (UTC)"],
  // ]);
  console.log("inboundd=======", typeof delayCode)
  useEffect(() => {
    let format = "YYYY-MM-DD - HH:mm:ss";
    let revFormat = "DD-MM-YYYY - HH:mm:ss";

    let gndDuration = moment.duration(
      moment(departureDetailsTime[departureTimeCalcFields[1]], format).diff(
        moment(inbondState[timeCalculationFields[1]], format)
      )
    );

    let delayDuration = moment.duration(
      moment(
        departureDetailsTime["Act Departure Date/Time (UTC)"],
        // departureDetailsTime["Est Departure Date/Time (UTC)"],
        format
      ).diff(
        moment(departureDetailsTime["Est Departure Date/Time (UTC)"], format)
        // moment(departureDetailsTime["Act Departure Date/Time (UTC)"], format)
      )
    );

    // if (gndDuration.isValid() || delayDuration.isValid()) {
    //     const hrs = gndDuration.get('days').toString().padStart(2, '0') + ":" + gndDuration.get("hours").toString().padStart(2, '0') + ":" + gndDuration.get("minutes").toString().padStart(2, '0')
    //     const timespan = hrs.toString().
    //         padStart(2, '0')
    //     // + ":" + gndDuration.get("seconds").toString().
    //     //     padStart(2, '0');

    // }

    if (delayDuration.isValid() || gndDuration.isValid()) {
      const days = delayDuration.get("days");

      const hrs = Boolean(Math.abs(days))
        ? String(delayDuration.get("days")).padStart(2, "0") +
        ":" +
        String(Math.abs(delayDuration.get("hours"))).padStart(2, "0") +
        ":" +
        String(Math.abs(delayDuration.get("minutes"))).padStart(2, "0")
        : String(delayDuration.get("hours")).padStart(2, "0") +
        ":" +
        String(Math.abs(delayDuration.get("minutes"))).padStart(2, "0");
      const timespan = hrs;
      console.log({ gndDuration, delayDuration });
      setDepartureDetailsTime({
        ...departureDetailsTime,
        "Gnd time": gndDuration.isValid()
          ? getTimeDiffFormatted(gndDuration)
          : "",
        Delay: delayDuration.isValid()
          ? getTimeDiffFormatted(delayDuration)
          : "",
      });
      // setDepartureDetailsTime({
      //     ...departureDetailsTime,
      //     'Delay': timespan,

      // })
    }
  }, [
    departureDetailsTime["Act Departure Date/Time (UTC)"],
    inbondState["Act Arrival Date/Time (UTC)"],
    departureDetailsTime["Est Departure Date/Time (UTC)"],
  ]);

  useEffect(() => {
    // setErrors({})
  }, [sectionId]);

  // console.log("set files is======================", files)

  const theme = useTheme();
  //TODO: implement useMemo() to boost performance between form value changes.
  return !loading ? (
    <div>
      <MuiThemeProvider theme={sectionTheme(props.permission)(theme)}>
        <div
          ref={sectionRef}
          style={{
            display: "block",
            padding: 10,
            zIndex: 0,
            position: "relative",
          }}
        >
          <form spellcheck="false" onSubmit={handleSubmit} id="mainform">
            {sectionDetails.map((section, i) => {
              return (
                <React.Fragment key={`id-${i}`}>
                  <div style={{ position: "relative", marginTop: 15 }}>
                    <SectionLabel name={section.sub_section_name} />
                    <br />
                    <input
                      form="mainform"
                      style={{ display: "none" }}
                      name={`org_section_id-${section.org_section_id}`}
                      value={section.org_section_id}
                    />
                    {sectionName === "Parts & Tools" && (
                      <>
                        <Box position="absolute" right={0} top={10}>
                          {mroOwned === MRO_MANAGED_PART && section.sub_section_name === "Part Details" ?
                            <>
                              <MROButton
                                variant="contained"
                                color="secondary"
                                disabled={!props?.permission?.write}
                                onClick={() => {
                                  setCreateSection(
                                    getSectionName(section.sub_section_name)
                                  );
                                  setAction("create");
                                  // setAction("create_alternative_part");
                                }}
                                startIcon={<AddIcon size="small" />}
                              >
                                Direct Add {getSectionName(section.sub_section_name)}
                              </MROButton> {''}
                              <MROButton
                                variant="contained"
                                color="secondary"
                                disabled={!props?.permission?.write}
                                onClick={() => {
                                  setCreateSection(
                                    getSectionName(section.sub_section_name)
                                  );
                                  setAction(
                                    mroOwned === MRO_MANAGED_PART
                                      ? getSectionName(section.sub_section_name) === "tool" ? "create" : "create_alternative_part"
                                      : "create"
                                  );
                                  // setAction("create_alternative_part");
                                  setSubSectionDetails(section.sub_sections)
                                }}
                                startIcon={<AddIcon size="small" />}
                              >
                                Request {getSectionName(section.sub_section_name)}
                              </MROButton>

                            </> :
                            <MROButton
                              variant="contained"
                              color="secondary"
                              disabled={!props?.permission?.write}
                              onClick={() => {
                                setCreateSection(
                                  getSectionName(section.sub_section_name)
                                );
                                setAction(
                                  mroOwned === MRO_REQUEST_PART
                                    ? getSectionName(section.sub_section_name) === "tool" ? "create" : "create_alternative_part"
                                    : "create"
                                );
                                // setAction("create_alternative_part");
                                setSubSectionDetails(section.sub_sections)
                              }}
                              startIcon={<AddIcon size="small" />}
                            >
                              Add {getSectionName(section.sub_section_name)}
                            </MROButton>}
                        </Box>
                        {section.sub_section_name === "Part Details" && (
                          <PartDetailsTableRefresh.Provider
                            value={tableRefresh}
                          >
                            <PartDetails
                              mroOwned={mroOwned}
                              setMroOwned={setMroOwned}
                              currentSelection={currentSelection}
                              data={props.data}
                              lineCustomerId={lineCustomerId}
                              createdId={createdId}
                              getList={setGetList}
                              workOrderId={workOrderId}
                              permission={props?.permission}
                            />
                          </PartDetailsTableRefresh.Provider>
                        )}
                        {section.sub_section_name === "Tooling" && (
                          <WorkOrderTooligs
                            createdId={createdId}
                            getList={setGetList}
                            workOrderId={workOrderId}
                          />
                        )}
                      </>
                    )}
                    {section.sub_section_name ===
                      "Aircraft Deferred Defects (ADDs)" && (
                        <AircraftDDs
                          setErrors={setErrors}
                          errors={errors}
                          createdId={createdId}
                          getList={setGetList}
                          workOrderId={workOrderId}
                          fields={section}
                        />
                      )}

                    <Grid
                      container
                      spacing={3}
                      alignItems={"flex-start"}
                      justify={"flex-start"}
                      style={{ paddingLeft: "0px !important" }}
                    >
                      {section &&
                        section.sub_section_fields &&
                        section.sub_section_fields.map((subSection, i) => {
                          if (
                            timeCalculationFields.includes(
                              subSection.field_name
                            )
                          ) {
                            return (
                              <Grid key={i} item xs={4}>
                                <InbondDelayCalculation
                                  inbondState={inbondState}
                                  // errors={errors}
                                  // setErrors={setErrors}
                                  formData={formData}
                                  setInbondState={setInbondState}
                                  sub_section_fields={
                                    section.sub_section_fields
                                  }
                                  subSection={{
                                    ...subSection,
                                    // defaultValue:
                                    //   inbondState[subSection.field_name] ? inbondState[subSection.field_name] : subSection.field_value,
                                    // field_value:
                                    // inbondState[subSection.field_name] ? inbondState[subSection.field_name] : subSection.field_value,
                                  }}
                                  form="mainform"
                                  handleChange={form.handleChange}
                                  formState={form.formState}
                                />
                              </Grid>
                            );
                          }

                          if (
                            departureTimeCalcFields.includes(
                              subSection.field_name
                            )
                          ) {
                            return (
                              <Grid key={i} item xs={4}>
                                <DepartureDelayCalculation
                                  lineCustomerId={lineCustomerId}
                                  inbondState={departureDetailsTime}
                                  // errors={errors}
                                  // setErrors={setErrors}
                                  formData={formData}
                                  setInbondState={setDepartureDetailsTime}
                                  actArrivalDate={
                                    inbondState["Act Arrival Date/Time (UTC)"]
                                  }
                                  sub_section_fields={
                                    section.sub_section_fields
                                  }
                                  subSection={{
                                    ...subSection,
                                    // defaultValue:
                                    //   departureDetailsTime[
                                    //     subSection.field_name
                                    //   ],
                                    // field_value:
                                    //   departureDetailsTime[
                                    //     subSection.field_name
                                    //   ],
                                  }}
                                  setDelayCode={setDelayCode}
                                  form="mainform"
                                  handleChange={form.handleChange}
                                  formState={form.formState}
                                />
                              </Grid>
                            );
                          }

                          if (HydAndOilQuarts.includes(subSection.field_name)) {
                            return (
                              <Grid item xs={4}>
                                <OilAndHydFluid
                                  errors={errors}
                                  setErrors={setErrors}
                                  formData={formData}
                                  oilState={oilState}
                                  setOilState={setOilState}
                                  subSection={subSection}
                                  sub_section_fields={
                                    section.sub_section_fields
                                  }
                                />
                              </Grid>
                            );
                          }
                          if (section.sub_section_name === 'Customer Acceptance' && subSection.field_name === "signature") {
                            return (
                              <Box style={{
                                backgroundColor: "#ffffff",
                                paddingLeft: '82px',
                                paddingTop: ' 15px',
                                width: '336px',
                                border: `1px solid ${theme1.palette.primary.main}`,
                                borderRadius: " 8px",
                                marginBottom: "12px",
                                marginLeft: '12px',
                                marginTop: '16px',
                                minHeight: "146px",

                              }}>
                                {subSection.file !== null ?
                                  <SignatureFile
                                    defaultValue={section?.sub_section_fields.map((file) => ({
                                      id: file?.file?.id,
                                      filename: file?.file?.filename,
                                      url: file?.file?.url,
                                    }))}
                                    // showBorderOnNoFiles={true}
                                    APIUrl={""}
                                    uploadButtonView={false}
                                    name={"Line"}
                                  /> : ''}
                              </Box>
                            )
                          }

                          if (
                            StationAndCommentFields.includes(
                              subSection.field_name
                            )
                          ) {
                            return (
                              <Grid
                                item
                                xs={12}
                                style={{ paddingRight: "25px" }}
                              >
                                <StaionCompleteAndComments
                                  bases={props.bases}
                                  errors={errors}
                                  setErrors={setErrors}
                                  formData={formData}
                                  workOrderId={workOrderId}
                                  subSection={subSection}
                                  selectedBase={props.data.base}
                                />
                              </Grid>
                            );
                          }
                          if (section.sub_section_name === "Planned Maint") {

                            return (
                              <Grid item key={`sub-sec-id-${i}`} xs={12}>
                                <PlannedTaskDetails
                                  workOrderId={workOrderId}
                                  subSection={subSection}
                                />
                              </Grid>
                            )
                          }

                          if (delayCode !== "00:00" && delayCode !== null && subSection.field_name === "Delay Commentary") {
                            return (
                              <Grid item key={`sub-sec-id-${i}`} xs={8}>
                                {fieldTypeMap[subSection.field_type] ===
                                  "textfield" && (
                                    <FreeTextField
                                      {...{ ...subSection, form: "mainform" }}
                                      workOrderId={workOrderId}
                                      form="mainform"
                                      handleChange={form.handleChange}
                                      formState={form.formState}
                                      errors={errors}
                                      setErrors={setErrors}
                                      formData={formData}
                                    />
                                  )}
                                {fieldTypeMap[subSection.field_type] ===
                                  "Datetime" && (
                                    <FreeTextField
                                      {...{ ...subSection, form: "mainform" }}
                                      form="mainform"
                                      handleChange={form.handleChange}
                                      formState={form.formState}
                                      errors={errors}
                                      setErrors={setErrors}
                                      formData={formData}
                                    />
                                  )}
                                {fieldTypeMap[subSection.field_type] ===
                                  "time" && (
                                    <FreeTextField
                                      {...{ ...subSection, form: "mainform" }}
                                      form="mainform"
                                      setErrors={setErrors}
                                      errors={errors}
                                      handleChange={form.handleChange}
                                      formState={form.formState}
                                      formData={formData}
                                    />
                                  )}
                                {fieldTypeMap[subSection.field_type] ===
                                  "dropdown" && (
                                    <DropdownField
                                      {...{ ...subSection, form: "mainform" }}
                                      form="mainform"
                                      handleChange={form.handleChange}
                                      formState={form.formState}
                                      errors={errors}
                                      setErrors={setErrors}
                                      formData={formData}
                                    />
                                  )}
                                {fieldTypeMap[subSection.field_type] ===
                                  "yes_or_no" && (
                                    <YesNoInput
                                      {...{ ...subSection, form: "mainform" }}
                                      form="mainform"
                                      handleChange={form.handleChange}
                                      formState={form.formState}
                                      errors={errors}
                                      setErrors={setErrors}
                                      formData={formData}
                                    />
                                  )}
                                {fieldTypeMap[subSection.field_type] ===
                                  "numeric" && (
                                    <NumericField
                                      {...{ ...subSection, form: "mainform" }}
                                      form="mainform"
                                      handleChange={form.handleChange}
                                      formState={form.formState}
                                      errors={errors}
                                      setErrors={setErrors}
                                      formData={formData}
                                    />
                                  )}
                                {fieldTypeMap[subSection.field_type] ===
                                  "File" && (
                                    <MultiUploadPreview
                                      multiple={true}
                                      id={workOrderId}
                                      org_section_id={section.org_section_id}
                                      {...{
                                        ...subSection,
                                        form: "mainform",
                                      }}
                                      form="mainform"
                                      handleChange={form.handleChange}
                                      setFiles={(files) => {
                                        setFiles(files);
                                      }}
                                      setOrgSectionId={setOrgSectionId}
                                      formData={formData}
                                      formState={form.formState}
                                      setErrors={setErrors}
                                      errors={errors}
                                      getSectionDetails={getSectionDetails}
                                      operableType="WorkOrder"
                                      permission={props?.permission}
                                    />
                                  )}
                                {fieldTypeMap[subSection.field_type] ===
                                  "Numerictext" && (
                                    <NumericField
                                      {...{ ...subSection, form: "mainform" }}
                                      form="mainform"
                                      handleChange={form.handleChange}
                                      formState={form.formState}
                                      errors={errors}
                                      formData={formData}
                                    />
                                  )}
                              </Grid>

                            )
                          }
                          if (subSection.field_name !== "Delay Commentary") {
                            return (
                              <Grid item key={`sub-sec-id-${i}`} xs={4}>
                                {fieldTypeMap[subSection.field_type] ===
                                  "textfield" && (
                                    <FreeTextField
                                      {...{ ...subSection, form: "mainform" }}
                                      workOrderId={workOrderId}
                                      form="mainform"
                                      handleChange={form.handleChange}
                                      formState={form.formState}
                                      errors={errors}
                                      setErrors={setErrors}
                                      formData={formData}
                                    />
                                  )}
                                {fieldTypeMap[subSection.field_type] ===
                                  "Datetime" && (
                                    <FreeTextField
                                      {...{ ...subSection, form: "mainform" }}
                                      form="mainform"
                                      handleChange={form.handleChange}
                                      formState={form.formState}
                                      errors={errors}
                                      setErrors={setErrors}
                                      formData={formData}
                                    />
                                  )}
                                {fieldTypeMap[subSection.field_type] ===
                                  "time" && (
                                    <FreeTextField
                                      {...{ ...subSection, form: "mainform" }}
                                      form="mainform"
                                      setErrors={setErrors}
                                      errors={errors}
                                      handleChange={form.handleChange}
                                      formState={form.formState}
                                      formData={formData}
                                    />
                                  )}
                                {fieldTypeMap[subSection.field_type] ===
                                  "dropdown" && (
                                    <DropdownField
                                      {...{ ...subSection, form: "mainform" }}
                                      form="mainform"
                                      handleChange={form.handleChange}
                                      formState={form.formState}
                                      errors={errors}
                                      setErrors={setErrors}
                                      formData={formData}
                                    />
                                  )}
                                {fieldTypeMap[subSection.field_type] ===
                                  "yes_or_no" && (
                                    <YesNoInput
                                      {...{ ...subSection, form: "mainform" }}
                                      form="mainform"
                                      handleChange={form.handleChange}
                                      formState={form.formState}
                                      errors={errors}
                                      setErrors={setErrors}
                                      formData={formData}
                                    />
                                  )}
                                {fieldTypeMap[subSection.field_type] ===
                                  "numeric" && (
                                    <NumericField
                                      {...{ ...subSection, form: "mainform" }}
                                      form="mainform"
                                      handleChange={form.handleChange}
                                      formState={form.formState}
                                      errors={errors}
                                      setErrors={setErrors}
                                      formData={formData}
                                    />
                                  )}
                                {fieldTypeMap[subSection.field_type] ===
                                  "File" && (
                                    <MultiUploadPreview
                                      multiple={true}
                                      id={workOrderId}
                                      org_section_id={section.org_section_id}
                                      {...{
                                        ...subSection,
                                        form: "mainform",
                                      }}
                                      form="mainform"
                                      handleChange={form.handleChange}
                                      setFiles={(files) => {
                                        setFiles(files);
                                      }}
                                      setOrgSectionId={setOrgSectionId}
                                      formData={formData}
                                      formState={form.formState}
                                      setErrors={setErrors}
                                      errors={errors}
                                      getSectionDetails={getSectionDetails}
                                      operableType="WorkOrder"
                                      permission={props?.permission}
                                    />
                                  )}
                                {fieldTypeMap[subSection.field_type] ===
                                  "Numerictext" && (
                                    <NumericField
                                      {...{ ...subSection, form: "mainform" }}
                                      form="mainform"
                                      handleChange={form.handleChange}
                                      formState={form.formState}
                                      errors={errors}
                                      formData={formData}
                                    />
                                  )}
                              </Grid>
                            );
                          }
                        })}
                      {section &&
                        section.sub_sections &&
                        section.sub_section_name !==
                        "Aircraft Deferred Defects (ADDs)" &&
                        section.sub_sections.map((childSection, i) => {
                          return (
                            <Grid
                              item
                              key={`child-sec-id-${i}`}
                              style={{ width: "100%", padding: "2% 1%" }}
                            >
                              {childSection.child_section_type !==
                                "table_data" && (
                                  <Typography variant={"subtitle2"}>
                                    {childSection.child_section_name}
                                  </Typography>
                                )}
                              <br />
                              <input
                                style={{ display: "none" }}
                                name={`org_section_id-${childSection.org_section_id}`}
                                value={childSection.org_section_id}
                              />
                              {childSection.child_section_type ===
                                "default" && (
                                  <>
                                    <Grid
                                      container
                                      spacing={3}
                                      alignItems={"flex-start"}
                                      justify={"flex-start"}
                                      style={{ marginLeft: "0px !important" }}
                                    >
                                      {childSection?.child_section_fields?.map(
                                        (child_section_field, index) => {
                                          return (
                                            <>

                                              <Grid
                                                item
                                                key={`sub-sec-id-${index}`}
                                                xs={12}
                                              >
                                                {child_section_field.field_type ===
                                                  "Table" && child_section_field.field_name === "Removed Parts Table" && (
                                                    <RemovedParts section={section} getSectionName={getSectionName} setCreateSection={setCreateSection} props={props} workOrderId={workOrderId} />

                                                  )
                                                }
                                              </Grid>


                                              <Grid
                                                item
                                                key={`sub-sec-id-${index}`}
                                                style={
                                                  fieldTypeMap[
                                                    child_section_field.field_type
                                                  ] === "yes_or_no" &&
                                                    child_section_field.secondary_field
                                                    ? {}
                                                    : { paddingTop: "20px" }
                                                }
                                                xs={5}
                                              >
                                                {fieldTypeMap[
                                                  child_section_field.field_type
                                                ] === "textfield" && (
                                                    <FreeTextField
                                                      {...child_section_field}
                                                      form="mainform"
                                                      workOrderId={workOrderId}
                                                      ref={form.formRef}
                                                      handleChange={form.handleChange}
                                                      formState={form.formState}
                                                      errors={errors}
                                                      setErrors={setErrors}
                                                      formData={formData}
                                                    />
                                                  )}
                                                {fieldTypeMap[
                                                  child_section_field.field_type
                                                ] === "Datetime" && (
                                                    <FreeTextField
                                                      {...child_section_field}
                                                      form="mainform"
                                                      ref={form.formRef}
                                                      handleChange={form.handleChange}
                                                      formState={form.formState}
                                                      errors={errors}
                                                      setErrors={setErrors}
                                                      formData={formData}
                                                    />
                                                  )}
                                                {fieldTypeMap[
                                                  child_section_field.field_type
                                                ] === "time" && (
                                                    <FreeTextField
                                                      {...child_section_field}
                                                      form="mainform"
                                                      ref={form.formRef}
                                                      handleChange={form.handleChange}
                                                      formState={form.formState}
                                                      errors={errors}
                                                      setErrors={setErrors}
                                                      formData={formData}
                                                    />
                                                  )}
                                                {fieldTypeMap[
                                                  child_section_field.field_type
                                                ] === "dropdown" && (
                                                    <DropdownField
                                                      {...child_section_field}
                                                      form="mainform"
                                                      ref={form.formRef}
                                                      handleChange={form.handleChange}
                                                      formState={form.formState}
                                                      errors={errors}
                                                      setErrors={setErrors}
                                                      formData={formData}
                                                    />
                                                  )}
                                                {fieldTypeMap[
                                                  child_section_field.field_type
                                                ] === "yes_or_no" && (
                                                    <YesNoInput
                                                      {...child_section_field}
                                                      form="mainform"
                                                      ref={form.formRef}
                                                      handleChange={form.handleChange}
                                                      formState={form.formState}
                                                      errors={errors}
                                                      setErrors={setErrors}
                                                      formData={formData}
                                                    />
                                                  )}
                                                {fieldTypeMap[
                                                  child_section_field.field_type
                                                ] === "numeric" && (
                                                    <NumericField
                                                      {...child_section_field}
                                                      form="mainform"
                                                      ref={form.formRef}
                                                      handleChange={form.handleChange}
                                                      formState={form.formState}
                                                      errors={errors}
                                                      setErrors={setErrors}
                                                      formData={formData}
                                                    />
                                                  )}
                                                {fieldTypeMap[
                                                  child_section_field.field_type
                                                ] === "File" && (
                                                    <UploadButton
                                                      multiple
                                                      {...child_section_field}
                                                      form="mainform"
                                                      ref={form.formRef}
                                                      handleChange={form.handleChange}
                                                      formState={form.formState}
                                                      errors={errors}
                                                      setErrors={setErrors}
                                                      formData={formData}
                                                    />
                                                  )}
                                                {fieldTypeMap[
                                                  child_section_field.field_type
                                                ] === "Numerictext" && (
                                                    <NumericField
                                                      {...child_section_field}
                                                      form="mainform"
                                                      handleChange={form.handleChange}
                                                      formState={form.formState}
                                                      errors={errors}
                                                      setErrors={setErrors}
                                                      formData={formData}
                                                    />
                                                  )}
                                              </Grid>
                                            </>
                                          );
                                        }
                                      )}
                                    </Grid>
                                  </>
                                )}
                              {childSection.child_section_type ===
                                "table_data" &&
                                childSection.child_section_name !==
                                "Tools Used Table" && (
                                  <>
                                    <Grid
                                      container
                                      spacing={3}
                                      alignItems={"flex-start"}
                                      justify={"flex-start"}
                                      style={{ marginLeft: "0px !important" }}
                                    >
                                      <SectionLabelAction
                                        onClick={handleTableData(childSection)}
                                        name={childSection.child_section_name}
                                        permission={props?.permission}
                                      />

                                      {/* {
                                                            childSection?.child_section_fields?.map((child_section_field, index) => {
                                                                return (
                                                                    <Grid item key={`sub-sec-id-${index}`} style={fieldTypeMap[child_section_field.field_type] === 'yes_or_no' && child_section_field.secondary_field ? { width: '100%' } : { width: '33%', paddingTop: '20px' }}>
                                                                        {(fieldTypeMap[child_section_field.field_type] === 'textfield') && <FreeTextField {...child_section_field} handleChange={form.handleChange} />}
                                                                        {(fieldTypeMap[child_section_field.field_type] === 'Datetime') && <FreeTextField  {...child_section_field} handleChange={form.handleChange} />}
                                                                        {(fieldTypeMap[child_section_field.field_type] === 'dropdown') && <DropdownField {...child_section_field} handleChange={form.handleChange} />}
                                                                        {fieldTypeMap[child_section_field.field_type] === 'yes_or_no' && <YesNoInput {...child_section_field} handleChange={form.handleChange} formState={form.formState} />}
                                                                        {fieldTypeMap[child_section_field.field_type] === 'numeric' && <NumericField {...child_section_field} handleChange={form.handleChange} formState={form.formState} />}
                                                                        {fieldTypeMap[child_section_field.field_type] === 'File' && <UploadButton {...child_section_field} handleChange={form.handleChange} formState={form.formState} />}
                                                                    </Grid>
                                                                )
                                                            })
                                                        } */}
                                    </Grid>
                                    <Rendermainttable
                                      setCreatedId={setCreatedId}
                                      createdId={createdId}
                                      workOrderId={workOrderId}
                                      currentSelection={childSection}
                                    />


                                  </>
                                )}
                            </Grid>
                          );
                        })}
                    </Grid>
                  </div>
                </React.Fragment>
              );
            })}
            {sectionName === "Records" && (
              <AdditionalEngineers
                sectionDetails={sectionDetails.find(
                  (sec) => sec.sub_section_name === "Work Order Closure"
                )}
                permission={props.permission}
              />
            )}
            {props?.permission?.write && (
              <div className={"action-buttons"}>
                <MROButton variant={"contained"} onClick={props.handleClose}>
                  Cancel
                </MROButton>
                <MROButton
                  variant={"contained"}
                  color={"primary"}
                  type={"submit"}
                  form="mainform"
                >
                  Save
                </MROButton>
              </div>
            )}
            {showTableData && (
              <AddMaintTasks
                currentSelection={currentSelection}
                setCreatedId={setCreatedId}
                workOrderId={workOrderId}
                handleClose={closeTableData}
                lineCustomerId={lineCustomerId}
              />
            )}
          </form>
          {createSection && action === "create" && (
            <AddNewPartsAndTools
              lineCustomerId={lineCustomerId}
              setCreatedId={setCreatedId}
              getList={getList}
              workOrderId={workOrderId}
              handleClose={handleClose}
              createSection={createSection}
              action={action}
              acReg={props.data.aircraft_registration}
              baseStationId={baseStationId}
              currentSelection={currentSelection}
            />
          )}

          {createSection && action === "create_alternative_part" && (
            <CreateAlternativePart
              onClose={handleClose}
              baseStationId={baseStationId}
              lineCustomerId={lineCustomerId}
              acReg={props.data.aircraft_registration}
              workOrderId={workOrderId}
              action={action}
              mroOwned={mroOwned}
              sectionDetails={subSectionDetails}
            />
          )}
        </div>
      </MuiThemeProvider>
    </div>
  ) : (
    <div style={{ height: "40vh" }}>
      <LoadingIndicator variant={"contained"} />
    </div>
  );
}


export default React.memo(SectionDetail);

const sectionTheme = (permission) => (theme) =>
  !permission?.write
    ? createTheme({
      ...theme,
      overrides: {
        ...theme.overrides,
        MuiGrid: {
          item: {
            zIndex: 0,
          },
        },
        MuiFormControl: {
          root: {
            zIndex: -1,
          },
        },
        MuiOutlinedInput: {
          root: {
            "& fieldset": {
              borderWidth: "1px !important",
              borderColor: theme.palette.primary.main,
            },
          },
        },
        MuiToggleButton: {
          ...theme.overrides.MuiToggleButton,
          root: {
            ...theme.overrides.MuiToggleButton.root,
            zIndex: -1,
          },
        },
      },
    })
    : createTheme({
      ...theme,
      overrides: {
        ...theme.overrides,
        MuiOutlinedInput: {
          root: {
            "& fieldset": {
              borderWidth: "1px !important",
              borderColor: theme.palette.primary.main,
            },
          },
        },
      },
    });
