import React, { useContext, useState } from "react";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Box,
    ButtonGroup,
    Button,
    Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MROButton from "../../../../components/buttons";
import MROTextField from "../../../../components/form_components/TextField";
import KitsClientService from "lib/services/api/procurement/Kits";
import { ToastMessageContext } from "lib/contexts/message_context";

const useStyles = makeStyles((theme) => ({
    actionButtons: {
        paddingTop: "20px",
        paddingBottom: "33px",
        textAlign: "right",
        "& button:not(:last-child)": {
            marginRight: "20px",
        },
    },
    modal: {
        "& .MuiDialog-paperWidthMd": {
            maxWidth: "350px",
        },
    },
    title: {
        display: "flex",
        justifyContent: "center",
        fontSize: "17px",
    },
    card: {
        padding: "0 25px",
    },
}));

function AddTags(props) {
    const classes = useStyles();
    const { handleClose, open } = props
    return (
        <div>
            <Dialog
                maxWidth={"md"}
                fullWidth={true}
                open={open}
                className={classes.modal}
                onClose={handleClose}
                aria-labelledby="base-station-manage"
            >
                <DialogTitle>
                    {" "}
                    <Typography color="primary" className={classes.title}>
                        Add Fixed Tag
                    </Typography>
                </DialogTitle>
                <form>
                    <DialogContent className={classes.card}>
                        <MROTextField
                            name={"name"}
                            label={"Name"}
                            InputLabelProps={{ shrink: true }}
                            placeholder="Enter tag name - Max 20 characters"
                            inputProps={{
                                maxLength: 20
                            }}
                        // autoFocus={error.kit_part}
                        // error={error.kit_part}
                        // helperText={
                        //     error.kit_part ? "*please enter kit part number" : null
                        // }
                        // onChange={(e) => {
                        //     setError({ ...error, kit_part: false });
                        // }}
                        />
                    </DialogContent>

                    <div className={classes.actionButtons}>
                        <MROButton
                            // disabled={false}
                            type="button"
                            variant={"contained"}
                            onClick={handleClose}
                        >
                            Cancel
                        </MROButton>
                        <MROButton
                            // loading={loading}
                            color="primary"
                            style={{ marginRight: "23px" }}
                            disabled={false}
                            // type="submit"
                            variant={"contained"}
                        >
                            Complete
                        </MROButton>
                    </div>
                </form>
            </Dialog>
        </div>
    )
}

export default AddTags