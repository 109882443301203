import React, { useContext, useState } from "react";
import MROTextField from "components/form_components/TextField";
import { MenuItem, Typography } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import MROButton from "components/buttons";
import { validateFormData } from "lib/utils/helperFunctions";
import { InspectionServices } from "lib/services/api/operaitons/WorkShop/Inspection/InspectionService";
import { organisationDetails } from "theme/main_theme";
import { ToastMessageContext } from "lib/contexts/message_context";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      height: "max-content",
    }
  },

  appBar: {
    backgroundColor: "#fff",
    color: "#000000CC",
    boxShadow: "0px 3px 6px #0000001A",
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  content: {
    backgroundColor: "#fff",
    // padding: "3% 6%",
    borderRadius: "0 0 5px 5px",
    minHeight: "fit-content",
  },
  // inputField: {
  //   maxWidth: "328px",
  // },
  actionButtons: {
    textAlign: "right",
    marginTop: "30px",
    "& button": {
      // marginRight: "20px",
    },
  },
}));

export default function CreateNewStage(props) {
  const { handleClose, action, action_Id } = props;
  const classes = useStyles();
  const message = useContext(ToastMessageContext)
  const [description, setDescription] = useState(null);
  const [title, setTitle] = useState(null);
  const [errors, setErrors] = useState({})
  const [addLib, setAddToLib] = useState(false)
  const [fieldZero, setFieldZero] = useState(null);
  const [fieldOne, setFieldOne] = useState(null);
  const [fieldTwo, setFieldTwo] = useState(null);
  const [fieldThree, setFieldThree] = useState(null);
  const [fieldFour, setFieldFour] = useState(null);
  const [fieldFive, setFieldFive] = useState(null);
  const [fieldSix, setFieldSix] = useState(null);
  const [fieldSeven, setFieldSeven] = useState(null);
  const [fieldEight, setFieldEight] = useState(null);
  const [fieldNine, setFieldNine] = useState(null);
  const [busy, setBusy] = useState(null);


  const handleCreate = (formData) => {
    setBusy("loading");

    const type0 = formData.get("field_type[0]")
    const type1 = formData.get("field_type[1]")
    const type2 = formData.get("field_type[2]")
    const type3 = formData.get("field_type[3]")
    const type4 = formData.get("field_type[4]")
    const type5 = formData.get("field_type[5]")
    const type6 = formData.get("field_type[6]")
    const type7 = formData.get("field_type[7]")
    const type8 = formData.get("field_type[8]")
    const type9 = formData.get("field_type[9]")






    formData.delete("field_name[0]")
    formData.set("field_name[0]", fieldZero || "")
    formData.delete("field_name[1]")
    formData.set("field_name[1]", fieldOne || "")
    formData.delete("field_name[2]")
    formData.set("field_name[2]", fieldTwo || "")
    formData.delete("field_name[3]")
    formData.set("field_name[3]", fieldThree || "")
    formData.delete("field_name[4]")
    formData.set("field_name[4]", fieldFour || "")
    formData.delete("field_name[5]")
    formData.set("field_name[5]", fieldFive || "")
    formData.delete("field_name[6]")
    formData.set("field_name[6]", fieldSix || "")
    formData.delete("field_name[7]")
    formData.set("field_name[7]", fieldSeven || "")
    formData.delete("field_name[8]")
    formData.set("field_name[8]", fieldEight || "")
    formData.delete("field_name[9]")
    formData.set("field_name[9]", fieldNine || "")


    formData.delete("field_type[0]")
    formData.set("field_type[0]", type0 || "")
    formData.delete("field_type[1]")
    formData.set("field_type[1]", type1 || "")
    formData.delete("field_type[2]")
    formData.set("field_type[2]", type2 || "")
    formData.delete("field_type[3]")
    formData.set("field_type[3]", type3 || "")
    formData.delete("field_type[4]")
    formData.set("field_type[4]", type4 || "")
    formData.delete("field_type[5]")
    formData.set("field_type[5]", type5 || "")
    formData.delete("field_type[6]")
    formData.set("field_type[6]", type6 || "")
    formData.delete("field_type[7]")
    formData.set("field_type[7]", type7 || "")
    formData.delete("field_type[8]")
    formData.set("field_type[8]", type8 || "")
    formData.delete("field_type[9]")
    formData.set("field_type[9]", type9 || "")


    formData.append("organisation_id", organisationDetails?.id);
    formData.append("add_to_library", addLib);
    formData.append("workshop_action_id", action_Id);
    let userDetails = JSON.parse(sessionStorage.getItem("user"));
    formData.append("raised_by", userDetails?.id);

    InspectionServices.addLibrary(formData).then((res) => {
      if (res?.success) {
        setBusy(null);
        props.getActionStages();
        handleClose(res.data);
        message.showToastMessage({
          message: "Stages added successfully",
          variant: "success",
        });
      } else {
        setBusy(null);
        res.errors &&
          message.showToastMessage({
            message: res.errors.join(","),
            variant: "error",
          });
      }
      setBusy(null);
    });
  };

  const validateFields = (formData) => {
    const { error, valid } = validateFormData(formData, [
      "field_name[0]",
      "field_name[1]",
      "field_name[2]",
      "field_name[3]",
      "field_name[4]",
      "field_name[5]",
      "field_name[6]",
      "field_name[7]",
      "field_name[8]",
      "field_name[9]",
      "field_type[0]",
      "field_type[1]",
      "field_type[2]",
      "field_type[3]",
      "field_type[4]",
      "field_type[5]",
      "field_type[6]",
      "field_type[7]",
      "field_type[8]",
      "field_type[9]",
    ]);

    console.log({ error });
    if (!valid) {
      setErrors(error);
      return;
    }
    return valid;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    if (!validateFields(formData)) {
      return;
    }

    if (action === "Add") {
      handleCreate(formData);
    }
  };

  const resetErrorsHandler = (name) => {
    setErrors((e) => ({ ...e, [name]: false }));
  };

  return (
    <div>
      <div className={classes.wrapper}>
        <div className={classes.content}>
          <form noValidate onSubmit={handleSubmit}>
            <Typography
              variant="body1"
              color="primary"
              className={classes.title}
            ></Typography>{" "}
            <Grid item container justify="space-between" alignItems="center">
              <Typography
                color="primary"
                variant="body1"
                style={{ fontWeight: "bold" }}
              >
                Stage Details
              </Typography>
            </Grid>
            <Grid
              container
              // spacing={1}
              alignItems={"center"}
              justify={"flex-start"}
            >
              <Grid
                container
                // spacing={1}
                alignItems={"center"}
                justify={"flex-start"}
              >
                <Grid item xs={12}>
                  <MROTextField
                    label={"Title"}
                    placeholder="Title - Max 75 Characters"
                    name={"stage_name"}
                    value={title}
                    margin="normal"
                    variant="outlined"
                    inputProps={{ maxLength: 75 }}
                    id="test"
                    InputLabelProps={{ shrink: true }}
                    required
                    error={errors.stage_name}
                    // className={classes.inputField}
                    onChange={(e) => {
                      resetErrorsHandler(e.target.name);
                      setTitle(e.target.value);
                    }}
                    color="primary"
                  />
                </Grid>
              </Grid>

              <Grid container item xs={12} className={classes.root} >
                <MROTextField
                  name={"description"}
                  label={"Detailed Description *"}
                  placeholder="Detailed Stage Description - Max 2000 Characters"
                  inputProps={{ maxLength: 6000 }}
                  InputProps={{ style: { width: "100%" } }}
                  InputLabelProps={{ shrink: true }}
                  multiline
                  value={description}
                  error={errors.description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                    resetErrorsHandler(e.target.name);
                  }}
                />
              </Grid>

              <>
                <Grid item container style={{ marginTop: "25px" }}>
                  <Typography
                    variant="body1"
                    color="primary"
                    style={{ fontWeight: "bold" }}
                  >
                    Stage Details
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    Upto 10 Sub Fields can be used to capture text E.g. torque
                    values, temp readings etc.
                  </Typography>
                </Grid>
                <Grid container xs={12}>



                  {/* sub field 1 and 2 */}


                  <Grid spacing={4} container item xs={11}>
                    <Grid item xs={6} container spacing={2}>
                      <Grid item xs={9}>
                        <MROTextField
                          name={"field_name[0]"}
                          label={"Sub-Field 1"}
                          value={fieldZero}
                          placeholder="Description - Max 50 Characters"
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ maxLength: 50 }}
                          className={classes.inputField}
                          onChange={(e) => setFieldZero(e.target.value)}
                        />

                      </Grid>

                      <Grid item xs={3}>
                        <MROTextField
                          name={"field_type[0]"}
                          label={"Type *"}
                          InputLabelProps={{ shrink: true }}
                          select
                          error={errors["field_type[0]"]}
                          onChange={(e) => {
                            resetErrorsHandler("field_type[0]");
                          }}
                        >
                          <MenuItem value={"FT"}>Free Text</MenuItem>
                          <MenuItem value={"NU"}>Numeric</MenuItem>
                          <MenuItem value={'YN'}>Yes | No</MenuItem>
                          <MenuItem value={'DT'}>Date & Time</MenuItem>
                          <MenuItem value={"TI"}>Time</MenuItem>
                        </MROTextField>
                      </Grid>
                    </Grid>
                    <Grid item xs={6} container spacing={2}>

                      <Grid item xs={9}>
                        <MROTextField
                          name={"field_name[1]"}
                          label={"Sub-Field 2"}
                          value={fieldOne}
                          placeholder="Description - Max 50 Characters"
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ maxLength: 50 }}
                          className={classes.inputField}
                          onChange={(e) => setFieldOne(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <MROTextField
                          name={"field_type[1]"}
                          label={"Type *"}
                          InputLabelProps={{ shrink: true }}
                          select
                          error={errors["field_type[1]"]}
                          onChange={(e) => {
                            resetErrorsHandler(e.target.name);
                          }}
                        >
                          <MenuItem value={"FT"}>Free Text</MenuItem>
                          <MenuItem value={"NU"}>Numeric</MenuItem>
                          <MenuItem value={'YN'}>Yes | No</MenuItem>
                          <MenuItem value={'DT'}>Date & Time</MenuItem>
                          <MenuItem value={"TI"}>Time</MenuItem>
                        </MROTextField>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid spacing={4} container item xs={11}>
                    <Grid item xs={6} container spacing={2}>
                      <Grid item xs={9}>
                        <MROTextField
                          name={"field_name[2]"}
                          label={"Sub-Field 3"}
                          value={fieldTwo}
                          placeholder="Description - Max 50 Characters"
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ maxLength: 50 }}
                          className={classes.inputField}
                          onChange={(e) => setFieldTwo(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <MROTextField
                          name={"field_type[2]"}
                          label={"Type *"}
                          InputLabelProps={{ shrink: true }}
                          select
                          error={errors["field_type[2]"]}
                          onChange={(e) => {
                            resetErrorsHandler(e.target.name);
                          }}
                        >
                          <MenuItem value={"FT"}>Free Text</MenuItem>
                          <MenuItem value={"NU"}>Numeric</MenuItem>
                          <MenuItem value={'YN'}>Yes | No</MenuItem>
                          <MenuItem value={'DT'}>Date & Time</MenuItem>
                          <MenuItem value={"TI"}>Time</MenuItem>
                        </MROTextField>
                      </Grid>
                    </Grid>
                    <Grid item xs={6} container spacing={2}>

                      <Grid item xs={9}>
                        <MROTextField
                          name={"field_name[3]"}
                          label={"Sub-Field 4"}
                          value={fieldThree}
                          placeholder="Description - Max 50 Characters"
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ maxLength: 50 }}
                          className={classes.inputField}
                          onChange={(e) => setFieldThree(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <MROTextField
                          name={"field_type[3]"}
                          label={"Type *"}
                          InputLabelProps={{ shrink: true }}
                          select
                          error={errors["field_type[3]"]}
                          onChange={(e) => {
                            resetErrorsHandler(e.target.name);
                          }}
                        >
                          <MenuItem value={"FT"}>Free Text</MenuItem>
                          <MenuItem value={"NU"}>Numeric</MenuItem>
                          <MenuItem value={'YN'}>Yes | No</MenuItem>
                          <MenuItem value={'DT'}>Date & Time</MenuItem>
                          <MenuItem value={"TI"}>Time</MenuItem>
                        </MROTextField>
                      </Grid>
                    </Grid>
                  </Grid>


                  <Grid spacing={4} container item xs={11}>
                    <Grid item xs={6} container spacing={2}>
                      <Grid item xs={9}>
                        <MROTextField
                          name={"field_name[4]"}
                          label={"Sub-Field 5"}
                          value={fieldFour}
                          placeholder="Description - Max 50 Characters"
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ maxLength: 50 }}
                          className={classes.inputField}
                          onChange={(e) => setFieldFour(e.target.value)}
                        />


                      </Grid>
                      <Grid item xs={3}>
                        <MROTextField
                          name={"field_type[4]"}
                          label={"Type *"}
                          InputLabelProps={{ shrink: true }}
                          select
                          error={errors["field_type[3]"]}
                          onChange={(e) => {
                            resetErrorsHandler(e.target.name);
                          }}
                        >
                          <MenuItem value={"FT"}>Free Text</MenuItem>
                          <MenuItem value={"NU"}>Numeric</MenuItem>
                          <MenuItem value={'YN'}>Yes | No</MenuItem>
                          <MenuItem value={'DT'}>Date & Time</MenuItem>
                          <MenuItem value={"TI"}>Time</MenuItem>
                        </MROTextField>
                      </Grid>
                    </Grid>

                    <Grid item xs={6} container spacing={2}>

                      <Grid item xs={9}>
                        <MROTextField
                          name={"field_name[5]"}
                          label={"Sub-Field 6"}
                          placeholder="Description - Max 50 Characters"
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ maxLength: 50 }}
                          value={fieldFive}
                          className={classes.inputField}
                          onChange={(e) => setFieldFive(e.target.value)}
                        ></MROTextField>
                      </Grid>
                      <Grid item xs={3}>
                        <MROTextField
                          name={"field_type[5]"}
                          label={"Type *"}
                          InputLabelProps={{ shrink: true }}
                          select
                          error={errors["field_type[5]"]}
                          onChange={(e) => {
                            resetErrorsHandler(e.target.name);
                          }}
                        >
                          <MenuItem value={"FT"}>Free Text</MenuItem>
                          <MenuItem value={"NU"}>Numeric</MenuItem>
                          <MenuItem value={'YN'}>Yes | No</MenuItem>
                          <MenuItem value={'DT'}>Date & Time</MenuItem>
                          <MenuItem value={"TI"}>Time</MenuItem>
                        </MROTextField>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid spacing={4} container item xs={11}>
                    <Grid item xs={6} container spacing={2}>

                      <Grid item xs={9}>
                        <MROTextField
                          name={"field_name[6]"}
                          label={"Sub-Field 7"}
                          value={fieldSix}
                          placeholder="Description - Max 50 Characters"
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ maxLength: 50 }}
                          className={classes.inputField}
                          onChange={(e) => setFieldSix(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <MROTextField
                          name={"field_type[6]"}
                          label={"Type *"}
                          InputLabelProps={{ shrink: true }}
                          select
                          error={errors["field_type[6]"]}
                          onChange={(e) => {
                            resetErrorsHandler(e.target.name);
                          }}
                        >
                          <MenuItem value={"FT"}>Free Text</MenuItem>
                          <MenuItem value={"NU"}>Numeric</MenuItem>
                          <MenuItem value={'YN'}>Yes | No</MenuItem>
                          <MenuItem value={'DT'}>Date & Time</MenuItem>
                          <MenuItem value={"TI"}>Time</MenuItem>
                        </MROTextField>
                      </Grid>
                    </Grid>

                    <Grid item xs={6} container spacing={2}>
                      <Grid item xs={9}>
                        <MROTextField
                          name={"field_name[7]"}
                          label={"Sub-Field 8"}
                          value={fieldSeven}
                          placeholder="Description - Max 50 Characters"
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ maxLength: 50 }}
                          className={classes.inputField}
                          onChange={(e) => setFieldSeven(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <MROTextField
                          name={"field_type[7]"}
                          label={"Type *"}
                          InputLabelProps={{ shrink: true }}
                          select
                          error={errors["field_type[7]"]}
                          onChange={(e) => {
                            resetErrorsHandler(e.target.name);
                          }}
                        >
                          <MenuItem value={"FT"}>Free Text</MenuItem>
                          <MenuItem value={"NU"}>Numeric</MenuItem>
                          <MenuItem value={'YN'}>Yes | No</MenuItem>
                          <MenuItem value={'DT'}>Date & Time</MenuItem>
                          <MenuItem value={"TI"}>Time</MenuItem>
                        </MROTextField>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid spacing={4} container item xs={11}>
                    <Grid item xs={6} container spacing={2}>
                      <Grid item xs={9}>
                        <MROTextField
                          name={"field_name[8]"}
                          label={"Sub-Field 9"}
                          value={fieldEight}
                          placeholder="Description - Max 50 Characters"
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ maxLength: 50 }}
                          className={classes.inputField}
                          onChange={(e) => setFieldEight(e.target.value)}
                        />


                      </Grid>
                      <Grid item xs={3}>
                        <MROTextField
                          name={"field_type[8]"}
                          label={"Type *"}
                          InputLabelProps={{ shrink: true }}
                          select
                          error={errors["field_type[8]"]}
                          onChange={(e) => {
                            resetErrorsHandler(e.target.name);
                          }}
                        >
                          <MenuItem value={"FT"}>Free Text</MenuItem>
                          <MenuItem value={"NU"}>Numeric</MenuItem>
                          <MenuItem value={'YN'}>Yes | No</MenuItem>
                          <MenuItem value={'DT'}>Date & Time</MenuItem>
                          <MenuItem value={"TI"}>Time</MenuItem>
                        </MROTextField>
                      </Grid>

                    </Grid>

                    <Grid item xs={6} container spacing={2}>
                      <Grid item xs={9}>
                        <MROTextField
                          name={"field_name[9]"}
                          label={"Sub-Field 10"}
                          value={fieldNine}
                          placeholder="Description - Max 50 Characters"
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ maxLength: 50 }}
                          className={classes.inputField}
                          onChange={(e) => setFieldNine(e.target.value)}
                        />


                      </Grid>
                      <Grid item xs={3}>
                        <MROTextField
                          name={"field_type[9]"}
                          label={"Type *"}
                          InputLabelProps={{ shrink: true }}
                          select
                          error={errors["field_type[9]"]}
                          onChange={(e) => {
                            resetErrorsHandler(e.target.name);
                          }}
                        >
                          <MenuItem value={"FT"}>Free Text</MenuItem>
                          <MenuItem value={"NU"}>Numeric</MenuItem>
                          <MenuItem value={'YN'}>Yes | No</MenuItem>
                          <MenuItem value={'DT'}>Date & Time</MenuItem>
                          <MenuItem value={"TI"}>Time</MenuItem>
                        </MROTextField>
                      </Grid>

                    </Grid>
                  </Grid>

                </Grid>
              </>
            </Grid>
            <div className={classes.actionButtons}>
              <Typography
                style={{
                  fontSize: "12px",
                  marginBottom: "20px",
                  marginRight: '20px',
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <input type="checkbox" value={addLib} onClick={() => setAddToLib(!addLib)} />
                &nbsp;&nbsp; Add to your library
              </Typography>
              <MROButton
                type="button"
                variant={"contained"}
                onClick={handleClose}
                style={{ marginRight: "20px" }}
              >
                Cancel
              </MROButton>
              <MROButton
                loading={busy === "loading"}
                type="submit"
                color="primary"
                variant={"contained"}
                style={{ marginRight: "15px" }}
              >
                Add
              </MROButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
