import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TabSectionBar } from "components/tab_component";
import Divider from "@material-ui/core/Divider";
import Plan from "./Planning/Plan";
import InspectionActions from "./Actions/InspectionActions";
import { Check, Warning, CheckBox } from "@material-ui/icons";
import PositiveSwitch from "components/form_components/switch";
import MaintActions from "./MaintActions";
import Parts from "./Parts/Parts";
import Services from "./Services/Services";
import Equipment from "./Equipment/Equipment";
import TemplatePlanning from "../CopyTemplates/Tabs/Planning/Plan"
import TemplateInspection from "../CopyTemplates/Tabs/Actions/InspectionActions"
import TemplateServices from "../CopyTemplates/Tabs/Services/Services"
import TemplateParts from "../CopyTemplates/Tabs/Parts/Parts"
import TemplateEquipment from "../CopyTemplates/Tabs/Equipment/Equipment"



const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: "1% 3%",
  },
  content: {
    boxShadow: "0px 1px 3px #00000033",
    margin: "2% 0",
  },
  detail: {
    backgroundColor: "#fff",
    padding: "1%",
  },
  success: {
    color: "green",
    marginLeft: 8,
  },
  warning: {
    color: "orange",
    marginLeft: 8,
  },
  indicator: {
    display: "flex",
    alignItems: "center",
  },
}));

function TabIndex(props) {
  const classes = useStyles();
  const { currentClosedDetails, currentPlanningDetails, workshop_id, planningId, planningWholeDetails, type, copyTab } = props
  const [currentTab, setCurrentTab] = useState(0);
  const [completed, setCompleted] = useState(0);

  const navMenu = [
    { label: <div className={classes.indicator}>Planning&nbsp;&nbsp; </div> },
    {
      label: (
        <div className={classes.indicator}>Actions&nbsp;&nbsp; </div>
      ),
    },
    // { label: <div className={classes.indicator}>Maint Actions </div> },
    { label: <div className={classes.indicator}>Parts </div> },
    { label: <div className={classes.indicator}>Services </div> },
    { label: <div className={classes.indicator}>Equipment </div> },
  ];

  return (
    <div className={classes.wrapper}>
      <TabSectionBar
        // completed={completed}
        {...props}
        tabProperties={navMenu}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        isGettingDetails={false}
      />
      <Divider
        variant="fullWidth"
        style={{ padding: "1px", backgroundColor: "rgba(222, 214, 214, 0.2)" }}
      />
      <div className={classes.detail}>
        {currentTab === 0 && type === "previous_order" && <Plan
          currentClosedDetails={currentClosedDetails}
          currentPlanningDetails={currentPlanningDetails}
          workshop_id={workshop_id}
          planningId={planningId}
          planningWholeDetails={planningWholeDetails}
        />}
        {currentTab === 0 && type === "templates" && <TemplatePlanning currentClosedDetails={currentClosedDetails}
          currentPlanningDetails={currentPlanningDetails}
          workshop_id={workshop_id}
          planningId={planningId}
          planningWholeDetails={planningWholeDetails} />}
        {currentTab === 1 && type === "previous_order" &&
          <InspectionActions
            currentClosedDetails={currentClosedDetails}
            currentPlanningDetails={currentPlanningDetails} workshop_id={workshop_id} />}{currentTab === 1 && type === "templates" &&
              <TemplateInspection
                currentClosedDetails={currentClosedDetails}
                currentPlanningDetails={currentPlanningDetails} workshop_id={workshop_id} />}
        {/* {currentTab === 2 && <MaintActions />} */}
        {currentTab === 2 && type === "previous_order" && <Parts currentClosedDetails={currentClosedDetails} currentPlanningDetails={currentPlanningDetails} copyTab={copyTab} />}
        {currentTab === 2 && type === "templates" && <TemplateParts currentClosedDetails={currentClosedDetails} currentPlanningDetails={currentPlanningDetails} copyTab={copyTab} />}


        {currentTab === 3 && type === "previous_order" && <Services currentClosedDetails={currentClosedDetails} currentPlanningDetails={currentPlanningDetails} copyTab={copyTab} />}
        {currentTab === 3 && type === "templates" && <TemplateServices currentClosedDetails={currentClosedDetails} currentPlanningDetails={currentPlanningDetails} copyTab={copyTab} />}
        {currentTab === 4 && type === "previous_order" && <Equipment currentClosedDetails={currentClosedDetails} currentPlanningDetails={currentPlanningDetails} copyTab={copyTab} />}
        {currentTab === 4 && type === "templates" && <TemplateEquipment currentClosedDetails={currentClosedDetails} currentPlanningDetails={currentPlanningDetails} copyTab={copyTab} />}
      </div>
    </div>
  );
}

export default TabIndex;
