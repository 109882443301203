import React, { useState, useEffect, useContext, useReducer } from "react";
import MROFullScreenRightDrawer from "components/fullwidth_dialog";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import Typography from "@material-ui/core/Typography";
import { alpha, Box, useTheme } from "@material-ui/core";
import MaterialTable from "material-table";
import tableReducer from "reducers/table_reducer";
import tableIcons, { options } from "components/universal/table_attributes";
import TableAction from "actions/table_actions";
import { INITIAL_TABLE_STATE } from "reducers/table_reducer";
import greenHollow from "assets/Green hollow.png";
import LineItems from "./LineItemsTable";
import { CommercialServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/commercial";
import AmberGear from "assets/AmberGear.png";
import { fixedInputValueTo2DecimalPlaces } from "lib/utils/helperFunctions";
import RedHandIcon from "assets/red_hand.png";


const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: "3%",
  },
  appBar: {
    backgroundColor: "#fff",
    color: "#000000CC",
    boxShadow: "0px 3px 6px #0000001A",
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  paper: {
    borderRadius: "5px",
    backgroundColor: "#F5F5F5",
    padding: theme.spacing(2),
    boxShadow: "0px 1px 4px #0000001A",
  },
  partValue: {
    fontSize: "14px",
    color: "#4D4F5C",
    padding: "2px",
    display: "flex",
    alignItems: "center",
  },
  partKey: {
    "& p": {
      fontSize: "14px",
      padding: "1px",
    },
  },
  partCard: {
    borderRadius: "8px",
    padding: "17px 24px",
    "& p": {
      fontSize: "14px",
      padding: "5px",
    },
  },
  sec1: {
    borderRadius: "10px",
    backgroundColor: "#fff",
    padding: theme.spacing(5),
    boxShadow: "0px 1px 4px #0000001A",
  },
  root: {
    backgroundColor: "#fff", height: '125vh'
  },

}));

function DetailedOverView(props) {
  const theme = useTheme()
  const classes = useStyles();
  const { handleClose, currentSelectedOrder, selectedData } = props;
  const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
  const [loading, setLoading] = useState(false);
  const [actionData, setActionData] = useState([])
  const { page, totalCount, pageSize } = tableState;
  const [selectedActionRowData, setSelectedActionRowData] = useState({});
  const [action, setAction] = useState(null);
  const [loadingActionPosition, setLoadingActionPosition] = useState(false);
  const [data, setData] = useState({})
  const [dataVariance, setDataVariance] = useState({
    varaince_1: "",
    variance_2: "",
  });
  useEffect(() => {
    setDataVariance({
      varaince_1: (data.action_charges_live) - (data.action_charges_at_approval),
      variance_2: (data.action_quantity_live) - (data.action_quantity_at_approval)
    })
  }, [data])
  // let variance1 = parseInt(data.charges_live) - parseInt(data.charges_at_approval)
  // let variance2 = parseInt(data.quantity_live) - parseInt(data.quantity_at_approval)
  const getActionBreakdown = async () => {
    setLoadingActionPosition(true)
    const params = {
      workshop_action_id: selectedData.id,
    };
    await CommercialServices.getActionBreakdown(params)
      .then((res) => {
        setData(res.data.action_detail)
        setActionData(res.data.action_overview)
      })
      .catch(() => {
        console.log("error");
      })
      .finally(() => {
        console.log("action");
        setLoadingActionPosition(false)
      });
  };


  useEffect(() => {
    getActionBreakdown()
  }, [])


  const tableOptions = {
    ...options,
    page: page,
    total: totalCount,
    pageSize: pageSize,
    search: false,
    paging: false,
    rowStyle: rowData => ({
      backgroundColor: (selectedActionRowData.item === rowData.item) ? alpha(theme.palette.primary.main, 0.15) : '#FFF', height: 42
    }),
    headerStyle: {
      height: 42
    }
  };

  const renderZeroState = (value) => {
    return (

      <div>
        <img src={RedHandIcon} height={'16px'} />
      </div>
    )


  }


  const column1 = [
    {
      title: "Item",
      field: "item",
      headerStyle: {
        paddingLeft: "15px",
      },
      cellStyle: {
        paddingLeft: "15px",
        width: "40%",
        textTransform: "capitalize"
      },

    },
    {
      title: "Ready",
      field: "approval_readiness",
      headerStyle: {
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
        // padding: '0 20px'
      },
      render: (rowData) => renderApvlState(rowData),
    },
    {
      title: "QTY Live",
      field: "quantity_live",
      headerStyle: {
        textAlign: "center",
      },
      cellStyle: {
        // textAlign: "start",
        textAlign: "center",
        // paddingLeft: '17px'
      },

    },
    {
      title: "QTY Apvl",
      field: "quantity_at_approval",
      headerStyle: {
        textAlign: "center",
        paddingLeft: '17px'
      },
      cellStyle: {
        // paddingLeft: '17px',
        textAlign: "center",
      },
      render: (rowData) => {
        return (
          <span>{rowData.quantity_at_approval === null ? "-" : rowData.quantity_at_approval}</span>

        )
      },
    },
    {
      title: "Value Live",
      field: "charges_live",
      headerStyle: {
        textAlign: "center",
        paddingLeft: '17px'
      },
      cellStyle: {
        // paddingLeft: '24px',
        textAlign: "center",
      }
    },
    {
      title: "Value Apvl",
      field: "charges_at_approval",
      headerStyle: {
        textAlign: "center",
        //  paddingLeft: '17px'
      },
      cellStyle: {
        //  paddingLeft: '17px'
        textAlign: "center",
      }
    },
    {
      title: "Status",
      field: "variance",
      headerStyle: {
        textAlign: "center",
      },
      cellStyle: {
        //  paddingLeft: '17px'
        textAlign: "center",
      },
      render: (rowData) =>
        <>
          {rowData.variance === "" ? renderZeroState(rowData.variance) : <span
            style={{ color: rowData.variance.replace(",", "").replace("%", "") > 100 ? "red" : "green", fontWeight: "bold", paddingLeft: rowData.variance ? "" : '8px' }}
          >
            {rowData.variance}
          </span>}
        </>
    },

  ];


  const renderApvlState = (rowData) => {

    if (rowData?.approval_readiness === 'green') {
      return (
        <div style={{ backgroundColor: "transparent", color: "green" }}>
          <img
            src={greenHollow}
            alt="Icon"
            style={{ height: "18px" }}
          />{" "}
        </div>
      )
    }
    else {
      return (
        <div style={{ backgroundColor: "transparent", color: "green" }}>
          <img
            src={AmberGear}
            alt="Icon"
            style={{ height: "18px" }}
          />{" "}
        </div>
      )

    }
  };

  const GetInfoCard = (props) => {
    const classes = useStyles();
    const { color } = props
    return (
      <Box display="flex">
        <Box flex={50} className={classes.partKey}>
          <Typography style={{ padding: "5px" }} color="primary">
            {props?.keyName}
          </Typography>
        </Box>
        <Box flex={50} className={classes.partValue}>
          <Typography style={{ color: props.color ? color : "", fontWeight: props.color ? 700 : 500 }}>   {props?.value ?? "-"} </Typography>

        </Box>
      </Box>
    );
  };

  return (
    <div  >
      <MROFullScreenRightDrawer open={true} >
        <AppBar position="static" className={classes.appBar} elevation={0}>
          <Toolbar>
            <IconButton
              onClick={handleClose}
              edge="start"
              className={classes.backButton}
              color="inherit"
              aria-label="back"
            >
              <BackIcon />
            </IconButton>
            <Typography variant="subtitle2" style={{ fontSize: "17px" }}>
              Detailed Overview
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.root}>

          <div className={classes.wrapper}>
            <Grid>
              <Typography
                variant="subtitle2"
                color="primary"
                style={{ marginBottom: "10px", fontSize: "17px" }}
              >
                Selected Action
              </Typography>
            </Grid>

            <div style={{ width: "100%", marginBottom: "20px" }}>
              <Box display="flex" bgcolor="#F5F5F5" className={classes.partCard}>
                <Box flexGrow={1}>

                  <div style={{ width: "100%" }}>
                    <GetInfoCard keyName={"Action"} value={data.action} />
                    <GetInfoCard keyName={"Value Live"} value={data.action_charges_live} />
                    <GetInfoCard keyName={"Value @ Approval"} value={data.action_charges_at_approval} />
                    <GetInfoCard keyName={"Variance"} value={dataVariance.varaince_1 ? parseFloat(dataVariance.varaince_1).toFixed(2) : '-'} color={parseFloat(dataVariance.varaince_1).toFixed(2) <= 0 ? "green" : "red"} />
                  </div>
                </Box>
                <Box flexGrow={1}>
                  <div style={{ width: "100%" }}>
                    <GetInfoCard keyName={"Title"} value={data.title} />
                    <GetInfoCard keyName={"QTY Live"} value={data.action_quantity_live} />
                    <GetInfoCard keyName={"QTY @ Approval"} value={data.action_quantity_at_approval} />
                    <GetInfoCard keyName={"Variance"} value={dataVariance.variance_2 ? parseFloat(dataVariance.variance_2).toFixed(2) : '-'} color={parseFloat(dataVariance.variance_2).toFixed(2) <= 0 ? "green" : "red"} />
                  </div>
                </Box>
              </Box>
            </div>

            <Grid>
              <Typography variant="subtitle2"
                color="primary"
                style={{ margin: "10px 0px 10px", fontSize: "17px" }}>
                Action Breakdown
              </Typography>
            </Grid>
            <MaterialTable
              style={{
                boxShadow: "0px 1px 4px #00000033",
                paddingBottom: '49px'
              }}
              icons={tableIcons}
              title={""}
              columns={column1}
              data={actionData || []}
              options={tableOptions}
              isLoading={loadingActionPosition}
              components={{
                Toolbar: () => null,
              }}
              localization={{
                toolbar: {},
                body: {
                  emptyDataSourceMessage: "No action breakdown found",
                  filterRow: {
                    filterTooltip: "Filter",
                  },
                },
              }}
              onChangePage={(page) => {
                TableAction.setPage(dispatch, page);
              }}
              onChangeRowsPerPage={(pageSize) => {
                TableAction.setPageSize(dispatch, pageSize);
              }}
              totalCount={totalCount}
              page={page}
              onRowClick={(event, rowData, selectedRow) => {
                const rowDataCopy = { ...rowData };
                setSelectedActionRowData(rowData)
                setLoading(true)
                setAction("line_items")
                // console.log("setSelectedRow", selectedActionRowData)

              }}
            />
            <Typography
              style={{
                position: 'absolute',
                marginTop: ' -35px',
                paddingLeft: '14px', fontStyle: "italic", fontSize: "13px"
              }}
            >
              All values shown include applicable markup and are shown in the Customer’s currency. Select a row to see the applicable live items
            </Typography>
            <Grid style={{ marginBottom: '30px' }}>
              <Grid>
                <Typography variant="subtitle2"
                  color="primary"
                  style={{ margin: "10px 0px 10px", fontSize: "17px" }}>
                  Live Action Items
                </Typography>
              </Grid>
              <Grid>
                {/* <Typography style={{ margin: "10px 0px 10px", fontSize: "15px" }}>
                  Select a row in the table above to view line items in the table
                  below
                </Typography> */}
              </Grid>

              {action === "line_items" && (
                <LineItems selectedData={selectedData} currentSelectedOrder={currentSelectedOrder}
                  selectedActionRowData={selectedActionRowData} setLoading={setLoading} loading={loading}
                  getActionBreakdown={getActionBreakdown}
                />)}</Grid>
          </div>   </div>
      </MROFullScreenRightDrawer>
    </div>
  );
}

export default DetailedOverView;
