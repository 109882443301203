import React, { useContext, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import MRODialog from "components/MRODialog";
import ServiceRequestListClientServices from "lib/services/api/procurement/Services";
import { ToastMessageContext } from "lib/contexts/message_context";

function WarningDialog(props) {
  const message = useContext(ToastMessageContext)
  const { action, handleClose, title, description, Id, titleColor, getServices, closeHandler } = props;
  const [busy, setBusy] = useState(null)

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target)
    setBusy("loading")
    formData.set("id", Id)
    if (action === "delete") {
      await ServiceRequestListClientServices.deleteServices(formData)
        .then((res) => {
          if (res.success) {
            message.showToastMessage({
              message: "Service deleted successfully!",
              variant: "success",
            });
            closeHandler();
            getServices();
          }
        }).catch(() => {
          message.showToastMessage({
            message: "Something went wrong. Try again!",
            variant: "error",
          });
        })
        .finally(() => {
          setBusy(null);
        });
    }
    if (action === "close") {
      formData.append("state", "close")
      await ServiceRequestListClientServices.changeState(formData)
        .then((res) => {
          if (res.success) {
            message.showToastMessage({
              message: "successfull!",
              variant: "success",
            });
            closeHandler();
            getServices();
          }else if(!res.success){
            message.showToastMessage({
              message: res.errors.map(err=> err),
              variant: "error",
            });
            handleClose();
          }
        })
        .finally(() => {
          setBusy(null);
        });
    }
  };

  return (
    <MRODialog
      open={action}
      width="599px"
      isLoading={busy === "loading"}
      handleClose={handleClose}
      padding="29px 30px 27px 25px"
      fromLeft="450px"
      btnTitle="Yes"
      handleSubmit={handleSubmit}
      centerActionBtns

    >
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography color={"primary" ?? titleColor} style={{ color: titleColor === "warning" && "#F68A1C" }} variant="h5">
          {title}
        </Typography>

        <Typography variant="body1" style={{ marginTop: 33, marginBottom: 10 }}>
          {description}
        </Typography>
      </Box>
    </MRODialog>
  );
}

export default WarningDialog;
