import React, { useContext, useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import MROTextField from "components/form_components/TextField";
import MROButton from "components/buttons";
import { makeStyles } from "@material-ui/core";
import { ToastMessageContext } from "lib/contexts/message_context";
import { validateFormData } from "lib/utils/helperFunctions";
import { InspectionServices } from "lib/services/api/operaitons/WorkShop/Inspection/InspectionService";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      height: "150px",
    },
  },
  actionButtons: {
    textAlign: "right",
    marginTop: "30px",
    "& button": {},
  },
  subTitle: {
    fontSize: "16px",
    fontWeight: "bold",
    marginBottom: "20px",
    color: "#4D4F5C",
  },
  actionButtons: {
    paddingBottom: "33px",
    textAlign: "right",
    "& button:not(:last-child)": {
      marginRight: "20px",
    },
  },
  partCard: {
    borderRadius: "8px",
    padding: "17px 24px",
  },
  partKey: {
    fontSize: "16px",
    padding: "5px",
  },
  partValue: {
    fontSize: "16px",
    color: "#4D4F5C",
    padding: "5px",
  },
  modal: {
    "& .MuiDialog-paperWidthMd": {
      maxWidth: "663px",
    },
  },
}));

function ManageComment(props) {
  const classes = useStyles();
  const {
    handleClose,
    action,
    selectedId,
    currentSelection,
    getAllActionComments,
  } = props;
  const message = useContext(ToastMessageContext);
  const [errors, setErrors] = useState({});
  const [busy, setBusy] = useState(null);
  const [actionComments, setActionComment] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (action === "Update") {
      getActionComments();
    }
  }, []);

  const getActionComments = async () => {
    setIsLoading(true);
    setBusy("get_comment");
    await InspectionServices.getActionComments(currentSelection?.id)
      .then((res) => {
        if (res) {
          setBusy(null);
          setActionComment(res.data);
        }
      })
      .catch(() => {
        setBusy(null);
        message.showToastMessage({
          message: "Something went wrong. Try again!",
          variant: "error",
        });
      })
      .finally(() => {
        setBusy(null);
        setIsLoading(false);
      });
  };

  const handleCreate = async (formData) => {
    setBusy("add");
    formData.append("action_id", selectedId);
    let userDetails = JSON.parse(sessionStorage.getItem("user"));
    formData.append("raised_by", userDetails?.id);
    await InspectionServices.addComments(formData)
      .then(() => {
        setBusy(null);
        handleClose();
        getAllActionComments();
        message.showToastMessage({
          message: "Created successfully!",
          variant: "success",
        });
      })
      .catch(() => {
        setBusy(null);
        handleClose();
        message.showToastMessage({
          message: "Something went wrong. Try again!",
          variant: "error",
        });
      });
    setBusy(null);
  };

  const handleUpdateData = async (formData) => {
    setBusy("edit");
    formData.append("action_id", selectedId);
    let userDetails = JSON.parse(sessionStorage.getItem("user"));
    formData.append("raised_by", userDetails?.id);
    await InspectionServices.editcomments(formData, currentSelection?.id)
      .then(() => {
        setBusy(null);
        handleClose();
        getAllActionComments();
        message.showToastMessage({
          message: "Edited successfully!",
          variant: "success",
        });
      })
      .catch(() => {
        setBusy(null);
        handleClose();
        message.showToastMessage({
          message: "Something went wrong. Try again!",
          variant: "error",
        });
      });
    setBusy(null);
  };

  const validateFields = (formData) => {
    const { error, valid } = validateFormData(formData, []);

    if (!valid) {
      setBusy(null);
      setErrors(error);
      return;
    }
    return valid;
  };

  const handleSubmit = (e) => {
    setBusy(action === "Add" ? "add" : "edit");
    e.preventDefault();
    const formData = new FormData(e.target);
    if (!validateFields(formData)) {
      return;
    }

    if (action === "Add") {
      handleCreate(formData);
    }
    if (action === "Update") {
      handleUpdateData(formData);
    }
  };

  const resetErrorsHandler = (name) => {
    setErrors((e) => ({ ...e, [name]: false }));
  };

  if (isLoading) {
    return (
      <Box
        width="100%"
        minHeight="50px"
        display="flex"
        justifyContent="center"
        background="transparent"
        alignItems="center"
      >
        <CircularProgress color="primary" size={35} />
      </Box>
    );
  }

  return (
    <div>
      <Dialog
        maxWidth={"md"}
        fullWidth={true}
        open={true}
        className={classes.modal}
        onClose={handleClose}
        aria-labelledby="base-station-manage"
      >
        <DialogTitle>
          <Typography color="primary" className={classes.title}>
            Comment
          </Typography>
        </DialogTitle>
        <DialogContent>
          <div>
            <form spellcheck="false" onSubmit={handleSubmit}>
              <Grid
                style={{ marginBottom: "20px" }}
                container
                spacing={5}
                alignItems={"center"}
                justify={"flex-start"}
              >
                <Grid className={classes.root} item xs={12}>
                  <MROTextField
                    name={"comment"}
                    label={"Comment *"}
                    placeholder="Description - Max 400 Characters"
                    inputProps={{ maxLength: 400 }}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    multiline
                    defaultValue={actionComments?.comments}
                    error={errors.comment}
                    onChange={(e) => {
                      resetErrorsHandler(e.target.name);
                    }}
                  />
                </Grid>
              </Grid>
              <div className={classes.actionButtons}>
                <MROButton
                  type="button"
                  variant={"contained"}
                  onClick={handleClose}
                >
                  Cancel
                </MROButton>
                <MROButton
                  loading={action === "Add" ? busy === "add" : busy === "edit"}
                  type="submit"
                  variant={"contained"}
                  color="primary"
                >
                  {action === "Add" ? "Save" : action}
                </MROButton>
              </div>
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ManageComment;
