import requestrApi from "../../common/boxr_api_client";

function getServiceRates(params) {
    return requestrApi({
        url: '/adhoc_rates/service_rates',
        method: 'GET',
        params:params
    });
}

function createServiceRates(formData) {
    return requestrApi({
        url: '/adhoc_rates/create_service_rate',
        method: 'POST',
        data:formData
    })
}

function updateServiceRate(formData) {
    return requestrApi({
        url:'/adhoc_rates/update_service_rate',
        method: 'PUT',
        data: formData
    })
}

function removeServiceRate(id) {
    return requestrApi({
        url: '/adhoc_rates/destroy_service_rate',
        method: 'DELETE',
        data: {id:id}
    })
}

function getCalloutRates(params) {
    return requestrApi({
        url: '/adhoc_rates/call_out_rates',
        method: 'GET',
        params:params
    });
}

function createCalloutRates(formData) {
    return requestrApi({
        url: '/adhoc_rates/create_call_out_rate',
        method: 'POST',
        data:formData
    })
}

function updateCalloutRate(formData) {
    return requestrApi({
        url:'/adhoc_rates/update_call_out_rate',
        method: 'PUT',
        data: formData
    })
}

function removeCalloutRate(id) {
    return requestrApi({
        url: '/adhoc_rates/destroy_call_out_rate',
        method: 'DELETE',
        data: {id:id}
    })
}

function getTermsAndConditions () {
    return requestrApi({
        url: '/adhoc_rates/listing_tc',
        method: 'GET',
    })
}

function addTermsAndConditions (formData) {
    return requestrApi( {
        url: '/adhoc_rates/upload_tc',
        method: 'POST',
        data: formData
    })
}

function removeTermsAndConditions(id) {
    return requestrApi( {
        url: '/adhoc_rates/destroy_tc',
        method: 'DELETE',
        data:{id:id}
    })
}

export const ServiceRatesOptions = {
    getServiceRates,
    createServiceRates,
    updateServiceRate,
    removeServiceRate
}

export const CallOutRatesService = {
    getCalloutRates,
    createCalloutRates,
    updateCalloutRate,
    removeCalloutRate
}

export const TermsAndConditionsService = {
    addTermsAndConditions,
    removeTermsAndConditions,
    getTermsAndConditions
}