import { Avatar, Box, Grid, Typography } from "@material-ui/core";
import MaterialTable from "material-table";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { Alert } from "@material-ui/lab";
import clsx from "clsx";
import TableAction from "../../actions/table_actions";
import tableIcons, {
  options,
} from "../../components/universal/table_attributes";
import UserService from "../../lib/services/api/admin/users/user_client";
import tableReducer, {
  INITIAL_TABLE_STATE,
} from "../../reducers/table_reducer";
import { ToastMessageContext } from "../../lib/contexts/message_context";
import { useStyles } from "./";

function LineManagerUsers({ messageState, setMessageState, user }) {
  const classes = useStyles();
  const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
  const { page, totalCount, pageSize, searchText, inProgress, data } =
    tableState;
  const [lmUsers, setLmUsers] = useState([]);
  // const [loading, setLoading] = useState(false);
  // const message = useContext(ToastMessageContext);
  const [users, setUsers] = useState([]);

  // console.log("arary is==================", lmUsers?.length)


  const messages = (status) => ({
    Good: `All is good, next action in ${status?.days || "-"} days`,
    Low: `Requires Attention, user will be locked out in ${status?.days || "-"
      } days`,
    Expired: "Expired or Incomplete",
  });

  const getMessageState = (data) => {
    switch (data?.status) {
      case "Expired":
        return "error";
      case "Good":
        return "success";
      case "Low":
        return "amber";
      default:
        break;
    }
  };

  const tableOptions = {
    ...options,
    page: page,
    search: false,
    total: totalCount,
    paging: false,
    pageSize: pageSize,
    searchText: searchText,
  };

  useEffect(() => {
    const params = {
      user_id: user.id
    }
    UserService.getLineManagerUsers(params).then(res => {
      setLmUsers(res.data)
    })

  }, []);

  // const getLimeManagerUsers = () => {
  //   setLoading(false);
  //   const params = {
  //     id: user.id,
  //     page: page + 1,
  //     count_per_page: pageSize,
  //   };
  //   TableAction.getList(dispatch, message, params, "users");
  // };

  // useEffect(() => {
  //   getLimeManagerUsers();
  // }, [pageSize, page, searchText, totalCount]);

  console.log(data);
  const columns = [
    {
      width: 100,
      title: "Name",
      field: "user_name",
      render: (rowData) => (
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          <Avatar
            src={rowData?.profile_img}
          />
          &nbsp;&nbsp;&nbsp;
          {rowData.user_name}
        </Box>
      ),
      headerStyle: {
        textAlign: "left",
        paddingLeft: 70,
      },
      cellStyle: {
        textAlign: "left",
        paddingLeft: 25,
      },
    },
    {
      title: "Compliance Status",
      field: "status",
      render: (rowData) => (
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt={0}
        >
          <Alert
            className={clsx(
              classes.alertWrapper,
              classes[getMessageState(rowData) + "Alert"]
            )}
            icon={false}
            severity={getMessageState(rowData)}
            color={getMessageState(rowData)}
          >
            {rowData?.message}
          </Alert>
        </Box>
      ),
      headerStyle: {
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "left",
        paddingLeft: -100,
        paddingRight: 20,
        width: "70%",
      },
    },
  ];

  // sorting user according to the status and days
  // expired => low => good
  // expired => ascending order in days
  // low => ascending order in days
  // good => ascending order in days
  const sortUsers = (users) => {
    const expiredUsers = [];
    const lowUsers = [];
    const goodUsers = [];

    users &&
      users.forEach((item, i) => {
        if (item.status.status.toLowerCase() === "expired") {
          expiredUsers.push(item);
        } else if (item.status.status.toLowerCase() === "good") {
          goodUsers.push(item);
        } else if (item.status.status.toLowerCase() === "low") {
          lowUsers.push(item);
        }
      });

    return [
      ...expiredUsers.sort((a, b) => a.status.days - b.status.days),
      ...lowUsers.sort((a, b) => a.status.days - b.status.days),
      ...goodUsers.sort((a, b) => a.status.days - b.status.days),
    ];
  };

  useEffect(() => {
    const { users } = data;

    if (users) {
      const sortedUsers = sortUsers(users);
      setUsers(sortedUsers);
    }
  }, [data]);

  return (
    <Grid xs={12} md={12}>
      <Box mt={2}>
        <Typography variant="h6" color="primary">
          You have {lmUsers?.length || 0} reports and their status is
        </Typography>
      </Box>
      <Box mt={2}>
        <MaterialTable
          style={{ boxShadow: "0px 1px 3px #00000033", paddingRight: "0px" }}
          icons={tableIcons}
          title={<Typography color="primary" variant="subtitle2"></Typography>}
          options={{
            ...tableOptions,
            toolbar: false,
            headerStyle: { height: 50 },
            maxBodyHeight: "350px"
          }}
          data={lmUsers || []}
          isLoading={inProgress}
          columns={columns}
          onChangePage={(page) => {
            TableAction.setPage(dispatch, page);
          }}
          onChangeRowsPerPage={(pageSize) => {
            TableAction.setPageSize(dispatch, pageSize);
          }}
          onSearchChange={(search) => {
            TableAction.setSearchText(dispatch, search);
          }}
          totalCount={totalCount}
          page={page}
        />
      </Box>
    </Grid>
  );
}

export default React.memo(LineManagerUsers);
