import requestrApi from "./common/boxr_api_client";

const updateDataUrl = {
    training: '/trainings',
    competency: '/competencies',
    storageCondition: '/storage_conditions/update_storage_conditions',
    procedureCategories: '/procedure_categories',
    aogSMS: '/line_customers/update_aog_sms_contacts',
    kpiEmail: '/line_customers/update_kpi_emails',
    reportEmail: '/line_customers/update_report_emails',
    delayCodes: '/line_customers/update_delay_codes',
    copyDelayCodes: '/line_customers/copy_delay_codes',
    copyCommercialRates: '/line_customers/copy_commercial_rate',
    copyScheduleChecks: '/line_customers/copy_scheduled_check_hours',
    fixedPriceList: '/line_customers/update_fixed_price_lists',
    scheduledChecks: '/line_customers/save_scheduled_check_hours',
    approvalType: '/approval_types/update_approval_types',
    permissionType: '/approval_types/update_type_permissions',
    companyApprovals: '/company_approvals/update_company_approval',
    approvalTypeGroup: '/group_types/update_group_type',
    authorisation: '/auth_lists/update_auth_list',
    customerInfo: '/line_customers/update_customer_info',
    taskLibrary: '/task_libraries/update_task_library',
    authDoc: '/auth_lists/save_doc',
    saveAuthList: '/auth_lists/save_final_records',
    vehicles: '/vehicles/update_vehicle',
    approvalTypeName: '/approval_types/update_approval_types',
    approvalGroupName: '/group_types/update_group_type',
    competencyCategory: '/competencies/update_category',
    competencyQuestion: '/competencies/update_question',
    handoverCheck: '/handovers/update_setup',
    recencyTask: '/recencies/update_recency',
    adhocFormDetail: '/adhoc_forms/update_adhoc_form',
    updateWorkOrder: '/work_orders/update_work_order',
    updateDelay: "/delays/update_delay",
    updateCallout: "/call_outs/update_call_out",
    vendorParts: '/accounts/update_parts',
    tasks: '/accounts/update_tasks',
    businessModel: '/accounts/update_business_model',
    gndEquip: '/accounts/update_gnd_equip',
    primaryParts: '/primary_parts/update_primary_part',
    primaryPartsBaseLevels: '/primary_parts/update_base_levels',
    currencies: '/currencies/update_currency',
    vendorPart: '/vendor_parts/update_vendor_part',
    managedPart: '/part_conditions/work_order_allocation',
    editMangedPart: '/part_conditions/edit_part_condition',
    deleteMangedPart: '/part_conditions/wo_parts_withdrawal',
    editPartWORequest: '/part_requests/edit_part_request',
    editPartRequest: '/part_conditions/return_part',
    directReturnPartrequest: 'part_conditions/return_allocation_flow',
    updateEmailReport: "customer/report_emails/update",
    updateContact: "customer/contact/update",
    updateCheckRate: "/check_rates/update_check_rate",
    editDDParts: "/part_requests/edit_part_request",
    updateHighLiftEquip: "/accounts/update_high_lift_equip",
    updatetoolRate: "/tooling_rates/edit_tooing_rate",
    copyToolingRates: "/tooling_rates/copy_tooling_rates",
    copyScheduledCheckHours: "/line_customers/copy_scheduled_check_hours",
    updateManagedParts: '/accounts/update_allocation_parts',
    updateMileageRates: "vehicles/update_vehicle",
    updateLabourRates: "/user_groups/update_labour_rate",
    fixedFees: "line_customers/edit_lc_app_type_rates"
}

function updateData(formData, id = null, section) {
    return requestrApi({
        url: id ? `${updateDataUrl[section]}/${id}` : `${updateDataUrl[section]}`,
        method: 'PUT',
        data: formData
    })
}
export function updateDataWithPost(formData, id = null, section) {
    return requestrApi({
        url: id ? `${updateDataUrl[section]}/${id}` : `${updateDataUrl[section]}`,
        method: 'POST',
        data: formData
    })
}

export const updateDataWithParams = (params, section) => {
    return requestrApi({
        url: `${updateDataUrl[section]}`,
        method: 'PUT',
        params: params
    })
}

export default updateData;
