import requestApi from "../../common/boxp_api_client";




//Shift Patterns

const getShiftCalendarDetails = (params) => {
    return requestApi({
        url: '/shift_plan',
        method: 'GET',
        params
    })
}

const getShiftWeeklyDetails = (params) => {
    return requestApi({
        url: '/week_view_shift_plan',
        method: 'GET',
        params
    })
}

const getShiftLeaveDetails = (params) => {
    return requestApi({
        url: '/leave_request',
        method: 'GET',
        params
    })
}


const getShiftOvertimeDetails = (params) => {
    return requestApi({
        url: '/overtime_request',
        method: 'GET',
        params
    })
}


function fetchUsers(params) {
    return requestApi({
        url: '/fetch_users',
        method: 'GET',
        params: params
    })

}
const createLeaveRequest = (formData) => {
    return requestApi({
        url: '/leave_request',
        method: 'POST',
        data: formData
    })
}

const createOvertimeRequest = (formData) => {
    return requestApi({
        url: '/overtime_request',
        method: 'POST',
        data: formData
    })
}



const getShiftDayListingDetails = (params) => {
    return requestApi({
        url: '/shift_plan_listing',
        method: 'GET',
        params
    })
}

const getLeaveReviewPage = (params) => {
    return requestApi({
        url: '/leave_review',
        method: 'GET',
        params
    })
}
const getOvertimeReviewPage = (params) => {
    return requestApi({
        url: '/overtime_review',
        method: 'GET',
        params
    })
}
const postLeaveActionRequest = (formData) => {
    return requestApi({
        url: '/leave_actions?_method=PUT',
        method: 'POST',
        data: formData
    })
}
const getShiftUsedHoursDetails = (params) => {
    return requestApi({
        url: '/get_shift_user_hours',
        method: 'GET',
        params
    })
}
const postOverTimeActionRequest = (formData) => {
    return requestApi({
        url: '/overtime_actions',
        method: 'POST',
        data: formData
    })
}
const getLeaveRequestDetails = (params) => {
    return requestApi({
        url: '/leave_request_popup',
        method: 'GET',
        params
    })
}
const getOvertimeRequestDetails = (params) => {
    return requestApi({
        url: '/overtime_request_popup',
        method: 'GET',
        params
    })
}


const getStaffLeaveDetails = (params) => {
    return requestApi({
        url: '/staff_leave',
        method: 'GET',
        params
    })
}

const getStaffOverTimeDetails = (params) => {
    return requestApi({
        url: '/staff_overtime',
        method: 'GET',
        params
    })
}
const getHomePageMyLeaveSectionDetails = (params) => {
    return requestApi({
        url: '/leave_section_details',
        method: 'GET',
        params
    })
}
const getHomePageMyOvertimeDetails = (params) => {
    return requestApi({
        url: '/staff_leave',
        method: 'GET',
        params
    })
}

const deleteLeaveRequest = (id) => {

    return requestApi({
        url: `/leave_request/${id}`,
        method: 'DELETE',
    })
}


const deleteOverTimeRequest = (id) => {

    return requestApi({
        url: `/overtime_request/${id}`,
        method: 'DELETE',
    })
}




const ShiftServices = {

    getShiftCalendarDetails,
    getShiftLeaveDetails,
    getShiftOvertimeDetails,
    fetchUsers,
    createLeaveRequest,
    getShiftWeeklyDetails,
    getShiftDayListingDetails,
    getLeaveReviewPage,
    postLeaveActionRequest,
    getShiftUsedHoursDetails,
    createOvertimeRequest,
    getOvertimeReviewPage,
    postOverTimeActionRequest,
    getLeaveRequestDetails,
    getOvertimeRequestDetails,
    getStaffLeaveDetails,
    getStaffOverTimeDetails,
    getHomePageMyLeaveSectionDetails,
    getHomePageMyOvertimeDetails,
    deleteLeaveRequest,
    deleteOverTimeRequest


}




export default ShiftServices
