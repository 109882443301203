import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography/Typography";
import AuthClient from "../../lib/services/api/auth_services";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import LockIcon from "@material-ui/icons/LockOutlined";
import useAuthStyles from "./auth_styles";
import { getParams } from "../../lib/utils/common_utils";
import MROTextField from "../../components/form_components/TextField";

export default function SetPin(props) {
  const classes = useAuthStyles();
  const [state, setState] = useState({
    pin: "",
    pin_confirmation: "",
  });
  const [password, handlePasswordChange] = useState();
  const [passwordConfirmation, handleConfirmPassword] = useState();
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ message: "", error: false });
  let headers = getParams(props.location.search);

  const query = new URLSearchParams(props.location.search);

  const accessToken = query.get("access_token");

  useEffect(() => {
    // if (!headers || !headers['access-token']) {
    //     props.history.push('/auth/forgot-password');
    // }

    // if (!accessToken) {
    //   props.history.push("/auth/forgot-password");
    // }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(state.pin, state.pin_confirmation);
    if (state.pin.length < 6) {
      setError({ message: "Minimum 6 digit required", error: true });
    }
    if (state.pin !== state.pin_confirmation) {
      setError({ message: "Pin do not match", error: true });
    } else {
      setLoading(true);
      setError({ message: "", error: false });
      const formData = state;
      console.log(formData, headers);
      AuthClient.changePin(formData, headers)
        .then((res) => {
          setMessage({
            message:
              "Your pin has been set successfully. Kindly use this pin to login into the MRO-PRO iOS or Android application.",
            success: true,
          });
        })
        .catch((error) => {
          console.log({ error });
          setLoading(false);
          setMessage({
            message: "Something went wrong. Please try again!",
            success: false,
          });
        });
    }
  };

  const handleChangeInput = (e) => {
    const re = /^[0-9]/; //rules
    console.log("passes", re.test(e.target.value), e.target.value);

    if (re.test(e.target.value)) {
      console.log("passes", re.test(e.target.value));
      return setState({
        ...state,
        [e.target.name]: e.target.value,
      });
    } else {
      setState({
        ...state,
        [e.target.name]: "",
      });
    }
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.overlay}></div>
      <div style={{ zIndex: "99" }}>
        {!!!message ? (
          <form
            spellcheck="false"
            className={classes.form}
            onSubmit={handleSubmit}
            autoComplete="custom-form"
          >
            <Typography component={"h6"} variant={"h6"}>
              Setup Pin
            </Typography>
            <div>Choose new pin </div>
            {/* <FormControl fullWidth className={classes.formField}> */}
            <MROTextField
              variant="outlined"
              classes={{
                root: classes.password,
              }}
              className={classes.formField}
              required
              name="pin"
              type="text"
              autoComplete="new-pin"
              placeholder="Enter Pin"
              autoFocus
              value={state.pin}
              inputProps={{
                maxLength: 6,
                minLength: 6,
              }}
              onChange={handleChangeInput}
              onFocus={() => setError(false)}
              startAdornment={
                <InputAdornment position="start">
                  <LockIcon htmlColor={"#ffffff80"} />
                </InputAdornment>
              }
            />
            {/* </FormControl> */}
            <FormControl fullWidth className={classes.formField}>
              <OutlinedInput
                required
                classes={{
                  root: classes.password,
                }}
                name="pin_confirmation"
                autoComplete="confirm-pin"
                placeholder="Confirm Pin"
                type="text"
                id="password-confirmation"
                value={state.pin_confirmation}
                inputProps={{
                  maxLength: 6,
                }}
                error={error.error}
                autoFocus
                helperText={error.error ? error.message : ""}
                onChange={handleChangeInput}
                onFocus={() => setError(false)}
                startAdornment={
                  <InputAdornment position="start">
                    <LockIcon htmlColor={"#ffffff80"} />
                  </InputAdornment>
                }
              />
              {error.error && (
                <Typography color="error">{error.message}</Typography>
              )}
            </FormControl>
            <div style={{ margin: "20px 0" }}>
              <Button
                type="submit"
                className={classes.submit}
                disabled={loading}
                fullWidth
              >
                Submit
              </Button>
            </div>
          </form>
        ) : (
          <div
            className={`${classes.form} ${
              message.success ? classes.success : classes.error
            }`}
          >
            <Typography
              variant="subtitle1"
              gutterBottom
              className={classes.message}
            >
              {message.message}
            </Typography>
            {!message.success && (
              <Button
                fullWidth
                className={classes.submit}
                onClick={() => setMessage(null)}
              >
                Retry
              </Button>
            )}
          </div>
        )}
      </div>
      <div className={classes.brandLogo}>
        <img src={"/mro-pro-logo.png"} alt={"mro pro logo"} />
      </div>
    </div>
  );
}



