import MaterialTable from 'material-table';
import React, { useContext, useEffect, useReducer, useState } from 'react'
import DeleteIcon from '@material-ui/icons/Delete';
import TableAction from '../../../../actions/table_actions';
import tableIcons, { options } from '../../../../components/universal/table_attributes';
import { ToastMessageContext } from '../../../../lib/contexts/message_context';
import tableReducer, { INITIAL_TABLE_STATE } from '../../../../reducers/table_reducer';
import { ICON_COLOUR } from '../../../../lib/constants/style_constants';
import DataServiceAction from '../../../../actions/data_actions';
import ManageDataDialog from '../../../../components/form_components/manage_data_dialog';
import { IconButton } from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import UpdateTechLog from './UpdateTechLog';

export const mapTasks = {
    'Tech Log Maint Tasks': 'tech_log_maint',
    'Non-Fixed Price Scheduled Maintenance': 'fixed_price_maint',
    'Defects': 'defects',
    'Cabin Maint Tasks': 'cabin_maint'
}

function RenderMaintTable(props) {

    const { currentSelection, workOrderId } = props;
    const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
    const { page, totalCount, pageSize, searchText, inProgress, data: _data } = tableState;
    const message = useContext(ToastMessageContext);
    const [currentselection, setCurrentSelection] = useState({});
    const [action, setAction] = useState(null);

    const tableOptions = {
        ...options,
        page: page,
        search: false,
        total: totalCount,
        pageSize: pageSize,
        searchText: searchText
    };


    const columns = [{
        title: '',
        field: '',
        headerStyle: {
            textAlign: 'left',
            width: '2%'
        },
        cellStyle: {
            textAlign: 'left',
            width: '2%'
        }
    }, {
        title: 'Task / TLP #',
        field: 'task_tlp',
        headerStyle: {
            textAlign: 'left',
            width: '20%'
        },
        cellStyle: {
            textAlign: 'left',
            width: '20%'
        }
    }, {
        title: 'ATA Chapter',
        field: 'ata_chapter',
        headerStyle: {
            textAlign: 'center',
            width: '15%'
        },
        cellStyle: {
            textAlign: 'center',
            width: '15%'
        }
    }, {
        title: 'Description',
        field: 'description',
        headerStyle: {
            textAlign: 'left',
            width: '30%'
        },
        cellStyle: {
            textAlign: 'left',
            width: '30%'
        }
    },
    {
        title: 'LAE Hrs',
        field: 'lae_hrs',
        headerStyle: {
            textAlign: 'center',

        },
        cellStyle: {
            textAlign: 'center',

        }
    }, {
        title: 'Non-LAE',
        field: 'non_lae_hrs',
        headerStyle: {
            textAlign: 'center',

        },
        cellStyle: {
            textAlign: 'center',

        }
    },
    {
        title: 'Total Hrs',
        field: 'man_hours',
        headerStyle: {
            textAlign: 'center',

        },
        cellStyle: {
            textAlign: 'center',

        }
    },
    {
        title: 'Actions',
        field: '',
        headerStyle: {
            textAlign: 'center',
            width: '10%'
        },
        cellStyle: {
            textAlign: 'center',
            width: '10%'
        },
        render: rowData => (
            // (currentSelection?.child_section_name === "Tech Log Maint Tasks" ?
            <>
                <IconButton onClick={() => {
                    setCurrentSelection(rowData);
                    setAction('edit');
                }}>
                    <Edit fontSize="small" htmlColor={ICON_COLOUR} />
                </IconButton>

                <IconButton onClick={() => {
                    setCurrentSelection(rowData);
                    setAction('delete');
                }}>
                    <DeleteIcon fontSize="small" htmlColor={ICON_COLOUR} />
                </IconButton>
            </>
            // : <IconButton onClick={() => {
            //     setCurrentSelection(rowData);
            //     setAction('delete');
            // }}>
            //     <DeleteIcon fontSize="small" htmlColor={ICON_COLOUR} />
            // </IconButton>
        )
    }];

    useEffect(() => {
        if (currentSelection?.child_section_name)
            getOperationList(mapTasks[currentSelection?.child_section_name])

    }, [page, totalCount, pageSize, currentSelection, props?.createdId])

    function getOperationList(task_type) {
        let params = {
            id: workOrderId,
            task_type,
            page: page + 1,
            count_per_page: pageSize
        }
        TableAction.getList(dispatch, message, params, 'operational_tasks');
    }

    useEffect(() => {
        if (action === 'delete') {


        }
    }, [action])

    const handleClose = () => {
        setAction(null);
        getOperationList(mapTasks[currentSelection?.child_section_name])
    }

    const handleSubmit = () => {
        let data = {
            task_id: currentselection.task_id
        }
        DataServiceAction.deleteData(dispatch, message, null, data, 'operationalTasks', handleClose)
    }

    return (
        <>
            <MaterialTable
                style={{ boxShadow: '0px 1px 3px #00000033', marginTop: '10px' }}
                icons={tableIcons}
                title={""}
                isLoading={inProgress}
                data={_data.tasks}
                columns={columns}
                options={tableOptions}
                onChangePage={(page) => {
                    TableAction.setPage(dispatch, page);
                }}
                onChangeRowsPerPage={(pageSize) => {
                    TableAction.setPageSize(dispatch, pageSize);
                }}
                onSearchChange={(search) => {
                    TableAction.setSearchText(dispatch, search);
                }}
                totalCount={totalCount}
                page={page}
            />
            {
                action === 'delete' && <ManageDataDialog type="Delete" noTitle handleSubmit={handleSubmit} handleClose={handleClose}>
                    Are you sure? Confirm Delete.
                </ManageDataDialog>
            }
            {action === "edit" && <UpdateTechLog handleClose={handleClose} currentSelection={currentselection} workOrderId={workOrderId} task_type={mapTasks[currentSelection?.child_section_name]} typename={currentSelection?.child_section_name} />}
        </>
    )
}

export default React.memo(RenderMaintTable)