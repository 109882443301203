import React, { useState, useEffect } from "react";
import MROFullScreenRightDrawer from "components/fullwidth_dialog";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import Typography from "@material-ui/core/Typography";
import { Box, useTheme } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { ICON_COLOUR } from "lib/constants/style_constants";
import CalendarActionDetails from "./CalenderActionDetails";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: "3%",
  },
  appBar: {
    backgroundColor: "#fff",
    color: "#000000CC",
    boxShadow: "0px 3px 6px #0000001A",
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  description: {
    borderRadius: "5px",
    height: "250px",
    fontSize: "15px",
    backgroundColor: "#F5F5F5",
    padding: theme.spacing(1),
    boxShadow: "0px 1px 4px #0000001A",
  },
  paper1: {
    borderRadius: "5px",
    width: "100%",
    backgroundColor: "#F5F5F5",
    padding: theme.spacing(1),
    boxShadow: "0px 1px 4px #0000001A",
  },
  paper: {
    borderRadius: "5px",
    backgroundColor: "#F5F5F5",
    padding: theme.spacing(2),
    boxShadow: "0px 1px 4px #0000001A",
  },
  partValue: {
    fontSize: "14px",
    color: "#4D4F5C",
    padding: "2px",
    display: "flex",
    alignItems: "center",
  },
  partKey: {
    "& p": {
      fontSize: "14px",
      padding: "1px",
    },
  },
  partCard: {
    borderRadius: "8px",
    padding: "17px 24px",
    "& p": {
      fontSize: "14px",
      padding: "5px",
    },
  },
  sec1: {
    borderRadius: "10px",
    backgroundColor: "#fff",
    padding: theme.spacing(5),
    boxShadow: "0px 1px 4px #0000001A",
  },
  EditIcon: {
    bgcolor: "primary",
    color: "#fff",
  },
  editButton: {
    marginLeft: "300px",
  },
  partValue: {
    fontSize: "14px",
    color: "#4D4F5C",
    padding: "2px",
    display: "flex",
    alignItems: "center",
  },
  partKey: {
    "& p": {
      fontSize: "14px",
      padding: "1px",
    },
  },
  partCard: {
    borderRadius: "8px",
    padding: "17px 24px",
    "& p": {
      fontSize: "14px",
      padding: "5px",
    },
  },
}));

function CalendarActionInfo(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { handleClose } = props;
  const [action, setAction] = useState(null);

  const handleCloseEdit = () => {
    if (action === "EditStage") {
      setAction(null);
    }
  };

  const GetInfoCard = (props) => {
    const classes = useStyles();
    const theme = useTheme();

    return (
      <Box display="flex">
        <Box flex={50} className={classes.partKey}>
          <Typography
            style={{ padding: "5px", color: theme.palette.primary.main }}
            color="secondary"
          >
            {props?.keyName}
          </Typography>
        </Box>
        <Box flex={50} className={classes.partValue}>
          {props?.value ?? "-"}
        </Box>
      </Box>
    );
  };

  return (
    <div>
      <MROFullScreenRightDrawer open={true}>
        <AppBar position="static" className={classes.appBar} elevation={0}>
          <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
            <Box display={"flex"} alignItems={"center"}>
              <IconButton
                onClick={handleClose}
                edge="start"
                className={classes.backButton}
                color="inherit"
                aria-label="back"
              >
                <BackIcon />
              </IconButton>
              <Typography variant="subtitle2">
                Calendar Action Information
              </Typography>
            </Box>
            <IconButton>
              <EditIcon
                fontSize="small"
                htmlColor={ICON_COLOUR}
                onClick={() => setAction("EditCalendarInfo")}
              />
            </IconButton>
          </Toolbar>
        </AppBar>

        <div className={classes.wrapper}>
          <Grid>
            <Typography
              color="primary"
              varient="h3"
              style={{ marginBottom: "10px", marginTop: "10px" }}
            >
              Action Details
            </Typography>
          </Grid>

          <Box
            display="flex"
            alignItems="center"
            //   justifyContent={(loading || _.isEmpty(partDetails)) && "center"}
            bgcolor="#F5F5F5"
            className={classes.partCard}
          >
            <Box flexGrow={1}>
              <div style={{ width: "100%" }}>
                <GetInfoCard keyName={"Raised by"} value="" />
                <GetInfoCard keyName={"Raised"} value="" />
                <GetInfoCard keyName={"Tech Data Reference"} value="" />
                <GetInfoCard keyName={"Action & Stage Title"} value="" />
              </div>
            </Box>
            <Box flexGrow={1}>
              <div style={{ width: "100%" }}>
                <GetInfoCard keyName={"Auth"} value="" />
                <GetInfoCard keyName={"ATA Chapter"} value="" />
                <GetInfoCard keyName={"Frequency (days)"} value="" />
                <GetInfoCard keyName={""} value="" />
              </div>
            </Box>
            <Box flexGrow={1}>
              <div style={{ width: "100%" }}>
                <GetInfoCard keyName={"Planned Man-Hrs"} value="" />
                <GetInfoCard keyName={"CAMO Part-M Records"} value="" />
                <GetInfoCard keyName={"Last Complete"} value="" />
                <GetInfoCard keyName={""} value="" />
              </div>
            </Box>
          </Box>

          <Grid>
            <Typography
              color="primary"
              varient="h3"
              style={{ marginBottom: "10px", marginTop: "10px" }}
            >
              Detailed Description
            </Typography>
          </Grid>
          <Grid className={classes.description}>
            <Typography style={{ fontSize: "15px" }}>
              Detailed stage description will appear here. Detailed stage
              descriptions can have up to 2000 characters. This higher character
              allowance than normal allows very detailed descriptions to be
              provide to engineers completing the stage. This box size is sized
              for 2000 characters to guide how large descriptions can be.
            </Typography>
          </Grid>

          <Grid>
            <Typography
              color="primary"
              varient="h3"
              style={{ marginBottom: "10px", marginTop: "10px" }}
            >
              Sub-Fields
            </Typography>
          </Grid>
          <Box
            display="flex"
            alignItems="center"
            //   justifyContent={(loading || _.isEmpty(partDetails)) && "center"}
            bgcolor="#F5F5F5"
            className={classes.partCard}
          >
            <Box flexGrow={1}>
              <div style={{ width: "100%" }}>
                <GetInfoCard keyName={"Sub Field 1"} value="" />
                <GetInfoCard keyName={"Sub Field 2"} value="" />
                <GetInfoCard keyName={"Sub Field 3"} value="" />
                <GetInfoCard keyName={"Sub Field 4"} value="" />
                <GetInfoCard keyName={"Sub Field 5"} value="" />
              </div>
            </Box>
            <Box flexGrow={1}>
              <div style={{ width: "100%" }}>
                <GetInfoCard keyName={"Sub Field 6"} value="" />
                <GetInfoCard keyName={"Sub Field 7"} value="" />
                <GetInfoCard keyName={"Sub Field 8"} value="" />
                <GetInfoCard keyName={"Sub Field 9"} value="" />
                <GetInfoCard keyName={"Sub Field 10"} value="" />
              </div>
            </Box>
          </Box>

          <Box>
            <Typography
              color="primary"
              varient="h3"
              style={{ marginBottom: "10px", marginTop: "10px" }}
            >
              Technical Data
            </Typography>
            <Grid className={classes.sec1}></Grid>
          </Box>

          <Grid>
            <Typography
              color="primary"
              varient="h3"
              style={{ marginBottom: "10px", marginTop: "10px" }}
            >
              Closure
            </Typography>
          </Grid>
          <Box
            display="flex"
            alignItems="center"
            //   justifyContent={(loading || _.isEmpty(partDetails)) && "center"}
            bgcolor="#F5F5F5"
            className={classes.partCard}
          >
            <Box flexGrow={1}>
              <div style={{ width: "100%" }}>
                <GetInfoCard keyName={"Closed"} value="" />
              </div>
            </Box>
            <Box flexGrow={1}>
              <div style={{ width: "100%" }}>
                <GetInfoCard keyName={"Closed by"} value="" />
              </div>
            </Box>
          </Box>
        </div>
      </MROFullScreenRightDrawer>

      {action === "EditCalendarInfo" && <CalendarActionDetails action={action} handleClose={() => setAction(null)} />}
    </div>
  );
}

export default CalendarActionInfo;
