import React, { useState, useEffect, useContext } from "react";
import MROTextField from "components/form_components/TextField";
import { Box } from "@material-ui/core";
import MROFullScreenRightDrawer from "components/fullwidth_dialog";
import {
  Toolbar,
  IconButton,
  AppBar,
  Typography,
  Grid,
  MenuItem
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import DateField from "components/form_components/DateField";
import MROButton from "components/buttons";
import { ServicesAndEquip } from "lib/services/api/operaitons/WorkShop/Services_Equipment/services";
import { userDetails, validateFormData } from "lib/utils/helperFunctions";
import { ToastMessageContext } from "lib/contexts/message_context";
import ServiceRequestListClientServices from "lib/services/api/procurement/Services";
import { ICON_COLOUR } from "lib/constants/style_constants";
import DeleteServices from "../DeleteServices";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#fff",
    color: "#000000CC",
    boxShadow: "0px 3px 6px #0000001A",
  },
  root: {
    backgroundColor: "#fff",
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  wrapper: {
    padding: "3%",
    background: "white",
  },
  title: {
    fontSize: "20px",
    marginBottom: "10px",
    marginRight: "20px",
  },
  paper: {
    borderRadius: "2px",
    backgroundColor: "#F5F5F5",
    // padding: theme.spacing(2),
    boxShadow: "0px 1px 4px #0000001A",
  },
}));

function AddSpecialistEquipment(props) {
  const classes = useStyles();
  const { closeHandler, onClose, workshop_id, action, isEdit, currentSelection, baseId, getWorkshopEquipment } = props;
  const message = useContext(ToastMessageContext)
  const [actionNumber, setActionNumber] = useState([]);
  const [deleteAction, setDeleteAction] = useState(false)
  const [busy, setBusy] = useState(null)
  const [desiredDate, setDesiredDate] = useState(null)
  const [errors, setErrors] = useState({})
  const [fieldsData, setFieldsData] = useState({
    workshop_action_id: null,
    description: null,
    desired_date: null,
    duration: null,
  });

  useEffect(() => {
    getActionNumber();
  }, []);

  const getActionNumber = async () => {
    const params = {
      id: workshop_id,
    };
    await ServicesAndEquip.getActionNumber(params)
      .then((res) => {
        if (res) {
          setActionNumber(res.data);
        }
      })
      .catch(() => {
        message.showToastMessage({
          message: "Something went wrong. Try again!",
          variant: "error",
        });
      });
  };

  useEffect(() => {
    if (isEdit && currentSelection !== null) {
      let tempData = {};
      Object.keys(currentSelection).forEach((key) => {
        tempData[key] = currentSelection[key];
      });
      setFieldsData(tempData);
    }
  }, [currentSelection]);

  const onChangeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    resetErrorsHandler(name);
    setFieldsData((prevState) => ({ ...prevState, [name]: value }));
  };


  const handleCreate = (formData) => {
    formData.append("user_id", userDetails.userId)
    formData.append("base_station_id", baseId)
    setBusy("loading")
    ServiceRequestListClientServices.workshopCreateEquipments(formData)
      .then((res) => {
        if (res) {
          onClose(res.success)
          props.getWorkshopEquipment()
          message.showToastMessage({
            message: "Created successfully!!",
            variant: "success"
          })
        }
      }).catch(() => {
        message.showToastMessage({
          message: "Something went wrong. Try again!!",
          variant: "error"
        })
      }).finally(() => {
        setBusy(null)
      })
  }
  const handleUpdate = (formData) => {
    setBusy("loading")
    formData.append("id", currentSelection.id)
    ServiceRequestListClientServices.workshopUpdateEquipments(formData)
      .then((res) => {
        if (res) {
          onClose(res.success)
          getWorkshopEquipment()
          message.showToastMessage({
            message: "Updated successfully!!",
            variant: "success"
          })
        }
      }).catch(() => {
        message.showToastMessage({
          message: "Something went wrong. Try again!!",
          variant: "error"
        })
      }).finally(() => {
        setBusy(null)
      })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target)
    const { error, valid } = validateFormData(formData, []);
    if (!valid) {
      setBusy(null);
      setErrors(error);
      return;
    }

    if (action === "Add") {
      handleCreate(formData)
    }
    if (isEdit) {
      handleUpdate(formData)
    }

  }

  const resetErrorsHandler = (name) => {
    setErrors((e) => ({ ...e, [name]: false }));
  };

  return (
    <div>
      <MROFullScreenRightDrawer open={true} className={classes.root}>
        <AppBar position="static" className={classes.appBar} elevation="">
          <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
            <Box display="flex" alignItems="center">
              <IconButton
                onClick={onClose}
                edge="start"
                className={classes.backButton}
                color="inherit"
                aria-label="back"
              >
                <BackIcon />
              </IconButton>
              <Typography variant="subtitle2" style={{ fontSize: "17px" }}>
                {isEdit ? 'Edit' + " " + 'Specialist Equipment' : 'Add' + " " + 'Specialist Equipment'}
              </Typography>
            </Box>
            <IconButton onClick={() => setDeleteAction(true)} style={{ display: !isEdit && "none" }}>
              <Typography
                style={{
                  fontSize: "13px",
                  fontWeight: "20px",
                  backgroundColor: "transparent",
                }}
              >
                Delete Item&nbsp;&nbsp;
              </Typography>
              <DeleteIcon fontSize="small" htmlColor={ICON_COLOUR} />
            </IconButton>
          </Toolbar>
        </AppBar>

        <form noValidate onSubmit={handleSubmit}>
          <Grid className={classes.wrapper}>
            <Typography color="primary" className={classes.title}>
              Action
            </Typography>
            <Grid container spacing={1} item xs={12} style={{ width: "50%" }}>
              <Grid item xs={12}>
                <MROTextField
                  name={"workshop_action_id"}
                  label={"Select Action *"}
                  InputLabelProps={{ shrink: true }}
                  placeholder={"Max 250 characters"}
                  defaultValue={fieldsData.workshop_action_id}
                  select
                  error={errors.workshop_action_id}
                  onChange={(e) => {
                    onChangeHandler(e)
                  }}
                >
                  {/* <MenuItem value={"select"}>
                    <span style={{ opacity: "40%" }}>Select</span>
                  </MenuItem> */}
                  {actionNumber?.map((item) => (
                    <MenuItem
                      key={item.workshop_action_id}
                      value={item.workshop_action_id}
                    >
                      {item.action_number}
                    </MenuItem>
                  ))}
                </MROTextField>
              </Grid>
              <Grid item xs={12}>
                <Typography color="primary">Item Details</Typography>
              </Grid>
              <Grid item xs={12}>
                <MROTextField
                  name="description"
                  placeholder="Enter details of specialist Tooling or Equipment required. Add maintenance data references where applicable. Max 500 characters"
                  label="Item Description *"
                  variant="outlined"
                  multiline
                  color="primary"
                  defaultValue={fieldsData.description}
                  inputProps={{ maxLength: 500 }}
                  InputProps={{ style: { height: 'max-content', width: "100%" } }}
                  InputLabelProps={{ shrink: true }}
                  error={errors.description}
                  onChange={(e) => {
                    onChangeHandler(e)
                  }}
                />
              </Grid>
              <Grid item xs={6} md={6} style={{ marginTop: "8px" }}>
                <DateField
                  name={"desired_date"}
                  label={"Desired Date *"}
                  InputLabelProps={{ shrink: true }}
                  error={errors.desired_date}
                  defaultValue={fieldsData.desired_date}
                  autoFocus={errors.desired_date}
                  value={desiredDate}
                  helperText={errors.desired_date ? "*Please select Desired Date *" : null}
                  onChange={() => {
                    resetErrorsHandler("desired_date")
                  }}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <MROTextField
                  name={"duration"}
                  type="number"
                  label={"Duration (days)"}
                  defaultValue={fieldsData.duration}
                  InputLabelProps={{ shrink: true }}
                  placeholder={"Number of days"}
                  error={errors.duration}
                  onChange={(e) => {
                    onChangeHandler(e)
                  }}
                ></MROTextField>
              </Grid>
            </Grid>

            <Box
              display="flex"
              justifyContent="flex-end"
              style={{ marginTop: "380px" }}
            >
              <MROButton
                style={{ marginRight: "20px" }}
                type="button"
                variant={"contained"}
                onClick={onClose}
              >
                Cancel
              </MROButton>
              <MROButton
                type="submit"
                variant={"contained"}
                color="primary"
                loading={busy === "loading"}
              >
                {isEdit ? "Update" : "Add"}
              </MROButton>
            </Box>
          </Grid>
        </form>
      </MROFullScreenRightDrawer>
      {deleteAction && (
        <DeleteServices
          action={"delete"}
          handleClose={() => setDeleteAction(false)}
          title="Delete Item"
          description="This cannot be undone. Are you sure?"
          tab="equipment"
          Id={currentSelection?.id}
          closeHandler={closeHandler}
          getServices={getWorkshopEquipment}
        />
      )}
    </div>
  );
}

export default AddSpecialistEquipment;


