import React, { useState, useReducer, useContext, useEffect } from 'react'
import MaterialTable from 'material-table';
import tableIcons, { options } from 'components/universal/table_attributes';
import ViewIcon from "@material-ui/icons/Visibility";
import CopyFileIcon from "assets/CopyFiles.svg"
import GreenCopyIcon from "assets/GreenCopyIcon.svg"
import { ICON_COLOUR } from 'lib/constants/style_constants';
import { IconButton, Box } from '@material-ui/core';
import TableAction from "actions/table_actions";
import tableReducer, { INITIAL_TABLE_STATE } from 'reducers/table_reducer';
import { ToastMessageContext } from 'lib/contexts/message_context';
import ServicesAdditionalPopUp from './ServicesAdditionalPopUp';
import TechFilesPopup from '../TechFilesPopup';
import { WorkshopContext } from 'views/Operations/Workshop/WorkshopContext/WorkshopContext';
import { MaintenanceServices } from 'lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation';
import TechFilesPopupServices from "views/Operations/Workshop/PlanningTab/CopyTemplates/Tabs/Services/TechFilesPopup"



function Services(props) {
    const { currentActionData, currentWorkshopDetails, currentClosedDetails, currentActionId, orderType } = props
    const [action, setAction] = useState(false)
    const [isTechFiles, setIsTechFiles] = useState(false)
    const [currentSelectedRow, setCurrentSelectedRow] = useState({})
    const [searchText, setSearchText] = useState("");
    const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
    const { totalCount, data, inProgress } = tableState;
    const message = useContext(ToastMessageContext);
    const [selectedRow, setSelectedRow] = useState({});
    const [pageSize, setPageSize] = useState(5);
    const [page, setPage] = useState(0);
    const [tData, setTData] = useState([])
    const [loading, setLoading] = useState(false)
    const [totalCountT, setTotalCountT] = useState(0)
    const { copyServices, serviceFlag, copyInspection, copiedJobs } = useContext(WorkshopContext)

    const tableOptions = {
        ...options,
        page: page,
        total: totalCount,
        pageSize: pageSize,
        search: false,
        toolbar: false,
        rowStyle: { height: 42 },
    };

    const tableOptions1 = {
        ...options,
        page: page,
        total: totalCountT,
        pageSize: pageSize,
        search: false,
        toolbar: false,
        rowStyle: { height: 42 },
    };


    const getServices = () => {
        const params = {
            count_per_page: pageSize,
            page: page + 1,
            workshop_order_id: currentActionData.workshop_order_id,
            workshop_action_id: currentActionData.workshop_action_id


        };
        TableAction.getList(dispatch, message, params, "workshopServices");
    }

    useEffect(() => {
        if (orderType === "Maintenance" || orderType === "Production") {
            getServices();
        }
    }, [page, pageSize]);

    const getTemplateServices = async () => {

        const params = {

            workshop_order_id: currentActionData?.workshop_order_id,
            workshop_action_id: currentActionData.workshop_action_id,
            page: 1 + page,
            limit: pageSize,
        }

        setLoading(true)
        await MaintenanceServices.getTemlateServiceListing(params).then((res) => {
            setTData(res.data)
            setTotalCountT(res.total_count)
        }).catch(() => {
            message.showToastMessage({
                message: "Something went wrong. Try again!",
                variant: "error",
            });
        }).finally(() => {

            setLoading(false)

        })
    }

    useEffect(() => {
        if (orderType === "Maintenance Template" || orderType === "Production Template") {
            getTemplateServices();
        }
    }, [page, pageSize]);

    const columns = [

        {
            title: "Scope",
            field: "scope",
            headerStyle: {
                textAlign: "left",
                paddingLeft: "20px"
            },
            cellStyle: {
                textAlign: "left",
                paddingLeft: "20px"
            },
        },
        {
            title: "Type",
            field: "service_type",
            headerStyle: {
                textAlign: "left",
            },
            cellStyle: {
                textAlign: "left",
            },
        },
        {
            title: "Description",
            field: "description",
            width: '45%',
            headerStyle: {
                textAlign: "left",
                width: '45%',
            },
            cellStyle: {
                textAlign: "left",
                width: '45%',
            },
        },
        {
            title: "Location",
            field: "location",
            headerStyle: {
                textAlign: "left",
            },
            cellStyle: {
                textAlign: "left",
            },
            render: (rowData) => {
                if (rowData?.location === "mro_on_site") return "MRO Site"
                else if (rowData?.location === "supplier_off_site") {
                    return "Supplier Site"
                }
            }
        },

        {
            title: "Tech Data",
            // field: "tech_data",
            headerStyle: {
                textAlign: "center",
            },
            cellStyle: {
                textAlign: "center",
            },
            render: (rowData) => (
                <div>
                    <IconButton>
                        <ViewIcon fontSize="small" style={{ color: ICON_COLOUR }} onClick={() => {
                            setIsTechFiles(true)
                            setAction("view_files")
                            setCurrentSelectedRow(rowData)
                        }}
                        />
                    </IconButton>

                </div>

            )
        },
        {
            title: "Copy",
            headerStyle: {
                textAlign: "center",
            },
            cellStyle: {
                textAlign: "center",
            },
            render: (rowData) => (
                <div>
                    {copiedJobs.find((item) => item === currentActionData.workshop_action_id) && <IconButton
                        onClick={() => {
                            setAction("View");
                            setSelectedRow(rowData);
                            copyServices.push(rowData.id)
                        }}
                        disabled={serviceFlag && copyServices.find((item) => item === rowData.id)}
                    >
                        {serviceFlag && copyServices.find((item) => item === rowData.id) ? <img src={GreenCopyIcon} height="25px" style={{ pointerEvents: serviceFlag && copyServices.find((item) => item === rowData.id) ? "none" : "" }} /> :

                            <img src={CopyFileIcon} height="25px" />
                        }
                    </IconButton>}
                </div>
            ),
        },
    ];

    const serviceColumns = [
        {
            title: "Scope",
            field: "part_scope_name",
            headerStyle: {
                textAlign: "left",
                paddingLeft: "20px"
            },
            cellStyle: {
                textAlign: "left",
                paddingLeft: "20px"
            },
        },
        {
            title: "Type",
            field: "type",
            headerStyle: {
                textAlign: "left",
            },
            cellStyle: {
                textAlign: "left",
            },
        },
        {
            title: "Description",
            field: "description",
            width: '45%',
            headerStyle: {
                textAlign: "left",
                width: '45%',
            },
            cellStyle: {
                textAlign: "left",
                width: '45%',
            },
        },
        {
            title: "Location",
            field: "location",
            headerStyle: {
                textAlign: "left",
            },
            cellStyle: {
                textAlign: "left",
            },

        },
        {
            title: "Tech Data",
            // field: "tech_data",
            headerStyle: {
                textAlign: "center",
            },
            cellStyle: {
                textAlign: "center",
            },
            render: (rowData) => (
                <div>
                    <IconButton>
                        <ViewIcon fontSize="small" style={{ color: ICON_COLOUR }} onClick={() => {
                            setIsTechFiles(true)
                            setAction("view_files")
                            setCurrentSelectedRow(rowData)
                        }}
                        />
                    </IconButton>

                </div>

            )
        },
        {
            title: "Copy",
            headerStyle: {
                textAlign: "center",
            },
            cellStyle: {
                textAlign: "center",
            },
            render: (rowData) => (
                <div>
                    {copiedJobs.find((item) => item === currentActionData.workshop_action_id) && <IconButton
                        onClick={() => {
                            setAction("View");
                            setSelectedRow(rowData);
                            copyServices.push(rowData.id)
                        }}
                        disabled={serviceFlag && copyServices.find((item) => item === rowData.id)}
                    >
                        {serviceFlag && copyServices.find((item) => item === rowData.id) ? <img src={GreenCopyIcon} height="25px" style={{ pointerEvents: serviceFlag && copyServices.find((item) => item === rowData.id) ? "none" : "" }} /> :

                            <img src={CopyFileIcon} height="25px" />
                        }
                    </IconButton>}
                </div>
            ),
        },
    ];

    return (
        <div> <MaterialTable
            style={{
                boxShadow: "0px 1px 3px #00000033",
                marginTop: "10px",
            }}
            icons={tableIcons}
            title={""}
            columns={orderType === "Maintenance" || orderType === "Production" ? columns : orderType === "Maintenance Template" || orderType === "Production Template" ? serviceColumns : columns}
            data={orderType === "Maintenance" || orderType === "Production" ? data.data : orderType === "Maintenance Template" || orderType === "Production Template" ? tData : []}
            isLoading={inProgress || loading}
            options={orderType === "Maintenance" || orderType === "Production" ? tableOptions : orderType === "Maintenance Template" || orderType === "Production Template" ? tableOptions1 : tableOptions}
            onChangePage={(page) => {
                setPage(page);
            }}
            onChangeRowsPerPage={(pageSize) => {
                setPageSize(pageSize);
            }}

            totalCount={orderType === "Maintenance" || orderType === "Production" ? totalCount : totalCountT}
            page={page}

        />

            {action && action === "View" && <ServicesAdditionalPopUp
                handleClose={() => setAction(null)}
                currentClosedDetails={currentClosedDetails}
                currentWorkshopDetails={currentWorkshopDetails}
                selectedRow={selectedRow}
                currentActionData={currentActionData}
                currentActionId={currentActionId}
                orderType={orderType}
            />
            }

            {
                action === 'view_files' && (orderType === "Maintenance" || orderType === "Production") && (
                    <TechFilesPopup
                        handleClose={() => setAction(null)}
                        currentSelectedRow={currentSelectedRow}
                        open={isTechFiles}
                        name={'service'}
                    />
                )
            }
            {
                action === 'view_files' && (orderType === "Maintenance Template" || orderType === "Production Template") && (
                    <TechFilesPopupServices
                        handleClose={() => setAction(null)}
                        currentSelectedRow={currentSelectedRow}
                        open={isTechFiles}

                    />
                )
            }
        </div>
    )
}

export default React.memo(Services)