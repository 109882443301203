import { makeStyles } from "@material-ui/core";

export const useReturnIssuedPartStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiDialogContent-root": {
      padding: "26px 85px",
    },
  },

  bodyText: {
    marginBottom: "24px",
    fontSize: "16px",
    color: "#4D4F5C",
    textAlign: "center",
  },

  qrContainer: {
    position: "fixed",
    width: "100vw",
    height: "100vh",
    top: 0,
    left: 0,
    background: "rgba(0,0,0,.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "all .5s",
    zIndex: 9,
  },

  heading: {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "25px"
  },

  firstStepRoot: {
    "& .MuiButtonGroup-root": {
      marginBottom: "15px"
    }
  }
}));
