// import request from "../common/api_client";
import requestrApi from "../common/boxr_api_client";
const BASE_PATH = '/primary_parts';

function fetchDependencies() {
    return requestrApi({
        url: `purchase_orders/fetch_dependencies`,
        method: 'GET',
    });
}

function updatePrimaryParts(data) {
    return requestrApi({
        url: `${BASE_PATH}/update_primary_part`,
        method: 'PUT',
        data,
    });
}
function getPrimaryParts(data) {
    return requestrApi({
        url: `${BASE_PATH}/listing_prime_parts`,
        method: 'GET',
        data,
    });
}


const PrimePartsClientServices = {
    fetchDependencies,
    updatePrimaryParts,
    getPrimaryParts
};

export default PrimePartsClientServices;
