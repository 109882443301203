import React, { useContext, useEffect, useState } from "react";
import RightDialog from "views/Stores/Parts/Stocks/RightDialog";
import { makeStyles } from "lib/utils/common_utils";
import { Box, IconButton, MenuItem, Typography } from "@material-ui/core";
import MROTextField from "components/form_components/TextField";
import MROButton from "components/buttons";
import DeleteIcon from "@material-ui/icons/Delete";
import { ICON_COLOUR } from "lib/constants/style_constants";
import QuantityCounter from "components/quantity_counter";
import { validateFormData } from "lib/utils/helperFunctions";
import { MaintenanceServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation";
import { ToastMessageContext } from "lib/contexts/message_context";
import ManageDataDialog from "components/form_components/manage_data_dialog";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-multiline": {
      minHeight: "89px",
    },
  },
  title: {
    fontSize: "20px",
    marginBottom: "16px",
    color: theme.palette.primary.main,
  },
}));

function EditPartDetails(props) {
  const { getData, closeHandler, open, onClose, data, actionDpdOptions, currentSelection, updateData } =
    props;

  const classes = useStyles();
  const message = useContext(ToastMessageContext);

  const [qty, setQty] = useState();
  const [errors, setErrors] = useState({});
  const [busy, setBusy] = useState(null);
  const [fieldsValue, setFieldsValue] = useState({
    workshop_action_id: null,
    issued_comment: null,
  });

  const [action, setAction] = useState(null);

  useEffect(() => {
    setFieldsValue({
      workshop_action_id: data?.data?.workshop_action_id,
      issued_comment: data?.data?.comment,
    });

    setQty(data?.data?.required_quantity);
  }, [currentSelection, data]);

  const onChange = (e) => {
    const { name, value } = e.target;

    setFieldsValue((ps) => ({ ...ps, [name]: value }));
  };

  const resetErrorHandler = (name) => {
    setErrors((e) => ({ ...e, [name]: false }));
  };

  const onSuccess = (response) => {
    if (response.success) {
      message.showToastMessage({
        message: "Order updated successfully",
        variant: "success",
      });
      updateData();
      onClose();
    } else {
      message.showToastMessage({
        open: true,
        message: response.errors.length
          ? response.errors.join(", ")
          : "Something went wrong",
        variant: "error",
      });
    }
    setBusy(null);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    formData.append("id", currentSelection?.id);

    const { error, valid } = validateFormData(formData, ["reason"]);
    const required_quantity = formData.get('quantity');
    if (+required_quantity === 0) {
      error['quantity'] = true;
      error['required_quantity_is_zero'] = true
      valid = false
    }
    if (!valid) {
      setErrors(error);
      return;
    }

    try {
      setBusy("loading");
      const response = await MaintenanceServices.editPartWOShopRequest(
        formData
      );
      onSuccess(response);
    } catch (error) {
      setBusy(null);
      message.showToastMessage({
        open: true,
        message: "Something went wrong",
        variant: "error",
      });
    }
  };

  const handleDelete = async () => {
    const formData = new FormData();
    formData.append("id", currentSelection.id);
    setAction("deleting");
    await MaintenanceServices.deleteRequest(formData)
      .then((res) => {
        if (res.success) {
          message.showToastMessage({
            message: "Deleted successfully",
            variant: "success",
          });
        }
        getData();
        closeHandler()
      }).catch(() => {
        message.showToastMessage({
          open: true,
          message: "Something went wrong",
          variant: "error",
        });
      }).finally(() => {
        setBusy(null);
      })
  };

  return (
    <RightDialog open={open} dialogCloseHandler={onClose}>
      <div className={classes.root}>
        <Box
          padding="23px 38px"
          width="100%"
          height="100%"
          display="flex"
          flexDirection="column"
        >
          <Typography
            variant="body1"
            style={{ color: "#4D4F5C", marginBottom: "47px" }}
          >
            Edit
          </Typography>
          <form noValidate onSubmit={submitHandler}>
            <MROTextField
              name={"workshop_action_id"}
              label={"Select Action"}
              InputLabelProps={{ shrink: true }}
              placeholder={"Select"}
              required
              select
              onChange={(e) => {
                resetErrorHandler(e.target.name);
                onChange(e);
              }}
              value={fieldsValue.workshop_action_id}
              error={errors.workshop_action_id}
            >
              {actionDpdOptions?.map((e) => (
                <MenuItem
                  key={e.workshop_action_id}
                  value={e.workshop_action_id}
                >
                  {e.action_number}
                </MenuItem>
              ))}
            </MROTextField>
            <MROTextField
              name="issued_comment"
              placeholder="Please enter the comments"
              label="Comments *"
              variant="outlined"
              multiline={"description"}
              color="primary"
              InputLabelProps={{ shrink: true }}
              error={errors.issued_comment}
              onChange={(e) => {
                resetErrorHandler(e.target.name);
                onChange(e);
              }}
              value={fieldsValue.issued_comment}
            />
            <QuantityCounter
              name={"quantity"}
              label={"Required"}
              placeholder="QTY"
              required
              autoFocus={errors["quantity"]}
              error={errors["quantity"] === true}
              helperText={
                errors["quantity"] &&
                typeof errors["quantity"] === "boolean" &&
                (errors["required_quantity_is_zero"]
                  ? "QTY Required Must Be Greater Than 0"
                  : `Please select Quanityt Received`)
              }
              shouldNotAcceptZero={true}
              setError={() =>
                setErrors((prevState) => ({
                  ...prevState,
                  quantity: false,
                }))
              }
              setQty={setQty}
              qty={qty}
            />
            <div
              style={{
                marginTop: "30px",
                marginBottom: "60px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <MROButton
                style={{ marginRight: "20px" }}
                type="button"
                variant={"contained"}
                onClick={onClose}
              >
                Cancel
              </MROButton>
              <MROButton
                type="submit"
                variant={"contained"}
                color="primary"
                loading={busy === "loading"}
              >
                Save
              </MROButton>
            </div>
          </form>
          <Box
            mt="auto"
            justifyContent="flex-end"
            alignSelf="flex-end"
            display="flex"
            alignItems="center"
            marginTop="380px"
          >
            <Typography>Delete item</Typography>
            <IconButton onClick={() => setAction("delete")}>
              <DeleteIcon color={ICON_COLOUR} />
            </IconButton>
          </Box>
        </Box>
      </div>

      {action === "delete" && (
        <ManageDataDialog
          loading={busy === "deleting"}
          type={"delete"}
          action={action}
          handleClose={() => setAction(null)}
          handleSubmit={handleDelete}
        >
          Are you sure? Confirm Delete.
        </ManageDataDialog>
      )}
    </RightDialog>
  );
}

export default EditPartDetails;
