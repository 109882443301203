import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Box,
    Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MROButton from "components/buttons";
import { Typography } from "@material-ui/core";
import Google from "assets/google.svg";
import Authy from "assets/authy.svg";
import QRCode from "qrcode.react";
import { ToastMessageContext } from "lib/contexts/message_context";
import { useContext } from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import UserIcon from "@material-ui/icons/PersonOutlined";
import useAuthStyles from "./auth_styles";
import LockIcon from "@material-ui/icons/LockOutlined";
import AuthClient from "lib/services/api/auth_services";
import AuthActions from "actions/auth_actions";

const useStyles = makeStyles((theme) => ({
    actionButtons: {
        paddingTop: "170px",
        paddingBottom: "33px",
        textAlign: "center",
        "& button:not(:last-child)": {
            marginRight: "15px",
        },
    },
    modal: {
        "& .MuiDialog-paperWidthMd": {
            width: "26%",
        },
    },
    title: {
        "& h2": {
            fontSize: "40px",
            fontWeight: 600,
        },
    },
    card: {
        width: "82%",
        backgroundColor: "#fff",
        color: '#000000CC',
        boxShadow: "0px 3px 6px #0000001A",
        // padding: "35px",
        marginTop: "20px",
        paddingBottom: "15px",
        marginLeft: "32px",
        borderRadius: "10px"
    },
    cards: {
        width: "82%",
        backgroundColor: "#fff",
        color: '#000000CC',
        boxShadow: "0px 3px 6px #0000001A",
        // padding: "35px",
        marginTop: "20px",
        marginLeft: "32px",
        borderRadius: "10px",
        padding: "10px"
    },
}));


export default function TotpAuthStep2(props) {
    const classes = useStyles();
    const message = useContext(ToastMessageContext)
    const { handleClose, open, setOtpMethod, factAndEntityId, closeHandler, email, password, application, browser, dispatch, authMethod } = { ...props };
    const [busy, setBusy] = useState();
    const [verified, setVerified] = useState(null)

    useEffect(() => {
        if (verified == "verified")
            AuthActions.userSignIn(dispatch, { email: email, verified_otp: true, success: true, password: password, application: application, browser: browser });
    }, [verified])


    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target)
        const otp = formData.get("otp")
        setBusy("loading")
        const params = {
            otp: otp,
            factor_sid: factAndEntityId?.factorSid,
            entity_sid: factAndEntityId?.entitySid,
            qr_code_link: factAndEntityId?.qrLink,
            stamp_number: authMethod?.stamp_number
        }
        AuthClient.verifyTOTPQR(params)
            .then((res) => {
                setBusy(null)
                if (res?.data?.factor_status == "verified") {
                    setVerified(res?.data?.factor_status)
                    closeHandler()
                    message.showToastMessage({
                        message: "Otp Verified successfully",
                        variant: "success",
                    });
                } else {
                    closeHandler()
                    setBusy(null)
                    message.showToastMessage({
                        message: "Entered OTP is invalid",
                        variant: "error",
                    });
                }
            })
            .catch((err) => {
                closeHandler()
                setBusy(null)
                message.showToastMessage({
                    message: "Entered OTP is invalid",
                    variant: "error",
                });
            })
    }


    const closeHandlerFunc = () => {
        setOtpMethod(true)
        handleClose()
    }

    return (
        <div>
            <Dialog
                // fullWidth={true}
                maxWidth={"md"}
                open={open}
                className={classes.modal}
                onClose={handleClose}
                // style={{width:"350px"}}
                aria-labelledby="base-station-manage"
            >
                <DialogTitle style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Typography
                        // variant="subtitle2"
                        color="primary"
                        className={classes.title}
                    >
                        TOTP Code
                    </Typography>
                </DialogTitle>

                <form onSubmit={handleSubmit}>
                    <DialogContent>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Typography>
                                Download your preferred “TOTP”<br></br>
                                &nbsp;&nbsp;&nbsp;&nbsp;APP and scan the QR code
                            </Typography>
                        </div>

                        <Box className={classes.card}>
                            <Typography color="primary" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>Example APPs</Typography>
                            <Grid container display="flex" justifyContent="space-around">
                                <Box display="flex" justifyContent="center">
                                    {" "}
                                    <img src={Google} alt="Icon" style={{ height: "58px" }} />{" "}
                                </Box>
                                <Box display="flex" justifyContent="center">
                                    {" "}
                                    <img src={Authy} alt="Icon" style={{ height: "58px" }} />{" "}
                                </Box>

                            </Grid>
                            <Grid container display="flex" justifyContent="space-around" >
                                <Box style={{ fontSize: "10px", marginRight: "10px" }}>
                                    Google Authenticator
                                </Box>
                                <Box style={{ fontSize: "10px", marginRight: "16px" }}>
                                    Twillio Authy
                                </Box>
                            </Grid>
                        </Box>

                        <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "30px" }}>
                            <Typography>
                                Enter the verification code provided by the<br></br>
                                &nbsp;&nbsp;&nbsp;&nbsp;APP to verify the QR code scanned
                            </Typography>
                        </Box>
                        <FormControl fullWidth style={{ marginTop: "20px" }}>
                            <OutlinedInput
                                required
                                name="otp"
                                autoComplete="new-otp"
                                placeholder="Enter verification code"
                                autoFocus
                                type="number"
                                // value={otpNum}
                                // onChange={(e) => setOtpNum(e.target.value)}
                                // error={!!error.email}
                                // helperText={error.email ? error.email : null}
                                // onFocus={() => { setError(false) }}
                                startAdornment={<InputAdornment position="start">
                                    <LockIcon htmlColor={"#00000033"} />
                                </InputAdornment>
                                }
                            />
                        </FormControl>

                    </DialogContent>

                    <div className={classes.actionButtons}>
                        <MROButton
                            // color="primary"
                            disabled={false}
                            type="button"
                            variant={"contained"}
                            onClick={closeHandlerFunc}
                        >
                            Cancel
                        </MROButton>
                        <MROButton
                            loading={busy === "loading"}
                            // color="secondary"
                            style={{ backgroundColor: "#4FC605", color: "#fff" }}
                            disabled={false}
                            type="submit"
                            // onClick={doneCloseHandler}
                            variant={"contained"}
                        >
                            Done
                        </MROButton>
                    </div>
                </form>
            </Dialog>
        </div >
    );
}
