import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";

import propTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function RightDialog(props) {
  const { children, open, dialogCloseHandler } = props;

  const classes = useStyles();

  const handleClose = () => {
    dialogCloseHandler();
  };

  return (
    <div>
      <Dialog
        fullWidth
        fullScreen
        style={{ marginLeft: "auto", width: "403px" }}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <div style={{ width: "100%", height: "100%" }}>{children}</div>
      </Dialog>
    </div>
  );
}

RightDialog.propTypes = {
  children: propTypes.node.isRequired,
  open: propTypes.bool.isRequired,
  dialogCloseHandler: propTypes.func.isRequired,
  dialogOpenHandler: propTypes.func.isRequired,
};

export default RightDialog;
