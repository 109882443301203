// import request from "../../common/api_client";
import requestApi from "../../common/boxp_api_client";
import requestrApi from "../../common/boxr_api_client";

function getUsersList(params) {
    return requestrApi({
        url: '/users',
        method: 'GET',
        params: params
    });
}

function createUser(formData) {
    return requestrApi({
        url: '/users',
        method: 'POST',
        data: formData
    })
}

function getUserDependencies(params) {
    return requestrApi({
        url: '/users/fetch_user_dependencies',
        method: 'GET',
        params: params
    })
}

function updateUserDetails(formData, id) {
    return requestrApi({
        url: `/users/${id}`,
        method: 'PUT',
        data: formData
    })
}

function getUserDetails(id) {
    return requestrApi({
        url: `/users/${id}`,
        method: 'GET',
    })
}

function getUserStatistics() {
    return requestrApi({
        url: '/users/users_stats',
        method: 'GET',
    })
}

function getUserMe(userId) {
    return requestApi({
        url: `get_user_compliance_status_bar?user_id=${userId}`,
        method: 'GET'
    })
}

function getLimeManagerUsers(params) {
    return requestrApi({
        url: `/users`,
        method: 'GET',
        params: params
    })
}
function getLineManagerUsers(params) {
    return requestApi({
        url: `/list_user_compliance_status_bar`,
        method: 'GET',
        params: params
    })
}
function updateUserGroupAndBases(params) {
    return requestApi({
        url: `/update_user_group_base_station_users?_method=PUT`,
        method: 'POST',
        params
    })
}
const UserService = {
    getLineManagerUsers, getUsersList, createUser, getUserDependencies, updateUserDetails, getUserDetails, getUserStatistics,
    getUserMe, getLimeManagerUsers,
    updateUserGroupAndBases
};

export default UserService;