import requestrApi from "./common/boxr_api_client";

const deletionUrl = {
    training: '/trainings/destroy_training',
    competency: '/competencies/destroy_competency',
    storageCondition: '/storage_conditions',
    procedureCategories: '/procedure_categories',
    aogSMS: '/line_customers/delete_aog_sms_contacts',
    kpiEmail: '/line_customers/delete_kpi_emails',
    reportEmail: '/line_customers/delete_report_emails',
    approvalType: '/approval_types/destroy_approval_types',
    companyApprovals: '/company_approvals/destroy_company_approval',
    approvalTypeGroup: '/group_types/destroy_group_type',
    authorisation: '/auth_lists/destroy_auth_list',
    taskLibrary: '/task_libraries/destroy_task_library',
    vehicles: '/vehicles/destroy_vehicle',
    competencyCategory: '/competencies/destroy_category',
    competencyQuestion: 'competencies/destroy_question',
    handoverCheck: '/handovers/destroy_setup',
    delayCodes: '/line_customers/delete_delay_codes',
    authList: '/auth_lists/destroy_changes',
    fixedPriceList: '/line_customers/delete_fixed_price_lists',
    airCraftTypeGroup: '/line_customers/delete_aircraft_type_groups',
    vendorParts: '/vendor_parts/delete_vendor_part',
    operation_workorder_tooling: 'operation_fields/delete_tooling',
    operationalTasks: '/operational_tasks/delete_task',
    destroyWorkOrder: '/work_orders/destroy_work_order',
    destroyDoc: '/operation_fields/destroy_doc',
    currencies: '/currencies',
    removeCertificates:"/primary_parts/remove_certificate",
    deleteAdhocFormAttachment: '/adhoc_forms/delete_adhoc_mr_attachment',
    deletePartRequests: 'part_requests/destroy_request',
    destroyRequest: 'part_requests/destroy_request',
    deleteContact: "customer/contact",
    deleteEmail: "customer/report_emails",
    deleteCheckRate: "/check_rates/destroy_check_rate",
    destroyCallOuts: "/call_outs/destroy_call_out",
    destroyDelay: "/destroy_delay",
    deleteToolRate:"/tooling_rates/delete_tooling_rate"
}

function deleteData(id, section, data, params) {
    let url = params ? `${deletionUrl[section]}${params ? '?id=' + id : id || ''}`
        : `${deletionUrl[section]}/${id || ''}`
    return requestrApi({
        url: url,
        method: 'DELETE',
        data: id ? '' : data
    })
}
function AuthDestroyChanges(section) {
    let url = deletionUrl[section];
    return requestrApi({
        url: url,
        method: 'DELETE',
    })
}

function deleteTheAttachment(section,formData) {
    let url = deletionUrl[section];
    return requestrApi({
        url: url,
        method: 'DELETE',
        data:formData
    })
}


export default deleteData;
export { AuthDestroyChanges };
export { deleteTheAttachment };