import React, { useEffect, useState } from "react";
import MROButton from "../buttons";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import FileIcon from "@material-ui/icons/AttachFile";
import { ReactComponent as UploadIcon } from "../../assets/icons/UploadDoc.svg";
import DataService from "../../lib/services/api";
import { Box, IconButton, Tooltip } from "@material-ui/core";
import { RemoveCircle, RemoveCircleOutline } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    input: {
        display: 'none',
    },
    fileWrapper: {
        position: 'relative'
    },
    Tooltip: {
        fontSize: theme.spacing(2)
    }
}));


function UploadMultipleButton(props) {
    const classes = useStyles();
    const { accept, name, action, handleRemove, multiple, handleImageChange, file: defaultValue, error, process, id, required } = props;
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);

    const fileList = Array.from(files[0] || []);

    useEffect(() => {
        if (process && files && files[0] && id) {
            console.log(process, files, files[0].name)
            setLoading(true);
            const formData = new FormData();
            formData.append(name, files[0]);
            formData.append('id', id);
            DataService.updateData(formData, "", 'authDoc').then((res) => {
                setLoading(false);
            })
        }
    }, [process])

    const handleChange = (e) => {
        setFiles(multiple ? [...files, e.target.files] : e.target.files);
        handleImageChange && handleImageChange(multiple ? [...files, e.target.files] : e.target.files);
    }

    const removeFile = (index) => () => {
        let _files = fileList.filter((file, i) => i !== index)
        setFiles(_files);
        if (_files.length === 0) {
            handleImageChange && handleImageChange([])
        }
    }

    return (
        <div>
            <input
                accept={accept || "*"}
                className={classes.input}
                id="upload-file"
                multiple={multiple || false}
                type="file"
                name={name}
                onChange={handleChange}
            />
            <label htmlFor="upload-file" style={{pointerEvents: !props?.permission?.write && 'none'}}>
                <MROButton variant="contained" color="secondary"  disabled={!props?.permission?.write} component="span" startIcon={<UploadIcon />}>
                    {action ? action : "Upload"}
                </MROButton>
            </label>
            <div style={{ padding: '15px 10px 5px 10px' }}>
                {defaultValue && defaultValue.length > 0 && <Typography variant={"subtitle2"} color="secondary">Existing Files:</Typography>}
                {defaultValue && defaultValue.map((file, i) => <div className={classes.fileWrapper} key={`file-${i}`}>
                    {(file.name || file.filename) ?

                        <Typography style={{ textDecoration: 'none', color: 'inherit' }} component={"a"} href={file.url ? file.url : file.file || "#"} target={(file.url || file.file) ? "_blank" : '_blank'} className={"card-tile-secondary"}><FileIcon />&nbsp;&nbsp;{file.name || file.filename}</Typography> : "No Files found"}
                    <Box position="absolute" right={-40} top={5}>
                        <Tooltip arrow title={`Remove ${file ? file.filename : ''}`} className={classes.Tooltip}>
                            <IconButton onClick={handleRemove(file.id)}>
                                <RemoveCircleOutline fontSize="default" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </div>)}
                {
                    error && required && fileList.length <= 0 &&
                    <Typography color="error" variant="subtitle2">{error}</Typography>
                }
                {fileList && fileList.length > 0 && <Typography variant={"subtitle2"} color="secondary">Chosen Files(new):</Typography>}
                {fileList && fileList.map((file, index) => <div className={classes.fileWrapper} key={index}>
                    <Typography component={"div"}
                        title={file?.name}
                        className={"card-tile-secondary"}><FileIcon />&nbsp;&nbsp;{file ? file?.name : ''}</Typography>
                        <Box position="absolute" right={-40} top={5}>
                        <Tooltip arrow title={`Remove ${file ? file?.name : ''}`} className={classes.Tooltip}>
                            <IconButton onClick={removeFile(index)}>
                                <RemoveCircleOutline fontSize="default" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </div>)}
            </div>
        </div>
    )
}

export default UploadMultipleButton;