import React, { useContext, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import MRODialog from "components/MRODialog";
import ServiceRequestListClientServices from "lib/services/api/procurement/Services";
import { ToastMessageContext } from "lib/contexts/message_context";

function WarningDialog(props) {
  // const message = useContext(ToastMessageContext)
  const { action, handleClose, title, description,titleColor, handleConfirm, hideYesButton, btnTitle} = props;
  const [busy, setBusy] = useState(null)

  return (
    <MRODialog
      open={action}
      width="599px"
      isLoading={busy === "loading"}
      handleClose={handleClose}
      padding="29px 30px 27px 25px"
      fromLeft="450px"
      handleSubmit={handleConfirm}
      centerActionBtns
      hideYesButton={hideYesButton}
      btnTitle={btnTitle}
    >
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography color={"primary"??titleColor} style={{color: titleColor === "warning" && "#F68A1C"}} variant="h5">
          {title}
        </Typography>

        <Typography variant="body1" style={{ marginTop: 33, marginBottom: 10 }}>
          {description}
        </Typography>
      </Box>
    </MRODialog>
  );
}

export default WarningDialog;
