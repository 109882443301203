import requestApi from "./common/boxp_api_client";

const dataUrl = {
    allContact: "/customer/contact/all",
    reportEmailWorkshop: "/customer/report_emails/all",
    addresses: "/customer/address/all",
    storageRates: "/customer/storage_rates/all",
    commercialRates:"/line_customers/create_commercial_rates",
    fetchQuotation:"/quotation",
    OrderList:"/maintenace_order_list",
    inductionList: "/induction",
    allTaskLibrary: "/task_library",
    allActions: "/action_imp",
    allActionComment: "/action_comment",
    getAllCustomer: "/customer/all",
    actionListings: "/action_overview",
    getAllUsageList: "/procurement_usage_list",
    usageThreshold: "/usage_threshold_get",
    partHistory: "/get_part_history"
}

export function getData(params, section) {
    return requestApi({
        url: dataUrl[section],
        method: 'GET',
        params: params,
        paramsSerializer: (params) => {
            // Sample implementation of query string building
            let result = '';
            Object.keys(params).forEach(key => {
                if (params[key] != null) {
                    if(key== "tasks[]" || key == "consumables[]"){
                        const tasksVal=params[key];
                        tasksVal.map(function(eachTask){
                            result += `${key}=${encodeURIComponent(eachTask)}&`;
                        })
                    }
                    else{
                        result += `${key}=${encodeURIComponent(params[key])}&`;
                    }
                   
                }
            });
            return result.substr(0, result.length - 1);
        }
    })
}

const BoxpListingService = {
    getData
}


export default BoxpListingService;
