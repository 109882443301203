import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Library from "./Library";
import CreateNewStage from "./CreateNewStage";
import MROButton from "components/buttons";
import { ButtonGroup } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  root: {},
  selected1: {
    background: theme.palette.secondary.main,
    color: "white",
  },
  label1: {
    textTransform: "none",
  },
  wrapper: {
    padding: "2%",
    background: "white",
  },
}));

export default function TabsWrappedLabel(props) {
  const classes = useStyles();
  const [state, setState] = useState("library");

  return (
    <div className={classes.wrapper}>
    <div className={classes.root}>
      <Box mt="25px" mb="25px">
        <ButtonGroup color="primary">
          <MROButton
            variant={state === "createNew" ? "contained" : "outlined"}
            onClick={() => setState("createNew")}
            style={{
              width: "125px",
              color: state === "createNew" ? "white" : undefined,
            }}
          >
            Create New
          </MROButton>
          <MROButton
            variant={state === "library" ? "contained" : "outlined"}
            onClick={() => setState("library")}
            style={{
              width: "125px",
              color: state === "library" ? "white" : undefined,
            }}
          >
            Library
          </MROButton>
        </ButtonGroup>
      </Box>

      <Box width="100%">
        {state === "createNew" ? (
          <CreateNewStage
            handleClose={props.handleClose}
            currentSelection={props.currentSelection}
            action_Id={props.action_Id}
            action = "Add"
            getActionStages={props.getActionStages}
          />
        ) : (
          <Library
            handleClose={() => {}}
            handleCloseDrawer={props.handleClose}
            currentSelection={props.currentSelection}
            action_Id={props.action_Id}
          />
        )}
      </Box>
      </div>
    </div>
  );
}
