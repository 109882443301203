import { Typography } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { useMemo } from 'react'
import ReactApexChart from 'react-apexcharts'


const Chart = (props) => {
    const { value1, value2, value3, name, color1, color2, color3, labels, totalPercentage } = props
    const [values, setValues] = useState([])
    const [label, setlabel] = useState([])
    const [orgColor, setOrgColor] = useState([])

    useMemo(() => {
        if (value1 !== undefined) {
            values.push(value1)
        }
        if (value2 !== undefined) {
            values.push(value2)
        }
        if (value3 !== undefined) {
            values.push(value3)
        }
        if (color1 !== undefined) {
            orgColor.push(color1)
        }
        if (color2 !== undefined) {
            orgColor.push(color2)
        }
        if (color3 !== undefined) {
            orgColor.push(color3)
        }
        setValues(values)
        setOrgColor(orgColor)
    }, [value1, value2, value3, color1, color2, color3])

    // console.log("Dasboardprops=========", props)
    useMemo(() => {
        if (labels !== undefined) {
            // console.log("label=======", labels)
            for (let i = 0;i < labels.length;i++) {
                label.push(labels[i])
            }
        }
        setlabel(label)
    }, [labels])
    const [state, setState] = useState({
        series: values,
        options: {
            chart: {
                // size: '100px',
                type: 'donut',
            },
            plotOptions: {
                pie: {
                    donut: {
                        size: '75%'
                    }

                    // customScale: 0.8
                }
            },

            labels: label,
            dataLabels: {

                formatter(val, opts) {
                    const name = opts.w.globals.labels[opts.seriesIndex]
                    return [name, opts.w.config.series[opts.seriesIndex]]
                },
            },

            colors: orgColor,
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false,
                position: 'bottom'
            },

            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        show: false,
                        position: 'bottom'
                    }
                }
            }]
        }
    })

    const [totalState, setTotalState] = useState({
        series: [1, 0, 0],
        options: {
            chart: {
                size: '100px',
                type: 'donut',
            },
            plotOptions: {
                pie: {
                    donut: {
                      size: '75%'
                    }

                    // customScale: 0.8
                }
            },
            labels: ['0', '0', '0'],
            colors: ['#dddddd', '#dddddd', '#dddddd'],
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false,
                position: 'bottom'
            },
            tooltip: {

                enabled: false
            },

            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        show: false,
                        position: 'bottom'
                    }
                }
            }]
        }
    })

    return (
        <>
            <ReactApexChart options={(value1 == 0 && value2 == 0) ? totalState.options : state.options} series={(value1 == 0 && value2 === 0 && value3 == 0) || (value1 == 0 && value2 == 0) ? totalState.series : state.series} type="donut" height={name === 'Parts' ? 120 : 200} />
        </>
    )
}
export default React.memo(Chart)