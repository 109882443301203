import React, { useContext, useEffect, useState } from "react";
import { ButtonGroup } from "@material-ui/core";
import { Button } from "@material-ui/core";
import MROTextField from "./form_components/TextField";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { makeStyles } from "@material-ui/core";
import { ToastMessageContext } from "lib/contexts/message_context";

const useStyles = makeStyles(() => ({
  disableField: {
    pointerEvents: "none",
    backgroundColor: "#F5F5F5",
  },
}));

function QuantityCounter(props) {
  const classes = useStyles();
  const {
    name,
    disabled,
    label,
    placeholder,
    required,
    type,
    autoFocus,
    error,
    helperText,
    onChange,
    value,
    qty,
    setQty,
    style,
    setError,
    minQty,
    number,
    shouldNotAcceptZero,
    step,
    marginR
  } = props;

  const message = useContext(ToastMessageContext);

  const [minimumQty, setMinimumQty] = useState(0);

  useEffect(() => {
    if (minQty) {
      setMinimumQty(minQty)
    }
  }, [minQty])

  useEffect(() => {
    if (shouldNotAcceptZero && qty === 0) {
      message.showToastMessage({
        open: true,
        message: `${label.replace("*", "")} must be greater than 0`,
        variant: "error",
      });
    }
  }, [shouldNotAcceptZero, label, qty])

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <MROTextField
        name={name}
        label={label}
        placeholder={placeholder}
        required={required}
        // disabled={disabled}
        type={number ?? ""}
        InputProps={{
          className: disabled && classes.disableField,
        }}
        autoFocus={autoFocus}
        error={error}
        helperText={helperText}
        onChange={(e) => {
          typeof setError === "function" && setError();
          setQty(parseInt(e.target.value.replace(/[A-Za-z]/g, "")));
        }}
        InputLabelProps={{ shrink: true }}
        value={isNaN(qty) ? "" : qty}
        fullWidth={true}
        style={{ marginRight: marginR ? marginR : "15px", ...style }}
      />

      <ButtonGroup color="primary" aria-label="outlined primary button group">
        <Button
          className={disabled && classes.disableField}
          // disabled={disabled}
          onClick={() => {
            typeof setError === "function" && setError();
            typeof setQty === "function" && setQty((prevState) => (prevState > minimumQty ? step ? prevState - 0.5 : prevState - 1 : prevState));
          }}
          style={{ width: "46px", height: "36px" }}
        >
          <RemoveIcon />
        </Button>
        <Button
          className={disabled && classes.disableField}
          // disabled={disabled}
          onClick={() => {
            typeof setError === "function" && setError();
            typeof setQty === "function" && setQty((prevState) => (isNaN(prevState) ? 1 : step ? prevState + 0.5 : prevState + 1));
          }}
          style={{ width: "46px", height: "36px" }}
        >
          <AddIcon />
        </Button>
      </ButtonGroup>
    </div>
  );
}

export default QuantityCounter;
