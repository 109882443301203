import React, { useState, useEffect, useContext } from "react";
import MROFullScreenRightDrawer from "components/fullwidth_dialog";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import Typography from "@material-ui/core/Typography";
import MROTextField from "components/form_components/TextField";
import FileUpload from "components/form_components/fileUpload";
import QuantityCounter from "components/quantity_counter";
import MROButton from "components/buttons";
import PositiveSwitch from "components/form_components/switch";
import { getOrganisationId } from "lib/utils/common_utils";
import { CalenderMaintenanceService } from "lib/services/api/operaitons/WorkShop/Storage/CalenderMaintenanceService";
import { MenuItem, FormControlLabel } from "@material-ui/core";
import DateField from "views/Accounts/Workshop/Maintenance/ManageInvoice/DateField";
import { validateFormData } from "lib/utils/helperFunctions";
import { ToastMessageContext } from "lib/contexts/message_context";
import NumericIncrement from "views/Operations/LineMaintenance/WorkOrder/FieldComponents/numeric_increment";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: "4%",
    // backgroundColor: "#F7F8FA",
  },
  appBar: {
    backgroundColor: "#fff",
    color: "#000000CC",
    boxShadow: "0px 3px 6px #0000001A",
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  actionButtons: {
    paddingTop: "20px",
    paddingBottom: "33px",
    textAlign: "right",
    marginRight: "20px",
    "& button:not(:last-child)": {
      marginRight: "20px",
    },
  },
  content: {
    margin: "3%",
    // backgroundColor: "#F7F8FA",
    // padding: "3% 6%",
    borderRadius: "0 0 5px 5px",
    minHeight: "fit-content",
  },
  inputField: {
    maxWidth: "328px",
  },
  input1: {
    height: 200,
    padding: 20,
  },
  input2: {
    height: 200,
    fontSize: "3em",
  },
}));

function CalendarActionDetails(props) {
  const classes = useStyles();
  const { handleClose, action, workshop_id, action_type, getAllActions, actionData, selectedId } = props;
  const [files, setFiles] = useState([]);
  const [errors, setErrors] = useState({});
  const [busy, setBusy] = useState(null);
  const [ataChapter, setAtaChapter] = useState(null);
  const [dropDownData, setDropdownData] = useState([]);
  const [mechAuth, setMechAuth] = useState(null);
  const [inspAuth, setInspAuth] = useState(null);
  const [state, setState] = useState(null);
  const [qty, setQty] = useState(null);
  const [maintFile, setMaintFile] = useState([]);
  const [show, setShow] = useState(false);
  const [showFrequency, setShowFrequency] = useState(true);
  const message = useContext(ToastMessageContext);
  const [states, setStates] = useState({ man_hours: '' });
  //create Api

  const handleFrequency = (e) => {
    // console.log("events=====", e.target.checked)
    if (e.target.checked === false) {
      setState({
        ...state,
        man_hours: '',
      })
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    let valid = true;

    let error = {
      title: false,
      ata_chapter_id: false,
      mech_auth_id: false,
      insp_auth_id: false,
      tech_data_ref: false,
      last_completed: false,
      calendar_frequency: false,
      camo_records: false,
      description: false,
      estimate_man_hours: false,
      repeat_events: false
    };
    if (formData && formData.get("title")?.length === 0) {
      error.title = true;
      valid = false;
    }
    if (formData && formData.get("ata_chapter_id")?.length === 0) {
      error.ata_chapter_id = true;
      valid = false;
    }
    if (formData && formData.get("mech_auth_id")?.length === 0) {
      error.mech_auth_id = true;
      valid = false;
    }
    if (formData && formData.get("insp_auth_id")?.length === 0) {
      error.insp_auth_id = true;
      valid = false;
    }
    if (formData && formData.get("tech_data_ref")?.length === 0) {
      error.tech_data_ref = true;
      valid = false;
    }

    if (formData && formData.get("last_completed")?.length === 0) {
      error.last_completed = true;
      valid = false;
    }
    if (formData && formData.get("camo_records") === " ") {
      error.camo_records = true;
      valid = false;
    }
    if (formData && formData.get("description")?.length === 0) {
      error.description = true;
      valid = false;
    }
    if (showFrequency && formData.get("calendar_frequency")?.length === 0) {
      error.calendar_frequency = true;
      valid = false;
    }
    if (formData && formData.get("estimate_man_hours")?.length === 0) {
      error.estimate_man_hours = true;
      valid = false;
    }
    if (!valid) {

      setErrors(error);
      return;
    }
    formData.append("workshop_order_id", workshop_id);
    formData.append("action_type", action_type);

    let userDetails = JSON.parse(sessionStorage.getItem("user"));
    formData.append("raised_by", userDetails?.id);
    if (show === false) {
      formData.append("camo_records", show)

    }
    if (showFrequency === false) {
      formData.append("repeat_events", showFrequency)
    }
    if (action === "createCalendar") {
      // formData.delete("maintenance_file");
      maintFile.forEach((file) => (formData.append("maintenance_file[]", file)))
      setBusy("loading");
      await CalenderMaintenanceService.createAction(formData).then((response) => {

        if (response.success) {
          handleClose()
          getAllActions()
          message.showToastMessage({
            message: "Actions created successfully!",
            variant: "success",
          });

        }
        else {
          message.showToastMessage({
            message: "Something went wrong. Try again!",
            variant: "error",
          });
        }
      }).catch((error) => {
        message.showToastMessage({
          message: "Something went wrong. Try again!",
          variant: "error",
        });
      })
    }
    // if (action === "Edit Calendar Action") {

    // }
  }

  useEffect(() => {
    getDropdownData();

  }, []);

  const resetErrorsHandler = (name) => {
    setErrors((e) => ({ ...e, [name]: false }));
  };

  const getDropdownData = async () => {
    const params = {
      organisation_id: getOrganisationId()
    }
    await CalenderMaintenanceService.getAllDropDown(params)
      .then((res) => {
        if (res) {
          setDropdownData(res.data);
        }
      })
      .catch(() => {
        message.showToastMessage({
          message: "Something went wrong. Try again!",
          variant: "error",
        });
      });
  };

  useEffect(() => {
    setShow(show)
  }, [show]);



  return (
    <div>
      <MROFullScreenRightDrawer open={true}>
        <AppBar position="static" className={classes.appBar} elevation={0}>
          <Toolbar>
            <IconButton
              onClick={() => {
                handleClose()
                getAllActions()
              }}
              edge="start"
              className={classes.backButton}
              color="inherit"
              aria-label="back"
            >
              <BackIcon />
            </IconButton>
            <Typography variant="subtitle2">
              Add Calendar Action
            </Typography>
          </Toolbar>
        </AppBar>
        <form noValidate onSubmit={(e) => {
          handleSubmit(e)
          // console.log("Addd clicked=======")
        }}>
          <Grid container xs={12} spacing={1}>
            <Grid item xs={6}>
              <div className={classes.wrapper}>
                <Grid>
                  <Typography
                    color="primary"
                    varient="h3"
                    style={{ marginBottom: "10px" }}
                  >
                    Action Details
                  </Typography>
                </Grid>

                <Grid item xs={10}>
                  <MROTextField
                    name={"title"}
                    label={"Action & Stage Title *"}
                    placeholder={"Action & Stage Title - Max 50 Characters"}
                    // requird
                    InputLabelProps={{ shrink: true }}
                    error={errors.title}
                    onChange={(e) => {
                      resetErrorsHandler(e.target.name);

                    }}
                  ></MROTextField>
                </Grid>
                <Grid item xs={6}>
                  <MROTextField
                    name={"ata_chapter_id"}
                    label={"ATA Chapter *"}
                    placeholder={"Select"}
                    value={ataChapter}
                    error={errors.ata_chapter_id}
                    select
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => {
                      resetErrorsHandler(e.target.name);
                      setAtaChapter(e.target.value);
                    }}
                  >
                    {dropDownData?.ata_chapters?.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.chapter_number}
                      </MenuItem>
                    ))}
                  </MROTextField>
                </Grid>
                <Grid item xs={6}>
                  <MROTextField
                    name={"mech_auth_id"}
                    label={"Mech Auth *"}
                    error={errors.mech_auth_id}
                    placeholder={"Select"}
                    select
                    // requird
                    InputLabelProps={{ shrink: true }}
                    value={mechAuth}
                    onChange={(e) => {
                      resetErrorsHandler(e.target.name);
                      setMechAuth(e.target.value);
                    }}
                  >
                    {dropDownData?.mech_insp_auth?.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option?.type_name}
                      </MenuItem>
                    ))}
                  </MROTextField>
                </Grid>
                <Grid item xs={6}>
                  <MROTextField
                    name={"insp_auth_id"}
                    label={"Insp Auth *"}
                    placeholder={"Select"}
                    select
                    // required
                    InputLabelProps={{ shrink: true }}
                    value={inspAuth}
                    error={errors.insp_auth_id}
                    onChange={(e) => {
                      resetErrorsHandler(e.target.name);
                      setInspAuth(e.target.value);
                    }}
                  >
                    {dropDownData?.mech_insp_auth?.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option?.type_name}
                      </MenuItem>
                    ))}
                  </MROTextField>
                </Grid>
                <Grid item xs={6}>
                  <NumericIncrement
                    name="estimate_man_hours"
                    label="Estimate Man-Hrs *"
                    error={errors.estimate_man_hours}
                    value={states.man_hours}
                    placeholder={"Man-Hours required"}
                    // onChange={handleChange}
                    noIncreament
                    state={states}
                    setState={setStates}
                    inputProps={{ min: 0, step: 0.5 }}
                    InputLabelProps={{ shrink: true }}
                    setError={() => resetErrorsHandler("estimate_man_hours")}
                    helperText={
                      errors["estimate_man_hours"] &&
                      `Please enter estimate man hours`
                    }
                  />

                </Grid>

                <Grid item xs={6}>
                  <MROTextField
                    name={"tech_data_ref"}
                    label={"Tech Data Ref *"}
                    placeholder={"Enter tech data reference for the task"}
                    // required
                    InputLabelProps={{ shrink: true }}
                    error={errors.tech_data_ref}
                    onChange={(e) => {
                      resetErrorsHandler(e.target.name);
                    }}
                  ></MROTextField>
                </Grid>
                <Grid item xs={6}>
                  <DateField
                    required
                    name="last_completed"
                    label={"Due Date *"}
                    InputLabelProps={{ shrink: true }}
                    error={errors.last_completed}
                    helperText={errors.last_completed ? "*please enter a date" : null}
                    onChange={(e) => { setErrors({ ...errors, last_completed: false }); }}


                  />
                </Grid>
                <Grid>
                  <FormControlLabel
                    style={{ marginLeft: '3px' }}
                    control={
                      <PositiveSwitch
                        checked={showFrequency}
                        value={showFrequency}
                        onChange={(e) => {
                          setShowFrequency(e.target.checked)
                          handleFrequency(e)

                        }
                        }
                        error={errors.repeat_events}
                        setError={() => resetErrorsHandler("repeat_events")}

                      />
                    }

                    label="Frequency based repeating event? "
                    labelPlacement="start"
                    name="repeat_events"
                  />
                </Grid>
                {showFrequency &&
                  <Grid item xs={6}>
                    <QuantityCounter
                      name={"calendar_frequency"}
                      label={"Frequency (days) "}
                      placeholder={"Repeat in days"}
                      InputLabelProps={{ shrink: true }}
                      setQty={setState}
                      qty={state}
                      shouldNotAcceptZero={true}
                      autoFocus={errors.calendar_frequency}
                      error={errors.calendar_frequency}
                      helperText={
                        errors["calendar_frequency"] ?
                          `Please enter days` : ""
                      }
                      setError={() =>
                        setErrors((prevState) => ({
                          ...prevState,
                          calendar_frequency: false,
                        }))
                      }
                    />
                  </Grid>
                }

                <FormControlLabel
                  style={{ marginLeft: '3px' }}
                  control={
                    <PositiveSwitch
                      checked={show}
                      value={show}
                      onChange={(e) => {
                        setShow(e.target.checked)

                      }
                      }
                      error={errors.camo_records}
                      setError={() => resetErrorsHandler("camo_records")}

                    />
                  }

                  label="Operator / CAMO Part M technical records applicable each time?"
                  labelPlacement="start"
                  name="camo_records"
                />

                <Grid>
                  <Typography variant="body1"
                    color="primary"
                    className={classes.title}>Tech Data</Typography>
                  <br />
                  <FileUpload
                    showBorderOnNoFiles={true}
                    setFiles={(files) =>
                      setMaintFile(files)
                    }
                    APIUrl={""}
                    multiple={true}
                    name={"maintenance_file[]"}
                    action={"Upload Document"}
                  />
                </Grid>
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className={classes.content}>

                <Typography
                  variant="body1"
                  color="primary"
                  className={classes.title}
                ></Typography>{" "}
                <Grid
                  item
                  container
                  justify="space-between"
                  alignItems="center"
                >
                  <Typography
                    color="primary"
                    varient="h3"
                  //   style={{ fontWeight: "bold" }}
                  >
                    Event Details
                  </Typography>
                </Grid>
                <Grid
                  container
                  // spacing={1}
                  alignItems={"center"}
                  justify={"flex-start"}
                >
                  <Grid container justify={"flex-start"} style={{ paddingRight: '15px' }}>
                    <Grid item xs={12}>
                      <MROTextField
                        name={"description"}
                        label={"Detailed Description *"}
                        placeholder="Detailed Event Description - Max 2000 Characters"
                        InputProps={{ style: { height: 200 } }}
                        InputLabelProps={{ shrink: true }}
                        multiline
                        className={classes.description}
                        error={errors.description}
                        onChange={(e) => {
                          resetErrorsHandler(e.target.name);

                        }}
                      />
                    </Grid>
                  </Grid>

                  <>
                    <Grid item container style={{ marginTop: "25px" }}>
                      <Typography
                        varient="h3"
                        color="primary"
                      //   style={{ fontWeight: "bold" }}
                      >
                        Sub-Fields
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography>
                        Upto 10 Sub Fields can be used to capture text E.g.
                        torque values, temp readings etc.
                      </Typography>
                    </Grid>

                    <Grid container spacing={2} item xs={12}>
                      <Grid item xs={6}>
                        <MROTextField
                          name={"field_name[0]"}
                          label={"Sub-Field 1"}
                          placeholder="Description - Max 50 Characters"
                          InputLabelProps={{ shrink: true }}
                          className={classes.inputField}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <MROTextField
                          name={"field_name[5]"}
                          label={"Sub-Field 6"}
                          placeholder="Description - Max 50 Characters"
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ classes: { input: classes.input1 } }}
                          className={classes.inputField}
                        ></MROTextField>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} item xs={12}>
                      <Grid item xs={6}>
                        <MROTextField
                          name={"field_name[1]"}
                          label={"Sub-Field 2"}
                          placeholder="Description - Max 50 Characters"
                          InputLabelProps={{ shrink: true }}
                          className={classes.inputField}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <MROTextField
                          name={"field_name[6]"}
                          label={"Sub-Field 7"}
                          placeholder="Description - Max 50 Characters"
                          InputLabelProps={{ shrink: true }}
                          className={classes.inputField}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} item xs={12}>
                      <Grid item xs={6}>
                        <MROTextField
                          name={"field_name[2]"}
                          label={"Sub-Field 3"}
                          placeholder="Description - Max 50 Characters"
                          InputLabelProps={{ shrink: true }}
                          className={classes.inputField}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <MROTextField
                          name={"field_name[7]"}
                          label={"Sub-Field 8"}
                          placeholder="Description - Max 50 Characters"
                          InputLabelProps={{ shrink: true }}
                          className={classes.inputField}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} item xs={12}>
                      <Grid item xs={6}>
                        <MROTextField
                          name={"field_name[3]"}
                          label={"Sub-Field 4"}
                          placeholder="Description - Max 50 Characters"
                          InputLabelProps={{ shrink: true }}
                          className={classes.inputField}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <MROTextField
                          name={"field_name[8]"}
                          label={"Sub-Field 9"}
                          placeholder="Description - Max 50 Characters"
                          InputLabelProps={{ shrink: true }}
                          className={classes.inputField}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} item xs={12}>
                      <Grid item xs={6}>
                        <MROTextField
                          name={"field_name[4]"}
                          label={"Sub-Field 5"}
                          placeholder="Description - Max 50 Characters"
                          InputLabelProps={{ shrink: true }}
                          className={classes.inputField}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <MROTextField
                          name={"field_name[9]"}
                          label={"Sub-Field 10"}
                          placeholder="Description - Max 50 Characters"
                          InputLabelProps={{ shrink: true }}
                          className={classes.inputField}
                        />
                      </Grid>
                    </Grid>
                  </>
                </Grid>
                <div className={classes.actionButtons}>
                  <MROButton variant={"contained"} onClick={handleClose}>Cancel</MROButton>
                  <MROButton variant={"contained"} color={"primary"}

                    type="submit"
                  >
                    Add
                  </MROButton>
                </div>

              </div>
            </Grid>
          </Grid>

        </form>
      </MROFullScreenRightDrawer>
    </div>
  );
}

export default CalendarActionDetails;
