import React, { useReducer, useEffect, useState, useContext } from 'react'
import { Grid, Paper, Typography, Box, Button } from '@material-ui/core'
import MaterialTable from 'material-table'
import tableIcons, {
  options,
} from '../../../components/universal/table_attributes'

import { formatDate, getOrganisationId } from 'lib/utils/common_utils';
import tableReducer, {
  INITIAL_TABLE_STATE,
} from 'reducers/table_reducer';
import { UserDashboardServices } from 'lib/services/api/dashboard/user_compiance';
import TableAction from 'actions/table_actions'
import { ToastMessageContext } from "lib/contexts/message_context";







export const statusMapButtons = {
  "Green": "button-positive",
  "Amber": "button-warning",
  "Red": "button-negative",
  "Expired": "button-negative",

}
export const statusMapText = { 'Green': 'success', 'Amber': 'warning', 'Red': 'error' };

export default function Regency(props) {

  const { selectedBase } = props
  const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
  const { page, pageSize, inProgress } = tableState;
  const [totalCount, setTotalCount] = useState(10);
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const message = useContext(ToastMessageContext);


  // recency listing
  const getRecencyListingData = async () => {
    setLoading(true)
    const params = {
      organisation_id: getOrganisationId(),
      page: page + 1,
      limit: pageSize,
      base_station_id: selectedBase.join(',')
    }
    await UserDashboardServices.getRecencyListingApi(params).then((response) => {
      setData(response.data)
      setTotalCount(response.total_count)
    }).catch(() => {
      message.showToastMessage({
        message: "Something went wrong",
        variant: "error",
      });
    }).finally(() => {
      setLoading(false)
    })

  }

  useEffect(() => {
    getRecencyListingData()
  }, [page, pageSize, totalCount, selectedBase])

  const tableOptions = {
    ...options,
    page: page,
    total: totalCount,
    pageSize: pageSize,
    search: true,
    toolbar: true,
    headerStyle: {
      paddingTop: "19px",
      paddingBottom: "19px",
      textAlign: "center",
    },
  };
  const columns = [
    {
      title: 'Status',
      field: 'overall_status',
      width: '10%',
      headerStyle: {
        paddingLeft: '45px',
        textAlign: 'left'

      },
      cellStyle: {
        paddingLeft: '20px'
      },
      render: rowData => <Button size="small" className={statusMapButtons[rowData.overall_status]} variant="outlined"><b>{rowData.overall_status === 'Red' ? "Expired" : 'Low'}</b></Button>
    },
    {
      title: 'Stamp #',
      field: 'stamp_number',

      headerStyle: {
        textAlign: 'left',

      },
      cellStyle: {
        textAlign: 'left',
      },

    },
    {
      title: 'User',
      field: 'user_name',

      headerStyle: {
        textAlign: 'left',

      },
      cellStyle: {
        textAlign: 'left',
      },
    },
    {
      title: 'Base',
      field: 'station',
      headerStyle: {
        textAlign: 'left',

      },
      cellStyle: {
        textAlign: 'left',
      },

    },
    {
      title: 'Item',
      field: 'group_name',
      headerStyle: {
        textAlign: 'left',
        width: '30%'

      },
      cellStyle: {
        textAlign: 'left',
        width: '30%'
      },
    },

    {
      title: 'Tasks',
      field: 'task_count',
      headerStyle: {
        textAlign: 'left',

      },
      cellStyle: {
        textAlign: 'left',
      },
      render: rowData => rowData.task_count ? <span>
        <Typography style={{ fontWeight: 600, color: rowData.task_status === "Red" ? '#FF0202' : rowData.task_status === "Amber" ? '#FFB300' : rowData.task_status === "Green" ? "#4FC605" : '' }} >
          {
            rowData.task_count
          }
        </Typography>
      </span > : '-'
    },
    {
      title: 'ATAs',
      field: 'ata_count',
      headerStyle: {
        textAlign: 'left',

      },
      cellStyle: {
        textAlign: 'left',
      },
      render: rowData => rowData.ata_count ? <span>
        <Typography style={{ fontWeight: 600, color: rowData.ata_status === "Red" ? '#FF0202' : rowData.ata_status === "Amber" ? '#FFB300' : rowData.ata_status === "Green" ? "#4FC605" : '' }} >
          {
            rowData.ata_count
          }
        </Typography>
      </span > : '-'
    },
    {
      title: 'Override',
      field: 'recency_expiry',
      headerStyle: {
        textAlign: 'left',

      },
      cellStyle: {
        textAlign: 'left',
      },
      render: rowData => rowData.recency_expiry ? <span style={{ fontWeight: 600, color: rowData.days <= 0 ? "#FF0202" : rowData.days > 30 ? "#4FC605" : "#FFB300" }}>
        {formatDate(rowData.recency_expiry)}
      </span> : '-'
    },
    {
      title: 'Days',
      field: 'days',
      headerStyle: {
        textAlign: 'left',

      },
      cellStyle: {
        textAlign: 'left',
      },
      render: rowData => rowData.days ?
        <span style={{ fontWeight: 600, color: rowData.days <= 0 ? "#FF0202" : rowData.days > 30 ? "#4FC605" : "#FFB300" }}>
          {rowData.days}
        </span> : "-"
    }

  ]
  return (
    <>
      <Grid xs={12} md={12}>
        <Box p={1}>
          <MaterialTable
            style={{ boxShadow: '0px 1px 3px #00000033', paddingRight: '0px' }}
            icons={tableIcons}
            title={<Typography color="primary" variant="subtitle2">
              Recency
            </Typography>}
            data={data || []}
            columns={columns}
            options={tableOptions}
            isLoading={loading}
            onChangePage={(page) => {
              TableAction.setPage(dispatch, page);
            }}
            onChangeRowsPerPage={(pageSize) => {
              TableAction.setPageSize(dispatch, pageSize);
            }}
            onSearchChange={(search) => {
              TableAction.setSearchText(dispatch, search);
            }}
            totalCount={totalCount}
            page={page}
          />
        </Box>
      </Grid>
    </>
  )
}

