import React, { useContext, useEffect, useState } from 'react'
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import { Box, Dialog, DialogContent, DialogTitle, FormControlLabel, withStyles } from "@material-ui/core";
import MROTextField from "components/form_components/TextField";
import MROButton from 'components/buttons';
import { Typography } from "@material-ui/core";
import { FormControl } from '@material-ui/core';
import moment from 'moment';
import { LineMaintainenceServices } from 'lib/services/api/dashboard/line_maintenance';
import { getOrganisationId } from 'lib/utils/common_utils';
import { ToastMessageContext } from 'lib/contexts/message_context';
import tableIcons, { options } from 'components/universal/table_attributes';
import Checkbox from '@material-ui/core/Checkbox';
import MaterialTable from 'material-table';
import _ from 'lodash';





const GreenCheckbox = withStyles({
    root: {
        color: "#4FC605",
        '&$checked': {
            color: "#4FC605",
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);





const useStyles = makeStyles((theme) => ({
    modal: {
        "& .MuiDialog-paperWidthMd": {
            width: "760px",
            paddingLeft: "24px",
            paddingRight: "14px"
        }
    },

    title: {
        display: "flex",
        justifyContent: "left",
        textAlign: 'left',
        fontSize: "16px",

    },

    actionButtons: {
        paddingTop: "30px",
        paddingBottom: "33px",
        textAlign: "right",
        "& button:not(:last-child)": {
            marginRight: "20px",
        },
    },

}));

export default function ScheduledChecks(props) {

    const classes = useStyles()
    const { handleClose, currentSelection } = props
    const [subSection, setSubSection] = useState({})
    const [loading, setLoading] = useState(false)
    const [busy, setBusy] = useState(false)
    const [data, setData] = useState([])
    const message = useContext(ToastMessageContext)
    useEffect(() => {
        if (currentSelection !== undefined) {
            setLoading(true)
            const params = {
                line_customer_id: currentSelection?.lineCustomerId,
                id: currentSelection?.id
            }

            LineMaintainenceServices.getWorkOrderSection(params).then((res) => {
                let data1 = res?.sections.find((item) => item.section_name === "Maint")


                if (data1) {
                    // setSection(data1)
                    getDetails(data1?.org_section_id)
                }

            }).catch((res) => {
                message.showToastMessage({
                    message: "Something Went Wrong",
                    variant: "error",
                }); message.showToastMessage({
                    message: "Something Went Wrong",
                    variant: "error",
                });
            }).finally(() => {
                setLoading(false)
            })



        }





    }, [currentSelection])

    const getDetails = (org_section_id) => {



        const params = {
            line_customer_id: currentSelection?.lineCustomerId,
            id: currentSelection?.id,
            org_section_id: org_section_id,
            operable_type: "WorkOrder"
        }
        LineMaintainenceServices.getWorkOrderSectionDetails(params).then((res) => {
            let data_new = res?.section_details?.sub_sections.find((item) => item.sub_section_name === "Scheduled Checks")

            if (data_new !== undefined && data_new?.sub_section_fields.length > 0) {
                setSubSection(data_new)
                setData(data_new?.sub_section_fields)
            }
        }).catch(() => {
            message.showToastMessage({
                message: "Something Went Wrong",
                variant: "error",
            });
        })



    }


    // console.log("data===", data)

    const handleSubmit = () => {
        setBusy(true)


        let fields = data.map((item) => {
            let key = item.id
            let value = item.field_value ? item.field_value.toString() : item.field_value
            return { [key]: value }

        })
        var newObj = fields.reduce((a, b) => Object.assign(a, b), {})


        const params = {
            close: false,
            id: currentSelection?.id,

            data: [{
                org_section_id: subSection?.org_section_id,
                fields: [newObj]
            }],
            operable_type: "WorkOrder",
        }


        LineMaintainenceServices.updateWorkOrderSectionDetails(params).then((res) => {
            handleClose()
        }).catch(() => {
            message.showToastMessage({
                message: "Something Went Wrong",
                variant: "error",
            });
        }).finally(() => {
            setBusy(false)
        })



    }




    const tableOptions = {
        ...options,

        pageSizeOptions: [5, 10, 20],
        search: false,
        sort: false,
        paging: false,
        toolbar: false
    };

    const handleChange = (rowData) => {

        let value = data.map((item) => {
            if (item.id === rowData.id) {


                item.field_value = rowData?.field_value === ("true" || true) ? "false" : "true"
            }
            return item
        })
        setData(value)
        // console.log("row====", rowData, value)

    }





    const column = [
        {
            title: '',
            field: "id",
            render: (rowData) => <div>
                <FormControlLabel
                    control={<GreenCheckbox checked={(rowData?.field_value === "true" || rowData?.field_value === true) ? true : false} onChange={(e) => {
                        handleChange(rowData)
                    }} name="" />}
                    label=""
                />

            </div>
        },


        {

            title: 'Check Name',
            field: "field_name",
            width: "90%",
            headerStyle: {
                textAlign: 'left',
                width: "90%",
            },
            cellStyle: {
                textAlign: 'left',
                width: "90%",
            },


        }]




    return (
        <div>

            <Dialog
                maxWidth={"md"}
                open={true}
                className={classes.modal}
                onClose={handleClose}
                aria-labelledby="download"
            >
                <DialogTitle>
                    <Typography
                        color="primary"
                        className={classes.title}
                    >
                        Scheduled Checks
                    </Typography>
                </DialogTitle>
                <DialogContent>


                    <Box className={classes.spacing}>
                        <MaterialTable
                            style={{
                                boxShadow: "0px 1px 4px #00000033",
                                //   marginTop: "10px",
                            }}
                            isLoading={loading}
                            icons={tableIcons}
                            title={""}
                            columns={column}
                            data={data || []}
                            options={tableOptions}

                        />
                    </Box>

                    <div style={{}} className={classes.actionButtons}>
                        <MROButton
                            // loading={busy === "loading"}
                            variant={"contained"}
                            onClick={() => handleClose()}
                            type="button"
                        >
                            Cancel
                        </MROButton>
                        <MROButton
                            loading={busy}
                            variant={"contained"}
                            color={"primary"}
                            type="button"
                            onClick={() => handleSubmit()}
                        >
                            Complete
                        </MROButton>
                    </div>
                </DialogContent>


            </Dialog>




        </div>
    )
}
