import React, { useState, useEffect } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { ICON_COLOUR } from "../lib/constants/style_constants";
import Divider from "@material-ui/core/Divider";
import { ClearAll, Clear } from "@material-ui/icons";
import { IconButton, useTheme } from "@material-ui/core";
import PartIcon from 'assets/PartIcon.png'
import DescriptionIcon from 'assets/Description.png'
import MROTextField from "./form_components/TextField";

const useStyles = makeStyles(theme => ({
    wrapper: {
        backgroundColor: "#fff",
        // padding: theme.spacing(1, 2),
        border: "1px solid #d5d5d5",
        margin: "1% 0",
    },
    divider: {
        margin: '8px 0'
    },
    filters: {
        padding: "10px 0",
        '& .filter': {
            paddingRight: theme.spacing(3),
        }
    }
}));

function SearchBarWithPartsFilters(props) {
    const classes = useStyles();
    const theme = useTheme()
    const { handleSearch, searchText, noSearch, title, boxShadow, padding, placeholder, partNumber, setPartNumber, description, setDescription, handleDescriptionCopy, handlePartCopy } = props;

    const clearSearch = () => {
        handleSearch('')
    }

    return (
        <div style={{ boxShadow: boxShadow, border: "none", padding: padding }} className={classes.wrapper}>
            {
                !noSearch &&
                <>
                    <MROTextField
                        fullWidth
                        placeholder={"   Search anything"}
                        onChange={(e) => handleSearch(e.target.value)}
                        value={searchText}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{

                            startAdornment: <InputAdornment position="start" >
                                <SearchIcon style={{ fontSize: '16px', color: theme.palette.primary.main, marginRight: '10px' }} />
                            </InputAdornment>,

                            endAdornment: <>
                                <InputAdornment position="end" onClick={() => { handlePartCopy() }} style={{ cursor: 'pointer' }}>
                                    <img src={PartIcon} height={'22px'} />
                                </InputAdornment>
                                <InputAdornment position="end" onClick={() => { handleDescriptionCopy() }} style={{ cursor: 'pointer' }}>
                                    <img src={DescriptionIcon} height={'22px'} />
                                </InputAdornment>
                            </>
                        }}

                    />
                    {props.children && <Divider variant="fullWidth" className={classes.divider} />}
                </>
            }
            <div className={classes.filters}>
                {props.children && <Typography variant="subtitle2" color="primary" gutterBottom>{title ?? "Filters"}</Typography>}
                {props.children}
            </div>
        </div>
    )
}

export default SearchBarWithPartsFilters;