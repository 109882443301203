import React from "react";

import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { IconButton, makeStyles } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },

  root: {
    "& .MuiPaper-root": {
      width: (props) => props.width ?? undefined,
    },
  },
}));

export default function InfoPopup(props) {
  const { description } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const classes = useStyles(props);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <IconButton aria-describedby={id} onClick={handleClick}>
        <InfoOutlinedIcon />
      </IconButton>
      <Popover
        id={id}
        className={classes.root}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Typography className={classes.typography}>{description}</Typography>
      </Popover>
    </div>
  );
}
