import React, { useContext, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import MROTextField from "components/form_components/TextField";
import {
  Box,
  CircularProgress,
  IconButton,
  MenuItem,
  useTheme,
  Menu,
  Popover,
  Paper
} from "@material-ui/core";
import { ICON_COLOUR } from "lib/constants/style_constants";
import EditIcon from "@material-ui/icons/Edit";
import RightDialog from "views/Stores/Parts/Stocks/RightDialog";
import QuantityCounter from "components/quantity_counter";
import WarningIcon from "@material-ui/icons/Warning";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteDailog from "components/form_components/DeleteDailog";
import { MaintenanceServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation";
import { validateFormData } from "lib/utils/helperFunctions";
import { ToastMessageContext } from "lib/contexts/message_context";
import WarningDialog from "../UniversalWarningDialog";
import MROButton from "components/buttons";
import { WorkshopContext } from "../WorkshopContext/WorkshopContext";
import {
  formatDateFunctionWithAmPm,
  _formatDate,
} from "lib/utils/common_utils";
import VerticalIcon from '@material-ui/icons/MoreVert';
import Tooltip from "@material-ui/core/Tooltip";


const useStyles = makeStyles((theme) => ({
  paper: {
    margin: 0,
    padding: 0,
    // backgroundColor: "#fff",
    // padding: theme.spacing(3),
    // boxShadow: "0px 1px 4px #0000001A",
  },
  partKey: {
    fontSize: "13px",
    // padding: "5px",
  },
  partValue: {
    fontSize: "13px",
    color: "#4D4F5C",
    padding: "3px",
    display: "flex",
    alignItems: "center",
  },
  partCard: {
    borderRadius: "3px",
    padding: "5px 10px",
    "& p": {
      fontSize: "14px",
      padding: "3px",
    },

  },
  tooltip: {
    position: 'relative',
    display: 'inline-block',
    borderBottom: '1px dotted black' /* If you want dots under the hoverable text */
  },
  tooltipText: {
    background: 'black',
    color: '#fff',
    fontSize: '9px',
    visibility: 'hidden',
    width: '120px',
    textAlign: 'center',
    padding: '5px 0',
    borderRadius: '6px',

    /* Position the tooltip text - see examples below! */
    position: 'relative',
    zIndex: 99999
  },
}));

const GetInfoCard = (props) => {
  const theme = useTheme();
  const [action, setAction] = useState(null);
  const classes = useStyles();
  const [open, setOpen] = useState(false)
  const [mouseX, setMouseX] = useState();
  const [mouseY, setMouseY] = useState();
  const [poperState, setPoperState] = useState(false)
  const [currentCommentsSelections, setCurrentCommentSelections] = useState({});


  const handlepopupclose = () => {
    setAction("")
    setPoperState(false)
  }



  return (
    <Box display="flex">
      <Box flex={10} className={classes.partKey}>
        <Typography
          style={{ padding: "5px", color: theme.palette.primary.main }}
          color="secondary"
        >
          {props?.keyName}
        </Typography>
      </Box>

      {props?.keyName === "Serial #" && props?.value.length > 25 ?
        <>
          <Box flex={22} className={classes.partValue} style={{ cursor: 'pointer' }} onMouseMove={(e) => {
            setAction("view_key");
            setCurrentCommentSelections(props?.value);
            setPoperState(!poperState)
            setMouseX(e.clientX);
            setMouseY(e.clientY);
          }}
            onMouseLeave={(e) => {
              // setAction("view")
              // setPoperState(false)
            }}>
            {props?.value.slice(0, 25) + '...'}
          </Box>
          {action === "view_key" && <Popover id='key' open={Boolean(poperState)}
            anchorReference={'anchorPosition'}
            onClose={handlepopupclose}
            anchorEl={poperState}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            anchorPosition={{ top: mouseY, left: mouseX }}
            PaperProps={{
              marginLeft: '-11px'
            }}
          >

            <Paper style={{ width: 'max-content', height: 'fit-content', padding: '10px' }}>
              <Typography style={{ fontSize: '13px' }}>{currentCommentsSelections}</Typography>


            </Paper>
          </Popover>}
        </>
        :
        <Box flex={22} className={classes.partValue}>
          {props?.value}
        </Box>}
    </Box>
  );
};

function MaintenanceDetails(props) {
  const classes = useStyles();
  const {
    quoteId,
    data,
    workshopOrder,
    actionProps,
    currentSelection,
    bases,
    lineCustomers,
    getHeaderDetails,
    getList,
    handleClose: closeSideDrawer,
    dateField,
    loading,
    isGettingDetails,
    orderState
  } = props;

  const message = useContext(ToastMessageContext);

  const { setShouldTabDataUpdate } = useContext(WorkshopContext);

  const [action, setAction] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [qty, setQty] = useState();

  const [busy, setBusy] = useState(null);

  const [fieldsData, setFieldsData] = useState({
    part_number: null,
    serial_number: null,
    line_customer_id: null,
    customer_order_number: null,
    item_description: null,
    base_station_id: null,
  });
  const [isUnsavedData, setIsUnsavedData] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const [open, setOpen] = useState(false)
  // console.log("maint is========================", currentSelection, currentSelection?.order_no)

  useEffect(() => {
    if (!data) return;
    const {
      base_station_id,
      line_customer_id,
      part,
      serial,
      description,
      customer_ref,
      quantity,
    } = data;
    setFieldsData({
      part_number: part,
      serial_number: serial,
      line_customer_id,
      customer_order_number: customer_ref,
      item_description: description,
      base_station_id,
    });

    setQty(quantity);
  }, [data, isEdit]);

  const [errors, setErrors] = useState({});

  const resetErrorHandler = (name) => {
    setErrors((err) => ({ ...err, [name]: false }));
  };

  const handleChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    resetErrorHandler(name);
    setFieldsData((ps) => ({ ...ps, [name]: value }));
    setIsUnsavedData(true);
  };

  const handleClose = () => {
    if (isUnsavedData) {
      setAction("warning");
      return;
    }
    setIsEdit(false);
  };

  const updateData = async () => {
    const data = { ...fieldsData, quantity: qty };

    setBusy("loading");
    try {
      const response = await MaintenanceServices.updateHeader(data, quoteId);
      if (response.success) {
        getHeaderDetails();
        message.showToastMessage({
          message: "Updated Successfully",
          variant: "success",
        });
        setIsEdit(false);
        getList();
        setAction(null);
        setIsUnsavedData(false);
        setBusy(null);
        setShouldTabDataUpdate(true);
      }
    } catch (error) {
      message.showToastMessage({
        message: "Something went wrong",
        variant: "error",
      });
      setBusy("error");
    }
  };

  const submitHandler = (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);

    const { valid, error } = validateFormData(formData);

    if (!valid) {
      setErrors(error);
      return;
    }
    updateData();
  };

  const showArchiveWarningModal = () => {
    setAction("delete");
  };

  const archiveOrderHandler = async () => {
    try {
      setBusy("archive-loading");
      const response = await MaintenanceServices.archiveOrder(quoteId);
      if (response.success) {
        getHeaderDetails();
        message.showToastMessage({
          message: "Archived Successfully",
          variant: "success",
        });
        setIsEdit(false);
        getList();
        setAction(null);
        setIsUnsavedData(false);
        setBusy(null);
        setShouldTabDataUpdate(true);
        closeSideDrawer();
      }
      else {

        message.showToastMessage({
          message: response.error ? response.error : "Something went wrong. Try again!",
          variant: "error",
        });
        setIsEdit(false);
        setAction(null);
        setBusy(null);
        // closeSideDrawer();
      }
    } catch (error) {
      message.showToastMessage({
        message: "Something went wrong",
        variant: "error",
      });
      setBusy("error");
    }
  };

  // Email Export Records
  const handleClose1 = () => {
    setAction(null);
    setOpen(false)
    setAnchorEl(false)

  }
  let userDetails = JSON.parse(sessionStorage.getItem("user"));
  const handleEmailExport = async () => {
    const params = {
      workshop_order_id: currentSelection?.id,
      user_id: userDetails?.id,
    }
    await MaintenanceServices.sendEmailExportRecords(params).then(() => {

      message.showToastMessage({
        message: `Success - Records emailed to User email address!`,
        variant: "success",
      });
      setOpen(false)
      setAnchorEl(false)
    }).catch(() => {
      message.showToastMessage({
        message: "Something went wrong",
        variant: "error",
      });
    })

  }


  if (loading || isGettingDetails) {
    return (
      <Box
        width={loading ? "100%" : ""}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress color="primary" size={40} />
      </Box>
    );
  }

  return (
    <div className={classes.paper}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent={"center"}
        bgcolor="#fff"
        className={classes.partCard}
      >
        <Box flexGrow={1}>
          <div style={{ width: "100%" }}>
            <GetInfoCard keyName={"Part #"} value={data?.part || "-"} />
            <GetInfoCard keyName={"Serial #"} value={data?.serial || "-"} />
            <GetInfoCard
              keyName={"Description"}
              value={data?.description || "-"}
            />
          </div>
        </Box>
        <Box flexGrow={1}>
          <div style={{ width: "100%" }}>
            <GetInfoCard keyName={"Customer"} value={data?.customer || "-"} />
            <GetInfoCard
              keyName={"Customer Ref"}
              value={data?.customer_ref || "-"}
            />
            <GetInfoCard
              keyName={props.tabId === 23 ? "State" : "Quantity"}
              value={props.tabId === 23 ? data?.state : data?.quantity || "-"}
            />
          </div>
        </Box>
        <Box flexGrow={1}>
          <div style={{ width: "100%" }}>
            <GetInfoCard
              keyName={"Order #"}
              value={
                actionProps === "Send Quote "
                  ? workshopOrder?.order_number : actionProps === "calendarAction" || props?.actionStoppedOrder || props.type === 'dashboardCommercial' ?
                    currentSelection?.order_number : currentSelection?.order_no
              }
            />
            <GetInfoCard
              keyName={"Raised"}
              value={
                actionProps === "Send Quote "
                  ? formatDateFunctionWithAmPm(workshopOrder?.raised)
                  : formatDateFunctionWithAmPm(dateField) || "-"
              }
            />
            <GetInfoCard keyName={"Base"} value={data?.base || "-"} />
          </div>
        </Box>


        <Box flexGrow={1}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              width: "100%",
            }}
          >
            <IconButton onClick={(event) => {
              setAnchorEl(true);
              setOpen(true)

            }}>
              <VerticalIcon size="small" />
            </IconButton>

            {open &&
              <Menu id={`long-menu`}
                anchorEl={anchorEl}
                // keepMounted
                open={open}
                className={classes.menu}
                onClose={handleClose1}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ horizontal: "right" }}
                style={{
                  marginTop: '111px',
                  marginLeft: '-80px'
                }}
                PaperProps={{
                  style: {
                    maxHeight: 45 * 4.5,
                    width: "25ch",
                  },
                }}
              >

                {orderState != "Closed" && props?.permission.write && <MenuItem onClick={() => {
                  setOpen(false)
                  setIsEdit(true)
                }}>
                  <Typography style={{ paddingLeft: '2px', cursor: !props?.permission?.write && 'none' }} >Edit Header Details</Typography>
                </MenuItem>}
                {orderState === "Closed" && <MenuItem onClick={() => {
                  handleEmailExport()
                }}>

                  <Typography style={{ paddingLeft: '2px' }} >Email Export Records</Typography>
                </MenuItem>}

              </Menu >

            }




          </div>
        </Box>
      </Box>
      {isEdit && (
        <RightDialog open={isEdit} dialogCloseHandler={handleClose}>
          <div className={classes.root}>
            <Box
              padding="23px 38px"
              width="100%"
              height="100%"
              display="flex"
              flexDirection="column"
            >
              <Typography
                style={{
                  color: "#4D4F5C",
                  marginBottom: "10px",
                  fontWeight: "bold",
                  fontSize: "15px",
                }}
              >
                Edit
              </Typography>
              <Typography
                color="primary"
                // variant="body1"
                style={{ margin: "10px opx 30px", fontSize: "15px" }}
              >
                Order
              </Typography>

              <form noValidate onSubmit={submitHandler}>
                <MROTextField
                  name={"part_number"}
                  label={"Part Number"}
                  InputLabelProps={{ shrink: true }}
                  placeholder={"Part Number"}
                  required
                  error={errors.part_number}
                  onChange={handleChange}
                  value={fieldsData.part_number}
                ></MROTextField>

                <MROTextField
                  name={"serial_number"}
                  label={"Serial Number"}
                  InputLabelProps={{ shrink: true }}
                  placeholder={"Serial Number"}
                  required
                  error={errors.serial_number}
                  onChange={handleChange}
                  value={fieldsData.serial_number}
                ></MROTextField>
                <Grid item xs={12}>
                  <MROTextField
                    name="item_description"
                    placeholder="Enter description - max 30 charactersn"
                    label="Item Description *"
                    variant="outlined"
                    multiline={"description"}
                    color="primary"
                    InputLabelProps={{ shrink: true }}
                    error={errors.item_description}
                    onChange={handleChange}
                    value={fieldsData.item_description}
                    inputProps={{ maxLength: 30 }}
                  />
                </Grid>
                <MROTextField
                  name={"line_customer_id"}
                  label={"Select Customer"}
                  InputLabelProps={{ shrink: true }}
                  placeholder={"Jet2.com"}
                  required
                  select
                  error={errors.line_customer_id}
                  onChange={handleChange}
                  value={fieldsData.line_customer_id}
                >
                  {lineCustomers?.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </MROTextField>
                <MROTextField
                  name="customer_order_number"
                  placeholder="Purchase Order, or Service Order ref"
                  label="Customer Order # *"
                  variant="outlined"
                  color="primary"
                  InputLabelProps={{ shrink: true }}
                  error={errors.customer_order_number}
                  onChange={handleChange}
                  value={fieldsData.customer_order_number}
                />
                <QuantityCounter
                  name={"quantity"}
                  label={"Quantity"}
                  placeholder="Man-Hours required"
                  required
                  setQty={setQty}
                  qty={qty}
                  error={errors.quantity}
                  setError={() => resetErrorHandler("quantity")}
                  helperText={errors.quantity && "Please enter Quantity"}
                />
                <MROTextField
                  name="base_station_id"
                  placeholder="Base"
                  label="Workshop Base *"
                  variant="outlined"
                  color="primary"
                  InputLabelProps={{ shrink: true }}
                  select
                  error={errors.base_station_id}
                  setError={handleChange}
                  value={fieldsData.base_station_id}
                  onChange={handleChange}
                >
                  {bases?.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.station}
                    </MenuItem>
                  ))}
                </MROTextField>

                <Box mt="20px" display="flex" justifyContent="flex-end">
                  <MROButton
                    variant="contained"
                    onClick={handleClose}
                    style={{ marginRight: 15 }}
                  >
                    Cancel
                  </MROButton>
                  <MROButton
                    variant="contained"
                    color="primary"
                    type="submit"
                    loading={busy === "loading"}
                  >
                    Update
                  </MROButton>
                </Box>

                <Grid style={{ marginTop: "30px" }}>
                  <Typography color="primary">
                    Archive Order? - Irreversible!
                  </Typography>
                  <Box display="flex" width="100%" mt="15px">
                    <WarningIcon
                      style={{ color: "rgb(255, 179, 0)", marginRight: 16 }}
                    />
                    <Typography variant="body1">
                      Closes the Order in archive mode, which CANNOT be undone!
                      &nbsp;&nbsp;
                      <IconButton onClick={showArchiveWarningModal}>
                        <DeleteIcon fontSize="small" htmlColor={ICON_COLOUR} />
                      </IconButton>
                    </Typography>
                  </Box>
                </Grid>
              </form>
            </Box>
            {action === "delete" && (
              <DeleteDailog
                title="Caution"
                description={
                  <span
                    style={{
                      fontSize: 14,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <WarningIcon size={20} htmlColor="#FFB300" /> &nbsp; This
                    process cannot be reversed! Are you sure
                  </span>
                }
                open={true}
                handleClose={() => setAction(null)}
                handleConfirm={archiveOrderHandler}
                loading={busy === "archive-loading"}
              />
            )}
          </div>

          {action === "warning" && (
            <WarningDialog
              titleColor="warning"
              action={action === "warning"}
              handleClose={() => setAction(null)}
              handleConfirm={() => {
                setIsEdit(false);
                setAction(null);
                setIsUnsavedData(false);
              }}
              btnTitle="Yes"
              title="Warning"
              description="Data is NOT saved! Are you sure you want to leave without saving?"
            />
          )}
        </RightDialog>
      )}
    </div>
  );
}

export default MaintenanceDetails;
