import {
    AppBar,
    IconButton,
    Toolbar,
    Typography,
    Grid,
    Box,
    FormControlLabel,
    Paper,
    MenuItem,
    Button,
    withStyles,
} from "@material-ui/core";

import React, { useContext, useEffect, useMemo, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { CommercialServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/commercial";
import { ToastMessageContext } from "lib/contexts/message_context";
import MaterialTable from "material-table";
import tableIcons, { options } from "components/universal/table_attributes";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import MROTextField from "components/form_components/TextField"
import RescheduleIcon from "@material-ui/icons/History";
import LinearProgressBar from 'components/linear_progress_bar';
import NumericIncrement from "components/numeric_increment_job_clockings";
import MROButton from "components/buttons";
import { Search } from "@material-ui/icons";
import PositiveSwitch from "components/form_components/switch";
import MessagePopup from "components/universal/messagePopup";
import CreateApprovaCaution from "./CreateApprovaCaution";
import { WorkshopContext } from "../WorkshopContext/WorkshopContext";
import { validateFormData } from "lib/utils/helperFunctions";
import Checkbox from '@material-ui/core/Checkbox';
import EstimationHistory from "./EstimationHistory";
import DateField from 'views/Accounts/Workshop/Maintenance/ManageInvoice/DateField';
import moment from "moment";
import PartPricing from "./LookupPricing/PartPricing";
import { getUserId } from "lib/utils/common_utils";


const GreenCheckbox = withStyles({
    root: {
        color: "#4FC605",
        '&$checked': {
            color: "#4FC605",
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
    wrapper: {
        margin: "3%",
    },
    appBar: {
        backgroundColor: "#fff",
        color: "#000000CC",
        boxShadow: "0px 3px 6px #0000001A",
        fontSize: '14px'
    },
    backButton: {
        marginRight: theme.spacing(2),
    },
    disableField: {
        pointerEvents: "none",
        backgroundColor: "#F5F5F5",
    },
    title: {
        fontSize: '16px',
        color: theme.palette.primary.main
    }, title1: {
        fontSize: '14px',

    },
    detail: {
        backgroundColor: "#fff",
        padding: "1%",
    },
    spacing: {
        paddingTop: '15px', paddingBottom: '10px'
    },

    consolidatedTxt: {
        display: "flex", margin: '-23px 0px 20px 1px'

    },
    text: {
        fontSize: "12px"

    },
    button: {
        background: theme.palette.primary.main,
        width: "36px",
        height: " 36px",
        borderRadius: "5px"
    },
    diabledButton: {
        backgroundColor: "#707070",
        width: "36px",
        height: " 36px",
        borderRadius: "5px"
    }

}));




export default function InitialExceptions(props) {

    const classes = useStyles();
    const {
        handleClose,
        jobData,
        estData,
        approvedPositionnData,
        currentSelectedOrder,
        currentPosition,
        getApprovedPosition
    } = props;


    const [action, setAction] = useState(null);
    const [currentSelection, setCurrentSelection] = useState({});
    const [states, setStates] = useState({ man_hours: '' });
    const [errors, setErrors] = useState({});
    const [receipType, setReceiptType] = useState('')
    const { setApprovalStateCommercial, approvalStateCommercial, } = useContext(WorkshopContext);
    const [input, setInput] = useState({
        tat_days: "",
        labour_value: "",
        parts_value: "",
        service_value: "",
        equipment_value: "",
        aog_fee: "",
    });
    const [selectedException, setSetSelectionException] = useState([])
    const [sum, setSum] = useState(null);
    const [initilaSum, setInitialSum] = useState(null);
    const [selectedSum, setSelectedSum] = useState(null);
    const [revisedtotal, setRevisedTotal] = useState(null)
    const [revisedInitialtotal, setRevisedInitialTotal] = useState(null)
    const [confirmationPopup, setConfirmationPopup] = useState(false)
    const [acceptAll, setAcceptAll] = useState(false)
    const [popupValues, setPopUpValues] = useState({})
    const [messagePopup, setMessagePopup] = useState(false)
    const [formvalues, setFormvalues] = useState({})
    const [busy, setBusy] = useState(null);
    const message = useContext(ToastMessageContext);
    const [exceptionChecked, setExceptionChecked] = useState(false)
    const [itemChecked, setItemChecked] = useState(false)
    const [exceptionData, setExceptionData] = useState([])
    const [disabled, setDisabled] = useState(false)
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(5)
    const [totalCount, setTotalCount] = useState(0)
    const [loading, setLoading] = useState(false)
    const [standardId, setStandardId] = useState(null)
    const [etd, setETD] = useState(null)
    const [tatDays, setTatDays] = useState("")
    const [totalTat, setTotalTat] = useState(null)
    const [tableInput, setTableInput] = useState({
        labour_cost: 0, parts_cost: 0, service_cost: 0, equipment_cost: 0
    })
    const [planningVerfication, setPlanningVerification] = useState([])
    const getList = async () => {
        const params = {
            // page: 1 + page,
            // limit: pageSize,
            workshop_order_id: currentSelectedOrder.id,


        };
        setLoading(true)
        CommercialServices.getExceptionTableListing(params).then((res) => {

            if (res.success) {
                let data = res?.data

                if (data.length > 0) {
                    let value = data.map((item) => {
                        if (item.commercial_type === 1) {
                            item.status = true
                        }
                        return item
                    })

                    let selectedValue = value.filter((item) => {
                        if (item.status === true) {
                            return item.id

                        }
                    })
                    if (selectedValue.length > 0) {
                        let array1 = selectedValue.map((item) => {
                            return item.id
                        })
                        setSetSelectionException(array1)

                        //calculation

                        const initialLabourVal = 0;
                        let labour = selectedValue.map((item) =>
                            +item.labour_cost)
                        const labourTotal = labour.reduce(
                            (accumulator, currentValue) => accumulator + currentValue,
                            initialLabourVal,
                        );
                        const initialPartsVal = 0;
                        let parts = selectedValue.map((item) =>
                            +item.parts_cost)
                        const partTotal = parts.reduce(
                            (accumulator, currentValue) => accumulator + currentValue,
                            initialPartsVal,
                        );
                        const initialServicesVal = 0;
                        let services = selectedValue.map((item) =>
                            +item.service_cost)
                        const servicesTotal = services.reduce(
                            (accumulator, currentValue) => accumulator + currentValue,
                            initialServicesVal,
                        );
                        const initialEquipmentVal = 0;
                        let equipment = selectedValue.map((item) =>
                            +item.equipment_cost)
                        const sumEqip = equipment.reduce(
                            (accumulator, currentValue) => accumulator + currentValue,
                            initialEquipmentVal,
                        );
                        let rowData = { labour_cost: labourTotal, parts_cost: partTotal, service_cost: servicesTotal, equipment_cost: sumEqip }
                        setTableInput(rowData)
                        handleCalculation1(rowData)




                    }



                }

                setExceptionData(res.data)
                setTotalCount(res.total_count)
            }
        }).catch(() => {

        }).finally(() => {
            setLoading(false)
        })

    };


    useEffect(() => {
        if (exceptionData.length > 0) {
            let data = exceptionData.filter((item) => item.planning_verification === false)
            setPlanningVerification(data)
        }
    }, [exceptionData])




    useEffect(() => {
        getList();
    }, [page, pageSize]);


    // useEffect(() => {
    //     if (estData.length > 0 && estData !== undefined) {
    //         let apTAT = estData[0].tat_days ? estData[0].tat_days : 0
    //         let reTAT = estData[1].tat_days ? estData[1].tat_days : 0
    //         if (apTAT > reTAT) {
    //             setTatDays(apTAT)
    //         }
    //         else if (reTAT > apTAT) {
    //             setTatDays(reTAT)
    //         }
    //         else {
    //             setTatDays(approvedPositionnData?.tat)
    //         }
    //         // console.log("appppp======", apTAT, reTAT)

    //     }
    //     else {
    //         setTatDays(approvedPositionnData?.tat)
    //     }
    // }, [estData, approvedPositionnData])


    const [initialInput, setInitialInput] = useState({
        tat_days: "",
        labour_value: "",
        parts_value: "",
        service_value: "",
        equipment_value: "",
        aog_fee: "",
    });
    const [selectedInput, setSelectedInput] = useState({
        tat_days: 0.00,
        labour_value: 0.00,
        parts_value: 0.00,
        service_value: 0.00,
        equipment_value: 0.00,
        aog_fee: 0.00,
    });

    const [reportData, setReportData] = useState([

        {
            item: "Display Cost to Date",
            details: "Show “Costs to Date” section in the report?",
            status: false,
            name: "costs_to_date",
            id: 1
        }, {
            item: "Show Cost Breakdown",
            details: "Shows the Cost breakdown section with total costs per Action",
            status: false,
            name: "cost_breakdown",
            id: 2
        }, {
            item: "Detailed Costs",
            details: "Show additional breakdown of Labour, Parts, Service and Equipment Costs in ALL Cost sections of the Commercial Report?",
            status: false,
            name: "cost_breakdown_detail",
            id: 3
        }, {
            item: "Detailed CSV",
            details: "Send detailed “Cost Breakdown Report CSV” with the report?",
            status: false,
            name: "costs_csv",
            id: 4
        }, {
            item: "Workshop Report",
            details: "Send Workshop Report with to date Actions and Stage progress?",
            status: false,
            name: "workshop_report",
            id: 5
        }, {
            item: "Do NOT send Report",
            details: "Do not send anything to the customer in relation to this approval?",
            status: false,
            name: "do_not_send",
            id: 6

        },



    ])

    useEffect(() => {
        if (approvedPositionnData !== null) {

            setInitialInput({
                tat_days: +approvedPositionnData?.tat || 0,
                labour_value: +approvedPositionnData?.labour || 0,
                parts_value: +approvedPositionnData?.parts || 0,
                service_value: +approvedPositionnData?.services || 0,
                equipment_value: +approvedPositionnData?.equipment || 0,
                aog_fee: +approvedPositionnData?.aog_fee || 0,
            });

            setInput({
                tat_days: +approvedPositionnData?.tat || 0,
                labour_value: +approvedPositionnData?.labour || 0,
                parts_value: +approvedPositionnData?.parts || 0,
                service_value: +approvedPositionnData?.services || 0,
                equipment_value: +approvedPositionnData?.equipment || 0,
                aog_fee: +approvedPositionnData?.aog_fee || 0,
            });

            if (approvedPositionnData?.position !== undefined || approvedPositionnData?.position !== null) {
                setExceptionChecked(true)
                setDisabled(true)
            }
            // setStandardId(approvedPositionnData?.standard_id)
        }
    }, [approvedPositionnData])


    // useEffect(() => {
    //     if (approvedPositionnData?.inducted_start !== null) {
    //         let tat = tatDays ? tatDays : 0
    //         let date = approvedPositionnData?.inducted_start ? moment(approvedPositionnData?.inducted_start, "YYYY-MM-DD HH:mm:ss").add(tat, "days").format("DD/MM/YYYY") : null
    //         setETD(date)
    //     }
    // }, [approvedPositionnData, tatDays])

    useEffect(() => {
        if (approvedPositionnData !== null) {
            setTotalTat(approvedPositionnData?.tat ? approvedPositionnData?.tat : 0)
            setStandardId(approvedPositionnData?.standard_id)
            setTatDays(approvedPositionnData?.tat_remaining)
        }
    }, [approvedPositionnData])

    useEffect(() => {
        if (tatDays !== null && tatDays !== undefined) {
            let today = moment().format("DD/MM/YYYY")
            let tat = tatDays ? tatDays : 0
            let date = today ? moment(today, "DD/MM/YYYY").add(tat, "days").format("DD/MM/YYYY") : null
            setETD(date)
            if (date) {
                let date_format = "DD/MM/YYYY"
                let c_etd = moment(date, date_format)
                let inducted = currentSelectedOrder?.inducted_start ? moment(currentSelectedOrder?.inducted_start, "DD/MM/YYYY HH:mm:ss").format(date_format) : null

                if (inducted !== null) {
                    let i_date = moment(inducted, date_format)
                    let final_date = c_etd.diff(i_date, "days")

                    setTotalTat(final_date)
                }
            }

        }
    }, [tatDays])
    useMemo(
        () =>
            setSum(
                +input.labour_value +
                +input.parts_value +
                +input.service_value +
                +input.equipment_value +
                +input.aog_fee
            ),
        [input]
    );


    useMemo(
        () =>
            setInitialSum(
                +initialInput.labour_value +
                +initialInput.parts_value +
                +initialInput.service_value +
                +initialInput.equipment_value +
                +initialInput.aog_fee
            ),
        [initialInput]
    );
    useMemo(
        () =>
            setSelectedSum(
                +selectedInput.labour_value +
                +selectedInput.parts_value +
                +selectedInput.service_value +
                +selectedInput.equipment_value +
                +selectedInput.aog_fee
            ),
        [selectedInput]
    );


    useMemo(
        () =>
            setRevisedTotal(
                +input.labour_value +
                +input.parts_value +
                +input.service_value +
                +input.equipment_value
            ),
        [input]
    );
    useMemo(
        () =>
            setRevisedInitialTotal(
                +initialInput.labour_value +
                +initialInput.parts_value +
                +initialInput.service_value +
                +initialInput.equipment_value
            ),
        [initialInput]
    );




    const resetErrorsHandler = (name) => {
        setErrors((e) => ({ ...e, [name]: false }));
    };

    const handleInput = function (e) {
        const { value } = e.target;
        if (value.match(/\./g)) {
            const [, decimal] = value.split(".");

            // restrict value to only 2 decimal places
            if (decimal?.length > 2) {
                // do nothing
                return;
            }
        }
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    const handleInitialInput = function (e) {
        const { value } = e.target;
        if (value.match(/\./g)) {
            const [, decimal] = value.split(".");

            // restrict value to only 2 decimal places
            if (decimal?.length > 2) {
                // do nothing
                return;
            }
        }

        setInitialInput({
            ...input,
            [e.target.name === "initial_labour" ? "labour_value" : e.target.name === "initial_parts" ? "parts_value" : e.target.name === "initial_service" ? "service_value" : e.target.name === "initial_equipment" ? "equipment_value" : e.target.name]: e.target.value,
        });
    };


    const tableOptions1 = {
        ...options,
        paging: false,
        search: false,
        paging: false,

    };

    const tableOptions2 = {
        ...options,
        paging: false,
        search: false,


    };

    const handleReport = (rowData) => {

        let reportStatus = reportData.map((item) => {
            if (item.id === rowData.id) {
                item.status = !rowData?.status
            }
            return item
        })
        setReportData(reportStatus)

    }

    useEffect(() => {
        if (exceptionData.length > 0 && selectedException.length > 0) {
            let selectedValue = exceptionData.map((item) => {
                selectedException.filter((excep) => {
                    if (excep === item.id) {
                        item.status = true
                    }
                    // return item
                })
                return item
            })

        }
    }, [exceptionData, selectedException])



    const handleExceptionTable = (rowData) => {

        let value = exceptionData.map((item) => {
            if (item.id === rowData.id) {
                item.status = !rowData?.status
            }
            return item
        })
        setExceptionData(value)

        if (rowData?.status === false) {
            handleMinusCalculation(rowData)
            let indexExp = selectedException.filter(item => item !== rowData?.id)
            setSetSelectionException(indexExp)

        }
        else {
            selectedException.push(rowData.id)
            handleCalculation(rowData)
        }


    }
    // console.log("selectedDataf=====", selectedException)
    const handleCalculation = (rowData) => {

        setSelectedInput({
            labour_value: parseFloat(+selectedInput.labour_value + rowData.labour_cost).toFixed(2),
            parts_value: parseFloat(+selectedInput.parts_value + rowData.parts_cost).toFixed(2),
            service_value: parseFloat(+selectedInput.service_value + rowData.service_cost).toFixed(2),
            equipment_value: parseFloat(+selectedInput.equipment_value + rowData.equipment_cost).toFixed(2),
            aog_fee: 0,

        });

        setInput({
            labour_value: parseFloat(+input.labour_value + rowData.labour_cost).toFixed(2),
            parts_value: parseFloat(+input.parts_value + rowData.parts_cost).toFixed(2),
            service_value: parseFloat(+input.service_value + rowData.service_cost).toFixed(2),
            equipment_value: parseFloat(+input.equipment_value + rowData.equipment_cost).toFixed(2),
            aog_fee: +input.aog_fee || 0,
            tat_days: +input.tat_days
        });


    }

    const handleCalculation1 = (rowData) => {
        // console.log("selected====", rowData)
        setSelectedInput({
            labour_value: parseFloat(+selectedInput.labour_value + rowData.labour_cost).toFixed(2),
            parts_value: parseFloat(+selectedInput.parts_value + rowData.parts_cost).toFixed(2),
            service_value: parseFloat(+selectedInput.service_value + rowData.service_cost).toFixed(2),
            equipment_value: parseFloat(+selectedInput.equipment_value + rowData.equipment_cost).toFixed(2),
            aog_fee: 0,

        });

        setInput({
            labour_value: parseFloat(+input.labour_value + rowData.labour_cost + (+approvedPositionnData?.labour)).toFixed(2),
            parts_value: parseFloat(+input.parts_value + rowData.parts_cost + (+approvedPositionnData?.parts)).toFixed(2),
            service_value: parseFloat(+input.service_value + rowData.service_cost + (+approvedPositionnData?.services)).toFixed(2),
            equipment_value: parseFloat(+input.equipment_value + rowData.equipment_cost + (+approvedPositionnData?.equipment)).toFixed(2),
            aog_fee: +input.aog_fee + (+approvedPositionnData?.aog_fee) || 0,
            tat_days: +input.tat_days
        });


    }
    const handleMinusCalculation = (rowData) => {

        setSelectedInput({
            labour_value: parseFloat(+selectedInput.labour_value - rowData.labour_cost).toFixed(2),
            parts_value: parseFloat(+selectedInput.parts_value - rowData.parts_cost).toFixed(2),
            service_value: parseFloat(+selectedInput.service_value - rowData.service_cost).toFixed(2),
            equipment_value: parseFloat(+selectedInput.equipment_value - rowData.equipment_cost).toFixed(2),
            aog_fee: 0
        });
        setInput({
            labour_value: parseFloat(+input.labour_value - rowData.labour_cost).toFixed(2),
            parts_value: parseFloat(+input.parts_value - rowData.parts_cost).toFixed(2),
            service_value: parseFloat(+input.service_value - rowData.service_cost).toFixed(2),
            equipment_value: parseFloat(+input.equipment_value - rowData.equipment_cost).toFixed(2),
            aog_fee: +input.aog_fee || 0,
            tat_days: +input.tat_days
        });



    }

    useEffect(() => {
        if (selectedInput) {
            setTableInput({
                labour_cost: +selectedInput.labour_value,
                parts_cost: +selectedInput.parts_value,
                service_cost: +selectedInput.service_value,
                equipment_cost: +selectedInput.equipment_value,
            });

        }
    }, [selectedInput])
    useEffect(() => {
        if (initialInput && approvedPositionnData?.total === null) {
            let value_selected = {
                labour_value: parseFloat(+initialInput.labour_value + tableInput.labour_cost).toFixed(2),
                parts_value: parseFloat(+initialInput.parts_value + tableInput.parts_cost).toFixed(2),
                service_value: parseFloat(+initialInput.service_value + tableInput.service_cost).toFixed(2),
                equipment_value: parseFloat(+initialInput.equipment_value + tableInput.equipment_cost).toFixed(2),
                aog_fee: parseFloat(+initialInput.aog_fee).toFixed(2),


            }
            // console.log("selected====", tableInput, value_selected)

            setInput(value_selected);



        }



    }, [initialInput])




    // console.log("minus====", selectedInput, input)

    const estColumns = [
        {
            title: "Type",
            field: "type",

            headerStyle: {
                textAlign: "left",
                paddingLeft: "22px",
            },
            cellStyle: {
                textAlign: "left",
                paddingLeft: "22px",
            },

        },
        {
            title: "Position",
            field: "position",

        },
        {
            title: "Date",
            field: "date",
        },
        {
            title: "History",
            field: "",
            width: "20%",

            headerStyle: {
                textAlign: "left",

            },
            cellStyle: {
                width: "20%",
            }, render: (rowData) => {
                if (rowData?.type === "Approved") {
                    return ""
                }
                else if (rowData?.type === "Revised") {
                    return (
                        <IconButton onClick={() => {
                            setAction("est_history")
                            setCurrentSelection(rowData)
                        }}>
                            <RescheduleIcon />
                        </IconButton>

                    )
                }

            }
        },
        {
            title: "Inducted / Start",
            field: "inducted_start",


        }, {
            title: "TAT",
            field: "tat_days",


        }, {
            title: "ETD",
            field: "etd",

        }, {
            title: "Progress",
            field: "progress",
            render: (rowData) =>
                <div style={{ position: 'absolute', marginTop: '-10px', marginLeft: '25px' }}>
                    <LinearProgressBar
                        variant='determinate'
                        value={Number(rowData.progress)}
                        width='70px'
                        barHeight='20px'
                        bgColor={(rowData.progress > 0 && rowData.progress < 75) ? '#4FC605' : (rowData.progress >= 75 && rowData.progress < 100) ? '#FFB300' : rowData.progress >= 100 ? '#FF0202' : '#fff'}
                        color={rowData.progress >= 100 ? "#FFFFFF" : "#4D4F5C"}
                    />
                </div>

        },

    ]

    const reportColumn = [
        {
            title: "Item",
            field: "item",
            headerStyle: {
                textAlign: "left",
                paddingLeft: '15px'
            },
            cellStyle: {
                textAlign: "left",
                paddingLeft: '15px'
            },


        },
        {
            title: "Detail",
            field: "details",
            width: "60%",

            headerStyle: {
                textAlign: "left",
                width: "60%",
            },
            cellStyle: {
                textAlign: "left",
                width: "60%",
            },
        },
        {
            title: "Action",
            field: "",
            render: (rowData) => <div>
                <FormControlLabel
                    control={
                        <PositiveSwitch
                            checked={rowData?.status}
                            onChange={(e) => {
                                handleReport(rowData)
                            }}
                        />
                    }
                    label=""
                    labelPlacement=""
                // name={"show_all_issued_items"}
                />

            </div>

        },

    ]
    const exceptionColumn = [
        {
            title: "Action",
            field: "action_number",
            headerStyle: {
                textAlign: "left",
                paddingLeft: '15px'
            },
            cellStyle: {
                textAlign: "left",
                paddingLeft: '15px'
            },


        },
        {
            title: "Type",
            field: "action_category",
            headerStyle: {
                textAlign: "left",

            },
            cellStyle: {
                textAlign: "left",

            },
        },
        {
            title: "Description",
            field: "title",
            width: "40%",

            headerStyle: {
                textAlign: "left",
                width: "40%",
            },
            cellStyle: {
                textAlign: "left",
                width: "40%",
            },
        },
        {
            title: "Labour",
            field: "labour_cost",
        },

        {
            title: "Parts",
            field: "parts_cost",
        },

        {
            title: "Services",
            field: "service_cost",
        },

        {
            title: "Equipment",
            field: "equipment_cost",
        },
        {
            title: "Overall",
            field: "overview_total",
        },



        {
            title: "Action",
            field: "",
            render: (rowData) => <div>
                <FormControlLabel
                    control={<GreenCheckbox checked={rowData?.status} onChange={(e) => {
                        handleExceptionTable(rowData)
                    }} name="" />}
                    label=""
                />

            </div>

        },

    ]
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        // const { error, valid } = validateFormData(formData, ["send_report_to_customer", "receipt_charge", "receipt_type"]);
        let c_date = formData.get("calculated_etd")

        let optionalFields = ["send_report_to_customer", "receipt_charge", "receipt_type"]
        let format = "DD/MM/YYYY"
        let today = moment()
        let valid = true;
        let error = {};

        for (const pair of formData.entries()) {
            const key = pair[0];
            const value = pair[1];

            // console.log("value is===================", optionalFields)

            if (value === "select" || value === "Search") {
                error[key] = true;
                valid = false;
            }

            if (value === "" && !optionalFields.includes(key)) {
                error[key] = true;
                valid = false;
            }
        }


        if (moment(c_date, format).isBefore(moment(today, format))) {
            valid = false;
            error = {
                ...error,
                calculated_etd: "ETD cannot be less than today!"

            };
        }

        // console.log("date====", c_date)
        if (!valid) {
            setBusy(null);
            setErrors(error);
            return;
        }
        setBusy("loading");

        formData.delete("calculated_etd")
        formData.append("calculated_etd", moment(etd, "DD/MM/YYYY").format("YYYY-MM-DD"))

        // formData.delete("tat_days")
        formData.delete("labour_value")
        formData.delete("parts_value")
        formData.delete("service_value")
        formData.delete("equipment_value")
        formData.delete("aog_fee")
        formData.delete("total")
        formData.delete("initial_total")
        // formData.append("tat_days", tatDays)
        formData.append("labour_value", input.labour_value || "")
        formData.append("parts_value", input.parts_value || "")
        formData.append("service_value", input.service_value || "")
        formData.append("equipment_value", input.equipment_value || "")
        formData.append("aog_fee", input.aog_fee || "")
        formData.append("total", parseFloat(revisedtotal).toFixed(2) || "")
        formData.append("initial_total", parseFloat(revisedInitialtotal).toFixed(2) || "")
        formData.append("workshop_order_id", currentSelectedOrder?.id);
        formData.append("user_id", getUserId());
        // formData.delete("send_report_to_customer");
        // formData.append("send_report_to_customer", report);
        reportData.forEach((item) => formData.append(`${item.name}`, item.status))


        // formData.append("commercial_type", exceptionChecked ? 1 : 0)
        if (selectedException.length > 0) {
            selectedException.forEach((item, index) => formData.append(`action_id[${index}]`, item))
        }
        formData.append("approval_type", 1)
        if (standardId !== null) {
            formData.append("workshop_standard_price_id", standardId)
        }

        setPopUpValues({
            tat_days: tatDays,
            labour_value: input.labour_value,
            parts_value: input.parts_value,
            service_value: input.service_value,
            equipment_value: input.equipment_value,
            aog_fee: input.aog_fee,
            total: sum
        })

        if (valid) {

            setConfirmationPopup(true)
            setFormvalues(formData)

        }



    };


    const handlePopUp = async (e) => {
        setMessagePopup(true)

        await CommercialServices.createNewApproval(formvalues)
            .then((res) => {
                if (res) {
                    handleClose(res.success);
                    setApprovalStateCommercial(res.success);
                    getApprovedPosition();
                    message.showToastMessage({
                        message: "created successfully!!",
                        variant: "success",
                    });
                }
            })
            .catch(() => {
                message.showToastMessage({
                    message: "Something went wrong. Try again!!",
                    variant: "error",
                });
            })
            .finally(() => {
                setBusy(null);
                setMessagePopup(false)
            });

    }





    return (
        <div>
            <form onSubmit={handleSubmit}>




                <div style={{ margin: "3%" }} >
                    <div className={classes.consolidatedTxt} >
                        <InfoOutlinedIcon />
                        &nbsp;&nbsp;
                        <Typography variant="subtitle1" className={classes.text} >
                            Use Initial & Exceptions method for to capture an Initial Charges (quote or standard charges) and subsequent Exceptions (defects etc)
                        </Typography>
                    </div>
                    <Typography variant="subtitle2" className={classes.title1}>Estimated Departure</Typography>
                    <div className={classes.spacing}>
                        Current Approved and Revised ETDs
                    </div>
                    <Box className={classes.spacing}>
                        <MaterialTable
                            style={{
                                boxShadow: "0px 1px 4px #00000033",
                                //   marginTop: "10px",
                            }}
                            isLoading={''}
                            icons={tableIcons}
                            title={""}
                            columns={estColumns}
                            data={estData || []}
                            options={tableOptions1}
                            components={{
                                Toolbar: () => null,
                            }}
                            localization={{
                                toolbar: {},
                                body: {
                                    emptyDataSourceMessage: "No History found",
                                    filterRow: {
                                        filterTooltip: "Filter",
                                    },
                                },
                            }}
                        />
                    </Box>
                    <div className={classes.spacing}>
                        Update Approved ETD as required
                    </div>


                    <Grid
                        container
                        spacing={2}
                        alignItems={"center"}
                        justify={"flex-start"}
                        xs={12}
                    >
                        <Grid className={classes.purchaseGrid} item xs={3}>
                            <MROTextField
                                name={"tat_remaining"}
                                label={"TAT Remaining (Days) *"}
                                defaultValue={approvedPositionnData?.tat_remaining}
                                type="text"
                                value={tatDays}
                                placeholder={""}
                                InputLabelProps={{ shrink: true }}

                                error={errors.tat_remaining}
                                onChange={(e) => {
                                    resetErrorsHandler("tat_remaining");
                                    setTatDays(e.target.value)
                                    handleInput(e);
                                    setErrors({ ...errors, tat_remaining: false })
                                }}
                            ></MROTextField>
                        </Grid>
                        <Grid item>
                            <MROTextField
                                name={"tat_days"}
                                label={"TAT (Days)*"}
                                defaultValue={approvedPositionnData?.tat}
                                value={totalTat}
                                type="text"
                                placeholder={"0.00"}
                                error={errors.tat_days}
                                onChange={(e) => {
                                    resetErrorsHandler(e.target.name);
                                    setTotalTat(e.target.value)
                                }}
                                InputProps={{
                                    className: classes.disableField,
                                }}
                                InputLabelProps={{ shrink: true }}
                            ></MROTextField>

                        </Grid>
                        {/* {approvedPositionnData?.etd === null ? <Grid item>

                            <Grid className={classes.purchaseGrid} item xs={12}>
                                <DateField
                                    required
                                    name="calculated_etd"
                                    label={"Calculated ETD *"}
                                    InputLabelProps={{ shrink: true }}
                                    error={errors.calculated_etd}
                                    // helperText={errors.calculated_etd ? errors.calculated_etd : "*please enter a ETA"}
                                    helperText={errors.calculated_etd ? errors.calculated_etd : !errors.calculated_etd ? null : "*please enter a ETA"}

                                />
                            </Grid>

                        </Grid> : */}
                        <Grid item xs={4}>
                            <MROTextField
                                name={"calculated_etd"}
                                label={"Calculated ETD *"}
                                defaultValue={approvedPositionnData?.etd}
                                value={etd}
                                type="text"
                                width="123px"
                                placeholder={""}
                                error={errors.calculated_etd}
                                helperText={errors.calculated_etd ? errors.calculated_etd : !errors.calculated_etd ? null : "*please enter a ETA"}
                                onChange={(e) => {
                                    resetErrorsHandler(e.target.name);
                                    // handleInput(e);
                                    setErrors({ ...errors, calculated_etd: false })
                                }}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    className: classes.disableField,
                                }}
                            ></MROTextField>
                        </Grid>



                    </Grid>
                    <div className={classes.spacing}>
                        <Typography variant="subtitle2" className={classes.title1}>Shipping</Typography>

                        <Grid
                            container
                            spacing={2}
                            alignItems={"center"}
                            justify={"flex-start"}
                            xs={12}
                        >
                            <Grid item xs={3}>
                                <MROTextField
                                    name={"receipt_type"}
                                    label={"Receipt Type "}
                                    defaultValue={currentSelectedOrder?.receipt_type}
                                    select
                                    placeholder={"Receipt type"}
                                    InputProps={{
                                        className: classes.disableField,
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                >
                                    <MenuItem value={"customer_delivery"}>Customer Delivery</MenuItem>
                                    <MenuItem value={"mro_collect"}>MRO Collect</MenuItem>
                                    <MenuItem value={"mro_arranged_third_party_agent"}>MRO Arranged 3rd Party Agent</MenuItem>
                                </MROTextField>
                            </Grid>
                            <Grid item xs={3}>
                                <MROTextField
                                    name={"receipt_charge"}
                                    label={"Receipt Charge"}
                                    defaultValue={currentSelectedOrder?.receipt_charge ? parseFloat(currentSelectedOrder?.receipt_charge).toFixed(2) : 0}
                                    type="text"
                                    placeholder={"Receipt Charge"}

                                    InputProps={{
                                        // className: classes.disableField,
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                ></MROTextField>
                            </Grid>

                            <Grid item xs={3}>
                                <MROTextField
                                    name={"return_shipping_type"}
                                    label={"Return Shipping Type *"}
                                    select
                                    placeholder={"Select"}
                                    error={errors.return_shipping_type}
                                    onChange={(e) => {
                                        resetErrorsHandler(e.target.name);
                                        setReceiptType(e.target.value)
                                    }}
                                    defaultValue={""}
                                    InputLabelProps={{ shrink: true }}
                                >

                                    {/* <MenuItem value={"Select"} disabled>Select</MenuItem> */}
                                    <MenuItem value={"exclude_return_shipping"}>Excludes Return Shipping</MenuItem>
                                    <MenuItem value={"customer_collect"}>Customer Collect</MenuItem>
                                    <MenuItem value={'mro_delivery'}>MRO Delivery</MenuItem>
                                    <MenuItem value={'mro_arranged_third_party_agent'}>MRO Arranged 3rd Party Agent</MenuItem>

                                </MROTextField>
                            </Grid>

                            {(receipType === "mro_delivery" || receipType === "mro_arranged_third_party_agent") && <Grid item xs={3}>
                                <NumericIncrement
                                    name="return_charge"
                                    label="Return Charge *"
                                    error={errors.return_charge}
                                    value={states.man_hours}
                                    placeholder={"Return charge "}
                                    // onChange={handleChange}
                                    noIncreament
                                    state={states}
                                    setState={setStates}
                                    inputProps={{ min: 0, step: 0.25 }}
                                    InputLabelProps={{ shrink: true }}
                                    setError={() => resetErrorsHandler("return_charge")}
                                    helperText={
                                        errors["return_charge"] &&
                                        `Please enter return charge`
                                    }
                                />

                            </Grid>}


                        </Grid>

                    </div>
                    {/* initial value sction tab 1 */}
                    <div className={classes.spacing}>
                        <Typography variant="subtitle2" className={classes.title1} >Initial Value</Typography>
                        <div style={{ paddingTop: '10px' }}>
                            Initial Approved Position Values
                        </div>
                        <Grid container style={{ paddingTop: '10px' }} >
                            <Grid>
                                <IconButton className={approvedPositionnData?.total !== null ? classes.diabledButton : classes.button} onClick={() => {
                                    setAction("lookup")
                                }} style={{ pointerEvents: approvedPositionnData?.total !== null ? "none" : "" }}>
                                    <Search htmlColor="#fff" fontSize="small" style={{ fontSize: '25px' }} />
                                </IconButton>
                            </Grid>
                            <Grid><Typography style={{ padding: " 7px", fontSize: "14px" }}>Lookup from Standard Pricing or Job History</Typography> </Grid>

                        </Grid>

                    </div>


                    <Grid
                        container
                        spacing={2}
                        alignItems={"center"}
                        justify={"flex-start"}
                        xs={12}
                        className={classes.spacing}
                    >
                        <Grid item >
                            <MROTextField
                                label={"Order QTY"}
                                defaultValue={currentSelectedOrder?.quantity}
                                type="text"
                                width="120px"
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    className: classes.disableField,
                                }}
                            ></MROTextField>
                        </Grid>
                        <Grid item >
                            <MROTextField
                                name={"initial_labour"}
                                label={"Labour *"}
                                value={initialInput.labour_value}
                                type="text"
                                width="120px"
                                placeholder={"0.00"}
                                InputProps={{
                                    className: approvedPositionnData?.labour == null ? "" : classes.disableField,
                                }}
                                onChange={(e) => {
                                    resetErrorsHandler(e.target.name);
                                    setInitialInput({ ...initialInput, labour_value: e.target.value })
                                }}
                                InputLabelProps={{ shrink: true }}
                            ></MROTextField>
                        </Grid>

                        <Grid item >
                            <MROTextField
                                name={"initial_parts"}
                                label={"Parts *"}
                                value={initialInput.parts_value}
                                type="text"
                                width="120px"
                                placeholder={"0.00"}
                                InputProps={{
                                    className: approvedPositionnData?.parts === null ? "" : classes.disableField,
                                }}
                                onChange={(e) => {
                                    resetErrorsHandler(e.target.name);
                                    setInitialInput({ ...initialInput, parts_value: e.target.value })

                                }}
                                InputLabelProps={{ shrink: true }}
                            ></MROTextField>
                        </Grid>
                        <Grid item >
                            <MROTextField
                                name={"initial_service"}
                                width="120px"
                                label={"Services *"}
                                value={initialInput.service_value}
                                type="text"
                                placeholder={"0.00"}
                                InputProps={{
                                    className: approvedPositionnData?.services === null ? "" : classes.disableField,

                                }}
                                onChange={(e) => {
                                    resetErrorsHandler(e.target.name);
                                    setInitialInput({ ...initialInput, service_value: e.target.value })

                                }}
                                InputLabelProps={{ shrink: true }}
                            ></MROTextField>
                        </Grid>
                        <Grid item >
                            <MROTextField
                                name={"initial_equipment"}
                                label={"Equipment *"}
                                width="120px"
                                value={initialInput.equipment_value}
                                placeholder={"0.00"}
                                type="text"
                                InputProps={{
                                    className: approvedPositionnData?.equipment === null ? "" : classes.disableField,
                                }}
                                onChange={(e) => {
                                    resetErrorsHandler(e.target.name);
                                    setInitialInput({ ...initialInput, equipment_value: e.target.value })

                                }}
                                InputLabelProps={{ shrink: true }}
                            ></MROTextField>
                        </Grid>
                        <Grid item >
                            <MROTextField
                                name={"initial_aog"}
                                label={"Other *"}
                                value={initialInput.aog_fee}
                                placeholder={"0.00"}
                                type="text"
                                width="120px"
                                InputProps={{
                                    // className: approvedPositionnData?.aog_fee === null ? "" : classes.disableField
                                }}
                                onChange={(e) => {
                                    setInitialInput({ ...initialInput, aog_fee: e.target.value })
                                    setInput({ ...input, aog_fee: e.target.value })
                                    resetErrorsHandler(e.target.name)
                                }}
                                InputLabelProps={{ shrink: true }}

                            ></MROTextField>
                        </Grid>

                        <Grid item >
                            <MROTextField
                                name={"initial_total"}
                                label={"Total *"}
                                width="120px"
                                value={parseFloat(initilaSum).toFixed(2)}
                                placeholder={"0.00"}
                                defaultValue={0.0}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    className: classes.disableField,
                                }}


                            ></MROTextField>
                        </Grid>
                        <Grid item >
                            <MROTextField
                                label={"Currency"}
                                defaultValue={currentPosition?.currency}
                                type="text"
                                width="120px"
                                placeholder={"USD"}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    className: classes.disableField,
                                }}
                            ></MROTextField>
                        </Grid>




                    </Grid>
                    {/* exception sections tab 1*/}
                    <div className={classes.spacing}>


                        <Typography variant="subtitle2" className={classes.title1}>Exceptions</Typography>
                    </div>
                    <div>
                        <FormControlLabel style={{ marginLeft: '3px', fontSize: '15px' }}
                            control={
                                <PositiveSwitch
                                    checked={exceptionChecked}
                                    onChange={(e) => {
                                        setExceptionChecked(e.target.checked)
                                    }}
                                    disabled={disabled}
                                />
                            }
                            label="Add Exceptions now?"
                            labelPlacement="start"
                        // name={"show_all_issued_items"}
                        />
                    </div>
                    {exceptionChecked && <>

                        <Grid><Typography style={{ paddingTop: " 7px", fontSize: "14px", paddingBottom: "10px" }}>Select the exception / defects that go beyond the initial Approved Position scope of work</Typography> </Grid>

                        <Paper style={{ paddingBottom: "15px" }}>
                            <Box className={classes.spacing} style={{ height: '350px', overflow: "scroll", paddingLeft: "19px", paddingRight: "15px" }}>

                                <MaterialTable
                                    style={{
                                        boxShadow: "0px 1px 4px #00000033",
                                        //   marginTop: "10px",
                                        maxBodyHeight: "350px"
                                    }}
                                    isLoading={loading}
                                    icons={tableIcons}
                                    title={""}
                                    columns={exceptionColumn}
                                    data={exceptionData || []}
                                    options={tableOptions2}
                                    components={{
                                        Toolbar: () => null,
                                    }}
                                    localization={{
                                        toolbar: {},
                                        body: {
                                            emptyDataSourceMessage: "No Records found",
                                            filterRow: {
                                                filterTooltip: "Filter",
                                            },
                                        },
                                    }}
                                    // onChangePage={(page) => {
                                    //     setPage(page);
                                    // }}
                                    // onChangeRowsPerPage={(pageSize) => {
                                    //     setPageSize(pageSize);
                                    // }}

                                    totalCount={totalCount}
                                    page={page}
                                />

                            </Box>
                        </Paper>
                        <div style={{ paddingTop: '15px' }}>
                            Selected exception values
                        </div>
                        {/* selected exceptions */}
                        <Grid
                            container
                            spacing={2}
                            alignItems={"center"}
                            justify={"flex-start"}
                            xs={12}
                            className={classes.spacing}
                        >
                            <Grid item >
                                <MROTextField
                                    label={"Order QTY"}
                                    defaultValue={currentSelectedOrder?.quantity}
                                    type="text"
                                    width="120px"
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        className: classes.disableField,
                                    }}
                                ></MROTextField>
                            </Grid>
                            <Grid item >
                                <MROTextField
                                    label={"Labour *"}
                                    value={selectedInput.labour_value}
                                    type="text"
                                    width="120px"
                                    placeholder={"0.00"}
                                    InputProps={{
                                        className: classes.disableField,
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                ></MROTextField>
                            </Grid>

                            <Grid item >
                                <MROTextField

                                    label={"Parts *"}
                                    value={selectedInput.parts_value}
                                    type="text"
                                    width="120px"
                                    placeholder={"0.00"}
                                    InputProps={{
                                        className: classes.disableField,
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                ></MROTextField>
                            </Grid>
                            <Grid item >
                                <MROTextField

                                    width="120px"
                                    label={"Services *"}
                                    value={selectedInput.service_value}
                                    placeholder={"0.00"}
                                    InputProps={{
                                        className: classes.disableField,
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                ></MROTextField>
                            </Grid>
                            <Grid item >
                                <MROTextField

                                    label={"Equipment *"}
                                    width="120px"
                                    value={selectedInput.equipment_value}
                                    placeholder={"0.00"}
                                    type="text"
                                    InputProps={{
                                        className: classes.disableField,
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                ></MROTextField>
                            </Grid>
                            <Grid item >
                                <MROTextField
                                    label={"Other *"}
                                    value={selectedInput.aog_fee}
                                    placeholder={"0.00"}
                                    type="text"
                                    width="120px"
                                    InputProps={{
                                        className: classes.disableField,
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                ></MROTextField>
                            </Grid>

                            <Grid item >
                                <MROTextField
                                    label={"Total *"}
                                    width="120px"
                                    value={parseFloat(selectedSum).toFixed(2)}
                                    placeholder={"0.00"}
                                    defaultValue={0.0}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        className: classes.disableField,
                                    }}
                                ></MROTextField>
                            </Grid>
                            <Grid item >
                                <MROTextField
                                    label={"Currency"}
                                    defaultValue={currentPosition?.currency}
                                    type="text"
                                    width="120px"
                                    placeholder={"USD"}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        className: classes.disableField,
                                    }}
                                ></MROTextField>
                            </Grid>




                        </Grid>



                    </>}
                    {/* commercial sections tab1*/}


                    <div className={classes.spacing}>
                        <Typography variant="subtitle2" className={classes.title1} >Commercial Values</Typography>

                        <div style={{ paddingTop: '10px' }}>
                            Workshop Order TOTAL value in the Customer’s currency
                        </div>
                    </div>


                    <Grid
                        container
                        spacing={2}
                        alignItems={"center"}
                        justify={"flex-start"}
                        xs={12}
                        className={classes.spacing}
                    >
                        <Grid item >
                            <MROTextField
                                label={"Order QTY"}
                                defaultValue={currentSelectedOrder?.quantity}
                                type="text"
                                width="120px"
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    className: classes.disableField,
                                }}

                            ></MROTextField>
                        </Grid>
                        <Grid item >
                            <MROTextField
                                name={"labour_value"}
                                label={"Labour *"}
                                value={input.labour_value}
                                type="text"
                                width="120px"
                                placeholder={"0.00"}
                                // error={errors.tat_days}
                                onChange={(e) => {
                                    resetErrorsHandler(e.target.name);
                                    handleInput(e);
                                }}
                                InputProps={{
                                    className: classes.disableField,
                                }}
                                InputLabelProps={{ shrink: true }}
                            ></MROTextField>
                        </Grid>

                        <Grid item >
                            <MROTextField
                                name={"parts_value"}
                                label={"Parts *"}
                                value={input.parts_value}
                                type="text"
                                width="120px"
                                placeholder={"0.00"}
                                error={errors.parts_value}
                                onChange={(e) => {
                                    resetErrorsHandler(e.target.name);
                                    handleInput(e);
                                }}
                                InputProps={{
                                    className: classes.disableField,
                                }}
                                InputLabelProps={{ shrink: true }}
                            ></MROTextField>
                        </Grid>
                        <Grid item >
                            <MROTextField
                                name={"service_value"}
                                width="120px"
                                label={"Services *"}
                                value={input.service_value}
                                type="text"
                                placeholder={"0.00"}
                                error={errors.service_value}
                                onChange={(e) => {
                                    resetErrorsHandler(e.target.name);
                                    handleInput(e);
                                }}
                                InputProps={{
                                    className: classes.disableField,
                                }}
                                InputLabelProps={{ shrink: true }}
                            ></MROTextField>
                        </Grid>
                        <Grid item >
                            <MROTextField
                                name={"equipment_value"}
                                label={"Equipment *"}
                                width="120px"
                                value={input.equipment_value}
                                placeholder={"0.00"}
                                type="text"
                                error={errors.equipment_value}
                                onChange={(e) => {
                                    resetErrorsHandler(e.target.name);
                                    handleInput(e);
                                }}
                                InputProps={{
                                    className: classes.disableField,
                                }}
                                InputLabelProps={{ shrink: true }}
                            ></MROTextField>
                        </Grid>
                        <Grid item >
                            <MROTextField
                                name={"aog_fee"}
                                label={"Other *"}
                                value={input.aog_fee}
                                placeholder={"0.00"}
                                type="text"
                                width="120px"
                                error={errors.aog_fee}
                                onChange={(e) => {
                                    resetErrorsHandler(e.target.name);
                                    handleInput(e);
                                }}
                                InputProps={{
                                    className: classes.disableField,
                                }}
                                InputLabelProps={{ shrink: true }}
                            ></MROTextField>
                        </Grid>

                        <Grid item >
                            <MROTextField
                                name={"total"}
                                label={"Total *"}
                                width="120px"
                                error={errors.total}
                                value={parseFloat(sum).toFixed(2)}
                                placeholder={"0.00"}
                                defaultValue={0.0}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    className: classes.disableField,
                                }}
                                onChange={(e) => {
                                    resetErrorsHandler(e.target.name);
                                }}

                            ></MROTextField>
                        </Grid>
                        <Grid item >
                            <MROTextField
                                label={"Currency"}
                                defaultValue={currentPosition?.currency}
                                type="text"
                                width="120px"
                                placeholder={"USD"}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    className: classes.disableField,
                                }}
                            ></MROTextField>
                        </Grid>




                    </Grid>



                    {/* comments Sections tab1*/}
                    <Typography variant="subtitle2" className={classes.title1}>Commercial Report Comments</Typography>

                    <Grid
                        container
                        spacing={2}
                        alignItems={"center"}
                        justify={"flex-start"}
                        xs={12}
                        className={classes.spacing}
                    >

                        <Grid item xs={12}>
                            <MROTextField
                                name={"comments"}
                                label={"Comments *"}
                                type="text"
                                multiline
                                placeholder={"Add comments you wish to appear on your commercial report summary - Max 250 Characters"}
                                error={errors.comments}
                                onChange={(e) => {
                                    resetErrorsHandler(e.target.name);
                                }}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ maxLength: 250 }}
                                InputProps={{
                                    style: {
                                        height: 'max-content',
                                        width: "100%",

                                    },
                                }}
                            ></MROTextField>
                        </Grid>





                    </Grid>

                    {/* report sections */}
                    <div className={classes.spacing}>
                        <Typography variant="subtitle2" className={classes.title1}>Commercial Report Options</Typography>
                    </div>
                    <Box className={classes.spacing}>
                        <MaterialTable
                            style={{
                                boxShadow: "0px 1px 4px #00000033",
                                //   marginTop: "10px",
                            }}
                            isLoading={''}
                            icons={tableIcons}
                            title={""}
                            columns={reportColumn}
                            data={reportData || []}
                            options={tableOptions1}
                            components={{
                                Toolbar: () => null,
                            }}
                            localization={{
                                toolbar: {},
                                body: {
                                    emptyDataSourceMessage: "No Records found",
                                    filterRow: {
                                        filterTooltip: "Filter",
                                    },
                                },
                            }}
                        />
                    </Box>
                </div>

                <div style={{ marginLeft: "0px" }} className={"action-buttons"}>
                    <MROButton
                        // loading={busy === "loading"}
                        variant={"contained"}
                        color={"primary"}
                        type="submit"
                    >
                        Complete
                    </MROButton>
                </div>









            </form >


            {action === "lookup" && (
                <PartPricing handleClose={() => setAction(null)} currentWorkshopDetails={{ quantity: currentSelectedOrder?.quantity, part_number: currentSelectedOrder?.part_number, description: currentSelectedOrder?.description, salesRate: approvedPositionnData?.sales_rate }}
                    setInput={setInitialInput} input={initialInput} name={"initial"} setStandardId={setStandardId} setTatDays={setTatDays}
                />

            )
            }



            {
                confirmationPopup && <CreateApprovaCaution handleClose={() => {
                    setConfirmationPopup(false)

                }}

                    onClose={() => {
                        setConfirmationPopup(false)
                        setAcceptAll(false)
                    }}
                    open={confirmationPopup}
                    popupValues={popupValues}
                    setAcceptAll={setAcceptAll}
                    acceptAll={acceptAll}

                    handleSubmit={handlePopUp}
                    planningVerfication={planningVerfication}
                />
            }
            {
                messagePopup &&
                <MessagePopup
                    handleClose={() => setMessagePopup(false)}
                    open={messagePopup}
                    topTitle={"The Commercial Reports for the job are being prepared"}
                    bottomTitle={"Please be patient, this will take a few moments"}
                />
            }

            {
                action === "est_history" && (
                    <EstimationHistory
                        currentSelection={currentSelection}
                        handleClose={() => setAction(null)}

                    />
                )
            }

        </div >
    )
}
