import React, { useContext, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { styled } from "@material-ui/styles";
import FileUpload from "components/form_components/fileUpload";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { ICON_COLOUR } from "lib/constants/style_constants";
import makeStyles from "@material-ui/core/styles/makeStyles";
import certificateIcon from "assets/certificate@2x.png";
import { ToastMessageContext } from "lib/contexts/message_context";
import { InspectionServices } from "lib/services/api/operaitons/WorkShop/Inspection/InspectionService";
import MROButton from "components/buttons";
import { MaintenanceServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation";
import { BASE_API_URL_BOXP } from "lib/constants";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minHeight: "30vh",
    maxHeight: "150vh",
  },
  actionButtons: {
    paddingTop: "5px",
    paddingBottom: "20px",
    textAlign: "right",
    marginRight: "20px",
    "& button:not(:last-child)": {
      marginRight: "20px",
    },
  },
}));

function Certificate(props) {
  const { open, handleCloseDailog, selectedId, action_type, styleProp } = props;
  const classes = useStyles();
  const [boolState, setBoolState] = useState(false);
  const message = useContext(ToastMessageContext);
  const [busy, setBusy] = useState(null);
  const [actionCertificate, setActionCertificate] = useState([]);
  const [certificate, setCertificate] = useState([]);
  const [deletedStatus, setDeletedStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getActionCertificate();
  }, []);

  const getActionCertificate = async () => {
    setBusy("certificate_loading");
    setIsLoading(true);
    await InspectionServices.getActionCertificate(selectedId)
      .then((res) => {
        setBusy(null);
        if (res) {
          setActionCertificate(res?.data);
        }
      })
      .catch(() => {
        message.showToastMessage({
          message: "Something went wrong. Try again!",
          variant: "error",
        });
      })
      .finally(() => {
        setIsLoading(false);
        setBusy(null);
      });
  };

  useEffect(() => {
    if (certificate.length) {
      setDeletedStatus(false);
    }
  }, [certificate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    formData.append("action_id", selectedId);
    formData.append("action_type", action_type);
    formData.delete("action_certificate[]");
    certificate.forEach((certificateFile) =>
      formData.append("action_certificate[]", certificateFile)
    );
    setBusy("loading");
    if (deletedStatus !== true) {
      await InspectionServices.addCertificate(formData)
        .then(() => {
          setBusy(null);
          handleCloseDailog();
          message.showToastMessage({
            message: "Added successfully!",
            variant: "success",
          });
          setDeletedStatus(false);
        })
        .catch(() => {
          setBusy(null);
          handleCloseDailog();
          message.showToastMessage({
            message: "Something went wrong. Try again!",
            variant: "error",
          });
        });
      setBusy(null);
    }
    setBusy(null);
    handleCloseDailog();
  };

  const deleteFileHandler = async (id) => {
    setBusy("file-deleting");
    try {
      const response = await MaintenanceServices.deleteFile(id);

      if (response.success) {
        message.showToastMessage({
          message: "File deleted successfully",
          variant: "success",
        });
        setDeletedStatus(true);
        getActionCertificate();
      }
      setBusy(null);
    } catch (error) {
      message.showToastMessage({
        message: "Something went wrong",
        variant: "error",
      });
      setBusy("file-deleting-error");
    }
  };

  return (
    <div>
      <Dialog
        onClose={handleCloseDailog}
        aria-labelledby="customized-dialog-title"
        open={open}
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleCloseDailog}>
          <Grid style={{ display: "flex", justifyContent: "space-between" }}>
            <Box display="flex" alignItems="center">
              <Typography
                style={{ fontSize: "17px" }}
                variant="h5"
                color="primary"
              >
                Action Certificates &nbsp;&nbsp;
                {!boolState && props.permission.write && (
                    <IconButton style={{ display: styleProp }}>
                      <EditIcon
                        fontSize="small"
                        htmlColor={ICON_COLOUR}
                        onClick={() => setBoolState(true)}
                      />
                    </IconButton>
                )}
              </Typography>
            </Box>
            <IconButton>
              <Box display="flex" justifyContent="center">
                {" "}
                <img src={certificateIcon} alt="Icon" />{" "}
              </Box>
            </IconButton>
          </Grid>
          <Typography style={{ fontSize: "12px" }}>
            Upload ALL task relevant certification documents - E.g., Form1’s,
            8130’s, C of C’s etc.
          </Typography>
        </DialogTitle>
        <form noValidate onSubmit={handleSubmit}>
          <DialogContent dividers>
            <Grid
              style={{
                width: "500px",
                height: "200px",
              }}
            >
              <FileUpload
                defaultValue={
                  (actionCertificate !== undefined && boolState === false) ||
                  boolState === true
                    ? actionCertificate?.map((item) => ({
                        filename: item?.file_name,
                        url: `${item?.path}`,
                        id: item?.id,
                      }))
                    : ""
                }
                setFiles={(files) => {
                  setCertificate(files);
                }}
                onDelete={boolState === false ? null : deleteFileHandler}
                showBorderOnNoFiles={true}
                name="action_certificate[]"
                multiple={true}
                APIUrl={""}
                action={"Upload Document"}
                isLoading={isLoading}
                isDeleting={busy === "file-deleting"}
                uploadButtonView={boolState === false}
              />
            </Grid>
          </DialogContent>
          {boolState === true && (
            <div className={classes.actionButtons}>
              <MROButton variant={"contained"} onClick={handleCloseDailog}>
                Cancel
              </MROButton>
              <MROButton
                loading={busy === "loading"}
                variant={"contained"}
                color={"primary"}
                type={"submit"}
              >
                Complete
              </MROButton>
            </div>
          )}
        </form>
      </Dialog>
    </div>
  );
}

export default Certificate;
