import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Grid,
  makeStyles,
  MenuItem,
  Typography,
} from "@material-ui/core";
import MROTextField from "../../../../components/form_components/TextField";
import RightDialog from "./RightDialog";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import MROButton from "../../../../components/buttons";
import DateField from "../../../../components/form_components/DateField";
import { ToastMessageContext } from "../../../../lib/contexts/message_context";
import { Autocomplete } from "@material-ui/lab";
import PartsClientServices from "../../../../lib/services/api/stores/tooling/parts_client";
import { CircularProgress } from "@material-ui/core";
import FetchedFilePreview from "components/form_components/FetchedFilePreview";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { format24HrDateOnly } from "lib/utils/common_utils";

const useStyels = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    padding: "23px 37px 0px 37px",
    display: "flex",
    flexDirection: "column",
  },
  heading: {
    fontSize: "16px",
    color: "#4D4F5C",
    lineHeight: "22px",
    fontWeight: "bold",
  },
  label: {
    color: theme.palette.primary.main,
    marginBottom: "5px",
    display: "block",
  },
}));

function EditDialog(props) {
  const classes = useStyels();

  const message = useContext(ToastMessageContext);

  const user = JSON.parse(sessionStorage.getItem("user"));
  const [baseStation, setBaseStation] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const {
    open,
    dialogCloseHandler,
    selectedSecondaryPart,
    partNumber,
    shelfLifeControl,
  } = props;

  const [qty, setQty] = useState();

  const [owner, setOwner] = useState("");

  const [location, setLocation] = useState("");
  const [locationId, setLocationId] = useState("");

  const [date, setDate] = useState("");

  const [mroOwnedPart, setMroOwnedPart] = useState(false);

  // for drop down items
  const [owners, setOwners] = useState([]);
  const [unitOfMeasureItems, setUnitOfMeasureItems] = useState([]);

  const [error, setError] = useState({});

  const [storeLocation, setStoreLocation] = useState([]);

  const [orgName, setOrgName] = useState("");

  const [files, setFiles] = useState([]);

  const [serialNumber, setSerialNumber] = useState("")

  const [isFileUploading, setIsFileUploading] = useState(false);

  const [isDeletingFile, setIsDeletingFile] = useState(false);

  // this is for showing the loding spinner on the correct file
  const [selectedFileId, setSelectedFileId] = useState();

  useEffect(() => {
    PartsClientServices.fetchDependencies()
      .then((res) => {
        if (res) {
          setOwners(res.line_customers);
          setUnitOfMeasureItems(res.units_of_measure);
          setOrgName(res.organisation_name);
        }
      })
      .catch(() => { });
  }, []);

  const validateFields = (formData) => {
    const owner = formData.get("line_customer_id");

    const storeLocation = formData.get("store_location");

    const qtyValidate = formData.get("quantity_received");

    let valid = true;
    let errors = {};
    if (!owner && !mroOwnedPart) {
      valid = false;
      errors = { ...errors, owner: "*please select owner" };
    }

    if (!storeLocation) {
      valid = false;
      errors = { ...errors, store: "*please select store location" };
    }

    if (qty === 0) {
      valid = false;
      errors = { ...errors, qty: "*please add quantity" };
    }

    if (!valid) {
      setError(errors);
    }
    return valid;
  };

  const updateDetailsHandler = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    formData.delete("serial_number")
    // formData.append("user_id", user.id);
    formData.append(
      "secondary_part_id",
      selectedSecondaryPart.secondary_part_id
    );
    formData.append("base_station_id", baseStation);
    formData.append("batch_number", selectedSecondaryPart.batch_number);

    formData.append("serial_number", serialNumber === "null" || "" || null ? "" : serialNumber);

    formData.append("mro_owned", mroOwnedPart);
    formData.append("store_location", location);
    if (!validateFields(formData)) {
      return;
    }
    setIsLoading(true);
    PartsClientServices.updateSecondaryPart(formData)
      .then((res) => {
        if (res?.success) {
          message.showToastMessage({
            message: " Part updated successfully",
            variant: "success",
          });
          dialogCloseHandler();
        } else {
          res.errors &&
            message.showToastMessage({
              message: res.errors.join(","),
              variant: "error",
            });
        }
      })
      .catch((err) => {
        message.showToastMessage({
          message: "Something went wrong. Try Again",
          variant: "error",
        });
      })
      .finally(() => setIsLoading(false));
  };

  // populating the field with the selected values
  // console.log("selecte sec part is========================", selectedSecondaryPart)
  useEffect(() => {
    if (selectedSecondaryPart) {
      selectedSecondaryPart.store_location &&
        setSerialNumber(selectedSecondaryPart.serial_number)
      setLocation(selectedSecondaryPart.store_location);
      setQty(parseInt(selectedSecondaryPart.quantity));
      setBaseStation(selectedSecondaryPart.base_station_id)
      setOwner(selectedSecondaryPart.line_customer_id);
      selectedSecondaryPart.shelf_life_expiry &&
        setDate(selectedSecondaryPart.shelf_life_expiry);
      selectedSecondaryPart.mro_owned &&
        setMroOwnedPart(selectedSecondaryPart.mro_owned);
    }
  }, [selectedSecondaryPart]);

  useEffect(() => {
    if (selectedSecondaryPart.base_station_id)
      PartsClientServices.fetchStoreLocations(
        selectedSecondaryPart.base_station_id,
        partNumber
      ).then((res) => setStoreLocation(res.data));
  }, [selectedSecondaryPart.base_station_id, partNumber]);



  return (
    <RightDialog open={open} dialogCloseHandler={dialogCloseHandler}>
      <div className={classes.container}>
        <Typography variant="h4" className={classes.heading}>
          Edit
        </Typography>

        <div style={{ display: "flex", marginTop: "14px" }}>
          <Typography
            variant="body1"
            style={{ marginRight: "50px" }}
            color="primary"
          >
            Batch #
          </Typography>
          <Typography variant="body1">
            {selectedSecondaryPart.batch_number}
          </Typography>
        </div>
        <div style={{ display: "flex", marginTop: "14px" }}>
          <Typography
            variant="body1"
            style={{ marginRight: "30px" }}
            color="primary"
          >
            Receipt by
          </Typography>
          <Typography variant="body1">
            {selectedSecondaryPart.created_by}
          </Typography>
        </div>

        <form onSubmit={updateDetailsHandler} noValidate>
          <MROTextField
            name={"base_station_id"}
            label={"Base"}
            InputLabelProps={{ shrink: true }}
            value={baseStation}
            autoFocus={error.base_station_id}
            required
            select
            error={error.base_station_id}
            onChange={(e) => {
              setBaseStation(e.target.value)
              setError({ ...error, base_station_id: null });
            }}
          >
            {props?.baseStations?.map((item) => (
              <MenuItem
                value={item.base_station_id}
                key={item.base_station_id}
              >
                {item.station}
              </MenuItem>
            ))}
          </MROTextField>
          <FormControlLabel
            style={{ marginLeft: 0 }}
            control={
              <Switch
                checked={mroOwnedPart}
                onChange={(e) => setMroOwnedPart(e.target.checked)}
                // name="checkedB"
                color="primary"
              />
            }
            label="MRO Owned Part?"
            labelPlacement="start"
          />
          <div>
            <Autocomplete
              freeSolo
              disableClearable
              options={storeLocation || []}
              getOptionLabel={(option) =>
                option.location_name ? option.location_name : ""
              }
              inputValue={location || ""}
              onChange={(event, newValue, option) => {
                setLocation(newValue.location_name);
                setLocationId(newValue.store_location_id);
              }}
              // onInputChange={(e, newValue, option) => {
              //   console.log(
              //     "from auto input onchange -------------",
              //     newValue,
              //     option.store_location_id
              //   );
              // }}
              renderInput={(params) => (
                <MROTextField
                  {...params}
                  name={"store_location"}
                  label={"Store Location"}
                  required
                  autoFocus={error.store}
                  error={error.store}
                  helperText={error.store}
                  onChange={(e) => {
                    setError({ ...error, store: null });
                    setLocation(e.target.value);
                    console.log(
                      "from text field -------------",
                      e.target.value
                    );
                  }}
                  InputLabelProps={{ shrink: true }}
                // className={classes.inputField}
                />
              )}
            />

            <MROTextField
              name={mroOwnedPart ? "" : "line_customer_id"}
              label={"Owner *"}
              select
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {
                setError({ ...error, owner: null });
                setOwner(e.target.value);
              }}
              autoFocus={error.owner}
              error={error.owner}
              helperText={error.owner}
              value={mroOwnedPart ? orgName : owner}
              disabled={mroOwnedPart}
            >
              {mroOwnedPart && <MenuItem value={orgName}>{orgName}</MenuItem>}
              {owners &&
                owners.map((item) => (
                  <MenuItem value={item.line_customer_id}>{item.name}</MenuItem>
                ))}
            </MROTextField>

            {shelfLifeControl && (
              <DateField
                label={"Shelf Life expiry"}
                name="shelf_life_expiry"
                value={date}
                onChange={(e) => {
                  setDate(e);
                }}
                defaultValue={format24HrDateOnly(date)}
              // error={error.datepicker1}
              // onChange={() => {
              //   setError({ ...error, datepicker1: false });
              // }}
              // helperText={error.datepicker1 && "*Invalid date"}
              />
            )}

            <MROTextField
              name={"serial_number"}
              label={"Serial Number"}
              value={serialNumber}
              onChange={(e) => {
                setSerialNumber(e.target.value)
              }}
              InputLabelProps={{ shrink: true }}
            />

            <div style={{ display: "flex", alignItems: "center" }}>
              <MROTextField
                name={"quantity"}
                label={"Quantity"}
                placeholder="QTY"
                required
                type="number"
                autoFocus={error.qty}
                error={error.qty}
                helperText={error.qty}
                onChange={(e) => {
                  setError({ ...error, qty: null });
                  setQty(parseInt(e.target.value.replace(/[A-Za-z]/g, "")));
                }}
                InputLabelProps={{ shrink: true }}
                value={isNaN(qty) ? "" : qty}
                fullWidth={false}
                style={{ marginRight: "15px" }}
              />

              <ButtonGroup
                color="primary"
                aria-label="outlined primary button group"
              >
                <Button
                  onClick={() =>
                    setQty((prevState) =>
                      prevState > 1 ? prevState - 1 : prevState
                    )
                  }
                  style={{ width: "46px", height: "36px" }}
                >
                  <RemoveIcon />
                </Button>
                <Button
                  onClick={() =>
                    setQty((prevState) =>
                      isNaN(prevState) ? 1 : prevState + 1
                    )
                  }
                  style={{ width: "46px", height: "36px" }}
                >
                  <AddIcon />
                </Button>
              </ButtonGroup>
            </div>
            <Grid item xs={12} style={{ marginBottom: "20px" }}>
              {/* <UploadButton
                name={"material_certificate"}
                action={"Upload Document"}
              /> */}
              {/* <FileUpload
                name={"material_certificate"}
                action={"Upload Document"}
                label="Part certification document (C of C, Form 1 etc) *"
                setFiles={() => {}}
              /> */}
              {/* <div style={{ marginBottom: 20 }}>
                <p className={classes.label}>
                  Part certification document (C of C, Form 1 etc) *
                </p>
                <input
                  // accept="image/*"

                  style={{ display: "none" }}
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={fileChangeHandler}
                /> */}
              {/* <label htmlFor="contained-button-file">
                  <MROButton
                    variant="contained"
                    color="secondary"
                    component="span"
                  >
                    {isFileUploading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <>
                        <CloudUploadIcon style={{ marginRight: 10 }} /> Upload
                        Document
                      </>
                    )}
                  </MROButton>
                </label>
              </div> */}
              {/* <FetchedFilePreview
                fileDeleteHandler={fileDeleteHandler}
                files={files}
                isLoading={isDeletingFile}
                selectedFileId={selectedFileId}
              /> */}
            </Grid>
          </div>

          <div
            style={{
              marginTop: "auto",
              marginBottom: "60px",
              alignSelf: "flex-end",
            }}
          >
            <MROButton
              style={{ marginRight: "20px" }}
              type="button"
              variant={"contained"}
              onClick={dialogCloseHandler}
            >
              Cancel
            </MROButton>
            <MROButton type="submit" variant={"contained"} color="primary">
              {isLoading ? <CircularProgress size={20} /> : "Save"}
            </MROButton>
          </div>
        </form>
      </div>
    </RightDialog>
  );
}

export default EditDialog;
