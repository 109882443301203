// import request from "./common/api_client";
import requestrApi from "./common/boxr_api_client";

const dataUrl = {
    handOverDeps: 'handovers/fetch_dependencies',
    getAckOpenhandoverData: 'handovers/set_handover',
    training: '/trainings',
    competency: '/competencies',
    fetchTraining: 'user_compliances/fetch_user_trainings',
    fetchCompetency: 'user_compliances/fetch_user_competencies',
    storageCondition: '/storage_conditions',
    procedureCategories: '/procedure_categories',
    aogSMS: '/line_customers/aog_sms_contacts',
    reportEmail: '/line_customers/report_emails',
    kpiEmail: '/line_customers/kpi_emails',
    commercialRates: '/line_customers/commercial_rates',
    commercialRatesData: '/line_customers/fetch_commercial_rate',
    scheduledCheckHours: '/line_customers/scheduled_check_hours',
    delayCodes: '/line_customers/delay_codes',
    fixedPriceList: '/line_customers/aircraft_type_groups',
    scheduledChecks: '/line_customers/scheduled_check_hours',
    userCompliance: '/user_compliances/listing_users',
    complianceCompetency: '/user_compliances/listing_competencies',
    complianceTraining: '/user_compliances/listing_trainings',
    competencyBaseList: '/competencies/listing_base_applications',
    competencyGroupList: '/competencies/listing_group_applications',
    trainingBaseList: '/trainings/listing_base_applications',
    trainingGroupList: '/trainings/listing_group_applications',
    approvalType: '/approval_types',
    approvalTypeGroup: '/group_types',
    companyApprovals: '/company_approvals',
    authList: '/auth_lists',
    typePermissions: '/approval_types/listing_type_permissions',
    approvalScope: '/group_types/listing_approval_types',
    authFilters: '/auth_lists/fetch_dependencies',
    authGroupsCreateList: '/auth_lists/fetch_approval_types',
    authPermissionList: '/auth_lists/fetch_approval_permissions',
    authUserDetail: '/auth_lists/fetch_user_information',
    workOrder: '/work_orders',
    customerInfo: '/line_customers/customer_infos',
    taskLibrary: '/task_libraries',
    ataChapters: '/task_libraries/fetch_ata_chapters',
    ataScheduledChecks: 'ata_chapters/listing_scheduled_checks',

    vehicleList: '/vehicles',
    basesList: '/vehicles/fetch_base_stations',
    vehicleInspection: '/vehicle_inspections',
    vehicleInspectionFilters: '/vehicle_inspections/fetch_filter_dependencies',
    vehicleInspectionDetail: '/vehicle_inspections/vehicle_inspection_info',
    workOrderSections: '/operation_fields/fetch_sections',
    workOrderSectionDetail: '/operation_fields/fetch_fields',
    workOrderDependencyTable: '/',
    stationInspections: '/station_inspections',
    stationInspectionFilters: '/station_inspections/fetch_filter_dependencies',
    stationCheckDetail: '/station_inspections/station_inspection_info',
    baseStationChecks: '/base_stations/fetch_station_checks',
    userApprovals: '/user_compliances/approval_information',
    userGeneralFiles: '/user_compliances/general_files',
    competencyQuestions: '/competencies/competency_categories',
    adhocForms: '/adhoc_forms',
    LineManagers: '/base_stations/fetch_user_information',
    authListVersionDownload: '/auth_lists/version_download',
    handoverHistory: '/handovers',
    handoverChecks: '/handovers/setups',
    recencyTask: '/recencies',
    recency: '/user_compliances/recency',
    adhocFormDetail: '/adhoc_forms/fetch_adhoc_info',
    adhocDependency: '/adhoc_forms/adhoc_dependency',
    users: '/users/lm_compliance_status',
    vendorParts: '/vendor_parts',
    operational_tasks: '/operational_tasks',
    // operation_workorder_tooling: '/operation_fields/work_order_toolings',
    operation_workorder_tooling: '/operation_fields/operation_toolings',
    fetch_tooling: '/operation_fields/fetch_toolings',
    accountScheduledChecks: '/accounts/scheduled_checks',
    accountsWorkOrderReports: '/accounts/workorder_reports',
    accountsTowing: '/accounts/towing',
    accountsGND: '/accounts/gnd_equip',
    equipmentList: '/accounts/list_equipments',
    accountsCallOutFees: '/accounts/callout_fees',
    accountsCallOutHrs: '/accounts/callout_reports',
    accountsDelayReports: '/accounts/delay_reports',
    accountsTechLogMaint: '/accounts/tech_log_maint_tasks',
    accountsCabinMaint: '/accounts/cabin_maint_tasks',
    accountsNonFixedPrice: '/accounts/non_fixed_price_tasks',
    accountsDefects: '/accounts/defect_tasks',
    accountsPlannedTask: 'accounts/planned_tasks',
    accountsPlannedWorkOrders: "/work_orders/planned_work_order_account",
    accountsOutboundWorkOrders: "/work_orders/outbound_work_order",
    comsumeables: '/accounts/consumables',
    parts: '/accounts/parts',
    baseStations: '/base_stations',
    fetchUserInfo: '/operation_fields/fetch_user_info',
    workOrderListing: '/work_orders/listing_work_orders',
    dashboardHandovers: '/dashboards/handovers',
    dashboardVehicles: '/dashboards/vehicles',
    dashboardToolings: '/dashboards/toolings',
    dashboardSelfLifeExpiry: 'dashboards/shelf_life_expiry',
    dashboardVehicleMotPass: '/dashboards/vehicle_mot_pass',
    operationTotalHours: '/operation_fields/calculate_man_hours',
    operationTaskLibrary: '/operation_fields/fetch_task_libraries',
    listingATAChapter: '/ata_chapters/listing_ata_chapters',
    workOrderSendEmail: '/work_orders/send_work_order_mail',
    primeParts: '/primary_parts/listing_prime_parts',
    fetchSuppliers: '/part_suppliers/fetch_suppliers',
    partsFetchDependencies: '/primary_parts/fetch_dependencies',
    primaryPartsBaseLevels: '/primary_parts/listing_base_levels',
    currencies: '/currencies',
    purchaseOrders: '/purchase_orders/listing_orders',
    getCertificates: '/primary_parts/material_certificates',
    generateConsolidatedReport: '/consolidate_reports/generate_report',
    managedParts: '/accounts/managed_parts',
    mroMangedParts: '/part_conditions/listing_conditioned_parts',
    existingQty: '/primary_parts/unique_part_quantity',
    fetchHandOverById: 'handovers/fetch_handover_info',
    fetchRequestTable: '/part_requests/listing_part_requests',
    DelayListing: '/delays/listing_delays',
    CallOutsListing: '/call_outs/listing_callouts',
    stockOptionList: '/part_requests/stock_options',
    allocationList: "/part_requests/allocated_parts_details",
    allocatedList: '/part_conditions/listing_allocated',
    getAllRequest: '/part_requests/procurement_requests_listing',
    getAllProcRequest: '/procurement_requests/procurement_requests_listing',
    getAllocatedJobReq: "/part_requests/allocated_job_req_details",
    getAccountsFormListing: '/accounts/form_listing',
    getPurchasedItems: '/purchase_orders/po_items',

    // delay
    getDelayAndCalloutTabs: '/operation_fields/fetch_maint_sections',
    getDelayAndCalloutFields: '/operation_fields/fetch_maint_fields',
    //cancellation
    getCancellation: "/line_customers/canclations",


    //Delay Reason field dropdown values api
    delayReasonDpdValues: '/line_customers/delay_codes',

    // Request part
    requestPart: "/primary_parts",

    // compliance => approval => capability
    capabilitiesList: "/capabilities",
    capabilitiesEngineTask: "/capabilities/capabilities_eng_tasks",
    approvalFetchDependencies: "/auth_lists/fetch_filter_dependencies",

    // Request part table in parts and tool section;
    partsAndToolsRequestParts: '/part_requests/operation_request_listing',
    partInfoTable: "/part_requests/allocated_job_req_details",
    getAllKits: "/primary_parts/listing_kits",
    kitsPrimaryPart: "/kits/kit_primary_parts",

    // Admin => Workshop => Admin
    releaseTypesListing: "/release_types",

    // Procurement => Parts => Request => Allocated
    stockOptionsList: "primary_parts/fetch_secondary_parts",
    alternatePartList: "/part_requests/allocated_job_req_details",
    alternatePartOptionsListing: "/primary_parts/list_alternate_parts",


    //workshop admin customer contact details
    allContact: "/customer/contact/all",
    reportEmailWorkshop: "/customer/report_emails/all",

    workshopRequestPart: "/part_requests/operation_request_listing",
    workshopToolingRequestPart: "/toolings/workshop_toolings",
    workshopServices: "/services/operation_listing",
    getAllEquipmentList: "/equipment_requests",
    getWorkshopEquipment: "/equipment_requests/operation_listing",
    approval_tiers: "/approval_tiers",
    services: "/services",
    scheduledCheckRates: "/check_rates",
    // DD Parts
    DDPartListing: "/dd_parts/listing_requests",
    // DDPartListing: "/dd_parts"

    //CommercialRates
    scheduledCheckCus: "/line_customers/scheduled_check_hours",
    scheduledCheckRates: "/check_rates",
    accountScheduledRates: "/accounts/check_rates",
    Gnd_handling_fields_high_lift_equip: "/accounts/gnd_handling_fields",
    Gnd_handling_fields_large_steps: "/accounts/gnd_handling_fields",
    Gnd_handling_fields_medium_steps: "/accounts/gnd_handling_fields",
    Gnd_handling_fields_wheel_change_kit: "/accounts/gnd_handling_fields",
    accountDelayInbound: '/work_orders/inbound_work_orders',
    accountDelayExceeds: '/work_orders/exceeds_gnd_time',

    //procurement usage
    getAllUsageList: "/procurement_usage_list",
    getAllToolData: "/toolings",
    getAllForcast: "procurement_requests/parts_forecast",
    //removed parts
    lineRemoveParts: '/part_conditions/removed_parts',
    commercial_tooling: '/toolings/commercial_rates_toolings',
    //accounts/admin
    mileageRates: "/vehicles/listing_vehicles",
    labourRates: "/user_groups/listing_labour_rates",
    fixedFees: "/line_customers/listing_lc_app_type_rates"
}

export function getData(params, section) {
    return requestrApi({
        url: dataUrl[section],
        method: 'GET',
        params: params,
        paramsSerializer: (params) => {
            // Sample implementation of query string building
            let result = '';
            Object.keys(params).forEach(key => {
                if (params[key] != null) {
                    if (key == "tasks[]" || key == "consumables[]" || key == "aircraft_registrations[]") {
                        const tasksVal = params[key];
                        tasksVal.map(function (eachTask) {
                            result += `${key}=${encodeURIComponent(eachTask)}&`;
                        })
                    }
                    else {
                        result += `${key}=${encodeURIComponent(params[key])}&`;
                    }

                }
            });
            return result.substr(0, result.length - 1);
        }
    })
}

const ListingService = {
    getData
}


export default ListingService;
