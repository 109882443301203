import React, { useContext, useEffect, useReducer, useState } from "react";
import Dialog from "@material-ui/core/Dialog";

import {
    createStyles,
    Grid,
    IconButton,
    makeStyles,
    Typography,

} from "@material-ui/core";
import tableIcons, { options } from "components/universal/table_attributes";
import MaterialTable from "material-table";
import { ToastMessageContext } from "lib/contexts/message_context";
const useStyles = makeStyles((theme) =>
    createStyles({
        modal: {
            "& .MuiDialog-paperWidthSm": {
                minWidth: "750px",
                // height: "110vh"
            },
        },
        container: {
            // minWidth: "1200px",
            // minHeight: "400px",
            padding: "50px",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden !important"
        },

        popupTitle: {
            fontSize: "20px",
            lineHeight: "28px",
            position: "absolute",
            top: "19px",
            bottom: "15px",
            left: "25px",
        },
        divider: {
            borderBottom: `1px solid ${theme.palette.primary.main}`,
        },
        tableTitle: {
            fontSize: "16px",
            lineHeight: "22px",
            color: "#4D4F5C",
            fontWeight: "bold",
            marginTop: "19px",
            marginBottom: "16px",
        },
        tableCell: {
            height: '32px',
            justifyContent: "space-between",
            whiteSpace: "normal",
            wordWrap: "break-word",

        },
    })
);

function EstimationHistory(props) {
    const { handleClose, currentSelection } = props
    const classes = useStyles();
    const message = useContext(ToastMessageContext);
    const [data, setData] = useState([])
    const [Loading, setLoading] = useState(false)


    useEffect(() => {
        setLoading(true)

        setData(currentSelection?.history)
        setLoading(false)

    }, [currentSelection])

    const tableOptions = {
        ...options,
        paging: false,
        search: false,
        toolbar: false,
        rowStyle: {
            height: '42px'
        }
    };

    const column = [

        {
            title: "Revised",
            field: "revised",

            headerStyle: {
                textAlign: "left",
                paddingLeft: "15px",

            },
            cellStyle: {
                textAlign: "left",
                paddingLeft: "15px",
            },
        },
        {
            title: "User",
            field: "user",


        },

        {
            title: "TAT",
            field: "tat",

        },
        {
            title: "ETD",
            field: "etd",

        },

        {
            title: "Reason",
            field: "reason",
            width: '50%',
            headerStyle: {
                textAlign: "left",

                width: '50%',
            },
            cellStyle: {
                textAlign: "left",

                width: '50%',
            },
        },
    ];





    return (
        <div>
            <Dialog
                open={true}
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                className={classes.modal}
            >
                <div className={classes.container}>
                    <Typography
                        variant="body1"
                        color="primary"
                        style={{ marginBottom: "20px" }}
                        className={classes.popupTitle}
                    >
                        History
                    </Typography>

                    <Grid item xs={6} style={{ marginTop: "10px", }}>
                        <MaterialTable
                            style={{
                                boxShadow: "0px 1px 3px #00000033",
                                borderRadius: "4px",
                                width: "650px",
                                // minWidth: "500px"
                            }}

                            options={tableOptions}
                            title=""
                            icons={tableIcons}
                            columns={column}
                            data={data || []}
                            isLoading={Loading}


                        />
                    </Grid>
                </div>
            </Dialog>

        </div>
    )
}


export default EstimationHistory

