import React, { useEffect, useState } from "react";
import MROFullScreenRightDrawer from "components/fullwidth_dialog";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import Typography from "@material-ui/core/Typography";
import { Box, useTheme } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { ICON_COLOUR } from "lib/constants/style_constants";
import FileUpload from "components/form_components/fileUpload";
import ManageSpecialistService from "./ManageSpecialistService";
import { formatDate, formatMMMDate } from "lib/utils/common_utils";
import { userDetails } from "lib/utils/helperFunctions";
import { BASE_API_URL_BOXR } from "lib/constants";
import ServiceRequestListClientServices from "lib/services/api/procurement/Services";
import LoadingIndicator from "components/universal/loading_indicator";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: "3%",
  },
  appBar: {
    backgroundColor: "#fff",
    color: "#000000CC",
    boxShadow: "0px 3px 6px #0000001A",
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  description: {
    borderRadius: "5px",
    backgroundColor: "#F5F5F5",
    padding: theme.spacing(2),
    // boxShadow: "0px 1px 4px #0000001A",
  },
  fontDescription: {
    color: "#4D4F5C",
    fontSize: "14px",
  },
  partValue: {
    fontSize: "14px",
    color: "#4D4F5C",
    padding: "2px",
    display: "flex",
    alignItems: "center",
  },
  partKey: {
    "& p": {
      fontSize: "14px",
      padding: "1px",
    },
  },
  partCard: {
    borderRadius: "8px",
    padding: "17px 24px",
    "& p": {
      fontSize: "14px",
      padding: "5px",
    },
  },
  sec1: {
    borderRadius: "10px",
    backgroundColor: "#fff",
    padding: theme.spacing(5),
    boxShadow: "0px 1px 4px #0000001A",
  },
  sec1: {
    borderRadius: "10px",
    backgroundColor: "#fff",
    padding: theme.spacing(5),
    boxShadow: "0px 1px 4px #0000001A",
  },
}));

function SpecialistService(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [isEdit, setIsEdit] = useState(null);
  const [loading, setLoading] = useState(false)
  const [releaseReqData, setReleaseReqData] = useState({})
  const { handleClose, workshop_id, dependencies, currentSelection, getServices, orderState, data, setCurrentSelection } = props;
  let releaseBStatus = releaseReqData?.data?.release_type_b === undefined || releaseReqData?.data?.release_type_b === null
  const [techData, setTechData] = useState([])


  const GetInfoCard = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    return (
      <Box display="flex">
        <Box flex={10} className={classes.partKey}>
          <Typography
            style={{ padding: "5px", color: theme.palette.primary.main }}
            color="primary"
          >
            {props?.keyName}
          </Typography>
        </Box>
        <Box flex={22} className={classes.partValue} style={{ marginRight: props.marginRight && '-52px' }}>
          {props?.value ?? "-"}
        </Box>
      </Box >
    );
  };

  useEffect(() => {
    if (currentSelection?.service_type) {
      let value = currentSelection?.service_type.toLowerCase()
      handleReleaseRequirement(value)
    }
  }, [currentSelection?.service_type])

  useEffect(() => {
    if (data?.data !== undefined) {

      let value = data?.data.filter((item) => {
        if (item.id === currentSelection?.id) {
          setTechData(item.tech_data)

        }
      }
      )

    }

  }, [data])
  console.log("data======", techData)


  const handleReleaseRequirement = async (value) => {
    setLoading(true)
    // let part_category = value === 1 ? "contracted" : "subcontracted"
    const params = {
      workshop_order_id: workshop_id,
      service_type: value
    }
    await ServiceRequestListClientServices.releaseRequirement(params)
      .then((res) => {
        if (res.success) {
          setReleaseReqData(res)
          getServices()
        }
      })
      .catch((err) => {
        console.log(err)
      }).finally(() => {
        setLoading(false)
      })
  }

  return (
    <div>
      <MROFullScreenRightDrawer open={true}>
        <AppBar position="static" className={classes.appBar} elevation={0}>
          <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
            <Box display="flex" alignItems="center">
              <IconButton
                onClick={handleClose}
                edge="start"
                className={classes.backButton}
                color="inherit"
                aria-label="back"
              >
                <BackIcon />
              </IconButton>
              <Typography variant="subtitle2" style={{ fontSize: "17px" }}>
                Specialist Service
              </Typography>
            </Box>
            {orderState != "Closed" && props?.permission.write && !currentSelection?.purchased &&
              <IconButton onClick={() => setIsEdit("Update")}>
                <EditIcon fontSize="small" color={ICON_COLOUR} />
              </IconButton>
            }
          </Toolbar>
        </AppBar>

        {!loading ? (
          <div className={classes.wrapper}>
            <Box>
              <Typography
                style={{
                  color: theme.palette.primary.main,
                  margin: "12px 0px 12px 0px",
                  fontSize: "17px",
                }}
                color="primary"
                variant="subtitle2"
              >
                Request Details
              </Typography>

              <Box
                display="flex"
                alignItems="center"
                //   justifyContent={(loading || _.isEmpty(partDetails)) && "center"}
                bgcolor="#F5F5F5"
                className={classes.partCard}
                width='100%'
              >
                <Box width={'44.2%'}>
                  <div >
                    <GetInfoCard
                      keyName={"Raised by"}
                      value={currentSelection?.raised_by || "-"}
                    />
                    <GetInfoCard
                      keyName={"Action #"}
                      value={currentSelection?.workshop_action_number || "-"}
                    />
                    <GetInfoCard
                      keyName={"Scope"}
                      value={currentSelection?.scope || "-"}
                    />

                    <GetInfoCard
                      keyName={"Insp Auth"}
                      value={currentSelection?.insp_auth || "-"}
                    />
                    <GetInfoCard
                      keyName={"Service Type"}
                      value={currentSelection?.service_type || "-"}
                    />
                    <GetInfoCard
                      keyName={"Release Requirement"}
                      value={
                        <>
                          <span>
                            {releaseReqData?.data?.release_type_a || ""}
                          </span>
                          <span> {releaseBStatus ? "" : <span style={{ marginLeft: "4px" }}></span>}{releaseBStatus ? "" : "and"}{releaseBStatus ? "" : <span style={{ marginLeft: "4px" }}></span>}
                            {releaseReqData?.data?.release_type_b || ""}
                          </span>
                          <span>
                            {releaseReqData?.data?.message || ""}
                          </span>
                        </>
                      }
                    />
                  </div>
                </Box>
                <Box width={'45%'}>
                  <div>
                    <GetInfoCard keyName={"Raised"} value={formatMMMDate(currentSelection?.raised) || "-"} />
                    <GetInfoCard
                      keyName={"Location"}
                      value={currentSelection?.location === "mro_on_site" ? "MRO Site" : "Supplier Site" || "-"}
                    />

                    <GetInfoCard
                      keyName={"P/N sent off-site"}
                      value={currentSelection?.part_number || "-"}
                    />
                    <GetInfoCard
                      keyName={"S/N sent off-site"}
                      value={currentSelection?.serial_number || "-"}
                    />
                    <GetInfoCard
                      keyName={"Desired Date"}
                      value={formatDate(currentSelection?.desired_date) || "-"}
                    />
                    <GetInfoCard
                      keyName={"Tech Data Ref"}
                      value={currentSelection?.tech_data_ref || "-"}
                    />
                  </div>
                </Box>
              </Box>
            </Box>

            <Typography
              style={{
                color: theme.palette.primary.main,
                margin: "12px 0px 12px 0px",
                fontSize: "17px",
              }}
              color="primary"
              variant="subtitle2"
            >
              Description
            </Typography>
            <Grid className={classes.description}>
              <Typography className={classes.fontDescription}>
                {currentSelection.description}
              </Typography>
            </Grid>

            <Box>
              <Typography
                style={{
                  color: theme.palette.primary.main,
                  margin: "12px 0px 12px 0px",
                  fontSize: "17px",
                }}
                color="primary"
                variant="subtitle2"
              >
                Tech Data
              </Typography>
              <Grid>
                <FileUpload
                  defaultValue={
                    techData !== undefined
                      ? techData?.map((item) => ({
                        filename: item?.filename,
                        url: `${item?.url}`,
                        id: item?.id,
                      }))
                      : ""
                  }
                  APIUrl={""}
                  showBorderOnNoFiles={true}
                  uploadButtonView={true}
                  multiple={true}
                  name={"shipping_condition_photo[]"}
                  action={"Upload Document"}
                  id="shipping_condition_photo[]"
                />
              </Grid>
            </Box>

            <Box>
              <Typography
                style={{
                  color: theme.palette.primary.main,
                  margin: "12px 0px 12px 0px",
                  fontSize: "17px",
                }}
                color="primary"
                variant="subtitle2"
              >
                Purchasing Details
              </Typography>

              <Box
                display="flex"
                alignItems="center"
                //   justifyContent={(loading || _.isEmpty(partDetails)) && "center"}
                bgcolor="#F5F5F5"
                className={classes.partCard}
                width={'100%'}
              >
                <Box width={'45%'}>
                  <div >
                    <GetInfoCard keyName={"Service Order #"} value={currentSelection?.order_number || "-"} marginRight={true} />
                    <GetInfoCard keyName={"Supplier Ref"} value={currentSelection?.supplier_quote_ref || "-"} marginRight={true} />
                    <GetInfoCard keyName={"Purchased By"} value={currentSelection?.purchased_by || "-"} marginRight={true} />
                  </div>
                </Box>
                <Box width={'45%'}>
                  <div >
                    <GetInfoCard keyName={"Supplier"} value={currentSelection?.supplier || "-"} />
                    <GetInfoCard keyName={"Planned for"} value={formatDate(currentSelection?.planned_date)} />
                    <GetInfoCard keyName={"Purchased"} value={currentSelection?.purchased_at || "-"} />
                  </div>
                </Box>
              </Box>
            </Box>

            <Box>
              <Typography
                style={{
                  color: theme.palette.primary.main,
                  margin: "12px 0px 12px 0px",
                  fontSize: "17px",
                }}
                color="primary"
                variant="subtitle2"
              >
                Closure
              </Typography>

              <Box
                display="flex"
                alignItems="center"
                //   justifyContent={(loading || _.isEmpty(partDetails)) && "center"}
                bgcolor="#F5F5F5"
                className={classes.partCard}
                width={'100%'}
              >
                <Box width={'45%'}>
                  <div >
                    <GetInfoCard
                      color="primary"
                      keyName={"Dispatched by"}
                      value={currentSelection?.dispatched_by || "-"}
                      marginRight={true}
                    />
                    <GetInfoCard
                      color="primary"
                      keyName={"Received by"}
                      value={currentSelection?.received_by || "-"}
                      marginRight={true}
                    />
                    <GetInfoCard
                      color="primary"
                      keyName={"Closed by"}
                      value={currentSelection?.insp_by || "-"}
                      marginRight={true}
                    />
                  </div>
                </Box>
                <Box width={'45%'}>
                  <div >
                    <GetInfoCard
                      color="primary"
                      keyName={"Dispatched"}
                      value={currentSelection?.dispatched_at || "-"}
                    />
                    <GetInfoCard
                      color="primary"
                      keyName={"Received"}
                      value={currentSelection?.received_at || "-"}
                    />

                    <GetInfoCard
                      color="primary"
                      keyName={"Closed"}
                      value={currentSelection?.insp_at || "-"} />
                  </div>
                </Box>
              </Box>
            </Box>

            <Grid>
              <Typography
                style={{
                  color: theme.palette.primary.main,
                  margin: "12px 0px 12px 0px",
                  fontSize: "17px",
                }}
                color="primary"
                variant="subtitle2"
              >
                Certification (C of C / Form 1)
              </Typography>
              <Grid>
                <FileUpload
                  defaultValue={
                    currentSelection?.order_certificates !== undefined
                      ? currentSelection?.order_certificates?.map((item) => ({
                        filename: item?.filename,
                        url: `${item?.url}`,
                        id: item?.id,
                      }))
                      : ""
                  }
                  APIUrl={""}
                  showBorderOnNoFiles={true}
                  uploadButtonView={true}
                  multiple={true}
                  name={"shipping_condition_photo[]"}
                  action={"Upload Document"}
                  id="shipping_condition_photo[]"
                />
              </Grid>
            </Grid>
          </div>) : (
          <LoadingIndicator variant={"block"} />
        )}
      </MROFullScreenRightDrawer>

      {isEdit === "Update" && (
        <ManageSpecialistService
          open={true}
          currentSelection={currentSelection}
          onClose={() => setIsEdit(null)}
          action={isEdit}
          dependencies={dependencies}
          workshop_id={workshop_id}
          closeHandler={handleClose}
          getServices={getServices}
          techData={techData}
        />
      )}
    </div>
  );
}

export default SpecialistService;
