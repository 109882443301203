import requestApi from "lib/services/api/common/boxp_api_client";


const createMessage = (formData) => {
    return requestApi({
        url: '/new_message',
        method: 'POST',
        data: formData

    })

}

function fetchDependency(params) {
    return requestApi({
        url: '/fetch_users',
        method: 'GET',
        params: params
    })

}
function getListingConversation(params) {
    return requestApi({
        url: '/listing_conversations',
        method: 'GET',
        params: params
    })

}
function getGroupMessageDetails(params) {
    return requestApi({
        url: '/group_message_details',
        method: 'GET',
        params: params
    })

}

function getChatMessage(params) {
    return requestApi({
        url: '/chat_messages',
        method: 'GET',
        params: params
    })

}

function updateSubjectTitle(formData) {
    return requestApi({
        url: '/update_thread_subject?_method=PUT',
        method: 'POST',
        data: formData
    })

}
function viewParticipantDetails(params) {
    return requestApi({
        url: '/view_participants',
        method: 'GET',
        params: params
    })

}

function updateArchiveState(formData) {
    return requestApi({
        url: '/archive_thread?_method=PUT',
        method: 'POST',
        data: formData
    })

}
function updateReplyMessage(formData) {
    return requestApi({
        url: '/reply_message',
        method: 'POST',
        data: formData
    })

}

function getAllComplianceReadAndSign(params) {
    return requestApi({
        url: '/read_sign_messages',
        method: 'GET',
        params: params
    })
}

function fetchCategoryDependencies(params) {
    return requestApi({
        url: '/read_sign_categories',
        method: 'GET',
        params: params
    })
}
function fetchCustomerDependencies(params) {
    return requestApi({
        url: '/fetch_customers',
        method: 'GET',
        params: params
    })
}
function getComplianceReadDetails(params) {
    return requestApi({
        url: '/get_read_sign_message',
        method: 'GET',
        params: params
    })
}

function getTotalUnReadCount(params) {
    return requestApi({
        url: '/total_unread_messages',
        method: 'GET',
        params: params
    })
}

function updateUnReadMessageStatus(formData) {
    return requestApi({
        url: '/read_message?_method=PUT',
        method: 'POST',
        data: formData
    })

}

function updateReadStatusById(params) {
    return requestApi({
        url: '/read_bulk_messages?_method=PUT',
        method: 'POST',
        params
    })

}

function fetchApprovalTypes(params) {
    return requestApi({
        url: '/fetch_approval_types',
        method: 'GET',
        params: params
    })
}


function getReadSignListing(params) {
    return requestApi({
        url: '/reading_list',
        method: 'GET',
        params: params
    })
}


function removeUserFromChat(params) {
    return requestApi({
        url: '/remove_user_from_chat',
        method: 'DELETE',
        params: params
    })

}

function updateReadAndSign(formData) {
    return requestApi({
        url: '/edit_read_sign?_method=PUT',
        method: 'POST',
        data: formData
    })

}

export const MessageServices = {
    createMessage,
    fetchDependency,
    getListingConversation,
    getGroupMessageDetails,
    getChatMessage,
    updateSubjectTitle,
    viewParticipantDetails,
    updateArchiveState,
    updateReplyMessage,
    getAllComplianceReadAndSign,
    fetchCategoryDependencies,
    fetchCustomerDependencies,
    getComplianceReadDetails,
    getTotalUnReadCount,
    updateUnReadMessageStatus,
    updateReadStatusById,
    fetchApprovalTypes,
    getReadSignListing,
    removeUserFromChat,
    updateReadAndSign


};

