import React, { useContext, useState, useEffect } from 'react'
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import { Dialog, DialogContent, DialogTitle, Box, MenuItem } from "@material-ui/core";
import MROButton from 'components/buttons';
import { Typography } from "@material-ui/core";
import { ToastMessageContext } from "lib/contexts/message_context";
import DateField from 'components/form_components/DateField';
import MROTextField from "components/form_components/TextField";
import ServiceRequestListClientServices from "lib/services/api/procurement/Services";
import { MaintenanceServices } from 'lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation';
import { WorkshopContext } from 'views/Operations/Workshop/WorkshopContext/WorkshopContext';
import moment from 'moment';
import { getOrganisationId } from 'lib/utils/common_utils';

const useStyles = makeStyles((theme) => ({
    formWrapper: {
        background: "#fff",
        width: "100%",
    },
    title: {
        display: "flex",
        justifyContent: "left",
        "& h2": {
            //   color: theme.palette.primary.main,
            fontSize: "40px",
            fontWeight: 40,
        },
    },
    subTitle: {
        fontSize: "16px",
        fontWeight: "bold",
        marginBottom: "20px",
        color: "#4D4F5C",
    },
    card: {
        padding: "0 25px",
    },
    actionButtons: {
        paddingBottom: "33px",
        textAlign: "end",
        marginRight: "6px",
        marginTop: '21px',
        "& button:not(:last-child)": {
            marginRight: "20px",
        },
    },
    purchaseGrid: {
        paddingTop: "0 !important",
        paddingBottom: "0 !important",
        height: "85px",
    },
    purchaseStock: {
        marginBottom: "75px",
        maxWidth: "340px",
        marginTop: '10px'
    },
    modal: {
        "& .MuiDialog-paperWidthMd": {
            width: "24%",
        },
    }
}));


export default function AdditionalData(props) {
    const classes = useStyles();
    const { handleClose, currentClosedDetails, currentPlanningDetails, selectedRow, copyTab } = props
    const [actionDpdOptions, setActionDpdOptions] = useState([]);
    const message = useContext(ToastMessageContext)
    const { setServiceStatus } = useContext(WorkshopContext)
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false)

    const getActions = async () => {
        try {
            const { data } = await MaintenanceServices.getActionsDpdOptions({
                id: currentPlanningDetails.id,
            });
            setActionDpdOptions(data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getActions();
    }, []);

    const handleCopyFile = async (id) => {
        const formData = new FormData()
        formData.append("from_service_id", selectedRow.id)
        formData.append("to_service_id", id)
        formData.append("workshop_order_id", currentClosedDetails?.id)
        await MaintenanceServices.copyTemplateServicesFiles(formData).then((res) => {

        }).catch(() => {

        })

    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        let userDetails = JSON.parse(sessionStorage.getItem("user"));
        let valid = true;
        let errors = {
            workshop_action_id: false,
            desired_date: false

        };
        if (formData && formData.get("workshop_action_id")?.length === 0) {
            errors.workshop_action_id = true;
            valid = false;
        }
        if (copyTab === "normal") {
            if (formData && formData.get("desired_date").length === 0) {
                errors.desired_date = true;
                valid = false;
            }
        }
        if (!valid) {

            setError(errors);
            return;
        }

        if (copyTab === "normal") {
            setLoading(true)
            formData.append("service_id", selectedRow.id)
            formData.append("location", selectedRow.location === "Supplier Site" ? "supplier_off_site" : "mro_on_site");
            formData.append("part_scope_id", selectedRow.part_scope_id);
            formData.append("approval_type_id", selectedRow?.approval_type_id);
            formData.append("part_category_id", selectedRow.part_category_id)
            formData.append("user_id", userDetails?.id);
            formData.append("workshop_order_id", currentPlanningDetails?.id);
            formData.append("description", selectedRow?.description);
            formData.append("tech_data_ref", selectedRow?.tech_data_ref);
            formData.append("base_station_id", currentPlanningDetails?.base_station_id);
            formData.append("from_workshop_action_id", currentPlanningDetails?.id);
            formData.append("to_workshop_action_id", currentClosedDetails?.id);
            formData.append("wst_service_id", selectedRow.id);

            // selectedRow.tech_data.forEach(file => formData.append("tech_data", file))
            // selectedRow.order_reports.forEach(file => formData.append("order_reports", file))


            await ServiceRequestListClientServices.createServices(formData).then((response) => {
                if (response.success) {
                    setServiceStatus(true)
                    handleClose()
                    message.showToastMessage({
                        message: "Copied successfully ",
                        variant: "success",
                    });
                }
            }).catch((error) => {
                message.showToastMessage({
                    message: "Something went wrong",
                    variant: "error",
                });
            }).finally(() => {
                setLoading(false)
            })
        }
        else if (copyTab === "template") {
            setLoading(true)
            // const date = formData.get("desired_date")
            // formData.delete("desired_date")
            // formData.append("desired_date", moment(date, "DD/MM/YYYY").format("YYYY-MM-DD"));
            formData.append("location", selectedRow.location === "Supplier Site" ? "supplier_off_site" : "mro_on_site");
            formData.append("part_scope_id", selectedRow.part_scope_id);
            formData.append("approval_type_id", selectedRow?.approval_type_id);
            formData.append("part_category_id", selectedRow.part_category_id)
            formData.append("workshop_order_id", currentPlanningDetails?.id);
            formData.append("description", selectedRow?.description);
            formData.append("tech_data_ref", selectedRow?.tech_data_ref);
            formData.append("organisation_id", getOrganisationId());

            await MaintenanceServices.addPlanningTemplateService(formData).then((response) => {
                if (response.success) {
                    handleCopyFile(response.id)

                    setServiceStatus(true)
                    handleClose()
                    message.showToastMessage({
                        message: "Copied successfully ",
                        variant: "success",
                    });
                }
                if (response.error) {
                    setServiceStatus(false)
                    handleClose()
                    message.showToastMessage({
                        message: response?.error ? response?.error : "Something went wrong",
                        variant: "error",
                    });
                }

            }).catch((response) => {
                message.showToastMessage({
                    message: response?.error ? response?.error : "Something went wrong",
                    variant: "error",
                });
            }).finally(() => {
                setLoading(false)
            })
        }
    }

    return (
        <div>
            <Dialog
                maxWidth={"md"}
                open={true}
                className={classes.modal}
                onClose={handleClose}
                aria-labelledby="base-station-manage"
            >
                <DialogTitle>
                    <Typography
                        color="primary"
                        className={classes.title}
                    >
                        Additional Data Required
                    </Typography>
                </DialogTitle>
                <DialogContent className={classes.card}>
                    <div className={classes.formWrapper}>
                        <form noValidate onSubmit={handleSubmit}>


                            <Grid item xs={12}>
                                <MROTextField
                                    required
                                    name={"workshop_action_id"}
                                    label={"Select Action"}
                                    select
                                    error={error.workshop_action_id}
                                    InputLabelProps={{ shrink: true }}
                                    helperText={
                                        error.workshop_action_id ? "*please select action" : null
                                    }
                                    onChange={(e) => {
                                        setError({ ...error, workshop_action_id: false });
                                    }}

                                >
                                    <MenuItem value={"select"}> <span style={{ opacity: "40%" }}>Select</span></MenuItem>
                                    {actionDpdOptions && actionDpdOptions?.map((list) => (
                                        <MenuItem key={list.workshop_action_id
                                        } value={list.workshop_action_id
                                        }>{list.action_number}</MenuItem>
                                    ))}
                                </MROTextField>
                            </Grid>
                            {copyTab === "normal" && <Grid item xs={12}>
                                <DateField
                                    required
                                    name="desired_date"
                                    label={"Desired Date"}
                                    InputLabelProps={{ shrink: true }}
                                    error={error.desired_date}
                                    helperText={error.desired_date ? "*please enter a date" : null}
                                    onChange={(e) => { setError({ ...error, desired_date: false }); }}

                                />
                            </Grid>}

                            <div className={classes.actionButtons}>
                                <MROButton
                                    //   disabled={loading}
                                    type="button"
                                    variant={"contained"}
                                    onClick={handleClose}
                                >
                                    Cancel
                                </MROButton>
                                <MROButton
                                    //   disabled={loading}
                                    type="submit"
                                    variant={"contained"}
                                    color="primary"
                                    loading={loading}
                                >
                                    Complete
                                </MROButton>
                            </div>
                        </form>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}
