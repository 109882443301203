import requestApi from "lib/services/api/common/boxp_api_client";
import { BASE_API_URL_BOXP } from "lib/constants";
// import { BASE_API_URL_BOXR } from "lib/constants";

const addTask = (formData) => {
    return requestApi({
      url: `${BASE_API_URL_BOXP}/task_library`,
      method: "POST",
      data: formData,
    });
  };
const getTaskLibraryData = (id) => {
    return requestApi({
      url: `${BASE_API_URL_BOXP}/task_library/${id}`,
      method: "GET",
    });
  };

  const updateTaskLibrary = (data, id) => {
    return requestApi({
      url: `${BASE_API_URL_BOXP}/task_library/${id}?_method=PUT`,
      method: "POST",
      data,
    });
  };
const deleteTask = (id) => {
    return requestApi({
      url: `${BASE_API_URL_BOXP}/task_library/${id}`,
      method: "DELETE",
    });
  };

export const TaskLibraryServices = {
    addTask,
    getTaskLibraryData,
    updateTaskLibrary,
    deleteTask
};
