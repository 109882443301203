import React, { useContext, useEffect, useReducer, useState } from "react";
import Dialog from "@material-ui/core/Dialog";

import {
    createStyles,
    Grid,
    IconButton,
    makeStyles,
    Typography,

} from "@material-ui/core";
import tableIcons, { options } from "components/universal/table_attributes";
import MaterialTable from "material-table";
import { ToastMessageContext } from "lib/contexts/message_context";
import { getOrganisationId } from "lib/utils/common_utils";
import LoadingIndicator from "components/universal/loading_indicator";
import { canvasToBlob } from 'blob-util'
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import CheckBoxIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import GreenTick from 'assets/CheckTick.svg'
import PlanningLineMaintenance from 'lib/services/api/planning/LineMaintenance/lineEventsandSeries';
import MROButton from "components/buttons";
import { render } from "react-dom";


const useStyles = makeStyles((theme) =>
    createStyles({
        modal: {
            "& .MuiDialog-paperWidthSm": {
                minWidth: "1100px",
                height: "830px"
            },
        },
        container: {
            // minWidth: "1200px",
            // minHeight: "400px",
            padding: "50px",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            // overflow: "hidden !important"
        },

        popupTitle: {
            fontSize: "20px",
            lineHeight: "28px",
            position: "absolute",
            top: "19px",
            bottom: "15px",
            left: "25px",
        },
        actionButtons: {
            marginTop: '25px',
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
        },


    })
);
export default function LinkWorkOrder(props) {

    const { handleClose, currentSelection, eventId, onClose } = props
    const classes = useStyles();
    const message = useContext(ToastMessageContext);
    const [file, setFile] = useState([])
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [totalCount, setTotalCount] = useState(0)
    const [data, setData] = useState([])
    const [busy, setBusy] = useState(false)
    const [checked, setChecked] = useState({});

    const handleChange = (rowData, checked) => {
        setChecked({ ...rowData, checked: true });
    };


    const getListing = () => {

        const params = {
            organisation_id: getOrganisationId(),
            page: page + 1,
            limit: pageSize,
            event_id: eventId
        }

        setLoading(true)
        PlanningLineMaintenance.linkWorkOrderListing(params).then((res) => {
            setData(res.data)
            setTotalCount(res.total_count)
        }).catch(() => {
            message.showToastMessage({
                message: "Something went wrong. Try again!",
                variant: "error",
            });
        }).finally(() => {
            setLoading(false)
        })

    }




    useEffect(() => {

        getListing();

    }, [page, pageSize, totalCount]);


    function container() {
        // Use the fullscreen element if in fullscreen mode, otherwise just the document's body
        return document.fullscreenElement ?? document.body;
    }


    const handleWorkOrder = () => {
        const formData = new FormData()


        if (checked.id === undefined || null) {
            message.showToastMessage({
                message: "Select a Work Order!",
                variant: "error",
            });
            return;
        }
        else {
            setBusy(true)
            formData.append("event_id", eventId)
            formData.append("work_order_id", checked?.id)
            formData.append("task", "link")
            PlanningLineMaintenance.updateLinkUnlinkWorkOrder(formData).then((res) => {
                message.showToastMessage({
                    message: "Work order linked successfully",
                    variant: "success",
                });

                onClose()

            }).catch(() => {
                onClose()
                message.showToastMessage({
                    message: "Something went wrong. Try again!",
                    variant: "error",
                });
            }).finally(() => {
                setBusy(false)
            })
        }
    }


    const tableOptions = {
        ...options,
        page: page,
        total: totalCount,
        pageSize: pageSize,
        toolbar: false,
        search: false,

    };


    const column = [

        {
            title: "WO #",
            field: "work_order_number",

            headerStyle: {
                textAlign: "left",
                paddingLeft: "15px",

            },
            cellStyle: {
                textAlign: "left",
                paddingLeft: "15px",

            },
        },
        {
            title: "Base",
            field: "base_station",

            headerStyle: {

                textAlign: "left",
            },
            cellStyle: {

                textAlign: "left",
            },

        },

        {
            title: "Airline",
            field: "airline",

        }, {
            title: "Aircraft",
            field: "aircraft",
            headerStyle: {

                textAlign: "left",
            },
            cellStyle: {

                textAlign: "left",
            },

        }, {
            title: "Arr Flight #",
            field: "arr_flight",
            headerStyle: {

                textAlign: "left",
            },
            cellStyle: {

                textAlign: "left",
            },
            render: (rowData) => <Typography style={{ color: rowData?.color == 'red' ? "#FF0202" : rowData?.color == 'green' ? "#8CDA5C" : '' }}>{rowData?.arr_flight || "-"}</Typography>
        }, {
            title: "Arrived At",
            field: "arrived_at",
            width: "30%",
            headerStyle: {

                textAlign: "left",
                width: "30%",
            },
            cellStyle: {

                textAlign: "left",
                width: "30%",
            },

        },

        {
            title: "Select",
            field: "hours",
            render: (rowData) =>
                <>
                    <IconButton onClick={() => {
                        handleChange(rowData, true)
                    }}>
                        {checked.id !== undefined && checked.id === rowData.id ? <img src={GreenTick} height={'18px'} /> :
                            <CheckBoxIcon fontSize='14px' checked={true} />}
                    </IconButton>
                </>


        },
    ];

    return (
        <div>
            <Dialog
                open={true}
                onClose={onClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                className={classes.modal}
                container={container()}
            >
                <div className={classes.container}>
                    <div style={{ paddingLeft: '10px' }}>
                        <Typography
                            variant="body1"
                            color="primary"
                            style={{ marginBottom: "20px", marginLeft: '23px' }}
                            className={classes.popupTitle}
                        >
                            Link to Work Order
                        </Typography>
                        <div style={{ paddingTop: '6px', marginLeft: '-12px', paddingBottom: '10px' }}>

                            <InfoOutlinedIcon style={{ fontSize: '18px' }} /><span style={{
                                fontSize: '13px', position: "absolute",
                                marginTop: '0px', paddingLeft: '10px'
                            }}>Select from the table below of unlinked Work Orders matching the Airline and Base from the LM Event</span></div>
                    </div>
                    <div style={{ paddingTop: '20px' }}>
                        <MaterialTable
                            style={{ boxShadow: "0px 1px 3px #00000033", marginTop: "10px" }}
                            icons={tableIcons}
                            classes
                            title={""}
                            columns={column}
                            data={data || []}
                            isLoading={loading}
                            options={tableOptions}
                            localization={{
                                toolbar: {
                                    searchPlaceholder: "Search anything",
                                },
                                body: {
                                    emptyDataSourceMessage: "No Records found",
                                    filterRow: {
                                        filterTooltip: "Filter",
                                    },
                                },
                                header: {
                                    actions: "Actions",
                                },
                            }}
                            onChangePage={(page) => {
                                setPage(page);
                            }}
                            onChangeRowsPerPage={(pageSize) => {
                                setPageSize(pageSize);
                            }}
                            totalCount={totalCount}
                            page={page}

                        />
                    </div>
                    <Grid className={classes.actionButtons} spacing={1} container style={{ paddingBottom: '20px' }}>
                        <Grid item>
                            <MROButton
                                type="button"
                                variant={"contained"}
                                onClick={onClose}
                            >
                                Cancel
                            </MROButton>
                        </Grid>
                        <Grid item>
                            <MROButton
                                onClick={() => {
                                    handleWorkOrder()
                                }}
                                variant={"contained"}
                                color="primary"
                                loading={busy}
                            >
                                Complete
                            </MROButton>
                        </Grid>
                    </Grid>
                </div>
            </Dialog>
        </div>
    )
}
