import React, { useReducer, useState, useEffect } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import FlightIcon from "@material-ui/icons/FlightTakeoffOutlined";
import authReducer, { INITIAL_STATE } from "../../reducers/auth_reducer";
import AuthActions from "../../actions/auth_actions";
import TopNavigationBar from "../../components/tab_component";
import Mro1 from "./Mro1"
import Mro2 from "./Mro2"
import Mro3 from "./Mro3"
import PageNotFound from "../../components/universal/page_not_found";

export default function ReportsSection(props) {

  const topNavMenu = [
    {
      label: (
        <div>
          <FlightIcon className="nav_icon" />
          &nbsp;MRO1
        </div>
      ),
      name: "MRO1",
      redirect: "/org/reports/mro1",
      section: "/reports/mro1",
    },
    {
      label: (
        <div>
          <FlightIcon className="nav_icon" />
          &nbsp;MRO2
        </div>
      ),
      name: "MRO 2",
      redirect: "/org/reports/mro2",
      section: "/reports/mro2",
    },
    {
      label: (
        <div>
          <FlightIcon className="nav_icon" />
          &nbsp;MRO3
        </div>
      ),
      name: "MRO 3",
      redirect: "/org/reports/mro3",
      section: "/reports/mro3",
    },
  ];

  const Section = "Reports";
  const [authState, dispatch] = useReducer(authReducer, INITIAL_STATE);
  const [categories, setCategories] = useState([]);
  const [topMenus, setTopMenus] = useState([]);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location.pathname === "/org/reports") {
      history.push("/org/reports/mro1");
    }
  });

  useEffect(() => {
    setCategories(authState?.authState?.categories?.find(category => category.name === "Reports").sub_categories)
  }, [authState?.authState?.categories])
  useEffect(() => {
    if (categories) {
      setTopMenus(
        topNavMenu.filter((section) => {
          return categories?.some((category) => {
            return true;
          });
        })
      );
    }
  }, [categories?.length]);

  useEffect(() => {
    AuthActions.setUserData(dispatch, sessionStorage.getItem("authState"));
  }, []);



  return (
    <div>
      {!!topMenus.length && <>
        <TopNavigationBar {...props} tabProperties={topMenus} />
        <Switch>
          <Route
            path={"/org/reports/mro1"}
            component={(props) => (
              <Mro1
                {...props}

              />
            )}
          />
          <Route
            path={"/org/reports/mro2"}
            component={(props) => (
              <Mro2
                {...props}

              />
            )}
          />
          <Route
            path={"/org/reports/mro3"}
            component={(props) => (
              <Mro3

                {...props}

              />
            )}
          />

          <Route component={PageNotFound} />
        </Switch>
      </>
      }
    </div>
  )
}

