import React from "react";
import MROFullScreenRightDrawer from "components/fullwidth_dialog";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: "3%",
  },
  appBar: {
    backgroundColor: "#fff",
    color: "#000000CC",
    boxShadow: "0px 3px 6px #0000001A",
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  paper: {
    borderRadius: "5px",
    backgroundColor: "#F5F5F5",
    padding: theme.spacing(2),
    boxShadow: "0px 1px 4px #0000001A",
  },
  partValue: {
    fontSize: "14px",
    color: "#4D4F5C",
    padding: "2px",
    display: "flex",
    alignItems: "center",
  },
  partKey: {
    "& p": {
      fontSize: "14px",
      padding: "1px",
    },
  },
  partCard: {
    borderRadius: "8px",
    padding: "17px 24px",
    "& p": {
      fontSize: "14px",
      padding: "5px",
    },
  },
  sec1: {
    borderRadius: "10px",
    backgroundColor: "#fff",
    padding: theme.spacing(5),
    boxShadow: "0px 1px 4px #0000001A",
  },
}));

function ManageInspection(props) {
  const classes = useStyles();
  const { handleClose} = props;

  return (
    <div>
      <MROFullScreenRightDrawer open={true}>
        <AppBar position="static" className={classes.appBar} elevation={0}>
          <Toolbar>
            <IconButton
              onClick={handleClose}
              edge="start"
              className={classes.backButton}
              color="inherit"
              aria-label="back"
            >
              <BackIcon />
            </IconButton>
            <Typography variant="subtitle2">Event Information</Typography>
          </Toolbar>
        </AppBar>

        <div className={classes.wrapper}>
          <Grid>
            <Typography
              color="Primary"
              varient="h3"
              style={{ marginBottom: "10px" }}
            >
              Event
            </Typography>
          </Grid>
          <Grid className={classes.paper}>
            <Box display="flex">
              <Box flex={50} className={classes.partKey}>
                <Typography color="Primary" variant="h6">
                  Action & stage Title
                </Typography>
              </Box>
              <Box flex={50} className={classes.partValue}>
                <Typography>Replace Humidity Cnt Desiccant</Typography>
              </Box>
            </Box>
          </Grid>

          <Grid>
            <Typography
              color="Primary"
              varient="h3"
              style={{ marginBottom: "10px", marginTop: "10px" }}
            >
              Sub-Fields
            </Typography>
          </Grid>
          <Grid className={classes.paper}>
            <Box display="flex">
              <Box flex={50} className={classes.partKey}>
                <Typography color="primary" variant="h6">
                  Sub-Field description 1
                </Typography>
              </Box>
              <Box flex={50} className={classes.partValue}>
                <Typography>Sub-Field text</Typography>
              </Box>
              <Box flex={50} className={classes.partKey}>
                <Typography color="primary" variant="h6">
                  Sub-Field description 6
                </Typography>
              </Box>
              <Box flex={50} className={classes.partValue}>
                <Typography>Sub-Field text</Typography>
              </Box>
            </Box>

            <Box display="flex">
              <Box flex={50} className={classes.partKey}>
                <Typography color="primary" variant="h6">
                  Sub-Field description 2
                </Typography>
              </Box>
              <Box flex={50} className={classes.partValue}>
                <Typography>Sub-Field text</Typography>
              </Box>
              <Box flex={50} className={classes.partKey}>
                <Typography color="primary" variant="h6">
                  Sub-Field description 2
                </Typography>
              </Box>
              <Box flex={50} className={classes.partValue}>
                <Typography>Sub-Field text</Typography>
              </Box>
            </Box>

            <Box display="flex">
              <Box flex={50} className={classes.partKey}>
                <Typography color="primary" variant="h6">
                  Sub-Field description 3
                </Typography>
              </Box>
              <Box flex={50} className={classes.partValue}>
                <Typography>Sub-Field text</Typography>
              </Box>
              <Box flex={50} className={classes.partKey}>
                <Typography color="primary" variant="h6">
                  Sub-Field description 8
                </Typography>
              </Box>
              <Box flex={50} className={classes.partValue}>
                <Typography>Sub-Field text</Typography>
              </Box>
            </Box>
            <Box display="flex">
              <Box flex={50} className={classes.partKey}>
                <Typography color="primary" variant="h6">
                  Sub-Field description 4
                </Typography>
              </Box>
              <Box flex={50} className={classes.partValue}>
                <Typography>Sub-Field text</Typography>
              </Box>
              <Box flex={50} className={classes.partKey}>
                <Typography color="primary" variant="h6">
                  Sub-Field description 9
                </Typography>
              </Box>
              <Box flex={50} className={classes.partValue}>
                <Typography>Sub-Field text</Typography>
              </Box>
            </Box>
            <Box display="flex">
              <Box flex={50} className={classes.partKey}>
                <Typography color="primary" variant="h6">
                  Sub-Field description 5
                </Typography>
              </Box>
              <Box flex={50} className={classes.partValue}>
                <Typography>Sub-Field text</Typography>
              </Box>
              <Box flex={50} className={classes.partKey}>
                <Typography color="primary" variant="h6">
                  Sub-Field description 10
                </Typography>
              </Box>
              <Box flex={50} className={classes.partValue}>
                <Typography>Sub-Field text</Typography>
              </Box>
            </Box>
          </Grid>

          <Grid>
            <Typography
              color="primary"
              varient="h3"
              style={{ marginBottom: "10px", marginTop: "10px" }}
            >
              Event Closure
            </Typography>
          </Grid>
          <Grid className={classes.paper}>
            <Box display="flex">
              <Box flex={50} className={classes.partKey}>
                <Typography color="primary" variant="h6">
                  Mech
                </Typography>
              </Box>
              <Box flex={50} className={classes.partValue}>
                <Typography>5678</Typography>
              </Box>
              <Box flex={50} className={classes.partKey}>
                <Typography color="primary" variant="h6">
                  Date / Time
                </Typography>
              </Box>
              <Box flex={50} className={classes.partValue}>
                <Typography>25/Dec/2020 11:59</Typography>
              </Box>
            </Box>

            <Box display="flex">
              <Box flex={50} className={classes.partKey}>
                <Typography color="primary" variant="h6">
                  Insp
                </Typography>
              </Box>
              <Box flex={50} className={classes.partValue}>
                <Typography>7890</Typography>
              </Box>
              <Box flex={50} className={classes.partKey}>
                <Typography color="primary" variant="h6">
                  Date / Time
                </Typography>
              </Box>
              <Box flex={50} className={classes.partValue}>
                <Typography>25/Dec/2020 11:59</Typography>
              </Box>
            </Box>
          </Grid>

          <Grid>
            <Typography
              color="primary"
              varient="h3"
              style={{ marginBottom: "10px", marginTop: "10px" }}
            >
              Tech Records
            </Typography>
            <Grid className={classes.sec1}></Grid>
          </Grid>

          <Grid>
            <Typography
              color="primary"
              varient="h3"
              style={{ marginBottom: "10px" }}
            >
              Event Scheduling
            </Typography>
          </Grid>
          <Grid className={classes.paper}>
            <Box display="flex">
              <Box flex={50} className={classes.partKey}>
                <Typography color="primary" variant="h6">
                  Next Due
                </Typography>
              </Box>
              <Box flex={50} className={classes.partValue}>
                <Typography>19/Jan/2021</Typography>
              </Box>
            </Box>
          </Grid>
        </div>
      </MROFullScreenRightDrawer>
    </div>
  );
}

export default ManageInspection;
