import React from 'react'
import TabIndex from './TabIndex'
import { Typography, Paper } from '@material-ui/core'

export default function LeaveRequest() {
    return (
        <div>

            <Typography color='primary' variant="h6" style={{ paddingTop: '10px' }}>Leave & Overtime Requests</Typography>

            <TabIndex />

        </div>
    )
}

