import requestrApi from "../common/boxr_api_client"

const createCapability = (data) => {
  return requestrApi({
    url: "/capabilities",
    method: "POST",
    data: data,
  });
};

const updateCapability = (data) => {
  return requestrApi({
    url: "/capabilities/update_capabilities",
    method: "PUT",
    data: data,
  });
};

const getPlatforms = (params) => {
  return requestrApi({
    url: "/capabilities/listing_platforms",
    method: "GET",
    params
  });
};
const getFetchDependency = (params) => {
  return requestrApi({
    url: "/capabilities/fetch_dependencies",
    method: "GET",
    params
  });
};
const getAllCapabilities = (params) => {
  return requestrApi({
    url: "/capabilities",
    method: "GET",
    params
  });
};

function deleteEngineTask(formData) {
  return requestrApi({
    url: `/capabilities/destroy_eng_tasks`,
    method: 'DELETE',
    data: formData
  })
}

const createPlatform = (data) => {
  return requestrApi({
    url: "/capabilities/create_platform",
    method: "POST",
    data,
  });
};
const createEngineTask = (data) => {
  return requestrApi({
    url: "/capabilities/create_eng_tasks",
    method: "POST",
    data,
  });
};
const createCapWithoutRecord = (data) => {
  return requestrApi({
    url: `/capabilities/create_capability`,
    method: "POST",
    data: data,
  });
};

const updatePlatform = (data) => {
  return requestrApi({
    url: "/capabilities/update_platform",
    method: "PUT",
    data,
  });
};

const mapPlatform = (data) => {
  return requestrApi({
    url: "/capabilities/map_platform",
    method: "PUT",
    data,
  });
};

const editEngineTask = (data) => {
  return requestrApi({
    url: "/capabilities/update_eng_tasks",
    method: "PUT",
    data,
  });
};

const getAllTechData = (params) => {
  return requestrApi({
    url: "/capabilities/listing_tech_data",
    method: "GET",
    params
  });
};
const createTechData = (data) => {
  return requestrApi({
    url: `/capabilities/create_tech_data`,
    method: "POST",
    data: data,
  });
};

function fetchSampleDocument(params) {
  return requestrApi({
    url: 'capabilities/fetch_sample_document',
    method: 'GET',
    params
  });
}

function bulkUpload(formData) {
  return requestrApi({
    url: '/capabilities/capability_upload',
    method: 'POST',
    data: formData
  })
}

const updateTechData = (data) => {
  return requestrApi({
    url: "/capabilities/update_tech_data",
    method: "PUT",
    data,
  });
};

function deleteTechData(formData) {
  return requestrApi({
    url: `capabilities/destroy_tech_data`,
    method: 'DELETE',
    data: formData
  })
}
function verifyRevisionState(formData) {
  return requestrApi({
    url: `/capabilities/verify_revision_status`,
    method: 'POST',
    data: formData
  })
}

const getAllRevisionDetails = (params) => {
  return requestrApi({
    url: "/capabilities/revision_history",
    method: "GET",
    params
  });
};



const CapabilityServices = {
  createCapability,
  getPlatforms,
  createPlatform,
  updatePlatform,
  mapPlatform,
  getFetchDependency,
  createCapWithoutRecord,
  createEngineTask,
  editEngineTask,
  deleteEngineTask,
  updateCapability,
  getAllCapabilities,
  getAllTechData,
  createTechData,
  fetchSampleDocument,
  bulkUpload,
  updateTechData,
  deleteTechData,
  verifyRevisionState,
  getAllRevisionDetails
};

export default CapabilityServices;
