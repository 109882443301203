import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography/Typography";
import AuthClient from "../../lib/services/api/auth_services";
import { validateEmail } from "../../lib/utils/common_utils";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MailIcon from "@material-ui/icons/EmailOutlined";
import useAuthStyles from "./auth_styles";


export default function ForgotPassword(props) {
    const classes = useAuthStyles();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [error, setError] = useState({});

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const email = formData.get('username').trim();
        if (!validateEmail(email)) {
            setError({ email: "invalid format, provide valid email" });
            return;
        }
        formData.delete("username")
        setLoading(true);
        formData.append("email", email)
        // formData.append("redirect_url", `${process.env.REACT_APP_BASE_URL}/auth/reset-password`);
        formData.append("redirect_url", "https://app.mro-pro.com/auth/reset-password");
        AuthClient.requestReset(formData).then((res) => {
            setMessage(res);
            setLoading(false);
        }).catch((e) => {
            setLoading(false);
            setMessage({ message: <span>Entered username not found in the system<br /> Kindly retry with your registered email address!</span>, success: false });
        });
    };

    return (
        <div className={classes.wrapper}>
            <div className={classes.overlay}></div>
            <div style={{ zIndex: '99' }}>
                {!!!message ? <form spellcheck="false" className={classes.form} onSubmit={handleSubmit} autoComplete="custom-form">
                    <Typography component={"h6"} variant={"h6"}>Reset Password</Typography>
                    <div className={"desc"}>Enter your registered email id to get password reset link</div>
                    <FormControl fullWidth className={classes.formField}>
                        <OutlinedInput
                            required
                            name="username"
                            autoComplete="new-password"
                            placeholder="Username"
                            autoFocus
                            error={!!error.email}
                            helperText={error.email ? error.email : null}
                            onFocus={() => { setError(false) }}
                            startAdornment={<InputAdornment position="start">
                                <MailIcon htmlColor={"#ffffff80"} />
                            </InputAdornment>
                            }
                        />
                    </FormControl>
                    <div style={{ margin: '20px 0' }}>
                        <Button
                            type="submit"
                            className={classes.submit}
                            disabled={loading}
                            fullWidth
                        >
                            Submit
                            </Button>
                        <div style={{ margin: '20px 0', textAlign: "center" }}>
                            <span style={{ cursor: 'pointer' }} onClick={() => props.history.push('/auth/signin')}>
                                Sign in instead?
                        </span>
                        </div>
                    </div>
                </form> : <form spellcheck="false" className={classes.form}>
                        <Typography style={{ width: '400px' }} align="center" variant="subtitle1" gutterBottom
                            className={classes.message}>
                            {message.message}
                        </Typography>
                        {!message.success && <Button
                            fullWidth
                            className={classes.submit}
                            onClick={() => setMessage(null)}
                        >
                            Retry
                        </Button>}
                        {message.message &&
                            <Typography align="center" variant="subtitle2"
                                style={{ cursor: 'pointer', margin: '20px 0' }}
                                onClick={() => props.history.push('/auth/signin')}>
                                Sign in
                        </Typography>
                        }
                    </form>}
            </div>
            <div className={classes.brandLogo}>
                <img src={'/mro-pro-logo.png'} alt={"mro pro logo"} />
            </div>
        </div>
    );
}