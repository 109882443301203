import React,{useState,createContext} from "react";

export const FormContext = createContext({});

export default function FormDataProvider({ children }) {
    const [formState,setFormState] = useState({});

    const handleChange = ({name,value}) => {
        setFormState({...formState,[name]:value});
    }
    let formData = {formState,handleChange};
    return (
        <FormContext.Provider value={formData}>
            {children}
        </FormContext.Provider>
    );
}