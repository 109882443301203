import React from "react";


const landingIcon = ({ fill }) => (<svg xmlns="http://www.w3.org/2000/svg" width="125" height="18" viewBox="0 0 135 96.902">
  <g id="plane-landing" transform="translate(0 -50.969)">
    <g id="Group_5696" data-name="Group 5696" transform="translate(0 50.969)">
      <g id="Group_5695" data-name="Group 5695">
        <path id="Path_19812" data-name="Path 19812" d="M5.979,81.268l-.013,0a3.258,3.258,0,0,0-1.557,6.306l45.538,15.152L34.8,108.756a2.637,2.637,0,0,0,.548,5.052l9.93,1.634a2.644,2.644,0,0,0,.861,0l37.737-6.277,36.346,5.921c13.894,2.071,15.816-2.889,16.075-4.459a6.971,6.971,0,0,0-1.734-5.466c-1.822-2.347-4.959-4.615-9.587-6.934a15.285,15.285,0,0,1-3.06-1.975c-2.741-2.17-4.78-3.4-17.867-5.558L32.46,78.911,20.234,53.581a3.258,3.258,0,0,0-2.405-1.8l-4.678-.77a3.258,3.258,0,0,0-3.784,3.325L10.311,82Z" transform="translate(-1.364 -50.969)" fill={fill} />
        <path id="Path_19813" data-name="Path 19813" d="M128.078,277.051H6.205a6.205,6.205,0,1,0,0,12.41H128.078a6.205,6.205,0,1,0,0-12.41Z" transform="translate(0 -192.559)" fill={fill} />
      </g>
    </g>
  </g>
</svg>)
export default landingIcon;

