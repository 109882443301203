import {
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import MROButton from "components/buttons";
import { makeStyles } from "@material-ui/core/styles";
import exclamationIcon from "assets/exclamation.png"
import WarningIcon from "@material-ui/icons/Warning";
import LoadingIndicator from "components/universal/loading_indicator";
import { WorkshopContext } from "../WorkshopContext/WorkshopContext";
import { useCallback } from "react";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        backgroundColor: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        margin: "1% 0",
        display: "flex",
        "& button": {
            marginRight: theme.spacing(2),
            width: "200px",
            "& .MuiButton-label": {
                justifyContent: "space-between",
                width: "150px",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
            },
        },
    },
    input: {
        color: "#fff",
    },
    paper: {
        borderRadius: "5px",
        backgroundColor: "#fff",
        padding: theme.spacing(3),
        boxShadow: "0px 1px 4px #0000001A",
    },
    head: {
        marginTop: "20px",
        marginBottom: "10px",
    },
    error: {
        borderColor: "red",
    },
    svg: {
        filter: "invert(1)",
        height: 18,
        marginBottom: 2,
    },
    disableSwitch: {
        pointerEvents: "none",
    },
    disableField: {
        pointerEvents: "none",
        backgroundColor: "#F5F5F5",
    },
    message: {
        display: "flex",
        justifyContent: "flex-start",
        margin: theme.spacing(1)
    }
}));

const PlanningClosure = (props) => {

    const classes = useStyles();
    const { setConditionState, conditionState } = useContext(WorkshopContext)

    const {
        getData,
        closureData,
        acceptAll,
        setAcceptAll,
        handleCloseFunction,
        handleSubmit,
        handleClose,
        setAction,
        busyPopup, isLoadingClosure, setGetData } = props

    useEffect(() => {
        if (acceptAll) {
            setAction("open_popup")
        }
    }, [acceptAll])

    const handleCloseHandler = () => {
        setAction(null)
        setConditionState(false);
        setAcceptAll(false)
        setGetData(false)
        handleClose()
    }


    return (
        <>
            <Dialog
                //hideBackdrop={acceptAll && true} // Disable the backdrop color/image
                //disableEnforceFocus={acceptAll && true} // Let the user focus on elements outside the dialog
                //style={{ position: acceptAll && 'initial'}} // This was the key point, reset the position of the dialog, so the user can interact with other elements
                //disableBackdropClick={acceptAll && true} // Remove the backdrop click (just to be sure)
                aria-labelledby="customized-dialog-title"
                onClose={handleCloseHandler}
                open={getData}
            >
                <DialogTitle id="customized-dialog-title">
                    <Box style={{ display: "flex", justifyContent: "space-between" }}>
                        <Box display="flex" alignItems="center">
                            <Typography
                                style={{
                                    fontSize: "18px",
                                }}
                                color="primary"
                            >
                                Planning Closure
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="center">
                            {" "}
                            <img
                                src={exclamationIcon}
                                alt="Icon"
                            // style={{ height: "28px" }}
                            />{" "}
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent style={{ width: "500px" }}>
                    {!isLoadingClosure ? <Box
                        ml={0}
                        pt={0}
                        display="flex"
                        // alignItems="center"
                        justifyContent={"flex-start"}
                        flexDirection="column"
                    >

                        {closureData?.failures?.release_type_a &&
                            <div className={classes.message}>
                                <WarningIcon
                                    style={{
                                        color: "red",
                                        marginRight: "10px",
                                        fontSize: 20,
                                    }}
                                />&nbsp;&nbsp;{closureData?.failures?.release_type_a}
                            </div>}
                        {closureData?.failures?.release_type_b &&
                            <div className={classes.message}>
                                <WarningIcon
                                    style={{
                                        color: "red",
                                        marginRight: "10px",
                                        fontSize: 20,
                                    }}
                                />&nbsp;&nbsp;{closureData?.failures?.release_type_b}
                            </div>}
                        {closureData?.failures?.status_work &&
                            <div className={classes.message}>
                                <WarningIcon
                                    style={{
                                        color: "red",
                                        marginRight: "10px",
                                        fontSize: 20,
                                    }}
                                />&nbsp;&nbsp;{closureData?.failures?.status_work}
                            </div>}
                        {closureData?.failures?.tech_data &&
                            <div className={classes.message}>
                                <WarningIcon
                                    style={{
                                        color: "red",
                                        marginRight: "10px",
                                        fontSize: 20,
                                    }}
                                />&nbsp;&nbsp;{closureData?.failures?.tech_data}
                            </div>}
                        {closureData?.failures?.action &&
                            <div className={classes.message}>
                                <WarningIcon
                                    style={{
                                        color: "red",
                                        marginRight: "10px",
                                        fontSize: 20,
                                    }}
                                />&nbsp;&nbsp;{closureData?.failures?.action}
                            </div>}
                        {closureData?.failures?.service_life &&
                            <div className={classes.message}>
                                <WarningIcon
                                    style={{
                                        color: "red",
                                        marginRight: "10px",
                                        fontSize: 20,
                                    }}
                                />&nbsp;&nbsp;{closureData?.failures?.service_life}
                            </div>}
                        {closureData?.cautions?.service_life &&
                            <div className={classes.message}>
                                <WarningIcon
                                    style={{
                                        color: "#FFB300",
                                        marginRight: "10px",
                                        fontSize: 20,
                                    }}
                                />&nbsp;&nbsp;
                                {closureData?.cautions?.service_life}
                            </div>}
                        {closureData?.cautions?.equipment &&
                            <div className={classes.message}>
                                <WarningIcon
                                    style={{
                                        color: "#FFB300",
                                        marginRight: "10px",
                                        fontSize: 20,
                                    }}
                                />&nbsp;&nbsp;
                                {closureData?.cautions?.equipment}
                            </div>}

                        {closureData?.cautions?.part &&
                            <div className={classes.message}>
                                <WarningIcon
                                    style={{
                                        color: "#FFB300",
                                        marginRight: "10px",
                                        fontSize: 20,
                                    }}
                                />&nbsp;&nbsp;
                                {closureData?.cautions?.part}
                            </div>}


                        {closureData?.cautions?.service &&
                            <div className={classes.message}>
                                <WarningIcon
                                    style={{
                                        color: "#FFB300",
                                        marginRight: "10px",
                                        fontSize: 20,
                                    }}
                                />&nbsp;&nbsp;
                                {closureData?.cautions?.service}
                            </div>}
                    </Box> : <LoadingIndicator variant="block" />}
                    <Box pt={3} display="flex" alignItems="center">
                        <span style={{ textDecoration: "underline" }}>Accept all</span>
                        &nbsp;&nbsp;&nbsp;
                        <input
                            style={{ pointerEvents: closureData?.failures?.release_type_a || closureData?.failures?.release_type_b || closureData?.failures?.status_work || closureData?.failures?.action || closureData?.failures?.tech_data ? "none" : "" }}
                            type="checkbox"
                            value={acceptAll}
                            onClick={() => {
                                setAcceptAll(!acceptAll);
                            }}
                        />
                    </Box>
                    <Box
                        mt="auto"
                        display="flex"
                        justifyContent="center"
                        style={{ marginTop: "20px", marginBottom: "20px" }}
                    >
                        <MROButton
                            style={{ marginRight: "20px" }}
                            type="button"
                            variant={"contained"}
                            onClick={handleCloseFunction}
                            buttonName="Cancel"
                        >
                            Cancel
                        </MROButton>
                        <MROButton
                            type="button"
                            variant={"contained"}
                            color={acceptAll ? "primary" : ""}
                            style={{ backgroundColor: closureData?.failures?.release_type_a || closureData?.failures?.release_type_b || closureData?.failures?.status_work || closureData?.failures?.action || closureData?.failures?.tech_data ? "red" : !acceptAll ? "#FFB300" : "", color: closureData?.failures?.release_type_a || closureData?.failures?.release_type_b || closureData?.failures?.status_work || closureData?.failures?.action || closureData?.failures?.tech_data ? "#ffff" : "" }}
                            loading={busyPopup === "loading"}
                            onClick={(e) => {
                                acceptAll && handleSubmit(e);
                            }}
                            name="complete1"
                        >
                            Complete
                        </MROButton>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}
export default PlanningClosure;