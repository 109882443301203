import React, { useState, useEffect } from 'react'
import ReactBarChart from 'react-apexcharts'

function BarChart(props) {
    const { value, color } = props

    const [values, setValues] = useState([])

    const [columnState, setColumnState] = useState({
        series: [{
            data: value
        }],

        options: {
            chart: {
                height: 350,
                type: 'bar',
                toolbar: {
                    show: false
                }
            },
            plotOptions: {
                bar: {

                    dataLabels: {
                        enabled: false, // top, center, bottom
                    },
                }
            },
            colors: color,
            dataLabels: {
                enabled: false,
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    // colors: ['#4FC605']
                }
            },

            xaxis: {
                categories: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
                position: 'bottom',
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
            },
            grid: {
                borderColor: " ",
                row: {
                    colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
                    opacity: 0.5,
                },
            },
            tooltip: {
                // theme: 'dark',
                x: {
                    show: true
                },
                y: {
                    title: {
                        formatter: function () {
                            return ''
                        }
                    }
                }
            },
            yaxis: {
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false,
                },
                labels: {
                    show: false,

                },
                tooltip: {
                    enabled: false,
                }

            },

        },


    })

    setTimeout(() => {
        setColumnState({
            series: [
                {
                    data: value
                }
            ]
        });
    }, 4000);

    return (
        <>
            <ReactBarChart options={columnState.options} series={columnState.series} type="bar" height={200} />
        </>
    )
}
export default React.memo(BarChart)