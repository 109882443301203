import React from "react";

import { Box, makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";

import Circle from "./Circle";

function IndicatorLabel(props) {
  const classes = useStyles();

  const { color, title, style } = props;

  return (
    <Box display="flex" alignItems="center">
      <Circle color={color} />
      <Typography className={classes.title} style={style}>
        {title}
      </Typography>
    </Box>
  );
}

export default IndicatorLabel;

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "18px",
    lineHeight: "23px",
    color: "#2D2D2D",
    marginLeft: 8,
    textTransform: "uppercase",
  },
}));
