import React, { useState, useReducer, useEffect, useContext } from 'react'
import MROFullScreenRightDrawer from "components/fullwidth_dialog";
import {
    AppBar,
    IconButton,
    Toolbar,
    makeStyles,
    Typography,
    Box,
    Button,
    Grid,
    Menu,
    MenuItem,
    Paper,
    Tooltip
} from "@material-ui/core";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import MROButton from "components/buttons";
import MaterialTable from "material-table";
import tableIcons, { options } from 'components/universal/table_attributes';
import tableReducer, { INITIAL_TABLE_STATE } from "reducers/table_reducer";

import WarningIcon from "@material-ui/icons/Warning";
import { Add, AddBox } from "@material-ui/icons";
import CreateTechData from './CreateTechData';
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Deleteitem from './TechDataPopup/DeleteItem';
import VerifyRevision from './TechDataPopup/VerifyRevision';
import RevisionHistory from './TechDataPopup/RevisionHistory';
import Popover from '@material-ui/core/Popover';
import KeyIcon from 'assets/key.png'
import LinkIcon from 'assets/LinkIcon.png'
import copy from 'copy-to-clipboard';
import CapabilityServices from 'lib/services/api/compliance/capability_client';
import ViewIcon from "@material-ui/icons/Visibility";
import { ICON_COLOUR } from "lib/constants/style_constants";
import { BASE_API_URL_BOXR } from 'lib/constants';
import { ToastMessageContext } from 'lib/contexts/message_context';
import clxc from "clsx";
import moment from 'moment';
import useDebounce from 'lib/utils/debounce';



const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: "#fff",
        color: "#000000CC",
        boxShadow: "0px 3px 6px #0000001A",
    },
    backButton: {
        marginRight: theme.spacing(2),
    },
    root: { "& > :first-child": { position: "unset" } },
    wrapper: {
        // padding: "3%",
        background: "white",
    },
    paper: {
        border: '1px solid',
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
    },
    stockBtn: {
        borderColor: '#4D4F5C',
        borderRadius: '6px',
        minWidth: '70px',
        '& span': {
            // display: 'flex',
            // justifyContent: 'space-between',
            '& svg': {
                marginLeft: '10px'
            }
        }
    }
}));
function PlanningTechData(props) {

    const { refData, currentSelection, techLoading, setTechLoading, techAction, setTechAction, setAcceptAll, rprops, planningDetails } = props

    const classes = useStyles();
    // console.log("======", rprops)

    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [totalCount, setTotalCount] = useState(0)
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const ITEM_HEIGHT = 48;
    const [currentTechSelections, setCurrentTechSelections] = useState({});
    const [poperState, setPoperState] = useState(false)
    const [techData, setTechData] = useState([])
    const [userNameCopy, setUserNameCopy] = useState(false)
    const [passwordCopy, setPasswordCopy] = useState(false)
    const message = useContext(ToastMessageContext)
    const [searchText, setSearchText] = useState("");
    const debouncedVal = useDebounce(searchText);

    const handleClick = (event) => {
        setOpen(true);
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setOpen(false);
        setTechAction(null);
        setCurrentTechSelections({});
    };

    const getAllTechDataDetails = async () => {
        setLoading(true)
        const params = {
            id: refData || planningDetails?.capabilty_id,
            page: page + 1,
            count_per_page: pageSize,
            search_keyword: debouncedVal

        }


        await CapabilityServices.getAllTechData(params).then((res) => {
            setTechData(res.data)
            setTotalCount(res.total_count)
            setTechLoading(false)
        }).catch(() => {
            message.showToastMessage({
                message: "Something went wrong",
                variant: "error",
            });
        }).finally(() => {
            setLoading(false)
        })


    }
    // console.log("techdata===", techLoading, refData)
    useEffect(() => {
        if (techLoading === true && refData !== undefined && refData !== null || planningDetails?.capabilty_id) {

            getAllTechDataDetails()
        }
    }, [refData, debouncedVal, page, pageSize, totalCount, techLoading, planningDetails])

    const handlepopupclose = () => {
        setTechAction("")
        setPoperState(false)
    }
    const tableOptions = {
        ...options,
        page: page,
        total: totalCount,
        pageSize: pageSize,
        search: true,
        toolbar: true,
        // rowStyle: { height: 42 },
    };

    const renderStatusButton = (rowData) => {
        let ButtonColor, buttonText;

        if (rowData?.age <= 30 && rowData?.age !== null) {

            ButtonColor = "button-positive";
            buttonText = `${rowData?.age} days`;
        }
        else if (rowData?.age > 90) {
            ButtonColor = "button-negative";
            buttonText = `${rowData?.age} days`;
        } else if (rowData?.age > 30 && rowData?.age <= 90) {
            ButtonColor = "button-warning";
            buttonText = `${rowData?.age} days`;
        }
        else if (rowData?.age === null) {
            return "-"
        }

        return (
            <div style={{ paddingLeft: '21px' }}>
                <Button
                    size="small"
                    className={clxc(classes.button, ButtonColor, classes.stockBtn)}
                    style={{
                        whiteSpace: "nowrap",
                        fontSize: 10,
                        justifyContent: "center",
                        alignItems: "center",
                        height: "26px",
                    }}
                    variant="outlined"
                >
                    <Typography style={{ fontSize: '13px', textAlign: 'center' }}>{buttonText}</Typography>
                </Button>
            </div>
        )
    }


    const column = [

        {
            title: "Title",
            field: "name",
            width: '30%',
            headerStyle: {
                width: '30%',
                textAlign: 'left', paddingLeft: '15px'
            },
            cellStyle: {
                width: '30%',
                textAlign: 'left', paddingLeft: '15px'
            }
        },

        {
            title: "View",
            field: "file",
            headerStyle: {

                textAlign: 'left'
            },
            cellStyle: {

                textAlign: 'left'
            },
            render: (rowData) => (
                <IconButton
                    onClick={() => {
                        if (rowData?.type === "file") {
                            if (rowData.file !== null) {
                                window.open(BASE_API_URL_BOXR + rowData.file.url);
                            } else {
                                message.showToastMessage({
                                    message: "No Document Found.",
                                    variant: "error",
                                });
                            }
                        }

                        else if (rowData?.type === "link") {
                            // console.log("link====", Boolean(rowData.url.search("https")))
                            window.open(Boolean(rowData.url.search("https")) ? "https://" + rowData.url : rowData.url);
                        }
                    }
                    }
                    size="small"
                >
                    <ViewIcon fontSize="small" style={{ color: ICON_COLOUR }} />
                </IconButton >
            ),
        },

        {
            title: 'Type',
            field: 'type',

            headerStyle: {

                textAlign: 'left'
            },
            cellStyle: {

                textAlign: 'left'
            },
            render: (rowData) => rowData?.type === "file" ? "File" : "Link"
        },
        {
            title: 'Key',
            field: 'key',

            headerStyle: {

                textAlign: 'left'
            },
            cellStyle: {

                textAlign: 'left'
            },
            render: (rowData) => <>

                {rowData?.username && rowData?.password && <IconButton onClick={() => {
                    setTechAction("view_key");
                    setCurrentTechSelections(rowData);
                    setPoperState(!poperState)

                }}>
                    <img src={KeyIcon} height={'12px'} />
                </IconButton>}

                {techAction === "view_key" && <Popover id='key' open={Boolean(poperState)}
                    anchorReference={'anchorPosition'}
                    onClose={handlepopupclose}
                    anchorEl={poperState}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    anchorPosition={{
                        top: 410,
                        left: 920
                    }}
                >

                    <Paper style={{ width: '417px', height: '104px', padding: '10px' }}>
                        <Typography color="primary" style={{ fontSize: '18px' }}>Shared Login Details</Typography>
                        <Grid container>
                            <Grid xs={12} container>
                                <Grid xs={4}> <Typography variant={"subtitle2"} style={{ fontSize: '16px', paddingTop: '2px', paddingRight: "4px" }}>User / Account:</Typography></Grid>
                                <Grid><Typography style={{ marginLeft: '-17px', paddingTop: '2px', paddingRight: '10px' }}>{currentTechSelections.username}</Typography></Grid>
                                <Grid style={{ paddingTop: '3px' }}> <Tooltip title={userNameCopy ? "Copied" : "Copy"} placement="bottom" onClick={() => {
                                    copy(currentTechSelections?.username);
                                    setUserNameCopy(true)
                                }} style={{ cursor: 'pointer' }}>
                                    <img src={LinkIcon} alt="" height="16px" /></Tooltip> </Grid>
                            </Grid>
                            <Grid xs={12} container>
                                <Grid xs={3}> <Typography variant={"subtitle2"} style={{ fontSize: '16px', paddingTop: '2px', paddingRight: "4px" }}>Password:</Typography></Grid>
                                <Grid ><Typography style={{ marginLeft: '-17px', paddingTop: '2px', paddingRight: '10px' }}>{currentTechSelections?.password}</Typography></Grid>
                                <Grid style={{ paddingTop: '3px' }}> <Tooltip title={passwordCopy ? "Copied" : "Copy"} placement="bottom" onClick={() => {
                                    copy(currentTechSelections?.password);
                                    setPasswordCopy(true)

                                }} style={{ cursor: 'pointer' }}>
                                    <img src={LinkIcon} alt="" height="16px" /></Tooltip> </Grid>
                            </Grid>


                        </Grid>

                    </Paper>
                </Popover>}


            </>
        },

        {
            title: 'Revision',
            field: 'revision'
        },
        {
            title: 'Revision Date',
            field: 'revision_date',
            render: (rowData) => rowData?.revision_date ? moment(rowData?.revision_date, "YYYY-MM-DD").format("DD/MM/YYYY") : "-"
        },
        {
            title: 'Verified',
            field: 'verified',
            render: (rowData) => rowData?.verified ? moment(rowData?.verified, "YYYY-MM-DD").format("DD/MM/YYYY") : "-"
        },
        {
            title: 'Age',
            field: 'age',
            render: (rowData) => renderStatusButton(rowData)
        },
        {
            title: 'Action',
            field: 'view',
            render: (rowData) => <>
                {(rprops?.permission.write && rprops?.orderStateNum <= 8) && <IconButton onClick={(e) => {
                    setCurrentTechSelections(rowData);
                    handleClick(e);
                }}>
                    <MoreVertIcon />
                </IconButton>}

            </>
        }

    ]




    return (
        <>

            <div className={classes.wrapper}>

                <br />
                <Grid>
                    <MaterialTable
                        style={{
                            boxShadow: "0px 1px 3px #00000033",
                            marginTop: "10px",
                        }}
                        icons={tableIcons}
                        classes
                        title={""}
                        columns={column}
                        data={techData || []}
                        isLoading={loading}
                        options={tableOptions}
                        onChangePage={(page) => {
                            setPage(page);
                        }}
                        onChangeRowsPerPage={(pageSize) => {
                            setPageSize(pageSize);
                        }}
                        onSearchChange={(search) => {
                            setPage(0)
                            setSearchText(search)

                        }}
                        totalCount={totalCount}
                        page={page}
                        actions={(rprops?.permission.write && rprops?.orderStateNum <= 8) && [
                            {
                                icon: () => <AddBox color="secondary" />,
                                tooltip: "Add data",
                                isFreeAction: true,
                                hidden: refData === [] || refData === undefined || refData === null,
                                onClick: (event, data) => {
                                    setTechAction("createtechdata");


                                },
                            },




                        ]}
                    />

                </Grid>
                <br />
                <Grid container> <WarningIcon
                    style={{
                        color: "#FFB300",
                        marginRight: "10px",
                        fontSize: 20,
                        marginTop: '2px'
                    }}
                /> <Typography><i>The revision status of technical data must be checked prior to use! </i></Typography></Grid>


            </div>
            {
                open && (
                    <Menu
                        id={`long-menu`}
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={handleCloseMenu}
                        PaperProps={{
                            style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: "20ch",
                            },
                        }}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        transformOrigin={{ horizontal: "right" }}
                    >

                        <MenuItem

                            key={"View"}
                            style={{ fontSize: "12px" }}
                            onClick={() => {
                                setTechAction("update_tech");

                            }}
                        >
                            Update Item & Revision
                        </MenuItem>


                        <MenuItem

                            key={"verify"}
                            style={{ fontSize: "12px" }}
                            onClick={() => {
                                setTechAction("verify");
                            }}
                        >
                            Verify Revision Status
                        </MenuItem>
                        <MenuItem

                            key={"revision"}
                            style={{ fontSize: "12px" }}
                            onClick={() => {
                                setTechAction("revision");
                            }}
                        >
                            Revision History
                        </MenuItem>
                        <MenuItem

                            key={"delete"}
                            style={{ fontSize: "12px" }}
                            onClick={() => {
                                setTechAction("delete");
                            }}
                        >
                            Delete item
                        </MenuItem>
                    </Menu>
                )
            }
            {techAction && techAction === "createtechdata" &&
                <CreateTechData handleClose={() => {
                    setTechAction('')

                }}
                    capabiltity={currentSelection}
                    refData={refData}
                    getTechListing={getAllTechDataDetails}
                    techaction={techAction}
                    setAcceptAll={setAcceptAll}
                />}

            {techAction && techAction === "update_tech" && <CreateTechData handleClose={() => {
                setTechAction('')
                setOpen(false)
            }} capabiltity={currentSelection}
                getTechListing={getAllTechDataDetails}
                techaction={techAction}
                refData={refData}
                currentSelection={currentTechSelections}
                setAcceptAll={setAcceptAll}
            />}
            {techAction && techAction === "delete" && <Deleteitem handleClose={() => {
                setTechAction('')
                setOpen(false)
            }} getTechListing={getAllTechDataDetails}
                techAction={techAction}
                currentSelection={currentTechSelections} />}
            {techAction && techAction === "verify" && <VerifyRevision handleClose={() => {
                setTechAction('')
                setOpen(false)
            }}

                getTechListing={getAllTechDataDetails}
                action={techAction}
                currentSelection={currentTechSelections}

            />}
            {techAction && techAction === "revision" && <RevisionHistory handleClose={() => {
                setTechAction('')
                setOpen(false)
            }}
                getTechListing={getAllTechDataDetails}
                action={techAction}
                currentSelection={currentTechSelections}
            />}
        </>
    )
}

export default PlanningTechData