import MaterialTable from "material-table";
import React, { useReducer, useState, useEffect, useContext } from "react";
import tableReducer, { INITIAL_TABLE_STATE } from "reducers/table_reducer";
import tableIcons, { options } from "components/universal/table_attributes";
import TableAction from "actions/table_actions";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useDebounce from "lib/utils/debounce";
import CopyFileIcon from "assets/CopyFiles.svg"
import GreenCopyIcon from "assets/GreenCopyIcon.svg"
import AdditionalData from "./AdditionalData";
import { ToastMessageContext } from "lib/contexts/message_context";
import { IconButton } from "@material-ui/core";
import { WorkshopContext } from "views/Operations/Workshop/WorkshopContext/WorkshopContext";
import alertIcon from 'assets/icons/Alert_icon.png'
import { MaintenanceServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation";




const useStyles = makeStyles(() => ({}));

function Parts(props) {
  // const classes = useStyles();
  const { currentClosedDetails, currentPlanningDetails, copyTab } = props

  const [action, setAction] = useState(false)
  const message = useContext(ToastMessageContext);
  const { copyPartTabStatus, flag } = useContext(WorkshopContext)
  const [searchText, setSearchText] = useState("");
  const [selectedRow, setSelectedRow] = useState({});
  const debouncedVal = useDebounce(searchText);
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [totalCount, setTotalCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  const tableOptions = {
    ...options,
    page: page,
    total: totalCount,
    pageSize: pageSize,
    search: false,
    toolbar: false
  };
  console.log("parts=======", copyTab)


  const getList = () => {


    setLoading(true)
    const params = {
      workshop_order_id: currentClosedDetails?.id,
      search: debouncedVal,
      page: 1 + page,
      limit: pageSize,

    };

    MaintenanceServices.getTemlatePartsListing(params).then((res) => {
      setData(res.data)
      setTotalCount(res.total_count)
    }).catch(() => {
      message.showToastMessage({
        message: "Something went wrong. Try again!",
        variant: "error",
      });
    }).finally(() => {
      setLoading(false)
    })
  };

  useEffect(() => {
    getList();
  }, [page, debouncedVal, pageSize]);

  const columns = [
    {
      title: "Action #",
      field: "action_number",
      headerStyle: {
        textAlign: "left",
        paddingLeft: "20px"
      },
      cellStyle: {
        textAlign: "left",
        paddingLeft: "20px"
      },
    },
    {
      title: "Part #",
      field: "part_number",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      title: "Description",
      field: "description",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        textAlign: "left",
        width: "70%",
      },
    },
    {
      title: "Quantity",
      field: "quantity",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      title: "Action",
      headerStyle: {
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (rowData) => (
        <div>
          <IconButton
            onClick={() => {
              setAction("View");
              copyPartTabStatus.push(rowData.id)
              setSelectedRow(rowData);

            }}
            disabled={flag && copyPartTabStatus.find((item) => item === rowData.id)}
          >
            {flag && copyPartTabStatus.find((item) => item === rowData.id) ? <img src={GreenCopyIcon} height="25px" style={{ pointerEvents: flag && copyPartTabStatus.find((item) => item === rowData.id) ? "none" : "", }} /> :

              <img src={CopyFileIcon} height="25px" />
            }
          </IconButton>
        </div>
      ),
    },
  ];
  return (
    <div>

      <MaterialTable
        style={{
          boxShadow: "0px 1px 3px #00000033",
          marginTop: "10px",
        }}
        icons={tableIcons}
        title={""}
        columns={columns}
        data={data || []}
        isLoading={loading}
        options={tableOptions}
        // onRowClick={(e, rowData) => {
        //   selected.push(rowData.id)
        // }

        //   }
        onChangePage={(page) => {
          setPage(page);
        }}
        onChangeRowsPerPage={(pageSize) => {
          setPageSize(pageSize);
        }}
        totalCount={totalCount}
        page={page}
      />
      <div style={{ margin: ' 14px 0px 20px 3px' }}>
        <img src={alertIcon} height="15px" /> <span style={{ padding: '6px' }}> Copy Actions first! Parts must be associated to a new Action in the 'To' Order</span>
      </div>
      {action && action === "View" &&
        <AdditionalData handleClose={() => setAction(null)} currentClosedDetails={currentClosedDetails} currentPlanningDetails={currentPlanningDetails} selectedRow={selectedRow} copyTab={copyTab} />}
    </div>
  );
}

export default Parts;
