import React, { useContext, useEffect, useReducer, useState } from "react";
import Dialog from "@material-ui/core/Dialog";

import {
    createStyles,
    Grid,
    IconButton,
    makeStyles,
    Typography,
    TableRow,
    TableCell,
    TableBody,
    MenuItem,
    Table,
    TableContainer,
    Divider,
    TableHead,
} from "@material-ui/core";
import tableIcons, { options } from "components/universal/table_attributes";
import MaterialTable from "material-table";
import { ToastMessageContext } from "lib/contexts/message_context";
import tableReducer, { INITIAL_TABLE_STATE } from "reducers/table_reducer";
import { InspectionServices } from "lib/services/api/operaitons/WorkShop/Inspection/InspectionService";


const useStyles = makeStyles((theme) =>
    createStyles({
        modal: {
            "& .MuiDialog-paperWidthSm": {
                minWidth: "750px",
                // height: "110vh"
            },
        },
        container: {
            // minWidth: "1200px",
            // minHeight: "400px",
            padding: "50px",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden !important"
        },

        popupTitle: {
            fontSize: "20px",
            lineHeight: "28px",
            position: "absolute",
            top: "19px",
            bottom: "15px",
            left: "25px",
        },
        divider: {
            borderBottom: `1px solid ${theme.palette.primary.main}`,
        },
        tableTitle: {
            fontSize: "16px",
            lineHeight: "22px",
            color: "#4D4F5C",
            fontWeight: "bold",
            marginTop: "19px",
            marginBottom: "16px",
        },
        tableCell: {
            height: '32px',
            justifyContent: "space-between",
            whiteSpace: "normal",
            wordWrap: "break-word",

        },
    })
);

export default function Stages(props) {
    const { handleClose, currentSelection } = props;
    const classes = useStyles();
    const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
    const message = useContext(ToastMessageContext);
    const [actionStages, setActionStages] = useState([]);
    const [pageSizes, setPageSizes] = useState(5);
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(5);
    const [isGettingDetails, setIsGettingDetails] = useState(false);
    const tableOptions = {
        ...options,
        page: page,
        total: totalCount,
        pageSize: pageSizes,
        search: false,
        toolbar: false,
        rowStyle: { height: 42 },
    };


    const column = [
        // {
        //     title: "",
        //     field: "id",
        //     width: "4%",
        //     render: () => <span>&nbsp;</span>,
        // },
        {
            title: "Stage",
            field: "stage_position",
            headerStyle: {
                textAlign: "left",
                paddingLeft: "0px",
            },
            cellStyle: {
                textAlign: "left",
                paddingLeft: "10px",
            },
        },
        {
            title: "Title",
            field: "stage_name",
            width: '80%',
            headerStyle: {

                textAlign: "left",
            },
            cellStyle: {
                width: '80%',
                textAlign: "left",
            },
        },

        {
            title: "SubFields",
            headerStyle: {
                //     textAlign: "right",
                paddingRight: '17px',
            },
            // cellStyle: {
            //     paddingLeft: '80px',
            //     textAlign: "center"
            // },
            render: (rowData) => (
                <span>
                    {!rowData?.stage_fields?.length ? "0" : rowData?.stage_fields?.length}
                </span>
            ),
        },


    ];

    const getAllActionStages = async () => {
        setIsGettingDetails(true);
        const params = {
            workshop_action_id: currentSelection?.workshop_action_id,
            page: page + 1,
            limit: pageSizes,

            // search: debouncedVal,
        };
        await InspectionServices.getAllActionStages(params)
            .then((res) => {
                if (res) {
                    setActionStages(res.data);
                    setTotalCount(res.total_count);
                }
            })
            .catch((err) => {
                message.showToastMessage({
                    message: "Something went wrong. Try again!",
                    variant: "error",
                });
            })
            .finally(() => {
                setIsGettingDetails(false);
            });
    };


    useEffect(() => {
        if (currentSelection?.workshop_action_id)
            getAllActionStages();
    }, [currentSelection?.workshop_action_id, page, pageSizes]);
    return (
        <div>
            <Dialog
                open={true}
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                className={classes.modal}
            >
                <div className={classes.container}>
                    <Typography
                        variant="body1"
                        color="primary"
                        style={{ marginBottom: "20px" }}
                        className={classes.popupTitle}
                    >
                        Stages
                    </Typography>

                    <Grid item xs={6} style={{ marginTop: "10px", }}>
                        <MaterialTable
                            style={{
                                boxShadow: "0px 1px 3px #00000033",
                                borderRadius: "4px",
                                width: "650px",
                                // minWidth: "500px"
                            }}
                            isLoading={isGettingDetails}
                            options={tableOptions}
                            title=""
                            icons={tableIcons}
                            columns={column}
                            data={actionStages || []}
                            localization={{
                                body: {
                                    emptyDataSourceMessage: "No data Found",
                                },
                                header: {
                                    actions: "",
                                },
                            }}
                            onChangePage={(page) => {
                                setPage(page);
                            }}
                            onChangeRowsPerPage={(pageSize) => {
                                setPageSizes(pageSize);
                            }}
                            totalCount={totalCount}
                            page={page}
                            detailPanel={(rowData) =>
                                !rowData?.stage_fields?.length ? (
                                    <div
                                        style={{
                                            marginLeft: "83px",
                                            wordWrap: "break-word",
                                            opacity: "50%",
                                            padding: "10px",
                                        }}
                                    >
                                        <span style={{ fontSize: "14px" }}>
                                            No sub fields found
                                        </span>
                                    </div>
                                ) : (
                                    <TableContainer>
                                        <Table>

                                            <TableBody>
                                                {rowData?.stage_fields?.map((item, index) => (
                                                    <TableRow className={classes.tableCell}>
                                                        <TableCell>
                                                            <span style={{ marginLeft: ' 83px', fontSize: '13px' }}>{index + 1}&nbsp;
                                                            </span><span style={{ marginLeft: '14px', fontSize: '13px' }}> {item?.field_name}  </span></TableCell>
                                                        {/* <TableCell> {item?.field_name}</TableCell> */}
                                                    </TableRow>

                                                ))}

                                            </TableBody>
                                        </Table>


                                    </TableContainer>
                                )
                            }
                        />
                    </Grid>
                </div>
            </Dialog>

        </div>
    );
}
