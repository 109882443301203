import React, { useState } from 'react'
import MROButton from "components/buttons";
import ViewIcon from "@material-ui/icons/Visibility";
import { Box, CircularProgress, Grid, Typography, useTheme } from '@material-ui/core';
import makeStyles from "@material-ui/core/styles/makeStyles";
import { ICON_COLOUR } from "lib/constants/style_constants";
import FileUpload from "components/form_components/fileUpload";
import lock from "assets/icons/Lock.svg";
import unlock from "assets/icons/Unlock.svg";
import { useContext } from 'react';
import { ToastMessageContext } from 'lib/contexts/message_context';
import { MaintenanceServices } from 'lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation';
import { _formatDate } from 'lib/utils/common_utils';
import { WorkshopContext } from '../WorkshopContext/WorkshopContext';
import { useEffect } from 'react';
import { BASE_API_URL_BOXP } from 'lib/constants';
import ReleaseCertPopup from './ReleaseCertPopup';

const Styles = {
    svg: {
        filter: "invert(1)",
        height: 18,
        marginBottom: 2,
    },
};
const useStyles = makeStyles((theme) => ({
    wrapper: {
        margin: "3%",
    },
    appBar: {
        backgroundColor: "#fff",
        color: "#000000CC",
        boxShadow: "0px 3px 6px #0000001A",
    },
    backButton: {
        marginRight: theme.spacing(2),
    },
    paper: {
        borderRadius: "5px",
        backgroundColor: "#F5F5F5",
        padding: theme.spacing(2),
        boxShadow: "0px 1px 4px #0000001A",
    },
    partValue: {
        fontSize: "14px",
        color: "#4D4F5C",
        padding: "2px",
        display: "flex",
        alignItems: "center",
    },
    partKey: {
        "& p": {
            fontSize: "14px",
            padding: "1px",
        },
    },
    partCard: {
        borderRadius: "8px",
        padding: "17px 24px",
        "& p": {
            fontSize: "14px",
            padding: "5px",
        },
    },
    sec1: {
        borderRadius: "10px",
        backgroundColor: "#fff",
        padding: theme.spacing(5),
        boxShadow: "0px 1px 4px #0000001A",
    },
    sec: {
        width: "30%",
        height: "90px",
        borderRadius: "6px",
        backgroundColor: "#fff",
        padding: theme.spacing(5),
        boxShadow: "0px 1px 4px #0000001A",
    },
    fileView: {
        backgroundColor: "#ffffff",
        padding: "45px",
        border: "1px solid gray",
        borderRadius: " 8px",
        marginBottom: "12px",
    },
}));

function Scrap(props) {
    const {
        releaseDetails,
        data,
        getReleaseList,
        isGettingDetails,
        editTab,
        setEditTab,
        getHeaderDetails,
        orderStateNum,
        tabStatusScrap,
        closeTabStatusScrap,
        getWorkshopOrderDetails,
        setIsGettingDetails,
        releaseDocuments
    } = props;
    const message = useContext(ToastMessageContext)
    const classes = useStyles();
    // const [aogUpdate, setAogUpdate] = useState();
    // const [errors, setErrors] = useState({});
    const { editButton, setEditButton } =
        useContext(WorkshopContext);
    const [closeTab, setCloseTab] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [busy, setBusy] = useState(null)
    const [isRelease, setIsRelease] = useState(false);
    const [msds, setMsds] = useState([])
    const [multilationFiles, setMultilationFiles] = useState([]);

    // console.log("releaee doc is======================", releaseDocuments, releaseDocuments.unserviceable_label)


    const deleteFileHandler = async (id) => {
        setBusy("file-deleting");
        try {
            const response = await MaintenanceServices.deleteFile(id);

            if (response.success) {
                message.showToastMessage({
                    message: "File deleted successfully",
                    variant: "success",
                });
                // getReleaseList();
                // getWorkshopOrderDetails();
                // setEditTab(!editTab)
                // setDisabled(!disabled);
            }
            setBusy(null);
        } catch (error) {
            message.showToastMessage({
                message: "Something went wrong",
                variant: "error",
            });
            setBusy("file-deleting-error");
        }
    }
    const handleEditAPiCall = async () => {
        setIsGettingDetails(true);
        const param = {
            workshop_order_id: releaseDetails?.workshop_order_id,
            tab: "release",
            release_tab_style: 4

        };
        await MaintenanceServices.editButton(param)
            .then((res) => {
                if (res.success) {
                    getWorkshopOrderDetails();
                    setEditButton(!editButton);
                }
            })
            .catch((err) => {
                message.showToastMessage({
                    message: "Something went wrong. Try again!",
                    variant: "error",
                });
                setBusy(null);
            })
            .finally(() => {
                setBusy(null);
                setIsGettingDetails(false);
            });
    };

    useEffect(() => {
        if (editButton) {
            handleEditAPiCall();
        }
    }, [editButton])

    const handleCloseTab = () => {
        getReleaseList()
        getWorkshopOrderDetails();
        setEditTab(!editTab)
        setDisabled(!disabled);
    }

    const handleTab = () => {
        setEditButton(true)
        setEditTab(!editTab);
        setDisabled(!disabled);
    };

    const theme = useTheme()
    const GetInfoCard = (props) => {
        const classes = useStyles();
        return (
            <Box display="flex">
                <Box flex={15} className={classes.partKey}>
                    <Typography
                        style={{ padding: "5px", color: theme.palette.primary.main }}
                        color="secondary"
                    >
                        {props?.keyName}
                    </Typography>
                </Box>
                <Box flex={50} className={classes.partValue}>
                    {props?.value ?? "-"}
                </Box>
            </Box>

        )


    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData(e.target);

        formData.append("workshop_order_id", releaseDetails?.workshop_order_id,)
        formData.append("release_tab_style", 4)
        formData.append("edit_tab", true)
        formData.append("completed_at", releaseDetails?.completed_at)
        formData.append("completed_by", releaseDetails?.completed_by)

        // console.log("file is multilationFiles================", multilationFiles)
        // if (multilationFiles.length === 0) {
        //     releaseDetails.photos_of_multilation.forEach((_, i) => {
        //         let file_name = releaseDetails.photos_of_multilation[i].file_name
        //         let file = new File([releaseDetails.photos_of_multilation[i]], file_name)
        //         console.log("file is================",  file)
        //             formData.append("photos_of_multilation[]", file)
        //     });
        // } else {
        multilationFiles.forEach(files => formData.append("photos_of_multilation[]", files))
        // }

        // let params = {
        //     edit_tab: true
        // }
        setBusy("loading");
        try {
            const response = await MaintenanceServices.updateScrap(
                formData,
                data.release_id,
            );

            if (response.success) {
                message.showToastMessage({
                    message: "Updated Successfully!",
                    variant: "success",
                });
                handleCloseTab()
            } else {
                message.showToastMessage({
                    message:
                        response.error.length > 0
                            ? response.error?.join(", ")
                            : "Something went wrong!",
                    variant: "error",
                });
            }

            setBusy(null);
        } catch (error) {
            setBusy("error");
            message.showToastMessage({
                message: "Something went wrong",
                variant: "error",
            });
        }
    };

    if (isGettingDetails) {
        return (
            <Box
                width="100%"
                minHeight="400px"
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <CircularProgress color="primary" size={40} />
            </Box>
        );
    }
    return (
        <div><Box style={{ display: "flex", justifyContent: "space-between" }}>
            <Box display="flex" alignItems="center">
                <Typography
                    style={{
                        color: theme.palette.primary.main,
                        marginBottom: "12px",
                        fontSize: "17px",
                    }}
                    color="primary"

                >
                    Documentation
                </Typography>
            </Box>
        </Box>

            <Box
                display="flex"
                alignItems="center"
                bgcolor="#F5F5F5"
                className={classes.partCard}
            >
                <Box flexGrow={1}>
                    <div style={{ width: "100%" }}>
                        <GetInfoCard keyName={"Serviceable Label"} value={
                            releaseDocuments.serviceable_label != undefined && releaseDocuments?.serviceable_label?.length != 0 ?
                                <div style={{ marginTop: "3px" }}>
                                    <ViewIcon
                                        style={{ cursor: "pointer" }}
                                        fontSize="small"
                                        htmlColor={ICON_COLOUR}
                                        onClick={() => {
                                            if (releaseDocuments?.serviceable_label !== undefined) {
                                                window.open(releaseDocuments?.serviceable_label.map(document => document.file_path
                                                ))
                                            }
                                            else {
                                                message.showToastMessage({
                                                    message: "No Label Found.",
                                                    variant: "error",
                                                });
                                            }
                                        }}
                                    />
                                </div> : "N/A"
                        } />
                        <GetInfoCard keyName={"Workshop Report"} value={
                            releaseDocuments?.workshop_report !== undefined && releaseDocuments?.workshop_report?.length != 0 ?
                                <div style={{ marginTop: "3px" }}>
                                    <ViewIcon
                                        style={{ cursor: "pointer" }}
                                        fontSize="small"
                                        htmlColor={ICON_COLOUR}
                                        onClick={() => {
                                            if (releaseDocuments?.workshop_report !== undefined) {
                                                window.open(releaseDocuments?.workshop_report.map(document => document.file_path
                                                ))
                                            }
                                            else {
                                                message.showToastMessage({
                                                    message: "No Report Found.",
                                                    variant: "error",
                                                });
                                            }

                                        }}
                                    />
                                </div> : "N/A"
                        } />
                        <GetInfoCard keyName={"C of C"} value={
                            releaseDocuments?.c_of_c !== undefined && releaseDocuments?.c_of_c.length != 0 ?
                                <div style={{ marginTop: "3px" }}>
                                    <ViewIcon
                                        style={{ cursor: "pointer" }}
                                        fontSize="small"
                                        htmlColor={ICON_COLOUR}
                                        onClick={() => {
                                            if (releaseDocuments?.c_of_c !== undefined) {
                                                window.open(releaseDocuments?.c_of_c.map(document => document.file_path
                                                ))
                                            }
                                            else {
                                                message.showToastMessage({
                                                    message: "No file Found.",
                                                    variant: "error",
                                                });
                                            }

                                        }}
                                    />
                                </div> : "N/A"} />
                        <GetInfoCard keyName={"Release Cert1 "}
                            value={
                                <div style={{ marginTop: "3px" }}>
                                    <ViewIcon
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setIsRelease(true);
                                            setMsds(
                                                releaseDocuments.release_cert_1?.map((item) => ({
                                                    ...item,
                                                    url: item.file_path,
                                                }))
                                            );
                                        }}
                                        fontSize="small"
                                        htmlColor={ICON_COLOUR}
                                    />
                                </div>
                            } />
                        <GetInfoCard keyName={"Release Cert2 "}
                            value={
                                <div style={{ marginTop: "3px" }}>
                                    <ViewIcon
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setIsRelease(true);
                                            setMsds(
                                                releaseDocuments.release_cert_2?.map((item) => ({
                                                    ...item,
                                                    url: item.file_path,
                                                }))
                                            );
                                        }}
                                        fontSize="small"
                                        htmlColor={ICON_COLOUR}
                                    />
                                </div>
                            } />
                        <GetInfoCard keyName={"Scrap Cert "}
                            value={releaseDocuments?.scrap_certificate != [] && releaseDocuments?.scrap_certificate?.length != 0 ?
                                <div style={{ marginTop: "3px" }}>
                                    <ViewIcon
                                        style={{ cursor: "pointer" }}
                                        fontSize="small"
                                        htmlColor={ICON_COLOUR}
                                        onClick={() => {
                                            if (releaseDocuments?.scrap_certificate !== undefined) {
                                                window.open(releaseDocuments?.scrap_certificate.map(document => document.file_path
                                                ))
                                            }
                                            else {
                                                message.showToastMessage({
                                                    message: "No Label Found.",
                                                    variant: "error",
                                                });
                                            }
                                        }}

                                    />
                                </div> : "N/A"} />
                        <GetInfoCard keyName={"Unserviceable Label"}
                            value={
                                releaseDetails?.unserviceable_label !== [] && releaseDocuments?.unserviceable_label?.length != 0 ?
                                    <div style={{ marginTop: "3px" }}>
                                        <ViewIcon
                                            style={{ cursor: "pointer" }}
                                            fontSize="small"
                                            htmlColor={ICON_COLOUR}
                                            onClick={() => {
                                                if (releaseDocuments?.unserviceable_label != undefined) {
                                                    window.open(releaseDocuments?.unserviceable_label.map(document => document.file_path
                                                    ))
                                                }
                                                else {
                                                    message.showToastMessage({
                                                        message: "No label Found.",
                                                        variant: "error",
                                                    });
                                                }

                                            }}
                                        />
                                    </div> : "N/A"} />
                    </div>
                </Box>
            </Box>
            <Typography
                style={{
                    color: theme.palette.primary.main,
                    margin: "15px 0px 12px",
                    fontSize: "17px",
                }}
                color="primary"

            >
                Scrapped Item
            </Typography>
            <Typography style={{ marginBottom: "10px" }}>
                Photos of mutilation
            </Typography>

            <form noValidate onSubmit={handleSubmit}>
                <FileUpload
                    defaultValue={releaseDetails.photos_of_multilation !== undefined
                        ? releaseDetails.photos_of_multilation.map((item) => ({
                            filename: item?.file_name,
                            url: `${item?.file_path}`,
                            id: item?.id,
                        }))
                        : ""}
                    APIUrl={""}
                    setFiles={(files) => setMultilationFiles(files)}
                    uploadButtonView={editTab === false}
                    multiple={true}
                    name={"photos_of_multilation[]"}
                    id="photos_of_multilation[]"
                    action={"Upload Document"}
                    onDelete={disabled ? null : deleteFileHandler}
                    isDeleting={busy === "file-deleting"}
                // onChange={handleChange}
                />
                <Typography
                    style={{
                        color: theme.palette.primary.main,
                        margin: "15px 0px 12px",
                        fontSize: "17px",
                    }}
                    color="primary"

                >
                    Disposal
                </Typography>
                <Box
                    display="flex"
                    alignItems="center"
                    //   justifyContent={(loading || _.isEmpty(partDetails)) && "center"}
                    bgcolor="#F5F5F5"
                    className={classes.partCard}
                >
                    <Box flexGrow={1}>
                        <div style={{ width: "100%" }}>
                            <GetInfoCard keyName={"Completed by"} value={releaseDetails?.completed_by_userstamp} />
                            <GetInfoCard keyName={"Completed"} value={_formatDate(releaseDetails?.completed_at)} />
                        </div>
                    </Box>
                </Box>

                <Grid style={{ display: "flex", justifyContent: "flex-end", marginTop: "15px" }}>
                    {orderStateNum > 9 && tabStatusScrap && props?.permission.write && (
                        <MROButton
                            type="button"
                            variant={"contained"}
                            style={{
                                backgroundColor: "#FFB000",
                                color: "white",
                                display: editTab ? "none" : undefined,
                            }}
                            onClick={() => handleTab()}
                            startIcon={<img style={Styles.svg} src={lock} alt="lock" />}
                        >
                            Edit Tab
                        </MROButton>
                    )}

                    {orderStateNum > 0 && closeTabStatusScrap === 2 && props?.permission.write && (
                        <MROButton
                            variant={"contained"}
                            style={{
                                backgroundColor: "red",
                                color: "white",
                                display: !editTab ? "none" : undefined,
                            }}
                            type="submit"
                            loading={busy === "loading"}
                            startIcon={<img style={Styles.svg} src={unlock} alt="unlock" />}
                            onClick={() => setCloseTab(true)}
                        >
                            Close Tab
                        </MROButton>
                    )}
                </Grid>
            </form>
            {
                isRelease &&
                <ReleaseCertPopup
                    dialogCloseHandler={() => setIsRelease(false)}
                    open={isRelease}
                    msds={msds}
                />
            }
        </div>
    )
}

export default Scrap