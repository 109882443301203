import React, { useEffect, useState } from "react";
import MROButton from "../../buttons";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Close as CloseIcon } from "@material-ui/icons";
import { ReactComponent as UploadIcon } from "../../../assets/icons/UploadDoc.svg";
import DataService from "../../../lib/services/api";
import Document from "./pdf.png";
import ImageFile from "./Image.png";
import { BASE_API_URL_BOXR } from "../../../lib/constants";
import clxc from "clsx";
import { Box, CircularProgress, Typography, useTheme } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  fileLabel: {
    color: theme.palette.primary.main,
    marginBottom: "5px",
    display: "block",
  },
  fileView: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "15px",
    border: "none",
    minHeight: "146px",
    borderRadius: " 8px",
    backgroundColor: "#ffff",
    marginBottom: "12px",
    "& ul": {
      display: "flex",
      padding: "0",
      listStyle: "none",
      flexWrap: "wrap",
      "& li": {
        margin: "0 10px",
        textAlign: "center",
        "& div": {
          width: "115px",
          height: "60px",
          position: "relative",
          marginBottom: "10px",
          "& img": {
            // maxWidth: "100%",
            height: "-webkit-fill-available",
            objectFit: "cover",
          },
          "& div": {
            position: "absolute",
            top: "0",
            right: "0",
            width: "20px",
            backgroundColor: "#F44336",
            height: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#fff",
            borderRadius: "50%",
          },
        },
        "& span": {
          width: "60px",
          display: "-webkit-box",
          WebkitLineClamp: "1",
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
        },
      },
    },
  },
  fileError: {
    color: "#f44336",
  },
}));

function SignatureFile(props) {
  const classes = useStyles();
  const {
    name,
    defaultValue,
    process,
    id,
    showBorderOnNoFiles,
    APIUrl,
  } = props;

  const [files, setFiles] = useState([]);
  const [selectedFileId, setSelectedFileId] = useState(null);
  const theme = useTheme();

  // console.log("default balue is===================", defaultValue)

  useEffect(() => {
    if (process && files && files[0] && id) {
      const formData = new FormData();
      formData.append(name, files[0]);
      formData.append("id", id);
      DataService.updateData(formData, "", "authDoc").then((res) => { });
    }
  }, [process]);


  return (
    <div>

      {files?.length || defaultValue?.length || showBorderOnNoFiles ?
        <div style={{ width: "35%" }} className={name === "Line" ? "" : classes.fileView}>
          {showBorderOnNoFiles && !defaultValue?.length && !files?.length && (
            <Typography style={{ fontSize: "14px" }}>
              No Signature found
            </Typography>
          )}
          <ul>
            {defaultValue?.length
              ? defaultValue?.map((file, index) => {
                const fileName = file.filename;
                // const objectUrl = URL.createObjectURL(file);
                // let uploadImage = objectUrl;
                let uploadImage = "";

                const fileExtension = file?.filename?.split(".")[1];
                if (
                  ["jpg", "jpeg", "png"].includes(
                    fileExtension?.toLowerCase()
                  )
                )
                  uploadImage = `${APIUrl ?? BASE_API_URL_BOXR}${file?.url}`
                return (
                  <li
                    key={file.id}
                    onClick={() =>
                      window.open(`${APIUrl ?? BASE_API_URL_BOXR}${file?.url}`)
                    }
                    style={{ cursor: "pointer", display: 'flex', justifyContent: "center", alignItems: "center", }}
                  >
                    <div style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                      <img src={uploadImage} width="200px" height="200px" alt={""} />

                      {/* <span>{fileName}</span> */}
                    </div>
                  </li>
                );
              })
              : null}
          </ul>
        </div>
        : null}
    </div>
  );
}

export default SignatureFile;
