import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Box, CircularProgress, useTheme } from '@material-ui/core';

function a11yProps(index) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar: {
        backgroundColor: "#fff",
        color: theme.palette.primary.main,
        padding: "1% 1% 0 1%"
    },
    tabRoot: {
        "&.MuiTab-root": {
            minHeight: "32px"
        }
    }, tabsRoot: {
        "&.MuiTabs-root": {
            minHeight: "38px"
        }
    },
}));

const MROTab = withStyles((theme) => ({
    root: {
        overflowX: "hidden !important",
        textTransform: 'none',
        fontWeight: 400,
        color: "black",
        '& .nav_icon': {
            visibility: 'hidden'
        },
        '&$selected': {
            color: theme.palette.primary.main,
            fontWeight: 500,
            '& .nav_icon': {
                visibility: "visible"
            }
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />)


const MROTab1 = withStyles((theme) => ({
    root: {
        overflowX: "hidden !important",
        textTransform: 'none',
        fontWeight: 400,
        color: "theme.palette.primary.main",
        '& .nav_icon': {
            visibility: 'hidden'
        },
        '&$selected': {
            color: "#FFF",
            background: theme.palette.primary.main,
            fontWeight: 500,
            '& .nav_icon': {
                visibility: "visible"
            }
        },
    },


    selected: {},
}))((props) => <Tab disableRipple {...props} />)

export default function TopNavigationBar(props) {
    const classes = useStyles();
    const [currentTab, setCurrentTab] = useState(0);
    const tabProp = props.tabProperties || [];
    const handleChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    const currentPath = props.location.pathname;
    const getTabIndex = () => {
        const tabIndex = tabProp.findIndex(tab => currentPath.indexOf(tab.section) !== -1);
        return tabIndex >= 0 ? tabIndex : 0;
    }

    useEffect(() => {
        setCurrentTab(getTabIndex())
    }, [currentTab])

    return (
        <div className={classes.root}>
            <AppBar position="static" className={classes.appBar} elevation={0}>
                <Tabs value={currentTab} onChange={handleChange} aria-label="top navigation bar" indicatorColor={"primary"} TabIndicatorProps={{ style: { height: '3px' } }}>
                    {tabProp.map((tab, i) =>
                        <MROTab key={i} label={tab.label} {...a11yProps(i)} onClick={() => { props.history.push(tab.redirect) }} />
                    )}
                </Tabs>
            </AppBar>
        </div>
    );
}

export function TabSectionBar(props) {
    const classes = useStyles();
    const { isGettingDetails, currentTab, setCurrentTab, disableBack, disableNavigation } = props;
    const tabProp = props.tabProperties || [];
    const handleChange = (event, newValue) => {
        if (disableNavigation) {
            return;
        }
        else if (disableBack) {
            if (newValue > currentTab) {
                setCurrentTab(newValue);
            }
        }
        else {
            setCurrentTab(newValue);
        }
    };

    if (isGettingDetails) {
        return (
            <Box
                width="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <CircularProgress color="primary" size={40} />
            </Box>
        );
    }

    return (
        <div className={classes.root}>
            <AppBar position="static" className={classes.appBar} elevation={0}>
                <Tabs value={currentTab} variant="scrollable" scrollButtons="auto" onChange={handleChange} aria-label="tab bar" indicatorColor={"primary"} TabIndicatorProps={{ style: { height: '3px' } }}>
                    {tabProp.map((tab, i) =>
                        <MROTab value={tab.id} key={i} label={tab.label} {...a11yProps(i)} />
                    )}
                </Tabs>
            </AppBar>
        </div>
    );
}




export function TabSectionBarV3(props) {
    const classes = useStyles();
    const theme = useTheme()
    const { isGettingDetails, currentTab, setCurrentTab, disableBack, disableNavigation } = props;
    const tabProp = props.tabProperties || [];
    const handleChange = (event, newValue) => {
        if (disableNavigation) {
            return;
        }
        else if (disableBack) {
            if (newValue > currentTab) {
                setCurrentTab(newValue);
            }
        }
        else {
            setCurrentTab(newValue);
        }
    };

    if (isGettingDetails) {
        return (
            <Box
                width="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <CircularProgress color="primary" size={40} />
            </Box>
        );
    }

    return (
        <div className={classes.root}>
            <AppBar position="static" className={classes.appBar} elevation={0}>
                <Tabs value={currentTab} variant="scrollable" scrollButtons="auto" onChange={handleChange}
                    aria-label="tab bar" indicatorColor={"primary"} classes={{ root: classes.tabsRoot }} TabIndicatorProps={{ style: { height: '3px' } }}>
                    {tabProp.map((tab, i) =>
                        <MROTab1 value={tab.id} key={i} label={tab.label} {...a11yProps(i)} classes={{ root: classes.tabRoot }} style={{
                            border: `1px solid ${theme.palette.primary.main}`,
                            borderTopLeftRadius: "15px",
                            borderTopRightRadius: "15px",
                        }} />
                    )}
                </Tabs>
            </AppBar>
        </div>
    );
}
