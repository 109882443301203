import { ToastMessageContext } from "lib/contexts/message_context";
import React, { useState, useCallback, useEffect, useContext } from "react";

import { useHistory } from "react-router";

import WarningDialog from "views/Procurement/Services/Requests/WarningDialog";

function RouterPrompt(props) {
  const {
    when,
    onOK = () => true,
    onCancel = () => false,
    title,
    description,
    tab,
  } = props;

  const history = useHistory();

  const message = useContext(ToastMessageContext);

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState("");
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    if (when) {
      history.block((prompt) => {
        setCurrentPath(prompt.pathname);
        setShowPrompt(true);
        setShowToast(true);
        return "false";
      });
    } else {
      history.block(() => {});
    }

    return () => {
      history.block(() => {});
    };
  }, [history, when]);

  const handleOK = useCallback(async () => {
    if (onOK) {
      const canRoute = await Promise.resolve(onOK());
      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
    }
  }, [currentPath, history, onOK]);

  const handleCancel = useCallback(async () => {
    if (onCancel) {
      const canRoute = await Promise.resolve(onCancel());
      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
    }
    setShowPrompt(false);
  }, [currentPath, history, onCancel]);

  useEffect(() => {
    if ((showToast && tab === "induction") || (showToast && tab === "quote") || (showToast && tab === "planning")) {
      message.showToastMessage({
        message: " Close Tab before leaving this tab!",
        variant: "error",
      });
    }
  }, [showToast, tab, currentPath]);

  if (showToast && tab === "induction" || (showToast && tab === "quote") || (showToast && tab === "planning")) {
    return null;
  }
  

  return showPrompt ? (
    <WarningDialog
      titleColor="warning"
      action={showPrompt}
      handleClose={handleCancel}
      handleConfirm={handleOK}
      title={title ?? "Warning"}
      description={
        description ??
        "Data is NOT saved! Are you sure you want to leave without saving?"
      }
    />
  ) : null;
}

export default RouterPrompt;
