import React, { useContext, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import MROButton from "components/buttons";
import { ToastMessageContext } from "lib/contexts/message_context";
import BoxpDataService from "lib/services/api/boxp_index";
import { CalenderMaintenanceService } from "lib/services/api/operaitons/WorkShop/Storage/CalenderMaintenanceService";

const useStyles = makeStyles(() => ({
  modal: {
    "& .MuiDialog-paperWidthMd": {
      width: "25%",
    },
  },
  title: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  warning: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "16px",
    marginBottom: "20px",
  },
  actionButtons: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

function DeleteAction(props) {
  const classes = useStyles();
  const {
    handleClose,
    open,
    selectedId,
    section,
    getAllActions,
    closeHandler,
    setSelectedRowId,
  } = props;
  const message = useContext(ToastMessageContext);
  const [busy, setBusy] = useState(null);

  const handleSubmit = () => {
    setBusy("loading");
    CalenderMaintenanceService.deleteAction(selectedId)
      .then((res) => {
        if (res.success) {
          setBusy(null);
          setSelectedRowId(null);
          getAllActions();
          closeHandler();
          message.showToastMessage({
            message: "Action removed successfully",
            variant: "success",
          });
        }
      })
      .catch(() => {
        setBusy(null);
        handleClose();
        message.showToastMessage({
          message: "Can’t be deleted! - Items exist against action!!",
          variant: "error",
        });
      });
    // BoxpDataService.boxpDeleteData(selectedId, section , "").then((res) => {
    //     if (res.success) {
    //         setBusy(null);
    //         setSelectedRowId(null)
    //         getAllActions();
    //         closeHandler();
    //         message.showToastMessage({ message: "Action removed successfully", variant: "success" });
    //     }else if(!res.scucces){
    //         setBusy(null);
    //         handleClose();
    //         // message.showToastMessage({ message: "Can’t be deleted! - Items exist against action!!", variant: "error" });
    //         message.showToastMessage({ message: "Action removed successfullys", variant: "success" });
    //     }
    // })
  };
  return (
    <div>
      <Dialog
        maxWidth={"md"}
        fullWidth={true}
        open={open}
        className={classes.modal}
        onClose={handleClose}
      >
        <DialogTitle>
          <Typography color="primary" className={classes.title}>
            Delete Item
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography className={classes.warning}>
            This cannot be undone. Are you sure?
          </Typography>
          <Grid className={classes.actionButtons} spacing={1} container>
            <Grid item>
              <MROButton
                type="button"
                variant={"contained"}
                onClick={handleClose}
              >
                Cancel
              </MROButton>
            </Grid>
            <Grid item>
              <MROButton
                loading={busy === "loading"}
                onClick={handleSubmit}
                type="submit"
                variant={"contained"}
                color="primary"
              >
                Yes
              </MROButton>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default DeleteAction;
