import React, { useState } from "react";
import MROFullScreenRightDrawer from "components/fullwidth_dialog";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import makeStyles from "@material-ui/core/styles/makeStyles";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import Typography from "@material-ui/core/Typography";
import { Box, useTheme } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { ICON_COLOUR } from "lib/constants/style_constants";
import AddSpecialistEquipment from "./AddSpecialistEquipment";
import { userDetails } from "lib/utils/helperFunctions";
import { formatDate } from "lib/utils/common_utils";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: "3%",
  },
  appBar: {
    backgroundColor: "#fff",
    color: "#000000CC",
    boxShadow: "0px 3px 6px #0000001A",
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  paper: {
    borderRadius: "5px",
    backgroundColor: "#F5F5F5",
    padding: theme.spacing(2),
    boxShadow: "0px 1px 4px #0000001A",
  },
  partValue: {
    fontSize: "14px",
    color: "#4D4F5C",
    padding: "2px",
    display: "flex",
    alignItems: "center",
  },
  partKey: {
    "& p": {
      fontSize: "14px",
      padding: "1px",
    },
  },
  partCard: {
    borderRadius: "8px",
    padding: "17px 24px",
    "& p": {
      fontSize: "14px",
      padding: "5px",
    },
  },
  sec1: {
    borderRadius: "10px",
    backgroundColor: "#fff",
    padding: theme.spacing(5),
    boxShadow: "0px 1px 4px #0000001A",
  },
}));

function SpecialistEquip(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [isEdit, setIsEdit] = useState(false);
  const { handleClose, action, workshop_id = { workshop_id }, orderState, currentSelection, getWorkshopEquipment } = props;

  const GetInfoCard = (props) => {
    const classes = useStyles();
    const theme = useTheme();

    return (
      <Box display="flex">
        <Box flex={50} className={classes.partKey}>
          <Typography
            style={{ padding: "5px", color: theme.palette.primary.main }}
            color="secondary"
          >
            {props?.keyName}
          </Typography>
        </Box>
        <Box flex={50} className={classes.partValue}>
          {props?.value ?? "-"}
        </Box>
      </Box>
    );
  };

  return (
    <div>
      <MROFullScreenRightDrawer open={true}>
        <AppBar position="static" className={classes.appBar} elevation={0}>
          <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
            <Box display="flex" alignItems="center">
              <IconButton
                onClick={handleClose}
                edge="start"
                className={classes.backButton}
                color="inherit"
                aria-label="back"
              >
                <BackIcon />
              </IconButton>
              <Typography variant="subtitle2" style={{ fontSize: "17px" }}>
                Specialist Equipment
              </Typography>
            </Box>
            {orderState != "Closed" && props?.permission.write && !currentSelection?.purchased &&
              <IconButton onClick={() => setIsEdit(true)}>
                <EditIcon fontSize="small" color={ICON_COLOUR} />
              </IconButton>}
          </Toolbar>
        </AppBar>

        <div className={classes.wrapper}>
          <Box>
            <Typography
              style={{
                color: theme.palette.primary.main,
                marginBottom: "12px",
                fontSize: "20px",
              }}
              color="primary"
              variant="subtitle2"
            >
              Details
            </Typography>

            <Box
              display="flex"
              alignItems="center"
              //   justifyContent={(loading || _.isEmpty(partDetails)) && "center"}
              bgcolor="#F5F5F5"
              className={classes.partCard}
            >
              <Box flexGrow={1}>
                <div style={{ width: "100%" }}>
                  <GetInfoCard keyName={"Raised by"} value={currentSelection?.raised_by || "-"} />
                  <GetInfoCard keyName={"Action #"} value={currentSelection.workshop_action_number || "-"} />
                  <GetInfoCard keyName={""} value="" />
                </div>
              </Box>
              <Box flexGrow={1}>
                <div style={{ width: "100%" }}>
                  <GetInfoCard keyName={"Raised"} value={currentSelection.raised || "-"} />
                  <GetInfoCard keyName={"Desired Date"} value={currentSelection.desired_date || "-"} />
                  <GetInfoCard keyName={"Duration (days)"} value={currentSelection.duration || "-"} />
                </div>
              </Box>
            </Box>
          </Box>

          <Box>
            <Typography
              style={{
                color: theme.palette.primary.main,
                marginBottom: "12px",
                fontSize: "20px",
              }}
              color="primary"
              variant="subtitle2"
            >
              Description
            </Typography>

            <Box
              display="flex"
              alignItems="center"
              //   justifyContent={(loading || _.isEmpty(partDetails)) && "center"}
              bgcolor="#F5F5F5"
              className={classes.partCard}
            >

              {currentSelection.description}
            </Box>
          </Box>

          <Box>
            <Typography
              style={{
                color: theme.palette.primary.main,
                marginBottom: "12px",
                fontSize: "20px",
              }}
              color="primary"
              variant="subtitle2"
            >
              Purchasing Details
            </Typography>

            <Box
              display="flex"
              alignItems="center"
              //   justifyContent={(loading || _.isEmpty(partDetails)) && "center"}
              bgcolor="#F5F5F5"
              className={classes.partCard}
            >
              <Box flexGrow={1}>
                <div style={{ width: "100%" }}>
                  <GetInfoCard keyName={"Equipment Order #"} value={currentSelection.order_number || "-"} />
                  <GetInfoCard keyName={"Supplier Ref"} value={currentSelection.supplier_reference || "-"} />
                  <GetInfoCard keyName={"Purchased By"} value={currentSelection.purchased_by || "-"} />
                </div>
              </Box>
              <Box flexGrow={1}>
                <div style={{ width: "100%" }}>
                  <GetInfoCard keyName={"Supplier"} value={currentSelection?.supplier} />
                  <GetInfoCard keyName={"Planned for"} value={formatDate(currentSelection.planned_date)} />
                  <GetInfoCard keyName={"Purchased"} value={currentSelection?.purchased_at} />
                </div>
              </Box>
            </Box>
          </Box>
          <Box>
            <Typography
              style={{
                color: theme.palette.primary.main,
                marginBottom: "12px",
                fontSize: "20px",
              }}
              color="primary"
              variant="subtitle2"
            >
              Closure
            </Typography>

            <Box
              display="flex"
              alignItems="center"
              bgcolor="#F5F5F5"
              className={classes.partCard}
            >
              <Box flexGrow={1}>
                <div style={{ width: "100%" }}>
                  <GetInfoCard keyName={"Receipt by"} value={currentSelection.receipt_by || "-"} />
                </div>
              </Box>
              <Box flexGrow={1}>
                <div style={{ width: "100%" }}>
                  <GetInfoCard keyName={"Receipt"} value={currentSelection.receipt_at || "-"} />
                </div>
              </Box>
            </Box>
          </Box>
        </div>
      </MROFullScreenRightDrawer>

      {isEdit && (
        <AddSpecialistEquipment
          workshop_id={workshop_id}
          action={action}
          open={isEdit}
          isEdit={isEdit}
          currentSelection={currentSelection}
          onClose={() => setIsEdit(false)}
          closeHandler={handleClose}
          getWorkshopEquipment={getWorkshopEquipment}
        />
      )}
    </div>
  );
}

export default SpecialistEquip;
