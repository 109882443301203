import {
    AppBar,
    IconButton,
    Toolbar,
    Typography,
    Paper,

} from "@material-ui/core";
import MROFullScreenRightDrawer from "components/fullwidth_dialog";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import React, { useContext, useEffect, useMemo, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MaterialTable from "material-table";
import tableIcons, { options } from "components/universal/table_attributes";
import { TabSectionBar } from "components/tab_component";
import Divider from "@material-ui/core/Divider";
import Accumulative from "./Accumulative";
import InitialExceptions from "./InitialExceptions";
import moment from "moment";





const useStyles = makeStyles((theme) => ({
    wrapper: {
        margin: "3%",
    },
    appBar: {
        backgroundColor: "#fff",
        color: "#000000CC",
        boxShadow: "0px 3px 6px #0000001A",
        fontSize: '14px'
    },
    backButton: {
        marginRight: theme.spacing(2),
    },
    disableField: {
        pointerEvents: "none",
        backgroundColor: "#F5F5F5",
    },
    title: {
        fontSize: '16px',
        color: theme.palette.primary.main
    }, title1: {
        fontSize: '14px',

    },
    detail: {
        backgroundColor: "#fff",
        padding: "1%",
    },
    spacing: {
        paddingTop: '15px', paddingBottom: '10px'
    },

    consolidatedTxt: {
        display: "flex", margin: '-23px 0px 20px 1px'

    },
    text: {
        fontSize: "12px"

    },
    button: {
        background: theme.palette.primary.main,
        width: "36px",
        height: " 36px",
        borderRadius: "5px"
    },
    noteText: {
        color: theme.palette.primary.main,
        paddingLeft: "10px", fontStyle: "italic", fontSize: "15px"
    }

}));




export default function CreateNewApprovedPosition(props) {

    const classes = useStyles();
    const {
        handleClose,
        jobData,
        estData,
        approvedPositionnData,
        currentSelectedOrder,
        currentPosition,
        getApprovedPosition,
        appPosition,
        currPosition,
        setApprovalData,
        setCurrentData,
        setVarianceData,
        setApprovalInitialData,
        approvalInitialData
    } = props;

    const [currentTab, setCurrentTab] = useState(0);

    const navMenu = [

        {
            label: (
                <div className={classes.indicator}>Initial & Exceptions&nbsp;&nbsp; </div>
            ),
        },
        { label: <div className={classes.indicator}>Accumulative&nbsp;&nbsp; </div> },
    ];

    useEffect(() => {

        let appData = null;
        let curData = null;
        let today = moment().format("DD/MM/YYYY")
        if (appPosition?.data !== undefined && appPosition?.data.length > 0) {
            appData = appPosition?.data[appPosition?.data.length - 1]
            let tat = 0
            let date = today ? moment(today, "DD/MM/YYYY HH:mm:ss").add(tat, "days").format("DD/MM/YYYY") : null

            //inducted_start
            setApprovalData((ps) => ({
                ...ps,
                position: appData?.approval_number,
                tat: appData?.tat_days_count,
                etd: date,
                labour: appData?.labour_value || 0,
                parts: appData?.parts_value || 0,
                services: appData?.service_value || 0,
                equipment: appData?.equipment_value || 0,
                aog_fee: appData?.aog_fee || 0,
                total: appData?.total,
                currency: appData?.currency_name,
                approvalType: appData?.approval_type,
                release_type_id: appData?.release_type_id,
                sales_rate: appData?.customer_sales_rate,
                standard_id: appData?.workshop_standard_price_id,
                inducted_start: appData?.inducted_start ? appData?.inducted_start : null,
                tat_remaining: null

            }))
            setApprovalInitialData((ps) => ({
                ...ps,
                position: appData?.approval_number,
                tat: appData?.tat_days_count,
                etd: date,
                labour: appData?.initial_labour,
                parts: appData?.initial_parts,
                services: appData?.initial_services,
                equipment: appData?.initial_equipment,
                aog_fee: appData?.initial_aog,
                total: appData?.initial_total,
                currency: appData?.currency_name,
                approvalType: appData?.approval_type,
                release_type_id: appData?.release_type_id,
                sales_rate: appData?.customer_sales_rate,
                standard_id: appData?.workshop_standard_price_id,
                inducted_start: appData?.inducted_start ? appData?.inducted_start : null,
                tat_remaining: null
            }))




        }

        if (appData === null) {
            // console.log("data====", appData, currentSelectedOrder)
            let tat = 0
            let date = today ? moment(today, "DD/MM/YYYY HH:mm:ss").add(tat, "days").format("DD/MM/YYYY") : null
            setApprovalData((ps) => ({
                ...ps,
                approvalType: null,
                tat: 0,
                etd: date,
                inducted_start: currentSelectedOrder?.inducted_start ? moment(currentSelectedOrder?.inducted_start, "DD/MM/YYYY HH:mm:ss").format("YYYY-MM-DD HH:mm:ss") : null,
                currency: currentSelectedOrder?.currency
            }))
            setApprovalInitialData((ps) => ({
                ...ps,
                approvalType: null,
                tat: 0,
                etd: date,
                inducted_start: currentSelectedOrder?.inducted_start ? moment(currentSelectedOrder?.inducted_start, "DD/MM/YYYY HH:mm:ss").format("YYYY-MM-DD HH:mm:ss") : null,
                currency: currentSelectedOrder?.currency

            }))
            setCurrentTab(0)
        }

        if (currPosition?.data !== undefined && currPosition?.data.length > 0) {

            curData = currPosition?.data[currPosition?.data.length - 1]

            let total = +curData?.est_labour + (+curData?.parts) + (+curData?.services) + (+curData?.equipment) + (+curData?.aog_fee)

            setCurrentData((ps) => ({
                ...ps,

                tat: curData?.tat_days,
                labour: curData?.est_labour || 0,
                parts: curData?.parts || 0,
                services: curData?.services || 0,
                equipment: curData?.equipment || 0,
                aog_fee: curData?.aog_fee || 0,
                total: total ? parseFloat(total).toFixed(2) : 0,
                currency: appData?.currency_name ? appData?.currency_name : currentSelectedOrder?.currency,
                t_labour: curData?.labour || 0

            }))
        }
        if (curData !== null) {

            let total = +curData?.est_labour + (+curData?.parts) + (+curData?.services) + (+curData?.equipment) + (+curData?.aog_fee)
            setVarianceData((ps) => ({
                ...ps,
                labour: parseFloat(parseFloat(curData?.est_labour).toFixed(2) - parseFloat(appData?.labour_value) || 0).toFixed(2),
                parts: parseFloat(parseFloat(curData?.parts).toFixed(2) - parseFloat(appData?.parts_value) || 0).toFixed(2),
                services: parseFloat(parseFloat(curData?.services).toFixed(2) - parseFloat(appData?.service_value) || 0).toFixed(2),
                equipment: parseFloat(parseFloat(curData?.equipment).toFixed(2) - parseFloat(appData?.equipment_value) || 0).toFixed(2),
                aog_fee: parseFloat(parseFloat(curData?.aog_fee).toFixed(2) - parseFloat(appData?.aog_fee) || 0).toFixed(2),
                total: parseFloat(parseFloat(total) - parseFloat(appData?.total) || 0).toFixed(2),
                currency: appData?.currency_name ? appData?.currency_name : currentSelectedOrder?.currency,

            }))


        }

    }, [appPosition, currPosition])
    useEffect(() => {

    }, [])

    useEffect(() => {
        if (approvedPositionnData !== undefined) {
            if (approvedPositionnData?.approvalType === null || approvedPositionnData?.approvalType === undefined || approvedPositionnData?.approvalType === 1) {
                setCurrentTab(0)
            } else {
                setCurrentTab(1)
            }
        }
    }, [approvedPositionnData])


    const columns = [
        {
            title: "",
            field: "name",
            width: "20%",
            headerStyle: {
                textAlign: "left",
            },
            cellStyle: {
                width: "20%",
                textAlign: "left",
                paddingLeft: '12px'
            },
        },
        {
            title: "Position",
            field: "position"
        },
        {
            title: "TAT*",
            field: "tat"
        },
        {
            title: "ETD",
            field: "etd"
        },
        {
            title: "Labour**",
            field: "labour",
            render: (rowData) => <div style={{ color: rowData?.name === "Variance" ? parseFloat(rowData?.labour) <= 0 ? "#4FC605" : "#FF0202" : "", fontWeight: rowData?.name === "Variance" ? 600 : "" }}>{rowData?.labour}</div>
        },
        {
            title: "Parts",
            field: "parts",
            render: (rowData) => <div style={{ color: rowData?.name === "Variance" ? parseFloat(rowData?.parts) <= 0 ? "#4FC605" : "#FF0202" : "", fontWeight: rowData?.name === "Variance" ? 600 : "" }}>{rowData?.parts}</div>
        },
        {
            title: "Services",
            field: "services",
            render: (rowData) => <div style={{ color: rowData?.name === "Variance" ? parseFloat(rowData?.services) <= 0 ? "#4FC605" : "#FF0202" : "", fontWeight: rowData?.name === "Variance" ? 600 : "" }}>{rowData?.services}</div>
        },
        {
            title: "Equipment",
            field: "equipment",
            render: (rowData) => <div style={{ color: rowData?.name === "Variance" ? parseFloat(rowData?.equipment) <= 0 ? "#4FC605" : "#FF0202" : "", fontWeight: rowData?.name === "Variance" ? 600 : "" }}>{rowData?.equipment}</div>
        },
        {
            title: "Other",
            field: "aog_fee",
            render: (rowData) => <div style={{ color: rowData?.name === "Variance" ? parseFloat(rowData?.aog_fee) <= 0 ? "#4FC605" : "#FF0202" : "", fontWeight: rowData?.name === "Variance" ? 600 : "" }}>{rowData?.aog_fee}</div>
        },
        {
            title: "Total",
            field: "total",
            render: (rowData) => <div style={{ color: rowData?.name === "Variance" ? parseFloat(rowData?.total) <= 0 ? "#4FC605" : "#FF0202" : "", fontWeight: rowData?.name === "Variance" ? 600 : "" }}>{rowData?.total}</div>
        }, {
            title: "Currency",
            field: "currency"
        },




    ]

    const tableOptions = {
        ...options,
        paging: false,
        search: false,
        paging: false,
        rowStyle: { height: 42 },
    };








    return (
        <div>
            <MROFullScreenRightDrawer open={true}>

                <AppBar position="static" className={classes.appBar} elevation={0}>
                    <Toolbar>
                        <IconButton
                            onClick={handleClose}
                            edge="start"
                            className={classes.backButton}
                            color="inherit"
                            aria-label="back"
                        >
                            <BackIcon />
                        </IconButton>
                        <Typography variant="subtitle2" style={{ fontSize: "17px" }}>
                            Create New Approved Position
                        </Typography>
                    </Toolbar>
                </AppBar>

                <div className={classes.wrapper}>
                    <Typography className={classes.title}>Job Overview</Typography>
                    <div className={classes.spacing}>
                        <MaterialTable
                            style={{
                                boxShadow: "0px 1px 4px #00000033",
                            }}
                            // isLoading={loadingCurrentPosition}
                            icons={tableIcons}
                            title={""}
                            columns={columns}
                            data={jobData || []}
                            options={tableOptions}
                            components={{
                                Toolbar: () => null,
                            }}
                            localization={{
                                toolbar: {},
                                body: {
                                    emptyDataSourceMessage: "No data found",
                                    filterRow: {
                                        filterTooltip: "Filter",
                                    },
                                },
                            }}

                        />
                    </div>




                    <div>
                        <i>* Current Position TAT is a live value from receipt / start of job to today.</i>
                    </div>
                    <div className={classes.spacing}>
                        <i>** Labour figure shown in the table is calculated from the estimated hours from each action. The ACTUAL bookings currently equate to:  <span className={classes.noteText}>{currentPosition?.t_labour}  </span></i>
                    </div>
                    <Typography className={classes.title}>New Approval</Typography>
                    <div className={classes.spacing}>
                        Select the type of Approval you want to create
                    </div>
                    <Paper style={{ marginTop: '10px' }}>
                        <TabSectionBar
                            // completed={completed}
                            {...props}
                            tabProperties={navMenu}
                            currentTab={currentTab}
                            setCurrentTab={setCurrentTab}
                            isGettingDetails={false}
                        />
                        <Divider
                            variant="fullWidth"
                            style={{ padding: "1px", backgroundColor: "rgba(222, 214, 214, 0.2)" }}
                        />

                        {currentTab == 1 &&
                            <Accumulative
                                handleClose={handleClose}
                                estData={estData}
                                approvedPositionnData={approvedPositionnData}
                                currentSelectedOrder={currentSelectedOrder}
                                currentPosition={currentPosition}
                                getApprovedPosition={getApprovedPosition} />

                        }

                        {currentTab === 0 &&
                            <InitialExceptions
                                handleClose={handleClose}
                                estData={estData}
                                approvedPositionnData={approvalInitialData}
                                currentSelectedOrder={currentSelectedOrder}
                                currentPosition={currentPosition}
                                getApprovedPosition={getApprovedPosition}

                            />

                        }

                    </Paper>
                </div>



            </MROFullScreenRightDrawer >




        </div >
    )
}
