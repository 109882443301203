import React, { useContext, useEffect, useState } from "react";
import MROTextField from "components/form_components/TextField";
import { Box } from "@material-ui/core";
import MROFullScreenRightDrawer from "components/fullwidth_dialog";
import {
  Toolbar,
  IconButton,
  AppBar,
  Typography,
  Grid,
  MenuItem,
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import FileUpload from "components/form_components/fileUpload";
import MROButton from "components/buttons";
import DateField from "components/form_components/DateField";
import InfoPopup from "views/Procurement/Services/Requests/InfoPopup";
import MROOnSiteOffSite from "components/form_components/MRoOnSite_OffSite_Field";
import { userDetails, validateFormData } from "lib/utils/helperFunctions";
import { ServicesAndEquip } from "lib/services/api/operaitons/WorkShop/Services_Equipment/services";
import { ToastMessageContext } from "lib/contexts/message_context";
import ServiceRequestListClientServices from "lib/services/api/procurement/Services";
import { BASE_API_URL_BOXR } from "lib/constants";
import DeleteIcon from "@material-ui/icons/Delete";
import { ICON_COLOUR } from "lib/constants/style_constants";
import DeleteServices from './DeleteServices'
import { MaintenanceServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation";


const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#fff",
    color: "#000000CC",
    boxShadow: "0px 3px 6px #0000001A",
  },
  root: {
    backgroundColor: "#fff",
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  wrapper: {
    padding: "3%",
    background: "white",
  },
  title: {
    fontSize: "17px",
    marginRight: "20px",
  },
  paper: {
    borderRadius: "2px",
    backgroundColor: "#F5F5F5",
    // padding: theme.spacing(2),
    boxShadow: "0px 1px 4px #0000001A",
  },
  resize: {
    textTransform: "none",
  },
  actionButtons: {
    // paddingTop: "15px",
    // paddingBottom: "10px",
    textAlign: "right",
    marginRight: "20px",
    "& button:not(:last-child)": {
      marginRight: "20px",
    },
  },
}));

function ManageSpecialistService(props) {
  const classes = useStyles();
  const { onClose, workshop_id, dependencies, action, currentSelection, getServices, closeHandler, baseId, techData } =
    props;
  const message = useContext(ToastMessageContext);
  const [onOffSite, setOnOffSite] = useState(null);
  const [errors, setErrors] = useState({});
  const [busy, setBusy] = useState(null);
  const [files, setFiles] = useState([]);
  const [deleteAction, setDeleteAction] = useState(false);
  const [actionNumber, setActionNumber] = useState([]);
  const [releaseReqData, setReleaseReqData] = useState({})
  const [fieldsData, setFieldsData] = useState({
    workshop_action_id: null,
    part_number: null,
    serial_number: null,
    approval_type_id: null,
    task_description: null,
    tech_data_ref: null,
    description: null,
    desired_date: null,
  });

  // console.log("base is is=====================", baseId)

  let releaseBStatus = releaseReqData?.data?.release_type_b === undefined || releaseReqData?.data?.release_type_b === null

  useEffect(() => {
    if (action === "Update" && currentSelection !== null) {
      let tempData = {};
      Object.keys(currentSelection).forEach((key) => {
        tempData[key] = currentSelection[key];
      });

      setFieldsData(tempData);
      setOnOffSite(
        currentSelection.location === "supplier_off_site"
          ? "supplier_off_site"
          : currentSelection.location === "mro_on_site"
            ? "mro_on_site"
            : null
      );
      let value = currentSelection?.part_category_id
      handleReleaseRequirement(value)
    }
  }, [currentSelection]);

  useEffect(() => {
    if (action === "Add") setOnOffSite("mro_on_site");
  }, []);

  const handleOn_OffSite = (value) => {
    setOnOffSite(value);
  };

  const resetErrorsHandler = (name) => {
    setErrors((e) => ({ ...e, [name]: false }));
  };


  const handleReleaseRequirement = async (value) => {
    let part_category = value === 1 ? "contracted" : "subcontracted"
    const params = {
      workshop_order_id: workshop_id,
      service_type: part_category
    }
    await ServiceRequestListClientServices.releaseRequirement(params)
      .then((res) => {
        if (res.success) {
          setReleaseReqData(res)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const onChangeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    resetErrorsHandler(name);
    setFieldsData((prevState) => ({ ...prevState, [name]: value }));

    if (name === "part_category_id") {
      handleReleaseRequirement(value)
    }
  };

  // console.log("release fda is================", releaseReqData?.data?.release_type_a)

  const uploadDocument = async (id, formData) => {
    if (onOffSite === "supplier_off_site") {
      formData.delete("part_number");
      formData.delete("serial_number");
    }
    formData.delete("location");
    formData.delete("description");
    formData.delete("workshop_order_id");
    formData.delete("part_category_id");
    formData.delete("part_scope_id");
    formData.delete("task_description");
    formData.delete("desired_date");
    formData.delete("approval_type_id");
    formData.delete("tech_data_ref");
    formData.delete("user_id");
    formData.delete("workshop_action_id");
    formData.delete("approval_type_id");
    formData.append("document_type", "tech_data_documents");
    files.forEach((file) => formData.append("documents[]", file));

    if (id === null) {
      return;
    } else {
      formData.append("id", id);
      if (files?.length) {
        await ServiceRequestListClientServices.updateDocuments(formData)
          .then((res) => {
            if (res.success) {
              console.log("vcalled ythis inside")
              getServices()
            }
          })
          .catch(() => {
            message.showToastMessage({
              message: "Something went wrong. Try again!",
              variant: "error",
            });
          });
      }
    }
  };

  useEffect(() => {
    getActionNumber();
  }, []);

  const getActionNumber = async () => {
    const params = {
      id: workshop_id,
    };
    await ServicesAndEquip.getActionNumber(params)
      .then((res) => {
        if (res) {
          setActionNumber(res.data);
        }
      })
      .catch(() => {
        message.showToastMessage({
          message: "Something went wrong. Try again!",
          variant: "error",
        });
      });
  };

  const handleUpdateService = async (formData) => {
    setBusy("Update");
    formData.append("id", currentSelection?.id);
    formData.delete("documents[]");
    await ServiceRequestListClientServices.updateServices(formData)
      .then((res) => {
        if (res.success) {
          getServices();
          closeHandler(res.data);
          message.showToastMessage({
            message: "Updated Successfully!",
            variant: "success",
          });
        }
      })
      .catch((err) => {
        // message.showToastMessage({
        //   message: "Something went wrong. Try again!",
        //   variant: "error",
        // });
      })
      .finally(() => {
        setBusy(null);
      });
    if (files && action !== "create") {
      formData.delete("location");
      formData.delete("part_category_id");
      formData.delete("part_scope_id");
      formData.delete("description");
      formData.delete("desired_date");
      formData.delete("tech_data_ref");
      formData.delete("workshop_action_id");
      formData.delete("approval_type_id");
      formData.delete("document_type");
      formData.append("document_type", "tech_data_documents");
      files.forEach((file) => formData.append("documents[]", file));
      if (files?.length !== 0) {
        await ServiceRequestListClientServices.updateDocuments(formData)
          .then((res) => {
            if (res) {
              onClose(res.data);
              getServices();
            }
          })
          .catch((err) => { })
          .finally(() => {
            setBusy(null);
          });
      }
    }
  };

  const handleCreateService = async (formData) => {
    formData.append("base_station_id", baseId)
    formData.append("user_id", userDetails.userId);
    formData.append("workshop_order_id", workshop_id);
    setBusy("create");
    formData.delete("documents[]");
    await ServiceRequestListClientServices.createServices(formData)
      .then((res) => {
        if (res.success) {
          getServices();
          uploadDocument(res.id, formData);
          onClose();
          message.showToastMessage({
            message: "Created Successfully!",
            variant: "success",
          });
        }
      })
      .catch((err) => {
        message.showToastMessage({
          message: "Something went wrong. Try again!",
          variant: "error",
        });
      })
      .finally(() => {
        setBusy(null);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const { error, valid } = validateFormData(formData, []);
    if (!valid) {
      setBusy(null);
      setErrors(error);
      return;
    }
    if (action === "Add") {
      handleCreateService(formData);
    }
    if (action === "Update" && !deleteAction) {
      handleUpdateService(formData);
    }
  };

  const deleteFileHandler = async (id) => {
    setBusy("file-deleting");
    try {
      const response = await ServiceRequestListClientServices.deleteDocument({ id: id });

      if (response.success) {
        message.showToastMessage({
          message: "File deleted successfully",
          variant: "success",
        });
        getServices();
      }
      setBusy(null);
    } catch (error) {
      message.showToastMessage({
        message: "Something went wrong",
        variant: "error",
      });
      setBusy("file-deleting-error");
    }
  };


  return (
    <MROFullScreenRightDrawer open={true} className={classes.root}>
      <AppBar position="static" className={classes.appBar} elevation="">
        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
          <Box display="flex" alignItems="center">
            <IconButton
              onClick={onClose}
              edge="start"
              className={classes.backButton}
              color="inherit"
              aria-label="back"
            >
              <BackIcon />
            </IconButton>
            <Typography variant="subtitle2" style={{ fontSize: "17px" }}>
              {action} Specialist Service{" "}
            </Typography>
          </Box>
          <IconButton onClick={() => setDeleteAction(true)} style={{ display: action === "Add" && "none" }}>
            <Typography
              style={{
                fontSize: "13px",
                fontWeight: "20px",
                backgroundColor: "transparent",
              }}
            >
              Delete Item&nbsp;&nbsp;
            </Typography>
            <DeleteIcon fontSize="small" htmlColor={ICON_COLOUR} />
          </IconButton>
        </Toolbar>
      </AppBar>

      <form noValidate onSubmit={handleSubmit} id="formSub">
        <Grid className={classes.wrapper}>
          <Typography color="primary" className={classes.title}>
            Action
          </Typography>
          <Grid item xs={12} style={{ width: "80%" }}>
            <MROTextField
              name={"workshop_action_id"}
              label={"Select Action *"}
              InputLabelProps={{ shrink: true }}
              placeholder={"Max 250 characters"}
              defaultValue={"select"}
              select
              value={fieldsData?.workshop_action_id}
              error={errors.workshop_action_id}
              onChange={onChangeHandler}
            >
              <MenuItem value={"select"}>
                <span style={{ opacity: "40%" }}>Select</span>
              </MenuItem>
              {actionNumber?.map((item) => (
                <MenuItem
                  key={item.workshop_action_id}
                  value={item.workshop_action_id}
                >
                  {item.action_number}
                </MenuItem>
              ))}
            </MROTextField>
          </Grid>
          <Grid container spacing={1} item xs={12} style={{ width: "80%" }}>
            <Grid item xs={12}>
              <Typography color="primary" style={{ fontSize: "17px" }}>
                Service Details
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ fontSize: "14px" }}>
                Where will the service be provided?
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <MROOnSiteOffSite
                name="location"
                handleToggle={handleOn_OffSite}
                value={onOffSite}
              />
            </Grid>
            {onOffSite === "supplier_off_site" && (
              <Grid container spacing={1}>
                <Grid item xs={6} md={4}>
                  <MROTextField
                    name={"part_number"}
                    label={"Part Number"}
                    InputLabelProps={{ shrink: true }}
                    placeholder={"Part Number sent off site"}
                    required
                    value={fieldsData?.part_number}
                    inputProps={{ maxLength: 30 }}
                    error={errors.part_number}
                    onChange={(e) => {
                      resetErrorsHandler(e.target.name);
                    }}
                  ></MROTextField>
                </Grid>
                <Grid item xs={6} md={4}>
                  <MROTextField
                    name={"serial_number"}
                    label={"Serial Number"}
                    InputLabelProps={{ shrink: true }}
                    placeholder={"Serial Number sent off site"}
                    required
                    value={fieldsData?.serial_number}
                    inputProps={{ maxLength: 30 }}
                    error={errors.serial_number}
                    onChange={(e) => {
                      resetErrorsHandler(e.target.name);
                    }}
                  ></MROTextField>
                </Grid>
              </Grid>
            )}

            <Grid container spacing={1} style={{ width: "100%" }}>
              <Grid item xs={6} md={4}>
                <MROTextField
                  name={"part_scope_id"}
                  label={"Service Scope"}
                  select
                  required
                  defaultValue={"select"}
                  InputLabelProps={{ shrink: true }}
                  className={classes.input}
                  value={fieldsData?.part_scope_id}
                  onChange={onChangeHandler}
                  error={errors.part_scope_id}
                >
                  <MenuItem value="select">
                    <span style={{ opacity: "40%" }}>Select</span>
                  </MenuItem>
                  {dependencies?.service_scopes?.map((item) => (
                    <MenuItem
                      key={item.part_scope_id}
                      value={item.part_scope_id}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </MROTextField>
              </Grid>
              <Grid item xs={6} md={4}>
                <MROTextField
                  name={"approval_type_id"}
                  label={"Insp Auth"}
                  InputLabelProps={{ shrink: true }}
                  placeholder={"Enter part number going offsite"}
                  select
                  required
                  value={fieldsData?.approval_type_id}
                  defaultValue={"select"}
                  error={errors.approval_type_id}
                  onChange={onChangeHandler}
                >
                  <MenuItem value="select">
                    <span style={{ opacity: "40%" }}>Select</span>
                  </MenuItem>
                  {dependencies?.insp_auth?.map((item) => (
                    <MenuItem
                      key={item.approval_type_id}
                      value={item.approval_type_id}
                    >
                      {item.type_name}
                    </MenuItem>
                  ))}
                </MROTextField>
              </Grid>
              <Grid item xs={6} md={4}>
                <Box display="flex" alignItems="center">
                  <MROTextField
                    name={"part_category_id"}
                    label={"Service Type"}
                    select
                    required
                    error={errors.part_category_id}
                    InputLabelProps={{ shrink: true }}
                    className={classes.input}
                    defaultValue={"select"}
                    value={fieldsData?.part_category_id}
                    onChange={onChangeHandler}
                  >
                    <MenuItem value="select">
                      <span style={{ opacity: "40%" }}>Select</span>
                    </MenuItem>
                    {dependencies?.service_types?.map((item) => (
                      <MenuItem
                        key={item.part_category_id}
                        value={item.part_category_id}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                  </MROTextField>
                  <Box ml="13px">
                    <InfoPopup
                      width="550px"
                      description={
                        <>
                          A <strong>Contract</strong> type service is provided
                          by an organisation approved by either the CAA, EASA or
                          the FAA in their own right, thus releasing their
                          service on a Form 1 or equivalent. <br /> <br /> A
                          &nbsp;
                          <strong>Subcontract</strong>&nbsp; type service is
                          provided by an organisation not approved in their own
                          right. Therefore, they are only approved to work under
                          this MRO&apos;s approval and release their service on a C of C
                        </>
                      }
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Grid container item xs={12} className={classes.paper}>
              <span style={{ color: "blue" }}>Release Requirement: </span>{" "}
              &nbsp;&nbsp;
              <span>
                {releaseReqData?.data?.release_type_a === null ? "Release type not yet planned" : releaseReqData?.data?.release_type_a || ""}
              </span>
              <span>{releaseBStatus ? "" : <span style={{ marginLeft: "4px" }}></span>}{releaseBStatus ? "" : "and"}{releaseBStatus ? "" : <span style={{ marginLeft: "4px" }}></span>}
                {releaseReqData?.data?.release_type_b !== null ? releaseReqData?.data?.release_type_b : "" || ""}
              </span>
              <span>
                {releaseReqData?.data?.message || ""}
              </span>
            </Grid>
            <Grid item xs={12}>
              <MROTextField
                name={"description"}
                label={"Task Description"}
                InputLabelProps={{ shrink: true }}
                placeholder={"Max 500 characters"}
                required
                multiline
                InputProps={{ style: { height: 'max-content', width: "100%" } }}
                value={fieldsData?.description}
                inputProps={{ maxLength: 500 }}
                error={errors.description}
                onChange={onChangeHandler}
              ></MROTextField>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={6} md={4}>
                <MROTextField
                  name={"tech_data_ref"}
                  label={"Tech Data Ref"}
                  InputLabelProps={{ shrink: true }}
                  placeholder={"Enter technical data reference"}
                  required
                  value={fieldsData?.tech_data_ref}
                  inputProps={{ maxLength: 40 }}
                  error={errors.tech_data_ref}
                  onChange={onChangeHandler}
                ></MROTextField>
              </Grid>
              <Grid item xs={6} md={4} style={{ marginTop: "8px" }}>
                <DateField
                  name={"desired_date"}
                  label={"Desired Date *"}
                  defaultValue={fieldsData?.desired_date}
                  InputLabelProps={{ shrink: true }}
                  required
                  error={errors.desired_date}
                  onChange={() => {
                    resetErrorsHandler("desired_date");
                  }}
                />
              </Grid>
            </Grid>

            <Typography style={{ marginBottom: "10px" }}>
              Technical Data
            </Typography>

            <Grid item xs={12}>
              <FileUpload
                defaultValue={
                  action === "Update" &&
                    techData !== undefined
                    ? techData?.map((item) => ({
                      filename: item?.filename,
                      url: `${item?.url}`,
                      id: item?.id,
                    }))
                    : ""
                }
                setFiles={(files) => {
                  setFiles(files);
                }}
                showBorderOnNoFiles={true}
                name="documents[]"
                multiple={true}
                onDelete={deleteFileHandler}
                isDeleting={busy === "file-deleting"}
                APIUrl={""}
                action={"Upload Document"}
              />
            </Grid>
          </Grid>
          <div className={classes.actionButtons}>
            <MROButton
              style={{ marginRight: "20px" }}
              type="button"
              variant={"contained"}
              onClick={onClose}
            >
              Cancel
            </MROButton>
            <MROButton
              type="submit"
              variant={"contained"}
              color="primary"
              loading={busy === (action === "create" ? "create" : "Update")}
            >
              {action}
            </MROButton>
          </div>
        </Grid>
        {deleteAction && (
          <DeleteServices
            action={"delete"}
            handleClose={() => setDeleteAction(false)}
            title="Delete Item"
            description="This cannot be undone. Are you sure?"
            Id={currentSelection?.id}
            closeHandler={closeHandler}
            getServices={getServices}
            tab="services"
          />
        )}
      </form>
    </MROFullScreenRightDrawer>
  );
}

export default ManageSpecialistService;
