import React, { useContext, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Box
} from "@material-ui/core";
import MROButton from "components/buttons";
import { ToastMessageContext } from "lib/contexts/message_context";
import BoxpDataService from "lib/services/api/boxp_index";
import { CommercialServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/commercial";
import { WorkshopContext } from "../WorkshopContext/WorkshopContext";
import WarningIcon from "@material-ui/icons/Warning";
import exclamationIcon from "assets/exclamation.png"



const useStyles = makeStyles(() => ({
  modal: {
    "& .MuiDialog-paperWidthMd": {
      width: "45%",
      padding: '14px'
    },
  },
  title: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  warning1: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "13px",
    marginBottom: "20px",
  },
  warning: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "16px",
    marginBottom: "20px",
  },
  actionButtons: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

function DeleteCusApproval(props) {
  const classes = useStyles();
  const {
    handleClose,
    open,
    selectedId,
    closeHandler,
    getApprovedPosition,
    currentSelectedOrder
  } = props;
  const message = useContext(ToastMessageContext);
  const { setApprovalStateCommercial } = useContext(WorkshopContext)
  const [busy, setBusy] = useState(null);
  const [acceptAll, setAcceptAll] = useState(false)




  const handleSubmit = () => {
    setBusy("loading");
    const params = {
      workshop_order_id: currentSelectedOrder?.id
    }
    CommercialServices.deleteApproval(params, selectedId)
      .then((res) => {
        if (res.success) {
          setBusy(null);
          setApprovalStateCommercial(false);
          getApprovedPosition();
          closeHandler();
          message.showToastMessage({
            message: "Deleted Approved position successfully!!",
            variant: "success",
          });
        }
      })
      .catch(() => {
        setBusy(null);
        handleClose();
        message.showToastMessage({
          message: "Something went wrong. Try agin!!",
          variant: "error",
        });
      });
  }
  return (
    <div>
      <Dialog
        maxWidth={"md"}
        fullWidth={true}
        open={open}
        className={classes.modal}
        onClose={handleClose}
      >

        <DialogTitle id="customized-dialog-title">
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <Box display="flex" alignItems="center">
              <Typography
                style={{
                  fontSize: "18px",
                }}
                color="primary"
              >
                Delete Approval
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center">
              {" "}
              <img
                src={exclamationIcon}
                alt="Icon"
                style={{ height: "46px" }}
              />{" "}
            </Box>
          </Box>
        </DialogTitle>

        <DialogContent>
          <Grid item style={{ paddingBottom: '8px' }}>
            <Box display="flex" alignItems="center">
              <WarningIcon
                style={{
                  color: "#FFB300",
                  marginRight: "10px",
                  fontSize: 20,
                }}
              />
              <Typography style={{ fontSize: "16px" }}>
                This action cannot be undone!
              </Typography>
            </Box>
          </Grid>
          <Grid item style={{ paddingBottom: '8px' }}>
            <Box display="flex" alignItems="center">
              <WarningIcon
                style={{
                  color: "#FFB300",
                  marginRight: "10px",
                  fontSize: 20,
                }}
              />
              <Typography style={{ fontSize: "16px" }}>
                Job actuals may have changed resulting in difference accumulative, or exception charges in any subsequent approved position created
              </Typography>
            </Box>
          </Grid>

          <Box pt={3} display="flex" alignItems="center" style={{
            marginLeft: "11px",
            paddingBottom: '15px'
          }}>
            <span style={{ textDecoration: "underline" }}>Accept all</span>
            &nbsp;&nbsp;&nbsp;
            <input
              // style={{ pointerEvents: popupValues === undefined ? "none" : "" }}
              type="checkbox"
              value={acceptAll}
              onClick={() => {
                setAcceptAll(!acceptAll);
              }}
            />
          </Box>


          <Grid className={classes.actionButtons} spacing={1} container>
            <Grid item>
              <MROButton
                type="button"
                variant={"contained"}
                onClick={handleClose}
              >
                Cancel
              </MROButton>
            </Grid>
            <Grid item>

              <MROButton
                type="button"
                loading={busy === "loading"}
                variant={"contained"}
                color={"primary"}
                style={{ backgroundColor: !acceptAll ? "#FFB300" : "", color: acceptAll ? "#ffff" : "#000000" }}
                onClick={(e) => {
                  if (acceptAll) {
                    handleSubmit(e);
                  }
                  else if (!acceptAll) {
                    message.showToastMessage({
                      message: "Cautions must be accepted!",
                      variant: "error",
                    });

                  }
                }}
                name="complete1"
              >
                Complete
              </MROButton>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default DeleteCusApproval;
