import React, { useContext, useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import { AppBar, Box, IconButton, Toolbar } from "@material-ui/core";
import MaterialTable from "material-table";
import tableIcons, { options } from "components/universal/table_attributes";
import { makeStyles } from "@material-ui/core/styles";
import MROFullScreenRightDrawer from "components/fullwidth_dialog";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import { ICON_COLOUR } from "lib/constants/style_constants";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddBoxIcon from "@material-ui/icons/AddBox";
import ManageComment from "./ManageComment";
import { ToastMessageContext } from "lib/contexts/message_context";
import moment from "moment";
import DeleteData from "./DeleteComments";
import { MaintenanceServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation";
const useStyles = makeStyles((theme) => ({
  tableWrapper: {
    margin: "3%",
    width: "90%",
  },
  appBar: {
    backgroundColor: "#fff",
    color: "#000000CC",
    boxShadow: "0px 3px 6px #0000001A",
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  paper: {
    borderRadius: "5px",
    backgroundColor: "#F5F5F5",
    padding: theme.spacing(2),
    boxShadow: "0px 1px 4px #0000001A",
  },
  flex: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginRight: "98px",
  },
}));

function Comment(props) {
  const classes = useStyles();
  const { handleClose, selectedId, styleProp } = props;
  const message = useContext(ToastMessageContext);
  // const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
  const [action, setAction] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(5);
  const [pageSize, setPageSize] = useState(5);
  const [actionComments, setAllActionComments] = useState([]);
  const [currentSelection, setCurrentSelection] = useState({});
  let userDetails = JSON.parse(sessionStorage.getItem("user"));

  useEffect(() => {
    getAllActionComments();
  }, [page, pageSize]);

  const getAllActionComments = async () => {
    setLoading(true);
    const params = {
      action_id: selectedId,
      page: page + 1,
      limit: pageSize,
    };
    await MaintenanceServices.getAllActionComments(params)
      .then((res) => {
        if (res) {
          setAllActionComments(res.data);
          setTotalCount(res.total_count);
        }
        setLoading(false);
      })
      .catch((err) => {
        message.showToastMessage({
          message: "Something went wrong. Try again!",
          variant: "error",
        });
        setLoading(false);
      });
  };

  const tableOptions = {
    ...options,
    page: page,
    total: totalCount,
    pageSize: pageSize,
    search: false,
    toolbar: true,
    rowStyle: { height: 42 },
  };

  const columns = [
    {
      title: "Comment",
      // field: "comment",
      headerStyle: {
        textAlign: "left",
        paddingLeft: "30px",
      },
      cellStyle: {
        textAlign: "left",
        paddingLeft: "30px",
        width: "70%",
      },
      render: (rowData) => (
        <>
          <Typography
            style={{
              wordBreak: "break-all",
            }}
          >
            {rowData?.comments}
          </Typography>
        </>
      ),
    },
    {
      title: "User",
      // field: "user_id",
      headerStyle: {
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
        width: "10%",
      },
      render: (rowData) => <span>{rowData?.raised_by_userstamp}</span>,
    },
    {
      title: "Date/Time",
      field: "created_at",
      headerStyle: {
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
        width: "20%",
      },
      render: (rowData) =>
        moment(rowData?.raised_date_time, "DD/MM/YYYY HH:mm:ss").format(
          "DD/MM/YYYY HH:mm"
        ),
    },
    {
      title: "Action",
      render: (rowData) => {
        return (
          <div>
            {userDetails?.stamp_number === rowData?.raised_by_userstamp ? (
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
              >
                <div style={{ display: styleProp }}>
                 {props.permission.write && <IconButton
                    onClick={() => {
                      setAction("Update");
                      setCurrentSelection(rowData);
                    }}
                    fontSize="small"
                  >
                    <EditIcon fontSize="small" style={{ color: ICON_COLOUR }} />
                  </IconButton>}
                </div>
                <div style={{ display: styleProp }}>
                  {props.permission.write && <IconButton
                    onClick={() => {
                      setAction("delete");
                      setCurrentSelection(rowData);
                    }}
                    fontSize="small"
                  >
                    <DeleteIcon
                      fontSize="small"
                      style={{ color: ICON_COLOUR }}
                    />
                  </IconButton>}
                </div>
              </Box>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
  ];

  // console.log("current selection is==================", currentSelection)

  return (
    <div>
      <MROFullScreenRightDrawer open={true}>
        <AppBar position="static" className={classes.appBar} elevation={0}>
          <Toolbar>
            <IconButton
              onClick={handleClose}
              edge="start"
              className={classes.backButton}
              color="inherit"
              aria-label="back"
            >
              <BackIcon />
            </IconButton>
            <Typography variant="subtitle2" style={{ fontSize: "17px" }}>
              Action Comments
            </Typography>
          </Toolbar>
        </AppBar>

        <div className={classes.tableWrapper}>
          <Typography color="primary" style={{ marginBottom: "15px" }}>
            Comments
          </Typography>
          <MaterialTable
            style={{
              boxShadow: "0px 1px 3px #00000033",
            }}
            icons={tableIcons}
            columns={columns}
            title={""}
            data={actionComments || []}
            isLoading={loading}
            options={tableOptions}
            onChangePage={(page) => {
              setPage(page);
            }}
            onChangeRowsPerPage={(pageSize) => {
              setPageSize(pageSize);
            }}
            totalCount={totalCount}
            page={page}
            actions={ props?.permission.write && [
              {
                icon: () => (
                  <div style={{ display: styleProp }}>
                    <AddBoxIcon color="secondary" />
                  </div>
                ),
                tooltip: "Add",
                isFreeAction: true,
                onClick: (event) => setAction("Add"),
              },
            ]}
          />
        </div>
      </MROFullScreenRightDrawer>
      {action && action !== "delete" && (
        <ManageComment
          action={action}
          selectedId={selectedId}
          handleClose={() => setAction(null)}
          currentSelection={currentSelection}
          getAllActionComments={getAllActionComments}
        />
      )}
      {action === "delete" && (
        <DeleteData
          handleClose={() => setAction(null)}
          open={true}
          section={"deleteComment"}
          currentSelection={currentSelection}
          getAllActionComments={getAllActionComments}
        />
      )}
    </div>
  );
}

export default Comment;
