
import React, { useContext, useEffect, useReducer, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SearchBarWithFilters from "../../../components/search_with_filters";
import TableAction from "../../../actions/table_actions";
import { DatePicker } from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import MaterialTable from "material-table";
import AddIcon from "@material-ui/icons/Add";
import VisibilityIcon from '@material-ui/icons/Visibility';
import tableIcons, {
    options,
} from "../../../components/universal/table_attributes";
import tableReducer, {
    INITIAL_TABLE_STATE,
} from "../../../reducers/table_reducer";
import PositiveSwitch from "../../../components/form_components/switch";
import MROButton from "components/buttons";
import {
    FormControl,
    Select,
    InputBase,
    MenuItem,
    Typography,
    Box,
} from "@material-ui/core";
import GetAppIcon from '@material-ui/icons/GetApp';

const { RangePicker } = DatePicker;


const useStyles = makeStyles((theme) => ({
    bold: {
        fontWeight: 600
    },
    tableWrapper: {
        width: "85%",
        margin: "1% 3%",
    },
}));

function LineParts(props) {

    const classes = useStyles();
    const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
    const { page, totalCount, pageSize, inProgress, data } = tableState;
    const [currentSelection, setCurrentSelection] = useState({});
    const [action, setAction] = useState(null);
    const [show, setShow] = useState(false);

    const [searchText, setSearchText] = useState("");

    const [value, setValue] = React.useState([null, null]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [lineCustomers, setLineCustomers] = useState([]);
    const [bases, setBases] = useState([]);
    const [base, setBase] = useState(null);

    const tableOptions = {
        ...options,
        page: page,
        total: totalCount,
        pageSize: pageSize,
        search: false,
    };

    const handleviewInfoIcon = () => {
        setAction('viewddpart');
        setCurrentSelection({});
    }

    const renderViewInfoIcon = (rowData) => {

        return (
            <div onClick={handleviewInfoIcon} style={{ backgroundColor: 'transparent' }}>
                <VisibilityIcon size="medium" />
            </div>
        )
    }

    const columns = [
        {
            title: "Date/Time",
            field: "raised",
            headerStyle: {
                textAlign: "left",
            },
            cellStyle: {
                textAlign: "left",
            },

        },
        {
            title: "A/C Reg",
            field: "reqNum",
            headerStyle: {
                textAlign: "center",
            },
            cellStyle: {
                textAlign: "center",
            },

        },
        {
            title: "Base",
            field: "customer",
        },
        {
            title: "Customer TLP/ DO",
            field: "ddNum",
        },
        {
            title: "MRO Report #",
            field: "partNum",
        },
        {
            title: "Part #",
            field: "descr",
        },
        // {
        //     title: "Order #",
        //     field: "worder_number",
        // },
        // {
        //     title: "Base",
        //     field: "base",
        // },
        // {
        //     title: "A/C Reg",
        //     field: "acReg",
        // },
       
   
      
        {
            title: "Description",
            field: "qtyReq",
        },
        {
            title: "Part Owner",
            field: "allocated",
        },
        {
            title: "QTY",
            field: "picked",
        },
        {
            title: "Value",
            field: "issued",
        },
        {
            title: "Total",
            field: "complete",
        }
    ];

    const handleBaseChange = ({ target: { value } }) => {
        setBase(value);
    };


    const handleDateChange = (value) => {
        setValue(value);
        if (value) {
            setStartDate(moment(value[0]).format("DD/MM/YYYY"));
            setEndDate(moment(value[1]).format("DD/MM/YYYY"));
        }
    };


    const handleCustomerChange = (event) => {

    };

    const handleNewDDPart = () => {
        setAction('view');
        setCurrentSelection({});
    }

    return (
        <div className={classes.tableWrapper}>
            <SearchBarWithFilters
                handleSearch={(value) => {
                    TableAction.setPage(dispatch, 0);
                    setSearchText(value);
                }}
            >

                <FormControl size="medium" className={"filter"}>
                    <Select
                        fullWidth
                        displayEmpty
                        onChange={(event, value) => handleCustomerChange(event)}
                        id="tags-standard"
                        value={lineCustomers.name}
                        defaultValue={""}
                        input={<InputBase />}
                    >
                        <MenuItem value={""}>Customer (All)</MenuItem>
                        {lineCustomers &&
                            lineCustomers.map((lineCustomer, i) => (
                                <MenuItem key={i} value={lineCustomer.name}>
                                    {lineCustomer.name}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
                <FormControl size="medium" className={"filter"}>
                    <Select
                        fullWidth
                        displayEmpty
                        onChange={handleBaseChange}
                        id="tags-standard"
                        value={"test"}
                        name="Base Station"
                        defaultValue={"1"}
                        input={<InputBase />}
                    >
                        <MenuItem value={"test"}>Base (All)</MenuItem>

                    </Select>
                </FormControl>

                <FormControl size="medium" className={"filter"}>
                    <Select
                        fullWidth
                        displayEmpty
                        onChange={handleBaseChange}
                        id="tags-standard"
                        value={"test"}
                        name="Base Station"
                        defaultValue={"1"}
                        input={<InputBase />}
                    >
                        <MenuItem value={"test"}>Part Owner (All)</MenuItem>

                    </Select>
                </FormControl>


                <FormControl className="filter">
                    <RangePicker
                        format="DD/MM/YYYY"
                        value={value}
                        onChange={handleDateChange}
                        allowClear={true}
                    />
                </FormControl>

            </SearchBarWithFilters>


            <MaterialTable
                style={{ boxShadow: "0px 1px 3px #00000033", marginTop: "10px" }}
                icons={tableIcons}
                classes
                title={""}
                columns={columns}
                data={[{
                    raised: "25/04/2021",
                    reqNum: 1978,
                    customer: "VIR",
                    worder_number: "-",
                    base: "LHR",
                    acReg: "VBIG",
                    ddNum: "21163/3",
                    partNum: "NAS-1278-01",
                    descr: "bOLT",
                    qtyReq: 21,
                    allocated: 21,
                    picked: 9,
                    issued: 9,
                    complete: "-",
                    openDays: 15,
                    action
                }]}
                isLoading={false}
                options={tableOptions}
                localization={{
                    toolbar: {
                        searchPlaceholder: "Search anything",
                    },
                    body: {
                        emptyDataSourceMessage: "No Work Orders found",
                        filterRow: {
                            filterTooltip: "Filter",
                        },
                    },
                    header: {
                        actions: "Actions",
                    },
                }}
                onChangePage={(page) => {
                    TableAction.setPage(dispatch, page);
                }}
                onChangeRowsPerPage={(pageSize) => {
                    TableAction.setPageSize(dispatch, pageSize);
                }}
                totalCount={totalCount}
                page={page}

            />

                <br />
            <div id="gridhdrddPrts" style={{ display: 'inline-flex' }}>

                <div style={{ marginLeft: '0px' }}>
                    <MROButton
                        style={{ color: "white" }}
                        variant="contained"
                        color="primary"
                        onClick={() => handleNewDDPart()}
                        startIcon={<GetAppIcon size="small" />}
                    >
                        Download CSV
                    </MROButton>
                </div>

            </div>
        </div>


    );

}


export default LineParts;