import { Paper, Grid, Typography, IconButton, Box } from '@material-ui/core'
import { Visibility } from '@material-ui/icons'
import { ICON_COLOUR } from 'lib/constants/style_constants'
import React, { useEffect, useState } from 'react'
import GroupGreyIcon from 'assets/Dashboard/audience.svg'
import GroupIcon from 'assets/Dashboard/group.svg'
import BackArrow from 'assets/LeaveBwd.svg'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ForwardArrow from 'assets/LeaveFwd.svg'
import moment from 'moment'
import _ from 'lodash'
import OvertimeRequest from './OvertimeRequest'


function CalendarWeekly(props) {

    const { data, week, setSelectedWeek, setSelectedDay, approvedData, headerDetails, date } = props
    const handleDate = (value) => {
        let day = moment(value, "YYYY-MM-DD").format("DD")
        return day
    }

    const [leaveDay, setLeaveDay] = useState("")
    const [action, setAction] = useState("")








    return (

        <Grid container direction='row' style={{ paddingLeft: '15px' }}>
            <Grid item xs={0.5} style={{ paddingTop: '10px' }} ><img src={BackArrow} onClick={() => {
                setSelectedWeek(moment(week).subtract('1', 'week'))
            }} height={"400px"} /></Grid>


            <Grid item style={{ paddingTop: '10px', justifyContent: 'center' }} container xs={11} direction='row'>



                <Paper style={{ padding: '20px 15px 10px 10px', height: '370px', width: '80vw' }}>
                    <Grid style={{ display: 'grid', gridTemplateColumns: "repeat(7, 1fr)" }} item xs={12}>
                        {data.length > 0 && data.map((value) => (<>      <Grid>

                            <Grid item style={{ paddingBottom: '5px', paddingLeft: '10px', width: '10.1vw' }} xs={1.7}>

                                <Paper style={{ height: '106px', background: value.overtime_details.length > 0 ? "#FFF" : "#F5F5F5", pointerEvents: value.overtime_details.length > 0 ? "" : "none" }}>


                                    <>
                                        <Grid container >
                                            <Grid xs={8}>
                                                <Typography style={{ textAlign: 'start', fontSize: '20px', paddingLeft: '8px' }}> {handleDate(value.date)}</Typography>
                                            </Grid>
                                            {value.overtime_details.length > 0 &&
                                                <Grid>
                                                    {props?.permission?.write && <IconButton onClick={() => {
                                                        setLeaveDay(value.date)
                                                        setAction("overtime_request")
                                                    }}>
                                                        <Visibility color={ICON_COLOUR} style={{ fontSize: '14px' }} />
                                                    </IconButton>}
                                                </Grid>}
                                        </Grid>



                                        {value.overtime_details.length > 0 && value.overtime_details.map((item) => (


                                            <Grid style={{ paddingBottom: '5px', paddingLeft: '5px', paddingRight: '14px' }} xs={1.5}>
                                                <Paper style={{ height: 'max-content', background: item.shift_type_id === 2 ? '#4FC605' : item?.shift_type_id === 3 ? '#767676' : item?.shift_type_id === 4 ? '#FFB300' : item?.shift_type_id === 5 ? '#FF0202' : "", borderRadius: '5px', color: '#fff' }}>
                                                    <Grid container>
                                                        <Grid item xs={8}><Typography style={{ fontSize: '12px', paddingLeft: '2px' }}>{approvedData?.user}</Typography></Grid>
                                                        <Grid><AccessTimeIcon style={{ fontSize: '12px' }} /> </Grid>
                                                        <Grid><Typography style={{ fontSize: '12px' }}>{item?.hours}</Typography></Grid>
                                                    </Grid>
                                                </Paper>
                                            </Grid>
                                        ))}

                                    </>



                                </Paper>


                            </Grid>

                        </Grid>
                        </>))}
                    </Grid>
                    {data.length > 0 && <Grid container xs={8} direction=' row' style={{ padding: '15px' }}>
                        <Grid style={{ paddingRight: '20px' }}><Typography variant='subtitle1' color='primary' style={{ fontSize: '18px' }}>Week {date.week}</Typography></Grid>

                        <Grid style={{ paddingTop: '5px' }}><img src={GroupGreyIcon} height={'12px'} /></Grid>
                        <Grid style={{ paddingTop: '5px', paddingRight: '20px' }}><Typography style={{ paddingLeft: '10px' }}>{headerDetails?.total_users || 0}</Typography></Grid>
                        <Grid style={{ paddingTop: '5px', paddingRight: '5px' }}>      <AccessTimeIcon style={{ fontSize: '15px', marginTop: '6px' }} /></Grid>
                        <Grid style={{ paddingTop: '6px' }}>   <Typography style={{ paddingLeft: '2px' }} >{headerDetails?.total_shift_hours || 0}</Typography></Grid>
                    </Grid>}
                    <Grid item style={{ paddingTop: '10px', display: 'grid', gridTemplateColumns: "repeat(7, 1fr)" }} xs={12} >

                        {data.length > 0 && data.map((value) => (
                            <>      <Grid>

                                <Grid item style={{ paddingBottom: '5px', paddingLeft: '10px', width: '10.1vw', cursor: 'pointer' }} xs={1.7} onClick={() => {
                                    setSelectedDay({ date: value.date })
                                }} >

                                    <Paper style={{ height: '140px' }}>


                                        <>
                                            <Grid container style={{ marginBottom: '3px', }} >
                                                <Grid xs={4}>
                                                    <Typography style={{ textAlign: 'start', fontSize: '20px', paddingLeft: '8px' }}> {handleDate(value.date)}</Typography></Grid>
                                                <Grid item xs={3} container style={{ paddingTop: '2px' }}>
                                                    <Grid style={{ paddingTop: '2px' }}><img src={GroupGreyIcon} height={'8px'} /></Grid>
                                                    <Grid><Typography style={{ paddingLeft: '10px', fontSize: '14px', paddingTop: '4px' }}>{value?.total_users}</Typography></Grid>
                                                </Grid>
                                                <Grid item xs={5} container justifyContent="center" style={{ paddingTop: '6px' }}>
                                                    <Grid>      <AccessTimeIcon style={{ fontSize: '10px' }} /></Grid>
                                                    <Grid>   <Typography style={{ paddingLeft: '2px', fontSize: '13px' }} >{value?.total_shift_hours}</Typography></Grid>
                                                </Grid>
                                            </Grid>



                                            <span style={{ height: '140px', color: '#FFF' }}>
                                                {value.shift_details.length > 0 && value.shift_details.map((event) => (
                                                    <Grid style={{ background: event?.shift_type_name === "Early" ? '#4FC605' : event?.shift_type_name === "Day" ? '#767676' : event?.shift_type_name === "Late" ? '#FFB300' : event?.shift_type_name === "Night" ? '#FF0202' : "", borderRadius: '10px', width: '94%', marginLeft: '6px', marginBottom: '3px' }} container>

                                                        <Grid xs={4}>
                                                            <Typography style={{ textAlign: 'start', fontSize: '14px', paddingLeft: '8px' }}> {event?.shift_type_name === "Early" ? "E" : event?.shift_type_name === "Day" ? "D" : event?.shift_type_name === "Late" ? "L" : event?.shift_type_name === "Night" ? "N" : ""}</Typography></Grid>
                                                        <Grid item xs={3} container >
                                                            <Grid><img src={GroupIcon} height={'8px'} /></Grid>
                                                            <Grid><Typography style={{ paddingLeft: '10px', fontSize: '14px' }}>{event?.users}</Typography></Grid>
                                                        </Grid>
                                                        <Grid item xs={4} container justifyContent="center" >
                                                            <Grid>      <AccessTimeIcon style={{ fontSize: '10px', marginTop: '6px' }} /></Grid>
                                                            <Grid>   <Typography style={{ paddingLeft: '2px', fontSize: '13px' }} >{event?.shift_hours}</Typography></Grid>
                                                        </Grid>

                                                    </Grid>))}

                                            </span>
                                        </>



                                    </Paper>


                                </Grid>

                            </Grid>
                            </>


                        ))
                        }





                    </Grid >



                </Paper>



                <Paper>


                </Paper>


            </Grid >
            <Grid item xs={0.5} style={{ paddingTop: '10px' }}><img src={ForwardArrow} onClick={() => {
                setSelectedWeek(moment(week).add('1', 'week'))
            }} height={"400px"} /></Grid>
            {
                action && action === "overtime_request" && < OvertimeRequest leaveDay={leaveDay} handleClose={() => {
                    setAction('')
                }} />
            }

        </Grid >
    )
}

export default CalendarWeekly