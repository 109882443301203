import React, { useEffect, useState } from "react";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
    DateTimePicker,
    KeyboardDateTimePicker,
    KeyboardTimePicker
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { makeStyles } from '@material-ui/core/styles';
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(1, 0),
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: theme.palette.primary.main + '!important',
                borderWidth: '1px !important'
            },
            '&:hover fieldset': {
                borderColor: theme.palette.primary.light + '!important',
                // borderWidth: '1px !important'
            },
            '& .MuiOutlinedInput-input': {
                padding: '15px'
            }
        },
        '& label': {
            color: theme.palette.primary.main
        }
    },
}))

function DateField({ label, name, defaultValue, variant, disabled, onlyTime, onChange, setErrors, errors, format }) {

    const classes = useStyles();
    const [selectedDate, setSelectedDate] = useState(null);



    useEffect(() => {
        if (defaultValue !== 'Invalid date') {
            setSelectedDate(defaultValue ? moment(defaultValue, onlyTime ? "HH:mm" : "YYYY-MM-DD HH:mm:ss a") : null)
        }
        else {
            setSelectedDate("")
            // setErrors({
            //     ...errors,
            //     [name]: { valid: false }
            // })
        }
    }, [defaultValue]);

    const changeDate = (date) => {
        if (date === null) {
            // setErrors({
            //     ...errors,
            //     [name]: { valid: false }
            // })
            setSelectedDate("")
            onChange && onChange("")
        } else {
            setSelectedDate(date);
            onChange && onChange(moment(date).format("YYYY-MM-DD - HH:mm:ss a"))
            // setErrors({
            //     ...errors,
            //     [name]: { valid: true }
            // })
        }
    }

    const changeTime = (date) => {
        setSelectedDate(date); onChange && onChange(moment(date).format("HH:mm"))
        if (date === null) {
            // setErrors({
            //     ...errors,
            //     [name]: { valid: false }
            // })
        } else {
            // setErrors({
            //     ...errors,
            //     [name]: { valid: true }
            // })
        }
    }

    if (onlyTime) {
        return <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardTimePicker
                clearable
                className={classes.root}
                fullWidth
                autoOk
                disabled={disabled}
                // focused
                id=""
                inputVariant="outlined"
                label={label}
                variant={variant}
                color={"primary"}
                ampm={false}
                value={selectedDate}
                // onBlur={event => console.log(event.target.value)}
                InputAdornmentProps={{ position: "end" }}
                // error={errors && errors[name] && !errors[name]?.valid}
                onChange={date => { changeTime(date) }}
            />
            <input style={{ display: 'none' }} name={name || "date"} defaultValue={defaultValue} value={selectedDate ? moment(selectedDate).format("HH:mm") : null} />
        </MuiPickersUtilsProvider>
    }

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDateTimePicker
                clearable
                className={classes.root}
                fullWidth
                autoOk
                disabled={disabled}
                // focused
                id=""
                ampm={false}
                inputVariant="outlined"
                label={label}
                // variant={variant}
                color={"primary"}
                format={ format ?? "DD-MM-YYYY - HH:mm"}
                value={selectedDate}
                // // onBlur={event => console.log(event.target.value)}
                // InputAdornmentProps={{ position: "end" }}
                // error={errors && errors[name] && !errors[name]?.valid}
                onChange={date => { changeDate(date) }}
            />
            <input style={{ display: 'none' }} name={name || "date"} defaultValue={defaultValue} value={selectedDate ? moment(selectedDate).format("YYYY-MM-DD - HH:mm") : null} />
        </MuiPickersUtilsProvider>
    )
}

export default React.memo(DateField);
