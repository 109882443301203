
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "lib/utils/common_utils";
import { DialogContent, IconButton, Typography } from "@material-ui/core";
import FileUpload from "components/form_components/fileUpload";
import { BASE_API_URL_BOXR } from "lib/constants";
const useStyles = makeStyles((theme) => ({
    certDialog: {
        "& .MuiDialog-paper": {
            boxShadow: "0px 3px 6px #00000029",
            position: "absolute",
            left: "calc(50% - 250px)",
            width: '44%'
        },
    },
}));
function TechFilesPopup(props) {
    const { handleClose, currentSelectedRow, open } = props
    const classes = useStyles();
    return (
        <div>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="755px"
                style={{
                    minHeight: "256",
                    // left: "20%",
                    boxShadow: "0px 3px 6px #00000029",
                }}
                className={classes.certDialog}
            >
                <div style={{ padding: "20px", overflow: "hidden" }}>
                    <Typography
                        color="primary"
                        varient="h3"
                        style={{ marginBottom: "10px" }}
                    >
                        Files
                    </Typography>
                    <div>
                        <FileUpload
                            defaultValue={currentSelectedRow.tech_data !== undefined
                                ? currentSelectedRow.tech_data.map((file) => ({
                                    filename: file?.filename,
                                    url: file?.url,
                                    id: file?.id,
                                })) : " "
                            }
                            APIUrl={""}
                            showBorderOnNoFiles={true}
                            uploadButtonView={true}
                            multiple={true}
                            name={"maintenance_file"}
                            action={"Upload Document"}

                        />
                    </div>
                </div>

            </Dialog>
        </div>
    )
}

export default TechFilesPopup