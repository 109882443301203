import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    titleTile: {
        padding: "3px 20px",
        fontSize: "13px",
        fontWeight: 500,
        margin: '20px 0'
    },
    titleTile2: {
        padding: "3px 20px",
        fontSize: "13px",
        fontWeight: 500,
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.secondary.dark
    }
}))

function SectionLabel(props) {

    const classes = useStyles();

    return (
        <Button {...props} variant={"contained"} disableElevation component={"div"} className={classes.titleTile}>{props.name}</Button>
    )
}

export function SubSectionLabel(props) {

    const classes = useStyles();

    return (
        <Button variant={"contained"} disableElevation component={"div"} className={classes.titleTile2}>{props.name}</Button>
    )
}

export default SectionLabel;
