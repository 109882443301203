import React, { useEffect, useContext, useState } from 'react'
import { Dialog, DialogContent, DialogTitle, Box, ButtonGroup, Button, FormControlLabel, Grid, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import MROButton from 'components/buttons';
import MROTextField from 'components/form_components/TextField';
import { getOrganisationId } from 'lib/utils/common_utils';
import { ToastMessageContext } from 'lib/contexts/message_context';
import PositiveSwitch from 'components/form_components/switch';
import NumericIncrement from 'views/Operations/LineMaintenance/WorkOrder/FieldComponents/numeric_increment';
import ResourcesServices from 'lib/services/api/planning/Resource/resources';








const useStyles = makeStyles(theme => ({
    actionButtons: {
        paddingTop: '20px',
        paddingBottom: '33px',
        textAlign: "center",
        '& button:not(:last-child)': {
            marginRight: '20px'
        }
    },
    modal: {
        '& .MuiDialog-paperWidthMd': {
            maxWidth: '530px',
        }
    },
    title: {
        fontSize: '18px',
        '& h2': {
            color: theme.palette.primary.main,
            textAlign: 'center'
        }
    },
    formLabel: {

        '& span:last-child': {
            fontSize: '14px',
        }
    }

}));


function MarkAbsent(props) {
    const classes = useStyles();
    const { action, handleClose, getListing, currentSelection, onClose } = props;
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false)
    const [checked, setChecked] = useState(false)
    const [states, setStates] = useState({ man_hours: '' });
    const message = useContext(ToastMessageContext)



    const resetErrorsHandler = (name) => {
        setErrors((e) => ({ ...e, [name]: false }));
    };

    const handleSubmit = async (e) => {


        e.preventDefault()
        const formData = new FormData(e.target)

        let valid = true;
        let error = {};


        for (let pair of formData.entries()) {
            const value = pair[1];
            const key = pair[0];
            // console.table("formdata=====", key, value)
            if (!value) {
                error[key] = true;
                valid = false;
            }
        }
        if (parseFloat(states.man_hours) >= parseFloat(currentSelection?.shift_hours)) {
            valid = false;
            error = {
                ...error,
                hours1: `Cannot be greater than Shift Hours!`,
            };
        }

        if (!valid) {
            setErrors(error);
            return;
        }
        formData.append("shift_id", currentSelection?.id)
        formData.append("key", "absent")
        formData.append("full_day", checked)
        setLoading(true)
        await ResourcesServices.updateShifAbsentTraning(formData).then((response) => {
            if (response.success) {
                message.showToastMessage({
                    message: "Shift updated sucessfully",
                    variant: "success",
                });
                handleClose()
                getListing()
            }
            else {
                message.showToastMessage({
                    message: response?.error ? response?.error : "Something went wrong. Try again",
                    variant: "error",
                });
                handleClose()
                getListing()
            }
        }).catch(() => {
            message.showToastMessage({
                message: "Something went wrong. Try again",
                variant: "error",
            });
        }).finally(() => {
            setLoading(false)
        })
    }







    return (
        <div>
            <Dialog
                maxWidth={"md"}
                fullWidth={true}
                open={true}
                className={classes.modal}
                onClose={handleClose}
                aria-labelledby="indirect-jobs"
            >
                <DialogTitle variant="subtitle2" color="secondary"
                    className={classes.title}>  <Typography style={{ textAlign: 'center', fontSize: '18px', }}> Mark as Absent</Typography>
                </DialogTitle>

                <DialogContent className={classes.card}>
                    <form onSubmit={handleSubmit}>
                        <Grid style={{ textAlign: 'center' }}>
                            <FormControlLabel style={{ fontSize: '18px' }}

                                control={
                                    <PositiveSwitch
                                        checked={checked}
                                        onChange={(e) => {
                                            setChecked(e.target.checked);
                                        }}
                                    //  name="status"
                                    />

                                }
                                label="User absent for the full shift ?"
                                labelPlacement="start"
                                className={classes.formLabel}
                            />
                        </Grid>
                        {!checked ?
                            <Grid container>
                                <Grid xs={2}></Grid>
                                <Grid style={{ paddingBottom: '20px' }} xs={10}>
                                    <NumericIncrement
                                        name="hours"
                                        label="Hours *"
                                        error={errors.hours}
                                        value={states.man_hours}
                                        placeholder={"Hours"}
                                        noIncreament
                                        state={states}
                                        setState={setStates}
                                        inputProps={{ min: 0, step: 0.50 }}
                                        InputLabelProps={{ shrink: true }}
                                        setError={() => {
                                            resetErrorsHandler("hours")
                                            resetErrorsHandler("hours1")
                                        }}
                                        helperText={
                                            errors["hours"] ? `Please enter  hours` : errors["hours1"] ?
                                                <span style={{ color: 'red' }}>   {errors["hours1"]} </span> : ''
                                        }
                                    />
                                </Grid>
                            </Grid> : <Grid style={{ marginBottom: '10px' }}> {" "}</Grid>}


                        <div className={classes.actionButtons}>
                            <MROButton
                                //   disabled={loading}
                                type="button"
                                variant={"contained"}
                                onClick={handleClose}
                            >
                                Cancel
                            </MROButton>
                            <MROButton
                                loading={loading}
                                type="submit"
                                variant={"contained"}
                                color="primary"

                            >
                                Complete
                            </MROButton>

                        </div>
                    </form>
                </DialogContent>

            </Dialog>

        </div>
    )
}

export default MarkAbsent