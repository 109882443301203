import React, { useEffect, useReducer, useContext } from "react";
import { makeStyles, useTheme } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import MaterialTable from "material-table";
import tableReducer, { INITIAL_TABLE_STATE } from "reducers/table_reducer";
import tableIcons, {
    options,
} from "components/universal/table_attributes";
import TableAction from "actions/table_actions";
import StatusDot from "components/status_dots";
import ScopeDialog from "views/Compliance/Approvals/Capability/ScopeDialog";
import { ToastMessageContext } from "lib/contexts/message_context";


export default function ScopePopup(props) {
    const { open, currentSelection, handleClose, } = props;

    const message = useContext(ToastMessageContext);
    const classes = useStyles();

    const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
    const { page, totalCount, pageSize, searchText, data, inProgress } = tableState;


    const GetInfoCard = (props) => {

        const theme = useTheme();

        return (
            <Box display="flex">
                <Box flex={25} className={classes.partKey}>
                    <Typography
                        style={{ padding: "5px", color: theme.palette.primary.main }}
                        color="secondary"
                    >
                        {props?.keyName}
                    </Typography>
                </Box>
                <Box flex={75} className={classes.partValue}>
                    {props?.value ?? "-"}
                </Box>
            </Box>
        );
    };


    useEffect(() => {
        getList(currentSelection?.id)
    }, [page, pageSize, searchText])

    const getList = (id) => {
        const params = {
            page: page + 1,
            count_per_page: pageSize,
            id: id,
            searchText: searchText,
        };

        TableAction.getList(dispatch, message, params, "capabilitiesEngineTask");
    };

    // tableOptions
    const tableOptions = {
        ...options,
        page: page,
        total: totalCount,
        pageSize: pageSize,
        search: true,
        toolbar: true,
        paging: true,
        headerStyle: {
            textAlign: "center",
            paddingTop: "22px",
            paddingBottom: "16px",
        },
    };

    const columns = [
        {
            title: '',
            field: 'id',
            width: '4%',
            render: rowData => <span>&nbsp;</span>,
            editable: 'never'
        },
        {
            title: "Tech Data Ref",
            field: "tech_data_ref",
            headerStyle: {
                textAlign: "left"
            },
            cellStyle: {
                textAlign: "left"
            }
        },
        {
            title: "Description",
            field: "description",
            headerStyle: {
                textAlign: "left"
            },
            cellStyle: {
                textAlign: "left"
            }
        },
    ];

    return (
        <div style={{ position: "relative" }}>
            <ScopeDialog
                open={open}
                handleClose={handleClose}
                width="920px"
                notShowButton={true}
            >
                <Box display={"flex"} justifyContent="space-between" mb="15px">
                    <Box>
                        <Typography color="primary" className={classes.bodyText}>
                            Detail
                        </Typography>

                    </Box>

                </Box>


                <Box
                    display="flex"
                    alignItems="center"
                    //   justifyContent={(loading || _.isEmpty(partDetails)) && "center"}
                    bgcolor="#F5F5F5"
                    className={classes.partCard}
                >
                    <Box flexGrow={1}>
                        <div style={{ width: "100%" }}>
                            <GetInfoCard keyName={"Ref #"} value={currentSelection?.reference_data} />
                            <GetInfoCard keyName={"Status / Work"} value={currentSelection?.status_work ? currentSelection?.status_work.toString() : ""} />
                            <GetInfoCard keyName={"Tech Data Ref"} value={currentSelection?.tech_data_ref} />
                            <GetInfoCard keyName={"Rating"} value={currentSelection?.capability_type?.name} />
                            <GetInfoCard keyName={"ATA Chapter"} value={currentSelection?.ata_chapter} />

                        </div>
                    </Box>
                </Box>
                <Box
                    width="100%"
                    mb="15px"
                    ml="0px"
                >
                    <Typography color="primary" className={classes.bodyText}>
                        Scope
                    </Typography>
                </Box>


                <Box width="100%">
                    <MaterialTable
                        style={{ boxShadow: "0px 1px 3px #00000033" }}
                        title={<Typography variant="subtitle2"> Task Scope</Typography>}
                        icons={tableIcons}
                        columns={columns}
                        data={data.data || []}
                        options={tableOptions}
                        localization={{
                            toolbar: {
                                searchPlaceholder: "Search anything",
                            },
                            body: {
                                emptyDataSourceMessage: "No scopes Found",
                                filterRow: {
                                    filterTooltip: "Filter",
                                },
                            },
                        }}
                        isLoading={inProgress}
                        onChangePage={(page) => {
                            TableAction.setPage(dispatch, page);
                        }}
                        onChangeRowsPerPage={(pageSize) => {
                            TableAction.setPageSize(dispatch, pageSize);
                        }}
                        onSearchChange={(search) => {
                            TableAction.setSearchText(dispatch, search);
                        }}
                        totalCount={totalCount}
                        page={page}
                    />
                </Box>
            </ScopeDialog>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    bodyText: {
        fontSize: 20,
        // lineHeight: "28px",
    },
    partValue: {
        fontSize: "14px",
        color: "#4D4F5C",
        padding: "2px",
        display: "flex",
        alignItems: "center",
    },
    partKey: {
        "& p": {
            fontSize: "14px",
            padding: "1px",
        },
    },
    partCard: {
        borderRadius: "8px",
        padding: "17px 24px",
        "& p": {
            fontSize: "14px",
            padding: "5px",
        },
    },
}));
