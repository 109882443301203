// export const API_WS_ROOT = 'wss://dev-mropro-api.digiryte.co.uk/cable';
// export const HEADERS = {
//   'Content-Type': 'application/json',
//   Accept: 'application/json',
// };

export const MRO_MANAGED_PART = "managed_part";
export const MRO_NON_MANAGED_PART = "non_managed_part";
export const MRO_REQUEST_PART = "request_part";
export const MRO_QR_DIRECT = "QR Direct Add"
