import React, { useState, useEffect } from "react";
import MROTextField from "components/form_components/TextField";
import DateTimeField from "../../../../../components/form_components/DateTimeField";
import RecordsTotalHours from "./RecordsTotalHours";
import { MenuItem } from "@material-ui/core";

function FreeTextField(props) {
  const { handleChange, isDpdDisabled } = props;

  // console.log("props coming are=============", { props })

  const inputProps = {
    maxLength: props.max_length,
    step: props.decimal_places ? 1 / (10 ^ props.decimal_places) : null,
    readOnly: props.disabled ? true : false,
    style: {
      textTransform: props.field_name?.replace(/\s/g, '').toLowerCase() === 'tlp#' && 'uppercase',
    }
  };
  const fieldProps = {
    // required: props.mandatory || false,
    type: props.field_type === "Datetime" ? "date" : "text",
    label:
      props.mandatory && !props?.disabled
        ? props.field_name + " *"
        : props.field_name,
    name: `${props.id || props.org_field_id}`,
    // defaultValue: props.field_value ? props.field_value : props.field_name == "Est Arrival Date/Time (UTC)" ? props?.sectionDetails && props?.sectionDetails[0].sub_section_fields[0].field_value : props.field_name == "Act Arrival Date/Time (UTC)" ? props?.sectionDetails && props?.sectionDetails[0].sub_section_fields[1].field_value : null,
    defaultValue: props.field_name?.replace(/\s/g, '').toLowerCase() === 'tlp#' ? props.field_value?.toUpperCase() : props.field_value,
    value: props.disabled
      ? props.field_value
      : props.field_type !== "Datetime"
        ? props.value
        : 0,
    // disabled: props.disabled,
    error:
      props.errors &&
      props.errors[props.id || props.org_field_id] &&
      !props.errors[props.id || props.org_field_id].valid &&
      props.mandatory,
    autoFocus:
      props.errors &&
      props.errors[props.id || props.org_field_id] &&
      !props.errors[props.id || props.org_field_id].valid &&
      props.mandatory,
    // error: props.formData && props?.formData[props.id || props.org_field_id] === '' && props.mandatory
  };

  if (props.field_name === "Total Hours") {
    return <RecordsTotalHours workOrderId={props.workOrderId} />;
  }

  return props.field_type === "Datetime" ? (
    <div style={{ width: "100%", paddingRight: "25px", marginTop: "0.5rem" }}>
      <DateTimeField
        {...fieldProps}
        format="ll - HH:mm"
        inputProps={inputProps}
        InputLabelProps={{ shrink: true }}
        onChange={handleChange}
        errors={props.errors}
        setErrors={props.setErrors}
        style={
          inputProps.readOnly
            ? { opacity: 0.55, cursor: "not-allowed !important" }
            : {}
        }
        disabled={props.disabled}
      />
    </div>
  ) : props.field_type === "Time" ? (
    <div style={{ width: "100%", paddingRight: "25px", marginTop: "0.5rem" }}>
      <DateTimeField
        {...fieldProps}
        inputProps={inputProps}
        onlyTime
        InputLabelProps={{ shrink: true }}
        onChange={handleChange}
        errors={props.errors}
        setErrors={props.setErrors}
        style={
          inputProps.readOnly
            ? { opacity: 0.55, cursor: "not-allowed !important" }
            : {}
        }
      />
    </div>
  ) : props.field_type === "Dropdown" ? (
    <div style={{ width: "100%", paddingRight: "25px" }}>
      <MROTextField select disabled={isDpdDisabled} {...fieldProps} style={{
        ...(inputProps.readOnly && {
          color: "rgba(0, 0, 0, 0.87) !important",
          pointerEvents: "none",
          backgroundColor: "rgba(0, 0, 0, 0.1)",
        })
      }}>
        {props?.menuItems?.map((item, idx) => (
          <MenuItem key={idx} value={item}>
            {item}
          </MenuItem>
        ))}
      </MROTextField>
    </div>
  ) : props.field_type === "Freetext" ? (
    <div style={{ width: "100%", paddingRight: "25px" }}>
      <MROTextField
        {...fieldProps}
        inputProps={inputProps}
        InputLabelProps={{ shrink: true }}
        multiline={props.field_name === "Delay Commentary"}
        maxRows={props.field_name === "Delay Commentary" && 5}
        // value={props.field_value}
        defaultValue={props.field_value}
        onChange={(e) => {
          handleChange(e);
        }}
        style={
          inputProps.readOnly
            ? {
              color: "rgba(0, 0, 0, 0.87) !important",
              cursor: "not-allowed !important",
              backgroundColor: "rgba(0, 0, 0, 0.1)",
            }
            : {}
        }
      // error={!fieldProps.defaultValue && fieldProps.required}
      />
    </div>
  ) : (
    <div style={{ width: "100%", paddingRight: "25px" }}>
      <MROTextField
        {...fieldProps}
        inputProps={inputProps}
        InputLabelProps={{ shrink: true }}
        value={props.value}
        onChange={(e) => {
          handleChange(e);
        }}
        style={
          inputProps.readOnly
            ? {
              color: "rgba(0, 0, 0, 0.87) !important",
              cursor: "not-allowed !important",
              backgroundColor: "rgba(0, 0, 0, 0.1)",
            }
            : {}
        }
      // error={!fieldProps.defaultValue && fieldProps.required}
      />
    </div>
  )
}

export default React.memo(FreeTextField);
