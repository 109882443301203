import requestApi from "lib/services/api/common/boxp_api_client";
import { BASE_API_URL_BOXP } from "lib/constants";

const createStorageOrder = (formData) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/create_storage_order`,
    method: "POST",
    data: formData,
  });
};
const downloadStockReport = (params) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/download_stock_report`,
    method: "GET",
    params
  });
};

const getLinkedMaintOrderListing = (params) => {
  return requestApi({
    url: `/list_linked_maintenance_order`,
    method: "GET",
    params,
  });
}

const createLinkedMaintenanceOrder = (formData) => {
  return requestApi({
    url: `/create_linked_maintenance_order`,
    method: "POST",
    data: formData,
  });
};

const getCalenderActionDue = (params) => {
  return requestApi({
    url: `/calendar_actions_due`,
    method: "GET",
    params
  })
}


const getStorageTableList = (params) => {
  return requestApi({
    url: `/maintenace_order_list`,
    method: "GET",
    params
  })
}
export const StorageServices = {
  createStorageOrder,
  downloadStockReport,
  getLinkedMaintOrderListing,
  createLinkedMaintenanceOrder,
  getCalenderActionDue,
  getStorageTableList
};
