import React, { useState, useContext, useEffect } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Box, useTheme, CircularProgress, MenuItem, IconButton } from "@material-ui/core";
import { ICON_COLOUR } from "lib/constants/style_constants";
import MROTextField from "components/form_components/TextField";
import MROButton from "components/buttons";
import ViewIcon from "@material-ui/icons/Visibility";
import FileUpload from "components/form_components/fileUpload";
import Unlock from '../../../../assets/Unlock.png'
import LockIcon from '../../../../assets/icons/Lock.svg'
import { ToastMessageContext } from "lib/contexts/message_context";
import { MaintenanceServices } from 'lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation'
import { WorkshopContext } from "../WorkshopContext/WorkshopContext";
import {
  fixedInputValueTo2DecimalPlaces,
  removeSecFromDateTime,
  validateFormData,
} from "lib/utils/helperFunctions";
import ReleaseCertPopup from "./ReleaseCertPopup";
import SignatureFile from 'components/form_components/fileUpload/SIgnatureFile';
import moment from "moment";
import _ from "lodash";
import { BASE_API_URL_BOXP } from "lib/constants";
import Add from "@material-ui/icons/Add";
import DateTimeField from 'components/form_components/PartsDatefield'
import ManageAddress from "./AddAddress";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: "3%",
  },
  appBar: {
    backgroundColor: "#fff",
    color: "#000000CC",
    boxShadow: "0px 3px 6px #0000001A",
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  paper: {
    borderRadius: "5px",
    backgroundColor: "#F5F5F5",
    padding: theme.spacing(2),
    boxShadow: "0px 1px 4px #0000001A",
  },
  partValue: {
    fontSize: "14px",
    color: "#4D4F5C",
    padding: "2px",
    display: "flex",
    alignItems: "center",
  },
  partKey: {
    "& p": {
      fontSize: "14px",
      padding: "1px",
    },
  },
  partCard: {
    borderRadius: "8px",
    padding: "17px 24px",
    "& p": {
      fontSize: "14px",
      padding: "5px",
    },
  },
  sec1: {
    borderRadius: "10px",
    backgroundColor: "#fff",
    padding: theme.spacing(5),
    boxShadow: "0px 1px 4px #0000001A",
  },
  sec: {
    width: "20%",
    height: "70px",
    borderRadius: "6px",
    backgroundColor: "#fff",
    padding: theme.spacing(5),
    boxShadow: "0px 1px 4px #0000001A",
  },
  fileView: {
    backgroundColor: "#ffffff",
    padding: "45px",
    border: "1px solid gray",
    borderRadius: " 8px",
    marginBottom: "12px",
  },
  viewOnlyField: {
    pointerEvents: "none",
    background: "#F5F5F5",
  },

}));
const numFields = ["weight", "length", "width", "height"];
function ReleaseDetails(props) {

  // console.log(props, "ReleaseDetails")
  const { releaseDetails, data,
    getReleaseList,
    workshopOrderDetails,
    getWorkshopOrderDetails,
    setIsGettingDetails,
    orderStateNum,
    getHeaderDetails,
    tabStatus,
    closeTabStatus,
    isGettingDetails,
    releaseDocuments,
    editTab,
    setEditTab, fetchDetails
  } = props

  const classes = useStyles();
  const theme = useTheme();
  const message = useContext(ToastMessageContext);
  const [customerAddress, setCustomerAddress] = useState([]);
  const [vehicleList, setVehicleList] = useState([])
  const [disabled, setDisabled] = useState(true);
  const [errors, setErrors] = useState({});
  const [isRelease, setIsRelease] = useState(false);
  const [msds, setMsds] = useState([])
  const [busy, setBusy] = useState(null);
  const [prePackingFiles, setPrePackingFiles] = useState([]);
  const [itemPackedFiles, setItemPackedFiles] = useState([]);
  const { editButton, setEditButton } =
    useContext(WorkshopContext);
  const [closeTab, setCloseTab] = useState(false);

  const [fieldsData, setFieldsData] = useState({

    weight: null,
    length: null,
    width: null,
    height: null,
    dispatch_type: null,
    receipt_cost: null,
    customer_address_id: null,
    vehicles_id: null,
    company: null,
    collected_by: null,
    carrier: null,
    tracking_no: null

  });
  //new 
  const [addressAction, setAddAction] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null)
  const [selectedAddressDetails, setSelectedDetailsAddress] = useState(null)
  const [distance, setDistance] = useState(0)



  const getVehicleList = () => {
    MaintenanceServices.getVehicleList().then((res) => {
      setVehicleList(res.data)
    }).catch(() => {

    })


  }

  const getCustomerAddress = () => {
    const param = {
      workshop_order_id: releaseDetails?.workshop_order_id,
    };
    MaintenanceServices.getCustomerAddress(param).then((res) => {
      if (res.success) {
        setCustomerAddress(res.data)
        if (selectedAddress !== null) {

          setFieldsData({ ...fieldsData, customer_address_id: selectedAddress })
        }

      }
    }).catch(() => {

    })

  }

  useEffect(() => {
    getVehicleList()
  }, [])

  useEffect(() => {
    if (releaseDetails?.workshop_order_id) { getCustomerAddress() }
  }, [releaseDetails?.workshop_order_id])




  useEffect(() => {
    if (fieldsData.customer_address_id !== null && customerAddress.length > 0) {

      let address = customerAddress.filter((item) => item.id === fieldsData.customer_address_id)
      setSelectedDetailsAddress(address[0])
      console.log("details is====================", selectedAddressDetails, address, selectedAddress)
    }
  }, [fieldsData])






  useEffect(() => {

    if (selectedAddressDetails !== null && selectedAddressDetails !== undefined && data !== undefined) {
      const service = new window.google.maps.DistanceMatrixService();

      // latitude if needed
      const origin1 = { lat: parseFloat(`${data?.base_station_latitude}`), lng: parseFloat(`${data?.base_station_longitude}`) };
      const destinationB = { lat: parseFloat(`${selectedAddressDetails?.latitude}`), lng: parseFloat(`${selectedAddressDetails?.longitude}`) };
      //address
      const origin2 = `${data?.base_station_address}`;
      const destinationA = `${selectedAddressDetails?.address}`;

      const request = {
        origins: [origin2],
        destinations: [destinationA],
        travelMode: window.google.maps.TravelMode.DRIVING,
        unitSystem: window.google.maps.UnitSystem.IMPERIAL,
        avoidHighways: false,
        avoidTolls: false,
      };
      // console.log("reques==", request)
      // The total distance of this route, expressed in meters (value) and as text. The textual value is formatted according to the unitSystem specified in the request (or in metric, if no preference was supplied).
      service.getDistanceMatrix(request).then((response) => {
        if (response?.rows[0].elements[0].status === "OK") {
          // const string = response.rows[0].elements[0].distance.text
          // let string1 = string.split(" ")
          // setDistance(string1[0])
          let string2 = response.rows[0].elements[0].distance.value
          setDistance(string2)
        }
        else {
          setDistance(0)
        }

      }).catch((err) => {
        console.log("could not find placess")
      })
    }


  }, [selectedAddressDetails])

  const onChangeHandler = (e) => {
    const name = e.target.name;
    const value = numFields.includes(name)
      ? fixedInputValueTo2DecimalPlaces(e.target.value)
      : e.target.value;


    resetErrorsHandler(name);
    setFieldsData((prevState) => ({ ...prevState, [name]: value }));
  };
  const resetErrorsHandler = (name) => {
    setErrors((e) => ({ ...e, [name]: false }));
  };

  const handleTab = () => {
    setEditButton(true)
    setEditTab(!editTab);
    setDisabled(!disabled);
    // setCloseTab(true)
  };

  const deleteFileHandler = async (id) => {
    setBusy("file-deleting");
    try {
      const response = await MaintenanceServices.deleteFile(id);

      if (response.success) {
        message.showToastMessage({
          message: "File deleted successfully",
          variant: "success",
        });
        getReleaseList();
        // setBusy(null);
      }
      setBusy(null);
    } catch (error) {
      message.showToastMessage({
        message: "Something went wrong",
        variant: "error",
      });
      setBusy("file-deleting-error");
    }
  }

  const handleEditAPiCall = async () => {
    setIsGettingDetails(true);
    const param = {
      workshop_order_id: releaseDetails?.workshop_order_id,
      tab: "release",
      release_tab_style: 1
    };
    await MaintenanceServices.editButton(param)
      .then((res) => {
        if (res.success) {
          getWorkshopOrderDetails();
          setEditButton(!editButton);
        }
      })
      .catch((err) => {
        message.showToastMessage({
          message: "Something went wrong. Try again!",
          variant: "error",
        });
        setBusy(null);
      })
      .finally(() => {
        setBusy(null);
        setIsGettingDetails(false);
      });
  };

  useEffect(() => {
    if (editButton) {
      handleEditAPiCall();
    }
  }, [editButton])

  const handleCloseTab = () => {
    getReleaseList()
    getWorkshopOrderDetails();
    setEditTab(!editTab)
    setDisabled(!disabled);
  }


  const GetInfoCard = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    return (
      <Box display="flex">
        <Box flex={50} className={classes.partKey}>
          <Typography
            style={{ padding: "5px", color: theme.palette.primary.main }}
            color="secondary"
          >
            {props?.keyName}
          </Typography>
        </Box>
        <Box flex={50} className={classes.partValue}>
          {props?.value ?? "-"}
        </Box>
      </Box>
    );
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    const { valid, error } = validateFormData(formData);

    if (!valid) {
      setErrors(error);
      return;
    }

    formData.append("workshop_order_id", releaseDetails?.workshop_order_id);
    formData.append("release_tab_style", 1)
    formData.delete("date");
    formData.set("courier_date", releaseDetails?.courier_date)

    formData.set(
      "packed_at", releaseDetails?.packed_at

    );
    formData.append("packed_by", releaseDetails?.packed_by

    )
    formData.append("item_details_correct", releaseDetails?.item_details_correct
    )

    formData.append("release_method", releaseDetails?.release_method)

    formData.append("docs_attached", releaseDetails?.docs_attached)
    // formData.delete('pre_packing_photos[]')

    formData.append("shipping_fee", releaseDetails.shipping_fee)
    // releaseDetails.customer_address.forEach(item => formData.append("shipping_address", item.id))
    prePackingFiles.forEach(files => formData.set("pre_packing_photos[]", files))
    formData.delete("customer_address_id");
    formData.append("shipping_address", fieldsData.customer_address_id)
    formData.append("delivery_distance", distance)

    itemPackedFiles.forEach(files => formData.set("packed_item_photos[]", files))

    releaseDetails.collect_signature.forEach(collectSign => formData.set("collect_signature", collectSign))
    // for (var [key, value] of formData.entries()) {
    //   console.log(key, value);
    // }
    if (closeTab) {
      formData.append("edit_tab", true)
      formData.append("web_app", true)
    }
    setBusy("loading");
    const params = {
      edit_tab: true
    }
    try {
      const response = await MaintenanceServices.updateReleaseTabData(
        formData,
        releaseDetails.id,
      );
      if (response.success) {
        console.log("response.success", response)
        // getHeaderDetails();
        getReleaseList();
        handleCloseTab();
        message.showToastMessage({
          message: "Updated Successfully!",
          variant: "success",
        });
      } else {
        message.showToastMessage({
          message:
            response.error.length > 0
              ? response.error?.join(", ")
              : "Something went wrong!",
          variant: "error",
        });
      }
      console.table("response", response);
      setBusy(null);
    } catch (error) {
      setBusy("error");
      message.showToastMessage({
        message: "Something went wrong",
        variant: "error",
      });
    }
  };

  useEffect(() => {
    let tempData = {};
    Object.keys(releaseDetails).forEach((key) => {
      tempData[key] = releaseDetails[key];
    });
    setFieldsData(tempData);

  }, [releaseDetails]);

  const handleCustomerAddress = (address) => {
    if (address != null || []) {
      console.log("CustomerAdress", address)
      let objArray2 = [];
      address.map((arr) => {
        objArray2.push(arr.address_line_1)
        objArray2.push(arr.address_line_2)
        objArray2.push(arr.address_line_3)
        objArray2.push(arr.city)
        objArray2.push(arr.country)
        objArray2.push(arr.postcode)
      })
      let customerAddress = objArray2.filter(Boolean).join(",")
      return customerAddress
    }

    else {
      console.log("error")
      return "-"
    }

  }

  if (isGettingDetails) {
    return (
      <Box
        width="100%"
        minHeight="400px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress color="primary" size={40} />
      </Box>
    );
  }

  return (
    <div>
      <form noValidate onSubmit={submitHandler} >
        <Box>
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <Box display="flex" alignItems="center">
              <Typography
                style={{
                  color: theme.palette.primary.main,
                  marginBottom: "12px",
                  fontSize: "17px",
                }}
                color="primary"

              >
                Documentation
              </Typography>
            </Box>
          </Box>

          <Box
            display="flex"
            alignItems="center"

            bgcolor="#F5F5F5"
            className={classes.partCard}
          >
            <Box flexGrow={1}>
              <div style={{ width: "100%" }}>
                <GetInfoCard
                  keyName={"Serviceable Label"}
                  value={releaseDocuments.serviceable_label !== undefined && releaseDocuments?.serviceable_label.length != 0 ?
                    <div style={{ marginTop: "3px" }}>
                      <ViewIcon
                        style={{ cursor: "pointer" }}
                        fontSize="small"
                        htmlColor={ICON_COLOUR}
                        onClick={() => {
                          if (releaseDocuments?.serviceable_label != undefined && releaseDocuments?.serviceable_label != []) {
                            window.open(releaseDocuments?.serviceable_label.map(document => document.file_path
                            ))
                          }
                          else {
                            message.showToastMessage({
                              message: "No Label Found.",
                              variant: "error",
                            });
                          }
                        }}
                      />
                    </div> : "N/A"
                  } />
                <GetInfoCard keyName={"Workshop Report"} value={
                  releaseDocuments?.workshop_report !== undefined && releaseDocuments?.workshop_report != [] ?
                    <div style={{ marginTop: "3px" }}>
                      <ViewIcon
                        style={{ cursor: "pointer" }}
                        fontSize="small"
                        htmlColor={ICON_COLOUR}
                        onClick={() => {
                          if (releaseDocuments?.workshop_report !== undefined && releaseDocuments?.workshop_report != []) {
                            window.open(releaseDocuments?.workshop_report.map(document => document.file_path
                            ))
                          }
                          else {
                            message.showToastMessage({
                              message: "No Report Found.",
                              variant: "error",
                            });
                          }

                        }}
                      />
                    </div> : "N/A"
                } />
                <GetInfoCard keyName={"C of C"} value={
                  releaseDocuments?.c_of_c !== undefined && releaseDocuments?.c_of_c.length != [] ?
                    <div style={{ marginTop: "3px" }}>
                      <ViewIcon
                        style={{ cursor: "pointer" }}
                        fontSize="small"
                        htmlColor={ICON_COLOUR}
                        onClick={() => {
                          if (releaseDocuments?.c_of_c !== undefined && releaseDocuments?.c_of_c != []) {
                            window.open(releaseDocuments.c_of_c.map(document => document.file_path
                            ))
                          }
                          else {
                            message.showToastMessage({
                              message: "No file Found.",
                              variant: "error",
                            });
                          }

                        }}
                      />
                    </div> : "N/A"} />
                <GetInfoCard keyName={"Release Cert1 "}
                  value={
                    <div style={{ marginTop: "3px" }}>
                      <ViewIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setIsRelease(true);
                          setMsds(
                            releaseDocuments.release_cert_1?.map((item) => ({
                              ...item,
                              url: item.file_path,
                            }))
                          );
                        }}
                        fontSize="small"
                        htmlColor={ICON_COLOUR}
                      />
                    </div>
                  } />
                <GetInfoCard keyName={"Release Cert2 "}
                  value={
                    <div style={{ marginTop: "3px" }}>
                      <ViewIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setIsRelease(true);
                          setMsds(
                            releaseDocuments.release_cert_2?.map((item) => ({
                              ...item,
                              url: item.file_path,
                            }))
                          );
                        }}
                        fontSize="small"
                        htmlColor={ICON_COLOUR}
                      />
                    </div>
                  } />
                <GetInfoCard keyName={"Scrap Cert"}
                  value={releaseDocuments?.scrap_certificate !== undefined && releaseDocuments?.scrap_certificate.length != [] ?
                    <div style={{ marginTop: "3px" }}>
                      <ViewIcon
                        style={{ cursor: "pointer" }}
                        fontSize="small"
                        htmlColor={ICON_COLOUR}
                        onClick={() => {
                          if (releaseDocuments?.scrap_certificate != undefined && releaseDocuments?.scrap_certificate != []) {
                            window.open(releaseDocuments?.scrap_certificate.map(document => document.file_path
                            ))
                          }
                          else {
                            message.showToastMessage({
                              message: "No Label Found.",
                              variant: "error",
                            });
                          }
                        }}

                      />
                    </div> : "N/A"} />
                <GetInfoCard keyName={"Unserviceable Label"} value={
                  releaseDocuments?.unserviceable_label != undefined && releaseDocuments?.unserviceable_label.length != 0 ?
                    <div style={{ marginTop: "3px" }}>
                      <ViewIcon
                        style={{ cursor: "pointer" }}
                        fontSize="small"
                        htmlColor={ICON_COLOUR}
                        onClick={() => {
                          if (releaseDocuments?.unserviceable_label != undefined && releaseDocuments?.unserviceable_label != []) {
                            window.open(releaseDocuments?.unserviceable_label.map(document => document.file_path
                            ))
                          }
                          else {
                            message.showToastMessage({
                              message: "No label Found.",
                              variant: "error",
                            });
                          }

                        }}
                      />
                    </div> : "N/A"} />
              </div>
            </Box>
            <Box flexGrow={1}>
              <div style={{ width: "100%" }}>
                <GetInfoCard keyName={""} value={""} />
                <GetInfoCard keyName={""} value="" />
                <GetInfoCard keyName={""} value="" />
                <GetInfoCard keyName={""} value="" />
                <GetInfoCard keyName={""} value="" />
                <GetInfoCard keyName={""} value="" />
                <GetInfoCard keyName={""} value="" />
              </div>
            </Box>
          </Box>

          <Typography
            style={{
              color: theme.palette.primary.main,
              margin: "15px 0px 12px",
              fontSize: "17px",
            }}
            color="primary"

          >
            Item Condition (pre-packing)
          </Typography>
          <Typography style={{ marginBottom: "10px" }}>
            Photos of item pre-packing
          </Typography>
          <div>


            <FileUpload
              defaultValue={releaseDetails.pre_packing_photos !== undefined
                && releaseDetails.pre_packing_photos.map((file) => ({
                  filename: file?.file_name,
                  url: file?.file_path,
                  id: file?.id,
                }))
              }
              APIUrl={""}

              setFiles={setPrePackingFiles}
              uploadButtonView={editTab === false}
              multiple={true}
              name={"pre_packing_photos[]"}
              id="pre_packing_photos[]"
              action={"Upload Document"}
              onDelete={disabled ? null : deleteFileHandler}
              isDeleting={busy === "file-deleting"}

            />
          </div>

          <Typography
            style={{
              color: theme.palette.primary.main,
              margin: "15px 0px 12px",
              fontSize: "17px",
            }}
            color="primary"

          >
            Packing
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <MROTextField
                name={"packed by"}
                label={"Packed By"}
                variant="outlined"
                placeholder={""}
                disabled={true}
                required
                value={releaseDetails.packed_by_userstamp}
                InputLabelProps={{ shrink: true }}
              ></MROTextField>
            </Grid>
            <Grid item xs={3}>
              <MROTextField
                name={"Packed"}
                label={"Packed"}
                placeholder={""}
                variant="outlined"
                disabled={true}
                value={moment(releaseDetails.packed_at, "YYYY-MM-DD hh:mm:ss a").format("DD/MM/YYYY HH:mm")}
                required
                InputLabelProps={{ shrink: true }}
              ></MROTextField>
            </Grid>
          </Grid>

          <Grid container spacing={3} style={{ marginTop: "30px" }}>
            <Grid item xs={3}>
              <MROTextField
                error={errors.weight}
                name={"weight"}
                label={"Weight (kg)"}
                placeholder={"Shipping weight kg"}
                className={disabled ? classes.viewOnlyField : null}
                defaultValue={
                  releaseDetails.weight
                }
                variant="outlined"
                value={fieldsData.weight}
                required
                InputLabelProps={{ shrink: true }}
                onChange={onChangeHandler}
              ></MROTextField>
            </Grid>
            <Grid item xs={2}>
              <MROTextField
                error={errors.length}
                name={"length"}
                label={"L (cm)"}
                placeholder={"Length"}
                // disabled={disabled}
                className={disabled ? classes.viewOnlyField : null}
                defaultValue={
                  releaseDetails !== undefined && releaseDetails.length
                }
                required
                onChange={onChangeHandler}
                InputLabelProps={{ shrink: true }}
                value={fieldsData.length}
              ></MROTextField>
            </Grid>
            <Grid item xs={2}>
              <MROTextField
                error={errors.width}
                name={"width"}
                label={"W (cm)"}
                // disabled={disabled}
                className={disabled ? classes.viewOnlyField : null}
                required
                placeholder={"Width"}
                InputLabelProps={{ shrink: true }}
                defaultValue={
                  releaseDetails.width
                }
                value={fieldsData.width}
                onChange={onChangeHandler}
              ></MROTextField>
            </Grid>
            <Grid item xs={2}>
              <MROTextField
                name={"height"}
                label={"H (cm)"}
                error={errors.height}
                // disabled={disabled}
                className={disabled ? classes.viewOnlyField : null}
                required
                placeholder={"Height"}
                InputLabelProps={{ shrink: true }}
                defaultValue={
                  releaseDetails.height
                }
                value={fieldsData.height}
                onChange={onChangeHandler}
              ></MROTextField>
            </Grid>
          </Grid>

          {editTab ? (
            <Typography style={{ margin: "10px 0px 10px 0px" }}>
              Packed Photos
            </Typography>
          ) : (
            <Typography style={{ margin: "10px 0px 10px 0px" }}>
              Photos of packed item
            </Typography>
          )}


          <div>
            <FileUpload
              //onDelete={handleDeleteCertificates}
              defaultValue={releaseDetails.packed_item_photos !== undefined
                && releaseDetails.packed_item_photos.map((item) => ({
                  filename: item?.file_name,
                  url: `${item?.file_path}`,
                  id: item?.id,
                }))
              }
              APIUrl={""}
              setFiles={(files) => { setItemPackedFiles(files) }}
              uploadButtonView={editTab === false}
              // onChange={handlePackedChange}
              // onChange={handleChange}
              name={"packed_item_photos[]"}
              id="packed_item_photos[]"
              action={"Upload Document"}
              onDelete={disabled ? null : deleteFileHandler}
              isDeleting={busy === "file-deleting"}
            />


          </div>


          <Typography
            style={{
              color: theme.palette.primary.main,
              margin: "15px 0px 12px",
              fontSize: "17px",
            }}
            color="primary"

          >
            Shipping
          </Typography>

          <Grid container spacing={3}>

            <Grid item xs={3}>
              <MROTextField
                error={errors.dispatch_type}
                name={"dispatch_type"}
                label={"Dispatch Type *"}
                variant="outlined"
                placeholder={"Excellent"}
                className={disabled ? classes.viewOnlyField : null}
                select
                value={fieldsData.dispatch_type}
                InputLabelProps={{ shrink: true }}
                onChange={onChangeHandler}
              >
                <MenuItem disabled value={null}>
                  Select
                </MenuItem>
                <MenuItem value={"customer_collect"}>Customer Collect</MenuItem>
                <MenuItem value={"mro_delivery"}>MRO Delivery</MenuItem>
                <MenuItem value={"mro_arranged_third_party_agent"}>MRO Arranged 3rd Party Agent</MenuItem>

              </MROTextField>
            </Grid>
            {fieldsData.dispatch_type === "mro_delivery" && <>
              <Grid container item xs={9}>
                <Grid item xs={3}>
                  <MROTextField
                    error={errors.vehicles_id}
                    name={"vehicles_id"}
                    label={"Vehicle Used *"}
                    variant="outlined"
                    placeholder={"Excellent"}
                    className={disabled ? classes.viewOnlyField : null}
                    select
                    value={fieldsData.vehicles_id}
                    InputLabelProps={{ shrink: true }}
                    onChange={onChangeHandler}
                  >
                    <MenuItem disabled value={null}> Select</MenuItem>
                    {vehicleList.map((item) =>
                      <MenuItem value={item.id}>{item.registration}</MenuItem>

                    )}
                  </MROTextField>
                </Grid>
              </Grid>
              <br />

              <Grid container xs={12} spacing={3} style={{ paddingLeft: '15px' }}>
                <Grid item xs={3} style={{ paddingTop: '21px' }}>

                  <DateTimeField
                    // format={"YYYY-MM-DD"}
                    disabled={disabled}
                    label={"Date *"}
                    name={'courier_date'}
                    InputLabelProps={{ shrink: true }}
                    error={errors.courier_date}
                    defaultValue={releaseDetails?.courier_date}
                    helperText={errors.arr_datetime ? "*please enter a date" : null}
                    style={
                      { opacity: 0.55, cursor: "not-allowed !important" }}
                    onChange={onChangeHandler}
                  // InputProps={{
                  //     className: !props?.permission?.write && classes.disableField,
                  // }}
                  />
                </Grid>

                <Grid
                  item
                  xs={6}

                >
                  <MROTextField
                    error={errors.customer_address_id}
                    name={"customer_address_id"}
                    label={"Delivery Address *"}
                    placeholder={"Enter distance driven"}
                    variant="outlined"
                    select
                    InputLabelProps={{ shrink: true }}
                    className={disabled ? classes.viewOnlyField : null}
                    value={fieldsData?.customer_address_id}
                    onChange={onChangeHandler}
                  >
                    <MenuItem disabled value={null}> Select</MenuItem>
                    {customerAddress.map((item) =>
                      <MenuItem value={item.id} key={item.id}>{item.address}</MenuItem>

                    )}
                  </MROTextField>
                </Grid>

                <Grid item style={{ marginTop: '21px' }}>
                  {disabled ? "" : <IconButton style={{ outline: 'auto' }} onClick={() => {
                    setAddAction("create")
                  }}><Add /></IconButton>}

                </Grid>
              </Grid>
            </>}
            {fieldsData.dispatch_type === "customer_collect" && <>

              <Grid item xs={3}>
                <MROTextField
                  error={errors.collected_by}
                  name={"collected_by"}
                  label={"Name *"}
                  variant="outlined"
                  placeholder={"Excellent"}
                  className={disabled ? classes.viewOnlyField : null}
                  value={fieldsData.collected_by}
                  InputLabelProps={{ shrink: true }}
                  onChange={onChangeHandler}
                >

                </MROTextField>
              </Grid>

              <Grid
                item
                xs={3}
              // style={{ marginTop: "8px" }}
              // className={classes.viewOnlyField}
              >
                <MROTextField
                  error={errors.company}
                  name={"company"}
                  label={"Company *"}
                  variant="outlined"
                  placeholder={"Excellent"}
                  className={disabled ? classes.viewOnlyField : null}
                  value={fieldsData.company}
                  InputLabelProps={{ shrink: true }}
                  onChange={onChangeHandler}
                >

                </MROTextField>
              </Grid>

              <Grid container xs={12} spacing={3} style={{ paddingLeft: '15px' }}>
                <Grid item xs={3} style={{ paddingTop: '21px' }}>

                  <DateTimeField
                    // format={"YYYY-MM-DD"}
                    disabled={disabled}
                    label={"Date *"}
                    name={'courier_date'}
                    InputLabelProps={{ shrink: true }}
                    error={errors.courier_date}
                    defaultValue={releaseDetails?.courier_date}
                    helperText={errors.courier_date ? "*please enter a date" : null}
                    style={
                      { opacity: 0.55, cursor: "not-allowed !important" }}
                    onChange={onChangeHandler}
                  // InputProps={{
                  //     className: !props?.permission?.write && classes.disableField,
                  // }}
                  />
                </Grid>







              </Grid>


            </>}
            {fieldsData.dispatch_type === "mro_arranged_third_party_agent" && <>

              <Grid
                item
                xs={3}
              // style={{ marginTop: "8px" }}
              // className={classes.viewOnlyField}
              >
                <MROTextField
                  error={errors.carrier}
                  name={"carrier"}
                  label={"Carrier *"}
                  variant="outlined"
                  placeholder={"Excellent"}
                  className={disabled ? classes.viewOnlyField : null}
                  value={fieldsData.carrier}
                  InputLabelProps={{ shrink: true }}
                  onChange={onChangeHandler}
                >

                </MROTextField>
              </Grid>
              <Grid
                item
                xs={3}
              // style={{ marginTop: "8px" }}
              // className={classes.viewOnlyField}
              >
                <MROTextField
                  error={errors.tracking_no}
                  name={"tracking_no"}
                  label={"Tracking # *"}
                  variant="outlined"
                  placeholder={"Excellent"}
                  className={disabled ? classes.viewOnlyField : null}
                  value={fieldsData.tracking_no}
                  InputLabelProps={{ shrink: true }}
                  onChange={onChangeHandler}
                >

                </MROTextField>
              </Grid>

              <Grid container xs={12} spacing={3} style={{ paddingLeft: '15px' }}>
                <Grid item xs={3} style={{ paddingTop: '21px' }}>

                  <DateTimeField
                    // format={"YYYY-MM-DD"}
                    disabled={disabled}
                    label={"Date *"}
                    name={'courier_date'}
                    InputLabelProps={{ shrink: true }}
                    error={errors.courier_date}
                    defaultValue={releaseDetails?.courier_date}
                    helperText={errors.arr_datetime ? "*please enter a date" : null}
                    style={
                      { opacity: 0.55, cursor: "not-allowed !important" }}
                    onChange={onChangeHandler}
                  // InputProps={{
                  //     className: !props?.permission?.write && classes.disableField,
                  // }}
                  />
                </Grid>

                <Grid
                  item
                  xs={6}

                >
                  <MROTextField
                    error={errors.customer_address_id}
                    name={"customer_address_id"}
                    label={"Delivery Address *"}
                    placeholder={"Enter distance driven"}
                    variant="outlined"
                    select
                    InputLabelProps={{ shrink: true }}
                    className={disabled ? classes.viewOnlyField : null}
                    value={fieldsData?.customer_address_id}
                    onChange={onChangeHandler}
                  >
                    <MenuItem disabled value={null}> Select</MenuItem>
                    {customerAddress.map((item) =>
                      <MenuItem value={item.id} key={item.id}>{item.address}</MenuItem>

                    )}
                  </MROTextField>
                </Grid>

                <Grid item style={{ marginTop: '21px' }}>
                  {disabled ? "" : <IconButton style={{ outline: 'auto' }} onClick={() => {
                    setAddAction("create")
                  }}><Add /></IconButton>}

                </Grid>
              </Grid>
            </>}
          </Grid>



          {/* <Box
            display="flex"
            alignItems="center"
            bgcolor="#F5F5F5"
            className={classes.partCard}
          >
            <Box flexGrow={1}>
              {releaseDetails.release_method === "shipped" &&
                <div style={{ width: "100%" }}>
                  <GetInfoCard keyName={"Method"} value={releaseDetails?.release_method} />

                  <GetInfoCard keyName={"Shipping Address"} value={releaseDetails?.customer_address != [] || undefined ? handleCustomerAddress(releaseDetails.customer_address) : "-"} />
                  <GetInfoCard keyName={"Carrier"} value={releaseDetails?.carrier || "-"} />
                  <GetInfoCard keyName={"Tracking #"} value={releaseDetails?.tracking_no || "-"
                  } />
                  <GetInfoCard keyName={"Date Shipped"} value={releaseDetails?.courier_date ? moment(releaseDetails?.courier_date, "YYYY-MM-DD hh:mm:ss a").format("DD/MM/YYYY HH:mm") : '-'
                  } />
                  <GetInfoCard keyName={"Shipping Fee"} value={releaseDetails?.shipping_fee || "-"} />
                </div>}
              {releaseDetails.release_method === null &&
                <div style={{ width: "100%" }}>
                  <GetInfoCard keyName={"Method"} value={"-"} />
                  <GetInfoCard keyName={"Shipping Address"} value={"-"} />
                  <GetInfoCard keyName={"Carrier"} value={"-"} />
                  <GetInfoCard keyName={"Tracking #"} value={"-"
                  } />
                  <GetInfoCard keyName={"Date Shipped"} value={"-"
                  } />
                  <GetInfoCard keyName={"Shipping Fee"} value={"-"} />
                </div>}
              {releaseDetails.release_method === "collect" &&
                <div style={{ width: "100%" }}>
                  <GetInfoCard keyName={"Method"} value={releaseDetails?.release_method || "-"} />
                  <GetInfoCard keyName={"Collected by"} value={releaseDetails?.collected_by || "-"} />
                  <GetInfoCard keyName={"Company"} value={releaseDetails?.company || "-"
                  } />
                  <GetInfoCard keyName={"Date Collected"} value={releaseDetails?.courier_date ? moment(releaseDetails.courier_date, "YYYY-MM-DD hh:mm:ss a").format("DD/MM/YYYY HH:mm") : '-'

                  } />
                  <GetInfoCard keyName={"Signature"} value={""} />
                  <Grid>
                    <SignatureFile
                      defaultValue={releaseDetails?.collect_signature?.map(
                        (file) => ({
                          filename: file?.file_name,
                          url: file?.file_path,
                          id: file?.id,
                        })
                      )}
                      APIUrl={""}
                      uploadButtonView={false}
                    />
                  </Grid>


                </div>

              }
            </Box>
          </Box> */}
        </Box>
        <Grid
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "20px",
          }}
        >

          {orderStateNum > 9 && tabStatus && props?.permission.write &&
            < MROButton
              type="button"

              variant={"contained"}
              style={{
                backgroundColor: "#FFB000",
                color: "white",
                display: editTab ? "none" : undefined,
              }}
              onClick={() => handleTab()}
              startIcon={<img src={LockIcon} style={{ height: '18px', filter: "invert(1)" }} />}
            >
              Edit Tab
            </MROButton>}
          {orderStateNum > 9 && closeTabStatus === 2 && props?.permission.write && <MROButton
            variant={"contained"}

            style={{
              backgroundColor: "red",
              color: "white",
              display: !editTab ? "none" : undefined,
            }}
            type="submit"
            loading={busy === "loading"}
            startIcon={
              <img src={Unlock} style={{ height: '18px' }} />
            }
            onClick={() => {
              setCloseTab(true)


            }
            }
          >
            Close Tab
          </MROButton>}



        </Grid>
      </form>
      {
        isRelease &&
        <ReleaseCertPopup
          dialogCloseHandler={() => setIsRelease(false)}
          open={isRelease}
          msds={msds}
        />
      }
      {addressAction && addressAction === "create" && (
        <ManageAddress
          handleClose={() => setAddAction(null)}
          workshopDetails={data}
          setSelectedAddress={setSelectedAddress}
          getCustomerAddress={getCustomerAddress}

        />
      )}
    </div >
  );
}

export default ReleaseDetails;
