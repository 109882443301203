import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { ActionCableProvider } from 'react-actioncable-provider';

import App from './App';
import * as serviceWorker from './serviceWorker';
// import { API_WS_ROOT } from './constants';
const authorization_headers = (sessionStorage.getItem('authorization_headers'));
var authUrl = '';


// if (authorization_headers != null) {
//   const token = JSON.parse(sessionStorage.getItem('authorization_headers'));
//   authUrl = API_WS_ROOT + '?uid=' + token.uid + '&access-token=' + token['access-token'] + '&client=' + token.client
// }
ReactDOM.render(
  <React.StrictMode>
    <ActionCableProvider url={authUrl} >
      <App />
    </ActionCableProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
