import React, { useContext, useEffect, useState } from "react";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Box,
    Grid,
    Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MROButton from "components/buttons";
import PlanningLineMaintenance from 'lib/services/api/planning/LineMaintenance/lineEventsandSeries';
import { ToastMessageContext } from "lib/contexts/message_context";
import WarningIcon from "@material-ui/icons/Warning";
import CapabilityServices from "lib/services/api/compliance/capability_client";
import { getOrganisationId } from "lib/utils/common_utils";
import moment from "moment";



const useStyles = makeStyles((theme) => ({
    actionButtons: {
        paddingTop: "20px",
        paddingBottom: "33px",
        textAlign: "right",
        "& button:not(:last-child)": {
            marginRight: "20px",
        },
    },
    modal: {
        "& .MuiDialog-paperWidthMd": {
            maxWidth: "750px",
        },
    },
    title: {
        fontSize: '22px',
        "& h2": {
            color: theme.palette.primary.main,

        },

        "& span": {
            color: theme.palette.primary.main,
            fontSize: 22,
            textAlign: 'center'
        },
    },
    card: {
        padding: "0 25px",
    },
}));


export default function VerifyRevision(props) {
    const classes = useStyles();
    const { handleClose, getTechListing, currentSelection } = props
    const message = useContext(ToastMessageContext)
    const [loading, setLoading] = useState(false)
    const user = JSON.parse(sessionStorage.getItem("user"));

    const handleSubmit = async (e) => {
        const formData = {
            id: currentSelection.id,
            organisation_id: getOrganisationId(),
            user_id: user?.id,
            revision: currentSelection?.revision,
            revision_date: currentSelection?.revision_date

        }

        try {

            setLoading(true)
            const response = await CapabilityServices.verifyRevisionState(formData);
            if (response.success) {
                message.showToastMessage({
                    message: response?.message ? response?.message : "Deleted sucessfully",
                    variant: "success",
                });
                handleClose()
                getTechListing()
            }
            else {
                message.showToastMessage({
                    message: response?.error ? response?.error : "Something went wrong. Try again",
                    variant: "error",
                });
                handleClose()
                getTechListing()
            }
        } catch (error) {
            message.showToastMessage({
                message: "Something went wrong. Try again",
                variant: "error",
            });
            setLoading(false)
        }
        setLoading(false)
    }







    return (
        <div>
            <Dialog
                maxWidth={"md"}
                fullWidth={true}
                open={true}
                className={classes.modal}
                onClose={handleClose}
                aria-labelledby="delete-event"
            >
                <DialogTitle
                    variant="subtitle1"
                    color="secondary"
                    className={classes.title}
                >
                    <Typography style={{ textAlign: 'center', fontSize: '22px', }}>Verify Revision Status</Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography style={{ textAlign: 'center', paddingBottom: '10px' }}>Confirm - The revision # and revision date below remain the current version on the present date?</Typography>
                    <Typography style={{ textAlign: 'center', paddingBottom: '10px' }}>Revision #: <b style={{ paddingLeft: '10px' }}>{currentSelection?.revision ? currentSelection?.revision : '-'}</b></Typography>
                    <Typography style={{ textAlign: 'center', paddingBottom: '10px' }}>Revision Date: <b style={{ paddingLeft: '10px' }}>{currentSelection?.revision_date ? moment(currentSelection?.revision_date, "YYYY-MM-DD").format("DD/MM/YYYY") : "-"} </b></Typography>
                    <Typography style={{ textAlign: 'center', paddingBottom: '10px' }}>Confirming will update the “Verified” date to today’s date and the “Age” to zero</Typography>
                </DialogContent>
                <Box
                    display="flex"
                    width="100%"
                    justifyContent="center"
                    my="25px"
                    align="center"
                >
                    <MROButton
                        type="button"
                        variant={"contained"}
                        onClick={handleClose}
                        style={{ marginRight: 20 }}
                    >
                        Cancel
                    </MROButton>
                    <MROButton
                        color="primary"
                        onClick={handleSubmit}
                        variant={"contained"}
                        loading={loading}
                    >
                        Confirm
                    </MROButton>
                </Box>
            </Dialog>

        </div>
    )
}
