import React from "react"

const Departure = ({ fill }) =>
(<svg xmlns="http://www.w3.org/2000/svg" width="125" height="18" viewBox="0 0 135 94.355">
  <g id="takeoff-the-plane" transform="translate(0 -55.948)">
    <g id="Group_5702" data-name="Group 5702" transform="translate(0 55.948)">
      <g id="Group_5701" data-name="Group 5701" transform="translate(0 0)">
        <path id="Path_19818" data-name="Path 19818" d="M13.742,112.759l-.012.006a3.166,3.166,0,0,0,2.46,5.814l44.261-14.726-8.276,13.515a2.563,2.563,0,0,0,3.368,3.613l8.679-4.51a2.552,2.552,0,0,0,.67-.5L90.6,89.11l31.732-16.557c12.018-6.478,10.625-11.458,9.913-12.83a6.777,6.777,0,0,0-4.533-3.244c-2.784-.765-6.546-.7-11.5.187a14.89,14.89,0,0,1-3.532.246c-3.4-.093-5.7.135-17.141,6.079L32.978,95.506,8.713,82.913a3.165,3.165,0,0,0-2.919,0L1.706,85.04A3.166,3.166,0,0,0,.7,89.831l16.841,20.977Z" transform="translate(0 -55.948)" fill={fill} />
        <path id="Path_19819" data-name="Path 19819" d="M136.829,282.5H18.375a6.031,6.031,0,0,0,0,12.063H136.829a6.031,6.031,0,0,0,0-12.062Z" transform="translate(-7.86 -200.208)" fill={fill} />
      </g>
    </g>
  </g>
</svg>)


export default Departure