import React,{useState,useEffect} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import {ICON_COLOUR} from "../lib/constants/style_constants";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';


const useStyles = makeStyles(theme => ({
    wrapper:{
        backgroundColor: "#fff",
        border: "1px solid #d5d5d5",
        margin: "1% 0",
        display: 'flex',
        '& button':{
            marginRight:theme.spacing(2),
            width:'200px',
            '& .MuiButton-label':{
                justifyContent:'space-between',
                width:'150px',
                whiteSpace:'nowrap',
                textOverflow:'ellipsis',
                overflow:'hidden'
            }
        }
    },
}));

function SearchByCategory(props){
    const classes = useStyles();
    const anchorRef = React.useRef(null);
    const [open,setOpen] = useState(false);
    const {options,setSelectedOption,selectedOption,searchText,handleSearchText,placeholder} = props;

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    const handleSelect = (option) => {
        setSelectedOption(option);
        setOpen(false);
    }

    return (
        <div className={classes.wrapper}>
            {options && options.length > 0 && <> <Button noWrap fullWidth ref={anchorRef} variant="contained" color="primary" endIcon={<ArrowDropDownIcon size="small"/>} onClick={()=>setOpen(true)}>{selectedOption.label}</Button>
            <Popper style={{zIndex:'1500'}} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={()=>setOpen(false)}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                    {options.map(option => <MenuItem
                                        onClick={() => handleSelect(option)}>{option.label}</MenuItem>)}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
            </>}
            <InputBase
                fullWidth
                style={{height:50,paddingLeft:10}}
                placeholder={placeholder||"Search anything"}
                value={searchText}
                onChange={(e)=>handleSearchText(e.target.value)}
                startAdornment={<InputAdornment position="start"><SearchIcon htmlColor={ICON_COLOUR}/></InputAdornment>}
            />
        </div>
    )
}

export default SearchByCategory;