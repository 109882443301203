import React, { useEffect, useContext, useState } from 'react'
import { Dialog, DialogContent, DialogTitle, Box, ButtonGroup, Button, FormControlLabel, Grid, MenuItem, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import MROButton from 'components/buttons';
import MROTextField from 'components/form_components/TextField';
import { getOrganisationId } from 'lib/utils/common_utils';
import { ToastMessageContext } from 'lib/contexts/message_context';
import ResourcesServices from 'lib/services/api/planning/Resource/resources';

const useStyles = makeStyles(theme => ({
    actionButtons: {
        paddingTop: '20px',
        paddingBottom: '33px',
        textAlign: 'center',
        '& button:not(:last-child)': {
            marginRight: '20px'
        }
    },
    modal: {
        '& .MuiDialog-paperWidthMd': {
            maxWidth: '600px',
        }
    },
    title: {
        fontSize: '18px',
        '& h2': {
            color: theme.palette.primary.main,

        }
    },
    formLabel: {

        '& span:last-child': {
            fontSize: '14px',
        }
    },
    card: {
        padding: '6px 60px 15px 60px'
    }

}));


function EditZones(props) {
    const classes = useStyles();
    const { action, handleClose, getListing, currentSelection, zoneData, onClose } = props;
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false)
    const message = useContext(ToastMessageContext)
    const handleSubmit = async (e) => {


        e.preventDefault()
        const formData = new FormData(e.target)

        let valid = true;
        let error = {};


        for (let pair of formData.entries()) {
            const value = pair[1];
            const key = pair[0];
            // console.table("formdata=====", key, value)
            if (!value) {
                error[key] = true;
                valid = false;
            }
        }

        if (!valid) {
            setErrors(error);
            return;
        }
        let id = formData.get("shift_zone_id")
        formData.delete("shift_zone_id")
        formData.append("id", id)


        formData.append("shift_id", currentSelection?.id)
        formData.append("key", "zone")
        setLoading(true)
        await ResourcesServices.updateShifZoneArea(formData).then((res) => {
            message.showToastMessage({
                message: "Updated successfully !",
                variant: "success",
            });
            onClose()
            getListing()
        }).catch((res) => {
            onClose()
            message.showToastMessage({
                message: res.message ? res.message : "Something went wrong. Try again!",
                variant: "error",
            });
        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <div>
            <Dialog
                maxWidth={"md"}
                fullWidth={true}
                open={true}
                className={classes.modal}
                onClose={handleClose}
                aria-labelledby="indirect-jobs"
            >
                <DialogTitle variant="subtitle2" color="secondary"
                    className={classes.title}><Typography style={{ textAlign: 'center', fontSize: '20px', }}>Edit User Zone</Typography>
                </DialogTitle>
                <Typography style={{ textAlign: 'center' }}>Edit action will only update this single shift</Typography>
                <DialogContent className={classes.card}>
                    <form onSubmit={handleSubmit}>
                        <Grid container>
                            <Grid xs={1}></Grid>
                            <Grid style={{ paddingBottom: '10px' }} xs={10}>
                                <MROTextField
                                    name={"shift_zone_id"}
                                    label={"Zone *"}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ style: { height: 'max-content', width: "100%" } }}
                                    inputProps={{ maxLength: 100 }}
                                    select
                                    multiline
                                    //  required
                                    placeholder={"Select an Zone(optional)"}
                                    className={classes.inputField}
                                    defaultValue={currentSelection?.shift_zone_id}
                                    error={errors.shift_zone_id}
                                    helperText={errors.shift_zone_id ? "*please select zone" : null}
                                    onChange={(e) => { setErrors({ ...errors, shift_zone_id: false }); }}
                                >

                                    {zoneData && zoneData.map((value) => (
                                        <MenuItem value={value.id}>{value.name}</MenuItem>
                                    ))}


                                </MROTextField>
                            </Grid>
                            <Grid xs={1}></Grid>
                        </Grid>

                        <div className={classes.actionButtons}>
                            <MROButton
                                //   disabled={loading}
                                type="button"
                                variant={"contained"}
                                onClick={handleClose}
                            >
                                Cancel
                            </MROButton>
                            <MROButton
                                loading={loading}
                                type="submit"
                                variant={"contained"}
                                color="primary"

                            >
                                Complete
                            </MROButton>

                        </div>
                    </form>
                </DialogContent>

            </Dialog>

        </div >
    )
}

export default EditZones