import React, { useContext, useEffect, useState } from "react";
import {
    Button,
    ButtonGroup,
    makeStyles,
    MenuItem,
    Typography,
} from "@material-ui/core";
import MROTextField from "../../../../components/form_components/TextField";
import RightDialog from "./RightDialog";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import MROButton from "../../../../components/buttons";

import PartsClientServices from "../../../../lib/services/api/stores/tooling/parts_client";
import { ToastMessageContext } from "lib/contexts/message_context";
import QuantityCounter from "components/quantity_counter";
import { getOrganisationId } from "lib/utils/common_utils";

const useStyles = makeStyles({
    container: {
        width: "100%",
        height: "100%",
        padding: "23px 37px 0px 37px",
        display: "flex",
        flexDirection: "column",

        "& form": {
            height: "100%",
            display: "flex",
            flexDirection: "column",
        },
    },
    heading: {
        fontSize: "16px",
        color: "#4D4F5C",
        lineHeight: "22px",
        fontWeight: "bold",
    },
});


export default function SplitDialog(props) {
    const classes = useStyles();
    const {
        open,
        dialogCloseHandler,
        secondaryPartId,
        getPartList,
        batchNumber,
        quantitys
    } = props;

    const message = useContext(ToastMessageContext);

    const [qty, setQty] = useState();
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const user = JSON.parse(sessionStorage.getItem("user"));

    const submitHandler = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        formData.append("organisation_id", getOrganisationId());
        formData.append("secondary_part_id", secondaryPartId);

        let error = {};
        let isValid = true;

        // Display the key/value pairs
        for (let pair of formData.entries()) {
            if (pair[1] === "") {
                error[pair[0]] = true;
                isValid = false;
            }
        }
        // console.log(error);
        if (!isValid) {
            setErrors(error);
            return;
        }
        setLoading(true)
        PartsClientServices.updateSplitBatch(formData).then((res) => {
            dialogCloseHandler()
            getPartList()
            if (res?.success) {
                message.showToastMessage({
                    message: "Transferred successfully",
                    variant: "success",
                });
            }
            else {
                message.showToastMessage({
                    message: res?.errors ? res?.errors : "Something went wrong!",
                    variant: "error",
                });

            }

        }).catch(() => {
            message.showToastMessage({
                message: "Something went wrong!",
                variant: "error",
            });
        }).finally(() => {
            setLoading(false)
        })
    };

    return (
        <RightDialog open={open} dialogCloseHandler={dialogCloseHandler}>
            <div className={classes.container}>
                <Typography variant="h4" className={classes.heading}>
                    Split Batch
                </Typography>


                <div style={{ display: "flex", marginTop: "14px" }}>
                    <Typography
                        variant="body1"
                        style={{ marginRight: "30px" }}
                        color="primary"
                    >
                        Batch #
                    </Typography>
                    <Typography variant="body1">{batchNumber}</Typography>
                </div>

                <div style={{ display: "flex", marginTop: "14px", marginBottom: "20px" }}>
                    <Typography
                        variant="body1"
                        style={{ marginRight: "30px" }}
                        color="primary"
                    >
                        Quantity
                    </Typography>
                    <Typography variant="body1"> {quantitys}</Typography>
                </div>

                <form onSubmit={submitHandler}>
                    <QuantityCounter
                        name={"quantity"}
                        label={"Batch QTY *"}
                        shouldNotAcceptZero={true}
                        placeholder={"QTY"}
                        setQty={setQty}
                        qty={qty}
                        autoFocus={errors["quantity"]}
                        error={errors.quantity}
                        helperText={
                            errors.quantity ? `Please enter QTY Required` : ""
                        }
                        setError={() =>
                            setErrors((prevState) => ({
                                ...prevState,
                                quantity: false,
                            }))
                        }
                        minQty={1}

                    />
                    <div
                        style={{
                            marginTop: "auto",
                            marginBottom: "60px",
                            alignSelf: "flex-end",
                        }}
                    >
                        <MROButton
                            style={{ marginRight: "20px" }}
                            type="button"
                            variant={"contained"}
                            onClick={dialogCloseHandler}
                        >
                            Cancel
                        </MROButton>
                        <MROButton
                            disabled={loading}
                            type="submit"
                            variant={"contained"}
                            color="primary"
                        >
                            Complete
                        </MROButton>
                    </div>
                </form>
            </div>
        </RightDialog>
    )
}
