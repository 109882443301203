import React, { useState } from 'react'
import MROTextField from "components/form_components/TextField";
import MROButton from "components/buttons";
import ViewIcon from "@material-ui/icons/Visibility";
import { Box, Grid, Typography, useTheme } from '@material-ui/core';
import { ViewIcon as Visibility } from '@material-ui/icons';
import makeStyles from "@material-ui/core/styles/makeStyles";
import { ICON_COLOUR } from "lib/constants/style_constants";
import { useContext } from 'react';
import { ToastMessageContext } from 'lib/contexts/message_context';
import { BASE_API_URL_BOXP } from 'lib/constants';
import ReleaseCertPopup from './ReleaseCertPopup';
const useStyles = makeStyles((theme) => ({

    partValue: {
        fontSize: "14px",
        color: "#4D4F5C",
        padding: "2px",
        display: "flex",
        alignItems: "center",
    },
    partKey: {
        "& p": {
            fontSize: "14px",
            padding: "1px",
        },
    },
    partCard: {
        borderRadius: "8px",
        padding: "17px 24px",
        "& p": {
            fontSize: "14px",
            padding: "5px",
        },
    },

}));

function NoGoodsOut(props) {
    const { releaseDetails, releaseDocuments } = props;
    const message = useContext(ToastMessageContext)
    const classes = useStyles();
    const [isRelease, setIsRelease] = useState(false);
    const [msds, setMsds] = useState([])

    const theme = useTheme()
    const GetInfoCard = (props) => {
        console.log(props)
        const classes = useStyles();
        return (
            <Box display="flex">
                <Box flex={15} className={classes.partKey}>
                    <Typography
                        style={{ padding: "5px", color: theme.palette.primary.main }}
                        color="secondary"
                    >
                        {props?.keyName}
                    </Typography>
                </Box>
                <Box flex={50} className={classes.partValue}>
                    {props?.value ?? "-"}
                </Box>
            </Box>

        )


    }
    // console.log("......releaseDocuments ", typeof releaseDocuments.serviceable_label)
    return (
        <div><Box style={{ display: "flex", justifyContent: "space-between" }}>
            <Box display="flex" alignItems="center">
                <Typography
                    style={{
                        color: theme.palette.primary.main,
                        marginBottom: "12px",
                        fontSize: "17px",
                    }}
                    color="primary"

                >
                    Documentation
                </Typography>
            </Box>
        </Box>

            <Box
                display="flex"
                alignItems="center"
                bgcolor="#F5F5F5"
                className={classes.partCard}
            >
                <Box flexGrow={1}>
                    <div style={{ width: "100%" }}>
                        <GetInfoCard keyName={"Serviceable Label"} value={
                            releaseDocuments.serviceable_label !== undefined && releaseDocuments?.serviceable_label?.length != 0 ?
                                <ViewIcon
                                    style={{ cursor: "pointer" }}
                                    fontSize="small"
                                    htmlColor={ICON_COLOUR}
                                    onClick={() => releaseDocuments.serviceable_label !== undefined ? window.open(releaseDocuments.serviceable_label.map(document => document.file_path
                                    )) : "N/A"}
                                /> : "N/A"
                        } />
                        <GetInfoCard keyName={"Workshop Report"} value={
                            releaseDocuments?.workshop_report !== undefined && releaseDocuments?.serviceable_label != [] ?
                                <div style={{ marginTop: "3px" }}>
                                    <ViewIcon
                                        style={{ cursor: "pointer" }}
                                        fontSize="small"
                                        htmlColor={ICON_COLOUR}
                                        onClick={() => {
                                            if (releaseDocuments?.workshop_report !== undefined && releaseDocuments?.serviceable_label != []) {
                                                window.open(releaseDocuments?.workshop_report.map(document => document.file_path
                                                ))
                                            }
                                            else {
                                                message.showToastMessage({
                                                    message: "No Report Found.",
                                                    variant: "error",
                                                });
                                            }

                                        }}
                                    />
                                </div> : "N/A"
                        } />
                        <GetInfoCard keyName={"C of C"} value={
                            releaseDocuments?.c_of_c !== undefined && releaseDocuments?.c_of_c.length != [] ?
                                <div style={{ marginTop: "3px" }}>
                                    <ViewIcon
                                        style={{ cursor: "pointer" }}
                                        fontSize="small"
                                        htmlColor={ICON_COLOUR}
                                        onClick={() => {
                                            if (releaseDocuments?.c_of_c !== undefined && releaseDocuments?.serviceable_label != []) {
                                                window.open(releaseDocuments?.c_of_c.map(document => document.file_path
                                                ))
                                            }
                                            else {
                                                message.showToastMessage({
                                                    message: "No file Found.",
                                                    variant: "error",
                                                });
                                            }

                                        }}
                                    />
                                </div> : "N/A"} />

                        <GetInfoCard keyName={"Release Cert1 "}
                            value={
                                <div style={{ marginTop: "3px" }}>
                                    <ViewIcon
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setIsRelease(true);
                                            setMsds(
                                                releaseDocuments.release_cert_1?.map((item) => ({
                                                    ...item,
                                                    url: item.file_path,
                                                }))
                                            );
                                        }}
                                        fontSize="small"
                                        htmlColor={ICON_COLOUR}
                                    />
                                </div>
                            } />
                        <GetInfoCard keyName={"Release Cert2"}
                            value={
                                <div style={{ marginTop: "3px" }}>
                                    <ViewIcon
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setIsRelease(true);
                                            setMsds(
                                                releaseDocuments.release_cert_2?.map((item) => ({
                                                    ...item,
                                                    url: item.file_path,
                                                }))
                                            );
                                        }}
                                        fontSize="small"
                                        htmlColor={ICON_COLOUR}
                                    />
                                </div>
                            } />
                        <GetInfoCard keyName={" Scrap Cert "}
                            value={releaseDocuments?.scrap_certificate != [] && releaseDocuments?.scrap_certificate?.length != 0 ?
                                <div style={{ marginTop: "3px" }}>
                                    <ViewIcon
                                        style={{ cursor: "pointer" }}
                                        fontSize="small"
                                        htmlColor={ICON_COLOUR}
                                        onClick={() => {
                                            if (releaseDocuments?.scrap_certificate !== undefined) {
                                                window.open(releaseDocuments?.scrap_certificate.map(document => document.file_path
                                                ))
                                            }
                                            else {
                                                message.showToastMessage({
                                                    message: "No Label Found.",
                                                    variant: "error",
                                                });
                                            }
                                        }}

                                    />
                                </div> : "N/A"} />

                        <GetInfoCard keyName={"Unserviceable Label"} value={
                            releaseDocuments?.unserviceable_label !== [] && releaseDocuments?.unserviceable_label?.length != 0 ?
                                <div style={{ marginTop: "3px" }}>
                                    <ViewIcon
                                        style={{ cursor: "pointer" }}
                                        fontSize="small"
                                        htmlColor={ICON_COLOUR}
                                        onClick={() => {
                                            if (releaseDocuments.unserviceable_label != []) {
                                                window.open(releaseDocuments.unserviceable_label.map(document => document.file_path
                                                ))
                                            }
                                            else {
                                                message.showToastMessage({
                                                    message: "No label Found.",
                                                    variant: "error",
                                                });
                                            }

                                        }}
                                    />
                                </div> : "N/A"} />
                    </div>
                </Box>
            </Box>
            {
                isRelease &&
                <ReleaseCertPopup
                    dialogCloseHandler={() => setIsRelease(false)}
                    open={isRelease}
                    msds={msds}
                />
            }
        </div>
    )
}

export default NoGoodsOut