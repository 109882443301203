import React from "react";

import { Box, Typography } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";

import { useReturnIssuedPartStyles } from "./styles";
import MROButton from "components/buttons";
import QrCodeIcon from "assets/icons/qr-code.png";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

function StepTwo(props) {
  const { setStep, handleToggle, value, errors, continueHandler } = props;

  const classes = useReturnIssuedPartStyles();

  return (
    <Box width="100%">
      <Box>
        <Typography align="center" className={classes.heading}>
          Print Label &amp; Place in RTS location
        </Typography>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        margin="0 auto"
        mb="20px"
        justifyContent="center"
      >
        <Typography className={classes.bodyText} style={{ marginBottom: 0 }}>
          Lost QR code?
        </Typography>
        <MROButton
          style={{ marginLeft: "24px", background: "#4FC607" }}
          type="button"
          variant={"contained"}
          color="secondary"
          onClick={() => setStep(3)}
        >
          <img
            src={QrCodeIcon}
            alt="qr-code"
            style={{ width: "18px", height: "18px", marginRight: "8px" }}
          />
          Print Label
        </MROButton>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        margin="0 auto"
        mt="32px"
        mb="23px"
      >
        <WarningIcon
          style={{ color: "#FFB300", marginLeft: "21px" }}
          fontSize="20px"
        />{" "}
        <Typography className={classes.bodyText} style={{ marginBottom: 0 }}>
          Place parts (with QR code) in RTS location pending return! Final
          return from RTS allocation state to Stock must be completed via the
          &ldquo;Return Parts&rdquo; process found in Pick Parts in the MRO-PRO
          APP.
        </Typography>
      </Box>

      <Box margin="0 auto" width="fit-content">
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box display="flex" mr="16px">
            <Typography
              gutterBottom={12}
              style={{
                color: errors?.rts_label ? "red" : "#4D4F5C",
                fontSize: "19px",
              }}
            >
              Confirm - Item/s in RTS location with label attached? *
            </Typography>
          </Box>
          <input style={{ display: "none" }} name="rts_label" value={value} />
          <ToggleButtonGroup
            value={value}
            name=""
            exclusive
            onChange={(e, val) => handleToggle(val)}
            aria-label="yes-or-no"
          >
            <ToggleButton
              // className={
              //   errors.part_removed && !errors?.part_removed?.valid
              //     ? classes.error
              //     : {}
              // }
              size={"small"}
              value="no"
              aria-label="centered"
            >
              No
            </ToggleButton>
            <ToggleButton
              // className={
              //   errors.part_removed && !errors?.part_removed?.valid
              //     ? classes.error
              //     : {}
              // }
              size={"small"}
              value="yes"
              aria-label="left aligned"
            >
              Yes
            </ToggleButton>
          </ToggleButtonGroup>{" "}
        </Box>
        {errors?.rts_label && (
          <Typography
            style={{ marginBottom: "47px" }}
            color="error"
            variant="body2"
          >
            {errors.rts_label}
          </Typography>
        )}
      </Box>

      <Box margin="0 auto" mt="48px" display="flex" justifyContent="center">
        <MROButton
          // loading={busy === "loading"}
          // type="submit"
          onClick={continueHandler}
          variant={"contained"}
          color="primary"
        >
          Complete
        </MROButton>
      </Box>
    </Box>
  );
}

export default StepTwo;
