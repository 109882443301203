import React from "react"



const Parts = ({ fill }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="25.401" height="25.833" viewBox="0 0 35.401 35.833" fill={fill}>
        <g id="gears" transform="translate(-3.085)">
            <g id="Layer_2_3_" transform="translate(3.085 0)">
                <g id="E404_gear_Media_media_player_multimedia" transform="translate(0 0)">
                    <path id="Path_20467" data-name="Path 20467" d="M29.2,150.315a.726.726,0,0,0-.722-.649h-1.61a2.127,2.127,0,0,1-1.968-1.323l-.006-.015A2.129,2.129,0,0,1,25.344,146l1.139-1.139a.726.726,0,0,0,0-1.026L25.249,142.6c-.064-.061-.126-.122-.186-.187l-.794-.794a.726.726,0,0,0-1.026,0l-1.139,1.14a2.127,2.127,0,0,1-2.331.454l-.015-.006a2.128,2.128,0,0,1-1.326-1.969v-1.61a.726.726,0,0,0-.726-.726h-3.13a.726.726,0,0,0-.726.726v1.61a2.127,2.127,0,0,1-1.323,1.968l-.015.006a2.129,2.129,0,0,1-2.333-.454l-1.139-1.142a.726.726,0,0,0-1.026,0L5.8,143.828a.725.725,0,0,0,0,1.025h0l1.139,1.139a2.128,2.128,0,0,1,.454,2.331l-.006.013a2.128,2.128,0,0,1-1.969,1.33H3.811a.726.726,0,0,0-.726.723v3.13a.726.726,0,0,0,.726.726H5.42a2.127,2.127,0,0,1,1.968,1.323l.006.015a2.128,2.128,0,0,1-.454,2.333L5.8,159.055a.725.725,0,0,0,0,1.025h0l2.214,2.214a.726.726,0,0,0,1.026,0l1.139-1.139a2.118,2.118,0,0,1,1.5-.623,2.17,2.17,0,0,1,.831.167l.017.008a2.126,2.126,0,0,1,1.322,1.967v1.61a.726.726,0,0,0,.725.728h3.131a.726.726,0,0,0,.726-.726v-1.61a2.127,2.127,0,0,1,1.323-1.968l.016-.007a2.128,2.128,0,0,1,2.331.454l1.137,1.139a.726.726,0,0,0,1.026,0l2.214-2.214a.726.726,0,0,0,0-1.026l-1.139-1.139a2.128,2.128,0,0,1-.454-2.331l.008-.019a2.127,2.127,0,0,1,1.967-1.321h1.61a.726.726,0,0,0,.726-.726v-3.2s0,0,0,0Zm-12.985,8h-.07a6.36,6.36,0,0,1-.066-12.72h.07a6.36,6.36,0,0,1,.07,12.72Z" transform="translate(-3.085 -129.179)" fill={fill} />
                    <path id="Path_20468" data-name="Path 20468" d="M124.087,254.55h-.054a4.961,4.961,0,0,0,.052,9.921h.054a4.961,4.961,0,0,0-.052-9.921Z" transform="translate(-111.029 -236.735)" fill={fill} />
                    <path id="Path_20469" data-name="Path 20469" d="M323.1,109.5h.033a2.985,2.985,0,0,0-.031-5.969h-.033a2.983,2.983,0,0,0-2.174,4.993l.195.195A2.972,2.972,0,0,0,323.1,109.5Z" transform="translate(-297.925 -96.284)" fill={fill} />
                    <path id="Path_20470" data-name="Path 20470" d="M245.29,8.554h-1.279a1.834,1.834,0,0,1-1.7-1.14l-.01-.027a1.833,1.833,0,0,1,.4-1.994l.9-.906a.432.432,0,0,0,0-.611h0l-1.759-1.759a.432.432,0,0,0-.611,0h0l-.9.9a1.834,1.834,0,0,1-2.009.392l-.019-.008h0a1.832,1.832,0,0,1-1.129-1.692V.433A.433.433,0,0,0,236.736,0h-2.487a.433.433,0,0,0-.433.433V1.711A1.833,1.833,0,0,1,232.684,3.4l-.016.007h0a1.834,1.834,0,0,1-2.009-.391l-.9-.9a.434.434,0,0,0-.612,0L227.38,3.876a.434.434,0,0,0,0,.612l.9.9a1.834,1.834,0,0,1,.392,2.009v0l-.007.017a1.832,1.832,0,0,1-1.692,1.135h-1.062a2.126,2.126,0,0,1,1.153,1.89v1.61a.731.731,0,0,0,.459.675l.019.008a.732.732,0,0,0,.8-.152l1.138-1.138a2.111,2.111,0,0,1,1.5-.622,1.334,1.334,0,0,1,.159.006,4.385,4.385,0,1,1,4.389,3.785h-.048q-.118,0-.235-.006a2.149,2.149,0,0,1,.07.554,2.111,2.111,0,0,1-.622,1.5L233.569,17.8a.732.732,0,0,0-.154.8l.006.017a.733.733,0,0,0,.676.461h1.61a2.12,2.12,0,0,1,1.462.582v-.918a1.834,1.834,0,0,1,1.139-1.7l.015-.006h.008a1.832,1.832,0,0,1,2,.4l.9.9a.431.431,0,0,0,.61,0h0l1.759-1.759a.431.431,0,0,0,0-.61h0l-.9-.908a1.832,1.832,0,0,1-.4-2l0-.01.006-.013a1.834,1.834,0,0,1,1.7-1.14h1.279a.433.433,0,0,0,.433-.433V8.986a.433.433,0,0,0-.431-.432Z" transform="translate(-210.32 0)" fill={fill} />
                </g>
            </g>
        </g>
    </svg>

)
export default Parts