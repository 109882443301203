import React, { useEffect, useReducer } from "react";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core";
import { Popover } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import MaterialTable from "material-table";
import tableReducer, { INITIAL_TABLE_STATE } from "reducers/table_reducer";
import tableIcons, {
  options,
} from "../../../../components/universal/table_attributes";
import TableAction from "actions/table_actions";

const StyledMenu = withStyles({
  paper: {
    width: "523px",
    height: "fit-content",
    top: "290px !important",
    padding: "30px",
    boxShadow: "0px 3px 6px #00000029",
  },
})((props) => (
  <Popover
    elevation={0}
    // getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

export default function HistoryTablePopup(props) {
  const { currentSelection, anchorEl, data, handleClose, busy} = props;

  const classes = useStyles();

  const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
  const { page, totalCount, pageSize } = tableState;

  // tableOptions
  const tableOptions = {
    ...options,
    page: page,
    total: totalCount,
    pageSize: pageSize,
    search: false,
    toolbar: false,
    paging: false,
    headerStyle: {
      textAlign: "center",
      paddingTop: "22px",
      paddingBottom: "16px",
    },
  };

  const columns = [
    {
      title: "Allocation",
      field: "status",
      render: (rowData) => rowData?.status?.toUpperCase(),
    },
    {
      title: "User",
      field: "user",
    },
    {
      title: "Base",
      field: "base_station",
    },
    {
      title: "Date / Time",
      field: "datetime",
    },
  ];

  return (
    <div style={{ position: "relative" }}>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchr
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          width="100%"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb="30px"
        >
          <Typography color="primary" className={classes.bodyText}>
            History
          </Typography>
          <Typography className={classes.bodyText} style={{ color: "#4D4F5C" }}>
            ALOC ID {currentSelection?.alloc_id}
          </Typography>
        </Box>

        <Box width="100%">
          <MaterialTable
            style={{ boxShadow: "0px 1px 3px #00000033" }}
            icons={tableIcons}
            columns={columns}
            data={data || []}
            // isLoading={loading}
            options={tableOptions}
            localization={{
              toolbar: {
                searchPlaceholder: "Search anything",
              },
              body: {
                emptyDataSourceMessage: "No histories Found",
                filterRow: {
                  filterTooltip: "Filter",
                },
              },
            }}
            isLoading={busy === "loading"}
            onChangePage={(page) => {
              TableAction.setPage(dispatch, page);
            }}
            onChangeRowsPerPage={(pageSize) => {
              TableAction.setPageSize(dispatch, pageSize);
            }}
            onSearchChange={(search) => {
              TableAction.setSearchText(dispatch, search);
            }}
            totalCount={totalCount}
            page={page}
          />
        </Box>
      </StyledMenu>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  bodyText: {
    fontSize: 20,
    lineHeight: "28px",
  },
}));
