import React, { useContext, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    Typography,
    Box
} from "@material-ui/core";
import MROButton from "components/buttons";
import { ToastMessageContext } from "lib/contexts/message_context";
import WarningIcon from "@material-ui/icons/Warning";
import exclamationIcon from "assets/exclamation.png"



const useStyles = makeStyles(() => ({
    modal: {
        "& .MuiDialog-paperWidthMd": {
            width: "55%",
            padding: '14px'
        },
    },
    title: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    warning1: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "13px",
        marginBottom: "20px",
    },
    warning: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "16px",
        marginBottom: "20px",
    },
    actionButtons: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}));

export default function ApprovalCaution(props) {
    const classes = useStyles();
    const { open, handleClose, popupValues, setAcceptAll, acceptAll, handleSubmit, onClose, toggleValue, busy } = props


    const handlePopUp = (e) => {
        handleClose()
        handleSubmit(e)
    }
    return (
        <div>
            <Dialog
                maxWidth={"md"}
                fullWidth={true}
                open={open}
                className={classes.modal}
                onClose={onClose}

            >

                <DialogTitle id="customized-dialog-title">
                    <Box style={{ display: "flex", justifyContent: "space-between" }}>
                        <Box display="flex" alignItems="center">
                            <Typography
                                style={{
                                    fontSize: "18px",
                                }}
                                color="primary"
                            >
                                Approval Cautions
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="center">
                            {" "}
                            <img
                                src={exclamationIcon}
                                alt="Icon"
                                style={{ height: "46px" }}
                            />{" "}
                        </Box>
                    </Box>
                </DialogTitle>

                <DialogContent>
                    <Grid item style={{ paddingBottom: '8px' }}>
                        <Box display="flex" alignItems="center">
                            <WarningIcon
                                style={{
                                    color: "#FFB300",
                                    marginRight: "10px",
                                    fontSize: 20,
                                }}
                            />
                            <Typography style={{ fontSize: "16px" }}>
                                {toggleValue === "yes" && "Approve ALL Maintenance Action covered by this approval!"}
                                {toggleValue === "no" && "Reject ALL Maintenance Action that have been rejected by this approval!"}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item style={{ paddingBottom: '8px' }}>
                        <Box display="flex" alignItems="center">
                            <WarningIcon
                                style={{
                                    color: "#FFB300",
                                    marginRight: "10px",
                                    fontSize: 20,
                                }}
                            />
                            <Typography style={{ fontSize: "16px" }}>
                                {toggleValue === "yes" && "Un-Approve & Reapprove previously Approved Actions if you want to reset Action Overview variances"}
                                {toggleValue === "no" && "If the item is being scrapped, then raise applicable maintenance actions to scrap the item!"}
                            </Typography>
                        </Box>
                    </Grid>

                    <Box pt={3} display="flex" alignItems="center" style={{
                        marginLeft: "11px",
                        paddingBottom: '15px'
                    }}>
                        <span style={{ textDecoration: "underline" }}>Accept all</span>
                        &nbsp;&nbsp;&nbsp;
                        <input
                            style={{ pointerEvents: "" }}
                            type="checkbox"
                            value={acceptAll}
                            onClick={() => {
                                setAcceptAll(!acceptAll);
                            }}
                        />
                    </Box>


                    <Grid className={classes.actionButtons} spacing={1} container>
                        <Grid item>
                            <MROButton
                                type="button"
                                variant={"contained"}
                                onClick={handleClose}
                            >
                                Cancel
                            </MROButton>
                        </Grid>
                        <Grid item>

                            <MROButton
                                type="button"
                                loading={busy === "loading"}
                                variant={"contained"}
                                color={"primary"}
                                style={{ backgroundColor: !acceptAll ? "#FFB300" : "", color: acceptAll ? "#ffff" : "#000000" }}
                                onClick={(e) => {
                                    acceptAll && handlePopUp(e);
                                }}
                                name="complete1"
                            >
                                Complete
                            </MROButton>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    )
}
