import React from "react";

export default function StatusDot(props) {
    const {variant,size} = props;
    let newStyle ={};
    if(size){
        newStyle = {
            width:size,
            height:size
        }
    }
    return (
        <span className={`dot ${variant}`} style={newStyle}/>
    )

}