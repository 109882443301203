import React, { useReducer, useEffect, useContext, useState } from 'react'
import { Grid, Paper, Typography, Box, IconButton } from '@material-ui/core'
import MaterialTable from 'material-table'
import moment from 'moment';
import tableIcons, { options } from 'components/universal/table_attributes';
import tableReducer, {
    INITIAL_TABLE_STATE,
} from 'reducers/table_reducer';
import TableAction from 'actions/table_actions'
import { ToastMessageContext } from "lib/contexts/message_context";
import { LineMaintainenceServices } from 'lib/services/api/dashboard/line_maintenance';
import { format24HrDateTime, getOrganisationId } from 'lib/utils/common_utils';
import { getTimeDiffFormatted } from 'views/Operations/LineMaintenance/WorkOrder/section_detail';
import DetailsPopUp from 'views/Planning/Line maintenance/LMEvents/DetailsPopUp';
import Visibility from '@material-ui/icons/Visibility';

import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import { downloadCsvFileFromUrlWithBase } from 'lib/utils/helperFunctions';

function Recovered({ selectedFilters, selectedBase, filterData, startDate, endDate }) {
    const [action, setAction] = useState('')
    const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
    const { page, pageSize, inProgress } = tableState;
    const [recoveredData, setRecoveredData] = useState([])
    const message = useContext(ToastMessageContext)
    const [loading, setLoading] = useState(false)
    const [totalCount, setTotalCount] = useState(10);
    const [selectedRowDetails, setSelectedRowDetails] = useState({})
    const tableOptions = {
        ...options,
        page: page,
        total: totalCount,
        pageSize: pageSize,
        search: false,
        sort: false

    };
    const getRecoveredListing = async () => {
        setLoading(true)
        const params = {
            organisation_id: getOrganisationId(),
            dial_name: "recovered",
            page: page + 1,
            limit: pageSize,
            start_date: startDate ? filterData.startdate : moment().subtract(7, 'days').format("YYYY-MM-DD"),
            end_date: endDate ? filterData.enddate : moment().format("YYYY-MM-DD")


        }
        selectedBase.forEach((item, index) => params[`base_station_id[${[index]}]`] = item)
        selectedFilters.forEach((item, index) => params[`line_customer_id[${[index]}]`] = item)
        await LineMaintainenceServices.getPerformanceListing(params).then((response) => {
            setRecoveredData(response.data)
            setTotalCount(response.total_count);
        }).catch((err) => {
            message.showToastMessage({
                message: "Something went wrong",
                variant: "error",
            })
        }).finally(() => {
            setLoading(false)
        })

    }

    useEffect(() => {
        getRecoveredListing()
    }, [page, pageSize, selectedFilters, selectedBase, totalCount, filterData])


    const columns = [
        {
            title: 'W/O #',
            field: 'work_order_number',
            width: '10%',
        },
        {
            title: 'Aircraft',
            field: 'aircraft_registration',


        },
        {
            title: 'Airline',
            field: 'customer_name',


        },
        {
            title: 'Base',
            field: 'base_station',


        },
        {
            title: 'Est Arrival',
            field: 'est_arr_date',
            render: rowData => rowData.est_arr_date ? format24HrDateTime(rowData.est_arr_date) : '-'


        },
        {
            title: 'Act Arrival',
            field: 'aircraft_arrival_date',
            render: rowData => rowData.aircraft_arrival_date ? format24HrDateTime(rowData.aircraft_arrival_date) : '-'

        },
        {
            title: 'Delay In',
            field: 'delayin',
            render: rowData => handleDelay(rowData.aircraft_arrival_date, rowData.est_arr_date)

        },
        {
            title: 'Est Departure',
            field: 'est_dep_date',
            render: rowData => rowData.est_dep_date ? format24HrDateTime(rowData.est_dep_date) : '-'
        },

        {
            title: 'Act Departure',
            field: 'act_dep_date',
            render: rowData => rowData.act_dep_date ? format24HrDateTime(rowData.act_dep_date) : '-'
        },
        {
            title: 'Delay',
            field: 'delay',
            render: rowData => handleDelay(rowData.act_dep_date, rowData.est_dep_date)

        },
        {
            title: 'Delay Code',
            field: 'delay_code',
            render: rowData => rowData.delay_code || '-'

        }, {
            title: 'Action',
            field: 'action',
            render: (rowData) => (
                <IconButton onClick={(e) => {
                    setAction('View')
                    setSelectedRowDetails(rowData)
                }
                }>

                    <Visibility fontSize="small" />
                </IconButton>

            )
        },

    ]
    const handleDelay = (value1, value2) => {

        const format = "YYYY-MM-DD - HH:mm:ss";
        const estArrivalDateTime = moment(value2, format);
        const actArrivalDateTime = moment(value1, format);
        const duration = moment.duration(actArrivalDateTime.diff(estArrivalDateTime))
        if (duration.isValid()) {
            const inbdDelayTime = getTimeDiffFormatted(duration)
            return inbdDelayTime
        } else {
            return "-"
        }

    }


    //const download Csv
    const handleDownloadCsv = async () => {
        // console.log('clicked======')
        setLoading(true)

        const params = {
            organisation_id: getOrganisationId(),
            dial_name: "recovered",
            start_date: startDate ? filterData.startdate : moment().subtract(7, 'days').format("YYYY-MM-DD"),
            end_date: endDate ? filterData.enddate : moment().format("YYYY-MM-DD"),
            download_csv: true
        }
        selectedBase.forEach((item, index) => params[`base_station_id[${[index]}]`] = item)
        selectedFilters.forEach((item, index) => params[`line_customer_id[${[index]}]`] = item)
        await LineMaintainenceServices.getPerformanceListing(params).then((response) => {
            downloadCsvFileFromUrlWithBase(response.file)
        }).catch((err) => {
            message.showToastMessage({
                message: "Something went wrong",
                variant: "error",
            })
        }).finally(() => {
            setLoading(false)
        })
    }
    return (
        <>
            <Grid xs={12} >

                <MaterialTable
                    style={{ boxShadow: '0px 1px 3px #00000033', paddingRight: '0px' }}
                    icons={tableIcons}
                    title={<Typography color="primary" variant="subtitle2">
                        Late in recoveries
                    </Typography>}
                    options={tableOptions}
                    data={recoveredData}
                    columns={columns}
                    isLoading={loading}
                    actions={[
                        {
                            icon: () => <DownloadIcon color="secondary" />,
                            tooltip: 'Download',
                            isFreeAction: true,
                            onClick: () => {
                                handleDownloadCsv()
                            }
                        }
                    ]}
                    onChangePage={(page) => {
                        TableAction.setPage(dispatch, page);
                    }}
                    onChangeRowsPerPage={(pageSize) => {
                        TableAction.setPageSize(dispatch, pageSize);
                    }}
                    onSearchChange={(search) => {
                        TableAction.setSearchText(dispatch, search);
                    }}
                    totalCount={totalCount}
                    page={page}
                />
                {action && action === 'View' && <DetailsPopUp handleClose={() => setAction(null)} selectedRowDetails={selectedRowDetails.id} />}
            </Grid>
        </>
    )
}

export default Recovered