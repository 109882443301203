import {DATA_SERVICE_ACTIONS} from "../actions/data_actions";
import CreateReducer from '../lib/utils/create_reducer';

export const INITIAL_DATA_STATE = {
    currentData:{},
    inProcessing:false
}

const dataReducer = CreateReducer(INITIAL_DATA_STATE, {
    [DATA_SERVICE_ACTIONS.TOGGLE_IN_PROGRESS](state,action){
        return {
            ...state,
            inProcessing:!!action.payload || !state.inProcessing
        }
    },
    [DATA_SERVICE_ACTIONS.CLEAR_DATA](state,action){
        return {
            ...state,
            inProcessing: false,
            currentData: {}
        }
    },
    [DATA_SERVICE_ACTIONS.SET_DATA](state,action){
        return {
            ...state,
            inProcessing: false,
            currentData:action.payload.data||action.payload[action.key]
        }
    },
});

export default dataReducer;