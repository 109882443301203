import BoxrListingService from "lib/services/api/boxr_listing_service";

export const TABLE_ACTIONS = {
    CHANGE_STATE: 'change_state_value',
    SET_RESPONSE: 'set_response_data',
    TOGGLE_IN_PROGRESS_STATE: 'toggle_in_progress',
    UPDATE_DATA_IN_LIST: 'update_data_in_list',
    SET_CURRENT_SELECTION: 'set_current_selection',
    CLEAR_STATE: 'clear_data'
}

function setPage(dispatch, page) {
    dispatch({ type: TABLE_ACTIONS.CHANGE_STATE, name: 'page', value: page });
}
function setSearchText(dispatch, searchText) {
    dispatch({ type: TABLE_ACTIONS.CHANGE_STATE, name: 'searchText', value: searchText })
}
function setPageSize(dispatch, pageSize) {
    dispatch({ type: TABLE_ACTIONS.CHANGE_STATE, name: 'pageSize', value: pageSize })
}
function setTotalCount(dispatch, totalCount) {
    dispatch({ type: TABLE_ACTIONS.CHANGE_STATE, name: 'totalCount', value: totalCount })
}
function getList(dispatch, message, params, section) {
    dispatch({ type: TABLE_ACTIONS.TOGGLE_IN_PROGRESS_STATE });
    BoxrListingService.getData(params, section).then((res) => {
        if (res.success) {
            dispatch({ type: TABLE_ACTIONS.SET_RESPONSE, payload: { data: res, totalCount: res.total_count || 0 } });
        }
        else {
            message && message.showToastMessage({ message: res.errors ? res.errors.join(', ') : "Something went wrong.Try Again", variant: "error" });
        }
    }).catch((e) => {
        dispatch({ type: TABLE_ACTIONS.TOGGLE_IN_PROGRESS_STATE });
        message.showToastMessage({ message: "Something went wrong. Try Again", variant: "error" })
    })
}

function addDataToList(tableDispatch, dataDispatch, data) {
    tableDispatch({ type: TABLE_ACTIONS.UPDATE_DATA_IN_LIST, payload: data });
    if (dataDispatch) {
        dataDispatch({ type: 'clear_data' });
    }
}

function clearData(tableDispatch) {
    tableDispatch({ type: TABLE_ACTIONS.CLEAR_STATE })
}


const BoxrTableAction = {
    setPage,
    setPageSize,
    setSearchText,
    setTotalCount,
    getList,
    addDataToList,
    clearData
}
export default BoxrTableAction;