import React, { useReducer, useEffect, useContext, useState } from 'react'
import { Grid, Paper, Typography, Box, Button } from '@material-ui/core'
import MaterialTable from 'material-table'
import moment from 'moment';
import tableIcons, { options } from 'components/universal/table_attributes';
import tableReducer, {
    INITIAL_TABLE_STATE,
} from 'reducers/table_reducer';
import TableAction from 'actions/table_actions'
import { ToastMessageContext } from "lib/contexts/message_context";
import { LineMaintainenceServices } from 'lib/services/api/dashboard/line_maintenance';
import { getOrganisationId } from 'lib/utils/common_utils';
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import { downloadCsvFileFromUrlWithBase } from 'lib/utils/helperFunctions';



function ManHours({ selectedFilters, selectedBase, filterData, startDate, endDate }) {
    const [action, setAction] = useState('')
    const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
    const { page, pageSize, inProgress } = tableState;
    const [manHoursData, setManHoursData] = useState([])
    const message = useContext(ToastMessageContext)
    const [loading, setLoading] = useState(false)
    const [totalCount, setTotalCount] = useState(10);

    const tableOptions = {
        ...options,
        page: page,
        total: totalCount,
        pageSize: pageSize,
        search: false,
        sort: false

    };
    const getManHoursListing = async () => {
        setLoading(true)
        const params = {
            organisation_id: getOrganisationId(),
            dial_name: "man_hours",
            page: page + 1,
            limit: pageSize,
            start_date: startDate ? filterData.startdate : moment().subtract(7, 'days').format("YYYY-MM-DD"),
            end_date: endDate ? filterData.enddate : moment().format("YYYY-MM-DD")


        }
        selectedBase.forEach((item, index) => params[`base_station_id[${[index]}]`] = item)
        selectedFilters.forEach((item, index) => params[`line_customer_id[${[index]}]`] = item)
        await LineMaintainenceServices.getPerformanceListing(params).then((response) => {
            setManHoursData(response.data)
            setTotalCount(response.total_count);
        }).catch((err) => {
            message.showToastMessage({
                message: "Something went wrong",
                variant: "error",
            })
        }).finally(() => {
            setLoading(false)
        })

    }

    useEffect(() => {
        getManHoursListing()
    }, [page, pageSize, selectedFilters, selectedBase, filterData])


    const columns = [
        {
            title: 'Base',
            field: 'base_station',

        },
        {
            title: 'Airline',
            field: 'customer',
            width: '30%',
            headerStyle: {
                width: '30%',
                textAlign: 'left',


            },
            cellStyle: {
                width: '30%',
                textAlign: 'left',


            },



        },
        {
            title: 'Schd Checks',
            field: 'schd_checks',


        },
        {
            title: 'TL Tasks',
            field: 'tl_tasks',


        },
        {
            title: 'Cabin',
            field: 'cabin',


        },
        {
            title: 'N-Fixed Price',
            field: 'n_fixed_price',


        },
        {
            title: 'Defects',
            field: 'defects',


        }, {
            title: 'Planned',
            field: 'planned',


        },
        {
            title: 'Total',
            field: 'total_man_hours',

        }


    ]

    //const download Csv
    const handleDownloadCsv = async () => {
        // console.log('clicked======')
        setLoading(true)

        const params = {
            organisation_id: getOrganisationId(),
            dial_name: "man_hours",
            start_date: startDate ? filterData.startdate : moment().subtract(7, 'days').format("YYYY-MM-DD"),
            end_date: endDate ? filterData.enddate : moment().format("YYYY-MM-DD"),
            download_csv: true
        }
        selectedBase.forEach((item, index) => params[`base_station_id[${[index]}]`] = item)
        selectedFilters.forEach((item, index) => params[`line_customer_id[${[index]}]`] = item)
        await LineMaintainenceServices.getPerformanceListing(params).then((response) => {
            downloadCsvFileFromUrlWithBase(response.file)
        }).catch((err) => {
            message.showToastMessage({
                message: "Something went wrong",
                variant: "error",
            })
        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <>
            <Grid xs={12} >

                <MaterialTable
                    style={{ boxShadow: '0px 1px 3px #00000033', paddingRight: '0px' }}
                    icons={tableIcons}
                    title={<Typography color="primary" variant="subtitle2">
                        Man Hours
                    </Typography>}
                    options={tableOptions}
                    data={manHoursData}
                    columns={columns}
                    isLoading={loading}
                    onChangePage={(page) => {
                        TableAction.setPage(dispatch, page);
                    }}
                    onChangeRowsPerPage={(pageSize) => {
                        TableAction.setPageSize(dispatch, pageSize);
                    }}
                    onSearchChange={(search) => {
                        TableAction.setSearchText(dispatch, search);
                    }}
                    totalCount={totalCount}
                    page={page}
                    actions={[
                        {
                            icon: () => <DownloadIcon color="secondary" />,
                            tooltip: 'Download',
                            isFreeAction: true,
                            onClick: () => {
                                handleDownloadCsv()
                            }

                        }
                    ]}
                />

            </Grid>
        </>
    )
}

export default ManHours