import React, { useState, useEffect } from "react";
import MROTextField from "../../../../../components/form_components/TextField";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import SubtractIcon from "@material-ui/icons/Remove";
function NumericField(props) {

    const { handleChange, formState } = props;
    const [val, setVal] = useState(props.field_value)

    const inputProps = {
        max: props.max_length,
        step: props.decimal_places ? (1 / (10 ^ props.decimal_places)) : null,
        readOnly: props.disabled ? true : false
    }
    const fieldProps = {
        // required: props.mandatory || false,
        type: "text",
        label: props.field_name === "Tool Pre-Check Completed By - Stamp #" ? 'Tool Pre-Checks *' :
            props.mandatory ? props.field_name + ' *' : props.field_name,
        name: props.id || props.org_field_id,
        defaultValue: props.field_value,
        error: props.errors && props.errors[props.id || props.org_field_id] && !props.errors[props.id || props.org_field_id].valid,
        autoFocus: props.errors && props.errors[props.id || props.org_field_id] && !props.errors[props.id || props.org_field_id].valid,
        secondary_fields: props.secondary_fields ? {
            // required: props.secondary_fields[0].mandatory,
            label: props.secondary_fields[0].field_name,
            name: props.secondary_fields[0].org_field_id + ' *',
            defaultValue: props.secondary_fields[0].field_value,
            disabled: props.secondary_fields[0].disabled,
            error: props.errors && !props?.errors[props.secondary_fields[0].org_field_id].valid,
            autoFocus: props.errors && !props?.errors[props.secondary_fields[0].org_field_id].valid,

        } : {}
    }

    const handleValueControl = (type) => {
        const currentVal = val || 0;
        if (type === 'inc') {
            setVal(+currentVal + 1);
            handleChange({ name: props.id || props.org_field_id, value: +currentVal + 1 });
        }
        else if (type === 'dec') {
            setVal(+currentVal >= 1 ? +currentVal - 1 : 0);
            handleChange({ name: props.id || props.org_field_id, value: +currentVal >= 1 ? +currentVal - 1 : 0 });
        }
    }

    const secondaryCheck = props.field_value >= 1 && props.secondary_field;

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
            <div style={{
                width: props.noIncreament ? '65%' : '83.5%', display: 'inline-block',
                width: secondaryCheck ? '75%' : '100%', paddingRight: secondaryCheck ? '25px' : '25px'
            }}>
                <MROTextField
                    {...fieldProps}
                    inputProps={inputProps}
                    InputLabelProps={{ shrink: true }}
                    // disabled={!props?.edit}
                    value={val}
                    onChange={(e) => {
                        handleChange({ name: e.target.name, value: e.target.value });
                        setVal(e.target.value);
                    }}
                    style={inputProps.readOnly ? { opacity: 0.55, cursor: 'not-allowed !important' } : {}}
                />
            </div>
            {
                props.noIncreament &&
                <div style={{ width: '35%', paddingLeft: '25px', display: 'inline-block' }}>
                    <ButtonGroup color="primary" aria-label="Add or Reduce">
                        <Button size={"small"} onClick={() => handleValueControl('dec')}><SubtractIcon fontSize={"small"} /></Button>
                        <Button size={"small"} onClick={() => handleValueControl('inc')}><AddIcon fontSize={"small"} /></Button>
                    </ButtonGroup>
                </div>
            }
            {
                secondaryCheck &&
                <div style={{
                    display: 'inline-block',
                    width: '75%', paddingRight: '25px'
                }}>
                    <MROTextField
                        {...fieldProps.secondary_fields}
                        inputProps={{
                            ...inputProps,
                            readOnly: props.secondary_fields[0].disabled ? true : false
                        }}
                        style={props.secondary_fields[0].disabled ? { opacity: 0.55, cursor: 'not-allowed !important' } : {}}
                        InputLabelProps={{ shrink: true }}
                        // disabled={!props?.edit}
                        onChange={(e) => {
                            handleChange({ name: e.target.name, value: e.target.value });
                            console.log(e.target.name)
                        }}
                    />
                </div>
            }
        </div>
    )
}

export default React.memo(NumericField);